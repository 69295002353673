<template>
  <!-- <div :class="(viewType_ === 'card' ? 'card' : '')" v-if="agreement_.AgreementId"> -->
  <el-card
    v-if="agreement_.AgreementId"
    :shadow="(viewType_ === 'card' ? 'always' : 'never')"
    :body-style="(viewType_ === 'card' ? '' : { padding: '0' })"
    :style="(viewType_ === 'card' ? '' : { border: 'none' })">
    <!-- <div v-if="viewType_ === 'card'" class="card-header">
      <span>{{agreement_.Name}}</span>
    </div> -->
    <div v-if="viewType_ === 'card'" slot="header">
      <span>{{agreement_.Name || 'New Agreement'}}</span>
    </div>
    <!-- <div :class="(viewType_ === 'card' ? 'card-block' : '')"> -->
    <el-tabs type="card">
      <el-tab-pane label="Basic">
        <agreement-edit
            v-if="!agreement_.UI || (agreement_.UI === 'Standard')"
            :p-agreement.sync="agreement_"
            @agreement-copied="$emit('agreement-copied', $event)"
            @agreement-deleted="$emit('agreement-deleted', $event)"
            :onSave="onSave"
        ></agreement-edit>
        <charitable-donation-edit
            v-if="agreement_.UI === 'CharitableDonation'"
            :agreement.sync="agreement_"
            @update-agreement="refreshChangeLogList"
            @deleted="$emit('agreement-deleted', $event)"
        ></charitable-donation-edit>
      </el-tab-pane>
      <el-tab-pane label="Schedule" :disabled="!(agreement_.AgreementId > 0)" v-if="agreement_.UI === 'Standard'">
        <span slot="label"><i class="el-icon-date"></i> Schedule &amp; Allocation</span>
        <schedule-and-allocation :agreement.sync="agreement_"></schedule-and-allocation>
      </el-tab-pane>
      <el-tab-pane label="Allocation" v-if="agreement_.UI === 'CharitableDonation' && agreement_.AgreementId > 0">
        <allocation :params="allocationParameters"></allocation>
      </el-tab-pane>
      <el-tab-pane label="Transactions" :disabled="!(agreement_.AgreementId > 0)" v-if="agreement_.UI === 'Standard'">
        <agreement-transaction-list :agreement-id="agreement_.AgreementId"></agreement-transaction-list>
      </el-tab-pane>
      <el-tab-pane label="Documents" :disabled="!(agreement_.AgreementId > 0)">
        <attachment-list :show-upload="true" :params="attachmentParameters" :security-level="securityLevel_"></attachment-list>
      </el-tab-pane>
      <el-tab-pane label="Change Log" :disabled="!(agreement_.AgreementId > 0)">
        <change-log-list v-if="agreement_.AgreementId > 0 && showChangeLogList" schema="liq" table="Agreement" :entity-id="agreement_.AgreementId" />
      </el-tab-pane>
    </el-tabs>
    <!-- </div> -->
  <!-- </div> -->
  </el-card>
</template>

<script>
import Vue from 'vue';
import mixinSchema_liq from './../DAL/mixinSchema_liq';
import AgreementEdit from './AgreementEdit.vue';
import CharitableDonationEdit from '@/views/Agreements/CharitableDonationEdit.vue';
import ScheduleAndAllocation from './ScheduleAndAllocation.vue';
import AgreementTransactionList from './AgreementTransactionList.vue';
import AttachmentList from './../Attachments/AttachmentList.vue';
import Allocation from './../Allocation/Allocation.vue';
import ChangeLogList from './../ChangeLog/ChangeLogList.vue';

export default Vue.extend({
  name: 'AgreementEditView',
  mixins: [mixinSchema_liq],
  props: {
    agreement: Object,
    agreementId: [Number, String],
    viewType: String,
    securityLevel: {
      type: Number,
      default: null
    },
    onSave: Function
  },
  components: {
    'agreement-edit': AgreementEdit,
    'charitable-donation-edit': CharitableDonationEdit,
    'schedule-and-allocation': ScheduleAndAllocation,
    'agreement-transaction-list': AgreementTransactionList,
    'attachment-list': AttachmentList,
    'allocation': Allocation,
    'change-log-list': ChangeLogList
  },
  data: function() {
    return {
      viewType_: this.viewType || 'card',
      securityLevel_: this.securityLevel,
      showChangeLogList: true,
      agreement_: this.liq_GetAgreements_New()
    };
  },
  created: function() {
    if (this.securityLevel_ === null) {
      this.securityLevel_ = tryParseInt(
        getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP),
        0
      );
    }
  },
  mounted: async function(){
    if (this.agreement && Object.keys(this.agreement).length > 0) {
      this.agreement_ = this.agreement;
    }
    else if (this.agreementId && this.agreementId > 0){
      const el = this;
      await this.liq_GetAgreements_Object({
          AgreementId: this.agreementId
        }
        , function (data) {
          el.agreement_ = data;
        }
      );
    }
    else {
      this.agreement_ = this.liq_GetAgreements_New();
      this.agreement_.AgreementId = -1;
      this.agreement_.UI = 'Standard';
    }
  },
  computed: {
    attachmentParameters: function() {
      return {
        entityId: this.agreement_.AgreementId,
        entityType: 'Agreement',
        storeCategoryId: 40,
        clientId: this.agreement_.ClientId
      };
    },
    allocationParameters: function() {
      return {
        EntityId: this.agreement_.AgreementId,
        EntityType: 'Agreement',
        ClientId: this.agreement_.ClientId
      };
    }
  },
  watch: {
    agreement: function(val, oldVal) {
      this.$emit('update:agreement', this.agreement_);
    }
  },
  methods: {
    refreshChangeLogList: async function() {
      this.showChangeLogList = false;
      await this.$nextTick();
      this.showChangeLogList = true;
    }
  }
});
</script>