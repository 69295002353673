 <template>
    <div>
        <el-form ref="frmBankAccountEdit" :model="Main" label-width="35%" size="small" :disabled="securityLevel_<80">
            <div class="divButtons text-right" v-if="!viewType" style="margin-bottom: 5px;">
                <el-button type="success" plain size="mini" icon="el-icon-check" @click="save">Save</el-button>
            </div>
            <el-form-item label="VAM Name:" prop="Name" :rules="[{required: true, message: 'Please select VAM Name', trigger: 'blur'}]">
                <el-input v-model="Main.Name" class="fullContent"></el-input>
            </el-form-item>
            <el-form-item label="Client:" prop="ClientId" :rules="[{required: true, type: 'integer', message: 'Please select Client', trigger: 'change'}]">
                <client-selector
                    v-model="Main.ClientId"
                    class="fullContent"
                    filterable clearable default-first-option
                ></client-selector>
            </el-form-item>
            <el-form-item label="Owner:" prop="OwnerId" :rules="[{required: true, type: 'integer', message: 'Please select Owner', trigger: 'change'}]">
                <el-select v-model="Main.OwnerId" :disabled="!owners.length" class="fullContent" filterable clearable>
                    <el-option v-for="owner in owners" :key="owner.Id" :label="owner.Name" :value="owner.Id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Joint Owners:" v-if="Main.Id" >
                <div v-if="!jointBankAccountOwners.length">None</div>
                    <div v-for="o in jointBankAccountOwners" :key="o.JointBankAccountOwnerId">
                        <span>{{o.Name}} </span> <i class="el-icon-delete" @click="deleteJointBankAccountOwner(o.JointBankAccountOwnerId)" style="color:red"></i>
                    </div>
                    <div v-if="securityLevel_>=80">
                        <el-button @click="openJointBankAccountOwnerAssigner" type="text">Add Joint Owner</el-button>
                    </div>

            </el-form-item>
            <el-form-item label="Bank:" prop="BankId" :rules="[{required: true, message: 'Please select Bank', trigger: 'change'}]">
                <bank-selector
                    v-model="Main.BankId"
                    @options-fetched="banksFetched"
                    v-show="Main.ClientId"
                    :client-id="Main.ClientId"
                    class="fullContent"
                ></bank-selector>
            </el-form-item>
            <el-form-item label="Acct Type:" prop="Type" :rules="[{required: true, message: 'Please select Acct Type', trigger: 'change'}]">
                <el-select filterable v-model="Main.Type" class="fullContent">
                    <el-option label="Checking" value="Checking"></el-option>
                    <el-option label="Savings" value="Savings"></el-option>
                    <el-option label="Investment" value="Investment"></el-option>
                    <el-option label="Credit Card" value="Credit Card"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Chart of Accounts:" prop="COAId" :rules="[{required: true, message: 'Please select Chart of Accounts', trigger: 'change'}]">
                <el-cascader
                    :options="chartOfAccountsHierarchy"
                    :filter-method="filterMethodCoA"
                    filterable
                    expand-trigger="hover"
                    @change="handleAccountNodeClick"
                    v-model="chartOfAccountsArray"
                    class="fullContent"
                ></el-cascader>
            </el-form-item>
            <el-form-item label="Account Number:" prop="Number" :rules="[{required: true, message: 'Please select Number', trigger: 'blur'}]">
                <el-input v-model="Main.Number" class="fullContent"></el-input>
            </el-form-item>
            <el-form-item label="Primary Number:" prop="PrimaryNumber" >
                <el-input v-model="Main.PrimaryNumber" class="fullContent"></el-input>
                <small title="If this account is included in another account's statement, enter that account's number here">i.e. Parent Account Number</small>
            </el-form-item>
            <el-form-item label="Balance Sheet Category:" prop="BalanceSheetCategoryId" :rules="[{required: true, message: 'Please select Balance Sheet Category', trigger: 'change'}]">
                <el-select v-model="Main.BalanceSheetCategoryId" :disabled="!balanceSheetSubCategories.length" class="fullContent" filterable clearable>
                    <el-option v-for="category in balanceSheetSubCategories" :key="category.Id" :label="category.label" :value="category.Id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Key Account:">
                <el-switch active-text="Yes" inactive-text="No" v-model="Main.IsKeyAccount"></el-switch>
            </el-form-item>
            <el-form-item label="Dashboard Order:" prop="DashboardOrder">
                <el-input-number v-model="Main.DashboardOrder"></el-input-number>
            </el-form-item>
            <el-form-item label="Description:" prop="Description">
                <el-input v-model="Main.Description" class="fullContent"></el-input>
            </el-form-item>
            <el-form-item label="Alert at Minimum Balance:" prop="AlertMinimumBalance">
                <formatted-input v-model="Main.AlertMinimumBalance" formatType="currency" :numDecimals="2" class="fullContent" style="max-width: 160px;"></formatted-input>
                <small v-if="Main.Type=='Investment'" title='Investment Accounts will include Treasuries and Cash Equivalents when evaluating Minimum Balance'>Includes Treasuries and Cash Equivalents</small>
            </el-form-item>
            <el-form-item label="Date Acquired:" prop="AcquiredDate">
                <el-date-picker v-model="Main.AcquiredDate" type="date" placeholder="Pick a day" class="fullContent" style="max-width: 160px;"></el-date-picker>
            </el-form-item>
            <el-form-item label="Date Closed:" prop="ClosedDate">
                <el-date-picker v-model="Main.ClosedDate" type="date" placeholder="Pick a day" class="fullContent" style="max-width: 160px;"></el-date-picker>
            </el-form-item>
            <el-form-item label="Opening Balance:" prop="StartingBalance">
                <el-date-picker 
                    :value="Main.StartingBalanceMonth + ''" 
                    @input="Main.StartingBalanceMonth = $event"
                    type="month" placeholder="Optional" value-format="yyyyMM" class="fullContent" style="max-width: 160px;"></el-date-picker>
                <el-currency-input v-model="Main.StartingBalance" class="fullContent" style="max-width: 160px;" />
            </el-form-item>
            <el-form-item label="Manual Transactions:" prop="ManualTransactions">
                <el-checkbox v-model="Main.ManualTransactions"></el-checkbox>
            </el-form-item>
            <el-form-item label="Signers:">
                <div v-if="Main.Id > 0">
                    <div v-if="!bankAccountSigners.length">None</div>
                    <div v-for="bankAccountSigner in bankAccountSigners" :key="bankAccountSigner.AssociationId">
                        <span>{{bankAccountSigner.KeyPersonLastFirst}}: </span>
                        <span title="Internal">{{bankAccountSigner.Limit | currency(0)}}</span>
                        /
                        <span title="External">{{bankAccountSigner.LimitExternal | currency(0)}}</span>
                        <i class="el-icon-delete" @click="deleteBankAccountSigner(bankAccountSigner.AssociationId)" style="color:red"></i>
                    </div>
                    <div v-if="securityLevel_>=80">
                        <a href="javascript:;" :class="{ 'appear-disabled': !(Main.ClientId > 0) }" :disabled="!(Main.ClientId > 0)" @click="openBankAccountSignerAssigner" 
                            :title="Main.ClientId > 0 ? '' : 'Client must be selected before signers can be added'">Add signer</a>
                    </div>
                </div>
                <div v-else style="font-style: italic; color: grey;">Bank account must be saved before signers can be added.</div>
            </el-form-item>
            <el-form-item label="Created:" v-if="!!Main.BankAccountId">
                <span class="form-control-static" >{{Main.CreateDateLabel}}</span>
            </el-form-item>
            <el-form-item label="Modified:" v-if="!!Main.BankAccountId">
                <span class="form-control-static">{{Main.ModifyDateLabel}}</span>
            </el-form-item>
            <el-form-item label="Id:" v-if="!!Main.BankAccountId">
                <span class="form-control-static" >{{Main.BankAccountId}}</span>
            </el-form-item>
            <div style="display: none;" sm-forceexport>
                <span>Click <a :href='`${location.origin}/smartControl/BankAccounts/BankAccountsManagementView?params=${encodeURIComponent(JSON.stringify({AccountId: Main.Id}))}`'>here</a> to view this bank account in VAM.</span>
            </div>
        </el-form>
        <div v-if="viewType === 'dialog' || viewType === 'SmartPopup'" class="text-lg-right" style="padding-top: 20px;">
            <el-button @click="closePopup">Cancel</el-button>
            <el-button type="success" plain @click="save">Save</el-button>
        </div>
        
        <el-dialog title="Add Signer" :visible.sync="showBankAccountSignerAssigner" append-to-body width="400px">
            <bank-account-signer-assigner
                v-if="showBankAccountSignerAssigner"
                :bank-account="Main"
                @close-dialog="closeBankAccountSignerAssigner"
            ></bank-account-signer-assigner>
        </el-dialog>
        <el-dialog title="Add Joint Owner" :visible.sync="showJointBankAccountOwnerAssigner" append-to-body width="400px">
            <el-select v-model="jointOwnerIdToAdd" v-if="showJointBankAccountOwnerAssigner" class="fullContent" filterable clearable>
                <el-option v-for="owner in jointBankAccountOwnerOptions" :key="owner.JointBankAccountOwnerId" :label="owner.Name" :value="owner.OwnerId"></el-option>
            </el-select>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showJointBankAccountOwnerAssigner = false">Close</el-button>
                <el-button type="primary" plain :disabled="!jointOwnerIdToAdd || securityLevel_<80" @click="addJointBankAccountOwner">Add Joint Owner</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import mixinSchema_BalanceSheet from './../DAL/mixinSchema_balanceSheet'
    import mixinSchema_system from './../DAL/mixinSchema_system'
    import BankSelector from '@/components/form/BankSelector.vue';
    import ClientSelector from '@/components/form/ClientSelector.vue';
    // import OwnerSelector from '@/components/form/OwnerSelector.vue';
    import FormattedInput from '@/components/form/FormattedInput.vue';
    import BankAccountSignerAssigner from '@/views/BankAccount/BankAccountSignerAssigner.vue';
    import findIndex from 'lodash/findIndex';

    export default Vue.extend({
        name: 'BankAccountEdit'
        , mixins: [
            mixinUtility
            , mixinSchema_liq
            , mixinSchema_vam
            , mixinSchema_BalanceSheet
            , mixinSchema_system
        ]
        , components: {
            'bank-selector': BankSelector
            , 'client-selector': ClientSelector
            // , 'owner-selector': OwnerSelector
            , 'formatted-input': FormattedInput
            , 'bank-account-signer-assigner': BankAccountSignerAssigner
        }
        , props: {
            bankAccountId: {
                type: Number
                , required: false
                , default: null
            }
            , viewType: {
                type: String
                , required: false
                , default: null
            }
            , onSave: {
                type: Function
                , required: false
                , default: null
            }
            , closeHandler: {
                type: Function
                , required: false
                , default: null
            }
            , securityLevel: {
                type: Number,
                default: null
            },
        }
        , data: function () {
            return {
                Main: this.liq_GetAccounts_New()
                // , clients: []
                , owners: []
                , balanceSheetSubCategories: []
                , chartOfAccounts: []
                , chartOfAccountsHierarchy: []
                , bankAccountSigners: []
                , isNew: !(this.bankAccountId > 0)
                , location: window.location
                , showBankAccountSignerAssigner: false
                , showJointBankAccountOwnerAssigner: false
                , securityLevel_: this.securityLevel
                , banks: []
                , jointBankAccountOwnerOptions: []
                , jointOwnerIdToAdd: null
            }
        }
        , created: async function () {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(
                    getStoredSecurityLevel(this.$namedKey.SecurityView.ManageBankAccounts),
                    0
                );
            }
            if (this.bankAccountId > 0) {
                this.Main = await this.liq_GetAccounts_Object({
                    AccountId: this.bankAccountId
                });
            }
            // this.clients = await this.vam_GetUserClients({});
            this.fetchOwners();
            this.fetchChartOfAccounts();
            this.fetchBalanceSheetSubCategories();
            this.fetchBankAccountSigners();
        }
        , watch: {
            'Main.ClientId': function (val, oldVal) {
                if (this.Main.Id === null || oldVal !== null) { // when oldVal == null on an existing record, that is when the value is initially set to the db value, and we don't want that to trigger a change
                    this.Main.OwnerId = null; //if Client is changed, then owner must be reset
                    this.fetchOwners();
                    this.fetchChartOfAccounts();
                    this.fetchBalanceSheetSubCategories();
                }
            }
        }
        , computed: {
            chartOfAccountsArray: {
                get: function () {
                    if (this.Main.COAId && this.chartOfAccounts.length) {
                        const account = this.getSelectedArrayItem(this.chartOfAccounts, this.Main.COAId, 'Id');
                        if (account) {
                            return JSON.parse(account.IdArray);
                        }
                        else {
                            return [];
                        }
                    }
                    else {
                        return [];
                    }
                },
                set: function (newValue) {
                    this.Main.COAId = newValue;
                }
            }
            , jointBankAccountOwners(){
                return this.jointBankAccountOwnerOptions.filter(o => o.JointBankAccountOwnerId);
            }
        }
        , methods: {
            save: async function () {
                this.$refs.frmBankAccountEdit.validate(async valid => {
                    if (valid) {
                        try {
                            if (this.Main.Id > 0) {
                                const so = new SmartObject('LiquidityAccount', this.Main.Id);
                                await so.updateObject(this.Main);

                                this.$notify.success('Bank account updated');
                            }
                            else {
                                const so = new SmartObject('LiquidityAccount');
                                await so.createObject(this.Main);
                                this.Main.Id = so.entityId;
                                
                                this.$notify.success('Bank account created');

                                //await this.$nextTick();
                                //this.sendNotificationEmail();
                            }

                            if (typeof this.onSave === 'function') {
                                this.onSave(this.Main.Id, this.Main.Name);
                            }
                        }
                        catch (err) {
                            this.$notify.error('Something went wrong processing your request, please try again.');
                        }
                    }
                    else {
                        this.$message.error('Not Saved.  See Validation Errors.');
                        return false;
                    }
                });
            }
            , sendNotificationEmail: async function () {
                this.$notify.info('Sending notification email...');

                const toAddresses = {};
                const dsNotifyNewBankAccount = this.system_GetNamedKeys('System.NotifyNewBankAccount');
                dsNotifyNewBankAccount.forEach(nk => {
                    toAddresses[nk.KeyText] = nk.KeyText;
                });

                try {
                    var result = await $(
                        this.$refs.frmBankAccountEdit.$el
                    ).exportToEmail({
                        toAddresses: toAddresses,
                        subject: `New Bank Account Created: ${this.Main.Name}`,
                        additionalAttributes: {
                            EntityType: 'Account',
                            EntityId: this.Main.Id,
                            Source: 'Bank Account Creation'
                        }
                    });

                    if (result.succeeded) {
                        this.$notify.success('Notification email sent.');
                    } else {
                        this.$notify.error('Notification email failed.');
                    }
                } catch (err) {
                    this.$notify.error('Notification email failed.');
                }
            }
            , handleAccountNodeClick(data) {
                this.Main.COAId = data[data.length - 1];
            }
            , fetchBalanceSheetSubCategories: async function () {
                this.balanceSheetSubCategories = await this.BalanceSheet_GetSubCategories({
					ClientId: this.Main.ClientId
					}
				);
            }
            , fetchOwners: async function () {
                if (this.Main.ClientId > 0) {
                    this.owners = await this.vam_GetOwnersV2({
                        ClientId: this.Main.ClientId
                        , ClientIdList: undefined
                    });
                }
                else {
                    this.owners = [];
                }
                this.fetchJointOwners();
            }
            , async fetchJointOwners() {
                if (this.Main.Id){
                        this.jointBankAccountOwnerOptions = await this.liq_GetJointBankAccountOwners({
					        BankAccountId: this.Main.Id
					    }
					    , true // async (optional)
                    );
                    console.log(this.jointBankAccountOwnerOptions)
                }                
            }
            , fetchChartOfAccounts: async function () {
                if (this.Main.ClientId > 0) {
                    this.chartOfAccounts = await this.vam_GetChartOfAccountsForTree({
                        ClientId: this.Main.ClientId
					});
                    this.chartOfAccountsHierarchy = this.getCascaderHierarchy(this.chartOfAccounts, 'Id', 'Name', 'ParentId');
                }
                else {
                    this.chartOfAccountsHierarchy = []
                }
            }
            , fetchBankAccountSigners: async function () {
                if (this.Main.Id > 0) {
                    this.bankAccountSigners = await this.liq_GetBankAccountSigners({
                        BankAccountId: this.Main.Id
                    });
                }
            }
            , openBankAccountSignerAssigner: function () {
                if (this.Main.ClientId > 0) {
                    this.showBankAccountSignerAssigner = true;
                }
            }
            , openJointBankAccountOwnerAssigner: function () {
                this.showJointBankAccountOwnerAssigner = true;
            }
            , closePopup: function () {
                if (typeof this.closeHandler === 'function') {
                    this.closeHandler();
                }
                this.$emit('close');
            }
            , closeBankAccountSignerAssigner: function () {
                this.showBankAccountSignerAssigner = false;
                this.fetchBankAccountSigners();
            }
            , closeJointBankAccountOwnerAssigner: function () {
                this.showBankAccountSignerAssigner = false;
                this.fetchBankAccountSigners();
            }
            , filterMethodCoA(item, query) {
                return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
            , deleteBankAccountSigner: async function (AssociationId) {
                if (AssociationId > 0) {
                    try {
                        await this.$confirm(
                            'Are you sure you want to remove this signer?',
                            'Warning',
                            {
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'Cancel',
                                type: 'warning'
                            }
                        );

                        await new SmartObject('BankAccountSigners', AssociationId).deleteObject();

                        const indexOfDeletedRecord = findIndex(
                            this.bankAccountSigners,
                            bankAccountSigner => {
                                return bankAccountSigner.AssociationId === AssociationId;
                            }
                        );
                        this.bankAccountSigners.splice(indexOfDeletedRecord, 1);

                        this.$notify.success('Signer removed.');
                    } catch (err) {}
                }
            }
            , deleteJointBankAccountOwner: async function (jointBankAccountOwnerId) {
                if (jointBankAccountOwnerId > 0) {
                    try {
                        await this.$confirm(
                            'Are you sure you want to remove this joint bank account owner?',
                            'Warning',
                            {
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'Cancel',
                                type: 'warning'
                            }
                        );

                        await new SmartObject('JointBankAccountOwners', jointBankAccountOwnerId).deleteObject();
                        
                        this.fetchJointOwners();
                        await this.$nextTick();
                        this.$notify.success('Joint owner removed.');
                    } catch (err) {}
                }
            }
            , async addJointBankAccountOwner(){
                if (this.Main.Id && this.jointOwnerIdToAdd){
                    const so = new SmartObject('JointBankAccountOwners');
                    await so.createObject({
                        BankAccountId: this.Main.Id,
                        OwnerEntityId: this.jointOwnerIdToAdd
                    });
                    this.$notify.success('Joint owner removed.');
                    this.fetchJointOwners();
                    this.jointOwnerIdToAdd = null;
                    this.showJointBankAccountOwnerAssigner = false;
                }
            }
            , async banksFetched(banks){
                this.banks = banks;
            }
        }
    })
</script>

<style scoped>
    .el-form-item{
        margin-bottom:5px;
    }

    .el-button--mini.is-circle {
        padding: 1px;
    }

    a.appear-disabled {
        color: grey;
        cursor: not-allowed;
        text-decoration: none !important;
    }
</style>