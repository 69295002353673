
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as AvService from '../../services/DAL/avService';
import * as Common from '../../utils/common';

@Component({
    inheritAttrs: false
})
export default class AirportSelector extends Vue {
    //#region Private declarations for Services
    private _avService: AvService.AvService;
    private _common: Common.default;
    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: string;
    //#endregion Props

    @Prop({default: true, type: Boolean}) usOnly: boolean;
    @Prop({default: true, type: Boolean}) frequentOnly: boolean;
    //#region Data
    airports = [] as AvService.Airports[];
    selectedValue: string = null;
    isLoading: boolean = false;
    //#endregion Data

    //#region Computed
    get airportsForSelect(): any[] {
        return this._common.getDDLOptionsWithGroup(this.airports, 'Grouping', 'Grouping', 'Display', 'ICAO');
    }

    //#endregion Computed

   //#region Lifecycle
    async created() {
        this._avService = new AvService.AvService();
        this._common = new Common.default();
    }
    async mounted(){
        this.selectedValue = this.value;

        if (this.selectedValue) {
            this.fetchAirports(this.selectedValue);
        }
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: string, oldVal: string) {
        this.selectedValue = val;
    }
    //#endregion Watches

    //#region Methods
    async fetchAirports(searchTerm: string) {
        this.isLoading = true;

        const parameters = {} as AvService.GetAirportsParameters;
        parameters.Search = searchTerm;
        parameters.USOnly = this.usOnly;
        parameters.FrequentOnly = this.frequentOnly;
        
        this.airports = await this._avService.GetAirports(parameters);
        
        this.$emit('options-fetched', this.airports);
        
        if (this.airports.length === 1) {
            this.selectedValue = this.airports[0].ICAO;
            this.$emit('single-option-available', this.selectedValue);
            if (this.selectedValue !== this.value) {
                this.$emit('input', this.selectedValue);
            }
        }

        this.isLoading = false;
    }
    //#endregion Methods
}
