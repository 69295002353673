
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import ClientSelector from '@/components/form/ClientSelector.vue';
import * as AssetService from './../../services/DAL/assetService';
import formatters from '../../utils/formatters';
import ElementUI from 'element-ui';
import uniqBy from 'lodash/uniqBy';

declare var $;
declare function tryParseInt(input: any, defaultValue: number): number;


@Component({
    components: {
        ClientSelector
    }
})
export default class InvestmentParentList extends Vue {
    $refs: {
        tblInvestmentParentList: ElementUI.Table
        , ucInvestmentEdit: HTMLDivElement
    }
    //#region Data
    private _assetService: AssetService.AssetService;
    private formatters: formatters;

    @Prop({type: Number}) readonly clientId!: number;
    @Prop({type: Number}) readonly investmentParentId!: number;

    clientId_: number = this.clientId || null;
    investmentParentId_: number = tryParseInt(this.investmentParentId, null);

    filterInvestmentParentList: string = ''
    investmentParentList = [] as AssetService.InvestmentParentList[];
    isLoading = false;
    
    //#endregion Data

    //#region Computed
	get investmentParentListFiltered(): AssetService.InvestmentParentList[] {
		const filter_ = (this.filterInvestmentParentList || '').toLowerCase();
        return this.investmentParentList.filter(entity => {
            return (
				(entity.Id || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.Name || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.Currency || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.InvestmentType || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.Sector || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.Ownership || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.Region || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.Investments || '').toString().toLowerCase().indexOf(filter_) > -1
            );
        });
    }

    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this.formatters = new formatters();
    }

    async mounted() {
        if (this.$route && this.$route.meta && this.$route.meta.filterText) this.filterInvestmentParentList = this.$route.meta.filterText;

        if (!!this.clientId_ || !!this.investmentParentId_) {
            await this.getInvestmentParents();

        }
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('clientId_')
    onClientChange(val: number, oldVal: number) {
        if (!(oldVal == undefined && val == null)){
            this.getInvestmentParents();
        }
    }

    //#endregion Watches
    //#region Methods
    async editInvestmentparentlistItem (investmentParent: AssetService.InvestmentParentList, event, column) {
        if (this.$route) this.$route.meta.filterText = this.filterInvestmentParentList;
        if (investmentParent && investmentParent != null) {
            this.$router.push({ name: 'Investment', params: { investmentId: investmentParent.Id.toString() } }).catch(() => {});

        }
    }

    filterHandler(value, row, column) {
        const property = column['property'];
        return row[property] === value;
    }
    filters(column){
        const filters = this.investmentParentList.map(function(list) {
            return {
                text: list[column]
                , value: list[column]
                };
        });
        return uniqBy(filters, 'value');
    }
    async getInvestmentParents() {
        this.isLoading = true;
        this.investmentParentList = [] as AssetService.InvestmentParentList[];
        const params = {} as AssetService.GetInvestmentParentListParameters;
        params.ClientIdList = this.clientId_.toString();
        this.investmentParentList = await this._assetService.GetInvestmentParentList(params);
        this.isLoading = false;
    }
    
    //#endregion Methods
}
