

import Vue from "vue";
import { Component, Watch, Prop, Ref } from 'vue-property-decorator';
import * as UtilService from '@/services/DAL/utilService';
import * as VamService from '@/services/DAL/vamService';
import ClientSelector from '@/components/form/ClientSelector.vue';
import VendorEditTabs from '@/views/Vendor/VendorEditTabs.vue';
import uniqBy from "lodash/uniqBy";

import formatters from "../../utils/formatters";
import ElementUI from "element-ui";

declare function getStoredSecurityLevel(Id: number): number;

@Component({
  components: {
    ClientSelector,
    VendorEditTabs
  },
})
export default class VendorList extends Vue {
  @Ref() readonly refVendors!: ElementUI.Table;
  //#region Data
  private _utilService: UtilService.UtilService;
  private _vamService: VamService.VamService;
  private formatters: formatters = new formatters();

  @Prop({ type: Number }) readonly clientId!: number;

  selectedClientId: number = null;
  
  filterVendors: string = ''
  vendors = [] as VamService.Vendors[];
  selectedVendor = {} as VamService.Vendors;
  loading: boolean = false;
  showDataPane: boolean = false;


  securityLevel_: number = getStoredSecurityLevel(
    this.$namedKey.SecurityView.ManageAP
  );
  
  tableHeight: number = null;
  //#endregion Data

  //#region Lifecycle
  async created() {
    window.addEventListener("resize", this.setTableHeight);
    this._utilService = new UtilService.UtilService();
    this._vamService = new VamService.VamService();
    this.formatters = new formatters();
  }

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight);
  }
  async mounted() {
    this.setTableHeight();
  }
  //#endregion Lifecycle

  //#region Computed
  get showDetailColumns(): boolean {
    return (
      ["xs", "sm", "md"].indexOf(this.$getBootstrapSize()) > 0 ||
      !this.showDataPane
    );
  }
get vendorsFiltered(): VamService.Vendors[] {
		const filter_ = (this.filterVendors || '').toLowerCase();
        return this.vendors.filter(entity => {
            return (
				(entity.Name || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.City || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.State || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.ClientCount || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.AgreementCount || '').toString().toLowerCase().indexOf(filter_) > -1
            );
        });
    }
  //#endregion Computed

  //#region Watches
  @Watch("selectedClientId")
  onChange_ClientId(val, oldVal) {
    this.vendors = [] as VamService.Vendors[];
    if (this.selectedClientId) {
      this.fetchVendors();
    }
  }
  

  //#endregion Watches

  //#region Methods
  setTableHeight() {
    // 9/22/21: This is THE solution for proper table sizing with floating header. Wrap it in a el-row with flex and height set to tableHeight, and set table's max-height to tableHeight.  see other references here to setTableHeight.
    this.tableHeight = this.$el.clientHeight - 100 - 38; //100: Selectors; 38: Filter w/ padding (ClientHeight is what is inside the router.  934 in DEV)
    this.refVendors.doLayout();
  }
  async editVendorsItem(selectedRow: VamService.Vendors, event, column) {
    if (selectedRow && selectedRow != null) {
      this.showDataPane = false;
      await this.$nextTick();
      this.showDataPane = true;
      this.selectedVendor = selectedRow;
    }
  }


  async deletedVendor() {
    this.closeVendor();
    await this.fetchVendors();
  }
  closeVendor() {
    this.showDataPane = false;
    this.selectedVendor = null;
    if (this.refVendors) {
      this.refVendors.setCurrentRow(null);
    }
  }

  async fetchVendors() {
    this.loading = true;
    this.closeVendor();
    const params = {} as VamService.GetVendorsParameters;
    params.ClientId = !this.selectedClientId ? null : this.selectedClientId;
    this.vendors = await this._vamService.GetVendors(params);

    this.loading = false;
  }
  filterHandler(value, row: VamService.Vendors, column) {
    const property = column["property"];
    return row[property] === value;
  }
  filters(column) {
        const filters = this.vendors.map(function(list) {
            return {
                text: list[column]
                , value: list[column]
                };
        });
        return uniqBy(filters, 'value');
    }
    rowClassName({row, rowIndex}): string {
      if (!row.IsComplete){
          return 'warning-row';
      }
      return '';
    }

  //#endregion Methods
}
