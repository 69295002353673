<template>
    <div>
        <el-table v-if="Main[0]"
                  :data="Main"
                  :summary-method="getSummary"
                  size="mini"
                  show-summary
                  v-loading="isLoading"
                  style="width: 100%;"
                  @expand-change="handleExpand"
                  stripe>
            <el-table-column type="expand">
                <template slot-scope="props">
                    <p v-if="!!(props.row.InvoiceDescription)">Invoice Description: {{ props.row.InvoiceDescription}}</p>
                    <p>Bank Trans: {{ props.row.Description}}</p>
                    <p>Bank Full Transaction Amount: <a :href="props.row.TransactionURL" target="_blank">{{ props.row.FullBankTransactionAmount | currency}}</a> </p>
                    <span class="h4" v-show="!!props.row.InvoiceId && !!props.row.allocations && props.row.allocations.length">Allocations:</span>
                    <el-table v-if="props.row.InvoiceId && props.row.allocations && props.row.allocations[0]"
                              :data="props.row.allocations"
                              show-summary :summary-method="getAllocationSummary"                              
                              style="width: 100%;"
                              stripe>
                        <el-table-column label="Chart Of Accounts Path"
                                         min-width="350"
                                         :show-overflow-tooltip="true"
                                         sortable
                                         prop="ChartOfAccountsPath">
                            <template slot-scope="scope">
                                <span>{{scope.row.ChartOfAccountsPath}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Department"
                                         min-width="200"
                                         :show-overflow-tooltip="true"
                                         sortable
                                         prop="Department">
                            <template slot-scope="scope">
                                <span>{{scope.row.Department}}</span>
                            </template>
                        </el-table-column>                
                        <el-table-column label="Amount"
                                         min-width="100"
                                         sortable
                                         prop="Amount" align="right">
                            <template slot-scope="scope">
                                <span>{{scope.row.Amount | currency}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Agreement Amt"
                                         min-width="140"
                                         sortable
                                         prop="AmountForAgreement" align="right">
                            <template slot-scope="scope">
                                <span>{{scope.row.AmountForAgreement | currency}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column label="Invoice"
                             prop="InvoiceDisplay">
                <template slot-scope="props">
                    <template v-if="(props.row.EntityType != 'Agreement')">
                        <router-link v-if="props.row.InvoiceId"
                            :to="{ name: 'Invoices', params: { invoiceId: props.row.InvoiceId }}" target="_blank">{{props.row.InvoiceDisplay}}
                        </router-link>
                        <el-popover 
                            v-if="props.row.EntityType == 'ScheduleItem' && props.row.InvoiceId != null"
                            placement="top-start"
                            title="Transaction matched to agreement schedule, not the listed invoice.  However, the listed invoice was referenced to the matched agreement schedule."
                            trigger="hover"
                            >
                            <span slot="reference" style="font-weight:bold" >&nbsp; *</span>
                        </el-popover>
                    </template>
                    <template v-else>
                        <router-link v-if="props.row.InvoiceId"
                            :to="{ name: 'Invoices', params: { invoiceId: props.row.InvoiceId }}" target="_blank">{{props.row.InvoiceDisplay}}
                        </router-link>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="Schedule Date"
                             prop="Date"
                             min-width="75"
                             align="center"
                             :formatter="formatterShortDate">
            </el-table-column>
            <el-table-column label="Schedule Amt"
                             prop="ScheduledAmount"
                             :formatter="formatterCurrency"
                             v-if="!showPledgeFields"
                             min-width="100"
                             align="right">
            </el-table-column>
            <el-table-column label="Agreement Amt"
                             prop="AgreementAmount"
                             :formatter="formatterCurrency"
                             v-if="!showPledgeFields"
                             min-width="100"
                             align="right">
            </el-table-column>
            <el-table-column label="Bank Posted"
                             prop="BankTransactionAmount"
                             min-width="150"
                             align="center"
                             >
                <template slot-scope="props">
                    <span v-if="!!props.row.PostedDate">{{props.row.PostedDate | shortDate}} {{props.row.BankTransactionAmount | currency}}</span>
                    <span v-else>No Bank Transaction</span>
                </template>    
            </el-table-column>
            <el-table-column label="Paid Amount"
                             prop="PaidAmount"
                             min-width="100"
                             align="right">
                <template slot-scope="props">
                    <template v-if="(Math.abs((Math.abs(props.row.ScheduledAmount) - Math.abs(props.row.PaidAmount)) / Math.abs(props.row.ScheduledAmount)) > .01) && props.row.ScheduledAmount != null">
                        <el-popover 
                            placement="top-start"
                            title="Paid Amount Different than Scheduled Amount"
                            trigger="hover"
                            >
                            <el-tag slot="reference" type="danger">{{props.row.PaidAmount | currency}}</el-tag>
                            <ul>
                                <li>
                                    Scheduled Amount: {{0-props.row.ScheduledAmount | currency }}
                                </li>
                                <li>
                                    Paid Amount: {{props.row.PaidAmount | currency }}
                                </li>
                            </ul>
                        </el-popover>
                    </template>
                    <template v-else>
                        {{props.row.PaidAmount | currency}}
                    </template>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import utility from './../VueCommon/Utility.js' //has formatters

    export default Vue.extend({
        name: 'AgreementTransactionsList'
        , mixins: [mixinSchema_liq, utility]
        , props: {
            agreementId: {
                type: Number
                , required: true
            }
        }
        , data() {
            return {
                Main: {}
                , isLoading: false
            }
        }
        , created: function () {
            this.fetchMain();
        }
        , watch: {
            'agreementId': function (val, oldVal) {
                this.fetchMain();
            }
        }
        , computed: {
            showPledgeFields: function(){
                if (!!this.Main && this.Main.length && Object.keys(this.Main[0]).length) return this.Main[0].ShowPledgeFields || false;
                else return false;
            }
        }
        , methods: {
            fetchMain: async function () {
                this.isLoading = true;
                this.Main = await this.liq_GetAgreementTransactions(
                    {
                        AgreementId: this.agreementId
                    });
                this.isLoading = false;
            }
            , async handleExpand (row, expandedRows) {
                console.log(row, expandedRows);
                // To Do: Allocations are not displaying on first expand.  They only show up if you expend, collapse, then expand again.
                if (row.allocations && row.allocations.length) {
                    console.log('aleady have allocations');
                }
                else if (row.InvoiceId){
                    row.allocations = await this.liq_GetInvoiceRegister({
                        InvoiceId: row.InvoiceId
                    });
                    console.log('Allocations', row.allocations);
                }
                else {
                    row.allocations = [];
                }
                await this.$nextTick();
            }
            , getSummary(param) {
                const columnList = [3,4,5,6];
                //const totalLabel = 'Total';
                const totalLabelIndex = 1;
                return this.getSummaryArray(param, columnList, undefined, totalLabelIndex);
            }
            , getAllocationSummary(param, t) {
                const columnList = [2,3];
                const totalLabel = 'Total';
                const totalLabelIndex = 0;
                return this.getSummaryArray(param, columnList, totalLabel, totalLabelIndex);
            }
        }
    })
</script>

<style >
    .el-table__footer-wrapper {
        font-weight: bold;
    }
</style>
