// TS API for Schema reports
// Auto-generated 01/27/20 07:40


declare var smartData: any;
// output classes:
export class ActivitySources {
    Source: string = undefined;
}
export class ConfigurationPermissionsForUser {
    ConfigurationId: number = undefined;
    ConfigurationType: string = undefined;
    Configuration: string = undefined;
    SecurityLevel: number = undefined;
    HasPermission: boolean = undefined;
    PermissionId: number = undefined;
    ConfigurationTypeId: number = undefined;
}
export class Configurations {
    Id: number = undefined;
    Name: string = undefined;
    ClientId: number = undefined;
    Description: string = undefined;
}
export class ConfigurationSettingEntityTypes {
    Id: number = undefined;
    ConfigurationTypeId: number = undefined;
    ConfigurationSettingId: number = undefined;
    EntityType: string = undefined;
    Label: string = undefined;
}
export class DefaultOwners {
    Id: number = undefined;
}
export class EntitySettingConfigurations {
    ConfigurationSettingId: number = undefined;
    Label: string = undefined;
    DataType: string = undefined;
    DisplayType: string = undefined;
    SortIndex: number = undefined;
}
export class EntitySettings {
    Id: number = undefined;
    EntityType: string = undefined;
    EntityId: number = undefined;
    Label: string = undefined;
    SettingBit: boolean = undefined;
    SettingInt: number = undefined;
    SettingDecimal: number = undefined;
    ClientId: number = undefined;
    ConsolidateBalanceSheet: boolean = undefined;
    ConsolidateDetail: boolean = undefined;
}
export class Groups {
    Id: number = undefined;
    Name: string = undefined;
}
export class LayoutGroupDetail {
    Id: number = undefined;
    AccountId: number = undefined;
    IncludeDescendantAccounts: boolean = undefined;
    IncludeDescendantAccountsNull: boolean = undefined;
    DepartmentId: number = undefined;
    OwnerId: number = undefined;
    Factor: number = undefined;
    LayoutGroupId: number = undefined;
    Exclude: boolean = undefined;
    Sign: string = undefined;
    EntityType: string = undefined;
    EntityId: number = undefined;
    Modified: string = undefined;
    AccountNamePath: string = undefined;
    AccountIdArray: string = undefined;
    OwnerName: string = undefined;
    ClientId: number = undefined;
    DepartmentName: string = undefined;
    EntityName: string = undefined;
    ValidFromDisplay: string = undefined;
    ValidToDisplay: string = undefined;
    ValidFrom: string = undefined;
    ValidTo: string = undefined;
}
export class LayoutGroups {
    Id: number = undefined;
    LayoutId: number = undefined;
    Name: string = undefined;
    DisplayOrder: number = undefined;
    ParentId: number = undefined;
    AlternateDisplay: string = undefined;
    Factor: number = undefined;
    ValidFrom: string = undefined;
    ValidTo: string = undefined;
    LastModified: string = undefined;
    Children: number = undefined;
    Icon: string = undefined;
}
export class Layouts {
    Id: number = undefined;
    LayoutId: number = undefined;
    Name: string = undefined;
}
export class OverallSettings {
    ConfigurationSettingId: number = undefined;
    ConfigurationSettingsId: number = undefined;
    Label: string = undefined;
    SettingBit: boolean = undefined;
}
export class ReportParameters {
    Prompt: string = undefined;
    Placeholder: string = undefined;
    Field: string = undefined;
    ControlName: string = undefined;
    ReportParameterId: number = undefined;
    ParameterId: number = undefined;
    DefaultValue: string = undefined;
    Hidden: boolean = undefined;
    Required: boolean = undefined;
    IsDynamic: boolean = undefined;
    Parameter: string = undefined;
    Type: string = undefined;
    Explanation: string = undefined;
}
export class ReportPermissionsForUser {
    ReportId: number = undefined;
    ReportGroup: string = undefined;
    DisplayName: string = undefined;
    Description: string = undefined;
    GroupId: number = undefined;
    SecurityLevel: number = undefined;
    HasPermission: boolean = undefined;
    PermissionId: number = undefined;
}
export class Reports {
    ReportGroup: string = undefined;
    FileName: string = undefined;
    DisplayName: string = undefined;
    Description: string = undefined;
    ReportId: number = undefined;
    GroupId: number = undefined;
    Disabled: boolean = undefined;
    IsMaintainer: boolean = undefined;
}

// Parameter interfaces:

export interface GetConfigurationPermissionsForUserParameters {
    UserId: string;
}

export interface GetConfigurationsParameters {
    ClientId: number;
    ConfigurationTypeId: number;
}

export interface GetConfigurationSettingEntityTypesParameters {
    ConfigurationSettingId: number;
}

export interface GetDefaultOwnersParameters {
    ConfigurationId: number;
}

export interface GetEntitySettingsParameters {
    ConfigurationId: number;
    ConfigurationSettingId: number;
}

export interface GetLayoutGroupDetailParameters {
    LayoutGroupId: number;
}

export interface GetLayoutGroupsParameters {
    LayoutId: number;
    ParentId: number;
}

export interface GetOverallSettingsParameters {
    ConfigurationId: number;
}

export interface GetReportParametersParameters {
    ReportId: number;
}

export interface GetReportPermissionsForUserParameters {
    UserId: string;
}

export interface GetReportsParameters {
    GroupId: number;
}

export class ReportsService {
  GetActivitySources (): Promise<ActivitySources[]> {
    return smartData({
        storedProcName: 'reports.GetActivitySources',
        params: {}
      });
    }

  GetConfigurationPermissionsForUser (params: GetConfigurationPermissionsForUserParameters): Promise<ConfigurationPermissionsForUser[]> {
    return smartData({
        storedProcName: 'reports.GetConfigurationPermissionsForUser',
        params: params
      });
    }

  GetConfigurations (params: GetConfigurationsParameters): Promise<Configurations[]> {
    return smartData({
        storedProcName: 'reports.GetConfigurations',
        params: params
      });
    }

  GetConfigurationSettingEntityTypes (params: GetConfigurationSettingEntityTypesParameters): Promise<ConfigurationSettingEntityTypes[]> {
    return smartData({
        storedProcName: 'reports.GetConfigurationSettingEntityTypes',
        params: params
      });
    }

  GetDefaultOwners (params: GetDefaultOwnersParameters): Promise<DefaultOwners[]> {
    return smartData({
        storedProcName: 'reports.GetDefaultOwners',
        params: params
      });
    }

  GetEntitySettingConfigurations (): Promise<EntitySettingConfigurations[]> {
    return smartData({
        storedProcName: 'reports.GetEntitySettingConfigurations',
        params: {}
      });
    }

  GetEntitySettings (params: GetEntitySettingsParameters): Promise<EntitySettings[]> {
    return smartData({
        storedProcName: 'reports.GetEntitySettings',
        params: params
      });
    }

  GetGroups (): Promise<Groups[]> {
    return smartData({
        storedProcName: 'reports.GetGroups',
        params: {}
      });
    }

  GetLayoutGroupDetail (params: GetLayoutGroupDetailParameters): Promise<LayoutGroupDetail[]> {
    return smartData({
        storedProcName: 'reports.GetLayoutGroupDetail',
        params: params
      });
    }

  GetLayoutGroups (params: GetLayoutGroupsParameters): Promise<LayoutGroups[]> {
    return smartData({
        storedProcName: 'reports.GetLayoutGroups',
        params: params
      });
    }

  GetLayouts (): Promise<Layouts[]> {
    return smartData({
        storedProcName: 'reports.GetLayouts',
        params: {}
      });
    }

  GetOverallSettings (params: GetOverallSettingsParameters): Promise<OverallSettings[]> {
    return smartData({
        storedProcName: 'reports.GetOverallSettings',
        params: params
      });
    }

  GetReportParameters (params: GetReportParametersParameters): Promise<ReportParameters[]> {
    return smartData({
        storedProcName: 'reports.GetReportParameters',
        params: params
      });
    }

  GetReportPermissionsForUser (params: GetReportPermissionsForUserParameters): Promise<ReportPermissionsForUser[]> {
    return smartData({
        storedProcName: 'reports.GetReportPermissionsForUser',
        params: params
      });
    }

  GetReports (params: GetReportsParameters): Promise<Reports[]> {
    return smartData({
        storedProcName: 'reports.GetReports',
        params: params
      });
    }
}