
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as DebtService from '../../services/DAL/debtService';
import formatters from '../../utils/formatters';
import uniqBy from 'lodash/uniqBy';
import Common from '../../utils/common';
import ElementUI from 'element-ui';
import alasql from 'alasql';
import * as XLSX from 'xlsx';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;

declare var SmartObject: any;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function getStoredSecurityLevel(Id: number): number;

@Component
export default class InstrumentList extends Vue {
    $refs: {
        refInstruments: ElementUI.Table
    }
    //#region Private declarations for Services
    private _debtService: DebtService.DebtService;
    private common: Common;
    public formatters: formatters;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({type: Number}) readonly instrumentId: number;
    @Prop({type: Number}) readonly investmentId: number;
    @Prop({ type: Number, default: null }) readonly securityLevel: number;

    //#endregion Props

    //#region Data
    instruments = [] as DebtService.Instruments[];
    selectedInstrument = {} as DebtService.Instruments;
    showInstrument: boolean = false;
    loading: boolean = false;
    securityLevel_: number = 0;
    //#endregion Data

    //#region Lifecycle
    created() {
        this._debtService = new DebtService.DebtService();
        this.formatters = new formatters();
        this.common = new Common();
        this.securityLevel_ = this.securityLevel || 
            tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets), 0);
    }
    async mounted() {
        await this.fetchInstruments();
    }
    //#endregion Lifecycle

    //#region Computed
    //#endregion Computed

    //#region Watches
    @Watch('instrumentId', { deep: true })
    async instrumentIdChanged(val: any, oldVal: any) {
        this.fetchInstruments();
    }

    //#endregion Watches

    //#region Computed
    //#endregion Computed

    //#region Methods
    async fetchInstruments(){
        this.instruments = [] as DebtService.Instruments[];
        this.loading = true;
        const params = {} as DebtService.GetInstrumentsParameters;
        params.InvestmentId = this.investmentId;
        params.InstrumentId = this.instrumentId;
        this.instruments = await this._debtService.GetInstruments(params);
        await this.$nextTick();
        if (this.$refs.refInstruments){
        this.$refs.refInstruments.doLayout();
        }
        this.loading = false;
    }
    filterHandler(value, row, column) {
        const property = column['property'];
        return row[property] === value;
    }
    filters(column){
        const filters = this.instruments.map(function(list) {
            return {
                text: list[column]
                , value: list[column]
                };
        });
        return uniqBy(filters, 'value');
    }
    async editInstrumentItem(
        selectedRow: DebtService.Instruments,
        event,
        column
    ) {
        if (selectedRow && selectedRow != null) {
            const instrumentId = selectedRow.Id.toString();
            this.$router.push({ name: 'Instrument', params: { instrumentId } }).catch(() => {});
        }
    }
    // newInstrument(){
    //     this.selectedInstrument = {} as DebtService.Instruments;
    //     const newInstrumentInstrumentId = this.instrumentId.toString();
    //     this.$router.push({ name: 'New Instrument', params: { newInstrumentInstrumentId } }).catch(() => {});
    // }
    // deletedInstrument(transactionId: number){
    //     if (!!this.selectedInstrument && this.selectedInstrument.Id == transactionId){
    //         const index = this.instruments.indexOf(this.selectedInstrument);
    //         this.instruments.splice(index, 1);
    //         this.selectedInstrument = {} as DebtService.Instruments;
    //     }
    //     else{
    //         this.fetchInstruments();
    //     }
    // }
    async exportItems(){
        if (this.instruments.length > 0) {
            var opts = [{ sheetid: this.instrumentId.toString(), header: true }];
            try {
                
                await alasql.promise(`SELECT * INTO XLSX("Headers ${this.instrumentId.toString()} .xlsx",?) FROM ?`, [opts, [this.instruments]])
                this.$notify.success('Excel file exported.');
            }
            catch(err) {
                this.$notify.error(err.message);
            }
        }
    }


    //#endregion Methods
}
