
    import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import * as DebtService from '@/services/DAL/debtService';
    import ConsiderationList from '@/views/InvestmentTransaction/Consideration/ConsiderationList.vue';
    import cloneDeep from 'lodash/cloneDeep';
    import Common from '@/utils/common';
    import { Field } from "node_modules/vee-validate/types";
    import VueMarkdown from '@adapttive/vue-markdown'
    import StakeTransInvestment from '@/views/InvestmentTransaction/Funds/StakeTransactionInvestment.vue';
    import * as stakeTransactionTypes from './stakeTransactionTypes';
    import { ConsiderationsType } from '@/services/IConsiderationTypes.interface';


import ElementUI from "element-ui";

    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;
    declare var SmartObject: any;
    interface iValuePremiumDiscount{
            baseConsumption: number
            , consideration: number
            , amount: number
    }


    @Component({
        components: {
            ConsiderationList,
            StakeTransInvestment,
            //@ts-ignore
            VueMarkdown
        }
    })
    export default class StakeTransaction extends Vue {
    @Ref() readonly refConsiderationList!: ConsiderationList;

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private common: Common;

    //#endregion Private declarations for Services

    //#region Props
    @Prop( { type: Object }) header: TransactionsService.Header;
    @Prop( { type: Number }) transactionHeaderId: number;
    //#endregion Props

    //#region Data
    loading = false;
    securityLevel_: number = null;
    showHeaderNotes = false;
    
    considerations: Array<ConsiderationsType > = [];
    
    header_ = {} as TransactionsService.Header;
    stakeTransactionInvestments = [] as stakeTransactionTypes.iStakeTransactionInvestment[];
    
    showAttachmentList: boolean = true;
    showChangeLogList: boolean = true;
    selectedTab: string = '1';
    
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.common = new Common();

        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
        if (!this.header || Object.keys(this.header).length == 0 || this.header.SubTypeShort == undefined){
            await this.getTransactionHeader();
        }
        else {
            this.header_ = cloneDeep(this.header)
        }
        await this.getTransactionInvestments();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('header')
    async headerChanged(val: TransactionsService.Header, oldVal: TransactionsService.Header) {
        const idChanged = (oldVal.Id != val.Id);
        this.header_ = cloneDeep(this.header);
    }
    
    //#endregion Watches

    //#region Computed
    get isFormValid(): boolean{
        const formValid = !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
        if (!formValid) return formValid;

        if (this.outOfBalance) return false;
        if (!this.baseCapitalTransactions || !this.baseCapitalTransactions.length) return false;
        if (this.operation == 'Swap' && (!this.swapCapitalTransactions || !this.swapCapitalTransactions.length)) return false;

        if (!this.baseConsumptionValue || this.baseConsumptionValue == 0) return false;
        if (!this.totalConsideration || this.totalConsideration == 0 ) return false;

        return true;
    }

    get baseInvestmentRecords(): stakeTransactionTypes.iStakeTransactionInvestment[]{
        return this.stakeTransactionInvestments.filter(eti => !eti.IsSwap);
    }
    get swapInvestmentRecords(): stakeTransactionTypes.iStakeTransactionInvestment[]{
        return  this.stakeTransactionInvestments.filter(eti => eti.IsSwap);
    }
    get baseCapitalTransactions(): stakeTransactionTypes.iCapitalTransaction[] {
        const mapRecords = this.baseInvestmentRecords.map(eti => eti.capitalTransaction)
        return mapRecords.flat();
    }
    get swapCapitalTransactions(): stakeTransactionTypes.iCapitalTransaction[] {
        const mapRecords = this.swapInvestmentRecords.map(eti => eti.capitalTransaction)
        return mapRecords.flat();
    }
    get operation(): string{
        if (this.header_){
            return this.header_.SubTypeShort;
        }
    }
    get operationDirection(): string{
        if (this.header_){
            return this.header_.SubTypeBaseDirection;
        }
    }
    get isTransferOrSwap(): boolean{
        return (this.operation == 'Transfer' || this.operation == 'Swap')  && !(this.header_ && this.header_.SubTypeScope == '3rd Party');
    }
    get noConsideration(): boolean {
        return (this.header_ && this.header_.SubTypeNoConsideration);
    }
    get useConsideration(): boolean {
        return !this.noConsideration; 
    }
    get considerationDirection(): string{
        return this.oppositeDirection(this.operationDirection);
    }
    get otherConsiderationTotal(): number{
        let otherConsideration: number = 0;
        if (this.considerations && this.considerations.length){
            otherConsideration = this.considerations.reduce(function(a, b) {
                return a + b.ConsiderationValue; // was Amount until 2/6/24. ConsiderationValue is consistent with other balance comparisons.
            }, 0)
        }
        if (this.operationDirection == 'Result'){ // 
            return 0-otherConsideration;
        }
        else return otherConsideration;
    }
    get totalConsideration(): number{
        return this.otherConsiderationTotal + this.swapConsiderationValue;
    }

    get swapConsiderationValue(): number{
        let result: number;
        if (this.operation == 'Swap' && this.swapCapitalTransactions && this.swapCapitalTransactions.length){
            result =  this.swapCapitalTransactions.reduce(function(a, b) {
                return (a || 0) + b.calledCapitalTransferred;
            }, 0)
        }
        else result = 0;
        return 0-result; //flip sign because the Swap is always in the "Source" direction

    }
    get baseConsumptionValue(): number{
        let result: number;
        if (this.baseCapitalTransactions && this.baseCapitalTransactions.length){
            result =  this.baseCapitalTransactions.reduce(function(a, b) {
                return (a || 0) + b.calledCapitalTransferred;
            }, 0)
        }
        else result = 0;
        if (this.operationDirection == 'Source'){ 
            return 0-result;
        }
        else return result;
        
    }
    get baseConsumptionCost(): number{
        let result: number;
        if (this.baseCapitalTransactions && this.baseCapitalTransactions.length){
            result =  this.baseCapitalTransactions.reduce(function(a, b) {
                return (a || 0) + b.price;
            }, 0)
        }
        else result = 0;
        return result; // always positive        
    }
    get considerationTransferredAmount(): number {
        // note: this would seem like it just double flips from above.  But everything (see note above) is flipped there, and here only flipped back for Selling (return) shares.
        return this.operation == 'Sell' && this.isTransferOrSwap ? 0 - this.baseConsumptionCost : this.baseConsumptionCost; // could be Buy or Transfer to not  flip the sign.
    }
    get baseConsumptionFMV(): number{ //always positive
        let result: number;
        if (this.baseCapitalTransactions && this.baseCapitalTransactions.length){
            result =  this.baseCapitalTransactions.reduce(function(a, b) {
                return (a || 0) + (b.fMV) ;
            }, 0)
        }
        else result = 0;
        // if (this.operationDirection == 'Source'){
        //     return 0-result;
        // }
        // else return result;
        return result;
    }
    get valuePremiumDiscountTransferSwap(): iValuePremiumDiscount{
        const consideration = this.operation == 'Buy' ? 0 - this.totalConsideration : this.totalConsideration;
        const amount = this.operation == 'Sell' ? 0 - (this.baseConsumptionFMV - consideration) : this.baseConsumptionFMV - consideration
        return{
            baseConsumption: this.baseConsumptionFMV
            , consideration: consideration
            , amount: amount
        }
    }
    get valuePremiumDiscount(): iValuePremiumDiscount{ // i.e. not Transfer/Swap
        // 10/12/21: this has only been tested for non-Swap/Trans Sell.  There shouldn't explicitly be "Buy" types outside Trans/Swap, but maybe something else could fall into that.
        const consideration = this.totalConsideration;
        const amount = consideration -  this.baseConsumptionCost;
        return{
            baseConsumption: this.baseConsumptionCost
            , consideration: consideration
            , amount: amount
        }
    }
    get ownerIdList(): Array<number>{
        const ret = [];
        if (this.header_ && this.header_.BaseOwnerId) ret.push(this.header_.BaseOwnerId);
        if (this.header_ && this.header_.TargetOwnerId) ret.push(this.header_.TargetOwnerId);
        return ret;
    }

    
    public get outOfBalance(): boolean{
        if (this.noConsideration) return false; // InterOwner has no consideration, so cannot be out of balance
        if (!this.totalConsideration || !this.considerationTransferredAmount || isNaN(this.considerationTransferredAmount) || isNaN(this.totalConsideration)) return true;

        return (!!this.totalConsideration && !!this.considerationTransferredAmount && Math.abs(this.totalConsideration - this.considerationTransferredAmount) > 1)
    }
    //#endregion Computed


    //#region Methods
    oppositeDirection (direction: string): string{
        if (direction == 'Result') return 'Source';
        if (direction == 'Source') return 'Result';
    }
     
    async getTransactionHeader(){
        this.header_ = {} as TransactionsService.Header;
        if (this.transactionHeaderId || (this.header && this.header.Id)){
            const params = {} as TransactionsService.GetHeaderParameters;
            params.HeaderId = this.transactionHeaderId || this.header.Id; // might be refetching it to get the ful dataset if it was passed in from new
            this.header_ = (await this._transactionsService.GetHeader(params))[0];
        }
    }
    async getTransactionInvestments(){
        this.stakeTransactionInvestments = [] as stakeTransactionTypes.iStakeTransactionInvestment[];
        let stakeTransactionInvestments = [] as TransactionsService.TransactionInvestments[];
        this.loading = true;
        const params = {} as TransactionsService.GetTransactionInvestmentsParameters;
        params.TransactionHeaderId = this.header_.Id;
        stakeTransactionInvestments = await this._transactionsService.GetTransactionInvestments(params);
        this.stakeTransactionInvestments = stakeTransactionInvestments.map(eti => ({...eti, isNew: true, capitalTransaction: {} as stakeTransactionTypes.iCapitalTransaction }));
        if (!this.baseInvestmentRecords || !this.baseInvestmentRecords.length){
            this.addNewStakeTransactionInvestment(false, this.header_.BaseInvestmentId);
        }
        if (this.operation == 'Swap' && (!this.swapInvestmentRecords || !this.swapInvestmentRecords.length)){
            this.addNewStakeTransactionInvestment(true);
        }
        this.loading = false;
    }

    considerationListUpdated(considerations: Array<ConsiderationsType >){
        this.considerations = considerations;
    }
    addNewStakeTransactionInvestment(isSwap: boolean, defaultInvestmentId: number |  null = null){
        const newInvestments: stakeTransactionTypes.iStakeTransactionInvestment = {
            isNew: true,
            IsSwap: isSwap,
            SourceInvestmentId: defaultInvestmentId,
            TargetInvestmentId: null,
            capitalTransaction: {} as stakeTransactionTypes.iCapitalTransaction
        }
        this.stakeTransactionInvestments.push(newInvestments);
    }
    async stakeDeleted(record: stakeTransactionTypes.iStakeTransactionInvestment){
        this.getTransactionInvestments(); // reset (rather than below)
        // (below was trying to delete the one record, then add back a blank if none, but lots of problems.)
        // const isSwap = record.IsSwap;
        // const index = this.stakeTransactionInvestments.indexOf(record);
        // console.log('StakeTransaction.Delete');
        // this.stakeTransactionInvestments.splice(index, 1);
        // await this.$nextTick();
        // if ((isSwap && !this.swapInvestmentRecords.length) || (!isSwap && !this.baseInvestmentRecords.length)){
        //     this.addNewStakeTransactionInvestment(isSwap, this.header_.BaseInvestmentId); //if just deleted the last record, add a new empty record
        // }
    }
//#endregion Methods

    }
    
