
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as LiqService from '../../services/DAL/liq_Service';
import ManualBankTransactionEdit from '@/views/BankTransaction/ManualBankTransactionEdit.vue';
import ChangeLogList from '@/js/ChangeLog/ChangeLogList.vue';

@Component({
    components: {
        ManualBankTransactionEdit,
        ChangeLogList
    }
})
export default class ManualBankTransactionTabView extends Vue {
    //#region Private Declarations
    private _liqService: LiqService.LiqService;
    //#endregion Private Declarations

    //#region Props
    @Prop() manualAccountTransaction: LiqService.ManualAccountTransaction;
    @Prop() manualAccountTransactionId: number
    @Prop() deletable: boolean;
    @Prop() viewType: string;
    //#endregion Props

    //#region Data
    manualAccountTransaction_: LiqService.ManualAccountTransaction = new LiqService.ManualAccountTransaction();
    showChangeLogList: boolean = true;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();

        if (this.manualAccountTransaction && Object.keys(this.manualAccountTransaction).length) {
            this.manualAccountTransaction_ = this.manualAccountTransaction;
        }
        if (!this.manualAccountTransaction_.Id && this.manualAccountTransactionId) {
            await this.fetchManualAccountTransaction();
        }
    }
    //#endregion Lifecycle

    //#region Computed
    get isNew(): boolean {
        return (this.manualAccountTransactionId || 0) === 0 && (
            !this.manualAccountTransaction_ ||
            !Object.keys(this.manualAccountTransaction_).length ||
            (this.manualAccountTransaction_.Id || 0) === 0 // can't check <= 0 b/c Ids are negative
        );
    }
    //#endregion Computed

    //#region Methods
    async fetchManualAccountTransaction() {
        const params = {} as LiqService.GetManualAccountTransactionParameters;
        params.ManualAccountTransactionId = this.manualAccountTransactionId;
        const dsManualAccountTransaction = await this._liqService.GetManualAccountTransaction(params);

        if (dsManualAccountTransaction.length) {
            this.manualAccountTransaction_ = dsManualAccountTransaction[0];
        }
        else {
            this.manualAccountTransaction_ = new LiqService.ManualAccountTransaction();
        }
    }

    async refreshChangeLogList() {
        this.showChangeLogList = false;
        await this.$nextTick();
        this.showChangeLogList = true;
    }

    onSave($event) {
        this.refreshChangeLogList();
        this.$emit('save', $event);
    }

    onDelete($event) {
        this.$emit('delete', $event);

        if (this.viewType === 'dialog') {
            this.$emit('close');
        }
    }
    //#endregion
}
