
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class FileRenameView extends Vue {
    //#region Private declarations for Services
    //#endregion Private declarations for Services

    //#region Props
    @Prop() filename: string;
    @Prop() viewType: string;
    //#endregion Props

    //#region Data
    filenameNoExtension: string = null;
    fileExtension: string = null;
    //#endregion Data

    //#region Lifecycle
    created() {
        this.initializeFileProperties();
    }
    mounted() {
        this.focus();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('filename')
    onChange_filename(val: object, oldVal: object) {
        this.initializeFileProperties();
    }
    //#endregion Watches

    //#region Methods
    initializeFileProperties() {
        const filename = this.filename || '';
        const idxLastPeriod = filename.lastIndexOf('.');
        this.filenameNoExtension = filename.substring(0, idxLastPeriod);
        this.fileExtension = filename.substring(idxLastPeriod);
    }
    save(){
        this.$emit('save', this.filenameNoExtension + this.fileExtension);
    }
    focus(){
        (this.$refs.fileNameInput as HTMLFormElement).focus();
    }
    //#endregion Methods
}
