
import Vue from 'vue';
import { Component, Watch, Prop, Ref } from 'vue-property-decorator';
import * as VamService from '../../services/DAL/vamService';
import AttachmentList from '@/js/Attachments/AttachmentList.vue';
import ChangeLogList from '@/components/other/tsChangeLogList.vue'
import ContactListView from '@/views/Contact/ContactListView.vue';
import VendorEdit from '@/js/Vendor/VendorEdit.vue';
import CommentLogList from '@/components/other/tsCommentLogList.vue';
import AgreementList from '@/views/Agreements/AgreementList.vue';
import ApproverSelectionList from '@/views/Workflow/ApproverSelectionList.vue';
import { ElTabs } from 'element-ui/types/tabs';

declare var $: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(value: any, defaultValue: any): any;

@Component({
    components: {
        AttachmentList,
        ChangeLogList,
        VendorEdit,
        CommentLogList,
        AgreementList,
        ApproverSelectionList,
        ContactListView
    }
})
export default class VendorEditTabs extends Vue {
    @Ref() readonly vendorEditTabs!: ElTabs;
    //#region Private Declarations
    private _vamService: VamService.VamService;
    //#endregion Private Declarations

    //#region Props
    @Prop({ type: Object }) readonly vendor?: VamService.Vendors;
    @Prop() readonly vendorId?: number;
    //#endregion Props

    //#region Data
    vendor_ = {} as VamService.Vendors;
    vendorId_: number = null;

    showAttachmentList: boolean = false;
    showImage: boolean = false;
    showChangeLogList: boolean = false;
    securityLevel_: number = getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP);
    activeTab: string = '0';
    vendorClients = [] as VamService.VendorClients[];
    transactionsClientId: number = null;
    fromDate_: any = this.$dayjs().subtract(1, "year").format('MM/DD/YYYY');
    toDate_: any = this.$dayjs().format('MM/DD/YYYY');
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();

        if (this.vendor && Object.keys(this.vendor).length && this.vendor.Id > 0) {
            this.vendor_ = this.vendor;
            this.vendorId_ = this.vendor.Id;
        }
        else if (this.vendorId) {
            this.vendorId_ = this.vendorId;
            await this.fetchVendor();
        }
        await this.fetchVendorClients();
    }
    //#endregion Lifecycle
    get transactionsDateRange() {
        return [this.fromDate_, this.toDate_];
    }
    set transactionsDateRange(value) {
        this.fromDate_ = this.$moment(value[0]).format('MM/DD/YYYY');
        this.toDate_ = this.$moment(value[1]).format('MM/DD/YYYY');
        this.loadTransactionsTab();
    }

    get datePickerOptions() {
        const el = this;
        return    {     
            shortcuts: [
                    {
                        text: "This Year",
                        onClick(picker) {
                            const start = el.$dayjs().startOf("year");
                            const end = el.$dayjs().endOf("year");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "YTD",
                        onClick(picker) {
                            const start = el.$dayjs().startOf("year");
                            const end = el.$dayjs();
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "This Month",
                        onClick(picker) {
                            const start = el.$dayjs().startOf("month");
                            const end = el.$dayjs().endOf("month");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "MTD",
                        onClick(picker) {
                            const start = el.$dayjs().startOf("month");
                            const end = el.$dayjs();
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "LTM",
                        onClick(picker) {
                            const start = el.$dayjs().subtract(1, "year").add(1, "day");
                            const end = el.$dayjs();
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "Last Year",
                        onClick(picker) {
                            const start = el.$dayjs().subtract(1, "year").startOf("year");
                            const end = el.$dayjs().subtract(1, "year").endOf("year");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "Last Month",
                        onClick(picker) {
                            const start = el.$dayjs().subtract(1, "month").startOf("month");
                            const end = el.$dayjs().subtract(1, "month").endOf("month");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "Last Week",
                        onClick(picker) {
                            const start = el.$dayjs().subtract(1, "week").startOf("week");
                            const end = el.$dayjs().subtract(1, "week").endOf("week");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "This Week",
                        onClick(picker) {
                            const start = el.$dayjs().startOf("week");
                            const end = el.$dayjs().endOf("week");
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    },
                    {
                        text: "ALL",
                        onClick(picker) {
                            const start = el.$dayjs('1/1/1900');
                            const end = el.$dayjs('12/31/2199');
                            picker.$emit("pick", [start.toDate(), end.toDate()]);
                        }
                    }
                ]
            }
        }


    @Watch('transactionsClientId')
    onTransactionsClientIdChange(val: number, oldVal: number) {
        this.loadTransactionsTab();
    }
    //#region Methods
    onTabClick(tab) {
        this.$route.meta.selectedTab = tab.name;
        switch (tab.label) {
            case 'Contacts':
                break;
            case 'Payment Notes':
                var $ucPaymentNotes = $(this.vendorEditTabs.$el).find('#ucPaymentNotes');
                if (!$ucPaymentNotes.hasClass('loaded')) {
                    $ucPaymentNotes.fetchSmartControl({
                        params: {
                            VendorId: this.vendorId_
                        }
                        , collapsible: false
                        , showSpinner: true
                    });
                    $ucPaymentNotes.addClass('loaded');
                }
                break;
            case 'Transactions':
                this.loadTransactionsTab();    
                break;

            case 'Documents':
                this.showAttachmentList = true;
                break;
            case 'Change Log':
                this.showChangeLogList = true;
                break;
            case 'Image':
                this.showImage = true;
                break;
        }
    }

    onSave(vendor) {
        this.refreshChangeLogList();
        this.$emit('save', vendor);
    }

    deleted(vendorId: number){
        this.$emit('deleted', vendorId);
    }
    async loadTransactionsTab(){
        var $ucTransactions = $(this.vendorEditTabs.$el).find('#ucTransactions');
        if (this.transactionsClientId) {
            $ucTransactions.fetchSmartControl({
                params: {
                    Report: 'VendorSpend'
                    , controlHeight: '560px'
                    , HideMenu: true
                    , Zoom: .9
                    //, controlHeight: $tabContent.closest('.tab-content').height()
                    , Parameters: {
                        VendorId: this.vendorId_
                        , DateRangeStart: this.fromDate_
                        , DateRangeEnd: this.toDate_
                        , ConfigurationId: 46 //Standard
                        , ClientId: this.transactionsClientId
                    }
                }
                , showSpinner: true
            });
        }

    }
    async fetchVendor() {
        this.vendorClients = [];
        const params = {} as VamService.GetVendorsParameters;
        params.VendorId = this.vendorId_;
        const result = await this._vamService.GetVendors(params);
        if (result.length == 1) {
            this.vendor_ = result[0];
        }
    }
    async fetchVendorClients() {
        const params = {} as VamService.GetVendorClientsParameters;
        params.VendorId= this.vendorId_;
        const vc = await this._vamService.GetVendorClients(params);
        if (vc && vc.length){
            this.vendorClients = vc.filter(item => item.VendorClientId); //only include clients that have been selected for the vendor
        }
        if (this.vendorClients && this.vendorClients.length == 1) this.transactionsClientId = this.vendorClients[0].ClientId;
    }

    async refreshChangeLogList() {
        this.showChangeLogList = false;
        await this.$nextTick();
        this.showChangeLogList = true;
    }
    //#endregion
}
