
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import * as BalanceSheetService from '../../services/DAL/balanceSheetService';
import * as LiqService from '../../services/DAL/liq_Service';
import * as ReportsService from '../../services/DAL/reportsService';
import * as VamService from '../../services/DAL/vamService';
import assign from 'lodash/assign';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare var tryParseBool: any;

@Component
export default class BalanceSheetConfigEntitySettingCreate extends Vue {
    $refs: {
        frmBalanceSheetConfigEntitySettingCreate: ElementUI.Form
    }
    //#region Data
    private _assetService: AssetService.AssetService;
    private _balanceSheetService: BalanceSheetService.BalanceSheetService;
    private _liqService: LiqService.LiqService;
    private _reportsService: ReportsService.ReportsService;
    private _vamService: VamService.VamService;

    @Prop()
    clientId: number;
    @Prop()
    configurationId: number;
    @Prop()
    entitySettingConfiguration: ReportsService.EntitySettingConfigurations;

    entitySetting: ReportsService.EntitySettings = {
        Id: null,
        EntityType: null,
        EntityId: null,
        Label: null,
        SettingBit: null,
        SettingInt: null,
        SettingDecimal: null,
        ClientId: null,
        ConsolidateBalanceSheet: null,
        ConsolidateDetail: null
    };
    selectedConfigurationSettingEntityType: ReportsService.ConfigurationSettingEntityTypes = {
        Id: null,
        ConfigurationTypeId: null,
        ConfigurationSettingId: null,
        EntityType: null,
        Label: null
    };
    configurationSettingEntityTypes: ReportsService.ConfigurationSettingEntityTypes[] = [];
    bankAccounts: LiqService.Accounts[] = [];
    balanceSheetSubCategories: BalanceSheetService.Categories[] = [];
    generalAssets: AssetService.GeneralAssetList[] = [];
    investments: AssetService.InvestmentList[] = [];
    owners: VamService.OwnersV2[] = [];
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this._balanceSheetService = new BalanceSheetService.BalanceSheetService();
        this._liqService = new LiqService.LiqService();
        this._reportsService = new ReportsService.ReportsService();
        this._vamService = new VamService.VamService();

        this.fetchConfigurationSettingEntityTypes();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('selectedConfigurationSettingEntityType.EntityType')
    onChange_EntityType() {
        this.entitySetting.EntityType = this.selectedConfigurationSettingEntityType.EntityType;
        this.entitySetting.EntityId = null;

        switch (this.selectedConfigurationSettingEntityType.EntityType) {
            case 'Account':
                if (!this.bankAccounts.length) {
                    this.fetchBankAccounts();
                }
                break;
            case 'BalanceSheetCategory':
                if (!this.balanceSheetSubCategories.length) {
                    this.fetchBalanceSheetSubCategories();
                }
                break;
            case 'GeneralAsset':
                if (!this.generalAssets.length) {
                    this.fetchGeneralAssets();
                }
                break;
            case 'Investment':
                if (!this.investments.length) {
                    this.fetchInvestments();
                }
                break;
            case 'Owner':
                if (!this.owners.length) {
                    this.fetchOwners();
                }
                break;
        }
    }
    //#endregion Watches

    //#region Methods
    async fetchConfigurationSettingEntityTypes() {
        this.configurationSettingEntityTypes = [];
        const getConfigurationSettingEntityTypesParameters = {} as ReportsService.GetConfigurationSettingEntityTypesParameters;
        getConfigurationSettingEntityTypesParameters.ConfigurationSettingId = this.entitySettingConfiguration.ConfigurationSettingId;
        this.configurationSettingEntityTypes = await this._reportsService.GetConfigurationSettingEntityTypes(
            getConfigurationSettingEntityTypesParameters
        );
        if (this.configurationSettingEntityTypes.length === 1) {
            this.selectedConfigurationSettingEntityType = this.configurationSettingEntityTypes[0];
        }
    }

    async fetchBankAccounts() {
        this.bankAccounts = [];
        const getAccountsParameters = {} as LiqService.GetAccountsParameters;
        getAccountsParameters.ClientId = this.clientId;
        this.bankAccounts = await this._liqService.GetAccounts(
            getAccountsParameters
        );
        if (this.bankAccounts.length === 1) {
            this.entitySetting.EntityId = this.bankAccounts[0].Id;
        }
    }

    async fetchBalanceSheetSubCategories() {
        this.balanceSheetSubCategories = [];
        const getSubCategoriesParameters = {} as BalanceSheetService.GetSubCategoriesParameters;
        getSubCategoriesParameters.ClientId = this.clientId;
        this.balanceSheetSubCategories = await this._balanceSheetService.GetSubCategories(
            getSubCategoriesParameters
        );
        if (this.balanceSheetSubCategories.length === 1) {
            this.entitySetting.EntityId = this.balanceSheetSubCategories[0].Id;
        }
    }

    async fetchGeneralAssets() {
        this.generalAssets = [];
        const getGeneralAssetListParameters = {} as AssetService.GetGeneralAssetListParameters;
        getGeneralAssetListParameters.ClientId = this.clientId;
        this.generalAssets = await this._assetService.GetGeneralAssetList(
            getGeneralAssetListParameters
        );
        if (this.generalAssets.length === 1) {
            this.entitySetting.EntityId = this.generalAssets[0].Id;
        }
    }

    async fetchInvestments() {
        this.investments = [];
        const getInvestmentListParameters = {} as AssetService.GetInvestmentListParameters;
        getInvestmentListParameters.ClientId = this.clientId;
        this.investments = await this._assetService.GetInvestmentList(
            getInvestmentListParameters
        );
        if (this.investments.length === 1) {
            this.entitySetting.EntityId = this.investments[0].Id;
        }
    }

    async fetchOwners() {
        this.owners = [];
        const getOwnersParameters = {} as VamService.GetOwnersV2Parameters;
        getOwnersParameters.ClientId = this.clientId;
        this.owners = await this._vamService.GetOwnersV2(getOwnersParameters);
        if (this.owners.length === 1) {
            this.entitySetting.EntityId = this.owners[0].Id;
        }
    }

    async saveForm() {
        this.$refs.frmBalanceSheetConfigEntitySettingCreate.validate(
            async valid => {
                if (valid) {
                    try {
                        const attributes = assign({}, this.entitySetting, {
                            ConfigurationId: this.configurationId,
                            ConfigurationSettingId: this
                                .entitySettingConfiguration
                                .ConfigurationSettingId,
                            SettingBit: null
                        });
                        if (
                            this.entitySettingConfiguration.DataType === 'Bit'
                        ) {
                            attributes.SettingBit = true;
                        }
                        await new SmartObject(
                            'ConfigurationSettings'
                        ).createObject(attributes);

                        this.$notify.success(
                            this.selectedConfigurationSettingEntityType
                                .Label +
                            ' added to ' +
                            this.entitySettingConfiguration.Label +
                            ' section.'
                        );

                        this.$emit('save');
                        this.$emit('close-dialog');
                    } catch (err) {
                        try {
                            const errJson = JSON.parse(err.responseText);
                            if (
                                errJson.ExceptionMessage.toLowerCase().indexOf(
                                    'cannot insert duplicate key'
                                ) > -1
                            ) {
                                this.$notify.error(
                                    'This ' +
                                    this.selectedConfigurationSettingEntityType.Label.toString().toLowerCase() +
                                    ' already exists in the ' +
                                    this.entitySettingConfiguration.Label +
                                    ' section. Please choose a different entity.'
                                );
                            } else {
                                throw new Error();
                            }
                        } catch {
                            this.$notify.error('Something went wrong processing your request, please try again.');
                        }
                    }
                }
            }
        );
    }
    //#endregion Methods
}
