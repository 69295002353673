
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import ClientSelector from '@/components/form/ClientSelector.vue';
import OwnerSelector from '@/components/form/OwnerSelector.vue';
import FormattedInput from '@/components/form/FormattedInput.vue';
import BankAccountSelector from '@/components/form/BankAccountSelector.vue'
import ElementUI from 'element-ui';
import { sec } from 'mathjs';

declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: number, deflt: number): number;
declare var SmartObject: any;

@Component({
    components: {
        ClientSelector,
        OwnerSelector,
        BankAccountSelector,
        FormattedInput
    }
})
export default class FixedIncomeEdit extends Vue {
    $refs: {
        frmFixedIncomeEdit: ElementUI.Form
    }
    //#region Data
    private _assetService: AssetService.AssetService;

    @Prop() fixedIncome: AssetService.FixedIncome;
    @Prop() fixedIncomeId!: number;
    @Prop() viewType: string;
    @Prop() closeHandler: Function;

    fixedIncome_ = {} as AssetService.FixedIncome;
    fixedIncomeId_: number = this.fixedIncomeId;
    calculatedInterest: number = null;
    calculatedAccruedInterest: number = null;
    calculatedPrice: number = null;
    calculatedPricePer100: number = null;
    calculatedBEY: number = null;
    securityLevel_: number = 0;

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();

        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );

        if (this.fixedIncome && Object.keys(this.fixedIncome).length && this.fixedIncome.Id){
            this.fixedIncomeId_ = this.fixedIncome.Id;
        }

        if (this.fixedIncome && Object.keys(this.fixedIncome).length && this.fixedIncome.Id && this.fixedIncome.ImpliedDiscount) { 
        // check for ImpliedDiscount to see if the object has the additional props from the DB.  if not, go to next step to retreive from db
            this.fixedIncome_ = this.fixedIncome;
        }
        else if (this.fixedIncomeId_) {
            await this.fetchFixedIncome();
        }
        else {
            this.fixedIncome_ = new AssetService.FixedIncome();
            this.$nullifyObjectProps(this.fixedIncome_);
        }

        this.calculatedAccruedInterest = this.fixedIncome_.PurchasedAccruedInterest;
        this.calculatedInterest = this.fixedIncome_.Interest;
        this.calculatedPrice = this.fixedIncome_.Price;
        this.calculatedPricePer100 = this.fixedIncome_.PricePer100;
        this.calculatedBEY = this.fixedIncome_.BondEquivalentYield;
    }

    mounted() {
        if (this.$refs && this.$refs.frmFixedIncomeEdit){
            this.$refs.frmFixedIncomeEdit.resetFields();
        }
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('fixedIncome_.SettlementDate')
    onChange_SettlementDate(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.fetchTreasuryBillPricing();
        }
    }

    @Watch('fixedIncome_.MaturityDate')
    onChange_MaturityDate(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.fetchTreasuryBillPricing();
        }
    }
    @Watch('fixedIncome_.PurchaseSettlementDate')
    onChange_PurchaseSettlementDate(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.fetchTreasuryBillPricing();
        }
    }

    @Watch('fixedIncome_.Yield')
    onChange_Yield(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.fetchTreasuryBillPricing();
        }
    }

    @Watch('fixedIncome_.FaceValue')
    onChange_FaceValue(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.fetchTreasuryBillPricing();
        }
    }
    //#endregion Watches

    //#region Computed
    get isNew(): boolean {
        return (
            !this.fixedIncome_ ||
            !Object.keys(this.fixedIncome_).length ||
            (this.fixedIncome_.Id || 0) <= 0
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    get fiType(): string {
        if (this.fixedIncome_ && this.fixedIncome_.SettlementDate && this.fixedIncome_.MaturityDate) {
            // greater than 10 years then it is a bond; greater than 1 year then it is a note; otherwise it is a bill
            if (this.$dayjs(this.fixedIncome_.MaturityDate).diff(this.fixedIncome_.SettlementDate, 'year', true) > 10) {
                return 'Bond';
            }
            else if (this.$dayjs(this.fixedIncome_.MaturityDate).diff(this.fixedIncome_.SettlementDate, 'year', true) > 1) {
                return 'Note';
            }
            else {
                return 'Bill';
            }
        }
        return '';
    }
    //#endregion Computed

    //#region Methods
    validateAlphanumeric(rule, value, callback) {
        const regex = new RegExp('^[a-z0-9]+$', 'i');
        if (!value || regex.test(value)) {
            callback();
        }
        else {
            callback(new Error('Please enter only letters and numbers.'));
        }
    }

    async fetchFixedIncome() {
        const params = {} as AssetService.GetFixedIncomeParameters;
        params.FixedIncomeId = this.fixedIncomeId_;
        const fixedIncomes = await this._assetService.GetFixedIncome(params);
        if (fixedIncomes.length) {
            this.fixedIncome_ = fixedIncomes[0];
        }
    }
    
    async fetchTreasuryBillPricing() {
        const params = {} as AssetService.GetTreasuryBillPricingParameters;
        params.SettleDate = this.fixedIncome_.SettlementDate || null;
        params.MaturityDate = this.fixedIncome_.MaturityDate || null;
        params.Yield = this.fixedIncome_.Discount || null;
        params.FaceValue = this.fixedIncome_.FaceValue || null;
        
        if(this.fixedIncome_ && this.fixedIncome_.Method == 'Coupon'){
            params.SettleDate = this.fixedIncome_.PurchaseSettlementDate || null;
        }
        
        params.Price = null;
        const treasuryBillPricing = await this._assetService.GetTreasuryBillPricing(params);

        if (treasuryBillPricing && treasuryBillPricing.length) {
            this.calculatedInterest = treasuryBillPricing[0].Interest;
            this.calculatedPrice = treasuryBillPricing[0].Price;
            this.calculatedPricePer100 = treasuryBillPricing[0].PricePer100;
            this.calculatedBEY = treasuryBillPricing[0].BondEquivalentYield;
        }
        else {
            this.calculatedInterest = null;
            this.calculatedPrice = null;
            this.calculatedPricePer100 = null;
            this.calculatedBEY = null;
        }
console.log('fetchTreasuryBillPricing', treasuryBillPricing);
        if (this.fixedIncome_ && this.fixedIncome_.Method == 'Coupon' && this.fixedIncome_.PurchaseSettlementDate){
            params.Method = 'Coupon';
            params.SettleDate = this.fixedIncome_.SettlementDate || null;
            params.MaturityDate = this.fixedIncome_.PurchaseSettlementDate;
            const interestPricing = await this._assetService.GetTreasuryBillPricing(params);
            if (interestPricing && interestPricing.length) {
                this.calculatedAccruedInterest = interestPricing[0].Interest;
            }
            else {
                this.calculatedAccruedInterest = null;
            }
        }
    }
    
    async saveForm() {
        this.$refs.frmFixedIncomeEdit.validate(async valid => {
            if (valid) {
                let message: string;
                const isNew = this.isNew;

                try {
                    delete this.fixedIncome_.PricePer100; //computed - can't be set/updated
                    if (!isNew){
                        await new SmartObject('FixedIncome', this.fixedIncome_.Id).updateObject(
                            this.fixedIncome_
                        );
                    }
                    else {
                        const newId = await new SmartObject('FixedIncome').createObject(
                            this.fixedIncome_
                        );
                        this.fixedIncome_.Id = newId;
                    }

                    this.$notify.success(`Fixed Income ${this.isNew ? 'added' : 'saved'}.`);

                    this.$emit('save', this.fixedIncome_);
                    this.$emit('update:fixedIncome', this.fixedIncome_);

                    if (isNew) {
                        this.$emit('close');
                    }
                }
                catch (err) {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                    return;
                }
            }
        });
    }

    async deleteFixedIncome() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this fixed income item. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    await new SmartObject(
                        'FixedIncome',
                        this.fixedIncome_.Id
                    ).deleteObject();
                    this.$notify.success('Fixed Income deleted.');
                    this.$emit('delete', this.fixedIncome_.Id);
                    this.$emit('close');
                    
                } catch (e) {
                    this.$notify.error(e.message);
                }
            } catch {}
        }
    }

    closePopup() {
        if (typeof this.closeHandler === 'function') {
            this.closeHandler();
        }
        this.$emit('close');
    }
    //#endregion Methods
}
