<template>
    <div id="divUploadAttachment">
        <div ref="divDropbox" class="dropbox" :style="{ 'background-color': isDragging ? '#F0F0F0' : 'white' }" 
            @dragenter.prevent.stop="isDragging = true"
            @dragover.prevent.stop="isDragging = true"
            @dragleave.prevent.stop="isDragging = false"
            @dragend.prevent.stop="isDragging = false"
            @drop.prevent.stop="isDragging = false; filesChange($event.dataTransfer.files);"
            @click="$refs.txtSelectFile.click()">
            <input ref="txtSelectFile" type="file" class="hidden" @change="filesChange($event.target.files)">
            <span v-if="isInitial">
                {{placeholder}}
            </span>
            <el-progress v-if="isSaving" :text-inside="true" :stroke-width="20" :percentage="uploadPercentage" style="width: 200px"></el-progress>
            <!-- <span v-if="isSaving">
                Uploading file...
            </span> -->
        </div>
        <el-dialog title="File Name (Change If Needed)" :visible.sync="showFileRename" width="420px">
            <file-rename-view
                v-if="showFileRename"
                :filename="selectedFile ? selectedFile.name : null"
                @save="saveFileRename"
                @cancel="cancelFileRename"
                view-type="dialog"
            ></file-rename-view>
        </el-dialog>
        <el-dialog :visible.sync="showAttachmentEdit" width="30%" title="Save Document">
            <attachment-edit 
                ref="refAttachmentEdit" 
                v-if="showAttachmentEdit" 
                :store="store" 
                :key="fileProperties.eTag" 
                :file-properties="fileProperties" 
                :parameters="parameters" 
                :security-level="securityLevel_"
                @attachment-saved="newAttachmentSaved" 
                @attachment-deleted="fileDeleted" 
                @attachment-canceled="attachmentCanceled" 
                >
            </attachment-edit>
        </el-dialog>
        <el-dialog title="Name Conflict" :visible.sync="showNameConflict" width="30%" >
            <span>A File with the same name already exists:</span><br>
            <span>{{decodeURI(this.fileProperties.webUrl).replace('https://visiumpartners.sharepoint.com', 'sharepoint')}}</span><br>
            <span v-if="!!attachmentToMap && attachmentToMap.AttachmentId" :title="attachmentToMap.AttachmentId">Ingested: {{attachmentToMap.IngestDate | shortDateTime}} {{attachmentToMap.IngestUser | shortUser}}</span><br>
            <span v-if="!store.IsIngestionSource">You can Cancel, then rename the new file, or Continue and map the existing file to this entity.</span>
            <span v-else>The existing file is in an Ingestion Folder, so it cannot be used.  Cancel here, then either rename your new file, or ingest the existing file.</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelNameConflict">Cancel</el-button>
                <el-button type="warning" @click="handleUseExisting" v-if="!store.IsIngestionSource">Use Existing</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
/*
4 Possible paths after uploading file:
1. (most common) File is new (no conflict on upload),
    a. User is prompted for attachment-edit which saves the Attachment details in the database and produces an AttachmentId
    b. attachment-uploaded is emitted and the parent will either prompt for mapping, or automap, using the AttachmentId
2. File Already exists.  User is prompted if he wants to cancel, or use the exising file.  3 paths from that:
    a. Cancel.  Pop up closes, no file is saved, no attachment is created in the database.
    b. Use Existing: two paths:
        i. If the file has already been ingested (i.e. it has an attachmentId), then emit attachment-uploaded and close modals
        ii. If it has not been ingested, then user is prompted like in 1a above, but using the file properties of the existing file.

Other notes:
1. Mapping does not happen in this component.  The parent must handle attachment-uploaded and do that.
2. The way a document can exist but not be ingested is if it exists in one of our folders, like ingest, or Share Point.
    a. Do we need to do something about moving a file if it's found in an ingest folder? TODO (maybe)
*/
    import Vue from 'vue';
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';
    import AttachmentEdit from '@/views/Attachments/AttachmentEditV3.vue'
    import mixinSchema_attachment from './../DAL/mixinSchema_attachment'
    import MapAttachment from './MapAttachment.vue'
    import FileRenameView from '@/components/other/FileRenameView.vue';
    import accounting from 'accounting';

    export default Vue.extend({
        name: 'uploadAttachment'
        , mixins: [mixinSchema_attachment]
        , components: {
            'file-rename-view': FileRenameView
            , 'attachment-edit': AttachmentEdit
            , 'map-attachment': MapAttachment
        }
        , props: {
            storeId: {
                type: Number
                , required: true
            }
            , parameters: {
                type: Object
                , default: function () {
                    return {}
                }
            }
            , securityLevel: {
                type: Number
                , default: null
            }
            , skipAttachmentProcess: {
                type: Boolean
                , default: false
            }
            , renameToFilename: {
                type: String
            }
            , placeholder: {
                type: String
                , default: 'Drop file here to upload, or click to browse'
            }
            , onlyEmitFilePropertiesOnNameConflict: {
                type: Boolean
                , default: false
            }

        }
        , data() {
            return {
                isDragging: false
                , isInitial: true
                , isSaving: false
                , fileCount: 0
                , selectedFile: null
                , fileProperties: {}
                , showFileRename: false
                , showAttachmentEdit: false
                , store: this.attachment_GetStores_New()
                , showAttachmentMap: false
                , attachmentToMap: {}
                , securityLevel_: this.securityLevel
                , showNameConflict: false
                , fileSize: 0
                , uploadedSize: 0
                , graphService: {}
            }
        }
        , created: async function () {
            this.graphService = new MicrosoftGraphService();
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDocuments), 0);
            }
            this.store = await this.fetchStore();
        }
        , computed: {
            uploadPercentage(){
                if (!this.fileSize) return 0;
                return accounting.unformat(accounting.toFixed((this.uploadedSize / this.fileSize) * 100, 0)); // toFixed returns a string, so unformat returns a number
            }
        }
        , methods: {
            filesChange(files) {
                this.fileCount = files.length;
                if (!files.length) {
                    return;
                }
                this.isInitial = false;
                this.isSaving = true;
                this.attachmentToMap = {};
                this.selectedFile = files[0];
                if (this.renameToFilename) {
                    this.uploadFile(this.renameToFilename);
                } else{
                    this.showFileRename = true;
                }                
            }
            , cancelFileRename() {
                this.showFileRename = false;
                this.$notify.warning('File upload canceled');
                console.log('File upload canceled from rename prompt');
                this.resetFileInput();
            }
            , saveFileRename(filename) {
                if (filename) {
                    this.showFileRename = false;
                    this.uploadFile(filename);
                }
            }
            , async uploadFile(filename) {
                const encodedFilename = encodeFileName(filename);
                const graphClient = await this.graphService.getGraphClient();
                this.fileProperties = {};
                try {
                    const uploadSession = await graphClient
                        .api(this.store.FolderItemPath + ':/' + encodedFilename + ':/createUploadSession')
                        .post({
                                "item": {
                                    "@microsoft.graph.conflictBehavior": "fail"
                                }
                            });
                    this.fileProperties = await this.uploadChunks(this.selectedFile, uploadSession.uploadUrl);
                    if (this.skipAttachmentProcess){
                        this.$emit('document-uploaded', { file: this.fileProperties, store: this.store, attachment: {} }); //attachmentToMap will be empty
                    }
                    else {
                        this.showAttachmentEdit = true;
                    }
                }
                catch (err){
                    if (err.statusCode == 409) {
                        this.handleFilenameConflict(encodedFilename);
                        return;
                    }
                    else { 
                        this.isSaving = false;
                        this.isInitial = true;
                        this.$emit('uploadFailed', err);
                        this.$notify({
                            title: 'File Upload Error',
                            type: 'error',
                            message: err.message
                        });
                        return;
                    }
                }
                
                this.resetFileInput();
            }
            , async uploadChunks(file, uploadUrl) {
                var reader = new FileReader(); 
                this.fileSize = null;
                this.uploadedSize = null;

                // Variables for byte stream position
                var position = 0; 
                var chunkLength = 320 * 1024; 
                console.log("File size is: " + file.size);
                this.fileSize = file.size; 
                var continueRead = true; 
                while (continueRead) {
                    var chunk; 
                    try {
                        continueRead = true; 
                        //Try to read in the chunk
                        try {
                            const stopByte = position + chunkLength; 
                            console.log("Sending Asynchronous request to read in chunk bytes from position " + position + " to end " + stopByte); 

                            chunk = await this.readFragmentAsync(file, position, stopByte); 
                            console.log("UploadChunks: Chunk read in of " + chunk.byteLength + " bytes."); 
                            if (chunk.byteLength > 0) {
                                continueRead = true; 
                            }else {
                                break; 
                            }
                            console.log("Chunk bytes received = " + chunk.byteLength); 
                        }catch (e) {
                            console.log("Bytes Received from readFragmentAsync:: " + e); 
                            break; 
                        }
                        // Try to upload the chunk.
                        try {
                            console.log("Request sent for uploadFragmentAsync"); 
                            const res = await this.uploadChunk(chunk, uploadUrl, position, file.size); 
                            // Check the response.
                            console.log(res);
                            if (!(res.status === 202 || res.status === 201 || res.status === 200))
                                throw ("Put operation did not return expected response"); 
                            if (res.status === 201 || res.status === 200)
                            {
                                console.log("Reached last chunk of file.  Status code is: " + res.status);
                                continueRead = false; 
                                this.uploadedSize = this.fileSize; //complete
                                return res.json; // file data
                            }  
                            else
                            {
                                console.log("Continuing - Status Code is: " + res.status);
                                position = Number(res.json.nextExpectedRanges[0].split('-')[0]);
                                this.uploadedSize = position;
                            }    
                            await this.$nextTick(); //update UI (percentage)
                            console.log("Successful response received from uploadChunk."); 
                        }catch (e) {
                            console.log("Error occured when calling uploadChunk::" + e); 
                        }

                    }catch (e) {
                        continueRead = false; 
                    }
                }    
            }

    // Reads in the chunk and returns a promise.
            , async readFragmentAsync(file, startByte, stopByte) {
                var frag = ""; 
                const reader = new FileReader(); 
                console.log("startByte :" + startByte + " stopByte :" + stopByte); 
                var blob = file.slice(startByte, stopByte); 
                reader.readAsArrayBuffer(blob); 
                return new Promise((resolve, reject) =>  {
                    reader.onloadend = (event) =>  {
                        console.log("onloadend called  " + reader.result.byteLength); 
                        if (reader.readyState === reader.DONE) {
                            frag = reader.result; 
                            resolve(frag); 
                        }
                    }; 
                }); 
            }

            // Upload each chunk using PUT
            , async uploadChunk(chunk, uploadURL, position, totalLength) {
                var max = position + chunk.byteLength - 1; 
                //var contentLength = position + chunk.byteLength;

                console.log("Chunk size is: " + chunk.byteLength + " bytes."); 

                // return new Promise((resolve, reject) =>  {

                    try {
                        const crHeader = "bytes " + position + "-" + max + "/" + totalLength;
                        //Execute PUT request to upload the content range.
                        const jqXHR = await this.$http.put(
                            uploadURL
                            , chunk
                            , { headers: {"Content-Range": crHeader, "content-type": "application/octet-stream" } }
                        ); 
                        
                        const results = {};
                        results.status = jqXHR.status;
                        results.json = jqXHR.data;
                        return results;
                    }catch (e) {
                        console.log("exception inside uploadChunk::  " + e); 
                    }
            }

            , async handleFilenameConflict(encodedFilename){
                console.log('409; getting existing file', this.store.FolderItemPath + '/children/' + encodedFilename);
                try { // try to get the existing file before offering to use it.
                    const graphClient = await this.graphService.getGraphClient();
                    const retryRes = await graphClient
                        .api(this.store.FolderItemPath + '/children/' + encodedFilename)
                        .get(); 
                    console.log('existing file:', retryRes);
                    this.fileProperties = retryRes;
                    if (this.onlyEmitFilePropertiesOnNameConflict){
                        this.$emit('name-conflict', this.fileProperties);
                        return;
                    }
                    this.attachmentToMap = await this.attachment_GetStoreAttachments_Object({
                        StoreId: this.store.Id
                        , FileId: this.fileProperties.id
                        }
                        , true // async (optional)
                    );
                }
                catch (err) { //failed getting existing file
                    console.error(err);
                    return;
                }
                this.showNameConflict = true;
            }
            , async handleUseExisting(){
                console.log('using existing file');
                this.showNameConflict = false;
                this.resetFileInput();
                if (!!this.attachmentToMap && Object.keys(this.attachmentToMap).length){ //if it was already ingested, just emit the event. The parent component will do the actual mapping
                    this.$emit('document-uploaded', { file: this.fileProperties, store: this.store, attachment: this.attachmentToMap});
                }
                else {
                    this.showAttachmentEdit = true;
                }
            }
            , async cancelNameConflict(){
                this.showNameConflict = false;
                this.attachmentToMap = {};
                this.isSaving = false;
                this.isInitial = true;
                this.$emit('uploadFailed');
                this.$notify.warning('File upload canceled');
            }
            , async fetchStore() {
                return await this.attachment_GetStores_Object({
                    StoreId: this.storeId
                });
            }
            , newAttachmentSaved: function (attachment, fileProperties) {
                console.log(attachment, fileProperties);
                this.showAttachmentEdit = false;
                this.$emit('document-uploaded', { file: fileProperties, store: this.store, attachment: attachment});
            }
            , resetFileInput() {
                this.isSaving = false;
                this.isInitial = true;
                this.$refs.txtSelectFile.type = 'text';
                this.$refs.txtSelectFile.type = 'file';
            }
            , fileDeleted: function (attachment) {
                this.showAttachmentEdit = false;
                this.selectedFileProperties = {};
            }
            , attachmentCanceled: function() {
                this.showAttachmentEdit = false;
                this.selectedFileProperties = {};
                this.attachmentToMap = {};
                this.selectedFileId = null;
            }
        }
     })
</script>

<style>
    #divUploadAttachment .dropbox {
        padding: 6px 12px;
        margin-top: 3px;
        border: 2px dashed #bbb;
        text-align: center;
        font-size: 14px;
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        cursor: pointer;
    }
    
    #divUploadAttachment .dropbox:hover {
        background-color: #F0F0F0 !important;
    }

    #divUploadAttachment .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 0;
    }
</style>