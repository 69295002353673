
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import ExcelImport from '@/components/ExcelImport.vue';
declare var getDateStringFromExcelDate: any;
@Component({
  components: {
    ExcelImport
  }
})
export default class InvestmentTransactionImport extends Vue {
    //#region Private declarations for Services
    //#endregion Private declarations for Services

    //#region Props
    //#endregion Props

    //#region Data
    fieldOptions: object[] = [
                    { "name": "Date", parseFunction: getDateStringFromExcelDate }
                    , { "name": "New Structure Type", "field": "NewStructureType" }
                    , { "name": "New Structure", "field": "NewStructure" }
                    , { "name": "Docs Received", "field": "DocumentationReceived" }
                ];
    logParameters: object = {
                    database: 'visium'
                    , schema: 'transactions'
                    , caller: 'ProcessImportInvesmentTransactionsPurchases'
                    , event: 'exec'
                };
    requiredColumns: string[] = ['InvestmentId', 'Date', 'Entity', 'Amount']          
    //#endregion Data

    //#endregion Computed
    //#endregion Computed

    //#region Lifecycle
    async created() {
    }
    //#endregion Lifecycle
    async handleImport(result){
      console.log(result);
    }
    //#region Methods
}
