//js API for Schema system
//Auto-generated 04/03/20 14:39
 

var mixinSchema_system = {
    methods: {
        /** @description Get AzureDatabaseServiceLevel using system.GetAzureDatabaseServiceLevel
         */
		 system_GetAzureDatabaseServiceLevel: function (params, onSuccess, onError, async) {
			/*
				this.AzureDatabaseServiceLevel = await this.system_GetAzureDatabaseServiceLevel({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'system.GetAzureDatabaseServiceLevel'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get BusinessObjects using System.GetBusinessObjects
         * @param {string} schemaName - 
         */
		, System_GetBusinessObjects: function (schemaName) {
			/*
				this.BusinessObjects = this.System_GetBusinessObjects(
					null // schemaName
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetBusinessObjects'
			  , params: {
					SchemaName: schemaName
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get BusinessObjectsAssignedToSecurityView using System.GetBusinessObjectsAssignedToSecurityView
         * @param {number} securityView - 
         */
		, System_GetBusinessObjectsAssignedToSecurityView: function (securityView) {
			/*
				this.BusinessObjectsAssignedToSecurityView = this.System_GetBusinessObjectsAssignedToSecurityView(
					null // securityView
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetBusinessObjectsAssignedToSecurityView'
			  , params: {
					SecurityView: securityView
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get BusinessObjectSchemas using System.GetBusinessObjectSchemas
         */
		, System_GetBusinessObjectSchemas: function () {
			/*
				this.BusinessObjectSchemas = this.System_GetBusinessObjectSchemas(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetBusinessObjectSchemas'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get BusinessObjectsForSecurityRole using System.GetBusinessObjectsForSecurityRole
         * @param {number} securityRole - 
         */
		, System_GetBusinessObjectsForSecurityRole: function (securityRole) {
			/*
				this.BusinessObjectsForSecurityRole = this.System_GetBusinessObjectsForSecurityRole(
					null // securityRole
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetBusinessObjectsForSecurityRole'
			  , params: {
					SecurityRole: securityRole
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get BusinessObjectsForUser using System.GetBusinessObjectsForUser
         * @param {number} userObjectId - 
         */
		, System_GetBusinessObjectsForUser: function (userObjectId) {
			/*
				this.BusinessObjectsForUser = this.System_GetBusinessObjectsForUser(
					null // userObjectId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetBusinessObjectsForUser'
			  , params: {
					UserObjectId: userObjectId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get BusinessObjectsUnassignedToSecurityView using System.GetBusinessObjectsUnassignedToSecurityView
         * @param {number} securityView - 
         */
		, System_GetBusinessObjectsUnassignedToSecurityView: function (securityView) {
			/*
				this.BusinessObjectsUnassignedToSecurityView = this.System_GetBusinessObjectsUnassignedToSecurityView(
					null // securityView
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetBusinessObjectsUnassignedToSecurityView'
			  , params: {
					SecurityView: securityView
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ChangeLog using system.GetChangeLog
         * @param {string} schema - 
         * @param {string} table - 
         * @param {string} field - 
         * @param {number} entityId - 
         */
		, system_GetChangeLog: function (params, onSuccess, onError, async) {
			/*
				this.ChangeLog = await this.system_GetChangeLog({
					Schema: schema
					, Table: table
					, Field: field
					, EntityId: entityId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'system.GetChangeLog'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Comments using system.GetComments
         * @param {string} entityType - 
         * @param {number} entityId - 
         */
		, system_GetComments: function (params, onSuccess, onError, async) {
			/*
				this.Comments = await this.system_GetComments({
					EntityType: entityType
					, EntityId: entityId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'system.GetComments'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ComponentPaths using System.GetComponentPaths
         */
		, System_GetComponentPaths: function () {
			/*
				this.ComponentPaths = this.System_GetComponentPaths(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetComponentPaths'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Components using System.GetComponents
         * @param {string} path - 
         */
		, System_GetComponents: function (path) {
			/*
				this.Components = this.System_GetComponents(
					null // path
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetComponents'
			  , params: {
					Path: path
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ComponentsAssignedToSecurityView using System.GetComponentsAssignedToSecurityView
         * @param {number} securityView - 
         */
		, System_GetComponentsAssignedToSecurityView: function (securityView) {
			/*
				this.ComponentsAssignedToSecurityView = this.System_GetComponentsAssignedToSecurityView(
					null // securityView
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetComponentsAssignedToSecurityView'
			  , params: {
					SecurityView: securityView
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ComponentsForSecurityRole using System.GetComponentsForSecurityRole
         * @param {number} securityRole - 
         */
		, System_GetComponentsForSecurityRole: function (securityRole) {
			/*
				this.ComponentsForSecurityRole = this.System_GetComponentsForSecurityRole(
					null // securityRole
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetComponentsForSecurityRole'
			  , params: {
					SecurityRole: securityRole
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ComponentsForUser using System.GetComponentsForUser
         * @param {number} userObjectId - 
         */
		, System_GetComponentsForUser: function (userObjectId) {
			/*
				this.ComponentsForUser = this.System_GetComponentsForUser(
					null // userObjectId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetComponentsForUser'
			  , params: {
					UserObjectId: userObjectId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ComponentsUnassignedToSecurityView using System.GetComponentsUnassignedToSecurityView
         * @param {number} securityView - 
         */
		, System_GetComponentsUnassignedToSecurityView: function (securityView) {
			/*
				this.ComponentsUnassignedToSecurityView = this.System_GetComponentsUnassignedToSecurityView(
					null // securityView
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetComponentsUnassignedToSecurityView'
			  , params: {
					SecurityView: securityView
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get HighestNamedKeyValue using system.GetHighestNamedKeyValue
         * @param {string} keyType - 
         */
		, system_GetHighestNamedKeyValue: function (keyType) {
			/*
				this.HighestNamedKeyValue = this.system_GetHighestNamedKeyValue(
					null // keyType
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetHighestNamedKeyValue'
			  , params: {
					KeyType: keyType
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get HighestSortIndexValue using system.GetHighestSortIndexValue
         * @param {number} menuCategory - 
         */
		, system_GetHighestSortIndexValue: function (menuCategory) {
			/*
				this.HighestSortIndexValue = this.system_GetHighestSortIndexValue(
					null // menuCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetHighestSortIndexValue'
			  , params: {
					MenuCategory: menuCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get KeyCategories using system.GetKeyCategories
         */
		, system_GetKeyCategories: function () {
			/*
				this.KeyCategories = this.system_GetKeyCategories(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetKeyCategories'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get KeyTypes using system.GetKeyTypes
         * @param {number} namedKeyCategoryId - 
         */
		, system_GetKeyTypes: function (namedKeyCategoryId) {
			/*
				this.KeyTypes = this.system_GetKeyTypes(
					null // namedKeyCategoryId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetKeyTypes'
			  , params: {
					NamedKeyCategoryId: namedKeyCategoryId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get LastLogins using system.GetLastLogins
         */
		, system_GetLastLogins: function () {
			/*
				this.LastLogins = this.system_GetLastLogins(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetLastLogins'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get LogUserStats using system.GetLogUserStats
         * @param {string} database - 
         * @param {string} schema - 
         * @param {string} caller - 
         * @param {string} event - 
         * @param {number} minMilliseconds - 
         */
		, system_GetLogUserStats: function (database, schema, caller, event, minMilliseconds) {
			/*
				this.LogUserStats = this.system_GetLogUserStats(
					null // database
					, null // schema
					, null // caller
					, null // event
					, null // minMilliseconds
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetLogUserStats'
			  , params: {
					Database: database
					, Schema: schema
					, Caller: caller
					, Event: event
					, MinMilliseconds: minMilliseconds
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MaxMenuCategorySortIndex using system.GetMaxMenuCategorySortIndex
         */
		, system_GetMaxMenuCategorySortIndex: function () {
			/*
				this.MaxMenuCategorySortIndex = this.system_GetMaxMenuCategorySortIndex(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetMaxMenuCategorySortIndex'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MaxMenuEntrySortIndex using system.GetMaxMenuEntrySortIndex
         * @param {number} menuCategoryId - 
         */
		, system_GetMaxMenuEntrySortIndex: function (menuCategoryId) {
			/*
				this.MaxMenuEntrySortIndex = this.system_GetMaxMenuEntrySortIndex(
					null // menuCategoryId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetMaxMenuEntrySortIndex'
			  , params: {
					MenuCategoryId: menuCategoryId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MaxMenuItemSortIndex using system.GetMaxMenuItemSortIndex
         * @param {number} menuCategoryId - 
         */
		, system_GetMaxMenuItemSortIndex: function (menuCategoryId) {
			/*
				this.MaxMenuItemSortIndex = this.system_GetMaxMenuItemSortIndex(
					null // menuCategoryId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetMaxMenuItemSortIndex'
			  , params: {
					MenuCategoryId: menuCategoryId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MaxNamedKeyFields using system.GetMaxNamedKeyFields
         * @param {string} keyType - 
         */
		, system_GetMaxNamedKeyFields: function (keyType) {
			/*
				this.MaxNamedKeyFields = this.system_GetMaxNamedKeyFields(
					null // keyType
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetMaxNamedKeyFields'
			  , params: {
					KeyType: keyType
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MenuCategories using system.GetMenuCategories
         */
		, system_GetMenuCategories: function () {
			/*
				this.MenuCategories = this.system_GetMenuCategories(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetMenuCategories'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MenuEntries using system.GetMenuEntries
         * @param {number} menuCategoryId - 
         */
		, system_GetMenuEntries: function (menuCategoryId) {
			/*
				this.MenuEntries = this.system_GetMenuEntries(
					null // menuCategoryId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetMenuEntries'
			  , params: {
					MenuCategoryId: menuCategoryId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MenuEntriesHierarchy using system.GetMenuEntriesHierarchy
         * @param {boolean} showDisabledMenuItems - 
         */
		, system_GetMenuEntriesHierarchy: function (params, onSuccess, onError, async) {
			/*
				this.MenuEntriesHierarchy = await this.system_GetMenuEntriesHierarchy({
					ShowDisabledMenuItems: showDisabledMenuItems
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'system.GetMenuEntriesHierarchy'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get MenuItemByRoute using system.GetMenuItemByRoute
         * @param {string} path - 
         * @param {string} parameters - 
         */
		, system_GetMenuItemByRoute: function (params, onSuccess, onError, async) {
			/*
				this.MenuItemByRoute = await this.system_GetMenuItemByRoute({
					Path: path
					, Parameters: parameters
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'system.GetMenuItemByRoute'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get MenuItemsAssignedToSecurityView using System.GetMenuItemsAssignedToSecurityView
         * @param {number} securityView - 
         * @param {number} securityCategory - 
         */
		, System_GetMenuItemsAssignedToSecurityView: function (securityView, securityCategory) {
			/*
				this.MenuItemsAssignedToSecurityView = this.System_GetMenuItemsAssignedToSecurityView(
					null // securityView
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetMenuItemsAssignedToSecurityView'
			  , params: {
					SecurityView: securityView
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MenuItemsAssignedToSecurityViewV2 using system.GetMenuItemsAssignedToSecurityViewV2
         * @param {number} securityView - 
         */
		, system_GetMenuItemsAssignedToSecurityViewV2: function (securityView) {
			/*
				this.MenuItemsAssignedToSecurityViewV2 = this.system_GetMenuItemsAssignedToSecurityViewV2(
					null // securityView
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetMenuItemsAssignedToSecurityViewV2'
			  , params: {
					SecurityView: securityView
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MenuItemsBySecurity using system.GetMenuItemsBySecurity
         * @param {number} menuCategoryId - 
         * @param {boolean} groupByCategory - 
         */
		, system_GetMenuItemsBySecurity: function (menuCategoryId, groupByCategory) {
			/*
				this.MenuItemsBySecurity = this.system_GetMenuItemsBySecurity(
					null // menuCategoryId
					, null // groupByCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetMenuItemsBySecurity'
			  , params: {
					MenuCategoryId: menuCategoryId
					, GroupByCategory: groupByCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MenuItemsForSecurityRole using System.GetMenuItemsForSecurityRole
         * @param {number} securityRole - 
         * @param {number} securityCategory - 
         */
		, System_GetMenuItemsForSecurityRole: function (securityRole, securityCategory) {
			/*
				this.MenuItemsForSecurityRole = this.System_GetMenuItemsForSecurityRole(
					null // securityRole
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetMenuItemsForSecurityRole'
			  , params: {
					SecurityRole: securityRole
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MenuItemsForUser using System.GetMenuItemsForUser
         * @param {number} userObjectId - 
         * @param {number} securityCategory - 
         */
		, System_GetMenuItemsForUser: function (userObjectId, securityCategory) {
			/*
				this.MenuItemsForUser = this.System_GetMenuItemsForUser(
					null // userObjectId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetMenuItemsForUser'
			  , params: {
					UserObjectId: userObjectId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MenuItemsForUserV2 using system.GetMenuItemsForUserV2
         * @param {number} userObjectId - 
         * @param {number} securityCategory - 
         */
		, system_GetMenuItemsForUserV2: function (userObjectId, securityCategory) {
			/*
				this.MenuItemsForUserV2 = this.system_GetMenuItemsForUserV2(
					null // userObjectId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetMenuItemsForUserV2'
			  , params: {
					UserObjectId: userObjectId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MenuItemsUnassignedToSecurityView using System.GetMenuItemsUnassignedToSecurityView
         * @param {number} securityView - 
         * @param {number} securityCategory - 
         */
		, System_GetMenuItemsUnassignedToSecurityView: function (securityView, securityCategory) {
			/*
				this.MenuItemsUnassignedToSecurityView = this.System_GetMenuItemsUnassignedToSecurityView(
					null // securityView
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetMenuItemsUnassignedToSecurityView'
			  , params: {
					SecurityView: securityView
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MenuItemsUnassignedToSecurityViewV2 using system.GetMenuItemsUnassignedToSecurityViewV2
         * @param {number} securityView - 
         */
		, system_GetMenuItemsUnassignedToSecurityViewV2: function (securityView) {
			/*
				this.MenuItemsUnassignedToSecurityViewV2 = this.system_GetMenuItemsUnassignedToSecurityViewV2(
					null // securityView
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetMenuItemsUnassignedToSecurityViewV2'
			  , params: {
					SecurityView: securityView
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NamedKeyCategories using System.GetNamedKeyCategories
         */
		, System_GetNamedKeyCategories: function () {
			/*
				this.NamedKeyCategories = this.System_GetNamedKeyCategories(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetNamedKeyCategories'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NamedKeys using system.GetNamedKeys
         * @param {string} keyType - 
         * @param {string} parentKeyType - 
         * @param {number} parentKeyValue - 
         * @param {string} keyCategory - 
         * @param {number} namedKeyCategoryId - 
         * @param {number} namedKeyTypeId - 
         * @param {number} keyValue - 
         * @param {boolean} orderByText - 
         * @param {boolean} includeDotSubstitutionRows - 
         */
		, system_GetNamedKeys: function (keyType, parentKeyType, parentKeyValue, keyCategory, namedKeyCategoryId, namedKeyTypeId, keyValue, orderByText, includeDotSubstitutionRows) {
			/*
				this.NamedKeys = this.system_GetNamedKeys(
					null // keyType
					, null // parentKeyType
					, null // parentKeyValue
					, null // keyCategory
					, null // namedKeyCategoryId
					, null // namedKeyTypeId
					, null // keyValue
					, null // orderByText
					, null // includeDotSubstitutionRows
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetNamedKeys'
			  , params: {
					KeyType: keyType
					, ParentKeyType: parentKeyType
					, ParentKeyValue: parentKeyValue
					, KeyCategory: keyCategory
					, NamedKeyCategoryId: namedKeyCategoryId
					, NamedKeyTypeId: namedKeyTypeId
					, KeyValue: keyValue
					, OrderByText: orderByText
					, IncludeDotSubstitutionRows: includeDotSubstitutionRows
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NamedKeyTypes using System.GetNamedKeyTypes
         * @param {number} namedKeyCategoryId - 
         */
		, System_GetNamedKeyTypes: function (namedKeyCategoryId) {
			/*
				this.NamedKeyTypes = this.System_GetNamedKeyTypes(
					null // namedKeyCategoryId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetNamedKeyTypes'
			  , params: {
					NamedKeyCategoryId: namedKeyCategoryId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NamedKeyTypeValues using system.GetNamedKeyTypeValues
         * @param {string} namedKeyTypeName - 
         */
		, system_GetNamedKeyTypeValues: function (namedKeyTypeName) {
			/*
				this.NamedKeyTypeValues = this.system_GetNamedKeyTypeValues(
					null // namedKeyTypeName
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetNamedKeyTypeValues'
			  , params: {
					NamedKeyTypeName: namedKeyTypeName
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get QueriesAssignedToSecurityView using System.GetQueriesAssignedToSecurityView
         * @param {number} securityView - 
         */
		, System_GetQueriesAssignedToSecurityView: function (securityView) {
			/*
				this.QueriesAssignedToSecurityView = this.System_GetQueriesAssignedToSecurityView(
					null // securityView
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetQueriesAssignedToSecurityView'
			  , params: {
					SecurityView: securityView
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get QueriesForSecurityRole using System.GetQueriesForSecurityRole
         * @param {number} securityRole - 
         */
		, System_GetQueriesForSecurityRole: function (securityRole) {
			/*
				this.QueriesForSecurityRole = this.System_GetQueriesForSecurityRole(
					null // securityRole
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetQueriesForSecurityRole'
			  , params: {
					SecurityRole: securityRole
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get QueriesForUser using System.GetQueriesForUser
         * @param {number} userObjectId - 
         */
		, System_GetQueriesForUser: function (userObjectId) {
			/*
				this.QueriesForUser = this.System_GetQueriesForUser(
					null // userObjectId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetQueriesForUser'
			  , params: {
					UserObjectId: userObjectId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get QueriesUnassignedToSecurityView using System.GetQueriesUnassignedToSecurityView
         * @param {number} securityView - 
         */
		, System_GetQueriesUnassignedToSecurityView: function (securityView) {
			/*
				this.QueriesUnassignedToSecurityView = this.System_GetQueriesUnassignedToSecurityView(
					null // securityView
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetQueriesUnassignedToSecurityView'
			  , params: {
					SecurityView: securityView
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get QueryWhitelist using System.GetQueryWhitelist
         * @param {string} type - 
         */
		, System_GetQueryWhitelist: function (type) {
			/*
				this.QueryWhitelist = this.System_GetQueryWhitelist(
					null // type
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetQueryWhitelist'
			  , params: {
					Type: type
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityAuditLog using system.GetSecurityAuditLog
         * @param {boolean} showAll - 
         */
		, system_GetSecurityAuditLog: function (showAll) {
			/*
				this.SecurityAuditLog = this.system_GetSecurityAuditLog(
					null // showAll
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetSecurityAuditLog'
			  , params: {
					ShowAll: showAll
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityLog using System.GetSecurityLog
         * @param {number} securityLogType - 
         * @param {number} maxNumResults - 
         */
		, System_GetSecurityLog: function (securityLogType, maxNumResults) {
			/*
				this.SecurityLog = this.System_GetSecurityLog(
					null // securityLogType
					, null // maxNumResults
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityLog'
			  , params: {
					SecurityLogType: securityLogType
					, MaxNumResults: maxNumResults
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityRolesAssignedToNamedKeyCategory using system.GetSecurityRolesAssignedToNamedKeyCategory
         * @param {number} namedKeyCategoryId - 
         */
		, system_GetSecurityRolesAssignedToNamedKeyCategory: function (namedKeyCategoryId) {
			/*
				this.SecurityRolesAssignedToNamedKeyCategory = this.system_GetSecurityRolesAssignedToNamedKeyCategory(
					null // namedKeyCategoryId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetSecurityRolesAssignedToNamedKeyCategory'
			  , params: {
					NamedKeyCategoryId: namedKeyCategoryId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityRolesAssignedToSecurityView using System.GetSecurityRolesAssignedToSecurityView
         * @param {number} securityView - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityRolesAssignedToSecurityView: function (securityView, securityCategory) {
			/*
				this.SecurityRolesAssignedToSecurityView = this.System_GetSecurityRolesAssignedToSecurityView(
					null // securityView
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityRolesAssignedToSecurityView'
			  , params: {
					SecurityView: securityView
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityRolesAssignedToUser using system.GetSecurityRolesAssignedToUser
         * @param {number} userObjectId - 
         * @param {number} securityCategory - 
         */
		, system_GetSecurityRolesAssignedToUser: function (userObjectId, securityCategory) {
			/*
				this.SecurityRolesAssignedToUser = this.system_GetSecurityRolesAssignedToUser(
					null // userObjectId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetSecurityRolesAssignedToUser'
			  , params: {
					UserObjectId: userObjectId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityRolesForBusinessObject using System.GetSecurityRolesForBusinessObject
         * @param {number} businessObjectId - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityRolesForBusinessObject: function (businessObjectId, securityCategory) {
			/*
				this.SecurityRolesForBusinessObject = this.System_GetSecurityRolesForBusinessObject(
					null // businessObjectId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityRolesForBusinessObject'
			  , params: {
					BusinessObjectId: businessObjectId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityRolesForComponent using System.GetSecurityRolesForComponent
         * @param {number} componentId - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityRolesForComponent: function (componentId, securityCategory) {
			/*
				this.SecurityRolesForComponent = this.System_GetSecurityRolesForComponent(
					null // componentId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityRolesForComponent'
			  , params: {
					ComponentId: componentId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityRolesForMenuItem using system.GetSecurityRolesForMenuItem
         * @param {number} menuItemId - 
         * @param {number} securityCategory - 
         */
		, system_GetSecurityRolesForMenuItem: function (menuItemId, securityCategory) {
			/*
				this.SecurityRolesForMenuItem = this.system_GetSecurityRolesForMenuItem(
					null // menuItemId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetSecurityRolesForMenuItem'
			  , params: {
					MenuItemId: menuItemId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityRolesForQuery using System.GetSecurityRolesForQuery
         * @param {number} queryWhitelistId - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityRolesForQuery: function (queryWhitelistId, securityCategory) {
			/*
				this.SecurityRolesForQuery = this.System_GetSecurityRolesForQuery(
					null // queryWhitelistId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityRolesForQuery'
			  , params: {
					QueryWhitelistId: queryWhitelistId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityRolesUnassignedToNamedKeyCategory using system.GetSecurityRolesUnassignedToNamedKeyCategory
         * @param {number} namedKeyCategoryId - 
         */
		, system_GetSecurityRolesUnassignedToNamedKeyCategory: function (namedKeyCategoryId) {
			/*
				this.SecurityRolesUnassignedToNamedKeyCategory = this.system_GetSecurityRolesUnassignedToNamedKeyCategory(
					null // namedKeyCategoryId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetSecurityRolesUnassignedToNamedKeyCategory'
			  , params: {
					NamedKeyCategoryId: namedKeyCategoryId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityRolesUnassignedToSecurityView using System.GetSecurityRolesUnassignedToSecurityView
         * @param {number} securityView - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityRolesUnassignedToSecurityView: function (securityView, securityCategory) {
			/*
				this.SecurityRolesUnassignedToSecurityView = this.System_GetSecurityRolesUnassignedToSecurityView(
					null // securityView
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityRolesUnassignedToSecurityView'
			  , params: {
					SecurityView: securityView
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityRolesUnassignedToUser using system.GetSecurityRolesUnassignedToUser
         * @param {number} userObjectId - 
         * @param {number} securityCategory - 
         */
		, system_GetSecurityRolesUnassignedToUser: function (userObjectId, securityCategory) {
			/*
				this.SecurityRolesUnassignedToUser = this.system_GetSecurityRolesUnassignedToUser(
					null // userObjectId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetSecurityRolesUnassignedToUser'
			  , params: {
					UserObjectId: userObjectId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityViewsAssignedToBusinessObject using System.GetSecurityViewsAssignedToBusinessObject
         * @param {number} businessObjectId - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityViewsAssignedToBusinessObject: function (businessObjectId, securityCategory) {
			/*
				this.SecurityViewsAssignedToBusinessObject = this.System_GetSecurityViewsAssignedToBusinessObject(
					null // businessObjectId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityViewsAssignedToBusinessObject'
			  , params: {
					BusinessObjectId: businessObjectId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityViewsAssignedToComponent using System.GetSecurityViewsAssignedToComponent
         * @param {number} componentId - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityViewsAssignedToComponent: function (componentId, securityCategory) {
			/*
				this.SecurityViewsAssignedToComponent = this.System_GetSecurityViewsAssignedToComponent(
					null // componentId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityViewsAssignedToComponent'
			  , params: {
					ComponentId: componentId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityViewsAssignedToMenuItem using system.GetSecurityViewsAssignedToMenuItem
         * @param {number} menuItemId - 
         * @param {number} securityCategory - 
         */
		, system_GetSecurityViewsAssignedToMenuItem: function (menuItemId, securityCategory) {
			/*
				this.SecurityViewsAssignedToMenuItem = this.system_GetSecurityViewsAssignedToMenuItem(
					null // menuItemId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetSecurityViewsAssignedToMenuItem'
			  , params: {
					MenuItemId: menuItemId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityViewsAssignedToQuery using System.GetSecurityViewsAssignedToQuery
         * @param {number} queryWhitelistId - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityViewsAssignedToQuery: function (queryWhitelistId, securityCategory) {
			/*
				this.SecurityViewsAssignedToQuery = this.System_GetSecurityViewsAssignedToQuery(
					null // queryWhitelistId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityViewsAssignedToQuery'
			  , params: {
					QueryWhitelistId: queryWhitelistId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityViewsAssignedToSecurityRole using System.GetSecurityViewsAssignedToSecurityRole
         * @param {number} securityRole - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityViewsAssignedToSecurityRole: function (securityRole, securityCategory) {
			/*
				this.SecurityViewsAssignedToSecurityRole = this.System_GetSecurityViewsAssignedToSecurityRole(
					null // securityRole
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityViewsAssignedToSecurityRole'
			  , params: {
					SecurityRole: securityRole
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityViewsForUser using System.GetSecurityViewsForUser
         * @param {number} userObjectId - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityViewsForUser: function (userObjectId, securityCategory) {
			/*
				this.SecurityViewsForUser = this.System_GetSecurityViewsForUser(
					null // userObjectId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityViewsForUser'
			  , params: {
					UserObjectId: userObjectId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityViewsUnassignedToBusinessObject using System.GetSecurityViewsUnassignedToBusinessObject
         * @param {number} businessObjectId - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityViewsUnassignedToBusinessObject: function (businessObjectId, securityCategory) {
			/*
				this.SecurityViewsUnassignedToBusinessObject = this.System_GetSecurityViewsUnassignedToBusinessObject(
					null // businessObjectId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityViewsUnassignedToBusinessObject'
			  , params: {
					BusinessObjectId: businessObjectId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityViewsUnassignedToComponent using System.GetSecurityViewsUnassignedToComponent
         * @param {number} componentId - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityViewsUnassignedToComponent: function (componentId, securityCategory) {
			/*
				this.SecurityViewsUnassignedToComponent = this.System_GetSecurityViewsUnassignedToComponent(
					null // componentId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityViewsUnassignedToComponent'
			  , params: {
					ComponentId: componentId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityViewsUnassignedToMenuItem using system.GetSecurityViewsUnassignedToMenuItem
         * @param {number} menuItemId - 
         * @param {number} securityCategory - 
         */
		, system_GetSecurityViewsUnassignedToMenuItem: function (menuItemId, securityCategory) {
			/*
				this.SecurityViewsUnassignedToMenuItem = this.system_GetSecurityViewsUnassignedToMenuItem(
					null // menuItemId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetSecurityViewsUnassignedToMenuItem'
			  , params: {
					MenuItemId: menuItemId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityViewsUnassignedToQuery using System.GetSecurityViewsUnassignedToQuery
         * @param {number} queryWhitelistId - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityViewsUnassignedToQuery: function (queryWhitelistId, securityCategory) {
			/*
				this.SecurityViewsUnassignedToQuery = this.System_GetSecurityViewsUnassignedToQuery(
					null // queryWhitelistId
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityViewsUnassignedToQuery'
			  , params: {
					QueryWhitelistId: queryWhitelistId
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SecurityViewsUnassignedToSecurityRole using System.GetSecurityViewsUnassignedToSecurityRole
         * @param {number} securityRole - 
         * @param {number} securityCategory - 
         */
		, System_GetSecurityViewsUnassignedToSecurityRole: function (securityRole, securityCategory) {
			/*
				this.SecurityViewsUnassignedToSecurityRole = this.System_GetSecurityViewsUnassignedToSecurityRole(
					null // securityRole
					, null // securityCategory
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetSecurityViewsUnassignedToSecurityRole'
			  , params: {
					SecurityRole: securityRole
					, SecurityCategory: securityCategory
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get TopAzureDatabaseServiceLevelOverride using system.GetTopAzureDatabaseServiceLevelOverride
         */
		, system_GetTopAzureDatabaseServiceLevelOverride: function () {
			/*
				this.TopAzureDatabaseServiceLevelOverride = this.system_GetTopAzureDatabaseServiceLevelOverride(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetTopAzureDatabaseServiceLevelOverride'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Users using system.GetUsers
         * @param {string} firstName - 
         * @param {string} lastName - 
         */
		, system_GetUsers: function (firstName, lastName) {
			/*
				this.Users = this.system_GetUsers(
					null // firstName
					, null // lastName
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetUsers'
			  , params: {
					FirstName: firstName
					, LastName: lastName
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get UsersAssignedToSecurityRole using system.GetUsersAssignedToSecurityRole
         * @param {number} securityRole - 
         * @param {boolean} orderByFirstName - 
         */
		, system_GetUsersAssignedToSecurityRole: function (securityRole, orderByFirstName) {
			/*
				this.UsersAssignedToSecurityRole = this.system_GetUsersAssignedToSecurityRole(
					null // securityRole
					, null // orderByFirstName
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetUsersAssignedToSecurityRole'
			  , params: {
					SecurityRole: securityRole
					, OrderByFirstName: orderByFirstName
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get UsersForBusinessObject using System.GetUsersForBusinessObject
         * @param {number} businessObjectId - 
         */
		, System_GetUsersForBusinessObject: function (businessObjectId) {
			/*
				this.UsersForBusinessObject = this.System_GetUsersForBusinessObject(
					null // businessObjectId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetUsersForBusinessObject'
			  , params: {
					BusinessObjectId: businessObjectId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get UsersForComponent using System.GetUsersForComponent
         * @param {number} componentId - 
         */
		, System_GetUsersForComponent: function (componentId) {
			/*
				this.UsersForComponent = this.System_GetUsersForComponent(
					null // componentId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetUsersForComponent'
			  , params: {
					ComponentId: componentId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get UsersForMenuItem using system.GetUsersForMenuItem
         * @param {number} menuItemId - 
         */
		, system_GetUsersForMenuItem: function (menuItemId) {
			/*
				this.UsersForMenuItem = this.system_GetUsersForMenuItem(
					null // menuItemId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetUsersForMenuItem'
			  , params: {
					MenuItemId: menuItemId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get UsersForQuery using System.GetUsersForQuery
         * @param {number} queryWhitelistId - 
         */
		, System_GetUsersForQuery: function (queryWhitelistId) {
			/*
				this.UsersForQuery = this.System_GetUsersForQuery(
					null // queryWhitelistId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetUsersForQuery'
			  , params: {
					QueryWhitelistId: queryWhitelistId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get UsersForSecurityView using System.GetUsersForSecurityView
         * @param {number} securityView - 
         */
		, System_GetUsersForSecurityView: function (securityView) {
			/*
				this.UsersForSecurityView = this.System_GetUsersForSecurityView(
					null // securityView
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'System.GetUsersForSecurityView'
			  , params: {
					SecurityView: securityView
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get UsersUnassignedToSecurityRole using system.GetUsersUnassignedToSecurityRole
         * @param {number} securityRole - 
         */
		, system_GetUsersUnassignedToSecurityRole: function (securityRole) {
			/*
				this.UsersUnassignedToSecurityRole = this.system_GetUsersUnassignedToSecurityRole(
					null // securityRole
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetUsersUnassignedToSecurityRole'
			  , params: {
					SecurityRole: securityRole
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get WelcomePageMenuItems using system.GetWelcomePageMenuItems
         */
		, system_GetWelcomePageMenuItems: function () {
			/*
				this.WelcomePageMenuItems = this.system_GetWelcomePageMenuItems(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'system.GetWelcomePageMenuItems'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
    }
};
export default mixinSchema_system;