
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  }
})
export default class Breadcrumb extends Vue {
  list: Array<any>;
  isLast (index: number) {
    return index === this.list.length - 1;
  }
  showName(item) {
    if (item.meta && item.meta.label) {
      item = item.meta && item.meta.label;
    }
    if (item.name) {
      item = item.name;
    }
    return item;
  }
}
