// TS API for Schema visium
// Auto-generated 10/10/23 10:56


declare var smartData: any;
// output classes:
export class CancelAndResendVendorVerification {
    VendorVerificationId: number = undefined;
}
export class BalanceSheetCategories {
    Id: number=undefined;
    SuperCategory: string=undefined;
    Category: string=undefined;
    SubCategory: string=undefined;
    Client: string=undefined;
    ClientId: number=undefined;
    ClientParents: number=undefined;
    GeneralAssets: number=undefined;
    Deletable: boolean=undefined;
    DisplayOrder: number=undefined;
    Asset: boolean=undefined;
    liability: boolean=undefined;
    label: string=undefined;
    key: number = undefined;
}
export class BalanceSheetCategoryAssets {
    EntityId: number=undefined;
    EntityType: string=undefined;
    EntityTypeDisplay: string=undefined;
    Name: string = undefined;
}
export class Banks {
    Id: number=undefined;
    Name: string=undefined;
    Abbreviation: string=undefined;
    City: string=undefined;
    State: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    IsDefault: boolean = undefined;
}
export class EntityMap {
    Id: number=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    EntityText: string=undefined;
    EntityTextType: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string = undefined;
}
export class Persons {
    Id: number=undefined;
    Name: string = undefined;
}
export class SendVendorVerification {
    VendorVerificationId: number = undefined;
}

// Parameter interfaces:

export interface CancelAndResendVendorVerificationParameters {
    VendorVerificationId: number;
}

export interface GetBalanceSheetCategoriesParameters {
    ClientId: number;
    Asset: boolean;
    Liability: boolean;
}

export interface GetBalanceSheetCategoryAssetsParameters {
    BalanceSheetCategoryId: number;
}

export interface GetBanksParameters {
    ClientId: number;
    BankId: number;
}

export interface GetEntityMapParameters {
    EntityId: number;
    EntityType: string;
    EntityTextType: string;
}

export interface GetPersonsParameters {
    PersonId: number;
    SearchTerm: string;
}

export interface ResetVerificationParameters {
    VendorId: number;
}

export interface SendVendorVerificationParameters {
    VendorId: number;
    KeyPersonId: number;
}

export class VisiumService {

  CancelAndResendVendorVerification (params: CancelAndResendVendorVerificationParameters): Promise<CancelAndResendVendorVerification[]> {
    return smartData({
        storedProcName: 'visium.CancelAndResendVendorVerification',
        params: params
      });
    }

  GetBalanceSheetCategories (params: GetBalanceSheetCategoriesParameters): Promise<BalanceSheetCategories[]> {
    return smartData({
        storedProcName: 'visium.GetBalanceSheetCategories',
        params: params
      });
    }

  GetBalanceSheetCategoryAssets (params: GetBalanceSheetCategoryAssetsParameters): Promise<BalanceSheetCategoryAssets[]> {
    return smartData({
        storedProcName: 'visium.GetBalanceSheetCategoryAssets',
        params: params
      });
    }

  GetBanks (params: GetBanksParameters): Promise<Banks[]> {
    return smartData({
        storedProcName: 'visium.GetBanks',
        params: params
      });
    }

  GetEntityMap (params: GetEntityMapParameters): Promise<EntityMap[]> {
    return smartData({
        storedProcName: 'visium.GetEntityMap',
        params: params
      });
    }

  GetPersons (params: GetPersonsParameters): Promise<Persons[]> {
    return smartData({
        storedProcName: 'visium.GetPersons',
        params: params
      });
    }

  ResetVerification (params: ResetVerificationParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'visium.ResetVerification',
        params: params
      });
    }

  SendVendorVerification (params: SendVendorVerificationParameters): Promise<SendVendorVerification[]> {
    return smartData({
        storedProcName: 'visium.SendVendorVerification',
        params: params
      });
    }
}
