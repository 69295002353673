
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import accounting from 'accounting';

/* based on previously existing form component (CurrencyInput), as well as:
    https://jsfiddle.net/crswll/xxuda425/5/, found from
    https://stackoverflow.com/questions/41112733/whats-the-proper-way-to-implement-formatting-on-v-model-in-vue-js-2-0
*/

declare function tryParseFloat(input: any, defaultValue: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
    inheritAttrs: false
})
export default class FormattedInput extends Vue {
    //#region Data
    @Prop()
    value: any;
    @Prop()
    formatType: string;
    @Prop({
        default: null
    })
    numDecimals: number;

    isFocused: boolean = false;
    internalValue: any = this.parseValue(this.value);
    lastTypedValue: any = null; // gkb 05/14/19 - added this to track last typed value instead of emitting typed value
    //#endregion Data

    //#region Lifecycle
    async created() {
        this.lastTypedValue = this.getFormattedEditValue();
    }
    //#endregion Lifecycle

    //#region Computed
    get displayValue(): any {
        // gkb 01/30/19 - we want displayValue to be updated when either internalValue or value is changed; using them here ensures they trigger the update when not actually used based on the if branch
        const x = this.value;
        const y = this.internalValue;

        if (this.isFocused) {
            // return this.internalValue == null ? null : this.internalValue.toString();
            // return this.value == null ? null : this.value.toString(); // gkb 01/30/19 - display what they type, not the parsed version of it (e.g. don't remove a decimal at the end of a number while they're typing)
            return this.lastTypedValue == null ? null : this.lastTypedValue.toString(); // gkb 05/14/19 - stop emitting what they type, and instead we track and display the last typed value
        } else {
            return this.getFormattedDisplayValue();
        }
    }
    set displayValue(modifiedValue) {
        this.lastTypedValue = modifiedValue;
        
        if (modifiedValue === '-') {
            return; // do nothing - user started typing a negative number
        }

        // Note: We cannot set this.value because it is a prop. Instead, we manage internalValue, and $emit
        //  the 'input' with the parsed value to notify the parent component (which also updates v-model).
        this.internalValue = this.parseAndConvertValue(modifiedValue);

        // this.$emit('input', this.internalValue);
        // this.$emit('input', modifiedValue); // gkb 01/30/19 - emit what they type, not the parsed version of it (e.g. don't remove a decimal at the end of a number while they're typing)
        // gkb 05/14/19 - don't emit anything as they type - wait until blur, and emit the internalValue
    }
    //#endregion Computed

    //#region Watches
    // gkb 01/30/19 - this will be fired unnecessarily when typing, but needs to be here in case 'value' is updated externally
    @Watch('value')
    onChange_value(val, oldVal) {
        // this.internalValue = val;
        this.internalValue = this.parseValue(val); // gkb 01/30/19 - don't allow internalValue to be unparsed (esp. since we're now emitting 'input' on what was typed, rather than parsed)
    }
    //#endregion Watches

    //#region Methods
    getFormattedEditValue() {
        if (this.internalValue === undefined || this.internalValue === null) {
            return null;
        }

        let formattedValue = null;
        let numericalValue = null;
        switch (this.formatType) {
            case 'currency':
            case 'decimal':
                numericalValue = tryParseFloat(this.internalValue, null);
                if (numericalValue !== null) {
                    formattedValue =
                        accounting.formatNumber(this.internalValue, tryParseInt(this.numDecimals, 2));
                }
                break;
            case 'percent':
                numericalValue = tryParseFloat(this.internalValue, null);
                if (numericalValue !== null) {
                    formattedValue =
                        accounting.formatNumber(this.internalValue * 100, tryParseInt(this.numDecimals, 0));
                }
                break;
            case 'percentDisplay':
                numericalValue = tryParseFloat(this.internalValue, null);
                if (numericalValue !== null) {
                    formattedValue =
                        accounting.formatNumber(this.internalValue, tryParseInt(this.numDecimals, 0));
                }
                break;
            default:
                formattedValue = this.internalValue;
                break;
        }
        return formattedValue;
    }

    getFormattedDisplayValue() {
        if (this.internalValue === undefined || this.internalValue === null) {
            return null;
        }

        let formattedValue = null;
        let numericalValue = null;
        switch (this.formatType) {
            case 'currency':
                formattedValue = 
                    accounting.formatMoney(this.internalValue, '$', tryParseInt(this.numDecimals, 2));
                break;
            case 'decimal':
                numericalValue = tryParseFloat(this.internalValue, null);
                if (numericalValue !== null) {
                    formattedValue =
                        accounting.formatNumber(this.internalValue, tryParseInt(this.numDecimals, 2));
                }
                break;
            case 'percent':
                numericalValue = tryParseFloat(this.internalValue, null);
                if (numericalValue !== null) {
                    formattedValue =
                        accounting.formatNumber(this.internalValue * 100, tryParseInt(this.numDecimals, 0)) + '%';
                }
                break;
            case 'percentDisplay':
                numericalValue = tryParseFloat(this.internalValue, null);
                if (numericalValue !== null) {
                    formattedValue =
                        accounting.formatNumber(this.internalValue, tryParseInt(this.numDecimals, 0)) + '%';
                }
                break;
            default:
                formattedValue = this.internalValue;
                break;
        }
        return formattedValue;
    }

    parseValue(modifiedValue) {
        // if (!modifiedValue) {
        if (modifiedValue === '' || modifiedValue === undefined || modifiedValue === null) { // gkb 07/25/19 - don't parse 0 as null
            return null;
        }

        let newValue = null;
        switch (this.formatType) {
            case 'currency':
            case 'decimal':
            case 'percent':
            case 'percentDisplay':
                newValue = tryParseFloat(
                    modifiedValue.toString().replace(/[^\d\.\-]/g, ''),
                    0
                );
                break;
            default:
                newValue = modifiedValue;
                break;
        }
        return newValue;
    }

    parseAndConvertValue(modifiedValue) {
        // if (!modifiedValue) {
        if (modifiedValue === '' || modifiedValue === undefined || modifiedValue === null) { // gkb 07/25/19 - don't parse 0 as null
            return null;
        }

        let newValue = null;
        switch (this.formatType) {
            case 'currency':
            case 'decimal':
            case 'percentDisplay':
                newValue = tryParseFloat(
                    modifiedValue.toString().replace(/[^\d\.\-]/g, ''),
                    0
                );
                break;
            case 'percent':
                newValue =
                    tryParseFloat(modifiedValue.toString().replace(/[^\d\.\-]/g, ''), 0) /
                    100;
                break;
            default:
                newValue = modifiedValue;
                break;
        }
        return newValue;
    }
    focus(){
        (this.$refs.input as HTMLFormElement).focus();
    }
    //#endregion Methods
}
