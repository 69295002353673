
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';
import MicrosoftGraphClient, { GraphRequest, ResponseType } from '@microsoft/microsoft-graph-client';
import * as LiqService from '@/services/DAL/liq_Service';
import * as AttachmentService from '@/services/DAL/attachmentService';
import Common from '../../utils/common';

declare var SmartObject: any;

declare function encodeFileName(fileName: string): string;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: number, deflt: number): number;
declare function getSecurityUserId(): string;

@Component({
  components: {
  }
})
export default class InvoiceDelete extends Vue {
    $refs: {
    IngestExplorer: Vue
  }
    private _liqService: LiqService.LiqService;
    private _attachmentService: AttachmentService.AttachmentService;
    private _common: Common;
    
    @Prop() invoice: LiqService.Invoices;

    @Prop() securityLevel: number;

  //#region Data
    private _graphService: MicrosoftGraphService;

    securityLevel_: number = 0;
    token: string = '';
    // path: string = '/drive/items/01YBCTP6S574PL7AWL3VFYDAEFJB7XNS44';
    // workingPath: string = '/drive/items/01YBCTP6TMLGHPBJGKTVF3UA5ULM5ZG6VV';
    selectedFileData: any = {};
    fileNotFound: boolean = true;
    fileOKToDelete: boolean = false;
    action: string = 'Delete';
    ingestionStores = [] as LiqService.InvoiceIngestionStores[];
    selectedIngestionStoreId: number = null;


  //#endregion Data

  //#region Lifecycle
  async created() {
    this._graphService = new MicrosoftGraphService();
    this._common = new Common();
    this._liqService = new LiqService.LiqService();
    this._attachmentService = new AttachmentService.AttachmentService();
    if (this.securityLevel) {
        this.securityLevel_ = this.securityLevel;
    }
    else {
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP),
            0
        );
    }
    await this.fetchIngestionStores();
  }

  async mounted() {
      console.log(this.invoice);
      this.selectedFileData = {};
      if (!!this.invoice && !!this.invoice.FileId){
        this.selectedFileData = await this.getFileData();
        this.fileOKToDelete = await this.checkFileUsage();
      }
  }
  //#endregion Lifecycle

  //#region Computed
    get title() {
        return "Invoice " + this.invoice.InvoiceId
    }
    get selectedIngestionStore() {
        if (this.selectedIngestionStoreId){
            return this._common.getSelectedArrayItem(
                this.ingestionStores,
                this.selectedIngestionStoreId.toString(),
                'Id'
            );
        }
    }

    get fileIsInIngest() {
        if (Object.keys(this.selectedFileData).length > 0 && this.selectedIngestionStoreId && this.selectedFileData.parentReference.id != this.selectedIngestionStore.ItemId) {
            return false;
        }
        else {
            return true;
        }
    }
    get keepLabel() {
        if (this.fileNotFound) {
             return 'No Document to delete';
        }
        if (!this.fileIsInIngest) {
            return 'Return File to Ingest Folder'
        }
        else {
            return 'Keep File In Ingest Folder'
        }
    }
  //#endregion Computed

  //#region Methods
    async fetchIngestionStores() {
        const params = {} as LiqService.GetInvoiceIngestionStoresParameters;
        params.ClientId = this.invoice.ClientId;
        this.ingestionStores = await this._liqService.GetInvoiceIngestionStores(params);
        if (this.ingestionStores.length == 1){
            this.selectedIngestionStoreId = this.ingestionStores[0].Id;
        }
    }

    async checkFileUsage(): Promise<boolean>{
        const params = {} as AttachmentService.CheckFileBeforeDeleteParameters;
        params.DriveId = this.invoice.DriveId;
        params.FileId = this.invoice.FileId;
        params.EntityType = 'Invoice';
        params.EntityId = this.invoice.InvoiceId;
        const response = await this._attachmentService.CheckFileBeforeDelete(params);

        if (response.length > 0){
            return false; //has other usages; don't delete
        }
        else {
            return true; // no other usages, can delete
        }

    }
  async getFileData(){
    const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
    if (!this.invoice.FileId){
        this.fileNotFound=true;
        return {};
    }
    try {
        const response: microsoftgraph.DriveItem = await graphClient
            .api(`/drives/${this.invoice.DriveId}/items/${this.invoice.FileId}`)
            .get();
        if (Object.keys(response).length == 0) {
            this.fileNotFound = true;
        }
        else {
            this.fileNotFound = false;
        }
        return response;
    }
    catch (err) {
        this.fileNotFound=true;
        console.error(err);
    }
    finally {
        if (this.fileNotFound)   {
            this.$notify({
                title: 'Invoice',
                type: 'warning',
                message: 'File not Found'
            });
        }
    }

  }
  async deleteInvoice(){
    
    try {
        await new SmartObject('Invoice', this.invoice.InvoiceId).deleteObject();
        this.$notify.success('Invoice Record deleted.');
        if (!this.fileNotFound){
            await this.handleFile();
        }
    } catch(err) {
        this.$notify.error(err.message);
    }
    this.$emit('deleted');
  }
  async handleFile(){
    const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
    if (this.action == 'Delete'){
        const response = await graphClient
            .api(`/drives/${this.invoice.DriveId}/items/${this.invoice.FileId}`)
            .delete();
        console.log(response);
        // s/b 204
        // log delete: 
        const securityUserId = await getSecurityUserId();
        const changeLog = {
                    ChangeDate: this.$moment().utc()
                    , UserId: securityUserId
                    , Schema: 'liq'
                    , Table: 'Invoice'
                    , EntityId: this.invoice.InvoiceId
                    , Field: 'FileId'
                    , BeforeValue: this.invoice.FileId
                };
        const changeLogId = await new SmartObject('ChangeLog').createObject(changeLog);
        this.$notify.success('Invoice Image deleted.');
    }
    else {
        let file: microsoftgraph.DriveItem;
        const response = await graphClient
            .api(`/drives/${this.invoice.DriveId}/items/${this.invoice.FileId}`)
            .responseType(ResponseType.RAW)
            .patch({
                  parentReference: {
                    //id: "01YBCTP6S574PL7AWL3VFYDAEFJB7XNS44" //"/drive/root:/VAM/APInvoices/Ingest"
                    "id": this.selectedIngestionStore.ItemId
                    , "driveId": this.selectedIngestionStore.DriveId
                  }
                });
        if (response.status == 200){ // success, object returned.
            const responseObj: microsoftgraph.DriveItem = await response.json();
            file = responseObj
        }
        else if (response.status == 202 ){ // accepted.  must monitor location header to get final result.
            const location = response.headers.get("location"); 
            if (location) {
                file = await this._graphService.getDriveItemFromLocation(location);
            }                    
        }

        this.$notify.success(`Invoice Image moved to ${file.parentReference.path.substring(file.parentReference.path.indexOf('/root:/')+6)}.`);
    }
  }
  cancelDelete(){
      this.$emit('canceled');
  }
  //#endregion Methods
}
