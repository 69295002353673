
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import InvestmentEdit from '@/views/Investment/InvestmentEdit.vue';
import ClientSelector from '@/components/form/ClientSelector.vue';
import StockQuote from '@/components/form/StockQuote/StockQuote.vue';
import * as VamService from './../../services/DAL/vamService';
import * as AssetService from './../../services/DAL/assetService';
import { Filter, FilterList } from '../../utils/Filter';
import assign from 'lodash/assign';
import findIndex from 'lodash/findIndex';
import Common from '../../utils/common';
import formatters from '../../utils/formatters';
import ElementUI from 'element-ui';

declare var $;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function tryParseBool(input: any, defaultValue: boolean): boolean;

interface investments extends AssetService.InvestmentList {
    Loading: boolean;
    Valuation: number;
    ValuationAsOf: Date;
    NetShares: number; 
    Quote: number;
}


@Component({
    components: {
        ClientSelector,
        StockQuote,
        InvestmentEdit
    }
})
export default class InvestmentList extends Vue {
    $refs: {
        tblInvestmentList: ElementUI.Table
        , ucInvestmentEdit: HTMLDivElement
    }
    //#region Data
    private _vamService: VamService.VamService;
    private _assetService: AssetService.AssetService;
    private _utilFilter: Filter;
    private _common: Common;
    public formatters: formatters;

    @Prop() readonly clientId!: any;
    @Prop() readonly investmentParentId!: number;
    @Prop() readonly loadInvestmentId!: number;
    @Prop({type: Boolean, default: false}) readonly loadTransactionsTab: boolean;

    clientId_: number = tryParseInt(this.clientId, null);
    investmentParentId_: number = tryParseInt(this.investmentParentId, null);
    loadInvestmentId_: number = tryParseInt(this.loadInvestmentId, null);
    currentOnly: boolean = !this.investmentParentId_;
    newTransactionOnly: boolean = false;
    investments: investments[] = [] as investments[];
    filter: string = '';
    selectedInvestment = {} as AssetService.InvestmentList;
    showInvestmentEditView = false;
    isLoading = false;
    hoveredTickerInvestmentId: number = null;
    
    distinctTickers: FilterList[] = [];
    distinctOwners: FilterList[] = [];
    distinctBankAccounts: FilterList[] = [];
    distinctInvestmentTypes: FilterList[] = [];
    distinctNames: FilterList[] = [];
    distinctClosedText: FilterList[] = [];
    distinctAccountTypes: FilterList[] = [];

    showValuation: boolean = false;
    gotValuations: boolean = false;
    //#endregion Data

    //#region Computed
    get investmentsFiltered(): investments[] {
        return this.investments.filter(investment => {
            const filter_ = (this.filter || '').toLowerCase();
            return (
                (investment.InvestmentId || '').toString().toLowerCase().indexOf(filter_) >
                    -1 ||
                (investment.Name || '').toLowerCase().indexOf(filter_) > -1 ||
                (investment.Ticker || '').toLowerCase().indexOf(filter_) >
                    -1
            );
        });
    }

    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();
        this._assetService = new AssetService.AssetService();
        this._utilFilter = new Filter();
        this._common = new Common();
        this.formatters = new formatters();
    }

    async mounted() {
        if (this.$route && this.$route.meta && this.$route.meta.filterText) this.filter = this.$route.meta.filterText;
        if (this.$route && this.$route.meta && this.$route.meta.currentOnly != undefined) this.currentOnly = this.$route.meta.currentOnly;

        if (!!this.clientId_ || !!this.investmentParentId_ || !!this.loadInvestmentId_) {
            await this.getInvestments();

            if (this.loadInvestmentId_ > 0) {
                const investment = this._common.getSelectedArrayItem(
                    this.investmentsFiltered,
                    this.loadInvestmentId_.toString(),
                    'Id'
                );
                if (investment) {
                    this.selectInvestment(investment, null);
                }
            }
        }
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('clientId_')
    onClientChange(val: number, oldVal: number) {
        if (!(oldVal == undefined && val == null)){
            this.getInvestments();
        }
    }

    @Watch('currentOnly')
    onCurrentOnlyChange(val: boolean, oldVal: boolean) {
        if (!(oldVal == undefined && val == null)){
            this.getInvestments();
        }
    }

    @Watch('newTransactionOnly')
    onNewTransactionOnlyChange(val: boolean, oldVal: boolean) {
        if (!(oldVal == undefined && val == null)){
            this.getInvestments();
        }
    }

    @Watch('showValuation')
    onShowValuation(val: boolean, oldVal: boolean){
        if (!this.gotValuations){
            this.fetchValuations();
        }
    }
    //#endregion Watches
    //#region Methods
    async selectInvestment(investment: investments, event) {
        if (investment && this.$route) {
            this.$route.meta.filterText = this.filter;
            this.$route.meta.currentOnly = this.currentOnly;
            this.$router.push({ name: 'Investment', params: { investmentId: investment.InvestmentId.toString() } }).catch(() => {});
        }
        else {
            this.loadInvestmentTab(investment.InvestmentId); //When loaded from Investment Parent's pop up, there will be no route/router, so just open a new tab, same as the button.  this can be removed when parent is reworked away from SmartControl & Popup
        }
    }

    closeInvestment() {
        this.selectedInvestment = null;
        this.$refs.tblInvestmentList.setCurrentRow(null);
    }

    onDeleteInvestment(InvestmentId) {
        if (InvestmentId) {
            const index = findIndex(this.investments, investment => {
                return investment.Id === InvestmentId;
            });
            if (index > -1) {
                this.investments.splice(index, 1);
            }
        }
        this.closeInvestment();
    }

    async getInvestments() {
        this.isLoading = true;
        this.investments = [] as investments[];
        const params = {} as AssetService.GetInvestmentListParameters;
        params.ClientId = this.clientId_;
        params.CurrentOnly = this.currentOnly;
        params.InvestmentParentId = this.investmentParentId_;
        params.OnlyUsingNewTransactionMethodology = this.newTransactionOnly;
        const investments_ = await this._assetService.GetInvestmentList(params);
        for (const investment of investments_) {
            this.investments.push(
                assign({}, investment, {
                    Loading: false,
                    NetShares: null,
                    Valuation: null,
                    ValuationAsOf: null,
                    Quote: null
                })
            );
        }

        this.distinctTickers = this._utilFilter.getFilterList(this.investmentsFiltered, 'Ticker');
        this.distinctOwners = this._utilFilter.getFilterList(this.investmentsFiltered, 'Owner');
        this.distinctBankAccounts = this._utilFilter.getFilterList(this.investmentsFiltered, 'BankAccountNameNumberMasked');
        this.distinctNames = this._utilFilter.getFilterList(this.investmentsFiltered, 'Name');
        this.distinctInvestmentTypes = this._utilFilter.getFilterList(this.investmentsFiltered, 'InvestmentType');
        this.distinctAccountTypes = this._utilFilter.getFilterList(this.investmentsFiltered, 'AccountType');
        this.distinctClosedText = this._utilFilter.getFilterList(this.investmentsFiltered, 'ClosedText');
        this.isLoading = false;
    }
    
    handleColumnFilter(value, row, column) {
        return this._utilFilter.handleColumnFilter(value, row, column);
    }

    sortTicker(a: any ,b: any){
        return ('' + a.TickerSort).localeCompare(b.TickerSort);
    }
    async fetchValuations(){
        if (this.gotValuations){
            return;
        }
        const SCIValuationParameters = {} as AssetService.GetInvestmentStatisticsSingleCompanyParameters;
        const HFValuationParameters = {} as AssetService.GetInvestmentStatisticsHedgeFundParameters;
        const PEValuationParameters = {} as AssetService.GetInvestmentStatisticsPrivateEquityParameters;
        for (const investment of this.investments) {
            if (investment.ClosedText == 'open'){ //closed will never have value or shares
                investment.Loading = true;
                await this.$nextTick();
                if (investment.InvestmentType == 'Hedge Fund' || investment.InvestmentType == 'Other Fund' || investment.InvestmentType == 'Hedge Fund w/ Commitment') {
                    HFValuationParameters.InvestmentId = investment.InvestmentId;
                    const valuation: AssetService.InvestmentStatisticsHedgeFund[] = await this._assetService.GetInvestmentStatisticsHedgeFund(HFValuationParameters);
                    investment.Valuation = valuation[0].ValuationForSummaryStatistics;
                    const asOf = this.$moment(valuation[0].FMVAsOf);
                    if (asOf._isValid){
                        investment.ValuationAsOf = asOf._d;
                    }
                    investment.Loading = false;
                }
                else if (investment.InvestmentType == 'Private Equity' || investment.InvestmentType == 'PIP' || investment.InvestmentType == 'Venture' ){
                    PEValuationParameters.InvestmentId = investment.InvestmentId;
                    const valuation: AssetService.InvestmentStatisticsPrivateEquity[] = await this._assetService.GetInvestmentStatisticsPrivateEquity(PEValuationParameters);
                    investment.Valuation = valuation[0].ValuationForSummaryStatistics;
                    const asOf = this.$moment(valuation[0].FMVAsOf);
                    if (asOf._isValid){
                        investment.ValuationAsOf = asOf._d;
                    }
                    investment.Loading = false;
                }
                else {
                    SCIValuationParameters.InvestmentId = investment.InvestmentId;
                    const valuation: AssetService.InvestmentStatisticsSingleCompany[] = await this._assetService.GetInvestmentStatisticsSingleCompany(SCIValuationParameters);
                    investment.Valuation = valuation[0].ValuationForSummaryStatistics;
                    const asOf = this.$moment(valuation[0].FMVAsOf);
                    if (asOf._isValid){
                        investment.ValuationAsOf = asOf._d;
                    }
                    investment.NetShares = valuation[0].SharesHeld;
                    investment.Loading = false;
                }
            }
        }
    }

    async loadInvestmentTab(InvestmentId: number){
        const url = `/fs/Assets/Investment/${InvestmentId.toString()}`;
        this.$setSystemStatus('Opened Investment in new window');
        var win = window.open(url, '_blank');
        win.focus();
    }

    async loadInvestmentEdit() {
        this.selectedInvestment = new AssetService.InvestmentList();
        this.$nullifyObjectProps(this.selectedInvestment);
        this.selectedInvestment.InvestmentParentId = this.investmentParentId_;
        this.selectedInvestment.ParentId = this.investmentParentId_;
        this.showInvestmentEditView = true;
    }
    async investmentCreated(newInvestment: AssetService.InvestmentList){
        this.investments.push(
                assign({}, newInvestment, {
                    Loading: false,
                    NetShares: null,
                    Valuation: null,
                    ValuationAsOf: null,
                    Quote: null
                })
        );
    }
    //#endregion Methods
}
