/* tslint:disable:no-null-keyword */

import moment from 'moment';
import accounting from 'accounting';
import numbro from 'numbro';
import toNumber from 'lodash/toNumber';
import isNumber from 'lodash/isNumber';
import Vue from 'vue';
export default class Formatters extends Vue {
  formatterDateTime(row: any, column: any): string {
    if (row[column.property]) {
      return moment(row[column.property]).format('MM/DD/YY H:mm:ss');
    } else {
      return '';
    }
  }
  formatterShortDate(row: any, column: any): string {
    if (row[column.property]) {
      return moment(row[column.property]).format('MM/DD/YY');
    } else {
      return '';
    }
  }
  formatterShortDateTime(row: any, column: any): string {
    if (row[column.property]) {
      return moment(row[column.property]).format('MM/DD/YY H:mm');
    } else {
      return '';
    }
  }
  formatterShortestDate(row: any, column: any): string {
    if (row[column.property]) {
      return moment(row[column.property]).format('M/D/YY');
    } else {
      return '';
    }
  }
  formatterShortestDateTime(row: any, column: any): string {
    if (row[column.property]) {
      return moment(row[column.property]).format('M/D/YY H:mm');
    } else {
      return '';
    }
  }
  formatterShortestDateTimeWithSeconds(row: any, column: any): string {
    if (row[column.property]) {
      return moment(row[column.property]).format('M/D/YY H:mm:ss');
    } else {
      return '';
    }
  }
  formatterCurrency(row: any, column: any): string {
    let out = '';
    out = row[column.property] == null ? null : accounting.formatMoney(row[column.property]);
    return out;
  }
  formatterCurrency0(row: any, column: any): string {
    let out = '';
    out = row[column.property] == null ? null : accounting.formatMoney(row[column.property], {precision: 0});
    return out;
  }
  formatterPercent(row: any, column: any, decimals: number): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property] * 100, decimals || 0) + '%';
    } else {
      return undefined;
    }
  }
  formatterPercent0(row: any, column: any): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property] * 100, 0) + '%';
    } else {
      return undefined;
    }
  }
  formatterPercent1(row: any, column: any): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property] * 100, 1) + '%';
    } else {
      return undefined;
    }
  }
  formatterPercent2(row: any, column: any): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property] * 100, 2) + '%';
    } else {
      return undefined;
    }
  }
  formatterPercentDisplay(row: any, column: any, decimals: number): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property], decimals || 0) + '%';
    } else {
      return undefined;
    }
  }
  formatterPercentDisplay0(row: any, column: any): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property], 0) + '%';
    } else {
      return undefined;
    }
  }
  formatterPercentDisplay1(row: any, column: any): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property], 1) + '%';
    } else {
      return undefined;
    }
  }
  formatterPercentDisplay2(row: any, column: any): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property], 2) + '%';
    } else {
      return undefined;
    }
  }
  formatterNumberGeneric(row: any, column: any): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return numbro(toNumber(row[column.property])).format({
        thousandSeparated: true
      });
    } else {
      return undefined;
    }
  }
  formatterNumber0(row: any, column: any): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property], 0);
    } else {
      return undefined;
    }
  }
  formatterNumber1(row: any, column: any): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property], 1);
    } else {
      return undefined;
    }
  }
  formatterNumber2(row: any, column: any): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property], 2);
    } else {
      return undefined;
    }
  }
  formatterNumber3(row: any, column: any): string {
    if (
      isNumber(toNumber(row[column.property])) &&
      !isNaN(toNumber(row[column.property]))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(row[column.property], 3);
    } else {
      return undefined;
    }
  }
  percent(value: number, decimals: number): string {
    if (
      isNumber(toNumber(value)) &&
      !isNaN(toNumber(value))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      return accounting.formatNumber(value * 100, decimals || 0) + '%';
    } else {
      return undefined;
    }
  }
  currency(value: number, decimals: number): string {
    if (
      isNumber(toNumber(value)) &&
      !isNaN(toNumber(value))
    ) {
      // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
      let out = '';
      out = value == null ? null : accounting.formatMoney(value, {precision: decimals});
      return out;
      } else {
      return undefined;
    }
  }
  formatChangeValue(row, column) {
    if (row[column.property]) {//if it has any value at all...
        if (row[column.property].length >= 6 && row[column.property].length < 25 && moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).isValid()) {
            // > 6 because moment will treat a number like a date; <25 because it will also just pick a date out of a whole sentence.
            if (moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format('H:mm:ss') == '0:00:00') {//time = midnight, so it's really just a date
                return moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format('MM/DD/YY');
            }
            else {
                return moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format('MM/DD/YY H:mm');
            }
        }
        else if ((row.Field == 'Amount' || row.Field == 'Cost' || row.Field == 'Price') && isNumber(toNumber(row[column.property])) ) {
            return numbro(toNumber(row[column.property])).formatCurrency();
        }
        else if ((isNumber(toNumber(row[column.property]))) && !isNaN(toNumber(row[column.property]))) { //toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
            return numbro(toNumber(row[column.property])).format({ thousandSeparated: true});
        }
        else {
            return row[column.property];
        }
    }
    else {
        return '';
    }
}

}
