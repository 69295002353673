
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as TaxService from '../../services/DAL/taxService';
import Common from "../../utils/common";
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component
export default class TaxClassEdit extends Vue {
  $refs: {
    frmTaxClassEdit: ElementUI.Form
  }
  //#region Data
  private _tax_Service: TaxService.TaxService;
  private _common: Common;

  @Prop() taxClass: TaxService.TaxClasses;
  @Prop() onClose: Function;
  @Prop() onSave: Function;
  @Prop() onDelete: Function;
  @Prop() viewType: string;

  taxClass_: TaxService.TaxClasses = new TaxService.TaxClasses();

  get isNew(): boolean {
    return !this.taxClass || !Object.keys(this.taxClass).length;
  }
  get isFormDirty(): boolean {
    return Object.keys((this as any).veeFields).some(
      key => (this as any).veeFields[key].dirty
    );
  }
  //#endregion Data

  //#region Lifecycle
  async created() {
    if (this.taxClass && Object.keys(this.taxClass).length) {
      this.taxClass_ = this.taxClass;
    }
    this._tax_Service = new TaxService.TaxService();
    this._common = new Common();
  }
  //#endregion Lifecycle

  //#region Methods
  async saveForm() {
    this.$refs.frmTaxClassEdit.validate(async valid => {
      if (valid) {
        let message: string;

        if (this.isNew) {
          this.taxClass_.Id = await new SmartObject('TaxClass').createObject(
            this.taxClass_
          );
          message = 'Tax class added.';
        } else {
          await new SmartObject('TaxClass', this.taxClass_.Id).updateObject(
            this.taxClass_
          );
          message = 'Changes saved.';
        }

        this.$notify.success(message);

        if (typeof this.onSave === 'function') {
          await this.onSave(this.taxClass_.Id);
        }

        if (this.isNew) {
          this.closeDialog();
        }
      }
    });
  }

  async deleteTaxClass() {
    if (!this.isNew) {
      try {
        await this.$confirm(
          'This will permanently delete this tax class. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        try {
          await new SmartObject('TaxClass', this.taxClass_.Id).deleteObject();

          this.$notify.success('Tax class deleted.');

          if (typeof this.onDelete === 'function') {
            await this.onDelete(this.taxClass_.Id);
            this.onClose_();
          }
        } catch {
          this.$notify.error('Something went wrong processing your request, please try again.');
        }
      } catch {}
    }
  }

  onClose_() {
    if (typeof this.onClose === 'function') {
      this.onClose();
    }
  }

  closeDialog() {
    this.$emit('close-dialog');
    this.onClose_();
  }
  //#endregion Methods
}
