
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop, Model } from "vue-property-decorator";
import * as TaxService from "../../services/DAL/taxService";

@Component({
    inheritAttrs: false
})
export default class CharitableOrganizationSelector extends Vue {
    //#region Private declarations for Services
    private _taxService: TaxService.TaxService;

    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: string; //ein
    //#endregion Props

    //#region Data
    selectedValue: string = null;
    selectedCharitableOrganization = {} as TaxService.SearchExemptOrganizations;
    loading: boolean = false;

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._taxService = new TaxService.TaxService();
    }
    async mounted() {
        this.selectedValue = this.value;
        this.fetchOrganization();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch("value")
    onChange_value(val: string, oldVal: string) {
        this.selectedValue = val;
    }
    
    //#endregion Watches

    //#region Methods
    async searchCharityNavigator(queryString, cb){
        this.loading = true;
        const params = {} as TaxService.SearchExemptOrganizationsParameters;
        params.SearchTerm = queryString;
        const searchResults = await this._taxService.SearchExemptOrganizations(params);
        cb(searchResults);
        this.loading = false;
    }

    async fetchOrganization(){
        this.loading = true;
        this.selectedCharitableOrganization = {} as TaxService.SearchExemptOrganizations;
        const params = {} as TaxService.SearchExemptOrganizationsParameters;
        params.EIN = this.selectedValue;
        const results = await this._taxService.SearchExemptOrganizations(params);
        if (results && results.length == 1){
            const result = results[0];
            if (result && Object.keys(result).length){
                this.selectedValue = result.EIN;
                this.selectedCharitableOrganization = result;
            }
            this.loading = false;
        }
    }

    selectCharitableOrganization(charitableOrganization){
        this.selectedValue = charitableOrganization.EIN;
        this.selectedCharitableOrganization = charitableOrganization;
        this.$emit('input', this.selectedValue)
        this.$emit('charitable-organization-selected', charitableOrganization);
    }


    //#endregion Methods
}
