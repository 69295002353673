
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop, Model } from "vue-property-decorator";
import * as AssetService from "../../services/DAL/assetService";
import * as TaxService from "../../services/DAL/taxService";

@Component({
    inheritAttrs: false
})
export default class CharitableOrganization extends Vue {
    //#region Private declarations for Services
    private _assetService: AssetService.AssetService;
    private _taxService: TaxService.TaxService;

    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: string;
    //#endregion Props

    //#region Data
    selectedValue: string = null;
    searchString: string = null;
    searchEIN: string = null;
    searchResults = [] as TaxService.SearchExemptOrganizations[];
    organization = {} as any;
    loading: boolean = false;

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this._taxService = new TaxService.TaxService();
    }
    async mounted() {
        this.selectedValue = this.value;
        this.fetchOrganization();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch("value")
    onChange_value(val: string, oldVal: string) {
        this.selectedValue = val;
    }
    
    //#endregion Watches

    //#region Methods
    async searchCharityNavigator(){
        this.loading = true;
        this.searchResults = [] as TaxService.SearchExemptOrganizations[];
        const params = {} as TaxService.SearchExemptOrganizationsParameters;
        params.SearchTerm = this.searchString;
        this.searchResults = await this._taxService.SearchExemptOrganizations(params);
        this.loading = false;
    }
    async searchCharityNavigatorEIN(){
        console.log(this.searchEIN);
        this.loading = true;
        this.searchResults = [] as TaxService.SearchExemptOrganizations[];
        const params = {} as TaxService.SearchExemptOrganizationsParameters;
        params.EIN = this.searchEIN;
        this.searchResults = await this._taxService.SearchExemptOrganizations(params);
        if (this.searchResults && this.searchResults.length == 1){
            this.$emit('charitable-organization-selected', this.searchResults[0]);
            this.$emit('input', this.searchResults[0].EIN);
        }
        this.loading = false;
    }

    async fetchOrganization(){
        // const result: any = await this._charityNavigatorService.getOrganization(this.selectedValue);
        // this.selectedValue = result.EIN;
    }
    selectCharitableOrganization(charitableOrganization){
        this.selectedValue = charitableOrganization;
        this.$emit('input', this.selectedValue)
        this.$emit('charitable-organization-selected', charitableOrganization);
    }


    //#endregion Methods
}
