//js API for Schema attachment
//Auto-generated 03/13/20 15:30
 

var mixinSchema_attachment = {
    methods: {
        /** @description attachment.FixFileId
         * @param {number} storeId - 
         * @param {string} eTag - 
         * @param {string} fileId - 
         */
		 attachment_FixFileId: function (params, onSuccess, onError, async) {
			/*
				this.FixFileId = await this.attachment_FixFileId({
					StoreId: storeId
					, eTag: eTag
					, FileId: fileId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.FixFileId'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Attachment using attachment.GetAttachment
         * @param {number} attachmentId - 
         * @param {string} fileId - 
         */
		, attachment_GetAttachment: function (params, onSuccess, onError, async) {
			/*
				this.Attachment = await this.attachment_GetAttachment({
					AttachmentId: attachmentId
					, FileId: fileId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.GetAttachment'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Attachment using attachment.GetAttachment; Return Single Object
         * @param {number} attachmentId - 
         * @param {string} fileId - 
         */
		, attachment_GetAttachment_Object: function (params, onSuccess, onError, async) {
			/*
				this.Attachment = await this.attachment_GetAttachment_Object({
					AttachmentId: attachmentId
					, FileId: fileId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'attachment.GetAttachment'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get NEW Attachment using columns of attachment.GetAttachment
         */
		, attachment_GetAttachment_New: function () {
			/*
				this.Attachment = await this.attachment_GetAttachment_New();
			*/
					return {
						Id: null
						, FileId: null
						, ClientId: null
						, Dated: null
						, Description: null
						, IngestUser: null
						, IngestDate: null
						, TypeId: null
						, StoreId: null
						, Comment: null
						, EntityType: null
						, CategoryId: null
						, Type: null
						, Deletable: null
						, ShortDateUser: null
						, Maps: null
						, MappingNote: null
						, StoreCategoryId: null
						};
			}
        /** @description Get Attachments using attachment.GetAttachments
         * @param {number} attachmentTypeId - 
         * @param {string} entityType - 
         * @param {number} entityId - 
         * @param {number} storeId - 
         * @param {boolean} unmappedOnly - 
         */
		, attachment_GetAttachments: function (params, onSuccess, onError, async) {
			/*
				this.Attachments = await this.attachment_GetAttachments({
					AttachmentTypeId: attachmentTypeId
					, EntityType: entityType
					, EntityId: entityId
					, StoreId: storeId
					, UnmappedOnly: unmappedOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.GetAttachments'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Categories using attachment.GetCategories
         * @param {string} entityType - 
         * @param {number} storeId - 
         * @param {number} categoryId - 
         */
		, attachment_GetCategories: function (params, onSuccess, onError, async) {
			/*
				this.Categories = await this.attachment_GetCategories({
					EntityType: entityType
					, StoreId: storeId
					, CategoryId: categoryId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.GetCategories'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get EntitiesToMap using attachment.GetEntitiesToMap
         * @param {number} typeId - 
         * @param {string} selectedEntityType - 
         * @param {number} selectedEntityId - 
         * @param {number} clientId - 
         * @param {number} attachmentId - 
         */
		, attachment_GetEntitiesToMap: function (params, onSuccess, onError, async) {
			/*
				this.EntitiesToMap = await this.attachment_GetEntitiesToMap({
					TypeId: typeId
					, SelectedEntityType: selectedEntityType
					, SelectedEntityId: selectedEntityId
					, ClientId: clientId
					, AttachmentId: attachmentId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.GetEntitiesToMap'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Maps using attachment.GetMaps
         * @param {number} attachmentId - 
         */
		, attachment_GetMaps: function (params, onSuccess, onError, async) {
			/*
				this.Maps = await this.attachment_GetMaps({
					AttachmentId: attachmentId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.GetMaps'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get MapsForEntity using attachment.GetMapsForEntity
         * @param {string} entityType - 
         * @param {number} entityId - 
         */
		, attachment_GetMapsForEntity: function (params, onSuccess, onError, async) {
			/*
				this.MapsForEntity = await this.attachment_GetMapsForEntity({
					EntityType: entityType
					, EntityId: entityId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.GetMapsForEntity'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get RecentAttachments using attachment.GetRecentAttachments
         * @param {boolean} dummy - 
         * @param {boolean} unmappedOnly - 
         */
		, attachment_GetRecentAttachments: function (params, onSuccess, onError, async) {
			/*
				this.RecentAttachments = await this.attachment_GetRecentAttachments({
					Dummy: dummy
					, UnmappedOnly: unmappedOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.GetRecentAttachments'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Sites using attachment.GetSites
         */
		, attachment_GetSites: function () {
			/*
				this.Sites = this.attachment_GetSites(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'attachment.GetSites'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get StoreAttachments using attachment.GetStoreAttachments
         * @param {number} storeId - 
         * @param {string} fileId - 
         */
		, attachment_GetStoreAttachments: function (params, onSuccess, onError, async) {
			/*
				this.StoreAttachments = await this.attachment_GetStoreAttachments({
					StoreId: storeId
					, FileId: fileId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.GetStoreAttachments'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get StoreAttachments using attachment.GetStoreAttachments; Return Single Object
         * @param {number} storeId - 
         * @param {string} fileId - 
         */
		, attachment_GetStoreAttachments_Object: function (params, onSuccess, onError, async) {
			/*
				this.StoreAttachments = await this.attachment_GetStoreAttachments_Object({
					StoreId: storeId
					, FileId: fileId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'attachment.GetStoreAttachments'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get StoreCategories using attachment.GetStoreCategories
         * @param {number} storeCategoryId - 
         */
		, attachment_GetStoreCategories: function (params, onSuccess, onError, async) {
			/*
				this.StoreCategories = await this.attachment_GetStoreCategories({
					StoreCategoryId: storeCategoryId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.GetStoreCategories'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Stores using attachment.GetStores
         * @param {string} type - 
         * @param {number} storeId - 
         * @param {number} clientId - 
         * @param {boolean} onlyDefaultEntityStore - 
         * @param {number} categoryId - 
         * @param {string} sourceDriveId - 
         * @param {boolean} onlyAPARStore - 
         * @param {boolean} onlyStageAPARStore - 
         * @param {number} storeCategoryId - 
         */
		, attachment_GetStores: function (params, onSuccess, onError, async) {
			/*
				this.Stores = await this.attachment_GetStores({
					Type: type
					, StoreId: storeId
					, ClientId: clientId
					, OnlyDefaultEntityStore: onlyDefaultEntityStore
					, CategoryId: categoryId
					, SourceDriveId: sourceDriveId
					, OnlyAPARStore: onlyAPARStore
					, OnlyStageAPARStore: onlyStageAPARStore
					, StoreCategoryId: storeCategoryId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.GetStores'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Stores using attachment.GetStores; Return Single Object
         * @param {string} type - 
         * @param {number} storeId - 
         * @param {number} clientId - 
         * @param {boolean} onlyDefaultEntityStore - 
         * @param {number} categoryId - 
         * @param {string} sourceDriveId - 
         * @param {boolean} onlyAPARStore - 
         * @param {boolean} onlyStageAPARStore - 
         * @param {number} storeCategoryId - 
         */
		, attachment_GetStores_Object: function (params, onSuccess, onError, async) {
			/*
				this.Stores = await this.attachment_GetStores_Object({
					Type: type
					, StoreId: storeId
					, ClientId: clientId
					, OnlyDefaultEntityStore: onlyDefaultEntityStore
					, CategoryId: categoryId
					, SourceDriveId: sourceDriveId
					, OnlyAPARStore: onlyAPARStore
					, OnlyStageAPARStore: onlyStageAPARStore
					, StoreCategoryId: storeCategoryId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'attachment.GetStores'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get NEW Stores using columns of attachment.GetStores
         */
		, attachment_GetStores_New: function () {
			/*
				this.Stores = await this.attachment_GetStores_New();
			*/
					return {
						Id: null
						, Name: null
						, BasePath: null
						, Suffix: null
						, DetailSuffix: null
						, Type: null
						, ClientId: null
						, SharePointSiteId: null
						, SharePointListId: null
						, DisplayOrder: null
						, ListPath: null
						, RootPath: null
						, RootPathNoColon: null
						, DrivePath: null
						, ItemPath: null
						, IsIngestionSource: null
						, AllowFileDelete: null
						, CategoryId: null
						, Folder: null
						, DriveId: null
						, StoreCategoryId: null
						, ItemId: null
						};
			}
        /** @description Get Types using attachment.GetTypes
         * @param {number} categoryId - 
         * @param {string} entityType - 
         */
		, attachment_GetTypes: function (params, onSuccess, onError, async) {
			/*
				this.Types = await this.attachment_GetTypes({
					CategoryId: categoryId
					, EntityType: entityType
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'attachment.GetTypes'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
    }
};
export default mixinSchema_attachment;