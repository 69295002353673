//js API for Schema liab
//Auto-generated 08/15/19 10:55
 

var mixinSchema_liab = {
    methods: {
        /** @description Get AnnuityPayments using liab.GetAnnuityPayments
         * @param {number} noteId - 
         */
		 liab_GetAnnuityPayments: function (noteId) {
			/*
				this.AnnuityPayments = this.liab_GetAnnuityPayments(
					null // noteId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liab.GetAnnuityPayments'
			  , params: {
					NoteId: noteId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get CreditExport using liab.GetCreditExport
         * @param {number} clientId - 
         */
		, liab_GetCreditExport: function (clientId) {
			/*
				this.CreditExport = this.liab_GetCreditExport(
					null // clientId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liab.GetCreditExport'
			  , params: {
					ClientId: clientId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NoteCollateral using liab.GetNoteCollateral
         * @param {number} noteId - 
         * @param {date} asOf - 
         */
		, liab_GetNoteCollateral: function (noteId, asOf) {
			/*
				this.NoteCollateral = this.liab_GetNoteCollateral(
					null // noteId
					, null // asOf
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liab.GetNoteCollateral'
			  , params: {
					NoteId: noteId
					, AsOf: asOf
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NotePledge using liab.GetNotePledge
         * @param {number} noteId - 
         * @param {date} asOf - 
         */
		, liab_GetNotePledge: function (noteId, asOf) {
			/*
				this.NotePledge = this.liab_GetNotePledge(
					null // noteId
					, null // asOf
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liab.GetNotePledge'
			  , params: {
					NoteId: noteId
					, AsOf: asOf
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NoteRates using liab.GetNoteRates
         * @param {number} noteId - 
         */
		, liab_GetNoteRates: function (noteId) {
			/*
				this.NoteRates = this.liab_GetNoteRates(
					null // noteId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liab.GetNoteRates'
			  , params: {
					NoteId: noteId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Notes using liab.GetNotes
         * @param {number} clientId - 
         * @param {number} ownerId - 
         * @param {number} noteStatus - 
         * @param {number} noteType - 
         * @param {boolean} includeHolderOwners - 
         * @param {number} noteId - 
         * @param {string} noteTypeList - 
         * @param {number} bankAccountId - 
         */
		, liab_GetNotes: function (params, onSuccess, onError, async) {
			/*
				this.Notes = await this.liab_GetNotes({
					ClientId: clientId
					, OwnerId: ownerId
					, NoteStatus: noteStatus
					, NoteType: noteType
					, IncludeHolderOwners: includeHolderOwners
					, NoteId: noteId
					, NoteTypeList: noteTypeList
					, BankAccountId: bankAccountId
					}
					, true // async (optional)
				);
			*/

		if (typeof params.NoteTypeList === "object" && params.NoteTypeList != null && params.NoteTypeList.length > 0) {
                params.NoteTypeList = params.NoteTypeList.join(',');
            }
			else if (typeof params.NoteTypeList === "object" && params.NoteTypeList != null && params.NoteTypeList.length == 0) {
				params.NoteTypeList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liab.GetNotes'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Notes using liab.GetNotes; Return Single Object
         * @param {number} clientId - 
         * @param {number} ownerId - 
         * @param {number} noteStatus - 
         * @param {number} noteType - 
         * @param {boolean} includeHolderOwners - 
         * @param {number} noteId - 
         * @param {string} noteTypeList - 
         * @param {number} bankAccountId - 
         */
		, liab_GetNotes_Object: function (params, onSuccess, onError, async) {
			/*
				this.Notes = await this.liab_GetNotes_Object({
					ClientId: clientId
					, OwnerId: ownerId
					, NoteStatus: noteStatus
					, NoteType: noteType
					, IncludeHolderOwners: includeHolderOwners
					, NoteId: noteId
					, NoteTypeList: noteTypeList
					, BankAccountId: bankAccountId
					}
					, true // async (optional)
				);
			*/

		if (typeof params.NoteTypeList === "object" && params.NoteTypeList != null && params.NoteTypeList.length > 0) {
                params.NoteTypeList = params.NoteTypeList.join(',');
            }
			else if (typeof params.NoteTypeList === "object" && params.NoteTypeList != null && params.NoteTypeList.length == 0) {
				params.NoteTypeList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'liab.GetNotes'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get NoteSummary using liab.GetNoteSummary
         * @param {number} noteId - 
         * @param {date} asOf - 
         * @param {number} clientId - 
         * @param {number} ownerId - 
         * @param {number} bankId - 
         * @param {number} noteStatus - 
         */
		, liab_GetNoteSummary: function (noteId, asOf, clientId, ownerId, bankId, noteStatus) {
			/*
				this.NoteSummary = this.liab_GetNoteSummary(
					null // noteId
					, null // asOf
					, null // clientId
					, null // ownerId
					, null // bankId
					, null // noteStatus
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liab.GetNoteSummary'
			  , params: {
					NoteId: noteId
					, AsOf: asOf
					, ClientId: clientId
					, OwnerId: ownerId
					, BankId: bankId
					, NoteStatus: noteStatus
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NoteTransactions using liab.GetNoteTransactions
         * @param {number} noteId - 
         * @param {date} asOf - 
         * @param {boolean} ascendingOrder - 
         * @param {number} ownerId - 
         */
		, liab_GetNoteTransactions: function (noteId, asOf, ascendingOrder, ownerId) {
			/*
				this.NoteTransactions = this.liab_GetNoteTransactions(
					null // noteId
					, null // asOf
					, null // ascendingOrder
					, null // ownerId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liab.GetNoteTransactions'
			  , params: {
					NoteId: noteId
					, AsOf: asOf
					, AscendingOrder: ascendingOrder
					, OwnerId: ownerId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NoteTransactionTypes using liab.GetNoteTransactionTypes
         * @param {number} noteId - 
         */
		, liab_GetNoteTransactionTypes: function (noteId) {
			/*
				this.NoteTransactionTypes = this.liab_GetNoteTransactionTypes(
					null // noteId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liab.GetNoteTransactionTypes'
			  , params: {
					NoteId: noteId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NoteTransactionTypeSummary using liab.GetNoteTransactionTypeSummary
         * @param {number} noteId - 
         * @param {date} asOf - 
         * @param {number} ownerId - 
         */
		, liab_GetNoteTransactionTypeSummary: function (noteId, asOf, ownerId) {
			/*
				this.NoteTransactionTypeSummary = this.liab_GetNoteTransactionTypeSummary(
					null // noteId
					, null // asOf
					, null // ownerId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liab.GetNoteTransactionTypeSummary'
			  , params: {
					NoteId: noteId
					, AsOf: asOf
					, OwnerId: ownerId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NoteTypes using liab.GetNoteTypes
         */
		, liab_GetNoteTypes: function () {
			/*
				this.NoteTypes = this.liab_GetNoteTypes(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liab.GetNoteTypes'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get PromissoryNoteSummary using liab.GetPromissoryNoteSummary
         * @param {number} clientId - 
         * @param {number} ownerId - 
         * @param {number} noteStatus - 
         * @param {date} asOf - 
         * @param {number} noteId - 
         */
		, liab_GetPromissoryNoteSummary: function (clientId, ownerId, noteStatus, asOf, noteId) {
			/*
				this.PromissoryNoteSummary = this.liab_GetPromissoryNoteSummary(
					null // clientId
					, null // ownerId
					, null // noteStatus
					, null // asOf
					, null // noteId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liab.GetPromissoryNoteSummary'
			  , params: {
					ClientId: clientId
					, OwnerId: ownerId
					, NoteStatus: noteStatus
					, AsOf: asOf
					, NoteId: noteId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
    }
};
export default mixinSchema_liab;
