//js API for Schema av
//Auto-generated 12/21/18 16:22
 

var mixinSchema_av = {
    methods: {
        /** @description Get Aircraft using av.GetAircraft
         * @param {number} clientId - 
         * @param {number} aircraftId - 
         */
		 av_GetAircraft: function (params, onSuccess, onError, async) {
			/*
				this.Aircraft = await this.av_GetAircraft({
					ClientId: clientId
					, AircraftId: aircraftId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'av.GetAircraft'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get DepreciationCashMethod using av.GetDepreciationCashMethod
         */
		, av_GetDepreciationCashMethod: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetDepreciationCashMethod({
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetDepreciationCashMethod'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get DepreciationImport using av.GetDepreciationImport
         */
		, av_GetDepreciationImport: function (params, onSuccess, onError, async) {
			/*
				this.DepreciationImport = await this.av_GetDepreciationImport({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'av.GetDepreciationImport'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Expenses using av.GetExpenses
         * @param {number} clientId - 
         * @param {number} aircraftId - 
         * @param {date} dateRangeStart - 
         * @param {date} dateRangeEnd - 
         * @param {string} type - 
         * @param {number} tripId - 
         * @param {boolean} requiresAttentionOnly - 
         * @param {number} expenseId - 
         */
		, av_GetExpenses: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetExpenses({
					ClientId: clientId
					, AircraftId: aircraftId
					, DateRangeStart: dateRangeStart
					, DateRangeEnd: dateRangeEnd
					, Type: type
					, TripId: tripId
					, RequiresAttentionOnly: requiresAttentionOnly
					, ExpenseId: expenseId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetExpenses'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ImportActivityJetAviation using av.GetImportActivityJetAviation
         * @param {number} importLogId - 
         */
		, av_GetImportActivityJetAviation: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetImportActivityJetAviation({
					ImportLogId: importLogId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetImportActivityJetAviation'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ImportActivityNetJets using av.GetImportActivityNetJets
         * @param {number} importLogId - 
         */
		, av_GetImportActivityNetJets: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetImportActivityNetJets({
					ImportLogId: importLogId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetImportActivityNetJets'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ImportCPRJetAviation using av.GetImportCPRJetAviation
         * @param {number} importLogId - 
         */
		, av_GetImportCPRJetAviation: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetImportCPRJetAviation({
					ImportLogId: importLogId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetImportCPRJetAviation'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ImportServicesNetJets using av.GetImportServicesNetJets
         * @param {number} importLogId - 
         */
		, av_GetImportServicesNetJets: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetImportServicesNetJets({
					ImportLogId: importLogId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetImportServicesNetJets'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get JetAviationMonthly using av.GetJetAviationMonthly
         * @param {number} year - 
         */
		, av_GetJetAviationMonthly: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetJetAviationMonthly({
					Year: year
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetJetAviationMonthly'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get JetAviationSummary using av.GetJetAviationSummary
         * @param {number} clientId - 
         */
		, av_GetJetAviationSummary: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetJetAviationSummary({
					ClientId: clientId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetJetAviationSummary'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get JetUtilizationSummary using av.GetJetUtilizationSummary
         * @param {number} clientId - 
         */
		, av_GetJetUtilizationSummary: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetJetUtilizationSummary({
					ClientId: clientId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetJetUtilizationSummary'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NetJetsActivityInvoiceReconciliation using av.GetNetJetsActivityInvoiceReconciliation
         * @param {number} clientId - 
         * @param {number} aircraftId - 
         */
		, av_GetNetJetsActivityInvoiceReconciliation: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetNetJetsActivityInvoiceReconciliation({
					ClientId: clientId
					, AircraftId: aircraftId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetNetJetsActivityInvoiceReconciliation'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Prepaid using av.GetPrepaid
         * @param {number} clientId - 
         * @param {number} ownerId - 
         * @param {number} prepaidId - 
         */
		, av_GetPrepaid: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetPrepaid({
					ClientId: clientId
					, OwnerId: ownerId
					, PrepaidId: prepaidId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetPrepaid'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get TripLegDetails using av.GetTripLegDetails
         * @param {number} tripLegId - 
         */
		, av_GetTripLegDetails: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetTripLegDetails({
					TripLegId: tripLegId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetTripLegDetails'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get TripLegManifest using av.GetTripLegManifest
         * @param {string} type - 
         * @param {number} tripLegId - 
         */
		, av_GetTripLegManifest: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetTripLegManifest({
					Type: type
					, TripLegId: tripLegId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetTripLegManifest'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get TripLegs using av.GetTripLegs
         * @param {number} clientId - 
         * @param {number} aircraftId - 
         * @param {date} dateRangeStart - 
         * @param {date} dateRangeEnd - 
         * @param {boolean} forExcelExport - 
         * @param {number} tripId - 
         * @param {boolean} requiresAttentionOnly - 
         */
		, av_GetTripLegs: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetTripLegs({
					ClientId: clientId
					, AircraftId: aircraftId
					, DateRangeStart: dateRangeStart
					, DateRangeEnd: dateRangeEnd
					, ForExcelExport: forExcelExport
					, TripId: tripId
					, RequiresAttentionOnly: requiresAttentionOnly
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetTripLegs'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Trips using av.GetTrips
         * @param {number} clientId - 
         * @param {number} aircraftId - 
         * @param {date} dateRangeStart - 
         * @param {date} dateRangeEnd - 
         */
		, av_GetTrips: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetTrips({
					ClientId: clientId
					, AircraftId: aircraftId
					, DateRangeStart: dateRangeStart
					, DateRangeEnd: dateRangeEnd
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetTrips'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get TripsForExpense using av.GetTripsForExpense
         * @param {number} expenseId - 
         */
		, av_GetTripsForExpense: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_GetTripsForExpense({
					ExpenseId: expenseId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.GetTripsForExpense'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description av.ProcessCashDepreciationImport
         * @param {number} logId - 
         */
		, av_ProcessCashDepreciationImport: function (params, onSuccess, onError, async) {
			/*
				this.ProcessCashDepreciationImport = await this.av_ProcessCashDepreciationImport({
					LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'av.ProcessCashDepreciationImport'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description av.ProcessImportActivityNetJets
         * @param {number} importLogId - 
         */
		, av_ProcessImportActivityNetJets: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_ProcessImportActivityNetJets({
					ImportLogId: importLogId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.ProcessImportActivityNetJets'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description av.ProcessImportCPRJetAviation
         * @param {number} importLogId - 
         */
		, av_ProcessImportCPRJetAviation: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_ProcessImportCPRJetAviation({
					ImportLogId: importLogId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.ProcessImportCPRJetAviation'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description av.ProcessImportJetAviationFlightData
         * @param {number} importLogId - 
         */
		, av_ProcessImportJetAviationFlightData: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_ProcessImportJetAviationFlightData({
					ImportLogId: importLogId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.ProcessImportJetAviationFlightData'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description av.ProcessImportServiceNetJets
         * @param {number} importLogId - 
         */
		, av_ProcessImportServiceNetJets: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.av_ProcessImportServiceNetJets({
					ImportLogId: importLogId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'av.ProcessImportServiceNetJets'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
    }
};
export default mixinSchema_av;
