
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import KeyPersonSelector from '@/components/form/KeyPersonSelector.vue';
import FormattedInput from '@/components/form/FormattedInput.vue';
import * as LiqService from '../../services/DAL/liq_Service';
import * as VamService from '../../services/DAL/vamService';
import Common from '../../utils/common';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare var tryParseBool: any;

@Component({
    components: {
        KeyPersonSelector,
        FormattedInput
    }
})
export default class BankAccountSignerAssigner extends Vue {
    $refs: {
        frmBankAccountSignerAssigner: ElementUI.Form
    }
    //#region Data
    private _liqService: LiqService.LiqService;
    private _vamService: VamService.VamService;
    private _common: Common;

    @Prop() bankAccount: LiqService.Accounts;

    bankAccountSigner = new LiqService.BankAccountSigners();
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();
        this._vamService = new VamService.VamService();
        this._common = new Common();

        this.bankAccountSigner.BankAccountId = this.bankAccount ? this.bankAccount.Id : null;
    }

    mounted() {
        this.$refs.frmBankAccountSignerAssigner.resetFields();
    }
    //#endregion Lifecycle

    //#region Methods
    async saveForm() {
        this.$refs.frmBankAccountSignerAssigner.validate(async valid => {
            if (valid) {
                try {
                    await new SmartObject('BankAccountSigners').createObject(this.bankAccountSigner);

                    this.$notify.success('Signer added.');

                    this.closeDialog();
                } catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
            }
        });
    }

    closeDialog() {
        this.$emit('close-dialog');
    }
    //#endregion Methods
}
