
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import * as AssetService from '@/services/DAL/assetService';
    import EventLogList from '@/views/Event/EventLogList.vue';


    @Component({
        components: {
            EventLogList
        }
    })
    export default class ProcessLookThrough extends Vue {

    //#region Private declarations for Services
    private _assetService: AssetService.AssetService;
    //#endregion Private declarations for Services

    //#region Props
    //#endregion Props

    //#region Data
    queueRecord = {} as AssetService.QueueProcessLookThrough;
    loading = false;
    showEventList = true;
    //#endregion Data

    //#region Lifecycle
    created() {
        this._assetService = new AssetService.AssetService();
    }
    //#endregion Lifecycle

    //#region Watches
    //#endregion Watches

    //#region Computed
    //#endregion Computed

    //#region Methods
    async queueLookThrough(){
        try {
            this.loading = true;
            const params = {} as AssetService.QueueProcessLookThroughParameters;
            const result = await this._assetService.QueueProcessLookThrough(params);
            this.queueRecord = result[0];
            console.log(result);
            this.$notify.info(`Process queued (${this.queueRecord.LogId})`);
            this.showEventList = false;
            await this.$nextTick();
            this.showEventList = true;
        } catch (err) {
            console.error(err);
        }
        this.loading = false;
    }
    //#endregion Methods

    }
