<template>
    <div>
        <el-row>
            <el-col>
                <h2>Method setting:</h2>
                <el-table v-if="DepreciationCashMethodList[0]"
                          ref="refDepreciationCashMethodList"
                          :data="DepreciationCashMethodList"
                          style="width: 100%;"
                          stripe
                          row-key="Id">
                    <el-table-column label="Client"
                                     min-width=""
                                     sortable
                                     prop="Client">
                        <template slot-scope="scope">
                            <span>{{scope.row.Client }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Aircraft"
                                     min-width=""
                                     sortable
                                     prop="Aircraft">
                        <template slot-scope="scope">
                            <span>{{scope.row.Aircraft }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Method"
                                     min-width=""
                                     sortable
                                     prop="Method">
                        <template slot-scope="scope">
                            <el-radio v-model="scope.row.Method" label="LTCG">LTCG</el-radio>
                            <el-radio v-model="scope.row.Method" label="Ordinary">Ordinary</el-radio>
                            <el-button v-if="showUpdateButton" type="success" size="mini" v-show="scope.row.Method != scope.row.OriginalMethod" @click="UpdateDepreciationMethod(scope.$index, scope.row)">Save</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="Last Change"
                                     min-width=""
                                     sortable
                                     prop="LastChange">
                        <template slot-scope="scope">
                            <span>{{scope.row.LastChange }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <hr />
        <el-row id="model-list">
            <h2>Import from Tax Model:</h2>
            <el-table v-if="modelConfig[0]"
                      :data="modelConfig"
                      style="width: 100%;"
                      stripe
                      >
                <el-table-column label="Client"
                                 prop="Client"
                                 min-width="90px">
                </el-table-column>
                <el-table-column label="Aircraft"
                                 prop="Aircraft"
                                 min-width="100px">
                </el-table-column>
                <el-table-column label="Description"
                                 prop="Description"
                                 min-width="200px">
                </el-table-column>
                <el-table-column label="Type"
                                 prop="TaxType"
                                 min-width="100px">
                </el-table-column>
                <el-table-column label="Current"
                                 align="center"
                                 min-width="100px"
                                 prop="Value"
                                 :formatter="formatterCurrency">
                </el-table-column>
                <el-table-column label="Tax Model" align="right" min-width="100px">
                    <template slot-scope="props">
                        <div v-loading.body="loading" v-bind:style="{ backgroundColor: highlightDifference(props.row.Value, props.row.NewValue) }">
                            <currency-label :value="props.row.NewValue"></currency-label>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="Created"
                                 align="right"
                                 min-width="150px"
                                 prop="Created">
                </el-table-column>
            </el-table>
            <el-button v-if="showUpdateButton" type="success" @click="UpdateDepreciationImport">Update</el-button>
        </el-row>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinAv from './../DAL/mixinSchema_av'
    import mixinUtil from './../DAL/mixinSchema_util'
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';
    import util from './../VueCommon/Utility.js'
    import CurrencyLabel from './../VueCommon/CurrencyLabel.vue'

    export default Vue.extend({
        name: 'JetDepreciationImport'
        , mixins: [mixinAv, util, mixinUtil]
        , components: {
            'currency-label': CurrencyLabel
        }
        , data() {
            return {
                  token: ''
                , showFileName: false
                , model: []
                , modelConfig: []
                , showUpdateButton: true
                , loading: false
                , DepreciationCashMethodList: this.av_GetDepreciationCashMethod()
                , graphService: {}
            }
        }
        , created: async function () {
            this.graphService = new MicrosoftGraphService();
            this.modelConfig = await this.av_GetDepreciationImport();
            this.fetchModel();
        }
        , watch: {
        }
        , methods: {
            fetchModel: async function () {
                this.loading = true;
                const el = this;
                const graphClient = await this.graphService.getGraphClient();
                const context = {
                    itemCount: 0
                    , graphClient: graphClient
                }
                async function getModel(pathToModel, graphClient) {
                    try {
                        const response = await graphClient
                                .api(pathToModel)
                                .get();
                        return response.values[0][0];
                    }
                    catch (e){
                        console.error(e);
                        el.$alert(e.message, 'Error');
                    }
                }

                function importItem(item, context) {
                    return new Promise((resolve, reject) => {
                        var model = getModel(item.Path, context.graphClient)
                            .then(function (response) {
                                item.NewValue = response;
                                console.log(response);
                                context.itemCount++;
                                resolve();
                            });
                    });
                }
                this.forEachPromise(this.modelConfig, importItem, context).then(() => {
                    this.loading = false;
                });


            }
            , UpdateDepreciationImport: async function () {
                this.loading = true;
                this.showUpdateButton = false;

                async function saveItem(item, logId) {
                    //return new Promise((resolve, reject) => {
                        const importItem = {
                            LogId: logId
                            , ConfigurationId: item.ConfigurationId 
                            , Value: item.NewValue
                        }
                        const so = new SmartObject('DepreciationImport');
                        const newId = await so.createObject(importItem);
                        item.Id = newId;
                        return (newId);
                    //});
                }

                var logId = 0;
                var t = this;
                const logEvent = await this.util_LogEventSelect_Object({
                        Database: 'VisiumDB'
                        , Schema: 'av'
                        , Caller: 'DepreciationImport'
                        , Event: 'Import'
					}
					, true // async (optional)
                );
                logId = logEvent.LogId;
                this.forEachPromise(t.modelConfig, saveItem, logId).then(async () => {
                    this.modelConfig = await this.av_GetDepreciationImport({});
                    this.$notify.success('Imported');
                    await this.av_ProcessCashDepreciationImport({
                        LogId: logId
                    });
                    this.$notify.success('Cash value of depreciation updated');
                    this.loading = false;
                });
            }
            , highlightDifference: function (a, b) {
                return (accounting.toFixed(a, 2) != accounting.toFixed(b, 2)) ? 'Yellow' : '';
            }
            , async UpdateDepreciationMethod(index, row) {
                //console.log(index, row);
                const so = new SmartObject('DepreciationCashMethod', row.Id);
                const success = await so.updateObject(row);
                if (success) {
                    if (success !== row.Id) {
                        row.Id = success;
                    }
                    row.OriginalMethod = row.Method; //reset, so Update Button goes away.
                    row.LastChange = moment().format('MMMM Do YYYY, h:mm:ss a');
                    this.$notify.success('Method Updated');
                }
            }
        }
    })
</script>
