
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop, Model } from "vue-property-decorator";
import * as AvService from "../../services/DAL/avService";
import Common from '../../utils/common';

@Component({
  inheritAttrs: false
})
export default class AircraftSelector extends Vue {
  //#region Private declarations for Services
  private _avService: AvService.AvService;
  private _common: Common;
  //#endregion Private declarations for Services

  //#region Props
  @Prop()
  value: number;
  @Prop()
  clientId: number;
  @Prop({
    default: false
  })
  requireClientId: boolean;
  //#endregion Props

  //#region Data
  aircrafts = [] as AvService.Aircraft[];
  selectedValue: number = null;
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._avService = new AvService.AvService();
    this._common = new Common();
  }
  async mounted() {
    this.selectedValue = this.value;
    this.fetchAircrafts();
  }
  //#endregion Lifecycle

  //#region Watches
  @Watch('value')
  onAircraftChange(val: number, oldVal: number) {
    this.selectedValue = val;

    if (this.aircrafts.length) {
        let selectedObject: AvService.Aircraft = new AvService.Aircraft();
        if (this.selectedValue) {
            selectedObject = this._common.getSelectedArrayItem(this.aircrafts, this.selectedValue.toString(), 'AircraftId');
        }
        this.$emit('value-set', selectedObject);
    }
  }

  @Watch('clientId')
  onClientChange(val: number, oldVal: number) {
    this.fetchAircrafts();
  }

  //#endregion Watches

  //#region Methods
  async fetchAircrafts() {
    this.aircrafts = [];
    if (!this.requireClientId || !!this.clientId) {
      const params = {} as AvService.GetAircraftParameters;
      params.ClientId = this.clientId;
      this.aircrafts = await this._avService.GetAircraft(params);
    }
  }
  //#endregion Methods
}
