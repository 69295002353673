
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Ref} from 'vue-property-decorator';
import InstrumentTransactionsList from '@/views/DebtInstrument/InstrumentTransactionsList.vue';
import InstrumentEdit from '@/views/DebtInstrument/InstrumentEdit.vue';
import CommentLogList from '@/components/other/tsCommentLogList.vue'
import EntityJournalVouchers from '@/views/GeneralLedger/EntityJournalVouchers.vue'
import AttachmentList from '@/js/Attachments/AttachmentList.vue';
import ChangeLogList from '@/js/ChangeLog/ChangeLogList.vue';
import * as DebtService from '../../services/DAL/debtService';
import Common from '../../utils/common';

import ElementUI from 'element-ui';
import VueRouter from 'vue-router';

declare var $;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function tryParseBool(input: any, defaultValue: boolean): boolean;
declare function getStoredSecurityLevel(Id: number): number;

@Component({
    components: {
        InstrumentTransactionsList
        , InstrumentEdit
        , AttachmentList
        , ChangeLogList
        , EntityJournalVouchers
        , CommentLogList
    }
    ,beforeRouteEnter(to, from, next){
        next(vm => {
            // console.log('from: ', from);
            vm.$data.fromRoute = from;
        });
    }

})
export default class Instrument extends Vue {
    @Ref() readonly refInstrumentTabs!: ElementUI.Tabs;
    //#region Data
    private _debtService: DebtService.DebtService;
    private common: Common;

    @Prop()
    instrumentId: number;
    @Prop()
    loadTransactionId: number;
    @Prop({ type: Number, default: null })
    securityLevel!: number;
    @Prop({ type: Object })
    instrument: DebtService.Instruments;
    @Prop({type: String, default: 'card'})
    viewType: string;
    

    instrumentId_: number = null;
    loadTransactionId_: number = null;
    securityLevel_: number = this.securityLevel;
    showAttachmentList: boolean = false;
    showChangeLogList: boolean = false;
    showAccounting: boolean = false;
    showConversion: boolean = false;
    showTransactionsList: boolean = false;
    showTranches: boolean = true;
    selectedTab: string = '0';
    instrument_ = {} as DebtService.Instruments;
    loading: boolean = false;
    showInstrumentEdit = false;
    fromRoute!: any;
    showStatistics = true;
    instrumentTypes = [] as DebtService.InstrumentTypes[];
    //#endregion Data

    //#region Computed
    
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._debtService = new DebtService.DebtService();
        this.common = new Common();
        if (this.loadTransactionId) {
            this.loadTransactionId_ = this.loadTransactionId;
            this.showTransactionsList = true;
        }
        if (this.securityLevel_ === null) {
            this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDebts), 0);
        }
        if (this.instrumentId) this.instrumentId_= this.instrumentId;

        if (this.instrument && Object.keys(this.instrument).length) {
            this.instrument_ = this.instrument;
            this.instrumentId_ = this.instrument.Id;
        }
        else {
            this.fetchInstrument();
        }
        this.fetchInstrumentTypes();
    }
    destroyed(){
    }

    async mounted() {
        if (this.loadTransactionId_){
            this.selectedTab = '0';
        }
        else if (this.$route.meta && this.$route.meta.selectedTab){
            this.selectedTab = this.$route.meta.selectedTab;
        }
    }
    //#endregion Lifecycle

    //#region Watches

    //#endregion Watches

    get instrumentType(): DebtService.InstrumentTypes{
        return this.common.getArrayItemFromConditions(
            this.instrumentTypes,
            { Id: this.instrument_.TypeId }
        ) as DebtService.InstrumentTypes;
    }
    get amountLabel(): string {
        console.log(this.instrumentType);
        if (this.instrumentType){
            if (this.instrumentType.IsInvestment || this.instrumentType.IsPromissoryNote) return 'Initial Amount';
            if (this.instrumentType.IsRevolver) return undefined;
            else return 'Amount';
        }
    }
    //#region Methods
    async fetchInstrument(){
        this.instrument_ = {} as DebtService.Instruments;
        if (this.instrumentId_ == null){
            console.error('InstrumentId_ is null');
            return;
        }
        this.loading = true;
        const params = {} as DebtService.GetInstrumentsParameters;
        params.InstrumentId = this.instrumentId_;
        const instruments = await this._debtService.GetInstruments(params);
        if (!!instruments && instruments.length == 1){
            this.instrument_ = instruments[0];
        }
        this.loading = false;
    }
    async fetchInstrumentTypes(){
        const params = {} as DebtService.GetInstrumentTypesParameters;
        params.IsInvestment = null;
        this.instrumentTypes = await this._debtService.GetInstrumentTypes(params);
    }
    async onTabClick(tab) {
        this.$route.meta.selectedTab = tab.name;
        switch (tab.label) {
            case 'Valuation':
                var $ucValuationList = $(this.refInstrumentTabs.$el).find('#ucValuationList');
                if (!$ucValuationList.hasClass('loaded')) {
                    $ucValuationList.fetchSmartControl({
                        params: {
                            EntityType: 'Instrument'
                            , EntityId: this.instrumentId_
                            , CanEdit: this.securityLevel_ >= this.$namedKey.SecurityLevel.Admin
                        }
                        , collapsible: false
                        , showSpinner: true
                    });
                    $ucValuationList.addClass('loaded');
                }
                break;
            case 'Net Transactions':
                var $ucNetTransactionList = $(this.refInstrumentTabs.$el).find('#ucNetTransactionList');
                if (!$ucNetTransactionList.hasClass('loaded')) {
                    $ucNetTransactionList.fetchSmartControl({
                        params: {
                            InstrumentId: this.instrumentId_
                        }
                        , collapsible: false
                        , showSpinner: true
                    });
                    $ucNetTransactionList.addClass('loaded');
                }
                break;
            case 'Transactions':
                this.showTransactionsList = false;
                await this.$nextTick();
                this.showTransactionsList = true;
                break;
            case 'Accounting':
                this.showAccounting = false;
                await this.$nextTick();
                this.showAccounting = true;
                break;
            case 'Tranches':
                this.showTranches = false;
                await this.$nextTick();
                this.showTranches = true;
                break;
            case 'Documents':
                this.showAttachmentList = false;
                await this.$nextTick();
                this.showAttachmentList = true;
                break;
            case 'Change Log':
                this.showChangeLogList = false;
                await this.$nextTick();
                this.showChangeLogList = true;
                break;
            case 'Conversion':
                this.showConversion = false;
                await this.$nextTick();
                this.showConversion = true;
                break;
        }
    }
    conversionUpdated(instrument: DebtService.Instruments){
        this.instrument_ = instrument;
    }
    async instrumentSaved(instrument: DebtService.Instruments){
        this.showInstrumentEdit = false;
        await this.fetchInstrument();
    }
    async instrumentDeleted(){
        this.showInstrumentEdit = false;
        this.$emit('deleted');
        if (this.fromRoute && this.fromRoute.fullPath == '/debts/Instruments') this.$router.go(-1); //back to instrumentList
        // ToDo: other possible options we should consider returning to rather than Welcome screen?
        else this.$router.push('/welcome'); //return to home screen
    }
    async refreshStatistics(){
        this.showStatistics = false;
        await this.$nextTick();
        this.showStatistics = true;
    }

    //#endregion Methods
}
