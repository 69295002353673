//js API for Schema balancesheet
//Auto-generated 03/19/19 18:04
 

var mixinSchema_balancesheet = {
    methods: {
        /** @description Get AssetAllocation using BalanceSheet.GetAssetAllocation
         * @param {number} balanceSheetLogId - 
         * @param {number} clientId - 
         * @param {number} configurationId - 
         */
		 BalanceSheet_GetAssetAllocation: function (balanceSheetLogId, clientId, configurationId) {
			/*
				this.AssetAllocation = this.BalanceSheet_GetAssetAllocation(
					null // balanceSheetLogId
					, null // clientId
					, null // configurationId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'BalanceSheet.GetAssetAllocation'
			  , params: {
					BalanceSheetLogId: balanceSheetLogId
					, ClientId: clientId
					, ConfigurationId: configurationId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Categories using BalanceSheet.GetCategories
         * @param {number} clientId - 
         * @param {boolean} asset - 
         * @param {boolean} liability - 
         */
		, BalanceSheet_GetCategories: function (params, onSuccess, onError, async) {
			/*
				this.Categories = await this.BalanceSheet_GetCategories({
					ClientId: clientId
					, Asset: asset
					, Liability: liability
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'BalanceSheet.GetCategories'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get CategoriesV2 using BalanceSheet.GetCategoriesV2
         * @param {number} superCategoryId - 
         */
		, BalanceSheet_GetCategoriesV2: function (superCategoryId) {
			/*
				this.CategoriesV2 = this.BalanceSheet_GetCategoriesV2(
					null // superCategoryId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'BalanceSheet.GetCategoriesV2'
			  , params: {
					SuperCategoryId: superCategoryId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get HistoryLogId using BalanceSheet.GetHistoryLogId
         * @param {date} asOf - 
         */
		, BalanceSheet_GetHistoryLogId: function (params, onSuccess, onError, async) {
			/*
				this.HistoryLogId = await this.BalanceSheet_GetHistoryLogId({
					AsOf: asOf
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'BalanceSheet.GetHistoryLogId'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get HistoryPeriod using BalanceSheet.GetHistoryPeriod
         */
		, BalanceSheet_GetHistoryPeriod: function () {
			/*
				this.HistoryPeriod = this.BalanceSheet_GetHistoryPeriod(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'BalanceSheet.GetHistoryPeriod'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get LogIds using BalanceSheet.GetLogIds
         * @param {string} database - 
         * @param {string} schema - 
         * @param {string} caller - 
         * @param {string} event - 
         * @param {string} keyName - 
         * @param {number} keyInt - 
         * @param {string} keyVarChar - 
         * @param {date} keyDate - 
         * @param {boolean} completedOnly - 
         * @param {number} logId - 
         * @param {boolean} includeIncompleteRegardlessOfMilliseconds - 
         * @param {number} minMilliseconds - 
         * @param {number} maxRows - 
         * @param {number} eventId - 
         */
		, BalanceSheet_GetLogIds: function (database, schema, caller, event, keyName, keyInt, keyVarChar, keyDate, completedOnly, logId, includeIncompleteRegardlessOfMilliseconds, minMilliseconds, maxRows, eventId) {
			/*
				this.LogIds = this.BalanceSheet_GetLogIds(
					null // database
					, null // schema
					, null // caller
					, null // event
					, null // keyName
					, null // keyInt
					, null // keyVarChar
					, null // keyDate
					, null // completedOnly
					, null // logId
					, null // includeIncompleteRegardlessOfMilliseconds
					, null // minMilliseconds
					, null // maxRows
					, null // eventId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'BalanceSheet.GetLogIds'
			  , params: {
					Database: database
					, Schema: schema
					, Caller: caller
					, Event: event
					, KeyName: keyName
					, KeyInt: keyInt
					, KeyVarChar: keyVarChar
					, KeyDate: keyDate
					, CompletedOnly: completedOnly
					, LogId: logId
					, IncludeIncompleteRegardlessOfMilliseconds: includeIncompleteRegardlessOfMilliseconds
					, MinMilliseconds: minMilliseconds
					, MaxRows: maxRows
					, EventId: eventId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get NetWorthBarChart using BalanceSheet.GetNetWorthBarChart
         * @param {string} logId - 
         * @param {number} configurationId - 
         */
		, BalanceSheet_GetNetWorthBarChart: function (logId, configurationId) {
			/*
				this.NetWorthBarChart = this.BalanceSheet_GetNetWorthBarChart(
					null // logId
					, null // configurationId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'BalanceSheet.GetNetWorthBarChart'
			  , params: {
					LogId: logId
					, ConfigurationId: configurationId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ReportDrillDetail using BalanceSheet.GetReportDrillDetail
         * @param {number} balanceSheetLogId - 
         * @param {number} configurationId - 
         * @param {string} type - 
         * @param {number} categoryId - 
         * @param {number} ownerId - 
         * @param {string} description - 
         * @param {string} superCategory - 
         * @param {string} category - 
         * @param {string} subCategory - 
         * @param {number} comparisonLogId - 
         */
		, BalanceSheet_GetReportDrillDetail: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.BalanceSheet_GetReportDrillDetail({
					BalanceSheetLogId: balanceSheetLogId
					, ConfigurationId: configurationId
					, Type: type
					, CategoryId: categoryId
					, OwnerId: ownerId
					, Description: description
					, SuperCategory: superCategory
					, Category: category
					, SubCategory: subCategory
					, ComparisonLogId: comparisonLogId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'BalanceSheet.GetReportDrillDetail'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SubCategories using BalanceSheet.GetSubCategories
         * @param {number} clientId - 
         * @param {boolean} asset - 
         * @param {boolean} liability - 
         */
		, BalanceSheet_GetSubCategories: function (params, onSuccess, onError, async) {
			/*
				this.SubCategories = await this.BalanceSheet_GetSubCategories({
					ClientId: clientId
					, Asset: asset
					, Liability: liability
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'BalanceSheet.GetSubCategories'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get SuperCategories using BalanceSheet.GetSuperCategories
         * @param {number} clientId - 
         */
		, BalanceSheet_GetSuperCategories: function (clientId) {
			/*
				this.SuperCategories = this.BalanceSheet_GetSuperCategories(
					null // clientId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'BalanceSheet.GetSuperCategories'
			  , params: {
					ClientId: clientId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description balancesheet.ProcessHistory
         * @param {date} asOf - 
         * @param {boolean} ignoreCheck - 
         * @param {number} logId - 
         */
		, balancesheet_ProcessHistory: function (params, onSuccess, onError, async) {
			/*
				this.ProcessHistory = await this.balancesheet_ProcessHistory({
					AsOf: asOf
					, IgnoreCheck: ignoreCheck
					, LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'balancesheet.ProcessHistory'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
    }
};
export default mixinSchema_balancesheet;
