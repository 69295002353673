
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Fullscreen extends Vue {
  closed(){
    this.$router.push('/welcome');
  }
}
