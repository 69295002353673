
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as VamService from '../../../services/DAL/vamService';
declare var SmartObject: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: number, deflt: number): number;

@Component
export default class DECert extends Vue {
  //#region Private Declarations
  private _vam_Service: VamService.VamService;
  //#endregion Private Declarations

  //#region Props
  @Prop({
      required: true,
      type: Object
    }) entity: VamService.OwnersV2;
  @Prop({
      default: true
    }) highlightFields: boolean;
  //#endregion Props

  //#region Data
  securityLevel: number = 0;

  //#endregion Data

  //#region Computed
  get fieldStyle(){
      if (this.highlightFields){
          return {'background-color': 'yellow'};
      }
  }
    get authorizedParties(){
      return JSON.parse(this.$objectPropertyIfExists(this.entity, 'AuthorizedPartiesList'));
  }
  get manager(): any{
      if (this.authorizedParties && this.authorizedParties.length){
          const authorizedParties = this.authorizedParties.filter(
            item => item.RoleText == 'Manager'
        );
        return authorizedParties[0];
      }
  }
//#endregion Computed

  //#region Lifecycle
  created() {
    this.securityLevel = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageOwners),
      0
    );
  }
  //#endregion Lifecycle

  //#region Watches
  //#endregion Watches

  //#region Methods
  //#endregion Methods
}
