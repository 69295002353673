
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as LiqService from '../../services/DAL/liq_Service';

@Component({
    inheritAttrs: false
})
export default class AgreementTypeSelector extends Vue {
    //#region Private declarations for Services
    private _liqService: LiqService.LiqService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: number;
    //#endregion Props

    //#region Data
    agreementTypes = [] as LiqService.AgreementTypes[];
    selectedValue: number = null;
    //#endregion Data

   //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchAgreementTypes();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
    }
    //#endregion Watches

    //#region Methods
    async fetchAgreementTypes() {
        this.agreementTypes = [];
        this.agreementTypes = await this._liqService.GetAgreementTypes();
        this.$emit('options-fetched', this.agreementTypes);
        if (this.agreementTypes.length === 1){
            this.selectedValue = this.agreementTypes[0].Id;
            this.$emit('single-option-available', this.selectedValue);
            if (this.selectedValue !== this.value) {
                this.$emit('input', this.selectedValue);
            }
        }
    }
    //#endregion Methods
}
