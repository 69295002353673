
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ClientSelector from "@/components/form/ClientSelector.vue";
import AircraftSelector from "@/components/form/AircraftSelector.vue";
import TripLegEdit from "@/views/Aviation/TripLegEdit.vue";
import * as AvService from "./../../services/DAL/avService";
import * as VamService from '../../services/DAL/vamService';
import formatters from "../../utils/formatters";
import dayjs from "dayjs";
import find from "lodash/find";
import findIndex from 'lodash/findIndex';
import ElementUI from "element-ui";

declare var $: any;
declare var SmartObject: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
  components: {
    ClientSelector,
    AircraftSelector,
    TripLegEdit
  }
})
export default class TripLegEditList extends Vue {
  $refs: {
    refTripLegs: ElementUI.Table
    }
  //#region Private declarations for Services
  private _avService: AvService.AvService;
    private _vamService: VamService.VamService;
  public formatters: formatters;
  //#endregion Private declarations for Services

  //#region Data
  tripLegs = [] as AvService.TripLegs[];
  tripLegManifests: Object = {};
  selectedClientId: number = null;
  selectedClient = {} as VamService.UserClients
  selectedAircraftId: number = null;
  selectedAircraft =  {} as AvService.Aircraft;
  selectedDateRange = [];
  requiresAttentionOnly: boolean = true;
  isLoading: boolean = false;
  selectedTripLegForEdit = {} as AvService.TripLegs;
  selectedTripLegForPassengers = {} as AvService.TripLegs;
  showTripLegEdit: boolean = false;
  showTripLegPassengers: boolean = false;
  isHighlighting: boolean = false;
  $rowToCopyFrom: any = $();
  $rowsToCopyTo: any = $();
  reasonToCopy: string = null;
  clientIdIsMissing: boolean = false;
  forExcelExport: boolean = false;
  securityLevel_: number = null;
  datePickerOptions = {
                        shortcuts: [
                            {
                                text: 'This Year',
                                onClick(picker) {
                                  const start = dayjs().startOf("year");
                                  const end = dayjs().endOf("year");
                                    picker.$emit('pick', [start.toDate(), end.toDate()]);
                                }
                            }
                            , {
                                text: 'YTD',
                                onClick(picker) {
                                  const start = dayjs().startOf("year");
                                  const end = dayjs();
                                    picker.$emit('pick', [start.toDate(), end.toDate()]);
                                }
                            }
                            , {
                                text: 'This Month',
                                onClick(picker) {
                                  const start = dayjs().startOf("month");
                                  const end = dayjs().endOf("month");
                                    picker.$emit('pick', [start.toDate(), end.toDate()]);
                                }
                            }
                            , {
                                text: 'MTD',
                                onClick(picker) {
                                  const start = dayjs().startOf("month");
                                  const end = dayjs();
                                    picker.$emit('pick', [start.toDate(), end.toDate()]);
                                }
                            }
                            , {
                              text: "LTM",
                              onClick(picker) {
                                  const start = dayjs().subtract(1, "year").add(1, "day");
                                  const end = dayjs();
                                  picker.$emit("pick", [start.toDate(), end.toDate()]);
                              }
                            }
                            , {
                                text: 'Last Year',
                                onClick(picker) {
                                  const start = dayjs().subtract(1, "year").startOf("year");
                                  const end = dayjs().subtract(1, "year").endOf("year");
                                  picker.$emit('pick', [start.toDate(), end.toDate()]);
                                }
                            }
                            , {
                                text: 'Last Month',
                                onClick(picker) {
                                  const start = dayjs().subtract(1, "month").startOf("month");
                                  const end = dayjs().subtract(1, "month").endOf("month");
                                  picker.$emit('pick', [start.toDate(), end.toDate()]);
                                }
                            }
                            , {
                                text: 'Last Week',
                                onClick(picker) {
                                  const start = dayjs().subtract(1, "week").startOf("week");
                                  const end = dayjs().subtract(1, "week").endOf("week");
                                  picker.$emit('pick', [start.toDate(), end.toDate()]);
                                }
                            }
                            , {
                                text: 'This Week',
                                onClick(picker) {
                                  const start = dayjs().startOf("week");
                                  const end = dayjs().endOf("week");
                                  picker.$emit('pick', [start.toDate(), end.toDate()]);
                                }
                            }

                        ]
                      }

  //#endregion Data

  //#region Lifecycle
  async created() {
    this._avService = new AvService.AvService();
    this._vamService = new VamService.VamService();
    this.formatters = new formatters();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageTrips),
      0
    );
    
    this.resetSearch();
  }
  //#endregion Lifecycle

  //#region Computed
  get passengerDialogTitle(): string {
    return `Passengers for Trip Leg ${
      this.selectedTripLegForPassengers.LegId
    } - ${this.selectedTripLegForPassengers.FromCity} to ${
      this.selectedTripLegForPassengers.ToCity
    }, ${this.$moment(
      this.selectedTripLegForPassengers.DepartureTime
    ).format("M/D/YY")}`;
  }

  get totalHours(){
     if (this.tripLegs && this.tripLegs.length){
        return this.tripLegs.reduce(function(a, b) {
                  return a + b.LegConsumedHours;
              }, 0);
     }
  }
  //#endregion Computed

  //#region Watches
  @Watch('selectedClientId')
  onClientChange(val: number, oldVal: number) {
    if (this.selectedClientId > 0) {
      this.clientIdIsMissing = false;
    }
  }
  //#endregion Watches

  //#region Methods
  resetSearch() {
    const dateNow = new Date();
    this.selectedAircraftId = null;
    this.selectedDateRange[0] = new Date(dateNow.getFullYear(), 0, 1);
    this.selectedDateRange[1] = dateNow;
    this.requiresAttentionOnly = !!(this.securityLevel_ == 70); // default to true when client EAs
  }

  async getTripLegs() {
    if (this.selectedClientId > 0) {
      this.clientIdIsMissing = false;

      this.isLoading = true;
      this.tripLegs =[] as AvService.TripLegs[];
      const tripLegsParameters = {} as AvService.GetTripLegsParameters;
      tripLegsParameters.ClientId = this.selectedClientId;
      tripLegsParameters.AircraftId = this.selectedAircraftId;
      tripLegsParameters.DateRangeStart = this.selectedDateRange? this.selectedDateRange[0] : null;
      tripLegsParameters.DateRangeEnd = this.selectedDateRange ? this.selectedDateRange[1] : null;
      tripLegsParameters.RequiresAttentionOnly = this.requiresAttentionOnly;
      tripLegsParameters.ForExcelExport = this.forExcelExport;
      this.tripLegs = await this._avService.GetTripLegs(tripLegsParameters);
      this.isLoading = false;
    }
    else {
      this.clientIdIsMissing = true;
    }
  }

  async getTripLegManifests(tripLegId: number) {
    if (this.tripLegManifests[tripLegId] === undefined) {
      const tripLegManifestParameters = {} as AvService.GetTripLegManifestParameters;
      tripLegManifestParameters.TripLegId = tripLegId;
      tripLegManifestParameters.Type = "Passenger";
      this.tripLegManifests[
        tripLegId
      ] = await this._avService.GetTripLegManifest(tripLegManifestParameters);
    }
  }


  selectTripLegForEdit(tripLeg: AvService.TripLegs) {
    this.selectedTripLegForEdit = tripLeg;
    this.showTripLegEdit = true;
  }

  async selectTripLegForPassenger(tripLeg: AvService.TripLegs) {
    this.selectedTripLegForPassengers = tripLeg;
    await this.getTripLegManifests(tripLeg.LegId);
    this.showTripLegPassengers = true;
  }

  async closeTripLegEdit() {
    const index = this.tripLegs.indexOf(this.selectedTripLegForEdit);
    if (index == -1) return; // abandon now if no match
    this.isLoading = true;
    const tripLegsParameters = {} as AvService.GetTripLegsParameters;
    //replace all legs of the trip for the given leg because the Company/Purpose entry could impact all those legs
    tripLegsParameters.TripId = this.selectedTripLegForEdit.TripId; 
    const tls = await this._avService.GetTripLegs(tripLegsParameters);
    tls.forEach(tl =>{
      const index = findIndex(this.tripLegs, { 'LegId': tl.LegId});
      this.tripLegs.splice(index, 1, tl); //replace with row from database 
    })
    this.isLoading = false;
    this.selectedTripLegForEdit = {} as AvService.TripLegs;
  }

  async updateReason(tripLegId: number, reason: string) {
    const tl = new SmartObject("TripLeg", tripLegId);
    try {
      await tl.updateAttribute("Reason", reason);

      this.$notify.success("Changes saved.");
    } catch {
      this.$notify.error("Update failed, please try again.");
    }
  }

  async copyReason(tripLegIds: number[], reason: string) {
    try {
      const promises = [];
      tripLegIds.forEach(tripLegId => {
        promises.push(
          (async tripLegId => {
            const tl = new SmartObject("TripLeg", tripLegId);
            await tl.updateAttribute("Reason", reason);

            const tripLeg = find(this.tripLegs, tripLeg => {
              return tripLeg.LegId === tripLegId;
            });
            if (tripLeg) {
              tripLeg.Reason = reason;
            }
          })(tripLegId)
        );
      });

      await Promise.all(promises);

      this.$notify.success("Changes saved.");
    } catch (err) {
      this.$notify.error("Update failed, please try again.");
    }
  }

  async exportToExcel() {
      this.forExcelExport = true;
      await this.getTripLegs();
      //$(this.$refs.tblTripLegEditList).parent().exportToExcel({
        $(this.$refs.refTripLegs.$el).exportToExcel({
        filename: 'Trip Log - ' +
            (this.selectedClient.Name || 'All Clients') + ' - ' +
            (this.selectedAircraft.Display || 'All Aircrafts') + ' - ' +
            (this.selectedDateRange && this.selectedDateRange.length ? 
                this.$moment(this.selectedDateRange[0]).format('MM.DD.YYYY') + '-' +
                this.$moment(this.selectedDateRange[1]).format('MM.DD.YYYY')
                : 'All Dates'
            ) +
            (this.requiresAttentionOnly ? ' - Requires Attention Only' : '')
      });
      this.forExcelExport = false;
      await this.getTripLegs();
  }
  evaluateCompanyPurposeStatus(status: string): any{
    if (status == 'OK') return {};
    if (status == 'Repositioning') return {'font-size':'smaller', 'opacity':.5}; //muted
    if (status == 'REQUIRED') return {'color':'red'};
    if (status == 'UNKNOWN') return {'color':'red'};
  }
  //#endregion Methods
}
