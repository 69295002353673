import Vue from 'vue';

export interface FilterList {
  text: string;
  value: string;
}

export class Filter extends Vue {
  getFilterList(array: any[], property: string): FilterList[] {
      const list: FilterList[] = array.map(investment => ({value: investment[property], text: investment[property]}));
      const uniqueFilteredList: FilterList[] = list.filter((item, index, self) => // remove null and only return unique
          !!item.value && self.findIndex(x => x.value === item.value) == index
      );
      uniqueFilteredList.sort(this.compareValues('value'));
      return uniqueFilteredList;
  }

  handleColumnFilter(value, row, column) {
      const property = column['property'];
      return row[property] === value;
  }

  compareValues(key, order = 'asc') {
    return function(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order == 'desc') ? (comparison * -1) : comparison
      );
    };
  }
}
