
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import ExcelImport from '@/components/ExcelImport.vue';
import formatters from '../../utils/formatters';

import * as AvService from './../../services/DAL/avService';
import * as UtilService from './../../services/DAL/utilService';
declare var getDateStringFromExcelDate: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;
@Component({
  components: {
    ExcelImport
  }
})
export default class ImportJetAviationCPR extends Vue {
  //#region Private declarations for Services
  private _avService: AvService.AvService;
  private _utilService: UtilService.UtilService;
  private formatters: formatters = new formatters();

  //#endregion Private declarations for Services

  //#region Props
  //#endregion Props

  //#region Data
  importProcesses: AvService.ImportCPRJetAviation[] = [];
  loading: boolean = false;
  showExcelImport: boolean = true;
  logId: number = null;
  statusMessage: string = '';
  fieldOptions: object[] = [
      { name: "Amount", parseFunction: this.unFormatParens },
      { name: "Doc.Date", field: 'DocDate' },
      { name: 'Acct Num', field: 'AcctNum' },
      { name: 'Expense Category', field: 'ExpenseCategory' },
      { name: 'Ven Num', field: 'VenNum' },
      { name: 'Post Date', field: 'PostDate' },
      { name: 'Invoice', field: 'CPRInvoice' }
    ];

  logParameters: object = {
    database: 'visium',
    schema: 'av',
    caller: 'JetAviationCPRImport',
    event: 'Import'
  };
  requiredColumns: string[] = ['Doc.Date', 'Project', 'Expense Category', 'Description']; /* 06-27-2022 11:08 CAMPBED: removed Post Date because records were being lost.*/
  securityLevel_: number = null;
  
  //#endregion Data

  //#endregion Computed

  //#endregion Computed

  //#region Lifecycle
  async created() {
    this._avService = new AvService.AvService();
    this._utilService = new UtilService.UtilService();
    this.formatters = new formatters();
    this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAviationDataImport),
            0
        );
    this.getImportProcesses();
  }
  //#endregion Lifecycle
  unFormatParens(strInput) {
      return strInput.toString().replace('(', '-').replace(')', '');
  }

  async getImportProcesses() {
    this.loading = true;
    const parms = {} as AvService.GetImportCPRJetAviationParameters;
    this.importProcesses = await this._avService.GetImportCPRJetAviation(parms);
    this.loading = false;
  }
  async handleImport(result) {
    console.log(result);
    if (result.errors > 0) {
      this.$alert(
        `${result.errors} records resulted in an error when writing to the database.  Review the records with an empty Id in the last column displayed, then try again.`
      );
    } else if (result.successes > 0) {
      this.showExcelImport = false;
      this.logId = result.importLogId;
      await this.processImport();
    }
  }
  async processImport() {
    this.loading = true;
    const params = {} as AvService.ProcessImportCPRJetAviationParameters;
    params.ImportLogId = this.logId;
    await this._avService.ProcessImportCPRJetAviation(params);
    this.$notify.success('CPR Processed');
    this.statusMessage = 'Refreshing Process List';
    await this.getImportProcesses();
    this.statusMessage = '';
    this.loading = false;
    this.showExcelImport = true;
  }
  selectEvent(row) {
    this.$emit('event-clicked', row.ImportLogId);
  }
  //#region Methods
}
