// TS API for Schema util
// Auto-generated 10/19/19 10:06


declare var smartData: any;
// output classes:

export class DatabaseServiceLevelSchedule {
    WeekdayName: string = undefined;
    Id: number = undefined;
    DayOfWeek: number = undefined;
    Type: string = undefined;
    StartTime: string = undefined;
    EndTime: string = undefined;
}

export class EmailAttachments {
    Id: number = undefined;
    EmailId: number = undefined;
    DocumentId: number = undefined;
    DocumentPath: string = undefined;
    Title: string = undefined;
    CreateDate: string = undefined;
    CreateUser: string = undefined;
}

export class Emails {
    Id: number = undefined;
    EntityType: string = undefined;
    EntityId: number = undefined;
    Source: string = undefined;
    FromAddress: string = undefined;
    FromName: string = undefined;
    ToAddresses: string = undefined;
    ToRecipients: string = undefined;
    CcRecipients: string = undefined;
    BccRecipients: string = undefined;
    Subject: string = undefined;
    Body: string = undefined;
    SendDate: string = undefined;
    SendUser: string = undefined;
    NumAttachments: number = undefined;
}

export class EventKeys {
    Id: number = undefined;
    LogId: number = undefined;
    KeyName: string = undefined;
    CreateDate: string = undefined;
    IntValue: number = undefined;
    VarcharValue: string = undefined;
    DateTimeValue: string = undefined;
}

export class EventMessages {
    Id: number = undefined;
    Message: string = undefined;
    Rows: number = undefined;
    IsError: boolean = undefined;
    StartTime: string = undefined;
    FinishTime: string = undefined;
    RunTimeMilliseconds: number = undefined;
    RunTimeSeconds: number = undefined;
    RunTimeMinutes: number = undefined;
}

export class LogEvent {
    Id: number = undefined;
    EventId: number = undefined;
    CreateDate: string = undefined;
    CreateUser: string = undefined;
    CompleteDate: string = undefined;
    Milliseconds: number = undefined;
    Database: string = undefined;
    Schema: string = undefined;
    Caller: string = undefined;
    Event: string = undefined;
    Success: boolean = undefined;
    Machine: string = undefined;
    Seconds: number = undefined;
    Minutes: number = undefined;
    Hours: number = undefined;
    CreateDateET: string = undefined;
    CompleteDateET: string = undefined;
    RowNum: number = undefined;
}

export class LogIds {
    Id: number = undefined;
    LogId: number = undefined;
    RunTime: string = undefined;
    RunTimeWithId: string = undefined;
    EventId: number = undefined;
    CreateDate: string = undefined;
    CreateUser: string = undefined;
    CompleteDate: string = undefined;
    Milliseconds: number = undefined;
    Database: string = undefined;
    Schema: string = undefined;
    Caller: string = undefined;
    Event: string = undefined;
    Success: boolean = undefined;
    Machine: string = undefined;
    Seconds: number = undefined;
    Minutes: number = undefined;
    Hours: number = undefined;
    CreateDateET: string = undefined;
    CompleteDateET: string = undefined;
    RowNum: number = undefined;
}

export class ProcessViewer {
    Id: number = undefined;
    Database: string = undefined;
    Schema: string = undefined;
    Caller: string = undefined;
    Event: string = undefined;
    Active: boolean = undefined;
    ProcessViewerName: string = undefined;
}

export class LogEventReturnRow {
    LogId: number = undefined;
}

export class LogEventSelect {
    LogId: number = undefined;
}

// Parameter interfaces:

export interface CompleteLogEventParameters {
    LogId: number;
    Success: boolean;
    FailIfMessageErrors: boolean;
}

export interface GetEmailAttachmentsParameters {
    EmailId: number;
}

export interface GetEmailsParameters {
    EntityType: string;
    EntityId: number;
}

export interface GetEventKeysParameters {
    LogId: number;
}

export interface GetEventMessagesParameters {
    LogId: number;
}

export interface GetLogEventParameters {
    Database: string;
    Schema: string;
    Caller: string;
    Event: string;
    MinMilliseconds: number;
    MaxRows: number;
    LogId: number;
    IncludeIncompleteRegardlessOfMilliseconds: boolean;
    EventId: number;
}

export interface GetLogIdsParameters {
    Database: string;
    Schema: string;
    Caller: string;
    Event: string;
    KeyName: string;
    KeyInt: number;
    KeyVarChar: string;
    KeyDate: string;
    CompletedOnly: boolean;
    LogId: number;
    IncludeIncompleteRegardlessOfMilliseconds: boolean;
    MinMilliseconds: number;
    MaxRows: number;
    EventId: number;
    SuccessOnly: boolean;
}

export interface LogEventKeyParameters {
    LogId: number;
    KeyName: string;
    IntValue: number;
    VarcharValue: string;
    DateTimeValue: string;
}

export interface LogEventMessageParameters {
    LogId: number;
    Message: string;
    Rows: number;
    IsError: boolean;
}

export interface LogEventReturnRowParameters {
    Database: string;
    Schema: string;
    Caller: string;
    Event: string;
    LogId: number;
}

export interface LogEventSelectParameters {
    Database: string;
    Schema: string;
    Caller: string;
    Event: string;
    LogId: number;
}

export class UtilService {
  CompleteLogEvent (params: CompleteLogEventParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'util.CompleteLogEvent',
        params: params
      });
    }

  GetDatabaseServiceLevelSchedule (): Promise<DatabaseServiceLevelSchedule[]> {
    return smartData({
        storedProcName: 'util.GetDatabaseServiceLevelSchedule',
        params: {}
      });
    }

  GetEmailAttachments (params: GetEmailAttachmentsParameters): Promise<EmailAttachments[]> {
    return smartData({
        storedProcName: 'util.GetEmailAttachments',
        params: params
      });
    }

  GetEmails (params: GetEmailsParameters): Promise<Emails[]> {
    return smartData({
        storedProcName: 'util.GetEmails',
        params: params
      });
    }

  GetEventKeys (params: GetEventKeysParameters): Promise<EventKeys[]> {
    return smartData({
        storedProcName: 'util.GetEventKeys',
        params: params
      });
    }

  GetEventMessages (params: GetEventMessagesParameters): Promise<EventMessages[]> {
    return smartData({
        storedProcName: 'util.GetEventMessages',
        params: params
      });
    }

  GetLogEvent (params: GetLogEventParameters): Promise<LogEvent[]> {
    return smartData({
        storedProcName: 'util.GetLogEvent',
        params: params
      });
    }

  GetLogIds (params: GetLogIdsParameters): Promise<LogIds[]> {
    return smartData({
        storedProcName: 'util.GetLogIds',
        params: params
      });
    }

  GetProcessViewer (): Promise<ProcessViewer[]> {
    return smartData({
        storedProcName: 'util.GetProcessViewer',
        params: {}
      });
    }

  LogEventKey (params: LogEventKeyParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'util.LogEventKey',
        params: params
      });
    }

  LogEventMessage (params: LogEventMessageParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'util.LogEventMessage',
        params: params
      });
    }

  LogEventReturnRow (params: LogEventReturnRowParameters): Promise<LogEventReturnRow[]> {
    return smartData({
        storedProcName: 'util.LogEventReturnRow',
        params: params
      });
    }

  LogEventSelect (params: LogEventSelectParameters): Promise<LogEventSelect[]> {
    return smartData({
        storedProcName: 'util.LogEventSelect',
        params: params
      });
    }
}
