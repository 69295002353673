
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as LiqService from '../../services/DAL/liq_Service';
import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';
import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';
import UploadAttachment from '@/js/Attachments/UploadAttachment.vue'
import FormattedInput from "@/components/form/FormattedInput.vue";
import Common from '../../utils/common';
import CommentLogList from '@/js/CommentLog/CommentLogList.vue';

import MicrosoftGraphClient, {
  GraphRequest,
  ResponseType,
  Client
} from '@microsoft/microsoft-graph-client';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(value: any, defaultValue: any): any;

@Component({
    components: {
        UploadAttachment
        ,FormattedInput
        ,CommentLogList
    }
})
export default class BankAccountReconciliationEdit extends Vue {
    $refs: {
        frmReconciliationEdit: ElementUI.Form
    }
    //#region Private Declarations
    private _liqService: LiqService.LiqService;
    private _graphService: MicrosoftGraphService;
    private _common: Common;
    //#endregion Private Declarations

    //#region Props
    @Prop({ required: true, type: Object }) reconciliation: LiqService.BankDepositoryAccountReconciliations;
    @Prop() reconciliationId: number;
    @Prop() viewType: string;
    @Prop() showSideBySide: boolean;
    //#endregion Props

    //#region Data
    reconciliation_ = {} as LiqService.BankDepositoryAccountReconciliations;

    securityLevel_: number = getStoredSecurityLevel(this.$namedKey.SecurityView.ManageBankAccounts);
    fileData = {} as MicrosoftGraphTypes.DriveItem;
    isLoading = false;
    sideBySide = false;
    showCommentLogList = true;
    notes = '';

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();
        this._graphService = new MicrosoftGraphService();
        this._common = new Common();

        this.setReconciliation();
    }
    //#endregion Lifecycle

    //#region Computed
    get isNew(): boolean {
        return !(this.reconciliation_.ReconciliationId > 0);
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    get placeholder(): string{
        return `Upload Statement to start.  Drop file here, or click to browse.  File will be renamed ${this.reconciliation_.ExpectedFileName}.`
    }
    //#endregion Computed

    @Watch('reconciliation')
    onChange_reconciliation(val: LiqService.BankDepositoryAccountReconciliations, oldVal: LiqService.BankDepositoryAccountReconciliations){
        if (val.ReconciliationId != oldVal.ReconciliationId || val.ReconciliationId == null || oldVal.ReconciliationId == null){
            this.setReconciliation();
        }
    }
    @Watch('sideBySide')
    onChange_sideBySide(){
        this.$emit('side-by-side', {sideBySide: this.sideBySide, fileData: this.fileData});
    }
    @Watch('showSideBySide')
    onChange_showSideBySide(){
        this.sideBySide = this.showSideBySide;
    }    
    //#region Methods
    async setReconciliation(){
        this.fileData = {} as MicrosoftGraphTypes.DriveItem;
        this.sideBySide = false;
        if (this.reconciliation && Object.keys(this.reconciliation).length) {
            this.reconciliation_ = this.reconciliation;
            this.fetchFileData();
        }
        else {
            this.reconciliation_ = {} as LiqService.BankDepositoryAccountReconciliations;
        }
    }
    async fetchReconciliation(reconciliationId: number) {
        this.isLoading = true;
        const params = {} as LiqService.GetBankDepositoryAccountReconciliationsParameters;
        params.ReconciliationId = reconciliationId || this.reconciliationId;
        const bankDepositoryAccountReconciliations = await this._liqService.GetBankDepositoryAccountReconciliations(
            params
        );
        if (!!bankDepositoryAccountReconciliations && bankDepositoryAccountReconciliations.length == 1){
            this.reconciliation_ = bankDepositoryAccountReconciliations[0];
        }
        else {
            this.reconciliation_ = {} as LiqService.BankDepositoryAccountReconciliations;
        }
        this.isLoading = false;
    }
    async fetchFileData(){
        if (!!this.reconciliation_.FileId && !!this.reconciliation_.DrivePath){
            try {
                const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
                this.fileData = await graphClient.api(this.reconciliation_.DrivePath + 'items/' + this.reconciliation_.FileId).get();
            }
            catch (err){
                console.error(err);
            }
        }
        else {
            this.fileData = {} as MicrosoftGraphTypes.DriveItem;
        }
        this.$emit('file-data', this.fileData);
    }
    async documentUploaded(doc){
        console.log(doc);
        this.reconciliation_.FileId = doc.file.id;
        this.reconciliation_.Status = 'In Progress';
        const reconciliationId = await new SmartObject('Reconciliation').createObject(this.reconciliation_);
        this.reconciliation_.ReconciliationId = reconciliationId; // set this before the fetch because the fetch "disconnects" from the parents.  Setting ReconciliationId makes the other tabs active.
        await this.fetchReconciliation(reconciliationId); // need to get it from the database so it has VAMChange.
        this.fileData = doc.file;
        this.sideBySide = true;
        this.$emit('file-data', this.fileData);
    }
    uploadFailed (error) {
        this.$notify.error('Upload Failed');
    }
    async saveReconciliation() {
        this.$refs.frmReconciliationEdit.validate(async valid => {
            if (valid) {
                let message: string;
                try {
                    await new SmartObject('Reconciliation', this.reconciliation_.ReconciliationId).updateObject(this.reconciliation_);
                    await this.saveNotes();

                    message = 'Changes saved.';
                }
                catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }

                this.$notify.success(message);

                await this.fetchReconciliation(this.reconciliation_.ReconciliationId);
                this.$emit('saved', this.reconciliation_);
            }
        });
    }
    async saveNotes(note?: string) {
        if (note && note.length){
            this.notes = note;
        }
        if (this.notes) {
            await new SmartObject('CommentLog').createObject({
                EntityType: 'Reconciliation',
                EntityId: this.reconciliation_.ReconciliationId,
                Comment: this.notes
            });
            this.notes = null;

            this.showCommentLogList = false;
            await this.$nextTick();
            this.showCommentLogList = true;
        }
    }

    async deleteReconciliation() {
        if (!this.isNew) {
        try {
            await this.$confirm(
                'This will permanently delete this Reconciliation Record and Statement File. Continue?',
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );

            try {
                await new SmartObject('Reconciliation', this.reconciliation_.ReconciliationId).deleteObject();

                this.$notify.success('Reconciliation deleted.');
                const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
                await graphClient.api(this.reconciliation_.DrivePath + 'items/' + this.reconciliation_.FileId).delete();
                this.$notify.success('Statement file deleted.');
                this.$emit('item-deleted', this.reconciliation_.ReconciliationId);
                this.$emit('close');
            } catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
            } catch {}
        }
    }
    async editReconciliation(){
        this.reconciliation_.Status = 'In Progress';
    }
    async onNameConflict(fileProperties: MicrosoftGraphTypes.DriveItem) {
        const check = new SmartObject('Reconciliation');
            await check.loadDataFromCompositeKey({
                FileID: fileProperties.id
            });
        if (!!check.dataObject && Object.keys(check.dataObject).length == 0){
            this.$notify.warning(`using existing file created by ${fileProperties.createdBy.user.displayName} on ${fileProperties.createdDateTime}`);
            this.documentUploaded( { file: fileProperties});
        }
        else {
            this.$alert('File has already been used.  Please contact support.'); //this seems highly unlikely.
        }

    }
    async resetVAMChange(){
        try {
            await this.$confirm(
                `Are you sure you want to reset the VAM Change to the current value of ${this.$accounting.formatMoney(this.reconciliation_.Change)}?`,
                'Reset VAM Change',
                {
                    confirmButtonText: 'Reset',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );

            try {
                await new SmartObject('Reconciliation', this.reconciliation_.ReconciliationId).updateObject({VAMChange: this.reconciliation_.Change});
                await this.fetchReconciliation(this.reconciliation_.ReconciliationId);
                await this.saveNotes(`VAM Change reset to ${this.$accounting.formatMoney(this.reconciliation_.Change)}`)
                this.$emit('saved', this.reconciliation_);
            }
            catch (err) {
                this.$notify.error('Something went wrong processing your request, please try again.');
            }
        }
        catch (err) {} // confirm
    }
    //#endregion
}
