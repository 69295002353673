
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as TransactionsService from '../../services/DAL/transactionsService';
import * as AssetService from '../../services/DAL/assetService';
import * as LiqService from '../../services/DAL/liq_Service';
import InvestmentTransactionTabView from '@/views/InvestmentTransaction/InvestmentTransactionTabView.vue';
import formatters from '../../utils/formatters';
import uniqBy from 'lodash/uniqBy';
import Common from '../../utils/common';
import assign from 'lodash/assign';
import * as transactionTypes from '@/views/InvestmentTransaction/transaction';
import ElementUI from 'element-ui';
import alasql from 'alasql';
import * as XLSX from 'xlsx';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;


declare var SmartObject: any;

@Component({
    components: {
        InvestmentTransactionTabView
    }
})
export default class InvestmentTransactionList extends Vue {
    $refs: {
        refTransactions: ElementUI.Table
    }
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private _assetService: AssetService.AssetService;
    private _liqService: LiqService.LiqService;
    private common: Common;
    private formatters: formatters;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({required: true}) investmentId: number;
    @Prop() selectedTransactionId: number;
    //#endregion Props

    //#region Data
    investment = {} as AssetService.InvestmentList;
    transactions = [] as transactionTypes.Transactions[];
    selectedTransaction = {} as transactionTypes.Transactions;
    showTransactionEdit: boolean = false;
    loading: boolean = false;
    matchingBankTransactionsCollection = new Map();
    showCalculations = false;
    securityLevel_: number = null;

    //#endregion Data

    //#region Lifecycle
    created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this._assetService = new AssetService.AssetService();
        this._liqService = new LiqService.LiqService();
        this.formatters = new formatters();
        this.common = new Common();
        this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets), 0);

    }
    async mounted() {
        await this.fetchInvestment();
        await this.fetchTransactions();
        if (this.selectedTransactionId){
            this.selectedTransactionIdChanged(this.selectedTransactionId, undefined);
        }

    }
    //#endregion Lifecycle

    //#region Computed
    //#endregion Computed

    //#region Watches
    @Watch('investmentId', { deep: true })
    async investmentIdChanged(val: any, oldVal: any) {
        await this.fetchInvestment();
        this.fetchTransactions();
    }
    @Watch('selectedTransactionId')
    async selectedTransactionIdChanged(val: any, oldVal: any) {
        const row = this.common.getSelectedArrayItem(
            this.transactions,
            val.toString(),
            'Id'
        );
        await this.editInvestmentTransaction(row, undefined, undefined);
    }

    //#endregion Watches

    //#region Computed
    get loadingCalculations(){
        if (!this.transactions || this.transactions.length == 0) return false;
        return this.transactions.some((transaction) => {return transaction.loadingCalculations});
    }
    //#endregion Computed

    //#region Methods
    async fetchInvestment(){
        this.investment = {} as AssetService.InvestmentList;
        if (this.investmentId == null){
            console.error('TransactionId is null');
            return;
        }
        this.loading = true;
        const params = {} as AssetService.GetInvestmentListParameters;
        params.InvestmentId = this.investmentId;
        const investments = await this._assetService.GetInvestmentList(params);
        if (!!investments && investments.length == 1){
            this.investment = investments[0];
            this.showCalculations = (this.investment.ClosedDate == null)
        }
        this.loading = false;
    }
async fetchTransactions(){
        this.transactions = [] as transactionTypes.Transactions[];
        if (this.investmentId == null){
            console.error('TransactionId is null');
            return;
        }
        this.loading = true;
        const params = {} as TransactionsService.GetTransactionsListParameters;
        params.InvestmentId = this.investmentId;
        const transactions = await this._transactionsService.GetTransactionsList(params);
        transactions.map(transaction => {
            this.transactions.push(
                assign({}, transaction, {
                    NetShares: undefined,
                    RunningCostBasis: undefined,
                    loadingCalculations: false
                })
            );
        })
        await this.$nextTick();
        if (this.$refs.refTransactions){
        this.$refs.refTransactions.doLayout();
        }
        this.loading = false;
        if (this.showCalculations){
            this.fetchCalculations(); // not awaited - we want it to run in the background
        }
    }
    async fetchCalculations(){
        this.showCalculations = true;
        this.transactions.map(async transaction => {
            transaction.loadingCalculations = true;
            const params = {} as TransactionsService.GetTransactionCalculationParameters;
            params.TransactionId = transaction.Id;
            const calculations = await this._transactionsService.GetTransactionCalculation(params);
            if (!!calculations && calculations.length == 1){
                transaction.NetShares = calculations[0].NetShares;
                transaction.RunningCostBasis = calculations[0].RunningCostBasis;
            }
            transaction.loadingCalculations = false;
        });

    }
    cancelledTransactionEdit(){
        this.showTransactionEdit = false;
        this.selectedTransaction = {} as transactionTypes.Transactions;
    }
    async savedTransactionEdit(transaction: transactionTypes.Transactions){
        await this.fetchTransactions();
    }
    rowStyle(row, rowIndex){
        if (row.row.IsAccrual){
                return {'background-color': 'yellow'};
            }
    } 
    filterHandler(value, row, column) {
        const property = column['property'];
        return row[property] === value;
    }
    filters(column){
        const filters = this.transactions.map(function(list) {
            return {
                text: list[column]
                , value: list[column]
                };
        });
        return uniqBy(filters, 'value');
    }
    async getMatchData(transaction: transactionTypes.Transactions){
        let data = this.matchingBankTransactionsCollection.get(transaction.Id);
        if (!data){
            const params = {} as LiqService.GetMatchedTransactionParameters;
            params.EntityId = transaction.Id;
            params.EntityType = 'InvestmentTransaction';
            data = await this._liqService.GetMatchedTransaction(params);
            //this.matchingBankTransactionsCollection.set(transaction.Id, data); //The method below is required to make Map act reactive. https://stackoverflow.com/questions/37130105/does-vue-support-reactivity-on-map-and-set-data-types
            this.matchingBankTransactionsCollection = new Map(this.matchingBankTransactionsCollection.set(transaction.Id, data));
        }
    }
    getCachedMatchData(transaction: transactionTypes.Transactions){
        return this.matchingBankTransactionsCollection.get(transaction.Id)
    }
    getSummary(param) {
        const columnList = [6,7,9];
        const totalLabel = 'Total';
        const totalLabelIndex = 1;
        const formats = new Map();
        formats.set(6, 'currency');
        formats.set(7, 'number0');
        formats.set(9, 'currency');
        const result = this.common.getSummaryArray(param, columnList, totalLabel, totalLabelIndex, formats);
        result[0] = `Count: ${this.transactions.length}`
        return result;
    }
    async editInvestmentTransaction(
        selectedRow: transactionTypes.Transactions,
        event,
        column
    ) {
        if (selectedRow && selectedRow != null) {
        this.showTransactionEdit = false;
        this.selectedTransaction = selectedRow;
        this.showTransactionEdit = true;
        }
    }
    newTransaction(){
        this.showTransactionEdit = false;
        this.selectedTransaction = {} as transactionTypes.Transactions;
        this.showTransactionEdit = true;
    }
    async ExportTransactions(){
        if (this.transactions.length > 0) {
            var opts = [{ sheetid: this.investment.Name, header: true }];
            try {
                
                await alasql.promise(`SELECT * INTO XLSX("${this.investment.Name} Investment Transactions.xlsx",?) FROM ?`, [opts, [this.transactions]])
                this.$notify.success('Excel file exported.');
            }
            catch(err) {
                this.$notify.error(err.message);
            }
        }
    }
    deletedTransaction(transactionId: number){
        if (!!this.selectedTransaction && this.selectedTransaction.Id == transactionId){
            const index = this.transactions.indexOf(this.selectedTransaction);
            this.transactions.splice(index, 1);
            this.selectedTransaction = {} as transactionTypes.Transactions;
        }
        else{
            this.fetchTransactions();
        }
        this.showTransactionEdit = false;
    }
    //#endregion Methods
}
