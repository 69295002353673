
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import AddressSelect from '@/components/form/AddressSelect.vue';
import StateSelector from '@/components/form/StateSelector.vue';
import * as VamService from '../../services/DAL/vamService';
import Common from "../../utils/common";
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component({
    components: {
        AddressSelect,
        StateSelector
    }
})
export default class RegisteredAgentEdit extends Vue {
  $refs: {
    frmRegisteredAgentEdit: ElementUI.Form
  }
  //#region Data
  private _vamService: VamService.VamService;
  private _common: Common;

  @Prop() registeredAgent: VamService.RegisteredAgents;
  @Prop() onClose: Function;
  @Prop() onSave: Function;
  @Prop() onDelete: Function;
  @Prop() viewType: string;

  states = [] as VamService.States[];
  addresses = [] as VamService.Addresses[];

  registeredAgent_: VamService.RegisteredAgents = new VamService.RegisteredAgents();

  get isNew(): boolean {
    return !this.registeredAgent || !Object.keys(this.registeredAgent).length;
  }
  get isFormDirty(): boolean {
    return Object.keys((this as any).veeFields).some(
      key => (this as any).veeFields[key].dirty
    );
  }
  //#endregion Data

  //#region Lifecycle
  async created() {
    if (this.registeredAgent && Object.keys(this.registeredAgent).length) {
      this.registeredAgent_ = this.registeredAgent;
    }
    this._vamService = new VamService.VamService();
    this._common = new Common();
  }

  mounted() {
    this.$refs.frmRegisteredAgentEdit.resetFields();
  }
  //#endregion Lifecycle

  //#region Watches
  @Watch('registeredAgent_.StateId')
  async onChangeStateId(val?: object, oldVal?: object) {
    if (oldVal === undefined) {
      return; // initial load
    }
    let selectedState: VamService.States = null;
    if (this.registeredAgent_.AddressId) {
      selectedState = this._common.getSelectedArrayItem(
        this.states,
        this.registeredAgent_.StateId.toString(),
        'Id'
      );
    }
    if (selectedState) {
      this.registeredAgent_.State = selectedState.Abbreviation;
    } else {
      this.registeredAgent_.State = null;
    }
  }
  
  @Watch('registeredAgent_.AddressId')
  async onChangeAddressId(val?: object, oldVal?: object) {
    if (oldVal === undefined) {
      return; // initial load
    }
    let selectedAddress: VamService.Addresses = null;
    if (this.registeredAgent_.AddressId) {
      selectedAddress = this._common.getSelectedArrayItem(
        this.addresses,
        this.registeredAgent_.AddressId.toString(),
        'Id'
      );
    }
    if (selectedAddress) {
      this.registeredAgent_.FullStreet = selectedAddress.FullStreet;
      this.registeredAgent_.CityStateZip = selectedAddress.CityStateZip;
      this.registeredAgent_.County = selectedAddress.County;
      this.registeredAgent_.FullAddress = selectedAddress.FullAddress;
    } else {
      this.registeredAgent_.FullStreet = null;
      this.registeredAgent_.CityStateZip = null;
      this.registeredAgent_.County = null;
      this.registeredAgent_.FullAddress = null;
    }
  }
  //#endregion Watches

  //#region Methods
  async saveForm() {
    this.$refs.frmRegisteredAgentEdit.validate(async valid => {
      if (valid) {
        let message: string;

        if (this.isNew) {
          this.registeredAgent_.Id = await new SmartObject('RegisteredAgent').createObject(
            this.registeredAgent_
          );
          message = 'Registered agent added.';
        } else {
          await new SmartObject('RegisteredAgent', this.registeredAgent_.Id).updateObject(
            this.registeredAgent_
          );
          message = 'Changes saved.';
        }

        this.$notify.success(message);

        if (typeof this.onSave === 'function') {
          await this.onSave(this.registeredAgent_.Id);
        }

        if (this.isNew) {
          this.closeDialog();
        }
      }
    });
  }

  async deleteRegisteredAgent() {
    if (!this.isNew) {
      try {
        await this.$confirm(
          'This will permanently delete this registered agent. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        try {
          await new SmartObject('RegisteredAgent', this.registeredAgent_.Id).deleteObject();

          this.$notify.success('Registered agent deleted.');

          if (typeof this.onDelete === 'function') {
            await this.onDelete(this.registeredAgent_.Id);
            this.onClose_();
          }
        } catch {
          this.$notify.error('Something went wrong processing your request, please try again.');
        }
      } catch {}
    }
  }

  onClose_() {
    if (typeof this.onClose === 'function') {
      this.onClose();
    }
  }

  closeDialog() {
    this.$emit('close-dialog');
    this.onClose_();
  }
  //#endregion Methods
}
