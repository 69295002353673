
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
import * as ReportsService from './../../services/DAL/reportsService';
import BalanceSheetConfigEntitySettingCreate from '@/views/BalanceSheet/BalanceSheetConfigEntitySettingCreate.vue';
import FormattedInput from '@/components/form/FormattedInput.vue';

declare var SmartObject: any;

@Component({
    components: {
        BalanceSheetConfigEntitySettingCreate,
        FormattedInput
    }
})
export default class BalanceSheetConfigEntitySettingsList extends Vue {
    //#region Data
    private _reportsService: ReportsService.ReportsService;

    @Prop()
    clientId: number;
    @Prop()
    configuration: ReportsService.Configurations;
    @Prop()
    entitySettingConfiguration: ReportsService.EntitySettingConfigurations;

    entitySettings: ReportsService.EntitySettings[] = [];
    showBalanceSheetConfigEntitySettingCreate = false;
    isLoading = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._reportsService = new ReportsService.ReportsService();
    }

    async mounted() {
        this.fetchEntitySettings();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchEntitySettings() {
        this.isLoading = true;
        this.entitySettings = [];

        const getEntitySettingsParameters = {} as ReportsService.GetEntitySettingsParameters;
        getEntitySettingsParameters.ConfigurationId = this.configuration.Id;
        getEntitySettingsParameters.ConfigurationSettingId = this.entitySettingConfiguration.ConfigurationSettingId;
        this.entitySettings = await this._reportsService.GetEntitySettings(
            getEntitySettingsParameters
        );
        this.isLoading = false;
    }

    async setEntitySetting(value: any, row: ReportsService.EntitySettings) {
        try {
            let updated = true;
            if (row.Id > 0) {
                const cs = new SmartObject('ConfigurationSettings', row.Id);
                switch (this.entitySettingConfiguration.DataType) {
                    case 'Bit':
                        await cs.updateAttribute('SettingBit', value);
                        break;
                    case 'Int':
                        await cs.updateAttribute('SettingInt', value);
                        break;
                    case 'Decimal':
                        await cs.updateAttribute(
                            'SettingDecimal',
                            value
                        );
                        break;
                    default:
                        updated = false;
                        break;
                }
            } else {
                row.Id = await new SmartObject(
                    'ConfigurationSettings'
                ).createObject({
                    ConfigurationId: this.configuration.Id,
                    EntityType: row.EntityType,
                    EntityId: row.EntityId,
                    SettingBit: row.SettingBit,
                    ConfigurationSettingId: this.entitySettingConfiguration.ConfigurationSettingId
                });
            }

            if (updated) {
                this.$notify.success('Entity setting updated.');
            }
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }
    //#endregion Methods
}
