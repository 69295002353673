
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import AddressEdit from '@/views/Address/AddressEdit.vue';
import * as VamService from '../../services/DAL/vamService';
import ElementUI from 'element-ui';
declare var $: any;
declare var SmartObject: any;

@Component({
  inheritAttrs: false,
  components: {
    AddressEdit
  }
})
export default class AddressSelect extends Vue {
  $refs: {
    btnAddAddress: ElementUI.Button
    , ddlAddressSelect: ElementUI.Select
  }
  //#region Data
  private _vam_Service: VamService.VamService;

  @Prop() name: string;
  @Prop() value: number;
  @Prop() size: string;

  addresses = [] as VamService.Addresses[];
  selectedValue: number = null;
  showAddressCreate: Boolean = false;
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._vam_Service = new VamService.VamService();
  }
  async mounted() {
    this.selectedValue = this.value;
    this.fetchAddresses();

    // get actual button width, and reset width of select to fill remaining space next to button
    const buttonWidth = $(this.$refs.btnAddAddress.$el).outerWidth() || 44;
    $(this.$refs.ddlAddressSelect.$el).css('width', 'calc(100% - ' + (buttonWidth + 6) + 'px)');
  }
  //#endregion Lifecycle

  //#region Watches
  @Watch('value')
  onChange_value(val: number, oldVal: number) {
    this.selectedValue = val;
  }
  //#endregion Watches

  //#region Methods
  async fetchAddresses() {
    this.addresses = [];
    const getAddressesParameters = {} as VamService.GetAddressesParameters;
    this.addresses = await this._vam_Service.GetAddresses(getAddressesParameters);
    
    this.$emit('options-fetched', this.addresses);
  }

  async onSaveNewAddress(AddressId: number) {
    await this.fetchAddresses();
    this.$emit('input', AddressId);
    this.$emit('address-added');
  }
  //#endregion Methods
}
