
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop, Model } from "vue-property-decorator";
import * as AssetService from "../../services/DAL/assetService";

@Component({
    inheritAttrs: false
})
export default class TgPublicEquitySelector extends Vue {
    //#region Private declarations for Services
    private _assetService: AssetService.AssetService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: number;
    @Prop()
    investmentId: number;
    //#endregion Props

    //#region Data
    equities = [] as AssetService.FundingSourcePublicEquity[];
    selectedValue: number = null;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
    }
    async mounted() {
        this.selectedValue = this.value;
        this.fetchEquities();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch("value")
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
    }
    @Watch("investmentId")
    onChange_investmentId(val: number, oldVal: number) {
        this.fetchEquities();
    }

    //#endregion Watches

    //#region Methods
    async fetchEquities() {
        this.equities = [];
        if (this.investmentId) {
            const params = {} as AssetService.GetFundingSourcePublicEquityParameters;
            params.InvestmentId = this.investmentId;
            this.equities = await this._assetService.GetFundingSourcePublicEquity(params);
        }
    }

    //#endregion Methods
}
