declare let process: any;
import { RawAxiosRequestConfig } from 'axios';
import * as Msal from '@azure/msal-browser';

const headers: RawAxiosRequestConfig['headers'] = {};
headers['Content-Type'] = 'application/json';
headers['Accept'] = 'application/json';

export const APIConfig = {} as RawAxiosRequestConfig;
APIConfig.baseURL = process.env.VUE_APP_API_URL;
APIConfig.timeout = 100000;
APIConfig.headers = headers;

export const msalConfig = {
    tenant: 'visiumpartners.com',
    clientID: '094051a0-c667-40dc-b51e-f4c1dcac1856', // <-- this is VAMWeb2022
    scope: '094051a0-c667-40dc-b51e-f4c1dcac1856/.default',
    apiScope: 'c92d4105-40fe-4c63-8ba9-0e4cebb1f34e/access_as_user', // <-- that is VAMAPINet6.  this is VAM.Web (old): 'api://a2c28b43-3098-47ae-a3e3-4423ecddee5f/access_as_user',
    authority: 'https://login.microsoftonline.com/visiumpartners.com',
    b2cScopes: [
        'https://graph.microsoft.com/user.read',
        'https://graph.microsoft.com/files.readwrite.all',
        'https://graph.microsoft.com/sites.readwrite.all',
        'profile'
    ],
    redirectUri: process.env.VUE_APP_REDIRECT_URL,
    postLogoutRedirectUri: process.env.VUE_APP_LOGOUT_REDIRECT_URL,
    LogLevel: Msal.LogLevel.Error
};
