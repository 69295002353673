declare var storeSecurityProfile: any;
declare var SmartJS: any;
// declare var SmartJSIsLoaded: any;
declare var $: any;

import Vue from 'vue';
import { AuthService } from '../services/AuthService';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { TokenResponse } from '../models/TokenResponse';

const LOGIN = 'LOGIN';
const RELOGIN = 'RELOGIN';
const LOGOUT = 'LOGOUT';
const CLEARTOKEN = 'CLEARTOKEN';

const _auth: AuthService = new AuthService();

const state = {
  isAuthenticated: false,
  apiToken: '',
  graphToken: '',
  accountInfo: {}
};

const mutations = {
  [LOGIN](state, loginResponse: TokenResponse) {
    state.isAuthenticated = true;
    state.accountInfo = loginResponse.account;
    state.apiToken = loginResponse.apiToken;
    state.apiTokenExpiresAt = (loginResponse.apiToken ? jwtDecode(loginResponse.apiToken).exp : undefined);
    state.graphToken = loginResponse.graphToken;
  },
  [RELOGIN](state, refreshResponse: TokenResponse) {
    state.isAuthenticated = true;
    state.accountInfo = refreshResponse.account;
    state.apiToken = refreshResponse.apiToken;
    state.apiTokenExpiresAt = (refreshResponse.apiToken ? jwtDecode(refreshResponse.apiToken).exp : undefined);
    state.graphToken = refreshResponse.graphToken;
  },
  [LOGOUT](state) {
    state.isAuthenticated = false;
    state.apiToken = '';
    state.graphToken = '';
    state.accountInfo = {};
  },
  [CLEARTOKEN](state) {
    state.isAuthenticated = false;
    state.apiToken = '';
    state.graphToken = '';
  }
};

const actions = {
  isAuthenticatedTryRefresh: async ({ state, commit, dispatch, getters }) => {
    if (_auth.isLoggedIn() && getters.isAuthenticated) {
      return true;
    } else {
      const tryResult = await dispatch('refreshToken');
      return tryResult.success;
    }
  },
  login: async ({ commit }) => {
    try {
      commit('systemStatusMessage', 'Auth Login');
      const loginResponse: TokenResponse = await _auth.login();
      commit(LOGIN, loginResponse);
      commit('systemStatusMessage', 'Committed Login');

      console.log(`loggged in at ${Vue.prototype.$moment()
        .format('MM/DD/YY h:mm:ss A')
        };\n token expires at ${Vue.prototype.$moment
        .unix(jwtDecode(loginResponse.apiToken).exp)
        .format(
          'MM/DD/YY h:mm:ss A'
        )}\n token issued at ${Vue.prototype.$moment
        .unix(jwtDecode(loginResponse.apiToken).iat)
          .format('MM/DD/YY h:mm:ss A')}`);

      commit('systemStatusMessage', 'Setting Headers');
      SmartJS.defaults.requestHeaders.Authorization = 'Bearer ' + loginResponse.apiToken;

      commit('systemStatusMessage', 'Getting Security Profile');
      await storeSecurityProfile({
        methodName: 'GetSecurityLevelsOfCurrentUser',
        viewField: 'SecurityView',
        levelField: 'SecurityLevel'
      });
      commit('systemStatusMessage', 'Getting Named Keys');
      await Vue.prototype.$namedKeyService.refreshAll();

      commit('systemStatusMessage', '');
      return loginResponse;
    }
    catch (err) {
      console.error(err);
      commit(LOGOUT);
      SmartJS.defaults.requestHeaders.Authorization = undefined;
      commit('systemStatusMessage', '');
      throw err;
    }
  },
  async logout({ commit, dispatch }) {
    await _auth.logout();
    commit(LOGOUT);
    // localStorage.clear();
  },
  async refreshToken ({ commit, state, getters }): Promise<any> {
    console.log('Refreshing token from store.refreshToken.');
    if (_auth.isLoggedIn()) {
        try {
        commit('systemStatusMessage', 'Refreshing Token');
        const refreshResponse: TokenResponse = await _auth.acquireTokenSilent();
        commit(RELOGIN, refreshResponse);

        commit('systemStatusMessage', 'Setting Headers (Refresh)');
        SmartJS.defaults.requestHeaders.Authorization = 'Bearer ' + refreshResponse.apiToken;
        axios.defaults.headers.common.Authorization = 'Bearer ' + refreshResponse.apiToken;
        $.ajaxSetup({
          headers: { 'Authorization': `Bearer ${refreshResponse.apiToken}` }
        });

        console.log(`refreshed token at ${Vue.prototype
          .$moment()
          .format(
            'MM/DD/YY h:mm:ss A'
          )};\n token expires at ${Vue.prototype.$moment
            .unix(jwtDecode(refreshResponse.apiToken).exp)
            .format(
              'MM/DD/YY h:mm:ss A'
            )}\n token issued at ${Vue.prototype.$moment
              .unix(jwtDecode(refreshResponse.apiToken).iat)
              .format('MM/DD/YY h:mm:ss A')}`);

        // if (SmartJSIsLoaded) { // on the initial load, SmartJS may not be done loading, but these calls will get run again immediately
          commit('systemStatusMessage', 'Refreshing Security Profile');
          await Promise.all([
            Vue.prototype.$namedKeyService.refreshAll(),
            storeSecurityProfile({
              methodName: 'GetSecurityLevelsOfCurrentUser',
              viewField: 'SecurityView',
              levelField: 'SecurityLevel'
            })
          ]);
        // }
        commit('systemStatusMessage', '');
      return { success: true, apiToken: refreshResponse.apiToken };
    } catch (err) {
        console.log('Error in store.refreshToken: ' + err);
        commit('systemStatusMessage', 'Error Refreshing Token');
        commit(CLEARTOKEN);
        return { success: false, apiToken: '' };
      }
    }
    else {
      commit('systemStatusMessage', 'Not Logged In');
      commit(CLEARTOKEN);
      return { success: false };
    }
  }
};
const getters = {
  isAuthenticated: state => {
    // let loggedIn = _auth.isLoggedIn();
    // if (!loggedIn) {
    //   console.log(`IsAuthenticated Getter: false - _auth.isLoggedIn() is ${loggedIn}`);
    //   return false;
    // }
    if (!state.isAuthenticated ) {
      console.log(`IsAuthenticated Getter: false - state.isAuthenticated is ${state.isAuthenticated}`);
      return false;
    }
    if (state.apiTokenExpiresAt <= Date.now() / 1000) {
      console.log(!!state.apiToken && state.apiTokenExpiresAt > 0 ? `IsAuthenticated Getter: false - API token expired at ${Vue.prototype.$moment
        .unix(state.apiTokenExpiresAt)
        .format('MM/DD/YY h:mm:ss A')}` : 'No Id Token');
      return false;
    }
    console.log(!!state.apiToken && state.apiTokenExpiresAt > 0 ? `IsAuthenticated Getter: true - API token expires at ${Vue.prototype.$moment
      .unix(state.apiTokenExpiresAt)
      .format('MM/DD/YY h:mm:ss A')}` : 'No Id Token');
    return true;
  },
  account: state => state.accountInfo,
  apiToken: state => state.apiToken,
  graphToken: state => state.graphToken
};

export default {
  state,
  mutations,
  actions,
  getters
};
