
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import EntityEdit from '../../views/Entity/EntityEdit.vue';
import EntityDocuments from '../../views/Entity/EntityDocuments.vue';
import OwnerAgreementList from '../../views/Agreements/OwnerAgreementList.vue';
import OwnerLiabilityList from '../../views/Liability/OwnerLiabilityList.vue';
import AttachmentList from '../../js/Attachments/AttachmentList.vue';
import CommentLogList from '../../js/CommentLog/CommentLogList.vue';
import * as VamService from '../../services/DAL/vamService';
import * as AttachmentService from '../../services/DAL/attachmentService';
import Common from '../../utils/common';

declare var $;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function getStoredSecurityLevel(Id: number): number;
declare function getAppCookieKeyValue(
    key: string,
    defaultValue: object
): string;
declare function setAppCookieKeyValue(key: string, value: object): void;

@Component({
    components: {
        EntityEdit,
        OwnerAgreementList,
        OwnerLiabilityList,
        AttachmentList,
        CommentLogList,
        EntityDocuments
    }
})
export default class EntityEditView extends Vue {
    //#region Data
    private _vam_Service: VamService.VamService;
    private _attachmentService: AttachmentService.AttachmentService;

    @Prop() owner: VamService.OwnersV2;
    @Prop({ type: Number, default: null })
    securityLevel!: number;
    @Prop() onClose: Function;
    @Prop() onSave: Function;
    @Prop() onDelete: Function;

    owner_: VamService.OwnersV2 = new VamService.OwnersV2();
    securityLevel_: number = this.securityLevel;
    isLoading: boolean = false;
    DefaultEntityStoreId: number = -1;
    showForms: boolean = true; 
    //#endregion Data

    //#region Computed
    get attachmentParameters(): {} {
        return {
        entityId: this.owner_.Id,
        entityType: 'Owner',
        clientId: this.owner_.ClientId,
        storeCategoryId: 30 // Entity/Owner
      };
    }
    get isIndividual(): boolean{
      return (this.owner_.OwnerCategoryName == 'Individual');
    }

    //#endregion Computed

//#region Lifecycle
    async created() {
        if (Object.keys(this.owner).length) {
            this.owner_ = this.owner;
        }
        this._vam_Service = new VamService.VamService();
        this._attachmentService = new AttachmentService.AttachmentService();

        if (!this.securityLevel_) {
            this.securityLevel_ = tryParseInt(
                getStoredSecurityLevel(this.$namedKey.SecurityView.ManageOwners),
                0
            );
        }
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('owner_.ClientId')
    async onSelectedClientChange(val: object, oldVal: object) {
        const params = {} as AttachmentService.GetStoresParameters;
        params.ClientId = this.owner_.ClientId;
        params.OnlyDefaultEntityStore = true;
        const defaultStore = await this._attachmentService.GetStores(params);
        if (!!defaultStore && defaultStore.length) {
          this.DefaultEntityStoreId = defaultStore[0].Id;
        }
        else {
          this.DefaultEntityStoreId = null;
        }
    }
    //#endregion Watches

    //#region Methods
    onTabClick(tab) {
        var $tabPane = $(tab.$el);

        if (!$tabPane.hasClass('loaded')) {
            switch (tab.label) {
                case 'Assets':
                    $tabPane
                        .children('[path][controlname]')
                        .fetchSmartControl({
                            params: {
                                OwnerId: this.owner_.Id
                            }
                        });
                    break;
            }
            $tabPane.addClass('loaded');
        }
    }

    async onClose_() {
        if (typeof this.onClose === 'function') {
            this.onClose();
        }
    }
    //#endregion Methods
}
