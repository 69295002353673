// TS API for Schema debt
// Auto-generated 09/24/24 10:36


declare var smartData: any;
// output classes:
export class AdvancedTermPromissoryNotes {
    Id: number=undefined;
    Name: string=undefined;
    LenderOwnerEntityId: number=undefined;
    LenderDescription: string=undefined;
    LenderClientId: number=undefined;
    DebtorOwnerEntityId: number=undefined;
    DebtorDescription: string=undefined;
    DebtorClientId: number=undefined;
    RateInformation: string=undefined;
    PaymentFrequency: string=undefined;
    CompoundingFrequency: string=undefined;
    MaturityDate: string=undefined;
    MaturityDescription: string=undefined;
    Amount: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    SysStartTime: string=undefined;
    SysEndTime: string=undefined;
    InceptionDate: string=undefined;
    Deletable: boolean=undefined;
    Notes: number=undefined;
    Created: string=undefined;
    Modified: string = undefined;
}
export class ExternalCashMovement {
    Id: number=undefined;
    PromissoryNoteTransactionId: number=undefined;
    FromOwnerEntityId: number=undefined;
    FromOwnerEntity: string=undefined;
    ToOwnerEntityId: number=undefined;
    ToOwnerEntity: string=undefined;
    Description: string=undefined;
    SourceEntityId: number=undefined;
    SourceEntityType: string=undefined;
    UseEntityId: number=undefined;
    UseEntityType: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string = undefined;
}
export class ExternalCashMovementExploded {
    Id: number=undefined;
    PromissoryNoteTransactionId: number=undefined;
    PromissoryNoteId: number=undefined;
    FromOwnerEntityId: number=undefined;
    ToOwnerEntityId: number=undefined;
    TransactionDate: string=undefined;
    Description: string=undefined;
    Amount: number=undefined;
    EntityAmount: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Type: string=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    ParentEntityId: number=undefined;
    ParentEntityType: string=undefined;
    ParentOwnerEntityId: number=undefined;
    ParentClientId: number=undefined;
    ProFormaDate: string=undefined;
    TransactionHeaderId: number = undefined;
}
export class Instruments {
    Id: number=undefined;
    Name: string=undefined;
    Description: string=undefined;
    StatusId: number=undefined;
    TypeId: number=undefined;
    Type: string=undefined;
    ClientId: number=undefined;
    OwnerEntityId: number=undefined;
    OwnerEntity: string=undefined;
    GrantorType: string=undefined;
    GrantorEntityId: number=undefined;
    CounterParty: string=undefined;
    AccountNumber: string=undefined;
    GeneralAssetId: number=undefined;
    InvestmentId: number=undefined;
    Amount: number=undefined;
    InceptionDate: string=undefined;
    RateSpread: number=undefined;
    RateBasis: string=undefined;
    Transactions: number=undefined;
    Deletable: boolean=undefined;
    PayFromBankAccountId: number=undefined;
    DrawIntoBankAccountId: number=undefined;
    GrantorBankAccountId: number = undefined;
}
export class InstrumentTransactions {
    Id: number=undefined;
    InstrumentId: number=undefined;
    Instrument: string=undefined;
    TypeId: number=undefined;
    TransactionType: string=undefined;
    OwnerEntityId: number=undefined;
    CalculatedOwnerEntityId: number=undefined;
    Date: string=undefined;
    Amount: number=undefined;
    ConsiderationValue: number=undefined;
    Principal: number=undefined;
    Interest: number=undefined;
    Comment: string=undefined;
    Source: string=undefined;
    SourceInstrumentTransactionId: number=undefined;
    TransactionHeaderId: number=undefined;
    HeaderUpdated: string=undefined;
    Description: string=undefined;
    BaseDirection: string=undefined;
    ConsiderationDirection: string=undefined;
    ConsiderationType: string=undefined;
    ConsiderationTypeId: number=undefined;
    ConsiderationDescription: string=undefined;
    LastModified: string=undefined;
    Created: string=undefined;
    Modified: string=undefined;
    InvestmentId: number = undefined;
    ToOwnerEntityId: number=undefined;
    FromOwnerEntityId: number=undefined;
}
export class InstrumentTransactionType {
    Id: number=undefined;
    Name: string=undefined;
    PaymentOrDraw: string=undefined;
    PrincipalOrInterest: string=undefined;
    AllowNegative: boolean=undefined;
    ConsiderationOption: string=undefined;
    CoAId: number=undefined;
    AutomaticTransactionTypeId: number=undefined;
    BaseDirection: string=undefined;
    RequireNegativeAndDisplayPositive: boolean = undefined;
}
export class InstrumentTypes {
    Id: number=undefined;
    Name: string=undefined;
    IsInvestment: boolean=undefined;
    IsSAFE: boolean=undefined;
    IsMortgage: boolean=undefined;
    IsRevolver: boolean=undefined;
    IsAnnuity: boolean=undefined;
    IsPromissoryNote: boolean=undefined;
    IsIntraClient: boolean=undefined;
    GrantorType: string = undefined;
}
export class PromissoryNotes {
    PromissoryNoteId: number=undefined;
    AdvancedTermPromissoryNoteId: number=undefined;
    AdvanceTermPromissoryNoteName: string=undefined;
    LenderClientId: number=undefined;
    LenderOwnerEntityId: number=undefined;
    LenderOwnerEntityNameShortest: string=undefined;
    DebtorClientId: number=undefined;
    DebtorOwnerEntityId: number=undefined;
    DebtorOwnerEntityNameShortest: string=undefined;
    Name: string=undefined;
    Description: string=undefined;
    InterestRate: number=undefined;
    RateInformation: string=undefined;
    Amount: number=undefined;
    AmountIsEstimate: boolean=undefined;
    PaymentFrequency: string=undefined;
    CompoundingFrequency: string=undefined;
    CompoundingPerPeriod: number=undefined;
    EffectiveInterestRate: number=undefined;
    InceptionDate: string=undefined;
    InceptionDateForPaymentDateCalculation: string=undefined;
    MaturityDate: string=undefined;
    MaturityDescription: string=undefined;
    Periods: number=undefined;
    CompoundingPeriods: number=undefined;
    CompoundingFrequencyCount: number=undefined;
    CompoundingFrequencyCountType: string=undefined;
    CoAIdAsset: number=undefined;
    CoAIdLiability: number=undefined;
    CoAIdInterestIncome: number=undefined;
    CoAIdInterestExpense: number=undefined;
    CoAIdAccruedInterestPayable: number=undefined;
    CoAIdAccruedInterestReceivable: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Created: string=undefined;
    Modified: string=undefined;
    OverrideCompoundingDates: string=undefined;
    Notes: string = undefined;
}
export class PromissoryNoteSchedule {
    Id: number=undefined;
    PromissoryNoteId: number=undefined;
    AsAt: string=undefined;
    Type: string=undefined;
    Comment: string=undefined;
    CompoundingPeriodNumber: number=undefined;
    PromissoryNoteTransactionId: number=undefined;
    PeriodStart: string=undefined;
    PeriodEnd: string=undefined;
    TransactionDate: string=undefined;
    AccruedInterest: number=undefined;
    Payment: number=undefined;
    BeginningBalance: number=undefined;
    EndingBalance: number=undefined;
    NumDays: number=undefined;
    InterestRate: number=undefined;
    JVInterestAccrued: number=undefined;
    JVInterestPaid: number=undefined;
    JVPrincipalPaid: number=undefined;
    Editable: boolean=undefined;
    Deletable: boolean=undefined;
    SubsequentPayments: number=undefined;
    Amount: number=undefined;
    Source: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Created: string=undefined;
    Modified: string=undefined;
    GLRecalculated: string=undefined;
    GLRecalculatedByTransactionId: number=undefined;
    IsOverrideCompoundingDate: boolean = undefined;
    LegacyNoteTransactionId: number=undefined;
    InterNotePaymentPromissoryNoteId: number=undefined;
    InterNotePaymentRecordType: string=undefined;
    InterNotePaymentCounterName: string=undefined;
    TransactionIsBalanced: boolean=undefined;
    TransactionIsBalancedDescription: string=undefined;
    CreditNoteId: number=undefined;
    CreditNoteTransactionHeaderId: number=undefined;
}
export class PromissoryNoteTransactionsLegacyAnalysis {
    Id: number=undefined;
    TransactionDate: string=undefined;
    Amount: number=undefined;
    Type: string=undefined;
    Comment: string=undefined;
    LegacyTotal: number=undefined;
    LegacyId: number=undefined;
    LegacyTransactionDate: string=undefined;
    LegacyAmount: number=undefined;
    LegacyType: string=undefined;
    LegacyComment: string=undefined;
    CreditNoteTransactionHeaderId: number=undefined;
    CreditNoteTransactionDescription: string=undefined;
    LegacySource: string=undefined;
    Status: string=undefined;
    Copyable: boolean = undefined;
}

// Parameter interfaces:

export interface CopyLegacyPromissoryNoteTransactionParameters {
    NoteTransactionId: number;
}

export interface GetAdvancedTermPromissoryNotesParameters {
    ClientId: number;
    LenderOwnerEntityId: number;
    DebtorOwnerEntityId: number;
    AdvancedTermPromissoryNoteId: number;
}

export interface GetExternalCashMovementParameters {
  ExternalCashMovementId: number;
  PromissoryNoteTransactionId: number;
}

export interface GetExternalCashMovementExplodedParameters {
    PromissoryNoteTransactionId: number;
    ExternalCashMovementId: number;
}

export interface GetInstrumentsParameters {
    InstrumentId: number;
    InvestmentId: number;
    TypeId: number;
    OwnerEntityId: number;
}

export interface GetInstrumentTransactionsParameters {
    InstrumentId: number;
    TransactionHeaderId: number;
    Direction: string;
}

export interface GetInstrumentTransactionTypeParameters {
    InstrumentId: number;
    Direction: string;
    TransactionHeaderId: number;
}

export interface GetInstrumentTypesParameters {
    IsInvestment: boolean;
}

export interface GetPromissoryNotesParameters {
    ClientId: number;
    LenderOwnerEntityId: number;
    DebtorOwnerEntityId: number;
    AdvancedTermPromissoryNoteId: number;
    PromissoryNoteId: number;
}

export interface GetPromissoryNoteScheduleParameters {
    PromissoryNoteId: number;
    AsOf: string;
    PromissoryNoteTransactionId: number;
    AsOfTransactionId: number;
}

export interface GetPromissoryNoteTransactionsLegacyAnalysisParameters {
    NoteId: number;
}

export class DebtService {

  CopyLegacyPromissoryNoteTransaction (params: CopyLegacyPromissoryNoteTransactionParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'debt.CopyLegacyPromissoryNoteTransaction',
        params: params
      });
    }

  GetAdvancedTermPromissoryNotes (params: GetAdvancedTermPromissoryNotesParameters): Promise<AdvancedTermPromissoryNotes[]> {
    return smartData({
        storedProcName: 'debt.GetAdvancedTermPromissoryNotes',
        params: params
      });
    }

  GetExternalCashMovement (params: GetExternalCashMovementParameters): Promise<ExternalCashMovement[]> {
    return smartData({
        storedProcName: 'debt.GetExternalCashMovement',
        params: params
      });
    }

  GetExternalCashMovementExploded (params: GetExternalCashMovementExplodedParameters): Promise<ExternalCashMovementExploded[]> {
    return smartData({
        storedProcName: 'debt.GetExternalCashMovementExploded',
        params: params
      });
    }

  GetInstruments (params: GetInstrumentsParameters): Promise<Instruments[]> {
    return smartData({
        storedProcName: 'debt.GetInstruments',
        params: params
      });
    }

  GetInstrumentTransactions (params: GetInstrumentTransactionsParameters): Promise<InstrumentTransactions[]> {
    return smartData({
        storedProcName: 'debt.GetInstrumentTransactions',
        params: params
      });
    }

  GetInstrumentTransactionType (params: GetInstrumentTransactionTypeParameters): Promise<InstrumentTransactionType[]> {
    return smartData({
        storedProcName: 'debt.GetInstrumentTransactionType',
        params: params
      });
    }

  GetInstrumentTypes (params: GetInstrumentTypesParameters): Promise<InstrumentTypes[]> {
    return smartData({
        storedProcName: 'debt.GetInstrumentTypes',
        params: params
      });
    }

  GetPromissoryNotes (params: GetPromissoryNotesParameters): Promise<PromissoryNotes[]> {
    return smartData({
        storedProcName: 'debt.GetPromissoryNotes',
        params: params
      });
    }

  GetPromissoryNoteSchedule (params: GetPromissoryNoteScheduleParameters): Promise<PromissoryNoteSchedule[]> {
    return smartData({
        storedProcName: 'debt.GetPromissoryNoteSchedule',
        params: params
      });
    }

  GetPromissoryNoteTransactionsLegacyAnalysis (params: GetPromissoryNoteTransactionsLegacyAnalysisParameters): Promise<PromissoryNoteTransactionsLegacyAnalysis[]> {
    return smartData({
        storedProcName: 'debt.GetPromissoryNoteTransactionsLegacyAnalysis',
        params: params
      });
    }
}
