
    import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
    import * as AssetService from '@/services/DAL/assetService';
    import * as VamService from '@/services/DAL/vamService';
    import ClientSelector from "@/components/form/ClientSelector.vue";
    import OwnerSelector from "@/components/form/OwnerSelector.vue";
    import formatters from '@/utils/formatters';
    import NamedKeySelector from '@/components/form/NamedKeySelector.vue';
    import Common from '@/utils/common';
    import FormattedInput from '@/components/form/FormattedInput.vue';
    import ChangeLogList from '@/components/other/tsChangeLogList.vue'
    import CommentLogList from '@/components/other/tsCommentLogList.vue'
    import AttachmentList from '@/js/Attachments/AttachmentList.vue'
    import debounce from 'lodash/debounce';
    import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';
    import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';
    import MicrosoftGraphClient, {
    GraphRequest,
    ResponseType,
    Client
    } from '@microsoft/microsoft-graph-client';
    import cloneDeep from 'lodash/cloneDeep';
    import ElementUI from 'element-ui';

    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;
    declare var SmartObject: any;
    declare var $;

    @Component({
        components: {
            ChangeLogList,
            ClientSelector,
            NamedKeySelector,
            CommentLogList,
            AttachmentList,
            FormattedInput
        }
    })
    export default class ValuablesEdit extends Vue {
    @Ref() readonly valuablesForm!: ElementUI.Form;
    @Ref() readonly commentLog!: CommentLogList;
    @Ref() readonly changeLog!: ChangeLogList;
    @Ref() readonly refValuablesTabs!: ElementUI.Tabs;

    //#region Private declarations for Services
    private _assetService: AssetService.AssetService;
    private _vamService: VamService.VamService;
    private _graphService: MicrosoftGraphService;
    private common: Common;
    private formatters: formatters;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({ type: Number }) readonly valuablesId: number; //pValuablesId
    @Prop({ type: Object }) readonly valuables: AssetService.Valuables; //pValuables
    @Prop({ type: Number, default: null }) readonly securityLevel: number;
    
    //#endregion Props

    //#region Data
    securityLevel_: number = null;
    valuablesId_: number = null
    valuables_ = {} as AssetService.Valuables;
    vendors =  [] as VamService.Vendors[];
    locations = [] as AssetService.ValuablesLocations[];
    valuablesCategories = [] as AssetService.ValuablesCategories[];

    //formValid = false
    SaleInvoiceFileInfo = {} as MicrosoftGraphTypes.DriveItem
    PurchaseInvoiceFileInfo = {} as MicrosoftGraphTypes.DriveItem
    commentDiary: string = null;
    storeId: number = null;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this._vamService = new VamService.VamService();
        this._graphService = new MicrosoftGraphService();

        if (this.securityLevel_ === null) {
            this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageValuables), 0);
        }

        if (this.valuables && Object.keys(this.valuables).length){
            this.valuables_ = cloneDeep(this.valuables);
            this.valuablesId_ = this.valuables_.ValuablesId;
        }
        else if (this.valuablesId > 0){
            this.valuablesId_ = this.valuablesId;
            this.fetchValuables();
        }
        else {
            this.valuables_ = new AssetService.Valuables();
            // this.isNew = true;
        }
        
        this.PurchaseInvoiceFileInfo = await this.getfileData(this.valuables_.PurchaseInvoiceFileId);
        this.SaleInvoiceFileInfo = await this.getfileData(this.valuables_.SaleInvoiceFileId);
        this.fetchLocations();
        this.fetchCategories();
    }
    //#endregion Lifecycle

    //#region Computed
    
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
        key => (this as any).veeFields[key].dirty
        );
    }        
    get attachmentParameters() {
        return {
            entityId: this.valuables_.Id
            , entityType: 'Valuables'
            , storeId: this.storeId
            , storeCategoryId: 120 //Valuables
            , clientId: this.valuables_.ClientId
        }
    }
    get costInputTitle() {
        if (this.valuables_.IsCostFromLedger) {
            return 'Cost is total of Ledger entries';
        }
        return '';

    }
    get selectedCategory(): AssetService.ValuablesCategories {
        if (this.valuables_.CategoryId && this.valuablesCategories.length){
            return this.valuablesCategories.find(cat => cat.CategoryId == this.valuables_.CategoryId);
        }
        else {
            return {} as AssetService.ValuablesCategories;
        }
    }
    get formValid(): boolean{
        const formValid = !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
        if (!formValid) return formValid;

        return true;
    }
    //#endregion Computed

    //#region Watches
    @Watch('valuables')
    async valuablesChanged(val: AssetService.Valuables, oldVal: AssetService.Valuables) {
        if (this.valuables && this.valuables.ValuablesId) {
            this.valuables_ = this.valuables;
        }
    }
    @Watch('valuablesId')
    async valuablesIdChanged(val: Number, oldVal: Number) {
        this.fetchValuables();
    }
    @Watch('valuables_', {deep: true})
    async valuables_Changed(val: AssetService.Valuables, oldVal: AssetService.Valuables) {
        this.validateForm();
    }
    @Watch('valuables_.ClientId', {deep: true})
    async ClientIdChanged(val: AssetService.Valuables, oldVal: AssetService.Valuables) {
        this.fetchVendors();
    }

    //#endregion Watches
    //#region Methods
    validateSaleVendorOther(rule, value, callback){
        if (this.valuables_.SaleToPartyVendorId > -1) {
            callback();
        }
        else if (value === '' || value === null) {
            callback(new Error('Other Sale To Party is required'));
        }
        else {
            callback();
        }
    }
    validatePurchaseVendorOther(rule, value, callback){
        if (this.valuables_.PurchaseVendorId > -1) {
            callback();
        }
        else if (value === '' || value === null) {
            callback(new Error('Other Vendor is required'));
        }
        else {
            callback();
        }
    }
    validateLocation(rule, value, callback){
        if (this.valuables_.Location && this.valuables_.Location.length > 1) { //min length 2
            callback();
        }
        else if ((this.valuables_.HasSaleInvoice || this.valuables_.SaleDate != null || this.valuables_.SalePrice != null)) {
            callback(); //Location not required when Sold
        }
        else {
            callback(new Error('Location is Required'));
        }
    }

    validateForm(){
        // debounce(function () {
        //     if (this.$refs['valuablesForm']) {
        //         this.$refs['valuablesForm'].validate((valid) => {
        //             this.formValid = valid;
        //         });
        //     }
        //     else {
        //         this.formValid = false;
        //     }
        // }, 500)
    }
    async getfileData(fileId){
        if (fileId){
            try {
                const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
                return await graphClient.api('drive/items/' + fileId).get();
            }
            catch (err){
                console.error(err);
            }
        }
        else {
            return {} as MicrosoftGraphTypes.DriveItem;
        }
    }

    async fetchLocations() {
        const params = {} as AssetService.GetValuablesLocationsParameters;
        this.locations = await this._assetService.GetValuablesLocations(params);
    }
    async fetchCategories() {
        this.valuablesCategories = await this._assetService.GetValuablesCategories();
    }

    async fetchValuables() {
        const params = {} as AssetService.GetValuablesParameters;
        params.ValueablesId = this.valuablesId;
        const valuables = await this._assetService.GetValuables(params);
        if (valuables.length == 1){
            this.valuables_ = valuables[0];
        }
    }
    async fetchVendors() {
        if (this.valuables_ && this.valuables_.ClientId){
            const params = {} as VamService.GetVendorsParameters;
            params.ClientId = this.valuables_.ClientId;
            this.vendors = await this._vamService.GetVendors(params);
        }
        else {
            this.vendors = [] as VamService.Vendors[];
        }
    }
    closeCard() {
        this.$emit('closed');
    }
    openFile(url) {
        window.open(url, '_blank');
    }
    fieldIsDirty(field: string): boolean{
        return (this.$validator.fields.find({name: field}) && this.$validator.fields.find({name: field}).flags.dirty);
    }
    setStore(client: VamService.UserClients){
        const settings: Array<any> = JSON.parse(client.SettingsJSON);
        if (settings){
            this.storeId = null;
            let setting: any;
            if (settings){
                setting = settings.find(setting => setting.Setting == 'attachment.Store.Valuables.Default');
                if (setting){
                    this.storeId = setting.ValueInt;
                }
            }
            if (!this.storeId) {
                this.$alert('No File Store has been configured for the selected Client');
            }
        }
    }
    clientSet(client: VamService.UserClients){
        this.valuables_.OwnerId = client.EntityId;
        this.valuables_.Owner = client.Name;
        this.setStore(client);
    }
    async saveValuables() {
        // this.valuablesForm.validate(async (valid) => {
        if (this.formValid) {
            //make a local copy of the object with some logic to check for actual changes:
            //undefined will ingore the parameter entirely, so the value won't be updated or logged.
            var item = {
                OwnerId: this.fieldIsDirty('Client') ? this.valuables_.OwnerId : undefined //Owner is driven by changing Client
                ,CategoryId: this.fieldIsDirty('Category') ? this.valuables_.CategoryId : undefined
                , Type: this.fieldIsDirty('Type') ? this.valuables_.Type : undefined
                , Principal: this.fieldIsDirty('Principal') ? this.valuables_.Principal : undefined
                , SaleInvoiceId: this.fieldIsDirty('SaleInvoiceId') ? this.valuables_.SaleInvoiceId : undefined
                , Description: this.fieldIsDirty('Description') ? this.valuables_.Description : undefined
                , Cost: this.fieldIsDirty('Cost') ? this.valuables_.Cost : undefined
                , SaleDate: this.fieldIsDirty('SaleDate') ? this.valuables_.SaleDate : undefined
                , Source: this.fieldIsDirty('Source') ? this.valuables_.Source : undefined
                , Location: this.fieldIsDirty('Location') ? this.valuables_.Location : undefined
                , InsuranceId: this.fieldIsDirty('InsuranceId') ? this.valuables_.InsuranceId : undefined
                , InvoiceId: this.fieldIsDirty('InvoiceId') ? this.valuables_.InvoiceId : undefined
                , PurchaseVendorId: this.fieldIsDirty('PurchaseVendorId') ? this.valuables_.PurchaseVendorId : undefined
                , PurchaseFromOther: this.fieldIsDirty('PurchaseFromOther') ? this.valuables_.PurchaseFromOther : undefined
                , PurchaseFromOtherVendorInvoiceId: this.fieldIsDirty('PurchaseFromOtherVendorInvoiceId') ? this.valuables_.PurchaseFromOtherVendorInvoiceId : undefined
                , PurchaseDate: this.fieldIsDirty('PurchaseDate') ? this.valuables_.PurchaseDate : undefined
                , SalePrice: this.fieldIsDirty('SalePrice') ? this.valuables_.SalePrice : undefined
                , SaleToPartyVendorId: this.fieldIsDirty('SaleToPartyVendorId') ? this.valuables_.SaleToPartyVendorId : undefined
                , SalePartyVendorOther: this.fieldIsDirty('SalePartyVendorOther') ? this.valuables_.SalePartyVendorOther : undefined
            }
            //additional cleaning logic:
            if (item.SaleToPartyVendorId > -1) {
                item.SalePartyVendorOther = null
            }
            if (item.PurchaseVendorId > -1) {
                item.PurchaseFromOther = null
            }

            // not sure about this yet.
            // if (item.InvoiceId > -1) {
            //     item.PurchaseFromOtherVendorInvoiceId = null
            // }

            if (!item.OwnerId && !!this.valuables_.OwnerId) item.OwnerId = this.valuables_.OwnerId; // Client won't be dirty when it's set automatically for a user that only has one client

            let success;
            const wasNew = (this.valuables_.ValuablesId == -1);
            if (wasNew) {
                const so = new SmartObject('Valuables');
                success = await so.createObject(item);
            }
            else {
                const so = new SmartObject('Valuables', this.valuablesId_);
                success = await so.updateObject(item);
            }
            if (success) {
                if (success !== this.valuables_.ValuablesId) {
                    this.valuables_.ValuablesId = success;
                    this.valuablesId_ = success;
                }
                this.$notify.success('Valuables Updated');
                if (this.changeLog) { //won't exist on new item, but the changing of the id from -1 to a regular id will cause it to refresh
                    this.changeLog.fetchChangeLog();
                }

                //set comment, if any:
                if (this.commentDiary != '' && this.commentDiary != null) {
                    const soC = new SmartObject('CommentLog');
                    const commentId = await soC.createObject({
                        EntityType: "Valuables"
                        , EntityId: this.valuables_.ValuablesId
                        , Comment: this.commentDiary
                    });
                    if (commentId > 0) {
                        this.$notify.success('Comment Added');
                        if (this.commentLog) {//won't exist on new item, but the changing of the id from -1 to a regular id will cause it to refresh
                            this.commentLog.fetchCommentLog();
                        }
                        this.commentDiary = null;
                    }
                }
                await this.fetchValuables();
                if (wasNew){
                    this.$emit('created', this.valuables_);
                }
                else {
                    this.$emit('update:valuables', this.valuables_)
                }

            }
        }
        else {
            this.$notify.error('Not Saved.  See Validation Errors.');
            return false;
        }
        // });
    }
    async onTabClick(tab) {
        switch (tab.label) {
            case 'Valuation':
                var $ucValuationList = $((this.$refs.refValuablesTabs as Vue).$el).find('#ucValuationList');
                if (!$ucValuationList.hasClass('loaded')) {
                    $ucValuationList.fetchSmartControl({
                        params: {
                            EntityType: 'Valuables'
                            , EntityId: this.valuables_.ValuablesId
                            , CanEdit: this.securityLevel_ >= this.$namedKey.SecurityLevel.Admin
                        }
                        , collapsible: false
                        , showSpinner: true
                    });
                    $ucValuationList.addClass('loaded');
                }
                break;
        }
    }
}
