//js API for Schema liq
//Auto-generated 06/06/23 16:31
 

var mixinSchema_liq = {
    methods: {
        /** @description liq.AdjustAllocationForScheduleTypeChange
         * @param {number} agreementId - 
         */
		 liq_AdjustAllocationForScheduleTypeChange: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.liq_AdjustAllocationForScheduleTypeChange({
					AgreementId: agreementId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'liq.AdjustAllocationForScheduleTypeChange'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description liq.AllocateAgreement
         * @param {number} agreementId - 
         */
		, liq_AllocateAgreement: function (params, onSuccess, onError, async) {
			/*
				this.AllocateAgreement = await this.liq_AllocateAgreement({
					AgreementId: agreementId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.AllocateAgreement'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.CompleteInvoice
         * @param {number} invoiceId - 
         * @param {number} statusId - 
         * @param {string} completedNotes - 
         * @param {date} paidDate - 
         * @param {date} completedDate - 
         */
		, liq_CompleteInvoice: function (invoiceId, statusId, completedNotes, paidDate, completedDate) {
			/*
				this.CompleteInvoice = this.liq_CompleteInvoice(
					null // invoiceId
					, null // statusId
					, null // completedNotes
					, null // paidDate
					, null // completedDate
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liq.CompleteInvoice'
			  , params: {
					InvoiceId: invoiceId
					, StatusId: statusId
					, CompletedNotes: completedNotes
					, PaidDate: paidDate
					, CompletedDate: completedDate
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description liq.ConfirmTransactionMatch
         * @param {number} transactionId - 
         * @param {number} entityId - 
         * @param {string} entityType - 
         * @param {boolean} reviewForTax - 
         * @param {number} departmentId - 
         * @param {number} matchedTransactionId - 
         * @param {boolean} updateInvestmentTransactionAmount - 
         * @param {string} comment - 
         */
		, liq_ConfirmTransactionMatch: function (params, onSuccess, onError, async) {
			/*
				this.ConfirmTransactionMatch = await this.liq_ConfirmTransactionMatch({
					TransactionId: transactionId
					, EntityId: entityId
					, EntityType: entityType
					, ReviewForTax: reviewForTax
					, DepartmentId: departmentId
					, MatchedTransactionId: matchedTransactionId
					, UpdateInvestmentTransactionAmount: updateInvestmentTransactionAmount
					, Comment: comment
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.ConfirmTransactionMatch'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.CopyAgreement
         * @param {number} agreementId - 
         */
		, liq_CopyAgreement: function (params, onSuccess, onError, async) {
			/*
				this.CopyAgreement = await this.liq_CopyAgreement({
					AgreementId: agreementId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.CopyAgreement'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.CreateCharitableInvoice
         * @param {number} charitableAgreementId - 
         * @param {number} statusId - 
         */
		, liq_CreateCharitableInvoice: function (params, onSuccess, onError, async) {
			/*
				this.CreateCharitableInvoice = await this.liq_CreateCharitableInvoice({
					CharitableAgreementId: charitableAgreementId
					, StatusId: statusId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.CreateCharitableInvoice'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.CreateInvoiceEmails
         * @param {number} logId - 
         */
		, liq_CreateInvoiceEmails: function (params, onSuccess, onError, async) {
			/*
				this.CreateInvoiceEmails = await this.liq_CreateInvoiceEmails({
					LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.CreateInvoiceEmails'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.CreateManualInvoiceBankTransaction
         * @param {number} invoiceId - 
         * @param {number} bankAccountId - 
         */
		, liq_CreateManualInvoiceBankTransaction: function (params, onSuccess, onError, async) {
			/*
				this.CreateManualInvoiceBankTransaction = await this.liq_CreateManualInvoiceBankTransaction({
					InvoiceId: invoiceId
					, BankAccountId: bankAccountId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.CreateManualInvoiceBankTransaction'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Accounts using liq.GetAccounts
         * @param {number} clientId - 
         * @param {string} orderBy - 
         * @param {string} accountType - 
         * @param {number} accountId - 
         * @param {number} ownerId - 
         * @param {number} cOAId - 
         * @param {boolean} manualAccountsOnly - 
         */
		, liq_GetAccounts: function (params, onSuccess, onError, async) {
			/*
				this.Accounts = await this.liq_GetAccounts({
					ClientId: clientId
					, OrderBy: orderBy
					, AccountType: accountType
					, AccountId: accountId
					, OwnerId: ownerId
					, COAId: cOAId
					, ManualAccountsOnly: manualAccountsOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAccounts'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Accounts using liq.GetAccounts; Return Single Object
         * @param {number} clientId - 
         * @param {string} orderBy - 
         * @param {string} accountType - 
         * @param {number} accountId - 
         * @param {number} ownerId - 
         * @param {number} cOAId - 
         * @param {boolean} manualAccountsOnly - 
         */
		, liq_GetAccounts_Object: function (params, onSuccess, onError, async) {
			/*
				this.Accounts = await this.liq_GetAccounts_Object({
					ClientId: clientId
					, OrderBy: orderBy
					, AccountType: accountType
					, AccountId: accountId
					, OwnerId: ownerId
					, COAId: cOAId
					, ManualAccountsOnly: manualAccountsOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'liq.GetAccounts'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get NEW Accounts using columns of liq.GetAccounts
         */
		, liq_GetAccounts_New: function () {
			/*
				this.Accounts = await this.liq_GetAccounts_New();
			*/
					return {
						Id: null
						, BankAccountId: null
						, Client: null
						, ClientId: null
						, Name: null
						, NameNumberMasked: null
						, DisplayName: null
						, Description: null
						, OwnerName: null
						, OwnerId: null
						, Number: null
						, CashAsOf: null
						, Cash: null
						, CashShortDateUser: null
						, CashSource: null
						, Type: null
						, IsKeyAccount: null
						, DashboardOrder: null
						, AcquiredDate: null
						, ClosedDate: null
						, BankId: null
						, BankName: null
						, BankNameNumberMasked: null
						, BalanceSheetCategoryId: null
						, SourceNameDisplay: null
						, NumberDisplayMasked: null
						, AlertMinimumBalance: null
						, AlertMinimumBalanceDisplay: null
						, AlertMinimumBalance0: null
						, Style: null
						, COADisplay: null
						, COAId: null
						, ManualTransactions: null
						, PrimaryAccountForOwner: null
						, Available: null
						, AvailableAsOf: null
						, MaskNameType: null
						, CreateDateLabel: null
						, ModifyDateLabel: null
						, PrimaryNumber: null
						, StartingBalance: null
						, StartingBalanceMonth: null
						};
			}
        /** @description Get AgreementBudget using liq.GetAgreementBudget
         * @param {number} agreementId - 
         */
		, liq_GetAgreementBudget: function (params, onSuccess, onError, async) {
			/*
				this.AgreementBud = await this.liq_GetAgreementBudget({
					AgreementId: agreementId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAgreementBudget'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get AgreementBudget using liq.GetAgreementBudget; Return Single Object
         * @param {number} agreementId - 
         */
		, liq_GetAgreementBudget_Object: function (params, onSuccess, onError, async) {
			/*
				this.AgreementBud = await this.liq_GetAgreementBudget_Object({
					AgreementId: agreementId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'liq.GetAgreementBudget'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get NEW AgreementBudget using columns of liq.GetAgreementBudget
         */
		, liq_GetAgreementBudget_New: function () {
			/*
				this.AgreementBud = await this.liq_GetAgreementBudget_New();
			*/
					return {
						AgreementId: null
						, BudgetAmount: null
						, Invoiced: null
						, Paid: null
						, Remaining: null
						, AgreementPaid: null
						, InvoicePaid: null
						, ReferenceOnlyPaid: null
						, Unpaid: null
						, RemainingStyle: null
						};
			}
        /** @description Get AgreementGroups using liq.GetAgreementGroups
         * @param {number} clientId - 
         * @param {number} vendorId - 
         * @param {boolean} aggregateUnitsOnly - 
         */
		, liq_GetAgreementGroups: function (params, onSuccess, onError, async) {
			/*
				this.AgreementGroups = await this.liq_GetAgreementGroups({
					ClientId: clientId
					, VendorId: vendorId
					, AggregateUnitsOnly: aggregateUnitsOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAgreementGroups'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get NEW AgreementGroups using columns of liq.GetAgreementGroups
         */
		, liq_GetAgreementGroups_New: function () {
			/*
				this.AgreementGroups = await this.liq_GetAgreementGroups_New();
			*/
					return {
						AgreementGroupId: null
						, Name: null
						, VendorId: null
						, VendorSpecific: null
						, VendorName: null
						, ClientId: null
						, ClientName: null
						, Label: null
						, AggregateUnits: null
						, UnitsLabel: null
						, NotifyThresholdMinimum: null
						, NotifyEmail: null
						, NumAgreements: null
						, Deletable: null
						};
			}
        /** @description Get AgreementGroupUnitBalance using liq.GetAgreementGroupUnitBalance
         * @param {number} agreementGroupId - 
         */
		, liq_GetAgreementGroupUnitBalance: function (params, onSuccess, onError, async) {
			/*
				this.AgreementGroupUnitBalance = await this.liq_GetAgreementGroupUnitBalance({
					AgreementGroupId: agreementGroupId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAgreementGroupUnitBalance'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Agreements using liq.GetAgreements
         * @param {number} clientId - 
         * @param {number} agreementTypeId - 
         * @param {number} vendorId - 
         * @param {number} agreementId - 
         * @param {number} ownerId - 
         * @param {boolean} excludeBudgeted - 
         * @param {number} groupId - 
         * @param {boolean} currentOnly - 
         */
		, liq_GetAgreements: function (params, onSuccess, onError, async) {
			/*
				this.Agreements = await this.liq_GetAgreements({
					ClientId: clientId
					, AgreementTypeId: agreementTypeId
					, VendorId: vendorId
					, AgreementId: agreementId
					, OwnerId: ownerId
					, ExcludeBudgeted: excludeBudgeted
					, GroupId: groupId
					, CurrentOnly: currentOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAgreements'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Agreements using liq.GetAgreements; Return Single Object
         * @param {number} clientId - 
         * @param {number} agreementTypeId - 
         * @param {number} vendorId - 
         * @param {number} agreementId - 
         * @param {number} ownerId - 
         * @param {boolean} excludeBudgeted - 
         * @param {number} groupId - 
         * @param {boolean} currentOnly - 
         */
		, liq_GetAgreements_Object: function (params, onSuccess, onError, async) {
			/*
				this.Agreements = await this.liq_GetAgreements_Object({
					ClientId: clientId
					, AgreementTypeId: agreementTypeId
					, VendorId: vendorId
					, AgreementId: agreementId
					, OwnerId: ownerId
					, ExcludeBudgeted: excludeBudgeted
					, GroupId: groupId
					, CurrentOnly: currentOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'liq.GetAgreements'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get NEW Agreements using columns of liq.GetAgreements
         */
		, liq_GetAgreements_New: function () {
			/*
				this.Agreements = await this.liq_GetAgreements_New();
			*/
					return {
						AgreementId: null
						, Name: null
						, TypeId: null
						, AgreementType: null
						, OwnerId: null
						, OwnerName: null
						, ClientId: null
						, Client: null
						, VendorId: null
						, VendorName: null
						, AccountId: null
						, AccountNumber: null
						, AccountName: null
						, BankAccountNameDisplay: null
						, InceptionDate: null
						, TerminationDate: null
						, CreateDate: null
						, CreateUser: null
						, ModifyDate: null
						, ModifyUser: null
						, Created: null
						, Modified: null
						, ScheduleType: null
						, Identifier: null
						, IncludeOnBalanceSheet: null
						, BalanceSheetCategoryId: null
						, ScheduleError: null
						, Configuration: null
						, GroupId: null
						, GroupName: null
						, GroupVendorSpecific: null
						, AggregateUnits: null
						, UnitsLabel: null
						, NotifyThresholdMinimum: null
						, NotifyEmail: null
						, DefaultUnitsMethod: null
						, CharitableDonationPayBy: null
						, UI: null
						, ApprovedRejected: null
						, PledgeCommitment: null
						, ShowPledgeFields: null
						, PledgeRemainingCommitment: null
						, PledgeActualTermination: null
						, InvoicesExpected: null
						, CharitableEINInvalidatedLogId: null
						, Deletable: null
						, ErrorMessage: null
						, HasError: null
						};
			}
        /** @description Get AgreementsDisplay using liq.GetAgreementsDisplay
         * @param {number} clientId - 
         * @param {number} vendorId - 
         * @param {number} agreementId - 
         * @param {number} ownerId - 
         * @param {boolean} excludeBudgeted - 
         * @param {number} groupId - 
         */
		, liq_GetAgreementsDisplay: function (params, onSuccess, onError, async) {
			/*
				this.AgreementsDisplay = await this.liq_GetAgreementsDisplay({
					ClientId: clientId
					, VendorId: vendorId
					, AgreementId: agreementId
					, OwnerId: ownerId
					, ExcludeBudgeted: excludeBudgeted
					, GroupId: groupId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAgreementsDisplay'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get AgreementsForInvoiceReference using liq.GetAgreementsForInvoiceReference
         * @param {number} ownerId - 
         * @param {number} vendorId - 
         * @param {boolean} excludeBudgeted - 
         * @param {date} invoiceDate - 
         */
		, liq_GetAgreementsForInvoiceReference: function (params, onSuccess, onError, async) {
			/*
				this.AgreementsForInvoiceReference = await this.liq_GetAgreementsForInvoiceReference({
					OwnerId: ownerId
					, VendorId: vendorId
					, ExcludeBudgeted: excludeBudgeted
					, InvoiceDate: invoiceDate
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAgreementsForInvoiceReference'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get NEW AgreementsForInvoiceReference using columns of liq.GetAgreementsForInvoiceReference
         */
		, liq_GetAgreementsForInvoiceReference_New: function () {
			/*
				this.AgreementsForInvoiceReference = await this.liq_GetAgreementsForInvoiceReference_New();
			*/
					return {
						AgreementId: null
						, Name: null
						, BankAccountNameDisplay: null
						, OwnerId: null
						, VendorId: null
						, ScheduleType: null
						, Schedule: null
						, Amount: null
						, HasScheduledItems: null
						, GroupId: null
						, AggregateUnits: null
						, UnitsLabel: null
						, NotifyThresholdMinimum: null
						, NotifyEmail: null
						, DefaultUnitsMethod: null
						, DefaultUnitsMultiplier: null
						, WorkflowId: null
						, PaysAutomatically: null
						, BypassInvoiceReview: null
						, TerminationDate: null
						, InceptionDate: null
						, InvoiceDateToTermStatus: null
						};
			}
        /** @description Get AgreementTransactions using liq.GetAgreementTransactions
         * @param {number} agreementId - 
         */
		, liq_GetAgreementTransactions: function (params, onSuccess, onError, async) {
			/*
				this.AgreementTransactions = await this.liq_GetAgreementTransactions({
					AgreementId: agreementId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAgreementTransactions'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get AgreementTypes using liq.GetAgreementTypes
         */
		, liq_GetAgreementTypes: function (params, onSuccess, onError, async) {
			/*
				this.AgreementTypes = await this.liq_GetAgreementTypes({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAgreementTypes'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Allocation using liq.GetAllocation
         * @param {string} entityType - 
         * @param {number} entityId - 
         */
		, liq_GetAllocation: function (params, onSuccess, onError, async) {
			/*
				this.Allocation = await this.liq_GetAllocation({
					EntityType: entityType
					, EntityId: entityId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAllocation'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get NEW Allocation using columns of liq.GetAllocation
         */
		, liq_GetAllocation_New: function () {
			/*
				this.Allocation = await this.liq_GetAllocation_New();
			*/
					return {
						Id: null
						, EntityType: null
						, EntityId: null
						, Amount: null
						, AccountId: null
						, COAId: null
						, AccountName: null
						, AccountPath: null
						, DepartmentId: null
						, DepartmentName: null
						, DisplayModified: null
						, ModifyOrCreateDate: null
						, ModifyOrCreateUserFirstLast: null
						, CreateUser: null
						, CreateDate: null
						, ModifyUser: null
						, ModifyDate: null
						, ChartOfAccountsArray: null
						, OwnerId: null
						, Pct: null
						};
			}
        /** @description Get AmexTransactionImportPossibleDuplicates using liq.GetAmexTransactionImportPossibleDuplicates
         * @param {number} logId - 
         */
		, liq_GetAmexTransactionImportPossibleDuplicates: function (params, onSuccess, onError, async) {
			/*
				this.AmexTransactionImportPossibleDuplicates = await this.liq_GetAmexTransactionImportPossibleDuplicates({
					LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAmexTransactionImportPossibleDuplicates'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get AmexTransactionImportProcesses using liq.GetAmexTransactionImportProcesses
         */
		, liq_GetAmexTransactionImportProcesses: function (params, onSuccess, onError, async) {
			/*
				this.AmexTransactionImportProcesses = await this.liq_GetAmexTransactionImportProcesses({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetAmexTransactionImportProcesses'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get BankAccountList using liq.GetBankAccountList
         * @param {number} clientId - 
         * @param {string} accountType - 
         * @param {number} accountId - 
         * @param {number} ownerId - 
         * @param {number} cOAId - 
         * @param {boolean} manualAccountsOnly - 
         * @param {number} bankId - 
         */
		, liq_GetBankAccountList: function (params, onSuccess, onError, async) {
			/*
				this.BankAccountList = await this.liq_GetBankAccountList({
					ClientId: clientId
					, AccountType: accountType
					, AccountId: accountId
					, OwnerId: ownerId
					, COAId: cOAId
					, ManualAccountsOnly: manualAccountsOnly
					, BankId: bankId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetBankAccountList'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get BankAccountSigners using liq.GetBankAccountSigners
         * @param {number} bankAccountId - 
         */
		, liq_GetBankAccountSigners: function (params, onSuccess, onError, async) {
			/*
				this.BankAccountSigners = await this.liq_GetBankAccountSigners({
					BankAccountId: bankAccountId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetBankAccountSigners'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get BankAccountStatistics using liq.GetBankAccountStatistics
         * @param {number} bankAccountId - 
         */
		, liq_GetBankAccountStatistics: function (params, onSuccess, onError, async) {
			/*
				this.BankAccountStatistics = await this.liq_GetBankAccountStatistics({
					BankAccountId: bankAccountId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetBankAccountStatistics'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get BankBalanceImportPossibleIssues using liq.GetBankBalanceImportPossibleIssues
         * @param {number} logId - 
         */
		, liq_GetBankBalanceImportPossibleIssues: function (params, onSuccess, onError, async) {
			/*
				this.BankBalanceImportPossibleIssues = await this.liq_GetBankBalanceImportPossibleIssues({
					LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetBankBalanceImportPossibleIssues'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get BankBalanceImportProcesses using liq.GetBankBalanceImportProcesses
         */
		, liq_GetBankBalanceImportProcesses: function (params, onSuccess, onError, async) {
			/*
				this.BankBalanceImportProcesses = await this.liq_GetBankBalanceImportProcesses({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetBankBalanceImportProcesses'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get BankDepositoryAccountReconciliations using liq.GetBankDepositoryAccountReconciliations
         * @param {number} clientId - 
         * @param {number} year - 
         * @param {number} month - 
         * @param {number} reconciliationId - 
         */
		, liq_GetBankDepositoryAccountReconciliations: function (params, onSuccess, onError, async) {
			/*
				this.BankDepositoryAccountReconciliations = await this.liq_GetBankDepositoryAccountReconciliations({
					ClientId: clientId
					, Year: year
					, Month: month
					, ReconciliationId: reconciliationId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetBankDepositoryAccountReconciliations'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get BankTransactionImportPossibleDuplicates using liq.GetBankTransactionImportPossibleDuplicates
         * @param {number} logId - 
         */
		, liq_GetBankTransactionImportPossibleDuplicates: function (params, onSuccess, onError, async) {
			/*
				this.BankTransactionImportPossibleDuplicates = await this.liq_GetBankTransactionImportPossibleDuplicates({
					LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetBankTransactionImportPossibleDuplicates'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get BankTransactionImportProcesses using liq.GetBankTransactionImportProcesses
         */
		, liq_GetBankTransactionImportProcesses: function (params, onSuccess, onError, async) {
			/*
				this.BankTransactionImportProcesses = await this.liq_GetBankTransactionImportProcesses({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetBankTransactionImportProcesses'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get CashChartOfAccounts using liq.GetCashChartOfAccounts
         * @param {number} clientId - 
         */
		, liq_GetCashChartOfAccounts: function (clientId) {
			/*
				this.CashChartOfAccounts = this.liq_GetCashChartOfAccounts(
					null // clientId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liq.GetCashChartOfAccounts'
			  , params: {
					ClientId: clientId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get CashTransfers using liq.GetCashTransfers
         * @param {number} transferId - 
         * @param {number} statusId - 
         * @param {boolean} includeInBalanceOnly - 
         * @param {number} fromBankAccountId - 
         * @param {number} toBankAccountId - 
         * @param {boolean} excludeFuture - 
         * @param {number} clientId - 
         * @param {number} approvalId - 
         */
		, liq_GetCashTransfers: function (params, onSuccess, onError, async) {
			/*
				this.CashTransfers = await this.liq_GetCashTransfers({
					TransferId: transferId
					, StatusId: statusId
					, IncludeInBalanceOnly: includeInBalanceOnly
					, FromBankAccountId: fromBankAccountId
					, ToBankAccountId: toBankAccountId
					, ExcludeFuture: excludeFuture
					, ClientId: clientId
					, ApprovalId: approvalId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetCashTransfers'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get CashTransferTypes using liq.GetCashTransferTypes
         */
		, liq_GetCashTransferTypes: function (params, onSuccess, onError, async) {
			/*
				this.CashTransferTypes = await this.liq_GetCashTransferTypes({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetCashTransferTypes'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ChaseCreditCardTransactionImportPossibleDuplicates using liq.GetChaseCreditCardTransactionImportPossibleDuplicates
         * @param {number} logId - 
         */
		, liq_GetChaseCreditCardTransactionImportPossibleDuplicates: function (params, onSuccess, onError, async) {
			/*
				this.ChaseCreditCardTransactionImportPossibleDuplicates = await this.liq_GetChaseCreditCardTransactionImportPossibleDuplicates({
					LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetChaseCreditCardTransactionImportPossibleDuplicates'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ChaseCreditCardTransactionImportProcesses using liq.GetChaseCreditCardTransactionImportProcesses
         */
		, liq_GetChaseCreditCardTransactionImportProcesses: function (params, onSuccess, onError, async) {
			/*
				this.ChaseCreditCardTransactionImportProcesses = await this.liq_GetChaseCreditCardTransactionImportProcesses({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetChaseCreditCardTransactionImportProcesses'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get CustomerNamesForRecognizedInvoice using liq.GetCustomerNamesForRecognizedInvoice
         * @param {number} recognizedInvoiceId - 
         */
		, liq_GetCustomerNamesForRecognizedInvoice: function (params, onSuccess, onError, async) {
			/*
				this.CustomerNamesForRecognizedInvoice = await this.liq_GetCustomerNamesForRecognizedInvoice({
					RecognizedInvoiceId: recognizedInvoiceId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetCustomerNamesForRecognizedInvoice'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get EntityTypes using liq.GetEntityTypes
         * @param {string} form - 
         */
		, liq_GetEntityTypes: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.liq_GetEntityTypes({
					Form: form
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'liq.GetEntityTypes'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ExpectedUsesAndSources using liq.GetExpectedUsesAndSources
         * @param {number} clientId - 
         * @param {number} configurationId - 
         */
		, liq_GetExpectedUsesAndSources: function (params, onSuccess, onError, async) {
			/*
				this.ExpectedUsesAndSources = await this.liq_GetExpectedUsesAndSources({
					ClientId: clientId
					, ConfigurationId: configurationId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetExpectedUsesAndSources'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Frequencies using liq.GetFrequencies
         */
		, liq_GetFrequencies: function () {
			/*
				this.Frequencies = this.liq_GetFrequencies(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liq.GetFrequencies'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get InvoiceIngestionStores using liq.GetInvoiceIngestionStores
         * @param {number} clientId - 
         */
		, liq_GetInvoiceIngestionStores: function (params, onSuccess, onError, async) {
			/*
				this.InvoiceIngestionStores = await this.liq_GetInvoiceIngestionStores({
					ClientId: clientId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetInvoiceIngestionStores'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get InvoiceRegister using liq.GetInvoiceRegister
         * @param {string} departmentIdList - 
         * @param {string} accountIdList - 
         * @param {string} vendorIdList - 
         * @param {string} ownerIdList - 
         * @param {string} clientIdList - 
         * @param {boolean} outstanding - 
         * @param {date} invoiceDateRangeStart - 
         * @param {date} invoiceDateRangeEnd - 
         * @param {date} invoiceDueDateRangeStart - 
         * @param {date} invoiceDueDateRangeEnd - 
         * @param {date} postedDateRangeStart - 
         * @param {date} postedDateRangeEnd - 
         * @param {number} invoiceId - 
         */
		, liq_GetInvoiceRegister: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.liq_GetInvoiceRegister({
					DepartmentIdList: departmentIdList
					, AccountIdList: accountIdList
					, VendorIdList: vendorIdList
					, OwnerIdList: ownerIdList
					, ClientIdList: clientIdList
					, Outstanding: outstanding
					, InvoiceDateRangeStart: invoiceDateRangeStart
					, InvoiceDateRangeEnd: invoiceDateRangeEnd
					, InvoiceDueDateRangeStart: invoiceDueDateRangeStart
					, InvoiceDueDateRangeEnd: invoiceDueDateRangeEnd
					, PostedDateRangeStart: postedDateRangeStart
					, PostedDateRangeEnd: postedDateRangeEnd
					, InvoiceId: invoiceId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/

		if (typeof params.DepartmentIdList === "object" && params.DepartmentIdList != null && params.DepartmentIdList.length > 0) {
                params.DepartmentIdList = params.DepartmentIdList.join(',');
            }
			else if (typeof params.DepartmentIdList === "object" && params.DepartmentIdList != null && params.DepartmentIdList.length == 0) {
				params.DepartmentIdList = null;
			}
		if (typeof params.AccountIdList === "object" && params.AccountIdList != null && params.AccountIdList.length > 0) {
                params.AccountIdList = params.AccountIdList.join(',');
            }
			else if (typeof params.AccountIdList === "object" && params.AccountIdList != null && params.AccountIdList.length == 0) {
				params.AccountIdList = null;
			}
		if (typeof params.VendorIdList === "object" && params.VendorIdList != null && params.VendorIdList.length > 0) {
                params.VendorIdList = params.VendorIdList.join(',');
            }
			else if (typeof params.VendorIdList === "object" && params.VendorIdList != null && params.VendorIdList.length == 0) {
				params.VendorIdList = null;
			}
		if (typeof params.OwnerIdList === "object" && params.OwnerIdList != null && params.OwnerIdList.length > 0) {
                params.OwnerIdList = params.OwnerIdList.join(',');
            }
			else if (typeof params.OwnerIdList === "object" && params.OwnerIdList != null && params.OwnerIdList.length == 0) {
				params.OwnerIdList = null;
			}
		if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length > 0) {
                params.ClientIdList = params.ClientIdList.join(',');
            }
			else if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length == 0) {
				params.ClientIdList = null;
			}
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'liq.GetInvoiceRegister'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Invoices using liq.GetInvoices
         * @param {number} clientId - 
         * @param {number} ownerId - 
         * @param {number} vendorId - 
         * @param {number} statusId - 
         * @param {number} invoiceId - 
         * @param {string} requiredApprovalUserId - 
         * @param {string} vendorInvoiceNumber - 
         * @param {number} agreementId - 
         * @param {string} fileId - 
         * @param {boolean} reviewOutstandingOnly - 
         */
		, liq_GetInvoices: function (clientId, ownerId, vendorId, statusId, invoiceId, requiredApprovalUserId, vendorInvoiceNumber, agreementId, fileId, reviewOutstandingOnly) {
			/*
				this.Invoices = this.liq_GetInvoices(
					null // clientId
					, null // ownerId
					, null // vendorId
					, null // statusId
					, null // invoiceId
					, null // requiredApprovalUserId
					, null // vendorInvoiceNumber
					, null // agreementId
					, null // fileId
					, null // reviewOutstandingOnly
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liq.GetInvoices'
			  , params: {
					ClientId: clientId
					, OwnerId: ownerId
					, VendorId: vendorId
					, StatusId: statusId
					, InvoiceId: invoiceId
					, RequiredApprovalUserId: requiredApprovalUserId
					, VendorInvoiceNumber: vendorInvoiceNumber
					, AgreementId: agreementId
					, FileId: fileId
					, ReviewOutstandingOnly: reviewOutstandingOnly
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Invoices using liq.GetInvoices; Return Single Object
         * @param {number} clientId - 
         * @param {number} ownerId - 
         * @param {number} vendorId - 
         * @param {number} statusId - 
         * @param {number} invoiceId - 
         * @param {string} requiredApprovalUserId - 
         * @param {string} vendorInvoiceNumber - 
         * @param {number} agreementId - 
         * @param {string} fileId - 
         * @param {boolean} reviewOutstandingOnly - 
         */
		, liq_GetInvoices_Object: function (clientId, ownerId, vendorId, statusId, invoiceId, requiredApprovalUserId, vendorInvoiceNumber, agreementId, fileId, reviewOutstandingOnly) {
			/*
				this.Invoices = this.liq_GetInvoices_Object(
					null // clientId
					, null // ownerId
					, null // vendorId
					, null // statusId
					, null // invoiceId
					, null // requiredApprovalUserId
					, null // vendorInvoiceNumber
					, null // agreementId
					, null // fileId
					, null // reviewOutstandingOnly
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liq.GetInvoices'
			  , params: {
					ClientId: clientId
					, OwnerId: ownerId
					, VendorId: vendorId
					, StatusId: statusId
					, InvoiceId: invoiceId
					, RequiredApprovalUserId: requiredApprovalUserId
					, VendorInvoiceNumber: vendorInvoiceNumber
					, AgreementId: agreementId
					, FileId: fileId
					, ReviewOutstandingOnly: reviewOutstandingOnly
					}
				, loadAsync: loadAsync
				});
				if (ds.length == 1)
						return ds[0];
				else
						return null;
			}
        /** @description Get NEW Invoices using columns of liq.GetInvoices
         */
		, liq_GetInvoices_New: function () {
			/*
				this.Invoices = this.liq_GetInvoices_New();
			*/
					return {
						InvoiceId: null
						, OwnerId: null
						, Date: null
						, DueDate: null
						, VendorId: null
						, VendorName: null
						, VendorInvoiceNumber: null
						, ReferenceEntityId: null
						, ReferenceEntityType: null
						, Amount: null
						, Notes: null
						, FileId: null
						, DriveId: null
						, StatusId: null
						, StatusText: null
						, PaidDate: null
						, CompletedDateDisplay: null
						, CompletedDate: null
						, PaidUserId: null
						, PaidMethodId: null
						, PaidMethodText: null
						, CreateUser: null
						, CreateDate: null
						, ModifyUser: null
						, ModifyDate: null
						, Client: null
						, ClientId: null
						, CreateDateLabel: null
						, ModifyDateLabel: null
						, CompletedDateLabel: null
						, CompletedNotes: null
						, PaidDateLabel: null
						, PaidDateSetLabel: null
						, ReferenceEntityName: null
						, OwnerName: null
						, BankAccountId: null
						, BankAccountNameDisplay: null
						, IsComplete: null
						, AutoPay: null
						, AllocationStatusText: null
						, RowClass: null
						, TransactionMatched: null
						, ConfirmedTransactionMatched: null
						, AgreementId: null
						, ScheduleItemId: null
						, ScheduleDate: null
						, ScheduleAmount: null
						, ParentReferenceEntityId: null
						, ParentReferenceEntityType: null
						, AgreementGroup: null
						, Units: null
						, UnitsDisplay: null
						, UnitsLabel: null
						, AggregateUnits: null
						, NotifyThresholdMinimum: null
						, NotifyEmail: null
						, Deleteable: null
						, DisableMatches: null
						, DisabledMatchReason: null
						, ApprovalId: null
						, Approved: null
						, ApprovalKeyPerson: null
						, DestinationPath: null
						, DestinationFolder: null
						, FYIId: null
						, NotifyApproversOnMatch: null
						, NotifyFYIOnMatch: null
						, MatchNotificationSent: null
						, BypassInvoiceReview: null
						, ReviewLogId: null
						, ReviewedBy: null
						, ReviewedOn: null
						, Reviewed: null
						, SelectableForReview: null
						, IsLoading: null
						, Approvers: null
						, FYIs: null
						, ApprovalSetDate: null
						, LockAgreementSelection: null
						, IsTripInvoice: null
						, FXUpdates: null
						, AutoIngestFrom: null
						, AutoIngestSubject: null
						, AutoIngestMessageId: null
						, RecognizedInvoiceJSONId: null
						, AutoIngestFileCreateUser: null
						, AutoIngestFileName: null
						};
			}
        /** @description Get InvoiceStatusForPaymentDashboard using liq.GetInvoiceStatusForPaymentDashboard
         */
		, liq_GetInvoiceStatusForPaymentDashboard: function (params, onSuccess, onError, async) {
			/*
				this.InvoiceStatusForPaymentDashboard = await this.liq_GetInvoiceStatusForPaymentDashboard({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetInvoiceStatusForPaymentDashboard'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get JointBankAccountOwners using liq.GetJointBankAccountOwners
         * @param {number} bankAccountId - 
         */
		, liq_GetJointBankAccountOwners: function (params, onSuccess, onError, async) {
			/*
				this.JointBankAccountOwners = await this.liq_GetJointBankAccountOwners({
					BankAccountId: bankAccountId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetJointBankAccountOwners'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get JPMCreditCardTransactionImportPossibleDuplicates using liq.GetJPMCreditCardTransactionImportPossibleDuplicates
         * @param {number} logId - 
         */
		, liq_GetJPMCreditCardTransactionImportPossibleDuplicates: function (params, onSuccess, onError, async) {
			/*
				this.JPMCreditCardTransactionImportPossibleDuplicates = await this.liq_GetJPMCreditCardTransactionImportPossibleDuplicates({
					LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetJPMCreditCardTransactionImportPossibleDuplicates'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get JPMCreditCardTransactionImportProcesses using liq.GetJPMCreditCardTransactionImportProcesses
         */
		, liq_GetJPMCreditCardTransactionImportProcesses: function (params, onSuccess, onError, async) {
			/*
				this.JPMCreditCardTransactionImportProcesses = await this.liq_GetJPMCreditCardTransactionImportProcesses({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetJPMCreditCardTransactionImportProcesses'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get LayoutHierarchy using liq.GetLayoutHierarchy
         * @param {number} layoutGroupId - 
         * @param {number} layoutId - 
         * @param {boolean} outermostLeafOnly - 
         */
		, liq_GetLayoutHierarchy: function (params, onSuccess, onError, async) {
			/*
				this.LayoutHierarchy = await this.liq_GetLayoutHierarchy({
					LayoutGroupId: layoutGroupId
					, LayoutId: layoutId
					, OutermostLeafOnly: outermostLeafOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetLayoutHierarchy'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get LogIds using liq.GetLogIds
         * @param {string} database - 
         * @param {string} schema - 
         * @param {string} caller - 
         * @param {string} event - 
         * @param {string} keyName - 
         * @param {number} keyInt - 
         * @param {string} keyVarChar - 
         * @param {date} keyDate - 
         * @param {boolean} completedOnly - 
         * @param {number} logId - 
         * @param {boolean} includeIncompleteRegardlessOfMilliseconds - 
         * @param {number} minMilliseconds - 
         * @param {number} maxRows - 
         * @param {number} eventId - 
         * @param {boolean} successOnly - 
         */
		, liq_GetLogIds: function (params, onSuccess, onError, async) {
			/*
				this.LogIds = await this.liq_GetLogIds({
					Database: database
					, Schema: schema
					, Caller: caller
					, Event: event
					, KeyName: keyName
					, KeyInt: keyInt
					, KeyVarChar: keyVarChar
					, KeyDate: keyDate
					, CompletedOnly: completedOnly
					, LogId: logId
					, IncludeIncompleteRegardlessOfMilliseconds: includeIncompleteRegardlessOfMilliseconds
					, MinMilliseconds: minMilliseconds
					, MaxRows: maxRows
					, EventId: eventId
					, SuccessOnly: successOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetLogIds'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ManualAccountTransaction using liq.GetManualAccountTransaction
         * @param {number} manualAccountTransactionId - 
         */
		, liq_GetManualAccountTransaction: function (manualAccountTransactionId) {
			/*
				this.ManualAccountTransaction = this.liq_GetManualAccountTransaction(
					null // manualAccountTransactionId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liq.GetManualAccountTransaction'
			  , params: {
					ManualAccountTransactionId: manualAccountTransactionId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MatchedTransaction using liq.GetMatchedTransaction
         * @param {number} entityId - 
         * @param {string} entityType - 
         */
		, liq_GetMatchedTransaction: function (entityId, entityType) {
			/*
				this.MatchedTransaction = this.liq_GetMatchedTransaction(
					null // entityId
					, null // entityType
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liq.GetMatchedTransaction'
			  , params: {
					EntityId: entityId
					, EntityType: entityType
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get MatchesForTransaction using liq.GetMatchesForTransaction
         * @param {number} transactionId - 
         * @param {number} matchedTransactionId - 
         * @param {boolean} useNewInvestmentTransactions - 
         */
		, liq_GetMatchesForTransaction: function (params, onSuccess, onError, async) {
			/*
				this.MatchesForTransaction = await this.liq_GetMatchesForTransaction({
					TransactionId: transactionId
					, MatchedTransactionId: matchedTransactionId
					, UseNewInvestmentTransactions: useNewInvestmentTransactions
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetMatchesForTransaction'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get NEW MatchesForTransaction using columns of liq.GetMatchesForTransaction
         */
		, liq_GetMatchesForTransaction_New: function () {
			/*
				this.MatchesForTransaction = await this.liq_GetMatchesForTransaction_New();
			*/
					return {
						MatchedTransactionId: null
						, EntityId: null
						, EntityType: null
						, TransactionId: null
						, PostedAmount: null
						, PostedDate: null
						, Description: null
						, AccountId: null
						, NumberDisplayMasked: null
						, AccountName: null
						, AccountOwnerId: null
						, CreateUser: null
						, CreateDate: null
						, SearchTermId: null
						, AutoMatchLogId: null
						, VendorId: null
						, VendorName: null
						, AutoMatchedDescription: null
						, AutoMatchRequiresConfirmation: null
						, AutoMatchTime: null
						, MatchConfirmed: null
						, IsConfirmed: null
						, SearchTermText: null
						, SearchTermEntityType: null
						, SearchTermEntityId: null
						, InvestmentId: null
						, AgreementId: null
						, NoteId: null
						, ReviewForTax: null
						, DepartmentId: null
						, ChartOfAccountsArray: null
						, EntityTypeDisplay: null
						, EntityTypeDisplayShort: null
						, MatchedDescription: null
						, ScheduledDate: null
						, ScheduledAmount: null
						, Multiplier: null
						, TransferId: null
						, Comment: null
						, PreviouslyMatchedAmount: null
						, LegacyCount: null
						, NewCount: null
						};
			}
        /** @description Get PledgeAgreement using liq.GetPledgeAgreement
         * @param {number} agreementId - 
         */
		, liq_GetPledgeAgreement: function (params, onSuccess, onError, async) {
			/*
				this.PledgeAgreement = await this.liq_GetPledgeAgreement({
					AgreementId: agreementId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetPledgeAgreement'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get PledgeAgreement using liq.GetPledgeAgreement; Return Single Object
         * @param {number} agreementId - 
         */
		, liq_GetPledgeAgreement_Object: function (params, onSuccess, onError, async) {
			/*
				this.PledgeAgreement = await this.liq_GetPledgeAgreement_Object({
					AgreementId: agreementId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'liq.GetPledgeAgreement'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get NEW PledgeAgreement using columns of liq.GetPledgeAgreement
         */
		, liq_GetPledgeAgreement_New: function () {
			/*
				this.PledgeAgreement = await this.liq_GetPledgeAgreement_New();
			*/
					return {
						AgreementId: null
						, Name: null
						, OwnerId: null
						, OwnerName: null
						, ClientId: null
						, InceptionDate: null
						, TerminationDate: null
						, Commitment: null
						, ScheduledPayments: null
						, CashAmount: null
						, CashTransactions: null
						, ClearedAmount: null
						, ClearedTransactions: null
						, RemainingCommitment: null
						, BalanceSheetCategoryId: null
						, LastTransaction: null
						, ActualTermination: null
						};
			}
        /** @description Get ReferenceEntity using liq.GetReferenceEntity
         * @param {string} entityType - 
         * @param {number} ownerId - 
         * @param {number} vendorId - 
         * @param {string} investmentIdList - 
         * @param {number} matchedTransactionId - 
         * @param {number} transactionIdToMatch - 
         * @param {number} agreementId - 
         * @param {date} lookupDate - 
         * @param {number} noteId - 
         * @param {boolean} ignoreDateWindow - 
         */
		, liq_GetReferenceEntity: function (params, onSuccess, onError, async) {
			/*
				this.ReferenceEntity = await this.liq_GetReferenceEntity({
					EntityType: entityType
					, OwnerId: ownerId
					, VendorId: vendorId
					, InvestmentIdList: investmentIdList
					, MatchedTransactionId: matchedTransactionId
					, TransactionIdToMatch: transactionIdToMatch
					, AgreementId: agreementId
					, LookupDate: lookupDate
					, NoteId: noteId
					, IgnoreDateWindow: ignoreDateWindow
					}
					, true // async (optional)
				);
			*/

		if (typeof params.InvestmentIdList === "object" && params.InvestmentIdList != null && params.InvestmentIdList.length > 0) {
                params.InvestmentIdList = params.InvestmentIdList.join(',');
            }
			else if (typeof params.InvestmentIdList === "object" && params.InvestmentIdList != null && params.InvestmentIdList.length == 0) {
				params.InvestmentIdList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetReferenceEntity'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get RelatedMatches using liq.GetRelatedMatches
         * @param {number} transactionId - 
         */
		, liq_GetRelatedMatches: function (params, onSuccess, onError, async) {
			/*
				this.RelatedMatches = await this.liq_GetRelatedMatches({
					TransactionId: transactionId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetRelatedMatches'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ReserveImport using liq.GetReserveImport
         */
		, liq_GetReserveImport: function (params, onSuccess, onError, async) {
			/*
				this.ReserveImport = await this.liq_GetReserveImport({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetReserveImport'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ScheduledPayments using liq.GetScheduledPayments
         * @param {number} clientId - 
         * @param {date} toDate - 
         * @param {number} approvalId - 
         */
		, liq_GetScheduledPayments: function (params, onSuccess, onError, async) {
			/*
				this.ScheduledPayments = await this.liq_GetScheduledPayments({
					ClientId: clientId
					, ToDate: toDate
					, ApprovalId: approvalId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetScheduledPayments'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ScheduleItem using liq.GetScheduleItem
         * @param {string} entityType - 
         * @param {number} entityId - 
         * @param {number} scheduleItemId - 
         */
		, liq_GetScheduleItem: function (params, onSuccess, onError, async) {
			/*
				this.ScheduleItem = await this.liq_GetScheduleItem({
					EntityType: entityType
					, EntityId: entityId
					, ScheduleItemId: scheduleItemId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetScheduleItem'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ScheduleItem using liq.GetScheduleItem; Return Single Object
         * @param {string} entityType - 
         * @param {number} entityId - 
         * @param {number} scheduleItemId - 
         */
		, liq_GetScheduleItem_Object: function (params, onSuccess, onError, async) {
			/*
				this.ScheduleItem = await this.liq_GetScheduleItem_Object({
					EntityType: entityType
					, EntityId: entityId
					, ScheduleItemId: scheduleItemId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'liq.GetScheduleItem'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get NEW ScheduleItem using columns of liq.GetScheduleItem
         */
		, liq_GetScheduleItem_New: function () {
			/*
				this.ScheduleItem = await this.liq_GetScheduleItem_New();
			*/
					return {
						Id: null
						, EntityId: null
						, EntityType: null
						, ScheduleNumber: null
						, ScheduleDate: null
						, Frequency: null
						, Amount: null
						, RangeStart: null
						, RangeEnd: null
						, CreateUser: null
						, CreateDate: null
						, ModifyUser: null
						, ModifyDate: null
						, Modified: null
						, Matches: null
						, MatchedDate: null
						, MatchStatus: null
						, Invoices: null
						, AgreementMatches: null
						, AgreementMatchDate: null
						, InvoiceMatches: null
						, InvoiceMatchDate: null
						};
			}
        /** @description Get ScheduleItemTransactions using liq.GetScheduleItemTransactions
         * @param {number} entityId - 
         * @param {string} entityType - 
         */
		, liq_GetScheduleItemTransactions: function (entityId, entityType) {
			/*
				this.ScheduleItemTransactions = this.liq_GetScheduleItemTransactions(
					null // entityId
					, null // entityType
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liq.GetScheduleItemTransactions'
			  , params: {
					EntityId: entityId
					, EntityType: entityType
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Schedules using liq.GetSchedules
         * @param {string} entityType - 
         * @param {number} entityId - 
         * @param {number} scheduleId - 
         */
		, liq_GetSchedules: function (params, onSuccess, onError, async) {
			/*
				this.Schedules = await this.liq_GetSchedules({
					EntityType: entityType
					, EntityId: entityId
					, ScheduleId: scheduleId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetSchedules'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Schedules using liq.GetSchedules; Return Single Object
         * @param {string} entityType - 
         * @param {number} entityId - 
         * @param {number} scheduleId - 
         */
		, liq_GetSchedules_Object: function (params, onSuccess, onError, async) {
			/*
				this.Schedules = await this.liq_GetSchedules_Object({
					EntityType: entityType
					, EntityId: entityId
					, ScheduleId: scheduleId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'liq.GetSchedules'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get NEW Schedules using columns of liq.GetSchedules
         */
		, liq_GetSchedules_New: function () {
			/*
				this.Schedules = await this.liq_GetSchedules_New();
			*/
					return {
						Id: null
						, EntityId: null
						, EntityType: null
						, Amount: null
						, FrequencyId: null
						, Name: null
						, SeedDate: null
						, NumberOfPayments: null
						, TotalOfPayments: null
						, LastPaymentDate: null
						, PaysAutomatically: null
						, CreateUser: null
						, CreateDate: null
						, ModifyUser: null
						, ModifyDate: null
						, Created: null
						, Modified: null
						, EndTypeDescription: null
						};
			}
        /** @description Get SearchTerms using liq.GetSearchTerms
         * @param {number} searchTermId - 
         * @param {number} entityId - 
         * @param {string} entityType - 
         */
		, liq_GetSearchTerms: function (searchTermId, entityId, entityType) {
			/*
				this.SearchTerms = this.liq_GetSearchTerms(
					null // searchTermId
					, null // entityId
					, null // entityType
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liq.GetSearchTerms'
			  , params: {
					SearchTermId: searchTermId
					, EntityId: entityId
					, EntityType: entityType
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SearchTerms using liq.GetSearchTerms; Return Single Object
         * @param {number} searchTermId - 
         * @param {number} entityId - 
         * @param {string} entityType - 
         */
		, liq_GetSearchTerms_Object: function (searchTermId, entityId, entityType) {
			/*
				this.SearchTerms = this.liq_GetSearchTerms_Object(
					null // searchTermId
					, null // entityId
					, null // entityType
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'liq.GetSearchTerms'
			  , params: {
					SearchTermId: searchTermId
					, EntityId: entityId
					, EntityType: entityType
					}
				, loadAsync: loadAsync
				});
				if (ds.length == 1)
						return ds[0];
				else
						return null;
			}
        /** @description Get NEW SearchTerms using columns of liq.GetSearchTerms
         */
		, liq_GetSearchTerms_New: function () {
			/*
				this.SearchTerms = this.liq_GetSearchTerms_New();
			*/
					return {
						Id: null
						, EntityId: null
						, EntityType: null
						, Text: null
						, CreateUser: null
						, CreateDate: null
						, ModifyUser: null
						, ModifyDate: null
						, SysStartTime: null
						, SysEndTime: null
						, MinAmount: null
						, MaxAmount: null
						, DepartmentId: null
						, EntityDescription: null
						, UseMinAmount: null
						, UseMaxAmount: null
						};
			}
        /** @description Get SourcesAndUsesReport using liq.GetSourcesAndUsesReport
         * @param {number} clientId - 
         * @param {date} rangeStart - 
         * @param {date} rangeEnd - 
         * @param {number} configurationId - 
         */
		, liq_GetSourcesAndUsesReport: function (params, onSuccess, onError, async) {
			/*
				this.SourcesAndUsesReport = await this.liq_GetSourcesAndUsesReport({
					ClientId: clientId
					, RangeStart: rangeStart
					, RangeEnd: rangeEnd
					, ConfigurationId: configurationId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetSourcesAndUsesReport'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get TransactionsV2 using liq.GetTransactionsV2
         * @param {number} clientId - 
         * @param {number} ownerId - 
         * @param {number} transactionId - 
         * @param {date} dateRangeStart - 
         * @param {date} dateRangeEnd - 
         * @param {number} accountId - 
         * @param {boolean} unmatchedOnly - 
         */
		, liq_GetTransactionsV2: function (params, onSuccess, onError, async) {
			/*
				this.TransactionsV2 = await this.liq_GetTransactionsV2({
					ClientId: clientId
					, OwnerId: ownerId
					, TransactionId: transactionId
					, DateRangeStart: dateRangeStart
					, DateRangeEnd: dateRangeEnd
					, AccountId: accountId
					, UnmatchedOnly: unmatchedOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetTransactionsV2'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get TransactionsV2 using liq.GetTransactionsV2; Return Single Object
         * @param {number} clientId - 
         * @param {number} ownerId - 
         * @param {number} transactionId - 
         * @param {date} dateRangeStart - 
         * @param {date} dateRangeEnd - 
         * @param {number} accountId - 
         * @param {boolean} unmatchedOnly - 
         */
		, liq_GetTransactionsV2_Object: function (params, onSuccess, onError, async) {
			/*
				this.TransactionsV2 = await this.liq_GetTransactionsV2_Object({
					ClientId: clientId
					, OwnerId: ownerId
					, TransactionId: transactionId
					, DateRangeStart: dateRangeStart
					, DateRangeEnd: dateRangeEnd
					, AccountId: accountId
					, UnmatchedOnly: unmatchedOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'liq.GetTransactionsV2'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get TransferSourceBankAccounts using liq.GetTransferSourceBankAccounts
         * @param {number} toBankAccountId - 
         * @param {number} cashTransferTypeId - 
         */
		, liq_GetTransferSourceBankAccounts: function (params, onSuccess, onError, async) {
			/*
				this.TransferSourceBankAccounts = await this.liq_GetTransferSourceBankAccounts({
					ToBankAccountId: toBankAccountId
					, CashTransferTypeId: cashTransferTypeId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.GetTransferSourceBankAccounts'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.ImportReserve
         * @param {number} excelCellImportConfigurationId - 
         * @param {number} amount - 
         */
		, liq_ImportReserve: function (params, onSuccess, onError, async) {
			/*
				this.ImportReserve = await this.liq_ImportReserve({
					ExcelCellImportConfigurationId: excelCellImportConfigurationId
					, Amount: amount
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.ImportReserve'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.ProcessAmexTransactionImport
         * @param {number} logId - 
         * @param {string} excludeList - 
         */
		, liq_ProcessAmexTransactionImport: function (params, onSuccess, onError, async) {
			/*
				this.ProcessAmexTransactionImport = await this.liq_ProcessAmexTransactionImport({
					LogId: logId
					, ExcludeList: excludeList
					}
					, true // async (optional)
				);
			*/

		if (typeof params.ExcludeList === "object" && params.ExcludeList != null && params.ExcludeList.length > 0) {
                params.ExcludeList = params.ExcludeList.join(',');
            }
			else if (typeof params.ExcludeList === "object" && params.ExcludeList != null && params.ExcludeList.length == 0) {
				params.ExcludeList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.ProcessAmexTransactionImport'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.ProcessBankBalanceImport
         * @param {number} logId - 
         * @param {string} excludeList - 
         */
		, liq_ProcessBankBalanceImport: function (params, onSuccess, onError, async) {
			/*
				this.ProcessBankBalanceImport = await this.liq_ProcessBankBalanceImport({
					LogId: logId
					, ExcludeList: excludeList
					}
					, true // async (optional)
				);
			*/

		if (typeof params.ExcludeList === "object" && params.ExcludeList != null && params.ExcludeList.length > 0) {
                params.ExcludeList = params.ExcludeList.join(',');
            }
			else if (typeof params.ExcludeList === "object" && params.ExcludeList != null && params.ExcludeList.length == 0) {
				params.ExcludeList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.ProcessBankBalanceImport'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.ProcessBankTransactionImport
         * @param {number} logId - 
         * @param {string} excludeList - 
         */
		, liq_ProcessBankTransactionImport: function (params, onSuccess, onError, async) {
			/*
				this.ProcessBankTransactionImport = await this.liq_ProcessBankTransactionImport({
					LogId: logId
					, ExcludeList: excludeList
					}
					, true // async (optional)
				);
			*/

		if (typeof params.ExcludeList === "object" && params.ExcludeList != null && params.ExcludeList.length > 0) {
                params.ExcludeList = params.ExcludeList.join(',');
            }
			else if (typeof params.ExcludeList === "object" && params.ExcludeList != null && params.ExcludeList.length == 0) {
				params.ExcludeList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.ProcessBankTransactionImport'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.ProcessChaseCreditCardTransactionImport
         * @param {number} logId - 
         * @param {string} excludeList - 
         */
		, liq_ProcessChaseCreditCardTransactionImport: function (params, onSuccess, onError, async) {
			/*
				this.ProcessChaseCreditCardTransactionImport = await this.liq_ProcessChaseCreditCardTransactionImport({
					LogId: logId
					, ExcludeList: excludeList
					}
					, true // async (optional)
				);
			*/

		if (typeof params.ExcludeList === "object" && params.ExcludeList != null && params.ExcludeList.length > 0) {
                params.ExcludeList = params.ExcludeList.join(',');
            }
			else if (typeof params.ExcludeList === "object" && params.ExcludeList != null && params.ExcludeList.length == 0) {
				params.ExcludeList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.ProcessChaseCreditCardTransactionImport'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.ProcessHistory
         * @param {date} liquidityDate - 
         * @param {date} liquidityEnd - 
         * @param {number} logId - 
         */
		, liq_ProcessHistory: function (params, onSuccess, onError, async) {
			/*
				this.ProcessHistory = await this.liq_ProcessHistory({
					LiquidityDate: liquidityDate
					, LiquidityEnd: liquidityEnd
					, LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.ProcessHistory'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.ProcessJPMCreditCardTransactionImport
         * @param {number} logId - 
         * @param {string} excludeList - 
         */
		, liq_ProcessJPMCreditCardTransactionImport: function (params, onSuccess, onError, async) {
			/*
				this.ProcessJPMCreditCardTransactionImport = await this.liq_ProcessJPMCreditCardTransactionImport({
					LogId: logId
					, ExcludeList: excludeList
					}
					, true // async (optional)
				);
			*/

		if (typeof params.ExcludeList === "object" && params.ExcludeList != null && params.ExcludeList.length > 0) {
                params.ExcludeList = params.ExcludeList.join(',');
            }
			else if (typeof params.ExcludeList === "object" && params.ExcludeList != null && params.ExcludeList.length == 0) {
				params.ExcludeList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.ProcessJPMCreditCardTransactionImport'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.QueueProcessCashFlow
         * @param {number} logId - 
         */
		, liq_QueueProcessCashFlow: function (params, onSuccess, onError, async) {
			/*
				this.QueueProcessCashFlow = await this.liq_QueueProcessCashFlow({
					LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.QueueProcessCashFlow'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.QueueProcessHistory
         * @param {number} logId - 
         */
		, liq_QueueProcessHistory: function (params, onSuccess, onError, async) {
			/*
				this.QueueProcessHistory = await this.liq_QueueProcessHistory({
					LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.QueueProcessHistory'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.RejectScheduledPayment
         * @param {number} approvalItemId - 
         * @param {string} comment - 
         * @param {boolean} notifyBatchCreator - 
         */
		, liq_RejectScheduledPayment: function (params, onSuccess, onError, async) {
			/*
				this.RejectScheduledPayment = await this.liq_RejectScheduledPayment({
					ApprovalItemId: approvalItemId
					, Comment: comment
					, NotifyBatchCreator: notifyBatchCreator
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.RejectScheduledPayment'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.ResendApprovalEmail
 
         */
		, liq_ResendApprovalEmail: function (params, onSuccess, onError, async) {
			/*
				this.ResendApprovalEmail = await this.liq_ResendApprovalEmail({
					GUID: gUID
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.ResendApprovalEmail'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.SendPaymentConfirmationEmailAndSMS
         * @param {number} approvalId - 
         */
		, liq_SendPaymentConfirmationEmailAndSMS: function (params, onSuccess, onError, async) {
			/*
				this.SendPaymentConfirmationEmailAndSMS = await this.liq_SendPaymentConfirmationEmailAndSMS({
					ApprovalId: approvalId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.SendPaymentConfirmationEmailAndSMS'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.UpdateInvoiceAmountForExchanceRate
         * @param {number} invoiceId - 
         * @param {number} oldAmount - 
         * @param {number} newAmount - 
         */
		, liq_UpdateInvoiceAmountForExchanceRate: function (params, onSuccess, onError, async) {
			/*
				this.UpdateInvoiceAmountForExchanceRate = await this.liq_UpdateInvoiceAmountForExchanceRate({
					InvoiceId: invoiceId
					, OldAmount: oldAmount
					, NewAmount: newAmount
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.UpdateInvoiceAmountForExchanceRate'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description liq.ValidateVendorInvoiceNumber
         * @param {number} vendorId - 
         * @param {string} vendorInvoiceNumber - 
         * @param {number} invoiceId - 
         */
		, liq_ValidateVendorInvoiceNumber: function (params, onSuccess, onError, async) {
			/*
				this.ValidateVendorInvoiceNumber = await this.liq_ValidateVendorInvoiceNumber({
					VendorId: vendorId
					, VendorInvoiceNumber: vendorInvoiceNumber
					, InvoiceId: invoiceId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'liq.ValidateVendorInvoiceNumber'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
    }
};
export default mixinSchema_liq;
