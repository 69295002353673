
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import RegisteredAgentEdit from '@/views/RegisteredAgent/RegisteredAgentEdit.vue';
import * as VamService from '../../services/DAL/vamService';
import Common from '../../utils/common';
import ElementUI from 'element-ui';
declare var $: any;

@Component({
    inheritAttrs: false,
    components: {
        RegisteredAgentEdit
    }
})
export default class RegisteredAgentSelector extends Vue {
    $refs: {
        ddlRegisteredAgentSelector: ElementUI.Select
        , btnAddRegisteredAgent: ElementUI.Button
    }
    //#region Private declarations for Services
    private _vamService: VamService.VamService;
    private _common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: number;
    @Prop()
    state: string;
    @Prop()
    stateId: number;
    @Prop({
        default: false
    })
    requireState: boolean;
    @Prop({
        default: false
    })
    requireStateId: boolean;
    //#endregion Props

    //#region Data
    registeredAgents = [] as VamService.RegisteredAgents[];
    selectedValue: number = null;
    showRegisteredAgentEdit: Boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();
        this._common = new Common();
    }
    async mounted() {
        this.selectedValue = this.value;
        this.fetchRegisteredAgents();

        // get actual button width, and reset width of select to fill remaining space next to button
        const buttonWidth = $(this.$refs.btnAddRegisteredAgent.$el).outerWidth() || 44;
        $(this.$refs.ddlRegisteredAgentSelector.$el).css('width', 'calc(100% - ' + (buttonWidth + 6) + 'px)');
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onRegisteredAgentChange(val: number, oldVal: number) {
        this.selectedValue = val;

        if (this.registeredAgents.length) {
            let selectedObject: VamService.RegisteredAgents = new VamService.RegisteredAgents();
            if (this.selectedValue) {
                selectedObject = this._common.getSelectedArrayItem(
                    this.registeredAgents,
                    this.selectedValue.toString(),
                    'Id'
                );
            }
            this.$emit('value-set', selectedObject);
        }
    }

    @Watch('state')
    onChange_state(val: number, oldVal: number) {
        this.fetchRegisteredAgents();
    }

    @Watch('stateId')
    onChange_stateId(val: number, oldVal: number) {
        this.fetchRegisteredAgents();
    }

    //#endregion Watches

    //#region Methods
    async fetchRegisteredAgents() {
        this.registeredAgents = [];

        if ((!this.requireState || !!this.state) && (!this.requireStateId || !!this.stateId)) {
            const params = {} as VamService.GetRegisteredAgentsParameters;
            params.State = this.state;
            params.StateId = this.stateId;
            this.registeredAgents = await this._vamService.GetRegisteredAgents(params);
        }
    }

    async onSaveNewRegisteredAgent(RegisteredAgentId: number) {
        await this.fetchRegisteredAgents();
        this.$emit('input', RegisteredAgentId);
    }

    closeRegisteredAgent() {
        this.showRegisteredAgentEdit = false;
    }
    //#endregion Methods
}
