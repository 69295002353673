
import Vue from 'vue';
import { Watch, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import * as AvService from './../../services/DAL/avService';
import PersonSelector from "@/components/form/PersonSelector.vue";
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';

declare var SmartObject: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
    components: {
        PersonSelector
    }
})
export default class TripLegPassengerList extends Vue {
    //#region Data
    private _avService: AvService.AvService;

    @Prop() passengers: AvService.TripLegManifest[];
    @Prop() tripLegId: number;
    @Prop({ default: true }) autoSave: boolean;
    @Prop() allowPassengerEdit: boolean;
    @Prop() viewType: string;

    passengers_: AvService.TripLegManifest[] = [] as AvService.TripLegManifest[];
    passengers_cache: AvService.TripLegManifest[] = [] as AvService.TripLegManifest[]; // use this to compare 'passengers_' to, to determine which records to save when setting IsBusinessTravel on all passengers
    isBusinessTrip: boolean = null;
    isCharitableTrip: boolean = null;
    isSavingAll: boolean = false;
    securityLevel_: number = null;

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._avService = new AvService.AvService();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAviationDataImport),
            0
        );

    }

    async mounted() {
        if (this.passengers && this.passengers.length) {
            this.passengers_ = this.passengers;
        }
        else if (this.tripLegId > 0) {
            await this.fetchPassengers();
        }

        if (!this.passengers_.length && this.allowPassengerEdit) {
            this.addPassenger();
        }
    }
    //#endregion Lifecycle


//#region Methods
    async fetchPassengers() {
        const parameters = {} as AvService.GetTripLegManifestParameters;
        parameters.Type = 'Passenger';
        parameters.TripLegId = this.tripLegId;
        this.passengers_ = await this._avService.GetTripLegManifest(parameters);
        this.$emit('update:passengers', this.passengers_);
    }

    async updateField(tripLegManifest: AvService.TripLegManifest, fieldName: string, fieldValue: any, fieldDescription: string) {
        if (this.autoSave) {
            try {
                if (tripLegManifest.TripLegManifestId > 0) {
                    const tlm = new SmartObject('TripLegManifest', tripLegManifest.TripLegManifestId);
                    await tlm.updateAttribute(fieldName, fieldValue);
                }
                else {
                    const tlm = new SmartObject('TripLegManifest');
                    const attributes: any = {
                        TripLegId: this.tripLegId || null,
                        Type: 'Passenger'
                    };
                    attributes[fieldName] = fieldValue;
                    await tlm.createObject(attributes);
                    tripLegManifest.TripLegManifestId = tlm.entityId;
                }
                this.$notify.success(fieldDescription + ' updated.');
            }
            catch {
                this.$notify.error('Something went wrong processing your request, please try again.');
            }
        }

        this.$emit('update:passengers', this.passengers_);
    }

    async setPassengerTypeOfAllPassengers(isBusinessTrip: boolean = undefined) {
        if (isBusinessTrip != undefined) {
            this.isBusinessTrip = isBusinessTrip;
        }
        if (this.isBusinessTrip && this.isCharitableTrip){
            this.isCharitableTrip = false;
        }
        this.passengers_cache = cloneDeep(this.passengers_);

        this.passengers_.forEach(passenger => {
            passenger.IsBusinessTravel = this.isBusinessTrip;
            passenger.IsCharitable = this.isCharitableTrip;
        });
        
        if (this.autoSave) {
            let numToUpdate: number = 0;
            let allSuccessful: boolean = true;

            this.isSavingAll = true;

            for (let i = 0; i < this.passengers_.length; i++) {
                const passenger: AvService.TripLegManifest = this.passengers_[i];
                const passenger_cache: AvService.TripLegManifest = this.passengers_cache[i];

                if (passenger.IsBusinessTravel !== passenger_cache.IsBusinessTravel) {
                    numToUpdate++;

                    const tlm = new SmartObject('TripLegManifest', passenger.TripLegManifestId);
                    try {
                        await tlm.updateAttribute('IsBusinessTravel', passenger.IsBusinessTravel);
                        passenger_cache.IsBusinessTravel = passenger.IsBusinessTravel;
                    } catch {
                        allSuccessful = false;
                    }
                }
                if (allSuccessful && passenger.IsCharitable !== passenger_cache.IsCharitable) {
                    numToUpdate++;

                    const tlm = new SmartObject('TripLegManifest', passenger.TripLegManifestId);
                    try {
                        await tlm.updateAttribute('IsCharitable', passenger.IsCharitable);
                        passenger_cache.IsCharitable = passenger.IsCharitable;
                    } catch {
                        allSuccessful = false;
                    }
                }
            }

            this.isSavingAll = false;

            if (numToUpdate > 0) {
                if (allSuccessful) {
                    this.$notify.success('Passenger type' + (numToUpdate > 1 ? 's' : '') + ' updated.');
                } else {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
            }
        }
        
        this.$emit('update:passengers', this.passengers_);
    }

    async addPassenger() {
        const lastTripLegManifestId = (this.passengers_.length ? this.passengers_[this.passengers_.length - 1].TripLegManifestId : 0);
        const thisTripLegManifestId = (lastTripLegManifestId < 0 ? lastTripLegManifestId - 1 : -1);
        const newPassenger = new AvService.TripLegManifest();
        const position = (!this.passengers_.length ? 'Lead Passenger' : null);
        newPassenger.TripLegManifestId = thisTripLegManifestId;
        newPassenger.IsBusinessTravel = false;
        newPassenger.Position = position;
        this.passengers_.push(newPassenger);

        await this.updateField(newPassenger, 'Position', position, 'Position');
    }

    async removePassenger(passenger: AvService.TripLegManifest) {
        try {
            await this.$confirm(
                'Are you sure you want to remove this passenger?',
                'Remove Passenger',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );
            
            const index = findIndex(this.passengers_, passenger_ => {
                return passenger_.TripLegManifestId === passenger.TripLegManifestId;
            });
            if (index > -1) {
                this.passengers_.splice(index, 1);
            }

            this.$emit('update:passengers', this.passengers_);

            if (!this.autoSave) {
                return;
            }

            if (passenger.TripLegManifestId > 0) {
                const tlm = new SmartObject('TripLegManifest', passenger.TripLegManifestId);
                try {
                    await tlm.deleteObject();
                    this.$notify.success('Passenger removed.');
                }
                catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
            }
            else {
                this.$notify.success('Passenger removed.');
            }
        }
        catch {}
    }
    async setIsBusinessTravel(row: AvService.TripLegManifest){
        if (row.IsBusinessTravel && row.IsCharitable){ // if Business, can't be Charitable
            row.IsCharitable = false;
            await this.updateField(row, 'IsCharitable', row.IsCharitable, 'Charitable');    
        }
        await this.updateField(row, 'IsBusinessTravel', row.IsBusinessTravel, 'Type');
    }
    //#endregion Methods
}
