
import Vue from 'vue';
import Component from 'vue-class-component';
import * as SystemService from './../services/DAL/systemService';

declare var $: any;

@Component
export default class Dashboard extends Vue {
    //#region Private declarations for Services
    private _systemService: SystemService.SystemService;
    //#endregion Private declarations for Services

    //#region Data
    welcomePageMenuItems: SystemService.WelcomePageMenuItems[] = [];
    loading: boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._systemService = new SystemService.SystemService();

        this.fetchWelcomePageMenuItems();

        // manually setting breadcrumb below (for smart controls) means we have to manually reset it here
        $('.breadcrumb-item:last')
            .find('span')
            .text('Welcome');
    }
    //#endregion Lifecycle

    //#region Computed
    get user() {
        return this.$store.getters.account;
    }
    //#endregion Computed

    //#region Methods
    async fetchWelcomePageMenuItems() {
        this.loading = true;
        this.welcomePageMenuItems = await this._systemService.GetWelcomePageMenuItems();
        this.loading = false;
    }

    async handleClick(welcomePageMenuItem: SystemService.WelcomePageMenuItems) {
        if (welcomePageMenuItem.URL) {
            window.location.href = welcomePageMenuItem.URL;
        } else if (welcomePageMenuItem.VuePath && welcomePageMenuItem.VuePath !== '/') {
            this.$router.push('/' + welcomePageMenuItem.VuePath);
        } else if (welcomePageMenuItem.SmartControlPath) {
            const path = welcomePageMenuItem.SmartControlPath;
            const controlName = welcomePageMenuItem.SmartControlName;
            const query: any = {};

            if (welcomePageMenuItem.Parameters) {
                query.params = welcomePageMenuItem.Parameters;
            }
            if (welcomePageMenuItem.LoadType && welcomePageMenuItem.LoadType !== 'Default') {
                query.loadType = welcomePageMenuItem.LoadType;

                if (welcomePageMenuItem.Name) {
                    query.title = welcomePageMenuItem.Name;
                }
            }

            this.$router.push({
                path: `/smartControl/` + path + '/' + controlName,
                query: query
            });

            setTimeout(() => {
                $('.breadcrumb-item:last')
                    .find('span')
                    .text(welcomePageMenuItem.Name);
            });
        }
    }
    //#endregion Methods
}
