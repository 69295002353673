<template>
    <div id="divIngestAttachment">
            Ingest Attachments:
            <explorer :parameters="parameters" :security-level="securityLevel_"></explorer>
            <hr />
            <template v-if="(securityLevel_>80)">
                Existing Attachments:
            </template>
            <sharepoint-library-list v-if="securityLevel_>80" :parameters="parameters" :security-level="securityLevel_" ></sharepoint-library-list>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Explorer from './Explorer.vue'
    import SharePointLibraryList from './SharePointLibraryList.vue'

    export default Vue.extend({
        name: 'IngestAttachment'
        , components: {
            'explorer': Explorer
            , 'sharepoint-library-list': SharePointLibraryList
        }
        , props: {
            securityLevel: {
                type: Number
                , default: null
            }
        }
        , data() {
            return {
                parameters: {
                    storeId: 14 // Doc Ingest
                }
                , securityLevel_: this.securityLevel
            }
        }
        , created: function () {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDocuments), 0);
            }
        }
    })
</script>
