
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AttachmentService from '../../services/DAL/attachmentService';
import * as VamService from '../../services/DAL/vamService';
import * as Common from '../../utils/common';
import * as MicrosoftGraphTypes from "@microsoft/microsoft-graph-types" 
import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';
import MicrosoftGraphClient, { GraphRequest, ResponseType } from '@microsoft/microsoft-graph-client';

declare var SmartObject: any;
declare function tryParseInt(input: number, deflt: number): number;
declare function getStoredSecurityLevel(Id: number): number;
class CopyOrMove {
    action: string = 'move';
    message: string = 'moving file'
}

@Component
export default class AttachmentEdit extends Vue {
    //#region Private declarations for Services
    private _attachmentService: AttachmentService.AttachmentService;
    private _vamService: VamService.VamService;
    private _graphService: MicrosoftGraphService;
    private _common: Common.default;

    //#endregion Private declarations for Services

    //#region Props
    @Prop() fileId!: string;
    @Prop() fileProperties!: microsoftgraph.DriveItem; // note that microsoftgraph has to be all lowercase.  But the import is CamelCased.  This is the only way to not get Dependancy not found.
    @Prop() attachmentId!: number;
    @Prop() store!: AttachmentService.Stores;
    @Prop() ingestFromStore!: AttachmentService.Stores;
    @Prop({
         default:()=>{
          return {}
         },
        type: Object
        }) parameters: any;    
    @Prop({ type: Number, default: null }) securityLevel!: number;
    @Prop({ type: Boolean, default: false }) onlyEditDetails!: number;
    //#endregion Props

    //#region Data
    attachmentId_: number = this.attachmentId || null;
    securityLevel_: number = this.securityLevel;
    fileProperties_ = this.fileProperties || {} as microsoftgraph.DriveItem;
    sourceStoreGraphProperties = {} as microsoftgraph.DriveItem;
    destinationStoreGraphProperties = {} as microsoftgraph.DriveItem;
    store_ = this.store || this.ingestFromStore || {} as AttachmentService.Stores;
    fileId_ = this.fileId || this.$objectPropertyIfExists(this.fileProperties, 'id') || '';

    attachment = {} as AttachmentService.Attachment;
    clientIdConfirmNonSpecific: boolean = false;
    showClientSelector: boolean = true;
    gettingSourceFolder: boolean = false;
    message: string = '';
    isLoading: boolean = false;

    clients = [] as VamService.UserClients[];
    storeCategories = [] as AttachmentService.StoreCategories[];
    stores = [] as AttachmentService.Stores[];
    storesForDDL= [] as Common.DDLOptionsWithGroup[];
    attachmentTypes = [] as AttachmentService.Types[];

    fileDestinationOK = true;
    checkingDestination = false;
    
    //#endregion Data

    //#endregion Computed
    get showDeleteSourceFileAndRecord(): boolean {
        return this.securityLevel_>=80 && !!this.$objectPropertyIfExists(this.store_, 'DriveItemsPath') && this.attachment.Deletable 
            && !(this.store && this.store.IsIngestionSource)  // not if the sent-instore is an ingestion source
            && !(!this.store) // and not if there is no sent-in store
    }

    get showClientNonSpecificConfirm(){
        if (!this.attachment.ClientId && !!this.$objectPropertyIfExists(this.selectedStoreCategory, 'AllowClientNonSpecific')) return true;
        if (!this.attachment.ClientId && !(!!this.selectedStoreCategory && Object.keys(this.selectedStoreCategory).length)) return true; // if no store is selected, then assume we must verify client
        return false;
    }
    get showNext() {
        if (!!this.showClientNonSpecificConfirm && !this.clientIdConfirmNonSpecific) {
            return false;
        }
        else if (!this.attachment.StoreCategoryId || !this.attachment.TypeId) {
            return false;
        }
        else {
            return true;
        }
    }
    get selectedStoreCategory(): AttachmentService.StoreCategories {
        let cat: AttachmentService.StoreCategories = {} as AttachmentService.StoreCategories;
        if (this.$objectPropertyIfExists(this.attachment, 'StoreCategoryId')){
            cat = this._common.getSelectedArrayItem(this.storeCategories, this.attachment.StoreCategoryId.toString(), 'Id');
        }
        return cat;
    }
    get attachmentTypesForSelect(): any[] {
        return this._common.getDDLOptionsWithGroup(this.attachmentTypes, 'EntityType', 'EntityType', 'Name', 'Id');
    }
    get disableClientId(): boolean {
        if (!!this.parameters && Object.keys(this.parameters).length && !!this.parameters.clientId)
            return true;
        else if (this.store_ && !!this.store_.ClientId)
            return true;
        else
            return false;
    }   
    get sourceFolder(): string {
        if (!this.$objectPropertyIfExists(this.sourceStoreGraphProperties, 'webUrl')) return undefined;
        return decodeURI(this.sourceStoreGraphProperties.webUrl.replace('https://visiumpartners.sharepoint.com', '')) + '/';
    } 
    get destinationFolder(): string {
        if (!this.$objectPropertyIfExists(this.destinationStoreGraphProperties, 'webUrl')) return undefined;
        return decodeURI(this.destinationStoreGraphProperties.webUrl.replace('https://visiumpartners.sharepoint.com', '')) + '/';
    } 
    get sourceDestinationDifferent(): boolean {
        return (!!this.destinationStoreGraphProperties.webUrl && !!this.sourceStoreGraphProperties.webUrl && this.sourceStoreGraphProperties.webUrl !== this.destinationStoreGraphProperties.webUrl)
    }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();
        this._attachmentService = new AttachmentService.AttachmentService();
        this._graphService = new MicrosoftGraphService();
        this._common = new Common.default();

        if (this.securityLevel_ === null) {
            this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDocuments), 0);
        }
        this.store_ = this.store;
        this.fetchSourceFolder();
        await this.fetchFileProperties();
        this.fetchStoreCategories();
        this.fetchClients();
    }
    //#endregion Lifecycle
    //#region Watches
    @Watch('attachment.StoreCategoryId')
    async storeCategoryChanged(val: number, oldVal: number){
        this.fetchStores();
        this.fetchAttachmentTypes();
    }
    @Watch('attachment.StoreId')
    storeIdChanged (val, oldVal){
        if (this.store_ && val == this.store_.Id){
            return;  // don't do anything if the store_ is already the selected one
        }
        if (val){
            this.store_ = this._common.getSelectedArrayItem(this.stores, val.toString(), 'Id');
            if (this.store_) {
                this.fetchDestination();
            }
            if (this.store_ && this.store_.ClientId){
                this.attachment.ClientId = this.store_.ClientId;
            }
        }
        else {
            this.store_ = {} as AttachmentService.Stores;
        }
    }

    @Watch('attachment.ClientId')
    async clientIdChanged(val: number, oldVal: number){
        this.fetchStores();
    }
    //#endregion Watches
    //#region Methods
    async fetchClients() {
        if (!!this.parameters && !!this.parameters.clientId){
            this.attachment.ClientId = this.parameters.clientId;
            this.showClientSelector = false;
        }
        else {
            this.clients = await this._vamService.GetUserClients();
            if (this.clients.length == 1){
                this.attachment.ClientId = this.clients[0].Id;
                this.showClientSelector = false;
            }
            else {
                this.showClientSelector = true;
            }
        }
    }

    async fetchAttachmentTypes(){
        const originalTypeId = this.attachment.TypeId;
        const parms = {} as AttachmentService.GetTypesParameters;
        parms.CategoryId = this.attachment.StoreCategoryId;
        parms.EntityType = this.$objectPropertyIfExists(this.parameters, 'entityType');
        this.attachmentTypes = await this._attachmentService.GetTypes(parms);
        if (originalTypeId){
            const types = this.attachmentTypes.filter(function(type){ return type.Id === originalTypeId });
            if (!types || !types.length){ // if the previous value is not among the list of types, clear it
                this.attachment.TypeId = undefined;
            }
        }
        else if (this.attachmentTypes.length == 1){
            this.attachment.TypeId = this.attachmentTypes[0].Id;
        }
        else if (this.attachmentTypes.length == 0){
            console.warn('No Attachment Types Found, so attachment type cannot be selected, preventing moving forward.  Parameters (CategoryId, EntityType):', parms.CategoryId, parms.EntityType );
        }
        // otherwise, we already know it's not set, so no clearing needed
    }

    async fetchStores(){
        const parms = {} as AttachmentService.GetStoresParameters;
        parms.StoreCategoryId = this.attachment.StoreCategoryId;
        parms.ClientId = this.attachment.ClientId;
        this.stores = await this._attachmentService.GetStores(parms)
        this.storesForDDL = this._common.getDDLOptionsWithGroup(this.stores, 'Type', 'Type', 'Name', 'Id'); 
        if (this.stores.length ==1 ){
            this.attachment.StoreId = this.stores[0].Id;
        }
        else if (this.stores.length) {
            this.attachment.StoreId = this.stores[0].Id; //set default to first item
        }
        else {
            this.attachment.StoreId = null;
            console.error('no Store');
        }
    }
    async fetchStoreCategories(){
        const params = {} as AttachmentService.GetStoreCategoriesParameters;
        params.StoreCategoryId = this.$objectPropertyIfExists(this.parameters, 'storeCategoryId');
        this.storeCategories = await this._attachmentService.GetStoreCategories(params);
    }
    async fetchDestination(){
        this.destinationStoreGraphProperties = await this.getDriveItem(this.store_.FolderItemPath);
        if (this.sourceDestinationDifferent){
            await this.checkDestinationFile();
        }
    }
    async checkDestinationFile(){
        this.checkingDestination = true;
        this.fileDestinationOK = false;
        var suffix = '?$select=id';
        const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
        const path: string = `drives/${this.destinationStoreGraphProperties.parentReference.driveId}/items/${this.destinationStoreGraphProperties.id}:/${this.fileProperties_.name}`
        if (path){
            try {
                this.fileProperties_ = await graphClient
                    .api(path  + suffix)
                    .get();
                // if this does not error, then the file already exists, which would cause an error later.
                this.fileDestinationOK = false;
            }
            catch(err) {
                if (err.code == "itemNotFound") {
                    // this is the desired outcome
                    this.fileDestinationOK = true;
                }
                else {
                    console.error(err);
                    this.$notify.warning(err.message || 'error checking destination file folder.');
                }
            }
        }
        this.checkingDestination = false;
    }

    async getDriveItem(drive: string): Promise<MicrosoftGraphTypes.DriveItem> {
        if (drive){
            const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
            return await graphClient.api(drive).get();
        }
        else return {} as MicrosoftGraphTypes.DriveItem;
    }
    async fetchFileProperties(){
        if (this.$objectPropertyIfExists(this.fileProperties_, 'id')) {
            this.fetchAttachment();
        }
        else {
            var suffix = '?$select=name,id,folder,webUrl,createdBy,createdDateTime,parentReference';
            const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
            const path: string = this.$objectPropertyIfExists(this.ingestFromStore, 'DriveItemsPath') || this.$objectPropertyIfExists(this.store_, 'DriveItemsPath');
            if (path){
                try {
                    this.fileProperties_ = await graphClient
                        .api(path + this.fileId_ + suffix)
                        .get();
                    this.fetchAttachment();
                }
                catch(err) {
                    console.log(err);
                    if (err.code == "itemNotFound") {
                        this.$notify.warning('File not available.  You may not have permission from the File Store (One Drive or Share Point).');
                    }
                    else {
                        this.$notify.warning(err.message || 'unknown error Getting File Properties');
                    }
                    this.$nextTick(function () {
                        this.$emit('attachment-canceled');
                    });
                }
            }
        }
    }
    async fetchAttachment() {
        if (!this.attachmentId_) {
            //try to infer the date:
            var tryDate = this.fileProperties_.name.substring(0, this.fileProperties_.name.indexOf(" "));//text prior to first space
            this.attachment = new AttachmentService.Attachment();
            this.attachment.FileId = this.fileProperties_.id;
            if (this.$objectPropertyIfExists(this.parameters, 'clientId')){
                this.attachment.ClientId = this.parameters.clientId
            }
            else if (this.$objectPropertyIfExists(this.store_, 'ClientId')) {
                this.attachment.ClientId = this.store_.ClientId
            }
            else {
                this.attachment.ClientId = null;
            }
            this.attachment.Dated = this.$moment(tryDate, ["MM-DD-YYYY", "YYYY-MM-DD", "MM-DD-YY", "YY-MM-DD"]).isValid() 
                                        && this.$moment(tryDate, ["MM-DD-YYYY", "YYYY-MM-DD", "MM-DD-YY", "YY-MM-DD"]).parsingFlags().parsedDateParts.length >= 3 // "3.NCH Balkan Fund LP Agreement sgd 30 Dec 2004" results in a valid date of march 1, current year. so check that the date has at least 3 parts
                                    ? this.$moment(tryDate).format('MM/DD/YYYY') : null;
            this.attachment.Description = this.fileProperties_.name.substr(0, this.fileProperties_.name.lastIndexOf('.')) || this.fileProperties_.name;
            this.attachment.StoreCategoryId = this.$objectPropertyIfExists(this.parameters, 'storeCategoryId') || null;
            this.attachment.TypeId = (this.parameters.typeId ? this.parameters.typeId : null);
            this.attachment.Deletable = true; // when new, it has no maps, so it can be deleted
            if (this.store_) this.attachment.StoreId = this.store_.Id;

            this.clientIdConfirmNonSpecific = false;
        }
        else {
            const parms = {} as AttachmentService.GetAttachmentParameters;
            parms.AttachmentId = this.attachmentId_;
            this.attachment = (await this._attachmentService.GetAttachment(parms))[0];
            this.clientIdConfirmNonSpecific = (this.attachment.ClientId == null ? true : false);
        }
    }
    async fetchSourceFolder(){
        let store: AttachmentService.Stores = this.store_;
        if ((!this.store_ || !Object.keys(this.store_).length) && (this.ingestFromStore || Object.keys(this.ingestFromStore).length)){
            store = this.ingestFromStore;
        }

        if (this.$objectPropertyIfExists(store, 'FolderItemPath')){
            this.gettingSourceFolder = true;
            try {
                this.sourceStoreGraphProperties = await this.getDriveItem(store.FolderItemPath);
            }
            catch (err) {
                console.log(err);
                this.$notify.warning(err.message);
                this.gettingSourceFolder = false;
            }
            this.gettingSourceFolder = false;
        }
    }

    async copyAndReplaceAttachment(graphClient: MicrosoftGraphClient.Client ,sourceDriveId: string, sourceFileId: string, destinationDriveId: string, destinationItemId: string): Promise<string>{
        const res = await graphClient
            .api(`drives/${sourceDriveId}/items/${sourceFileId}/copy`)
            .responseType(ResponseType.RAW)
            .post( // NOTE: Post for Copy; send driveId and (folder)Id
            {
                "parentReference": { 
                    'driveId': destinationDriveId,
                    'id': destinationItemId
                    }
            });
        const location: string = res.headers.get("location"); 
        if (location) {
            // get the asyncOperationsResponse
            this.message = 'waiting for copy...'
            let newFileId: string = '';
            while (newFileId == ''){
                // const rawResponse: any = await this.$http.get(location, { headers: {'Authorization' : ''} }); // no auth required for location get
                // it appears that Axios tries some kind of redirect that ultimately results in a SPDY_PROTOCOL_ERR.  Fetch seems to work fine.
                const rawResponse: any = await fetch(location);
                const asyncOperationsResponse = await rawResponse.json();
                if (!!asyncOperationsResponse && asyncOperationsResponse.status == 'completed' && !!asyncOperationsResponse.resourceId){
                    newFileId = asyncOperationsResponse.resourceId;
                    this.message = 'Copied'
                }
                else if (!!asyncOperationsResponse && asyncOperationsResponse.status == 'failed'){
                    if (!!asyncOperationsResponse.error && !!asyncOperationsResponse.error.code && asyncOperationsResponse.error.code == 'nameAlreadyExists'){
                        this.message = "File name already exists in destination folder";
                        await this.$alert('Document cannot be saved because a file with the same name already exists.  Changes NOT SAVED', 'File already exists', {
                            confirmButtonText: 'OK'
                            , type: 'error'
                        });
                    }
                    else {
                        await this.$alert(`Changes NOT SAVED: ${asyncOperationsResponse.error.message}`, 'File Move Error', {
                            confirmButtonText: 'OK'
                            , type: 'error'
                        });
                    }
                    return undefined;
                }
                else {
                    this.message = this.message + '.';
                    await new Promise( resolve => setTimeout (resolve, 500));
                }
            }
            if (!!newFileId && newFileId !== this.attachment.FileId) { // when copied, a new file with new FileId is created.  Delete orig, update attachment
                this.message = 'deleting original file';
                await graphClient
                    .api(`drives/${this.sourceStoreGraphProperties.parentReference.driveId}/items/${this.attachment.FileId}`)
                    .delete();
                this.attachment.FileId = newFileId; 
            }
            this.message = '';
            return newFileId;
        }
        else {
            return undefined;
        }        
    }
    async ingestAttachment() {
        this.isLoading = true;
        if (this.sourceDestinationDifferent) {//only move file if the source of the file is an Ingestion Source
            const copyOrMove = this.copyOrMove(this.sourceFolder, this.destinationFolder);
            //OneDrive-OneDrive is "move" (single action). if SharePoint is involved, it's "Copy," which is really a two-step move - copy the file, then delete the original
            this.message = `Moving file (${copyOrMove.message})`;
            const originalFileId = this.attachment.FileId;
            let newFileId: string;
            try {
                const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
                if (copyOrMove.action == 'Copy'){
                    const sourceDriveId = this.sourceStoreGraphProperties.parentReference.driveId;
                    const sourceFileId = this.attachment.FileId;
                    const destinationDriveId = this.destinationStoreGraphProperties.parentReference.driveId;
                    const destinationItemId = this.destinationStoreGraphProperties.id;
                    newFileId = await this.copyAndReplaceAttachment(graphClient, sourceDriveId, sourceFileId, destinationDriveId, destinationItemId);
                    // newFileId will be undefined if unsuccessful (like due to duplicate name in destination folder)
                } 
                else { //move
                    const res = await graphClient
                        .api(`drives/${this.sourceStoreGraphProperties.parentReference.driveId}/items/${this.attachment.FileId}`)
                        .patch( // NOTE: Patch for move
                        {
                            "parentReference": { 
                                "id": this.store_.ItemId
                                , "driveId": this.store_.DriveId
                                }
                        });
                    newFileId = this.attachment.FileId; // implies success, but this could have an error, too.  TODO: handle error case, similar to above for CopyAndReplace.
                }
                if (newFileId){
                    this.$notify.success('Document Moved');
                    this.message = 'Document moved';
                    await this.doSave(originalFileId);
                }
                this.message = '';
            }
            catch(err){
                console.log(err);
                if (err.statusCode == 409) {
                    this.$alert('Document cannot be saved because a file with the same name already exists.  Changes NOT SAVED', 'File already exists', {
                        confirmButtonText: 'OK'
                        , type: 'error'
                        , callback: action => {
                            return;
                        }
                    });
                }
                else {
                    this.$alert(err.message, 'Error', {
                        confirmButtonText: 'OK'
                        , type: 'error'
                        , callback: action => {
                            return;
                        }
                    });
                }
                this.message = 'Error moving file.  Changes not saved.'
                return;
            }
        }
        else {
            this.doSave();
        }
        this.isLoading = false;
    }
    async doSave(originalFileId?: string) {
        if (this.attachmentId_) {// not new
            this.message = 'Saving Document Record';
            const so = new SmartObject('Attachment', this.attachmentId_);
            const success = await so.updateObject(this.attachment);
            if (success) {
                this.message = '';
                this.$notify.success('Document Updated');
                this.$emit('attachment-saved', this.attachment, this.fileProperties, originalFileId);
            }
        }
        else {
            this.message = 'Creating Document Record';
            const so = new SmartObject('Attachment');
            const newAttachmentIdInt = await so.createObject(this.attachment);
            if (newAttachmentIdInt) {
                this.attachment.Id = newAttachmentIdInt;
                this.attachment.AttachmentId = newAttachmentIdInt;
                this.message = '';
                this.$notify.success('Document Saved');
                this.$emit('attachment-saved', this.attachment, this.fileProperties_, originalFileId);
            }
        }
    }
    async deleteAttachment(fullyDelete: boolean = false) {
      try {
        if (fullyDelete){
            const deleted = await this.deleteFile(this.store_); // the passed-in store
            if (!deleted) return; // don't continue if canceled or other error
        }
        else {
            try {
                const message = 'Delete Document? (File will be returned to Ingest Folder)';
                await this.$confirm(message, 'Delete', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });
            }
            catch {
                return; // cancelled confirm
            }
            //Move back to Ingest:
            this.isLoading = true;
            const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
            let ingestStore: AttachmentService.Stores;
            if (this._common.getSelectedArrayItem(this.stores, 'Ingest', 'Type')){
                ingestStore = this._common.getSelectedArrayItem(this.stores, 'Ingest', 'Type');
            }
            else {
                const parms = {} as AttachmentService.GetStoresParameters;
                parms.Type = 'Ingest';
                const ingestStores = await this._attachmentService.GetStores(parms)
                ingestStore = ingestStores[0];
            }
            const destinationFolder = `/drive/root:${ingestStore.Folder}/`;
            const copyOrMove = this.copyOrMove(this.sourceFolder, destinationFolder);
            this.message = `Moving file (${copyOrMove.message})`;
            if (copyOrMove.action == 'Copy'){
                const sourceDriveId = this.sourceStoreGraphProperties.parentReference.driveId;
                const sourceFileId = this.attachment.FileId;
                const destinationDriveId = ingestStore.DriveId;
                const destinationItemId = ingestStore.ItemId;
                await this.copyAndReplaceAttachment(graphClient, sourceDriveId, sourceFileId, destinationDriveId, destinationItemId);
            }
            else {
                await graphClient
                    .api('drives/' + this.sourceStoreGraphProperties.parentReference.driveId + '/items/' + this.attachment.FileId)
                    .patch(
                    {
                        "parentReference": { "path": destinationFolder}
                    });
            }
            this.$notify.success('File Returned to Ingest');
        }
        if (this.attachmentId_){
            this.message = 'Deleting Document Record';
            var so = new SmartObject('Attachment', this.attachmentId_);
            await so.deleteObject();
            this.$notify.success('Document Record Deleted');
            this.$emit('attachment-deleted', this.attachment);
        }
        this.message = '';
        this.isLoading = false;
        }
        catch(err){
            this.isLoading = false;
            if (err){
                console.log(err);
                if (err.statusCode == 409) {
                    this.$alert('Document cannot be saved because a file with the same name already exists.  Changes NOT SAVED', 'File already exists', {
                        confirmButtonText: 'OK'
                        , type: 'error'
                    });
                }
                else {
                    this.$alert(err.message, {type:'error'});
                }
            }
            else {
                console.log('canceled');
            }
        }
        this.isLoading = false;
    }
    async deleteFile(store: AttachmentService.Stores): Promise<boolean> {
        // store is either the IngestFromStore or store_, which is the store for the existing attachment
        try{
            await this.$confirm('Delete File? (File will be permanently deleted)', 'Delete', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });
            const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
            const path = this.$objectPropertyIfExists(this.ingestFromStore, 'DriveItemsPath') || this.$objectPropertyIfExists(this.store_, 'DriveItemsPath')
            this.isLoading = true;
            await graphClient
                .api(path + this.fileId_)
                .delete();
            this.$notify.success('File Deleted');
            this.$emit('attachment-deleted', this.attachment);
            this.isLoading = false;
            return true;
        }
        catch(err) {
            this.isLoading = false;
            if (err) {
                console.log(err);
                return false;
            }
            else {
                console.log('canceled');
            }
            return false;
        }
    }
    copyOrMove(sourceFolder: string, destinationFolder: string): CopyOrMove {
        const ret = new CopyOrMove();
        if (destinationFolder.indexOf('/sites/') > -1 && sourceFolder.indexOf('/sites/') == -1) {
            ret.action = 'Copy';
            ret.message = 'OD->SP'
        }
        else if (destinationFolder.indexOf('/sites/') == -1 && sourceFolder.indexOf('/sites/') > -1) {
            ret.action = 'Copy';
            ret.message = 'SP->OD'
        }
        else if (destinationFolder.indexOf('/sites/') > -1 && sourceFolder.indexOf('/sites/') > -1) {
            ret.action = 'Copy';
            ret.message = 'SP->SP'
        }
        else {
            ret.action = 'Move';
            ret.message = 'OD->OD'
        }
        return ret;
    }

    //#endregion Methods
}
