
    import { Component, Vue, Prop, Watch, Ref, VModel } from "vue-property-decorator";
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import * as AssetService from '@/services/DAL/assetService';
    import * as AcctService from '@/services/DAL/acctService';
    import formatters from "@/utils/formatters";
    import uniqBy from 'lodash/uniqBy';
    import Common from '@/utils/common';
    import { Field } from "node_modules/vee-validate/types";
    import ElementUI from "element-ui";
    import EntityJournalVouchers from '@/views/GeneralLedger/EntityJournalVouchers.vue'
    import CommitmentAnalysis from '@/views/InvestmentTransaction/Capital/CommitmentAnalysis.vue';
    import * as stakeTransactionTypes from './stakeTransactionTypes';

    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;
    declare var SmartObject: any;


    @Component({
        components: {
            EntityJournalVouchers,
            CommitmentAnalysis
        }
    })
    export default class StakeTransactionInvestmentEdit extends Vue {

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private _assetService: AssetService.AssetService;
    public formatters: formatters;
    public common: Common;

    //#endregion Private declarations for Services

    //#region Props
    @VModel({ type: Object }) capitalTransactionValue!: stakeTransactionTypes.iCapitalTransaction;
    @Prop( { type: Object, required: true }) readonly header: TransactionsService.Header;
    @Prop( { type: Object, required: true }) readonly baseInvestment: AssetService.InvestmentList;
    @Prop( { type: Object }) readonly counterInvestment: AssetService.InvestmentList;
    @Prop( { type: Boolean, default: false }) readonly requireCounterInvestment: boolean;
    //#endregion Props

    //#region Data
    loading = false;
    securityLevel_: number = null;
    
    showEditDialog: boolean = false;
    showCapitalTable: boolean = true;
    enableInvestmentJournalVouchers = false;
    showInvestmentValuationPopover: boolean = false;
    showSharesPopover: boolean = false;
    commitmentAnalysis = []  as AssetService.CommitmentAnalysis[];
    investmentValuation = [] as AcctService.EntityGeneralLedger[];
    showCapitalCommitmentAnalysis: boolean = false;
    enableCommitmentAnalysis = false;
    percentCalled: number = null;
    transactionCapital = [] as TransactionsService.Capital[];
    capitalTransaction = {} as stakeTransactionTypes.iCapitalTransaction;
    isInitiating = false;
    gettingValuation = false;
    gettingCommitmentAnalysis = false;
    commitmentAnalysisResolve: (value: boolean) => void;
    commitmentAnalysisReject: (value: boolean) => void;
    investmentValuationResolve: (value: boolean) => void;
    investmentValuationReject: (value: boolean) => void;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this._transactionsService = new TransactionsService.TransactionsService();
        this.common = new Common();
    
        this.formatters = new formatters();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
        await this.$nextTick(); //catch up so that counterInvestment will be set
        this.baseInvestmentIdChanged(null, null); //initialize

    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('baseInvestment.InvestmentId')
    async baseInvestmentIdChanged(val: number, oldVal: number) {
        this.isInitiating = true;
        const promises = [
            this.getCommitmentAnalysis(),
            this.getInvestmentValuation()
        ];
        await Promise.all(promises);
        await this.$nextTick();
        // await this.getTransactionCapital(); //depends on CommitmentAnalysis
        // await this.setCapitalTransaction(); 
        await this.refreshAll();
        await this.setCalculatedFMV(); //depends on CapitalTransaction
        this.isInitiating = false;
    }
    @Watch('counterInvestment.InvestmentId')
    async counterInvestmentIdChanged(val: number, oldVal: number) {
        // await this.getTransactionCapitalCounter(); 
        await this.refreshAll();
        await this.$nextTick();
    }

    @Watch('capitalTransaction.calledCapitalTransferred')
    async calledCapitalTransferredChanged(val: number, oldVal: number) {
        this.setCalculatedFMV();
    }
    @Watch('capitalTransaction.commitmentAmount')
    async commitmentAmountChanged(val: number, oldVal: number) {
        this.setCalculatedCalledCapitalTransferred();
    }
    @Watch('capitalTransaction.fMVMethod')
    async fmvMethodChanged(val: string, oldVal: string) {
        this.setCalculatedFMV();
    }
    @Watch('capitalTransaction.capitalMethod')
    async capitalMethodChanged(val: string, oldVal: string) {
        this.setCalculatedCalledCapitalTransferred();
    }
    @Watch('percentCalled')
    async percentCalledChanged(val: number, oldVal: number) {
        this.setCalculatedCalledCapitalTransferred();
    }
    //#endregion Watches

    //#region Computed

    get percentCalledDisplay(): number {
        return this.percentCalled * 100;
    }

    set percentCalledDisplay(val: number) {
        this.percentCalled = val / 100;
    }

    get nonCalledBasisTransferredPercent(): number {
        if (this.capitalTransaction){
            return this.capitalTransaction.nonCalledBasisTransferredPercent * 100
        }
    }
    set nonCalledBasisTransferredPercent(val: number) {
        if (this.capitalTransaction){
            this.capitalTransaction.nonCalledBasisTransferredPercent = val/100
        }
    }

    get isFormValid(): boolean{
        const formValid = !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
        return formValid;
    }

    get operation(): string{
        if (this.header){
            return this.header.SubTypeShort;
        }
    }
    get operationDirection(): string{
        if (this.header){
            return this.header.SubTypeBaseDirection;
        }
    }
    get isGift(): boolean{ // Assignment, no Consideration
        return (this.header && this.header.SubOperation == 'Gift');
    }
    get noConsideration(): boolean {
        return (this.header && this.header.SubTypeNoConsideration);
    }
    get useConsideration(): boolean {
        return !this.noConsideration; 
    }

    get hasCommitment(): boolean {
        // for Buy, we have to assume we may be buying a commitment, so it doesn't matter whether there is an existing commitment. 
        //Just look at whether the investment uses commitments at all; but also make sure it has some rows in commitmentAnalysis, even if no commitment outstanding.
        if (this.operation == 'Buy' && this.baseInvestment && this.baseInvestment.HasCommitment && this.commitmentAnalysis && this.commitmentAnalysis.length) return true; 
        else if (this.commitmentAnalysis && this.commitmentAnalysis.length && this.commitmentAnalysisLastRow.CommitmentToDate > 0 && !this.isGift) return true;
        else return false;
        //8/24/22 the below method would return a number sometimes.  Also, for HF w/ Commitment, we don't want to treat it like a commitment until there actually is a commitment (i.e. CommitmentToDate > 0)
        //return (this.commitmentAnalysis && this.commitmentAnalysis.length && this.commitmentAnalysisLastRow.CommitmentToDate > 0);
    }

    get considerationDirection(): string{
        return this.oppositeDirection(this.operationDirection);
    }
    
    get isTransferOrSwap(): boolean{
        return (this.operation == 'Transfer' || this.operation == 'Swap') && !(this.header && this.header.SubTypeScope == '3rd Party');
    }

    get baseCapitalCommitment(): TransactionsService.Capital {
        if (this.transactionCapital && this.transactionCapital.length){
            return this.transactionCapital.filter(capital => (capital.InvestmentId == this.baseInvestment.InvestmentId && capital.CapitalType == 'Commitment'))[0]; //should only be 1
        }
    }
    get baseCapitalCall(): TransactionsService.Capital {
        if (this.transactionCapital && this.transactionCapital.length){
            return this.transactionCapital.filter(capital => (capital.InvestmentId == this.baseInvestment.InvestmentId && capital.CapitalType == 'Transaction'))[0]; //should only be 1
        }
    }

    get baseNonCallBasis(): TransactionsService.Capital {
        if (this.transactionCapital && this.transactionCapital.length){
            return this.transactionCapital.filter(capital => (capital.InvestmentId == this.baseInvestment.InvestmentId && capital.CapitalType == 'Transfer Non-Call Basis'))[0]; //should only be 1
        }
    }

    get counterCapitalCommitment(): TransactionsService.Capital {
        if (this.transactionCapital && this.transactionCapital.length){
            return this.transactionCapital.filter(capital => (capital.InvestmentId == this.counterInvestment.InvestmentId && capital.CapitalType == 'Commitment'))[0]; //should only be 1
        }
    }
    get counterCapitalCall(): TransactionsService.Capital {
        if (this.transactionCapital && this.transactionCapital.length){
            return this.transactionCapital.filter(capital => (capital.InvestmentId == this.counterInvestment.InvestmentId && capital.CapitalType == 'Transaction'))[0]; //should only be 1
        }
    }
    get counterNonCallBasis(): TransactionsService.Capital {
        if (this.transactionCapital && this.transactionCapital.length){
            return this.transactionCapital.filter(capital => (capital.InvestmentId == this.counterInvestment.InvestmentId && capital.CapitalType == 'Transfer Non-Call Basis'))[0]; //should only be 1
        }
    }
    get capitalTransactions(): stakeTransactionTypes.iCapitalTransaction[]{
        if (this.capitalTransactionValue) return [this.capitalTransactionValue];
    }

    get commitmentAnalysisLastRowArray(): AssetService.CommitmentAnalysis[] {
        if (this.commitmentAnalysis && this.commitmentAnalysis.length){
            return [this.commitmentAnalysis[this.commitmentAnalysis.length-1]]; // return as an array so we can show it in a table
        }
    }
    get commitmentAnalysisLastRow(): AssetService.CommitmentAnalysis {
        if (this.commitmentAnalysisLastRowArray && this.commitmentAnalysisLastRowArray.length){
            return this.commitmentAnalysisLastRowArray[0]; 
        }
    }
    get totalBasis(): number {
        if (this.investmentValuation && this.investmentValuation.length){
            return this.investmentValuation.reduce((accumulator, valuation) => {
                return accumulator + valuation.Basis;
                }, 0);
        }
    }
    get totalCalled(): number {
        if (this.investmentValuation && this.investmentValuation.length){
            return this.investmentValuation.reduce((accumulator, valuation) => {
                return accumulator + valuation.Called;
                }, 0);
        }
    }
    get totalNonCall(): number {
        return this.totalBasis - this.totalCalled;

    }
    get calculatedFMV(): stakeTransactionTypes.iCalculatedFMV{
        if (this.investmentValuation && this.investmentValuation.length && this.capitalTransaction) {
            let calledToDate: number;
            let totalCommitment: number;
            if (this.commitmentAnalysis && this.commitmentAnalysis.length){
                calledToDate = this.commitmentAnalysisLastRow.CalledToDate;
                totalCommitment = this.commitmentAnalysisLastRow.CommitmentToDate;
            }
            const basis = Math.abs(this.capitalTransaction.calledCapitalTransferred); // when sell or Transfer, the value has been flipped, so we need to flip it back.
            const existingFMV = this.investmentValuation[this.investmentValuation.length-1].RunningBalanceBalanceSheet;
            const ratio = existingFMV / (0-calledToDate);
            const fmv = ratio * basis;

            const totalBasis = this.investmentValuation.reduce((accumulator, valuation) => {
                return accumulator + valuation.Basis;
                }, 0);
            const callBasis = this.investmentValuation.reduce((accumulator, valuation) => {
                return accumulator + valuation.Called;
                }, 0);
            const nonCalledBasis = totalBasis - callBasis;


            return {
                existingFMV: existingFMV,
                existingCalledCapital: (0-calledToDate),
                ratio: ratio,
                transferredFMV: fmv,
                calledCapitalTransferred: basis,
                existingNonCallBasis: nonCalledBasis,
                totalCommitment: totalCommitment
            }; 
        }
        else return {} as stakeTransactionTypes.iCalculatedFMV;
    }
    
    get hasNoBasis(): boolean {
        if (!this.calculatedFMV) return false;
        return !(!!(this.calculatedFMV.existingCalledCapital) || !!(this.calculatedFMV.existingNonCallBasis));
    }

    get formReady(){
        return(!this.isInitiating && !this.gettingValuation && !this.gettingCommitmentAnalysis)
    }

    //#endregion Computed

    //#region Methods
    oppositeDirection (direction: string): string{
        if (direction == 'Result') return 'Source';
        if (direction == 'Source') return 'Result';
    }
    async getTransactionCapitalCounter(){
        if (this.counterInvestment && this.counterInvestment.InvestmentId){
            const params = {} as TransactionsService.GetCapitalParameters;
            params.TransactionHeaderId = this.header.Id;
            params.InvestmentId = this.counterInvestment.InvestmentId;
            const counterCapital = await this._transactionsService.GetCapital(params);
            this.transactionCapital.push(...counterCapital);
            await this.$nextTick();
            if (this.hasCommitment && (!this.counterCapitalCommitment || !this.counterCapitalCommitment.ConsiderationDirection)){ 
                const newCap = new TransactionsService.Capital();
                newCap.CapitalType = 'Commitment';
                newCap.CapitalTypeDisplay = 'Commitment';
                newCap.CapitalTypeId = 1;
                newCap.TransactionHeaderId = this.header.Id;
                newCap.Investment = this.counterInvestment.NameOwnerAccountTypeIdBank;
                newCap.InvestmentId = this.counterInvestment.InvestmentId;
                newCap.ConsiderationDirection = 'Result';
                this.transactionCapital.push(newCap);
            }
            if (this.hasCommitment && (!this.counterCapitalCall || !this.counterCapitalCall.ConsiderationDirection)){ 
                const newCap = new TransactionsService.Capital();
                newCap.CapitalType = 'Transaction';
                newCap.CapitalTypeDisplay = 'Capital Transfer';
                newCap.CapitalTypeId = 6;
                newCap.TransactionHeaderId = this.header.Id;
                newCap.Investment = this.counterInvestment.NameOwnerAccountTypeIdBank;
                newCap.InvestmentId = this.counterInvestment.InvestmentId;
                newCap.ConsiderationDirection = 'Result';
                this.transactionCapital.push(newCap);
            }
            if (this.operation != 'Buy' && (!this.counterNonCallBasis || !this.counterNonCallBasis.ConsiderationDirection)){
                const newCap = new TransactionsService.Capital();
                newCap.CapitalType = 'Transfer Non-Call Basis';
                newCap.CapitalTypeDisplay = 'Net Basis Transfer';
                newCap.CapitalTypeId = 14;
                newCap.TransactionHeaderId = this.header.Id;
                newCap.Investment = this.counterInvestment.NameOwnerAccountTypeIdBank;
                newCap.InvestmentId = this.counterInvestment.InvestmentId;
                newCap.ConsiderationDirection = 'Result';
                newCap.EntryType = 'Percent';
                this.transactionCapital.push(newCap);
            }
            //Note: no logic for counter here for Buy - non-call because there should not be a counter in that situation.

        }
    }
    async getTransactionCapital(){
        if (this.baseInvestment && this.baseInvestment.InvestmentId){
            const params = {} as TransactionsService.GetCapitalParameters;
            params.TransactionHeaderId = this.header.Id;
            params.InvestmentId = this.baseInvestment.InvestmentId;
            this.loading = true;
            const capital = await this._transactionsService.GetCapital(params);
            this.transactionCapital.push(...capital);
            await this.$nextTick();
            // create records and push them onto the stack if no records were retreived from the DB
            // but only create the types of records needed. e.g. if not hasCommitment, don't create Commitment nor CapCall records.
            if (this.hasCommitment && (!this.baseCapitalCommitment || !this.baseCapitalCommitment.ConsiderationDirection)){ 
                const newCap = new TransactionsService.Capital();
                newCap.CapitalType = 'Commitment';
                newCap.CapitalTypeDisplay = 'Commitment';
                newCap.CapitalTypeId = 1;
                newCap.TransactionHeaderId = this.header.Id;
                newCap.Investment = this.baseInvestment.NameOwnerAccountTypeIdBank;
                newCap.InvestmentId = this.baseInvestment.InvestmentId;
                newCap.ConsiderationDirection = 'Source';
                this.transactionCapital.push(newCap);
            }
            if (this.hasCommitment && (!this.baseCapitalCall || !this.baseCapitalCall.ConsiderationDirection)){ 
                const newCap = new TransactionsService.Capital();
                newCap.CapitalType = 'Transaction';
                newCap.CapitalTypeDisplay = 'Capital Transfer';
                newCap.CapitalTypeId = 6;
                newCap.TransactionHeaderId = this.header.Id;
                newCap.Investment = this.baseInvestment.NameOwnerAccountTypeIdBank;
                newCap.InvestmentId = this.baseInvestment.InvestmentId;
                newCap.ConsiderationDirection = 'Source';
                newCap.EntryType =  this.hasCommitment ? 'Calculated' : 'Manual';
                newCap.FMVMethod = newCap.EntryType;
                this.transactionCapital.push(newCap);
            }
            if (this.operation != 'Buy' && (!this.baseNonCallBasis || !this.baseNonCallBasis.ConsiderationDirection)){
                const newCap = new TransactionsService.Capital();
                newCap.CapitalType = 'Transfer Non-Call Basis';
                newCap.CapitalTypeDisplay = 'Net Basis Transfer';
                newCap.CapitalTypeId = 14;
                newCap.TransactionHeaderId = this.header.Id;
                newCap.Investment = this.baseInvestment.NameOwnerAccountTypeIdBank;
                newCap.InvestmentId = this.baseInvestment.InvestmentId;
                newCap.ConsiderationDirection = 'Source';
                newCap.EntryType = this.hasCommitment ? 'Percent' : 'Amount';
                if (!this.hasCommitment) newCap.FMVMethod = 'Manual'; // when !hasCommitment, we'll put the FMV onto the baseNonCallBasis record rather than baseCapitalCall.
                this.transactionCapital.push(newCap);
            }
            if (this.operation == 'Buy' && !this.hasCommitment && (!this.baseNonCallBasis || !this.baseNonCallBasis.ConsiderationDirection)){
                const newCap = new TransactionsService.Capital();
                newCap.CapitalType = 'Transfer Non-Call Basis';
                newCap.CapitalTypeDisplay = 'Capital Purchase'; // show it as a purchase initially.  but after the first refresh from the database, it's going to show Transfer Non-Call Basis
                newCap.CapitalTypeId = 14;
                newCap.TransactionHeaderId = this.header.Id;
                newCap.Investment = this.baseInvestment.NameOwnerAccountTypeIdBank;
                newCap.InvestmentId = this.baseInvestment.InvestmentId;
                newCap.ConsiderationDirection = 'Result';
                newCap.EntryType = 'Amount';
                newCap.FMVMethod = 'Manual';
                this.transactionCapital.push(newCap);
            }


            //CapitalTransaction logic moved to setCapitalTransaction, which will be called only after everything is retreived.

            this.showCapitalTable = false; //redraw so that the header for FMV will show
            await this.$nextTick();
            this.showCapitalTable = true;
            this.loading = false;

        }
    }
    async setCapitalTransaction(){
        this.capitalTransactionValue = {} as stakeTransactionTypes.iCapitalTransaction;
        this.capitalTransaction = {} as stakeTransactionTypes.iCapitalTransaction;
        await this.$nextTick();
        let fmv: number;
        let price: number;
        let nonCalledBasisTransferredAmount: number = null;
        const isNew = !(this.transactionCapital.some(item => {
            return !!item.CapitalId; //if any of the capital items have an id, then it's not new.
        }));

        if (this.hasCommitment && this.baseCapitalCall){
            fmv = Math.abs(this.baseCapitalCall.FMV);
            price = Math.abs(this.baseCapitalCall.Price);
        }
        else if (!this.hasCommitment && this.baseNonCallBasis){
            fmv = Math.abs(this.baseNonCallBasis.FMV);
            price = Math.abs(this.baseNonCallBasis.Price);
        }
        if (this.baseNonCallBasis){
        nonCalledBasisTransferredAmount = this.operation != 'Buy' ? 0-this.baseNonCallBasis.Amount : this.baseNonCallBasis.Amount;
        }

        const totalBasis = (this.baseNonCallBasis ? this.baseNonCallBasis.Amount : 0) + (this.baseCapitalCall ? this.baseCapitalCall.Amount : 0);
        this.capitalTransactionValue = { //this will be the copy returned to the main display.  if changes are saved, it will be updated by the refresh.
            isNew: isNew,
            commitmentAmount: (!this.baseCapitalCommitment || this.baseCapitalCommitment.Amount == null) ? 0 : Math.abs(this.baseCapitalCommitment.Amount),
            calledCapitalTransferred: (!this.baseCapitalCall || this.baseCapitalCall.Amount == null) ? 0 : Math.abs(this.baseCapitalCall.Amount),
            capitalMethod: this.hasCommitment ? this.baseCapitalCall.EntryType : this.baseNonCallBasis.EntryType,
            fMV: fmv,
            fMVMethod: this.hasCommitment ? this.baseCapitalCall.FMVMethod : this.baseNonCallBasis.FMVMethod,
            price: price
            //valuePremiumDiscount:  Math.abs(this.baseCapitalCall ? this.baseCapitalCall.FMV : 0) - (Math.abs(this.baseCapitalCall ? this.baseCapitalCall.Amount : 0) + fmv)
            , valuePremiumDiscount: fmv - price
            , nonCalledBasisMethod: this.baseNonCallBasis ? this.baseNonCallBasis.EntryType : null
            , nonCalledBasisTransferredPercent: this.baseNonCallBasis ? this.baseNonCallBasis.Factor : null
            //, nonCalledBasisTransferredAmount: this.baseNonCallBasis ? 0 - this.baseNonCallBasis.Amount : null
            , nonCalledBasisTransferredAmount: nonCalledBasisTransferredAmount

        }
        await this.$nextTick();
        this.capitalTransaction = Object.assign({}, this.capitalTransactionValue); //this will be the working copy
    }
    async editCapitalTransactionItem(
        tranche: TransactionsService.EquityTransactionTranches ,
        event,
        column
    ) {
        if (this.requireCounterInvestment && (!this.counterInvestment || !this.counterInvestment.InvestmentId)){
            this.$alert('"To" Investment must be selected before selecting shares.');
            return;
        }
        // update all the fields.  at this point, calculatedFMV will exist.
        await this.$nextTick();
        this.setCalculatedCalledCapitalTransferred();
        this.setCalculatedFMV();

        this.showEditDialog = true;
    }
    async refreshAll(){
        this.transactionCapital = [];
        await this.$nextTick(); // be sure that other threads are complete and transactionCapital is cleared
        const promises = [
            this.getTransactionCapital(),
        ];
        if (this.counterInvestment.InvestmentId){
            promises.push(this.getTransactionCapitalCounter());
        }
        await Promise.all(promises);
        await this.setCapitalTransaction();
    }
    setCapitalRecords(){
        const hadNoBasis = this.hasNoBasis && this.baseNonCallBasis && !this.baseNonCallBasis.Amount && !this.capitalTransaction.nonCalledBasisTransferredAmount; // computed hasNoBasis value might change due to next step, so set this constant.
        if (hadNoBasis){
            // temporarily set the amount to non-Zero so that all the checks for "!!this.baseNonCallBasis.Amount" will be positive.
            // then at the end set it to zero.  (It was likely undefined prior to this step.)
            this.baseNonCallBasis.Amount = 1;
            this.capitalTransaction.nonCalledBasisMethod = 'Amount';
            this.capitalTransaction.nonCalledBasisTransferredAmount = 1;
            console.log('set NonCallBasis to 1', this.hasNoBasis);
        }

        // moved from watches.  can be refactored to only check for existance of objects once.
        //capitalTransaction.calledCapitalTransferred only set when hasCommitment.
        if (this.operation == 'Buy' && this.baseCapitalCall){ // "base" is really the target, and there is no "counter"
            this.baseCapitalCall.Amount = this.capitalTransaction.calledCapitalTransferred;
        }
        else if (this.operation == 'Buy' && this.baseNonCallBasis && this.hasCommitment){ 
            this.baseNonCallBasis.Amount = this.capitalTransaction.calledCapitalTransferred;
        }
        else if (this.operation == 'Buy' && this.baseNonCallBasis && !this.hasCommitment){ 
            this.baseNonCallBasis.Amount = this.capitalTransaction.nonCalledBasisTransferredAmount;
        }
        else {
            if (this.baseCapitalCall) this.baseCapitalCall.Amount = 0 - this.capitalTransaction.calledCapitalTransferred;
            if (this.counterCapitalCall) this.counterCapitalCall.Amount = this.capitalTransaction.calledCapitalTransferred;
        }

        if (this.operation != 'Buy' && this.baseNonCallBasis && this.capitalTransaction){ // don't do nonCallBasis for Buy
            // set up non called basis:
            if (this.capitalTransaction.nonCalledBasisMethod == 'Percent'){
                this.capitalTransaction.nonCalledBasisTransferredAmount = this.capitalTransaction.nonCalledBasisTransferredPercent * this.calculatedFMV.existingNonCallBasis;
            }
            
            this.baseNonCallBasis.EntryType = this.capitalTransaction.nonCalledBasisMethod;
            if (this.counterNonCallBasis) this.counterNonCallBasis.EntryType = this.capitalTransaction.nonCalledBasisMethod;

            if (this.capitalTransaction.nonCalledBasisMethod == 'Percent') this.baseNonCallBasis.Factor = this.capitalTransaction.nonCalledBasisTransferredPercent;
            else if (this.capitalTransaction.nonCalledBasisMethod == 'Amount') this.baseNonCallBasis.Factor = null;
            if (this.counterNonCallBasis && this.capitalTransaction.nonCalledBasisMethod == 'Percent') this.counterNonCallBasis.Factor = this.capitalTransaction.nonCalledBasisTransferredPercent;
            else if (this.counterNonCallBasis && this.capitalTransaction.nonCalledBasisMethod == 'Amount') this.counterNonCallBasis.Factor = null;

            //flip the sign like every other non-Buy, just for consistency:
            this.baseNonCallBasis.Amount = 0 - this.capitalTransaction.nonCalledBasisTransferredAmount;
            if (this.counterNonCallBasis) this.counterNonCallBasis.Amount = this.capitalTransaction.nonCalledBasisTransferredAmount;
        }

        if (this.operation == 'Buy' && this.baseCapitalCommitment){ // "base" is really the target, and there is no "counter"
            this.baseCapitalCommitment.Amount = this.capitalTransaction.commitmentAmount;
        }
        else {
            if (this.baseCapitalCommitment) this.baseCapitalCommitment.Amount = 0 - this.capitalTransaction.commitmentAmount;
            if (this.counterCapitalCommitment) this.counterCapitalCommitment.Amount = this.capitalTransaction.commitmentAmount;
        }

        //capitalTransaction.price, and thus baseCapitalCall.Price, only gets set if useConsideration
        //but baseCapitalCall only exists when hasCommitment. Otherwise, use baseNonCallBasis
        if(this.hasCommitment && this.baseCapitalCall){
            this.baseCapitalCall.Price = this.capitalTransaction.price;
            if (this.counterCapitalCall) this.counterCapitalCall.Price = this.capitalTransaction.price;
        }
        else if (!this.hasCommitment && this.baseNonCallBasis){
            this.baseNonCallBasis.Price = this.capitalTransaction.price;
            if (this.counterNonCallBasis) this.counterNonCallBasis.Price = this.capitalTransaction.price;
        }

        //capitalTransaction.fMV is an input that is always set. if baseCapitalCall doesn't have Amount or Price, don't attach FMV to it. Try baseNonCallBasis.
        // "|| this.baseNonCallBasis.Amount == 0" added 9/13/24. Otherwise, FMV would not be set if Amount was 0.
        if (this.baseCapitalCall && (this.baseCapitalCall.Amount || this.baseCapitalCall.Amount == 0 || this.baseCapitalCall.Price)){
            this.baseCapitalCall.FMV = this.capitalTransaction.fMV;
            this.baseCapitalCall.FMVMethod = this.capitalTransaction.fMVMethod;
            if (this.counterCapitalCall) this.counterCapitalCall.FMV = this.capitalTransaction.fMV;
            if (this.counterCapitalCall) this.counterCapitalCall.FMVMethod = this.capitalTransaction.fMVMethod;

        }
        else if (this.baseNonCallBasis && (this.baseNonCallBasis.Amount || this.baseNonCallBasis.Amount == 0 || this.hasNoBasis)){
            this.baseNonCallBasis.FMV = this.capitalTransaction.fMV;
            this.baseNonCallBasis.FMVMethod = this.capitalTransaction.fMVMethod;
            if (this.counterNonCallBasis) this.counterNonCallBasis.FMV = this.capitalTransaction.fMV;
            if (this.counterNonCallBasis) this.counterNonCallBasis.FMVMethod = this.capitalTransaction.fMVMethod;

        }
        if (this.baseCapitalCall) this.baseCapitalCall.EntryType = this.capitalTransaction.capitalMethod;
        if (this.counterCapitalCall) this.counterCapitalCall.EntryType = this.capitalTransaction.capitalMethod;

        if (hadNoBasis && this.baseNonCallBasis){
            // reset to zero
            this.baseNonCallBasis.Amount = 0;
            this.capitalTransaction.nonCalledBasisTransferredAmount = 0;
            if (this.counterNonCallBasis) this.counterNonCallBasis.Amount = 0;
        }


    }
    async saveStake(){
        this.loading = true;
        if (this.capitalTransaction.commitmentAmount == null){
            this.capitalTransaction.commitmentAmount = 0;
            await this.$nextTick();
        }
        this.setCapitalRecords();
        // Commitment
        if (this.baseCapitalCommitment && this.baseCapitalCommitment.Id){
            await new SmartObject('InvestmentTransactionCapital', this.baseCapitalCommitment.Id).updateObject(
                this.baseCapitalCommitment
            );
            this.$notify.success('Base Capital Commitment Updated');
        }
        else if (this.baseCapitalCommitment && this.baseCapitalCommitment.Amount) { // only create a new record if the amount is non-0
            const newId = await new SmartObject('InvestmentTransactionCapital').createObject(
                this.baseCapitalCommitment
            );
            this.baseCapitalCommitment.Id = newId;
            this.$notify.success('Base Capital Commitment Created');
        }

        // Call:
        if (this.baseCapitalCall && this.baseCapitalCall.Id){
            await new SmartObject('InvestmentTransactionCapital', this.baseCapitalCall.Id).updateObject(
                this.baseCapitalCall
            );
            this.$notify.success('Base Capital Call Updated');
        }
        else if (this.baseCapitalCall && (this.baseCapitalCall.Amount || this.baseCapitalCall.Price || this.baseCapitalCall.FMV)) {
            const newId = await new SmartObject('InvestmentTransactionCapital').createObject(
                this.baseCapitalCall
            );
            this.baseCapitalCall.Id = newId;
            this.$notify.success('Base Capital Call Created');
        }

        // Non-Call Basis:
        if (this.baseNonCallBasis && this.baseNonCallBasis.Id){
            await new SmartObject('InvestmentTransactionCapital', this.baseNonCallBasis.Id).updateObject(
                this.baseNonCallBasis
            );
            this.$notify.success('Base Net Basis Transfer Updated');
        }
        else if (this.baseNonCallBasis && (this.baseNonCallBasis.Amount || this.hasNoBasis)) {
            const newId = await new SmartObject('InvestmentTransactionCapital').createObject(
                this.baseNonCallBasis
            );
            this.baseNonCallBasis.Id = newId;
            this.$notify.success('Base Net Basis Transfer Created');
        }


        // counter:
        //set Source Ids
        if (this.counterCapitalCommitment) this.counterCapitalCommitment.SourceId = this.baseCapitalCommitment.Id;
        if (this.counterCapitalCall) this.counterCapitalCall.SourceId = this.baseCapitalCall.Id;
        if (this.counterNonCallBasis) this.counterNonCallBasis.SourceId = this.baseNonCallBasis.Id;

        // Commitment:
        if (this.counterCapitalCommitment && this.counterCapitalCommitment.Id){
            await new SmartObject('InvestmentTransactionCapital', this.counterCapitalCommitment.Id).updateObject(
                this.counterCapitalCommitment
            );
            this.$notify.success('Counter Capital Commitment Updated');
        }
        else if (this.counterCapitalCommitment && this.counterCapitalCommitment.Amount) { // only create a new record if the amount is non-0
            const newId = await new SmartObject('InvestmentTransactionCapital').createObject(
                this.counterCapitalCommitment
            );
            this.counterCapitalCommitment.Id = newId;
            this.$notify.success('Counter Capital Commitment Created');
        }

        // Call:
        if (this.counterCapitalCall && this.counterCapitalCall.Id){
            await new SmartObject('InvestmentTransactionCapital', this.counterCapitalCall.Id).updateObject(
                this.counterCapitalCall
            );
            this.$notify.success('Counter Capital Call Updated');
        }
        else if (this.counterCapitalCall && (this.counterCapitalCall.Amount || this.counterCapitalCall.Price || this.counterCapitalCall.FMV)) {
            const newId = await new SmartObject('InvestmentTransactionCapital').createObject(
                this.counterCapitalCall
            );
            this.counterCapitalCall.Id = newId;
            this.$notify.success('Counter Capital Call Created');
        }

        // Non-Call Basis:
        if (this.counterNonCallBasis && this.counterNonCallBasis.Id){
            await new SmartObject('InvestmentTransactionCapital', this.counterNonCallBasis.Id).updateObject(
                this.counterNonCallBasis
            );
            this.$notify.success('Counter Net Basis Transfer Updated');
        }
        else if (this.counterNonCallBasis && (this.counterNonCallBasis.Amount || this.hasNoBasis)) {
            const newId = await new SmartObject('InvestmentTransactionCapital').createObject(
                this.counterNonCallBasis
            );
            this.counterNonCallBasis.Id = newId;
            this.$notify.success('Counter Net Basis Transfer Created');
        }

        this.loading = false;
        await this.refreshAll();
        this.showEditDialog = false;
    }
    async getInvestmentValuation(){
        this.investmentValuation = [] as AcctService.EntityGeneralLedger[];
        this.gettingValuation = true; // this controls the form being available
        this.enableInvestmentJournalVouchers = false; // this controls the JV pop up itself.  false/tick/true refreshes it.
        await this.$nextTick(); // resets, needed after reapply Valuations
        this.enableInvestmentJournalVouchers = true; // this will trigger fetchedInvestmentJournalVouchers
        await this.$nextTick();
        // the following will wait for fetchedInvestmentJournalVouchers
        await new Promise((resolve, reject) => {
            this.investmentValuationResolve = resolve;
            this.investmentValuationReject = reject
        })

    }
    fetchedInvestmentJournalVouchersDisplay(data: AcctService.EntityGeneralLedger[], keepOpen: boolean = false){
        this.investmentValuation = data;
        this.gettingValuation = false;
        this.investmentValuationResolve(true);
    }
    fetchedInvestmentJournalVouchers(data: AcctService.EntityGeneralLedger[], keepOpen: boolean = false){
        this.investmentValuation = data;
        this.showInvestmentValuationPopover = false; // will close the pop up after initially loading the data
        this.gettingValuation = false;
        this.investmentValuationResolve(true);
    }
    fetchedCommitmentAnalysis(data: AssetService.CommitmentAnalysis[]){
        this.commitmentAnalysis = data;
        if (this.commitmentAnalysis && this.commitmentAnalysis.length && (this.percentCalled == null || this.percentCalled == undefined || isNaN(this.percentCalled))){
            if (this.commitmentAnalysisLastRow.CommitmentToDate == 0 || this.commitmentAnalysisLastRow.CommitmentToDate == null){
                this.percentCalled = null;
            }
            else {
                this.percentCalled = (0-this.commitmentAnalysisLastRow.CalledToDate) / this.commitmentAnalysisLastRow.CommitmentToDate;
            }
        }
        this.gettingCommitmentAnalysis = false;
        this.commitmentAnalysisResolve(true);    
    }
    async getCommitmentAnalysis(){
        this.showCapitalCommitmentAnalysis = true;
        this.gettingCommitmentAnalysis = true;
        this.enableCommitmentAnalysis = true; // this will trigger fetchedCommitmentAnalysis
        await this.$nextTick();
        this.showCapitalCommitmentAnalysis = false;
        // the following will wait for fetchedCommitmentAnalysis
        await new Promise((resolve, reject) => {
            this.commitmentAnalysisResolve = resolve;
            this.commitmentAnalysisReject = reject
        })
    }
    async deleteStake() {
            try {
                await this.$confirm(
                    'Are you sure you want to delete this item?  Doing so will delete the Source and Target Capital Commitment and Call records. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    if (this.counterCapitalCommitment && this.counterCapitalCommitment.Id){
                        // must do the counter-item first because there's a constraint between it and the primary item.
                        await new SmartObject(
                            'InvestmentTransactionCapital',
                            this.counterCapitalCommitment.Id
                        ).deleteObject();
                        this.$notify.success('Counter Capital Commitment item deleted.');
                    }
                    if (this.counterCapitalCall && this.counterCapitalCall.Id){
                        // must do the counter-item first because there's a constraint between it and the primary item.
                        await new SmartObject(
                            'InvestmentTransactionCapital',
                            this.counterCapitalCall.Id
                        ).deleteObject();
                        this.$notify.success('Counter Capital Call item deleted.');
                    }
                    if (this.counterNonCallBasis && this.counterNonCallBasis.Id){
                        // must do the counter-item first because there's a constraint between it and the primary item.
                        await new SmartObject(
                            'InvestmentTransactionCapital',
                            this.counterNonCallBasis.Id
                        ).deleteObject();
                        this.$notify.success('Counter Non Call Basis item deleted.');
                    }
                    if (this.baseCapitalCommitment && this.baseCapitalCommitment.Id){
                        await new SmartObject(
                            'InvestmentTransactionCapital',
                            this.baseCapitalCommitment.Id
                        ).deleteObject();
                        this.$notify.success('Base Capital Commitment item deleted.');
                    }
                    if (this.baseCapitalCall && this.baseCapitalCall.Id){
                        await new SmartObject(
                            'InvestmentTransactionCapital',
                            this.baseCapitalCall.Id
                        ).deleteObject();
                        this.$notify.success('Base Capital Call item deleted.');
                    }
                    if (this.baseNonCallBasis && this.baseNonCallBasis.Id){
                        await new SmartObject(
                            'InvestmentTransactionCapital',
                            this.baseNonCallBasis.Id
                        ).deleteObject();
                        this.$notify.success('Base Non Call Basis item deleted.');
                    }
        

                } catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
                this.showEditDialog = false;
                this.capitalTransaction = {} as stakeTransactionTypes.iCapitalTransaction;
                this.$emit('deleted');
            } catch {}
    }

    setCalculatedFMV(){
        if (this.capitalTransaction.fMVMethod == 'Calculated' && this.calculatedFMV && this.calculatedFMV.transferredFMV){
            this.capitalTransaction.fMV = this.calculatedFMV.transferredFMV;
        }
    }
    setCalculatedCalledCapitalTransferred(){
        if (this.capitalTransaction.capitalMethod == 'Calculated'){
            this.capitalTransaction.calledCapitalTransferred = this.percentCalled * this.capitalTransaction.commitmentAmount;
        }
    }


//#endregion Methods

    }
    
