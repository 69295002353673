
import { Component, Vue, Watch, Prop, VModel, Ref } from 'vue-property-decorator';
import * as aviationTypes from '@/views/Aviation/AviationTypes'
import * as AvService from '@/services/DAL/avService';
import Common from "@/utils/common";
import findIndex from 'lodash/findIndex';
import AircraftSelector from '@/components/form/AircraftSelector.vue'
import ElementUI from 'element-ui';

declare var SmartObject: any;

class PurposeCompany extends AvService.PurposeCompany{
    newVisitedPerson?: aviationTypes.VisitedPerson;
    constructor(){
        super();
        this.Id = null;
        this.TripId = null;
        this.LegId = null;
        this.CompanyName = null;
        this.Purpose = null;
        this.newVisitedPerson = new aviationTypes.VisitedPerson();
    }
}

@Component({
    components: {
        AircraftSelector
    }
})
export default class CompanyAndPurposeEdit extends Vue {
    @Ref() readonly refReferenceTripsTable!: ElementUI.Table;

    private _avService: AvService.AvService;
    private common: Common;

    @Prop({ type: Object, required: true }) tripLeg!: AvService.TripLegs;

    purposeCompanys = [] as AvService.PurposeCompany[];
    loading = false;
    vpResolve: (value: aviationTypes.VisitedPerson) => void;
    vpReject: (value: boolean) => void;
    vpUpdateResolve: (value: aviationTypes.VisitedPerson) => void;
    vpUpdateReject: (value: boolean) => void;

    pcResolve: (value: PurposeCompany) => void;
    pcReject: (value: boolean) => void;
    pcUpdateResolve: (value: PurposeCompany) => void;
    pcUpdateReject: (value: boolean) => void;
    showVisitedPersonEdit = false;
    selectedVisitedPerson = {} as aviationTypes.VisitedPerson;
    selectedPurposeCompany = {} as PurposeCompany;
    showPurposeCompanyEdit = false;
    referenceAircraftId: number = this.tripLeg.AircraftId; //default the same aircraft
    showReferenceLegSelector: boolean = false;
    referenceFromDate: string = this.$dayjs(this.tripLeg.LegDepartureDate).add(-1, 'D').format('MM/DD/YYYY');
    referenceToDate: string = this.$dayjs(this.tripLeg.LegDepartureDate).add(2, 'D').format('MM/DD/YYYY');
    referenceLegs = [] as AvService.TripLegs[];

    get disablePCSave(): boolean{
        if (this.selectedPurposeCompany && this.selectedPurposeCompany.Id){
            return (!this.selectedPurposeCompany.CompanyName || !this.selectedPurposeCompany.Purpose);
        }
        else if (this.selectedPurposeCompany && !this.selectedPurposeCompany.Id){
            return (!this.selectedPurposeCompany.CompanyName || !this.selectedPurposeCompany.Purpose
                || !this.selectedPurposeCompany.newVisitedPerson.Name || !this.selectedPurposeCompany.newVisitedPerson.Title);
        }
    }

    get isFirstLeg(): boolean{
        return (this.tripLeg && this.tripLeg.SourceLegId == '1');
    }

    get disableChanges(): boolean {
        //first, if CompanyPurpose is set by CompanyPurposeReferenceTripLegId, then disabled.
        if (this.tripLeg.CompanyPurposeReferenceTripLegId) return true;

        // when tripId is set and LegId is not, that indicates that data is inherited from the first leg.  So the setting can't be changed.
        // and that will be the case for any and all the records, so use .some:
        return this.purposeCompanys.some((purposeCompany) => {
            return (!!purposeCompany && !!purposeCompany.TripId && !purposeCompany.LegId)
            });
    }

    get setAtTripLevel(): boolean {
        return (!!this.selectedPurposeCompany && !!this.selectedPurposeCompany.TripId);
    }
    set setAtTripLevel(val: boolean){
        if (this.selectedPurposeCompany){
            this.selectedPurposeCompany.TripId = val ? this.tripLeg.TripId : null;
        }
    }

    get settingsApplyForAllLegs(): boolean{
        if (this.tripLeg && this.tripLeg.IsOnlyLegWithPassengers) return false; //doesn't matter if it's the only leg

        return this.purposeCompanys.some((purposeCompany) => {
            return (!!purposeCompany && !!purposeCompany.TripId && purposeCompany.LegId)
            });
    }
    get referenceDateRange() {
        return [this.referenceFromDate, this.referenceToDate];
    }
    set referenceDateRange(value) {
        this.referenceFromDate = this.$moment(value[0]).format('MM/DD/YYYY');
        this.referenceToDate = this.$moment(value[1]).format('MM/DD/YYYY');
        this.getReferenceLegs();
    }


    @Watch('referenceAircraftId')
    onChangeReferenceAircraftId(val: number, oldVal: number) {
        this.getReferenceLegs();
    }
    @Watch('showReferenceLegSelector')
    onChangeshowReferenceLegSelector(val: number, oldVal: number) {
        if (this.showReferenceLegSelector) this.getReferenceLegs();
    }


    created() {
        this._avService = new AvService.AvService();
        this.common = new Common();
        this.getPurposeCompany();
        this.getReferenceLeg();
    }

    async getPurposeCompany(){
        this.loading = true;
        const params = {} as AvService.GetPurposeCompanyParameters;
        params.TripLegId = this.tripLeg.CompanyPurposeReferenceTripLegId || this.tripLeg.LegId;
        this.purposeCompanys = await this._avService.GetPurposeCompany(params);
        this.$emit('update-count', this.purposeCompanys.length);
        this.loading = false;
    }

    async getReferenceLegs(){
        this.loading = true;
        this.referenceLegs = [] as AvService.TripLegs[];
        if (!this.referenceAircraftId) return; //require AircraftId
        const params = {} as AvService.GetTripLegsParameters;
        params.AircraftId = this.referenceAircraftId;
        params.DateRangeStart = this.referenceFromDate;
        params.DateRangeEnd = this.referenceToDate;
        params.ClientId = this.tripLeg.ClientId; //ensures same client id
        const legs = await this._avService.GetTripLegs(params);
        this.referenceLegs = legs.filter(leg => leg.CanBeUsedForCompanyPurposeReference);
        this.loading = false;
        await this.$nextTick();
        if (this.refReferenceTripsTable && this.tripLeg.CompanyPurposeReferenceTripLegId && this.referenceLegs && this.referenceLegs.length) {
            const refLeg = this.common.getArrayItemFromConditions(this.referenceLegs, { LegId: this.tripLeg.CompanyPurposeReferenceTripLegId });
            this.refReferenceTripsTable.setCurrentRow(refLeg);
        }
    }
    async getReferenceLeg(){
        if (!this.tripLeg.CompanyPurposeReferenceTripLegId) return; 

        this.loading = true;
        const params = {} as AvService.GetTripLegsParameters;
        params.TripLegId = this.tripLeg.CompanyPurposeReferenceTripLegId
        params.ClientId = this.tripLeg.ClientId; //ensures same client id
        const legs = await this._avService.GetTripLegs(params);
        if (legs && legs.length ==1){
            this.referenceAircraftId = legs[0].AircraftId;
            this.referenceFromDate = this.$dayjs(legs[0].LegDepartureDate).format('L');
            this.referenceToDate = this.$dayjs(legs[0].LegDepartureDate).format('L');
        }
        this.loading = false;
    }

    async editVisitedPersonItem(visitedPerson: aviationTypes.VisitedPerson, column, event, purposeCompany: AvService.PurposeCompany){
        if (this.disableChanges) return;
        try {
            this.selectedVisitedPerson = visitedPerson;
            this.selectedPurposeCompany = purposeCompany;
            this.showVisitedPersonEdit=true;
            // the follow will wait for the dialog to be closed
            this.selectedVisitedPerson = await new Promise<aviationTypes.VisitedPerson>((resolve, reject) => {
                this.vpUpdateResolve = resolve;
                this.vpUpdateReject = reject
            })

            if (this.selectedVisitedPerson.Id){
                const update = await new SmartObject('VisitedPerson', this.selectedVisitedPerson.Id).updateObject(this.selectedVisitedPerson);
                if (update != this.selectedVisitedPerson.Id) console.error('update did not result in same id');
                this.selectedVisitedPerson = new aviationTypes.VisitedPerson();
                this.selectedPurposeCompany = new PurposeCompany();
            }
        }catch{
            console.log('Edit Visited Person canceled');
            this.selectedVisitedPerson = new aviationTypes.VisitedPerson();
            this.selectedPurposeCompany = new PurposeCompany();
        }
    }
    async editPurposecompanyItem(purposeCompany: AvService.PurposeCompany){
        if (this.disableChanges) return;
        try {
            this.selectedPurposeCompany = purposeCompany; // these are not exactly the same time, but should be ok
            //update the People count for deleting purposes
            const people = JSON.parse(this.selectedPurposeCompany.VisitedPersonArrayJSON);
            this.selectedPurposeCompany.PeopleCount = people.length;
            this.showPurposeCompanyEdit=true;
            // the follow will wait for the dialog to be closed
            this.selectedPurposeCompany = await new Promise<PurposeCompany>((resolve, reject) => {
                this.pcUpdateResolve = resolve;
                this.pcUpdateReject = reject
            })

            if (this.selectedPurposeCompany.Id){
                const update = await new SmartObject('PurposeCompany', this.selectedPurposeCompany.Id).updateObject(this.selectedPurposeCompany);
                if (update != this.selectedPurposeCompany.Id) console.error('update did not result in same id');
                this.selectedPurposeCompany = new PurposeCompany();
            }
        }catch{
            console.log('Edit Company / Purpose canceled');
            this.selectedPurposeCompany = new PurposeCompany();
        }
      
    }
    async addPurposeCompany(){
        if (this.disableChanges) return;
        try {
            this.selectedPurposeCompany = new PurposeCompany();
            this.selectedPurposeCompany.newVisitedPerson = new aviationTypes.VisitedPerson();
            if (this.settingsApplyForAllLegs) this.selectedPurposeCompany.TripId = this.tripLeg.TripId;
            this.selectedPurposeCompany.LegId = this.tripLeg.LegId;
            this.showPurposeCompanyEdit = true;
            // the following will wait for the dialog to be closed
            this.selectedPurposeCompany = await new Promise<PurposeCompany>((resolve, reject) => {
                this.pcResolve = resolve;
                this.pcReject = reject
            })
       
            const id = await new SmartObject('PurposeCompany').createObject(this.selectedPurposeCompany);
            if (id){
                this.selectedPurposeCompany.Id = id;
                if (this.selectedPurposeCompany.newVisitedPerson){
                    this.selectedPurposeCompany.newVisitedPerson.PurposeCompanyId = id;
                    const vpId = await new SmartObject('VisitedPerson').createObject(this.selectedPurposeCompany.newVisitedPerson);
                    if (vpId){
                        this.selectedPurposeCompany.newVisitedPerson.Id = vpId;
                        this.selectedPurposeCompany.VisitedPersonArrayJSON = JSON.stringify([this.selectedPurposeCompany.newVisitedPerson]);
                    }
                }
                this.purposeCompanys.push(this.selectedPurposeCompany);
                this.$emit('update-count', this.purposeCompanys.length);
            }
            this.selectedPurposeCompany = new PurposeCompany();
        }catch(e){
            console.log(e);
            console.log('New Purpose Company canceled');
        }

    }
    async addVisitedPerson(purposeCompany: AvService.PurposeCompany){
        if (this.disableChanges) return;
        try {
            this.selectedVisitedPerson = new aviationTypes.VisitedPerson();
            this.showVisitedPersonEdit=true;
            // the following will wait for the dialog to be closed
            this.selectedVisitedPerson = await new Promise<aviationTypes.VisitedPerson>((resolve, reject) => {
                this.vpResolve = resolve;
                this.vpReject = reject
            })
            this.selectedVisitedPerson.PurposeCompanyId = purposeCompany.Id;
       
            const id = await new SmartObject('VisitedPerson').createObject(this.selectedVisitedPerson);
            if (id){
                this.selectedVisitedPerson.Id = id;
                const existing: aviationTypes.VisitedPerson[] = JSON.parse(purposeCompany.VisitedPersonArrayJSON);
                existing.push(this.selectedVisitedPerson);
                purposeCompany.VisitedPersonArrayJSON = JSON.stringify(existing);
            }
            this.selectedVisitedPerson = new aviationTypes.VisitedPerson();
        }catch{
            console.log('New Visited Person canceled');
            this.selectedVisitedPerson = new aviationTypes.VisitedPerson();
        }
    }
    btnAddVisitedPerson(){
        if (this.selectedVisitedPerson && this.selectedVisitedPerson.Id){
            this.vpUpdateResolve(this.selectedVisitedPerson);    
        }
        else {
            this.vpResolve(this.selectedVisitedPerson);    
        }
        this.showVisitedPersonEdit = false;
    }
    btnCancelVisitedPerson(){
        if (this.selectedVisitedPerson && this.selectedVisitedPerson.Id){
            this.vpUpdateReject(true);
        }
        else {
            this.vpReject(true);
        }        
        this.showVisitedPersonEdit = false;
    }
    async btnDeleteVisitedPerson(){
        try {
            await this.$confirm(
            'Are you sure you want to delete this Visited Person?',
            'Warning',
            {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }
            );

            try {
                await new SmartObject('VisitedPerson', this.selectedVisitedPerson.Id).deleteObject();
                this.$notify.success('Visited Person deleted.');
                const existing: aviationTypes.VisitedPerson[] = JSON.parse(this.selectedPurposeCompany.VisitedPersonArrayJSON);
                //const index = existing.indexOf(this.selectedVisitedPerson);

                const index = findIndex(existing, this.selectedVisitedPerson, function(vp, selectedVisitedPerson) { return vp.Id == selectedVisitedPerson.Id })
                if (index > -1){
                    existing.splice(index, 1);
                    this.selectedPurposeCompany.VisitedPersonArrayJSON = JSON.stringify(existing);
                }
                this.vpUpdateReject(false);
                this.showVisitedPersonEdit = false;
                this.selectedPurposeCompany = new PurposeCompany();
                this.selectedVisitedPerson = new aviationTypes.VisitedPerson();
            } catch (e) {
                this.$notify.error(e);
                console.log(e);
            }
        } 
        catch {} // cancelled

    }

    btnSavePurposeCompany(){
        if (this.selectedPurposeCompany && this.selectedPurposeCompany.Id){
            this.pcUpdateResolve(this.selectedPurposeCompany);    
        }
        else {
            this.pcResolve(this.selectedPurposeCompany);    
        }
        this.showPurposeCompanyEdit = false;
    }
    btnCancelPurposeCompany(){
        if (this.selectedPurposeCompany && this.selectedPurposeCompany.Id){
            this.pcUpdateReject(true);
        }
        else {
            this.pcReject(true);
        }        
        this.showPurposeCompanyEdit = false;
    }
    async btnDeletePurposeCompany(){
        try {
            await this.$confirm(
            'Are you sure you want to delete this Company and Purpose?',
            'Warning',
            {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }
            );

            try {
                await new SmartObject('PurposeCompany', this.selectedPurposeCompany.Id).deleteObject();
                this.$notify.success('Company / Purpose record deleted.');
                // selectedPurposeCompany is not the same as the row from purposeCompanys, so get the row by Id:
                const row = this.common.getSelectedArrayItem(this.purposeCompanys,this.selectedPurposeCompany.Id.toString(), 'Id')
                const index = this.purposeCompanys.indexOf(row);
                if (index > -1){
                    this.purposeCompanys.splice(index, 1);
                }
                this.pcUpdateReject(false);
                this.showPurposeCompanyEdit = false;
                this.$emit('update-count', this.purposeCompanys.length);
                this.selectedPurposeCompany = new PurposeCompany();
            } catch (e) {
                this.$notify.error(e);
                console.log(e);
            }
        } 
        catch {} // cancelled
    }

    async selectTripLegForReference(refTripLeg: AvService.TripLegs){
        if (!refTripLeg.CanBeUsedForCompanyPurposeReference) return;
        this.tripLeg.CompanyPurposeReferenceTripLegId = refTripLeg.LegId;
        this.tripLeg.CompanyPurposeReferenceTripLegDescription = refTripLeg.TailNumber + ' ' + this.$dayjs(refTripLeg.LegDepartureDate).format('L') + ' ' + refTripLeg.FromCode + '->' + refTripLeg.ToCode;
        if (this.refReferenceTripsTable) this.refReferenceTripsTable.setCurrentRow(refTripLeg);
        await new SmartObject('TripLeg', this.tripLeg.LegId).updateObject({CompanyPurposeReferenceTripLegId: refTripLeg.LegId});
        this.$notify.success('Company / Purpose reference updated.');
        this.getPurposeCompany();
        this.showReferenceLegSelector = false;
    }
}

