
// import Vue from "vue";
// import Component from "vue-class-component";
import { Component, Vue, Watch, Prop, Model } from "vue-property-decorator";
import * as DebtService from "../../services/DAL/debtService";
import { readonly } from "vue";

@Component({
    inheritAttrs: false
})
export default class PromissoryNoteSelector extends Vue {
    //#region Private declarations for Services
    private _debtService: DebtService.DebtService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() readonly value: number;
    @Prop({type: Number}) readonly lenderOwnerEntityId: number;
    @Prop({type: Number}) readonly debtorOwnerEntityId: number;
    //#endregion Props

    //#region Data
    notes = [] as DebtService.PromissoryNotes[];
    selectedValue: number | null = null;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._debtService = new DebtService.DebtService();
        this.selectedValue = this.value;
        await this.fetchNotes();
    }

    //#endregion Lifecycle

    //#region Watches
    @Watch("value")
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
        if (oldVal && !val) {
            this.fetchNotes();
        }
    }

    @Watch("lenderOwnerEntityId")
    onChange_ownerId(val: number, oldVal: number) {
        this.fetchNotes();
    }
    @Watch("debtorOwnerEntityId")
    onChange_ownerIdList(val: number, oldVal: number) {
        this.fetchNotes();
    }
    //#endregion Watches

    //#region Methods
    async fetchNotes() {
        this.notes = [];
        const params = {} as DebtService.GetPromissoryNotesParameters;
        params.LenderOwnerEntityId = this.lenderOwnerEntityId;
        params.DebtorOwnerEntityId = this.debtorOwnerEntityId;
        params.PromissoryNoteId = ((typeof this.selectedValue === 'number') ? this.selectedValue : null) as number;
        console.log(params);
        this.notes = await this._debtService.GetPromissoryNotes(params);
    }
    //#endregion Methods
}
