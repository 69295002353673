
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import ClientSelector from '@/components/form/ClientSelector.vue';
import FormattedInput from '@/components/form/FormattedInput.vue';
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component({
    components: {
        ClientSelector,
        FormattedInput
    }
})
export default class GeneralPartnerPointEdit extends Vue {
    $refs: {
        frmGeneralPartnerPointEdit: ElementUI.Form
    }
    //#region Data
    private _assetService: AssetService.AssetService;

    @Prop() generalPartnerPoint: AssetService.GeneralPartnerPoints;
    @Prop() investmentParentId: number;
    @Prop() viewType: string;

    generalPartnerPoint_: AssetService.GeneralPartnerPoints = new AssetService.GeneralPartnerPoints();
    isLoading: boolean = false;
    //#endregion Data

    //#region Lifecycle
    created() {
        if (this.generalPartnerPoint && Object.keys(this.generalPartnerPoint).length) {
            this.generalPartnerPoint_ = this.generalPartnerPoint;
        }
        this._assetService = new AssetService.AssetService();
    }

    mounted() {
        this.$refs.frmGeneralPartnerPointEdit.resetFields();
    }
    //#endregion Lifecycle

    //#region Computed
    get isNew(): boolean {
        return (
            !this.generalPartnerPoint_ ||
            !Object.keys(this.generalPartnerPoint_).length ||
            (this.generalPartnerPoint_.Id || 0) <= 0
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    get yearDate(): any {
        return (this.generalPartnerPoint_.Year ? new Date(this.generalPartnerPoint_.Year, 0, 1) : null)
    }
    set yearDate(value) {
        this.generalPartnerPoint_.Year = (value ? new Date(value).getFullYear() : null);
    }
    //#endregion Computed

    //#region Methods
    async saveForm() {
        this.$refs.frmGeneralPartnerPointEdit.validate(async valid => {
            if (valid) {
                let message: string;
                const isNew = this.isNew;
                this.isLoading = true;

                try {
                    if (isNew) {
                        this.generalPartnerPoint_.InvestmentParentId = this.investmentParentId;
                        this.generalPartnerPoint_.Id = await new SmartObject('GeneralPartnerAnnualPoints').createObject(this.generalPartnerPoint_);
                        message = 'General partner point added.';
                    } else {
                        await new SmartObject('GeneralPartnerAnnualPoints', this.generalPartnerPoint_.Id).updateObject(this.generalPartnerPoint_);
                        message = 'Changes saved.';
                    }
                }
                catch (err) {
                    try {
                        const errJson = JSON.parse(err.responseText);
                        if (errJson.ExceptionMessage.toLowerCase().indexOf('cannot insert duplicate key') > -1) {
                            this.$notify.error('There is already a record for this investment parent for the selected client and year. Please edit the existing record, or change the client or year and try again.');
                        }
                        else {
                            this.$notify.error('Something went wrong processing your request, please try again.');
                        }
                    }
                    catch {
                        this.$notify.error('Something went wrong processing your request, please try again.');
                    }
                    this.isLoading = false;
                    return;
                }

                this.$notify.success(message);
                this.isLoading = false;

                this.$emit('save', this.generalPartnerPoint_.Id);
                this.$emit('update:generalPartnerPoint', this.generalPartnerPoint_);

                if (isNew) {
                    this.$emit('close');
                }
            }
        });
    }

    async deleteGeneralPartnerPoint() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this general partner point. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    this.isLoading = true;
                    await new SmartObject('GeneralPartnerAnnualPoints', this.generalPartnerPoint_.Id).deleteObject();

                    this.$notify.success('General partner point deleted.');
                    this.isLoading = false;

                    this.$emit('delete', this.generalPartnerPoint_.Id);
                    this.$emit('close');
                } catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                    this.isLoading = false;
                }
            } catch {}
        }
    }
    //#endregion Methods
}
