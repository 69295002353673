// TS API for Schema fr
// Auto-generated 05/08/23 16:30


declare var smartData: any;
// output classes:
export class ReceivedEmailsAndInvoiceProcessing {
    ReceivedEmailId: number=undefined;
    ReceivedTime: string=undefined;
    FromAddress: string=undefined;
    Subject: string=undefined;
    AttachmentCount: number=undefined;
    ProcessResult: string=undefined;
    Preview: string=undefined;
    Body: string=undefined;
    AttachmentsJSON: string=undefined;
    MessageId: string = undefined;
}
export class RecognizedFields {
    Field: string=undefined;
    FieldType: string=undefined;
    CurrencyAmount: number=undefined;
    Date: string=undefined;
    ItemCount: number=undefined;
    String: string=undefined;
    Confidence: number=undefined;
    JSONValue: string = undefined;
}
export class RecognizedInvoice {
    Id: number=undefined;
    Source: string=undefined;
    CreateDate: string=undefined;
    Disposition: string=undefined;
    Completed: string=undefined;
    Vendor_VendorName: string=undefined;
    Vendor2_VendorAddressRecipient: string=undefined;
    VendorId: number=undefined;
    VendorSource: string=undefined;
    VendorName: string=undefined;
    Customer_CustomerName: string=undefined;
    Customer2_ShippingAddressRecipient: string=undefined;
    Customer3_CustomerAddressRecipient: string=undefined;
    OwnerId: number=undefined;
    OwnerSource: string=undefined;
    OwnerName: string=undefined;
    InvoiceDate: string=undefined;
    DueDate: string=undefined;
    InvoiceTotal: number=undefined;
    VendorInvoiceId: string=undefined;
    ReceivedEmailId: number=undefined;
    FromAddress: string=undefined;
    ReplyToAddress: string=undefined;
    Subject: string=undefined;
    ReceivedTime: string = undefined;
}

// Parameter interfaces:

export interface CreateOwnerRecognizedNameParameters {
    VendorId: number;
    OwnerId: number;
    Name: string;
}

export interface CreateVendorRecognizedNameParameters {
    VendorId: number;
    Name: string;
}

export interface GetReceivedEmailsAndInvoiceProcessingParameters {
    ClientId: number;
    StartDate: string;
    EndDate: string;
}

export interface GetRecognizedFieldsParameters {
    RecognizedInvoiceId: number;
}

export interface GetRecognizedInvoiceParameters {
    RecognizedInvoiceJSONId: number;
}

export class FrService {

  CreateOwnerRecognizedName (params: CreateOwnerRecognizedNameParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'fr.CreateOwnerRecognizedName',
        params: params
      });
    }

  CreateVendorRecognizedName (params: CreateVendorRecognizedNameParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'fr.CreateVendorRecognizedName',
        params: params
      });
    }

  GetReceivedEmailsAndInvoiceProcessing (params: GetReceivedEmailsAndInvoiceProcessingParameters): Promise<ReceivedEmailsAndInvoiceProcessing[]> {
    return smartData({
        storedProcName: 'fr.GetReceivedEmailsAndInvoiceProcessing',
        params: params
      });
    }

  GetRecognizedFields (params: GetRecognizedFieldsParameters): Promise<RecognizedFields[]> {
    return smartData({
        storedProcName: 'fr.GetRecognizedFields',
        params: params
      });
    }

  GetRecognizedInvoice (params: GetRecognizedInvoiceParameters): Promise<RecognizedInvoice[]> {
    return smartData({
        storedProcName: 'fr.GetRecognizedInvoice',
        params: params
      });
    }
}
