import Vue from 'vue';
import { RawAxiosRequestConfig } from 'axios';

export class TickerQuoteService extends Vue {
  private _url: string = '/OHLC/';

  async getQuote(ticker: string): Promise<object> {
    const endpoint: string = this._url + 'quoteA';
    const APIConfig = {} as RawAxiosRequestConfig;
    APIConfig.params = { ticker: ticker };
    const response = await this.$http.get(endpoint, APIConfig);
    const quote: object = JSON.parse(response.data)['Time Series (1min)'];
    const price: number = quote[Object.keys(quote)[0]]['4. close'];

    return {
      symbol: ticker,
      latestPrice: price,
      latestTime: this.$dayjs(Object.keys(quote)[0]).tz('America/New_York')
    };
  }
}
