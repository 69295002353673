 <template>
     <div>
         <button v-on:click="showEditCapitalCallSchedule=true" class="btn btn-success"><i class="fa fa-plus" />Add New Schedule</button>
         <table v-if="Main[0]" id="rpt_tblCapitalCallSchedule" class="table table-striped">
             <thead>
                 <tr>
                     <th></th>
                     <th class="alignCenter">Id</th>
                     <th class="alignCenter">Date</th>
                     <th class="alignRight">Percentage</th>
                     <th class="alignCenter">Notes</th>
                 </tr>
             </thead>
             <tbody>
                 <tr v-for="schedule in Main" :key="function (){ return moment.utc(schedule.date).format('YYYYMMDD')}" style="height:29px;">

                     <td>
                         <el-button type="primary" size="mini" icon="el-icon-edit" v-show="editingId == -1" @click="editingId = schedule.Id"></el-button>
                         <el-button type="danger" size="mini" icon="el-icon-delete" v-show="editingId == -1" @click="deleteSchedule(schedule.Id)"></el-button>
                     </td>
                     <td class="alignCenter">
                         <span>{{schedule.Id}}</span>
                     </td>
                     <td class="alignCenter" style="white-space:nowrap">
                         <span>{{schedule.Date | shortDate}}</span>
                     </td>
                     <td class="alignRight" style="white-space:nowrap">
                         <span>{{schedule.Percentage | percent2}}</span>
                     </td>
                     <td style="white-space:nowrap">
                         <span>{{schedule.Notes}}</span>
                     </td>

                 </tr>
             </tbody>
             <tfoot>
                 <tr>
                     <td>Total:</td>
                     <td></td>
                     <td></td>
                     <td class="alignRight">
                         <span>{{totalPercent | percent2}}</span>
                     </td>
                     <td></td>
                 </tr>
             </tfoot>
         </table>
         <el-dialog :visible.sync="showEditCapitalCallSchedule" size="small" :show-close="true" :close-on-click-modal="false"
                    @close="showEditCapitalCallSchedule = false">
             <h3 slot="title">{{(editingId == -1) ? 'New' : 'Edit'}} Schedule</h3>
             <el-form :model="editSchedule" :rules="editScheduleRules" ref="editSchedule" label-width="120px">
                 <el-form-item label="Date:" prop="Date">
                     <el-date-picker v-model="editSchedule.Date"
                                     type="date"
                                     format="MM/dd/yyyy"
                                     placeholder="MM/DD/YYYY">
                     </el-date-picker>
                 </el-form-item>
                 <el-form-item label="Percentage:" prop="Percentage">
                     <formatted-input 
                        v-model="editSchedule.Percentage" 
                        format-type="percent" 
                        num-decimals="2" 
                        v-validate 
                        name="Percentage" 
                        clearable 
                        style="width: 200px;">
                    </formatted-input>
                 </el-form-item>
                 <el-form-item label="Notes:" prop="Notes">
                     <el-input type="textarea" v-model="editSchedule.Notes" style="word-break: break-word"></el-input>
                 </el-form-item>
             </el-form>
             <template slot="footer">
                 <el-button type="primary" @click="submitForm('editSchedule')">{{(editingId == -1) ? 'Create' : 'Update'}}</el-button>
                 <el-button @click="resetForm('editSchedule')" v-show="editingId == -1">Reset</el-button>
                 <el-button type="warning" @click="cancelEditSchedule">Cancel</el-button>
             </template>
         </el-dialog>
     </div>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_asset from './../DAL/mixinSchema_asset'
    import FormattedInput from '@/components/form/FormattedInput.vue';

    export default Vue.extend({
        name: 'CapitalCallSchedule'
        , mixins: [mixinUtility, mixinSchema_asset]
        , components: {
            FormattedInput
        }
        , props: {
            parameters: {
                type: Object
                , required: true
            }
        }
        , data: function () {
            return {
                vParameters: {}
                , Main: []
                , visible: false
                , editingId: -1
                , showEditCapitalCallSchedule: false
                , editSchedule: {
                    Id: null
                    , Date: null
                    , Percentage: null
                    , Notes: null
                    , InvestmentParentId: null
                }
                , editScheduleHolder: []
                , editScheduleRules: {
                    Percentage: [
                        { type: 'float', required: true, message: 'Percentage is Required', trigger: 'blur' }
                        //, { min: 0, max: 1, message: 'Percentage must be between 0 and 1', trigger: 'blur' }
                    ],
                    Date: [
                        { type: 'date', required: true, message: 'Please pick a date', trigger: 'change' }
                    ],
                }
            }
        }
        , created: function () {
            this.vParameters = this.parameters;
            this.fetchCapitalCallSchedule()
        }
        , computed: {
            totalPercent: function () {
                return this.arrayItemSum(this.Main, 'Percentage');
            }
        }
        , watch: {
            'parameters': function (val, oldVal) {
                this.vParameters = val;
            }
            , 'editingId': function (val, oldVal) {
                if (val > -1) {
                    this.editSchedule = this.getSelectedArrayItem(this.Main, val, 'Id');
                    this.editScheduleHolder = Vue.util.extend({}, this.editSchedule); //hold the original values in case user cancels, using a non-ref copy.
                    this.showEditCapitalCallSchedule = true;
                }
            }
        }
        , methods: {
            fetchCapitalCallSchedule: function () {
                var dsSchedule = this.asset_GetCapitalCallSchedule(
                    this.vParameters.InvestmentParentId
                );
                this.Main = dsSchedule;
            }
            , saveSchedule: function (capitalCallScheduleId) {
                var ccs = this.getSelectedArrayItem(this.Main, capitalCallScheduleId, 'Id')
                console.log(ccs);
                var so = new SmartObject('CapitalCallSchedule', capitalCallScheduleId);
                so.updateObject_lgcy(ccs);
                this.$notify.success('Capital Call Schedule Updated');
                this.editingId = -1;
                this.editScheduleHolder = null;
            }
            , deleteSchedule: function (capitalCallScheduleId) {
                var ccs = this.getSelectedArrayItem(this.Main, capitalCallScheduleId, 'Id')
                console.log(ccs);
                var so = new SmartObject('CapitalCallSchedule', capitalCallScheduleId);
                so.deleteObject_lgcy(ccs);
                this.deleteSelectedArrayItem(this.Main, capitalCallScheduleId, 'Id')
                this.$notify.success('Capital Call Schedule Deleted');
                this.editingId = -1;
            }
            , saveEditSchedule: function () {
                if (this.editingId == -1) {
                    console.log(this.editSchedule);
                    var capitalCallSchedule = new SmartObject('CapitalCallSchedule');
                    var id = capitalCallSchedule.createObject_lgcy({
                        Date: this.editSchedule.Date
                        , InvestmentParentId: this.vParameters.InvestmentParentId
                        , Notes: this.editSchedule.Notes
                        , Percentage: this.editSchedule.Percentage
                    });
                    this.editSchedule.Id = id;
                    this.Main.push(Vue.util.extend({}, this.editSchedule)); //Vue.util.extend pushes a non-ref copy so that subsequent changes to the original inputs won't affect the Main list.
                }
                else {
                    this.saveSchedule(this.editingId);
                }
                this.clearEditSchedule();
                this.showEditCapitalCallSchedule = false;
            }
            , cancelEditSchedule: function () {
                if (this.editingId > -1) {//restore original values
                    var sch = this.getSelectedArrayItem(this.Main, this.editingId, 'Id');
                    sch.Percentage = this.editScheduleHolder.Percentage;
                    sch.Date = this.editScheduleHolder.Date;
                    sch.Notes = this.editScheduleHolder.Notes;
                    this.editScheduleHolder = null;
                }
                this.clearEditSchedule();
                this.editingId = -1;
                this.showEditCapitalCallSchedule = false;
            }
            , clearEditSchedule: function () {
                this.editSchedule = {
                    Id: null
                    , Date: null
                    , Notes: null
                    , Percentage: null
                }
            }
            , submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.saveEditSchedule();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        }
    })

</script>
