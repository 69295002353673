// TS API for Schema workflow
// Auto-generated 08/04/22 16:06


declare var smartData: any;
// output classes:
export class CycleCodeWord {
    CodeWord: string = undefined;
}
export class ApprovalApprovers {
    ApprovalId: number=undefined;
    ApproverId: number=undefined;
    GUID: string=undefined;
    FullName: string=undefined;
    Approved: boolean=undefined;
    ResponseDate: string=undefined;
    IsInstantUser: boolean=undefined;
    Email: string=undefined;
    WorkflowType: string=undefined;
    Unsent: boolean = undefined;
}
export class ApprovalBatches {
    Workflow: string=undefined;
    WorkflowId: number=undefined;
    ApprovalId: number=undefined;
    Approved: boolean=undefined;
    UpdateDate: string=undefined;
    Created: string=undefined;
    Approver: string=undefined;
    PersonToConfirm: string=undefined;
    PersonToConfirmPhone1: string=undefined;
    PersonToConfirmPhoneType1: string=undefined;
    PersonToConfirmPhone2: string=undefined;
    PersonToConfirmPhoneType2: string=undefined;
    PersonToConfirmUSSMS: string=undefined;
    Items: number=undefined;
    InstantUserIsApprover: boolean = undefined;
}
export class ApprovalItems {
    ApprovalItemId: number=undefined;
    ApprovalId: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    RemoveUser: string=undefined;
    RemoveDate: string = undefined;
}
export class ApprovalItemsForApproval {
    ApprovalItemId: number=undefined;
    ApprovalId: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    EntityURL: string=undefined;
    OwnerEntityId: number=undefined;
    Client: string=undefined;
    Owner: string=undefined;
    Description: string=undefined;
    DueDate: string=undefined;
    DateToProcess: string=undefined;
    ScheduledAmount: number=undefined;
    DefaultPaymentMethod: string=undefined;
    DefaultPaymentMethodMissing: boolean=undefined;
    VendorId: number=undefined;
    VendorName: string=undefined;
    VendorInvoiceNumber: string=undefined;
    VendorUrl: string=undefined;
    ProcessingAdjustment: number=undefined;
    ReferenceEntityType: string=undefined;
    ReferenceEntityId: number=undefined;
    ReferenceEntity: string=undefined;
    ReferenceEntityURL: string=undefined;
    InvestmentName: string=undefined;
    InvestmentId: number=undefined;
    BankAccountId: number=undefined;
    BankNameNumberMasked: string=undefined;
    InvoiceGraphPath: string=undefined;
    InvoiceStatus: string=undefined;
    InvoiceApproverKeyPersonId: number=undefined;
    InvoiceApprover: string=undefined;
    NextStep: string=undefined;
    Confirmer: string=undefined;
    InvoiceApprovalId: number=undefined;
    SelectableItem: boolean = undefined;
}
export class Approvers {
    ApproverSelectionId: number=undefined;
    FullName: string=undefined;
    Email: string=undefined;
    Selectable: boolean=undefined;
    DefaultSelection: boolean=undefined;
    NotificationEntityType: string=undefined;
    NotificationEntityId: number = undefined;
}
export class ApproverSelection {
    Id: number=undefined;
    Vendor: string=undefined;
    VendorId: number=undefined;
    Workflow: string=undefined;
    WorkflowId: number=undefined;
    Client: string=undefined;
    ClientId: number=undefined;
    Owner: string=undefined;
    OwnerId: number=undefined;
    FullName: string=undefined;
    IsDefault: boolean=undefined;
    SelectionEntityType: string=undefined;
    SelectionEntityId: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    Created: string = undefined;
}
export class ApproversV2 {
    ApproverSelectionId: number=undefined;
    FullName: string=undefined;
    Email: string=undefined;
    Selectable: boolean=undefined;
    DefaultSelection: boolean=undefined;
    NotificationEntityType: string=undefined;
    NotificationEntityId: number = undefined;
}
export class PaymentConfirmationBatch {
    ApprovalId: number=undefined;
    Approved: boolean=undefined;
    UpdateDate: string=undefined;
    Created: string=undefined;
    Approver: string=undefined;
    PersonToConfirm: string=undefined;
    PersonToConfirmPhone1: string=undefined;
    PersonToConfirmPhoneType1: string=undefined;
    PersonToConfirmPhone2: string=undefined;
    PersonToConfirmPhoneType2: string=undefined;
    PersonToConfirmUSSMS: string=undefined;
    PersonToConfirmEmail: string=undefined;
    PersonToConfirmNameFirst: string=undefined;
    PersonToConfirmNameLast: string=undefined;
    PaymentConfirmationMethod: string=undefined;
    Items: number=undefined;
    ProvisionalApproval: boolean=undefined;
    ProvisionalApprovalComment: string=undefined;
    ProvisionalApprovalOn: string=undefined;
    ProvisionalApprovalBy: string=undefined;
    CallAttempts: number=undefined;
    FirstEmail: string=undefined;
    EmailCount: number=undefined;
    FirstSMS: string=undefined;
    LastSMS: string=undefined;
    LastSMSID: number=undefined;
    SMSCount: number=undefined;
    IsInitiated: boolean=undefined;
    CodeWord: string=undefined;
    InstantUserIsApprover: boolean = undefined;
}
export class Workflow {
    Id: number=undefined;
    Name: string=undefined;
    StatusTable: string=undefined;
    StatusColumn: string=undefined;
    ApprovalStatusId: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    SysStartTime: string=undefined;
    SysEndTime: string=undefined;
    RejectionStatusId: number=undefined;
    SharedFileExpirationDays: number=undefined;
    ReminderIntervalDays: number=undefined;
    ApprovalCreatesTrip: boolean=undefined;
    HasDocumentUrl: boolean=undefined;
    EmailFormat: string=undefined;
    AllowContactApprover: boolean = undefined;
}
export class SetResponse {
    Id: number=undefined;
    WorkFlowId: number=undefined;
    Approved: boolean=undefined;
    NotifyEmail: string=undefined;
    RejectionEmailHTML: string=undefined;
    Response: string = undefined;
}

// Parameter interfaces:

export interface ApproveTripInvoiceParameters {
    InvoiceId: number;
}

export interface CycleCodeWordParameters {
    ApprovalId: number;
}

export interface GetApprovalApproversParameters {
    ApprovalId: number;
}

export interface GetApprovalBatchesParameters {
    OutstandingOnly: boolean;
    OnlyInstantUser: boolean;
    WorkflowIdList: string;
    ApprovalId: number;
}

export interface GetApprovalItemsParameters {
    ApprovalId: number;
    IncludeRemoved: boolean;
}

export interface GetApprovalItemsForApprovalParameters {
    ApprovalId: number;
}

export interface GetApproversParameters {
    WorkflowId: number;
    ClientId: number;
    ApprovalId: number;
    VendorId: number;
}

export interface GetApproverSelectionParameters {
    VendorId: number;
    WorkflowId: number;
    ClientId: number;
    OwnerId: number;
    SelectionEntityType: string;
    SelectionEntityId: number;
}

export interface GetApproversV2Parameters {
    WorkflowId: number;
    ClientId: number;
    ApprovalId: number;
    VendorId: number;
}

export interface GetPaymentConfirmationBatchParameters {
    ApprovalId: number;
}

export interface GetWorkflowParameters {
    WorkkflowId: number;
}

export interface LogPaymentConfirmationActionParameters {
    ApprovalId: number;
    Action: string;
    Comment: string;
}

export interface SetResponseParameters {
    GUID: string;
    Approved: boolean;
    Response: string;
    RejectionEmailHTML: string;
    Comment: string;
}

export class WorkflowService {

  ApproveTripInvoice (params: ApproveTripInvoiceParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'workflow.ApproveTripInvoice',
        params: params
      });
    }

  CycleCodeWord (params: CycleCodeWordParameters): Promise<CycleCodeWord[]> {
    return smartData({
        storedProcName: 'workflow.CycleCodeWord',
        params: params
      });
    }

  GetApprovalApprovers (params: GetApprovalApproversParameters): Promise<ApprovalApprovers[]> {
    return smartData({
        storedProcName: 'workFlow.GetApprovalApprovers',
        params: params
      });
    }

  GetApprovalBatches (params: GetApprovalBatchesParameters): Promise<ApprovalBatches[]> {
    return smartData({
        storedProcName: 'workflow.GetApprovalBatches',
        params: params
      });
    }

  GetApprovalItems (params: GetApprovalItemsParameters): Promise<ApprovalItems[]> {
    return smartData({
        storedProcName: 'workflow.GetApprovalItems',
        params: params
      });
    }

  GetApprovalItemsForApproval (params: GetApprovalItemsForApprovalParameters): Promise<ApprovalItemsForApproval[]> {
    return smartData({
        storedProcName: 'workflow.GetApprovalItemsForApproval',
        params: params
      });
    }

  GetApprovers (params: GetApproversParameters): Promise<Approvers[]> {
    return smartData({
        storedProcName: 'WorkFlow.GetApprovers',
        params: params
      });
    }

  GetApproverSelection (params: GetApproverSelectionParameters): Promise<ApproverSelection[]> {
    return smartData({
        storedProcName: 'workflow.GetApproverSelection',
        params: params
      });
    }

  GetApproversV2 (params: GetApproversV2Parameters): Promise<ApproversV2[]> {
    return smartData({
        storedProcName: 'workflow.GetApproversV2',
        params: params
      });
    }

  GetPaymentConfirmationBatch (params: GetPaymentConfirmationBatchParameters): Promise<PaymentConfirmationBatch[]> {
    return smartData({
        storedProcName: 'workflow.GetPaymentConfirmationBatch',
        params: params
      });
    }

  GetWorkflow (params: GetWorkflowParameters): Promise<Workflow[]> {
    return smartData({
        storedProcName: 'workflow.GetWorkflow',
        params: params
      });
    }

  LogPaymentConfirmationAction (params: LogPaymentConfirmationActionParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'workflow.LogPaymentConfirmationAction',
        params: params
      });
    }

  SetResponse (params: SetResponseParameters): Promise<SetResponse[]> {
    return smartData({
        storedProcName: 'workflow.SetResponse',
        params: params
      });
    }
}
