<template>
    <div>
        <span class="h4" v-if="showTitle">Allocations:</span>
        <span class="small">click a row to edit</span>
        <el-button type="primary" @click="newAllocation" v-if="showNew" icon="plus" size="mini" class="pull-right" v-show="(securityLevel_>=80)" >New</el-button>

        <el-table :data='allocations'
                  show-summary
                  :summary-method="getSummaryAllocation"
                  @row-click='allocationClick'>
            <el-table-column label="Account"
                             class-name="small"
                             min-width="300"
                             sortable
                             prop="AccountName">
                <template slot-scope="scope">
                    <span :title="scope.row.AccountPath">{{scope.row.AccountName}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="DepartmentName"
                             v-if="showDepartment"
                             label="Department"
                             class-name="small"
                             min-width="150"
                             sortable>
            </el-table-column>
            <el-table-column prop="Amount"
                             v-if="valueType=='Amount'"
                             label="Amount"
                             align="right"
                             class-name="small"
                             :formatter="formatterCurrency"
                             min-width="120"
                             sortable>
            </el-table-column>
            <el-table-column prop="Pct"
                             v-if="valueType=='Percent'"
                             label="Percent"
                             align="right"
                             class-name="small"
                             :formatter="formatterPercent2"
                             min-width="120"
                             sortable>
            </el-table-column>
            <el-table-column label="Last Changed"
                             class-name="small"
                             min-width="180"
                             sortable>
                <template slot-scope="scope">
                    <span>{{scope.row.ModifyOrCreateDate | shortDateTime}} {{scope.row.ModifyOrCreateUserFirstLast}}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="Allocation"
                   :visible="!(Object.keys(editAllocation).length==0)"
                   :before-close="beforeCloseAllocationEdit"
                   >
            <allocation-edit v-if="Object.keys(editAllocation).length" :allocation="editAllocation" :client-id="params.ClientId" @cancel="editAllocation={}" @saved="savedAllocation" @deleted="deletedAllocation" :value-type="valueType" ></allocation-edit>
        </el-dialog>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import AllocationEdit from './../Allocation/AllocationEdit.vue'

    export default Vue.extend({
        name: 'Allocation'
        , components: {
            'allocation-edit': AllocationEdit
        }
        , mixins: [mixinUtility, mixinSchema_liq, mixinSchema_vam]
        , props: {
            params: {
                type: Object
                , required: true
                // , default: {
                //     EntityId: null
                //     , EntityType: null
                //     , ClientId: null
                // }
                // gkb 06/10/20 - fixes "error  Type of the default value for 'params' prop must be a function  vue/require-valid-default-prop"
                , default: function () {
                    return {
                        EntityId: null
                        , EntityType: null
                        , ClientId: null
                    };
                }
            }
            , showTitle: {
                type: Boolean
                , default: true
            }
            , showNew: {
                type: Boolean
                ,default: true
            }
            , valueType: {
                type: String
                , default: 'Amount'
            }
            , securityLevel: {
                type: Number
                , default: null
            }
        }
        , data() {
            return {
                editAllocationId: undefined
                , editAllocation: {}
                , departments: []
                , allocations: []
                , securityLevel_: this.securityLevel
            }
        }
        , created: function () {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), 0);
            }

            this.fetchAllocations();
        }
        , computed: {
            showDepartment: function () {
                var departments = this._filter(this.allocations, function (o) {
                    return o.DepartmentId != null;
                })
                return !(departments.length == 0);
            }
        }
        , watch: {
            'params': function (val, oldVal) {
                this.fetchAllocations();
            }
        }
        , methods: {
            fetchAllocations: async function () {
                this.allocations = await this.liq_GetAllocation({
					EntityType: this.params.EntityType
					, EntityId: this.params.EntityId
                });
            }
            , allocationClick: function (row) {
                if (this.securityLevel_>=80){
                    this.editAllocation = row;
                }
            }
            , beforeCloseAllocationEdit: function (done) {
                this.editAllocation = {};
                done();
            }
            , getSummaryAllocation(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Total';
                        return;
                    }
                    if (index === 1 || index === 3) {
                        sums[index] = '';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = smartFormat(values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value) && value != 0) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0), ',.2');
                    } else {
                        sums[index] = '';
                    }
                });

                return sums;
            }
            , deletedAllocation: function (deletedAllocation) {
                var index = this.allocations.indexOf(deletedAllocation);
                this.allocations.splice(index, 1);
                this.editAllocation = {}; //hides modal
            }
            , newAllocation: function () {
                var allocation = this.liq_GetAllocation_New();
                //allocation.Id = -1;
                allocation.EntityId = this.params.EntityId;
                allocation.EntityType = this.params.EntityType;
                this.editAllocation = allocation;
            }
            , savedAllocation: function (editAllocation) {
                var index = this.allocations.indexOf(editAllocation);
                if (index == -1) {//new, so add it to the array
                    this.allocations.push(editAllocation);
                }
                this.editAllocation = {};
            }
        }
    })
</script>
<style>
    .el-form-item__error {
        position: relative;
    }

</style>