
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as UtilService from './../services/DAL/utilService';
// import * as alasql from 'alasql';
import alasql from 'alasql'; // gkb 06/10/20 - fixes error on alasql.promise (below): "Property 'promise' does not exist on type 'typeof import("C:/Users/Greg Bishop/source/repos/VAM/src/VAM.Web2/node_modules/alasql/dist/alasql")"
import * as XLSX from 'xlsx';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;

declare var SmartObject: any;
interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}
class logProperties  {
    database: string = null;
    schema: string = null; 
    caller: string = null; 
    event: string = null; 
}
class fieldOptions{
    name: string = null; // Use square brackets for [spaced names]
    field: string = null; // database field to use if different than column header
    parseFunction: object = null; // function to parse column data prior to display/importation
}
@Component
export default class ExcelImport extends Vue {
    //#region Private declarations for Services
    private _utilService: UtilService.UtilService;

    //#endregion Private declarations for Services

    //#region Props

    @Prop({
        type: Array
    })
    excludeColumns: string[];
    @Prop({
        type: Array
        , default: () => ([])
    })
    requiredColumns: string[];
    @Prop({
        type: Array
    })
    fieldOptions: fieldOptions[];
    @Prop({
        type: Object
    })
    logProperties: logProperties;
    @Prop({
        type: Object
    })
    additionalColumns: {};
    @Prop({
        type: Number,
    })
    logId: number;
    @Prop({
        type: String,
        required: true
    })
    smartObjectName: string;
    @Prop({
        type: String
    })
    whereClause: string;
    @Prop({
        default: false
    })
    useTSV: boolean;
    @Prop({
        default: 'ImportLogId'
    })
    logIdColumn: string;
    @Prop({
        type: Number,
        default: 0
    })
    startOnRow: number;
    
    //#endregion Props

    //#region Data
    logId_: number = null;
    isDragging: boolean = false
    isInitial: boolean = true
    isSaving: boolean = false
    fileData: Array<Object> = [];
    showImportButton: boolean = false;
    fileName: string = '';
    importButtonLabel: string = 'Import Data';
    importButtonEnabled: boolean = true;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._utilService = new UtilService.UtilService();
    }
    
    async mounted(){
    }
    //#endregion Lifecycle

    //#region Watches

    //#endregion Watches

    //#region Methods
    filesChange(event: HTMLInputEvent) {
        const files = event.target.files;
        const fileCount = files.length;
        if (!files.length) {
            this.$notify.error('No file found to process');
            return;
        }
        else if (files.length>1){
            this.$notify.error('Only one file can be processed');
            return;
        }
        this.isInitial = false;
        this.isSaving = true;
        this.processFile(event);
    }
    async readFileAsync(file) { // helper to async-ify the reader
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
            resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsText(file);
        })
    }
    async trimFile(event: HTMLInputEvent): Promise<string> {
        const file = event.target.files[0];
        const readResult = await this.readFileAsync(file);
        console.log(readResult);
        const resultString: string = readResult.toString();
        const lines = resultString.split(/\r\n|\n/);
        console.log(lines);
        lines.splice(0, this.startOnRow);
        console.log(lines);
        const result = lines.join('\r\n');
        return result;
    }
    async processFile(file: HTMLInputEvent) {
        if (!!file.target && !!file.target.files && !!file.target.files.length && !!file.target.files[0].name){
            this.fileName = file.target.files[0].name;
        }
        let requiredWhere: string = '';
        if (!!this.requiredColumns && this.requiredColumns.length){
            for (var i = 0; i < this.requiredColumns.length; i++) {
                requiredWhere = requiredWhere + `[${this.requiredColumns[i]}] is not null and [${this.requiredColumns[i]}] != ''${(i < this.requiredColumns.length-1) ? ' and ' : ''}`;
            }
        }

        let where: string = '';
        if (this.whereClause) where = this.whereClause;
        if (!!where && !!requiredWhere) where = where + ' and ' + requiredWhere;
        else if (!where && !!requiredWhere) where = 'where ' + requiredWhere;
        let type: string;
        if (this.useTSV) {
            type = 'TSV';
        }
        else if (this.fileName.indexOf('.csv') > 0){
            type = 'CSV';
        }
        else {
            type = 'FILE'
        }
        let data: string | HTMLInputEvent;
        if (this.startOnRow > 0 && type == 'CSV'){
            data = await this.trimFile(file);
        }
        else data = file;
        const alaQuery = `SELECT * FROM ${type}(?,{headers:true}) ${where || ''}`;
        this.fileData = await alasql.promise(alaQuery, [data]);
        if (this.fileData.length == 0 ){
            this.$alert(`No Records were read from the file.  Please check the file.${(this.requiredColumns.length) ? ' Note that the following columns are required: ': ''}${(this.requiredColumns.length) ? this.requiredColumns.toString() + '. ' : ''} Make sure the Column Headers are in the first row.`
            , {type: 'error'});
            this.isInitial = true;
        }
        else {
            this.$notify.success(`${this.fileData.length} records read`);
        }
        this.showImportButton = !!this.fileData.length;
        this.isSaving = false;
        const el = this;
        (this.excludeColumns || []).forEach(function (column){
            el.fileData.map(function(item) { 
                delete item[column]; 
                return item; 
            });
        });
        (this.fieldOptions || []).forEach(function (option){
            el.fileData.map(function(item) { 
                if (item[option.name]){
                    if (!!option.parseFunction && typeof(option.parseFunction) == 'function'){
                        item[option.name] = option.parseFunction(item[option.name]);
                    }
                    if (option.field){
                        delete Object.assign(item, {[option.field]: item[option.name] })[option.name]; // rename
                    }
                }
                return item; 
            });
        });
        
        // remove spaces from names.  Doing this after FieldOptions so that any renaming there can depend on names as presented in file (with spaces)
        el.fileData.map(function(item) { 
            Object.keys(item).forEach(element => {
                    if (!!element && element !== element.replace(/\s/g, '')){
                        delete Object.assign(item, {[element.replace(/\s/g, '')]: item[element] })[element]; // rename
                    }
                }
            )
            return item; 
        });
        if (!!this.additionalColumns && Object.keys(this.additionalColumns).length){
            el.fileData.map(function(item) { 
                item = Object.assign(item, item, el.additionalColumns);
                return item
            });
        }
    }
    async importData() {
        const importLogId: number = null;
        const loading = this.$loading({
          lock: true,
          text: 'Importing',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.6)'
        });
        this.importButtonLabel = 'Processing...';
        this.importButtonEnabled = false;
        let successes = 0;
        let errors = 0;

        if (this.logId) this.logId_ = this.logId;
        else if (!!this.logProperties && Object.keys(this.logProperties).length){
            let logEvents = [] as UtilService.LogEventReturnRow[];
            const parameters = {} as UtilService.LogEventReturnRowParameters;
            parameters.Database = this.logProperties.database;
            parameters.Schema = this.logProperties.schema;
            parameters.Caller = this.logProperties.caller;
            parameters.Event = this.logProperties.event;
            logEvents = await this._utilService.LogEventReturnRow(parameters);
            if (!!logEvents && logEvents.length == 1){
                this.logId_ = logEvents[0].LogId;
            }
        }
        if (!!this.logId_ && !!this.fileName){
            const fileNameParams = {} as UtilService.LogEventKeyParameters;
            fileNameParams.LogId = this.logId_;
            fileNameParams.KeyName = 'FileName';
            fileNameParams.VarcharValue = this.fileName;
            await this._utilService.LogEventKey(fileNameParams);
        }
        const el = this;
        await Promise.all(this.fileData.map(async function(item) { 
            if (el.logId_){
                el.$set(item, el.logIdColumn, el.logId_);
            }
            try {
                const insertedId = await new SmartObject(el.smartObjectName).createObject(item);
                if (insertedId){
                    el.$set(item, 'Id', insertedId);
                    successes++;
                }
            }
            catch(err){
                el.$set(item, 'Id', 'X');
                el.$set(item, 'error', err.message);
                errors++;
            }
            return item; 
        }));
        this.$emit('imported', 
            {
                importLogId: el.logId_,
                successes: successes,
                errors: errors
            });
        loading.close();
        this.importButtonLabel = 'Import Data';
        this.importButtonEnabled = true;
        this.showImportButton = false;
    }
    //#endregion Methods
}
