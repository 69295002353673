
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop, Model } from "vue-property-decorator";
import * as LiabService from "../../services/DAL/liabService";

@Component({
    inheritAttrs: false
})
export default class NoteSelector extends Vue {
    //#region Private declarations for Services
    private _liqService: LiabService.LiabService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() readonly value: number;
    @Prop({type: Number}) readonly ownerId: number;
    @Prop( {type: Array }) readonly ownerIdList: Array<number>;
    @Prop() readonly noteTypeId: string | number | Array<number>;
    //#endregion Props

    //#region Data
    notes = [] as LiabService.Notes[];
    selectedValue: number = null;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiabService.LiabService();
    }

    async mounted() {
        this.selectedValue = this.value;
        this.fetchNotes();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch("value")
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
    }

    @Watch("ownerId")
    onChange_ownerId(val: number, oldVal: number) {
        this.fetchNotes();
    }
    @Watch("ownerIdList")
    onChange_ownerIdList(val: number, oldVal: number) {
        this.fetchNotes();
    }
    //#endregion Watches

    //#region Methods
    async fetchNotes() {
        this.notes = [];
        const params = {} as LiabService.GetNotesParameters;
        params.OwnerId = this.ownerId;
        if (Array.isArray(this.ownerIdList)){
            params.OwnerIdList = this.ownerIdList.join();
        }
        //params.NoteType = this.noteTypeId;
        if (typeof this.noteTypeId == "string" && Array.isArray(JSON.parse(this.noteTypeId))){
            params.NoteTypeList = JSON.parse(this.noteTypeId).join();
        }
        else if (typeof this.noteTypeId == "string" ){
            params.NoteType = Number(this.noteTypeId);
        }
        else if (typeof this.noteTypeId == "number"){
            params.NoteType = this.noteTypeId;
        }
        else if (this.noteTypeId) { //array
            params.NoteTypeList = this.noteTypeId.join();                
        }            

        this.notes = await this._liqService.GetNotes(params);
    }
    //#endregion Methods
}
