
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import * as TransactionsService from '@/services/DAL/transactionsService';
import ClientSelector from '@/components/form/ClientSelector.vue';
import Common from "@/utils/common";
import ElementUI from 'element-ui';
import SellMarketableSecurity from '@/views/InvestmentTransaction/MarketableSecuritiesSale/SellMarketableSecurity.vue'
import SellMarketableSecurities from '@/views/InvestmentTransaction/MarketableSecuritiesSale/SellMarketableSecurities.vue'
                    

declare interface SecuritiesTabTypes extends TransactionsService.MarketableSecuritySaleSecurities {
  TabType: string
}

@Component({
      components: {
        ClientSelector,
        SellMarketableSecurity,
        SellMarketableSecurities
      }
})
export default class MarketableSecuritiesTabsView extends Vue {
    @Ref() readonly refTabs!: ElementUI.Tabs;

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({type: Number, required: true}) readonly marketableSecuritySaleId!: number;

    //#endregion Props

    //#region Data
    loading = false;
    marketableSecuritySaleSecurities = [] as TransactionsService.MarketableSecuritySaleSecurities[];
    marketableSecuritySale = {} as TransactionsService.MarketableSecuritySale;
    selectedTabName = 'Info'; // will be the ticker for securities tabs
    //#endregion Data


     //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.common = new Common();
    }

    async mounted() {
        await this.fetchMarketableSecuritySaleSecurities();
        this.fetchMarketableSecuritySale();
    }

    //#endregion Lifecycle

    //#region Watches
    @Watch('marketableSecuritySaleId')
    onChange_marketableSecuritySaleId(val: number, oldVal: number) {
        if (!(oldVal == undefined && val == null)){
            this.fetchMarketableSecuritySaleSecurities();
        }
    }
    //#endregion Watches

    //#region Computed
    get tabsArray(): SecuritiesTabTypes[]{
      const securitiesTabs: SecuritiesTabTypes[] =  this.marketableSecuritySaleSecurities.map(o => {
        (o as SecuritiesTabTypes).TabType = 'Security';
        return o as SecuritiesTabTypes;
      });
      const infoTab: SecuritiesTabTypes = {} as SecuritiesTabTypes;
      infoTab.TabType = 'Info';
      infoTab.Name = 'Info';
      infoTab.Ticker = 'Info';
      infoTab.MarketableSecuritySaleId = this.marketableSecuritySaleId;

      const selectionTab: SecuritiesTabTypes = {} as SecuritiesTabTypes;
      selectionTab.TabType = 'Selection';
      selectionTab.Name = 'Selection';
      selectionTab.Ticker = 'Selection';
      selectionTab.MarketableSecuritySaleId = this.marketableSecuritySaleId;

      securitiesTabs.unshift(selectionTab);
      securitiesTabs.unshift(infoTab);
      return securitiesTabs;
    }
    get statusStyle(){
      if (this.marketableSecuritySale && this.marketableSecuritySale.Status){
        if (this.marketableSecuritySale.Status == 'Not Submitted') return {'background-color': 'yellow'};
        if (this.marketableSecuritySale.Status.includes('Awaiting Approval')) return {'background-color': 'orange'};
      }
    }
    //#endregion Computed

    //#region Methods
    async fetchMarketableSecuritySale(){
      if (this.marketableSecuritySaleId) {
          this.loading = true;
          const params = {} as  TransactionsService.GetMarketableSecuritySaleParameters;
          params.MarketableSecuritySaleId = this.marketableSecuritySaleId;
          const mss = await this._transactionsService.GetMarketableSecuritySale(params);
          if (mss && mss.length == 1) {
            this.marketableSecuritySale = mss[0];
          }
          else this.marketableSecuritySale = {} as TransactionsService.MarketableSecuritySale;
          this.loading = false;
      }
    }
    async fetchMarketableSecuritySaleSecurities(){
      if (this.marketableSecuritySaleId) {
          this.loading = true;
          const params = {} as  TransactionsService.GetMarketableSecuritySaleSecuritiesParameters;
          params.MarketableSecuritySaleId = this.marketableSecuritySaleId;
          this.marketableSecuritySaleSecurities = await this._transactionsService.GetMarketableSecuritySaleSecurities(params);
          this.loading = false;
      }
    }
    async addSaleSecurity(saleSecurity: TransactionsService.MarketableSecuritySaleSecurities){
      const newTab = {} as SecuritiesTabTypes;
      Object.assign(newTab, saleSecurity);
      newTab.TabType = 'Security';
      //will need to adjust this to insert not at the end but before the Email (and may other non-Securities) tab(s).
      this.tabsArray.push(newTab);
      await this.$nextTick();
      this.switchTab(saleSecurity);
    }
    switchTab(saleSecurity: TransactionsService.MarketableSecuritySaleSecurities){
      this.selectedTabName = saleSecurity.Ticker;
    }
    //#endregion Methods

}
