
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as UtilService from './../../services/DAL/utilService';
import formatters from '../../utils/formatters';
import moment from 'moment';

declare var $: any;
declare var SmartObject: any;
declare function tryParseInt(input: number, deflt: number): number;
declare function getStoredSecurityLevel(Id: number): number;
declare var process: any;

@Component
export default class EmailView extends Vue {
    //#region Private declarations for Services
    private _utilService: UtilService.UtilService;
    private formatters: formatters = new formatters();
    //#endregion Private declarations for Services

    //#region Props
    @Prop() email: UtilService.Emails;
    @Prop() viewType: string;
    //#endregion Props

    //#region Data
    emailAttachments = [];
    isLoading_emailMessages = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._utilService = new UtilService.UtilService();

        // this.fetchEmailAttachments();
    }
    mounted() {}
    //#endregion Lifecycle

    //#region Watches
    //#endregion Watches

    //#region Methods
    async fetchEmailAttachments() {
        this.emailAttachments = [];
        const getEmailAttachmentsParameters = {} as UtilService.GetEmailAttachmentsParameters;
        getEmailAttachmentsParameters.EmailId = this.email.Id;
        this.emailAttachments = await this._utilService.GetEmailAttachments(
            getEmailAttachmentsParameters
        );
    }

    openAttachment(emailAttachment) {}
    //#endregion Methods
}
