
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as UtilService from './../../services/DAL/utilService';
import moment from 'moment';
import formatters from '../../utils/formatters';
import EmailView from '@/views/Email/EmailView.vue';
import ElementUI from 'element-ui';

@Component({
    components: {
        EmailView
    }
})
export default class EmailList extends Vue {
    $refs: {
        tblEmailList: ElementUI.Table
    }
    //#region Data
    private _utilService: UtilService.UtilService;
    private formatters: formatters = new formatters();

    emails = [];
    selectedEmail: UtilService.Emails;
    showDataPane = false;
    isLoading = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._utilService = new UtilService.UtilService();
        this.formatters = new formatters();
    }

    async mounted() {
        this.fetchEmails();
    }
    //#endregion Lifecycle

    //#region Methods
    async selectEmail(email: UtilService.Emails) {
        if (email) {
            this.showDataPane = false;
            await this.$nextTick();
            this.showDataPane = true;
            this.selectedEmail = email;
        }
    }

    closeEmail() {
        this.showDataPane = false;
        this.selectedEmail = null;
        this.$refs.tblEmailList.setCurrentRow(null);
    }

    async fetchEmails() {
        this.isLoading = true;
        const getEmailsParameters = {} as UtilService.GetEmailsParameters;
        this.emails = await this._utilService.GetEmails(
            getEmailsParameters
        );
        this.isLoading = false;
    }

    fromNow(dtTm: moment.MomentInput): string {
        return moment(dtTm).fromNow();
    }
    //#endregion Methods
}
