<template>
    <div id="divSchedule" v-loading="loading" >
        <div id="vueScheduleHeader">
            Schedule Type:
            <el-radio-group v-model="pAgreement.ScheduleType" v-if="(securityLevel_>=80 && pAgreement && (pAgreement.Configuration == null || !($common.objectFromColumn(pAgreement.Configuration).ScheduleType)))" size="small">
                <el-radio-button label="Recurring" disabled v-if="pAgreement.ScheduleType == 'Recurring'"></el-radio-button>
                <el-radio-button label="Specific">Plan (Specific)</el-radio-button>
                <el-radio-button label="Budget" v-if="!(showPledgeFields)"></el-radio-button>
            </el-radio-group>
            <span v-else>{{pAgreement.ScheduleType}}</span>
            <specific-schedule v-show="pAgreement.ScheduleType=='Specific'" entity-type="Agreement" :entity-id="pAgreement.AgreementId" :client-id="pAgreement.ClientId" :security-level="securityLevel_" />
            <budget-schedule v-if="pAgreement.ScheduleType=='Budget'" :agreement-id="pAgreement.AgreementId" :client-id="pAgreement.ClientId" :security-level="securityLevel_" />
        </div>
        <el-card v-show="pAgreement.ScheduleType=='Recurring'">
            <div slot="header" class="clearfix" style="background-color: #F5F5F5;">
                <span>Edit Schedule:</span>
            </div>
            <schedule-edit 
                v-if="showScheduleEdit && !!schedule && Object.keys(schedule).length>0" 
                :schedule="schedule" 
                @saved="scheduleSaved"
                @allocation-updated="allocationUpdated"
                ></schedule-edit>
        <hr />
        <allocation :params="allocationParameters" ref="allocationAgreementRecurring"></allocation>
        <hr />
        <span class="h4">Scheduled:</span>
        <div class="row justify-content-end">
            <div class="col-lg-6 text-right">
                <div class="input-group" style="margin-bottom: 5px;">
                    <el-input v-model="filterScheduleItem" placeholder="filter" size="mini" clearable suffix-icon="el-icon-search" style="max-width: 250px;" />
                    <el-button type="primary" plain size="mini" icon="el-icon-refresh" @click="fetchScheduleItem()"></el-button>
                </div>
            </div>
        </div>
        <el-table
            ref="refScheduleItem"
            size="mini"
            :data="scheduleItemFiltered"
            row-key="Id"
            empty-text="No Data"
            v-loading.body="loading"
        >
            <el-table-column label="Date"
                        min-width="50"
                        align="center"
                        prop="ScheduleDate"
                        :formatter="formatterShortDate">
            </el-table-column>

            <el-table-column label="Amount"
                        min-width="100"
                        align="left"
                        prop="Amount"
                        :formatter="formatterCurrency">
            </el-table-column>

            <el-table-column label="Invoices"
                        min-width="55"
                        align="right"
                        prop="Invoices"
                        >
                <template slot-scope="scope">
                    <el-button type="text" @click="openNewInvoice(scope)" title="Click to create a new invoice for this agreement schedule" >+</el-button>
                    {{scope.row.Invoices}}
                </template>
            </el-table-column>

            <el-table-column label="Matches"
                        min-width="55"
                        align="right"
                        prop="Matches"
                        >
            </el-table-column>

            <el-table-column label="Matched Date"
                        min-width="100"
                        align="center"
                        prop="MatchedDate"
                        :formatter="formatterShortDateTime">
            </el-table-column>

            <el-table-column label="Modified"
                        min-width="140"
                        align="left"
                        prop="Modified"
                        >
            </el-table-column>


        </el-table>
        </el-card>
        <el-dialog :visible.sync="showNewInvoice" title="New Invoice (No Document)" width="50vw" top="1vh" append-to-body v-draggable @close="newInvoiceClosed">
            <new-invoice-view
                :invoice="newInvoiceSeed"
                v-if="showNewInvoice"
                view-type="dialog"
                @close-dialog="newInvoiceClosed"
                >
            </new-invoice-view>
        </el-dialog>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import mixinUtility from './../VueCommon/Utility.js'
    import AgreementEdit from './AgreementEdit.vue'
    import BudgetSchedule from './BudgetSchedule.vue'
    import Allocation from './../Allocation/Allocation.vue';
    import SpecificSchedule from './SpecificSchedule.vue'
    import ScheduleEdit from '@/views/Schedule/ScheduleEdit.vue'
    import NewInvoiceView from '@/js/AP/NewInvoiceView.vue';
    
    export default Vue.extend({
        name: 'ScheduleAndAllocation'
        , mixins: [mixinSchema_liq, mixinUtility]
        , props: {
            agreement: {
                type: Object
            }
            , securityLevel: {
                type: Number
                , default: null
            }

        }
        , model: {
            prop: 'agreement',
            event: 'update:pAgreement'
        }
        , components: {
            'budget-schedule': BudgetSchedule
            , 'specific-schedule': SpecificSchedule
            , 'agreement-edit': AgreementEdit
            , 'schedule-edit': ScheduleEdit
            , 'allocation': Allocation
            , NewInvoiceView
        }
        , data: function() {
            return {
                schedule: {}
                , securityLevel_: this.securityLevel
                , showScheduleEdit: true
                , loading: false
                , filterScheduleItem: ''
                , scheduleItem: []
                , showNewInvoice: false
                , newInvoiceSeed: {}
                , pAgreement: this.agreement
            }
        }
        , created: function () {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), 0);
            }

            if (this.pAgreement && this.pAgreement.ScheduleType == 'Recurring') {
                this.fetchSchedule();
                this.fetchScheduleItem();
            }
        }
        , mounted: function () {
        }
        , computed: {
            showPledgeFields(){
                const config = this.$common.objectFromColumn(this.pAgreement.Configuration);
                if (config && Object.keys(config).length){
                    return config.showPledgeFields;
                }
                else return false; // default false if no config, so that Budget button will be shown

            }
            , allocationParameters: function () {
                return {
                    EntityType: 'Agreement'
                    , EntityId: this.pAgreement.AgreementId
                    , ClientId: this.pAgreement.ClientId
                };
            }
            , scheduleItemFiltered: function() {
                const filter_ = (this.filterScheduleItem || '').toLowerCase();
                return this.scheduleItem.filter(entity => {
                    return (
                        (entity.Id || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                        (entity.EntityId || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                        (entity.EntityType || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                        (entity.ScheduleNumber || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                        (entity.Frequency || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                        (entity.Amount || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                        (entity.CreateUser || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                        (entity.ModifyUser || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                        (entity.Modified || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                        (entity.Matches || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                        (entity.MatchStatus || '').toString().toLowerCase().indexOf(filter_) > -1
                    );
                });
            }
        }
        , watch: {
            'pAgreement.ScheduleType': function (val, oldVal) {
                if (this.pAgreement.AgreementId > 0){ // don't do anything when it changes for a new agreement
                    console.log(val);
                    this.updateScheduleType(val);
                }
            }
        }
        , methods: {
            updateScheduleType: function (val) {
                var vm = this; //"this" is unknown inside jquery callback
                var onSuccessfulAllocationAdjustment = function (data) {
                    if (data && data[0] && data[0].RowsUpdated > 0) {
                        var message = data[0].RowsUpdated + ' Allocations updated for Schedule Type.';
                        vm.$notify.success(message);
                    }
                    vm.$emit('update:pAgreement', vm.pAgreement);
                }
                var agreement = new SmartObject('Agreement', this.pAgreement.AgreementId);
                agreement.updateAttribute_lgcy('ScheduleType', val
                    , {
                        onSuccess: function () {
                            vm.$notify.success('Schedule Type updated.');
                            if (val == 'Recurring') {
                                vm.fetchSchedule();
                            }
                            vm.liq_AdjustAllocationForScheduleTypeChange(
                                {
                                    AgreementId: vm.pAgreement.AgreementId
                                }
                                , onSuccessfulAllocationAdjustment
                            );
                        }
                    });
            }
            , fetchSchedule: async function () {
                this.loading = true;
                this.schedule = await this.liq_GetSchedules_Object({
                    EntityType: 'Agreement'
                    , EntityId: this.pAgreement.AgreementId
                });
                this.loading = false;

                this.showScheduleEdit = false;
                await this.$nextTick();
                if (this.schedule == null || Object.keys(this.schedule).length == 0) {
                    this.schedule = this.liq_GetSchedules_New();
                    this.schedule.EntityId = this.pAgreement.AgreementId;
                    this.schedule.EntityType = 'Agreement';
                    var schedule = new SmartObject('Schedule');
                    this.loading = true;
                    var scheduleId = await schedule.createObject(this.schedule);
                    if (scheduleId) {
                        this.schedule.Id = scheduleId;
                        this.$notify.success('New Schedule Record Created');
                    }
                    this.loading = false;
                }
                this.showScheduleEdit = true;
            }
            , async fetchScheduleItem(){
                this.loading = true;
                this.scheduleItem = await this.liq_GetScheduleItem({
                    EntityType: 'Agreement'
                    , EntityId: this.pAgreement.AgreementId
                });
                this.loading = false;
            }
            , async scheduleSaved(){
                await this.fetchScheduleItem();
            }
            , async allocationUpdated(){
                await this.$refs.allocationAgreementRecurring.fetchAllocations();
            }
            , openNewInvoice(scope){
                this.newInvoiceSeed = {
                    ClientId: this.pAgreement.ClientId
                    , OwnerId: this.pAgreement.OwnerId
                    , VendorId: this.pAgreement.VendorId
                    , AgreementId: this.pAgreement.AgreementId
                    , ReferenceEntityId: scope.row.Id
                    , ScheduleItemId: scope.row.Id
                    , ReferenceEntityType: 'ScheduleItem'
                    , Amount: scope.row.Amount
                    , DueDate: scope.row.ScheduleDate
                }
                this.showNewInvoice = true;
            }            
            , newInvoiceClosed(){
                this.fetchScheduleItem(); 
                this.showNewInvoice = false; 
                this.newInvoiceSeed = {};
            }
        }
    })
</script>
<style>
</style>
