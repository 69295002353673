 <template>
   <div >
      <el-container id="InvoiceEditContainer" style="height: 100%; width:100%">
        <el-main id="invoiceEditMain" >
          <div v-if="Main && Main.AutoIngestMessageId">
            <el-tooltip :content="`Subject: ${Main.AutoIngestSubject}`" placement="top-start">
              <span style="color: red">Auto Ingested from {{ Main.AutoIngestFrom }}
                <i style="color: blue" class="el-icon-message" @click="showEmail(Main.AutoIngestMessageId)" ></i>
              </span>
            </el-tooltip>
          </div>
          <div v-if="Main && Main.AutoIngestFileName">
            <el-tooltip :content="`Original File Name: ${Main.AutoIngestFileName}`" placement="top-start">
              <span style="color: red">Auto Ingested from file by {{ Main.AutoIngestFileCreateUser }}
              </span>
            </el-tooltip>
          </div>
          <el-form :disabled="securityLevel_ < $namedKey.SecurityLevel.BasicUser || formDisabled" :model="Main" :rules="invoiceRules" ref="frmInvoiceEdit" label-width="25%" size="mini" v-loading="loading" >
            <el-form-item v-if="securityLevel_ >=$namedKey.SecurityLevel.BasicUser">
              <div class="pull-right">
                <el-button type="success" title="SAVE" @click="saveAPInvoice" :disabled="!(securityLevel_>=80 && formValid && isFormDirty)" size="mini">
                  <i class="fa fa-check" aria-hidden="true"></i>SAVE
                </el-button>
              </div>
            </el-form-item>
            <el-form-item label="Vendor Invoice #:" prop="VendorInvoiceNumber">
              <el-input placeholder="Invoice #"
                        ref="vendorInvoiceNumberInput"
                        v-model="Main.VendorInvoiceNumber"
                        size="mini"
                        v-validate name="VendorInvoiceNumber"
                        v-on:blur="fetchDuplicateInvoiceNumbers"
                        style="width: 100%; max-width:150px"
                        :disabled="(securityLevel_<80)"></el-input>
              <div class="bg-danger row duplicateInvoice" v-show="(!!duplicateInvoiceNumbers && duplicateInvoiceNumbers.length > 0)" style="border-color:red; border-style:solid;">
                <h3>Duplicate Invoice Number</h3>
                <table id="rpt_tblDuplicateInvoice" class="table table-striped">
                  <thead sm-headertemplate>
                    <tr>
                      <th class="alignCenter">Date</th>
                      <th class="alignCenter">Due</th>
                      <th class="alignCenter">Amount</th>
                      <th class="alignCenter">Description</th>
                      <th class="alignCenter">Status</th>
                      <th class="alignCenter">Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="duplicateInvoiceNumber in duplicateInvoiceNumbers" :key="duplicateInvoiceNumber.InvoiceId">
                      <td>      <span>{{duplicateInvoiceNumber.Date | shortDate}}</span>  </td>
                      <td>      <span>{{duplicateInvoiceNumber.DueDate | shortDate}}</span>  </td>
                      <td>      <span>{{duplicateInvoiceNumber.Amount | currency}}</span>  </td>
                      <td>      <span>{{duplicateInvoiceNumber.Notes}}</span>  </td>
                      <td>      <span>{{duplicateInvoiceNumber.StatusText}}</span>  </td>
                      <td>      <span>{{duplicateInvoiceNumber.CreateDateLabel}}</span>  </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-form-item>
            <el-form-item label="Amount:" prop="Amount">
              <template v-if="Main.ApprovalId && Main.ApprovalSetDate != null">
              <el-badge :hidden="!Main.FXUpdates" is-dot title="Amount has been updated for FX.  See Change Log." >
                <span :title="`Amount cannot be changed after invoice is ${Main.Approved ? 'Approved' : 'Rejected' }.`">{{Main.Amount | currency}}</span>
              </el-badge>
                &nbsp;
              <i style="color: blue" class="el-icon-money" v-if="selectedVendor && selectedVendor.AllowInvoiceAmountUpdateForFX && securityLevel_ > 80" @click="updateAmountForFX" title="Update amount for foreign exchange"></i>
              </template>
              <formatted-input v-else v-model="Main.Amount" format-type="currency" num-decimals="2" size="mini" name="Amount" :disabled="(securityLevel_<80)" v-validate placeholder="Invoice Amount" style="width: 100%; max-width:150px; word-break: break-word"></formatted-input>
              <div v-if="showAmountOptions">
                  <div v-for="field in recognizedAmounts" :key="field.Field">
                    <el-popover placement="right" trigger="hover" content="click the value to set it as the Invoice Amount.  Disabled if the same value is already set.">
                      <span slot="reference" >{{ field.FieldDisplay }}: <el-button size="mini" type="text" :disabled="(Main && Main.Amount == field.CurrencyAmount) || securityLevel_ < $namedKey.SecurityLevel.Admin" @click="setRecognizedAmount(field.CurrencyAmount)">{{ $accounting.formatMoney(field.CurrencyAmount) }}</el-button></span>
                    </el-popover>
                  </div>
              </div>
            </el-form-item>

            <el-form-item label="Description:" prop="Notes">
              <el-input type="textarea" v-model="Main.Notes" size="mini"
                        v-validate name="Description"
                        :rows="descriptionLineCount"
                        :disabled="(securityLevel_<80)" style="width: 100%; "></el-input>
            </el-form-item>

            <el-form-item label="Client:" prop="ClientId">
              <el-select filterable clearable default-first-option size="mini"
                          v-bind:disabled="(clients.length ==0 ||securityLevel_<80 || !!Main.LockAgreementSelection)"
                          style="width: 100%; max-width:150px"
                          v-validate name="Client"
                          v-model="Main.ClientId">
                <el-option v-for="client in clients"
                            :key="client.Id"
                            :label="client.Name"
                            :value="client.Id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Owner:" prop="OwnerId">
              <el-select filterable clearable default-first-option size="mini"
                          style="width: 100%; max-width:300px"
                          v-validate name="Owner"
                          v-bind:disabled="(owners.length == 0 || securityLevel_<80 || !!Main.LockAgreementSelection)"
                          v-model="Main.OwnerId">
                <el-option v-for="owner in owners"
                            :key="owner.Id"
                            :label="owner.Name"
                            :value="owner.Id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Vendor:" prop="VendorId">
              <el-select filterable clearable default-first-option size="mini"
                          v-bind:disabled="(vendors.length == 0 || securityLevel_<80 || !!Main.LockAgreementSelection)"
                          style="width: 100%; max-width:300px"
                          name="Vendor" v-validate
                          v-model="Main.VendorId">
                <el-option v-for="vendor in vendors"
                            :key="vendor.Id"
                            :label="vendor.Name"
                            :value="vendor.Id">
                </el-option>
              </el-select>
              <el-button type="primary" plain size="mini" icon="el-icon-plus" @click="showVendorEdit = true" v-if="!(securityLevel_<80)" :disabled="!Main.ClientId" :title="!Main.ClientId ? 'Client must be selected before a vendor can be created' : 'Add vendor'"></el-button>
              <br >
              <small v-if="!!selectedVendor && !!selectedVendor.DefaultPaymentMethod">Payment Method: {{selectedVendor.DefaultPaymentMethod}}</small>
              <small style="color:silver;" v-else-if="!!selectedVendor && Main.VendorId" >(Payment Method not set)</small>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <router-link v-if="!!selectedVendor && Main.VendorId && securityLevel_ >= $namedKey.SecurityLevel.BasicUser" :to="{ name: 'Vendor Edit', params: { vendorId: Main.VendorId }}" target="_blank">
                <small >Manage Vendor {{Main.VendorId}}</small> 
                <i class="fa fa-external-link"></i>
              </router-link>
            </el-form-item>

            <el-form-item label="Invoice Date:" prop="Date">
              <el-date-picker v-model="Main.Date"
                              type="date"
                              v-validate name="InvoiceDate"
                              style="width: 100%; max-width:150px"
                              :disabled="(securityLevel_<80)"
                              format="MM/dd/yyyy"
                              size="mini"
                              placeholder="MM/DD/YYYY">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="Due Date:" prop="DueDate">
              <el-date-picker v-model="Main.DueDate"
                              type="date"
                              v-validate name="DueDate"
                              style="width: 100%; max-width:150px"
                              :disabled="(securityLevel_<80)"
                              format="MM/dd/yyyy"
                              size="mini"
                              placeholder="MM/DD/YYYY">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="Agreement:" prop="AgreementId" v-if="showAgreement">
              <el-select filterable clearable default-first-option size="mini"
                          v-bind:disabled="(agreements.length == 0 || securityLevel_<80)"
                          style="width: 100%; max-width:300px"
                          v-validate name="AgreementId"
                          placeholder="Select if applicable"
                          v-model="Main.AgreementId">
                <el-option v-for="agreement in agreements"
                            :key="agreement.AgreementId"
                            :label="agreement.Name"
                            :value="agreement.AgreementId">
                        <div style="display: flex;" >
                          <div style="display: flex; justify-content: space-between;">
                              <span style="float: left">{{agreement.Name}}</span>
                            </div>
                            &nbsp;
                            <el-tooltip :content="invoiceDateToTermStatusToolTip(agreement)" placement="top-start">
                              <div :style="{'float': 'right', 'color': '#8492a6', 'font-size':'smaller', 'background-color': invoiceDateToTermStatusColor(agreement)}" 
                                v-if="agreement.InceptionDate && agreement.TerminationDate">
                                <span style="float: right; color: #8492a6;">
                                {{ !!agreement.InceptionDate ? $dayjs(agreement.InceptionDate).format('L') : 'NA' }}
                                -
                                {{ !!agreement.TerminationDate ? $dayjs(agreement.TerminationDate).format('L') : 'NA' }}
                                </span>
                              </div>
                            </el-tooltip>
                        </div>
                </el-option>
              </el-select>
              <el-button type="primary" plain size="mini" icon="el-icon-refresh" title="Refresh agreements and schedules" @click="refreshAgreementLists" v-if="!(securityLevel_<80)"></el-button>
              <router-link 
                v-if="!!selectedVendor && Main.VendorId && securityLevel_ >= $namedKey.SecurityLevel.BasicUser" 
                  :to="{ name: 'AgreementEditView', params: { agreementId: Main.AgreementId }}" target="_blank">
                  <small >
                    View
                    <i class="fa fa-external-link"></i>
                  </small> 
              </router-link>
            </el-form-item>
            <el-form-item label="Schedule:" prop="ScheduleItemId" v-if="!!selectedAgreement && selectedAgreement.HasScheduledItems">
              <el-select filterable clearable size="mini" default-first-option
                          v-bind:disabled="(schedules.length == 0 || securityLevel_<80)"
                          style="width: 100%; max-width:300px"
                          placeholder="Select if applicable"
                          name="ScheduleItemId" v-validate
                          v-model="Main.ScheduleItemId">
                <el-option v-for="schedule in schedules"
                            :key="schedule.Id"
                            :label="schedule.Identifier"
                            :value="schedule.Id">
                </el-option>
              </el-select>
              <el-button type="primary" plain size="mini" icon="el-icon-refresh" title="Refresh agreements and schedules" @click="fetchSchedules" v-if="!(securityLevel_<80)"></el-button>
            </el-form-item>
            <el-form-item :label="selectedAgreement.UnitsLabel + ':'" prop="UnitsDisplay" v-if="!!selectedAgreement && selectedAgreement.AggregateUnits">
              <el-currency-input  :placeholder="selectedAgreement.UnitsLabel"
                        v-model="Main.UnitsDisplay"
                        :options="{currency: null, precision: selectedAgreement.UnitsLabel == 'Hours' ? 2 : 0}"                                    
                        size="mini"
                        name="Units"
                        style="width: 100%; max-width:120px; display: inline-block;"
                        :disabled="(securityLevel_<80)"
                        target-class="inputYellow"
                        v-validate
                      />
                    <template v-if="!!selectedAgreement.DefaultUnitsMethod">
                      &nbsp;({{selectedAgreement.DefaultUnitsMethod}})
                    </template>
              <span v-if="agreementGroupUnitBalance.UnitsBalance && !!selectedAgreement.DefaultUnitsMethod" style="margin-left: 8px;">
                Balance:
                <span :style="agreementGroupUnitBalanceIsBelowThreshold ? 'color: red' : ''"
                      :title="agreementGroupUnitBalanceIsBelowThreshold ? 'Balance is below threshold of ' + accounting.formatNumber(agreementGroupUnitBalance.NotifyThresholdMinimum, 2) : ''">
                  {{agreementGroupUnitBalance.UnitsBalance | number2}}
                  <i class="el-icon-warning" v-if="agreementGroupUnitBalanceIsBelowThreshold" />
                </span>
              </span>
            </el-form-item>

            <el-form-item label="Bank Account:">
              <bank-account-selector
                  v-model="Main.BankAccountId"
                  :disabled="Main.TransactionMatched || securityLevel_ < $namedKey.SecurityLevel.Admin"
                  :owner-id="Main.OwnerId"
                  v-validate name="BankAccount"
                  displayColumn="NameNumberMasked"
                  class="fullContent"
                  style="width: 100%; max-width:300px"
                  @options-fetched="bankAccountOptionsFetched"
              ></bank-account-selector>
            </el-form-item>
            <el-form-item label="Manual Transaction:" v-if="!!Main.InvoiceId && isManualBankAccount">
                <el-button type="primary" plain :disabled="Main.DisableMatches || (!!veeFields.Amount && veeFields.Amount.changed) || securityLevel_ < $namedKey.SecurityLevel.Admin" v-if="!!Main.InvoiceId && isManualBankAccount" @click="showCreateAndMatchManualTransaction = true"
                    :title="manualMatchTitle">Create Manual Transaction</el-button>
            </el-form-item>
            <el-form-item v-if="!!selectedAgreement && selectedAgreement.PaysAutomatically">
              <span class="form-control-static" style="color:red;">Per Agreement, this item will be Auto-Paid.</span>
            </el-form-item>

            <el-form-item label="Status:" prop="StatusId">
              <el-select filterable size="mini" default-first-option
                          v-bind:disabled="(statuses.length == 0 ||securityLevel_<80)"
                          style="width: 100%; max-width:300px"
                          v-validate name="Status"
                          v-model="Main.StatusId">
                <el-option v-for="status in statuses"
                            :key="status.KeyValueInt"
                            :label="status.TextParenDescription"
                            :disabled="!status.UserSelectable"
                            :value="status.KeyValueInt">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Approval" prop="ApprovalId" v-if="showApproval">
              <approval 
                ref="approval" 
                v-if="Main.ClientId && Main.VendorId"
                :approval-id="Main.ApprovalId" 
                :client-id="Main.ClientId" 
                :vendor-id="Main.VendorId" 
                :workflow-id="(!!selectedAgreement && !!selectedAgreement.WorkflowId) ? selectedAgreement.WorkflowId : 1" 
                @approver-clicked="approverClicked"
                :security-level="securityLevel_"></approval>
                <el-checkbox size="mini" v-model="Main.NotifyApproversOnMatch" v-validate name="NotifyApprovers" :disabled="Main.ConfirmedTransactionMatched || Main.MatchNotificationSent || securityLevel_ < $namedKey.SecurityLevel.Admin" >Notify Approvers when Matched to Bank Transaction</el-checkbox>
            </el-form-item>
            <el-form-item label="FYI" prop="FYIId" >
              <approval 
                v-if="Main.ClientId && Main.VendorId"
                ref="fyi" 
                :approval-id="Main.FYIId" 
                :client-id="Main.ClientId" 
                :vendor-id="Main.VendorId" 
                :workflow-id="2" 
                @approver-clicked="approverClicked"
                approver-label="FYI" 
                :security-level="securityLevel_"></approval>
                <el-checkbox size="mini" v-model="Main.NotifyFYIOnMatch" v-validate name="NotifyFYI" :disabled="Main.ConfirmedTransactionMatched || Main.MatchNotificationSent || securityLevel_ < $namedKey.SecurityLevel.Admin" >Notify FYIs when Matched to Bank Transaction</el-checkbox>
            </el-form-item>
            <el-form-item label="Completed:" v-if="Main.CompletedDate">
              <span class="form-control-static">{{Main.CompletedDate | shortDate}}</span>
              &nbsp;
              <span class="small">{{Main.CompletedNotes}}</span>
            </el-form-item>

            <el-form-item label="On/By:" v-if="Main.CompletedDate">
              <span class="form-control-static">{{Main.CompletedDateLabel}}</span>
            </el-form-item>
            <el-form-item v-if="fileData && Object.keys(fileData).length">
              <div slot="label">
                <el-checkbox v-model="updateFileName"
                              v-validate name="UpdateFileName">
                  Update File Name
                </el-checkbox>
              </div>
              <div>
                <span v-if="!updateFileName" :title="calculatedFileName">
                  {{fileData.name}}
                </span>
                <el-input v-if="updateFileName"
                          v-model="proposedFileName"
                          :title="fileData.name"
                          size="mini"
                          style="width: 100%; max-width:400px"
                          :disabled="(securityLevel_<80)"></el-input>
              </div>
            </el-form-item>
            <el-form-item v-if="updateFileName" label="Calculated Name:">
              <div>
                <span>
                  {{calculatedFileName}}
                </span>
              </div>
            </el-form-item>
            <el-form-item label="Notes:">
                  <comment-log-list 
                      ref="commentLog"
                      v-if="showCommentLogList"
                      entityType="Invoice" 
                      :entityId="Main.InvoiceId" 
                      :securityLevel="securityLevel_" 
                      :showTextbox="securityLevel_ >= 60" 
                      style="width: 100%; max-width:400px"
                      v-model="notes"></comment-log-list>
              </el-form-item>
            <hr />

            <el-form-item label="Created:" v-if="Main.InvoiceId">
              <span class="form-control-static" v-bind:title="Main.InvoiceId">{{Main.CreateDateLabel}}</span>
              (<span class="form-control-static" v-bind:title="Main.InvoiceId">{{Main.InvoiceId}}</span>)
            </el-form-item>
            <el-form-item label="Modified:" v-if="Main.InvoiceId">
              <span class="form-control-static">{{Main.ModifyDateLabel}}</span>
            </el-form-item>
            <el-form-item v-if="securityLevel_ >=$namedKey.SecurityLevel.BasicUser">
              <div class="pull-right">
                <el-button icon="el-icon-refresh" @click="init(true)" size="mini" circle></el-button>
                <el-button type="success" title="SAVE" @click="saveAPInvoice" :disabled="!(securityLevel_>=80 && formValid && isFormDirty)" size="mini">
                  <i class="fa fa-check" aria-hidden="true"></i>SAVE
                </el-button>
                <el-button type="danger" icon="el-icon-delete" :disabled="(securityLevel_<80 || !Main.Deleteable)" @click="showDelete = true" size="mini"></el-button>
              </div>
            </el-form-item>
            <div ref="divThresholdNotificationEmail" style="display: none;" v-if="!!selectedAgreement">
              <div>
                <p>
                  The balance of {{selectedAgreement.Name}} is now {{agreementGroupUnitBalance.UnitsBalance | number2}},
                  which is below the threshold of {{agreementGroupUnitBalance.NotifyThresholdMinimum | number2}}.
                </p>
                <p>
                  Thanks,
                  <br />
                  VAM
                </p>
              </div>
            </div>
          </el-form>
        </el-main>
      </el-container>
     <el-dialog :visible.sync="showDelete"
                v-if="!!Main && !!Main.InvoiceId"
                :append-to-body="appendDialogsToBody">
       <div slot="title">
         <span style="color:red">Delete Invoice and File</span>
       </div>
       <invoice-delete v-if="!!Main && Object.keys(Main).length > 0 && showDelete"
          :invoice="Main"
          @deleted="invoiceDeleted"
          @canceled="showDelete = false" >
       </invoice-delete>
     </el-dialog>
     <el-dialog title="Add Vendor" :visible.sync="showVendorEdit" width="660px" append-to-body>
       <vendor-edit v-if="showVendorEdit"
                    :onSave="onSaveNewVendor"
                    :vendor-client-id-to-add-automatically="Main.ClientId"
                    @close-dialog="showVendorEdit = false">
       </vendor-edit>
     </el-dialog>
    <el-dialog title="Create and Match New Manual Transaction" :visible.sync="showCreateAndMatchManualTransaction" width="500px">
        Confirm Create and Match Manual Bank Account Transaction
        <span slot="footer" class="dialog-footer">
            <el-button @click="showCreateAndMatchManualTransaction = false">Cancel</el-button>
            <el-button type="primary" plain @click="createAndMatchManualTransaction">Confirm</el-button>
        </span>
    </el-dialog>
    <el-dialog title="Set Text to Recognize Owner" :visible.sync="showRecognizeOwnerText" width="500px">
        Select from the distinct Customer names recognized on the invoice:
        <br>
        <el-radio v-for="item in recognizedNames" :key="item.String" v-model="selectedRecognizedName" :label="item.String"></el-radio>
        <span slot="footer" class="dialog-footer">
            <el-button @click="showRecognizeOwnerText = false">Cancel</el-button>
            <el-button v-if="selectedRecognizedName" type="primary" plain @click="setRecognizedOwnerNameValue">Set</el-button>
        </span>
    </el-dialog>

   </div>
</template>
 <script>
import Vue from "vue";
//import mixinLiquidity from './../VueCommon/liquidity.vue'
import mixinSchema_liq from "./../DAL/mixinSchema_liq";
import mixinSchema_fr from "./../DAL/mixinSchema_fr";
import mixinSchema_visium from "./../DAL/mixinSchema_visium";
import mixinSchema_vam from "./../DAL/mixinSchema_vam";
import mixinSchema_system from "./../DAL/mixinSchema_system";
import mixinSchema_attachment from "./../DAL/mixinSchema_attachment";
import mixinUtility from "./../VueCommon/Utility";
import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';
import FormattedInput from '@/components/form/FormattedInput.vue';
import InvoiceDelete from '@/views/Invoice/InvoiceDelete.vue';
import VendorEdit from './../Vendor/VendorEdit.vue'
import debounce from 'lodash/debounce';
import accounting from 'accounting';
import Common from '../../utils/common';
import BankAccountSelector from '@/components/form/BankAccountSelector.vue'
import Approval from '@/views/Approval/Approval.vue'
import CommentLogList from '@/js/CommentLog/CommentLogList.vue';
import mixinAttachmentService from './../VueCommon/mixinAttachmentService.js'
import { FrService } from '@/services/DAL/frService';


export default Vue.extend({
  name: "InvoiceEdit",
  mixins: [
    mixinUtility,
    mixinSchema_liq,
    mixinSchema_vam,
    mixinSchema_visium,
    mixinSchema_system,
    mixinSchema_attachment,
    mixinAttachmentService,
    mixinSchema_fr
  ],
  components: {
    'formatted-input': FormattedInput,
    'invoice-delete': InvoiceDelete,
    'vendor-edit': VendorEdit
    , 'bank-account-selector': BankAccountSelector
    , 'approval': Approval
    , 'comment-log-list': CommentLogList
  },
  props: {
    parameters: {
      type: Object
    },
    securityLevel: {
      type: Number,
      default: null
    },
    appendDialogsToBody: {
      type: Boolean,
      default: true
    }
    , invoice: {
      type: Object
    }
  },
  data: function() {
    var validateUnits = (rule, value, callback) => {
        if (!this.selectedAgreement || !this.selectedAgreement.AggregateUnits) {
            callback();
        }
        else if (value === '' || value === null) {
            callback(new Error(`${this.selectedAgreement.UnitsLabel} are required.`));
        }
        else {
            callback();
        }
    };
    return {
      Main: {},
      clients: [],
      owners: [],
      vendors: [],
      bankAccounts: [],
      agreements: [],
      duplicateInvoiceNumbers: [],
      schedules: [],
      statuses: [],
      agreementGroupUnitBalance: {},
      external: {
        //pass this object to other controls for them to set values.  Must use an object and the values underneath it because objects are implicitly "by ref"
        addedVendorId: -1,
        addedAgreementId: -1
      },
      securityLevel_: this.securityLevel,
      formValid: false,
      invoiceRules: {
        VendorInvoiceNumber: [
            { required: true, message: 'Vendor Invoice Number is required', trigger: 'blur' },
            { min: 1, trigger: 'blur' }
        ],
        Amount: [
          { required: true, message: 'Amount is Required', trigger: 'blur' }
        ],
        ClientId: [
            { required: true, type: 'integer', message: 'Client is Required', trigger: 'change' }
        ],
        OwnerId: [
            { required: true, type: 'integer', message: 'Owner is Required', trigger: 'change' }
        ],
        VendorId: [
            { required: true, type: 'integer', message: 'Vendor is Required', trigger: 'change' }
        ],
        Notes: [
            { required: true, message: 'Description is Required', trigger: 'change' }
        ],
        Date: [
            { required: true, message: 'Invoice Date is Required', trigger: 'change' }
        ],
        DueDate: [
            { required: true, message: 'Due Date is Required', trigger: 'change' }
        ],
        UnitsDisplay: [
            { validator: validateUnits, trigger: 'blur' }
        ],
      }
      , fileData: {}
      , updateFileName: false
      , proposedFileName: ''
      , showDelete: false
      , formDisabled: false
      , showVendorEdit: false
      , showCreateAndMatchManualTransaction: false
      , linkPermission:{}
      , loading: false
      , notes: ''
      , showCommentLogList: true
      , hideApproval: true // need to only initialize Approval after everything else is loaded, so that it is only loaded with the proper WorkflowId
      , graphService: {}
      , selectedRecognizedName: ''
      , recognizedNames: []
      , showRecognizeOwnerText: false
      , autoIngestedButOwnerNotSet: false
      , recognizedAmounts: []
    };
  },
  created: async function() {
    this.graphService = new MicrosoftGraphService();
    if (this.securityLevel_ === null) {
      this.securityLevel_ = tryParseInt(
        getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP),
        0
      );
    }
    this.init(false);
  },
  mounted: async function(){
    await this.$nextTick();
    this.$refs.vendorInvoiceNumberInput.focus();
  },
  watch: {
    "Main.VendorId": async function(val, oldVal) {
      await this.fetchAgreementList();
      this.fetchDuplicateInvoiceNumbers();
      this.proposedFileName = this.calculatedFileName;
    },
    "Main.VendorInvoiceNumber": function(val, oldVal) {
      this.proposedFileName = this.calculatedFileName;
    },
    "Main.Date": function(val, oldVal) {
      this.proposedFileName = this.calculatedFileName;
    },
    "Main.ClientId": function(val, oldVal) {
      if (!(oldVal === undefined)) {
        //when oldVal=undefined, that is when the value is initially set.
        this.Main.OwnerId = null; //if Client is changed, then owner must be reset
        this.Main.AgreementId = null;
        this.fetchOwners();
        this.fetchVendorList();
        this.proposedFileName = this.calculatedFileName;
      }
    },
    "Main.OwnerId": async function(val, oldVal) {
      if (!(oldVal === undefined)) {
        //when oldVal=undefined, that is when the value is initially set.
        this.Main.ReferenceEntityId = null;
        this.Main.ReferenceEntityType = null;
        this.Main.ReferenceEntityName = null;
        this.Main.AgreementId = null;
        this.Main.ScheduleItemId = null;
        this.schedules = [];
        this.agreements = [];
        this.fetchAgreementList();
        this.fetchSchedules();
        this.proposedFileName = this.calculatedFileName;
        if (this.Main.BankAccountId){
          this.Main.BankAccountId = null;
          this.$notify.warning({
            title: 'Bank Account',
            message: 'Selected Bank Account has been reset due to changing Owner.  Please validate Bank Account.',
            offset: 100
          });

        }
      }
      if (val && this.autoIngestedButOwnerNotSet && this.Main.VendorId) await this.setRecognizedOwnerName();
    },
    "Main.AgreementId": function(val, oldVal) {
      if (!(oldVal === undefined)) {
        this.fetchSchedules();
        if (!!this.selectedAgreement && this.selectedAgreement.PaysAutomatically && this.Main.StatusId == 10 ){ // only change the status if the current status is Ingested (i.e. new)
          this.Main.StatusId = 410; // Set For Payment
          this.$notify.info('Status automatically set to "Set for Payment" for Auto-pay agreement');
        }
      }
    },
    "Main.DueDate": function(val, oldVal) {
      if (!(oldVal === undefined)) {
        this.fetchSchedules();
      }
    },
    // "external.addedVendorId": function(val, oldVal) {
    //   this.Main.VendorId = val;
    //   this.fetchVendorList();
    // },
    // "external.addedAgreementId": function(val, oldVal) {
    //   this.Main.ReferenceEntityId = val;
    //   this.fetchReferences(); //refreshes Agreements
    // },
    "Main.StatusId": function(val, oldVal) {

    },
    "vParameters.InvoiceId": function(val, oldVal) {
      this.Main = this.getInvoiceObject(val) || {};
    },
    'Main.UnitsDisplay': function(val, oldVal){
      if (oldVal !== undefined){ // don't do when initially set
      this.Main.Units = val * this.selectedAgreement.DefaultUnitsMultiplier;
      }
    },
    "Main": {
        handler: function (val, oldVal) {
                this.validateForm();
            }
            , deep: true
    }
  },
  computed: {
    isFormDirty() {
        return Object.keys(this.veeFields).some(key => this.veeFields[key].dirty);
    }
    , descriptionLineCount(){
      if (this.Main && this.Main.Notes){
        const l = this.Main.Notes.split(/\n/).length;
        if (l && l>2) return l+1;
      }
      return 3;
    }
    , showAmountOptions(){
      // is auto-ingested and status still expects editing, and there are amounts to show.
      return (this.Main && this.Main.RecognizedInvoiceJSONId && this.Main.StatusId < 200 && this.recognizedAmounts && this.recognizedAmounts.length && this.securityLevel_ >= 80);
    }
    , manualMatchTitle() {
      if (!!this.veeFields.Amount && this.veeFields.Amount.changed) return 'Amount must be saved before creating Manual Transaction';
      return this.Main.DisableMatches ? this.Main.DisabledMatchReason : 'Create and match new manual transaction';
    }
    , isManualBankAccount: function() {
      if (!!this.Main.BankAccountId && !!this.bankAccounts && Object.keys(this.bankAccounts).length){
        const selectedBankAccount = this.getSelectedArrayItem(
                              this.bankAccounts,
                              this.Main.BankAccountId,
                              "Id"
                            );
        if (!selectedBankAccount || !Object.keys(selectedBankAccount).length) return false;
        return selectedBankAccount.ManualTransactions || false;
      }
      else return false;
    }
    , calculatedFileName: {
        get: function (){
        if (!!this.fileData && Object.keys(this.fileData).length > 0) {
          const dt = this.$moment(this.Main.Date).format('YYYY-MM-DD');
          const client = this.getSelectedName(this.clients, this.Main.ClientId, "Id", "Name") || '';
          const owner = this.getSelectedName(this.owners, this.Main.OwnerId, "Id", "Name") || '';
          const vendor = this.getSelectedName(this.vendors, this.Main.VendorId, "Id", "Name") || '';
          const ext = this.fileData.name.split('.').pop();
          if (!dt || !client || !owner){
            return this.proposedFileName;
          }
          else {
            return `${dt} ${client} ${owner} ${vendor}${(this.Main.VendorInvoiceNumber) ? ' Invoice ' + this.Main.VendorInvoiceNumber : ''}.${ext}`;
          }
        }
        else return '';
        }
    }
    , selectedVendor: function() {
      if (
        !!this.Main &&
        !!this.Main.VendorId &&
        this.vendors.length > 0
      ) {
        return this.getSelectedArrayItem(
          this.vendors,
          this.Main.VendorId,
          "Id"
        );
      } else {
        return this.vam_GetVendors_New();
      }
    }
    , selectedAgreement: function() {
      if (
        !!this.Main &&
        !!this.Main.AgreementId &&
        this.agreements.length > 0
      ) {
        return this.getSelectedArrayItem(
          this.agreements,
          this.Main.AgreementId,
          "AgreementId"
        );
      } else {
        return this.liq_GetAgreementsForInvoiceReference_New();
      }
    },
    vParameters: function() {
      if (typeof this.parameters === 'string'){
        return JSON.parse(this.parameters);
      }
      return this.parameters;
    }
    , ReferenceEntityType: function(){
      if (this.Main.LockAgreementSelection){
        return  this.Main.ReferenceEntityType
      }
      if (!!this.selectedAgreement && this.selectedAgreement.HasScheduledItems > 0 && !!this.Main.ScheduleItemId){
        return 'ScheduleItem'
      }
      if (!!this.selectedAgreement && !!this.selectedAgreement.AgreementId){
        return 'Agreement'
      }
      return null;
    }
    , ReferenceEntityId: function(){
      if (this.Main.LockAgreementSelection){
        return  this.Main.ReferenceEntityId
      }
      if (!!this.selectedAgreement && this.selectedAgreement.HasScheduledItems > 0 && !!this.Main.ScheduleItemId){
        return this.Main.ScheduleItemId
      }
      if (this.selectedAgreement){
        return this.Main.AgreementId
      }
      return null;
    }
    , agreementGroupUnitBalanceIsBelowThreshold: function() {
        return (this.agreementGroupUnitBalance.UnitsBalance || 0) <
            (this.agreementGroupUnitBalance.NotifyThresholdMinimum || 0);
    }
    , showApproval: function(){
      if (this.hideApproval) return false; // hide it until end of init
      if (this.Main.ApprovalId) return true; // even if it's complete
      const status = this.getSelectedArrayItem(
                this.statuses,
                this.Main.StatusId,
                'KeyValueInt'
            );
      if (!!status && status.KeyText == 'Awaiting Approval'){ // change this to use AdditionalData1 after first publish
        return true;
      }
      else 
        return false;
    },
    showAgreement: function(){
      if (this.Main.LockAgreementSelection){
        return false;
      }
      else return true;
    },
    showAgreementLink: function(){
      if (this.Main.AgreementId > 0){
        return (getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP) >= 50 
                || getStoredSecurityLevel(this.$namedKey.SecurityView.ViewAgreements) >= 50 );
      }
      else return false;
    }
  },
  methods: {
    async init(force = false) {
      this.formDisabled = false; // reset if disabled after last delete
      if (!force && this.invoice && Object.keys(this.invoice).length) { // && this.invoice.InvoiceId > 0) { // InvoiceId might not be set when passing in attributes of new invoice
          this.Main = Object.assign(this.liq_GetInvoices_New(), this.invoice);
      }
      else if (this.vParameters && Object.keys(this.vParameters).length) {
        this.Main = this.getInvoiceObject(this.vParameters.InvoiceId) || this.liq_GetInvoices_New();
      }
      else if (force && this.invoice && this.invoice.InvoiceId) {
        this.Main = this.getInvoiceObject(this.invoice.InvoiceId);
      }
      else {
        this.Main = this.liq_GetInvoices_New()
        this.Main.NotifyFYIOnMatch = true;
        this.Main.NotifyApproversOnMatch = true;
      }
      this.autoIngestedButOwnerNotSet = (!this.Main.OwnerId && !!this.Main.RecognizedInvoiceJSONId);

      this.fetchVendorList();
      this.clients = await this.vam_GetUserClients({});
      this.fetchOwners();
      this.fetchStatuses();
      await this.fetchAgreementList()
      // can't get schedules or agreement group unit balance until we have selectedAgreement, which depends on agreements
      this.fetchSchedules();
      this.fetchAgreementGroupUnitBalance(false);
      this.fetchFileData();
      this.hideApproval = false;
      this.getRecognizedAmounts();
    }
    , async getRecognizedAmounts(){
      if (this.Main && this.Main.RecognizedInvoiceJSONId && this.Main.StatusId < 200 && this.securityLevel_ >= 80){
        this.recognizedAmounts = [];
        const recognizedFields = await this.fr_GetRecognizedFields({
              RecognizedInvoiceId: this.Main.RecognizedInvoiceJSONId
            }
            , true // async (optional)
          );
          
          if (recognizedFields && recognizedFields.length){ 
          this.recognizedAmounts = recognizedFields.filter(field =>
            field.FieldType == 'currency' && field.Field != 'SubTotal' && field.Field != 'TotalTax'
          );
        }
      }
    }
    , validateForm: debounce(function() {
        if (this.$refs["frmInvoiceEdit"]) {
          this.$refs["frmInvoiceEdit"].validate(valid => {
            this.formValid = valid;
          });
        } else {
          this.formValid = false;
        }
      }, 500),
    fetchDuplicateInvoiceNumbers: async function() {
      if (this.Main.VendorId && this.Main.VendorInvoiceNumber) {
        this.duplicateInvoiceNumbers = await this.liq_ValidateVendorInvoiceNumber({
            VendorId: this.Main.VendorId,
            VendorInvoiceNumber: this.Main.VendorInvoiceNumber,
            InvoiceId: this.Main.InvoiceId          
        })
      } else {
        this.duplicateInvoiceNumbers = [];
      }
    },
    async processApproval(){
      if (this.showApproval){
        this.$setSystemStatus('Saving Approvers');
        const result = await this.$refs.approval.saveApproval();
        if (!!result && result.isNew) {
          this.Main.ApprovalId = result.approvalId;
        }
        this.$setSystemStatus('');
        return result;
      }
    },
    async processFYI(){
      this.$setSystemStatus('Saving FYI');
      const result = await this.$refs.fyi.saveApproval();
      if (!!result && result.isNew) {
        this.Main.FYIId = result.approvalId;
      }
      this.$setSystemStatus('');
      return result;
    },
    saveAPInvoice: async function() {
      this.loading=true;
      
      if (!this.Main.InvoiceId || this.Main.InvoiceId == -1){
        // must create the invoice first if it doesn't already exist.  We will update it again later, also.
        delete this.Main.CreateUser;
        delete this.Main.CreateDate;
        const id = await new SmartObject('Invoice').createObject(
            this.Main
        );
        this.Main.InvoiceId = id;
        this.Main.Deleteable = true;
        this.$notify.success("Invoice Created");
      }

      var so = new SmartObject("Invoice", this.Main.InvoiceId);
      this.Main.ReferenceEntityType = this.ReferenceEntityType; // Computed
      this.Main.ReferenceEntityId = this.ReferenceEntityId; // Computed
      const approvalResult = await this.processApproval();
      const fyiResult = await this.processFYI();
      await so.updateObject(this.Main);
      this.$notify.success("Invoice Updated");
      await this.saveNotes();
      await this.checkAndUpdateFileLocation();

      if (this.updateFileName){
        const fileNameUpdated = this.renameFile();
      }

      this.fetchAgreementGroupUnitBalance(true);
      if (this.selectedAgreement && this.selectedAgreement.BypassInvoiceReview){ // only send from here if no review is required, which only applies to Pledges (prior to 10/7/22 applied to Charitable, too).
        if (!!approvalResult && Object.keys(approvalResult).length && approvalResult.newApprovers && approvalResult.newApprovers.length){
          await this.processApprovalEmails(this.Main.ApprovalId, 'Approval')
        }
        if (!!fyiResult && Object.keys(fyiResult).length && fyiResult.newApprovers && fyiResult.newApprovers.length){
          await this.processApprovalEmails(this.Main.FYIId, 'FYI')
        }
      }
      await this.$validator.reset();
      this.$emit("saved", this.Main);
      this.loading = false;
    },
    async processApprovalEmails(approvalId, label, resendGUID){
      const emailResponse = await this.sendEmails(approvalId, resendGUID);
      if (!!emailResponse && Object.keys(emailResponse).length && emailResponse.data > 0){
        this.$notify.success(`${emailResponse.data} ${label} Email ${emailResponse.data>1 ? 's' : ''} sent`);
      }
      else {
        this.$notify.success(`No ${label} Emails sent`);
      }
    },
    fetchAgreementGroupUnitBalance: async function(notify) {
        if (!!this.selectedAgreement && this.selectedAgreement.AggregateUnits) {
            var dsBalance = await this.liq_GetAgreementGroupUnitBalance({
                AgreementGroupId: this.selectedAgreement.GroupId
            });

            if (dsBalance && dsBalance.length) {
                this.agreementGroupUnitBalance = dsBalance[0];

                if (notify) {
                    if (this.agreementGroupUnitBalance.Notify) {
                        $(this.$refs.divThresholdNotificationEmail).exportToEmail({
                            toAddresses: this.agreementGroupUnitBalance.NotifyEmail
                            , subject: 'Balance of ' + this.selectedAgreement.Name + ' is below threshold'
                            , additionalAttributes: {
                                EntityType: 'Invoice'
                                , EntityId: this.Main.InvoiceId
                                , Source: 'InvoiceAggregateUnits'
                            }
                        });
                    }
                    else if (this.agreementGroupUnitBalanceIsBelowThreshold) {
                        this.$notify.info({
                            title: null,
                            message: 'The agreement balance is below the threshold, but no new notification was sent because the last notification was sent on ' +
                                moment(this.agreementGroupUnitBalance.LastEmailSendDate).format('M/D/YYYY') + ' at ' +
                                moment(this.agreementGroupUnitBalance.LastEmailSendDate).format('h:m A') + '.',
                            duration: 5000
                        });
                    }
                }
            }
            else {
                this.agreementGroupUnitBalance = {};
            }
        }
        else {
            this.agreementGroupUnitBalance = {};
        }
    },
    async getFileData(){
      try{
          const graphClient = await this.graphService.getGraphClient();
          const response = await graphClient
              .api(`/drives/${this.Main.DriveId}/items/${this.Main.FileId}`)
              .get();
          return response;
      }
      catch(e){
        try{
          console.log(e);
          const response = await this.getExternalLinkForEntity('Invoice', this.Main.InvoiceId);
          if (response && response.data && response.data.driveItem){
            return response.data.driveItem;
          }
          else return;
        }
        catch (err) {
          console.warn(err);
        }
      }
    },
    async fetchFileData(){
      try {
        if (this.Main.DriveId && this.Main.FileId){ // don't attempt if not file, which will be the case for system-generated Invoices, like Charitable.
          const response = await this.getFileData();
          this.fileData = response;
          this.$emit('file-data', this.fileData);
          // TODO: add some logic here to only do the file name stuff if securityLevel >=80
          const tryDate = response.name.substring(0, response.name.indexOf(" ")); // use this both for setting the date and determining if the file name should be changed
          if (!this.Main.Date){ // try to set the date from the name
            this.Main.Date = moment(tryDate).isValid() ? moment(tryDate).format('MM/DD/YYYY') : null;
          }
          if (!this.Main.ClientId){ // try to set the Client from the name
            for (const client of this.clients){
              if (response.name.indexOf(client.Name)> -1) {
                this.Main.ClientId = client.Id;
              }
            }
          }
          if (!moment(tryDate).isValid()){ // if the filename doesn't start with a valid date, default to updating the file name using the suggested name.
            this.updateFileName = true;
          }
          this.proposedFileName = response.name;
        }
      }
      catch (err) {
          console.warn(err);
      }
    }
    , async renameFile(){
        if (this.proposedFileName && (!this.proposedFileName.split('.').pop() || this.proposedFileName.split('.').pop() === '')){
          this.$notify({
              title: 'Canceled',
              type: 'warning',
              message: 'File NAME NOT CHANGED - extension required'
          });
          return false;
        }
        if (this.proposedFileName  === ''){
          this.$notify({
              title: 'Canceled',
              type: 'warning',
              message: 'File NAME NOT CHANGED - File name required'
          });
          return false;
        }
        const graphClient = await this.graphService.getGraphClient();
        try {
          const response = await graphClient
            .api(`/drives/${this.Main.DriveId}/items/${this.Main.FileId}`)
            .patch({
                    "name": this.proposedFileName
                  });
          this.fileData = response;
          this.updateFileName = false;
          this.$notify({
              title: 'File Name',
              type: 'success',
              message: 'File Name Changed'
          });
          return true;
        }
        catch (err) {
            console.warn(err);
            if (err.statusCode == 409) {
                await this.$confirm('Document with the same name already exists. File Name was NOT CHANGED.', 'Already Exists!', {
                    showCancelButton: false,
                    type: 'warning'
                    });
              this.$notify({
                  title: 'Canceled',
                  type: 'warning',
                  message: 'File NAME NOT CHANGED'
              });
            }
            else {
                this.$notify({
                    title: 'File Name Change Error',
                    type: 'error',
                    message: err.message
                });
            }
            return false;

        }
    }
    , checkAndUpdateFileLocation: async function() {
      if (!this.Main.FileId) return;  // skip if no File

      const clientAPARStore = await this.attachment_GetStores_Object({
        ClientId: this.Main.ClientId
        , OnlyAPARStore: true
      });

      const graphClient = await this.graphService.getGraphClient();
      let existing = {};
      try{
        existing = await graphClient
          .api(`/drives/${this.Main.DriveId}/items/${this.Main.FileId}`)
          .get();
      }
      catch(err){
        console.log(err);
        return;
      }
      //let currentpath = res.parentReference.path.replace('/drive/', `drives/${existing.parentReference.driveId}/`) + '/';
      if (!(clientAPARStore.DriveId == existing.parentReference.driveId && clientAPARStore.ItemId == existing.parentReference.id)) {
        //move it to the path corresponding to the status
        try{
          let file = {};
          const moveRes = await graphClient
          .api(`/drives/${this.Main.DriveId}/items/${this.Main.FileId}`)
          .responseType('raw')
          .patch(
            {
              parentReference: {
                id: clientAPARStore.ItemId
                ,driveId: clientAPARStore.DriveId
              }
            });
            if (moveRes.status == 200){ // success, object returned.
                const moveResObj = await moveRes.json();
                file = moveResObj;
            }
            else if (moveRes.status == 202 ){ // accepted.  must monitor location header to get final result.
                const location = moveRes.headers.get("location"); 
                if (location) {
                    file = await this.getDriveItemFromLocation(location);
                }                    
            }
            if (this.Main.FileId != file.id || this.Main.DriveId != file.parentReference.driveId) {
              this.Main.FileId = file.id;
              this.Main.DriveId = file.parentReference.driveId;
              const so = new SmartObject("Invoice", this.Main.InvoiceId);
              await so.updateObject(this.Main);
            }
            this.$notify.success(`Invoice Image moved to ${file.parentReference.path.substring(file.parentReference.path.indexOf('/root:/')+6)}.`);

        }
        catch(err) {
          console.log(err);
          return;
        }
        
      }
    },
    fetchSchedules: async function() {
      this.schedules = [];
      if (!!this.selectedAgreement && this.selectedAgreement.HasScheduledItems){
        this.schedules = await this.liq_GetReferenceEntity(
          {
            EntityType: 'ScheduleItem',
            OwnerId: this.Main.OwnerId,
            VendorId: this.Main.VendorId,
            AgreementId: this.Main.AgreementId,
            LookupDate: this.Main.DueDate
            //, IgnoreDateWindow: ignoreDateWindow
          });
      }
    },
    fetchAgreementList: async function() {
      this.agreements = [];
      this.agreements = await this.liq_GetAgreementsForInvoiceReference({
            OwnerId: this.Main.OwnerId
            , VendorId: this.Main.VendorId
            , InvoiceDate: this.Main.Date
            });
            //do not auto-set when only 1 found.  the default setting leads users to leave it selected when it might not need to be
    },
    refreshAgreementLists: async function() {
        await this.fetchAgreementList();
        this.fetchSchedules();
    },
    fetchVendorList: async function() {
      if (this.Main.ClientId) {
        this.vendors = await this.vam_GetVendors({
            ClientId: this.Main.ClientId,
        });
      } else {
        this.vendors = [];
      }
    },
    fetchOwners: async function() {
      if (this.Main !== undefined && this.Main.ClientId !== undefined) {
        this.owners = await this.vam_GetOwnersV2({
					ClientId: this.Main.ClientId
					, ClientIdList: undefined
        });

      } else {
        //clear Owners:
        var a = this.owners;
        a.splice(0, a.length);
      }
    },
    getInvoiceObject: function(invoiceId) {
      return this.liq_GetInvoices_Object(
        null, // clientId
        null, // ownerId
        null, // vendorId
        null, // statusId
        invoiceId, // invoiceId
        null, // requiredApprovalUserId
        null, // vendorInvoiceNumber
        null // agreementId
      );
    },
    async fetchStatuses() {
      this.statuses =await this.system_GetNamedKeys(
        "Liquidity.InvoiceStatus", //keyType
        null, //parentKeyType
        null, //parentKeyValue
        null, //keyCategory
        null, //namedKeyCategoryId
        null, //namedKeyTypeId
        null, //keyValue
        null //orderByText
      );
      if (this.statuses && this.statuses.length){
        this.statuses.forEach(async function (status) {
          if (status.KeyValueInt == 310){
            status.UserSelectable = false;
          }
          else {
            status.UserSelectable = true;
          }
        });
      }
    },
    async invoiceDeleted(){
      this.formDisabled = true;
      this.showDelete = false;
      this.$emit("deleted");
    },
    onSaveNewVendor: async function(vendor) {
        await this.fetchVendorList();

        if (this.vendors.length) {
            const addedVendor = this.getSelectedArrayItem(
                this.vendors,
                vendor.Id.toString(),
                'Id'
            );
            // if the added vendor is now in the list of vendors (which requires being assigned to
            //  the invoice's client), set Main.VendorId to the new vendor Id
            if (addedVendor) {
                this.Main.VendorId = vendor.Id;
                this.veeFields['Vendor'].dirty = true;
            }
        }
    }
    , async createAndMatchManualTransaction() {
      this.showCreateAndMatchManualTransaction = false;

      try {
          const dsMatchedTransaction = await this.liq_CreateManualInvoiceBankTransaction({
              InvoiceId: this.Main.InvoiceId
              , BankAccountId: this.Main.BankAccountId
          });

          if (dsMatchedTransaction && dsMatchedTransaction.length) {
            this.$notify.success(`Manual Transaction ${dsMatchedTransaction[0].Id} has been created and matched.`);

              this.Main.DisableMatches = true;
              this.Main.DisabledMatchReason = 'Manual Transaction already matched';
              this.Main.Deleteable = false;
          }
      }
      catch (err) {
          console.warn(err);
          this.$notify({
              title: 'Error',
              type: 'error',
              message: 'An error occurred processing your request. Please try again.'
          });

      }
    }
    , bankAccountOptionsFetched(bankAccounts) {
      this.bankAccounts = bankAccounts;
    }
    , async approverClicked(approver){
      if (!approver.responseDate && approver.approved == null && this.Main.ReviewedOn != null){
        try {
          await this.$confirm(`Are you sure you want to send the email to ${approver.FullName} at ${approver.Email}?`, 'Resend?', {
              type: 'warning'
              });
          try {
            const resendApprovalEmails = await this.liq_ResendApprovalEmail({
              GUID: approver.GUID
              }
              , true // async (optional)
            );
            if (resendApprovalEmails.length > 0){
              this.$notify.success('Email resent');
            }
            else {
              this.$notify.error('No Email was sent');
            }
          }
          catch (err) {
            console.log (err);
          }
        }
        catch (err) {
          console.log ('canceled resend');
        }
      }
      else if (approver.responseDate) {
        this.$alert('Email cannot be resent because the user has already responded.');
      }
      else if (approver.approved != null) {
        this.$alert('Email cannot be resent because a response has already been received.');
      }
      else if (this.Main.ReviewedOn == null) {
        this.$alert('Email cannot be resent because this Invoice has not yet been reviewed.');
      }
    }
    , async saveNotes() {
        if (this.notes) {
            await new SmartObject('CommentLog').createObject({
                EntityType: 'Invoice',
                EntityId: this.Main.InvoiceId,
                Comment: this.notes
            });
            this.notes = null;
            this.showCommentLogList = false;
            await this.$nextTick();
            this.showCommentLogList = true;
        }
    }
    , async updateAmountForFX(){
      const el = this;
      try {
        const conf = await this.$prompt(
        'Please enter update Invoice Amount',
        'Invoice Amount',
        {
          // MessageBoxData Type must be incorrectly defined, so declaring it as any
          confirmButtonText: "OK",
          showCancelButton: true,
          inputValue: this.Main.Amount,
          inputErrorMessage: 'Invoice Amount is required and must be a positive number.',
          inputValidator: function (input) {
            if (
              input &&
              el._isNumber(el._toNumber(input)) &&
              !isNaN(el._toNumber(input)) &&
              el._toNumber(input) > 0
            )
              return true;
            else return false;
          },
        }
        );
        try {
          this.loading = true;
          const updatedInvoice = await this.liq_UpdateInvoiceAmountForExchanceRate({
            InvoiceId: this.Main.InvoiceId
            , OldAmount: this.Main.Amount
            , NewAmount: conf.value
            }
            , true // async (optional)
          );
          if (updatedInvoice && Object.keys(updatedInvoice).length){
            this.init(true);
          }
          this.loading = false;
        } catch(e) {
          console.warn();
          this.$message('There was a problem processing your Transaction.  See console for more information.')
          this.loading = false;
        }            

      }
      catch{} // cancelled prompt
    }
    , invoiceDateToTermStatusColor(agreement){
      let result = 'inherit'
      switch (agreement.InvoiceDateToTermStatus) {
      case 'In':
        result = 'lightgreen'
        break;
      case 'In90':
        result = 'lightsalmon'
        break;
      case 'Out':
        result = 'pink'
        break;
      }
      return result;
    }
    , invoiceDateToTermStatusToolTip(agreement){
      let result = undefined;
      switch (agreement.InvoiceDateToTermStatus) {
      case 'In':
        result = 'Invoice Date is within the date range of the agreement'
        break;
      case 'In90':
        result = 'Invoice Date is within 90 days of the end of the agreement'
        break;
      case 'Out':
        result = 'Invoice Date is outside the date range of the agreement'
        break;
      }
      return result;
    }
    , async showEmail (messageId){
        const graphClient = await this.graphService.getGraphClient();
        let message = {};
        try{
          message = await graphClient
            .api(`/users/payments@vinitaspartners.com/messages/${messageId}`)
            .get();
          if (message && message.webLink){
            const win = window.open(message.webLink, '_blank');
            win.focus();
          }
        }
        catch(err){
          console.log(err);
          return;
        }
    }
    , async setRecognizedOwnerName(){
        this.loading = true;
        this.recognizedNames = await this.liq_GetCustomerNamesForRecognizedInvoice({
					RecognizedInvoiceId: this.Main.RecognizedInvoiceJSONId
					}
					, true // async (optional)
				);
        if (this.recognizedNames && this.recognizedNames.length) this.showRecognizeOwnerText = true;
        this.loading = false;
    }
    , async setRecognizedOwnerNameValue(){
        try {
          await this.fr_CreateOwnerRecognizedName({
            VendorId: this.Main.VendorId
            , OwnerId: this.Main.OwnerId
            , Name: this.selectedRecognizedName
            }
            , true // async (optional)
          );
          this.$notify.success('Owner text set');
          this.selectedRecognizedName = '';
          this.showRecognizeOwnerText = false;
        }
        catch (err) {
            console.warn(err);
                this.$notify({
                    title: 'Error setting Owner Text',
                    type: 'error',
                    message: err.message
                });
        }
    }
    , async setRecognizedAmount(amount){
      this.Main.Amount = amount;
      this.veeFields['Amount'].dirty = true;
    }

  }
});
 </script>
