
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FeePayingCommitmentEdit from '@/views/InvestmentParent/FeePayingCommitmentEdit.vue';
import * as AssetService from './../../services/DAL/assetService';
import formatters from '../../utils/formatters';
import findIndex from 'lodash/findIndex';

declare var $: any;
declare function getContainerType(element: any): string;

@Component({
    components: {
        FeePayingCommitmentEdit
    }
})
export default class FeePayingCommitmentList extends Vue {
    //#region Private Declarations
    private _assetService: AssetService.AssetService;
    private formatters: formatters;
    //#endregion Private Declarations

    //#region Props
    @Prop()
    investmentParentId: number;
    //#endregion Props

    //#region Data
    feePayingCommitments: AssetService.FeePayingCommitments[] = [];
    selectedFeePayingCommitment: AssetService.FeePayingCommitments = null;
    showFeePayingCommitmentEdit = false;
    isLoading = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this.formatters = new formatters();
    }

    async mounted() {
        if (getContainerType($(this.$refs.divFeePayingCommitmentList)) === 'SmartPortlet') {
            this.addSmartPortletButtons();
        }
        this.fetchFeePayingCommitments();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchFeePayingCommitments() {
        this.isLoading = true;
        const parameters = {} as AssetService.GetFeePayingCommitmentsParameters;
        parameters.InvestmentParentId = this.investmentParentId;
        this.feePayingCommitments = await this._assetService.GetFeePayingCommitments(parameters);
        this.isLoading = false;
    }

    selectFeePayingCommitment(feePayingCommitment) {
        if (feePayingCommitment) {
            this.showFeePayingCommitmentEdit = false;
            this.$nextTick(() => {
                this.showFeePayingCommitmentEdit = true;
                this.selectedFeePayingCommitment = feePayingCommitment;
            });
        }
    }

    closeFeePayingCommitment() {
        this.showFeePayingCommitmentEdit = false;
        this.selectedFeePayingCommitment = null;
    }

    onSaveFeePayingCommitment(feePayingCommitmentId) {
        this.closeFeePayingCommitment();
        this.fetchFeePayingCommitments();
    }

    onDeleteFeePayingCommitment(feePayingCommitmentId) {
        if (
            this.selectedFeePayingCommitment &&
            this.selectedFeePayingCommitment.Id == feePayingCommitmentId
        ) {
            const index = findIndex(this.feePayingCommitments, feePayingCommitment => {
                return feePayingCommitment.Id === this.selectedFeePayingCommitment.Id;
            });
            if (index > -1) {
                this.feePayingCommitments.splice(index, 1);
            }
        }
        this.closeFeePayingCommitment();
    }

    addSmartPortletButtons() {
        const vm = this;
        $(this.$refs.divFeePayingCommitmentList).smartPortlet('addButtons', {
            'Add': {
                buttonClass: 'btn-success-stripe'
                , iconClass: 'fa fa-plus'
                , onClick: function() {
                    vm.selectedFeePayingCommitment = null;
                    vm.showFeePayingCommitmentEdit = true;
                }
            }
        });
    }
    //#endregion Methods
}
