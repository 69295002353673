
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import * as SystemService from './../../services/DAL/systemService';
import * as UtilService from './../../services/DAL/utilService';

declare var $: any;
declare var process: any;
declare var SmartObject: any;

@Component
export default class DatabaseScaleView extends Vue {
    //#region Data
    private _systemService: SystemService.SystemService;
    private _utilService: UtilService.UtilService;

    databaseData: any = {};
    serviceLevels: SystemService.AzureDatabaseServiceLevel[] = [];
    databaseServiceLevelSchedule: UtilService.DatabaseServiceLevelSchedule[] = [];
    selectedServiceLevel: string = null;
    isLoadingDatabase = false;
    isLoadingSchedule = false;
    azureDatabaseServiceLevelOverride: SystemService.TopAzureDatabaseServiceLevelOverride = {
        Id: null,
        NoChangeUntil: null
    };
    noChangeUntilIsValid: boolean = true;
    defaultPickerDate = null;
    pickerOptions = null;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._systemService = new SystemService.SystemService();
        this._utilService = new UtilService.UtilService();

        this.getDatabase();
        this.fetchServiceLevels();
        this.fetchDatabaseServiceLevelSchedule();
        await this.getNoChangeUntil();
        this.setPickerOptions();
    }
    //#endregion Lifecycle

    //#region Methods
    async getDatabase() {
        this.isLoadingDatabase = true;
        const endpoint = '/SQLAzureManagement/GetDatabase';
        const response = await this.$http.get(endpoint);
        this.databaseData = response.data;
        // this.databaseData = await $.get({
        //     url: (process.env.VUE_APP_API_URL || 'https://localhost:44390/api/v1') + '/SQLAzureManagement/GetDatabase'
        // });
        this.isLoadingDatabase = false;
    }

    async setDatabase() {
        if (this.selectedServiceLevel) {
            this.isLoadingDatabase = true;
            const endpoint = '/SQLAzureManagement/SetServiceLevel';
            // let response = await this.$http.patch(endpoint, this.selectedServiceLevel);
            const response = await this.$http.patch(endpoint, {'ServiceLevel': this.selectedServiceLevel});
            this.databaseData = response.data;
        // **************************************************THIS NEEDS TO BE TESTED****************************************************
            //  can't figure out why axios ($http) doesn't send data. So using ajax

            // this.databaseData = await $.ajax({
            //     type: 'PATCH',
            //     url: (process.env.VUE_APP_API_URL || 'https://localhost:44390/api/v1') + '/SQLAzureManagement/SetServiceLevel',
            //     data: {
            //         ServiceLevel: this.selectedServiceLevel
            //     }
            // });
            this.isLoadingDatabase = false;
            this.selectedServiceLevel = null;
        }
    }

    async fetchServiceLevels() {
        this.serviceLevels = [];
        this.serviceLevels = await this._systemService.GetAzureDatabaseServiceLevel();
    }

    async fetchDatabaseServiceLevelSchedule() {
        this.databaseServiceLevelSchedule = [];
        this.isLoadingSchedule = true;
        this.databaseServiceLevelSchedule = await this._utilService.GetDatabaseServiceLevelSchedule();
        this.isLoadingSchedule = false;
    }
    
    async getNoChangeUntil() {
        const topAzureDatabaseServiceLevelOverride = await this._systemService.GetTopAzureDatabaseServiceLevelOverride();
        if (topAzureDatabaseServiceLevelOverride && topAzureDatabaseServiceLevelOverride.length) {
            this.azureDatabaseServiceLevelOverride = topAzureDatabaseServiceLevelOverride[0];
        }
        else {
            this.azureDatabaseServiceLevelOverride = {
                Id: null,
                NoChangeUntil: null
            };
        }
    }

    setPickerOptions() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        const nowPlus12Hours = new Date();
        nowPlus12Hours.setHours(now.getHours() + 12);
        const tomorrowIsValid = nowPlus12Hours.getDate() !== today.getDate(); // tomorrow is valid if 12 hours from now is into tomorrow

        const shortcuts = [{
            text: 'Today',
            onClick(picker) {
                picker.$emit('pick', today);
            }
        }];
        if (tomorrowIsValid) {
            shortcuts.push({
                text: 'Tomorrow',
                onClick(picker) {
                    picker.$emit('pick', tomorrow);
                }
            });
        }

        this.defaultPickerDate = today;
        this.pickerOptions = {
            shortcuts: shortcuts
            , disabledDate: (date) => {
                if (tomorrowIsValid) {
                    return date < today || date > tomorrow;
                }
                else {
                    return date < today || date > today;
                }
            }
        };
    }

    async onChange_NoChangeUntil() {
        const now = new Date();
        const nowPlus12Hours = new Date();
        nowPlus12Hours.setHours(now.getHours() + 12);
        const noChangeUntil = this.azureDatabaseServiceLevelOverride.NoChangeUntil ? new Date(this.azureDatabaseServiceLevelOverride.NoChangeUntil) : null;

        this.noChangeUntilIsValid = !noChangeUntil || (noChangeUntil >= now && noChangeUntil <= nowPlus12Hours);

        if (this.noChangeUntilIsValid) {
            try {
                if (this.azureDatabaseServiceLevelOverride.Id > 0) {
                    const adslo = new SmartObject('AzureDatabaseServiceLevelOverride', this.azureDatabaseServiceLevelOverride.Id);
                    if (noChangeUntil) {
                        await adslo.updateAttribute('NoChangeUntil', noChangeUntil);
                    }
                    else {
                        await adslo.deleteObject();
                        this.azureDatabaseServiceLevelOverride = {
                            Id: null,
                            NoChangeUntil: null
                        };
                    }
                }
                else if (noChangeUntil) {
                    const adslo = new SmartObject('AzureDatabaseServiceLevelOverride');
                    await adslo.createObject({
                        NoChangeUntil: noChangeUntil
                    });
                    this.azureDatabaseServiceLevelOverride.Id = adslo.entityId;
                }
                else {
                    return;
                }
                
                this.$notify.success('Change saved.');
            } catch {
                this.$notify.error('Update failed, please try again.');
            }
        }
    }
    //#endregion Methods
}
