
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import moment from 'moment';
import formatters from '../../utils/formatters';
import AgreementGroupEdit from '@/views/Agreements/AgreementGroupEdit.vue';
import * as VamService from './../../services/DAL/vamService';
import * as LiqService from './../../services/DAL/liq_Service';
import { UserClient } from './../../models/Common/UserClient';
import ElementUI from 'element-ui';

@Component({
  components: {
    AgreementGroupEdit
  }
})
export default class AgreementGroupList extends Vue {
  $refs: {
    tblAgreementGroupList: ElementUI.Table
  }
  //#region Data
  private _vamService: VamService.VamService;
  private _liqService: LiqService.LiqService;
  private formatters: formatters = new formatters();

  userClients: VamService.UserClients[] = [];
  vendors: VamService.Vendors[] = [];
  agreementGroups = [];
  selectedClientId = null;
  selectedVendorId = null;
  filter = '';
  selectedAgreementGroup = null;
  showAgreementGroupEdit = false;
  showAgreementGroupCreate = false;
  isLoading = false;
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._vamService = new VamService.VamService();
    this._liqService = new LiqService.LiqService();
    this.formatters = new formatters();
  }

  async mounted() {
    this.getUserClients();
    this.getVendors();
    this.getAgreementGroups();
  }
  //#endregion Lifecycle

  //#region Watches
  @Watch('selectedClientId')
  onClientIdChanged(val: string, oldVal: string) {
    this.getVendors();
    this.getAgreementGroups();
  }
  @Watch('selectedVendorId')
  onVendorIdChanged(val: string, oldVal: string) {
    this.getAgreementGroups();
  }
  //#endregion Watches

  //#region Methods
  selectAgreementGroup(agreementGroup) {
    if (agreementGroup) {
      this.showAgreementGroupEdit = false;
      this.$nextTick(() => {
        this.showAgreementGroupEdit = true;
        this.selectedAgreementGroup = agreementGroup;
      });
    }
  }

  closeAgreementGroup() {
    this.showAgreementGroupEdit = false;
    this.selectedAgreementGroup = null;
    this.$refs.tblAgreementGroupList.setCurrentRow(null);
  }

  onDeleteAgreementGroup() {
    this.closeAgreementGroup();
    this.getAgreementGroups();
  }

  async getUserClients() {
    this.userClients = await this._vamService.GetUserClients();
  }

  async getVendors() {
    const getVendorsParameters = {} as VamService.GetVendorsParameters;
    getVendorsParameters.ClientId = this.selectedClientId;
    this.vendors = await this._vamService.GetVendors(getVendorsParameters);
  }

  async getAgreementGroups() {
    this.isLoading = true;
    const getAgreementGroupsParameters = {} as LiqService.GetAgreementGroupsParameters;
    getAgreementGroupsParameters.ClientId = this.selectedClientId;
    getAgreementGroupsParameters.VendorId = this.selectedVendorId;
    this.agreementGroups = await this._liqService.GetAgreementGroups(
      getAgreementGroupsParameters
    );
    this.isLoading = false;
  }

  loadAgreementList(ClientId, AgreementGroupId) {
    window.open(
      '/Treasury/AgreementList/null/' + ClientId + '/' + AgreementGroupId
    );
  }
  //#endregion Methods
}
