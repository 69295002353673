<template>
    <div id="divBankTransactionListVue" v-cloak>

            <el-row :gutter="10">
                <el-col :span="8">
                    <el-input v-model="filter" size="mini" placeholder="filter" clearable suffix-icon="el-icon-search">
                    </el-input>
                </el-col>
                <el-col :span="16" class="text-right">
                    <el-button type="primary" icon="fa fa-file-excel-o" circle @click="openReport"></el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-table v-if="transactions[0]"
                              ref="transactionList"
                              :data="filteredTransactions"
                              style="width: 100%;"
                              stripe
                              size="mini"
                              row-key="TransactionId"
                              highlight-current-row
                              @row-click="editTransaction">
                        <el-table-column
                            v-if="showColumnCheckBox"
                            type="selection"
                            width="55">
                        </el-table-column>                        
                        <el-table-column min-width="150"
                                         sortable
                                         label="Owner"
                                         v-if="showColumnOwner"
                                         prop="OwnerName">
                        </el-table-column>
                        <el-table-column min-width="105"
                                         sortable
                                         label="Id"
                                         v-if="showColumnId"
                                         align="center"
                                         prop="TransactionId">
                        </el-table-column>
                        <el-table-column min-width="180"
                                         sortable
                                         label="Account"
                                         v-if="showColumnAccount"
                                         prop="AccountName">
                        </el-table-column>
                        <el-table-column min-width="70"
                                         label="#"
                                         sortable
                                         align="center"
                                         v-if="showColumnAccountNumber"
                                         prop="NumberDisplayMasked">
                        </el-table-column>
                        <el-table-column min-width="170"
                                         label="Card"
                                         sortable
                                         v-if="HideIfColumnEmpty(transactions, 'CardHolder')"
                                         align="center"
                                         prop="CardHolder">
                        </el-table-column>
                        <el-table-column min-width="85"
                                         label="Posted"
                                         sortable
                                         align="center"
                                         prop="PostedDate"
                                         :formatter="formatterShortDate">
                        </el-table-column>
                        <el-table-column min-width="110"
                                         label="Amount"
                                         sortable
                                         prop="Amount"
                                         align="right">
                            <template slot-scope="props">
                                <template v-if="props.row.ShowInvestmentTransactionDetails">
                                    <el-popover placement="left"
                                                title="Investment Details"
                                                width="250" trigger="hover">
                                        <p>Principal: {{props.row.PrincipalCashBase | currency}}</p>
                                        <p>Income: {{props.row.IncomeCashBase | currency}}</p>
                                        <p>Cost: {{props.row.CostBase | currency}}</p>
                                        <p>Units: {{props.row.Units}}</p>
                                        <p>CUSIP: {{props.row.CUSIPNumber}}</p>
                                        <p>Ticker: {{props.row.TickerSymbol}}</p>
                                        <p>Type: {{props.row.TypeofTransaction}}</p>
                                        <p>Code: {{props.row.TransactionShortDescription}}</p>
                                        <el-button slot="reference" type="text" size="mini">{{props.row.Amount | currency}}</el-button>
                                    </el-popover>
                                </template>
                                <template v-else>
                                    <span>{{props.row.Amount | currency}}</span>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="400"
                                         sortable
                                         label="Description"
                                         prop="Description">
                        </el-table-column>
                        <el-table-column label="Status"
                                         sortable
                                         v-if="showColumnStatus"
                                         prop="Status"
                                         min-width="120">
                            <template slot-scope="props">
                                <el-tag size="mini" :type="props.row.TagType">{{ props.row.Status}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="props">
                                <el-button v-if="props.row.IsManualTransaction" @click.stop="editManualTransaction(props.row)" size="mini" plain icon="el-icon-edit"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <bank-transaction-edit :show-modal="showBankTransactionEdit"
                                    :client-id="selectedRow.ClientId"
                                    :bank-transaction-id="selectedRow.TransactionId"
                                    :row="selectedRow"
                                    :security-level="securityLevel_"
                                    @closed-bank-transaction-edit="closedBankTransactionEdit">
            </bank-transaction-edit>
            <el-dialog
                :title="`Edit Manual Transaction ${selectedManualAccountTransaction.TransactionId}`"
                :visible.sync="showManualBankTransactionEdit"
                width="600px">
                <manual-bank-transaction-tab-view
                    :manual-account-transaction-id="selectedManualAccountTransaction.TransactionId"
                    :deletable="selectedManualAccountTransaction.Deletable"
                    view-type="dialog"
                    @save="onUpdate_manualAccountTransaction"
                    @delete="onDelete_manualAccountTransaction"
                    @close="showManualBankTransactionEdit = false"
                    :key="selectedManualAccountTransaction.TransactionId"
                ></manual-bank-transaction-tab-view>
            </el-dialog>
    </div>

</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import BankTransactionEdit from './BankTransactionEdit.vue'
    import ManualBankTransactionTabView from '@/views/BankTransaction/ManualBankTransactionTabView.vue'

    export default Vue.extend({
        name: 'BankTransactionList'
        , mixins: [mixinUtility, mixinSchema_liq ]
        , components: {
            'bank-transaction-edit': BankTransactionEdit
            , 'manual-bank-transaction-tab-view': ManualBankTransactionTabView
        }
        , props: {
            parameters: {
                type: Object
                , required: true
            }
            , securityLevel: {
                type: Number
                , default: null
            }
            , showColumnCheckBox: {
                type: Boolean
                , default: false
            }
            , showColumnId: {
                type: Boolean
                , default: true
            }
            , showColumnOwner: {
                type: Boolean
                , default: true
            }
            , showColumnAccount: {
                type: Boolean
                , default: true
            }
            , showColumnAccountNumber: {
                type: Boolean
                , default: true
            }
            , showColumnStatus: {
                type: Boolean
                , default: true
            }
        }
        , data() {
            return {
                transactions: {}
                , filteredTransactions: {}
                , showBankTransactionEdit: false
                , showManualBankTransactionEdit: false
                , selectedRow: {}
                , selectedRowIndex: null
                , selectedManualAccountTransaction: {}
                , selectedManualAccountTransactionIndex: null
                , filter: ''
                , securityLevel_: this.securityLevel
            }
        }
        , created: function () {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), 0);
            }
            
            this.fetchTransactions();
        }
        , computed: {
        }
        , watch: {
            'filter': function (val, oldVal) {
                this.filteredTransactions = this.transactions.filter(function (e) {
                    return e.Description.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.OwnerName.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.AccountName.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.NumberDisplayMasked.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.Amount.toString().indexOf(val.toLowerCase()) !== -1
                        || e.TransactionId.toString().indexOf(val.toLowerCase()) !== -1
                        || e.PostedDate.toString().indexOf(val.toLowerCase()) !== -1
                        || e.Status.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        ;
                });
            }
        }
        , methods: {
            fetchTransactions: async function () {
                this.transactions = await this.liq_GetTransactionsV2({
                        ClientId: this.parameters.ClientId
                        , OwnerId: this.parameters.OwnerId
                        , TransactionId: this.parameters.TransactionId
                        , DateRangeStart: this.parameters.DateRangeStart
                        , DateRangeEnd: this.parameters.DateRangeEnd
                        , AccountId: this.parameters.BankAccountId
                        , UnmatchedOnly: this.parameters.UnmatchedOnly
					}
					, true // async (optional)
				);
                this.filteredTransactions = this.transactions;
                if (this.parameters.TransactionId && this.transactions.length == 1) {
                    //if single transaction is specified, and found, open it automatically
                    this.$nextTick(function () {
                        this.editTransaction(this.transactions[0]);
                    });
                }
                this.filter = '';
            }
            , editTransaction: function (selectedRow, event, column) {
                if (selectedRow && selectedRow != null) {
                    this.selectedRowIndex = this.transactions.indexOf(selectedRow);
                    this.selectedRow = selectedRow;
                    this.showBankTransactionEdit = true;
                }
            }
            , closedBankTransactionEdit: function (updatedRow) {
                this.showBankTransactionEdit = false;
                this.transactions.splice(this.selectedRowIndex, 1, updatedRow);
                this.selectedRow = {};
            }
            , editManualTransaction(row) {
                this.selectedManualAccountTransactionIndex = this.transactions.indexOf(row);
                this.selectedManualAccountTransaction = row;
                this.showManualBankTransactionEdit = true;
            }
            , onUpdate_manualAccountTransaction(manualAccountTransaction) {
                const row = this.transactions[this.selectedManualAccountTransactionIndex];
                Object.assign(row, manualAccountTransaction);
            }
            , onDelete_manualAccountTransaction() {
                this.transactions.splice(this.selectedManualAccountTransactionIndex, 1);
            }
            , async openReport(){
                var url = '/fs/smartControl/ReportViewer/ReportViewer?params=' + encodeURIComponent(JSON.stringify({
                        HideParameters: true,
                        ParametersAreURLEncoded: false,
                        Report: 'BankAccountTransactionRegister',
                        Parameters: {
                            ClientId: this.parameters.ClientId
                            , OwnerId: this.parameters.OwnerId
                            , DateRangeStart: this.parameters.DateRangeStart
                            , DateRangeEnd: this.parameters.DateRangeEnd
                            , AccountId: this.parameters.BankAccountId
                            , UnmatchedOnly: this.parameters.UnmatchedOnly == null ? false : this.parameters.UnmatchedOnly
                        }
                }));
                var win = window.open(url, '_blank');
                win.focus();
            }
        }
    })
</script>
<style>
    .el-form-item__error {
        position: relative;
    }
</style>