

    import { Component, Vue, Prop } from "vue-property-decorator";
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import formatters from '@/utils/formatters';
    import numbro from 'numbro';

    @Component
    export default class TransactionChangeLogList extends Vue {

    //#region Private declarations for Services
    private _transactionService: TransactionsService.TransactionsService;
    private formatters: formatters;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({ type: Number, required: true }) readonly transactionHeaderId: number;
    @Prop({ type: String }) readonly viewType: string;
    //#endregion Props

    //#region Data
    changeLogList = [] as TransactionsService.ChangeLog[];
    loading = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionService = new TransactionsService.TransactionsService();
        this.formatters = new formatters();
        this.fetchChangeLog();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchChangeLog() {
        this.loading = true;
        const params = {} as TransactionsService.GetChangeLogParameters;
        params.TransactionHeaderId = this.transactionHeaderId;
        this.changeLogList = await this._transactionService.GetChangeLog(params);
        this.loading = false;
    }
    arraySpanMethod({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
        if (columnIndex < 3) {
            const _row = this.getSpanArr(this.changeLogList, column.property)[rowIndex];
            const _col = _row > 0 ? 1 : 0;
            return {
                  rowspan: _row,
                  colspan: _col
            }
      }
    }
    getSpanArr(data, field: string): Array<number> {
        const spanArr = [];
        let pos: number = 0
        for (var i = 0; i < data.length; i++) {
            if (i === 0) {
                spanArr.push(1);
                pos = 0
            } else {
            // Determine whether the current element is the same as the previous element
        if (data[i][field] === data[i - 1][field]) {
                    spanArr[pos] += 1;
                    spanArr.push(0);
                } else {
                    spanArr.push(1);
                    pos = i;
                }
            }
        }
        return spanArr;
    }
}
