

    import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
    import * as SystemService from '@/services/DAL/systemService';
    import formatters from '@/utils/formatters';
    import findIndex from 'lodash/findIndex';
    import numbro from 'numbro';
    import ElementUI from "element-ui";
    
    declare function getSecurityUserId(): string;
    declare var SmartObject: any;
    declare var $: any;

    @Component
    export default class CommentLogList extends Vue {
      @Ref() readonly refNewInput!: ElementUI.Input;

    //#region Private declarations for Services
    private _systemService: SystemService.SystemService;
    private formatters: formatters;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({ type: String }) readonly entityType: string; 
    @Prop({ type: Number }) readonly entityId: number;
    @Prop({ type: String }) readonly entity2Type: string; 
    @Prop({ type: Number }) readonly entity2Id: number;
    @Prop({ type: Number, required: true }) readonly securityLevel: number;
    @Prop({ type: String }) readonly viewType: string;
    @Prop({ type: String }) readonly value: string;
    @Prop({ type: String, default: '' }) readonly title: string;
    @Prop({ type: Boolean, default: true }) readonly showTextbox: boolean;


    //#endregion Props

    //#region Data

      comments = [] as SystemService.Comments[];
      newComment: string = null;
      loading = false;
      editCommentRow = {} as SystemService.Comments;
      editCommentNewValue: string = null;
      currentSecurityUserId: string = null;

    //#endregion Data

    //#region Lifecycle
    async created() {
      this._systemService = new SystemService.SystemService();
      this.formatters = new formatters();
      this.currentSecurityUserId = await getSecurityUserId();
      
      this.fetchCommentLog();

      if (this.value) {
          this.newComment = this.value;
      }
      
      // $(window).off('click.CommentLogList').on('click.CommentLogList', async e => {
      //     if (this.editCommentRow &&
      //         Object.keys(this.editCommentRow).length && 
      //         e.target &&
      //         !$(e.target).closest('.txtComment').length
      //     ) {
      //         if (this.editCommentNewValue !== this.editCommentRow.Comment) {
      //             var successful = await this.saveCommentEdit();
      //             if (successful) {
      //                 this.closeCommentEdit();
      //             }
      //         }
      //         else {
      //             this.closeCommentEdit();
      //         }
      //     }
      // });
    }
    //#endregion Lifecycle
    //#region Watches

    @Watch('value')
    valueChanged(val: string, oldVal: string) {
        this.newComment = this.value;
    }
    @Watch('entityId')
    entityIdChanged (val: number, oldVal: number) {
        this.fetchCommentLog();
    }
    //#endregion Watches
    //#region Methods

    async saveEdit(){
      if (this.editCommentNewValue !== this.editCommentRow.Comment) {
        const successful = await this.saveCommentEdit();
          if (successful) {
              this.closeCommentEdit();
          }
        }
        else {
            this.closeCommentEdit();
        }
    }

    async fetchCommentLog() {
      if (!!this.entityType && this.entityId > 0) {
        this.loading = true;
        
        const params = {} as SystemService.GetCommentsParameters;
        params.EntityType = this.entityType;
        params.EntityId = this.entityId;
        params.Entity2Type = this.entity2Type;
        params.Entity2Id = this.entity2Id;
        this.comments = await this._systemService.GetComments(params);
        this.loading = false;
      }
      else {
        this.comments = [] as SystemService.Comments[];
      }
    }

    async onKeyPress_newComment(e) {
      if (e.which == 10) {
        if (!!this.entityType && this.entityId > 0) {
            // Ctrl+Enter, only for 'keypress'
            const cl = new SmartObject('CommentLog');
            await cl.createObject({
                Comment: this.newComment,
                EntityType: this.entityType,
                EntityId: this.entityId,
                Entity2Type: this.entity2Type,
                Entity2Id: this.entity2Id
            });
            await cl.loadData();
            this.$emit('added', cl.dataObject);
            this.newComment = '';
            this.fetchCommentLog();
        }
        else {
            this.$notify.warning('This note will be added when the record is saved.');
        }
      }
    }

    userCanEditAndDeleteRow(row): boolean {
        return (this.securityLevel >= 80 || row.CreateUser === this.currentSecurityUserId) && !row.SystemGenerated;
    }

    async onClickComment(row) {
        if (!!this.editCommentRow && Object.keys(this.editCommentRow).length && this.editCommentNewValue !== this.editCommentRow.Comment) {
            await this.saveCommentEdit();
        }

        if (this.userCanEditAndDeleteRow(row)) {
            this.openCommentEdit(row);
        }
        else {
            this.closeCommentEdit();
        }
    }

    async openCommentEdit(row) {
        this.editCommentRow = row;
        this.editCommentNewValue = row.Comment;
        await this.$nextTick();
        (this.$refs[`txtComment_${row.Id}`] as ElementUI.Input).focus();
    }

    closeCommentEdit() {
        this.editCommentRow = null;
        this.editCommentNewValue = null;
    }

    async saveCommentEdit() {
        try {
            const cl = new SmartObject('CommentLog', this.editCommentRow.Id);
            await cl.updateAttribute('Comment', this.editCommentNewValue);
            this.$notify.success('Note saved.');
            this.editCommentRow.Comment = this.editCommentNewValue;
            return true;
        }
        catch (err) {
            this.$notify.error('Something went wrong processing your request, please try again.');
            return false;
        }
    }

    async deleteComment(row) {
        try {
            await this.$confirm(
                'Are you sure you want to delete this note?',
                'Delete',
                {
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );

            try {
                const cl = new SmartObject('CommentLog', row.Id);
                await cl.deleteObject();
                this.$emit('deleted');
                this.$notify.success('Note deleted.');
                
                const index = findIndex(this.comments, commentLog => {
                    return commentLog.Id === row.Id;
                });
                if (index > -1) {
                    this.comments.splice(index, 1);
                }
            }
            catch (err) {
                this.$notify.error('Something went wrong processing your request, please try again.');
            }
        }
        catch (err) {}
    }
  }

