
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as LiqService from '../../services/DAL/liq_Service';
import * as VamService from '../../services/DAL/vamService';
import Common from '../../utils/common';
import FormattedInput from '@/components/form/FormattedInput.vue';
import CommentLogList from '@/js/CommentLog/CommentLogList.vue';
import ClientSelector from '@/components/form/ClientSelector.vue';
import OwnerSelector from '@/components/form/OwnerSelector.vue';
import CharitableOrganization from '@/views/Agreements/CharitableOrganization.vue'
import * as TaxService from "../../services/DAL/taxService";

class Agreement extends LiqService.Agreements {
    ApprovedRejected: string = null;
    Schedule_Amount: number = null; // required to exist on this object so validation works
    Schedule_Date: Date = null; // required to exist on this object so validation works
}

declare var $: any;
declare var SmartObject: any;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function getStoredSecurityLevel(Id: number): number;
declare function getAppCookieKeyValue(key: string, defaultValue: object): string;
declare var process: any;

@Component({
    components: {
        FormattedInput,
        ClientSelector,
        OwnerSelector,
        CommentLogList,
        CharitableOrganization
    }
})
export default class CharitableDonationEdit extends Vue {
    //#region Private declarations for Services
    private _vam_Service: VamService.VamService;
    private _liq_Service: LiqService.LiqService;
    public _common: Common;
    private _taxService: TaxService.TaxService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() agreement: LiqService.Agreements;
    @Prop() viewType: string;
    @Prop({ type: Number, default: null })
    securityLevel!: number;
    @Prop() onSave: Function;
    //#endregion Props

    //#region Data
    clients = [] as VamService.UserClients[];
    owners = [] as VamService.OwnersList[];
    vendors = [] as VamService.Vendors[];
    securityLevel_: number = this.securityLevel;
    vendorHasAnnualLetter: boolean = null;
    vendorDocumentDateAnnualLetter: Date = null;
    vendorAnnualLetterRequirementSatisfied: string = null;
    creator: any = {};
    scheduleId: number = null;
    notes: string = null;
    showCommentLogList: boolean = true;
    ownerCategoryName: string = '';
    clientIsSingleOption: boolean = false;
    showCharitableOrganization: boolean = false;
    agreement_: Agreement = new Agreement();
    selectedCharitableOrganization= {} as TaxService.SearchExemptOrganizations;
    taxpayerId: string = '';
    loading: boolean = false;

    get isNew(): boolean {
        return (
            !this.agreement ||
            !Object.keys(this.agreement).length ||
            (this.agreement.AgreementId || 0) <= 0
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    get baseUrl(): boolean {
        return process && process.env && process.env.VUE_APP_BASE_URL
            ? process.env.VUE_APP_BASE_URL
            : '';
    }

    get selectedVendor(): VamService.Vendors {
        if (!this.agreement_ || !this.agreement_.VendorId) return undefined;
        let selectedVendor = {} as VamService.Vendors;
        if (this.agreement_.VendorId > 0) {
            selectedVendor = this._common.getSelectedArrayItem(
                this.vendors,
                this.agreement_.VendorId.toString(),
                'Id'
            );
            if (this.vendors.length && !selectedVendor) {
                this.agreement_.VendorId = null;
            }
            return selectedVendor;
        }
        else {
            return undefined;
        }
    }
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liq_Service = new LiqService.LiqService();
        this._vam_Service = new VamService.VamService();
        this._common = new Common();
        this._taxService = new TaxService.TaxService();
        
        if (!this.securityLevel_) {
            this.securityLevel_ = tryParseInt(
                getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP) || getStoredSecurityLevel(this.$namedKey.SecurityView.ManageCharitableDonations),
                0
            );
        }

        if (this.agreement && Object.keys(this.agreement).length) {
            //this.agreement_ = this.agreement;
            this.agreement_ = $.extend(this.agreement_, this.agreement, {
                Schedule_Date: null,
                Schedule_Amount: null
            });
        }

        // gkb 10/01/18 - this causes issues with the client dropdown for some reason (such as not being changeable more than once and not firing the watcher)
        // if (!this.agreement_.ClientId) {
        //     this.agreement_.ClientId = tryParseInt(await getAppCookieKeyValue('SelectedClientId', null), null);
        // }

        if (!this.isNew) {
            const s = new SmartObject('Schedule');
            await s.loadDataFromCompositeKey({
                EntityType: 'Agreement',
                EntityId: this.agreement_.AgreementId
            });
            this.scheduleId = s.entityId || null;
            if (s.dataObject.Amount) {
                this.agreement_.Schedule_Amount = s.dataObject.Amount;
            }
            if (s.dataObject.SeedDate) {
                this.agreement_.Schedule_Date = s.dataObject.SeedDate;
            }

            this.fetchCreator();
        }
        
        this.fetchVendors();
    }
    mounted() {}
    //#endregion Lifecycle

    //#region Watches
    @Watch('agreement_.ClientId')
    onSelectedClientChange(val: object, oldVal: object) {
        if (oldVal !== undefined) { // initial load
            this.agreement_.OwnerId = null;
            this.agreement_.OwnerName = null;
            this.agreement_.VendorId = null;
            this.agreement_.VendorName = null;
            this.fetchVendors();
            this.setClientName();
        }
    }

    @Watch('agreement_.OwnerId')
    onSelectedOwnerChange(val: object, oldVal: object) {
        if (oldVal !== undefined) { // initial load
            this.setOwnerName();
        }
    }

    @Watch('agreement_.VendorId')
    onSelectedVendorChange(val: object, oldVal: object) {
        this.setVendorFields();
    }
    //#endregion Watches

    //#region Methods
    clientOptionsFetched(clients: VamService.UserClients[]) {
        this.clients = clients;
        if (this.agreement_.ClientId > 0) {
            this.setClientName();
        }

    }
    ownerOptionsFetched(owners: VamService.OwnersList[]) {
        this.owners = owners;
        if (this.agreement_.OwnerId > 0) {
            this.setOwnerName();
        }

    }
    async fetchVendors() {
        this.vendors = [];
        const getVendorsParameters = {} as VamService.GetVendorsParameters;
        getVendorsParameters.ClientId = this.agreement_.ClientId;
        getVendorsParameters.IsCharitable = true;
        this.vendors = await this._vam_Service.GetVendors(getVendorsParameters);

        if (this.agreement_.VendorId > 0) {
            this.setVendorFields();
        }
    }

    async fetchCreator() {
        if (this.agreement_.CreateUser) {
            const uo = new SmartObject('UserObject');
            await uo.loadDataFromCompositeKey({
                UserID: this.agreement_.CreateUser
            });
            this.creator = uo.dataObject || {};
        } else {
            this.creator = {};
        }
    }

    async charitableOrganizationSelected(charitableOrganization: TaxService.SearchExemptOrganizations){
        this.selectedCharitableOrganization = charitableOrganization || {} as TaxService.SearchExemptOrganizations;

        //we may use this object even if the vendor already exists (in the case that the VendorClient does not exist, so we would need to push it to the list)
        // so creating it outside the IFs
        const vendor = new VamService.Vendors();
        vendor.Name = charitableOrganization.Name;
        vendor.TaxpayerIdTypeId = 1; //EIN
        vendor.TaxpayerIdType = 'Employer ID Number';
        vendor.TaxpayerIdTypeAbbreviation = 'EIN';
        vendor.TaxpayerIdMask = '99-9999999';
        vendor.TaxpayerId = charitableOrganization.EIN;
        vendor.IsCharitable = true;
        vendor.W9Required = false;
        vendor.TaxpayerIdType = 'Employer ID Number'
        vendor.City = charitableOrganization.City;
        vendor.State = charitableOrganization.State;
        vendor.StreetAddress = charitableOrganization.Street;
        vendor.Zip = charitableOrganization.Zip;
        vendor.Notes = `Created from Charitable Donation, using data from IRS File. ${charitableOrganization.Detail}`;
        vendor.Source = 'IRS File';/* 05-11-2022 09:03 CAMPBED: deprecated in favor of SourceTypeId */
        vendor.SourceTypeId = 40; //Charity Navigator / IRS File

        let vendorClientRecordRequired = false;

        if (charitableOrganization.VendorId){
            this.agreement_.VendorId = charitableOrganization.VendorId;
            vendor.Id = charitableOrganization.VendorId;
            //still need to verify it exists for the Client. 
            const vc = new SmartObject('VendorClient');
            await vc.loadDataFromCompositeKey({
                VendorId: this.agreement_.VendorId
                , ClientId: this.agreement_.ClientId
            });
            vendorClientRecordRequired = !(vc && vc.dataObject && vc.dataObject.Id)
        }
        else { // add it and select it
            this.loading = true;
            try {
                vendor.Id = await new SmartObject('Vendor').createObject(vendor);
                this.$notify.success(`New organization (vendor) created. Id: ${vendor.Id}`);
            }
            catch (err) {
                try {
                    var errJson = JSON.parse(err.responseText);
                    if (errJson.ExceptionMessage.toLowerCase().indexOf('duplicate key') > -1) { 
                        // these errors should not be possible due to the checks above (depending on the error to occur was not working as expected in prod)
                        let keys = null;
                        
                        if (errJson.ExceptionMessage.toLowerCase().indexOf('taxpayerid') > -1) {
                            keys = {
                                TaxpayerId: charitableOrganization.EIN
                            };
                        }
                        else if (errJson.ExceptionMessage.toLowerCase().indexOf('name') > -1) {
                            keys = {
                                Name: charitableOrganization.Name
                            };
                        }
                        else {
                            throw new Error(err);
                        }

                        const v_existing = new SmartObject('Vendor');
                        await v_existing.updateFromCompositeKey({
                            keys: keys,
                            values: {
                                Name: charitableOrganization.Name,
                                TaxpayerIdTypeId: 1, // EIN
                                TaxpayerId: charitableOrganization.EIN,
                                IsCharitable: true                                    
                            }
                        });

                        vendor.Id = v_existing.entityId;
                        this.$notify.success(`Existing organization (vendor) marked as charitable. Id: ${vendor.Id}`);
                    }
                    else {
                        throw new Error(err);
                    }
                }
                catch (err2) {
                    this.$notify.error('An error occurred adding the organization (vendor).');
                }
            }

            vendorClientRecordRequired = true;
            this.agreement_.VendorId = vendor.Id;
            this.vendors.push(vendor);
            //done with adding new
        }
        if (vendorClientRecordRequired) {
            // get ChartOfAccounts
            const at = new SmartObject('AgreementType');
            await at.loadDataFromCompositeKey({
                Name: 'Charitable Donation'
            });

            let coaId = null;
            if (at.dataObject.Configuration) {
                try {
                    coaId = JSON.parse(at.dataObject.Configuration).DefaultAllocationCoAId || null;
                }
                catch {}
            }

            const vendorClient = new VamService.VendorClients();
            vendorClient.ClientId = this.agreement_.ClientId
            vendorClient.VendorId = vendor.Id;
            vendorClient.COAId = coaId;

            try {
                var newVendorClientId = await new SmartObject('VendorClient').createObject(vendorClient);
                this.$notify.success(`Organization added to ${this.agreement_.Client}`);
            }
            catch (err) {
                try {
                    const errJson = JSON.parse(err.responseText);
                    if (errJson.ExceptionMessage.toLowerCase().indexOf('duplicate key') > -1) {
                        const vc = new SmartObject('VendorClient');
                        await vc.loadDataFromCompositeKey({
                            VendorId: vendor.Id,
                            ClientId: this.agreement_.ClientId
                        });

                        // update COAId of existing VendorClient record if it's currently blank
                        if (vc.entityId && !vc.dataObject.COAId) {
                            await vc.updateAttribute('COAId', coaId);
                        }
                    }
                    else {
                        throw new Error(err);
                    }
                }
                catch (err2) {
                    this.$notify.error('An error occurred assigning the organization (vendor) to the selected client.');
                }
            }
        } //vendorClientRecordRequired

        if (charitableOrganization.VendorId && vendorClientRecordRequired){ //if it existed but the vc record did not, it might still have to be pushed to the list
            //check if it's in the list:
            const org = this._common.getSelectedArrayItem(this.vendors, charitableOrganization.VendorId.toString(), 'Id');
            if (!(org && org.Id)){
                this.vendors.push(vendor);
                this.agreement_.VendorId = charitableOrganization.VendorId; //need to set this again because it will not have been retained the first time since it wasn't in the list
            }
        }

        this.showCharitableOrganization = false;
        this.loading = false;
    }
    clientSingleOptionAvailable(selectedClientId: number){
        this.clientIsSingleOption = true;
    }
    setClientName() {
        let selectedClient = null;
        if (this.agreement_.ClientId > 0) {
            selectedClient = this._common.getSelectedArrayItem(
                this.clients,
                this.agreement_.ClientId.toString(),
                'Id'
            );
        }

        if (selectedClient) {
            this.agreement_.Client = selectedClient.Name;
        } else {
            this.agreement_.Client = null;
        }
    }

    setOwnerName() {
        let selectedOwner = null;
        if (this.agreement_.OwnerId > 0) {
            selectedOwner = this._common.getSelectedArrayItem(
                this.owners,
                this.agreement_.OwnerId.toString(),
                'Id'
            );
        }

        if (selectedOwner) {
            this.agreement_.OwnerName = selectedOwner.Name;
            this.ownerCategoryName = selectedOwner.OwnerCategoryName
        } else {
            this.agreement_.OwnerName = null;
            this.ownerCategoryName = null
        }
    }

    setVendorFields() {
        if (this.agreement_.CharitableEINInvalidatedLogId){
            //don't mess with the existing Vendor stuff.  "selectedVendor" won't be set because it's not in the list.  Just leave it alone.
            // also, we don't care about the Charitable status.
        }
        else if (this.selectedVendor) {
            this.agreement_.VendorName = this.selectedVendor.Name;
            this.taxpayerId = this.selectedVendor.TaxpayerId;
            this.fetchCharitableOrganization();
        } else {
            this.agreement_.VendorName = null;
            this.selectedCharitableOrganization = {} as TaxService.SearchExemptOrganizations;
        }
    }

    async fetchCharitableOrganization(){
    this.selectedCharitableOrganization = {} as TaxService.SearchExemptOrganizations;
    const params = {} as TaxService.SearchExemptOrganizationsParameters;
    params.EIN = this.taxpayerId;
    const searchResults = await this._taxService.SearchExemptOrganizations(params);
    if (searchResults && searchResults.length == 1){
      this.selectedCharitableOrganization = searchResults[0];
    }
  }

    async saveCharitableDonation(approvedRejected: string) {
        const form: any = this.$refs.frmCharitableDonationEdit;
        form.validate(async valid => {
            if (valid) {
                let message: string;
                let shouldSendSubmissionEmail: boolean = false;

                this.agreement_.Name =
                    this.agreement_.VendorName + ' ' +
                    this.$dayjs(this.agreement_.Schedule_Date).format('YYYYMMDD');
                this.agreement_.Identifier = this.agreement_.Client;
                this.agreement_.InceptionDate = this.$dayjs(
                    this.agreement_.Schedule_Date
                    ).format('MM/DD/YYYY');
                this.agreement_.TerminationDate = this.$dayjs(
                    this.agreement_.Schedule_Date
                    ).add(7, 'day').format('MM/DD/YYYY'); // terminate 7 days later so it falls off the list.
                this.agreement_.ScheduleType = 'Specific';
                this.agreement_.TypeId = 5; // Charitable Donation

                if (approvedRejected) {
                    this.agreement_.ApprovedRejected = approvedRejected;
                } else if (
                    this.isNew ||
                    this.agreement_.ApprovedRejected === 'Rejected'
                ) {
                    this.agreement_.ApprovedRejected = 'Requested';
                    shouldSendSubmissionEmail = true;
                }

                if (this.isNew) {
                    const at = new SmartObject('AgreementType');
                    await at.loadDataFromCompositeKey({
                        Name: 'Charitable Donation'
                    });

                    let accountId: number = null;
                    let departmentId: number = null;
                    if (at.dataObject.Configuration) {
                        try {
                            accountId = JSON.parse(at.dataObject.Configuration).DefaultAllocationCoAId || null;
                            const agreementGroupConfigurations = JSON.parse(at.dataObject.Configuration).AgreementGroupConfiguration || null;
                            if (agreementGroupConfigurations && agreementGroupConfigurations.length) {
                                const agreementGroupConfiguration = this._common.getSelectedArrayItem(
                                    agreementGroupConfigurations,
                                    this.agreement_.ClientId.toString(),
                                    'ClientId'
                                );
                                if (agreementGroupConfiguration && Object.keys(agreementGroupConfiguration).length && agreementGroupConfiguration.Configuration && agreementGroupConfiguration.Configuration.AgreementGroupId){
                                    this.agreement_.GroupId = agreementGroupConfiguration.Configuration.AgreementGroupId;
                                }
                            }
                            const departmentConfigurations = JSON.parse(at.dataObject.Configuration).DepartmentConfiguration || null;
                            if (departmentConfigurations && departmentConfigurations.length) {
                                const departmentConfiguration = this._common.getSelectedArrayItem(
                                    departmentConfigurations,
                                    this.agreement_.ClientId.toString(),
                                    'ClientId'
                                );
                                if (departmentConfiguration && Object.keys(departmentConfiguration).length && departmentConfiguration.Configuration && departmentConfiguration.Configuration.DepartmentId){
                                    departmentId = departmentConfiguration.Configuration.DepartmentId;
                                }
                            }
                        }
                        catch {
                            console.error('error getting Agreement Type and setting Account/Group');
                        }
                    }
                    
                    const AgreementId = await new SmartObject(
                        'Agreement'
                    ).createObject(this.agreement_);
                    this.agreement_.AgreementId = AgreementId;

                    const f = new SmartObject('Frequency');
                    await f.loadDataFromCompositeKey({
                        Argument1: 'Day',
                        PaymentsPerYear: 1
                    });

                    await new SmartObject('Schedule').createObject({
                        EntityType: 'Agreement',
                        EntityId: AgreementId,
                        Amount: this.agreement_.Schedule_Amount,
                        FrequencyId: f.entityId,
                        SeedDate: this.agreement_.Schedule_Date
                    });

                    await new SmartObject('Allocation').createObject({
                        EntityType: 'Agreement',
                        EntityId: AgreementId,
                        AccountId: accountId,
                        DepartmentId: departmentId,
                        Pct: 1
                    });

                    await this.saveNotes();

                    const a = new SmartObject('Agreement', AgreementId);
                    await a.loadData();
                    this.agreement_.CreateUser = a.dataObject.CreateUser;
                    this.agreement_.CreateDate = a.dataObject.CreateDate;
                    await this.fetchCreator();

                    message = 'Charitable donation agreement added.';
                } else {
                    delete this.agreement_.AccountId; // don't want this sent in updateObject()

                    await new SmartObject(
                        'Agreement',
                        this.agreement_.AgreementId
                    ).updateObject(this.agreement_);

                    if (
                        this.scheduleId > 0 &&
                        (((this as any).veeFields.txtSchedule_Amount || {})
                            .dirty ||
                            ((this as any).veeFields.txtSchedule_Date || {})
                                .dirty)
                    ) {
                        await new SmartObject(
                            'Schedule',
                            this.scheduleId
                        ).updateObject({
                            Amount: this.agreement_.Schedule_Amount,
                            SeedDate: this.agreement_.Schedule_Date
                        });
                    }

                    await this.saveNotes();

                    message = 'Changes saved.';
                }

                this.$notify.success(message);

                if (shouldSendSubmissionEmail) {
                    await this.$nextTick();
                    this.sendSubmissionEmail();
                }

                this.notes = null;

                if (typeof this.onSave === 'function') {
                    this.onSave(this.agreement_);
                }
                this.$emit('saved', this.agreement_);
                this.$emit('update-agreement', this.agreement_);

                // if (this.isNew) {
                //     this.closeDialog();
                // }
            } else {
                this.$message.error('Not Saved.  See Validation Errors.');
                return false;
            }
        });
    }

    async deleteCharitabledDonation() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this charitable donation agreement. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    await new SmartObject(
                        'Agreement',
                        this.agreement_.AgreementId
                    ).deleteObject();

                    this.$notify.success('Charitable donation agreement deleted.');
                    this.$emit('deleted', this.agreement_.AgreementId);
                } catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
            } catch {}
        }
    }

    async approveCharitableDonation() {
        this.agreement_.TerminationDate = this.$dayjs().add(7, 'd').format('MM/DD/YYYY'); // terminate 7 days after approval so it fall off the list
        const bankAccountId: number = this.agreement_.AccountId; // the save deletes that property, but we need it later.
        await this.saveCharitableDonation('Approved');
        const statusId = this.agreement_.CharitableDonationPayBy === 'Wire' ? 310 : 710;
        const params = {} as LiqService.CreateCharitableInvoiceParameters;
        params.CharitableAgreementId = this.agreement_.AgreementId;
        params.StatusId = statusId;
        await this._liq_Service.CreateCharitableInvoice(params);

        this.sendApprovalEmail();
    }

    async rejectCharitableDonation() {
        await this.saveCharitableDonation('Rejected');

        if (this.creator.EmailAddress && this.notes) {
            this.sendRejectionEmail();
            await this.saveNotes();
            this.notes = null;
        }
    }

    async saveNotes() {
        if (this.notes) {
            await new SmartObject('CommentLog').createObject({
                EntityType: 'Agreement',
                EntityId: this.agreement_.AgreementId,
                Comment: this.notes
            });

            this.showCommentLogList = false;
            await this.$nextTick();
            this.showCommentLogList = true;
        }
    }

    async sendSubmissionEmail() {
        this.$notify.info('Sending email...');

        const toAddresses: object = {};
        toAddresses[process.env.VUE_APP_CHARITABLE_EMAIL] = process.env.VUE_APP_CHARITABLE_EMAIL;

        try {
            var result = await $(
                this.$refs.divMainContent_CharitableDonationEdit
            ).exportToEmail({
                toAddresses: toAddresses,
                subject: 'New Charitable Donation Submitted',
                additionalAttributes: {
                    EntityType: 'Agreement',
                    EntityId: this.agreement_.AgreementId,
                    Source: 'Charitable Donation Submission'
                }
            });

            if (result.succeeded) {
                this.$notify.success('Submission email sent.');
            } else {
                console.log('to: ', toAddresses);
                console.error('failed: ', result);
                this.$notify.error('Submission email failed.');
            }
        } catch(ex) {
            console.log('to: ', toAddresses);
            console.error('error: ', ex);
            this.$notify.error('Submission email error.');
        }
    }

    async sendApprovalEmail() {
        this.$notify.info('Sending approval email...');

        const toAddresses: object = {};
        toAddresses[process.env.VUE_APP_CHARITABLE_EMAIL] = process.env.VUE_APP_CHARITABLE_EMAIL;
        if (this.creator.EmailAddress) {
            toAddresses[this.creator.EmailAddress] = this.creator.FirstLast;
        }

        try {
            var result = await $(
                this.$refs.divApproveEmail_CharitableDonationEdit
            ).exportToEmail({
                toAddresses: toAddresses,
                subject:
                    'Charitable Donation ' +
                    this.agreement_.AgreementId +
                    ' Approved',
                additionalAttributes: {
                    EntityType: 'Agreement',
                    EntityId: this.agreement_.AgreementId,
                    Source: 'Charitable Donation Approval'
                }
            });

            if (result.succeeded) {
                this.$notify.success('Approval email sent.');
            } else {
                console.log('to: ', toAddresses);
                console.error('failed: ', result);
                this.$notify.error('Approval email failed.');
            }
        } catch(ex) {
            console.log('to: ', toAddresses);
            console.error('error: ', ex);
            this.$notify.error('Approval email failed.');
        }
    }

    async sendRejectionEmail() {
        this.$notify.info('Sending rejection email...');

        const toAddresses: object = {};
        toAddresses[this.creator.EmailAddress] = this.creator.FirstLast;

        try {
            var result = await $(
                this.$refs.divRejectEmail_CharitableDonationEdit
            ).exportToEmail({
                toAddresses: toAddresses,
                subject:
                    'Charitable Donation ' +
                    this.agreement_.AgreementId +
                    ' Rejected',
                additionalAttributes: {
                    EntityType: 'Agreement',
                    EntityId: this.agreement_.AgreementId,
                    Source: 'Charitable Donation Rejection'
                }
            });

            if (result.succeeded) {
                this.$notify.success('Rejection email sent.');
            } else {
                this.$notify.error('Rejection email failed.');
            }
        } catch {
            this.$notify.error('Rejection email failed.');
        }
    }
    //#endregion Methods
}
