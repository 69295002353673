
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import mixinSchema_workflow from './../DAL/mixinSchema_workflow';
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';
    import ClientSelector from '@/components/form/ClientSelector.vue';
    import CashTransferEdit from '@/views/CashTransfers/CashTransferEdit.vue';
    import uniqBy from 'lodash/uniqBy';
    import alasql from 'alasql';
    import * as XLSX from 'xlsx';
    import Approval from '@/views/Approval/Approval.vue'
    import * as WorkflowService from '../../services/DAL/workflowService';

    alasql['utils'].isBrowserify = false;
    alasql['utils'].global.XLSX = XLSX;
    

    export default Vue.extend({
        name: 'PaymentList'
        , mixins: [mixinSchema_liq, mixinSchema_vam, mixinSchema_workflow, mixinUtility]
        , components: {ClientSelector, CashTransferEdit, Approval}
        , props: {
            params: {
                type: Object
            }
            , approvalId: {
                type: Number
            }
            , securityLevel: {
                type: Number,
                default: null
            }
        }
        , data() {
            return {
                paymentList: []
                , filteredPaymentList: []
                , clientId: null
                , selectedDate: this.$dayjs().add(4, 'week').format('MM/DD/YYYY')
                , accounts: []
                , activeNames: ['payments', 'selection', 'analysis']
                , filter: ''
                , loading: false
                , selectedPayments: []
                , selectedTransfers: []
                , forExcelExport: false
                , selectedClient: {}
                , transfers: []
                , showTransfer: false
                , transferToBankAccountId: null
                , transferToBankAccountLabel: null
                , transferAmount: null
                , clickedTransfer: {}
                , approvalId_: null
                , securityLevel_: this.securityLevel
                , approvalItems: []
                , excludeFutureTransfers: true
                , showApprovalItemRemove: false
                , approvalItemRemovalComment: ''
                , approvalItemToRemove: null
                , approvalItemRemovalNotify: true
                , showApproval: true
                , graphService: {}
                , approver: {} as WorkflowService.ApprovalApprovers
            }
        }
        , created: async function () {
            this.graphService = new MicrosoftGraphService();
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(
                    getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP),
                    0
                );
            }
            if (this.approvalId) {
                this.approvalId_ = this.approvalId;
                await this.fetchApprovalItems();
                this.fetchPaymentList();
            }
            //this.fetchPaymentList(); removing because clientId is not set at this point; it runs again when ClientId watch is triggered.  But do call it if approvalId
            this.fetchAccounts();
            this.fetchTransfers();
        }
        , computed: {
            approverLink(){
                const route = this.$router.resolve({name: 'ApprovalList', params: {approvalId: this.approver.ApprovalId}});
                const absoluteUrl = new URL(route.href, window.location.origin).href;
                return absoluteUrl;
            }
            , windowHeight() {
                return window.innerHeight - 265;
            }
            , selectedItems() {
                const payments = this.selectedPayments.map(payment => (
                    {
                        EntityType: payment.KeyEntityType,
                        EntityId: payment.KeyEntityId
                    }
                ));
                if (!!this.selectedTransfers && this.selectedTransfers.length){
                    const cashTransfers = this.selectedTransfers.map(transfer => (
                        {
                            EntityType: 'CashTransfer',
                            EntityId: transfer.Id
                        }
                    ));                
                    return payments.concat(cashTransfers);
                }
                else {
                    return payments;
                }
            }
            , approvalResponseReceived(){
                if (this.$refs.approval){
                    return this.$refs.approval.responseReceived;
                }
                else return false;
            }
            , allocationAnalysis(){
                if (this.selectedPayments.length ){ 
                    const res = alasql( //"+ 500" in Transfer is to have the effect of "Rounding up to nearest Thousand."
                        ' with \
                        balance as (select * from ?) \
                        , fromTransfers as \
                            (select t.FromBankAccountId as BankAccountId, sum(Amount) Amount \
                            from ? as t \
                            group by t.FromBankAccountId \
                            having sum(Amount) <> 0 \
                            ) \
                        , toTransfers as \
                            (select t.ToBankAccountId as BankAccountId, sum(Amount) Amount \
                            from ? as t \
                            group by t.ToBankAccountId \
                            having sum(Amount) <> 0 \
                            ) \
                        , payments as ( \
                                SELECT BankAccountId \
                                , SUM(Amount) AS TotalPayments \
                                FROM ? as payments \
                                GROUP BY BankAccountId \
                                )\
                        , driver as (\
                            select distinct BankAccountId from payments \
                            union \
                            select distinct BankAccountId from fromTransfers \
                            union \
                            select distinct BankAccountId from toTransfers \
                            ) \
                        , calc as ( \
                            select \
                             balance.Client \
                             , balance.BankNameNumberMasked \
                             , balance.Cash \
                             , balance.Available \
                             , balance.AlertMinimumBalance \
                             , balance.AlertMinimumBalance0 \
                             , p.TotalPayments \
                             , balance.OwnerId \
                             , s.BankAccountId \
                            ,  coalesce(balance.Available, 0) - coalesce(p.TotalPayments, 0) AS Ending \
                            ,  coalesce(balance.Available, 0) - coalesce(p.TotalPayments, 0) + coalesce(ttrans.Amount, 0) - coalesce(ftrans.Amount, 0) AS EndingWithTransfers \
                            , ttrans.Amount as TransferIn \
                            , ftrans.Amount as TransferOut \
                            , ftrans.BankAccountId as ftransBankAccountId \
                            , ttrans.BankAccountId as ttransBankAccountId \
                            from driver s \
                            left join balance on balance.BankAccountId = s.BankAccountId \
                            left join payments as p on p.BankAccountId = s.BankAccountId \
                            left join fromTransfers as ftrans on ftrans.BankAccountId = s.BankAccountId \
                            left join toTransfers as ttrans on ttrans.BankAccountId = s.BankAccountId \
                            ) \
                        select *,  \
                        case when EndingWithTransfers < AlertMinimumBalance0 then Round((0-(EndingWithTransfers)) + AlertMinimumBalance0 + 500, -3) end as Transfer \
                        from calc \
                        order by Client, BankNameNumberMasked'
                        , [this.accounts, this.transfers, this.transfers, this.selectedPayments]);
                    return res;
                }
                else
                return [];
            }
        }
        , watch: {
            'selectedDate': function (val, oldVal) {
                this.fetchPaymentList();
            }
            , 'excludeFutureTransfers': function (val, oldVal) {
                this.fetchTransfers();
            }
            , 'clientId': function (val, oldVal) {
                this.fetchPaymentList();
                this.fetchTransfers();
            }
            , 'filter': function (val, oldVal) {
                //this.filteredPaymentList = this.paymentList.filter(function (e) {
                //    return e.Client.toLowerCase().indexOf(val.toLowerCase()) !== -1
                //        || e.Owner.toLowerCase().indexOf(val.toLowerCase()) !== -1
                //        || e.Description.toLowerCase().indexOf(val.toLowerCase()) !== -1;
                //});
                this.filteredPaymentList = this.paymentList.filter(function (e) {
                    return ((e.Client) ? e.Client.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.Owner) ? e.Owner.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.EntityType) ? e.EntityType.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.EntityTypeDisplay) ? e.EntityTypeDisplay.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.EntityId) ? e.EntityId.toString().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.ScheduleDate) ? this.$dayjs(e.ScheduleDate).format('MM/DD/YYYY HH:mm:ss').indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.Amount) ? e.Amount.toString().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.Description) ? e.Description.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.ScheduleType) ? e.ScheduleType.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.VendorInvoiceNumber) ? e.VendorInvoiceNumber.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.DueDate) ? this.$dayjs(e.DueDate).format('MM/DD/YYYY HH:mm:ss').indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.StatusText) ? e.StatusText.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.Notes) ? e.Notes.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.FileId) ? e.FileId.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.ReferenceName) ? e.ReferenceName.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.ReferenceEntityId) ? e.ReferenceEntityId.toString().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.ReferenceEntityType) ? e.ReferenceEntityType.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.OrderDate) ? this.$dayjs(e.OrderDate).format('MM/DD/YYYY HH:mm:ss').indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.EntityURL) ? e.EntityURL.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.ReferenceURL) ? e.ReferenceURL.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        ;
                    });
            }

        }
        , methods: {
            fetchPaymentList: async function () {
                this.loading = true;
                this.$setSystemStatus('Getting Payments');
                this.paymentList = await this.liq_GetScheduledPayments({
                    ClientId: this.clientId,
                    ToDate: this.selectedDate,
                    ApprovalId: this.approvalId_
                });
                this.filteredPaymentList = this.paymentList;
                this.filter = '';
                if (this.paymentList.length && !!this.approvalId_ && !!this.approvalItems && this.approvalItems.length){
                    await this.$nextTick(); // otherwise, the table doesn't yet exist
                    this.$setSystemStatus('Setting Selected Payments');
                    const matchFunction = function(row, item) {
                        return row.KeyEntityType == item.EntityType && row.KeyEntityId == item.EntityId; 
                    }
                    this.setSelectedItems(this.paymentList, this.$refs.tblPayments, matchFunction);
                }
                this.$setSystemStatus('');
                this.loading = false;
            }
            ,fetchAccounts: async function () {
                // this is not called again when the Client changes.  We are only going to show accounts that correspond to selected payments, so no need to get it again for a specific client
                this.loading = true;
                this.$setSystemStatus('Getting Account Balances');
                this.accounts = await this.liq_GetAccounts(); // get all of them.  don't use liq_GetBankAccountList - need to have Balance
                this.$setSystemStatus('');
                this.loading = false;
            }
            , async fetchTransfers(){
                this.loading = true;
                this.$setSystemStatus('Getting Transfers');
                this.transfers = await this.liq_GetCashTransfers({
                    IncludeInBalanceOnly: true
                    , ExcludeFuture: this.excludeFutureTransfers
                    , ClientId: this.clientId
                    , ApprovalId: this.approvalId_
                });
                if (this.transfers.length && !!this.approvalId_ && !!this.approvalItems && this.approvalItems.length){
                    await this.$nextTick(); // otherwise, the table doesn't yet exist
                    this.$setSystemStatus('Setting Selected Transfers');
                    const matchFunction = function(row, item) {
                        return 'CashTransfer' == item.EntityType && row.Id == item.EntityId; 
                    }
                    this.setSelectedItems(this.transfers, this.$refs.tblTransfers, matchFunction);
                }
                this.$setSystemStatus('');
                this.loading = false;
            }
            , OpenFile: async function (fileId, driveId) {
                const graphClient = await this.graphService.getGraphClient();
                const fileProperties = await graphClient.api(`/drives/${driveId}/items/${fileId}`).get();
                if (fileProperties && fileProperties.webUrl) {
                    window.open(fileProperties.webUrl, '_blank');
                    this.$notify.success('File opened in new tab');
                }
            }
            , filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            }
            , filters(column){
                const filters = this.filteredPaymentList.map(function(list) {
                    return {
                        text: list[column]
                        , value: list[column]
                        };
                });
                return uniqBy(filters, 'value');
            }
            , handleSelectionChange(val) {
                this.selectedPayments = val;
            }
            , handleTransferSelectionChange(val) {
                this.selectedTransfers = val;
            }
            , async exportToExcel() {
                this.forExcelExport = true;
                this.$refs.tblPayments.doLayout();
                await this.$nextTick(); // let the view refresh before exporting
                this.loading = true;
                ($(this.$refs.tblPayments.$el).parent() as any).exportToExcel({
                    filename: 'Payments  - ' +
                        (this.selectedClient.Name || 'All Clients') + ' ' +
                        (this.selectedDate ? 
                            this.$dayjs(this.selectedDate).format('YYYY-DD-MM') 
                            : 'All Dates'
                        ) 
                });
                this.forExcelExport = false;
                this.$refs.tblPayments.doLayout();
                await this.$nextTick();
                this.loading = false;
            }
            , async exportToExcelSelected() {
                if (!this.selectedPayments.length){
                    this.$notify.warning('No Payments Selected, so nothing exported');
                }
                else {
                    this.loading = true;
                    const selectedPayments = this.selectedPayments.map((payment) => (
                        {
                            Client: payment.Client
                            , Owner: payment.Owner
                            , VendorName: payment.VendorName
                            , EntityType: payment.EntityTypeDisplay
                            , EntityId: payment.EntityId
                            , ScheduleDate: payment.ScheduleDate
                            , Amount: payment.Amount
                            , Description: payment.Description
                            , ScheduleType: payment.ScheduleType
                            , VendorInvoiceNumber: payment.VendorInvoiceNumber ? payment.VendorInvoiceNumber : ''
                            , DueDate: payment.DueDate ? payment.DueDate : ''
                            , ProcessingAdjustment: payment.ProcessingAdjustment ? payment.ProcessingAdjustment : ''
                            , DateToProcess: payment.DateToProcess ? payment.DateToProcess : ''
                            , BankNameNumberMasked: payment.BankNameNumberMasked
                            , DefaultPaymentMethod: payment.DefaultPaymentMethod ? payment.DefaultPaymentMethod : ''
                            , DefaultPaymentMethodMissing: payment.DefaultPaymentMethodMissing
                        }));
                    const allocationAnalysis = this.allocationAnalysis.map((aa) => (
                        {
                            Client: aa.Client
                            , BankNameNumberMasked: aa.BankNameNumberMasked
                            , Cash: aa.Cash
                            , Available: aa.Available
                            , Minimum: aa.AlertMinimumBalance ? aa.AlertMinimumBalance : ''
                            , TotalPayments: aa.TotalPayments
                            , Ending: aa.Ending
                            , Transfer: aa.Transfer
                        }));
                    const opts = [{sheetid:'Selected Payments',header:true},{sheetid:'Summary',header:true}];
                    
                    await alasql.promise('SELECT INTO XLSX("Selected Payments and Summary.xlsx",?) FROM ?',[opts,[selectedPayments,allocationAnalysis]]);
                    this.loading = false;
                }
            }
            , async createTransfer(summaryRow){
                this.transferToBankAccountId = summaryRow.BankAccountId;
                this.transferToBankAccountLabel = summaryRow.BankNameNumberMasked;
                this.transferAmount = summaryRow.Transfer;
                this.showTransfer = true;
            }
            , async transferUpdated(cashTransfer){
                if (!this.clickedTransfer || !this.clickedTransfer.Id){ // if Transfer is new
                    this.transfers.push(cashTransfer);
                    await this.$nextTick();
                    this.$refs.tblTransfers.toggleRowSelection(cashTransfer, true);
                }
                else {
                    const index = this.transfers.indexOf(this.clickedTransfer);
                    this.transfers.splice(index, 1, cashTransfer);
                    this.clickedTransfer = {}
                }
                this.showTransfer = false;
                //this.$forceUpdate();
                this.clickedTransfer = {};
            }
            , async transferRowClicked(selectedRow, column, event ){
                this.clickedTransfer = selectedRow;
                this.showTransfer = true;
            }
            , cashTransferDeleted(cashTransferId){
                if (!!this.clickedTransfer && this.clickedTransfer.Id == cashTransferId){
                    let index = this.transfers.indexOf(this.clickedTransfer);
                    this.transfers.splice(index, 1);
                    this.showTransfer = false;
                    this.clickedTransfer = {};
                    
                    index = this.selectedTransfers.indexOf(this.clickedTransfer);
                    this.selectedTransfers.splice(index, 1);
                }
            }
            , async approvalSaved(approvers){
                //because single-approver = true, there can only be one approvers.  So send the approval email for that approver.
                if (approvers && approvers.length == 1)
                {
                    this.approver = approvers[0];
                    console.log(this.approver);
                    await this.$nextTick();
                    const toAddresses = {};
                    toAddresses[this.approver.Email] = this.approver.Email;

                    const body = this.$refs.divApprovalEmail;

                    try {
                        const result = await ($(body) as any).exportToEmail({
                            toAddresses: toAddresses,
                            subject: `[Action Required] Approval Requested: ${this.approver.WorkflowType} (${this.approver.ApprovalId})`,
                            additionalAttributes: {
                                EntityType: 'Approval',
                                EntityId: this.approver.ApprovalId,
                                Source: 'PaymentList'
                            }
                        });

                        if (result.succeeded) {
                            this.$notify.success('Approval email sent.');
                        } else {
                            console.log('error', result);
                            this.$notify.error('Approval email failed.');
                        }
                    } catch (err) {
                        console.log(err);
                        this.$notify.error('Approval email failed.');
                    }
                }
                else {
                    this.$notify.warning('Approver not set; Email not sent.')
                }
            }
            , approvalEmailSent(emailResponse){
                if (!!emailResponse && Object.keys(emailResponse).length && emailResponse.data > 0){
                    this.$notify.success(`${emailResponse.data} Approval Email ${emailResponse.data>1 ? 's' : ''} sent`);
                }
                else {
                    this.$notify.success('No Approval Emails sent');
                }
            }
            , async fetchApprovalItems(){
                this.loading = true;
                this.$setSystemStatus('Getting Approval Items');
                this.approvalItems = await this.workflow_GetApprovalItems({
                    ApprovalId: this.approvalId_
                });
                this.$setSystemStatus('');
                this.loading = false;
            }
            , setSelectedItems(rows, table, matchFunction){
                // loop through (map) ApprovalItems, find matching Payments/Transfers, set those to selected
                this.$setSystemStatus('Setting Selected Items');
                this.approvalItems.map((item) => { 
                    // let matchingRow = rows.find(function(row) {
                    //     return row.KeyEntityType || row.EntityType == item.EntityType && row.KeyEntityId || row.EntityId == item.EntityId; //Transfers will not have "KeyEntity*", just Entity*
                    // }); 
                    const matchingRow = rows.find(function(row) {
                        return matchFunction(row, item);
                    }); 
                    if (!!matchingRow && Object.keys(matchingRow).length){
                        table.toggleRowSelection(matchingRow, true);
                    }
                });
                this.$setSystemStatus('');
            }
            , selectableItem(row){
                if (this.approvalId_) return false;
                return row.Selectable;
            }
            , rowStyle(row, rowIndex){
                if (!!this.approvalId_ 
                    && !!this.paymentList 
                    && this.paymentList.length
                    && !!this.$refs.tblPayments
                    && !!this.$refs.tblPayments.selection
                    && this.$refs.tblPayments.selection.length
                    ){
                    const found = this.$refs.tblPayments.selection.find(function(selection){
                        return selection.KeyEntityType == row.row.KeyEntityType && selection.KeyEntityId == row.row.KeyEntityId;
                    });
                    if(found){
                        return {'background-color': 'yellow'};
                    }
                }
            } 
            , rowStyleTransfer(row, rowIndex){
                if (!!this.approvalId_ 
                    && !!this.transfers 
                    && this.transfers.length
                    && !!this.$refs.tblTransfers
                    && !!this.$refs.tblTransfers.selection
                    && this.$refs.tblTransfers.selection.length
                    ){
                    const found = this.$refs.tblTransfers.selection.find(function(selection){
                        return selection.Id == row.row.Id;
                    });
                    if(found){
                        return {'background-color': 'yellow'};
                    }
                }
            }
            , fnEntityURL(approvalId){
                if (!approvalId) return;
                return `https://vam.vinitaspartners.com/Treasury/PaymentList/${approvalId.toString()}`;
            }
            , showRemoveApprovalItem(approvalItemId){
                this.approvalItemToRemove = approvalItemId;
                this.showApprovalItemRemove = true;
            }
            , async removeApprovalItem(){
                console.log(this.approvalItemToRemove, this.approvalItemRemovalComment, this.approvalItemRemovalNotify);
                try{
                    const paymentItem = this.getSelectedArrayItem(
                              this.paymentList,
                              this.approvalItemToRemove,
                              "ApprovalItemId"
                            );
                    await this.liq_RejectScheduledPayment({
                        ApprovalItemId: this.approvalItemToRemove
                        , Comment: this.approvalItemRemovalComment
                        , NotifyBatchCreator: this.approvalItemRemovalNotify
                    });
                    this.paymentList.splice(this.paymentList.indexOf(paymentItem), 1); //remove from list
                    this.$notify.success('Item removed');
                    this.showApproval = false;
                    await this.$nextTick();
                    this.showApproval = true; // refresh approval to show added comment
                }
                catch (err) {
                    this.$notify.error(err.message);
                }
                this.approvalItemToRemove = null;
                this.approvalItemRemovalComment = '';
                this.showApprovalItemRemove = false;
                this.approvalItemRemovalNotify = true;
            }
            , cancelRemoveApprovalItem(){
                this.approvalItemToRemove = null;
                this.approvalItemRemovalComment = '';
                this.showApprovalItemRemove = false;
                this.approvalItemRemovalNotify = true;
            }
        }
    })
