
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import KeyPersonEdit from '@/views/KeyPerson/KeyPersonEdit.vue';
import * as VamService from '@/services/DAL/vamService';
import Common from '@/utils/common';
import ElementUI from 'element-ui';
import { boolean } from 'mathjs';

declare var SmartObject: any;
declare var $: any;

// 11-03-2021 12:00 CAMPBED: add showAddNew Prop; Default true
// 05-17-2022 12:41 CAMPBED: add value-set emit

@Component({
    inheritAttrs: false,
    components: {
        KeyPersonEdit
    }
})
export default class KeyPersonSelector extends Vue {
    $refs: {
        ddlKeyPersonSelect: ElementUI.Select,
        btnAddKeyPerson: ElementUI.Button
    }
    //#region Data
    private _vam_Service: VamService.VamService;
    private _common: Common;

    @Prop({ type: Number }) readonly value: number;
    @Prop({ type: Number }) readonly clientId: number;
    @Prop({ type: Boolean, default: false }) readonly hasEmailAddress: boolean;
    @Prop({ type: Boolean, default: false }) readonly requireClientId: boolean
    @Prop({ type: Boolean, default: true }) readonly showAddNew: boolean;
    @Prop({ type: Boolean, default: false }) readonly isApproverOnly: boolean;
    @Prop( {type: Function}) readonly optionDisabledFunction: Function;

    keyPersons = [] as VamService.KeyPersons[];
    selectedValue: number = null;
    showKeyPersonEdit: Boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._vam_Service = new VamService.VamService();
        this._common = new Common();
    }
    async mounted() {
        this.selectedValue = this.value;
        this.fetchKeyPersons();

        // get actual button width, and reset width of select to fill remaining space next to button
        if (this.showAddNew && this.$refs.btnAddKeyPerson && this.$refs.ddlKeyPersonSelect) {
            const buttonWidth = $(this.$refs.btnAddKeyPerson.$el).outerWidth() || 44;
            $(this.$refs.ddlKeyPersonSelect.$el).css('width', 'calc(100% - ' + (buttonWidth + 6) + 'px)');
        }
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;

        if (this.keyPersons.length) {
            let selectedObject: VamService.KeyPersons = new VamService.KeyPersons();
            if (this.selectedValue) {
                selectedObject = this._common.getSelectedArrayItem(
                    this.keyPersons,
                    this.selectedValue.toString(),
                    'Id'
                );
            }
            this.$emit('value-set', selectedObject);
        }
    }
    @Watch('clientId')
    onClientChange(val: number, oldVal: number) {
        this.fetchKeyPersons();
    }
    //#endregion Watches

    //#region Methods
    async fetchKeyPersons() {
        this.keyPersons = [];
        if (!this.requireClientId || !!this.clientId){
            const params = {} as VamService.GetKeyPersonsParameters;
            params.ClientId = this.clientId;
            params.HasEmailAddress = this.hasEmailAddress;
            params.IsApproverOnly = this.isApproverOnly;
            this.keyPersons = await this._vam_Service.GetKeyPersons(params);
        }
        this.$emit('options-fetched', this.keyPersons);
        //emit value-set on initiation, if there is a value.  Won't happen from Watcher
        if (this.value && this.keyPersons && this.keyPersons.length){
            this.$emit('value-set', this.keyPersons.find(nk => nk.Id == this.value));
        }

    }

    async onSaveNewKeyPerson(KeyPersonId: number) {
        await this.fetchKeyPersons();
        this.$emit('input', KeyPersonId);
    }
    //#endregion Methods
}
