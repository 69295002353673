// export class PersonAndTitle{
//     name: string;
//     title: string;
//     constructor(name: string, title: string){
//         this.name = name;
//         this.title = title;
//     }
// }

// export class CompanyPurposePeople{
//     company: string;
//     purpose: string;
//     people: PersonAndTitle[];
//     constructor(company: string, purpose: string, people: PersonAndTitle[]){
//         this.company = company;
//         this.purpose = purpose;
//         this.people = people;
//     }
// }

export class VisitedPerson{
    Id: number;
    PurposeCompanyId: number;
    Name: string;
    Title: string;
    Modified: string;
}