
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import HoldingEntityEdit from '@/views/HoldingEntity/HoldingEntityEdit.vue';
import ElementUI from 'element-ui';
declare var $: any;

@Component({
    inheritAttrs: false
    , components: {
        HoldingEntityEdit,
    }
})
export default class HoldingEntitySelector extends Vue {
    $refs: {
        ddlEntitySelector: ElementUI.Select
        , btnAddEntity: ElementUI.Button
    }
//#region Private declarations for Services
    private _asset_Service: AssetService.AssetService;

//#endregion Private declarations for Services

//#region Props
    @Prop()
    value: number;
    @Prop()
    clientId: number;
    @Prop({
        default: true
    })
    appendDialogsToBody: boolean;
//#endregion Props

//#region Data
    entities = [] as AssetService.HoldingEntities[];
    selectedValue: number = null;
    showHoldingEntityEdit: boolean = false;
//#endregion Data

    //#region Computed
    //#endregion Computed

   //#region Lifecycle
    async created() {
        this._asset_Service = new AssetService.AssetService();
    }
    async mounted(){
        // get actual button width, and reset width of select to fill remaining space next to button
        const buttonWidth = $(this.$refs.btnAddEntity.$el).outerWidth();
        $(this.$refs.ddlEntitySelector.$el).css('width', 'calc(100% - ' + (buttonWidth + 6) + 'px)');

        this.selectedValue = this.value;
        this.fetchHoldingEntities();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onEntityChange(val: number, oldVal: number) {
        this.selectedValue = val;
    }
    @Watch('clientId')
    onClientChange(val: number, oldVal: number) {
        this.fetchHoldingEntities();
    }

    //#endregion Watches

//#region Methods
    async fetchHoldingEntities() {
        this.entities = [];
        if (this.clientId){
            const params = {} as AssetService.GetHoldingEntitiesParameters;
            params.ClientId = this.clientId;
            this.entities = await this._asset_Service.GetHoldingEntities(params);
        }
        this.$emit('options-fetched', this.entities);
        if (this.entities.length == 1){
            this.value = this.entities[0].Id;
        }
    }
    async onSaveNewHoldingEntity(HoldingEntityId: number) {
        await this.fetchHoldingEntities();
        this.$emit('input', HoldingEntityId);
    }

    //#endregion Methods
}
