
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import formatters from "@/utils/formatters";
import Common from "@/utils/common";
import * as TransactionsService from "@/services/DAL/transactionsService";
import ConsiderationList from '@/views/InvestmentTransaction/Consideration/ConsiderationList.vue';
import InvestmentParentSelector from "@/components/form/InvestmentParentSelector.vue";
import InvestmentSelector from "@/components/form/InvestmentSelector.vue";
import OwnerSelector from "@/components/form/OwnerSelector.vue";
import cloneDeep from "lodash/cloneDeep";
import { Field, FieldBag } from "node_modules/vee-validate/types";
import * as AssetService  from "@/services/DAL/assetService";
import CommentLogList from '@/components/other/tsCommentLogList.vue'
import VueMarkdown from '@adapttive/vue-markdown'
import debounce from 'lodash/debounce';
import ElementUI from "element-ui";
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;
declare var SmartObject: any;

interface balanced {
  isBalanced: boolean
      , message: string
      , source: number
      , result: number
      , netDistribution: number
      , showValues: boolean
}

class distributionItems extends TransactionsService.DistributionItems{
  Comment?: string= undefined;
}
@Component({
  components: {
    InvestmentSelector,
    InvestmentParentSelector,
    OwnerSelector,
    ConsiderationList,
    CommentLogList,
    //@ts-ignore
    VueMarkdown
  },
})
export default class Escrow extends Vue {
  @Ref() refConsiderationListResult!: ConsiderationList;
  @Ref() readonly inputSelectedEquityAmount!: ElementUI.Input;
  @Ref() readonly inputSelectedEquityAmountPerQuantity!: ElementUI.Input;
  @Ref() readonly frmEscrow!: ElementUI.Form;
  //#region Private declarations for Services
  private _transactionsService: TransactionsService.TransactionsService;
  private _assetService: AssetService.AssetService;
  public formatters: formatters;
  private common: Common;
  //#endregion Private declarations for Services

  //#region Props
  @Prop({ type: Object }) header: TransactionsService.Header;
  @Prop({ type: Number }) transactionHeaderId: number;
  //#endregion Props

  //#region Data
  loading = false;
  securityLevel_: number = null;

  investmentParent = {} as AssetService.InvestmentParentList;
  distributionInvestments = [] as TransactionsService.DistributionInvestments[];
  distributionItems = [] as distributionItems[];
  isNew = false;
  header_ = {} as TransactionsService.Header;
  cachedHeader = {} as TransactionsService.Header;
  creating = true;
  showHeaderNotes = false;
  getDistributionInvestments: any = null;
  totalShares: number = null;
  //#endregion Data

  //#region Lifecycle
  async created() {
    this.formatters = new formatters();
    this.common = new Common();
    this._transactionsService = new TransactionsService.TransactionsService();
    this._assetService = new AssetService.AssetService();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
      0
    );
    this.getDistributionInvestments = debounce(this.getDistributionInvestmentsDebounced, 500);
  }
  async mounted() {
    if (!this.header || Object.keys(this.header).length == 0) {
      await this.getTransactionHeader();
    } else {
      this.header_ = cloneDeep(this.header);
      this.cachedHeader = cloneDeep(this.header);
    }
    if (!this.header_.BaseInvestmentParentId && this.header_.BaseInvestmentId){
      await this.getInvestmentParentForNewTransaction();
    }
    await this.$nextTick();

    await this.getDistributionInvestments();
    
    this.creating = false;
    this.$emit('loaded', this.balanced.isBalanced);
  }
//#endregion Lifecycle

  //#region Watches
  @Watch("header", {deep: true})
  async headerChanged(
    val: TransactionsService.Header,
    oldVal: TransactionsService.Header
  ) {
    this.header_ = cloneDeep(this.header);
    
  }
  
  @Watch("header_", {deep: true})
  async header_Changed(val: TransactionsService.Header, oldVal: TransactionsService.Header) {
    this.getDistributionInvestments();
  }
  //#endregion Watches

  //#region Computed
  get headerIsDirty(): boolean {
    if (this.header_ && this.cachedHeader &&
        (
          this.header_.BaseOwnerId != this.cachedHeader.BaseOwnerId
        ||
          this.header_.BaseInvestmentParentId != this.cachedHeader.BaseInvestmentParentId
        ||
          this.header_.BaseInvestmentId != this.cachedHeader.BaseInvestmentId
        )
    ){
      return true;
    }
    else return false;
  }
  get escrowFields(): Object{
    return Object.keys(((this as any).veeFields as Array<Field>))
      .filter(key => key.startsWith('escrow.'))
      .reduce((obj, key) => {
      obj[key] = ((this as any).veeFields as Array<Field>)[key];
      return obj;
    }, {});
 }

  get isFormValid(): boolean {
    const formValid = !Object.keys((this as any).veeFields).some(
      (key) => (this as any).veeFields[key].invalid
    );
    if (!formValid) return formValid;

    return true;
  }

  get isDistributionItemsFormDirty(): boolean {
      const fields =  Object.keys(this.escrowFields).some(
          key => this.escrowFields[key].dirty
      );
      if (fields) return fields;
      else return false;
  }
  get isDistributionItemsFormValid(): boolean{
      return !(Object.keys(this.escrowFields).some(
          key => this.escrowFields[key].invalid
      ));
  }

  get distributionTotal(): number {
    if (this.escrow){
      return this.escrow.Amount || (this.escrow.AmountPerQuantity * this.escrow.Quantity);
    }
    else return 0;
  }

  get balanced(): balanced{
    const result: number = this.refConsiderationListResult ? this.refConsiderationListResult.totalConsideration : 0;
    const source: number = this.distributionTotal;
    const netDistribution: number = this.header_.NetAmount;
    const isBalanced: boolean = this.$accounting.toFixed(result, 2) == this.$accounting.toFixed(source, 2)  && this.$accounting.toFixed(source, 2) == this.$accounting.toFixed(netDistribution, 2);
    const message = this.creating ? '' : isBalanced ? 'Balanced' : 'Not Balanced';
    return {
      isBalanced
      , message
      , source
      , result
      , netDistribution
      , showValues: !isBalanced && !(result ==0 && source == 0)
    }
  }

  get escrow(): TransactionsService.DistributionItems {
    if (this.distributionItems && this.distributionItems.length == 1) return this.distributionItems[0];
    else {
      const di = {} as TransactionsService.DistributionItems;
      di.DistributionTypeId = 12;
      di.DistributionType = 'Escrow'
      di.TransactionHeaderId = this.header_.Id;
      return di;
    }
  }

  //#endregion Computed

  //#region Methods
  async updateHeader(){
    if (this.headerIsDirty){
      await new SmartObject('InvestmentTransactionHeader', this.header_.Id).updateObject(
                    this.header_
                );
      this.$notify.success('Transaction Header Updated');
      this.cachedHeader = cloneDeep(this.header_);
    }
  }
  async getInvestmentParentForNewTransaction(){
    if (this.header_.BaseInvestmentId && !this.header_.BaseInvestmentParentId){
      const params = {} as AssetService.GetInvestmentListParameters;
      params.InvestmentId = this.header_.BaseInvestmentId;
      const investments = await this._assetService.GetInvestmentList(params);
      if (investments && investments.length == 1){
        this.header_.BaseInvestmentParentId = investments[0].InvestmentParentId;
        if (this.header_ && !this.header_.BaseOwnerId){
          this.header_.BaseOwnerId = investments[0].OwnerId;
        }
      }
    }
  }
  async getDistInvestments(updateCommitment: boolean = false){
      const params = {} as TransactionsService.GetDistributionInvestmentsParameters;
      params.TransactionHeaderId = this.header_.Id;
      params.Update = updateCommitment;
      this.distributionInvestments = await this._transactionsService.GetDistributionInvestments(
        params
      );
  }
  async getDistributionInvestmentsDebounced() {
    if (this.header_ && this.header_.Id && this.header_.BaseInvestmentParentId) {
        this.distributionInvestments = [];
        if (this.investmentParent && !this.investmentParent.FundFamilyRequired && !this.header_.BaseInvestmentId){
          return;
        }
        this.loading = true;
        await this.updateHeader(); // only updates if dirty
        await this.getDistInvestments();
        this.isNew = this.distributionInvestments.length == 0; // this only means it got rows back, which means that it found or inserted a DistributionHeader. isNew only controls the readOnly for Owner and IP.
        if (this.distributionInvestments.length) {
          await this.getDistributionItems();
        }

        this.$validator.reset();
        this.loading = false;
      }
      
      // after above step, either investmentParentId and ownerId were already set, or they got set.  If not, then it's new.
      if (this.header_ && this.header_.Id && !(this.header_.BaseInvestmentParentId && this.header_.BaseOwnerId)) {
        this.isNew = true;
      }
  }
  async getDistributionItems() {
    const params = {} as TransactionsService.GetDistributionItemsParameters;
    params.TransactionHeaderId = this.header_.Id;
    this.distributionItems = await this._transactionsService.GetDistributionItems(
      params
    );
    this.$emit('loaded', this.balanced.isBalanced);
  }

  async getTransactionHeader() {
    this.header_ = {} as TransactionsService.Header;
    if (this.transactionHeaderId) {
      const params = {} as TransactionsService.GetHeaderParameters;
      params.HeaderId = this.transactionHeaderId;
      this.header_ = (await this._transactionsService.GetHeader(params))[0];
      this.cachedHeader = cloneDeep(this.header_);
    }
  }

  clearItem() {}
  async deleteItem() {
    // not yet implemented
  }
  async saveItem() {
    if (this.escrow.Id){
      await new SmartObject('DistributionItem', this.escrow.Id).updateObject(
        this.escrow
      );
      this.$notify.success('Escrow updated');
    }
    else {
      const newId = await new SmartObject('DistributionItem').createObject(this.escrow)
      this.escrow.Id = newId;
      this.distributionItems.push(this.escrow);
      await this.$nextTick();
      this.$notify.success('Escrow created');
    }
    this.$emit('saved', this.balanced.isBalanced)
  }
  investmentParentSet( investmentParent: AssetService.InvestmentParentList){
    this.investmentParent = investmentParent;
    if (this.investmentParent && this.investmentParent.Id){
      this.header_.BaseInvestmentParentId = this.investmentParent.Id;
      this.getDistributionInvestments();
    }
  }

//#endregion Methods
}

