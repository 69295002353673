
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as VamService from '../../services/DAL/vamService';
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component
export default class AddressEdit extends Vue {
  $refs: {
    frmAddressEdit: ElementUI.Form
  }
  //#region Data
  private _vam_Service: VamService.VamService;

  @Prop() address: VamService.Addresses;
  @Prop() onClose: Function;
  @Prop() onSave: Function;
  @Prop() onDelete: Function;
  @Prop() viewType: string;

  address_: VamService.Addresses = new VamService.Addresses();
  states = [];

  get isNew(): boolean {
    return !this.address || !Object.keys(this.address).length;
  }
  get isFormDirty(): boolean {
    return Object.keys((this as any).veeFields).some(
      key => (this as any).veeFields[key].dirty
    );
  }
  //#endregion Data

  //#region Lifecycle
  async created() {
    if (this.address && Object.keys(this.address).length) {
      this.address_ = this.address;
    }
    this._vam_Service = new VamService.VamService();
    this.fetchStates();
  }
  //#endregion Lifecycle

  //#region Methods
  async fetchStates() {
    this.states = [];
    const getStatesParameters = {} as VamService.GetStatesParameters;
    getStatesParameters.CountryId = 226; // US
    this.states = await this._vam_Service.GetStates(getStatesParameters);
  }

  async saveForm() {
    this.$refs.frmAddressEdit.validate(async valid => {
      if (valid) {
        let message: string;

        if (this.isNew) {
          this.address_.Id = await new SmartObject('Addresses').createObject(
            this.address_
          );
          message = 'Address added.';
        } else {
          await new SmartObject('Addresses', this.address_.Id).updateObject(
            this.address_
          );
          message = 'Changes saved.';
        }

        this.$notify.success(message);

        if (typeof this.onSave === 'function') {
          await this.onSave(this.address_.Id);
        }

        if (this.isNew) {
          this.closeDialog();
        }
      }
    });
  }

  async deleteAddress() {
    if (!this.isNew) {
      try {
        await this.$confirm(
          'This will permanently delete this address. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        try {
          await new SmartObject('Addresses', this.address_.Id).deleteObject();

          this.$notify.success('Address deleted.');

          if (typeof this.onDelete === 'function') {
            await this.onDelete(this.address_.Id);
            this.onClose_();
          }
        } catch {
          this.$notify.error('Something went wrong processing your request, please try again.');
        }
      } catch {}
    }
  }

  onClose_() {
    if (typeof this.onClose === 'function') {
      this.onClose();
    }
  }

  closeDialog() {
    this.$emit('close-dialog');
    this.onClose_();
  }
  //#endregion Methods
}
