
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import Common from "@/utils/common";
import * as AcctService from "@/services/DAL/acctService";
import * as TransactionsService from "@/services/DAL/transactionsService";
import uniqBy from 'lodash/uniqBy';
import GeneralLedgerEdit from '@/views/GeneralLedger/GeneralLedgerEdit.vue';
import { ElTableColumn } from "element-ui/types/table-column";
import { Event } from "@microsoft/microsoft-graph-types";
import ElementUI from "element-ui";

declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
  components: {
    GeneralLedgerEdit
  },
})
export default class GeneralLedgerTView extends Vue {
@Ref() readonly refGeneralLedgerForTransaction!: ElementUI.Table;
  //#region Private declarations for Services
  private _transactionsService: TransactionsService.TransactionsService;
  private _acctService: AcctService.AcctService;
  public common: Common;
  //#endregion Private declarations for Services

  //#region Props
  @Prop({ type: Number }) transactionHeaderId: number;
  @Prop({ type: Number }) promissoryNoteTransactionId: number;
  //#endregion Props

  //#region Data
  loading = false;
  securityLevel_: number = null;
  selectedGeneralLedger = {} as AcctService.GeneralLedgerForTransaction;
  showGeneralLedgerEdit = false;
  selectedEntityKey: string = '';
  valueMap = new Map<string, number>();
  valueMapTotal: number = null;

  showStatisticFMV = false;
  showStatisticBasis = false;
  showStatisticInvested = false;
  showStatisticCalled = false;
  showStatisticReturned = false;

  stats = ['FMV', 'Basis', 'Invested', 'Called', 'Returned'];
  statsSelected = [];
  isIndeterminateStats = false;
  checkAllStats = false;

  //effectively, the store:
  activeStep: number = 0;
  // generalLedger = [] as AcctService.GeneralLedger[];
  generalLedger: Array<AcctService.GeneralLedgerForTransaction> = [];

  //#endregion Data

  //#region Lifecycle
  created() {
    this.common = new Common();
    this._acctService = new AcctService.AcctService();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
      0
    );
    this.activeStep = 1;
    this.getGeneralLedgers();
  }
  //#endregion Lifecycle

  //#region Watches
  @Watch('promissoryNoteTransactionId')
  async promissoryNoteTransactionIdChanged(val: number, oldVal: number) {
    this.getGeneralLedgers();
  }
  @Watch('transactionHeaderId')
  async transactionHeaderIdChanged(val: number, oldVal: number) {
    this.getGeneralLedgers();
  }
  //#endregion Watches

  //#region Computed

  get assetValuation(): AcctService.GeneralLedgerForTransaction[]{
    if (!this.generalLedger || this.generalLedger.length == 0) return [] as AcctService.GeneralLedgerForTransaction[];

    const valuationRows = this.generalLedger.filter(
                    item => item.BalanceSheetAttribution != 0 || item.ProfitLossAttribution != 0
                )
    const sums = [
      ...valuationRows.reduce(
        (map, item) => {
          const { EntityKey: key, BalanceSheetAttribution, ProfitLossAttribution } = item;
          const prev = map.get(key);
          
          if(prev) {
            prev.BalanceSheetAttribution += BalanceSheetAttribution
            prev.ProfitLossAttribution += ProfitLossAttribution
          } else {
            map.set(key, Object.assign({}, item))
          }
          
          return map
        },
        new Map()
      ).values()
    ]
  return sums;
  }

  get accountSummary(){
    if (this.refGeneralLedgerForTransaction){
      const gl = ((this.refGeneralLedgerForTransaction as any).tableData as AcctService.GeneralLedgerForTransaction[]);
      const data = gl.map(gl => ({
         AccountNamePath: gl.AccountNamePath, 
         NormalBalance: gl.NormalBalance, 
         Attribution: gl.Attribution,
         AttributionSummaryDebit: gl.AttributionSummaryDebit || 0,
         AttributionSummaryCredit: gl.AttributionSummaryCredit || 0,
         AttributionSummaryDebitNetABS: 0,
         AttributionSummaryCreditNetABS: 0
         }));
      const sums = [
        ...data.reduce(
          (map, item) => {
            const { AccountNamePath: key, Attribution, AttributionSummaryCredit, AttributionSummaryDebit, NormalBalance } = item;
            const prev = map.get(key);
            
            if(prev) {
              prev.Attribution += Attribution;
              prev.AttributionSummaryCredit += AttributionSummaryCredit;
              prev.AttributionSummaryDebit += AttributionSummaryDebit;
              if (prev.AttributionSummaryDebit + prev.AttributionSummaryCredit == 0 ){
                prev.AttributionSummaryCreditNetABS = 0;
                prev.AttributionSummaryDebitNetABS = 0;
              }
              else if ((prev.AttributionSummaryDebit + prev.AttributionSummaryCredit > 0 && NormalBalance == 'D')
                ||(prev.AttributionSummaryDebit + prev.AttributionSummaryCredit < 0 && NormalBalance == 'C')){
                prev.AttributionSummaryDebitNetABS = Math.abs(prev.AttributionSummaryDebit + prev.AttributionSummaryCredit);
                prev.AttributionSummaryCreditNetABS = 0;
                }
              else if ((prev.AttributionSummaryDebit + prev.AttributionSummaryCredit > 0 && NormalBalance == 'C')
                ||(prev.AttributionSummaryDebit + prev.AttributionSummaryCredit < 0 && NormalBalance == 'D')){
                prev.AttributionSummaryCreditNetABS = Math.abs(prev.AttributionSummaryDebit + prev.AttributionSummaryCredit);
                prev.AttributionSummaryDebitNetABS = 0
                }

            } else { // first item.  still have to set the NetABS values:
              if (item.AttributionSummaryDebit + item.AttributionSummaryCredit == 0 ){
                item.AttributionSummaryCreditNetABS = 0;
                item.AttributionSummaryDebitNetABS = 0;
              }
              else if ((item.AttributionSummaryDebit + item.AttributionSummaryCredit > 0 && item.NormalBalance == 'D')
                ||(item.AttributionSummaryDebit + item.AttributionSummaryCredit < 0 && item.NormalBalance == 'C')){
                item.AttributionSummaryDebitNetABS = Math.abs(item.AttributionSummaryDebit + item.AttributionSummaryCredit);
                item.AttributionSummaryCreditNetABS = 0;
                }
              else if ((item.AttributionSummaryDebit + item.AttributionSummaryCredit > 0 && item.NormalBalance == 'C')
                ||(item.AttributionSummaryDebit + item.AttributionSummaryCredit < 0 && item.NormalBalance == 'D')){
                item.AttributionSummaryCreditNetABS = Math.abs(item.AttributionSummaryDebit + item.AttributionSummaryCredit);
                item.AttributionSummaryDebitNetABS = 0
                }
              map.set(key, Object.assign({}, item));
            }
            
            return map;
          },
          new Map()
        ).values()
      ]
      return sums;
    }
  }
  //#endregion Computed

  //#region Methods
  handleCheckAllStatsChange(val) {
    this.statsSelected = val ? this.stats : [];
    this.isIndeterminateStats = false;
    this.handleCheckedStatsChange(this.statsSelected);
  }
  handleCheckedStatsChange(value) {
    const checkedCount = value.length;
    this.checkAllStats = checkedCount === this.stats.length;
    this.isIndeterminateStats = checkedCount > 0 && checkedCount < this.stats.length;
    this.showStatisticFMV = value.includes('FMV');
    this.showStatisticBasis = value.includes('Basis');
    this.showStatisticInvested = value.includes('Invested');
    this.showStatisticCalled = value.includes('Called');
    this.showStatisticReturned = value.includes('Returned');
  }
  getSummary(param) {
    const columnList = [4,5];
    const totalLabel = "";
    const totalLabelIndex = 1;
    const formats = new Map();
    formats.set(4, "currency");
    formats.set(5, "currency");
    const showStats = [this.showStatisticFMV, this.showStatisticBasis, this.showStatisticInvested, this.showStatisticCalled, this.showStatisticReturned];
    for (let i = 0; i < showStats.length; i++){
      if (showStats[i]){
        const newColumnIndex = Math.max(...columnList) + 1;
        columnList.push(newColumnIndex);
        formats.set(newColumnIndex, "currency");
      }
    }
    const result = this.common.getSummaryArray(
      param,
      columnList,
      totalLabel,
      totalLabelIndex,
      formats
    );
    return result;
  }

  getSummaryAccountSummary(param) {
    const columnList = [1,2];
    const totalLabel = "NET Total";
    const totalLabelIndex = 0;
    const formats = new Map();
    formats.set(1, "currency");
    formats.set(2, "currency");
    const result = this.common.getSummaryArray(
      param,
      columnList,
      totalLabel,
      totalLabelIndex,
      formats
    );
    return result;
  }

  generalLedgerSaved(
    generalLedger: AcctService.GeneralLedgerForTransaction
  ) {
    const existing: AcctService.GeneralLedgerForTransaction = this.common.getSelectedArrayItem(
      this.generalLedger,
      generalLedger.Id.toString(),
      "Id"
    );
    if (existing && Object.keys(existing).length) {
      const index = this.generalLedger.indexOf(existing);
      this.generalLedger.splice(index, 1, generalLedger);
    } else {
      this.generalLedger.push(generalLedger);
    }
    this.$emit("saved", generalLedger);
    this.selectedGeneralLedger = {} as AcctService.GeneralLedgerForTransaction;
    this.showGeneralLedgerEdit = false;
  }
  async editGeneralLedgerItem(
    generalLedger: AcctService.GeneralLedgerForTransaction,
    event?,
    column?
  ) {
    this.selectedGeneralLedger = generalLedger;
    this.showGeneralLedgerEdit = true;
  }
  generalLedgerClosed(){
    this.selectedGeneralLedger = {} as AcctService.GeneralLedgerForTransaction;
    this.showGeneralLedgerEdit = false;
  }
  generalLedgerCancelled(gl: AcctService.GeneralLedgerForTransaction){
    // Cancel button should only show up when editing a newly-added (not yet saved) JV. so it needs to be removed from the list.
    // const index = this.generalLedger.indexOf(gl);
    // if (index > -1) {
    //   this.generalLedger.splice(index, 1);
    // }
    this.showGeneralLedgerEdit = false;
    this.selectedGeneralLedger = {} as AcctService.GeneralLedgerForTransaction;
    this.getGeneralLedgers();

  }
  generalLedgerDeleted(generalLedgerId: number) {
    // const existing: AcctService.GeneralLedgerForTransaction = this.common.getSelectedArrayItem(
    //   this.generalLedger,
    //   generalLedgerId.toString(),
    //   "Id"
    // );
    // if (existing && Object.keys(existing).length) {
    //   const index = this.generalLedger.indexOf(existing);
    //   this.generalLedger.splice(index, 1);
    // }
    this.showGeneralLedgerEdit = false;
    this.selectedGeneralLedger = {} as AcctService.GeneralLedgerForTransaction;
    this.$emit("delete", generalLedgerId);
    this.getGeneralLedgers();
  }
  async getGeneralLedgers() {
    if (this.transactionHeaderId || this.promissoryNoteTransactionId) {
      const params = {} as AcctService.GetGeneralLedgerForTransactionParameters;
      params.TransactionHeaderId = this.transactionHeaderId;
      params.PromissoryNoteTransactionId = this.promissoryNoteTransactionId
      this.loading = true;
      this.generalLedger = await this._acctService.GetGeneralLedgerForTransaction(
        params
      );
      this.loading = false;
    }
  }
  filterHandler(value, row, column) {
    const property = column['property'];
    return row[property] === value;
  }
 filters(column){
    const filters = this.generalLedger.map(function(list) {
        return {
            text: list[column]
            , value: list[column]
            };
    });
    return uniqBy(filters, 'value');
  }
  onClickInvestmentValuation(row: AcctService.GeneralLedgerForTransaction){
    if (this.selectedEntityKey == row.EntityKey){
      this.selectedEntityKey = null;
    }
    else {
      this.selectedEntityKey = row.EntityKey;
    }
  }
  valuationsRowClassName({row, rowIndex}): string {
    if (this.selectedEntityKey && this.selectedEntityKey == row.EntityKey && row.BalanceSheetAttribution != 0){
        return 'highlight2-row';
    }
    else if (this.selectedEntityKey && this.selectedEntityKey == row.EntityKey && row.ProfitLossAttribution != 0){
        return 'highlight-row';
    }
    return '';
  }
  valuationsCellClassName({row, column, rowIndex, columnIndex}): string {
    if(column.property && row.Id && this.valueMap.has(row.Id.toString() + column.property) && !!row[column.property]){
      return 'highlight-cell';
    }
    else return '';
  }
  investmentValuationCellClassName({row, column, rowIndex, columnIndex}): string {
    if (column.property && this.selectedEntityKey && this.selectedEntityKey == row.EntityKey && column.property == 'BalanceSheetAttribution'){
        return 'highlight2-row';
    }
    else if (column.property && this.selectedEntityKey && this.selectedEntityKey == row.EntityKey && column.property == 'ProfitLossAttribution'){
        return 'highlight-row';
    }
    return '';

  }
  async cellClick(selectedRow: AcctService.GeneralLedgerForTransaction, column: any, cell: any, event: any){
    if (column.property && column.property == 'Credit' || column.property == 'Debit' && selectedRow[column.property]){
      this.valueMapTotal = null;
      if (!this.valueMap.delete(selectedRow.Id.toString() + column.property)){ // delete will return true if found and deleted.  false if not found, in which case, add it:
        this.valueMap.set(selectedRow.Id.toString() + column.property, selectedRow[column.property]);
      }
      if (this.valueMap.size > 0){
        this.valueMapTotal = 0;
        this.valueMap.forEach((v) => {
          this.valueMapTotal += v;
        });
      }

    }
    else if (column.property=='Editable'){ //'Editable' really has nothing to do with this column; however it is set as the prop so that it can be identified here.
      return; //clicking the copy icon handles this
    }
    else {
      this.editGeneralLedgerItem(selectedRow, event, column);
    }
  }
  resetValueMap(){
    this.valueMapTotal = null;
    this.valueMap = new Map<string, number>();
  }
  copyJV(scope){
    console.log('copying JV', scope.row);
    const row: AcctService.GeneralLedgerForTransaction = scope.row;
    const item = Object.assign({}, row);
    item.Id = 0;
    item.CalculationDescription = null;
    item.Source = 'Manual';
    item.CreateSourceDescription = 'Manual';
    item.Date = row.Date;
    item.Editable = true;
    if (this.transactionHeaderId){
      item.SourceEntityType = 'InvestmentTransactionHeader';
      item.SourceEntityId = this.transactionHeaderId;
    }
    else if (this.promissoryNoteTransactionId){
      item.SourceEntityType = 'PromissoryNoteTransaction';
      item.SourceEntityId = this.promissoryNoteTransactionId;
    }
    this.generalLedger.splice(scope.$index+1, 0, item);
    this.editGeneralLedgerItem(item);
    
  }

  //#endregion Methods
}
