
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop, Model } from "vue-property-decorator";
import * as WorkflowService from '../../services/DAL/workflowService';
import CommentLogList from '@/js/CommentLog/CommentLogList.vue';
declare var SmartObject: any;
declare var $: any;

class ApproversToAdd{
    ApproverSelectionId: number;
    NotificationEntityType: string;
    NotificationEntityId: number;
    // KeyPersonId: number;
    FullName: string;
    Email: string;
}
class approvalItems{
    ApprovalId: number
    EntityType: string;
    EntityId: number;
}
interface IEntityUrl {
 (approvalId: number): string
}
function defaultEntityUrl(approvalId: number){
    return 'no URL defined'
}

// 11-08-2021 12:39 CAMPBED:    remove sendEmailOnSave and processApprovalEmails; add emit(saved)


@Component({
    inheritAttrs: false,
    components: {
        CommentLogList
    }

})
export default class Approval extends Vue {
    $refs: {
        divApprovedEmail: HTMLDivElement,
        divRejectionEmail: HTMLDivElement,
        commentLog: any
    }
    //#region Private declarations for Services
    private _workflowService: WorkflowService.WorkflowService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop(Number)
    workflowId: number;
    @Prop(Number)
    approvalId: number;
    @Prop(Number)
    clientId: number;
    @Prop(Number)
    vendorId: number;
    @Prop({ default: null }) readonly securityLevel!: number;
    @Prop({ default: 'Approver' }) approverLabel: string;
    @Prop({ default: false, type: Boolean }) singleApprover: boolean;
    @Prop({ type: Array }) approvalItems: approvalItems[];
    @Prop({ type: String, default: "Request Approval"}) requestApproveButtonText: string;
    @Prop({ type: String, default: "Approve"}) approveButtonText: string;    
    @Prop({ default: false, type: Boolean }) showRequestApprovalButton: boolean;
    @Prop({ default: false, type: Boolean }) showApproveRejectButtons: boolean;
    @Prop({ default: false, type: Boolean }) showCommentLog: boolean;
    @Prop({ default: true, type: Boolean }) showRejectButton: boolean;
    @Prop({type: Function, default: defaultEntityUrl}) fnEntityUrl: IEntityUrl;
    //#endregion Props

    //#region Data
    approvers: WorkflowService.Approvers[] = [];
    approvalApprovers: WorkflowService.ApprovalApprovers[] = [];
    newApprovalApprovers: WorkflowService.ApprovalApprovers[] = [];
    workflow: WorkflowService.Workflow = null;
    loading: boolean = false;
    securityLevel_: number = this.securityLevel;
    selectedNewApprover: WorkflowService.Approvers = null;
    approversToAdd: ApproversToAdd[] = [];
    approvalId_: number = null;
    approvalButtonDisabled: boolean = false;

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._workflowService = new WorkflowService.WorkflowService();
        this.approvalId_ = this.approvalId;
        await this.fetchApprovalApprovers();
        this.fetchApprovers();
        this.fetchWorkflow();
    }
    async mounted() {
    }
    //#endregion Lifecycle

    //#region Computed
    get responseReceived() {
        if (!this.approvalApprovers || !this.approvalApprovers.length) return false;
        return this.approvalApprovers.some(function(approver){
            return approver.ResponseDate != null;
        })

    }
    //#endregion Computed

    //#region Watches
    @Watch("clientId")
    onChange_clientId(val: number, oldVal: number) {
        this.fetchApprovers();
    }
    @Watch("vendorId")
    onChange_vendorId(val: number, oldVal: number) {
        this.fetchApprovers();
    }
    @Watch("approvalId")
    onChange_approvalId(val: number, oldVal: number) {
        this.approvalId_ = this.approvalId;
        this.fetchApprovalApprovers();
    }
    @Watch("workflowId")
    onChange_workflowlId(val: number, oldVal: number) {
        this.fetchWorkflow();
    }
    
    //#endregion Watches

    //#region Methods
    async fetchApprovalApprovers(){
        this.loading = true;
        if (this.approvalId_){
            const params = {} as WorkflowService.GetApprovalApproversParameters;
            params.ApprovalId = this.approvalId_;
            this.approvalApprovers = await this._workflowService.GetApprovalApprovers(params);
        }
        else {
            this.approvalApprovers = [];
        }
        this.loading = false;
    }
    async fetchWorkflow(){
        if (this.workflowId){
            this.loading = true;
            this.$setSystemStatus('Getting Workflow');
            const params = {} as WorkflowService.GetWorkflowParameters;
            params.WorkkflowId = this.workflowId;
            this.workflow = (await this._workflowService.GetWorkflow(params))[0];
            this.$setSystemStatus('');
            this.loading = false;
            }
        else {
            this.workflow = null;
        }
    }
    async fetchApprovers(){
        this.loading = true;
        this.$setSystemStatus('Getting Approvers');
        if (!this.responseReceived){
            console.log(this.approversToAdd)
            if (this.approversToAdd.length){
                this.approversToAdd = [];
                this.$notify.warning(`Previously set ${this.workflow ? this.workflow.Name : ''} selections removed`)
            }
            const params = {} as WorkflowService.GetApproversParameters;
            params.ApprovalId = this.approvalId_;
            params.WorkflowId = this.workflowId;
            params.ClientId = this.clientId;
            params.VendorId = this.vendorId;
            this.approvers = await this._workflowService.GetApprovers(params);
            if (!!this.vendorId && this.approversToAdd.length == 0 && !this.approvalId){ //only add default approvers if new (i.e. this.approvalId is null)
                const defaultApprovers = this.approvers.filter(approver => (approver.DefaultSelection === true));
                defaultApprovers.forEach(approver =>{
                    this.addNewApprover(approver);
                    this.$notify.success(`Default Approver added (${approver.FullName})`);
                })
            }
        }
        else {
            this.approvers = [];
        }
        this.$setSystemStatus('');
        this.loading = false;
    }
    addNewApprover(approver){
        this.approversToAdd.push({
            ApproverSelectionId: approver.ApproverSelectionId,
            NotificationEntityType: approver.NotificationEntityType,
            NotificationEntityId: approver.NotificationEntityId,
            FullName: approver.FullName,
            Email: approver.Email
        });
        // (this.approvalApprovers[this.approvalApprovers.indexOf(approver)]).Selectable = false;
        approver.Selectable = false;
        this.selectedNewApprover = null;
    }
    removeNewApprover(approverSelectionId){
        const approverToDeleteIndex: number = this.approversToAdd.findIndex(approver => approver.ApproverSelectionId === approverSelectionId);
        const approver: WorkflowService.Approvers = this.approvers.find(approver => approver.ApproverSelectionId === approverSelectionId);
        this.approversToAdd.splice(approverToDeleteIndex, 1);
        approver.Selectable = true;
    }
    async saveApproval(): Promise<Object> {
        this.$setSystemStatus('Saving Approval');
        this.approvalButtonDisabled = true; //prevent clicking it again
        await this.$nextTick();
        let isNew: boolean = false;
        this.loading = true;
        if (!this.approvalId_ && this.approversToAdd.length){ // add it
            isNew = true;
            const approval = new SmartObject('Approval');
            const newId = await approval.createObject({WorkFlowId: this.workflowId, ClientId: this.clientId});
            if (newId){
                this.$notify.success(`${this.approverLabel} Created`);
                this.approvalId_ = newId;
            }
            if (!!this.approvalItems && this.approvalItems.length){
                const itemInserts = this.approvalItems.map(async (item) => { 
                    item.ApprovalId = this.approvalId_;
                    return await new SmartObject('ApprovalItems').createObject(item);
                    });
                await Promise.all(itemInserts);
                this.$notify.success(`${itemInserts.length} Approval Items added`);
            }
        }
        if (this.approversToAdd.length){
            this.approversToAdd = await this.$asyncIterations.filter(this.approversToAdd, async (approver) => { 
                const so = new SmartObject('Approvers');
                const newId: number = await so.createObject({
                    ApprovalId: this.approvalId_
                    //, KeyPersonId: approver.keyPersonId
                    , NotificationEntityType: approver.NotificationEntityType
                    , NotificationEntityId: approver.NotificationEntityId
                });
                await so.loadData();
                this.newApprovalApprovers.push(so.dataObject);
                const newApprovalApprover: WorkflowService.ApprovalApprovers = new WorkflowService.ApprovalApprovers();
                newApprovalApprover.ApprovalId = this.approvalId_;
                newApprovalApprover.ApproverId = newId;
                newApprovalApprover.FullName = approver.FullName;
                newApprovalApprover.WorkflowType = this.workflow.Name;
                newApprovalApprover.Email = approver.Email;
                newApprovalApprover.GUID = so.dataObject.GUID;
                //newApprovalApprover.KeyPersonId = approver.KeyPersonId;
                
                this.approvalApprovers.push(newApprovalApprover);
                return !newId; // returing false from Filter will result in removing it from the list
            });
        }
        this.$emit('saved', this.approvalApprovers);
        
        if (this.showCommentLog && this.$refs && this.$refs.commentLog){
            this.$setSystemStatus('Getting Notes');
            this.$refs.commentLog.fetchCommentLog();
        }
        this.$setSystemStatus('');
        
        this.loading = false;
        
        return {
            approvalId: this.approvalId_,
            isNew: isNew,
            newApprovers: this.newApprovalApprovers,
            workflow: this.workflow
        }
    }
    async approveReject(approved: boolean, approver: WorkflowService.ApprovalApprovers){
        this.$setSystemStatus('Setting Approval Status');
        let comment: string = null;
        if (!approved){
            const conf: any  = await this.$prompt('Enter a reason for Rejection', 'Rejected', { // MessageBoxData Type must be incorrectly defined, so declaring it as any
                confirmButtonText: 'OK',
                showCancelButton: false,
                inputErrorMessage: 'Rejection Comment is required and must be no longer than 4,000 characters.',
                inputValidator: function(input){if (!!input && input.length < 4001) return true; else return false;}
                });
            comment = conf.value;
        }

        const params = {} as WorkflowService.SetResponseParameters;
        params.GUID = this.approvalApprovers[0].GUID;
        params.Approved = approved;
        params.Comment = comment;
        const response = await this._workflowService.SetResponse(params);
        if (!!response && response.length && response[0].NotifyEmail){
            await this.sendNotificationEmail(response[0].NotifyEmail, approved);
        }
        else if (!response || !response.length) { 
            this.$notify.error('No Response from Approval');
            return;
        }
        approver.Approved = approved;
        approver.ResponseDate = this.$dayjs().format('L LT');
        this.$setSystemStatus('Getting Notes');
        if (this.$refs && this.$refs.commentLog) this.$refs.commentLog.fetchCommentLog();
        this.$setSystemStatus('');
    }
    approverClicked(approvalApprover: WorkflowService.ApprovalApprovers){
        this.$emit('approver-clicked', approvalApprover);
    }
    async sendNotificationEmail(toAddress: string, approved: boolean) {
        this.$notify.info('Sending notification email...');

        const toAddresses = {};
        toAddresses[toAddress] = toAddress;

        let body: HTMLDivElement;
        if (approved){
            body = this.$refs.divApprovedEmail;
        }
        else {
            body = this.$refs.divRejectionEmail;
        }

        try {
            var result = await $(body).exportToEmail({
                toAddresses: toAddresses,
                subject: `Approval ${this.approvalId_} ${approved ? 'Approved' : 'REJECTED'}`,
                additionalAttributes: {
                    EntityType: 'Approval',
                    EntityId: this.approvalId_,
                    Source: 'Approval'
                }
            });

            if (result.succeeded) {
                this.$notify.success('Notification email sent.');
            } else {
                this.$notify.error('Notification email failed.');
            }
        } catch (err) {
            this.$notify.error('Notification email failed.');
        }
    }
    async deleteApprover(approver:  WorkflowService.ApprovalApprovers){
        try {
            await this.$confirm(
            'Are you sure you want to delete this approver? (The approval email has not yet been sent.)',
            'Warning',
            {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }
            );

            try {
            await new SmartObject('Approvers', approver.ApproverId).deleteObject();

            this.$notify.success('Approver deleted.');
            this.fetchApprovalApprovers();

            } catch (e) {
            this.$notify.error(e);
            }
        } 
        catch {} // cancelled
    }
    //#endregion Methods
}
