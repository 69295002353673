
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as LiqService from '../../services/DAL/liq_Service';
import InvoiceEdit from '@/js/AP/InvoiceEdit.vue';
import Allocation from '@/js/Allocation/Allocation.vue';
import AttachmentList from '@/js/Attachments/AttachmentList.vue';
import ChangeLogList from '@/js/ChangeLog/ChangeLogList.vue';
import formatters from '../../utils/formatters';
import PDFViewer from '@/components/other/PDFViewer.vue';
import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';
import TripInvoiceEdit from '@/views/Aviation/TripInvoiceEdit.vue'
import InvoiceRecognizedFields from '@/views/Invoice/InvoiceRecognizedFields.vue'

declare var $: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(value: any, defaultValue: any): any;

@Component({
    components: {
        InvoiceEdit,
        Allocation,
        AttachmentList,
        ChangeLogList,
        TripInvoiceEdit,
        InvoiceRecognizedFields,
        'pdf-viewer': PDFViewer
    }
})
export default class InvoiceEditView extends Vue {
    //#region Private Declarations
    private _liqService: LiqService.LiqService;
    //#endregion Private Declarations

    //#region Props
    @Prop({ required: true, type: Object }) invoice: LiqService.Invoices;
    @Prop() invoiceId: number;
    @Prop() showSideBySide?: boolean;
    //#endregion Props

    //#region Data
    invoice_ = {} as LiqService.Invoices;
    invoiceId_: number = null;

    showTransactionList: boolean = true;
    showAttachmentList: boolean = false;
    showImage: boolean = false;
    showChangeLogList: boolean = false;
    showInvoiceRecognizedFields: boolean = false;
    securityLevel_: number = getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP);
    activeTab: string = '0';
    fileData = {} as MicrosoftGraphTypes.DriveItem;
    sideBySide = this.showSideBySide;
    showAllocation = false;

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();

        if (this.invoice && Object.keys(this.invoice).length && this.invoice.InvoiceId > 0) {
            this.invoice_ = this.invoice;
            this.invoiceId_ = this.invoice.InvoiceId;
        }
        else if (this.invoiceId) {
            this.invoiceId_ = this.invoiceId;
            await this.fetchInvoice();
        }
    }
    //#endregion Lifecycle

    @Watch('sideBySide')
    onSideBySideChange(){
        this.$emit('side-by-side', {sideBySide: this.sideBySide, fileData: this.fileData});
    }
    @Watch('showSideBySide')
    onChange_showSideBySide(){
        this.sideBySide = this.showSideBySide;
    }    

    //#region Methods
    async onTabClick(tab) {
        switch (tab.label) {
            case 'Documents':
                this.showAttachmentList = true;
                break;
            case 'Allocation':
                this.showAllocation = false;
                await this.$nextTick();
                this.showAllocation = true;
                break;
            case 'Change Log':
                this.showChangeLogList = false;
                await this.$nextTick();
                this.showChangeLogList = true;
                break;
            case 'Image':
                this.showImage = true;
                break;
            case 'Recognition':
                this.showInvoiceRecognizedFields = false;
                await this.$nextTick();
                this.showInvoiceRecognizedFields = true;
                break;
        }
    }

    onSave(invoice) {
        this.$emit('save', invoice);
    }

    onDeleted(){
        this.$emit('invoice-deleted');
    }
    async fetchInvoice() {
        const params = {} as LiqService.GetInvoicesParameters;
        params.InvoiceId = this.invoiceId_;
        const invoices = await this._liqService.GetInvoices(params);
        if (invoices.length == 1) {
            this.invoice_ = invoices[0];
        }
    }
    gotFileData(fileData: MicrosoftGraphTypes.DriveItem){
        this.fileData = fileData;
        if (this.showSideBySide){ // show it initially
            this.$emit('side-by-side', {sideBySide: this.sideBySide, fileData: this.fileData});
        }
    }
    //#endregion
}
