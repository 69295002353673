
import Vue from 'vue';
import { Watch, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import * as AvService from './../../services/DAL/avService';
import PDFViewer from '@/components/other/PDFViewer.vue';
import TripInvoiceEdit from '@/views/Aviation/TripInvoiceEdit.vue';
import * as MicrosoftGraphTypes from "@microsoft/microsoft-graph-types" 
import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';
import MicrosoftGraphClient, { GraphRequest, ResponseType } from '@microsoft/microsoft-graph-client';
import { AttachmentLinkService } from './../../services/AttachmentLinkService';
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;
@Component({
    components: {
        'pdf-viewer': PDFViewer,
        TripInvoiceEdit
    }
})
export default class TripInvoice extends Vue {
    //#region Data
    private _avService: AvService.AvService;
    private _graphService: MicrosoftGraphService;
    private _attachmentLinkService: AttachmentLinkService;

    @Prop({
        required: true,
        type: Number
    }) invoiceId: number;

    tripInvoice: AvService.TripInvoice =  new AvService.TripInvoice();
    fileUrl: string = '';
    showFile: boolean = true; // Change this to true when CORS issue is worked out
    securityLevel_: number = null;
  
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._avService = new AvService.AvService();
        this._graphService = new MicrosoftGraphService();
        this._attachmentLinkService = new AttachmentLinkService();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAviationDataImport),
            0
        );

        await this.fetchTripInvoice();
    }
    //#endregion Lifecycle

    //#region Methods
    loadError(error){
        console.log(error);
        this.showFile = false;
    }
    async tryExternalLink(){
        try {
            const response = await this._attachmentLinkService.getExternalLinkForEntity('Invoice', this.invoiceId);
            if (!!response && !!response.data && !!response.data.downloadUrl){
                this.fileUrl = response.data.downloadUrl;    
            }
            else {
                this.$alert(response.data.message, 'No File', { type: 'error'});
            }
        }
        catch (exception){
            await this.$alert('Image could not be loaded', 'Invoice Image', {
                confirmButtonText: 'OK'
                , type: 'error'
            });

        }
    }
    async fetchTripInvoice() {
        const parameters = {} as AvService.GetTripInvoiceParameters;
        parameters.InvoiceId = this.invoiceId;
        const tripInvoices: AvService.TripInvoice[] = await this._avService.GetTripInvoice(parameters);

        if (tripInvoices && tripInvoices.length) {
            this.tripInvoice = tripInvoices[0];
        }
        try {
            const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
            const file = await graphClient.api(this.tripInvoice.FilePath).get();
            this.fileUrl = file['@microsoft.graph.downloadUrl'];
        }
        catch (exception){
            this.tryExternalLink();
        }
    }
    //#endregion Methods
}
