
import Vue from 'vue';
import { Watch, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';
import MicrosoftGraphClient, { GraphRequest, ResponseType } from '@microsoft/microsoft-graph-client';
import PDFObject from 'pdfobject';

@Component
export default class PDFViewer extends Vue {
    //#region Data
    private _graphService: MicrosoftGraphService;

    @Prop({
        type: String
    }) filePath: string;

    @Prop({
        type: String
    }) fileUrl: string;

    @Prop({
        type: String
    }) fileName: string;

    @Prop({
        type: Number
        , default: 200
    }) screenHeightAdjustment: number;

    @Prop({
        type: Boolean
        , default: false
    }) showClose: boolean;

    @Prop({
        type: String
        , default: 'card'
    }) viewType: string;

    fileData: object = {};
    isLoading = false;
    showPDF = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._graphService = new MicrosoftGraphService();
        this.loadPDF();
    }

    async mounted() {
    }
    //#endregion Lifecycle

    //#region Computed
    //#endregion Computed

    //#region Methods
    error(err) {
        console.log(err);
        this.isLoading = false;
    }
    async loadPDF() {
        if (this.fileUrl){
            try {
                this.isLoading = true;
                const pdfResponse = await fetch(this.fileUrl, {
                    method: 'GET',
                    mode: 'cors',
                    redirect: 'follow',
                });
                // this.src = pdfResponse.url;
                this.loadInvoiceImage(pdfResponse);
            }
            catch(error){
                this.$emit('loadError', error);
                this.isLoading = false;
                await this.$nextTick();
                window.open(this.fileUrl, '_blank');
                this.$notify.info('File opened in new tab');
            }
        }
        else {
            const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
            try {
                const response = await graphClient
                    .api(this.filePath)
                    .get();
                
                if (response) {
                    this.fileData = response;
                    this.$emit('fileData', response);
                    const pdfResponse = await fetch(response['@microsoft.graph.downloadUrl'])
                    // this.src = pdfResponse.url;
                    this.loadInvoiceImage(pdfResponse);
                }
                else {
                    console.log(`No response for ${this.filePath}`)
                }
            }
            catch (Error) {
                console.log(Error);
                this.isLoading = false;
            }
        }
    }
    async loadInvoiceImage(response) {
        const PDFOptions = {
            //width: "800px"
            //height: this.$getAvailableScreenHeight(this.screenHeightAdjustment)
            // height: '100%'
            //'height': '740px'
        };
        this.showPDF = true;
        await this.$nextTick();
        const div = this.$refs.divPDF;

        const blobResponse = await response.blob();
        const objectURL = URL.createObjectURL(blobResponse);
        const p = PDFObject.embed(objectURL, div, PDFOptions);
        this.isLoading = false;
    }

    //#endregion Methods
}
