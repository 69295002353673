// TS API for Schema av
// Auto-generated 11/30/23 10:55


declare var smartData: any;
// output classes:
export class Aircraft {
    AircraftId: number=undefined;
    Type: string=undefined;
    TailNumber: string=undefined;
    OwnershipType: string=undefined;
    Display: string=undefined;
    ClientId: number=undefined;
    ClientName: string=undefined;
    OwnerName: string=undefined;
    Share: number=undefined;
    DDLGroupBy: string=undefined;
    TypeOperatorDisplay: string = undefined;
}
export class Airports {
    ICAO: string=undefined;
    Name: string=undefined;
    Type: string=undefined;
    Display: string=undefined;
    Grouping: string = undefined;
}
export class DepreciationCashMethod {
    Id: number=undefined;
    AircraftId: number=undefined;
    Method: string=undefined;
    OriginalMethod: string=undefined;
    Aircraft: string=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    LastChange: string = undefined;
}
export class DepreciationImport {
    Id: number=undefined;
    ConfigurationId: number=undefined;
    LogId: number=undefined;
    Value: number=undefined;
    ClientId: number=undefined;
    Year: number=undefined;
    Description: string=undefined;
    TaxType: string=undefined;
    Path: string=undefined;
    DisplayOrder: number=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    Client: string=undefined;
    Created: string=undefined;
    Aircraft: string=undefined;
    AircraftId: number=undefined;
    NewValue: number = undefined;
}
export class Expenses {
    ExpenseId: number=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    OwnerId: number=undefined;
    OwnerName: string=undefined;
    AircraftId: number=undefined;
    Type: string=undefined;
    TailNumber: string=undefined;
    ServiceDate: string=undefined;
    ChargeType: string=undefined;
    AccountNumber: number=undefined;
    Supplier: string=undefined;
    Notes: string=undefined;
    Amount: number=undefined;
    ExpenseType: string=undefined;
    TripId: number=undefined;
    RequiresAttention: boolean=undefined;
    TripDates: string=undefined;
    Route: string=undefined;
    ReviewUser: string=undefined;
    ReviewDate: string=undefined;
    Reviewed: boolean=undefined;
    ReviewedHTML: string=undefined;
    Reviewer: string = undefined;
}
export class ImportActivityJetAviation {
    ImportLogId: number=undefined;
    FileName: string=undefined;
    StartTime: string=undefined;
    CompleteDate: string=undefined;
    Seconds: number=undefined;
    Success: boolean=undefined;
    TripRecords: number=undefined;
    TripLegRecords: number=undefined;
    ManifestRecords: number=undefined;
    PeopleRecords: number=undefined;
    CreateUser: string=undefined;
    ImportRecords: number = undefined;
}
export class ImportActivityNetJets {
    ImportLogId: number=undefined;
    FileName: string=undefined;
    ActivityMonth: string=undefined;
    StartTime: string=undefined;
    CompleteDate: string=undefined;
    Seconds: number=undefined;
    Success: boolean=undefined;
    TripRecords: number=undefined;
    TripLegRecords: number=undefined;
    BilledHours: number=undefined;
    ManifestRecords: number=undefined;
    ExpenseRecords: number=undefined;
    PeopleRecords: number = undefined;
}
export class ImportCPRJetAviation {
    ImportLogId: number=undefined;
    FileName: string=undefined;
    StartTime: string=undefined;
    CompleteDate: string=undefined;
    Seconds: number=undefined;
    Success: boolean=undefined;
    ExpenseRecords: number=undefined;
    Amount: number=undefined;
    CreateUser: string=undefined;
    ImportedRecords: number=undefined;
    ImportedAmount: number = undefined;
}
export class ImportServicesNetJets {
    ImportLogId: number=undefined;
    ActivityMonth: string=undefined;
    FileName: string=undefined;
    StartTime: string=undefined;
    CompleteDate: string=undefined;
    Seconds: number=undefined;
    Success: boolean=undefined;
    ExpenseRecords: number = undefined;
}
export class JetAviationMonthly {
    AircraftId: number=undefined;
    ClientId: number=undefined;
    StartDate: string=undefined;
    EndDate: string=undefined;
    DateKey: string=undefined;
    Hours: number=undefined;
    PercentBusiness: number=undefined;
    BusinessHours: number=undefined;
    Fixed: number=undefined;
    Variable: number=undefined;
    BusinessVariable: number=undefined;
    Financing: number=undefined;
    FixedOpEx: number=undefined;
    FixedCapEx: number = undefined;
}
export class JetAviationSummary {
    OwnerId: number=undefined;
    AircraftId: number=undefined;
    Year: string=undefined;
    Hours: number=undefined;
    BusinessHours: number=undefined;
    BusinessPercent: number=undefined;
    OpEx: number=undefined;
    Financing: number=undefined;
    DepreciationCashValue: number=undefined;
    OpExDedCashValue: number=undefined;
    CapGainsDeduction: number=undefined;
    TaxRate: number=undefined;
    NetCost: number=undefined;
    GrossCostPerHour: number=undefined;
    NetCostPerHour: number=undefined;
    VariableCostPerHour: number=undefined;
    Variable: number=undefined;
    Fixed: number=undefined;
    FixedCapEx: number=undefined;
    FixedOpEx: number=undefined;
    IsTotal: number = undefined;
}
export class NetJetsActivityInvoiceReconciliation {
    ClientId: number=undefined;
    Owner: string=undefined;
    OwnerId: number=undefined;
    AircraftId: number=undefined;
    Type: string=undefined;
    ValidFrom: string=undefined;
    ValidTo: string=undefined;
    ContractOrdinalPeriodId: number=undefined;
    AnnualAvailable: number=undefined;
    Allotted: number=undefined;
    CarriedOver: number=undefined;
    TotalAllotted: number=undefined;
    AdditionalHours: number=undefined;
    TotalAvailable: number=undefined;
    BilledHours: number=undefined;
    RemainHoursAvailable: number=undefined;
    AverageHoursPerMonthByYear: number=undefined;
    CumulativeAverageHoursPerMonth: number=undefined;
    PercentOfBusiness: number=undefined;
    CurrentPeriod: boolean=undefined;
    LastInvoicedTripDate: string = undefined;
}
export class PurposeCompany {
    Id: number=undefined;
    TripId: number=undefined;
    AircraftId: number=undefined;
    LegId: number=undefined;
    OwnerId: number=undefined;
    CompanyName: string=undefined;
    Purpose: string=undefined;
    Modified: string=undefined;
    PeopleCount: number=undefined;
    VisitedPersonArrayJSON: string = undefined;
}
export class SLSDaysNY {
    Year: number=undefined;
    Month: number=undefined;
    Region: string=undefined;
    StayDaysCalc: number=undefined;
    Forecast: number=undefined;
    Value: number=undefined;
    TotalDays: number=undefined;
    MonthDisplay: string=undefined;
    ForecastId: number=undefined;
    YearMonth: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    LastModified: string = undefined;
}
export class TripInvoice {
    InvoiceId: number=undefined;
    AircraftId: number=undefined;
    AircraftDisplay: string=undefined;
    UnitsLabel: string=undefined;
    Hours: number=undefined;
    UnitsDisplay: number=undefined;
    OwnerId: number=undefined;
    OwnerName: string=undefined;
    TripId: number=undefined;
    DepartureDate: string=undefined;
    ApprovalId: number=undefined;
    FileId: string=undefined;
    DriveId: string=undefined;
    FilePath: string=undefined;
    AnonymousUrl: string=undefined;
    Editable: boolean=undefined;
    Approved: boolean = undefined;
}
export class TripLegDetails {
    Id: number=undefined;
    TripId: number=undefined;
    EstimatedTimeOfDeparture: string=undefined;
    FromCode: string=undefined;
    ToCode: string=undefined;
    DistSM: number=undefined;
    Reason: string=undefined;
    ConsumedHours: number=undefined;
    FromAirport: string=undefined;
    FromRegion: string=undefined;
    FromCity: string=undefined;
    ToAirport: string=undefined;
    ToRegion: string=undefined;
    ToCity: string=undefined;
    PercentOfBusiness: number=undefined;
    PercentOfBusinessSource: string=undefined;
    OverrideBusinessPercent: number=undefined;
    OverrideBusinessPercentSelected: boolean = undefined;
}
export class TripLegManifest {
    TripLegManifestId: number=undefined;
    Type: string=undefined;
    Position: string=undefined;
    PersonId: number=undefined;
    Name: string=undefined;
    IsBusinessTravel: boolean=undefined;
    IsCharitable: boolean=undefined;
    Modified: string = undefined;
}
export class TripLegs {
    ClientId: number=undefined;
    Client: string=undefined;
    OwnerId: number=undefined;
    OwnerName: string=undefined;
    AircraftId: number=undefined;
    Type: string=undefined;
    TailNumber: string=undefined;
    TripRequiresAttention: boolean=undefined;
    TripDates: string=undefined;
    TripConsumedHours: number=undefined;
    TripStatuteMiles: number=undefined;
    TripId: number=undefined;
    Route: string=undefined;
    TripDepartureDate: string=undefined;
    LegId: number=undefined;
    LegDepartureDate: string=undefined;
    FromCode: string=undefined;
    FromAirport: string=undefined;
    FromRegion: string=undefined;
    FromCity: string=undefined;
    FromDisplay: string=undefined;
    ToCode: string=undefined;
    ToAirport: string=undefined;
    ToRegion: string=undefined;
    ToCity: string=undefined;
    ToDisplay: string=undefined;
    LegConsumedHours: number=undefined;
    LegStatuteMiles: number=undefined;
    RequiresAttention: boolean=undefined;
    SourceTripId: string=undefined;
    SourceLegId: string=undefined;
    Reason: string=undefined;
    Passengers: number=undefined;
    BusinessPassengers: number=undefined;
    PercentOfBusiness: number=undefined;
    PercentOfBusinessSource: string=undefined;
    DepartureTime: string=undefined;
    ArrivalTime: string=undefined;
    Modified: string=undefined;
    DistNM: number=undefined;
    DistSM: number=undefined;
    IsFirstLegWithPassengers: boolean=undefined;
    IsOnlyLegWithPassengers: boolean=undefined;
    CompanyPurposeDescription: string=undefined;
    CompanyPurposeStatus: string=undefined;
    ReasonJSON: string=undefined;
    IsGift: boolean=undefined;
    CompanyPurposeNameTitleList: string=undefined;
    CompanyList: string=undefined;
    CompanyPurposeReferenceTripLegId: number=undefined;
    CompanyPurposeReferenceTripLegDescription: string=undefined;
    CanBeUsedForCompanyPurposeReference: boolean=undefined;
    TripHasNoLegSpecificPurposeCompanyRecords: boolean=undefined;
    UnsetPassengerRecords: number=undefined;
    InvoiceUrl: string=undefined;
    VendorInvoiceNumber: string = undefined;
}
export class Trips {
    ClientId: number=undefined;
    Client: string=undefined;
    OwnerId: number=undefined;
    OwnerName: string=undefined;
    AircraftId: number=undefined;
    Type: string=undefined;
    TailNumber: string=undefined;
    DepartureDate: string=undefined;
    TripDates: string=undefined;
    Hours: number=undefined;
    BlockHours: number=undefined;
    TripId: number=undefined;
    Route: string=undefined;
    TripRequiresAttention: boolean=undefined;
    legs: number = undefined;
}
export class TripsForExpense {
    TripId: number=undefined;
    TripDescription: string=undefined;
    GroupBy: string=undefined;
    ServiceDateDiff: number = undefined;
}
export class ProcessImportCPRJetAviation {
    ImportLogId: number=undefined;
    FileName: string=undefined;
    StartTime: string=undefined;
    CompleteDate: string=undefined;
    Seconds: number=undefined;
    Success: boolean=undefined;
    ExpenseRecords: number=undefined;
    Amount: number=undefined;
    CreateUser: string=undefined;
    ImportedRecords: number=undefined;
    ImportedAmount: number = undefined;
}
export class ProcessImportServiceNetJets {
    ImportLogId: number=undefined;
    ActivityMonth: string=undefined;
    FileName: string=undefined;
    StartTime: string=undefined;
    CompleteDate: string=undefined;
    Seconds: number=undefined;
    Success: boolean=undefined;
    ExpenseRecords: number = undefined;
}

// Parameter interfaces:

export interface GetAircraftParameters {
    ClientId: number;
    AircraftId: number;
    FullyOwnedOnly: boolean;
}

export interface GetAirportsParameters {
    Search: string;
    USOnly: boolean;
    FrequentOnly: boolean;
}

export interface GetExpensesParameters {
    ClientId: number;
    AircraftId: number;
    DateRangeStart: string;
    DateRangeEnd: string;
    Type: string;
    TripId: number;
    RequiresAttentionOnly: boolean;
    ExpenseId: number;
}

export interface GetImportActivityJetAviationParameters {
    ImportLogId: number;
}

export interface GetImportActivityNetJetsParameters {
    ImportLogId: number;
}

export interface GetImportCPRJetAviationParameters {
    ImportLogId: number;
}

export interface GetImportServicesNetJetsParameters {
    ImportLogId: number;
}

export interface GetJetAviationMonthlyParameters {
    Year: number;
    ClientId: number;
}

export interface GetJetAviationSummaryParameters {
    ClientId: number;
}

export interface GetNetJetsActivityInvoiceReconciliationParameters {
    ClientId: number;
    AircraftId: number;
}

export interface GetPurposeCompanyParameters {
    TripLegId: number;
}

export interface GetSLSDaysNYParameters {
    Year: number;
}

export interface GetTripInvoiceParameters {
    InvoiceId: number;
}

export interface GetTripLegDetailsParameters {
    TripLegId: number;
}

export interface GetTripLegManifestParameters {
    Type: string;
    TripLegId: number;
}

export interface GetTripLegsParameters {
    ClientId: number;
    AircraftId: number;
    DateRangeStart: string;
    DateRangeEnd: string;
    ForExcelExport: boolean;
    TripId: number;
    RequiresAttentionOnly: boolean;
    TripLegId: number;
    ImportLogId: number;
}

export interface GetTripsParameters {
    ClientId: number;
    AircraftId: number;
    DateRangeStart: string;
    DateRangeEnd: string;
}

export interface GetTripsForExpenseParameters {
    ExpenseId: number;
}

export interface ProcessCashDepreciationImportParameters {
    LogId: number;
}

export interface ProcessImportActivityNetJetsParameters {
    ImportLogId: number;
}

export interface ProcessImportCPRJetAviationParameters {
    ImportLogId: number;
}

export interface ProcessImportJetAviationFlightDataParameters {
    ImportLogId: number;
}

export interface ProcessImportServiceNetJetsParameters {
    ImportLogId: number;
}

export class AvService {

  GetAircraft (params: GetAircraftParameters): Promise<Aircraft[]> {
    return smartData({
        storedProcName: 'av.GetAircraft',
        params: params
      });
    }

  GetAirports (params: GetAirportsParameters): Promise<Airports[]> {
    return smartData({
        storedProcName: 'av.GetAirports',
        params: params
      });
    }

  GetDepreciationCashMethod (): Promise<DepreciationCashMethod[]> {
    return smartData({
        storedProcName: 'av.GetDepreciationCashMethod',
        params: {}
      });
    }

  GetDepreciationImport (): Promise<DepreciationImport[]> {
    return smartData({
        storedProcName: 'av.GetDepreciationImport',
        params: {}
      });
    }

  GetExpenses (params: GetExpensesParameters): Promise<Expenses[]> {
    return smartData({
        storedProcName: 'av.GetExpenses',
        params: params
      });
    }

  GetImportActivityJetAviation (params: GetImportActivityJetAviationParameters): Promise<ImportActivityJetAviation[]> {
    return smartData({
        storedProcName: 'av.GetImportActivityJetAviation',
        params: params
      });
    }

  GetImportActivityNetJets (params: GetImportActivityNetJetsParameters): Promise<ImportActivityNetJets[]> {
    return smartData({
        storedProcName: 'av.GetImportActivityNetJets',
        params: params
      });
    }

  GetImportCPRJetAviation (params: GetImportCPRJetAviationParameters): Promise<ImportCPRJetAviation[]> {
    return smartData({
        storedProcName: 'av.GetImportCPRJetAviation',
        params: params
      });
    }

  GetImportServicesNetJets (params: GetImportServicesNetJetsParameters): Promise<ImportServicesNetJets[]> {
    return smartData({
        storedProcName: 'av.GetImportServicesNetJets',
        params: params
      });
    }

  GetJetAviationMonthly (params: GetJetAviationMonthlyParameters): Promise<JetAviationMonthly[]> {
    return smartData({
        storedProcName: 'av.GetJetAviationMonthly',
        params: params
      });
    }

  GetJetAviationSummary (params: GetJetAviationSummaryParameters): Promise<JetAviationSummary[]> {
    return smartData({
        storedProcName: 'av.GetJetAviationSummary',
        params: params
      });
    }

  GetNetJetsActivityInvoiceReconciliation (params: GetNetJetsActivityInvoiceReconciliationParameters): Promise<NetJetsActivityInvoiceReconciliation[]> {
    return smartData({
        storedProcName: 'av.GetNetJetsActivityInvoiceReconciliation',
        params: params
      });
    }

  GetPurposeCompany (params: GetPurposeCompanyParameters): Promise<PurposeCompany[]> {
    return smartData({
        storedProcName: 'av.GetPurposeCompany',
        params: params
      });
    }

  GetSLSDaysNY (params: GetSLSDaysNYParameters): Promise<SLSDaysNY[]> {
    return smartData({
        storedProcName: 'av.GetSLSDaysNY',
        params: params
      });
    }

  GetTripInvoice (params: GetTripInvoiceParameters): Promise<TripInvoice[]> {
    return smartData({
        storedProcName: 'av.GetTripInvoice',
        params: params
      });
    }

  GetTripLegDetails (params: GetTripLegDetailsParameters): Promise<TripLegDetails[]> {
    return smartData({
        storedProcName: 'av.GetTripLegDetails',
        params: params
      });
    }

  GetTripLegManifest (params: GetTripLegManifestParameters): Promise<TripLegManifest[]> {
    return smartData({
        storedProcName: 'av.GetTripLegManifest',
        params: params
      });
    }

  GetTripLegs (params: GetTripLegsParameters): Promise<TripLegs[]> {
    return smartData({
        storedProcName: 'av.GetTripLegs',
        params: params
      });
    }

  GetTrips (params: GetTripsParameters): Promise<Trips[]> {
    return smartData({
        storedProcName: 'av.GetTrips',
        params: params
      });
    }

  GetTripsForExpense (params: GetTripsForExpenseParameters): Promise<TripsForExpense[]> {
    return smartData({
        storedProcName: 'av.GetTripsForExpense',
        params: params
      });
    }

  ProcessCashDepreciationImport (params: ProcessCashDepreciationImportParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'av.ProcessCashDepreciationImport',
        params: params
      });
    }

  ProcessImportActivityNetJets (params: ProcessImportActivityNetJetsParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'av.ProcessImportActivityNetJets',
        params: params
      });
    }

  ProcessImportCPRJetAviation (params: ProcessImportCPRJetAviationParameters): Promise<ProcessImportCPRJetAviation[]> {
    return smartData({
        storedProcName: 'av.ProcessImportCPRJetAviation',
        params: params
      });
    }

  ProcessImportJetAviationFlightData (params: ProcessImportJetAviationFlightDataParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'av.ProcessImportJetAviationFlightData',
        params: params
      });
    }

  ProcessImportServiceNetJets (params: ProcessImportServiceNetJetsParameters): Promise<ProcessImportServiceNetJets[]> {
    return smartData({
        storedProcName: 'av.ProcessImportServiceNetJets',
        params: params
      });
    }
}
