
    import { Component, Vue, Prop, Watch, Ref, VModel } from "vue-property-decorator";
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import ConsiderationList from '@/views/InvestmentTransaction/Consideration/ConsiderationList.vue';
    import * as AssetService from '@/services/DAL/assetService';
    import InvestmentSelector from '@/components/form/InvestmentSelector.vue';
    import cloneDeep from 'lodash/cloneDeep';
    import Common from '@/utils/common';
    import * as stakeTransactionTypes from './stakeTransactionTypes';

    import StakeTransactionInvestmentEdit from '@/views/InvestmentTransaction/Funds/StakeTransactionInvestmentEdit.vue';

    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;
    declare var SmartObject: any;

    @Component({
        components: {
            ConsiderationList,
            InvestmentSelector,
            StakeTransactionInvestmentEdit,
        }
    })
    export default class StakeTransactionInvestment extends Vue {
    @Ref() readonly refConsiderationList!: ConsiderationList;

    //#region Private declarations for Services
    private common: Common;

    //#endregion Private declarations for Services

    //#region Props
    @VModel({ type: Object }) stakeTransactionInvestment!: stakeTransactionTypes.iStakeTransactionInvestment;
    @Prop( { type: Object, required: true }) header: TransactionsService.Header;
    //#endregion Props

    //#region Data
    loading = false;
    securityLevel_: number = null;
    
    baseInvestment = {} as AssetService.InvestmentList;
    selectedCounterInvestment = {} as AssetService.InvestmentList;
    header_ = {} as TransactionsService.Header;
    
    
    //#endregion Data

    //#region Lifecycle
    async created() {
        this.common = new Common();

        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
        this.header_ = cloneDeep(this.header)
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('header')
    async headerChanged(val: TransactionsService.Header, oldVal: TransactionsService.Header) {
        this.header_ = cloneDeep(this.header);
    }
    
    //#endregion Watches

    //#region Computed
    get operation(): string{
        if (this.header_){
            return this.header_.SubTypeShort;
        }
    }
    get operationDirection(): string{
        if (this.header_){
            return this.header_.SubTypeBaseDirection;
        }
    }
    get isTransferOrSwap(): boolean{
        return (this.operation == 'Transfer' || this.operation == 'Swap') && !(this.header_ && this.header_.SubTypeScope == '3rd Party');
    }

    //#endregion Computed


    //#region Methods
    oppositeDirection (direction: string): string{
        if (direction == 'Result') return 'Source';
        if (direction == 'Source') return 'Result';
    }
     
    investmentSelected(investment: AssetService.InvestmentList){
        if (investment && investment.InvestmentId){
            this.baseInvestment = investment;
            if (
                    (this.isTransferOrSwap && this.selectedCounterInvestment && this.selectedCounterInvestment.Id)
                    || !this.isTransferOrSwap) this.stakeTransactionInvestment.isNew = false;
        }
    }
    counterInvestmentSelected(investment: AssetService.InvestmentList){
        if (investment && investment.InvestmentId){
            this.selectedCounterInvestment = investment;
            if (this.baseInvestment && this.baseInvestment.Id) this.stakeTransactionInvestment.isNew = false;
        }
    }
    stakeDeleted(){
        this.$emit('deleted')
    }
//#endregion Methods

    }
    
