
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as LiqService from '../../services/DAL/liq_Service';
import FormattedInput from '@/components/form/FormattedInput.vue';
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component({
    components: {
        FormattedInput
    }
})
export default class ManualBankTransactionEdit extends Vue {
    $refs: {
        frmManualBankTransactionEdit: ElementUI.Form
    }
    //#region Private Declarations
    private _liqService: LiqService.LiqService;
    //#endregion Private Declarations

    //#region Props
    @Prop() manualAccountTransaction: LiqService.ManualAccountTransaction;
    @Prop() manualAccountTransactionId: number
    @Prop() deletable: boolean;
    @Prop() viewType: string;
    //#endregion Props

    //#region Data
    manualAccountTransaction_: LiqService.ManualAccountTransaction = new LiqService.ManualAccountTransaction();
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();

        if (this.manualAccountTransaction && Object.keys(this.manualAccountTransaction).length) {
            this.manualAccountTransaction_ = this.manualAccountTransaction;
        }
        if (!this.manualAccountTransaction_.Id && this.manualAccountTransactionId) {
            await this.fetchManualAccountTransaction();
        }
    }
    //#endregion Lifecycle

    //#region Computed
    get isNew(): boolean {
        return (this.manualAccountTransactionId || 0) === 0 && (
            !this.manualAccountTransaction_ ||
            !Object.keys(this.manualAccountTransaction_).length ||
            (this.manualAccountTransaction_.Id || 0) === 0 // can't check <= 0 b/c Ids are negative
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    //#endregion Computed

    //#region Methods
    async fetchManualAccountTransaction() {
        const params = {} as LiqService.GetManualAccountTransactionParameters;
        params.ManualAccountTransactionId = this.manualAccountTransactionId;
        const dsManualAccountTransaction = await this._liqService.GetManualAccountTransaction(params);

        if (dsManualAccountTransaction.length) {
            this.manualAccountTransaction_ = dsManualAccountTransaction[0];
        }
        else {
            this.manualAccountTransaction_ = new LiqService.ManualAccountTransaction();
        }
    }
    
    async saveForm() {
        this.$refs.frmManualBankTransactionEdit.validate(async valid => {
            if (valid) {
                let message: string;
                const isNew = this.isNew;

                try {
                    if (isNew) {
                        this.manualAccountTransaction_.Id = await new SmartObject('ManualAccountTransactions').createObject(this.manualAccountTransaction_);
                        message = 'Manual transaction added.';
                    } else {
                        await new SmartObject('ManualAccountTransactions', this.manualAccountTransaction_.Id).updateObject(this.manualAccountTransaction_);
                        message = 'Changes saved.';
                    }
                }
                catch (err) {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                    return;
                }

                this.$notify.success(message);

                this.$emit('save', this.manualAccountTransaction_);
                this.$emit('update:manualAccountTransaction', this.manualAccountTransaction_);

                if (this.viewType === 'dialog') {
                    this.$emit('close');
                }
            }
        });
    }

    async deleteManualAccountTransaction() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this manual transaction. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    await new SmartObject('ManualAccountTransactions', this.manualAccountTransaction_.Id).deleteObject();

                    this.$notify.success('Manual transaction deleted.');

                    this.$emit('delete', this.manualAccountTransaction_.Id);

                    if (this.viewType === 'dialog') {
                        this.$emit('close');
                    }
                } catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
            } catch {}
        }
    }
    //#endregion Methods
}
