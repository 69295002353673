
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import FormattedInput from '@/components/form/FormattedInput.vue';
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component({
    components: {
        FormattedInput
    }
})
export default class ManagementFeeEdit extends Vue {
    $refs: {
        frmManagementFeeEdit: ElementUI.Form
    }
    //#region Data
    private _assetService: AssetService.AssetService;

    @Prop() managementFee: AssetService.ManagementFees;
    @Prop() investmentParentId: number;
    @Prop() viewType: string;

    managementFee_: AssetService.ManagementFees = new AssetService.ManagementFees();
    isLoading: boolean = false;
    validateEndDate: Function = (rule, value, callback) => {
        if (value < this.managementFee_.StartDate) {
            callback(new Error('End Date cannot be before Start Date'));
        }
        else {
            callback();
        }
    };
    //#endregion Data

    //#region Lifecycle
    created() {
        if (this.managementFee && Object.keys(this.managementFee).length) {
            this.managementFee_ = this.managementFee;
        }
        this._assetService = new AssetService.AssetService();
    }
    //#endregion Lifecycle

    //#region Computed
    get isNew(): boolean {
        return (
            !this.managementFee_ ||
            !Object.keys(this.managementFee_).length ||
            (this.managementFee_.Id || 0) <= 0
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    //#endregion Computed

    //#region Methods
    async saveForm() {
        this.$refs.frmManagementFeeEdit.validate(async valid => {
            if (valid) {
                let message: string;
                const isNew = this.isNew;
                this.isLoading = true;

                try {
                    if (isNew) {
                        this.managementFee_.InvestmentParentId = this.investmentParentId;
                        this.managementFee_.Id = await new SmartObject('FundManagementFee').createObject(this.managementFee_);
                        message = 'Management fee added.';
                    } else {
                        await new SmartObject('FundManagementFee', this.managementFee_.Id).updateObject(this.managementFee_);
                        message = 'Changes saved.';
                    }
                }
                catch (err) {
                    try {
                        const errJson = JSON.parse(err.responseText);
                        if (errJson.ExceptionMessage.toLowerCase().indexOf('daterange') > -1) {
                            this.$notify.error('The selected date range overlaps the date range of an existing management fee of this investment parent. Please change the date range and try again.');
                        }
                        else {
                            this.$notify.error('Something went wrong processing your request, please try again.');
                        }
                    }
                    catch {
                        this.$notify.error('Something went wrong processing your request, please try again.');
                    }
                    this.isLoading = false;
                    return;
                }

                this.$notify.success(message);
                this.isLoading = false;

                this.$emit('save', this.managementFee_.Id);
                this.$emit('update:managementFee', this.managementFee_);

                if (isNew) {
                    this.$emit('close');
                }
            }
        });
    }

    async deleteManagementFee() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this management fee. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    this.isLoading = true;
                    await new SmartObject('FundManagementFee', this.managementFee_.Id).deleteObject();

                    this.$notify.success('Management fee deleted.');
                    this.isLoading = false;

                    this.$emit('delete', this.managementFee_.Id);
                    this.$emit('close');
                } catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                    this.isLoading = false;
                }
            } catch {}
        }
    }
    //#endregion Methods
}
