<template>
    <div>
        <el-collapse v-model="activeNames" >
            <el-collapse-item title="Scheduled" name="scheduled">
                <span class="small">click a row to edit</span>
                <el-button type="primary" @click="newScheduleItem" icon="plus" size="mini" class="pull-right">New</el-button>

                <el-table :data='scheduleItems'
                            @row-click='scheduleClick'>
                    <el-table-column label="Date"
                                        class-name="small"
                                        min-width="150"
                                        sortable
                                        :formatter="formatterShortDate"
                                        prop="ScheduleDate">
                    </el-table-column>
                    <el-table-column prop="Amount"
                                        label="Amount"
                                        class-name="small"
                                        min-width="150"
                                        align="right"
                                        :formatter="formatterCurrency"
                                        sortable>
                    </el-table-column>

                    <el-table-column label="Invoices"
                                min-width="55"
                                align="right"
                                class-name="small"
                                prop="Invoices"
                                >
                        <template slot-scope="scope">
                            <el-button type="text" @click.stop="openNewInvoice(scope)" title="Click to create a new invoice for this agreement schedule" >+</el-button>
                            {{scope.row.Invoices}}
                        </template>
                    </el-table-column>

                    <el-table-column label="Matches"
                                min-width="55"
                                align="right"
                                prop="Matches"
                                class-name="small"
                                >
                    </el-table-column>

                    <el-table-column label="Matched Date"
                                min-width="100"
                                align="center"
                                class-name="small"
                                prop="MatchedDate"
                                :formatter="formatterShortDateTime">
                    </el-table-column>

                    <el-table-column prop="Modified"
                                        label="Modified"
                                        class-name="small"
                                        min-width="250"
                                        sortable>
                    </el-table-column>
                </el-table>
            </el-collapse-item>
            <el-collapse-item title="Allocation" name="allocation">
                <allocation :params="allocationParams" ref="allocationAgreementSpecific" :show-title="false" value-type="Percent" ></allocation>
            </el-collapse-item>
        </el-collapse>
        <el-dialog title="Schedule Item"
                    :visible.sync="showEditSchedule"
                    :before-close="beforeCloseEditScheduleItem"
                    >
            <schedule-item-edit v-if="showEditSchedule" :scheduleItem="editScheduleItem" @cancel="editScheduleItem={}" @saved="savedScheduleItem" @deleted="deletedScheduleItem"></schedule-item-edit>
        </el-dialog>
        <el-dialog :visible.sync="showNewInvoice" title="New Invoice (No Document)" width="50vw" top="1vh" append-to-body v-draggable @close="newInvoiceClosed">
            <new-invoice-view
                :invoice="newInvoiceSeed"
                v-if="showNewInvoice"
                view-type="dialog"
                @close-dialog="newInvoiceClosed"
                >
            </new-invoice-view>
        </el-dialog>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_liq from './../DAL/mixinSchema_liq.js'
    import ScheduleItemEdit from './../AP/ScheduleItemEdit.vue'
    import Allocation from './../Allocation/Allocation.vue'
    import NewInvoiceView from '@/js/AP/NewInvoiceView.vue';


    export default Vue.extend({
        name: 'SpecificSchedule'
        , mixins: [mixinUtility, mixinSchema_liq ]
        , components: {
            'schedule-item-edit': ScheduleItemEdit
            , 'allocation': Allocation
            , NewInvoiceView
        }
        , props: {
            EntityType: String
            , EntityId: Number
            , ClientId: Number
        }
        , data() {
            return {
                scheduleItems: []
                , editScheduleItem: {}
                , showEditSchedule: false
                , activeNames: ['scheduled', 'allocation']
                , allocationParams: {
                    EntityId: this.EntityId
                    , EntityType: this.EntityType
                    , ClientId: this.ClientId
                }
                , showNewInvoice: false
                , newInvoiceSeed: {}
                , agrement: {}
            }
        }
        , created: async function () {
            if (this.EntityType == 'Agreement') {
                await this.fetchAgreement();
            }
            this.fetchScheduleItems();
        }
        , computed: {
        }
        , watch: {
        }
        , methods: {
            async fetchScheduleItems() {
                var items = await this.liq_GetScheduleItem({
                    EntityType: this.EntityType
                    ,EntityId:  this.EntityId
                });
                if (this.EntityType == 'Agreement' && !!this.agreement && !!this.agreement.TerminationDate) {
                    items = items.filter(x => (x.ScheduleDate <= this.agreement.TerminationDate) || x.Invoices > 0 || x.Matches > 0);
                }
                if (items.length > 10) {
                    this.activeNames = ['allocation']; //if > 10, don't expand the Scheduled tab.
                }
                this.scheduleItems = items;
            }
            , async fetchAgreement(){
                if (this.EntityType != 'Agreement') return;
                this.agreement = {};
                const agreements = await this.liq_GetAgreements({
                    AgreementId: this.EntityId
                });
                if (agreements && agreements.length == 1) this.agreement = agreements[0];
            }
            , scheduleClick: function (editScheduleItem) {
                this.editScheduleItem = editScheduleItem;
                this.showEditSchedule = true;
            }
            , beforeCloseEditScheduleItem: function (done) {
                this.editScheduleItem = {};
                done();
            }
            , deletedScheduleItem: function (deletedScheduleItem) {
                const index = this.scheduleItems.indexOf(deletedScheduleItem);
                this.scheduleItems.splice(index, 1);
                this.editScheduleItem = {}; //hides modal
                this.showEditSchedule = false;
            }
            , newScheduleItem: function () {
                const editScheduleItem = this.liq_GetScheduleItem_New();
                editScheduleItem.EntityId = this.EntityId;
                editScheduleItem.EntityType = this.EntityType;
                this.editScheduleItem = editScheduleItem;
                this.showEditSchedule = true;
            }
            , savedScheduleItem: function (editScheduleItem) {
                const index = this.scheduleItems.indexOf(editScheduleItem);
                if (index == -1) {//new, so add it to the array
                    this.scheduleItems.push(editScheduleItem);
                }
                this.editScheduleItem = {};
                this.showEditSchedule = false;
            }
            , openNewInvoice(scope){
                this.newInvoiceSeed = {
                    ClientId: this.agreement.ClientId
                    , OwnerId: this.agreement.OwnerId
                    , VendorId: this.agreement.VendorId
                    , AgreementId: this.agreement.AgreementId
                    , ReferenceEntityId: scope.row.Id
                    , ScheduleItemId: scope.row.Id
                    , ReferenceEntityType: 'ScheduleItem'
                    , Amount: scope.row.Amount
                    , DueDate: scope.row.ScheduleDate
                }
                this.showNewInvoice = true;
            }            
            , newInvoiceClosed(){
                this.fetchScheduleItems(); 
                this.showNewInvoice = false; 
                this.newInvoiceSeed = {};
            }
        }
    })
</script>
<style>
    .el-form-item__error {
        position: relative;
    }
</style>