
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import * as AssetService from "@/services/DAL/assetService";
import Common from "@/utils/common";
import formatters from "@/utils/formatters";
import ElementUI from "element-ui";
import uniqBy from 'lodash/uniqBy';
import alasql from 'alasql';
import * as XLSX from 'xlsx';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;

declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function getAvailableScreenHeightInPixels(input: number);

@Component({})
export default class CommitmentAnalysis extends Vue {
  //#region Private declarations for Services
  private _assetService: AssetService.AssetService;
  public common: Common;
  public formatters: formatters;

  //#endregion Private declarations for Services

  //#region Props
  @Prop({ type: Number }) readonly investmentId: number;
  @Prop({ type: Number }) readonly ExcludeTransactionHeaderId: number;
  @Prop({ type: [String, Date] }) readonly asOf: string | Date;
  //#endregion Props

  //#region Data
  securityLevel_: number = null;
  loading = false;
  commitmentAnalysis = []  as AssetService.CommitmentAnalysis[];
  
  showPrefund = false;
  showPrefundCheckBox = false;

  //#endregion Data

  //#region Lifecycle
  async created() {
    this._assetService = new AssetService.AssetService();
    this.formatters = new formatters();
    this.common = new Common();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
      0
    );
    this.fetchCommitmentAnalysis();
    
  }
  async mounted() {
  }
  //#endregion Lifecycle
  //#region Watches

  @Watch('investmentId')
    onChange_investmentId(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.fetchCommitmentAnalysis();
        }
    }
  @Watch('asOf')
    onChange_asOf(val: string | Date, oldVal: string | Date) {
        if (oldVal !== undefined) {
            this.fetchCommitmentAnalysis();
        }
    }

  //#endregion Watches

  get filteredCommitmentAnalysis(): AssetService.CommitmentAnalysis[]{
    return this.commitmentAnalysis.filter(ca => ((ca.RowType == 'Standard' && !this.showPrefund) || this.showPrefund));
  }

//#region Methods
  highlightFuture({row, column, rowIndex, columnIndex}): object {
    if (column.property.indexOf('Date') > -1 && this.$dayjs(row[column.property]).isAfter(this.$dayjs(), 'day')){ // only applies to diff columns, and only if DiffPct is non-0
      return {'background-color': 'yellow'};
    }
    else return {};
  }

async fetchCommitmentAnalysis(){
    this.loading = true;
    this.showPrefund = false;
    this.showPrefundCheckBox = false;
    const params = {} as AssetService.GetCommitmentAnalysisParameters;
    params.InvestmentId = this.investmentId;
    params.AsOf = (this.asOf) ? this.$dayjs(this.asOf).format('MM/DD/YYYY') : undefined;
    params.ExcludeTransactionHeaderId = this.ExcludeTransactionHeaderId;
    this.commitmentAnalysis = await this._assetService.GetCommitmentAnalysis(params);
    this.$emit('fetched', this.commitmentAnalysis);
    if (this.commitmentAnalysis && this.commitmentAnalysis.some(ca => ca.Prefund != null)) {
      this.showPrefund = true;
      this.showPrefundCheckBox = true;
    }
    this.loading = false;
}
  filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
  }
  filtersCapitalCommitment(column){
      const filters = this.commitmentAnalysis.map(function(list) {
          return {
              text: list[column]
              , value: list[column]
              };
      });
      return uniqBy(filters, 'value');
  }
  getSummary(param) {
    const adder = this.showPrefund ? 2 : 0;
      const columnList = [5,6, this.showPrefund ? 7 : undefined];
      const totalLabel = 'Total';
      const totalLabelIndex = 1;
      const formats = new Map();
      formats.set(5, 'currency');
      formats.set(6, 'currency');
      if (this.showPrefund )formats.set(7, 'currency');
      const result = this.common.getSummaryArray(param, columnList, totalLabel, totalLabelIndex, formats);
      // add Commitment and Calls together to get Remaining.  Should be the same as the last line, but this will put it in the Total row:
      result[7+adder] = this.$accounting.formatMoney(this.$accounting.parse(result[5]) + this.$accounting.parse(result[6]));
      return result;
  }
    async editInvestmentTransaction(
        selectedRow: AssetService.CommitmentAnalysis,
        event,
        column
    ) {
        if (selectedRow && selectedRow != null) {
            const transactionHeaderId = selectedRow.TransactionHeaderId.toString();
            this.$router.push({ name: 'Investment Transaction', params: { transactionHeaderId } }).catch(() => {});
        }
    }
    async exportItems(){
        if (this.commitmentAnalysis.length > 0) {
            var opts = [{ sheetid: this.investmentId.toString(), header: true }];
            try {
                
              await alasql.promise(`SELECT * INTO XLSX("Commitment  ${this.investmentId.toString()}.xlsx",?) FROM ?`, [opts, [this.commitmentAnalysis]])
              this.$notify.success('Excel file exported.');
            }
            catch(err) {
              this.$notify.error(err.message);
            }
        }
    }

  //#endregion Methods
}
