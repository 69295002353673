
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import * as LiqService from '@/services/DAL/liq_Service';
    import EventLogList from '@/views/Event/EventLogList.vue';


    @Component({
        components: {
            EventLogList
        }
    })
    export default class ProcessCashflow extends Vue {

    //#region Private declarations for Services
    private _liqService: LiqService.LiqService;
    //#endregion Private declarations for Services

    //#region Props
    //#endregion Props

    //#region Data
    queueRecord = {} as LiqService.QueueProcessCashFlow;
    loading = false;
    showEventList = true;
    //#endregion Data

    //#region Lifecycle
    created() {
        this._liqService = new LiqService.LiqService();
    }
    //#endregion Lifecycle

    //#region Watches
    //#endregion Watches

    //#region Computed
    //#endregion Computed

    //#region Methods
    async queueProcess(){
        try {
            this.loading = true;
            const params = {} as LiqService.QueueProcessCashFlowParameters;
            const result: LiqService.QueueProcessCashFlow[] = await this._liqService.QueueProcessCashFlow(params);
            this.queueRecord = result[0];
            console.log(result);
            this.$notify.info(`Process queued (${this.queueRecord.Id})`);
            this.showEventList = false;
            await this.$nextTick();
            this.showEventList = true;
        } catch (err) {
            console.error(err);
        }
        this.loading = false;
    }
    //#endregion Methods

    }
