 <template>
    <div id="layout-edit">
        <el-row :gutter="20">
            Layout: <el-select v-model="selectedLayoutId"
                size="small"
                v-bind:disabled="(layouts.length==0)"
                filterable
                clearable>
                <el-option v-for="item in layouts"
                        :key="item.Id"
                        :label="item.Name"
                        :value="item.Id">
                </el-option>
            </el-select>
        </el-row>
        <el-row :gutter="20">
            <el-button type="text" @click="levelUp" v-show="location!=''" :disabled="selectedLayoutGroupStack.length<2"><i class="fa fa-level-up"></i> {{location}}</el-button>
        </el-row>
        <el-row :gutter="20" v-show="showLayoutGroups">
            <el-input v-model="filter" placeholder="filter" size="mini" v-if="layoutGroups[0]" clearable></el-input>
            <el-table v-if="layoutGroups[0]"
                    :data="filteredLayoutGroups"
                    style="width: 100%;"
                    max-height="400"
                    stripe
                    @row-click="layoutGroupSelect">
                <el-table-column label=""
                                min-width="50px">
                    <template slot-scope="scope">
                        <i :class="scope.row.Icon"></i>
                    </template>
                </el-table-column>
                <el-table-column label="Group"
                                min-width="400px">
                    <template slot-scope="scope">
                        <span :title="scope.row.Id" >{{scope.row.Name}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row :gutter="20" v-show="showLayoutGroupDetail">
            <el-input v-model="filterDetails" placeholder="filter" size="small" v-if="layoutGroupDetails[0]"></el-input>
            <el-table v-if="layoutGroupDetails[0]"
                    :data="filteredLayoutGroupDetails"
                    style="width: 100%;"
                    max-height="400"
                    stripe 
                    @row-click="layoutGroupDetailsSelect">
                <el-table-column label="Account (CoA)" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'AccountId')">
                    <template slot-scope="scope">
                        <span :title="scope.row.AccountId">{{scope.row.AccountNamePath}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Department" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'DepartmentId')">
                    <template slot-scope="scope">
                        <span :title="scope.row.DepartmentId">{{scope.row.DepartmentName}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Owner" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'OwnerId')">
                    <template slot-scope="scope">
                        <span :title="scope.row.OwnerId">{{scope.row.OwnerName}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Include Child Accts" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'IncludeDescendantAccountsNull')">
                    <template slot-scope="scope">
                        <span>{{scope.row.IncludeDescendantAccounts}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Factor" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'Factor')">
                    <template slot-scope="scope">
                        <span>{{scope.row.Factor}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Sign" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'Sign')">
                    <template slot-scope="scope">
                        <span>{{scope.row.Sign}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Exclude" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'Exclude')">
                    <template slot-scope="scope">
                        <span>{{scope.row.Exclude}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Entity Type" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'EntityType')" >
                    <template slot-scope="scope">
                        <span>{{scope.row.EntityType}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Entity Name" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'EntityName')">
                    <template slot-scope="scope">
                        <span :title="scope.row.EntityId">{{scope.row.EntityName}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Client" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'ClientId')">
                    <template slot-scope="scope">
                        <span>{{scope.row.ClientId}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Valid From" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'ValidFrom')">
                    <template slot-scope="scope">
                        <span >{{scope.row.ValidFromDisplay | shortDate}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Valid To" 
                    v-if="HideIfColumnEmpty(layoutGroupDetails, 'ValidTo')">
                    <template slot-scope="scope">
                        <span>{{scope.row.ValidToDisplay | shortDate}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Modified" >
                    <template slot-scope="scope">
                        <span :title="scope.row.Id">{{scope.row.Modified}}</span>
                    </template>
                </el-table-column>

            </el-table>
        </el-row>
    </div>     

</template>
<script>
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_reports from './../DAL/mixinSchema_reports'
    import Vue from 'vue';

    export default Vue.extend({
        name: 'LayoutEdit'
        , mixins: [mixinSchema_reports, mixinUtility]
        , props: {
        }
        , data() {
            return {
                selectedLayoutId: null
                , layouts: []
                , layoutGroups: []
                , filteredLayoutGroups: []
                , layoutGroupDetails: []
                , filteredLayoutGroupDetails: []
                , selectedLayoutGroupStack:[
                    {
                        layoutId: null
                        , layoutType: null
                        , name: ''
                        , source: ''
                    }
                ]
                , filter: ''
                , filterDetails: ''
                , showLayoutGroups: true
                , showLayoutGroupDetail: false
            }
        }
        , mounted: function () {
            this.reset();
            this.fetchLayouts();
        }
        , computed: {
            location: function(){
                var out = '';
                for (var i = 0; i < this.selectedLayoutGroupStack.length; i++) { 
                    if (this.selectedLayoutGroupStack[i].name){
                        out += ((i > 0) ? ' > ' : '') + this.selectedLayoutGroupStack[i].name;
                    }
                }
                return out;
            }
        }
        , watch: {
            'filterDetails': function(val, oldVal){
                this.filteredLayoutGroupDetails = this.layoutGroupDetails.filter(function(e) {
                    return e.AccountNamePath.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.OwnerName.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.DepartmentName.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.Sign.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.EntityType.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.EntityName.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.EntityId.indexOf(val.toLowerCase()) !== -1
                        || e.Id.indexOf(val.toLowerCase()) !== -1
                        || e.Factor.indexOf(val.toLowerCase()) !== -1
                        ;
                });
            }
            , 'filter': function(val, oldVal){
                this.filteredLayoutGroups = this.layoutGroups.filter(function(e) {
                    return e.Name.toLowerCase().indexOf(val.toLowerCase()) !== -1;
                });
            }
            , 'selectedLayoutId': function(val, oldVal){
                this.selectedLayoutGroupStack = [
                    {
                        layoutId: val
                        , name: this.getSelectedArrayItem(this.layouts, val, 'Id').Name
                        , layoutGroupId: null
                        , source: 'LayoutGroup'
                    }
                ];
                this.fetchTable();

            }
        }
        , methods: {
            reset: function() {
                this.layouts = [];
                this.filteredLayoutGroups = [];
                this.selectedLayoutGroupStack = [
                    {
                        layoutId: null
                        , layoutGroupId: null
                        , name: null
                    }
                ]
                this.selectedLayouts = [];
            }
            ,layoutGroupSelect: function(selectedRow){
                this.selectedLayoutGroupStack.push({
                    layoutGroupId: selectedRow.Id
                    , name: selectedRow.Name
                    , source: (selectedRow.Children) ? 'LayoutGroup' : 'LayoutGroupDetail'
                })
                this.fetchTable();
            }
            , layoutGroupDetailsSelect: function(selectedRow){
                console.log(selectedRow);
            }
            , fetchLayouts: function(){
                this.layouts = this.reports_GetLayouts();
            }
            , fetchTable: function(){
                var layoutGroupId = null;
                var layoutId = null;
                if (this.selectedLayoutGroupStack.length>0){
                    if(this.selectedLayoutGroupStack[this.selectedLayoutGroupStack.length-1].layoutGroupId){
                        layoutGroupId = this.selectedLayoutGroupStack[this.selectedLayoutGroupStack.length-1].layoutGroupId;
                    }
                    else {
                        layoutId = this.selectedLayoutGroupStack[this.selectedLayoutGroupStack.length-1].layoutId;
                    }
                
                    if ((layoutId || layoutGroupId) && this.selectedLayoutGroupStack[this.selectedLayoutGroupStack.length-1].source == 'LayoutGroup'){
                        this.showLayoutGroups = true;
                        this.showLayoutGroupDetail = false;
                        this.layoutGroups = this.reports_GetLayoutGroups(layoutId, layoutGroupId);
                        this.filteredLayoutGroups = this.layoutGroups;
                    }
                    else if (this.selectedLayoutGroupStack[this.selectedLayoutGroupStack.length-1].source == 'LayoutGroup') {
                        this.layoutGroups = [];
                        this.filteredLayoutGroups = [];
                    }
                    else if ((layoutGroupId) && this.selectedLayoutGroupStack[this.selectedLayoutGroupStack.length-1].source == 'LayoutGroupDetail'){
                        this.showLayoutGroups = false;
                        this.showLayoutGroupDetail = true;
                        this.layoutGroupDetails = this.reports_GetLayoutGroupDetail(layoutGroupId);
                        this.filteredLayoutGroupDetails = this.layoutGroupDetails;
                    }
                    else if ((layoutGroupId) && this.selectedLayoutGroupStack[this.selectedLayoutGroupStack.length-1].source == 'LayoutGroupDetail'){
                        this.layoutGroupDetails = [];
                        this.filteredLayoutGroupDetails = [];
                    }
                }
            }
            , levelUp: function(){
                this.selectedLayoutGroupStack.splice(this.selectedLayoutGroupStack.length-1, 1);
                this.fetchTable();
            }
            ,unmapLayout: function(selectedRow, column, cell){
                if (column.label == ''){
                    this.$confirm('This will remove the selected mapping. Continue?', 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                        }).then(() => {
                            var index = this.selectedLayouts.indexOf(selectedRow);
                            this.selectedLayouts.splice(index, 1);
                            if (this.selectedLayoutId == selectedRow.SelectedLayoutId && this.selectedLayoutType == selectedRow.SelectedLayoutType){
                                //add it back to the left, if the left is the same source.
                                this.layouts.push(selectedRow);
                            }
                            this.$message({
                                type: 'success',
                                message: 'Removed'
                            });
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: 'Remove canceled'
                            });
                        });
                }
            }
            // , saveMaps: function(){
            //     var so = new SmartObject('AttachmentMap');
            //     var maps = [];
            //     for (var i = 0; i < this.selectedLayouts.length; i++) { 
            //             this.selectedLayouts[i].AttachmentId = this.attachment.Id;
            //             var attachmentMapId = so.createObject_lgcy(this.selectedLayouts[i]);
            //             if (attachmentMapId > 0){
            //                 this.selectedLayouts[i].attachmentMapId = attachmentMapId;
            //                 maps.push(this.selectedLayouts[i]);
            //             }
            //         }
            //     if (maps.length > 0){
            //         this.$message({
            //             type: 'info',
            //             message: 'Mapping records saved: ' + maps.length
            //         });
            //         this.$emit('attachment-mapped',maps);
            //     }
            // }
            , HideIfColumnEmpty: function(arrayOfObjects, columnName){
                var columnHasData = false;
                for (var j = 0; j < arrayOfObjects.length; j++){
                    if (arrayOfObjects[j][columnName]){
                        columnHasData = true;
                        return columnHasData;
                    }
                }
                return columnHasData;
            }
        }

    })
</script>
<style>
    .el-form-item__error {
        position: relative;
    }
</style>