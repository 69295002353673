const state = {
  systemStatusMessage: '',
};

const mutations = {
  systemStatusMessage(state, systemStatusMessage: string) {
    state.systemStatusMessage = systemStatusMessage;
  }
};

const actions = {
};
const getters = {
  systemStatusMessage: state => {
    return state.systemStatusMessage;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
