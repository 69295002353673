
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FormattedInput from '@/components/form/FormattedInput.vue';
import * as TransactionsService from '../../services/DAL/transactionsService';
import * as specificLotTypes from '@/views/InvestmentTransaction/specificLot';
import cloneDeep from 'lodash/cloneDeep';

declare var SmartObject: any;

@Component({
    components: {
        FormattedInput
    }
})
export default class SpecificLotEdit extends Vue {
    //#region Private declarations for Services
    //#endregion Private declarations for Services

    //#region Props
    @Prop({required: true}) specificLot: specificLotTypes.SpecificLot;
    //#endregion Props

    //#region Data
    specificLot_: specificLotTypes.SpecificLot;
    isLoading = false;
    
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
        key => (this as any).veeFields[key].dirty
        );
    }

    
    //#endregion Data

    //#region Lifecycle
    created() {
        this.specificLot_ = cloneDeep(this.specificLot);
    }
    mounted() {
        this.focus();
    }
    //#endregion Lifecycle
    
    //#region Watches
    //#endregion Watches

    //#region Methods
    save(){
        (this.$refs.frmSpecificLotEdit as HTMLFormElement).validate(async valid => {
            if (valid) {
                try{
                    const specificLotToUpdate = cloneDeep(this.specificLot_);
                    delete specificLotToUpdate.Id; 
                    const id = await new SmartObject('SpecificLot', this.specificLot_.Id).updateObject(
                        specificLotToUpdate
                    );
                    this.specificLot_.Id = id;
                    this.$notify.success('Specific Lot updated');
                    this.$emit('save', this.specificLot_);
                }
                catch (ex) {
                    this.$notify.error(ex.message);
                    console.error(ex);
                }
            }
        });
    }
    focus(){
        (this.$refs.inp as HTMLFormElement).focus();
    }
    async deleteSpecificLot() {
        try {
            await this.$confirm(
                'Are you sure you want to delete this Specific Lot?',
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );

            try {
                this.isLoading = true;
                await new SmartObject('SpecificLot', this.specificLot_.Id).deleteObject();

                this.$notify.success('Configuration deleted.');
                this.isLoading = false;

                this.$emit('deleted', this.specificLot_.Id);
                this.$emit('close');
            } catch (ex) {
                this.$notify.error('Something went wrong processing your request, please try again.');
                console.error(ex);
                this.isLoading = false;
            }
        } catch {} // no from Confirm
    }
    
    //#endregion Methods
}
