
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as TaxService from '../../services/DAL/taxService';
import TaxConfigurationEdit from '@/views/TaxConfiguration/TaxConfigurationEdit.vue';
import PaymentScheduleList from '@/views/TaxConfiguration/PaymentScheduleList.vue';
import formatters from '../../utils/formatters';

@Component({
    components: {
        TaxConfigurationEdit,
        PaymentScheduleList
    }
})
export default class TaxConfigurationTabView extends Vue {
    //#region Private Declarations
    private _taxService: TaxService.TaxService;
    private formatters: formatters;
    //#endregion Private Declarations

    //#region Props
    @Prop() importConfiguration: TaxService.ImportConfiguration;
    @Prop() viewType: string;
    //#endregion Props

    //#region Data
    importConfiguration_: TaxService.ImportConfiguration = new TaxService.ImportConfiguration();
    modelPaymentSchedules: TaxService.ModelPaymentSchedules[] = [];
    hasLoaded_schedules: boolean = false;
    isLoading_schedules: boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._taxService = new TaxService.TaxService();
        this.formatters = new formatters();

        if (this.importConfiguration && Object.keys(this.importConfiguration).length) {
            this.importConfiguration_ = this.importConfiguration;
        }
    }
    //#endregion Lifecycle

    //#region Methods
    onTabClick(tab) {
        if (tab.label === 'Payment Schedules' && !this.hasLoaded_schedules) {
            this.fetchModelPaymentSchedules();
            this.hasLoaded_schedules = true;
        }
    }

    async fetchModelPaymentSchedules() {
        this.isLoading_schedules = true;
        this.modelPaymentSchedules = [];
        const params = {} as TaxService.GetModelPaymentSchedulesParameters;
        params.ImportConfigurationId = this.importConfiguration_.ImportConfigurationId;
        this.modelPaymentSchedules = await this._taxService.GetModelPaymentSchedules(params);
        this.isLoading_schedules = false;
    }
    //#endregion
}
