
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as TransactionsService from '../../services/DAL/transactionsService';
import * as AssetService from '../../services/DAL/assetService';
import InvestmentTransactionAllocationEdit from '@/views/InvestmentTransaction/InvestmentTransactionAllocationEdit.vue';
import * as allocationTypes from '@/views/InvestmentTransaction/investmentTransactionAllocation';
import 'vue-organization-chart/dist/orgchart.css';

declare function tryParseInt(input: any, defaultValue: number): number;
declare function tryParseFloat(input: any, defaultValue: number): number;
declare function getStoredSecurityLevel(Id: number): number;
declare var process: any;

@Component({
    components: {
        InvestmentTransactionAllocationEdit
    }
})
export default class InvestmentInvestmentTransactionOwnerAllocation extends Vue {
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private _assetService: AssetService.AssetService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() hierarchy: any[];
    @Prop({ type: Number, default: null })
    securityLevel: number;
    @Prop() viewType: string;

    //#endregion Props

    //#region Data
    securityLevel_: number = this.securityLevel;
    selectedAllocation: allocationTypes.InvestmentTransactionAllocation = new allocationTypes.InvestmentTransactionAllocation();
    showAllocationEdit = false;
    //#endregion Data

    //#region Computed
    get sharesOrAmount(): String {
        if (!this.hierarchy) return 'Shares';
        else if (this.hierarchy[0].ShowShares)
            return 'Shares';
        else
        return 'Amount';
    }
    //#endregion Computed

//#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this._assetService = new AssetService.AssetService();

        if (!this.securityLevel_) {
            this.securityLevel_ = tryParseInt(
                getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP),
                0
            );
        }

    }
    mounted() {
    }
    //#endregion Lifecycle

    async editShares(data: allocationTypes.InvestmentTransactionAllocation){
        this.selectedAllocation = null;
        if (data.Id == '0'){
            this.$alert('Top Level Allocation cannot be edited. It is set by the main Transaction data.', 'No Edit');
            return;
        }
        await this.$nextTick(); //reset previous selection
        this.selectedAllocation = data;
        this.showAllocationEdit = true;
    }
    sumChildrenValue (children) {
        if (children && children.length > 0) {
            return children.reduce(function (a, b) {
                return b.Value == null ? a : tryParseFloat(a, 0) + tryParseFloat(b.Value, 0);
            }, 0);
        }
        else {
            return 0;
        }
    }
    cancelledAllocationEdit(){
        this.showAllocationEdit = false;
        console.trace('showAllocationEdit = false by cancelledAllocationEdit');  // trouble shooting issue with Allocation Edit closing unexpectedly.  removed after solved.
        this.selectedAllocation = new allocationTypes.InvestmentTransactionAllocation();
    }
    savedAllocationEdit(allocation: allocationTypes.InvestmentTransactionAllocation){
        this.showAllocationEdit = false;
        this.selectedAllocation.Shares = allocation.Shares;
        this.selectedAllocation.Amount = allocation.Amount;
        this.selectedAllocation.Value = allocation.Shares || allocation.Amount; //  this is what is going to get totalled
        if (this.selectedAllocation.TransactionAllocationId == -1){
            this.selectedAllocation.TransactionAllocationId = allocation.TransactionAllocationId;
        }
    }
}
