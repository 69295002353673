 <template>
    <el-row :gutter="20">
        <el-col :span="13" v-if="!hideList_">
            <el-card class="box-card" ref="selectCard">
                <el-row type="flex" align="middle" :gutter="10" style="margin-bottom: 5px;">
                    <el-col :span="7" v-show="clients.length > 1">
                        <el-select
                            v-model="selectedClientId"
                            size="mini"
                            class="fullContent"
                            placeholder="Select Client"
                            :disabled="!clients.length"
                            clearable filterable default-first-option>
                            <el-option v-for="client in clients" :key="client.Id" :label="client.Name" :value="client.Id"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="7">
                        <owner-selector
                            v-model="selectedOwnerId"
                            :client-id="selectedClientId"
                            :uses-list="(listConfiguration && Object.keys(listConfiguration)) ? listConfiguration.FilterOwnerUses : null"
                            size="mini"
                            class="fullContent"
                            placeholder="Select Owner"
                            clearable filterable default-first-option>
                        </owner-selector>
                    </el-col>
                    <el-col :span="7">
                        <el-select
                            v-model="selectedVendorId"
                            size="mini"
                            class="fullContent"
                            placeholder="Select Vendor"
                            :disabled="!vendors.length"
                            clearable filterable default-first-option>
                            <el-option v-for="vendor in vendors" :key="vendor.Id" :label="vendor.Name" :value="vendor.Id"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="3">
                        <el-button type="primary" plain size="mini" icon="el-icon-search" @click="fetchAgreements">Search</el-button>
                    </el-col>
                </el-row>
                <el-row type="flex" align="middle" :gutter="10">
                    <el-col :span="7" v-if="!listConfiguration.HideGroupFilter">
                        <el-select
                            v-model="selectedGroupId"
                            size="mini"
                            class="fullContent"
                            placeholder="Select Group"
                            :disabled="!groups.length"
                            clearable filterable default-first-option>
                            <el-option v-for="group in groups" :key="group.AgreementGroupId" :label="group.Name" :value="group.AgreementGroupId"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="7" v-if="!agreementTypeId_">
                        <agreement-type-selector
                            v-model="selectedAgreementTypeId"
                            size="mini"
                            class="fullContent"
                            placeholder="Select Agreement Type"
                            clearable filterable default-first-option>
                        </agreement-type-selector>
                    </el-col>
                    <el-col :span="7">
                        <el-checkbox v-model="currentOnly" label="Current Only" style="margin: 3px 0px 0px 10px;"></el-checkbox>
                    </el-col>
                </el-row>
            </el-card>
            <el-card class="box-card">
                <div slot="header" class="clearfix agreementListHeader">
                    <span v-if="listConfiguration.Title">{{listConfiguration.Title}}</span>
                    <span v-else>Agreements</span>
                    <el-button type="primary" plain @click="handleNewCharitableDonation" icon="el-icon-plus" size="mini" class="pull-right" v-if="!listConfiguration.HideNewCharitableDonationButton && securityLevel_>=80" style="margin-left: 6px;">Charitable Donation</el-button>
                    <el-button type="primary" plain @click="handleNewAgreement" icon="el-icon-plus" size="mini" class="pull-right" v-if="!listConfiguration.HideNewAgreementButton && securityLevel_>=80">Agreement</el-button>
                </div>
                <div v-loading.body="loading">
                    <el-row style="margin-bottom: 10px;">
                        <el-col :span="8" :push="16">
                            <el-input v-model="filter" placeholder="filter" size="mini" clearable suffix-icon="el-icon-search" style="max-width: 250px;"></el-input>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-table
                                    ref="refAgreementsList"
                                    :data="agreementsFiltered"
                                    :height="$getAvailableScreenHeightInPixels(248)"
                                    row-key="AgreementId"
                                    :row-class-name="tableRowClassName"
                                    highlight-current-row
                                    empty-text="No Data"
                                    @row-click="editAgreementsListItem">
                                <el-table-column label=""
                                                min-width="18"
                                                prop="HasError">
                                    <template slot-scope="scope">
                                        <i class="el-icon-warning" :title="scope.row.ErrorMessage" v-show="scope.row.HasError"></i>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Name"
                                                min-width=""
                                                sortable
                                                class-name="overflow"
                                                prop="Name">
                                    <template slot-scope="scope">
                                        <span :title="scope.row.AgreementId">{{scope.row.Name}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Owner"
                                                min-width=""
                                                sortable
                                                class-name="overflow"
                                                prop="OwnerName">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.OwnerName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Vendor"
                                                min-width=""
                                                sortable
                                                class-name="overflow"
                                                prop="VendorName">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.VendorName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Account"
                                                min-width=""
                                                sortable
                                                class-name="overflow"
                                                prop="BankAccountNameDisplay">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.BankAccountNameDisplay }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Schedule"
                                                min-width=""
                                                sortable
                                                prop="ScheduleType">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.ScheduleType }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
        </el-col>
        <el-col :span="11">
            <agreement-edit-view :agreement.sync="selectedAgreement" v-if="showAgreementEditView" @agreement-copied="agreementCopied" @agreement-deleted="agreementDeleted" :style="{ height: $getAvailableScreenHeight(), overflow: 'auto' }"></agreement-edit-view>
            <charitable-donation-edit :agreement.sync="selectedAgreement" viewType="card" v-if="showCharitableDonationEdit" @saved="switchToEditView" @closed="showCharitableDonationEdit = false" @deleted="agreementDeleted" :style="{ height: $getAvailableScreenHeight() }"></charitable-donation-edit>
        </el-col>
    </el-row>
</template>
<script>
import Vue from 'vue';
import mixinSchema_visium from './../DAL/mixinSchema_visium';
import mixinSchema_vam from './../DAL/mixinSchema_vam';
import mixinSchema_liq from './../DAL/mixinSchema_liq';
import AgreementEditView from './AgreementEditView.vue';
import CharitableDonationEdit from '@/views/Agreements/CharitableDonationEdit.vue';
import findIndex from 'lodash/findIndex';
import OwnerSelector from '@/components/form/OwnerSelector.vue';
import AgreementTypeSelector from '@/components/form/AgreementTypeSelector.vue';

export default Vue.extend({
    name: 'AgreementListView',
    mixins: [mixinSchema_vam, mixinSchema_visium, mixinSchema_liq],
    props: [
        'agreementTypeId',
        'initialClientId',
        'initialAgreementGroupId',
        'initialAgreementId',
        'hideList',
        'securityLevel'
        // 'addAgreement',
        // 'addCharitableDonation'
    ],
    components: {
        'agreement-edit-view': AgreementEditView,
        'charitable-donation-edit': CharitableDonationEdit,
        'owner-selector': OwnerSelector,
        'agreement-type-selector': AgreementTypeSelector
    },
    data: function() {
        return {
            agreementTypeId_: this.agreementTypeId,
            agreementType: {},
            listConfiguration: {},
            agreements: [],
            filter: '',
            clients: [],
            vendors: [],
            groups: [],
            selectedClientId: undefined,
            selectedOwnerId: undefined,
            selectedVendorId: undefined,
            selectedGroupId: undefined,
            selectedAgreementTypeId: undefined,
            currentOnly: true,
            selectedAgreement: this.liq_GetAgreements_New(),
            showAgreementEditView: false,
            showCharitableDonationEdit: false,
            hideList_: false,
            loading: false
            , securityLevel_: undefined
        };
    },
    created: async function() {
        if (!this.securityLevel_) {
            this.securityLevel_ = tryParseInt(
                getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP),
                0
            );
        }
        if (this.agreementTypeId_ && this.agreementTypeId_.toString().toLowerCase() === 'null') {
            this.agreementTypeId_ = null;
        }
        if (this.agreementTypeId_ > 0) {
            this.selectedAgreementTypeId = parseInt(this.agreementTypeId_);
            
            const at = new SmartObject('AgreementType', this.agreementTypeId_);
            await at.loadData();
            this.agreementType = at.dataObject || {};

            try {
                const configuration = JSON.parse(this.agreementType.Configuration);
                if (configuration && configuration.ListConfiguration) {
                    this.listConfiguration = configuration.ListConfiguration;
                }
            }
            catch (ex) {
                this.listConfiguration = {};
            }
        }
        if (this.initialClientId > 0) {
            this.selectedClientId = tryParseInt(this.initialClientId, null);
        }
        if (this.initialAgreementGroupId > 0) {
            this.selectedGroupId = tryParseInt(
                this.initialAgreementGroupId,
                null
            );
        }

        this.selectedClientId = this.selectedClientId || null;
        this.selectedOwnerId = this.selectedOwnerId || null;
        this.selectedVendorId = this.selectedVendorId || null;
        this.selectedGroupId = this.selectedGroupId || null;
        this.selectedAgreementTypeId = this.selectedAgreementTypeId || null;

        this.hideList_ = tryParseBool(this.hideList, false);

        if (!this.hideList_) {
            await this.fetchClients();
            await this.fetchVendors();
            await this.fetchGroups();
        }

        if (this.initialAgreementId > 0) {
            this.fetchAgreement(this.initialAgreementId);
        }
    },
    mounted: function() {
    },
    watch: {
        selectedClientId: async function(val, oldVal) {
            if (oldVal === undefined) {
                // initial programmatic change
                return;
            }

            this.selectedOwnerId = null; // if Client is changed, then owner must be reset
            this.selectedGroupId = null;

            if (val == '') {
                this.selectedClientId = null;
                return; // this is going to fire the watch again, so we don't want to do the next steps twice
            }
            
            await this.fetchVendors();
            await this.fetchGroups();
            
            if (val != null) {
                setAppCookieKeyValue_lgcy('SelectedClientId', val);
            }

            this.fetchAgreementsIfCriteriaSpecified();
        },
        selectedOwnerId: async function(val, oldVal) {
            if (oldVal === undefined) {
                // initial programmatic change
                return;
            }

            if (val == '') {
                this.selectedOwnerId = null;
                return; // this is going to fire the watch again, so we don't want to do the next steps twice
            }

            this.fetchAgreementsIfCriteriaSpecified();
        },
        selectedVendorId: async function(val, oldVal) {
            if (oldVal === undefined) {
                // initial programmatic change
                return;
            }

            if (val == '') {
                this.selectedVendorId = null;
                return; // this is going to fire the watch again, so we don't want to do the next steps twice
            }
            await this.fetchGroups();

            this.fetchAgreementsIfCriteriaSpecified();
        },
        selectedGroupId: async function(val, oldVal) {
            if (oldVal === undefined) {
                // initial programmatic change
                return;
            }

            if (val == '') {
                this.selectedGroupId = null;
                return; // this is going to fire the watch again, so we don't want to do the next steps twice
            }

            this.fetchAgreementsIfCriteriaSpecified();
        },
        selectedAgreementTypeId: async function(val, oldVal) {
            if (oldVal === undefined) {
                // initial programmatic change
                return;
            }

            if (val == '') {
                this.selectedAgreementTypeId = null;
                return; // this is going to fire the watch again, so we don't want to do the next steps twice
            }

            this.fetchAgreementsIfCriteriaSpecified();
        },
        currentOnly: async function(val, oldVal) {
            this.fetchAgreementsIfCriteriaSpecified();
        },
        'selectedAgreement.AgreementId': function(val, oldVal) {
            const index = findIndex(this.agreements, agreement => {
                return (
                    agreement.AgreementId === this.selectedAgreement.AgreementId
                );
            });
            if (index < 0 && this.selectedAgreement.AgreementId > 0) {
                this.agreements.push(this.selectedAgreement);
            }
        }
    },
    computed: {
        agreementsFiltered: function() {
            const filter_ = (this.filter || '').toLowerCase();
            return this.agreements.filter(function(agreement) {
                return (
                    (agreement.Name || '').toLowerCase().indexOf(filter_) > -1 ||
                    (agreement.OwnerName || '').toLowerCase().indexOf(filter_) > -1 ||
                    (agreement.VendorName || '').toLowerCase().indexOf(filter_) > -1 ||
                    (agreement.BankAccountNameDisplay || '').toLowerCase().indexOf(filter_) > -1 ||
                    (agreement.ScheduleType || '').toLowerCase().indexOf(filter_) > -1
                );
            });
        }
    },
    methods: {
        fetchClients: async function() {
            this.clients = await this.vam_GetUserClients({});
            if (this.clients.length == 1) {
                this.selectedClientId = this.clients[0].Id;
            } else if (this.clients.length > 0 && !this.selectedClientId) {
                const clientId = parseInt(
                    getAppCookieKeyValue_lgcy('SelectedClientId'),
                    null
                );
                if (clientId > 0) {
                    this.selectedClientId = clientId;
                }
            }
        },
        fetchAgreement: async function(AgreementId) {
            this.selectedAgreement = this.liq_GetAgreements_New(); //clear right pane
            this.showAgreementEditView = false;
            this.showCharitableDonationEdit = false;
            const dsAgreements = await this.liq_GetAgreements({
                AgreementId: AgreementId
            });
            if (dsAgreements && dsAgreements.length) {
                this.selectedAgreement = dsAgreements[0];
                this.showAgreementEditView = true;
            }
        },
        fetchAgreements: async function() {
            this.loading = true;
            this.selectedAgreement = this.liq_GetAgreements_New(); //clear right pane
            this.showAgreementEditView = false;
            this.showCharitableDonationEdit = false;
            this.agreements = await this.liq_GetAgreements({
                ClientId: this.selectedClientId,
                AgreementTypeId: this.selectedAgreementTypeId,
                VendorId: this.selectedVendorId,
                OwnerId: this.selectedOwnerId,
                GroupId: this.selectedGroupId,
                CurrentOnly: this.currentOnly
            });
            this.loading = false;
        },
        fetchAgreementsIfCriteriaSpecified: async function() {
            // only fetch if some reasonably limiting search criterion is specified
            if (this.selectedOwnerId > 0 ||
                this.selectedVendorId > 0 ||
                this.selectedGroupId > 0 ||
                this.selectedAgreementTypeId > 0
            ) {
                await this.fetchAgreements();
            }
        },
        fetchVendors: async function() {
            const vendorParams = {
                ClientId: this.selectedClientId
            };
            switch (this.listConfiguration.VendorFilter) {
                case 'Charitable':
                    vendorParams.IsCharitable = true;
                    break;
            }
            this.vendors = await this.vam_GetVendors(vendorParams);
        },
        fetchGroups: function() {
            const el = this;
            this.liq_GetAgreementGroups(
                {
                    ClientId: this.selectedClientId,
                    VendorId: this.selectedVendorId
                },
                function(data) {
                    if (data) {
                        el.groups = data;
                    } else {
                        el.groups = [];
                    }
                },
                function(error) {
                    console.error(error);
                }
            );
        },
        editAgreementsListItem: async function(selectedRow, event, column) {
            if (selectedRow && selectedRow != null) {
                this.showCharitableDonationEdit = false;
                this.showAgreementEditView = false; //this should reset the components, once it is set to true again after nextTick
                this.selectedAgreement = selectedRow;
                await this.$nextTick();
                this.showAgreementEditView = true;
            }
        },
        tableRowClassName({ row, rowIndex }) {
            //1/23/18 this is not currently working, but leaving it in.  The class gets assigned, but doesn't do anything.
            if (row.HasError) {
                return 'warning-row';
            }
            return '';
        },
        handleNewAgreement: async function() {
            this.showCharitableDonationEdit = false;
            this.showAgreementEditView = false; //this should reset the components, once it is set to true again after nextTick
            await this.$nextTick();
            this.selectedAgreement = this.liq_GetAgreements_New();
            this.selectedAgreement.AgreementId = -1;
            this.selectedAgreement.UI = 'Standard';
            this.selectedAgreement.Deletable = true;
            this.selectedAgreement.HasError = true;
            this.showAgreementEditView = true;
        },
        handleNewCharitableDonation: async function() {
            this.showAgreementEditView = false;
            this.showCharitableDonationEdit = false; //this should reset the components, once it is set to true again after nextTick
            await this.$nextTick();
            this.selectedAgreement = this.liq_GetAgreements_New();
            this.selectedAgreement.AgreementId = -1;
            this.selectedAgreement.UI = 'CharitableDonation';
            this.selectedAgreement.ClientId = this.selectedClientId;
            this.selectedAgreement.OwnerId = this.selectedOwnerId;
            this.selectedAgreement.VendorId = this.selectedVendorId;
            this.showCharitableDonationEdit = true;
        },
        switchToEditView: async function(agreement_) {
            this.showAgreementEditView = false; //this should reset the components, once it is set to true again after nextTick
            this.showCharitableDonationEdit = false;
            this.selectedAgreement = agreement_;
            await this.$nextTick();
            this.showAgreementEditView = true;
        },
        agreementCopied: async function (agreement_) {
            this.agreements.push(agreement_);
            this.selectedAgreement = agreement_;
            this.$refs.refAgreementsList.setCurrentRow(agreement_);
            this.showAgreementEditView = false;
            await this.$nextTick();
            this.showAgreementEditView = true;
        },
        agreementDeleted(agreementId) {
            if (this.selectedAgreement.AgreementId == agreementId) {
                const index = findIndex(this.agreements, agreement => {
                    return (
                        agreement.AgreementId ===
                        this.selectedAgreement.AgreementId
                    );
                });
                if (index > -1) {
                    this.agreements.splice(index, 1);
                }
                this.showAgreementEditView = false;
            }
        }
    }
});
</script>
<style >
.el-table .warning-row {
    background: oldlace;
}

.el-table td,
.el-table th {
    padding-bottom: 5px;
    padding-top: 5px;
}
</style>
