
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AssetService from './../../services/DAL/assetService';
import ClientSelector from '@/components/form/ClientSelector.vue';
import FundSelector from '@/components/form/FundSelector.vue';
import FundHoldingItemEditView from '@/views/FundFamily/FundHoldingItemEditView.vue';
import uniqBy from 'lodash/uniqBy';
import formatters from '../../utils/formatters';
import Common from '../../utils/common';
import { ElTable } from 'element-ui/types/table';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare function getSecurityUserId(): string;
declare function getStoredSecurityLevel(Id: number): number;

@Component({
  components: {
    ClientSelector,
    FundSelector,
    FundHoldingItemEditView
  }
})
export default class FundHoldingsListView extends Vue {
  $refs: {
      refFundHoldings: ElementUI.Table
    }
  //#region Data
  private _assetService: AssetService.AssetService;
  private formatters: formatters = new formatters();
  private _common: Common;

//  @Ref('refFundHoldings') readonly refFundHoldings!: ElTable;
  @Prop({ default: null })
    fundId: number;
  @Prop({ default: null })
    clientId: number;

  selectedClientId: number = null;
  selectedFundId: number = null;

  showDataPane: boolean = false;
  isLoading: boolean = false;

  filterFundHoldings: string = '';
  fundHoldings = [] as AssetService.FundHoldings[];
  funds = [] as AssetService.FundOverview[];
  selectedItem: AssetService.FundHoldings | AssetService.FundHoldingsWithTranches;

  securityLevel_: number = getStoredSecurityLevel(
    this.$namedKey.SecurityView.ManageAssets
  );
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._assetService = new AssetService.AssetService();
    this.formatters = new formatters();
    this._common = new Common();
    if (!!this.fundId && !!this.clientId){
        this.selectedClientId = this.clientId;
        const params = {} as AssetService.GetFundOverviewParameters;
        params.FundId = this.fundId;
        params.ClientId = this.clientId;
        this.funds = await this._assetService.GetFundOverview(params);
        this.selectedFundId = this.fundId;
    }
  }

  async mounted() {}
  //#endregion Lifecycle

  //#region Computed
  get selectedFund(): AssetService.FundOverview{
    if (!this.selectedFundId) return {} as AssetService.FundOverview;
    return this._common.getSelectedArrayItem(
                    this.funds,
                    this.selectedFundId.toString(),
                    'FundId'
                );
  }
  get fundHoldingsFiltered(): AssetService.FundHoldings[] {
    const filter_ = (this.filterFundHoldings || '').toLowerCase();
    return this.fundHoldings.filter(entity => {
      return (
        (entity.HoldingId || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.Holding || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.LPPercent || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.FMV || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.CostBasis || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.GainLossPct || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.PercentOfFund || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.CumulativePercentOfFund || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.LPFMV || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.LPCostBasis || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.Tranches || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1
      );
    });
  }
  //#endregion Computed

  //#region Watches
  @Watch('selectedFundId')
  onChange_FundId(val, oldVal) {
    this.fundHoldings = [] as AssetService.FundHoldings[];
    this.closeItem();
    if (this.selectedFundId) {
      this.fetchFundHoldings();
    }
  }
  
  //#endregion Watches

  //#region Methods
  async fetchFundHoldings() {
    this.isLoading = true;
    this.fundHoldings = [] as AssetService.FundHoldings[];
    const params = {} as AssetService.GetFundHoldingsParameters;
    params.ClientId = this.selectedClientId;
    params.FundId = this.selectedFundId;
    this.fundHoldings = await this._assetService.GetFundHoldings(params);
    await this.$nextTick();
    if (this.$refs.refFundHoldings){
      this.$refs.refFundHoldings.doLayout();
    }
    this.isLoading = false;
  }
  async editItem(
    selectedRow: AssetService.FundHoldings,
    event,
    column
  ) {
    if (selectedRow && selectedRow != null) {
      this.showDataPane = false;
      this.selectedItem = selectedRow;
      this.showDataPane = true;
    }
  }
  fundHoldingsRowClassName({ row, rowIndex }): string {
    if (+row.OldestAsOf != +this.selectedFund.FMVAsOf) { // the plus signs corecrce the dates into numbers, so that we can get a good comparison
            return 'warning-row';
        } else {
            return '';
        }
  }
  filterHandler(value, row: AssetService.FundHoldings, column) {
    const property = column['property'];
    return row[property] === value;
  }
  filters(column) {
    const filters = this.fundHoldingsFiltered.map(function(list) {
      return {
        text: list[column],
        value: list[column]
      };
    });
    return uniqBy(filters, 'value');
  }
  gotFunds(funds: AssetService.FundOverview[]){
    this.funds = funds;
  }
  getSummary(param) {
    const columnList = [3,4,!this.showDataPane ? 8 : 5, !this.showDataPane ? 9 : 6];
    const totalLabel = 'Total';
    const totalLabelIndex = 1;
    const formats = new Map();
    if (this.showDataPane){
      formats.set(3, 'currency0');
      formats.set(4, 'currency0');
      formats.set(5, 'currency0');
      formats.set(6, 'currency0');
    }
    else {
      formats.set(3, 'currency');
      formats.set(4, 'currency');
      formats.set(8, 'currency');
      formats.set(9, 'currency');
    }
    const result = this._common.getSummaryArray(param, columnList, totalLabel, totalLabelIndex, formats);
    if (!this.showDataPane){
      const costBasis = this.$accounting.unformat(result[3]);
      const fmv = this.$accounting.unformat(result[4]);
      const gainLoss = this.formatters.percent((fmv-costBasis) / costBasis, 2);
      result[5] = gainLoss;
    }
    return result;
  }
  async loadChildren(holding: AssetService.FundHoldings, treeNode, resolve){
    const params = {} as AssetService.GetFundHoldingsWithTranchesParameters;
    params.FundId = holding.FundId;
    params.ClientId = this.selectedClientId;
    params.HoldingId = holding.HoldingId;
    const tranches = await this._assetService.GetFundHoldingsWithTranches(params);
    resolve(tranches);
  }
  deletedItem(){
    this.filterFundHoldings = '';
    this.itemUpdated();
  }
  closeItem(){
    this.showDataPane = false;
    this.selectedItem = null;
    if (this.$refs.refFundHoldings){
      this.$refs.refFundHoldings.setCurrentRow(null);
    }

  }
  itemUpdated(){
    this.showDataPane = false;
    this.fetchFundHoldings();
  }
  //#endregion Methods
}
