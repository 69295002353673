// TS API for Schema acct
// Auto-generated 11/12/24 14:36


declare var smartData: any;
// output classes:
export class EntityGeneralLedger {
    GeneralLedgerId: number=undefined;
    Date: string=undefined;
    Source: string=undefined;
    SourceEntityType: string=undefined;
    SourceEntityTypeDisplay: string=undefined;
    SourceEntityId: number=undefined;
    CreateSourceDescription: string=undefined;
    TransactionHeaderId: number=undefined;
    HeaderDescription: string=undefined;
    BSPL: string=undefined;
    AccountNamePath: string=undefined;
    Debit: number=undefined;
    Credit: number=undefined;
    BalanceSheet: number=undefined;
    ProfitLoss: number=undefined;
    RunningBalanceBalanceSheet: number=undefined;
    RunningBalanceProfitLoss: number=undefined;
    CalculationDescription: string=undefined;
    Attribution: number=undefined;
    AttributionSummaryDebit: number=undefined;
    AttributionSummaryCredit: number=undefined;
    ValuationOrder: number=undefined;
    FMV: number=undefined;
    Basis: number=undefined;
    Invested: number=undefined;
    Returned: number=undefined;
    Called: number=undefined;
    Pending: boolean=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    ProvenanceEntityType: string=undefined;
    ProvenanceEntityId: number=undefined;
    NormalBalance: string=undefined;
    OwnerEntityId: number=undefined;
    OwnerEntityName: string=undefined;
    BalanceSheetAttribution: number=undefined;
    ProfitLossAttribution: number = undefined;
}
export class GeneralLedgerForTransaction {
    OwnerEntityName: string=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    EntityName: string=undefined;
    EntityKey: string=undefined;
    AccountNamePath: string=undefined;
    Debit: number=undefined;
    Credit: number=undefined;
    CreateSourceDescription: string=undefined;
    ConsiderationDescription: string=undefined;
    SourceEntityId: number=undefined;
    SourceEntityType: string=undefined;
    Editable: boolean=undefined;
    OwnerEntityId: number=undefined;
    AssetValuationAttribution: number=undefined;
    AssetGainLossAttribution: number=undefined;
    BalanceSheetAttribution: number=undefined;
    ProfitLossAttribution: number=undefined;
    Id: number=undefined;
    CoAId: number=undefined;
    DepartmentId: number=undefined;
    DC: string=undefined;
    Value: number=undefined;
    CalculationDescription: string=undefined;
    BSPL: string=undefined;
    Attribution: number=undefined;
    NormalBalance: string=undefined;
    AttributionSummaryDebit: number=undefined;
    AttributionSummaryCredit: number=undefined;
    Date: string = undefined;
    Source: string = undefined;
}
export class InvestmentBalanceSheetLegacyComparison {
    Source: string=undefined;
    Basis: number=undefined;
    FMV: number=undefined;
    Invested: number=undefined;
    Returned: number=undefined;
    RemainingCommitment: number=undefined;
    LongTermTax: number=undefined;
    TaxRate: number=undefined;
    RunTime: string = undefined;
}
export class InvestmentGeneralLedgerStatistics {
    InvestmentId: number=undefined;
    Basis: number=undefined;
    FMV: number=undefined;
    Invested: number=undefined;
    Returned: number=undefined;
    CommitmentToDate: number=undefined;
    RemainingCommitment: number=undefined;
    UnfundedCommitment: number=undefined;
    PrefundBalance: number=undefined;
    Called: number=undefined;
    LongTermTax: number=undefined;
    TaxRate: number=undefined;
    InvestmentParentId: number=undefined;
    ClientId: number=undefined;
    IsPrivateEquity: boolean = undefined;
    HasCommitment: boolean = undefined;
}
export class InvestmentGeneralLedgerStatisticsDetails {
    Source: string=undefined;
    AccountNamePath: string=undefined;
    FMV: number=undefined;
    Basis: number=undefined;
    Invested: number=undefined;
    Returned: number = undefined;
}

// Parameter interfaces:

export interface GetEntityGeneralLedgerParameters {
    AsOf: string;
    EntityType: string;
    EntityId: number;
    BSPL: string;
    ExcludeTransactionHeaderId: number;
    SpecificEntityOnly: boolean;
    IncludePending: boolean;
    UseEntityNotProvenance: boolean;
}

export interface GetGeneralLedgerForTransactionParameters {
    TransactionHeaderId: number;
    PromissoryNoteTransactionId: number;
}

export interface GetInvestmentBalanceSheetLegacyComparisonParameters {
    InvestmentId: number;
}

export interface GetInvestmentGeneralLedgerStatisticsParameters {
    InvestmentId: number;
}

export interface GetInvestmentGeneralLedgerStatisticsDetailsParameters {
    InvestmentId: number;
}

export class AcctService {

  GetEntityGeneralLedger (params: GetEntityGeneralLedgerParameters): Promise<EntityGeneralLedger[]> {
    return smartData({
        storedProcName: 'acct.GetEntityGeneralLedger',
        params: params
      });
    }

  GetGeneralLedgerForTransaction (params: GetGeneralLedgerForTransactionParameters): Promise<GeneralLedgerForTransaction[]> {
    return smartData({
        storedProcName: 'acct.GetGeneralLedgerForTransaction',
        params: params
      });
    }

  GetInvestmentBalanceSheetLegacyComparison (params: GetInvestmentBalanceSheetLegacyComparisonParameters): Promise<InvestmentBalanceSheetLegacyComparison[]> {
    return smartData({
        storedProcName: 'acct.GetInvestmentBalanceSheetLegacyComparison',
        params: params
      });
    }

  GetInvestmentGeneralLedgerStatistics (params: GetInvestmentGeneralLedgerStatisticsParameters): Promise<InvestmentGeneralLedgerStatistics[]> {
    return smartData({
        storedProcName: 'acct.GetInvestmentGeneralLedgerStatistics',
        params: params
      });
    }

  GetInvestmentGeneralLedgerStatisticsDetails (params: GetInvestmentGeneralLedgerStatisticsDetailsParameters): Promise<InvestmentGeneralLedgerStatisticsDetails[]> {
    return smartData({
        storedProcName: 'acct.GetInvestmentGeneralLedgerStatisticsDetails',
        params: params
      });
    }
}
