// TS API for Schema liab
// Auto-generated 06/29/21 08:15


declare var smartData: any;
// output classes:
export class AnnuityPayments {
    Id: number=undefined;
    Amount: number=undefined;
    TypeId: number=undefined;
    ChangeDate: string=undefined;
    ChangeUser: string=undefined;
    FirstPayment: string=undefined;
    PaymentNumber: number=undefined;
    PaymentDate: string=undefined;
    RawAmount: number = undefined;
}
export class CreditExport {
    Identifier: string=undefined;
    DisplayId: string=undefined;
    NoteName: string=undefined;
    CreditCeiling: number=undefined;
    Cap: number=undefined;
    CreditExtended: number=undefined;
    CreditDrawn: number=undefined;
    Available: number=undefined;
    Reserve: number=undefined;
    NetCeiling: number=undefined;
    Status: string = undefined;
}
export class NoteCollateral {
    Id: number=undefined;
    NoteId: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    CollateralRate: number=undefined;
    AdvanceRate: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    EntityName: string=undefined;
    AccountType: string=undefined;
    FMV: number=undefined;
    FMVAsOf: string=undefined;
    FMVType: string=undefined;
    CollateralValue: number=undefined;
    AdvanceValue: number=undefined;
    FMVDescription: string = undefined;
}
export class NotePledge {
    Id: number=undefined;
    NoteId: number=undefined;
    PledgeType: string=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    EntityName: string=undefined;
    AccountType: string=undefined;
    Required: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    FMV: number=undefined;
    FMVAsOf: string=undefined;
    FMVType: string=undefined;
    FMVDescription: string = undefined;
}
export class NoteRates {
    Id: number=undefined;
    NoteId: number=undefined;
    AsOf: string=undefined;
    Rate: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string = undefined;
}
export class Notes {
    Id: number=undefined;
    NoteId: number=undefined;
    DisplayId: string=undefined;
    NoteName: string=undefined;
    Name: string=undefined;
    Client: string=undefined;
    OwnerName: string=undefined;
    DebtorDisplay: string=undefined;
    StatusListName: string=undefined;
    StatusId: string=undefined;
    HolderEntityId: number=undefined;
    HolderName: string=undefined;
    HolderShortName: string=undefined;
    GrantorDisplay: string=undefined;
    SharePointListId: number=undefined;
    NoteTypeId: number=undefined;
    NoteType: string=undefined;
    DDLOwnerTypeDisplay: string=undefined;
    OwnerId: number=undefined;
    ClientId: number=undefined;
    Description: string=undefined;
    RateBasisId: number=undefined;
    RateBasisInformation: string=undefined;
    AdvanceCap: number=undefined;
    InceptionDate: string=undefined;
    CloseDate: string=undefined;
    Months: number=undefined;
    FirstPayment: string=undefined;
    MonthlyPayment: number=undefined;
    AmortizationTypeId: number=undefined;
    AmortizationType: string=undefined;
    DebtorBalanceSheetCategoryId: number=undefined;
    GrantorBalanceSheetCategoryId: number=undefined;
    LiabilityAccountId: number=undefined;
    InterestAccountId: number=undefined;
    DrawIntoBankAccountId: number=undefined;
    PayFromBankAccountId: number=undefined;
    NumberOfPayments: number = undefined;
}
export class NoteSummary {
    NoteId: number=undefined;
    NoteName: string=undefined;
    Description: string=undefined;
    Status: string=undefined;
    NoteType: string=undefined;
    RateBasis: string=undefined;
    RateBasisInformation: string=undefined;
    RateSpread: number=undefined;
    CurrentBaseRate: number=undefined;
    BaseRateAsOf: string=undefined;
    CurrentRate: number=undefined;
    RateUndrawnFee: number=undefined;
    UndrawnFeeAmount: number=undefined;
    AnnualPayment: number=undefined;
    CollateralDescription: string=undefined;
    CollateralEntityTypeList: string=undefined;
    CollateralEntityIdList: string=undefined;
    CollateralValue: number=undefined;
    CollateralMinimum: number=undefined;
    CurrentAdvance: number=undefined;
    AverageAdvanceRate: number=undefined;
    AdvanceCap: number=undefined;
    CreditExtended: number=undefined;
    CreditCeiling: number=undefined;
    CreditDrawn: number=undefined;
    CreditDrawnAsNegative: number=undefined;
    MarginReserve: number=undefined;
    ReserveComment: string=undefined;
    CreditAvailable: number=undefined;
    CreditAvailableLessMargin: number=undefined;
    NetCreditAvailable: number=undefined;
    DisplayOrder: number=undefined;
    HolderShortName: string = undefined;
}
export class NoteTransactions {
    Id: number=undefined;
    NoteId: number=undefined;
    TransactionDate: string=undefined;
    Amount: number=undefined;
    Principal: number=undefined;
    Interest: number=undefined;
    Comment: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    TransactionType: string=undefined;
    PrincipalBalance: number=undefined;
    InterestPaidToDate: number=undefined;
    TransactionTypeLabel: string=undefined;
    AmountDisplay: number=undefined;
    IgnoreForLiquidity: boolean=undefined;
    InvestmentTransactionCreditNoteId: number=undefined;
    TransactionHeaderId: number=undefined;
    OwnerId: number = undefined;
}
export class NoteTransactionTypes {
    Id: number=undefined;
    Name: string = undefined;
}
export class NoteTransactionTypeSummary {
    NoteId: number=undefined;
    TransactionType: string=undefined;
    TransactionTypeLabel: string=undefined;
    Principal: number=undefined;
    Interest: number = undefined;
}
export class NoteTypes {
    ItemValue: number=undefined;
    ItemText: string=undefined;
    GrantorType: string = undefined;
}
export class PromissoryNoteSummary {
    ClientId: number=undefined;
    NoteId: number=undefined;
    DisplayId: string=undefined;
    Name: string=undefined;
    StatusId: string=undefined;
    Status: string=undefined;
    OwnerId: number=undefined;
    DebtorName: string=undefined;
    GrantorName: string=undefined;
    Amount: number=undefined;
    InterestRate: number=undefined;
    FirstPayment: string=undefined;
    DateDueInFull: string=undefined;
    PrincipleRepaid: number=undefined;
    InterestAccruedFromPriorPayments: number=undefined;
    InterestPaid: number=undefined;
    RemainingPrinciple: number=undefined;
    InterestDueOnRemainingPrinciple: number=undefined;
    InterestAccrued: number = undefined;
}

// Parameter interfaces:

export interface GetAnnuityPaymentsParameters {
    NoteId: number;
}

export interface GetCreditExportParameters {
    ClientId: number;
}

export interface GetNoteCollateralParameters {
    NoteId: number;
    AsOf: string;
}

export interface GetNotePledgeParameters {
    NoteId: number;
    AsOf: string;
}

export interface GetNoteRatesParameters {
    NoteId: number;
}

export interface GetNotesParameters {
    ClientId: number;
    OwnerId: number;
    NoteStatus: number;
    NoteType: number;
    IncludeHolderOwners: boolean;
    NoteId: number;
    NoteTypeList: string;
    BankAccountId: number;
    OwnerIdList: string;
}

export interface GetNoteSummaryParameters {
    NoteId: number;
    AsOf: string;
    ClientId: number;
    OwnerId: number;
    BankId: number;
    NoteStatus: number;
}

export interface GetNoteTransactionsParameters {
    NoteId: number;
    AsOf: string;
    AscendingOrder: boolean;
    OwnerId: number;
    TransactionId: number;
}

export interface GetNoteTransactionTypesParameters {
    NoteId: number;
}

export interface GetNoteTransactionTypeSummaryParameters {
    NoteId: number;
    AsOf: string;
    OwnerId: number;
}

export interface GetPromissoryNoteSummaryParameters {
    ClientId: number;
    OwnerId: number;
    NoteStatus: number;
    AsOf: string;
    NoteId: number;
}

export class LiabService {

  GetAnnuityPayments (params: GetAnnuityPaymentsParameters): Promise<AnnuityPayments[]> {
    return smartData({
        storedProcName: 'liab.GetAnnuityPayments',
        params: params
      });
    }

  GetCreditExport (params: GetCreditExportParameters): Promise<CreditExport[]> {
    return smartData({
        storedProcName: 'liab.GetCreditExport',
        params: params
      });
    }

  GetNoteCollateral (params: GetNoteCollateralParameters): Promise<NoteCollateral[]> {
    return smartData({
        storedProcName: 'liab.GetNoteCollateral',
        params: params
      });
    }

  GetNotePledge (params: GetNotePledgeParameters): Promise<NotePledge[]> {
    return smartData({
        storedProcName: 'liab.GetNotePledge',
        params: params
      });
    }

  GetNoteRates (params: GetNoteRatesParameters): Promise<NoteRates[]> {
    return smartData({
        storedProcName: 'liab.GetNoteRates',
        params: params
      });
    }

  GetNotes (params: GetNotesParameters): Promise<Notes[]> {
    return smartData({
        storedProcName: 'liab.GetNotes',
        params: params
      });
    }

  GetNoteSummary (params: GetNoteSummaryParameters): Promise<NoteSummary[]> {
    return smartData({
        storedProcName: 'liab.GetNoteSummary',
        params: params
      });
    }

  GetNoteTransactions (params: GetNoteTransactionsParameters): Promise<NoteTransactions[]> {
    return smartData({
        storedProcName: 'liab.GetNoteTransactions',
        params: params
      });
    }

  GetNoteTransactionTypes (params: GetNoteTransactionTypesParameters): Promise<NoteTransactionTypes[]> {
    return smartData({
        storedProcName: 'liab.GetNoteTransactionTypes',
        params: params
      });
    }

  GetNoteTransactionTypeSummary (params: GetNoteTransactionTypeSummaryParameters): Promise<NoteTransactionTypeSummary[]> {
    return smartData({
        storedProcName: 'liab.GetNoteTransactionTypeSummary',
        params: params
      });
    }

  GetNoteTypes (): Promise<NoteTypes[]> {
    return smartData({
        storedProcName: 'liab.GetNoteTypes',
        params: {}
      });
    }

  GetPromissoryNoteSummary (params: GetPromissoryNoteSummaryParameters): Promise<PromissoryNoteSummary[]> {
    return smartData({
        storedProcName: 'liab.GetPromissoryNoteSummary',
        params: params
      });
    }
}
