
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as ReportsService from './../../services/DAL/reportsService';
import * as SystemService from './../../services/DAL/systemService';
import findIndex from 'lodash/findIndex';
import ElementUI from 'element-ui';

declare var $: any;
declare var SmartObject: any;

@Component
export default class ReportPermissionList extends Vue {
    $refs: {
        tblReportPermissionList: ElementUI.Table
        , tblConfigurationPermissionList: ElementUI.Table
    }
    //#region Data
    private _reportsService: ReportsService.ReportsService;
    private _systemService: SystemService.SystemService;

    selectedUser: SystemService.Users = new SystemService.Users();
    users: SystemService.Users[] = [];
    reportPermissions: ReportsService.ReportPermissionsForUser[] = [];
    configurationPermissions: ReportsService.ConfigurationPermissionsForUser[] = [];
    // filter: string = '';
    isLoading: boolean = false;
    securityLevel: number = null;
    //#endregion Data

    //#endregion Computed
    // get permissionsFiltered(): ReportsService.ReportPermissionsForUser[] {
    //     return this.reportPermissions.filter(permission => {
    //         let filter_ = (this.filter || '').toLowerCase();
    //         return (
    //             (permission.ReportGroup || '').toLowerCase().indexOf(filter_) > -1 ||
    //             (permission.DisplayName || '').toLowerCase().indexOf(filter_) > -1 ||
    //             (permission.Description || '').toLowerCase().indexOf(filter_) > -1
    //         );
    //     });
    // }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._reportsService = new ReportsService.ReportsService();
        this._systemService = new SystemService.SystemService();
    }

    async mounted() {
        this.fetchUsers();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchUsers() {
        const params = {} as SystemService.GetUsersParameters;
        this.users = await this._systemService.GetUsers(params);
    }
    
    async fetchReportPermissions() {
        this.isLoading = true;
        const params = {} as ReportsService.GetReportPermissionsForUserParameters;
        params.UserId = this.selectedUser.UserID;
        this.reportPermissions = await this._reportsService.GetReportPermissionsForUser(params);

        this.securityLevel = this.reportPermissions[0].SecurityLevel || 0;
        this.isLoading = false;

        if (this.securityLevel > 0 && this.securityLevel <= 50) {
            await this.$nextTick();

            $(this.$refs.tblReportPermissionList.$el).find('.lblReportGroup').each((idx, el) => {
                $(el).closest('tr').attr('ReportGroup', $(el).text());
            });

            await this.$nextTick();

            // gkb 03/22/19 - need to manually apply 'sm-table-group-item' in row-class-name of el-table, because otherwise the class gets removed from rows on-hover
            const $tblReportPermissionList = $(this.$refs.tblReportPermissionList.$el).find('.lblReportGroup').first().closest('table');
            $tblReportPermissionList.smartTable({
                groupBy: 'ReportGroup'
            });
        }
    }
    
    async fetchConfigurationPermissions() {
        this.isLoading = true;
        const params = {} as ReportsService.GetConfigurationPermissionsForUserParameters;
        params.UserId = this.selectedUser.UserID;
        this.configurationPermissions = await this._reportsService.GetConfigurationPermissionsForUser(params);

        this.securityLevel = this.configurationPermissions[0].SecurityLevel || 0;
        this.isLoading = false;

        if (this.securityLevel > 0 && this.securityLevel <= 50) {
            await this.$nextTick();

            $(this.$refs.tblConfigurationPermissionList.$el).find('.lblConfigurationType').each((idx, el) => {
                $(el).closest('tr').attr('ConfigurationType', $(el).text());
            });

            await this.$nextTick();

            // gkb 03/22/19 - need to manually apply 'sm-table-group-item' in row-class-name of el-table, because otherwise the class gets removed from rows on-hover
            const $tblConfigurationPermissionList = $(this.$refs.tblConfigurationPermissionList.$el).find('.lblConfigurationType').first().closest('table');
            $tblConfigurationPermissionList.smartTable({
                groupBy: 'ConfigurationType'
            });
        }
    }

    async setReportPermission(row: ReportsService.ReportPermissionsForUser) {
        const checked = row.HasPermission;
        if (checked) {
            row.PermissionId = await new SmartObject('Permission').createObject({
                UserId: this.selectedUser.UserID,
                ReportId: row.ReportId
            });
            this.$notify.success('Permission granted.');
        }
        else {
            await new SmartObject('Permission', row.PermissionId).deleteObject();
            row.PermissionId = null;
            this.$notify.success('Permission removed.');
        }
    }

    async setConfigurationPermission(row: ReportsService.ConfigurationPermissionsForUser) {
        const checked = row.HasPermission;
        if (checked) {
            row.PermissionId = await new SmartObject('ConfigurationPermission').createObject({
                UserId: this.selectedUser.UserID,
                ConfigurationId: row.ConfigurationId
            });
            this.$notify.success('Permission granted.');
        }
        else {
            await new SmartObject('ConfigurationPermission', row.PermissionId).deleteObject();
            row.PermissionId = null;
            this.$notify.success('Permission removed.');
        }
    }
    //#endregion Methods
}
