
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop, Model } from "vue-property-decorator";
import * as LiabService from "../../services/DAL/liabService";
import Common from "@/utils/common";
import formatters from "@/utils/formatters";
import uniqBy from 'lodash/uniqBy';

declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
  inheritAttrs: false,
})
export default class NoteTransactionSelector extends Vue {
  //#region Private declarations for Services
  private _liabService: LiabService.LiabService;
  private common: Common;
  private formatters: formatters;

  //#endregion Private declarations for Services

  //#region Props
  @Prop() readonly value: number;
  @Prop({
    required: true,
    type: Number,
  })
  readonly noteId: number;
  @Prop({
    type: Number,
  })
  readonly ownerId: number;
  //#endregion Props

  //#region Data
  transactions = [] as LiabService.NoteTransactions[];
  selectedValue: number = null;
  previouslySelectedTransaction = {} as LiabService.NoteTransactions;
  filterNoteTransactions: string = "";
  showDialog = false;
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._liabService = new LiabService.LiabService();
    this.common = new Common();
    this.formatters = new formatters();
  }

  async mounted() {
    this.selectedValue = this.value;
    if (this.value) {
      this.getSelected();
    } //else {
    //   this.fetchTransactions();
    // }
  }
  //#endregion Lifecycle

  get selectedTransaction(): LiabService.NoteTransactions {
    if (this.transactions && this.transactions.length && this.value) {
      return this.common.getSelectedArrayItem(
        this.transactions,
        this.value.toString(),
        "Id"
      );
    }
  }

  //#region Watches
  @Watch("value")
  onChange_value(val: number, oldVal: number) {
    this.selectedValue = tryParseInt(val, null);
  }
  // @Watch("noteId")
  // onChange_noteId(val: number, oldVal: number) {
  //   this.fetchTransactions();
  // }
  //#endregion Watches

  //#region Methods
  changeSelection() {
    this.previouslySelectedTransaction = this.selectedTransaction;
    this.selectedValue = null;
    this.showDialog = true;
    this.fetchTransactions();
  }
  async fetchTransactions() {
    this.$setSystemStatus("Getting Transactions");
    this.transactions = [];
    if (this.noteId && !this.selectedValue) {
      const params = {} as LiabService.GetNoteTransactionsParameters;
      params.NoteId = this.noteId;
      params.OwnerId = this.ownerId;
      this.transactions = await this._liabService.GetNoteTransactions(params);
      this.$emit("options-fetched", this.transactions);
      this.$setSystemStatus("");
    }
  }
  async getSelected() {
    if (this.value) {
      this.$setSystemStatus("Getting Selected Transaction");
      const params = {} as LiabService.GetNoteTransactionsParameters;
      params.NoteId = this.noteId;
      params.OwnerId = this.ownerId;
      params.TransactionId = this.value;
      this.transactions = await this._liabService.GetNoteTransactions(params);
      this.$emit("options-fetched", this.transactions);
      this.$setSystemStatus("");
    }
  }
  onClear() {
    this.selectedValue = null;
    this.$emit("input", null);
  }

  async editTransactionsItem(
    selectedRow: LiabService.NoteTransactions,
    event,
    column
  ) {
    if (selectedRow && selectedRow != null) {
      if (selectedRow.InvestmentTransactionCreditNoteId) {
        this.$alert(
          `Item cannot be selected because it is already used in Investment Transaction ${selectedRow.TransactionHeaderId}`
        );
      } else {
        this.$emit("input", selectedRow.Id);
        this.$emit("option-selected", selectedRow);
        this.showDialog = false;
      }
    }
  }
  dialogClosed() {
    if (
      !!this.previouslySelectedTransaction &&
      this.previouslySelectedTransaction.Id &&
      !this.selectedValue
    ) {
      this.selectedValue = this.previouslySelectedTransaction.Id;
      if (!this.selectedTransaction) {
        // put the existing transaction into the array so that it can be filtered by the computed prop selectedTransaction
        this.transactions.push(this.previouslySelectedTransaction);
      }
      this.$emit("input", this.previouslySelectedTransaction.Id); // on close, if there was a previous value, and no current value, reset to previous value
      this.$emit("option-selected", this.previouslySelectedTransaction);
    }
    this.showDialog = false;
  }
  rowStyle(row, rowIndex) {
    if (row.row.InvestmentTransactionCreditNoteId) {
      return { "background-color": "darkgrey" };
    }
  }
  filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
  }
  filters(column){
      const filters = this.transactions.map(function(list) {
          return {
              text: list[column]
              , value: list[column]
              };
      });
      return uniqBy(filters, 'value');
  }

  //#endregion Methods
}
