
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';

@Component({
    inheritAttrs: false
})
export default class NewStructureSelector extends Vue {
//#region Private declarations for Services
    private _asset_Service: AssetService.AssetService;

//#endregion Private declarations for Services

    //#region Props
    @Prop() investmentId: number;
    @Prop() entityId: number;
    @Prop() transactionId: number;
    @Prop() transactionDate: string;
    @Prop() value: string;
    @Prop() structureType: string
    //#endregion Props

    //#region Data
    structures = [] as AssetService.StructureForInvestment[];
    selectedValue: string = null;
    //#endregion Data

    //#region Computed
    //#endregion Computed

   //#region Lifecycle
    async created() {
        this._asset_Service = new AssetService.AssetService();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchStructures();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onStructureChange(val: string, oldVal: string) {
        this.selectedValue = val;
    }
    @Watch('entityId')
    onEntityIdChange(val: number, oldVal: number) {
        this.fetchStructures();
    }
    @Watch('investmentId')
    onInvestmentIdChange(val: number, oldVal: number) {
        this.fetchStructures();
    }
    @Watch('transactionId')
    onTransactionIdChange(val: number, oldVal: number) {
        this.fetchStructures();
    }
    @Watch('transactionDate')
    onTransactionDateChange(val: number, oldVal: number) {
        this.fetchStructures();
    }
    @Watch('structureType')
    onStructureTypeChange(val: number, oldVal: number) {
        this.fetchStructures();
    }
    //#endregion Watches

//#region Methods
    async fetchStructures() {
        this.structures = [];
        if (!this.investmentId || !this.transactionDate || !this.entityId || !this.structureType){ // transactionId is not required
            return;
        }
        const parms = {} as AssetService.GetStructureForInvestmentParameters;
        parms.InvestmentId = this.investmentId;
        parms.EntityId = this.entityId;
        parms.TransactionId = this.transactionId;
        parms.TransactionDate = this.transactionDate;
        parms.StructureType = this.structureType;
        this.structures = await this._asset_Service.GetStructureForInvestment(parms);
        if (this.structures.length == 1){
            this.selectedValue = this.structures[0].Structure;
            this.$emit('input', this.selectedValue);
        }
    }
    querySearch(queryString, cb) {
        var structures = this.structures;
        var results = queryString ? structures.filter(this.createFilter(queryString)) : structures;
        // call callback function to return suggestions
        cb(results);
      }
    createFilter(queryString) {
    return (structure) => {
        return (structure.Structure.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    };
    }
    handleSelect(item){
        this.selectedValue = item.Structure;
        this.$emit('input', item.Structure);
    }
    //#endregion Methods
}
