
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import AgreementEditView from '@/js/AP/AgreementEditView.vue';
import * as VamService from './../../services/DAL/vamService';
import * as LiqService from './../../services/DAL/liq_Service';
import ElementUI from 'element-ui';

@Component({
    components: {
        AgreementEditView
    }
})
export default class OwnerAgreementList extends Vue {
    $refs: {
        tblOwnerAgreementList: ElementUI.Table
    }
    //#region Data
    private _vamService: VamService.VamService;
    private _liqService: LiqService.LiqService;

    @Prop()
    ownerId: number;

    agreements: LiqService.AgreementsDisplay[] = [];
    filter: string = '';
    selectedAgreement = null;
    showAgreementEditView = false;
    isLoading = false;
    //#endregion Data

    //#region Computed
    get agreementsFiltered(): LiqService.AgreementsDisplay[] {
        return this.agreements.filter(agreement => {
            const filter_ = (this.filter || '').toLowerCase();
            return (
                (agreement.AgreementId || '').toString().toLowerCase().indexOf(filter_) >
                    -1 ||
                (agreement.Name || '').toLowerCase().indexOf(filter_) > -1 ||
                (agreement.AgreementType || '').toLowerCase().indexOf(filter_) >
                    -1
            );
        });
    }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();
        this._liqService = new LiqService.LiqService();
    }

    async mounted() {
        this.getAgreementsDisplay();
    }
    //#endregion Lifecycle

    //#region Methods
    selectAgreement(agreement) {
        if (agreement) {
            this.showAgreementEditView = false;
            this.$nextTick(() => {
                this.showAgreementEditView = true;
                this.selectedAgreement = agreement;
            });
        }
    }

    closeAgreement() {
        this.showAgreementEditView = false;
        this.selectedAgreement = null;
        this.$refs.tblOwnerAgreementList.setCurrentRow(null);
    }

    onDeleteAgreement() {
        this.closeAgreement();
        this.getAgreementsDisplay();
    }

    async getAgreementsDisplay() {
        this.isLoading = true;
        const getAgreementsDisplayParameters = {} as LiqService.GetAgreementsDisplayParameters;
        getAgreementsDisplayParameters.OwnerId = this.ownerId;
        this.agreements = await this._liqService.GetAgreementsDisplay(
            getAgreementsDisplayParameters
        );
        this.isLoading = false;
    }
    //#endregion Methods
}
