
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import formatters from '../../../utils/formatters';
import Common from "@/utils/common";
import ElementUI from 'element-ui';
import * as TransactionsService from "@/services/DAL/transactionsService";
                                 

@Component
export default class SellMarketableSecurities extends Vue {
    @Ref() readonly refMarketableSecuritiesForSale!: ElementUI.Table;

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    public formatters: formatters = new formatters();
    private common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({type: Number, required: true}) readonly marketableSecuritySaleId!: number;

    //#endregion Props

    //#region Data
    loading = false;
    marketableSecuritiesForSale = [] as TransactionsService.MarketableSecuritiesForSale[];
    selectedMarketableSecurity = {} as TransactionsService.MarketableSecuritiesForSale;
    //#endregion Data


     //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.common = new Common();
        this.formatters = new formatters();
    }

    async mounted() {
        await this.fetchMarketableSecuritiesForSales();
    }

    //#endregion Lifecycle

    //#region Watches
    @Watch('marketableSecuritySaleId')
    onChange_marketableSecuritySaleId(val: number, oldVal: number) {
        if (!(oldVal == undefined && val == null)){
            this.fetchMarketableSecuritiesForSales();
        }
    }
    //#endregion Watches

    //#region Computed
    //#endregion Computed

    //#region Methods
    async fetchMarketableSecuritiesForSales(){
        if (this.marketableSecuritySaleId) {
            this.loading = true;
            const params = {} as  TransactionsService.GetMarketableSecuritiesForSaleParameters;
            params.MarketableSecuritySaleId = this.marketableSecuritySaleId;
            this.marketableSecuritiesForSale = await this._transactionsService.GetMarketableSecuritiesForSale(params);
            this.loading = false;
        }

    }

    async editMarketablesecuritiesforsaleItem(row: TransactionsService.MarketableSecuritiesForSale){
      if (row.MarketableSecuritySaleSecurityId){
        this.$emit('switch-tab', row)
      }
    }
    async addParent(row: TransactionsService.MarketableSecuritiesForSale){
      if (!row.DisableInTool && !row.MarketableSecuritySaleSecurityId){
        const saleSecurity = {} as TransactionsService.MarketableSecuritySaleSecurities;
        saleSecurity.MarketableSecuritySaleId = this.marketableSecuritySaleId;
        saleSecurity.ParentId = row.ParentId;
        saleSecurity.Ticker = row.Ticker;
        saleSecurity.Name = row.Name;
        const id = await new SmartObject(
            "MarketableSecuritySaleSecurities"
          ).createObject(saleSecurity);
        if (id) {
          saleSecurity.Id = id;
          this.$emit('add-sale-security', saleSecurity);
          row.MarketableSecuritySaleSecurityId = id;
        }
      }
    }

    getSummaryMarketableSecurities(param): string[] {
        const columnList = [4, 6, 8];
        const totalLabel = ' ';
        const totalLabelIndex = 0;
        const formats = new Map();
        formats.set(4, 'currency');
        formats.set(6, 'currency');
        formats.set(8, 'currency');
        const result = this.common.getSummaryArray(param, columnList, totalLabel, totalLabelIndex, formats);
        return result;
    }
    marketableSecuritiesForSaleRowClassName({row, rowIndex}): string {
        if (row.DisableInTool) return 'muted'
		return '';
    }

    //#endregion Methods

}
