
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as VamService from '../../services/DAL/vamService';
import Common from '../../utils/common';

@Component({
    inheritAttrs: false
})
export default class StateSelector extends Vue {
    //#region Private declarations for Services
    private _vamService: VamService.VamService;
    private _common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() value: number | string;
    @Prop() useAbbreviation: boolean;
    @Prop() usaOnly: boolean;
    @Prop() excludedStateId: number;
    @Prop() excludedStateAbbreviation: string;
    //#endregion Props

    //#region Data
    states = [] as VamService.States[];
    selectedValue: number | string = null;
    //#endregion Data

   //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();
        this._common = new Common();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchStates();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number | string, oldVal: number | string) {
        this.selectedValue = val;

        if (this.states.length) {
            let selectedObject: VamService.States = new VamService.States();
            if (this.selectedValue) {
                selectedObject = this._common.getSelectedArrayItem(
                    this.states,
                    this.selectedValue.toString(),
                    (this.useAbbreviation ? 'Abbreviation' : 'Id')
                );
            }
            this.$emit('value-set', selectedObject);
        }
    }
    //#endregion Watches

    //#region Methods
    async fetchStates() {
        this.states = [];
        const parameters = {} as VamService.GetStatesParameters;
        if (this.usaOnly) {
            parameters.CountryId = 226; // United States
        }
        this.states = await this._vamService.GetStates(parameters);
        
        this.$emit('options-fetched', this.states);

        if (this.states.length === 1){
            this.selectedValue = (this.useAbbreviation ? this.states[0].Abbreviation : this.states[0].Id);
            this.$emit('single-option-available', this.selectedValue);
            if (this.selectedValue !== this.value) {
                this.$emit('input', this.selectedValue);
            }
        }
    }
    //#endregion Methods
}
