
//include the quote component
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { TickerQuoteService } from './../../../services/TickerQuoteService';
@Component({
  components: {}
})
export default class StockQuote extends Vue {
  //#region Data
  private _TickerQuoteService: TickerQuoteService;

  @Prop()
  ticker: string;

  stock: object = {};
  error: string = '';
  isLoading: boolean = false;

  //#endregion Data
  //#region Lifecycle
  async created() {
    this._TickerQuoteService = new TickerQuoteService();
  }

  async mounted() {
    this.getData();
  }
  //#endregion Lifecycle
  //#region Methods
  async getData() {
    this.stock = {};
    this.error = '';
    this.isLoading = true;

    try {
      const response: any = await this._TickerQuoteService.getQuote(this.ticker);
      if (typeof response === 'object') {
        this.stock = response;
      }
      else if (typeof response === 'string') {
        this.error = response;
        console.log(response);
      }
    } catch (err) {
      const errorMessage = `There was an error retrieving the data for ticker ${this.ticker}.`;
      // if (typeof err === 'object' && err.response && err.response.status === 404) {
      //   errorMessage = `No data could be found for ticker ${this.ticker}.`;
      // }
      console.log(err);
      this.error = errorMessage;
      console.log(errorMessage);
    }

    this.isLoading = false;
  }
  //#endregion Methods
}
