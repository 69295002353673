
import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import TripLegEdit from '@/views/Aviation/TripLegEdit.vue';
import groupArray from 'group-array';
import * as AvService from './../../services/DAL/avService';
import * as VamService from './../../services/DAL/vamService';
import formatters from '../../utils/formatters';

declare var $: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
  components: {
    TripLegEdit
  }
})
export default class Trips extends Vue {
    //#region Private declarations for Services
    private _vamService: VamService.VamService;
    private _avService: AvService.AvService;
    private formatters: formatters;
    //#endregion Private declarations for Services

    //#region Data
    clients = [] as VamService.UserClients[];
    trips = [] as AvService.Trips[];
    aircrafts = [] as AvService.Aircraft[];
    aircraftsGrouped = [];
    // selectedClient = <VamService.UserClients>{};
    selectedClientId: number = null;
    // selectedAircraft = <AvService.Aircraft>{};
    selectedAircraftId: number = null;
    selectedDateRange = [];
    filter = '';
    selectedLeg = {} as AvService.TripLegDetails;
    isLoading: boolean = false;
    securityLevel_: number = null;
  
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._avService = new AvService.AvService();
        this._vamService = new VamService.VamService();
        this.formatters = new formatters();
        this.securityLevel_ = tryParseInt(
          getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAviationDataImport),
          0
        );

    }

    async mounted() {
        await this.getClients();
        await this.getAircrafts();
    }
    //#endregion Lifecycle

    //#region Computed
      //#endregion

    //#region Watches
    @Watch('selectedClientId')
    onSelectedClientChanged(
        val: VamService.UserClients,
        oldVal: VamService.UserClients
    ) {
        this.selectedAircraftId = null; // <AvService.Aircraft>{};
        this.getAircrafts();
    }

    //#endregion

    //#region Methods
    clearSearch() {
        this.selectedClientId = null;
        this.selectedAircraftId = null;
        this.selectedDateRange = [];
    }

    async expandTrip(row, expandedRows) {
      // in order for .legs to be responsive, it has to be defined on the object-array.
      // So the GetTrips proc has a null column called "legs."
      if (row.TripId) {
          if (!row.legs) {
            // don't get if already gotten
            row.legs = await this.getTripLegs(row.TripId);
          }
      } else {
          row.legs = {};
      }
    }
    
    async selectTripLeg(tripLeg, event, column) {
        if (tripLeg) {
            $('.tblTripLegs[TripId!="' + tripLeg.TripId + '"]').find('tr.current-row').removeClass('current-row');
            this.selectedLeg = {} as AvService.TripLegDetails;
            await this.$nextTick();
            this.selectedLeg = tripLeg;
        }
    }

    closeTripLeg() {
        this.selectedLeg = {} as AvService.TripLegDetails;
    }

    async getClients() {
        this.isLoading = true;
        this.clients = await this._vamService.GetUserClients();
        if (this.clients.length == 1){
          this.selectedClientId = this.clients[0].Id;
        }
        this.isLoading = false;
    }

    async getAircrafts() {
        const _avService = new AvService.AvService();
        const getAircraftParameters = {} as AvService.GetAircraftParameters;
        getAircraftParameters.ClientId = this.selectedClientId;

        this.aircrafts = await _avService.GetAircraft(getAircraftParameters);
        const aircraftsGroups = groupArray(this.aircrafts, 'DDLGroupBy');
        this.aircraftsGrouped = Object.keys(aircraftsGroups).map(key => {
            if (aircraftsGroups.hasOwnProperty(key)) {
                return {
                    label: key,
                    options: aircraftsGroups[key]
                };
            }
        });
    }

    async getTrips() {
      this.isLoading = true;
      const tripParameters = {} as AvService.GetTripsParameters;
      tripParameters.ClientId = this.selectedClientId;
      tripParameters.AircraftId = this.selectedAircraftId;
      tripParameters.DateRangeStart = this.selectedDateRange[0];
      tripParameters.DateRangeEnd = this.selectedDateRange[1];
      this.trips = await this._avService.GetTrips(tripParameters);
      this.isLoading = false;
    }

    async getTripLegs(tripId: number): Promise<any[]> {
        const tripLegsParameters = {} as AvService.GetTripLegsParameters;
        tripLegsParameters.TripId = tripId;
        this.isLoading = true;
        const tripLegs = await this._avService.GetTripLegs(tripLegsParameters);
        this.isLoading = false;
        return tripLegs
    }
    //#endregion
}
