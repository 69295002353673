// TS API for Schema asset
// Auto-generated 07/16/24 14:33


declare var smartData: any;
// output classes:
export class DeleteInvestment {
    Rows: number=undefined;
    Message: string=undefined;
    IsError: boolean = undefined;
}
export class AccountTypes {
    Id: number=undefined;
    Code: string=undefined;
    Description: string=undefined;
    Display: string = undefined;
}
export class CapitalCallSchedule {
    Id: number=undefined;
    InvestmentParentId: number=undefined;
    Date: string=undefined;
    Percentage: number=undefined;
    Notes: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Display: string = undefined;
}
export class CommitmentAnalysis {
    TransactionHeaderId: number=undefined;
    KeyId: string=undefined;
    TransactionType: string=undefined;
    HeaderDescription: string=undefined;
    Account: string=undefined;
    Date: string=undefined;
    Amount: number=undefined;
    Commitment: number=undefined;
    Call: number=undefined;
    Prefund: number=undefined;
    PrefundBalance: number=undefined;
    RemainingCommitment: number=undefined;
    UnfundedCommitment: number=undefined;
    CommitmentToDate: number=undefined;
    CalledToDate: number=undefined;
    CalledPercentToDate: number=undefined;
    RowType: string=undefined;
    CapitalTypeOrder: number=undefined;
    NonRecallableCalls: number=undefined;
    Recallable: number=undefined;
    NonRecallableCalledToDate: number=undefined;
    RecallableCalledToDate: number = undefined;
}
export class CostBasisMethods {
    Id: number=undefined;
    Value: string=undefined;
    Label: string=undefined;
    Enabled: boolean = undefined;
}
export class DividendSummary {
    InvestmentId: number=undefined;
    OwnerId: number=undefined;
    Ticker: string=undefined;
    Name: string=undefined;
    OwnerAccount: string=undefined;
    Shares: number=undefined;
    SharesAtRecordDate: number=undefined;
    SharesToday: number=undefined;
    DividendPerShare: number=undefined;
    Frequency: string=undefined;
    DeclarationDate: string=undefined;
    Date: string=undefined;
    RecordDate: string=undefined;
    PaymentDate: string=undefined;
    ExpectedIncome: number=undefined;
    LastDividendActual: number=undefined;
    LastDividendDate: string=undefined;
    LastDividendPerShare: number=undefined;
    SharesAtLastDividend: number=undefined;
    LastTransactionHeaderId: number=undefined;
    NextExpectedPaymentDate: string=undefined;
    NextTransactionHeaderId: number = undefined;
}
export class DonationLetter {
    Date: string=undefined;
    ClientId: number=undefined;
    BaseOwner: string=undefined;
    ToEmail: string=undefined;
    Investment: string=undefined;
    Shares: number=undefined;
    Description: string=undefined;
    TransactionHeaderId: number=undefined;
    FileName: string=undefined;
    DonationLetterId: number=undefined;
    DonationLetterCreateUser: string=undefined;
    DonationLetterCreateDate: string=undefined;
    ReportStatusCode: string=undefined;
    RetryAfter: string=undefined;
    Message: string=undefined;
    EmailSent: string=undefined;
    GetAttachmentPath: string=undefined;
    GetFolderPath: string=undefined;
    AttachmentId: number=undefined;
    AttachmentDriveId: string=undefined;
    FolderDriveId: string=undefined;
    Queued: string=undefined;
    QueueUser: string=undefined;
    QueueUserDisplay: string=undefined;
    RunAfter: string=undefined;
    DonationLetterCreateUserDisplay: string = undefined;
}
export class ExpectedK1 {
    KeyId: number=undefined;
    InvestmentId: number=undefined;
    IsConverted: boolean=undefined;
    Name: string=undefined;
    NameDisplay: string=undefined;
    OwnerId: number=undefined;
    Owner: string=undefined;
    OwnerShortestName: string=undefined;
    AccountTypeCode: string=undefined;
    ClosedDate: string=undefined;
    TaxId: string=undefined;
    RecognizedK1Id: number=undefined;
    LogId: number=undefined;
    AttachmentId: number=undefined;
    MapId: number=undefined;
    CreateDateLocal: string=undefined;
    TaxYear: number=undefined;
    AttachmentDateYear: number=undefined;
    FileDescription: string=undefined;
    Partnership: string=undefined;
    PartnershipEIN: string=undefined;
    IsFinalK1: boolean=undefined;
    CashDistribution_19_A: number=undefined;
    VAMCash: number=undefined;
    VAMCapContribution: number=undefined;
    VAMCashAndEqTotal: number=undefined;
    CashDiff: number=undefined;
    StockDistribution_19_C: number=undefined;
    VAMStock: number=undefined;
    StockDiff: number=undefined;
    OrdinaryBusinesIncome_1: number=undefined;
    InterestIncome_5: number=undefined;
    OrdinaryDividends_6a: number=undefined;
    QualifiedDividends_6b: number=undefined;
    STCG_8: number=undefined;
    LTCG_9a: number=undefined;
    FileId: string=undefined;
    DriveId: string=undefined;
    FilePath: string=undefined;
    FilesForInvestmentYear: number = undefined;
}
export class FeePayingCommitments {
    Id: number=undefined;
    InvestmentParentId: number=undefined;
    StartDate: string=undefined;
    EndDate: string=undefined;
    Value: number = undefined;
}
export class FixedIncome {
    Id: number=undefined;
    Client: string=undefined;
    ClientId: number=undefined;
    Owner: string=undefined;
    OwnerEntityId: number=undefined;
    ShortDescription: string=undefined;
    Price: number=undefined;
    Interest: number=undefined;
    FaceValue: number=undefined;
    TreasuryType: string=undefined;
    Method: string=undefined;
    SettlementDate: string=undefined;
    PurchaseSettlementDate: string=undefined;
    PurchaseAmount: number=undefined;
    PurchasedAccruedInterest: number=undefined;
    MaturityDate: string=undefined;
    Discount: number=undefined;
    BankAccountId: number=undefined;
    BankNameNumberMasked: string=undefined;
    Deletable: boolean=undefined;
    CUSIP: string=undefined;
    DocumentationReceived: boolean=undefined;
    Valuation: number=undefined;
    ValuationAsOf: string=undefined;
    PricePer100: number=undefined;
    BondEquivalentYield: number=undefined;
    IsClosed: boolean=undefined;
    Created: string=undefined;
    Modified: string=undefined;
    HasEarlyRedemption: boolean=undefined;
    HasMatches: boolean=undefined;
    HasPendingRedemptionAsOf: string=undefined;
    ImpliedDiscount: number=undefined;
    EarlyReturnOfCapital: number=undefined;
    EarlyReturnOfFaceValue: number=undefined;
    EarlyReturnedInterest: number=undefined;
    RemainingFaceValue: number=undefined;
    RemainingInterest: number=undefined;
    InterestNetOfTaxes: number=undefined;
    BondEquivalentYieldNetOfTaxes: number=undefined;
    FirstRedemptionDate: string=undefined;
    WeightedBEYNumerator: number=undefined;
    WeightedDiscountNumerator: number=undefined;
    WeightedImpliedDiscountNumerator: number=undefined;
    WeightedPricePer100Numerator: number=undefined;
    IsTransferOut: boolean=undefined;
    IsTransferIn: boolean = undefined;
}
export class FixedIncomeSummary {
    ClientId: number=undefined;
    BankNameNumberMasked: string=undefined;
    BankAccountId: number=undefined;
    FMV: number=undefined;
    OldestFMV: string=undefined;
    Cost: number=undefined;
    RemainingFaceValue: number=undefined;
    NextMaturity: string=undefined;
    LastMaturity: string=undefined;
    WeightedBEY: number=undefined;
    WeightedBEYNumerator: number=undefined;
    NumberOfInstruments: number = undefined;
}
export class FundFamilies {
    Id: number=undefined;
    Name: string=undefined;
    Manager: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    ReportsTranches: boolean = undefined;
}
export class FundHoldingNames {
    Name: string = undefined;
}
export class FundHoldings {
    FundId: number=undefined;
    FundName: string=undefined;
    HoldingId: number=undefined;
    Holding: string=undefined;
    LPPercent: number=undefined;
    OldestAsOf: string=undefined;
    FMV: number=undefined;
    CostBasis: number=undefined;
    GainLossPct: number=undefined;
    PercentOfFund: number=undefined;
    CumulativePercentOfFund: number=undefined;
    LPFMV: number=undefined;
    LPCostBasis: number=undefined;
    Tranches: number=undefined;
    ReportsTranches: boolean=undefined;
    DisplayName: string=undefined;
    KeyId: number=undefined;
    HoldingInvestmentParentId: number=undefined;
    ClientId: number = undefined;
}
export class FundHoldingsWithTranches {
    FundId: number=undefined;
    FundName: string=undefined;
    HoldingId: number=undefined;
    Holding: string=undefined;
    TrancheId: number=undefined;
    Tranche: string=undefined;
    LPPercent: number=undefined;
    OldestAsOf: string=undefined;
    LatestAsOf: string=undefined;
    FMV: number=undefined;
    CostBasis: number=undefined;
    GainLossPct: number=undefined;
    PercentOfFund: number=undefined;
    LPFMV: number=undefined;
    LPCostBasis: number=undefined;
    FundCost: number=undefined;
    FundFMV: number=undefined;
    CumulativeFMV: number=undefined;
    DisplayName: string=undefined;
    KeyId: number=undefined;
    ClientId: number=undefined;
    ReportsTranches: boolean = undefined;
}
export class FundingSource {
    FundingSourceId: number=undefined;
    Display: string = undefined;
}
export class FundingSourceAnnuity {
    FundingSourceEntityId: number=undefined;
    Display: string = undefined;
}
export class FundingSourcePromissoryNote {
    FundingSourceEntityId: number=undefined;
    Display: string = undefined;
}
export class FundingSourcePublicEquity {
    FundingSourceEntityId: number=undefined;
    Display: string = undefined;
}
export class FundingSourceTypes {
    FundingSourceTypeId: number=undefined;
    Display: string=undefined;
    DialogEntityType: string=undefined;
    EntityType: string=undefined;
    EntityTypeTypeId: number=undefined;
    RequiresAmount: boolean=undefined;
    RequiresComment: boolean = undefined;
}
export class FundList {
    FundId: number=undefined;
    Name: string=undefined;
    ShortName: string = undefined;
}
export class FundOverview {
    FundId: number=undefined;
    FundFamily: string=undefined;
    Name: string=undefined;
    URL: string=undefined;
    Subscription: number=undefined;
    SubscriptionAsOf: number=undefined;
    HoldingCostBasis: number=undefined;
    CostBasisAsOf: string=undefined;
    HoldingFMV: number=undefined;
    FMVAsOf: string=undefined;
    MinFundHoldingId: number=undefined;
    Commitment: number=undefined;
    LPPercent: number=undefined;
    GainLoss: number = undefined;
}
export class GeneralAssetList {
    Id: number=undefined;
    OwnerId: number=undefined;
    Name: string=undefined;
    AcquiredDate: string=undefined;
    AcquiredComment: string=undefined;
    DisposedDate: string=undefined;
    DisposeComment: string=undefined;
    PurchaseValue: number=undefined;
    AssetClassName: string=undefined;
    BalanceSheetCategory: string=undefined;
    BalanceSheetSubCategory: string=undefined;
    OwnerName: string=undefined;
    Client: string = undefined;
}
export class GeneralPartnerPoints {
    Id: number=undefined;
    InvestmentParentId: number=undefined;
    ClientId: number=undefined;
    ClientName: string=undefined;
    Year: number=undefined;
    Points: number = undefined;
}
export class HoldingEntities {
    Id: number=undefined;
    ClientId: number=undefined;
    Name: string=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    ModifyDate: string=undefined;
    ModifyUser: string=undefined;
    Display: string=undefined;
    ClientName: string=undefined;
    Deletable: boolean = undefined;
}
export class ImportFundLookThrough {
    ImportLogId: number=undefined;
    FileName: string=undefined;
    StartTime: string=undefined;
    CompleteDate: string=undefined;
    Seconds: number=undefined;
    Success: boolean=undefined;
    ImportRecords: number=undefined;
    Value: number=undefined;
    CostBasis: number=undefined;
    Shares: number=undefined;
    AsOf: string=undefined;
    MaxAsOf: string = undefined;
}
export class InvestmentCashActivitySummary {
    Client: string=undefined;
    Investment: string=undefined;
    Proceeds: number=undefined;
    Purchases: number=undefined;
    NetCash: number=undefined;
    ParentId: number=undefined;
    BalanceSheetCategory: string=undefined;
    BalanceSheetSubCategory: string=undefined;
    BalanceSheetCategorySubCategory: string = undefined;
}
export class InvestmentList {
    Id: number=undefined;
    InvestmentId: number=undefined;
    Name: string=undefined;
    Ticker: string=undefined;
    TickerSort: string=undefined;
    Owner: string=undefined;
    OwnerShortName: string=undefined;
    InvestmentTypeId: number=undefined;
    InvestmentType: string=undefined;
    InvestmentTypeShort: string=undefined;
    AccountType: string=undefined;
    AccountTypeId: number=undefined;
    ClosedDate: string=undefined;
    ClosedText: string=undefined;
    Region: string=undefined;
    Sector: string=undefined;
    Ownership: string=undefined;
    Currency: string=undefined;
    Source: string=undefined;
    NameOwnerAccountTypeIdBank: string=undefined;
    OwnerId: number=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    OwnerIsIrrevocableTrust: boolean=undefined;
    TransferGroup: string=undefined;
    XTransferGroup: string=undefined;
    DefaultReturnTransactionTypeId: number=undefined;
    DefaultPurchaseTransactionTypeId: number=undefined;
    GetStatistics: boolean=undefined;
    InvestmentParentId: number=undefined;
    ParentId: number=undefined;
    CostBasisMethod: string=undefined;
    CUSIP: string=undefined;
    IsFixedIncome: boolean=undefined;
    IsTreasury: boolean=undefined;
    BankAccountId: number=undefined;
    IsBankAccountIdNotDefault: boolean=undefined;
    BankAccountNameNumberMasked: string=undefined;
    HasCommitment: boolean=undefined;
    NameTypeDisplay: string=undefined;
    NameTypeBankDisplay: string=undefined;
    ShowEquity: boolean=undefined;
    UseNewTransactionScheme: boolean=undefined;
    UseNewTransactionSchemeUser: string=undefined;
    UseNewTransactionSchemeDate: string=undefined;
    UseNewTransactionSchemeDisplay: string=undefined;
    TransactionConversionReview1: boolean=undefined;
    TransactionConversionReviewUser1: string=undefined;
    TransactionConversionReviewDate1: string=undefined;
    TransactionConversionReview1Display: string=undefined;
    TransactionConversionReview2: boolean=undefined;
    TransactionConversionReviewUser2: string=undefined;
    TransactionConversionReviewDate2: string=undefined;
    TransactionConversionReview2Display: string=undefined;
    CreatedInNewScheme: boolean=undefined;
    IsConverted: boolean=undefined;
    BalanceSheetCategoryIdOverride: number=undefined;
    BalanceSheetSuperCategory: string=undefined;
    BalanceSheetCategory: string=undefined;
    BalanceSheetSubCategory: string=undefined;
    SourceId: number=undefined;
    SourceCode: string=undefined;
    HFRedemptionFrequency: string=undefined;
    HFNoticeRequired: number=undefined;
    HFLiquidityRestrictions: string=undefined;
    HFLiquidityOutOptions: string=undefined;
    CreateDateUser: string=undefined;
    ModifyDateUser: string=undefined;
    Deletable: boolean=undefined;
    SuspendCapitalCallLiability: boolean=undefined;
    NotTaxableOverrideBalanceSheetCategory: boolean=undefined;
    ConversionStatus: string=undefined;
    InvestmentURL: string=undefined;
    Instruments: number=undefined;
    IncomeType: string = undefined;
}
export class InvestmentParentBalanceSheetCategories {
    Id: number=undefined;
    Name: string=undefined;
    ClientId: number=undefined;
    Category: string=undefined;
    SubCategory: string=undefined;
    BalanceSheetCategoryId: number=undefined;
    SuperCategory: string = undefined;
}
export class InvestmentParentList {
    Id: number=undefined;
    Name: string=undefined;
    TGCode: number=undefined;
    Source: number=undefined;
    Currency: string=undefined;
    InvestmentTypeId: number=undefined;
    InvestmentType: string=undefined;
    SectorId: number=undefined;
    Sector: string=undefined;
    OwnershipId: number=undefined;
    Ownership: string=undefined;
    Region: string=undefined;
    Investments: number=undefined;
    IsFixedIncome: boolean=undefined;
    FundFamilyRequired: boolean = undefined;
}
export class InvestmentSources {
    Id: number=undefined;
    Name: string=undefined;
    Display: string=undefined;
    ClientId: number = undefined;
}
export class InvestmentStatisticsHedgeFund {
    InvestmentId: number=undefined;
    Name: string=undefined;
    Owner: string=undefined;
    InvestmentType: string=undefined;
    Invested: number=undefined;
    Redemptions: number=undefined;
    LastTransactionId: number=undefined;
    FMVAsOf: string=undefined;
    FMV: number=undefined;
    FMVDistribution: number=undefined;
    FMVTransferredOut: number=undefined;
    FMVContribution: number=undefined;
    FMVAdjusted: number=undefined;
    EstimatedROI: number=undefined;
    FMVTypeSource: string=undefined;
    HFLiquidityOutOptions: string=undefined;
    HFLiquidityRestrictions: string=undefined;
    HFNoticeRequired: number=undefined;
    HFRedemptionFrequency: string=undefined;
    EarliestRedemptionDate: string=undefined;
    NoticeRequiredDate: string=undefined;
    Explanation: string=undefined;
    LogDate: string=undefined;
    ROIMultiplier: string=undefined;
    ValuationForSummaryStatistics: number=undefined;
    Closed: boolean = undefined;
}
export class InvestmentStatisticsPrivateEquity {
    InvestmentId: number=undefined;
    Name: string=undefined;
    Owner: string=undefined;
    InvestmentType: string=undefined;
    CommittedAmount: number=undefined;
    CalledAmount: number=undefined;
    Distributions: number=undefined;
    RemainingCommitment: number=undefined;
    LastTransactionId: number=undefined;
    FMVAsOf: string=undefined;
    FMV: number=undefined;
    FMVDistribution: number=undefined;
    FMVTransferredOut: number=undefined;
    FMVContribution: number=undefined;
    FMVAdjusted: number=undefined;
    FMVTypeSource: string=undefined;
    EstimatedROI: number=undefined;
    LogDate: string=undefined;
    ROIMultiplier: string=undefined;
    ValuationForSummaryStatistics: number=undefined;
    OwnerEntityId: number=undefined;
    OwnerEntityType: string=undefined;
    OwnerEntityName: string=undefined;
    OwnershipRatio: number = undefined;
}
export class InvestmentStatisticsSingleCompany {
    InvestmentId: number=undefined;
    Name: string=undefined;
    Owner: string=undefined;
    InvestmentType: string=undefined;
    SharesHeld: number=undefined;
    AverageCostPerShare: number=undefined;
    PurchaseCost: number=undefined;
    ReturnProceeds: number=undefined;
    LastTransactionId: number=undefined;
    CloseDate: string=undefined;
    ClosePrice: number=undefined;
    FMVAsOf: string=undefined;
    FMV: number=undefined;
    FMVDistribution: number=undefined;
    FMVTransferredOut: number=undefined;
    FMVContribution: number=undefined;
    FMVAdjusted: number=undefined;
    OwnershipMarketValue: number=undefined;
    OwnershipMarketValueComment: string=undefined;
    CurrentGainOrLoss: number=undefined;
    MarketValuePerShare: number=undefined;
    EstimatedROI: number=undefined;
    ROIMultiplier: string=undefined;
    CostBasisRemaining: number=undefined;
    RunningCostBasis: number=undefined;
    ValuationForSummaryStatistics: number=undefined;
    OwnershipMarketValuePerShare: number=undefined;
    ShareLabel: string = undefined;
}
export class InvestmentTypes {
    Id: number=undefined;
    Name: string=undefined;
    Display: string=undefined;
    ShowOwnership: boolean=undefined;
    ShowSector: boolean=undefined;
    ShowRegion: boolean=undefined;
    ShowFlashValuation: boolean=undefined;
    ShowCommitmentPeriod: boolean=undefined;
    FundFamilyRequired: boolean=undefined;
    HasInceptionDate: boolean=undefined;
    HasCarry: boolean=undefined;
    ShowCommitmentPeriodLengthInMonths: boolean=undefined;
    HasCommitment: boolean=undefined;
    ShowPartnerType: boolean=undefined;
    UsePublicCompanyValuation: boolean=undefined;
    IsHedgeFund: boolean=undefined;
    IsPrivateEquity: boolean = undefined;
}
export class JPMCompImport {
    ImportLogId: number=undefined;
    FileName: string=undefined;
    StartTime: string=undefined;
    CompleteDate: string=undefined;
    Seconds: number=undefined;
    Success: boolean=undefined;
    Records: number = undefined;
}
export class K1CashDetail {
    Type: string=undefined;
    InvestmentId: number=undefined;
    OwnerId: number=undefined;
    TransactionHeaderId: number=undefined;
    Date: string=undefined;
    Description: string=undefined;
    Cash: number=undefined;
    Ratio: number = undefined;
}
export class K1StockDetail {
    InvestmentId: number=undefined;
    OwnerId: number=undefined;
    TransactionHeaderId: number=undefined;
    Date: string=undefined;
    Description: string=undefined;
    StockAmount: number=undefined;
    CostBasis: number=undefined;
    Shares: number=undefined;
    Ratio: number = undefined;
}
export class LookThrough {
    AsOf: string=undefined;
    Client: string=undefined;
    Owner: string=undefined;
    FundFamily: string=undefined;
    Fund: string=undefined;
    Holding: string=undefined;
    HoldingCostBasis: number=undefined;
    HoldingFMV: number=undefined;
    HoldingFMVSortOpposite: number=undefined;
    Shares: number=undefined;
    HideSubTotal: boolean = undefined;
}
export class ManagementFees {
    Id: number=undefined;
    InvestmentParentId: number=undefined;
    StartDate: string=undefined;
    EndDate: string=undefined;
    FeeRate: number = undefined;
}
export class OwnerAssets {
    EntityId: number=undefined;
    EntityType: string=undefined;
    EntityTypeDisplay: string=undefined;
    Name: string = undefined;
}
export class ReApplyValuationStatus {
    Caller: string=undefined;
    ProcessStarted: string=undefined;
    CreateUser: string=undefined;
    LogId: number=undefined;
    ValuationsProcessed: number=undefined;
    ReApplyStarted: string=undefined;
    ReApplyCompleted: string = undefined;
}
export class ReceiptsOfAssets {
    BankInvestmentTransactionId: number=undefined;
    BankAccount: string=undefined;
    Ticker: string=undefined;
    Description: string=undefined;
    PostingDate: string=undefined;
    TradeDate: string=undefined;
    SettlementDate: string=undefined;
    Units: number=undefined;
    ValueLocal: number=undefined;
    BankAccountId: number=undefined;
    BankAccountMatched: boolean=undefined;
    InvestmentId: number=undefined;
    InvestmentMatched: boolean=undefined;
    InvestmentTransactionMatched: boolean=undefined;
    TransactionDate: string=undefined;
    Amount: number=undefined;
    Shares: number=undefined;
    InvestmentTransactionId: number=undefined;
    MatchedInvestmentTransaction: number = undefined;
}
export class RecognizedK1 {
    Id: number=undefined;
    LogId: number=undefined;
    AttachmentId: number=undefined;
    FileId: string=undefined;
    DriveId: string=undefined;
    CreateDateLocal: string=undefined;
    AttachmentDateYear: number=undefined;
    Description: string=undefined;
    TaxYear: number=undefined;
    Partnership: string=undefined;
    PartnershipEIN: string=undefined;
    OwnerEIN: string=undefined;
    IsFinalK1: boolean=undefined;
    CashDistribution_19_A: number=undefined;
    StockDistribution_19_C: number=undefined;
    OrdinaryBusinesIncome_1: number=undefined;
    InterestIncome_5: number=undefined;
    OrdinaryDividends_6a: number=undefined;
    QualifiedDividends_6b: number=undefined;
    STCG_8: number=undefined;
    LTCG_9a: number = undefined;
}
export class ReturnIncomeType {
    Id: number=undefined;
    IncomeType: string=undefined;
    Display: string = undefined;
}
export class StructureForInvestment {
    InvestmentId: number=undefined;
    StructureType: string=undefined;
    Structure: string=undefined;
    value: string=undefined;
    Display: string=undefined;
    EffectiveShares: number = undefined;
}
export class StructureTypeForInvestment {
    InvestmentId: number=undefined;
    StructureType: string=undefined;
    Display: string = undefined;
}
export class TreasuryBillPricing {
    SettleDate: string=undefined;
    MaturityDate: string=undefined;
    DaysToMaturity: number=undefined;
    Yield: number=undefined;
    FaceValue: number=undefined;
    Price: number=undefined;
    Interest: number=undefined;
    PricePer100: number=undefined;
    BondEquivalentYield: number=undefined;
    Method: string = undefined;
}
export class Valuables {
    Id: number=undefined;
    ValuablesId: number=undefined;
    ClientId: number=undefined;
    OwnerId: number=undefined;
    Owner: string=undefined;
    CategoryId: number=undefined;
    Category: string=undefined;
    Type: string=undefined;
    Principal: string=undefined;
    Source: string=undefined;
    Description: string=undefined;
    Location: string=undefined;
    LocationDisplay: string=undefined;
    InsuranceIdDisplay: string=undefined;
    InsuranceId: number=undefined;
    InvoiceId: number=undefined;
    Vendor: string=undefined;
    PurchaseVendorId: number=undefined;
    InvoiceNumber: string=undefined;
    InvoiceNumberDisplay: string=undefined;
    Cost: number=undefined;
    PurchaseDate: string=undefined;
    InvoiceDate: string=undefined;
    InvoiceNotes: string=undefined;
    PurchaseInvoiceFileId: string=undefined;
    SaleDate: string=undefined;
    PurchaseFromOther: string=undefined;
    PurchaseFromOtherVendorInvoiceId: string=undefined;
    SalePrice: number=undefined;
    SaleToPartyVendorId: number=undefined;
    SalePartyVendorOther: string=undefined;
    SaleVendorName: string=undefined;
    SaleInvoiceNumberDisplay: string=undefined;
    SaleInvoiceId: number=undefined;
    SaleInvoiceFileId: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    LastChange: string=undefined;
    HasPurchaseInvoice: boolean=undefined;
    HasSaleInvoice: boolean=undefined;
    IsCostFromLedger: boolean=undefined;
    IsSalePriceFromLedger: boolean=undefined;
    URL: string=undefined;
    Valuation: number=undefined;
    ValuationType: string=undefined;
    ValuationSource: string=undefined;
    AppraisalStatus: string=undefined;
    ValuationDate: string=undefined;
    InsurancePremiumPer1000: number=undefined;
    InsurancePremium: number = undefined;
}
export class ValuablesCategories {
    CategoryId: number=undefined;
    Category: string=undefined;
    PrincipalDescription: string=undefined;
    TypeDescription: string = undefined;
}
export class ValuablesLocations {
    Label: string=undefined;
    LocationDisplay: string=undefined;
    Items: number=undefined;
    ReportLabel: string=undefined;
    Cost: number = undefined;
}
export class ProcessJPMCompImport {
    ImportLogId: number=undefined;
    FileName: string=undefined;
    StartTime: string=undefined;
    CompleteDate: string=undefined;
    Seconds: number=undefined;
    Success: boolean=undefined;
    Records: number = undefined;
}
export class QueueProcessInvestmentStats {
    QueueId: number=undefined;
    LogId: number = undefined;
}
export class QueueProcessLookThrough {
    QueueId: number=undefined;
    LogId: number = undefined;
}
export class QueueReApplyValuations {
    QueueId: number=undefined;
    LogId: number = undefined;
}
export class SetInvestmentNewTransactionReview1 {
    Id: number=undefined;
    InvestmentId: number=undefined;
    Name: string=undefined;
    Ticker: string=undefined;
    TickerSort: string=undefined;
    Owner: string=undefined;
    OwnerShortName: string=undefined;
    InvestmentTypeId: number=undefined;
    InvestmentType: string=undefined;
    InvestmentTypeShort: string=undefined;
    AccountType: string=undefined;
    AccountTypeId: number=undefined;
    ClosedDate: string=undefined;
    ClosedText: string=undefined;
    Region: string=undefined;
    Sector: string=undefined;
    Ownership: string=undefined;
    Currency: string=undefined;
    Source: string=undefined;
    NameOwnerAccountTypeIdBank: string=undefined;
    OwnerId: number=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    OwnerIsIrrevocableTrust: boolean=undefined;
    TransferGroup: string=undefined;
    XTransferGroup: string=undefined;
    DefaultReturnTransactionTypeId: number=undefined;
    DefaultPurchaseTransactionTypeId: number=undefined;
    GetStatistics: boolean=undefined;
    InvestmentParentId: number=undefined;
    ParentId: number=undefined;
    CostBasisMethod: string=undefined;
    CUSIP: string=undefined;
    IsFixedIncome: boolean=undefined;
    IsTreasury: boolean=undefined;
    BankAccountId: number=undefined;
    IsBankAccountIdNotDefault: boolean=undefined;
    BankAccountNameNumberMasked: string=undefined;
    HasCommitment: boolean=undefined;
    NameTypeDisplay: string=undefined;
    NameTypeBankDisplay: string=undefined;
    ShowEquity: boolean=undefined;
    UseNewTransactionScheme: boolean=undefined;
    UseNewTransactionSchemeUser: string=undefined;
    UseNewTransactionSchemeDate: string=undefined;
    UseNewTransactionSchemeDisplay: string=undefined;
    TransactionConversionReview1: boolean=undefined;
    TransactionConversionReviewUser1: string=undefined;
    TransactionConversionReviewDate1: string=undefined;
    TransactionConversionReview1Display: string=undefined;
    TransactionConversionReview2: boolean=undefined;
    TransactionConversionReviewUser2: string=undefined;
    TransactionConversionReviewDate2: string=undefined;
    TransactionConversionReview2Display: string=undefined;
    CreatedInNewScheme: boolean=undefined;
    IsConverted: boolean=undefined;
    BalanceSheetCategoryIdOverride: number=undefined;
    BalanceSheetSuperCategory: string=undefined;
    BalanceSheetCategory: string=undefined;
    BalanceSheetSubCategory: string=undefined;
    SourceId: number=undefined;
    SourceCode: string=undefined;
    HFRedemptionFrequency: string=undefined;
    HFNoticeRequired: number=undefined;
    HFLiquidityRestrictions: string=undefined;
    HFLiquidityOutOptions: string=undefined;
    CreateDateUser: string=undefined;
    ModifyDateUser: string=undefined;
    Deletable: boolean=undefined;
    SuspendCapitalCallLiability: boolean=undefined;
    NotTaxableOverrideBalanceSheetCategory: boolean=undefined;
    ConversionStatus: string=undefined;
    InvestmentURL: string=undefined;
    Instruments: number=undefined;
    IncomeType: string = undefined;
}
export class SetInvestmentNewTransactionReview2 {
    Id: number=undefined;
    InvestmentId: number=undefined;
    Name: string=undefined;
    Ticker: string=undefined;
    TickerSort: string=undefined;
    Owner: string=undefined;
    OwnerShortName: string=undefined;
    InvestmentTypeId: number=undefined;
    InvestmentType: string=undefined;
    InvestmentTypeShort: string=undefined;
    AccountType: string=undefined;
    AccountTypeId: number=undefined;
    ClosedDate: string=undefined;
    ClosedText: string=undefined;
    Region: string=undefined;
    Sector: string=undefined;
    Ownership: string=undefined;
    Currency: string=undefined;
    Source: string=undefined;
    NameOwnerAccountTypeIdBank: string=undefined;
    OwnerId: number=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    OwnerIsIrrevocableTrust: boolean=undefined;
    TransferGroup: string=undefined;
    XTransferGroup: string=undefined;
    DefaultReturnTransactionTypeId: number=undefined;
    DefaultPurchaseTransactionTypeId: number=undefined;
    GetStatistics: boolean=undefined;
    InvestmentParentId: number=undefined;
    ParentId: number=undefined;
    CostBasisMethod: string=undefined;
    CUSIP: string=undefined;
    IsFixedIncome: boolean=undefined;
    IsTreasury: boolean=undefined;
    BankAccountId: number=undefined;
    IsBankAccountIdNotDefault: boolean=undefined;
    BankAccountNameNumberMasked: string=undefined;
    HasCommitment: boolean=undefined;
    NameTypeDisplay: string=undefined;
    NameTypeBankDisplay: string=undefined;
    ShowEquity: boolean=undefined;
    UseNewTransactionScheme: boolean=undefined;
    UseNewTransactionSchemeUser: string=undefined;
    UseNewTransactionSchemeDate: string=undefined;
    UseNewTransactionSchemeDisplay: string=undefined;
    TransactionConversionReview1: boolean=undefined;
    TransactionConversionReviewUser1: string=undefined;
    TransactionConversionReviewDate1: string=undefined;
    TransactionConversionReview1Display: string=undefined;
    TransactionConversionReview2: boolean=undefined;
    TransactionConversionReviewUser2: string=undefined;
    TransactionConversionReviewDate2: string=undefined;
    TransactionConversionReview2Display: string=undefined;
    CreatedInNewScheme: boolean=undefined;
    IsConverted: boolean=undefined;
    BalanceSheetCategoryIdOverride: number=undefined;
    BalanceSheetSuperCategory: string=undefined;
    BalanceSheetCategory: string=undefined;
    BalanceSheetSubCategory: string=undefined;
    SourceId: number=undefined;
    SourceCode: string=undefined;
    HFRedemptionFrequency: string=undefined;
    HFNoticeRequired: number=undefined;
    HFLiquidityRestrictions: string=undefined;
    HFLiquidityOutOptions: string=undefined;
    CreateDateUser: string=undefined;
    ModifyDateUser: string=undefined;
    Deletable: boolean=undefined;
    SuspendCapitalCallLiability: boolean=undefined;
    NotTaxableOverrideBalanceSheetCategory: boolean=undefined;
    ConversionStatus: string=undefined;
    InvestmentURL: string=undefined;
    Instruments: number=undefined;
    IncomeType: string = undefined;
}
export class SetInvestmentUseNewTransactionScheme {
    Id: number=undefined;
    InvestmentId: number=undefined;
    Name: string=undefined;
    Ticker: string=undefined;
    TickerSort: string=undefined;
    Owner: string=undefined;
    OwnerShortName: string=undefined;
    InvestmentTypeId: number=undefined;
    InvestmentType: string=undefined;
    InvestmentTypeShort: string=undefined;
    AccountType: string=undefined;
    AccountTypeId: number=undefined;
    ClosedDate: string=undefined;
    ClosedText: string=undefined;
    Region: string=undefined;
    Sector: string=undefined;
    Ownership: string=undefined;
    Currency: string=undefined;
    Source: string=undefined;
    NameOwnerAccountTypeIdBank: string=undefined;
    OwnerId: number=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    OwnerIsIrrevocableTrust: boolean=undefined;
    TransferGroup: string=undefined;
    XTransferGroup: string=undefined;
    DefaultReturnTransactionTypeId: number=undefined;
    DefaultPurchaseTransactionTypeId: number=undefined;
    GetStatistics: boolean=undefined;
    InvestmentParentId: number=undefined;
    ParentId: number=undefined;
    CostBasisMethod: string=undefined;
    CUSIP: string=undefined;
    IsFixedIncome: boolean=undefined;
    IsTreasury: boolean=undefined;
    BankAccountId: number=undefined;
    IsBankAccountIdNotDefault: boolean=undefined;
    BankAccountNameNumberMasked: string=undefined;
    HasCommitment: boolean=undefined;
    NameTypeDisplay: string=undefined;
    NameTypeBankDisplay: string=undefined;
    ShowEquity: boolean=undefined;
    UseNewTransactionScheme: boolean=undefined;
    UseNewTransactionSchemeUser: string=undefined;
    UseNewTransactionSchemeDate: string=undefined;
    UseNewTransactionSchemeDisplay: string=undefined;
    TransactionConversionReview1: boolean=undefined;
    TransactionConversionReviewUser1: string=undefined;
    TransactionConversionReviewDate1: string=undefined;
    TransactionConversionReview1Display: string=undefined;
    TransactionConversionReview2: boolean=undefined;
    TransactionConversionReviewUser2: string=undefined;
    TransactionConversionReviewDate2: string=undefined;
    TransactionConversionReview2Display: string=undefined;
    CreatedInNewScheme: boolean=undefined;
    IsConverted: boolean=undefined;
    BalanceSheetCategoryIdOverride: number=undefined;
    BalanceSheetSuperCategory: string=undefined;
    BalanceSheetCategory: string=undefined;
    BalanceSheetSubCategory: string=undefined;
    SourceId: number=undefined;
    SourceCode: string=undefined;
    HFRedemptionFrequency: string=undefined;
    HFNoticeRequired: number=undefined;
    HFLiquidityRestrictions: string=undefined;
    HFLiquidityOutOptions: string=undefined;
    CreateDateUser: string=undefined;
    ModifyDateUser: string=undefined;
    Deletable: boolean=undefined;
    SuspendCapitalCallLiability: boolean=undefined;
    NotTaxableOverrideBalanceSheetCategory: boolean=undefined;
    ConversionStatus: string=undefined;
    InvestmentURL: string=undefined;
    Instruments: number=undefined;
    IncomeType: string = undefined;
}

// Parameter interfaces:

export interface DeleteInvestmentParameters {
    InvestmentId: number;
}

export interface GetAccountTypesParameters {
    ClientId: number;
    InvestmentTypeId: number;
}

export interface GetCapitalCallScheduleParameters {
    InvestmentParentId: number;
}

export interface GetCommitmentAnalysisParameters {
    InvestmentId: number;
    AsOf: string;
    ExcludeTransactionHeaderId: number;
}

export interface GetDividendSummaryParameters {
    ClientId: number;
    RegularOnly: boolean;
}

export interface GetDonationLetterParameters {
    TransactionHeaderId: number;
}

export interface GetExpectedK1Parameters {
    TaxYear: number;
    ClientId: number;
}

export interface GetFeePayingCommitmentsParameters {
    InvestmentParentId: number;
}

export interface GetFixedIncomeParameters {
    ClientId: number;
    OwnerId: number;
    FixedIncomeId: number;
    CurrentOnly: boolean;
    BankAccountId: number;
}

export interface GetFixedIncomeSummaryParameters {
    ClientId: number;
}

export interface GetFundHoldingNamesParameters {
    ClientId: number;
}

export interface GetFundHoldingsParameters {
    FundId: number;
    ClientId: number;
    AsOf: string;
}

export interface GetFundHoldingsWithTranchesParameters {
    FundId: number;
    ClientId: number;
    AsOf: string;
    OrderBy: string;
    HoldingId: number;
}

export interface GetFundingSourceParameters {
    TransactionTypeId: number;
    PurchasingInvestmentId: number;
}

export interface GetFundingSourceAnnuityParameters {
    InvestmentId: number;
}

export interface GetFundingSourcePromissoryNoteParameters {
    InvestmentId: number;
}

export interface GetFundingSourcePublicEquityParameters {
    InvestmentId: number;
}

export interface GetFundingSourceTypesParameters {
    TransactionTypeId: number;
    NewInvestmentId: number;
}

export interface GetFundListParameters {
    ClientId: number;
    IncludeDirect: boolean;
}

export interface GetFundOverviewParameters {
    ClientId: number;
    AsOf: string;
    FundId: number;
}

export interface GetGeneralAssetListParameters {
    GeneralAssetId: number;
    ClientId: number;
    AssetClassId: number;
    OwnerId: number;
}

export interface GetGeneralPartnerPointsParameters {
    InvestmentParentId: number;
}

export interface GetHoldingEntitiesParameters {
    ClientId: number;
}

export interface GetImportFundLookThroughParameters {
    ImportLogId: number;
}

export interface GetInvestmentCashActivitySummaryParameters {
    StartDate: string;
    EndDate: string;
    ClientId: number;
}

export interface GetInvestmentListParameters {
    ClientId: number;
    InvestmentParentId: number;
    OwnerId: number;
    OrderBy: string;
    InvestmentTypeId: number;
    IncludeTransferGroupOfSpecifiedInvestmentParentId: boolean;
    IncludeChildrenOfOwner: boolean;
    InvestmentId: number;
    CurrentOnly: boolean;
    ConsiderationEntityType: string;
    OnlyUsingNewTransactionMethodology: boolean;
    ExcludeOwnerId: number;
    ExcludeInvestmentId: number;
    Ticker: string;
}

export interface GetInvestmentParentBalanceSheetCategoriesParameters {
    InvestmentParentId: number;
    ClientId: number;
}

export interface GetInvestmentParentListParameters {
    ClientIdList: string;
    OwnerIdList: string;
    FundFamilyId: number;
    InvestmentTypeId: number;
    ConsiderationEntityType: string;
    InvestmentParentId: number;
}

export interface GetInvestmentSourcesParameters {
    ClientId: number;
}

export interface GetInvestmentStatisticsHedgeFundParameters {
    InvestmentId: number;
    AsOf: string;
}

export interface GetInvestmentStatisticsPrivateEquityParameters {
    InvestmentId: number;
    AsOf: string;
}

export interface GetInvestmentStatisticsSingleCompanyParameters {
    InvestmentId: number;
    AsOf: string;
}

export interface GetInvestmentTypesParameters {
    InvestmentTypeId: number;
}

export interface GetJPMCompImportParameters {
    ImportLogId: number;
}

export interface GetK1CashDetailParameters {
    TaxYear: number;
    ClientId: number;
    InvestmentId: number;
}

export interface GetK1StockDetailParameters {
    TaxYear: number;
    ClientId: number;
    InvestmentId: number;
}

export interface GetLookThroughParameters {
    ClientId: number;
    ReportDate: string;
    FundIdList: string;
}

export interface GetManagementFeesParameters {
    InvestmentParentId: number;
}

export interface GetOwnerAssetsParameters {
    OwnerId: number;
}

export interface GetReApplyValuationStatusParameters {
    InvestmentId: number;
}

export interface GetReceiptsOfAssetsParameters {
    LogId: number;
    ClientId: number;
    Ticker: string;
    AllowMatchToNonAccrual: boolean;
}

export interface GetRecognizedK1Parameters {
    Year: number;
}

export interface GetStructureForInvestmentParameters {
    InvestmentId: number;
    StructureType: string;
    TransactionId: number;
    TransactionDate: string;
    EntityId: number;
}

export interface GetStructureTypeForInvestmentParameters {
    InvestmentId: number;
    Side: string;
}

export interface GetTreasuryBillPricingParameters {
    SettleDate: string;
    MaturityDate: string;
    Yield: number;
    FaceValue: number;
    Price: number;
    Method: string;
}

export interface GetValuablesParameters {
    ClientId: number;
    ValueablesId: number;
    Location: string;
    CategoryId: number;
}

export interface GetValuablesLocationsParameters {
    InventoryOnly: boolean;
    CategoryId: number;
}

export interface InsertDonationLetterParameters {
    TransactionHeaderId: number;
}

export interface ProcessFundLookThroughImportParameters {
    ImportLogId: number;
}

export interface ProcessJPMCompImportParameters {
    ImportLogId: number;
}

export interface QueueProcessInvestmentStatsParameters {
    LogId: number;
    Silent: boolean;
}

export interface QueueProcessLookThroughParameters {
    LogId: number;
}

export interface QueueReApplyValuationsParameters {
    InvestmentId: number;
    OnAndAfterDate: string;
}

export interface ReapplyFixedIncomeValuationsParameters {
    FixedIncomeId: number;
}

export interface ReapplyInvestmentValuationsParameters {
    InvestmentId: number;
    OnAndAfterDate: string;
    LogId: number;
    CompleteLogAtCompletion: boolean;
    NotifyUserAtCompletion: boolean;
}

export interface SetInvestmentNewTransactionReview1Parameters {
    InvestmentId: number;
    IsReviewed: boolean;
}

export interface SetInvestmentNewTransactionReview2Parameters {
    InvestmentId: number;
    IsReviewed: boolean;
}

export interface SetInvestmentUseNewTransactionSchemeParameters {
    InvestmentId: number;
    UseNewScheme: boolean;
}

export class AssetService {

  DeleteInvestment (params: DeleteInvestmentParameters): Promise<DeleteInvestment[]> {
    return smartData({
        storedProcName: 'asset.DeleteInvestment',
        params: params
      });
    }

  GetAccountTypes (params: GetAccountTypesParameters): Promise<AccountTypes[]> {
    return smartData({
        storedProcName: 'asset.GetAccountTypes',
        params: params
      });
    }

  GetCapitalCallSchedule (params: GetCapitalCallScheduleParameters): Promise<CapitalCallSchedule[]> {
    return smartData({
        storedProcName: 'asset.GetCapitalCallSchedule',
        params: params
      });
    }

  GetCommitmentAnalysis (params: GetCommitmentAnalysisParameters): Promise<CommitmentAnalysis[]> {
    return smartData({
        storedProcName: 'asset.GetCommitmentAnalysis',
        params: params
      });
    }

  GetCostBasisMethods (): Promise<CostBasisMethods[]> {
    return smartData({
        storedProcName: 'asset.GetCostBasisMethods',
        params: {}
      });
    }

  GetDividendSummary (params: GetDividendSummaryParameters): Promise<DividendSummary[]> {
    return smartData({
        storedProcName: 'asset.GetDividendSummary',
        params: params
      });
    }

  GetDonationLetter (params: GetDonationLetterParameters): Promise<DonationLetter[]> {
    return smartData({
        storedProcName: 'asset.GetDonationLetter',
        params: params
      });
    }

  GetExpectedK1 (params: GetExpectedK1Parameters): Promise<ExpectedK1[]> {
    return smartData({
        storedProcName: 'asset.GetExpectedK1',
        params: params
      });
    }

  GetFeePayingCommitments (params: GetFeePayingCommitmentsParameters): Promise<FeePayingCommitments[]> {
    return smartData({
        storedProcName: 'asset.GetFeePayingCommitments',
        params: params
      });
    }

  GetFixedIncome (params: GetFixedIncomeParameters): Promise<FixedIncome[]> {
    return smartData({
        storedProcName: 'asset.GetFixedIncome',
        params: params
      });
    }

  GetFixedIncomeSummary (params: GetFixedIncomeSummaryParameters): Promise<FixedIncomeSummary[]> {
    return smartData({
        storedProcName: 'asset.GetFixedIncomeSummary',
        params: params
      });
    }

  GetFundFamilies (): Promise<FundFamilies[]> {
    return smartData({
        storedProcName: 'asset.GetFundFamilies',
        params: {}
      });
    }

  GetFundHoldingNames (params: GetFundHoldingNamesParameters): Promise<FundHoldingNames[]> {
    return smartData({
        storedProcName: 'asset.GetFundHoldingNames',
        params: params
      });
    }

  GetFundHoldings (params: GetFundHoldingsParameters): Promise<FundHoldings[]> {
    return smartData({
        storedProcName: 'asset.GetFundHoldings',
        params: params
      });
    }

  GetFundHoldingsWithTranches (params: GetFundHoldingsWithTranchesParameters): Promise<FundHoldingsWithTranches[]> {
    return smartData({
        storedProcName: 'asset.GetFundHoldingsWithTranches',
        params: params
      });
    }

  GetFundingSource (params: GetFundingSourceParameters): Promise<FundingSource[]> {
    return smartData({
        storedProcName: 'asset.GetFundingSource',
        params: params
      });
    }

  GetFundingSourceAnnuity (params: GetFundingSourceAnnuityParameters): Promise<FundingSourceAnnuity[]> {
    return smartData({
        storedProcName: 'asset.GetFundingSourceAnnuity',
        params: params
      });
    }

  GetFundingSourcePromissoryNote (params: GetFundingSourcePromissoryNoteParameters): Promise<FundingSourcePromissoryNote[]> {
    return smartData({
        storedProcName: 'asset.GetFundingSourcePromissoryNote',
        params: params
      });
    }

  GetFundingSourcePublicEquity (params: GetFundingSourcePublicEquityParameters): Promise<FundingSourcePublicEquity[]> {
    return smartData({
        storedProcName: 'asset.GetFundingSourcePublicEquity',
        params: params
      });
    }

  GetFundingSourceTypes (params: GetFundingSourceTypesParameters): Promise<FundingSourceTypes[]> {
    return smartData({
        storedProcName: 'asset.GetFundingSourceTypes',
        params: params
      });
    }

  GetFundList (params: GetFundListParameters): Promise<FundList[]> {
    return smartData({
        storedProcName: 'asset.GetFundList',
        params: params
      });
    }

  GetFundOverview (params: GetFundOverviewParameters): Promise<FundOverview[]> {
    return smartData({
        storedProcName: 'asset.GetFundOverview',
        params: params
      });
    }

  GetGeneralAssetList (params: GetGeneralAssetListParameters): Promise<GeneralAssetList[]> {
    return smartData({
        storedProcName: 'asset.GetGeneralAssetList',
        params: params
      });
    }

  GetGeneralPartnerPoints (params: GetGeneralPartnerPointsParameters): Promise<GeneralPartnerPoints[]> {
    return smartData({
        storedProcName: 'asset.GetGeneralPartnerPoints',
        params: params
      });
    }

  GetHoldingEntities (params: GetHoldingEntitiesParameters): Promise<HoldingEntities[]> {
    return smartData({
        storedProcName: 'asset.GetHoldingEntities',
        params: params
      });
    }

  GetImportFundLookThrough (params: GetImportFundLookThroughParameters): Promise<ImportFundLookThrough[]> {
    return smartData({
        storedProcName: 'asset.GetImportFundLookThrough',
        params: params
      });
    }

  GetInvestmentCashActivitySummary (params: GetInvestmentCashActivitySummaryParameters): Promise<InvestmentCashActivitySummary[]> {
    return smartData({
        storedProcName: 'asset.GetInvestmentCashActivitySummary',
        params: params
      });
    }

  GetInvestmentList (params: GetInvestmentListParameters): Promise<InvestmentList[]> {
    return smartData({
        storedProcName: 'asset.GetInvestmentList',
        params: params
      });
    }

  GetInvestmentParentBalanceSheetCategories (params: GetInvestmentParentBalanceSheetCategoriesParameters): Promise<InvestmentParentBalanceSheetCategories[]> {
    return smartData({
        storedProcName: 'asset.GetInvestmentParentBalanceSheetCategories',
        params: params
      });
    }

  GetInvestmentParentList (params: GetInvestmentParentListParameters): Promise<InvestmentParentList[]> {
    return smartData({
        storedProcName: 'asset.GetInvestmentParentList',
        params: params
      });
    }

  GetInvestmentSources (params: GetInvestmentSourcesParameters): Promise<InvestmentSources[]> {
    return smartData({
        storedProcName: 'asset.GetInvestmentSources',
        params: params
      });
    }

  GetInvestmentStatisticsHedgeFund (params: GetInvestmentStatisticsHedgeFundParameters): Promise<InvestmentStatisticsHedgeFund[]> {
    return smartData({
        storedProcName: 'asset.GetInvestmentStatisticsHedgeFund',
        params: params
      });
    }

  GetInvestmentStatisticsPrivateEquity (params: GetInvestmentStatisticsPrivateEquityParameters): Promise<InvestmentStatisticsPrivateEquity[]> {
    return smartData({
        storedProcName: 'asset.GetInvestmentStatisticsPrivateEquity',
        params: params
      });
    }

  GetInvestmentStatisticsSingleCompany (params: GetInvestmentStatisticsSingleCompanyParameters): Promise<InvestmentStatisticsSingleCompany[]> {
    return smartData({
        storedProcName: 'asset.GetInvestmentStatisticsSingleCompany',
        params: params
      });
    }

  GetInvestmentTypes (params: GetInvestmentTypesParameters): Promise<InvestmentTypes[]> {
    return smartData({
        storedProcName: 'asset.GetInvestmentTypes',
        params: params
      });
    }

  GetJPMCompImport (params: GetJPMCompImportParameters): Promise<JPMCompImport[]> {
    return smartData({
        storedProcName: 'asset.GetJPMCompImport',
        params: params
      });
    }

  GetK1CashDetail (params: GetK1CashDetailParameters): Promise<K1CashDetail[]> {
    return smartData({
        storedProcName: 'asset.GetK1CashDetail',
        params: params
      });
    }

  GetK1StockDetail (params: GetK1StockDetailParameters): Promise<K1StockDetail[]> {
    return smartData({
        storedProcName: 'asset.GetK1StockDetail',
        params: params
      });
    }

  GetLookThrough (params: GetLookThroughParameters): Promise<LookThrough[]> {
    return smartData({
        storedProcName: 'asset.GetLookThrough',
        params: params
      });
    }

  GetManagementFees (params: GetManagementFeesParameters): Promise<ManagementFees[]> {
    return smartData({
        storedProcName: 'asset.GetManagementFees',
        params: params
      });
    }

  GetOwnerAssets (params: GetOwnerAssetsParameters): Promise<OwnerAssets[]> {
    return smartData({
        storedProcName: 'asset.GetOwnerAssets',
        params: params
      });
    }

  GetReApplyValuationStatus (params: GetReApplyValuationStatusParameters): Promise<ReApplyValuationStatus[]> {
    return smartData({
        storedProcName: 'asset.GetReApplyValuationStatus',
        params: params
      });
    }

  GetReceiptsOfAssets (params: GetReceiptsOfAssetsParameters): Promise<ReceiptsOfAssets[]> {
    return smartData({
        storedProcName: 'asset.GetReceiptsOfAssets',
        params: params
      });
    }

  GetRecognizedK1 (params: GetRecognizedK1Parameters): Promise<RecognizedK1[]> {
    return smartData({
        storedProcName: 'asset.GetRecognizedK1',
        params: params
      });
    }

  GetReturnIncomeType (): Promise<ReturnIncomeType[]> {
    return smartData({
        storedProcName: 'asset.GetReturnIncomeType',
        params: {}
      });
    }

  GetStructureForInvestment (params: GetStructureForInvestmentParameters): Promise<StructureForInvestment[]> {
    return smartData({
        storedProcName: 'asset.GetStructureForInvestment',
        params: params
      });
    }

  GetStructureTypeForInvestment (params: GetStructureTypeForInvestmentParameters): Promise<StructureTypeForInvestment[]> {
    return smartData({
        storedProcName: 'asset.GetStructureTypeForInvestment',
        params: params
      });
    }

  GetTreasuryBillPricing (params: GetTreasuryBillPricingParameters): Promise<TreasuryBillPricing[]> {
    return smartData({
        storedProcName: 'asset.GetTreasuryBillPricing',
        params: params
      });
    }

  GetValuables (params: GetValuablesParameters): Promise<Valuables[]> {
    return smartData({
        storedProcName: 'asset.GetValuables',
        params: params
      });
    }

  GetValuablesCategories (): Promise<ValuablesCategories[]> {
    return smartData({
        storedProcName: 'asset.GetValuablesCategories',
        params: {}
      });
    }

  GetValuablesLocations (params: GetValuablesLocationsParameters): Promise<ValuablesLocations[]> {
    return smartData({
        storedProcName: 'asset.GetValuablesLocations',
        params: params
      });
    }

  InsertDonationLetter (params: InsertDonationLetterParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'asset.InsertDonationLetter',
        params: params
      });
    }

  ProcessFundLookThroughImport (params: ProcessFundLookThroughImportParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'asset.ProcessFundLookThroughImport',
        params: params
      });
    }

  ProcessJPMCompImport (params: ProcessJPMCompImportParameters): Promise<ProcessJPMCompImport[]> {
    return smartData({
        storedProcName: 'asset.ProcessJPMCompImport',
        params: params
      });
    }

  QueueProcessInvestmentStats (params: QueueProcessInvestmentStatsParameters): Promise<QueueProcessInvestmentStats[]> {
    return smartData({
        storedProcName: 'asset.QueueProcessInvestmentStats',
        params: params
      });
    }

  QueueProcessLookThrough (params: QueueProcessLookThroughParameters): Promise<QueueProcessLookThrough[]> {
    return smartData({
        storedProcName: 'asset.QueueProcessLookThrough',
        params: params
      });
    }

  QueueReApplyValuations (params: QueueReApplyValuationsParameters): Promise<QueueReApplyValuations[]> {
    return smartData({
        storedProcName: 'asset.QueueReApplyValuations',
        params: params
      });
    }

  ReapplyFixedIncomeValuations (params: ReapplyFixedIncomeValuationsParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'asset.ReapplyFixedIncomeValuations',
        params: params
      });
    }

  ReapplyInvestmentValuations (params: ReapplyInvestmentValuationsParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'asset.ReapplyInvestmentValuations',
        params: params
      });
    }

  SetInvestmentNewTransactionReview1 (params: SetInvestmentNewTransactionReview1Parameters): Promise<SetInvestmentNewTransactionReview1[]> {
    return smartData({
        storedProcName: 'asset.SetInvestmentNewTransactionReview1',
        params: params
      });
    }

  SetInvestmentNewTransactionReview2 (params: SetInvestmentNewTransactionReview2Parameters): Promise<SetInvestmentNewTransactionReview2[]> {
    return smartData({
        storedProcName: 'asset.SetInvestmentNewTransactionReview2',
        params: params
      });
    }

  SetInvestmentUseNewTransactionScheme (params: SetInvestmentUseNewTransactionSchemeParameters): Promise<SetInvestmentUseNewTransactionScheme[]> {
    return smartData({
        storedProcName: 'asset.SetInvestmentUseNewTransactionScheme',
        params: params
      });
    }
}
