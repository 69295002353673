<template>
    <el-dialog v-loading.body="loading"
        :visible="showDepartmentPicker"
        :show-close="true"
        :before-close="handleClose"
        :close-on-click-modal="false"
        width="400px"
        >
        <span slot="title">Set Department</span>
        <el-select filterable clearable size="small"
                    placeholder="(searchable)"
                    name="Department" v-validate
                    style="width: 100%; "
                    v-bind:disabled="departments>0"
                    v-model="departmentId">
            <el-option v-for="department in departments"
                        :key="department.DepartmentId"
                        :label="department.Name"
                        :value="department.DepartmentId">
            </el-option>
        </el-select>
    </el-dialog>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'

    export default Vue.extend({
        name: 'DepartmentPicker'
        , mixins: [mixinUtility,  mixinSchema_vam]
        , props: {
            row: {
                required: true
            }
            , showDepartmentPicker: {
                type: Boolean
                , default: false
            }
        }
        , data: function () {
            return {
                loading: false
                , Department: {
                    Id: null
                    , ClientId: null
                    , DepartmentId: null
                    , Name: ''
                }
                , departments: []
            }
        }
        , created: function(){
        }
        , computed: {
            showSave: function () {
                return this.veeFields.dirty;
            }
            , departmentId: {
                get: function(){
                    return this.Department.DepartmentId;
                }
                , set: function(data){
                    this.Department.DepartmentId = data;
                    var department = this.getSelectedArrayItem(this.departments, this.Department.DepartmentId, 'DepartmentId');
                    this.Department.Name = (department) ? department.Name : null;
                    this.$nextTick(function () {
                        this.$emit('department-updated', this.Department);
                        this.$emit('update:showDepartmentPicker', false);
                    });

                }
            }
        }
        , watch: {
            'row': async function(iRow, oldVal){
                if (Object.keys(iRow).length > 0){
                    this.Department.Id = iRow.Id;
                    this.Department.ClientId = iRow.ClientId || null;
                    this.Department.DepartmentId = iRow.DepartmentId;
                    this.Department.Name = iRow.DepartmentName;
                    this.departments = await this.vam_GetDepartments({
                        ClientId: this.Department.ClientId
                    });
                }
            }
        }
        , methods: {
            handleClose(){
                this.$emit('update:showDepartmentPicker', false);
            }
        }
    })

</script>