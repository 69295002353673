<template>
    <!--Note: v-validate is used for Dirty/Clean, but not actual validation. Actual validation is handled by el-form, which doesn't track dirty/clean-->
    <el-form :model="editScheduleItem" ref="editScheduleItemForm" label-width="20%" v-if="!(Object.keys(editScheduleItem).length==0)">
        <el-form-item label="Date" prop="ScheduleDate">
            <el-date-picker type="date"
                            size="small"
                            placeholder="Date"
                            name="ScheduleDate" v-validate
                            v-model="editScheduleItem.ScheduleDate"
                            format="MM/dd/yyyy"
                            style="width: 100%; max-width:150px">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="Amount" prop="Amount">
            <formatted-input v-model="editScheduleItem.Amount" format-type="currency" num-decimals="2" size="small" name="Amount" v-validate style="width: 100%; max-width:200px;"></formatted-input>
        </el-form-item>
        <el-form-item label="Modified" prop="Modified">
            <span>{{editScheduleItem.Modified}}</span>
        </el-form-item>
        <el-form-item label=" ">
            <el-button type="primary" @click="saveScheduleItem" :disabled="!(Object.keys(veeFields).some(key => veeFields[key].dirty))">Save</el-button>
            <el-button type="warning" @click="$emit('cancel');">Cancel</el-button>
            <el-button type="danger" @click="deleteScheduleItem">Delete</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
    import Vue from 'vue';
    import FormattedInput from '@/components/form/FormattedInput.vue';

    export default Vue.extend({
        name: 'ScheduleItemEdit'
        , components: {
            'formatted-input': FormattedInput
        }
        , props: {
            scheduleItem: {
                type: Object
                , required: true
            }
        }
        , model: {
            prop: 'scheduleItem',
            event: 'saved'
        }
        , data() {
           return {
            editScheduleItem: this.scheduleItem
           }
        }
        , created: function () {
        }
        , computed: {
            showSave: function () {
                return this.veeFields.dirty;
            }
        }
        , watch: {
        }
        , methods: {
            saveScheduleItem: function () {
                this.$refs.editScheduleItemForm.validate((valid) => {
                    if (valid) {
                        var success = false;
                        if (this.editScheduleItem.Id == -1 || this.editScheduleItem.Id == null) {//new
                            const so = new SmartObject('ScheduleItem');
                            success = so.createObject_lgcy(this.editScheduleItem)
                            if (success > 0) {
                                this.editScheduleItem.Id = success;
                            }
                        }
                        else {
                            const so = new SmartObject('ScheduleItem', this.editScheduleItem.Id);
                            success = so.updateObject_lgcy(this.editScheduleItem);
                        }
                        if (success) {
                            this.$notify.success('Schedule Item Updated');
                            this.$emit('saved', this.editScheduleItem);
                        }
                    } else {
                        this.$message.error('Not Saved.  See Validation Errors.');
                        return false;
                    }
                });
            }
        , deleteScheduleItem: function () {
            this.$confirm('Delete Schedule Item?', 'Delete', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'danger'
                }).then(() => {
                    //delete:
                    var so = new SmartObject('ScheduleItem', this.editScheduleItem.Id);
                    so.deleteObject_lgcy();
                    this.$notify.success('Schedule Item Deleted');
                    this.$emit('deleted', this.editScheduleItem);

                }).catch(() => {
                    // canceled
                });
            }
        }
    })
</script>
<style>
    .el-form-item__error {
        position: relative;
    }
</style>