
import Vue from 'vue';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthService } from './AuthService';
import MicrosoftGraphClient, { GraphRequest, ResponseType } from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';


export class MicrosoftGraphService extends Vue {
  // constructor() {}
  public async getGraphClient(token?: string, currentGraphClient?: Client): Promise<Client> {
    // passed-in token and client are ignored.  Always get a fresh token
    const _auth: AuthService = new AuthService();
    const _token = await _auth.getGraphToken();
    const client = await Client.init({
      debugLogging: true,
      authProvider: function(done) {
        done(undefined, _token);
      }
    });
      return client;
  }
  public async getDriveItemFromLocation(location: string): Promise<microsoftgraph.DriveItem> {
      const graphClient = await this.getGraphClient();
      // get the asyncOperationsResponse
      let newFileId: string = '';
      while (newFileId == '') {
          const rawResponse: any = await fetch(location);
          const asyncOperationsResponse = await rawResponse.json();
          console.log(asyncOperationsResponse);
          if (!!asyncOperationsResponse && asyncOperationsResponse.status == 'completed' && !!asyncOperationsResponse.resourceLocation && !!asyncOperationsResponse.resourceId) {
              newFileId = asyncOperationsResponse.resourceId;
              console.log('completed');
              const newFile: microsoftgraph.DriveItem = await graphClient
                  .api(asyncOperationsResponse.resourceLocation.substring(asyncOperationsResponse.resourceLocation.indexOf('/drives/')))
                  .get();
              return newFile;
          }
          else if (!!asyncOperationsResponse && asyncOperationsResponse.status == 'failed') {
              if (!!asyncOperationsResponse.error && !!asyncOperationsResponse.error.code && asyncOperationsResponse.error.code == 'nameAlreadyExists') {
                  console.log('File name already exists in destination folder');
                  await this.$alert('Document cannot be saved because a file with the same name already exists.  Please change the File Name in the Ingest Folder.', 'File already exists', {
                      confirmButtonText: 'OK'
                      , type: 'error'
                  });
              }
              else {
                  await this.$alert(`Changes NOT SAVED: ${asyncOperationsResponse.error.message}`, 'File Move Error', {
                      confirmButtonText: 'OK'
                      , type: 'error'
                  });
              }
              return ;
          }
          else {
              console.log('waiting');
              await new Promise( resolve => setTimeout (resolve, 500));
          }
      }
      console.log('New File Id:', newFileId);
  }
}
