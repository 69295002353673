//js API for Schema fr
//Auto-generated 05/04/23 08:39
 

var mixinSchema_fr = {
    methods: {
        /** @description fr.CreateOwnerRecognizedName
         * @param {number} vendorId - 
         * @param {number} ownerId - 
         * @param {string} name - 
         */
		 fr_CreateOwnerRecognizedName: function (params, onSuccess, onError, async) {
			/*
				this.CreateOwnerRecognizedName = await this.fr_CreateOwnerRecognizedName({
					VendorId: vendorId
					, OwnerId: ownerId
					, Name: name
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'fr.CreateOwnerRecognizedName'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description fr.CreateVendorRecognizedName
         * @param {number} vendorId - 
         * @param {string} name - 
         */
		, fr_CreateVendorRecognizedName: function (params, onSuccess, onError, async) {
			/*
				this.CreateVendorRecognizedName = await this.fr_CreateVendorRecognizedName({
					VendorId: vendorId
					, Name: name
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'fr.CreateVendorRecognizedName'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get RecognizedFields using fr.GetRecognizedFields
         * @param {number} recognizedInvoiceId - 
         */
		, fr_GetRecognizedFields: function (params, onSuccess, onError, async) {
			/*
				this.RecognizedFields = await this.fr_GetRecognizedFields({
					RecognizedInvoiceId: recognizedInvoiceId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'fr.GetRecognizedFields'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
    }
};
export default mixinSchema_fr;
