
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';

@Component({
    inheritAttrs: false
})
export default class ReturnIncomeTypeSelector extends Vue {
    //#region Private declarations for Services
    private _assetService: AssetService.AssetService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: number;
    //#endregion Props

    //#region Data
    returnIncomeTypes = [] as AssetService.ReturnIncomeType[];
    selectedValue: number = null;
    //#endregion Data

    //#region Computed
    //#endregion Computed

   //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchReturnIncomeTypes();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
    }

    //#endregion Watches

    //#region Methods
    async fetchReturnIncomeTypes() {
        this.returnIncomeTypes = [];
        this.returnIncomeTypes = await this._assetService.GetReturnIncomeType();
    }
    //#endregion Methods
}
