<template>
<div>
    <el-row>
        <h5 style="display: inline;">Vendor Details</h5>
        <el-button type="danger" icon="el-icon-delete" :disabled="securityLevel_<80 || !Vendor.Deletable" @click="deleteVendor" size="mini" class="pull-right" style="margin-bottom: 5px;"></el-button>
        <el-button type="success" v-show="showSaveButton" @click="saveVendor" size="mini" plain class="pull-right" style="margin-bottom: 5px;margin-right: 5px">Save</el-button>
        <el-button type="primary" v-if="Vendor.Id" @click="refreshVendor" size="mini" plain class="pull-right" icon="el-icon-refresh" style="margin-bottom: 5px;margin-right: 5px"></el-button>

    </el-row>
        <vendor-details-edit 
            v-if="Vendor && Object.keys(Vendor).length" 
            :vendorInput.sync="Vendor" 
            :valid.sync="vendorDetailsValid" 
            :dirty.sync="vendorDetailsDirty" 
            :show-save-button="false" 
            @force-refresh="refreshVendor"
            />
    <hr>
    <h5>Client-Specific Settings</h5>
    <el-table v-if="Vendor.Id > 0 && vendorClientList[0]"
            ref="vendorClientList"
            :data="vendorClientList"
            :max-height="windowHeight"
            size="mini"
            style="width: 100%;"
            stripe
            row-key="VendorClientId">
        <el-table-column label="Enabled" min-width="75" prop="VendorClientId" align="center">
            <template slot-scope="scope">
                <el-checkbox :title="scope.row.VendorClientId" v-model="scope.row.Selected"></el-checkbox>
            </template>
        </el-table-column>
        <el-table-column label="Client" min-width="70" prop="ClientName">
            <template slot-scope="scope">
                <span :title="scope.row.ClientId" >{{scope.row.ClientName}}</span>
            </template>
        </el-table-column>
        <el-table-column label="Account" min-width="250" prop="COAId" align="center">
            <template slot-scope="scope">
                <el-button v-show="scope.row.Selected" type="text" :title="scope.row.COANamePath" @click="setCoA(scope.$index, scope.row)" >{{scope.row.COAButtonText}}</el-button>
            </template>
        </el-table-column>
        <el-table-column label="Department" min-width="200" prop="DepartmentId" align="center">
            <template slot-scope="scope">
                <el-button v-show="scope.row.Selected" type="text" :title="scope.row.DepartmentId" @click="setDepartment(scope.$index, scope.row)" >{{scope.row.DepartmentButtonText}}</el-button>
            </template>
        </el-table-column>
    </el-table>
    <span v-else>New vendor must be saved before client-specific settings can be added.</span>
    <coa-picker :row="coAPickerRow" :showCoAPicker.sync="showCoAPicker" @coa-updated="coAUpdated"></coa-picker>
    <department-picker :row="departmentPickerRow" :showDepartmentPicker.sync="showDepartmentPicker" @department-updated="departmentUpdated"></department-picker>
</div>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility'
    import CoAPicker from './../CoA/CoAPicker.vue'
    import DepartmentPicker from './../CoA/DepartmentPicker.vue'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import VendorDetailsEdit from './../Vendor/VendorDetailsEdit.vue'

    export default Vue.extend({
        name: 'VendorEdit'
        , mixins: [mixinUtility, mixinSchema_vam]
        , components: {
            'coa-picker': CoAPicker
            , 'department-picker': DepartmentPicker
            , 'vendor-details-edit': VendorDetailsEdit
        }
        , props: {
            vendorId: {type: Number}
            , params: {
                type: Object
                , default: () => { 
                    return {};
                }
            }
            , securityLevel: {
                type: Number
                , default: null
            }
            , onSave: {
                type: Function
            }
            , vendorClientIdToAddAutomatically: {
                type: Number
                , default: null
            }
        }
        , data: function () {
            return {
                Vendor: {}
                , vendorClientList: []
                , clients: []
                , departments: []
                , showCoAPicker: false
                , showDepartmentPicker: false
                , coAPickerRow: {
                    Id: null
                    , ClientId: null
                    , COAId: null
                    , COAIdArray: ''
                    , Name: ''
                    , NamePath: ''
                }
                , departmentPickerRow: {
                    Id: null
                    , ClientId: null
                    , DepartmentId: null
                    , Name: ''
                }
                , vendorDetailsDirty: false
                , vendorDetailsValid: false
                , vendorClientDataDirty: false
                , securityLevel_: this.securityLevel
                , vendorId_: null
            }
        }
        , created: async function () {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), 0);
            }

            if (this.params && this.params.VendorId > 0){
				this.vendorId_ = this.params.VendorId;
                this.refreshVendor();
            }
            else if (this.vendorId > 0){
                this.vendorId_ = this.vendorId;
                this.refreshVendor();
            }
            else {
                this.Vendor = this.vam_GetVendors_New();
                this.Vendor.Editable = true;
            }
        }
        , computed: {
            windowHeight: function () {
                return window.innerHeight - 265;
            }
            , vendorClientsDirty: function () {
                if (!this.vendorClientList || this.vendorClientList.length == 0) {
                    return false;
                }
                for (var i = 0, len = this.vendorClientList.length; i < len; i++) {
                    var vendorClientRow = this.vendorClientList[i];
                    if (
                        (vendorClientRow.VendorClientId == null && vendorClientRow.Selected)  //add new
                        ||
                        (vendorClientRow.VendorClientId != null && vendorClientRow.Selected && this.vendorClientDataDirty)  //update existing (vendorClientDataDirty would apply to any row, so this ia slightly optimistic)
                        ||
                        (vendorClientRow.VendorClientId != null && !vendorClientRow.Selected) //delete existing
                    ) {
                        return true;
                    }
                }
                return false;
            }
            , showSaveButton: function() {
                if (this.securityLevel_ < 80) {
                    return false;
                }
                else if (this.vendorDetailsDirty && !this.vendorDetailsValid) {
                    return false;
                }
                else if (!this.vendorClientsDirty && !this.vendorDetailsDirty) {
                    return false;
                }
                else {
                    return true;
                }
            }
        }
        , methods: {
            async refreshVendor(){
                this.Vendor = {};
                await this.$nextTick();//will cause the vendor component to destroy because !vendor
                this.Vendor = await this.vam_GetVendors_Object({
					VendorId: this.vendorId_
					}
					, true // async (optional)
				);
                this.fetchVendorClientList();
            }
            ,async saveVendor() {
                try {
                    console.log(this.Vendor.TaxpayerId);
                    const tid = this.Vendor.TaxpayerId;
                    if (this.Vendor.TaxpayerId) this.Vendor.TaxpayerId = this.Vendor.TaxpayerId.replaceAll('-', '');

                    if (this.Vendor.Id > 0) {
                        if (!this.Vendor.VerificationDescription) delete this.Vendor.VerificationDescription;
                        if (this.Vendor.SourceVerificationResponse == null || this.Vendor.SourceVerificationResponse == undefined) delete this.Vendor.SourceVerificationResponse;
                        if (!this.Vendor.SourceVerificationResponseId) delete this.Vendor.SourceVerificationResponseId;

                        await new SmartObject('Vendor', this.Vendor.Id).updateObject(this.Vendor);
                        this.$notify.success('Vendor updated.');
                        
                        if (this.vendorClientsDirty && this.vendorClientList && this.vendorClientList.length) {
                            for (var i = 0, len = this.vendorClientList.length; i < len; i++) {
                                this.saveVendorClient(this.vendorClientList[i]);
                            }
                        }
                    }
                    else {
                        this.Vendor.Id = await new SmartObject('Vendor').createObject(this.Vendor);
                        this.$notify.success('Vendor created.');
                        if (this.vendorClientIdToAddAutomatically){
                            await this.fetchVendorClientList();
                            await this.setVendorClientIdToAddAutomatically();
                            if (this.vendorClientList && this.vendorClientList.length == 1){
                                this.$alert('Client has been automatically added. Default Account and Department can be set.')
                            }
                            else {
                                this.$alert('Client has been automatically added. Default Account and Department can be set.  \nAdditional clients can be selected below if the Vendor should be available for additional clients.')    
                            }

                        }
                        else {
                            this.$alert('New Vendor has been created.  But you must select clients below.')
                            this.fetchVendorClientList();
                        }
                    }
                    this.Vendor.TaxpayerId = tid;
                    
                    if (this.onSave && typeof this.onSave === 'function') {
                        this.onSave(this.Vendor);
                    }
                }
                catch (err) {
                    try {
                        var errJson = JSON.parse(err.responseText);
                        if (errJson.ExceptionMessage.toLowerCase().indexOf('string or binary data would be truncated') > -1) {
                            this.$notify.error('One of the values entered is too long. Please adjust the value and try again.');
                        }
                        else if (errJson.ExceptionMessage.toLowerCase().indexOf('duplicate key') > -1) {
                            if (errJson.ExceptionMessage.toLowerCase().indexOf('taxpayerid') > -1) {
                                this.$notify.error('A vendor already exists with this taxpayer Id. Please enter a new taxpayer Id.');
                            }
                            else if (errJson.ExceptionMessage.toLowerCase().indexOf('name') > -1) {
                                this.$notify.error('A vendor already exists with this name. Please enter a new name.');
                            }
                            else {
                                this.$notify.error('A value you entered that must be unique has already been used on a different vendor. Please adjust the value and try again.');
                            }
                        }
                        else {
                            throw new Error(err);
                        }
                    }
                    catch (err2) {
                        this.$notify.error('An error occurred saving the vendor. Please try again.');
                    }
                }
            }
            , async setVendorClientIdToAddAutomatically(){
                const vc = this.getSelectedArrayItem(this.vendorClientList, this.vendorClientIdToAddAutomatically, 'ClientId');
                if (vc){
                    vc.Selected = true;
                    await this.saveVendorClient(vc);
                }
            }
            , async saveVendorClient(vendorClientRow){
                if (vendorClientRow.VendorClientId == null && vendorClientRow.Selected){ //add new
                    const so = new SmartObject('VendorClient');
                    var newVendorClientId = await so.createObject(vendorClientRow);
                    if (newVendorClientId > 0){
                        vendorClientRow.VendorClientId = newVendorClientId;
                        this.$notify.success('New Vendor Client Record Created');
                    }
                }
                else if (vendorClientRow.VendorClientId !=null && vendorClientRow.Selected) { //update existing
                    const so = new SmartObject('VendorClient', vendorClientRow.VendorClientId);
                    var updatedVendorClientId = await so.updateObject(vendorClientRow);
                    if (updatedVendorClientId == vendorClientRow.VendorClientId){
                        this.$notify.success('Vendor Client Record Updated');
                    }
                }
                else if (vendorClientRow.VendorClientId !=null && !vendorClientRow.Selected) { //delete existing
                    const so = new SmartObject('VendorClient', vendorClientRow.VendorClientId);
                    await so.deleteObject();
                    vendorClientRow.VendorClientId = null;
                    this.$notify.success('Vendor Client Record Deleted');
                }
            }
            , async deleteVendor(){
                try {
                    await this.$confirm(
                    'Are you sure you want to delete this vendor?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                    );

                    try {
                        await new SmartObject('Vendor', this.Vendor.Id).deleteObject();
                        this.$notify.success('Vendor deleted.');
                        this.$emit('deleted',  this.Vendor.Id);
                    } catch (e) {
                        this.$notify.error(e);
                    }
                } 
                catch {} // cancelled

            }
            , async fetchVendorClientList() {
                    this.vendorClientList = await this.vam_GetVendorClients({
                        VendorId: this.Vendor.Id
                    });
                }
            , setCoA(index, row) {
                this.coAPickerRow = {
                    Id: row.VendorClientId
                    , ClientId: row.ClientId
                    , COAId: row.COAId
                    , COAIdArray: row.COAIdArray
                    , Name: row.COAName
                    , NamePath: row.COANamePath
                }
                this.showCoAPicker = true;
            }
            , coAUpdated(coA){
                this.$nextTick(function () {
                    const vc = this.getSelectedArrayItem(this.vendorClientList, coA.Id, 'VendorClientId');
                    vc.COAId = coA.COAId;
                    vc.COAIdArray = coA.COAIdArray;
                    vc.COAName = coA.Name;
                    vc.COAButtonText = coA.Name || 'Not Set';
                    vc.COANamePath = coA.NamePath;
                    this.coAPickerRow = {
                        Id: null,
                        ClientId: null,
                        COAId: null,
                        COAIdArray: '',
                        Name: '',
                        NamePath: ''
                    };
                    this.vendorClientDataDirty = true;
                });
            }
            , setDepartment(index, row) {
                this.departmentPickerRow = {
                    Id: row.VendorClientId
                    , ClientId: row.ClientId
                    , DepartmentId: row.DepartmentId
                    , Name: row.DepartmentName
                };
                this.showDepartmentPicker = true;
            }
            , departmentUpdated(department){
                this.$nextTick(function () {
                    var vc = this.getSelectedArrayItem(this.vendorClientList, department.Id, 'VendorClientId');
                    vc.DepartmentId = department.DepartmentId;
                    vc.DepartmentName = department.Name;
                    vc.DepartmentButtonText = department.Name || 'Not Set';
                    this.departmentPickerRow = {
                        Id: null
                        , ClientId: null
                        , DepartmentId: null
                        , Name: ''
                    };
                    this.vendorClientDataDirty = true;
                });
            }
        }
    })
</script>
<style scoped>
    .el-form-item {
        margin-bottom: 5px;
    }
</style>