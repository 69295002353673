
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';

import ClientSelector from '@/components/form/ClientSelector.vue';
import OwnerSelector from '@/components/form/OwnerSelector.vue';
import InvestmentParentSelector from '@/components/form/InvestmentParentSelector.vue';
import Common from '../../utils/common';
import debounce from 'lodash/debounce';
import ElementUI from 'element-ui';

declare var $;
declare var SmartObject: any;

class roEach {
    client?: boolean
    owner?: boolean;
    investmentParent?: boolean;
    investment?: boolean;
}

@Component({
    inheritAttrs: false
    , components: {
        ClientSelector,
        OwnerSelector,
        InvestmentParentSelector
    }
})
export default class InvestmentSelector extends Vue {
    $refs: {
        ddlInvestment: ElementUI.Select,
        ucInvestmentEdit: HTMLDivElement,
        btnAddInvestment: ElementUI.Button
    }
    //#region Private declarations for Services
    private _asset_Service: AssetService.AssetService;
    private _common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: number;
    @Prop()
    readonly clientId: number;
    @Prop()
    readonly ownerId: number;
    @Prop()
    readonly considerationEntityType: string;
    @Prop()
    readonly investmentParentId: number;
    @Prop({
        required: false
        , type: Number
    })
    readonly excludeOwnerId: number;
    @Prop({
        required: false
        , type: Number
    })
    readonly excludeInvestmentId: number;
    @Prop({
        default: false
        , type: [Boolean,  Object]
    })
    readonly readOnly: boolean | roEach;
    @Prop({
        type: [Object]
    })
    readonly hideFields: roEach;
    @Prop({
        default: ''
    })
    readonly targetClass: string;
    @Prop({
        default: 'Investment:'
    })
    readonly label: string;
    @Prop({
        default: 'InvestmentId'
    })
    prop: string;
    // @Prop({
    //     default: () => ([])
    // })
    @Prop() readonly rules: Array<any>;
    @Prop()
    readonly source: string;
    @Prop()
    readonly includeTransferGroupOfSpecifiedInvestmentParentId: boolean;
    @Prop()
    readonly orderBy: boolean;
    @Prop()
    readonly ownerLabel: string;
    @Prop()
    readonly allowNewInvestment: boolean;
    @Prop()
    readonly allowInvestmentParentChangeInNewInvestment: boolean;
    @Prop()
    readonly investmentTypeIdForNewInvestment: boolean;
    @Prop( {type: String, default: 'NameOwnerAccountTypeIdBank' }) readonly labelColumn: string;
    //#endregion Props

    //#region Data
    selectedValue: number = null;
    selectedInvestment: AssetService.InvestmentList = new AssetService.InvestmentList();
    clientId_: number = null;
    ownerId_: number = null;
    investmentParentId_: number = null;
    investments: AssetService.InvestmentList[] = [] as AssetService.InvestmentList[];
    
    // *byProperty no longer used for anything.  10/21/21
    clientIdSetByProperty: boolean = false;
    ownerIdSetByProperty: boolean = false;
    investmentParentIdSetByProperty: boolean = false;
    
    readOnlyEach: roEach = {
                                client: false,
                                owner: false,
                                investmentParent: false,
                                investment: false
                            };
    hideFieldsEach: roEach = {
                                client: false,
                                owner: false,
                                investmentParent: false,
                                investment: false
                            };
    fetchInvestments = debounce(async function (this) { 
        // debounced functions for resuable components must be defined like data.
        // using debounce here so that it's not called several times as the properties are read

        const prevSelectedValue = this.selectedValue;
        
        this.investments = [] as AssetService.InvestmentList[];
        this.selectedValue = null;
        // this.selectedInvestment = new AssetService.InvestmentList();
        const params = {} as AssetService.GetInvestmentListParameters;
        if (this.value){
            params.InvestmentId = this.value;
        }
        else {
            params.ClientId = this.clientId_;
            params.OwnerId = this.ownerId_;
            params.InvestmentParentId = this.investmentParentId_;
            params.ConsiderationEntityType = this.considerationEntityType;
            params.ExcludeOwnerId = this.excludeOwnerId;
            params.ExcludeInvestmentId = this.excludeInvestmentId;
        }
        params.OrderBy = this.orderBy;
        params.IncludeTransferGroupOfSpecifiedInvestmentParentId = this.includeTransferGroupOfSpecifiedInvestmentParentId;
        this.investments = await this._asset_Service.GetInvestmentList(params);

        if (this.investments.length === 1){
            if (!prevSelectedValue) this.$notify.info('Investment automatically selected because only one option was available');
            this.selectedValue = this.investments[0].Id;
            this.clientId_ = this.investments[0].ClientId;
            this.ownerId_ = this.investments[0].OwnerId;
            this.investmentParentId_ = this.investments[0].InvestmentParentId;
            if (this.selectedValue !== this.value) {
                this.$emit('input', this.selectedValue);
            }
        }
        else if (prevSelectedValue > 0) {
            // if previously selected value is in the new investment list, set the current value to the previous value
            const prevSelectedInvestment = this._common.getSelectedArrayItem(this.investments, prevSelectedValue.toString(), 'Id');
            if (prevSelectedInvestment) {
                this.selectedValue = prevSelectedValue;
                if (this.selectedValue !== this.value) {
                    this.$emit('input', this.selectedValue);
                }
            }
        }

        this.getSelectedInvestment();
    }, 500);
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._asset_Service = new AssetService.AssetService();
        this._common = new Common();

        if (this.clientId){
            this.clientId_ = this.clientId;
            this.clientIdSetByProperty = true;
        }
        if (this.ownerId){
            this.ownerId_ = this.ownerId;
            this.ownerIdSetByProperty = true;
        }
        if (this.investmentParentId){
            this.investmentParentId_ = this.investmentParentId;
            this.investmentParentIdSetByProperty = true;
        }
        if (this.value) {
            this.selectedValue = this.value;
        }
        this.fetchInvestments();
    }
    async mounted() {
        this.setReadOnly();
        this.setHideFields();
    }
    //#endregion Lifecycle

    //#region Watches

    @Watch('value')
    onInvestmentChange(val: number, oldVal: number) {
        this.selectedValue = val;
        if (!this.investments.length) {
            this.fetchInvestments();
        }
        else {
            this.getSelectedInvestment();
        }
    }
    @Watch('selectedInvestment')
    onChange_selectedInvestment(val: AssetService.InvestmentList, oldVal: AssetService.InvestmentList) {
        // if directly selected, before picking Owner and Parent, set those, too.
        if (this.ownerId_ == null) this.ownerId_ = val.OwnerId;
        if (this.investmentParentId_ == null) this.investmentParentId_ = val.InvestmentParentId;
        this.$emit('value-set', this.selectedInvestment);
    }
    @Watch('investmentParentId')
    onChange_investmentParentId(val: number, oldVal: number){
        if (oldVal){
            this.investmentParentId_ = val;
            this.fetchInvestments();
        }
    }
    @Watch('readOnly', {deep: true})
    onChange_readOnly(val: boolean | roEach, oldVal: boolean | roEach){
        this.setReadOnly();
    }
    @Watch('hideFields', {deep: true})
    onChange_hideFields(val: roEach, oldVal: roEach){
        this.setHideFields();
    }
//#endregion Watches

    //#region Methods
    async setHideFields(){
        if (this.hideFields && Object.keys(this.hideFields).length){
            this.hideFieldsEach.client = this.hideFields.client || false;
            this.hideFieldsEach.owner = this.hideFields.owner || false;
            this.hideFieldsEach.investmentParent = this.hideFields.investmentParent || false;
            this.hideFieldsEach.investment = this.hideFields.investment || false;
        }
        else {
            this.hideFieldsEach.client = false;
            this.hideFieldsEach.owner = false;
            this.hideFieldsEach.investmentParent = false;
            this.hideFieldsEach.investment = false;

        }
    }
    async setReadOnly(){
        if (typeof this.readOnly == 'boolean'){
            this.readOnlyEach.client = this.readOnly;
            this.readOnlyEach.owner = this.readOnly;
            this.readOnlyEach.investmentParent = this.readOnly;
            this.readOnlyEach.investment = this.readOnly;
        }
        else if (typeof this.readOnly == 'object') {
            this.readOnlyEach.client = this.readOnly.client || false;
            this.readOnlyEach.owner = this.readOnly.owner || false;
            this.readOnlyEach.investmentParent = this.readOnly.investmentParent || false;
            this.readOnlyEach.investment = this.readOnly.investment || false;
        }
        await this.$nextTick();
        if (this.allowNewInvestment && !this.readOnlyEach.investment) {
            // get actual button width, and reset width of select to fill remaining space next to button
            const buttonWidth = $(this.$refs.btnAddInvestment.$el).outerWidth();
            $(this.$refs.ddlInvestment.$el).css('width', 'calc(100% - ' + (buttonWidth + 6) + 'px)');
        }
    }
    getSelectedInvestment() {
        if (this.investments.length && this.selectedValue > 0) {
            this.selectedInvestment = this._common.getSelectedArrayItem(this.investments, this.selectedValue.toString(), 'Id');
        }
        else {
            this.selectedInvestment = new AssetService.InvestmentList();
        }
    }
    async clientChanged(selectedValue){
        this.selectedInvestment = new AssetService.InvestmentList();
        this.selectedValue = null;
        await this.$nextTick();
        await this.fetchInvestments();    }
    async ownerChanged(selectedValue){
        this.selectedInvestment = new AssetService.InvestmentList();
        this.selectedValue = null;
        this.$emit('input', null);
        await this.$nextTick();
        await this.fetchInvestments();
    }
    async investmentParentChanged(selectedValue){
        this.selectedInvestment = new AssetService.InvestmentList();
        this.selectedValue = null;
        this.$emit('input', null);
        await this.$nextTick();
        await this.fetchInvestments();
    }

    async loadInvestmentEdit() {
        const vm = this;
        const $ucInvestmentEdit = $(this.$refs.ucInvestmentEdit);
        $ucInvestmentEdit.fetchSmartControl({
            params: {
                ClientId: this.clientId_
                , ParentId: this.investmentParentId_
                , InvestmentTypeId: this.investmentTypeIdForNewInvestment
                , AllowInvestmentParentChange: this.allowInvestmentParentChangeInNewInvestment
            }
            , title: 'New Investment'
            , width: 550
            , buttons: {
                'Save': {
                    buttonClass: 'btn-success-stripe'
                    , onClick: async function () {
                        $(this).prop('disabled', true);
                        
                        const newInvestmentId: number = await (window as any).InvestmentEdit.save($ucInvestmentEdit);

                        if (newInvestmentId > 0) {
                            $(this).smartPopup('destroy');

                            const i = new SmartObject('Investment', newInvestmentId);
                            await i.loadData();

                            vm.investmentParentId_ = i.dataObject.InvestmentParentId;
                            vm.ownerId_ = i.dataObject.OwnerId;
                            await vm.$nextTick();
                            vm.selectedValue = newInvestmentId;
                            if (vm.selectedValue !== vm.value) {
                                vm.$emit('input', vm.selectedValue);
                            }
                            await vm.$nextTick();
                            vm.fetchInvestments();
                        }
                        else {
                            $(this).prop('disabled', false);
                        }
                    }
                }
                , 'Cancel': function () {
                    $(this).smartPopup('destroy');
                }
            }
        });
    }
    //#endregion Methods
}
