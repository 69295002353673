
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as WorkflowService from './../../services/DAL/workflowService';
import findIndex from 'lodash/findIndex';
import ElementUI from 'element-ui';

/* 05-12-2022 10:16 CAMPBED: after removing ApproverList, this control has no point.  Not deleting it in case we ever need to do something with Workflow items in the future.  But removing it from the menu by unassigning it to any Security Views.*/

@Component
export default class WorkflowList extends Vue {
  $refs: {
    tblWorkflowList: ElementUI.Table
  }
    //#region Data
    private _workflowService: WorkflowService.WorkflowService;

    workflows: WorkflowService.Workflow[] = [];
    filter: string = '';
    selectedWorkflow: WorkflowService.Workflow = null;
    showWorkflowEdit = false;
    isLoading = false;
    //#endregion Data

    //#endregion Computed
    get showDetailColumns(): boolean {
        return (
            ['xs', 'sm', 'md'].indexOf(this.$getBootstrapSize()) > 0 ||
            !this.showWorkflowEdit
        );
    }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._workflowService = new WorkflowService.WorkflowService();
    }

    async mounted() {
        this.fetchWorkflows();
    }
    //#endregion Lifecycle

    //#region Methods
    selectWorkflow(workflow: WorkflowService.Workflow) {
        if (workflow) {
            this.showWorkflowEdit = false;
            this.$nextTick(() => {
                this.showWorkflowEdit = true;
                this.selectedWorkflow = workflow;
            });
        }
    }

    closeWorkflow() {
        this.showWorkflowEdit = false;
        this.selectedWorkflow = null;
        this.$refs.tblWorkflowList.setCurrentRow(null);
    }

    async fetchWorkflows() {
        this.isLoading = true;
        const params = {} as WorkflowService.GetWorkflowParameters;
        this.workflows = await this._workflowService.GetWorkflow(params);
        this.isLoading = false;
    }
    //#endregion Methods
}
