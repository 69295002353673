
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import ExcelImport from '@/components/ExcelImport.vue';
import formatters from '../../utils/formatters';
import Common from '../../utils/common';

import * as LiqService from './../../services/DAL/liq_Service';
import * as UtilService from './../../services/DAL/utilService';
declare var getDateStringFromExcelDate: any;
@Component({
  components: {
    ExcelImport
  }
})
export default class BankTransactionImport extends Vue {
  //#region Private declarations for Services
  private _liqService: LiqService.LiqService;
  private _utilService: UtilService.UtilService;
  private formatters: formatters = new formatters();
  private common: Common;

  //#endregion Private declarations for Services

  //#region Props
  //#endregion Props

  //#region Data
  bankTransactionImportProcesses: LiqService.BankTransactionImportProcesses[] = [];
  filterBankTransactionImportPossibleDuplicates: string = '';
  bankTransactionImportPossibleDuplicates: LiqService.BankTransactionImportPossibleDuplicates[] = [];
  transactionsLoading: boolean = false;
  showPotentialConflicts: boolean = false;
  showTransactionExcelImport: boolean = true;
  excludedTransactions: LiqService.BankTransactionImportPossibleDuplicates[] = [];
  logId: number = null;
  statusMessage: string = '';
  fieldOptions: object[] = [
    { name: 'Trade Date', field: 'TransactionDate' },
    { name: 'Post Date', field: 'PostDate' },
    { name: 'Settlement Date', field: 'SettlementDate' },
    { name: 'Account Name', field: 'AccountName' },
    { name: 'Account Number', field: 'AccountId' },
    { name: 'Account Type', field: 'AccountType' },
    { name: 'Type', field: 'TransactionType' },
    { name: 'Amount USD', field: 'Amount' },
    { name: 'Tran Code', field: 'TranCode' },
    { name: 'Tran Code Description', field: 'TranCodeText' }
  ];
  logParameters: object = {
    database: 'visium',
    schema: 'liq',
    caller: 'ImportBankTransactions',
    event: 'Import'
  };
  requiredColumns: string[] = ['Post Date', 'Amount USD'];
  excludeColumns: string[] = [
    'Price USD',
    'Price Local',
    'Quantity',
    'Cost USD',
    'Cost Local',
    'G/L Short USD',
    'G/L Short Local',
    'G/L Long USD',
    'G/L Long Local',
    'Amount Local',
    'Income USD',
    'Income Local',
    'Cusip',
    'Ticker',
    'Security Type',
    'Local Currency',
    'Commissions USD',
    'Commissions Local',
    'Broker',
    'Check Number',
    'Tax Withheld'
  ];
  //#endregion Data

  //#endregion Computed
  get bankTransactionImportPossibleDuplicatesFiltered(): LiqService.BankTransactionImportPossibleDuplicates[] {
    const filter_ = (
      this.filterBankTransactionImportPossibleDuplicates || ''
    ).toLowerCase();
    return this.bankTransactionImportPossibleDuplicates.filter(entity => {
      return (
        (entity.Source || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.Id || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.BankAccountId || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.Amount || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.Description || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1
      );
    });
  }
  get transactionsIdsToExclude(): number[]{
    return this.excludedTransactions.map(o => o.Id)
  }

  //#endregion Computed

  //#region Lifecycle
  async created() {
    this._liqService = new LiqService.LiqService();
    this._utilService = new UtilService.UtilService();
    this.formatters = new formatters();
    this.common = new Common();
    this.getBankTransactionImportProcesses();
  }
  //#endregion Lifecycle
  async getBankTransactionImportProcesses() {
    this.transactionsLoading = true;
    this.bankTransactionImportProcesses = await this._liqService.GetBankTransactionImportProcesses();
    this.transactionsLoading = false;
  }
  async handleImport(result) {
    console.log(result);
    if (result.errors > 0) {
      this.$alert(
        `${result.errors} records resulted in an error when writing to the database.  Review the records with an empty Id in the last column displayed, then try again.`
      );
    } else if (result.successes > 0) {
      this.showTransactionExcelImport = false;
      this.statusMessage = 'Checking for possible duplicate transactions';
      this.transactionsLoading = true;
      const params = {} as LiqService.GetBankTransactionImportPossibleDuplicatesParameters;
      params.LogId = result.importLogId;
      this.bankTransactionImportPossibleDuplicates = await this._liqService.GetBankTransactionImportPossibleDuplicates(
        params
      );
      this.transactionsLoading = false;
      this.logId = result.importLogId;
      if (this.bankTransactionImportPossibleDuplicates.length) {
        this.showPotentialConflicts = true;
      } else {
        this.statusMessage =
          'No Potential Duplicates Found.  Processing Import';
        await this.processTransactionsImport();
      }
    }
  }
  selectableItem(row) {
    return row.Source == 'IMPORT';
  }
  handleExcludeSelectionChange(val) {
    this.excludedTransactions = val;
  }
  async processTransactionsImport() {
    this.transactionsLoading = true;
    const params = {} as LiqService.ProcessBankTransactionImportParameters;
    params.LogId = this.logId;
    params.ExcludeList = this.transactionsIdsToExclude.toString();
    await this._liqService.ProcessBankTransactionImport(params);
    this.$notify.success('Transactions Processed');
    this.showPotentialConflicts = false;
    this.statusMessage = 'Refreshing Process List';
    await this.getBankTransactionImportProcesses();
    this.statusMessage = '';
    this.transactionsLoading = false;
    this.showTransactionExcelImport = true;
  }
  async cancelFromConflicts(){
    this.transactionsLoading = true;
    this.statusMessage = 'Writing Cancel Log message';
    const params = {} as UtilService.LogEventMessageParameters;
    params.LogId = this.logId;
    params.Message = 'User canceled from Conflicts UI';
    await this._utilService.LogEventMessage(params);
    this.statusMessage = 'Closing Log Event';
    const completeParams = {} as UtilService.CompleteLogEventParameters;
    completeParams.LogId = this.logId;
    completeParams.Success = false;
    await this._utilService.CompleteLogEvent(completeParams);
    this.statusMessage = '';
    this.excludedTransactions = [];
    this.bankTransactionImportPossibleDuplicates = [];
    this.transactionsLoading = false;
    this.showPotentialConflicts = false;
    this.showTransactionExcelImport = true;
    await this.getBankTransactionImportProcesses();
  }
  selectEvent(row){
    this.$emit('event-clicked', row.LogId);
  }
  //#region Methods
}
