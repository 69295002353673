
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import * as AssetService from "@/services/DAL/assetService";
import * as AcctService from "@/services/DAL/acctService";
import cloneDeep from "lodash/cloneDeep";
import formatters from '../../utils/formatters';
import Common from "@/utils/common";
import ElementUI from 'element-ui';
import { watch } from "vue";


declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component
export default class InvestmentGeneralLedgerStatistics extends Vue {
    @Ref() readonly refInvestmentGeneralLedgerStatistics!: ElementUI.Table;
    //#region Private declarations for Services
    private _acctService: AcctService.AcctService;
    private formatters: formatters = new formatters();
    private common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({required: true, type: Object}) readonly investment: AssetService.InvestmentList;
    @Prop({ type: Number, default: null }) readonly securityLevel: number;

    //#endregion Props

    //#region Data

    securityLevel_: number = null;
    investment_ = {} as AssetService.InvestmentList;
    loading: boolean = false;
    investmentGeneralLedgerStatistics = [] as AcctService.InvestmentGeneralLedgerStatistics[];
    investmentGeneralLedgerStatisticsDetails = [] as AcctService.InvestmentGeneralLedgerStatisticsDetails[];
    showDetails: boolean = false;

//#endregion Data

  //#region Lifecycle
  async created() {
    this._acctService = new AcctService.AcctService();
    this.common = new Common();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
      0
    );
    if (this.investment && Object.keys(this.investment).length) {
      this.investment_ = cloneDeep(this.investment);
    }
    else {
        this.$notify.error('Investment object required');
    }
    if (this.investment_){
      this.fetchInvestmentGeneralLedgerStatistics()
    }
  }
  async mounted() {
  }
//#endregion Lifecycle

//#region watches
@Watch('showDetails')
onShowDetailsChanged(val: boolean, oldVal: boolean) {
  if (val) {
    this.fetchInvestmentGeneralLedgerStatisticsDetails();
  }
}
//#endregion

//#region computed
get statistics(): AcctService.InvestmentGeneralLedgerStatistics {
  if (this.investmentGeneralLedgerStatistics && this.investmentGeneralLedgerStatistics.length == 1){
    return this.investmentGeneralLedgerStatistics[0];
  }
  else return {} as AcctService.InvestmentGeneralLedgerStatistics;
}

//#endregion

  async fetchInvestmentGeneralLedgerStatistics(){
    this.loading = true;
    const params = {} as AcctService.GetInvestmentGeneralLedgerStatisticsParameters;
    params.InvestmentId = this.investment_.InvestmentId;
    this.investmentGeneralLedgerStatistics = await this._acctService.GetInvestmentGeneralLedgerStatistics(params);
    this.loading = false;
  }

  async fetchInvestmentGeneralLedgerStatisticsDetails(){
    this.loading = true;
    const params = {} as AcctService.GetInvestmentGeneralLedgerStatisticsDetailsParameters;
    params.InvestmentId = this.investment_.InvestmentId;
    this.investmentGeneralLedgerStatisticsDetails = await this._acctService.GetInvestmentGeneralLedgerStatisticsDetails(params);
    this.loading = false;
  }

  beforeCloseDialog(){
    this.investmentGeneralLedgerStatisticsDetails = [];
  }

  getStatisticsDetailsSummary(param: { columns: any, data: any }) {
    const { columns, data } = param;
    const sums = [] as any;
    columns.forEach((column: any, index: number) => {
      if (index === 0) {
        sums[index] = 'Total';
        return;
      }
      const values = data.map((item: any) => Number(item[column.property]));
      if (!values.every((value: any) => isNaN(value))) {
        sums[index] = values.reduce((prev: any, curr: any) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] = this.$accounting.formatMoney(Math.round(sums[index]*100)/100); //round it because there could be 10ths of a cent that will appear different actual 0
      } else {
        sums[index] = '';
      }
    });
    return sums;
  }
}

