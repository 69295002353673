import isNumber from 'lodash/isNumber';
import accounting from 'accounting';
import Vue from 'vue';
import toNumber from 'lodash/toNumber';
import dayjs from 'dayjs';

export class DDLOptionsWithGroup {
  label: string = undefined;
  value: string = undefined;
  groupId: string = undefined;
  options: Array<DDLOptionsWithGroup> = undefined;
}

export default class Common extends Vue {
  checkPositiveNumberValidator(rule: any, value: string, callback: any): any {
    if (!value) {
      //   return callback(new Error('Please input a positive number'));
      return callback(); // gkb 08/10/18 - use 'required' if blank value isn't allowed
    }
    if (!isNumber(accounting.unformat(value)) || isNaN(accounting.unformat(value))) {
      callback(new Error('Please enter digits'));
    } else {
      if (accounting.unformat(value) <= 0) {
        callback(new Error('Number must be greater than 0'));
      } else {
        callback();
      }
    }
  }

  objectFromColumn(column: string, object): {} {
    if (typeof column === 'object') {
      // already an object
      return column;
    } else if (column) {
      try {
        return JSON.parse(column);
      } catch (err) {
        console.error(err);
        return {};
      }
    } else {
      return {};
    }
  }

  getCascaderHierarchy(
    inputArray: Array<any>,
    idField: string,
    nameField: string,
    parentIdField: string,
    disableSelectionField: string = 'DisableSelection'
  ): Array<any> {
    const id = idField || 'Id';
    const name = nameField || 'Name';
    const parentId = parentIdField || 'ParentId';
    const outArray = [];
    const disableSelection = disableSelectionField;
    const t = this; // 'this' doesn't make sense inside the .forEach
    inputArray.forEach(function(input) {
      const outObject = {
        value: input[id],
        label: input[name],
        disabled: input[disableSelection]
      };
      if (input.TreeLevel == 0) {
        // must have 'TreeLevel' column
        outArray.push(outObject);
      } else {
        const parent = t.getParentObjectFromArray(outArray, input[parentId]);
        if (parent.children != undefined) {
          parent.children.push(outObject);
        } else {
          parent.children = [outObject];
        }
      }
    });
    return outArray;
  }
  getCascaderParentChild(
    inputArray: Array<any>,
    idField: string,
    nameField: string,
    parentIdField: string,
    parentNameField: string
  ): Array<any> {
    idField = idField || 'Id';
    nameField = nameField || 'Name';
    parentIdField = parentIdField || 'ParentId';
    parentNameField = parentNameField || 'ParentName';
    const outArray = [];
    let lastParentId = undefined;

    inputArray.forEach(function(input) {
      const id = input[idField];
      const name = input[nameField];
      const parentId = input[parentIdField];

      if (input[parentIdField] !== lastParentId) {
        outArray.push({
          value: parentId,
          label: input[parentNameField],
          obj: input
        });
        lastParentId = parentId;
      }

      if (outArray.length) {
        if (!(outArray[outArray.length - 1].children instanceof Array)) {
          outArray[outArray.length - 1].children = [];
        }
        outArray[outArray.length - 1].children.push({
          value: id,
          label: name,
          obj: input
        });
      }
    });
    return outArray;
  }
  getSelectedArrayItem(inArray: Array<any>, Id: string, IdName: string): any {
    if (!!Id && !!inArray && inArray.length > 0) {
      const item = inArray.filter(function(arrayItem) {
        return arrayItem[IdName] == Id;
      });
      return item[0];
    } else {
      return undefined;
    }
  }
  /** @description Get array item using Conditions.  Conditions could be like the following:
  { 
    model: 'Tesla',                                    // a single value
    color: ['red', 'blue'],                            // a some value
    price: {                                           // a range/interval
        min: 2000, 
        max: 3000
    },
    transmission: v => v.toLowerCase() === 'automatic' // a function
  } 
  */
  filterArrayFromConditions(inArray: Array<object>, conditions: object): Array<object> {
    // https://stackoverflow.com/questions/44590352/filter-by-multiple-keys-and-values-javascript
    if (conditions && Object.keys(conditions).length && !!inArray && inArray.length) {
      const useConditions = search => a => Object.keys(search).every(k => 
        a[k] === search[k] ||
        Array.isArray(search[k]) && search[k].includes(a[k]) ||
        typeof search[k] === 'object' && +search[k].min <= a[k] &&  a[k] <= +search[k].max ||
        typeof search[k] === 'function' && search[k](a[k])
      )
      return inArray.filter(useConditions(conditions));      
    } else {
      return undefined;
    }
  }
  getArrayItemFromConditions(inArray: Array<object>, conditions: object): object {
    const x = this.filterArrayFromConditions(inArray, conditions);
    if (x && x.length == 1) return x[0];
    if (!x || x.length == 0) return undefined;
    if (x && x.length > 1) throw("getArrayItemFromConditions returned more than 1 item");
  }
  getSelectedName(inArray: Array<any>, Id: string, IdName: string, NameKey: string): string {
      const obj = this.getSelectedArrayItem(
        inArray,
        Id,
        IdName
      );
      if (!!obj && Object.keys(obj).length > 0) {
        return obj[NameKey];
      }
      else {
        return undefined;
      }
    }

  getParentObjectFromArray(input: any, parentId: string): any {
    let result: any = undefined;
    if (input instanceof Array) {
      for (let i = 0; i < input.length; i++) {
        result = this.getParentObjectFromArray(input[i], parentId);
      }
    } else {
      for (const prop in input) {
        if (prop == 'value') {
          if (input[prop] == parentId) {
            return input;
          }
        }
        if (input[prop] instanceof Object || input[prop] instanceof Array)
          result = this.getParentObjectFromArray(input[prop], parentId);
      }
    }
    return result;
  }
  getDDLOptionsWithGroup(
    ds: Array<any>,
    groupByLabel: string,
    groupById: string,
    itemLabel: string,
    itemId: string
  ): Array<any> {
    const options = [];
    let group = '';
    let groupItem = {} as DDLOptionsWithGroup;
    if (ds && ds.length > 0) {
      ds.forEach(function(row) {
        if (group != row[groupByLabel]) {
          groupItem = {
            label: row[groupByLabel],
            groupId: row[groupById],
            options: [],
            value: undefined
          };
          options.push(groupItem);
          group = row[groupByLabel];
        }
        const item = {
          value: row[itemId],
          label: row[itemLabel],
          options: undefined,
          groupId: undefined
        };
        groupItem.options.push(item);
      });
      return options;
    } else {
      return [];
    }
  }
  hideIfColumnEmpty(arrayOfObjects: any[], columnName: string): boolean {
    let columnHasData: boolean = false;
    for (let j: number = 0; j < arrayOfObjects.length; j++) {
      //need to check for actual false value
      if (arrayOfObjects[j][columnName] === false || !!arrayOfObjects[j][columnName]) {
        columnHasData = true;
        return columnHasData;
      }
    }
    return columnHasData;
  }
  hideIfColumnEmptyOrFalse(arrayOfObjects: any[], columnName: string): boolean {
    let columnHasData: boolean = false;
    for (let j: number = 0; j < arrayOfObjects.length; j++) {
      // onl y difference from hideIfColumnEmpty is that this does not check for explicit false value
      if (arrayOfObjects[j][columnName]) {
        columnHasData = true;
        return columnHasData;
      }
    }
    return columnHasData;
  }
  getSummaryArray(param, columnList: any[], totalLabel: string, totalLabelIndex: number, formats: Map<number, string>, countLabel: string = undefined, countLabelIndex: number = undefined): string[] {
    totalLabel = totalLabel || 'Total';
    totalLabelIndex = totalLabelIndex || 0;
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === totalLabelIndex) {
        sums[index] = totalLabel;
        return;
      }
      if (index === countLabelIndex && countLabel) { //countLabel must also be defined
        sums[index] = `${countLabel}: ${data.length}`;
        return;
      }
      if (
        this._findIndex(columnList, function(o) {
          return o == index;
        }) < 0
      ) {
        sums[index] = '';
        return;
      }
      const values = data.map(item => Number(item[column.property]));
      if (!values.every(value => isNaN(value))) {
        sums[index] =
          values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value) && value != 0) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        const format = formats.get(index);
        if (!
            (isNumber(toNumber(sums[index])) && !isNaN(toNumber(sums[index])))
          ) {
            sums[index] = '';
          }
        else if (format == 'currency') { // for currency, round to 2 decimal places, even when showing 0 decimals. This is to avoid showing 0.00 when there are hundredths or thousandths of a peny.
          sums[index] = accounting.formatMoney(Math.round(sums[index]*100)/100);
        }
        else if (format == 'currency0') {
          sums[index] = accounting.formatMoney(Math.round(sums[index]*100)/100, {precision: 0});
        }
        else if (format == 'number0') {
          sums[index] = accounting.formatNumber(sums[index], 0);
        }
        else if (format == 'number1') {
          sums[index] = accounting.formatNumber(sums[index], 1);
        }
        else if (format == 'number2') {
          sums[index] = accounting.formatNumber(sums[index], 2);
        }
        else if (format == 'number3') {
          sums[index] = accounting.formatNumber(sums[index], 3);
        }

      } else {
        sums[index] = '';
      }
      return;
    });
    return sums;
  }

  public datePickerOptions = {
    shortcuts: [
        {
            text: 'This Year',
            onClick(picker) {
              const start = dayjs().startOf("year");
              const end = dayjs().endOf("year");
                picker.$emit('pick', [start.toDate(), end.toDate()]);
            }
        }
        , {
            text: 'YTD',
            onClick(picker) {
              const start = dayjs().startOf("year");
              const end = dayjs();
                picker.$emit('pick', [start.toDate(), end.toDate()]);
            }
        }
        , {
            text: 'This Month',
            onClick(picker) {
              const start = dayjs().startOf("month");
              const end = dayjs().endOf("month");
                picker.$emit('pick', [start.toDate(), end.toDate()]);
            }
        }
        , {
            text: 'MTD',
            onClick(picker) {
              const start = dayjs().startOf("month");
              const end = dayjs();
                picker.$emit('pick', [start.toDate(), end.toDate()]);
            }
        }
        , {
          text: "LTM",
          onClick(picker) {
              const start = dayjs().subtract(1, "year").add(1, "day");
              const end = dayjs();
              picker.$emit("pick", [start.toDate(), end.toDate()]);
          }
        }
        , {
            text: 'Last Year',
            onClick(picker) {
              const start = dayjs().subtract(1, "year").startOf("year");
              const end = dayjs().subtract(1, "year").endOf("year");
              picker.$emit('pick', [start.toDate(), end.toDate()]);
            }
        }
        , {
            text: 'Last Month',
            onClick(picker) {
              const start = dayjs().subtract(1, "month").startOf("month");
              const end = dayjs().subtract(1, "month").endOf("month");
              picker.$emit('pick', [start.toDate(), end.toDate()]);
            }
        }
        , {
            text: 'Last Week',
            onClick(picker) {
              const start = dayjs().subtract(1, "week").startOf("week");
              const end = dayjs().subtract(1, "week").endOf("week");
              picker.$emit('pick', [start.toDate(), end.toDate()]);
            }
        }
        , {
            text: 'This Week',
            onClick(picker) {
              const start = dayjs().startOf("week");
              const end = dayjs().endOf("week");
              picker.$emit('pick', [start.toDate(), end.toDate()]);
            }
        }

    ]
  }

}
