 <template>
     <el-form :model="Main" :rules="MainRules" ref="editForm" label-width="200px" v-if="Main.NoteId">
         <el-form-item>
             <el-button type="primary" @click="save" size="mini" class="pull-right">Save</el-button>
         </el-form-item>
         <el-form-item label="Loan Amount" property="AdvanceCap">
             <span>{{Main.AdvanceCap | currency}}</span>
         </el-form-item>
         <el-form-item label="First Payment Date" property="FirstPayment"  required>
             <el-date-picker v-model="Main.FirstPayment"
                             type="date"
                             class="short"
                             size="mini"
                             placeholder="Pick a day">
             </el-date-picker>
         </el-form-item>
         <el-form-item label="Amortization Type" property="AmortizationTypeId" required>
             <el-select v-model="Main.AmortizationTypeId"
                        size="mini"
                        class="short"
                        v-bind:disabled="(amortizationTypes.length==0)"
                        filterable
                        clearable>
                 <el-option v-for="amortizationType in amortizationTypes"
                            :key="amortizationType.KeyValueInt"
                            :label="amortizationType.KeyText"
                            :value="amortizationType.KeyValueInt">
                 </el-option>
             </el-select>
         </el-form-item>
         <el-form-item label="Number of Months" required>
             <el-input v-model="Main.Months" class="veryshort" size="small" >
                 <template slot="append">
                     <span style="font-size:smaller;">Number of months for Amortization</span>
                </template>
            </el-input>
         </el-form-item>
         <el-form-item label="Number of Payments">
             <el-input v-model="Main.NumberOfPayments" class="veryshort" size="small" >
                 <template slot="append">
                     <span style="font-size:smaller;">If different than Amortization.  (Causes a balloon payment)</span>
                 </template>
             </el-input>
         </el-form-item>
         <el-form-item label="Fixed Monthly Payment" property="MonthlyPayment">
             <formatted-input v-model="Main.MonthlyPayment" format-type="currency" num-decimals="2" size="mini" class="short"></formatted-input>
         </el-form-item>

     </el-form>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinLiability from './../VueCommon/liability.vue'
    import FormattedInput from '@/components/form/FormattedInput.vue';
    import mixinSchema_liab from './../DAL/mixinSchema_liab.js'

    export default Vue.extend({
        name: 'NoteEditTermAmortization'
        , mixins: [mixinUtility, mixinLiability , mixinSchema_liab]
        , props: {
            noteId: {
                type: Number
                , required: true
            }
        }
        , components: {
            'formatted-input': FormattedInput
        }
        , data: function () {
            return {
                Main: []
                , clients: []
                , owners: []
                , statuses: []
                , bankAccounts: []
                , amortizationTypes: []
                //, activeNames: ['search', 'results']
                , MainRules: {
                    //Name: [
                    //    { type: 'string', required: true, message: 'VAM Name is required', trigger: 'blur' }
                    //    //, { min: 1, max: 100, message: 'Percentage must be between 1 and 100', trigger: 'blur' }
                    //],
                    //Date: [
                    //    { type: 'date', required: true, message: 'Please pick a date', trigger: 'change' }
                    //],
                }
            }
        }
        , mounted: function () {
            this.initialize();
            this.amortizationTypes = this.getAmortizationTypes();
        }
        , watch: {
            'noteId': function (val, oldVal) {
                this.initialize();
            }
        }
        , computed: {

        }
        , methods: {
            initialize: async function () {
                this.Main = await this.liab_GetNotes_Object({
                    //ClientId: clientId
                    //, OwnerId: ownerId
                    //, NoteStatus: noteStatus
                    //, NoteType: noteType
                    //, IncludeHolderOwners: includeHolderOwners
                     NoteId: el.noteId
                });
            }
            , save: function () {

            }
        }
    })
</script>
<style >

</style>