//js API for Schema util
//Auto-generated 04/25/19 08:21
 

var mixinSchema_util = {
    methods: {
        /** @description Get DatabaseServiceLevelSchedule using util.GetDatabaseServiceLevelSchedule
         */
		 util_GetDatabaseServiceLevelSchedule: function (params, onSuccess, onError, async) {
			/*
				this.DatabaseServiceLevelSchedule = await this.util_GetDatabaseServiceLevelSchedule({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'util.GetDatabaseServiceLevelSchedule'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get EmailAttachments using util.GetEmailAttachments
         * @param {number} emailId - 
         */
		, util_GetEmailAttachments: function (emailId) {
			/*
				this.EmailAttachments = this.util_GetEmailAttachments(
					null // emailId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'util.GetEmailAttachments'
			  , params: {
					EmailId: emailId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Emails using util.GetEmails
         * @param {string} entityType - 
         * @param {number} entityId - 
         */
		, util_GetEmails: function (entityType, entityId) {
			/*
				this.Emails = this.util_GetEmails(
					null // entityType
					, null // entityId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'util.GetEmails'
			  , params: {
					EntityType: entityType
					, EntityId: entityId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get EventKeys using util.GetEventKeys
         * @param {number} logId - 
         */
		, util_GetEventKeys: function (params, onSuccess, onError, async) {
			/*
				this.EventKeys = await this.util_GetEventKeys({
					LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'util.GetEventKeys'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get EventMessages using util.GetEventMessages
         * @param {number} logId - 
         */
		, util_GetEventMessages: function (logId) {
			/*
				this.EventMessages = this.util_GetEventMessages(
					null // logId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'util.GetEventMessages'
			  , params: {
					LogId: logId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get LogEvent using util.GetLogEvent
         * @param {string} database - 
         * @param {string} schema - 
         * @param {string} caller - 
         * @param {string} event - 
         * @param {number} minMilliseconds - 
         * @param {number} maxRows - 
         * @param {number} logId - 
         * @param {boolean} includeIncompleteRegardlessOfMilliseconds - 
         * @param {number} eventId - 
         */
		, util_GetLogEvent: function (params, onSuccess, onError, async) {
			/*
				this.LogEvent = await this.util_GetLogEvent({
					Database: database
					, Schema: schema
					, Caller: caller
					, Event: event
					, MinMilliseconds: minMilliseconds
					, MaxRows: maxRows
					, LogId: logId
					, IncludeIncompleteRegardlessOfMilliseconds: includeIncompleteRegardlessOfMilliseconds
					, EventId: eventId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'util.GetLogEvent'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get LogIds using util.GetLogIds
         * @param {string} database - 
         * @param {string} schema - 
         * @param {string} caller - 
         * @param {string} event - 
         * @param {string} keyName - 
         * @param {number} keyInt - 
         * @param {string} keyVarChar - 
         * @param {date} keyDate - 
         * @param {boolean} completedOnly - 
         * @param {number} logId - 
         * @param {boolean} includeIncompleteRegardlessOfMilliseconds - 
         * @param {number} minMilliseconds - 
         * @param {number} maxRows - 
         * @param {number} eventId - 
         * @param {boolean} successOnly - 
         */
		, util_GetLogIds: function (params, onSuccess, onError, async) {
			/*
				this.LogIds = await this.util_GetLogIds({
					Database: database
					, Schema: schema
					, Caller: caller
					, Event: event
					, KeyName: keyName
					, KeyInt: keyInt
					, KeyVarChar: keyVarChar
					, KeyDate: keyDate
					, CompletedOnly: completedOnly
					, LogId: logId
					, IncludeIncompleteRegardlessOfMilliseconds: includeIncompleteRegardlessOfMilliseconds
					, MinMilliseconds: minMilliseconds
					, MaxRows: maxRows
					, EventId: eventId
					, SuccessOnly: successOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'util.GetLogIds'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ProcessViewer using util.GetProcessViewer
         */
		, util_GetProcessViewer: function (params, onSuccess, onError, async) {
			/*
				this.ProcessViewer = await this.util_GetProcessViewer({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'util.GetProcessViewer'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description util.LogEventReturnRow
         * @param {string} database - 
         * @param {string} schema - 
         * @param {string} caller - 
         * @param {string} event - 
         * @param {number} logId - 
         */
		, util_LogEventReturnRow: function (params, onSuccess, onError, async) {
			/*
				this.LogEventReturnRow = await this.util_LogEventReturnRow({
					Database: database
					, Schema: schema
					, Caller: caller
					, Event: event
					, LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'util.LogEventReturnRow'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description util.LogEventSelect
         * @param {string} database - 
         * @param {string} schema - 
         * @param {string} caller - 
         * @param {string} event - 
         * @param {number} logId - 
         */
		, util_LogEventSelect: function (params, onSuccess, onError, async) {
			/*
				this.LogEventSelect = await this.util_LogEventSelect({
					Database: database
					, Schema: schema
					, Caller: caller
					, Event: event
					, LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'util.LogEventSelect'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description util.LogEventSelect; Return Single Object
         * @param {string} database - 
         * @param {string} schema - 
         * @param {string} caller - 
         * @param {string} event - 
         * @param {number} logId - 
         */
		, util_LogEventSelect_Object: function (params, onSuccess, onError, async) {
			/*
				this.LogEventSelect = await this.util_LogEventSelect_Object({
					Database: database
					, Schema: schema
					, Caller: caller
					, Event: event
					, LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'util.LogEventSelect'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
    }
};
export default mixinSchema_util;
