
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as VisiumService from '../../services/DAL/visiumService';

interface PersonWithNew extends VisiumService.Persons {
    IsNew?: boolean | null;
}

@Component({
    inheritAttrs: false
})
export default class PersonSelector extends Vue {
    //#region Private declarations for Services
    private _visiumService: VisiumService.VisiumService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: number;
    //#endregion Props

    //#region Data
    persons = [] as PersonWithNew[];
    selectedValue: number = null;
    isLoading: boolean = false;
    //#endregion Data

    //#region Computed
    //#endregion Computed

   //#region Lifecycle
    async created() {
        this._visiumService = new VisiumService.VisiumService();
    }
    async mounted(){
        this.selectedValue = this.value;

        if (this.selectedValue) {
            this.fetchInitialPerson(this.selectedValue);
        }
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
    }
    //#endregion Watches

    //#region Methods
    async fetchInitialPerson(personId: number) {
        this.isLoading = true;

        const parameters = {} as VisiumService.GetPersonsParameters;
        parameters.PersonId = personId;
        this.persons = await this._visiumService.GetPersons(parameters);
        
        this.$emit('options-fetched', this.persons);

        this.isLoading = false;
    }

    async fetchPersons(searchTerm: string) {
        this.isLoading = true;

        const parameters = {} as VisiumService.GetPersonsParameters;
        parameters.SearchTerm = searchTerm;
        this.persons = await this._visiumService.GetPersons(parameters);
        
        this.$emit('options-fetched', this.persons);
        
        if (this.persons.length === 1) {
            this.selectedValue = this.persons[0].Id;
            this.$emit('single-option-available', this.selectedValue);
            if (this.selectedValue !== this.value) {
                this.$emit('input', this.selectedValue);
            }
        }
        if (this.persons.length === 0) {
            const newPerson = {} as PersonWithNew;
            newPerson.IsNew = true
            newPerson.Id = -1;
            newPerson.Name = searchTerm;
            this.persons.push(newPerson);
        }
        this.isLoading = false;
    }
    handleChange(value: number) {
        if (value === -1) {
            this.createNewPerson();
        }
        else {
            this.selectedValue = value;
            this.$emit('change', value);
        }
    }

    async createNewPerson() {
        const name = this.persons && this.persons.length == 1 ? this.persons[0].Name : undefined;
        try {
            await this.$confirm(`Would you like to create a new person, "${name}"?`, 'Create Person', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'info'
            });
            const person = new SmartObject('Person');
            await person.createObject({Name: name});

            this.persons.splice(0, this.persons.length);
            const newPerson = {} as PersonWithNew;
            newPerson.IsNew = false
            newPerson.Id = person.entityId;
            newPerson.Name = name;
            this.persons.push(newPerson);
            await this.$nextTick();
            this.selectedValue = person.entityId;
            this.$emit('change', this.selectedValue);
            this.$emit('input', this.selectedValue);

        }
        catch (error) {
            console.log('cancelled creating new person');
            this.selectedValue = null;
            return;
        }
    }
    
    //#endregion Methods
}
