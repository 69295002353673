
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import mixinSchema_system from './../DAL/mixinSchema_system'
    import mixinSchema_visium from './../DAL/mixinSchema_visium'
    import filter from 'lodash/filter';
    import debounce from 'lodash/debounce';
    import ClientSelector from '@/components/form/ClientSelector.vue';
    import NamedKeySelector from '@/components/form/NamedKeySelector.vue';
    import * as VamService from '../../services/DAL/vamService';
    import * as SystemService from '@/services/DAL/systemService';
    import KeyPersonSelector from '@/components/form/KeyPersonSelector.vue';
import { boolean } from 'mathjs';

    export default Vue.extend({
        name: 'VendorDetailsEdit'
        , mixins: [mixinUtility, mixinSchema_vam, mixinSchema_visium, mixinSchema_system]
        , components: {
             'named-key-selector': NamedKeySelector
            , ClientSelector
            , KeyPersonSelector
        }
        , props: {
            vendorInput: {
                type: Object
            } 
            , securityLevel: {
                type: Number
                , default: null
            }
            , showSaveButton: {
                type: Boolean
                , default: true
            }
            , onSave: {
                type: Function
            }
        }
        , data: function () {
            const el = this;
            return {
                showSave: true
                , taxpayerIdTypes: []
                , vendor: {} as VamService.Vendors
                , vendorRules: {
                    SourceTypeId: [
                        { required: true, message: 'Source Type is required', trigger: 'blur' },
                    ]
                    , Name: [
                        { required: true, message: 'Vendor Name is required', trigger: 'blur' },
                        { min: 3, trigger: 'blur' }
                    ]
                    , State: [
                        { len: 2, trigger: 'blur', message: 'State must be 2 Letters only' }
                    ]
                }
                , formValid: false
                , taxClassification: null
                , llcType: null
                , securityLevel_: this.securityLevel
                , sourceType: {} as SystemService.NamedKeys
                , sourceTypeDisabled: false
                , clientId: null as number //only used for new item.
                , sourceEntityIdIsNew: !(el.vendorInput && el.vendorInput.SourceEntityId)
                , verifyDialogVisible: false
                , outstandingVendorVerifications: [] as VamService.OutstandingVendorVerifications[]
                , selectedKeyPersonForVerification: {} as VamService.KeyPersons
                , selectedClient: {} as VamService.UserClients
    
            }
        }
        , created: function () {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageVendors), 0);
            }
            if (this.vendorInput && Object.keys(this.vendorInput).length){
                this.vendor = this.vendorInput;
            }
            else {
                this.vendor = this.vam_GetVendors_New();
                this.vendor.SourceTypeId = 20; // Requested
                this.vendor.VendorFormNotRequired = false;
                this.vendor.Editable = true;
            }
            this.taxpayerIdTypes = this.system_GetNamedKeys(
                'TaxpayerIdType' //keyType
                , null //parentKeyType
                , null //parentKeyValue
                , null //keyCategory
                , null //namedKeyCategoryId
                , null //namedKeyTypeId
                , null //keyValue
                , null //orderByText
            );
        }
        , mounted: function () {
            this.setTaxClassification(); //create happens each time because this is loaded from Dynamic.  That may change if it's loaded from a VendorList.vue
        }
        , watch: {
            'vendorInput': function (val, oldVal) {
                if (this.val && Object.keys(this.val).length){
                    this.vendor = this.val;
                }
            }
            , 'vendor': {
                handler: function (val, oldVal) {
                    this.setTaxClassification();
                    this.inputUpdated();
                }
                , deep: true
            }
            , 'taxClassification': function (val, oldVal) {
                if (val == 'LLC') {//will be set by llcType
                    this.vendor.TaxClassification = this.llcType;
                }
                else if (val != '') {
                    this.vendor.TaxClassification = val;
                }
                else {
                    this.vendor.TaxClassification = null;
                }
                this.inputBlurred();
            }
            , 'llcType': function (val, oldVal) {
                if (val != '') {
                    this.vendor.TaxClassification = val;
                }
                else {
                    this.vendor.TaxClassification = null;
                }
                this.inputBlurred();
            }
            , 'vendor.State': function (val, oldVal) {
                if (val) {
                    this.vendor.State = this.vendor.State.toUpperCase();
                }
            }
            , 'clientId': function (val, oldVal) {
                this.validateForm();
            }
        }
        , computed: {
            isFormDirty() {
                return Object.keys(this.veeFields).some(key => this.veeFields[key].dirty || this.veeFields[key].changed);
            }
            , saveDisabled: function() {
                return (this.securityLevel_ < 80 || !this.formValid || !this.isFormDirty)
            }
            , showLLCType: function () {
                return (this.vendor.W9Required && this.taxClassification == 'LLC');
            }
            , windowHeight: function () {
                return window.innerHeight - 265;
            }
            , taxPayerIdMask: function () {
                var namedKey = this.getSelectedArrayItem(this.taxpayerIdTypes, this.vendor.TaxpayerIdTypeId, 'KeyValue');
                if (namedKey) {
                    return namedKey.AdditionalData1;
                }
                else return null;
            }
            , taxpayerIdTypesFiltered: function () {
                if (!this.vendor.IsCharitable){
                    return this.taxpayerIdTypes;
                }
                else {
                    const taxpayerTypes = this.taxpayerIdTypes.filter(
(                        item: { KeyText: string; }) => item.KeyText === 'Employer ID Number'
                    );
                    this.vendor.TaxpayerIdTypeId = taxpayerTypes[0].AdditionalData2TryInt
                    return taxpayerTypes;
                }
            }
            , hasVerificationResponse(): boolean{
                if (!this.outstandingVendorVerifications || this.outstandingVendorVerifications.length == 0) return false;
                else return this.outstandingVendorVerifications.some((vv: VamService.OutstandingVendorVerifications) => vv.VendorId == this.vendor.Id && vv.Canceled == null && vv.Response !== null);
            }
            , hasVerificationsOutstanding(): boolean{
                if (!this.outstandingVendorVerifications || this.outstandingVendorVerifications.length == 0) return false;
                else return this.outstandingVendorVerifications.some((vv: VamService.OutstandingVendorVerifications) => vv.Closed == false);
            }
            , outstandingVendorVerification(): VamService.OutstandingVendorVerifications {
                if (this.outstandingVendorVerifications && this.outstandingVendorVerifications.length >= 1){
                    const outstanding =  this.outstandingVendorVerifications.filter((vv: VamService.OutstandingVendorVerifications) => vv.Closed == false);
                    if (outstanding.length == 1) return outstanding[0];  // a database constraint gaurantees there can be only 1
                    else return undefined;
                }
                else return undefined;
            }
            , showSendVerificationButton(){
                return (this.vendor.SourceEntityType == 'KeyPerson' && this.vendor.SourceEntityId && this.verifyDialogVisible && !this.hasVerificationsOutstanding && !this.hasVerificationResponse)
            }
            , showCancelAndResendVerificationButton(){
                // if the outstanding record is for the same vendor, then we can cancel the outstanding record and send a new one
                return (this.vendor.SourceEntityType == 'KeyPerson' && this.vendor.SourceEntityId && this.verifyDialogVisible 
                    && this.hasVerificationsOutstanding
                    && this.outstandingVendorVerification.VendorId == this.vendor.Id) 
            }
            , showNoVerificationButtonExplanation(){
                return (this.vendor.SourceEntityType == 'KeyPerson' && this.vendor.SourceEntityId && this.verifyDialogVisible 
                    && this.hasVerificationsOutstanding
                    && this.outstandingVendorVerification.VendorId !== this.vendor.Id
                    && !this.hasVerificationResponse) 
            }
            , showVendorForm(){
                return tryParseBool(this.sourceType.AdditionalData3, false);
            }
        }
        , methods: {
            async deleteVendor(){
                try {
                    await this.$confirm(
                    'Are you sure you want to delete this vendor?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                    );

                    try {
                        await new SmartObject('Vendor', this.vendor.Id).deleteObject();
                        this.$notify.success('Vendor deleted.');
                        this.$emit('deleted',  this.vendor.Id);
                    } catch (e) {
                        this.$notify.error(e);
                    }
                } 
                catch {} // cancelled

            }
            , async saveVendor() {
                try {
                    const tid = this.vendor.TaxpayerId;
                    if (!this.vendor.VerificationDescription) delete this.vendor.VerificationDescription;
                    if (this.vendor.SourceVerificationResponse == null || this.vendor.SourceVerificationResponse == undefined) delete this.vendor.SourceVerificationResponse;
                    if (!this.vendor.SourceVerificationResponseId) delete this.vendor.SourceVerificationResponseId;

                    if (this.vendor.TaxpayerId) this.vendor.TaxpayerId = this.vendor.TaxpayerId.replaceAll('-', '');
                    if (this.vendor.Id) {
                        await new SmartObject('Vendor', this.vendor.Id).updateObject(this.Vendor);
                        this.$notify.success('Vendor updated.');
                    }
                    else {
                        const id = await new SmartObject('Vendor').createObject(this.vendor);
                        this.$notify.success('Vendor created.');
                        if (!this.vendor.Id && id > 0) {
                            this.vendor.Id = id;
                        }
                        if (this.clientId){
                            const vc = {} as VamService.VendorClients;
                            vc.VendorId = this.vendor.Id;
                            vc.ClientId = this.clientId;
                            const vcid = await new SmartObject('VendorClient').createObject(vc);
                            this.$notify.success('Vendor-Client created.');
                        }
                    }
                    this.$emit('vendor-updated', this.vendor);
                    this.vendor.TaxpayerId = tid;

                    if (this.onSave && typeof this.onSave === 'function') {
                        this.onSave(this.vendor);
                    }
                }
                catch (err) {
                    try {
                        var errJson = JSON.parse(err.responseText);
                        if (errJson.ExceptionMessage.toLowerCase().indexOf('string or binary data would be truncated') > -1) {
                            this.$notify.error('One of the values entered is too long. Please adjust the value and try again.');
                        }
                        else if (errJson.ExceptionMessage.toLowerCase().indexOf('duplicate key') > -1) {
                            if (errJson.ExceptionMessage.toLowerCase().indexOf('taxpayerid') > -1) {
                                this.$notify.error('A vendor already exists with this taxpayer Id. Please enter a new taxpayer Id.');
                            }
                            else if (errJson.ExceptionMessage.toLowerCase().indexOf('name') > -1) {
                                this.$notify.error('A vendor already exists with this name. Please enter a new name.');
                            }
                            else {
                                this.$notify.error('A value you entered that must be unique has already been used on a different vendor. Please adjust the value and try again.');
                            }
                        }
                        else {
                            throw new Error(err);
                        }
                    }
                    catch (err2) {
                        this.$notify.error('An error occurred processing your request. Please try again.');
                    }
                }
            }
            , inputBlurred: function (data: any) {
                // this.validateForm();
                // this.$emit('update:vendor', this.vendor);
                // this.$emit('update:valid', (this.formValid));
                // this.$emit('update:dirty', (this.isFormDirty));
                return;
            }
            , inputUpdated: debounce(function () {
                this.validateForm();
                this.$emit('update:vendor', this.vendor);
                this.$emit('update:valid', (this.formValid));
                this.$emit('update:dirty', (this.isFormDirty));
            }, 500)
            , validateForm: function () {
                if (!this.vendor.Id && !this.clientId){ // client required when vendor is new
                    this.formValid = false;
                }
                else if (this.$refs['editForm']) {
                    this.$refs['editForm'].validate((valid: any) => {
                        this.formValid = valid;
                    });
                }
                else {
                    this.formValid = false;
                }
            }
            , setTaxClassification: function () {
                if (this.vendor.TaxClassification == 'LLC-C' || this.vendor.TaxClassification == 'LLC-S' || this.vendor.TaxClassification == 'LLC-P') {
                    this.taxClassification = 'LLC';
                    this.llcType = this.vendor.TaxClassification;
                }
                else {
                    this.taxClassification = this.vendor.TaxClassification;
                    this.llcType = null;
                }
            }
            , async searchExistingVendors(queryString: any, cb: (arg0: any) => void) {
                if (queryString){
                    const vendorParams = {
                        searchTerm: queryString
                    };
                    const results = await this.vam_GetVendors(vendorParams);
                    cb(results);
                }
            }
            , createFilter(queryString: string) {
                return (link: { value: string; }) => {
                return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            }
            , existingVendorSelected(vendor: any){
                this.vendor = vendor;
                this.$emit('vendor-updated', this.vendor);
                if (this.onSave && typeof this.onSave === 'function') {
                    this.onSave(this.vendor);
                }
            }
            , setFormRequired(){
                this.vendor.VendorFormNotRequired = false; 
                this.veeFields['VendorFormNotRequired'].dirty=true;
            }            
            , sourceTypeSet(namedKey: SystemService.NamedKeys){
                this.sourceType = namedKey;
                if (namedKey.KeyText == "Charity Navigator"){
                    this.sourceTypeDisabled = true;
                }
                else {
                    this.sourceTypeDisabled = false;
                }
                if (tryParseBool(namedKey.AdditionalData1, false)){// requires verification
                    this.vendor.SourceEntityType = 'KeyPerson';
                }
                else {
                    this.vendor.SourceEntityType = null;
                    this.vendor.SourceEntityId = null;
                }
                if (!tryParseBool(namedKey.AdditionalData3, false)){// Show VendorFormRequired
                    this.vendor.VendorFormNotRequired = true; //if the option isn't shown, then it should be set to not required. (e.g. not required for Charity and Check Request)
                }
                else this.vendor.VendorFormNotRequired = false; // need to set it back to false 
            }
            , showVerifyDialog(){
                this.verifyDialogVisible = true;
                //this.getOutstandingVendorVerifications();
            }
            , async getOutstandingVendorVerifications(){
                if (this.vendor.SourceEntityType == 'KeyPerson' && this.vendor.SourceEntityId){
                    this.outstandingVendorVerifications = await this.vam_GetOutstandingVendorVerifications({
                        KeyPersonId: this.vendor.SourceEntityId
                        , VendorId: this.vendor.Id
                        }
                        , true // async (optional)
                    );

                }
            }
            , async cancelAndResendVerification(){
                if (this.outstandingVendorVerification && this.outstandingVendorVerification.Id){
                    const resend = await this.visium_CancelAndResendVendorVerification({
                        VendorVerificationId: this.outstandingVendorVerification.Id
                        }
                        , true // async (optional)
                    );
                    if (resend && resend.length == 1){
                        this.$notify.success('Request canceled and resent');
                        this.getOutstandingVendorVerifications();
                        setTimeout (this.getOutstandingVendorVerifications, 15000); // wait 15 secs then do it again, so hopefully the message id is updated.  this is not awaited, so the function will complete before this.
                    }
                }
            }
            , async sendVerification(){
				if (this.vendor.Id && this.vendor.SourceEntityType == 'KeyPerson' && this.vendor.SourceEntityId) {
                    const send = await this.visium_SendVendorVerification({
                        VendorId: this.vendor.Id
                        , KeyPersonId: this.vendor.SourceEntityId
                        }
                        , true // async (optional)
                    );
                    if (send && send.length == 1){
                        this.sourceEntityIdIsNew = false;
                        this.$notify.success('Request sent');
                        this.getOutstandingVendorVerifications();
                        setTimeout (this.getOutstandingVendorVerifications, 15000); // wait 15 secs then do it again, so hopefully the message id is updated.  this is not awaited, so the function will complete before this.
                    }
                }
            }
            , hideIfColumnEmpty(array: any, column: any): boolean{
                return this.HideIfColumnEmpty(array, column);
            }
            , verifierSelected(keyPerson){
                this.getOutstandingVendorVerifications();
                if (!this.vendor.SourceName) this.vendor.SourceName = keyPerson.NameFull;
                this.selectedKeyPersonForVerification = keyPerson;
            }
            , optionDisabledFunction(keyPerson){
                return !(keyPerson && keyPerson.USSMSNumber);
            }
            , async resetVerification(){
                try {
                    await this.$confirm(
                    'Are you sure you want to reset the verification process for this vendor?  Doing so will allow selecting a different source to verify, and will REQUIRE a new verification via SMS.',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                    );

                    try {
                        await this.visium_ResetVerification({
                            VendorId: this.vendor.Id
                        }
                        , true // async (optional)
                    );
                        this.$notify.success('Vendor Verification reset.');
                        this.$emit('force-refresh');
                        this.verifyDialogVisible = false;
                    } catch (e) {
                        this.$notify.error(e);
                    }
                } 
                catch {} // cancelled

            }
            , clientSet(client: VamService.UserClients){
                this.selectedClient = client;
                if (!this.vendor.Id && client && client.VendorVerificationNotRequired){ //new and VV not required, set Source to Pre-Existing, which is not user-selectable
                    this.vendor.SourceTypeId = 10; // Pre-Existing
                }
                else if (!this.vendor.Id){ // otherwise, if it's new, set it to the default
                    this.vendor.SourceTypeId = 20; // Requested
            }
            }
        }
    })
