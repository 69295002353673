
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import GeneralPartnerPointEdit from '@/views/InvestmentParent/GeneralPartnerPointEdit.vue';
import * as AssetService from './../../services/DAL/assetService';
import formatters from '../../utils/formatters';
import findIndex from 'lodash/findIndex';

declare var $: any;
declare function getContainerType(element: any): string;

@Component({
    components: {
        GeneralPartnerPointEdit
    }
})
export default class GeneralPartnerPointList extends Vue {
    //#region Private Declarations
    private _assetService: AssetService.AssetService;
    private formatters: formatters;
    //#endregion Private Declarations

    //#region Props
    @Prop()
    investmentParentId: number;
    //#endregion Props

    //#region Data
    generalPartnerPoints: AssetService.GeneralPartnerPoints[] = [];
    selectedGeneralPartnerPoint: AssetService.GeneralPartnerPoints = null;
    showGeneralPartnerPointEdit = false;
    isLoading = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this.formatters = new formatters();
    }

    async mounted() {
        if (getContainerType($(this.$refs.divGeneralPartnerPointList)) === 'SmartPortlet') {
            this.addSmartPortletButtons();
        }
        this.fetchGeneralPartnerPoints();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchGeneralPartnerPoints() {
        this.isLoading = true;
        const parameters = {} as AssetService.GetGeneralPartnerPointsParameters;
        parameters.InvestmentParentId = this.investmentParentId;
        this.generalPartnerPoints = await this._assetService.GetGeneralPartnerPoints(parameters);
        this.isLoading = false;
    }

    selectGeneralPartnerPoint(generalPartnerPoint) {
        if (generalPartnerPoint) {
            this.showGeneralPartnerPointEdit = false;
            this.$nextTick(() => {
                this.showGeneralPartnerPointEdit = true;
                this.selectedGeneralPartnerPoint = generalPartnerPoint;
            });
        }
    }

    closeGeneralPartnerPoint() {
        this.showGeneralPartnerPointEdit = false;
        this.selectedGeneralPartnerPoint = null;
    }

    onSaveGeneralPartnerPoint(generalPartnerPointId) {
        this.closeGeneralPartnerPoint();
        this.fetchGeneralPartnerPoints();
    }

    onDeleteGeneralPartnerPoint(generalPartnerPointId) {
        if (
            this.selectedGeneralPartnerPoint &&
            this.selectedGeneralPartnerPoint.Id == generalPartnerPointId
        ) {
            const index = findIndex(this.generalPartnerPoints, generalPartnerPoint => {
                return generalPartnerPoint.Id === this.selectedGeneralPartnerPoint.Id;
            });
            if (index > -1) {
                this.generalPartnerPoints.splice(index, 1);
            }
        }
        this.closeGeneralPartnerPoint();
    }

    addSmartPortletButtons() {
        const vm = this;
        $(this.$refs.divGeneralPartnerPointList).smartPortlet('addButtons', {
            'Add': {
                buttonClass: 'btn-success-stripe'
                , iconClass: 'fa fa-plus'
                , onClick: function() {
                    vm.selectedGeneralPartnerPoint = null;
                    vm.showGeneralPartnerPointEdit = true;
                }
            }
        });
    }
    //#endregion Methods
}
