
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import formatters from '@/utils/formatters';
    import Common from '@/utils/common';
    import * as WorkflowService from '../../services/DAL/workflowService';
    import WorkflowSelector from '@/components/form/WorkflowSelector.vue';
    import VendorSelector from '@/components/form/VendorSelector.vue';
    import ClientSelector from '@/components/form/ClientSelector.vue';
    import OwnerSelector from '@/components/form/OwnerSelector.vue';
    import KeyPersonSelector from '@/components/form/KeyPersonSelector.vue';
    import ContactSelector from '@/components/form/ContactSelector.vue';

    declare var SmartObject: any;
    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;


    @Component({
        components: {
            WorkflowSelector,
            ClientSelector,
            VendorSelector,
            OwnerSelector,
            KeyPersonSelector,
            ContactSelector
        }
    })
    export default class ApproverSelectionEdit extends Vue {

    //#region Private declarations for Services
    private _workflowService: WorkflowService.WorkflowService;
    private common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop( {type: Object}) readonly selection?: WorkflowService.ApproverSelection;

    //#endregion Props

    //#region Data
    isNew = false;
    selection_ = {} as WorkflowService.ApproverSelection;
    workflow = {} as WorkflowService.Workflow;
    securityLevel_: number = 0;
    //#endregion Data

    //#region Lifecycle
    created() {
        this._workflowService = new WorkflowService.WorkflowService();
        this.common = new Common();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets), // TODO: get better view name
            0
        );
    }
    
    mounted(){
      this.initialize();
    }
    //#endregion Lifecycle

    //#region Watches
    //#endregion Watches

    //#region Computed
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
        key => (this as any).veeFields[key].dirty
        );
    }
    get isFormValid(): boolean{
        return !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
    }
    get enableContact(): boolean {
      return (this.workflow && this.workflow.AllowContactApprover);
    }
    //#endregion Computed

    //#region Methods
    initialize(){
        if (this.selection && Object.keys(this.selection).length){
            this.selection_ = this.selection;
            this.isNew = !this.selection.Id;
        }
        else {
            this.isNew = true;
            this.selection_ = new WorkflowService.ApproverSelection();
            this.$nullifyObjectProps(this.selection_);
        }
        if (this.selection_.IsDefault == null) this.selection_.IsDefault = false;
        if (this.selection_.SelectionEntityType == null) this.selection_.SelectionEntityType = 'KeyPerson';
    }
   async saveItem(){
        if (this.isFormValid) {
            if (this.isNew){
                const id = await new SmartObject('ApproverSelection').createObject(
                    this.selection_
                );
                this.selection_.Id = id;
                this.isNew = false;
                this.$notify.success('New Selection Added');
            }
            else{
                await new SmartObject('ApproverSelection', this.selection_.Id).updateObject(
                    this.selection_
                );
                this.$notify.success('Selection Updated');
            }
            this.$emit('saved', this.selection_);
            this.$emit('closed');
        }
    }
        
    async deleteItem() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this Selection. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                await this.doDeleteSelection();
            } catch {}
        }
    }

    async doDeleteSelection() {
        try {
            await new SmartObject(
                'ApproverSelection',
                this.selection_.Id
            ).deleteObject();

            this.$notify.success('Selection deleted.');

            this.$emit('deleted', this.selection_.Id);
            this.selection_ = {} as WorkflowService.ApproverSelection;
            this.$emit('closed');            
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }
    gotWorkFlow(workflow: WorkflowService.Workflow){
      this.workflow = workflow;
    }
    //#endregion Methods
  }
