
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import BankTransactionImport from '@/views/BankTransaction/BankTransactionImport.vue'
import ChaseCreditCardImport from '@/views/BankTransaction/ChaseCreditCardImport.vue'
import BankBalanceImport from '@/views/BankAccount/BankBalanceImport.vue'
import VueMarkdown from '@adapttive/vue-markdown'
import * as VamService from './../../services/DAL/vamService';
import EventLogDetailView from '@/views/Event/EventLogDetailView.vue';

declare var getDateStringFromExcelDate: any;
@Component({
  components: {
    BankTransactionImport,
    ChaseCreditCardImport,
    BankBalanceImport,
    //@ts-ignore
    VueMarkdown,
    EventLogDetailView
  }
})
export default class BankImport extends Vue {
  //#region Private declarations for Services
  private _vamService: VamService.VamService;

  //#endregion Private declarations for Services

  //#region Props
  //#endregion Props

  //#region Data
  showInstructions: boolean = false;
  mdIsntructions: string = '';
  showDataPane: boolean = false;
  selectedLogId: number = null;
//#endregion Data

  //#endregion Computed

  //#endregion Computed

  //#region Lifecycle
  async created() {
    this._vamService = new VamService.VamService();
    this.getInstructions();
  }
  //#endregion Lifecycle
  //#region Methods
  async getInstructions(){
    const params = {} as VamService.GetMarkdownParameters;
    params.Name = 'BankImportInstructions';
    const md = await this._vamService.GetMarkdown(params);
    this.mdIsntructions = md[0].Value;
  }
  async doShowInstructions(){
    this.showInstructions = true;
    await this.$nextTick();
    (this.$refs.mdInstructions as any).source = this.mdIsntructions;
  }
  async selectEvent(logId: number) {
      if (logId) {
          this.showDataPane = false;
          await this.$nextTick();
          this.showDataPane = true;
          this.selectedLogId = logId;
      }
  }
  closeEventLog() {
      this.showDataPane = false;
      this.selectedLogId = null;
  }

  //#endregion Methods
}
