
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import FormattedInput from "@/components/form/FormattedInput.vue";
import AirportSelector from "@/components/form/AirportSelector.vue";
import TripLegPassengerList from "@/views/Aviation/TripLegPassengerList.vue";
import CompanyAndPurposeEdit from "@/views/Aviation/CompanyAndPurposeEdit.vue";
import * as AvService from './../../services/DAL/avService';
import * as WorkflowService from './../../services/DAL/workflowService';
import findIndex from 'lodash/findIndex';

declare var SmartObject: any;
declare function tryParseFloat(input: any, defaultValue: number): number;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
    components: {
        FormattedInput,
        AirportSelector,
        TripLegPassengerList,
        CompanyAndPurposeEdit
    }
})
export default class TripInvoiceEdit extends Vue {
    //#region Private declarations for Services
    private _avService: AvService.AvService;
    private _workflowService: WorkflowService.WorkflowService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() viewType: string;
    @Prop() invoiceId: number;
    @Prop() tripInvoice: AvService.TripInvoice;
    //#endregion Props

    //#region Data
    tripInvoice_: AvService.TripInvoice = new AvService.TripInvoice();
    tripLegs: AvService.TripLegs[] = [];
    tripLegManifestsPerLegId = {}; // key: LegId; value: AvService.TripLegManifest[]
    selectedTripLeg: any = {};
    isLoadingTripLegs: boolean = false;
    isSaving: boolean = false;
    showTripLegManifestList: boolean = false;
    usAirportsOnly = true;
    frequentAirportsOnly = true;
    securityLevel_: number = null;
      //#endregion Data

    @Watch('tripInvoice_.DepartureDate')
    onChange_tripDate(val: string, oldVal: string) {
        if (val && val !== oldVal && this.tripLegs && this.tripLegs.length && this.tripLegs[0].LegDepartureDate !== val) {
            this.tripLegs[0].LegDepartureDate = val;
        }
    }
    

    //#region Lifecycle
    async created() {
        this._avService = new AvService.AvService();
        this._workflowService = new WorkflowService.WorkflowService();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAviationDataImport),
            0
        );
        
        if (this.tripInvoice && Object.keys(this.tripInvoice).length && this.tripInvoice.InvoiceId > 0) {
            this.tripInvoice_ = this.tripInvoice;
        }
        else if (this.invoiceId > 0) {
            await this.fetchTripInvoice();
        }

        await this.fetchTripLegs();
    }
    //#endregion Lifecycle

    //#region Computed
    get selectedTripLegIndex(): number {
        const vm = this;
        return this.tripLegs.findIndex(thisTripLeg => {
            return thisTripLeg.LegId === vm.selectedTripLeg.LegId;
        });
    }
    //#endregion Computed

    //#region Methods
    async fetchTripInvoice() {
        const parameters = {} as AvService.GetTripInvoiceParameters;
        parameters.InvoiceId = this.invoiceId;
        const tripInvoices: AvService.TripInvoice[] = await this._avService.GetTripInvoice(parameters);
        
        if (tripInvoices && tripInvoices.length) {
            this.tripInvoice_ = tripInvoices[0];
        }
    }

    async fetchTripLegs() {
        if (this.tripInvoice_.TripId > 0) {
            this.isLoadingTripLegs = true;
            const parameters = {} as AvService.GetTripLegsParameters;
            parameters.TripId = this.tripInvoice_.TripId;
            this.tripLegs = await this._avService.GetTripLegs(parameters);
            this.isLoadingTripLegs = false;
        }
        else {
            const tl = new AvService.TripLegs();
            tl.LegId = -1;
            tl.ToCode = undefined
            tl.LegConsumedHours = this.tripInvoice_.UnitsLabel == 'Hours' ? this.tripInvoice_.UnitsDisplay : null;
            tl.DistSM = this.tripInvoice_.UnitsLabel == 'Statute Miles' ? this.tripInvoice_.UnitsDisplay : null;
            tl.LegDepartureDate = this.tripInvoice_.DepartureDate;
            // tl.Passengers = undefined;
            // tl.Reason = undefined;
            // tl.FromCode = undefined;
            this.tripLegs = [tl];
        }
    }

    viewPassengers(tripLeg: AvService.TripLegs) {
        this.selectedTripLeg = tripLeg;
        if (!this.tripLegManifestsPerLegId[this.selectedTripLeg.LegId]) {
            this.tripLegManifestsPerLegId[this.selectedTripLeg.LegId] = [] as AvService.TripLegManifest[];
        }
        this.showTripLegManifestList = true;
    }
    async copyPassengers(tripLeg: AvService.TripLegs, priorLeg: AvService.TripLegs) {
        if (!this.tripLegManifestsPerLegId[priorLeg.LegId]) {
            const parameters = {} as AvService.GetTripLegManifestParameters;
            parameters.TripLegId = priorLeg.LegId;
            parameters.Type = 'Passenger';
            this.tripLegManifestsPerLegId[priorLeg.LegId] = await this._avService.GetTripLegManifest(parameters);
        }
        if (!this.tripLegManifestsPerLegId[tripLeg.LegId]){
        this.tripLegManifestsPerLegId[tripLeg.LegId] = this.tripLegManifestsPerLegId[priorLeg.LegId];
        }
        tripLeg.Passengers = this.tripLegManifestsPerLegId[tripLeg.LegId].length
    }
    closePassengers() {
        if (this.tripLegManifestsPerLegId[this.selectedTripLeg.LegId]){// if we have retreived or created the tripLegManifests for this leg, check if any are business travel
            const someBusinessPassengers =  this.tripLegManifestsPerLegId[this.selectedTripLeg.LegId].some((tripLegManifest: AvService.TripLegManifest) => {
                return tripLegManifest.IsBusinessTravel;
            });
            //this is only used to determine whether or not to show the company and purpose stuff. It is not saved.
            // so we don't need to worry about doing match to find the actual pct.  Just set it to 100 if any are business, 0 if none are.
            this.selectedTripLeg.PercentOfBusiness = someBusinessPassengers ? 100 : 0; 
        }

        this.selectedTripLeg = {};
        this.showTripLegManifestList = false;
    }

    addTripLeg() {
        const lastLegId = (this.tripLegs.length ? this.tripLegs[this.tripLegs.length - 1].LegId : 0);
        const thisLegId = (lastLegId < 0 ? lastLegId - 1 : -1);
        let legDate: string;
        if (this.tripLegs.length && this.tripLegs.length > 1) { //get the last leg date
            legDate = this.tripLegs[this.tripLegs.length - 1].LegDepartureDate;
        }
        else {
            legDate = this.tripInvoice_.DepartureDate;
        }
        const tl = new AvService.TripLegs();
        tl.LegId = thisLegId;
        tl.LegDepartureDate = legDate;
        tl.FromCode = this.tripLegs.length ? this.tripLegs[this.tripLegs.length - 1].ToCode : undefined;
        this.tripLegs.push(tl);
    }

    async deleteTripLeg(tripLeg) {
        try {
            await this.$confirm(
                'Are you sure you want to delete this leg of the trip?',
                'Delete Trip Leg',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );
            
            const index = findIndex(this.tripLegs, tripLeg_ => {
                return tripLeg_.LegId === tripLeg.LegId;
            });
            if (index > -1) {
                this.tripLegs.splice(index, 1);
            }

            if (this.tripLegManifestsPerLegId[tripLeg.LegId]) {
                delete this.tripLegManifestsPerLegId[tripLeg.LegId];
            }

            if (tripLeg.LegId > 0) {
                const tl = new SmartObject('TripLeg', tripLeg.LegId);
                try {
                    await tl.deleteObject();
                    this.$notify.success('Leg deleted.');
                }
                catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
            }
            else {
                this.$notify.success('Leg deleted.');
            }
        }
        catch {}
    }

    updatePassengers(passengers: AvService.TripLegManifest[]) {
        this.selectedTripLeg.Passengers = passengers.length;
    }

    showCompanyPurpose(tripLeg: AvService.TripLegs): boolean {
        if (tripLeg.LegId < 0) {
            return false;
        }
        return tripLeg.PercentOfBusiness > 0
    }
    async saveTrip() {
        let totalLegHours: number = 0;
        let totalMiles: number = 0;

        try {
            if (!this.tripInvoice_.DepartureDate) {
                throw new Error('MissingDepartureDate');
            }
            
            this.tripLegs.forEach(tripLeg => {
                if (!tripLeg.FromCode) {
                    throw new Error('MissingFromCode');
                }
                if (!tripLeg.ToCode) {
                    throw new Error('MissingToCode');
                }
                if (this.tripInvoice_.UnitsLabel == 'Hours' && !tryParseFloat(tripLeg.LegConsumedHours, null)) {
                    throw new Error('InvalidLegHours');
                }
                if (this.tripInvoice_.UnitsLabel == 'Statute Miles' && !tryParseFloat(tripLeg.DistSM, null)) {
                    throw new Error('InvalidLegMiles');
                }
                if (!tripLeg.Passengers) {
                    throw new Error('MissingPassengers');
                }

                // For existing legs, only validate the passenger array length if it's defined - otherwise, the passengers were never loaded and thus aren't being changed.
                // For new legs, passengers must be set, so validate passenger array existence and length.
                if ((tripLeg.LegId > 0 && this.tripLegManifestsPerLegId[tripLeg.LegId] && !this.tripLegManifestsPerLegId[tripLeg.LegId].length) ||
                    (tripLeg.LegId <= 0 && (!this.tripLegManifestsPerLegId[tripLeg.LegId] || !this.tripLegManifestsPerLegId[tripLeg.LegId].length))
                ) {
                    throw new Error('MissingPassengers');
                }

                if (this.tripLegManifestsPerLegId[tripLeg.LegId]) {
                    this.tripLegManifestsPerLegId[tripLeg.LegId].forEach((tripLegmanifest: AvService.TripLegManifest) => {
                        if (!tripLegmanifest.PersonId) {
                            throw new Error('MissingPerson');
                        }
                    });
                }
                
                totalMiles += tryParseFloat(tryParseFloat(tripLeg.DistSM, 0).toFixed(4), 0);
                totalLegHours += tryParseFloat(tryParseFloat(tripLeg.LegConsumedHours, 0).toFixed(4), 0);
            });
        }
        catch (err) {
            switch (err.message) {
                case 'MissingDepartureDate':
                    this.$notify.warning('Please enter a Trip Date.');
                    break;
                case 'MissingFromCode':
                    this.$notify.warning(`One or more legs is missing the 'From' airport.`);
                    break;
                case 'MissingToCode':
                    this.$notify.warning(`One or more legs is missing the 'To' airport.`);
                    break;
                case 'InvalidLegHours':
                    this.$notify.warning('One or more legs has an invalid number of hours.');
                    break;
                case 'InvalidLegMiles':
                    this.$notify.warning('One or more legs has an invalid number of Statute Miles.');
                    break;
                case 'MissingPassengers':
                    this.$notify.warning('One or more legs has no passengers.');
                    break;
                case 'MissingPerson':
                    this.$notify.warning('One or more legs has a passenger entry with no person assigned.');
                    break;
                default:
                    this.$notify.warning('Something went wrong processing your request, please validate the data and try again.');
                    break;
            }
            return;
        }

        if (this.tripInvoice_.UnitsLabel == 'Hours' && this.tripInvoice_.UnitsDisplay !== null && this.tripInvoice_.UnitsDisplay.toFixed(4) !== totalLegHours.toFixed(4)) { // if Hours is null, then no hours have been entered, so there is nothing to compare to.
            this.$notify.warning(`Sum of leg hours (${totalLegHours.toFixed(2)}) must match trip hours (${this.tripInvoice_.UnitsDisplay.toFixed(2)}).`);
            return;
        }
        if (this.tripInvoice_.UnitsLabel == 'Statute Miles' && this.tripInvoice_.UnitsDisplay !== null && this.tripInvoice_.UnitsDisplay.toFixed(4) !== totalMiles.toFixed(4)) { // if Hours is null, then no hours have been entered, so there is nothing to compare to.
            this.$notify.warning(`Sum of leg miles (${totalMiles.toFixed(2)}) must match trip miles (${this.tripInvoice_.UnitsDisplay.toFixed(2)}).`);
            return;
        }
        
        this.isSaving = true;
        
        try {
            let tripObject;
            const tripAttrs = {
                OwnerId: this.tripInvoice_.OwnerId,
                AircraftId: this.tripInvoice_.AircraftId,
                SourceTripId: this.tripInvoice_.InvoiceId,
                Hours: this.tripInvoice_.UnitsLabel == 'Hours' ? totalLegHours : null,
                Distance: this.tripInvoice_.UnitsLabel == 'Statute Miles' ? totalMiles : null,
                DepartureDate: this.tripInvoice_.DepartureDate,
                InvoiceId: this.tripInvoice_.InvoiceId
            };

            if (this.tripInvoice_.TripId > 0) {
                tripObject = new SmartObject('Trip', this.tripInvoice_.TripId);
                await tripObject.updateObject(tripAttrs);
            }
            else {
                tripObject = new SmartObject('Trip');
                await tripObject.createObject(tripAttrs);
                this.tripInvoice_.TripId = tripObject.entityId;
            }

            this.tripLegs.forEach(async (tripLeg, index) => {
                let tripLegObject;
                const tripLegAttrs = {
                    TripId: this.tripInvoice_.TripId,
                    SourceLegId: index + 1,
                    LegDepartureDate: tripLeg.LegDepartureDate,
                    EstimatedTimeOfDeparture: tripLeg.LegDepartureDate,
                    FromCode: tripLeg.FromCode,
                    ToCode: tripLeg.ToCode,
                    Hours: tripLeg.LegConsumedHours,
                    DistSM: tripLeg.DistSM,
                    NoPax: tripLeg.Passengers,
                    //Reason: tripLeg.Reason
                };

                const tripLegManifests: AvService.TripLegManifest[] = this.tripLegManifestsPerLegId[tripLeg.LegId] || [];
                
                if (tripLeg.LegId > 0) {
                    tripLegObject = new SmartObject('TripLeg', tripLeg.LegId);
                    await tripLegObject.updateObject(tripLegAttrs);
                }
                else {
                    tripLegObject = new SmartObject('TripLeg');
                    await tripLegObject.createObject(tripLegAttrs);

                    // move passengers from key of fake Id to key of new, real Id
                    this.tripLegManifestsPerLegId[tripLegObject.entityId] = this.tripLegManifestsPerLegId[tripLeg.LegId];
                    delete this.tripLegManifestsPerLegId[tripLeg.LegId];

                    tripLeg.LegId = tripLegObject.entityId;
                }

                tripLegManifests.forEach(async (tripLegManifest, index) => {
                    let tripLegManifestObject;
                    const tripLegManifestAttrs = {
                        TripLegId: tripLeg.LegId,
                        PersonId: tripLegManifest.PersonId,
                        Type: 'Passenger',
                        Position: tripLegManifest.Position,
                        IsBusinessTravel: tripLegManifest.IsBusinessTravel,
                        IsCharitable: tripLegManifest.IsCharitable
                    };

                    if (tripLegManifest.TripLegManifestId > 0) {
                        tripLegManifestObject = new SmartObject('TripLegManifest', tripLegManifest.TripLegManifestId);
                        await tripLegManifestObject.updateObject(tripLegManifestAttrs);
                    }
                    else {
                        tripLegManifestObject = new SmartObject('TripLegManifest');
                        await tripLegManifestObject.createObject(tripLegManifestAttrs);
                        tripLegManifest.TripLegManifestId = tripLegManifestObject.entityId;
                    }
                });
            });
            if (!this.tripInvoice_.Approved) { // not yet approved; saving details implies approval, so set Approval
                const parameters = {} as WorkflowService.ApproveTripInvoiceParameters;
                parameters.InvoiceId = this.tripInvoice_.InvoiceId;
                const workflowResponse = await this._workflowService.ApproveTripInvoice(
                    parameters
                );
                if (!!workflowResponse && workflowResponse.length && workflowResponse[0].Success){
                    this.$notify.success(`Approval: ${workflowResponse[0].Response}`);
                }
                else if (!!workflowResponse && workflowResponse.length && !workflowResponse[0].Success){
                    this.$notify.warning(`Approval: ${workflowResponse[0].Response}`);
                }
            }
            this.isSaving = false;
            this.$message.success(`Trip saved.  You can now close this window.`);
        }
        catch {
            this.isSaving = false;
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }
    //#endregion Methods
}
