
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AttachmentService from '../../services/DAL/attachmentService';
import * as UtilService from '../../services/DAL/utilService';
// import * as Common from '../../utils/common';
import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';
import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';

import MicrosoftGraphClient, {
  GraphRequest,
  ResponseType,
  Client
} from '@microsoft/microsoft-graph-client';
import { now } from 'moment';

declare var SmartObject: any;
declare function tryParseInt(input: number, deflt: number): number;
declare function getStoredSecurityLevel(Id: number): number;

@Component
export default class BulkCopy extends Vue {
  //#region Private declarations for Services
  private _attachmentService: AttachmentService.AttachmentService;
  private _graphService: MicrosoftGraphService;
  private _utilService: UtilService.UtilService;
  // private _common: Common.default;

  //#endregion Private declarations for Services

  //#region Props
  //#endregion Props

  //#region Data
  securityLevel_: number = null;

  message: string = '';
  isLoading: boolean = false;
  iterator: number = null;
  bulkCopies = [] as AttachmentService.BulkCopy[];
  bulkCopy: AttachmentService.BulkCopy | null = null;
  bulkCopyItems = [] as AttachmentService.BulkCopyItems[];
  destinationDriveItem = {} as MicrosoftGraphTypes.DriveItem;
  logId: number = null;
  logNotes: string = '';
  filesProcessed: number = 0;
  alreadyExistsOnly = false;
  //#endregion Data

  get hasUnprocessedRows(){
    return this.bulkCopyItems.some(item => item.Success == null);
  }

  get allRowsHaveDestination(){
    return (this.bulkCopyItems.every(item => item.DestinationDriveId != null) && this.bulkCopyItems.every(item => item.DestinationParentId != null));
  }
  //#endregion Computed

  //#region Lifecycle
  async created() {
    this._attachmentService = new AttachmentService.AttachmentService();
    this._graphService = new MicrosoftGraphService();
    this._utilService = new UtilService.UtilService();
    // this._common = new Common.default();

    if (this.securityLevel_ === null) {
      this.securityLevel_ = tryParseInt(
        getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDocuments),
        0
      );
    }

    this.fetchBulkCopies();
  }
  //#endregion Lifecycle
  //#region Watches
  @Watch('bulkCopy')
  async bulkCopyChanged(
    val: AttachmentService.BulkCopy,
    oldVal: AttachmentService.BulkCopy
  ) {
    this.logNotes = '';
    this.fetchDestinationDriveItem();
    await this.fetchBulkCopyItems();
  }
  @Watch('alreadyExistsOnly')
  async alreadyExistsOnlyChanged(){
    this.bulkCopyItems = [] as AttachmentService.BulkCopyItems[];
    if (this.bulkCopy){
      this.fetchBulkCopyItems();
    }
  }
  //#endregion Watches
  //#region Methods
  async fetchBulkCopies() {
    this.isLoading = true;
    this.bulkCopies = await this._attachmentService.GetBulkCopy();
    this.isLoading = false;
  }

  async fetchDestinationDriveItem() {
    try {
      if (
        !!this.bulkCopy &&
        !!this.bulkCopy.DestinationItemId &&
        !!this.bulkCopy.DestinationDriveId
      ) {
        this.isLoading = true;
        this.destinationDriveItem = await this.getDriveItem(
          `drives/${this.bulkCopy.DestinationDriveId}/items/${this.bulkCopy.DestinationItemId}`
        );
        this.isLoading = false;
      }
    } catch (err) {
      this.$notify.error(err.message);
    }
  }
  async fetchBulkCopyItems() {
    if (!this.bulkCopy) {
      this.bulkCopyItems = [] as AttachmentService.BulkCopyItems[];
    } else {
      const parms = {} as AttachmentService.GetBulkCopyItemsParameters;
      parms.BulkCopyId = this.bulkCopy.Id;
      parms.AlreadyExistsOnly = this.alreadyExistsOnly;
      this.isLoading = true;
      this.bulkCopyItems = await this._attachmentService.GetBulkCopyItems(
        parms
      );
      this.isLoading = false;
    }
  }

  async getDriveItem(driveItem: string): Promise<MicrosoftGraphTypes.DriveItem> {
    if (driveItem) {
      const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
      const res = await graphClient.api(driveItem).get();
      return res;
    } else return {} as MicrosoftGraphTypes.DriveItem;
  }
  async getLogId(){
    let logEvents = [] as UtilService.LogEventReturnRow[];
    const parameters = {} as UtilService.LogEventReturnRowParameters;
    parameters.Database = 'visium';
    parameters.Schema = 'attachment';
    parameters.Caller = 'BulkCopy';
    parameters.Event = 'exec';
    logEvents = await this._utilService.LogEventReturnRow(parameters);
    if (!!logEvents && logEvents.length == 1){
        this.logId = logEvents[0].LogId;
    }
    if (this.logId){
      const keyParams = {} as UtilService.LogEventKeyParameters;
      keyParams.LogId = this.logId;
      keyParams.KeyName = 'BulkCopyId';
      keyParams.IntValue = this.bulkCopy.Id;
      await this._utilService.LogEventKey(keyParams);
      if (this.logNotes.length){
        keyParams.KeyName = 'Notes';
        keyParams.VarcharValue = this.logNotes;
        await this._utilService.LogEventKey(keyParams);
      }
    }
  }
  async logEventMessage(message: string, rows: number = null){
    const params = {} as UtilService.LogEventMessageParameters;
    params.LogId = this.logId;
    params.Message = message;
    params.Rows = rows;
    await this._utilService.LogEventMessage(params);
  }
  async setFileNames(){
    await this.getLogId();
    const proms = this.bulkCopyItems.map(async (file) => {
        try {
              await this.setFileName(file);
        } 
        catch (error) {
            console.log('error'+ error);
        }
    });
    await Promise.all(proms);
    const completeParams = {} as UtilService.CompleteLogEventParameters;
    completeParams.LogId = this.logId;
    completeParams.Success = true;
    await this._utilService.CompleteLogEvent(completeParams);

    this.$alert('Completed');

  }
  async processItems(){
    await this.getLogId();
    this.iterator = 100;
    while (this.hasUnprocessedRows && this.iterator > 0){
      const graphClient: Client = await this._graphService.getGraphClient(); // inside while so that it has fresh token on each loop
      const proms = this.bulkCopyItems.map(async (file) => {
          try {
              if (file.Id%this.iterator == 0 && file.Success == null){
                if (this.bulkCopy.Action == 'Delete'){
                  await this.deleteFile(file, graphClient);
                }
                else {
                  await this.processFile(file, graphClient);
                }
              }
          } 
          catch (error) {
              console.log('error'+ error);
          }
      });
      await Promise.all(proms);
      this.logEventMessage(`iteration ${this.iterator.toString()} complete`, proms.length); // not awaited; they aren't going to be in order anyway, so the exact timing of each row is not meaningful.
      this.iterator--;
    }
    this.iterator = null;
    const completeParams = {} as UtilService.CompleteLogEventParameters;
    completeParams.LogId = this.logId;
    completeParams.Success = true;
    await this._utilService.CompleteLogEvent(completeParams);

    this.$alert('Completed');
    // console.log('not found:', notfound);
  }
  async setFileName(file: AttachmentService.BulkCopyItems): Promise<number>{
    file.IsLoading = true;
    this.filesProcessed++;
    const sourceDriveId: string = file.DriveId;
    const sourceFileId: string = file.FileId;
    let res: MicrosoftGraphTypes.DriveItem;
    try{
      res = await this.getDriveItem(`drives/${sourceDriveId}/items/${sourceFileId}`);
    }
    catch(err){
      this.$alert(err);
      file.IsLoading = false;
      return 0;
    }
    file.Message = 'got FileName';
    file.Status = 'Saving...';
    file.FileName = res.name;
    file.Success = true;
    await new SmartObject(
            'BulkCopyItems',
            file.Id
        ).updateObject({
            Success: file.Success
            , Message: file.Message
            , Processed: this.$moment()
            , FileName: file.FileName
        });
    file.Status = 'saved';
    file.IsLoading = false;    
    return 1;
  }
  async deleteFile(file: AttachmentService.BulkCopyItems, graphClient: MicrosoftGraphClient.Client): Promise<number>{
    file.IsLoading = true;
    this.filesProcessed++;
    const sourceDriveId: string = file.DriveId;
    const sourceFileId: string = file.FileId;
    let fileCopied: number = 0;
    let res: Response;
    try{
        file.Status = 'deleting...'
        res = await graphClient
          .api(`drives/${sourceDriveId}/items/${sourceFileId}`)
          .responseType(ResponseType.RAW)
          //.version('beta')
          .delete();
          file.Message = 'Deleted';
          file.Status = '';
          file.Success = true;
          fileCopied = 1;
    }
    catch(err){
        file.Success = false;
        file.Message = err.Message;
    }
    file.Status = 'Saving...';
    await new SmartObject(
            'BulkCopyItems',
            file.Id
        ).updateObject({
            Success: file.Success
            , Message: file.Message
            , Processed: this.$moment()
        });
    file.Status = 'deleted';
    file.IsLoading = false;
    return fileCopied;
  }
  async processFile(file: AttachmentService.BulkCopyItems, graphClient: MicrosoftGraphClient.Client): Promise<number>{
    file.IsLoading = true;
    this.filesProcessed++;
    const sourceDriveId: string = file.DriveId;
    const sourceFileId: string = file.FileId;
    // const destinationDriveId: string = this.destinationDriveItem.parentReference.driveId;
    // const destinationItemId: string = this.destinationDriveItem.id;
    const destinationDriveId: string = file.DestinationDriveId;
    const destinationItemId: string = file.DestinationParentId;
    let fileCopied: number = 0;
    let res: Response;
    try{
        res = await graphClient
          .api(`drives/${sourceDriveId}/items/${sourceFileId}/copy`)
          .responseType(ResponseType.RAW)
          //.version('beta')
          .post( // NOTE: Post for Copy; send driveId and (folder)Id
          {
              "parentReference": {
                  'driveId': destinationDriveId,
                  'id': destinationItemId
                  }
          });
        const location: string = res.headers.get("location");
        //console.log(location);
        if (location) {
          // get the asyncOperationsResponse
          file.Status = 'waiting for copy...'
          while (file.NewItemId == null && file.Success == null){
              // it appears that Axios tries some kind of redirect that ultimately results in a SPDY_PROTOCOL_ERR.  Fetch seems to work fine.
              const rawResponse: any = await fetch(location);
              const asyncOperationsResponse = await rawResponse.json();
              console.log(asyncOperationsResponse);
              if (!!asyncOperationsResponse && asyncOperationsResponse.status == 'completed' && !!asyncOperationsResponse.resourceId){
                file.NewItemId = asyncOperationsResponse.resourceId;
                file.Message = 'Copied';
                file.Status = '';
                file.Success = true;
                await this.trySetFileName(file);
                
              }
              else if (!!asyncOperationsResponse && asyncOperationsResponse.status == 'failed'){
                  if (!!asyncOperationsResponse.error && !!asyncOperationsResponse.error.code && asyncOperationsResponse.error.code == 'nameAlreadyExists'){
                      file.Message = "File name already exists in destination folder";
                      file.Status = '';
                      file.Success = false;
                  }
                  else {
                      file.Message = asyncOperationsResponse.error.message;
                      file.Status = '';
                      file.Success = false;
                  }
              }
              else {
                  file.Status = file.Status + '.';
                  await new Promise( resolve => setTimeout (resolve, 1000)); // wait a second then check again
              }
          }
          fileCopied = 1;
      }
      else { // No Location
          file.Message = res.status + ' ' + res.statusText;
          file.Success = false;
      }
    }
    catch(err){
        file.Success = false;
        file.Message = err.Message;
    }
    file.Status = 'Saving...';
    await new SmartObject(
            'BulkCopyItems',
            file.Id
        ).updateObject({
            Success: file.Success
            , NewItemId: file.NewItemId
            , Message: file.Message
            , Processed: this.$moment()
            , FileName: file.FileName
        });
    file.Status = 'saved';
    file.IsLoading = false;
    return fileCopied;
  }
  async trySetFileName(file: AttachmentService.BulkCopyItems): Promise<void>{
    let newDriveItem = {} as MicrosoftGraphTypes.DriveItem;
    let tries: number = 0;
    while ( file.FileName == null && tries < 6){
        try{
            file.Status = `getting new file name (try ${tries})`;
            newDriveItem = await this.getDriveItem(
                `drives/${file.DestinationDriveId}/items/${file.NewItemId}`
            );
            file.FileName = newDriveItem.name;
            file.Status = '';
        }
        catch (err){ 
            file.Status = `waiting to retry getting new file name (tried ${tries})`;
            tries++;
            const origMessage = file.Message;
            file.Message = err.message;
            await new Promise( resolve => setTimeout (resolve, 2000)); 
            file.Status = 'retrying new file name';
            file.Message = origMessage;
        }
    } // while
    if (tries == 6){
        file.Status = 'unable to get file name';
    }
  }
  tableRowClassName({row, rowIndex}) {
        if (row.IsLoading) {
          return 'warning-row';
        }
        return '';
  }
  //#endregion Methods
}
