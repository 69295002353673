
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as LiqService from './../../services/DAL/liq_Service';
import AgreementEditView from '@/js/AP/AgreementEditView.vue';
import findIndex from 'lodash/findIndex';
import ElementUI from 'element-ui';

@Component({
    components: {
        AgreementEditView
    }
})
export default class AgreementList extends Vue {
    $refs: {
        refAgreementList: ElementUI.Table
    }
    //#region Private declarations for Services
    private _liqService: LiqService.LiqService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() vendorId: number;
    //#endregion Props

    //#region Data
    agreements: LiqService.Agreements[] = [];
    selectedAgreement: LiqService.Agreements = new LiqService.Agreements();
    filter: string = '';
    loading: boolean = false;
    showAgreementEditView: boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();
        this.fetchAgreements();
    }

    async mounted() {
    }
    //#endregion Lifecycle

    //#endregion Computed
    get agreementsFiltered(): LiqService.Agreements[] {
        const filter_ = (this.filter || '').toLowerCase();
        return this.agreements.filter(agreement => {
            return (
                (agreement.Client || '').toLowerCase().indexOf(filter_) > -1 ||
                (agreement.Name || '').toLowerCase().indexOf(filter_) > -1 ||
                (agreement.AgreementType || '').toLowerCase().indexOf(filter_) > -1 ||
                (agreement.OwnerName || '').toLowerCase().indexOf(filter_) > -1 ||
                (agreement.ScheduleType || '').toLowerCase().indexOf(filter_) > -1 ||
                (agreement.GroupName || '').toLowerCase().indexOf(filter_) > -1 ||
                (agreement.BankAccountNameDisplay || '').toLowerCase().indexOf(filter_) > -1
            );
        });
    }
    //#endregion Computed

    //#region Methods
    async fetchAgreements() {
        this.loading = true;
        const params = {} as LiqService.GetAgreementsParameters;
        params.VendorId = this.vendorId;
        this.agreements = await this._liqService.GetAgreements(params);
        this.loading = false;
    }

    selectAgreement(agreement: LiqService.Agreements) {
        this.selectedAgreement = agreement;
        this.showAgreementEditView = true;
    }

    async onAgreementCopied(agreement) {
        this.agreements.push(agreement);
        this.selectedAgreement = agreement;
        this.$refs.refAgreementList.setCurrentRow(agreement);
        this.showAgreementEditView = false;
        await this.$nextTick();
        this.showAgreementEditView = true;
    }

    removeAgreement(agreementId: number) {
        const index = findIndex(this.agreements, agreement => {
            return (
                agreement.AgreementId === agreementId
            );
        });
        if (index > -1) {
            this.agreements.splice(index, 1);
        }
        this.showAgreementEditView = false;
    }
    //#endregion Methods
}
