
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import AddressSelect from '@/components/form/AddressSelect.vue';
import CommentLogList from '@/js/CommentLog/CommentLogList.vue';
import * as VamService from '../../services/DAL/vamService';
import * as SystemService from '../../services/DAL/systemService';
import VueClipboard from 'vue-clipboard2';
import cloneDeep from 'lodash/cloneDeep';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function getStoredSecurityLevel(Id: number): number;

// 11-03-2021 12:47 CAMPBED: Always get KeyPersonClients, even when no KeyPersonId.  Handle setting KeyPersonId when saving KeyPersonClient when new.


@Component({
  components: {
    AddressSelect,
    CommentLogList
  }
})
export default class KeyPersonEdit extends Vue {
    $refs: {
        frmKeyPersonEdit: ElementUI.Form
    }
  //#region Data
  private _vam_Service: VamService.VamService;
  private _system_Service: SystemService.SystemService;

  @Prop() keyPerson: VamService.KeyPersons;
  @Prop() viewType: string;

  keyPerson_: VamService.KeyPersons = new VamService.KeyPersons();
  notes = null;
  relationships = [];
  phoneTypes = [];
  keyPersonClients = [];
  securityLevel = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageKeyPeople), 0);
  //#endregion Data

  //#region Computed
  get isNew(): boolean {
    return !this.keyPerson || !Object.keys(this.keyPerson).length;
  }
  get isFormDirty(): boolean {
    return Object.keys((this as any).veeFields).some(
      key => (this as any).veeFields[key].dirty
    );
  }
    get isKeyPersonClientDirty(): boolean {
        if (!this.keyPersonClients.length) {
            return false;
        }
        for (let i = 0; i < this.keyPersonClients.length; i++) {
            const keyPersonClient = this.keyPersonClients[i];
            if (
                (!keyPersonClient.KeyPersonClientId && keyPersonClient.Selected)  //add new
                ||
                (!!keyPersonClient.KeyPersonClientId && !keyPersonClient.Selected) //delete existing
            ) {
                return true;
            }
        }
        return false;
    }
  //#endregion Computed

  //#region Lifecycle
  async created() {
    VueClipboard.config.autoSetContainer = true;
    Vue.use(VueClipboard);
      
    if (this.keyPerson && Object.keys(this.keyPerson).length) {
      this.keyPerson_ = this.keyPerson;
    }
    this._vam_Service = new VamService.VamService();
    this._system_Service = new SystemService.SystemService();
    this.fetchRelationships();
    this.fetchPhoneTypes();

    this.fetchKeyPersonClients();
  }
  //#endregion Lifecycle

  //#region Methods
  async fetchRelationships() {
      this.relationships = [];
      const getNamedKeysParameters = {} as SystemService.GetNamedKeysParameters;
      getNamedKeysParameters.KeyType = 'KeyPerson.Relationship';
      this.relationships = await this._system_Service.GetNamedKeys(getNamedKeysParameters);
  }
  
  async fetchPhoneTypes() {
    this.phoneTypes = [];
    const getNamedKeysParameters = {} as SystemService.GetNamedKeysParameters;
    getNamedKeysParameters.KeyType = 'System.PhoneType';
    this.phoneTypes = await this._system_Service.GetNamedKeys(getNamedKeysParameters);
  }

  async fetchKeyPersonClients() {
      this.keyPersonClients = [];
      const getKeyPersonClientsParameters = {} as VamService.GetKeyPersonClientsParameters;
      getKeyPersonClientsParameters.KeyPersonId = this.keyPerson_.Id;
      this.keyPersonClients = await this._vam_Service.GetKeyPersonClients(getKeyPersonClientsParameters);
  }

  onCopySuccess() {
      this.$notify.success('Text copied to clipboard.');
  }

  onCopyError() {
      this.$notify.error('Failed to copy text to clipboard.');
  }

  async saveForm() {
    this.$refs.frmKeyPersonEdit.validate(async valid => {
      if (valid) {
        let message: string;

        const updateAttrs = cloneDeep(this.keyPerson_);
        delete updateAttrs.FullName; // can't update computed column

        if (this.isNew) {
            try {
                this.keyPerson_.Id = await new SmartObject('KeyPerson').createObject(updateAttrs);
            }
            catch (err) {
                try {
                    const errJson = JSON.parse(err.responseText);
                    if (errJson.ExceptionMessage.toLowerCase().indexOf('cannot insert duplicate key') > -1) {
                        this.$notify.error('The name entered matches the name of a key person already in the system. Please edit the existing record, or change the name and try again.');
                    }
                    else {
                        this.$notify.error('Something went wrong processing your request, please try again.');
                    }
                }
                catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
                return;
            }
          message = 'Person added.';

          if (this.notes) {
              await new SmartObject('CommentLog').createObject({
                  Comment: this.notes,
                  EntityType: 'KeyPerson',
                  EntityId: this.keyPerson_.Id
              });
          }
        } else {
          await new SmartObject('KeyPerson', this.keyPerson_.Id).updateObject(updateAttrs);
          message = 'Changes saved.';
        }

        this.$notify.success(message);

        if (this.isKeyPersonClientDirty) {
          await this.saveKeyPersonClientChanges();
        }

        this.$emit('saved', this.keyPerson_.Id);
        this.$emit('update:keyPerson', this.keyPerson_);

        if (this.isNew) {
          this.$emit('close');
        }
      }
    });
  }

  async saveKeyPersonClientChanges() {
    for (let i = 0; i < this.keyPersonClients.length; i++) {
        const keyPersonClient = this.keyPersonClients[i];
        if (!keyPersonClient.KeyPersonClientId && keyPersonClient.Selected) {
            if (!keyPersonClient.KeyPersonId) keyPersonClient.KeyPersonId = this.keyPerson_.Id; 
            const kpc = new SmartObject('KeyPersonClient');
            await kpc.createObject(keyPersonClient);
            keyPersonClient.KeyPersonClientId = kpc.entityId;
        }
        else if (!!keyPersonClient.KeyPersonClientId && !keyPersonClient.Selected) {
            const kpc = new SmartObject('KeyPersonClient', keyPersonClient.KeyPersonClientId);
            await kpc.deleteObject();
            keyPersonClient.KeyPersonClientId = null;
        }
    }
  }

  async deleteKeyPerson() {
    if (!this.isNew) {
      try {
        await this.$confirm(
          'This will permanently delete this person. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        try {
          await new SmartObject('KeyPerson', this.keyPerson_.Id).deleteObject();

          this.$notify.success('Person deleted.');

          this.$emit('deleted', this.keyPerson_.Id);
          this.$emit('close');
        } catch {
          this.$notify.error('Something went wrong processing your request, please try again.');
        }
      } catch {}
    }
  }
  //#endregion Methods
}
