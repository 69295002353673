//js API for Schema workflow
//Auto-generated 08/15/19 08:47
 

var mixinSchema_workflow = {
    methods: {
        /** @description workflow.ApproveTripInvoice
         * @param {number} invoiceId - 
         */
		 workflow_ApproveTripInvoice: function (params, onSuccess, onError, async) {
			/*
				this.ApproveTripInvoice = await this.workflow_ApproveTripInvoice({
					InvoiceId: invoiceId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'workflow.ApproveTripInvoice'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ApprovalApprovers using workFlow.GetApprovalApprovers
         * @param {number} approvalId - 
         */
		, workFlow_GetApprovalApprovers: function (params, onSuccess, onError, async) {
			/*
				this.ApprovalApprovers = await this.workFlow_GetApprovalApprovers({
					ApprovalId: approvalId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'workFlow.GetApprovalApprovers'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ApprovalItems using workflow.GetApprovalItems
         * @param {number} approvalId - 
         */
		, workflow_GetApprovalItems: function (params, onSuccess, onError, async) {
			/*
				this.ApprovalItems = await this.workflow_GetApprovalItems({
					ApprovalId: approvalId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'workflow.GetApprovalItems'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Approvers using WorkFlow.GetApprovers
         * @param {number} workflowId - 
         * @param {number} clientId - 
         * @param {number} approvalId - 
         * @param {number} vendorId - 
         */
		, WorkFlow_GetApprovers: function (params, onSuccess, onError, async) {
			/*
				this.Approvers = await this.WorkFlow_GetApprovers({
					WorkflowId: workflowId
					, ClientId: clientId
					, ApprovalId: approvalId
					, VendorId: vendorId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'WorkFlow.GetApprovers'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
    }
};
export default mixinSchema_workflow;
