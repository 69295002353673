
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import ImportJetAviationCPR from '@/views/Aviation/ImportJetAviationCPR.vue'
import ImportJetAviationActivity from '@/views/Aviation/ImportJetAviationActivity.vue'
import VueMarkdown from '@adapttive/vue-markdown'
import * as VamService from './../../services/DAL/vamService';
import EventLogDetailView from '@/views/Event/EventLogDetailView.vue';

declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;
declare var getDateStringFromExcelDate: any;
@Component({
  components: {
    ImportJetAviationCPR,
    ImportJetAviationActivity,
    //@ts-ignore
    VueMarkdown,
    EventLogDetailView
  }
})
export default class AviationImport extends Vue {
  //#region Private declarations for Services
  private _vamService: VamService.VamService;

  //#endregion Private declarations for Services

  //#region Props
  //#endregion Props

  //#region Data
  showInstructions: boolean = false;
  mdIsntructions: string = '';
  showDataPane: boolean = false;
  selectedLogId: number = null;
  activeName: string = null;
  securityLevel_: number = null;
  
//#endregion Data

  //#endregion Computed

  //#endregion Computed

  //#region Lifecycle
  async created() {
    this._vamService = new VamService.VamService();
    this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAviationDataImport),
            0
        );
    this.getInstructions();
  }
  //#endregion Lifecycle
  //#region Methods
  async getInstructions(){
    const params = {} as VamService.GetMarkdownParameters;
    params.Name = 'JetAviationImportInstructions';
    const md = await this._vamService.GetMarkdown(params);
    this.mdIsntructions = md[0].Value;
  }
  async doShowInstructions(){
    this.showInstructions = true;
    await this.$nextTick();
    (this.$refs.mdInstructions as any).source = this.mdIsntructions;
  }
  async selectEvent(logId: number) {
      if (logId) {
          this.showDataPane = false;
          await this.$nextTick();
          this.showDataPane = true;
          this.selectedLogId = logId;
      }
  }
  closeEventLog() {
      this.showDataPane = false;
      this.selectedLogId = null;
  }

  //#endregion Methods
}
