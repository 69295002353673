
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as LiqService from '../../services/DAL/liq_Service';
import BankAccountReconciliationEdit from '@/views/BankAccount/BankAccountReconciliationEdit.vue';
import AttachmentList from '@/js/Attachments/AttachmentList.vue';
import ChangeLogList from '@/js/ChangeLog/ChangeLogList.vue';
import BankTransactionList from '@/js/BankTransactions/BankTransactionList.vue'
import PDFViewer from '@/components/other/PDFViewer.vue';
import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';

declare var $: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(value: any, defaultValue: any): any;

class BankTransactionParameters {
    DateRangeStart: string;
    DateRangeEnd: string;
    BankAccountId: number;
}

@Component({
    components: {
        BankAccountReconciliationEdit,
        AttachmentList,
        ChangeLogList,
        'pdf-viewer': PDFViewer,
        BankTransactionList
    }
})
export default class BankAccountReconciliationEditView extends Vue {
    //#region Private Declarations
    private _liqService: LiqService.LiqService;
    //#endregion Private Declarations

    //#region Props
    @Prop({ type: Object }) reconciliation: LiqService.BankDepositoryAccountReconciliations;
    @Prop() reconciliationId: number;
    @Prop() viewType: string;
    @Prop() showSideBySide: boolean;
    //#endregion Props

    //#region Data
    reconciliation_ = {} as LiqService.BankDepositoryAccountReconciliations;

    showTransactionList: boolean = false;
    showAttachmentList: boolean = false;
    showImage: boolean = false;
    showChangeLogList: boolean = false;
    securityLevel_: number = getStoredSecurityLevel(this.$namedKey.SecurityView.ManageBankAccounts);
    activeTab: string = '0';
    fileData = {} as MicrosoftGraphTypes.DriveItem;
    isLoading = false;
    bankTransactionsParameters = {} as BankTransactionParameters;

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();

       this.setReconciliation();
    }
    //#endregion Lifecycle


    @Watch('reconciliation')
    onChange_reconciliation(){
        this.setReconciliation();
    }

    //#region Methods
    setReconciliation(){
        if (this.reconciliation && Object.keys(this.reconciliation).length) {
            this.reconciliation_ = this.reconciliation;
        }
        else {
            this.reconciliation_ = {} as LiqService.BankDepositoryAccountReconciliations;
        }
        this.activeTab = '0';
    }
    onTabClick(tab) {
        switch (tab.label) {
            case 'Documents':
                this.showAttachmentList = true;
                break;
            case 'Transactions':
                this.bankTransactionsParameters.DateRangeStart = this.reconciliation_.StartDate;
                this.bankTransactionsParameters.DateRangeEnd = this.reconciliation_.EndDate;
                this.bankTransactionsParameters.BankAccountId = this.reconciliation_.BankAccountId;
                this.showTransactionList = true;
                break;
            case 'Change Log':
                this.showChangeLogList = true;
                break;
            case 'Image':
                this.showImage = true;
                break;
        }
    }

    onSave(reconciliation) {
        this.refreshChangeLogList();
        this.$emit('saved', reconciliation);
    }

    onDeleted(){
        this.$emit('item-deleted');
    }
    async fetchReconciliation() {
        this.isLoading = true;
        const params = {} as LiqService.GetBankDepositoryAccountReconciliationsParameters;
        params.ReconciliationId = this.reconciliationId;
        const bankDepositoryAccountReconciliations = await this._liqService.GetBankDepositoryAccountReconciliations(
            params
        );
        if (!!bankDepositoryAccountReconciliations && bankDepositoryAccountReconciliations.length == 1){
            this.reconciliation = bankDepositoryAccountReconciliations[0];
        }
        else {
            this.reconciliation = {} as LiqService.BankDepositoryAccountReconciliations;
        }
        this.isLoading = false;
    }

    async refreshChangeLogList() {
        this.showChangeLogList = false;
        await this.$nextTick();
        this.showChangeLogList = true;
    }
    gotFileData(fileData: MicrosoftGraphTypes.DriveItem){
        this.fileData = fileData;
    }
    sideBySideChanged(payload){
        this.$emit('side-by-side', payload)
    }
    //#endregion
}
