
import { Watch, Prop, Model, Component, Vue } from "vue-property-decorator";
import * as DebtService from '../../services/DAL/debtService';
import InstrumentEdit from "@/views/DebtInstrument/InstrumentEdit.vue";
import Common from '@/utils/common';

@Component({
    inheritAttrs: false
    , components: {
        InstrumentEdit,
    }
})
export default class DebtInstrumentSelector extends Vue {
    //#region Private declarations for Services
    private _debtService: DebtService.DebtService;
    private _common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() readonly value: number;
    @Prop({type: Number}) readonly investmentId: number;
    @Prop({type: Number}) readonly ownerId: number;
    @Prop() readonly instrumentTypeId: string | number | Array<number>;
    @Prop({ default: false }) readonly requireClientId: boolean;
    @Prop({ default: false }) readonly requireOwnerId: boolean;
    @Prop({ default: false }) readonly requireInvestmentId: boolean;
    @Prop({ default: false }) readonly requireInstrumentTypeId: boolean;
    @Prop({ default: false }) readonly allowNew: boolean;
    //#endregion Props

    //#region Data
    instruments = [] as DebtService.Instruments[];
    selectedValue: number = null;
    showDialog: boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._debtService = new DebtService.DebtService();
        this._common = new Common();
    }

    async mounted() {
        this.selectedValue = this.value;
        this.fetchInstruments();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch("value")
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
        if (this.instruments.length) {
            let selectedObject: DebtService.Instruments = new DebtService.Instruments();
            if (this.selectedValue) {
                selectedObject = this._common.getSelectedArrayItem(
                    this.instruments,
                    this.selectedValue.toString(),
                    'Id'
                );
            }
            this.$emit('value-set', selectedObject);
        }

    }

    @Watch("ownerId")
    onChange_ownerId(val: number, oldVal: number) {
        this.fetchInstruments();
    }
    @Watch("investmentId")
    onChange_investmentId(val: number, oldVal: number) {
        this.fetchInstruments();
    }
    @Watch("instrumentTypeId")
    onChange_instrumentId(val: number, oldVal: number) {
        this.fetchInstruments();
    }
    //#endregion Watches

    //#region Methods
    async fetchInstruments() {
        this.instruments = [];
        if ((!this.requireOwnerId || !!this.ownerId) &&
            (!this.requireInvestmentId || !!this.investmentId) &&
            (!this.requireInstrumentTypeId || !!this.instrumentTypeId)
        ) {
            const params = {} as DebtService.GetInstrumentsParameters;
            params.InvestmentId = this.investmentId;
            params.OwnerEntityId = this.ownerId;
            this.instruments = await this._debtService.GetInstruments(params);
            if (this.instruments && this.instruments.length ==1 && !this.allowNew) {
                this.selectedValue = this.instruments[0].Id;
                this.$emit('input', this.selectedValue)
            }
            this.$emit('options-fetched', this.instruments);
        }
    }
    async onSaveNew(debtInstrument: DebtService.Instruments) {
        await this.fetchInstruments();
        this.selectedValue = debtInstrument.Id;
        this.$emit('input', debtInstrument.Id);
        this.$emit('new-instrument-created', debtInstrument);
        this.showDialog = false;
    }

    //#endregion Methods
}
