
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as BalanceSheetService from '../../services/DAL/balanceSheetService';
import * as TaxService from '../../services/DAL/taxService';
import * as VamService from '../../services/DAL/vamService';
import ClientSelector from '@/components/form/ClientSelector.vue';
import OwnerSelector from '@/components/form/OwnerSelector.vue';
import Common from '../../utils/common';
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component({
    components: {
        ClientSelector,
        OwnerSelector
    }
})
export default class TaxConfigurationEdit extends Vue {
    $refs: {
        frmTaxConfigurationEdit: ElementUI.Form
    }
    //#region Data
    private _balanceSheetService: BalanceSheetService.BalanceSheetService;
    private _taxService: TaxService.TaxService;
    private _vamService: VamService.VamService;
    private _common: Common;

    @Prop() importConfiguration: TaxService.ImportConfiguration;
    @Prop() viewType: string;

    importConfiguration_: TaxService.ImportConfiguration = new TaxService.ImportConfiguration();
    balanceSheetSubCategories = [] as BalanceSheetService.SubCategories[];
    clients = [] as VamService.UserClients[];
    owners = [] as VamService.OwnersList[];
    years = [
        { Year: 0, YearDisplay: 'Prior Year' },
        { Year: 1, YearDisplay: 'Current Year' },
        { Year: 2, YearDisplay: 'Next Year' }
    ];
    creator: any = {};
    isLoading: boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._balanceSheetService = new BalanceSheetService.BalanceSheetService();
        this._taxService = new TaxService.TaxService();
        this._vamService = new VamService.VamService();
        this._common = new Common();

        if (this.importConfiguration && Object.keys(this.importConfiguration).length) {
            this.importConfiguration_ = this.importConfiguration;
        }
        this.fetchBalanceSheetSubCategories();
        if (!this.isNew) {
            this.fetchCreator();
        }
    }

    mounted() {
        this.$refs.frmTaxConfigurationEdit.resetFields();
    }
    //#endregion Lifecycle

    //#region Computed
    get isNew(): boolean {
        return (
            !this.importConfiguration_ ||
            !Object.keys(this.importConfiguration_).length ||
            (this.importConfiguration_.ImportConfigurationId || 0) <= 0
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    get taxYearDate(): any {
        return (this.importConfiguration_.TaxYear ? new Date(this.importConfiguration_.TaxYear, 0, 1) : null)
    }
    set taxYearDate(value) {
        this.importConfiguration_.TaxYear = (value ? new Date(value).getFullYear() : null);
    }
    //#endregion Computed

    //#region Watches
    @Watch('importConfiguration_.ClientId')
    onChange_ClientId(val: object, oldVal: object) {
        if (oldVal !== undefined) { // initial load
            let selectedClient = null;
            if (this.importConfiguration_.ClientId > 0) {
                selectedClient = this._common.getSelectedArrayItem(
                    this.clients,
                    this.importConfiguration_.ClientId.toString(),
                    'Id'
                );
            }

            if (selectedClient) {
                this.importConfiguration_.Client = selectedClient.Name;
            } else {
                this.importConfiguration_.Client = null;
            }
        }
    }

    @Watch('importConfiguration_.OwnerId')
    onChange_OwnerId(val: object, oldVal: object) {
        if (oldVal !== undefined) { // initial load
            let selectedOwner = null;
            if (this.importConfiguration_.OwnerId > 0) {
                selectedOwner = this._common.getSelectedArrayItem(
                    this.owners,
                    this.importConfiguration_.OwnerId.toString(),
                    'OwnerId'
                );
            }

            if (selectedOwner) {
                this.importConfiguration_.Owner = selectedOwner.Name;
            } else {
                this.importConfiguration_.Owner = null;
            }
        }
    }

    @Watch('importConfiguration_.Year')
    onChange_Year(val: object, oldVal: object) {
        if (oldVal !== undefined) { // initial load
            let selectedYear = null;
            if (this.importConfiguration_.Year >= 0) {
                selectedYear = this._common.getSelectedArrayItem(
                    this.years,
                    this.importConfiguration_.Year.toString(),
                    'Year'
                );
            }

            if (selectedYear) {
                this.importConfiguration_.YearDisplay = selectedYear.YearDisplay;
            } else {
                this.importConfiguration_.YearDisplay = null;
            }
        }
    }

    @Watch('importConfiguration_.BalanceSheetSubCategoryId')
    onChange_BalanceSheetSubCategoryId(val: object, oldVal: object) {
        if (oldVal !== undefined) { // initial load
            let selectedSubCategory = null;
            if (this.importConfiguration_.BalanceSheetSubCategoryId >= 0) {
                selectedSubCategory = this._common.getSelectedArrayItem(
                    this.balanceSheetSubCategories,
                    this.importConfiguration_.BalanceSheetSubCategoryId.toString(),
                    'Id'
                );
            }

            if (selectedSubCategory) {
                this.importConfiguration_.BalanceSheetCategory = selectedSubCategory.Category;
                this.importConfiguration_.BalanceSheetSubCategory = selectedSubCategory.SubCategory;
            } else {
                this.importConfiguration_.BalanceSheetCategory = null;
                this.importConfiguration_.BalanceSheetSubCategory = null;
            }
        }
    }
    //#endregion Watches

    //#region Methods
    async fetchCreator() {
        if (this.importConfiguration_.CreateUser) {
            const uo = new SmartObject('UserObject');
            await uo.loadDataFromCompositeKey({
                UserID: this.importConfiguration_.CreateUser
            });
            this.creator = uo.dataObject || { FirstLast: 'N/A' };
        } else {
            this.creator = { FirstLast: 'N/A' };
        }
    }

    async fetchBalanceSheetSubCategories() {
        const parameters = {} as BalanceSheetService.GetSubCategoriesParameters;
        parameters.ClientId = this.importConfiguration_.ClientId;
        parameters.Liability = true;
        parameters.Asset = false;
        this.balanceSheetSubCategories = await this._balanceSheetService.GetSubCategories(parameters);
    }

    async saveForm() {
        this.$refs.frmTaxConfigurationEdit.validate(async valid => {
            if (valid) {
                let message: string;
                const isNew = this.isNew;
                this.isLoading = true;

                try {
                    if (isNew) {
                        this.importConfiguration_.ImportConfigurationId = await new SmartObject('ImportConfiguration').createObject(this.importConfiguration_);
                        message = 'Configuration added.';
                    } else {
                        await new SmartObject('ImportConfiguration', this.importConfiguration_.ImportConfigurationId).updateObject(this.importConfiguration_);
                        message = 'Changes saved.';
                    }
                }
                catch (err) {
                    try {
                        const errJson = JSON.parse(err.responseText);
                        if (errJson.ExceptionMessage.toLowerCase().indexOf('cannot insert duplicate key') > -1) {
                            this.$notify.error('There is already a tax configuration for this client and year. Please edit the existing record, or change the client or year and try again.');
                        }
                        else {
                            this.$notify.error('Something went wrong processing your request, please try again.');
                        }
                    }
                    catch {
                        this.$notify.error('Something went wrong processing your request, please try again.');
                    }
                    this.isLoading = false;
                    return;
                }

                this.$notify.success(message);
                this.isLoading = false;

                this.$emit('save', this.importConfiguration_.ImportConfigurationId);
                this.$emit('update:importConfiguration', this.importConfiguration_);

                if (isNew) {
                    this.$emit('close');
                }
            }
        });
    }

    async deleteTaxConfiguration() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this configuration. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    this.isLoading = true;
                    await new SmartObject('ImportConfiguration', this.importConfiguration_.ImportConfigurationId).deleteObject();

                    this.$notify.success('Configuration deleted.');
                    this.isLoading = false;

                    this.$emit('delete', this.importConfiguration_.ImportConfigurationId);
                    this.$emit('close');
                } catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                    this.isLoading = false;
                }
            } catch {}
        }
    }
    //#endregion Methods
}
