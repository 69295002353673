
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as TaxService from '../../services/DAL/taxService';
import FormattedInput from '@/components/form/FormattedInput.vue';
import Common from '../../utils/common';

declare var SmartObject: any;

@Component({
    components: {
        FormattedInput
    }
})
export default class PaymentScheduleEdit extends Vue {
    //#region Private Declarations
    private _taxService: TaxService.TaxService;
    private _common: Common;
    //#endregion Private Declarations

    //#region Props
    @Prop() modelPaymentSchedule: TaxService.ModelPaymentSchedules;
    @Prop() importConfigurationId: number;
    @Prop() viewType: string;
    //#endregion Props

    //#region Data
    modelPaymentSchedule_: TaxService.ModelPaymentSchedules = new TaxService.ModelPaymentSchedules();
    jurisdictions = [
        { ImportCellId: 4, Jurisdiction: 'State' },
        { ImportCellId: 5, Jurisdiction: 'Federal' }
    ];
    isLoading: boolean = false;
    //#endregion Data

    //#region Lifecycle
    created() {
        this._taxService = new TaxService.TaxService();
        this._common = new Common();

        if (this.modelPaymentSchedule && Object.keys(this.modelPaymentSchedule).length) {
            this.modelPaymentSchedule_ = this.modelPaymentSchedule;
        }
    }
    //#endregion Lifecycle

    //#region Computed
    get isNew(): boolean {
        return (
            !this.modelPaymentSchedule_ ||
            !Object.keys(this.modelPaymentSchedule_).length ||
            (this.modelPaymentSchedule_.ModelPaymentScheduleId || 0) <= 0
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    //#endregion Computed

    //#region Watches
    @Watch('modelPaymentSchedule_.ImportCellId')
    onChange_ImportCellId(val: object, oldVal: object) {
        if (oldVal !== undefined) { // initial load
            let selectedJurisdiction = null;
            if (this.modelPaymentSchedule_.ImportCellId >= 0) {
                selectedJurisdiction = this._common.getSelectedArrayItem(
                    this.jurisdictions,
                    this.modelPaymentSchedule_.ImportCellId.toString(),
                    'ImportCellId'
                );
            }

            if (selectedJurisdiction) {
                this.modelPaymentSchedule_.Jurisdiction = selectedJurisdiction.Jurisdiction;
            } else {
                this.modelPaymentSchedule_.Jurisdiction = null;
            }
        }
    }
    //#endregion Watches

    //#region Methods
    async saveForm() {
        (this.$refs.frmPaymentScheduleEdit as any).validate(async valid => {
            if (valid) {
                let message: string;
                const isNew = this.isNew;
                this.isLoading = true;

                try {
                    if (isNew) {
                        this.modelPaymentSchedule_.ImportConfigurationId = this.importConfigurationId;
                        this.modelPaymentSchedule_.ModelPaymentScheduleId = await new SmartObject('ModelPaymentSchedule').createObject(this.modelPaymentSchedule_);
                        message = 'Fee paying commitment added.';
                    } else {
                        await new SmartObject('ModelPaymentSchedule', this.modelPaymentSchedule_.ModelPaymentScheduleId).updateObject(this.modelPaymentSchedule_);
                        message = 'Changes saved.';
                    }
                }
                catch (err) {
                    try {
                        const errJson = JSON.parse(err.responseText);
                        if (errJson.ExceptionMessage.toLowerCase().indexOf('duplicate key') > -1) {
                            this.$notify.error('There is already a payment schedule for this import configuration with the same jurisdiction and date. Please edit the existing record, or change the jurisdiction or date and try again.');
                        }
                        else {
                            this.$notify.error('Something went wrong processing your request, please try again.');
                        }
                    }
                    catch {
                        this.$notify.error('Something went wrong processing your request, please try again.');
                    }
                    this.isLoading = false;
                    return;
                }

                this.$notify.success(message);
                this.isLoading = false;

                this.$emit('save', this.modelPaymentSchedule_.ModelPaymentScheduleId);
                this.$emit('update:modelPaymentSchedule', this.modelPaymentSchedule_);

                if (isNew) {
                    this.$emit('close');
                }
            }
        });
    }

    async deletePaymentSchedule() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this payment. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    this.isLoading = true;
                    await new SmartObject('ModelPaymentSchedule', this.modelPaymentSchedule_.ModelPaymentScheduleId).deleteObject();

                    this.$notify.success('Payment deleted.');
                    this.isLoading = false;

                    this.$emit('delete', this.modelPaymentSchedule_.ModelPaymentScheduleId);
                    this.$emit('close');
                } catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                    this.isLoading = false;
                }
            } catch {}
        }
    }
    //#endregion Methods
}
