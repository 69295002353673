// TS API for Schema tax
// Auto-generated 03/17/23 12:02


declare var smartData: any;
// output classes:
export class ImportConfiguration {
    ImportConfigurationId: number=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    OwnerId: number=undefined;
    Owner: string=undefined;
    Year: number=undefined;
    YearDisplay: string=undefined;
    TaxYear: number=undefined;
    DriveId: string=undefined;
    FileId: string=undefined;
    VAMImportSheetName: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    ImportPath: string=undefined;
    BalanceSheetSubCategoryId: number=undefined;
    BalanceSheetCategory: string=undefined;
    BalanceSheetSubCategory: string = undefined;
}
export class ModelImport {
    Id: number=undefined;
    ImportConfigurationId: number=undefined;
    ImportCellId: number=undefined;
    LogId: number=undefined;
    Value: number=undefined;
    ClientId: number=undefined;
    Year: number=undefined;
    TaxYear: number=undefined;
    Description: string=undefined;
    Jurisdiction: string=undefined;
    Path: string=undefined;
    DisplayOrder: number=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    ClientName: string=undefined;
    Created: string=undefined;
    NewValue: number=undefined;
    ValueIsCurrency: boolean=undefined;
    ReportingCategory: string=undefined;
    Owner: string = undefined;
}
export class ModelPaymentSchedules {
    ModelPaymentScheduleId: number=undefined;
    ImportConfigurationId: number=undefined;
    ClientId: number=undefined;
    TaxYear: number=undefined;
    ImportCellId: number=undefined;
    Jurisdiction: string=undefined;
    Date: string=undefined;
    PercentInt: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string = undefined;
}
export class OwnerCategoryStates {
    Id: number=undefined;
    OwnerCategoryId: number=undefined;
    OwnerCategoryName: string=undefined;
    StateId: number=undefined;
    StateName: string=undefined;
    StateAbbreviation: string=undefined;
    ReportRequirement: string=undefined;
    FilingFee: number=undefined;
    Frequency: string=undefined;
    Deadline: string = undefined;
}
export class PaymentSchedule {
    NoteId: number=undefined;
    Date: string=undefined;
    Payment: number=undefined;
    CalculatedPercentInt: number=undefined;
    CalculatedPercentage: number=undefined;
    PercentInt: number=undefined;
    Percentage: number=undefined;
    PaymentScheduleId: number=undefined;
    ScheduleType: string=undefined;
    _TotalTaxDue: number=undefined;
    LastModified: string = undefined;
}
export class SummaryCells {
    Id: number=undefined;
    YearCol: number=undefined;
    RE: string=undefined;
    Type: string=undefined;
    SubType: string=undefined;
    Description: string=undefined;
    Value: number=undefined;
    CreateDate: string=undefined;
    LogId: number=undefined;
    DisplayOrder: number = undefined;
}
export class TaxClasses {
    Id: number=undefined;
    Name: string=undefined;
    IRSId: string=undefined;
    DifferentTaxPayer: boolean = undefined;
}
export class SearchExemptOrganizations {
    EIN: string=undefined;
    Name: string=undefined;
    Street: string=undefined;
    City: string=undefined;
    State: string=undefined;
    Zip: string=undefined;
    VendorId: number=undefined;
    Deductibility: string=undefined;
    FilingRequirement: string=undefined;
    IsPrivateFoundation: boolean=undefined;
    PFFilingStatus: string=undefined;
    Latest990: string=undefined;
    Detail: string = undefined;
}

// Parameter interfaces:

export interface GetImportConfigurationParameters {
    ClientId: number;
    TaxYear: number;
}

export interface GetModelPaymentSchedulesParameters {
    ImportConfigurationId: number;
}

export interface GetOwnerCategoryStatesParameters {
    OwnerCategoryId: number;
}

export interface GetPaymentScheduleParameters {
    NoteId: number;
}

export interface GetSummaryCellsParameters {
    ClientId: number;
}

export interface GetTaxClassesParameters {
    OwnerCategoryId: number;
}

export interface SearchExemptOrganizationsParameters {
    SearchTerm: string;
    EIN: string;
}

export class TaxService {

  GetImportConfiguration (params: GetImportConfigurationParameters): Promise<ImportConfiguration[]> {
    return smartData({
        storedProcName: 'tax.GetImportConfiguration',
        params: params
      });
    }

  GetModelImport (): Promise<ModelImport[]> {
    return smartData({
        storedProcName: 'tax.GetModelImport',
        params: {}
      });
    }

  GetModelPaymentSchedules (params: GetModelPaymentSchedulesParameters): Promise<ModelPaymentSchedules[]> {
    return smartData({
        storedProcName: 'tax.GetModelPaymentSchedules',
        params: params
      });
    }

  GetOwnerCategoryStates (params: GetOwnerCategoryStatesParameters): Promise<OwnerCategoryStates[]> {
    return smartData({
        storedProcName: 'tax.GetOwnerCategoryStates',
        params: params
      });
    }

  GetPaymentSchedule (params: GetPaymentScheduleParameters): Promise<PaymentSchedule[]> {
    return smartData({
        storedProcName: 'tax.GetPaymentSchedule',
        params: params
      });
    }

  GetSummaryCells (params: GetSummaryCellsParameters): Promise<SummaryCells[]> {
    return smartData({
        storedProcName: 'tax.GetSummaryCells',
        params: params
      });
    }

  GetTaxClasses (params: GetTaxClassesParameters): Promise<TaxClasses[]> {
    return smartData({
        storedProcName: 'tax.GetTaxClasses',
        params: params
      });
    }

  SearchExemptOrganizations (params: SearchExemptOrganizationsParameters): Promise<SearchExemptOrganizations[]> {
    return smartData({
        storedProcName: 'tax.SearchExemptOrganizations',
        params: params
      });
    }
}
