
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import OwnerCategoryStateList from '@/views/OwnerCategory/OwnerCategoryStateList.vue';
import * as VamService from '../../services/DAL/vamService';
import * as SystemService from '../../services/DAL/systemService';
import _ from 'lodash';
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component({
  components: {
    OwnerCategoryStateList
  }
})
export default class OwnerCategoryEdit extends Vue {
  $refs: {
    frmOwnerCategoryEdit: ElementUI.Form
  }
  //#region Data
  private _vam_Service: VamService.VamService;
  private _system_Service: SystemService.SystemService;

  @Prop() ownerCategory: VamService.OwnerCategories;
  @Prop() onClose: Function;
  @Prop() onSave: Function;
  @Prop() onDelete: Function;
  @Prop() viewType: string;

  ownerCategory_: VamService.OwnerCategories = {
    Id: null,
    SuperCategoryId: null,
    SuperCategoryText: null,
    Name: null,
    Description: null
  };
  superCategories = [];

  get isNew(): boolean {
    return !this.ownerCategory || !Object.keys(this.ownerCategory).length;
  }
  get isFormDirty(): boolean {
    return Object.keys((this as any).veeFields).some(
      key => (this as any).veeFields[key].dirty
    );
  }
  //#endregion Data

  //#region Lifecycle
  async created() {
    if (this.ownerCategory && Object.keys(this.ownerCategory).length) {
      this.ownerCategory_ = this.ownerCategory;
      // this.ownerCategory_ = _.cloneDeep(this.ownerCategory);
    }
    this._vam_Service = new VamService.VamService();
    this._system_Service = new SystemService.SystemService();
    this.fetchSuperCategories();
  }
  //#endregion Lifecycle

  //#region Methods
  async fetchSuperCategories() {
    this.superCategories = [];
    const getNamedKeysParameters = {} as SystemService.GetNamedKeysParameters;
    getNamedKeysParameters.KeyType = 'Owner.SuperCategory';
    this.superCategories = await this._system_Service.GetNamedKeys(
      getNamedKeysParameters
    );
  }

  async saveForm() {
    this.$refs.frmOwnerCategoryEdit.validate(async valid => {
      if (valid) {
        let message: string;

        if (this.isNew) {
          this.ownerCategory_.Id = await new SmartObject(
            'OwnerCategory'
          ).createObject(this.ownerCategory_);
          message = 'Category added.';
        } else {
          await new SmartObject(
            'OwnerCategory',
            this.ownerCategory_.Id
          ).updateObject(this.ownerCategory_);
          message = 'Changes saved.';
        }

        this.$notify.success(message);

        if (typeof this.onSave === 'function') {
          await this.onSave(this.ownerCategory_.Id);
        }

        if (this.isNew) {
          this.closeDialog();
        }
      }
    });
  }

  async deleteOwnerCategory() {
    if (!this.isNew) {
      try {
        await this.$confirm(
          'This will permanently delete this category. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        try {
          await new SmartObject(
            'OwnerCategory',
            this.ownerCategory_.Id
          ).deleteObject();

          this.$notify.success('Category deleted.');

          if (typeof this.onDelete === 'function') {
            await this.onDelete(this.ownerCategory_.Id);
            this.onClose_();
          }
        } catch {
          this.$notify.error('Something went wrong processing your request, please try again.');
        }
      } catch {}
    }
  }

  onClose_() {
    if (typeof this.onClose === 'function') {
      this.onClose();
    }
  }

  closeDialog() {
    this.$emit('close-dialog');
    this.onClose_();
  }
  //#endregion Methods
}
