<template>
    <component :is="dynamicComponent" :ref="dynamicComponent" :params="params"></component>
</template>
<script>
    import Vue from 'vue';
    import PaymentList from './../AP/PaymentList.vue'
    import VendorEdit from './../Vendor/VendorEdit.vue'
    import AgreementListView from './../AP/AgreementListView.vue'
    import AgreementEditViewWrapper from './../AP/AgreementEditViewWrapper.vue'
    import AgreementTransactionsList from './../AP/AgreementTransactionList.vue' //remove when original AgreementsList is removed
    import ExcelCellImportConfigurationMangement from './../Excel/ExcelCellImportConfigurationMangement.vue'
    import JetDepreciationImport from './../Tax/JetDepreciationImport.vue'
    import AttachmentList from './../Attachments/AttachmentList.vue'
    import IngestAttachment from './../Attachments/IngestAttachment.vue'
    import ProcessLiquidityHistory from './../Treasury/ProcessLiquidityHistory.vue'

    export default Vue.extend({
        name: 'DynamicComponent'
        , components: {
            'payment-list': PaymentList
            , 'vendor-edit': VendorEdit
            , 'agreement-list-view': AgreementListView
            , 'agreement-edit-view-wrapper': AgreementEditViewWrapper
            , 'agreement-transactions-list': AgreementTransactionsList
            , 'excel-cell-import-configuration-mangement': ExcelCellImportConfigurationMangement
            , 'jet-depreciation-import': JetDepreciationImport
            , 'attachment-list': AttachmentList
            , 'ingest-attachment': IngestAttachment
            , 'process-liquidity-history': ProcessLiquidityHistory
}
        , mixins: []
        , props: {
            params: {
                type: Object
                , required: true
            }
        }
        , data() {
            return {
            }
        }
        , computed: {
            dynamicComponent: function () {
                return this._kebabCase(this.params.path);
            }
        }
    })
</script>
<style>
</style>