
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as WorkflowService from '../../services/DAL/workflowService';
import Common from '../../utils/common';

@Component({
    inheritAttrs: false
})
export default class WorkflowSelector extends Vue {
    //#region Private declarations for Services
    private _workflowService: WorkflowService.WorkflowService;
    private _common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({ type: Number }) readonly value: number;
    //#endregion Props

    //#region Data
    items = [] as WorkflowService.Workflow[];
    selectedValue: number = null;
    //#endregion Data

    //#region Computed
    //#endregion Computed

   //#region Lifecycle
    async created() {
        this._workflowService = new WorkflowService.WorkflowService();
        this._common = new Common();
    }
    async mounted(){
        this.selectedValue = this.value;
        await this.fetchWorkflows();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
        this.emitObject();
    }

    //#endregion Watches

    //#region Methods
    async fetchWorkflows() {
        const params = {} as WorkflowService.GetWorkflowParameters;
        this.items = await this._workflowService.GetWorkflow(params);
        this.$emit('options-fetched', this.items);
        this.emitObject();
    }
    emitObject(){
        if (this.items.length && this.selectedValue) {
            let selectedObject: WorkflowService.Workflow = new WorkflowService.Workflow();
            selectedObject = this._common.getSelectedArrayItem(
                this.items,
                this.selectedValue.toString(),
                ('Id')
            );
            if (selectedObject && Object.keys(selectedObject).length) {
                this.$emit('value-set', selectedObject);
            }
        }

    }
    //#endregion Methods
}
