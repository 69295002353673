
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ClientSelector from "@/components/form/ClientSelector.vue";
import TaxConfigurationTabView from '@/views/TaxConfiguration/TaxConfigurationTabView.vue';
import TaxConfigurationEdit from '@/views/TaxConfiguration/TaxConfigurationEdit.vue';
import * as TaxService from './../../services/DAL/taxService';
import findIndex from 'lodash/findIndex';
import ElementUI from 'element-ui';

@Component({
    components: {
        ClientSelector,
        TaxConfigurationTabView,
        TaxConfigurationEdit
    }
})
export default class TaxConfigurationList extends Vue {
    $refs: {
        tblTaxConfigurationList: ElementUI.Table
    }
    //#region Private Declarations
    private _taxService: TaxService.TaxService;
    //#endregion Private Declarations

    //#region Data
    importConfigurations: TaxService.ImportConfiguration[] = [];
    selectedClientId: number = null;
    filter: string = '';
    selectedTaxConfiguration: TaxService.ImportConfiguration = null;
    showTaxConfigurationEdit: boolean = false;
    showTaxConfigurationCreate: boolean = false;
    isLoading = false;
    //#endregion Data

    //#endregion Computed
    get showDetailColumns(): boolean {
        return (
            ['xs', 'sm', 'md'].indexOf(this.$getBootstrapSize()) > 0 ||
            !this.showTaxConfigurationEdit
        );
    }

    get importConfigurationsFiltered(): TaxService.ImportConfiguration[] {
        return this.importConfigurations.filter(importConfiguration => {
            const filter_ = (this.filter || '').toLowerCase();
            return (
                (importConfiguration.Client || '').toLowerCase().indexOf(filter_) > -1 ||
                (importConfiguration.Owner || '').toLowerCase().indexOf(filter_) > -1 ||
                (importConfiguration.TaxYear || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                (importConfiguration.YearDisplay || '').toLowerCase().indexOf(filter_) > -1 ||
                (importConfiguration.BalanceSheetCategory || '').toLowerCase().indexOf(filter_) > -1 ||
                (importConfiguration.BalanceSheetSubCategory || '').toLowerCase().indexOf(filter_) > -1
            );
        });
    }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._taxService = new TaxService.TaxService();
    }

    async mounted() {
        this.fetchImportConfigurations();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('selectedClientId')
    onClientIdChanged(val: string, oldVal: string) {
        this.fetchImportConfigurations();
    }
    //#endregion Watches

    //#region Methods
    selectTaxConfiguration(importConfiguration) {
        if (importConfiguration) {
            this.showTaxConfigurationEdit = false;
            this.$nextTick(() => {
                this.showTaxConfigurationEdit = true;
                this.selectedTaxConfiguration = importConfiguration;
            });
        }
    }

    closeTaxConfiguration() {
        this.showTaxConfigurationEdit = false;
        this.selectedTaxConfiguration = null;
        this.$refs.tblTaxConfigurationList.setCurrentRow(null);
    }

    onDeleteTaxConfiguration(importConfigurationId) {
        if (
            this.selectedTaxConfiguration &&
            this.selectedTaxConfiguration.ImportConfigurationId == importConfigurationId
        ) {
            const index = findIndex(this.importConfigurations, importConfiguration => {
                return importConfiguration.ImportConfigurationId === this.selectedTaxConfiguration.ImportConfigurationId;
            });
            if (index > -1) {
                this.importConfigurations.splice(index, 1);
            }
        }
        this.closeTaxConfiguration();
    }

    async fetchImportConfigurations() {
        this.isLoading = true;
        const params = {} as TaxService.GetImportConfigurationParameters;
        params.ClientId = this.selectedClientId;
        this.importConfigurations = await this._taxService.GetImportConfiguration(params);
        this.isLoading = false;
    }
    //#endregion Methods
}
