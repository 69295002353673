
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FundFamilyTabView from '@/views/FundFamily/FundFamilyTabView.vue';
import FundFamilyEdit from '@/views/FundFamily/FundFamilyEdit.vue';
import * as AssetService from './../../services/DAL/assetService';
import findIndex from 'lodash/findIndex';
import ElementUI from 'element-ui';

@Component({
    components: {
        FundFamilyTabView,
        FundFamilyEdit
    }
})
export default class FundFamilyList extends Vue {
    $refs: {
        tblFundFamilyList: ElementUI.Table
    }
    //#region Private Declarations
    private _assetService: AssetService.AssetService;
    //#endregion Private Declarations

    //#region Data
    fundFamilies: AssetService.FundFamilies[] = [];
    filter: string = '';
    selectedFundFamily = null;
    showFundFamilyEdit = false;
    showFundFamilyCreate = false;
    isLoading = false;
    //#endregion Data

    //#endregion Computed
    get showDetailColumns(): boolean {
        return (
            ['xs', 'sm', 'md'].indexOf(this.$getBootstrapSize()) > 0 ||
            !this.showFundFamilyEdit
        );
    }

    get fundFamiliesFiltered(): AssetService.FundFamilies[] {
        return this.fundFamilies.filter(fundFamily => {
            const filter_ = (this.filter || '').toLowerCase();
            return (
                (fundFamily.Name || '').toLowerCase().indexOf(filter_) > -1 ||
                (fundFamily.Manager || '').toLowerCase().indexOf(filter_) > -1
            );
        });
    }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
    }

    async mounted() {
        this.fetchFundFamilies();
    }
    //#endregion Lifecycle

    //#region Methods
    selectFundFamily(fundFamily) {
        if (fundFamily) {
            this.showFundFamilyEdit = false;
            this.$nextTick(() => {
                this.showFundFamilyEdit = true;
                this.selectedFundFamily = fundFamily;
            });
        }
    }

    closeFundFamily() {
        this.showFundFamilyEdit = false;
        this.selectedFundFamily = null;
        this.$refs.tblFundFamilyList.setCurrentRow(null);
    }

    onDeleteFundFamily(fundFamilyId) {
        // this.fetchFundFamilies();
        if (
            this.selectedFundFamily &&
            this.selectedFundFamily.Id == fundFamilyId
        ) {
            const index = findIndex(this.fundFamilies, fundFamily => {
                return fundFamily.Id === this.selectedFundFamily.Id;
            });
            if (index > -1) {
                this.fundFamilies.splice(index, 1);
            }
        }
        this.closeFundFamily();
    }

    async fetchFundFamilies() {
        this.isLoading = true;
        this.fundFamilies = await this._assetService.GetFundFamilies();
        this.isLoading = false;
    }
    //#endregion Methods
}
