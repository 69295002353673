 <template>
     <el-form :model="Main" :rules="MainRules" ref="editForm" label-width="30%" v-if="Main.NoteId">
         <el-form-item label="Name">
             <el-input v-model="Main.Name" size="mini" style="width: 100%; max-width:300px"></el-input>
         </el-form-item>
         <el-form-item label="Description">
             <el-input v-model="Main.Description" size="mini" style="width: 100%; max-width:300px"></el-input>
         </el-form-item>
         <el-form-item label="Client" prop="ClientId" required>
             <el-select filterable clearable size="mini"
                        style="width: 100%; max-width:100px"
                        v-bind:disabled="clients>0"
                        v-model="Main.ClientId">
                 <el-option v-for="client in clients"
                            :key="client.Id"
                            :label="client.Name"
                            :value="client.Id">
                 </el-option>
             </el-select>
         </el-form-item>
         <el-form-item label="Debtor" prop="OwnerId" required>
             <el-select filterable clearable size="mini"
                        style="width: 100%; max-width:300px"
                        v-bind:disabled="owners>0"
                        v-model="Main.OwnerId">
                 <el-option v-for="owner in owners"
                            :key="owner.Id"
                            :label="owner.Name"
                            :value="owner.Id">
                 </el-option>
             </el-select>
         </el-form-item>
         <el-form-item label="Balance Sheet Category" prop="BalanceSheetCategoryId" required>
             <el-select filterable clearable size="mini" style="width: 100%; max-width:450px"
                        v-bind:disabled="balanceSheetSubCategories>0"
                        v-model="Main.DebtorBalanceSheetCategoryId">
                 <el-option-group v-for="group in balanceSheetSubCategories" :key="group.groupId"
                                  :label="group.label">
                     <el-option v-for="item in group.options" :key="item.value"
                                :label="item.label"
                                :value="item.value">
                     </el-option>
                 </el-option-group>
             </el-select>
         </el-form-item>
         <el-form-item label="Liability Account" prop="LiabilityAccountId" required>
             <el-cascader :options="chartOfAccounts"
                          filterable
                          style="width: 100%; max-width:450px"
                          size="mini"
                          expand-trigger="hover"
                          @change="handleLiabilityAccountNodeClick"
                          :props="accountCascaderProps"
                          v-model="LiabilityAccountArray"></el-cascader>
         </el-form-item>
         <el-form-item label="Interest Account" prop="InterestAccountId" required>
             <el-cascader :options="chartOfAccounts"
                          filterable
                          style="width: 100%; max-width:450px"
                          size="mini"
                          expand-trigger="hover"
                          @change="handleInterestAccountNodeClick"
                          :props="accountCascaderProps"
                          v-model="InterestAccountArray"></el-cascader>
         </el-form-item>
         <el-form-item label="Proceeds Bank Account" property="DrawIntoBankAccountId" required>
             <el-select v-model="Main.DrawIntoBankAccountId"
                        size="mini"
                        style="width: 100%; max-width:450px"
                        v-bind:disabled="(bankAccounts.length==0)"
                        filterable
                        clearable
                        >
                 <el-option v-for="account in bankAccounts" :key="account.BankAccountId"
                            :label="account.NameNumberMasked"
                            :value="account.BankAccountId">
                 </el-option>
             </el-select>
         </el-form-item>
         <el-form-item label="Payments Bank Account" property="PayFromBankAccountId" required>
             <el-select v-model="Main.PayFromBankAccountId"
                        size="mini"
                        style="width: 100%; max-width:450px"
                        v-bind:disabled="(bankAccounts.length==0)"
                        filterable
                        clearable>
                 <el-option v-for="account in bankAccounts" :key="account.BankAccountId"
                            :label="account.NameNumberMasked"
                            :value="account.BankAccountId">
                 </el-option>
             </el-select>
         </el-form-item>
         <el-form-item label="Inception Date" property="InceptionDate">
             <el-date-picker v-model="Main.InceptionDate"
                             type="date"
                             style="width: 100%; max-width:150px"
                             size="mini"
                             placeholder="Pick a day">
             </el-date-picker>
         </el-form-item>
         <el-form-item label="Termination Date" property="CloseDate">
             <el-date-picker v-model="Main.CloseDate"
                             style="width: 100%; max-width:150px"
                             type="date"
                             size="mini"
                             placeholder="Pick a day">
             </el-date-picker>
         </el-form-item>
         <el-form-item label="Loan Amount" required property="AdvanceCap">
             <formatted-input v-model="Main.AdvanceCap" format-type="currency" num-decimals="2" size="mini" style="width: 100%; max-width:150px" name="AdvanceCap"></formatted-input>
         </el-form-item>
         <el-form-item>
            <el-button  @click="save" size="small" class="btn btn-default btn-success-stripe">Save</el-button>
         </el-form-item>
     </el-form>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinLiability from './../VueCommon/liability.vue'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import FormattedInput from '@/components/form/FormattedInput.vue';
    import mixinSchema_BalanceSheet from './../DAL/mixinSchema_balanceSheet'

    export default Vue.extend({
        name: 'NoteEditBasic'
        , mixins: [mixinUtility, mixinLiability, mixinSchema_liq, mixinSchema_vam, mixinSchema_BalanceSheet]
        , props: {
            noteId: {
                type: Number
                , required: true
            }
        }
        , components: {
            'formatted-input': FormattedInput
        }
        , data: function () {
            return {
                Main: []
                , clients: []
                , owners: []
                , statuses: []
                , bankAccounts: []
                , chartOfAccounts: []
                , amortizationTypes: []
                , MainRules: {
                    //Name: [
                    //    { type: 'string', required: true, message: 'VAM Name is required', trigger: 'blur' }
                    //    //, { min: 1, max: 100, message: 'Percentage must be between 1 and 100', trigger: 'blur' }
                    //],
                    //Date: [
                    //    { type: 'date', required: true, message: 'Please pick a date', trigger: 'change' }
                    //],
                }
            }
        }
        , created: function () {
            this.initialize();
        }
        , watch: {
            'noteId': function (val, oldVal) {
                this.initialize(val);
            }
            , 'Main.ClientId': async function (val, oldVal) {
                if (!(oldVal === undefined)) {//when oldVal=undefined, that is when the value is initially set.
                    this.Main.OwnerId = null; //if Client is changed, then owner must be reset
                    this.owners = await this.vam_GetOwnersV2({
                        ClientId: val
                        , ClientIdList: undefined
                    });

                    this.fetchBankAccounts();
                }
            }
        }
        , computed: {
            LiabilityAccountArray: function () {
                return this.getChartAccountsArrayForCoAId(this.Main.LiabilityAccountId);
            }
            , InterestAccountArray: function () {
                return this.getChartAccountsArrayForCoAId(this.Main.InterestAccountId);
            }
        }
        , methods: {
            initialize: async function (noteId) {
                if (!noteId) {
                    noteId = this.noteId;
                }
                this.Main = this.getNote(noteId);
                this.clients = await this.vam_GetUserClients({});
                this.owners = await this.vam_GetOwnersV2({
                        ClientId: this.Main.ClientId
                        , ClientIdList: undefined
                    });

                this.statuses = this.getNoteStatus();
                this.fetchBalanceSheetSubCategories();
                this.fetchChartOfAccounts(this.Main.ClientId);
                this.fetchBankAccounts();
                this.amortizationTypes = this.getAmortizationTypes();
            }
            , fetchBankAccounts: async function () {
                this.bankAccounts = await this.liq_GetBankAccountList({
                    ClientId: el.clientId
                    //, OrderBy: orderBy
                    //, AccountType: accountType
                    //, AccountId: accountId
                    //, OwnerId: ownerId
                    //, COAId: cOAId
                });
            }
            , fetchBalanceSheetSubCategories: function () {
                this.balanceSheetSubCategories = this.getBalanceSheetSubCategoriesForDDL(this.Main.ClientId, false, true);
            }
            , getBalanceSheetSubCategoriesForDDL: async function (clientId, assets, liabilities) {
            const ds = await this.BalanceSheet_GetSubCategories({
					ClientId: clientId
					, Asset: assets
					, Liability: liabilities
					}
				);
            const options = [];
            let group = '';
            const groupByLabel = 'Category';
            const groupById = 'Category';
            const itemLabel = 'SubCategory';
            const itemId = 'Id';
            let groupItem = {};
            ds.forEach(function (row) {
                if (group != row[groupByLabel]) {
                    groupItem = {
                        label: row[groupByLabel]
                        , groupId: row[groupById]
                        , options: []
                    };
                    options.push(groupItem);
                    group = row[groupByLabel];
                }
                var item = {
                    value: row[itemId]
                    , label: row[itemLabel]
                };
                groupItem.options.push(item);

            });
            return options;
        }

            , handleLiabilityAccountNodeClick: function () {

            }
            , handleInterestAccountNodeClick: function () {

            }
            , save: function () {

            }
            , fetchChartOfAccounts: async function () {
                if (this.Main.ClientId != null) {
                    var chartOfAccounts = await this.vam_GetChartOfAccountsForTree({
					    ClientId: this.Main.ClientId
					}
				);
                    this.chartOfAccounts = this.getCascaderHierarchy(chartOfAccounts, 'Id', 'Name', 'ParentId');
                }
                else {
                    this.chartOfAccounts = []
                }
            }
            , getChartAccountsArrayForCoAId: function (CoAId) {
            if (CoAId && CoAId != null) {
                var account = this.getChartOfAccounts(null, CoAId);
                if (account.length == 1) {
                    return JSON.parse(account[0].IdArray);
                }
            }
            else
                return [];

        }


        }
    })
</script>
<style scoped>
    .el-select-dropdown {
        z-index:9000 !important;
    }
    .el-picker-panel {
        z-index: 9000 !important;
    }
    .el-form-item {
        margin-bottom: 0;
    }
    .veryshort input, div.veryshort.el-date-editor, div.veryshort.el-input-number, div.veryshort.el-input-group {
        width: 75px;
    }
    .short input, div.short.el-date-editor, div.short.el-input-number, div.el-input-group {
        width: 150px;
    }
    .medium input, div.medium.el-date-editor, div.medium.el-input-number, div.el-input-group {
        width: 300px;
    }
    .long input, div.long.el-date-editor, div.long.el-input-number, div.el-input-group {
        width: 450px;
    }
</style>