
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Ref } from 'vue-property-decorator';
import * as DebtService from '../../services/DAL/debtService';
import * as VamService from '../../services/DAL/vamService';
import * as AssetService from '../../services/DAL/assetService';
import ClientSelector from '@/components/form/ClientSelector.vue';
import OwnerSelector from '@/components/form/OwnerSelector.vue';
import BankAccountSelector from '@/components/form/BankAccountSelector.vue'
import InvestmentSelector from "@/components/form/InvestmentSelector.vue";
import ElementUI from 'element-ui';
import Common from '../../utils/common';

declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: number, deflt: number): number;
declare var SmartObject: any;

@Component({
    components: {
        ClientSelector,
        OwnerSelector,
        BankAccountSelector,
        InvestmentSelector
    }
})
export default class InstrumentEdit extends Vue {
    @Ref() readonly frmInstrumentEdit!: ElementUI.Form;

    //#region Data
    private _debtService: DebtService.DebtService;
    private _vamService: VamService.VamService;
    private common: Common;

    @Prop({type: Object}) readonly instrument: DebtService.Instruments;
    @Prop({type: Number}) readonly instrumentId!: number;
    @Prop({type: Number}) readonly investmentId!: number;
    @Prop({type: String}) readonly viewType: string;

    instrument_ = {} as DebtService.Instruments;
    instrumentId_: number = this.instrumentId;
    securityLevel: number = 0;
    overrideDefaultBankAccountPayFrom = false;
    overrideDefaultBankAccountDrawInto = false;
    overrideDefaultBankAccountGrantor = false;
    owner = {} as VamService.OwnersList;
    instrumentTypes = [] as DebtService.InstrumentTypes[];
    investment = {} as AssetService.InvestmentList;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._debtService = new DebtService.DebtService();
        this._vamService = new VamService.VamService();
        this.common = new Common();

        this.securityLevel = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );

        if (this.instrument && Object.keys(this.instrument).length && this.instrument.Id){
            this.instrumentId_ = this.instrument.Id;
        }
        else if (this.instrumentId) {
            this.instrumentId_ = this.instrumentId;
        }

        if (this.instrument && Object.keys(this.instrument).length) { 
            this.instrument_ = this.instrument;
        }
        else if (this.instrumentId_) {
            await this.fetchInstrument();
        }
        else {
            this.instrument_ = new DebtService.Instruments();
            this.$nullifyObjectProps(this.instrument_);
            this.instrument_.InvestmentId = this.investmentId;
        }
        await this.fetchInstrumentTypes();
    }

    async mounted() {
        await this.initializeInstrument();
    }
    //#endregion Lifecycle

    //#region Watches

    // @Watch('instrument_.ClientId')
    // onChange_ClientId(val: number, oldVal: number) {
    //     if (oldVal !== undefined) {
    //     }
    // }


    //#endregion Watches

    //#region Computed
    get isNew(): boolean {
        return (
            !this.instrument_ ||
            !Object.keys(this.instrument_).length ||
            (this.instrument_.Id || 0) <= 0
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    get isFormValid(): boolean{
        return !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
    }
    get instrumentType(): DebtService.InstrumentTypes{
        if (this.instrument_.TypeId)
            return this.common.getArrayItemFromConditions(
                this.instrumentTypes,
                { Id: this.instrument_.TypeId }
            ) as DebtService.InstrumentTypes;
    }


    //#endregion Computed

    //#region Methods

    async initializeInstrument(){
        this.overrideDefaultBankAccountPayFrom = !!(this.instrument_ && this.instrument_.PayFromBankAccountId);
        this.overrideDefaultBankAccountDrawInto = !!(this.instrument_ && this.instrument_.DrawIntoBankAccountId);
        this.overrideDefaultBankAccountGrantor = !!(this.instrument_ && this.instrument_.GrantorBankAccountId);
    }

    async fetchInstrument() {
        const params = {} as DebtService.GetInstrumentsParameters;
        params.InstrumentId = this.instrumentId_;
        const instruments = await this._debtService.GetInstruments(params);
        if (instruments.length) {
            this.instrument_ = instruments[0];
            await this.initializeInstrument();
        }
        else this.instrument_ = {} as DebtService.Instruments;
    }    
    
    async fetchInstrumentTypes(){
        const params = {} as DebtService.GetInstrumentTypesParameters;
        params.IsInvestment = !!(this.investmentId || this.instrument_.InvestmentId);
        this.instrumentTypes = await this._debtService.GetInstrumentTypes(params);
    }


    async saveForm() {
        this.frmInstrumentEdit.validate(async valid => {
            if (valid) {
                let message: string;
                const isNew = this.isNew;

                try {
                    if (!isNew){
                        await new SmartObject('Instrument', this.instrument_.Id).updateObject(
                            this.instrument_
                        );
                    }
                    else {
                        if (!this.instrument_.StatusId) this.instrument_.StatusId = 20; //active
                        const newId = await new SmartObject('Instrument').createObject(
                            this.instrument_
                        );
                        this.instrumentId_ = newId;
                    }
                    await this.fetchInstrument();

                    this.$notify.success(`Instrument ${this.isNew ? 'added' : 'saved'}.`);
                    this.$emit('update:instrument', this.instrument_);
                    if (isNew){
                        this.$emit('create', this.instrument_);    
                        this.$emit('close');
                    }
                    else {
                        this.$emit('save', this.instrument_);
                    }
                }
                catch (err) {
                    console.error(err);
                    this.$notify.error('Something went wrong processing your request, please try again.');
                    return;
                }
            }
        });
    }

    async deleteInstrument() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this instrument. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    await new SmartObject(
                        'Instrument',
                        this.instrument_.Id
                    ).deleteObject();
                    this.$notify.success('Instrument deleted.');
                    this.$emit('delete', this.instrument_.Id);
                    this.$emit('close');
                    
                } catch (e) {
                    this.$notify.error(e.message);
                }
            } catch {}
        }
    }
    async ownerSet(owner: VamService.OwnersList){
        this.owner = owner;
    }
    async investmentSelected(investment: AssetService.InvestmentList) {
    this.investment = investment;
    this.instrument_.ClientId = investment.ClientId;
    this.instrument_.OwnerEntity = investment.Owner;
    this.instrument_.OwnerEntityId = investment.OwnerId;
    if (this.isNew){
        (this as any).veeFields['Investment'].dirty = true;
    }

    }
  }

    //#endregion Methods
