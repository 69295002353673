import Vue from 'vue';

export class AttachmentLinkService extends Vue {
  private _url: string = '/Attachment/';

  async getExternalLinkForEntity(entityType: string, entityId: number): Promise<any> {
    const endpoint: string = this._url + 'GetExternalLinkForEntity';
    try {
      const response = await this.$http.post(endpoint, {
        'entityType': entityType,
        'entityId': entityId
      });
      return response;
    } catch (error) {
        console.log(error);
      return error;
    }
  }
}
