<template >
    <div id="main">
        <el-dialog v-loading="loading" :visible.sync="showModalBankTransactionEdit" :show-close="true" :close-on-click-modal="false"
               v-on:close="closeBankTransactionEdit"
               width="75%">
            <span slot="title">Transaction {{BankTransactionId}}</span>
            <b><span class="form-control-static">{{Main.Description}}</span></b>
            <table class="table table-striped">
                <tr>
                    <th>Posted</th>
                    <th>Amount</th>
                    <th>Account</th>
                </tr>
                <tr>
                    <td>
                        <span class="form-control-static">{{Main.PostedDate | shortDate}}</span>
                    </td>
                    <td>
                        <span class="form-control-static">{{Main.Amount | currency}}</span>
                    </td>
                    <td>
                        <span class="form-control-static">{{Main.AccountName}}</span>
                        <span class="form-control-static">{{Main.NumberDisplayMasked}}</span>
                    </td>
                </tr>
            </table>
            <template v-if="!matchedTotalEqualsTransaction">
                <template v-if="matchBalance<0">
                    <span style="color:red">Over-Matched by {{matchBalance | currency }}</span>
                </template>
                <template v-else>
                    <span style="color:red">Balance: {{matchBalance | currency }}</span>
                </template>
            </template>
            <hr />
            <h4>Matches:</h4>
            <el-row>
                <el-col>
                    <el-table v-if="matchesForTransactionList[0]"
                              ref="refMatchesForTransactionList"
                              :data="matchesForTransactionList"
                              style="width: 100%;"
                              stripe
                              :summary-method="getSummaries"
                              show-summary
                              row-key="MatchTransactionId"
                              size="mini"
                              highlight-current-row
                              >
                        <el-table-column label="Action" 
                                         min-width=""
                                         prop="MatchedTransactionId" align="center">
                            <template slot-scope="scope">
                                <el-button size="mini"
                                            v-if="scope.row.MatchedTransactionId > 0 && securityLevel_ > $namedKey.SecurityLevel.BasicUser"
                                           type="danger"
                                           icon="el-icon-delete"
                                           :title="scope.row.MatchedTransactionId"
                                           :disabled="(securityLevel_<80)"
                                           @click.stop="handleDeleteMatch(scope.$index, scope.row)"></el-button>
                                <el-button size="mini"
                                           type="success"
                                           icon="el-icon-check"
                                           :disabled="(securityLevel_<80)"
                                           title="Confirm"
                                           v-if="scope.row.AutoMatchRequiresConfirmation && securityLevel_ > $namedKey.SecurityLevel.BasicUser"
                                           @click.stop="handleConfirmMatchedRow(scope.$index, scope.row)"></el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="Auto Matched"
                                         min-width="80"
                                         sortable
                                         v-if="HideIfColumnEmpty(matchesForTransactionList, 'AutoMatchedTime')"
                                         prop="AutoMatchedTime">
                            <template slot-scope="scope">
                                <el-button :title="scope.row.SearchTermText" @click.stop="handleShowEditSearchTerm(scope.$index, scope.row)" type="text">{{scope.row.AutoMatchTime | shortDateTime}}</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="Match Confirmed"
                                         min-width="100"
                                         sortable
                                         v-if="HideIfColumnEmpty(matchesForTransactionList, 'MatchConfirmed')"
                                         prop="MatchConfirmed">
                            <template slot-scope="scope">
                                <span>{{scope.row.MatchConfirmed}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Review"
                                         min-width="40"
                                         v-if="HideIfColumnEmpty(matchesForTransactionList, 'ReviewForTax')"
                                         prop="ReviewForTax">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.ReviewForTax" title="Review For Tax" @change="handleReviewForTaxChange(scope.row)" ></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label="Entity Type"
                                         min-width="90"
                                         sortable
                                         prop="EntityTypeDisplayShort">
                            <template slot-scope="scope">
                                <span >{{scope.row.EntityTypeDisplayShort}}: {{scope.row.EntityId}}</span>
                                <a :href="`../Treasury/BankTransactionEdit/${scope.row.TransferId}`" v-if="!!scope.row.TransferId" target="_blank"> ({{scope.row.TransferId}})</a>
                                <el-popover v-if="agreementBudgets[scope.$index] && Object.keys(agreementBudgets[scope.$index]).length" placement="right" trigger="hover" :disabled="!scope.row.EntityTypeDisplayShort == 'Agreement-Budget'">
                                    <el-form label-width="200px">
                                        <el-form-item label="Budgeted" prop="BudgetAmount" >
                                            {{agreementBudgets[scope.$index].BudgetAmount | currency}}
                                        </el-form-item>
                                        <el-form-item label="Invoiced:">
                                            {{agreementBudgets[scope.$index].Invoiced | currency}}
                                        </el-form-item>
                                        <el-form-item label="Invoices Paid:">
                                            {{agreementBudgets[scope.$index].InvoicePaid | currency}}
                                        </el-form-item>
                                        <el-form-item label="Unpaid Invoices:">
                                            {{agreementBudgets[scope.$index].Unpaid | currency}}
                                        </el-form-item>
                                        <el-form-item label="Reference Only Invoices:" title="Invoices that will not match to Transactions, but are complete and count against the budget">
                                            {{agreementBudgets[scope.$index].ReferenceOnlyPaid | currency}}
                                        </el-form-item>
                                        <el-form-item label="Agreement Paid:" title="Transactions Matched to the Agreement without an Invoice">
                                            {{agreementBudgets[scope.$index].AgreementPaid | currency}}
                                        </el-form-item>
                                        <el-form-item label="Total Against Budget:" title="Invoice Paid + Unpaid Invoices + Reference Only Invoices + Agreement Paid">
                                            {{agreementBudgets[scope.$index].Paid | currency}}
                                        </el-form-item>
                                        <el-form-item label="Remaining:" title="Budget - Total Against Budget">
                                            <el-tag :type="agreementBudgets[scope.$index].RemainingStyle">{{agreementBudgets[scope.$index].Remaining | currency}}</el-tag>
                                        </el-form-item>
                                    </el-form>
                                    <i slot="reference" class="el-icon-info" style="color:blue;" v-show="scope.row.EntityTypeDisplayShort == 'Agreement-Budget'"/>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column label="Matched Description"
                                         min-width="200"
                                         sortable
                                         prop="MatchedDescription">
                            <template slot-scope="scope">
                                <span>{{scope.row.MatchedDescription}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Comment"
                                         min-width="200"
                                         v-if="HideIfColumnEmpty(matchesForTransactionList, 'Comment')"
                                         prop="Comment">
                        </el-table-column>
                        <el-table-column label="Vendor Name"
                                         min-width=""
                                         v-if="HideIfColumnEmpty(matchesForTransactionList, 'VendorId')"
                                         sortable
                                         prop="VendorName">
                            <template slot-scope="scope">
                                <span :title="scope.row.VendorId">{{scope.row.VendorName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Sched Date"
                                         min-width=""
                                         sortable
                                         v-if="HideIfColumnEmpty(matchesForTransactionList, 'ScheduledDate')"
                                         prop="ScheduledDate" align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.ScheduledDate | shortDate}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Sched Amt"
                                         min-width=""
                                         sortable
                                         v-if="HideIfColumnEmpty(matchesForTransactionList, 'ScheduledAmount')"
                                         prop="ScheduledAmount" align="right">
                            <template slot-scope="scope">
                                <span>{{scope.row.ScheduledAmount | currency}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <hr />
            <h5 v-if="relatedMatchesForTransactionList[0]">Related Matched Transactions:</h5>
            <el-row v-if="relatedMatchesForTransactionList[0]">
                <el-col>
                    <el-table v-if="relatedMatchesForTransactionList[0]"
                              ref="refRelatedMatches"
                              :data="relatedMatchesForTransactionList"
                              style="width: 100%;"
                              stripe
                              :summary-method="getSummaries"
                              show-summary
                              row-key="MatchedTransactionId"
                              size="mini"
                              >
                        <el-table-column label="Transaction"
                                         sortable
                                         v-if="HideIfColumnEmpty(relatedMatchesForTransactionList, 'TransactionId')"
                                         prop="TransactionId">
                        </el-table-column>
                        <el-table-column label="Acct"
                                         sortable
                                         v-if="HideIfColumnEmpty(relatedMatchesForTransactionList, 'NumberDisplayMasked')"
                                         prop="NumberDisplayMasked">
                        </el-table-column>
                        <el-table-column label="Auto Matched"
                                         min-width="80"
                                         sortable
                                         v-if="HideIfColumnEmpty(relatedMatchesForTransactionList, 'AutoMatchedTime')"
                                         prop="AutoMatchedTime">
                            <template slot-scope="scope">
                                <el-button :title="scope.row.SearchTermText" @click.stop="handleShowEditSearchTerm(scope.$index, scope.row)" type="text">{{scope.row.AutoMatchTime | shortDateTime}}</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="Match Confirmed"
                                         min-width="100"
                                         sortable
                                         v-if="HideIfColumnEmpty(relatedMatchesForTransactionList, 'MatchConfirmed')"
                                         prop="MatchConfirmed">
                            <template slot-scope="scope">
                                <span>{{scope.row.MatchConfirmed}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Review"
                                         min-width="40"
                                         v-if="HideIfColumnEmpty(relatedMatchesForTransactionList, 'ReviewForTax')"
                                         prop="ReviewForTax">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.ReviewForTax" title="Review For Tax" @change="handleReviewForTaxChange(scope.row)" ></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label="Entity Type"
                                         min-width="90"
                                         sortable
                                         prop="EntityTypeDisplayShort">
                        </el-table-column>
                        <el-table-column label="Matched Description"
                                         min-width="200"
                                         sortable
                                         prop="MatchedDescription">
                            <template slot-scope="scope">
                                <span>{{scope.row.MatchedDescription}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Comment"
                                         min-width="200"
                                         v-if="HideIfColumnEmpty(relatedMatchesForTransactionList, 'Comment')"
                                         prop="Comment">
                        </el-table-column>
                        <!-- <el-table-column label="Vendor Name"
                                         min-width=""
                                         v-if="HideIfColumnEmpty(relatedMatchesForTransactionList, 'VendorId')"
                                         sortable
                                         prop="VendorName">
                            <template slot-scope="scope">
                                <span :title="scope.row.VendorId">{{scope.row.VendorName}}</span>
                            </template>
                        </el-table-column> -->
                        <el-table-column label="Posted Date"
                                         min-width=""
                                         sortable
                                         v-if="HideIfColumnEmpty(relatedMatchesForTransactionList, 'PostedDate')"
                                         prop="PostedDate" align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.PostedDate | shortDate}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Posted Amount"
                                         min-width=""
                                         sortable
                                         v-if="HideIfColumnEmpty(relatedMatchesForTransactionList, 'Amount')"
                                         prop="Amount" align="right">
                            <template slot-scope="scope">
                                <span>{{scope.row.Amount | currency}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showModalBankTransactionEdit = false">Close</el-button>
                <el-button type="primary" plain :disabled="Main.DisableMatches || securityLevel_<80" @click="showMatchEdit = true" v-if="securityLevel_ > $namedKey.SecurityLevel.BasicUser"
                    :title="Main.DisableMatches ? Main.DisabledMatchReason : ''">Add Match</el-button>
            </div>
        </el-dialog >
        <el-dialog v-loading.body="loading" :visible.sync="showMatchEdit" :show-close="true" :close-on-click-modal="false" v-draggable :title="`Match Transaction ${Main.TransactionId}`"
                   v-on:close="closeMatchEdit">
            <div slot="footer" class="dialog-footer">
                <div class="pull-left">
                    <el-button type="warning" icon="el-icon-plus" @click="showSetSearchTerm = true" :disabled="(securityLevel_<80)" v-show="setSearchTermKeys.show">
                        Set Search Term
                    </el-button>
                </div>
                <el-button @click="showMatchEdit = false">Close</el-button>
                <el-button type="success" icon="el-icon-check" @click="confirmMatch" v-if="showConfirmButton && securityLevel_>=80">Confirm Match</el-button>
            </div>

            <el-row :gutter="20">
                <el-col :span="6">
                    Account (Owner):
                </el-col>
                <el-col :span="18">
                    <span >{{Main.AccountName}} ({{Main.OwnerName}})</span>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    Transaction:
                </el-col>
                <el-col :span="18">
                    <span v-bind:style="descriptionStyle">{{Main.Description}}</span>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="!!Main.CardHolder">
                <el-col :span="6">
                    Card Holder:
                </el-col>
                <el-col :span="18">
                    <span>{{Main.CardHolder}}</span>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    Amt/Date:
                </el-col>
                <el-col :span="18">
                    <span>{{Main.Amount | currency}}&nbsp; &nbsp; {{Main.PostedDate | shortDate}}</span>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    Match Type:
                </el-col>
                <el-col :span="10">
                    <el-select placeholder="Select"
                               :disabled="(!!selectedMatch.MatchedTransactionId)"
                               default-first-option
                               :filterable="true"
                               size="small"
                               :key="Main.TransactionId"
                               style="width:100%"
                               v-model="selectedMatch.EntityType">
                        <el-option v-for="entityType in entityTypes" :key="entityType.Name"
                                   :label="entityType.Name"
                                   :value="entityType.Type">
                        </el-option>
                    </el-select>

                </el-col>
            </el-row>
            <el-row :gutter="20" class="Vendor" v-show="showVendor">
                <el-col :span="6">
                    Vendor:
                </el-col>
                <el-col :span="10">
                    <el-select placeholder="Select"
                               :disabled="(!!selectedMatch.MatchedTransactionId || !vendors || !vendors.length)"
                               :filterable="true"
                               default-first-option
                               size="small"
                               style="width:100%"
                               v-model="selectedMatch.VendorId">
                        <el-option v-for="vendor in vendors" :key="vendor.Id"
                                   :label="vendor.Name"
                                   :value="vendor.Id">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" size="mini" icon="el-icon-view" v-show="(selectedMatch.VendorId)" title="View Vendor" v-on:click="viewReference('Vendor', selectedMatch.VendorId)">
                    </el-button>
                </el-col>
            </el-row>

            <el-row :gutter="20" class="Agreement" v-show="showAgreement">
                <el-col :span="6">
                    Agreement:
                </el-col>
                <el-col :span="10">
                    <el-select placeholder="Select"
                               :disabled="(!!selectedMatch.MatchedTransactionId || !agreements || !agreements.length)"
                               :filterable="true"
                               default-first-option
                               size="small"
                               style="width:100%"
                               v-model="selectedMatch.AgreementId">
                        <el-option v-for="agreement in agreements" :key="agreement.AgreementId"
                                   :label="agreement.Name"
                                   :value="agreement.AgreementId">
                            <span style="float: left">{{ agreement.Name }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ agreement.ScheduleType }}</span>
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" size="mini" icon="el-icon-view" v-show="(selectedMatch.AgreementId)" title="View Agreement" v-on:click="viewReference('Agreement', selectedMatch.AgreementId)">
                    </el-button>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="Agreement" v-show="showAgreement && !!selectedMatchAgreement && !!selectedMatchAgreement.InvoicesExpected">
                <el-col :span="6">
                    <span class="text-danger">This Agreement expects Invoices.  Are you sure you want to Match directly to the Agreement?</span>
                </el-col>
            </el-row>

            <el-row :gutter="20" v-show="showInvestment && !selectedMatch.MatchedTransactionId">
                <el-col :span="6">
                    Investment:
                </el-col>
                <el-col :span="10">
                    <el-select placeholder="Select"
                               :disabled="(!!selectedMatch.MatchedTransactionId || !investmentDdlOptions || !investmentDdlOptions.length)"
                               :filterable="true"
                               default-first-option
                               size="small"
                               style="width:100%"
                               multiple
                               v-model="selectedInvestmentIds">
                        <el-option-group v-for="group in investmentDdlOptions" :key="group.groupId"
                                         :label="group.label">
                            <el-option v-for="item in group.options" :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-option-group>
                    </el-select>
                </el-col>
                <el-col :span="2">
                    <el-checkbox v-model="currentInvestmentsOnly" >Current Only</el-checkbox>
                </el-col>
                <el-col :span="6">
                    <el-checkbox v-model="includeChildrenOfOwner" >Include Investments of Children Owners</el-checkbox>
                </el-col>
            </el-row>

            <el-row :gutter="20" class="Note" v-show="showNote">
                <el-col :span="6">
                    Note:
                </el-col>
                <el-col :span="10">
                    <el-select v-model.number="selectedMatch.NoteId"
                               placeholder="Select"
                               :filterable="true"
                               size="small"
                               default-first-option
                               style="width:100%"
                               :disabled="(!!selectedMatch.MatchedTransactionId)">
                        <el-option-group v-for="group in notes" :key="group.groupId"
                                         :label="group.label">
                            <el-option v-for="item in group.options" :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-option-group>
                    </el-select>
                </el-col>
            </el-row>

            <el-row :gutter="20" v-show="selectedEntityType && selectedEntityType.showIgnoreDateWindow">
                <el-col :span="6">
                    Ignore Date Window:
                </el-col>
                <el-col :span="10">
                    <el-switch v-model="ignoreDateWindow"></el-switch>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-table v-if="references[0]"
                        ref="rpt_tblEntity"
                        size="mini"
                        :data="references"
                        style="width: 100%;"
                        stripe
                        row-key="EntityId"
                        highlight-current-row
                        @selection-change="handleSelectionChange"
                        >
                        <el-table-column type="selection" width="55" :selectable="isSelectable">
                        </el-table-column>
                        <el-table-column label="View" v-if="HideIfColumnEmpty(references, 'PreviewUrl')"> >
                            <template slot-scope="props">
                                <el-button v-show="props.row.Previewable" title="Preview" icon="el-icon-view" type="primary"
                                    @click="openPreviewURL(props.row.PreviewUrl)" size="mini"
                                    :entity-id="props.row.EntityId" :entity-type="props.row.EntityType">
                                    </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="Description"
                                        sortable
                                        min-width="120">
                            <template slot-scope="props">
                                <span :title="props.row.EntityId">{{props.row.Identifier}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="150"
                                         sortable
                                         :formatter="formatterCurrency"
                                         label="Amount"
                                         prop="Amount">
                        </el-table-column>
                        <el-table-column min-width="150"
                                         sortable
                                         :formatter="formatterCurrency"
                                         v-if="HideIfColumnEmpty(references, 'PreviouslyMatched')"
                                         label="Already Matched"
                                         prop="PreviouslyMatched">
                        </el-table-column>
                        <el-table-column min-width="150"
                                         sortable
                                         label="Schedule"
                                         prop="Schedule">
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>

            <el-row :gutter="20" v-show="showAccount">
                <el-col :span="6">
                    Account:
                </el-col>
                <el-col :span="18">
                    <el-cascader v-show="showAccount"
                                 placeholder="(searchable)"
                                 :options="chartOfAccounts"
                                 filterable
                                 size="small"
                                 :filter-method="filterMethodCoA"
                                 expand-trigger="hover"
                                 :props="accountCascaderProps"
                                 v-model="chartOfAccountsArray"></el-cascader>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-show="showAccount">
                <el-col :span="6">
                    Department:
                </el-col>
                <el-col :span="18">
                    <el-select filterable clearable size="small"
                               placeholder="Optional"
                               default-first-option
                               v-bind:disabled="departments>0"
                               v-model="selectedMatch.DepartmentId">
                        <el-option v-for="department in departments"
                                   :key="department.DepartmentId"
                                   :label="department.Name"
                                   :value="department.DepartmentId">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-show="showAccount">
                <el-col :span="6">
                    Comment:
                </el-col>
                <el-col :span="18">
                    <el-input type="textarea" v-model="selectedMatch.Comment" class="fullContent" maxlength="500" clearable></el-input>
                </el-col>
            </el-row>

            <el-row v-show="multipleSelection.length > 1">
                <el-col :span="4" :offset="4">
                    <label for="selectedTotal" class="control-label">Selected Total:</label>
                </el-col>
                <el-col :span="4">
                    <span span-class="form-control-static">{{selectedTotal | currency}}</span>
                </el-col>
            </el-row>
            <el-row v-if="multipleSelection.length==1 && (showInvestment || selectedMatch.EntityType == 'Cash') && selectedTotal != Main.Amount">
                <el-col :span="9" :offset="1" class="ReviewForTaxes">
                    <br>
                    <el-checkbox v-model="setInvestmentAmountToBankAmount">Set Investment Transaction Amount to Selected Bank Transaction Amount</el-checkbox>
                    <br>
                </el-col>
            </el-row>
            <div class="row">
                <div class="col-sm-offset-1 col-sm-9 ReviewForTaxes">
                    <el-checkbox v-model="selectedMatch.ReviewForTax">Review For Tax</el-checkbox>
                </div>
            </div>

        </el-dialog>
        <div-set-search-term v-bind:show-modal="showSetSearchTerm"
                                @close="closeSetSearchTerm"
                                v-bind:search-term-id="setSearchTermKeys.SearchTermId"
                                v-bind:search-term-entity-type="setSearchTermKeys.EntityType"
                                v-bind:search-term-entity-id="setSearchTermKeys.EntityId"
                                v-bind:transaction-description="Main.Description"
                                v-bind:transaction-amount="Main.Amount">
        </div-set-search-term>
</div>
</template>

<script>
    import Vue from 'vue';
    import setSearchTerm from './SetSearchTerm.vue'
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import mixinSchema_liab from './../DAL/mixinSchema_liab'
    import mixinSchema_asset from './../DAL/mixinSchema_asset'

    export default Vue.extend({
        name: 'BankTransactionEdit'
        , mixins: [mixinUtility, mixinSchema_liq, mixinSchema_liab, mixinSchema_vam, mixinSchema_asset]
        , components: {
             'div-set-search-term': setSearchTerm
        }
        , props: {
            row: {
                type:Object
            }
            , clientId: {
                type: Number
            }
            , BankTransactionId: {
                type: Number
            }
            , showModal: {
                type: Boolean
                , default: false
            }
            , securityLevel: {
                type: Number
                , default: null
            }
        }
        , data: function () {
            return {
                Main: {}
                , showModalBankTransactionEdit: false
                , showMatchEdit: false
                , vendors: []
                , investments: []
                , investmentDdlOptions: []
                , selectedInvestmentIds: []
                , agreements: []
                , references: []
                , departments: []
                , notes: []
                , entityTypes: []
                , showSetSearchTerm: false
                , external: { //pass this object to other controls for them to set values.  Must use an object and the values underneath it becasue objects are implicitly "by ref"
                    addedVendorId: -1
                    , addedInvestmentTransactionId: -1
                    , addedEntityId: -1
                }
                , loading: false
                , chartOfAccounts: [{
                    value: null
                    , label: null
                    , children: []
                }]
                , accountCascaderProps: {
                    value: 'value'
                }
                , matchesForTransactionList: []
                , relatedMatchesForTransactionList: []
                , agreementBudgets: []
                , selectedMatch: this.liq_GetMatchesForTransaction_New()
                , searchTermId: null
                , ignoreDateWindow: false
                , securityLevel_: this.securityLevel
                , includeChildrenOfOwner: false
                , currentInvestmentsOnly: true
                , multipleSelection: []
                , setInvestmentAmountToBankAmount: false
            }
        }
        , created: function () {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), 0);
            }
            if ((!this.row || !Object.keys(this.row).length) && !!this.BankTransactionId){ // opened from URL
                this.showModalBankTransactionEdit = true;
            }
            this.entityTypes = this.getEntityTypes();
        }
        , watch: {
            'showModal': function (val, oldVal) {
                this.showModalBankTransactionEdit = val;
            }
            , 'showModalBankTransactionEdit': function (val, oldVal) {
                if (val) {
                    this.initialize();
                }
            }
            , 'selectedMatch.VendorId': function (val, oldVal) {
                if (this.showAgreement) {
                    this.selectedMatch.AgreementId = null;
                    this.fetchAgreementList();
                }
                this.fetchReferences();
            }
            , 'showAgreement': function (val, oldVal) {
                if (val && this.agreements.length == 0) {
                    this.fetchAgreementList()
                }
            }
            , 'showNote': function (val, oldVal) {
                if (val && this.notes.length == 0) {
                    this.fetchNotesForOwnerSelect();
                }
            }
            , 'showInvestment': function (val, oldVal) {
                if (val && this.investments.length == 0) {
                    this.fetchInvestmentList()
                }
            }
            , 'showVendor': function (val, oldVal) {
                if (val && this.vendors.length == 0) {
                    this.fetchVendorList()
                }
            }
            , 'selectedMatch.MatchedTransactionId': function (val, oldVal) {
                this.fetchReferences();
            }
            , 'selectedMatch.EntityType': async function (val, oldVal) {
                //this.selectedEntityIds = []; //clear any already selected
                this.multipleSelection = []; // clear any already selected
                await this.fetchReferences();
            }
            , 'selectedInvestmentIds': function (val, oldVal) {
                this.fetchReferences();
            }
            , 'selectedMatch.AgreementId': function (val, oldVal) {
                this.fetchReferences();
            }
            , 'selectedMatch.NoteId': function (val, oldVal) {
                this.fetchReferences();
            }
            , 'external.addedVendorId': function (val, oldVal) {
                this.selectedMatch.VendorId = val;
                this.fetchVendorList();
            }
            , 'external.addedEntityId': function (val, oldVal) {
                this.selectedMatch.EntityId = val;
                this.fetchReferences();
            }
            , 'external.addedInvestmentTransactionId': function (val, oldVal) {
                this.selectedMatch.EntityId = val;
                this.fetchReferences();
            }
            , 'ignoreDateWindow': function (val, oldVal) {
                this.fetchReferences();
            }
            , 'includeChildrenOfOwner': function (val, oldVal) {
                this.fetchInvestmentList();
            }
            , 'currentInvestmentsOnly': function (val, oldVal){
                this.fetchInvestmentList();
            }
        }
        , computed: {
            selectedEntityType: function () {
                return this.getSelectedArrayItem(this.entityTypes, this.selectedMatch.EntityType, 'Type');
            }
            , showInvestment: function () {
                //var selectedEntityType = this.getSelectedArrayItem(this.entityTypes, this.selectedMatch.EntityType, 'Type');
                if (this.selectedEntityType) {
                    var showInvestment = this.selectedEntityType.showInvestment;
                    return showInvestment;
                }
                else {
                    return false;
                }
            }
            , descriptionStyle: function () {
                return {
                    'font-size': (this.Main && this.Main.Description && this.Main.Description.length > 200) ? 'xx-small' : 'inherit'
                }
            }
            , chartOfAccountsArray: {
                get: function () {
                    if (this.selectedMatch.ChartOfAccountsArray) {
                        return JSON.parse(this.selectedMatch.ChartOfAccountsArray);
                    }
                    else
                        return [];
                }
                , set: function (data) {
                    this.multipleSelection.splice(0, this.multipleSelection.length);
                    this.multipleSelection.push({EntityId: data[data.length - 1]});
                    // this.selectedEntityIds.splice(0, this.selectedEntityIds.length);
                    // this.selectedEntityIds.push(data[data.length - 1]);
                }
            }
            , showAccount: function () {
                //var selectedEntityType = this.getSelectedArrayItem(this.entityTypes, this.selectedMatch.EntityType, 'Type');

                if (this.selectedEntityType) {
                    var showAccount = this.selectedEntityType.showAccount || false;
                    return showAccount;
                }
                else {
                    return false;
                }
            }
            , showVendor: function () {
                //var selectedEntityType = this.getSelectedArrayItem(this.entityTypes, this.selectedMatch.EntityType, 'Type');

                if (this.selectedEntityType) {
                    var showVendor = this.selectedEntityType.showVendor;
                    return showVendor;
                }
                else {
                    return false;
                }
            }
            , showAgreement: function () {
                //var selectedEntityType = this.getSelectedArrayItem(this.entityTypes, this.selectedMatch.EntityType, 'Type');

                if (this.selectedEntityType) {
                    var showAgreement = this.selectedEntityType.showAgreement;
                    if (showAgreement) {//only show Agreeement after Vendor is selected
                        showAgreement = (this.selectedMatch.VendorId != undefined && this.selectedMatch.VendorId != null);
                    }
                    return showAgreement;
                }
                else {
                    return false;
                }
            }
            , showNote: function () {
                //var selectedEntityType = this.getSelectedArrayItem(this.entityTypes, this.selectedMatch.EntityType, 'Type');

                if (this.selectedEntityType) {
                    var showNote = this.selectedEntityType.showNote;
                    return showNote;
                }
                else {
                    return false;
                }
            }
            , showConfirmButton: function () {
                if (this.selectedMatch.MatchConfirmed) {
                    return false; //already confirmed
                }
                else if (this.selectedMatch.LogId || this.selectedMatch.EntityId) {
                    return true;
                }
                else if (!!this.multipleSelection && this.multipleSelection.length > 0) {
                    return true;
                }
                else {
                    return false;
                }
            }
            , setSearchTermKeys: function () {
                if (this.searchTermId) {
                    return {
                        SearchTermId: this.searchTermId
                        , EntityId: null
                        , EntityType: null
                    }
                }
                else {
                    var searchTermKeys = {
                        EntityId: (this.multipleSelection[0]) ? this.multipleSelection[0].EntityId : undefined
                        , EntityType: this.selectedMatch.SearchTermEntityType
                        , show: (//any EntityType here must be coded into the matching logic in proc liq.ProcessTransactionMatch
                            (this.selectedMatch.EntityType == 'Invoice' || this.selectedMatch.EntityType == 'Agreement' || this.selectedMatch.EntityType == 'ScheduleItem')
                            && (this.selectedMatch.EntityId || this.multipleSelection.length > 0) //only show if an id has been selected.
                        )
                    }
                    if (this.selectedMatch.SearchTermEntityId == undefined || this.selectedMatch.SearchTermEntityId == null) {
                        if (this.selectedMatch.EntityType == 'Invoice') {
                            searchTermKeys.EntityType = 'Vendor';
                            searchTermKeys.EntityId = this.selectedMatch.VendorId;
                        }
                        else if (this.selectedMatch.EntityType == 'ScheduleItem') {
                            searchTermKeys.EntityType = 'Agreement';
                            searchTermKeys.EntityId = this.selectedMatch.AgreementId;
                        }
                        else {
                            searchTermKeys.EntityType = this.selectedMatch.EntityType;
                            searchTermKeys.EntityId = (this.multipleSelection[0]) ? tryParseInt(this.multipleSelection[0].EntityId, -1) : -1;
                        }
                    }
                    return searchTermKeys;
                }
            }
            , selectedMatchAgreement: function(){
                if (!!this.selectedMatch && !!this.selectedMatch.AgreementId){
                    return this.getSelectedArrayItem(this.agreements, this.selectedMatch.AgreementId, 'AgreementId');
                }
                else {
                    return undefined;
                }
            }
            , selectedTotal: function () {
                var total = 0;
                for (let i = 0, len = this.multipleSelection.length; i < len; i++) {
                    const entityId = (this.multipleSelection[0]) ? this.multipleSelection[i].EntityId : undefined;
                    const selectedEntity = this.getSelectedArrayItem(this.references, entityId, 'Id');
                    if (selectedEntity) {
                        total = total + selectedEntity.Amount;
                    }
                }
                return total;
            }
            , matchedTotalEqualsTransaction: function () {
                if (this.matchesForTransactionList == undefined || this.matchesForTransactionList.length == 0) {
                    return true; //if no matches yet, don't treat like a mismatch.
                }
                if (!!this.matchesForTransactionList && this.matchesForTransactionList.length == 1 &&
                    (this.matchesForTransactionList[0].EntityTypeDisplayShort == 'Account'
                    || this.matchesForTransactionList[0].EntityTypeDisplayShort == 'Category'
                    || this.matchesForTransactionList[0].EntityTypeDisplayShort == 'Agreement-Budget'
                    || this.matchesForTransactionList[0].ScheduledAmount == null)
                    ) {
                    return true; //Agreement-Budget, Account and Category never have value, don't treat like a mismatch.
                }
                if (this.matchesForTransactionList[0].EntityTypeDisplayShort == 'Transfer') {
                    return (this.$accounting.formatMoney(this.propertySum(this.matchesForTransactionList, 'ScheduledAmount')) == this.$accounting.formatMoney(this.Main.Amount)); // don't consider "related" matches for Transfers because they whould be offsetting
                }

                return (this.$accounting.formatMoney(this.propertySum(this.matchesForTransactionList, 'ScheduledAmount') - this.propertySum(this.relatedMatchesForTransactionList, 'Amount')) == this.$accounting.formatMoney(this.Main.Amount));
            }
            , matchBalance: function () {
                if (this.matchesForTransactionList.length == 0) {
                    return 0; //if no matches yet, don't treat like a mismatch.
                }
                if (this.matchesForTransactionList.length == 1 &&
                    (this.matchesForTransactionList[0].EntityTypeDisplayShort == 'Account'
                    || this.matchesForTransactionList[0].EntityTypeDisplayShort == 'Category'
                    || this.matchesForTransactionList[0].EntityTypeDisplayShort == 'Agreement-Budget'
                    || this.matchesForTransactionList[0].ScheduledAmount == null) 
                ) {
                    return 0; //Agreement-Budget, Account and Category never have value, don't treat like a mismatch. Also if Scheduled Amount is null, then there's nothing to compare to
                }
                if (this.matchesForTransactionList[0].EntityTypeDisplayShort == 'Transfer') {
                    return this.Main.Amount - this.propertySum(this.matchesForTransactionList, 'ScheduledAmount'); // don't consider "related" matches for Transfers because they whould be offsetting
                }

                return this.Main.Amount - (this.propertySum(this.matchesForTransactionList, 'ScheduledAmount') - this.propertySum(this.relatedMatchesForTransactionList, 'Amount'));
            }
        }
        , methods: {
            initialize: async function () {
                if (this.BankTransactionId) {
                    await this.GetMatchesForTransaction();
                    if (!!this.row && Object.keys(this.row).length){
                        this.Main = this.row;
                    }
                    else {
                        await this.getBankTransaction(); // get the transaction if the row wasn't passed in from the list
                    }
                    if(this.Main.MatchedTransactions == 0) {
                        this.showMatchEdit = true;
                    }
                }
            }
            , GetMatchesForTransaction: async function (bankTransactionId) {
                this.loading = true;
                const datasets = await Promise.all([
                    this.liq_GetMatchesForTransaction({
                        TransactionId: this.BankTransactionId
                    }),
                    this.liq_GetRelatedMatches({
                        TransactionId: this.BankTransactionId
                    }),
                    this.GetAgreementBudgets()
                ]);
                this.matchesForTransactionList = datasets[0];
                this.relatedMatchesForTransactionList = datasets[1];
                this.loading = false;
            }
            , GetAgreementBudgets: async function(){
                var el = this;
                el.agreementBudgets = [];
                for (var i = 0, len = el.matchesForTransactionList.length; i < len; i++) {
                    if (el.matchesForTransactionList[i].EntityTypeDisplayShort == 'Agreement-Budget' && el.matchesForTransactionList[i].AgreementId > 0 ){
                        el.liq_GetAgreementBudget_Object({
                            AgreementId: el.matchesForTransactionList[i].AgreementId
                        }
                            , function (data) {
                                el.agreementBudgets.push(data);
                            }
                            , function (error) {
                                console.error(error);
                                el.agreementBudgets.push({});
                            });
                    }
                    else {
                        el.agreementBudgets.push({});
                    }
                }
            }
            , getNewMatch: function () {
                this.selectedMatch = this.liq_GetMatchesForTransaction_New();
            }
            , getBankTransaction: async function () {
                this.loading = true;
                this.Main = await this.liq_GetTransactionsV2_Object({
                        TransactionId: this.BankTransactionId
					}
					, true // async (optional)
                );
                this.loading = false;
            }
            , fetchInvestmentList: async function () {
                this.loading = true;
                this.investments = await this.asset_GetInvestmentList({
					OwnerId: this.Main.OwnerId
                    , IncludeChildrenOfOwner: this.includeChildrenOfOwner
                    , CurrentOnly: this.currentInvestmentsOnly
					}
					, true // async (optional)
				);
                this.loading = false;
                this.investmentDdlOptions = this.getDDLOptionsWithGroup(this.investments, 'Owner', 'OwnerId', 'NameOwnerAccountTypeIdBank', 'InvestmentId');
            }
            , viewReference: function (EntityType, EntityId) {
                var divBankTransactionList = this.searchParentsForElement(this.$el, 'divBankTransactionList');
                if (divBankTransactionList && divBankTransactionList != null) {
                    var $divBankTransactionList = $(divBankTransactionList);
                    if (EntityType == 'Agreement') {
                        const agreementId = EntityId.toString();
                        const route = this.$router.resolve({ name: 'AgreementEditView', params: { agreementId } }).catch(() => {});
                        openInNewTab(route.href);
                    }
                    else if (EntityType == 'Vendor') {
                        openInNewTab('/smartControl/Vendor/VendorEditView?params=' + JSON.stringify({
                            VendorId: EntityId
                        }));

                    }
                }
            }
            , openPreviewURL: function(previewUrl){
                window.open(previewUrl, '_blank');
            }
            , selectReference: function (EntityType, EntityId, event) {
                var $tr = $(event.currentTarget).closest('tr');
                if ($tr.hasClass('active-row')) {
                    $tr.removeClass('active-row')
                }
                else {
                    $tr.addClass('active-row')
                }
            }
            , fetchReferences: async function () {
                var OKToQuery = true;
                if (this.selectedMatch == undefined || this.selectedMatch.EntityType == undefined || this.selectedMatch.EntityType == null) {
                    OKToQuery = false;
                }
                if (OKToQuery) {//continue with checks
                    //const selectedEntityType = this.getSelectedArrayItem(this.entityTypes, this.selectedMatch.EntityType, 'Type');
                    const vendorRequired = (this.selectedEntityType && this.selectedEntityType.showVendor) ? true : false;
                    const agreementRequired = (this.selectedEntityType && this.selectedEntityType.showAgreement) ? true : false;
                    const investmentRequired = (this.selectedEntityType && this.selectedEntityType.showInvestment) ? true : false;
                    const accountRequired = (this.selectedEntityType && this.selectedEntityType.showAccount) ? true : false;
                    const noteRequired = (this.selectedEntityType && this.selectedEntityType.showNote) ? true : false;

                    //unlike the others, only not OkToQuery when we aren't looking for existing matches and there is no selectedInvestmentIds.  That's because we don't have a saved source of the possible array of selected investments
                    if (investmentRequired && !(this.selectedMatch.MatchedTransactionId) && (this.selectedInvestmentIds == undefined || this.selectedInvestmentIds.length == 0)) {
                        OKToQuery = false;
                    }
                    if (vendorRequired && (this.selectedMatch.VendorId == undefined || this.selectedMatch.VendorId == null)) {
                        OKToQuery = false;
                    }
                    if (noteRequired && (this.selectedMatch.NoteId == undefined || this.selectedMatch.NoteId == null)) {
                        OKToQuery = false;
                    }
                    if (agreementRequired && (this.selectedMatch.AgreementId == undefined || this.selectedMatch.AgreementId == null)) {
                        OKToQuery = false;
                    }
                    if (accountRequired) {
                        OKToQuery = false;
                        if (this.chartOfAccounts.length < 2) {//1 is the dummy; only do this once
                            this.fetchChartOfAccounts();
                            this.fetchDepartments();
                        }
                        //this.showAccount = true;
                    }
                }

                if (OKToQuery) {
                    this.loading = true;
                    this.references = await this.liq_GetReferenceEntity({
                        EntityType: this.selectedMatch.EntityType
                        , OwnerId: this.Main.OwnerId
                        , VendorId: this.selectedMatch.VendorId
                        , InvestmentIdList: (this.selectedInvestmentIds == '') ? null : this.selectedInvestmentIds.join(',')
                        , AgreementId: this.selectedMatch.AgreementId
                        , MatchedTransactionId: (this.selectedMatch.MatchedTransactionId) ? this.Main.TransactionId : null
                        , TransactionIdToMatch: this.Main.TransactionId
                        , NoteId: this.selectedMatch.NoteId
                        , IgnoreDateWindow: (this.selectedEntityType && this.selectedEntityType.showIgnoreDateWindow) ? this.ignoreDateWindow : undefined
                    });
                    this.loading = false;
                }
                else {
                    //clear references:
                    var a = this.references;
                    a.splice(0, a.length);
                }
            }
            , async closeBankTransactionEdit() {
                //get a fresh update from the db
                await this.getBankTransaction();  //updates this.Main
                this.$emit('closed-bank-transaction-edit', this.Main);
                //clear everything:
                this.$nextTick(function () {
                    this.Main = [];
                    this.vendors = [];
                    this.investments = [];
                    this.investmentDdlOptions = [];
                    this.selectedInvestmentIds = [];
                    this.agreements = [];
                    this.references = [];
                    this.departments = [];
                    this.notes = [];
                    this.external = { //pass this object to other controls for them to set values.  Must use an object and the values underneath it becasue objects are implicitly "by ref"
                        addedVendorId: -1
                        , addedInvestmentTransactionId: -1
                        , addedEntityId: -1
                    };
                    this.multipleSelection = [];
                    this.chartOfAccounts = [{
                        value: null
                        , label: null
                        , children: []
                    }];
                    this.selectedMatch = this.liq_GetMatchesForTransaction_New();
                    this.matchesForTransactionList = [];
                    this.agreementBudgets = [];
                });
            }
            , async fetchDepartments() {
                this.departments = await this.vam_GetDepartments({
                    ClientId: this.Main.ClientId
                });
            }
            , fetchVendorList: async function() {
                this.vendors = await this.vam_GetVendors({
                    ClientId: this.Main.ClientId
                });
                //removed Add New because you shouldn't be able to add a new vendor here; Vendors are tied to the invoice or agreement. Vendor selection is just to filter down the subsequent selectors.
            }
            , fetchAgreementList: async function () {
                this.agreements = await this.liq_GetAgreementsForInvoiceReference({
                    OwnerId: this.Main.OwnerId
                    , VendorId: this.selectedMatch.VendorId
                    , ExcludeBudgeted: true //budgeted agreements come through in reference drop down, not here
                });
                if (this.agreements.length == 1) {
                    await this.$nextTick(); //needs to be in nextTick so that the watcher will catch it and fire the fetchReferences.
                    this.selectedMatch.AgreementId = this.agreements[0].AgreementId;
                }
            }
            , getEntityTypes: function () {
                const ds = this.liq_GetEntityTypes();
                var ret = [];
                ds.forEach(function (entityType) {
                    var show = JSON.parse(entityType.ShowJSON) || {};
                    var et = {
                        Name: entityType.Name
                        , Type: entityType.EntityType
                        , DisplayName: entityType.DisplayName
                        , showInvestment: show.showInvestment || false
                        , showVendor: show.showVendor || false
                        , showAccount: show.showAccount || false
                        , showAgreement: show.showAgreement || false
                        , showNote: show.showNote || false
                        , showIgnoreDateWindow: show.showIgnoreDateWindow || false

                    }
                    ret.push(et);
                });
                return ret;
            }
            , fetchNotesForOwnerSelect: async function () {
                const notes = await this.liab_GetNotes({
                    BankAccountId: this.row.AccountId
                });
                this.notes = this.getDDLOptionsWithGroup(notes, 'NoteType', 'NoteTypeId', 'NoteName', 'NoteId');
                if (notes.length == 1) {
                    await this.$nextTick();
                    this.selectedMatch.NoteId = data[0].NoteId;
                }
            }
            , fetchChartOfAccounts: async function () {
                if (this.Main.ClientId != null) {
                    // var chartOfAccounts = this.vam_GetChartOfAccountsForTree(this.Main.ClientId, null, null);
                    var chartOfAccounts = await this.vam_GetChartOfAccountsForTree({
					    ClientId: this.Main.ClientId
					}
					, true // async (optional)
				);
                    this.chartOfAccounts = this.getCascaderHierarchy(chartOfAccounts, 'Id', 'Name', 'ParentId');
                }
                else {
                    this.chartOfAccounts = []
                }
            }
            //, editMatchesForTransactionListItem: function (selectedRow, event, column) {
            //    if (selectedRow && selectedRow != null) {
            //        console.log(selectedRow);
            //        this.selectedMatch = selectedRow;
            //        this.showMatchEdit = true;
            //    }
            //}
            , closeMatchEdit: function () {

            }
            , async handleConfirmMatchedRow(index, row) {
                const matchListIndex = this.matchesForTransactionList.indexOf(row);
                const rows = await this.liq_ConfirmTransactionMatch({
					TransactionId: row.TransactionId
					, EntityId: row.EntityId
					, EntityType: row.EntityType
					, ReviewForTax: row.ReviewForTax
					, DepartmentId: row.DepartmentId
					, MatchedTransactionId: row.matchedTransactionId
					, UpdateInvestmentTransactionAmount: this.setInvestmentAmountToBankAmount
					}
					, true // async (optional)
				);
                //that returns an array, but it can only be one row.

                this.$nextTick(function () {
                    this.matchesForTransactionList.splice(matchListIndex, 1, rows[0]);
                });
            }
            , confirmMatch: async function () {
                for (var i = 0, len = this.multipleSelection.length; i < len; i++) {
                    if (this.multipleSelection[i]){ 
                        await this.liq_ConfirmTransactionMatch({
                            TransactionId: this.BankTransactionId // transactionId
                            , EntityId: this.multipleSelection[i].EntityId // entityId
                            , EntityType: this.multipleSelection[i].EntityType || this.selectedMatch.EntityType // entityType
                            , ReviewForTax: this.selectedMatch.ReviewForTax // reviewForTax
                            , DepartmentId: (this.multipleSelection[i].EntityType || this.selectedMatch.EntityType == 'Account') ? this.selectedMatch.DepartmentId : null // departmentId
                            , UpdateInvestmentTransactionAmount: this.setInvestmentAmountToBankAmount
                            , Comment: this.selectedMatch.Comment
                        });
                        if (this.multipleSelection[i].EntityType == 'TaxModelConfig' && this.multipleSelection[i].TaxModelPaymentScheduleId != null){
                            await new SmartObject('ModelPaymentSchedule', this.multipleSelection[i].TaxModelPaymentScheduleId).deleteObject();
                            this.$notify.success('Tax Model Payment deleted.');

                        }
                    }
                }
                this.$notify.success('Match Confirmed');
                this.showMatchEdit = false;
                this.GetMatchesForTransaction();
            }
            ,async  handleDeleteMatch(index, row) {
                console.log(index, row);
                await this.$confirm('Delete Match?', 'Delete', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'danger'
                });
                //delete:
                this.loading = true;
                const so = new SmartObject('MatchedTransactions', row.MatchedTransactionId);
                await so.deleteObject();
                // const matchIndex = this.matchesForTransactionList.indexOf(row);
                // this.matchesForTransactionList.splice(matchIndex, 1);
                this.GetMatchesForTransaction(); // will refresh the list completely, and refresh the Related Matches
                this.Main.DisableMatches = false;
                this.Main.DisabledMatchReason = '';
                this.$notify.success('Match Deleted');
                this.$emit('matchDeleted', row.MatchedTransactionId);
                this.loading = false;
            }
            , getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Total';
                        return;
                    }
                    if (column.property=='ScheduledAmount' || column.property=='Amount') {
                        const values = data.map(item => Number(item[column.property]));
                        if (!values.every(value => isNaN(value))) {
                            sums[index] = 
                                this.$accounting.formatMoney(
                                    values.reduce((prev, curr) => {
                                            const value = Number(curr);
                                            if (!isNaN(value)) {
                                                return prev + curr;
                                            } else {
                                                return prev;
                                            }
                                        }, 0)
                                    , '$', 2);
                        } else {
                            sums[index] = 'N/A';
                        }
                    }
                });

                return sums;
            }
            , handleReviewForTaxChange(row) {
                var so = new SmartObject('MatchedTransactions', row.MatchedTransactionId);
                so.updateObject_lgcy({
                        ReviewForTax: row.ReviewForTax
                    });
                    this.$notify.success('Review for Tax Updated.');
            }
            , handleShowEditSearchTerm(index, row) {
                if (row.SearchTermId) {
                    this.searchTermId = row.SearchTermId;
                    this.showSetSearchTerm = true;
                }
            }
            , closeSetSearchTerm() {
                this.showSetSearchTerm = false;
                if (this.SearchTermId) {
                    this.SearchTermId = null;
                }
            }
            , handleSelectionChange(val) {
                this.multipleSelection = val;
            }
            , isSelectable(row, index) {
               return row.Selectable;
            }
            , openTransactionInNewTab(transactionId){
                // TODO: open in new tab
                console.log(transactionId);
            }
            , filterMethodCoA(item, query) {
                return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }

        }
    })

</script>
<style scoped>
    .el-table__footer-wrapper {
        font-weight: bold;
    }
    .el-row {
    margin-bottom: 10px;
  }
</style>
