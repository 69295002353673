
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import formatters from '@/utils/formatters';
    import Common from '@/utils/common';
    import * as WorkflowService from '../../services/DAL/workflowService';
    import WorkflowSelector from '@/components/form/WorkflowSelector.vue';
    import VendorSelector from '@/components/form/VendorSelector.vue';
    import ClientSelector from '@/components/form/ClientSelector.vue';
    import OwnerSelector from '@/components/form/OwnerSelector.vue';
    import ApproverSelectionEdit from '@/views/Workflow/ApproverSelectionEdit.vue';
    @Component({
        components: {
            WorkflowSelector,
            ClientSelector,
            VendorSelector,
            OwnerSelector,
            ApproverSelectionEdit
        }
    })
    export default class ApproverSelectionList extends Vue {

    //#region Private declarations for Services
    private _workflowService: WorkflowService.WorkflowService;
    private formatters: formatters;
    private common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop( {type: Number}) readonly workflowId?: number;
    @Prop( {type: Number}) readonly clientId?: number;
    @Prop( {type: Number}) readonly vendorId?: number;
    @Prop( {type: String}) readonly selectionEntityType?: string;
    @Prop( {type: Number}) readonly selectionEntityId?: number;
    @Prop( {type: Number}) readonly ownerId?: number;
    @Prop( {type: Boolean, default: false}) readonly hideSelection?: boolean;
    @Prop( {type: Boolean, default: false}) readonly disableEdit?: boolean;

    //#endregion Props

    //#region Data
    selections = [] as WorkflowService.ApproverSelection[];
    loading = false;
    showNewItem = true;
    
    workflowId_: number = this.workflowId || null;
    clientId_: number = this.clientId || null;
    vendorId_: number = this.vendorId || null;
    selectionEntityId_: number = this.selectionEntityId || null;
    selectionEntityType_: string = this.selectionEntityType || null;
    ownerId_: number = this.ownerId || null;
    selectedSelection = {} as WorkflowService.ApproverSelection;
    showSelectionEdit = false;
    //#endregion Data

    //#region Lifecycle
    created() {
        this._workflowService = new WorkflowService.WorkflowService();
        this.common = new Common();
        this.formatters = new formatters();
    }
    
    mounted(){
        if (this.vendorId_ || this.workflowId_ || this.clientId_ || (this.selectionEntityId_ && this.selectionEntityType_)){
            this.fetchSelections();
        }
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('workflowId_')
    onChange_workflowId(val: string | number, oldVal: string | number){
        if (val=='') this.workflowId_ = null;
    }
    @Watch('clientId_')
    onChange_clientId(val: string | number, oldVal: string | number){
        if (val=='') this.clientId_ = null;
    }
    //#endregion Watches

    //#region Computed
    //#endregion Computed

    //#region Methods
    async fetchSelections(){
        this.loading = true;
        const params = {} as WorkflowService.GetApproverSelectionParameters;
        params.VendorId = this.vendorId_;
        params.ClientId = this.clientId_;
        params.OwnerId = this.ownerId_;
        params.SelectionEntityId = this.selectionEntityId_;
        params.SelectionEntityType = this.selectionEntityType_;
        params.WorkflowId = this.workflowId_;
        this.selections = await this._workflowService.GetApproverSelection(params);
        this.loading = false;
    }
    //#endregion Methods
    async editApproverSelectionItem(row: WorkflowService.ApproverSelection){
        if (this.disableEdit) return;
        this.selectedSelection = row;
        this.showSelectionEdit = true;
    }
    addSelection(){
        this.selectedSelection = new WorkflowService.ApproverSelection();
        this.selectedSelection.VendorId = this.vendorId_;
        this.selectedSelection.ClientId = this.clientId_;
        this.selectedSelection.OwnerId = this.ownerId_;
        this.selectedSelection.WorkflowId = this.workflowId_;
        this.selectedSelection.SelectionEntityId = this.selectionEntityId_;
        this.selectedSelection.SelectionEntityType = this.selectionEntityType_;
        this.showSelectionEdit = true;
        
    }
}
