
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import ExcelImport from '@/components/ExcelImport.vue';
import formatters from '../../utils/formatters';

import * as LiqService from './../../services/DAL/liq_Service';
import * as UtilService from './../../services/DAL/utilService';
declare var getDateStringFromExcelDate: any;
@Component({
  components: {
    ExcelImport
  }
})
export default class BankBalanceImport extends Vue {
  //#region Private declarations for Services
  private _liqService: LiqService.LiqService;
  private _utilService: UtilService.UtilService;
  private formatters: formatters = new formatters();

  //#endregion Private declarations for Services

  //#region Props
  //#endregion Props

  //#region Data
  bankBalanceImportProcesses: LiqService.BankBalanceImportProcesses[] = [];
  filterBankBalanceImportPossibleIssues: string = '';
  bankBalanceImportPossibleIssues: LiqService.BankBalanceImportPossibleIssues[] = [];
  balancesLoading: boolean = false;
  showPotentialConflicts: boolean = false;
  showBalanceExcelImport: boolean = true;
  excludedBalances: LiqService.BankBalanceImportPossibleIssues[] = [];
  logId: number = null;
  statusMessage: string = '';
  fieldOptions: object[] = [
    { name: 'Bank accounts', field: 'AccountName' },
    { name: 'Account number', field: 'AccountNumber' },
    { name: 'Type', field: 'AccountType' },
    { name: 'Present balance', field: 'Balance' },
    { name: 'Available balance', field: 'Available' },
  ];
  logParameters: object = {
    database: 'visium',
    schema: 'liq',
    caller: 'ImportBankBalance',
    event: 'Import'
  };
  requiredColumns: string[] = ['Bank accounts'];

//#endregion Data

  //#endregion Computed
  get bankBalanceImportPossibleIssuesFiltered(): LiqService.BankBalanceImportPossibleIssues[] {
    const filter_ = (this.filterBankBalanceImportPossibleIssues || '').toLowerCase();
      return this.bankBalanceImportPossibleIssues.filter(entity => {
          return (
      (entity.Issue || '').toString().toLowerCase().indexOf(filter_) > -1 ||
      (entity.AccountName || '').toString().toLowerCase().indexOf(filter_) > -1 ||
      (entity.AccountNumber || '').toString().toLowerCase().indexOf(filter_) > -1 ||
      (entity.AccountType || '').toString().toLowerCase().indexOf(filter_) > -1 ||
      (entity.Balance || '').toString().toLowerCase().indexOf(filter_) > -1 ||
      (entity.Available || '').toString().toLowerCase().indexOf(filter_) > -1
          );
      });
  }

  //#endregion Computed

  //#region Lifecycle
  async created() {
    this._liqService = new LiqService.LiqService();
    this._utilService = new UtilService.UtilService();
    this.formatters = new formatters();
    this.getBankBalanceImportProcesses();
  }
  //#endregion Lifecycle
  async getBankBalanceImportProcesses() {
    this.balancesLoading = true;
    this.bankBalanceImportProcesses = await this._liqService.GetBankBalanceImportProcesses();
    this.balancesLoading = false;
  }
  async handleImport(result) {
    console.log(result);
    if (result.errors > 0) {
      this.$alert(
        `${result.errors} records resulted in an error when writing to the database.  Review the records with an empty Id in the last column displayed, then try again.`
      );
    } else if (result.successes > 0) {
      this.showBalanceExcelImport = false;
      this.statusMessage = 'Checking for possible duplicate balances';
      this.balancesLoading = true;
      const params = {} as LiqService.GetBankBalanceImportPossibleIssuesParameters;
      params.LogId = result.importLogId;
      this.bankBalanceImportPossibleIssues = await this._liqService.GetBankBalanceImportPossibleIssues(
        params
      );
      this.balancesLoading = false;
      this.logId = result.importLogId;
      if (this.bankBalanceImportPossibleIssues.length) {
        this.showPotentialConflicts = true;
      } else {
        this.statusMessage =
          'No Potential Issues Found.  Processing Import';
        await this.processBalancesImport();
      }
    }
  }
  selectableItem(row) {
    return row.Source == 'IMPORT';
  }
  handleExcludeSelectionChange(val) {
    this.excludedBalances = val;
  }
  async processBalancesImport() {
    this.balancesLoading = true;
    const params = {} as LiqService.ProcessBankBalanceImportParameters;
    params.LogId = this.logId;
    await this._liqService.ProcessBankBalanceImport(params);
    this.$notify.success('Balances Processed');
    this.showPotentialConflicts = false;
    this.statusMessage = 'Refreshing Process List';
    await this.getBankBalanceImportProcesses();
    this.statusMessage = '';
    this.balancesLoading = false;
    this.showBalanceExcelImport = true;
  }
  async cancelFromConflicts(){
    this.balancesLoading = true;
    this.statusMessage = 'Writing Cancel Log message';
    const params = {} as UtilService.LogEventMessageParameters;
    params.LogId = this.logId;
    params.Message = 'User canceled from Conflicts UI';
    await this._utilService.LogEventMessage(params);
    this.statusMessage = 'Closing Log Event';
    const completeParams = {} as UtilService.CompleteLogEventParameters;
    completeParams.LogId = this.logId;
    completeParams.Success = false;
    await this._utilService.CompleteLogEvent(completeParams);
    this.statusMessage = '';
    this.excludedBalances = [];
    this.bankBalanceImportPossibleIssues = [];
    this.balancesLoading = false;
    this.showPotentialConflicts = false;
    this.showBalanceExcelImport = true;
    await this.getBankBalanceImportProcesses();
  }
  selectEvent(row){
    this.$emit('event-clicked', row.LogId);
  }
  //#region Methods
}
