<template>
  <el-card 
    class="divCommentLogList"
    :shadow="(viewType === 'card' ? 'always' : 'never')" 
    :body-style="(viewType === 'card' ? '' : { padding: '0' })"
    :style="(viewType === 'card' ? '' : { border: 'none' })">
    <div v-if="!!title" slot="header" class="clearfix">
      <span>{{title}}</span>
    </div>
    <el-table 
      v-if="comments[0]"
      class="tblCommentLogList"
      ref="refCommentLogList"
      :data="comments"
      row-key="Id"
      :show-header="false"
      v-loading.body="loading">
      <el-table-column>
        <template slot-scope="scope">
          <div v-show="editCommentRow && scope.row.Id === editCommentRow.Id" class="divEditComment">
            <el-input :ref="`txtComment_${scope.row.Id}`" type="textarea" class="txtComment" v-model="editCommentNewValue" style="word-break: break-word"></el-input>
            <a href="javascript:;" @click="closeCommentEdit" style="font-size: 12px; margin-left: 11px;">Cancel</a>
          </div>
          <div v-show="!editCommentRow || scope.row.Id !== editCommentRow.Id" class="divReadComment" @click.stop="onClickComment(scope.row)" :CommentLogId="scope.row.Id">
            <el-row style="font-size: 11px; color: grey;" :title="scope.row.Id">
              {{scope.row.CreateUserFirstLast}} : {{scope.row.CreateDate | shortDateTime}}
              <a v-if="userCanEditAndDeleteRow(scope.row)" href="javascript:;" @click.stop="deleteComment(scope.row)" style="margin-left: 6px;">Delete</a>
              <el-tag size="mini" v-if="scope.row.TypeId > 0" :type="scope.row.CommentTypeColor" >{{scope.row.CommentTypeLabel}}</el-tag>
            </el-row>
            <el-row style="padding: 3px 4px; line-height: 17px;"><span v-html="scope.row.Comment.replace(/\r?\n/g, '<br />')"></span></el-row>
            <el-row v-show="scope.row.AdditionalInformation != null && scope.row.AdditionalInformation != ''">
              <a v-if="scope.row.AdditionalInformation && scope.row.AdditionalInformation.toLowerCase().startsWith('http')" :href="scope.row.AdditionalInformation" target="_blank">{{scope.row.AdditionalInformation}}</a>
              <span v-else>{{scope.row.AdditionalInformation}}</span>
            </el-row>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="showTextbox">
        <el-input
            type="textarea"
            v-model="newComment"
            placeholder="New note..."
            rows="2"
            @change="$emit('input', newComment)"
            @keypress.native="onKeyPress_newComment"
        >
        </el-input>
        <span id="lblNewCommentInstructions" v-if="!!entityType && entityId > 0">Ctrl+Enter to save note</span>
    </div>
  </el-card>
</template>

<script>
import Vue from 'vue';
import mixinSchema_system from './../DAL/mixinSchema_system';
import utility from './../VueCommon/Utility.js'; //has formatters
import findIndex from 'lodash/findIndex';

export default Vue.extend({
  name: 'CommentLogList',
  mixins: [mixinSchema_system, utility],
  props: {
    entityType: {
      required: false,
      type: String
    },
    entityId: {
      required: false,
      type: Number
    },
    securityLevel: {
      required: true,
      type: Number
    },
    viewType: {
      required: false,
      type: String
    },
    showTextbox: {
      required: false,
      type: Boolean,
      default: true
    },
    value: {
      required: false,
      type: String
    },
    title: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      comments: [],
      newComment: null,
      loading: false,
      editCommentRow: null,
      editCommentNewValue: null,
      currentSecurityUserId: null
    };
  },
  created: async function() {
    this.currentSecurityUserId = await getSecurityUserId();
      
    this.fetchCommentLog();

    if (this.value) {
        this.newComment = this.value;
    }

    $(window).off('click.CommentLogList').on('click.CommentLogList', async e => {
        if (this.editCommentRow !== null &&
            e.target &&
            // (!$(e.target).closest('.divReadComment').length ||
            //     tryParseInt($(e.target).closest('.divReadComment').attr('CommentLogId'), 0) !== this.editCommentRow.Id
            // ) &&
            !$(e.target).closest('.txtComment').length
        ) {
            if (this.editCommentNewValue !== this.editCommentRow.Comment) {
                var successful = await this.saveCommentEdit();
                if (successful) {
                    this.closeCommentEdit();
                }
            }
            else {
                this.closeCommentEdit();
            }
        }
    });
  },
  mounted: function() {
  },
  watch: {
    'value': function (val, oldVal) {
        this.newComment = this.value;
    }
    , 'entityId': function (val, oldVal) {
        this.fetchCommentLog();
    }
  },
  methods: {
    fetchCommentLog: async function() {
      if (!!this.entityType && this.entityId > 0) {
        this.loading = true;
        var el = this;
        
        this.system_GetComments(
            {
                EntityType: this.entityType,
                EntityId: this.entityId
            },
            function(data) {
                el.comments = data;
                el.loading = false;
            },
            function(error) {
                console.log(error);
                el.comments = [];
            }
        );
      }
    }

    , onKeyPress_newComment: async function(e) {
      if (e.which == 10) {
        if (!!this.entityType && this.entityId > 0) {
            // Ctrl+Enter, only for 'keypress'
            await new SmartObject('CommentLog').createObject({
                Comment: this.newComment,
                EntityType: this.entityType,
                EntityId: this.entityId
            });
            this.newComment = '';
            this.fetchCommentLog();
        }
        else {
            this.$notify.warning('This note will be added when the record is saved.');
        }
      }
    }

    , userCanEditAndDeleteRow: function(row) {
        return (this.securityLevel >= 80 || row.CreateUser === this.currentSecurityUserId) && !row.SystemGenerated;
    }

    , onClickComment: async function(row) {
        if (this.editCommentRow !== null && this.editCommentNewValue !== this.editCommentRow.Comment) {
            await this.saveCommentEdit();
        }

        if (this.userCanEditAndDeleteRow(row)) {
            this.openCommentEdit(row);
        }
        else {
            this.closeCommentEdit();
        }
    }

    , openCommentEdit: async function(row) {
        this.editCommentRow = row;
        this.editCommentNewValue = row.Comment;
        await this.$nextTick();
        this.$refs[`txtComment_${row.Id}`].focus();
    }

    , closeCommentEdit: function() {
        this.editCommentRow = null;
        this.editCommentNewValue = null;
    }

    , saveCommentEdit: async function() {
        try {
            const cl = new SmartObject('CommentLog', this.editCommentRow.Id);
            await cl.updateAttribute('Comment', this.editCommentNewValue);
            this.$notify.success('Note saved.');
            this.editCommentRow.Comment = this.editCommentNewValue;
            return true;
        }
        catch (err) {
            this.$notify.error('Something went wrong processing your request, please try again.');
            return false;
        }
    }

    , deleteComment: async function(row) {
        try {
            await this.$confirm(
                'Are you sure you want to delete this note?',
                'Delete',
                {
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );

            try {
                const cl = new SmartObject('CommentLog', row.Id);
                await cl.deleteObject();
                this.$notify.success('Note deleted.');
                
                const index = findIndex(this.comments, commentLog => {
                    return commentLog.Id === row.Id;
                });
                if (index > -1) {
                    this.comments.splice(index, 1);
                }
            }
            catch (err) {
                this.$notify.error('Something went wrong processing your request, please try again.');
            }
        }
        catch (err) {}
    }
  }
});
</script>

<style>
.divCommentLogList .tblCommentLogList thead > tr > th,
.divCommentLogList .tblCommentLogList tbody > tr > td {
  padding-bottom: 5px;
  padding-top: 5px;
}

.divCommentLogList #lblNewCommentInstructions {
  font-size: 11px;
  padding-left: 10px;
  color: grey;
  position: relative;
  top: -6px;
}
</style>
