
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import Common from '@/utils/common';
import CoASelector from '@/components/form/CoASelector.vue'
import * as AcctService from "@/services/DAL/acctService";
import ElementUI from 'element-ui';
import * as TransactionsService from '@/services/DAL/transactionsService';
import * as VAMService from '@/services/DAL/vamService';
declare var SmartObject: any;
import cloneDeep from 'lodash/cloneDeep';

@Component({
    components: {
        CoASelector,
    }
})
export default class GeneralLedgerEdit extends Vue {
    @Ref() frmGeneralLedgerEdit!: ElementUI.Form;

    //#region Private declarations for Services
    private _acctService: AcctService.AcctService;
    private common: Common;
    
    //#endregion Private declarations for Services

    //#region Props
    @Prop( {type: Object, required: true}) readonly generalLedger: AcctService.GeneralLedgerForTransaction;
    @Prop( {type: Boolean, default: false}) readonly hideButtons: boolean;
    @Prop({ type: Number }) readonly transactionHeaderId: number;
    @Prop({ type: Number }) readonly promissoryNoteTransactionId: number;

    //#endregion Props

    //#region Data
    generalLedger_ = {} as AcctService.GeneralLedgerForTransaction;
    originalGeneralLedger = {} as AcctService.GeneralLedgerForTransaction;
    loading = false;
    isNew = false;
   
    //#endregion Data

    //#region Lifecycle
    created() {
        this._acctService = new AcctService.AcctService();
        this.common = new Common();
        if (this.generalLedger && Object.keys(this.generalLedger).length){
            this.generalLedger_ = this.generalLedger;
            if (this.generalLedger_.Id == 0) {
                this.generalLedger_.Id = null;
                this.isNew = true;
            }
            this.originalGeneralLedger = cloneDeep(this.generalLedger_); // copy to restore if closed w/o save.
        }
    }
    async mounted() {
    }
    //#endregion Lifecycle
    
    //#region Watches
  @Watch('generalLedger_.DC')
  async dcChanged(val: string, oldVal: string) {
    if (this.generalLedger_.DC == 'C'){
        this.generalLedger_.Debit = null;
        this.generalLedger_.Credit = this.generalLedger_.Value;
    }
    else if (this.generalLedger_.DC == 'D'){
        this.generalLedger_.Credit = null;
        this.generalLedger_.Debit = this.generalLedger_.Value;
    }
}

    //#endregion Watches

    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
        key => (this as any).veeFields[key].dirty
        );
    }
    get isFormValid(): boolean{
        return !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
    }

    //#region Methods
    coASet(coA: VAMService.ChartOfAccountsForTree){
        this.generalLedger_.AccountNamePath = coA.NamePath;
    }
    async saveItem(){
        if (this.isFormValid) {
            this.generalLedger_.ConsiderationDescription = this.generalLedger_.CalculationDescription; // ConsiderationDescription is calculated, but for Manual, it is the CalculationDescription. That needs to be passed back.
            this.dcChanged(this.generalLedger_.DC, this.generalLedger_.DC); //update Debit/Credit
            if (this.isNew){
                const id = await new SmartObject('GeneralLedger').createObject(
                    this.generalLedger_
                );
                this.generalLedger_.Id = id;
                this.$notify.success('New General Ledger Item Added');
            }
            else{
                await new SmartObject('GeneralLedger', this.generalLedger_.Id).updateObject(
                    this.generalLedger_
                );
                this.$notify.success('General Ledger Item Updated');
            }
            this.$emit('saved', this.generalLedger_);
        }
    }
    async closeItem(){
        Object.assign(this.generalLedger_, this.originalGeneralLedger);
        this.$notify.warning('Closed JV Edit without saving.');
        this.$emit('closed');
    }
    async cancelItem() {
        if (this.isNew) {
            try {
                await this.$confirm(
                    'Cancelling before saving will delete this new item. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );
                this.$emit('cancelled', this.generalLedger_);
                this.generalLedger_ = {} as AcctService.GeneralLedgerForTransaction;
            } catch {}
        }
    }
        
    async deleteItem() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this General Ledger item. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                await this.doDeleteGeneralLedger();
            } catch {}
        }
    }

    async doDeleteGeneralLedger() {
        try {
            await new SmartObject(
                'GeneralLedger',
                this.generalLedger_.Id
            ).deleteObject();

            this.$notify.success('General Ledger item deleted.');

            if ((this.generalLedger_.SourceEntityType == 'InvestmentTransactionHeader' || this.generalLedger_.SourceEntityType == 'PromissoryNoteTransaction')
                 && this.generalLedger_.SourceEntityId){
                await new SmartObject('CommentLog').createObject({
                    EntityType: this.generalLedger_.SourceEntityType,
                    EntityId: this.generalLedger_.SourceEntityId,
                    Comment: `General Ledger ${this.generalLedger_.Id} Deleted.  ${this.$accounting.formatMoney(this.generalLedger_.Value)}`,
                    SystemGenerated: true
                });

                this.$notify.success('Transaction Comment added.');
            }

            this.$emit('deleted', this.generalLedger_.Id);
            this.generalLedger_ = {} as AcctService.GeneralLedgerForTransaction;
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }
    //#endregion Methods
}
