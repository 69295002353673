
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop, VModel } from "vue-property-decorator";
import * as LiqService from "../../services/DAL/liq_Service";
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
    inheritAttrs: false
})
export default class BankAccountSelector extends Vue {
    //#region Private declarations for Services
    private _liqService: LiqService.LiqService;
    //#endregion Private declarations for Services

    //#region Props
    @VModel({ type: Number }) selectedValue!: number;
    // @Prop() value: number;
    @Prop() // 9/9/19: no longer required.  Will return all accounts if not specified.
    ownerId: number;
    @Prop()
    manualAccountsOnly: boolean;
    @Prop({
        default: false
    })
    requireOwnerId: boolean;
    @Prop({
        default: 'DisplayName'
    })
    displayColumn: string;
    @Prop() // 9/9/19: no longer required.  Will return all accounts if not specified.
    clientId: number;
    @Prop() // 9/9/19: no longer required.  Will return all accounts if not specified.
    accountType: string;
    @Prop({ default: false }) readonly readOnly: boolean;
    @Prop({ type: Number }) readonly bankId: number;
    //#endregion Props

    //#region Data
    accounts = [] as LiqService.BankAccountList[];
    selectedBankAccount = {} as LiqService.BankAccountList;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();
    }

    async mounted() {
        this.fetchAccounts();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch("value")
    onChange_value(val: number, oldVal: number) {
        if (!this.accounts.length){
            this.fetchAccounts();
        }
        else {
            this.getSelectedBankAccount();
        }
    }
    @Watch("ownerId")
    onChange_ownerId(val: number, oldVal: number) {
        this.fetchAccounts();
    }
    @Watch("clientId")
    onChange_clientId(val: number, oldVal: number) {
        this.fetchAccounts();
    }
    @Watch("accountType")
    onChange_accountType(val: string, oldVal: string) {
        this.fetchAccounts();
    }
    //#endregion Watches

    //#region Methods
    async getSelectedBankAccount(){
        if (this.accounts.length && this.selectedValue > 0) {
            this.selectedBankAccount = this.accounts.find( account => account.BankAccountId == this.selectedValue);
        }
        else {
            this.selectedBankAccount = new LiqService.BankAccountList();
        }

    }
    async fetchAccounts() {
        this.$setSystemStatus('Getting Bank Accounts');
        this.accounts = [];
        if (!this.requireOwnerId || !!this.ownerId) {
            const params = {} as LiqService.GetBankAccountListParameters;
            params.OwnerId = this.ownerId;
            params.ClientId = this.clientId;
            params.AccountType = this.accountType;
            params.ManualAccountsOnly = this.manualAccountsOnly;
            params.BankId = this.bankId;
            this.accounts = await this._liqService.GetBankAccountList(params);
            this.$emit('options-fetched', this.accounts);
            if (this.accounts.length && Object.keys(this.accounts) && Object.keys(this.accounts[0]).some(e => e === this.displayColumn)) { // make sure the specified column exists
                this.$setSystemStatus(`Sorting by ${this.displayColumn}`);
                this.accounts.sort((a,b) => (a[this.displayColumn] > b[this.displayColumn]) ? 1 : ((b[this.displayColumn] > a[this.displayColumn]) ? -1 : 0));
            }
            if (!this.selectedValue && this.accounts.length == 1){
                this.selectedValue == this.accounts[0].BankAccountId;
            }
            else if (!this.selectedValue && this.accounts.length > 1 && !!this.ownerId) { // only auto-select when the owner is specified
                const firstPrimary = this.accounts.find(account => {
                    return account.PrimaryAccountForOwner; //first one that is true
                });
                if (!!firstPrimary && Object.keys(firstPrimary).length){
                    this.selectedValue = firstPrimary.BankAccountId;
                }
            }
            this.getSelectedBankAccount();
            this.$setSystemStatus('');
        }
    }
    onClear() {
        this.selectedValue = null;
        this.$emit('input', null);
    }
    //#endregion Methods
}
