<template>
    <agreement-edit-view :agreement.sync="agreement"></agreement-edit-view>
</template>

<script>
    import Vue from 'vue';
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import AgreementEditView from './AgreementEditView.vue'

    export default Vue.extend({
        name: 'AgreementEditViewWrapper'
        , mixins: [mixinSchema_liq]
         , props: {
            params: {
                type: Object
            }
        }
        , components: {
            'agreement-edit-view': AgreementEditView
        }
        , data: function () {
            return {
                agreement: {}
            }
        }
        , created: function () {
            if(this.params && this.params.AgreementId) {
                this.fetchAgreement(this.params.AgreementId);
            }
        }
        , watch: {
            'params': function (val, oldVal) {
                if (val && val.AgreementId) {
                    this.fetchAgreement(val.AgreementId);
                }
            }
            , 'agreement': function (val, oldVal) {
                this.$emit('update:agreement', this.agreement);
            }
        }
        , methods: {
             fetchAgreement: async function(agreementId) {
                 var el = this;
                 await this.liq_GetAgreements_Object({
                     AgreementId: agreementId
                 }
                     , function (data) {
                         el.agreement = data;
                     }
                 );
}
        }
    })
</script>