
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { OHLCImportService } from './../../services/OHLCImportService';
import EventLogList from '@/views/Event/EventLogList.vue';

@Component({
    components: {
        EventLogList
    }
})
export default class ExchangeRateImport extends Vue {
    //#region Data
    private _OHLCImportService: OHLCImportService;

    showEventList: boolean = true;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._OHLCImportService = new OHLCImportService();
    }

    async mounted() {}
    //#endregion Lifecycle

    //#region Methods
    async importOHLC() {

        try {
            const response = await this._OHLCImportService.importData();
            if (response.status == 200) {
                this.$notify.success({
                    title: null,
                    message: "Data import queued.",
                    duration: 0
                });
                this.showEventList = false;
                await this.$nextTick();
                this.showEventList = true;
            } else {
                this.$notify.error({
                    title: 'Error',
                    message: response.message,
                    duration: 0
                });
            }
        } catch (err) {
            console.error(err);
        }
    }
    async openReport(){
        var url = '/fs/smartControl/ReportViewer/ReportViewer?params=' + encodeURIComponent(JSON.stringify({
                        HideParameters: true,
                        ParametersAreURLEncoded: false,
                        Report: 'PublicEquityClosingPrices',
                        Parameters: {}
                    }));
                    var win = window.open(url, '_blank');
                    win.focus();
    }
    //#endregion Methods
}
