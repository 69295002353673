
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as SystemService from '../../services/DAL/systemService';

declare var $: any;

@Component({
    name: 'sidebar-item'
})
export default class SidebarItem extends Vue {
    //#region Data
    private systemService: SystemService.SystemService;

    @Prop() menuEntry: SystemService.MenuEntriesHierarchy;
    @Prop() menuEntriesHierarchy: SystemService.MenuEntriesHierarchy[];
    @Prop() index: string;

    menuEntries: SystemService.MenuEntriesHierarchy[];
    numEntriesToLoad = null;
    numEntriesLoaded = null;
    //#endregion Data

    //#region Lifecycle
    created() {
        this.systemService = new SystemService.SystemService();

        if (
            this.menuEntry.EntityType === 'MenuCategory' &&
            this.menuEntry.EntityId > 0
        ) {
            this.fetchMenuEntries();
        }
    }

    mounted() {
        // since this is a menu item, it's done loading once the component is mounted
        if (this.menuEntry.EntityType === 'MenuItem') {
            this.$emit('loaded');
        }
    }
    //#endregion Lifecycle

    //#region Methods
    onLoadEntry() {
        // since this is a menu category, it's done loading once all of its children (and their children, recursively) are done loading
        if (++this.numEntriesLoaded === this.numEntriesToLoad) {
            this.$emit('loaded');
        }
    }

    async fetchMenuEntries() {
        const vm = this;
        const menuEntries_ = this.menuEntriesHierarchy.filter(
            item => item.ParentId === vm.menuEntry.EntityId
        );
        this.numEntriesToLoad = menuEntries_.length;
        this.numEntriesLoaded = 0;
        this.menuEntries = menuEntries_;
        if (this.numEntriesToLoad === 0) {
            this.$emit('loaded');
        }
    }

    async handleClick(menuEntry: any) {
        if (menuEntry.URL) {
            window.location.href = menuEntry.URL;
        } else if (menuEntry.VuePath && menuEntry.VuePath !== '/') {
            const newRouterPath = '/' + menuEntry.VuePath;
            
            if (newRouterPath !== this.$route.path) {
                this.$router.push(newRouterPath);
            }
        } else if (menuEntry.SmartControlPath) {
            const path = menuEntry.SmartControlPath;
            const controlName = menuEntry.SmartControlName;
            const newRouterPath = `/smartControl/${path}/${controlName}`;

            const query: any = {};

            if (menuEntry.Parameters) {
                query.params = menuEntry.Parameters;
            }
            if (menuEntry.LoadType && menuEntry.LoadType !== 'Default') {
                query.loadType = menuEntry.LoadType;

                if (menuEntry.Name) {
                    query.title = menuEntry.Name;
                }
            }

            if (newRouterPath !== this.$route.path) {
                this.$router.push({
                    path: newRouterPath,
                    query: query
                });
            }

            setTimeout(() => {
                $('.breadcrumb-item:last')
                    .find('span')
                    .text(menuEntry.Name);
            });
        }
    }
    //#endregion Methods
}
