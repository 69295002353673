// TS API for Schema balanceSheet
// Auto-generated 05/14/24 13:20


declare var smartData: any;
// output classes:
export class AssetAllocation {
    SuperCategory: string=undefined;
    LogId: number=undefined;
    SuperCategoryId: number=undefined;
    FMV: number = undefined;
}
export class Categories {
    Id: number=undefined;
    SuperCategory: string=undefined;
    Category: string=undefined;
    SubCategory: string=undefined;
    Client: string=undefined;
    ClientId: number=undefined;
    ClientParents: number=undefined;
    GeneralAssets: number=undefined;
    Deletable: boolean=undefined;
    DisplayOrder: number=undefined;
    Asset: boolean=undefined;
    liability: boolean=undefined;
    label: string=undefined;
    key: number = undefined;
}
export class CategoriesV2 {
    Id: number=undefined;
    ClientId: number=undefined;
    ClientName: string=undefined;
    SuperCategoryId: number=undefined;
    SuperCategoryName: string=undefined;
    Name: string=undefined;
    HideSubtotal: boolean=undefined;
    IsTaxCategory: boolean = undefined;
}
export class HistoryLogId {
    LogId: number=undefined;
    Display: string=undefined;
    RunTimeWithId: string = undefined;
}
export class HistoryPeriod {
    AsOf: string=undefined;
    AsOfString: string = undefined;
}
export class LogIds {
    Id: number=undefined;
    LogId: number=undefined;
    RunTime: string=undefined;
    RunTimeWithId: string=undefined;
    EventId: number=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    CompleteDate: string=undefined;
    Milliseconds: number=undefined;
    Database: string=undefined;
    Schema: string=undefined;
    Caller: string=undefined;
    Event: string=undefined;
    Success: boolean=undefined;
    Machine: string=undefined;
    Seconds: number=undefined;
    Minutes: number=undefined;
    Hours: number=undefined;
    CreateDateET: string=undefined;
    CompleteDateET: string=undefined;
    RowNum: number=undefined;
    BalanceSheetSentToClient: boolean=undefined;
    BalanceSheetSentToClientOn: string=undefined;
    BalanceSheetSentToClientBy: string=undefined;
    BalanceSheetSentToClientKeyId: number=undefined;
    SPID: number = undefined;
}
export class NetWorthBarChart {
    AsOf: string=undefined;
    AsOfString: string=undefined;
    NetWorth: number = undefined;
}
export class ReportDrillDetail {
    Type: string=undefined;
    TypeDisplay: string=undefined;
    SuperCategory: string=undefined;
    Category: string=undefined;
    SubCategory: string=undefined;
    DisplayBalanceSheetCategoryId: number=undefined;
    Name: string=undefined;
    DetailDisplay: string=undefined;
    Owner: string=undefined;
    AmountInvested: number=undefined;
    CostBasis: number=undefined;
    CostPerShare: number=undefined;
    CashReturned: number=undefined;
    FMV: number=undefined;
    FMVPerShare: number=undefined;
    FMVUnfactored: number=undefined;
    EstTotalReturn: number=undefined;
    EstROIC: string=undefined;
    UnfundedCommitment: number=undefined;
    AccruedCarry: number=undefined;
    ConsolidateDetail: boolean=undefined;
    ConsolidateBalanceSheet: boolean=undefined;
    OwnerId: number=undefined;
    ClientId: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    LogId: number=undefined;
    SuperDisplayOrder: number=undefined;
    DisplayOrder: number=undefined;
    AccountType: string=undefined;
    CurrentFMV: number=undefined;
    DiscountFactor: number=undefined;
    TaxBase: number=undefined;
    BaseValue: number=undefined;
    NetShares: number=undefined;
    DiscountSource: string=undefined;
    Configuration: string=undefined;
    ConfigurationId: number=undefined;
    AccruedCarryInvestmentId: number=undefined;
    IsAccuredCarryInvestment: boolean=undefined;
    AccruedCarryOnBalanceSheet: boolean=undefined;
    Liquid: boolean=undefined;
    Debt: boolean=undefined;
    BalanceSheetCategoryIncluded: number=undefined;
    OwnerIncluded: number=undefined;
    EntityIncluded: number=undefined;
    IncludeAccruedCarryColumn: boolean=undefined;
    AsOf: string=undefined;
    TaxRate: number=undefined;
    Discount: number=undefined;
    CurrentLiability: boolean=undefined;
    Ticker: string=undefined;
    InvestmentParentId: number=undefined;
    SuperCategoryId: number=undefined;
    CategoryId: number=undefined;
    SubCategoryId: number=undefined;
    IsConverted: boolean = undefined;
}
export class SubCategories {
    Id: number=undefined;
    SuperCategory: string=undefined;
    Category: string=undefined;
    SubCategory: string=undefined;
    Client: string=undefined;
    ClientId: number=undefined;
    ClientParents: number=undefined;
    GeneralAssets: number=undefined;
    Deletable: boolean=undefined;
    DisplayOrder: number=undefined;
    Asset: boolean=undefined;
    liability: boolean=undefined;
    label: string=undefined;
    key: number = undefined;
}
export class SuperCategories {
    Id: number=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    Name: string=undefined;
    Asset: boolean=undefined;
    Liability: boolean=undefined;
    DisplayOrder: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Categories: number=undefined;
    Deletable: boolean = undefined;
}

// Parameter interfaces:

export interface GetAssetAllocationParameters {
    BalanceSheetLogId: number;
    ClientId: number;
    ConfigurationId: number;
}

export interface GetCategoriesParameters {
    ClientId: number;
    Asset: boolean;
    Liability: boolean;
}

export interface GetCategoriesV2Parameters {
    ClientId: number;
    SuperCategoryId: number;
    CategoryId: number;
}

export interface GetHistoryLogIdParameters {
    AsOf: string;
}

export interface GetLogIdsParameters {
    Database: string;
    Schema: string;
    Caller: string;
    Event: string;
    KeyName: string;
    KeyInt: number;
    KeyVarChar: string;
    KeyDate: string;
    CompletedOnly: boolean;
    LogId: number;
    IncludeIncompleteRegardlessOfMilliseconds: boolean;
    MinMilliseconds: number;
    MaxRows: number;
    EventId: number;
    SuccessOnly: boolean;
}

export interface GetNetWorthBarChartParameters {
    LogId: string;
    ConfigurationId: number;
}

export interface GetReportDrillDetailParameters {
    LogId: number;
    ComparisonLogId: number;
    ConfigurationId: number;
    Type: string;
    OwnerId: number;
    SuperCategoryId: number;
    CategoryId: number;
    SubCategoryId: number;
    Name: string;
    SuperCategory: string;
    IsAccruedCarryBalanceSheetItem: boolean;
}

export interface GetSubCategoriesParameters {
    ClientId: number;
    Asset: boolean;
    Liability: boolean;
    SubCategoryId: number;
}

export interface GetSuperCategoriesParameters {
    ClientId: number;
}

export interface ProcessHistoryParameters {
    AsOf: string;
    IgnoreCheck: boolean;
    LogId: number;
    Context: string;
    UseProforma: boolean;
}

export class BalanceSheetService {

  GetAssetAllocation (params: GetAssetAllocationParameters): Promise<AssetAllocation[]> {
    return smartData({
        storedProcName: 'BalanceSheet.GetAssetAllocation',
        params: params
      });
    }

  GetCategories (params: GetCategoriesParameters): Promise<Categories[]> {
    return smartData({
        storedProcName: 'BalanceSheet.GetCategories',
        params: params
      });
    }

  GetCategoriesV2 (params: GetCategoriesV2Parameters): Promise<CategoriesV2[]> {
    return smartData({
        storedProcName: 'BalanceSheet.GetCategoriesV2',
        params: params
      });
    }

  GetHistoryLogId (params: GetHistoryLogIdParameters): Promise<HistoryLogId[]> {
    return smartData({
        storedProcName: 'BalanceSheet.GetHistoryLogId',
        params: params
      });
    }

  GetHistoryPeriod (): Promise<HistoryPeriod[]> {
    return smartData({
        storedProcName: 'BalanceSheet.GetHistoryPeriod',
        params: {}
      });
    }

  GetLogIds (params: GetLogIdsParameters): Promise<LogIds[]> {
    return smartData({
        storedProcName: 'BalanceSheet.GetLogIds',
        params: params
      });
    }

  GetNetWorthBarChart (params: GetNetWorthBarChartParameters): Promise<NetWorthBarChart[]> {
    return smartData({
        storedProcName: 'BalanceSheet.GetNetWorthBarChart',
        params: params
      });
    }

  GetReportDrillDetail (params: GetReportDrillDetailParameters): Promise<ReportDrillDetail[]> {
    return smartData({
        storedProcName: 'BalanceSheet.GetReportDrillDetail',
        params: params
      });
    }

  GetSubCategories (params: GetSubCategoriesParameters): Promise<SubCategories[]> {
    return smartData({
        storedProcName: 'BalanceSheet.GetSubCategories',
        params: params
      });
    }

  GetSuperCategories (params: GetSuperCategoriesParameters): Promise<SuperCategories[]> {
    return smartData({
        storedProcName: 'BalanceSheet.GetSuperCategories',
        params: params
      });
    }

  ProcessHistory (params: ProcessHistoryParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'balancesheet.ProcessHistory',
        params: params
      });
    }
}
