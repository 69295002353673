
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as VisiumService from '../../services/DAL/visiumService';

@Component({
    inheritAttrs: false
})
export default class BankSelector extends Vue {
    //#region Private declarations for Services
    private _visiumService: VisiumService.VisiumService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: number;
    @Prop()
    clientId: number;
    //#endregion Props

    //#region Data
    banks = [] as VisiumService.Banks[];
    selectedValue: number = null;
    //#endregion Data

    //#region Computed
    //#endregion Computed

   //#region Lifecycle
    async created() {
        this._visiumService = new VisiumService.VisiumService();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchBanks();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
    }
    @Watch('clientId')
    onChange_clientId(val: number, oldVal: number) {
        this.fetchBanks();
    }
    //#endregion Watches

    //#region Methods
    async fetchBanks() {
        this.banks = [];
        const parms = {} as VisiumService.GetBanksParameters;
        parms.ClientId = this.clientId;
        this.banks = await this._visiumService.GetBanks(parms);
        this.$emit('options-fetched', this.banks);
        if (this.banks.length === 1){
            this.selectedValue = this.banks[0].Id;
            this.$emit('single-option-available', this.selectedValue);
            if (this.selectedValue !== this.value) {
                this.$emit('input', this.selectedValue);
            }
        }
    }
    //#endregion Methods
}
