
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import formatters from "@/utils/formatters";
import Common from "@/utils/common";
import * as TransactionsService from "@/services/DAL/transactionsService";
import ConsiderationList from '@/views/InvestmentTransaction/Consideration/ConsiderationList.vue';
import InvestmentParentSelector from "@/components/form/InvestmentParentSelector.vue";
import InvestmentSelector from "@/components/form/InvestmentSelector.vue";
import OwnerSelector from "@/components/form/OwnerSelector.vue";
import cloneDeep from "lodash/cloneDeep";
import { Field, FieldBag } from "node_modules/vee-validate/types";
import * as AssetService  from "@/services/DAL/assetService";
import CommentLogList from '@/components/other/tsCommentLogList.vue'
import VueMarkdown from '@adapttive/vue-markdown'
import debounce from 'lodash/debounce';
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;
declare var SmartObject: any;

interface balanced {
  isBalanced: boolean
      , message: string
      , source: number
      , result: number
      , netDistribution: number
      , showValues: boolean
}

class distributionItems extends TransactionsService.DistributionItems{
  Comment?: string= undefined;
}
@Component({
  components: {
    InvestmentSelector,
    InvestmentParentSelector,
    OwnerSelector,
    ConsiderationList,
    CommentLogList,
    //@ts-ignore
    VueMarkdown
  },
})
export default class Distribution extends Vue {
  @Ref() refConsiderationListResult!: ConsiderationList;
  //#region Private declarations for Services
  private _transactionsService: TransactionsService.TransactionsService;
  private _assetService: AssetService.AssetService;
  public formatters: formatters;
  private common: Common;
  //#endregion Private declarations for Services

  //#region Props
  @Prop({ type: Object }) header: TransactionsService.Header;
  @Prop({ type: Number }) transactionHeaderId: number;
  //#endregion Props

  //#region Data
  loading = false;
  securityLevel_: number = null;

  investmentParent = {} as AssetService.InvestmentParentList;
  distributionInvestments = [] as TransactionsService.DistributionInvestments[];
  distributionItems = [] as distributionItems[];
  isNew = false;
  header_ = {} as TransactionsService.Header;
  cachedHeader = {} as TransactionsService.Header;
  creating = true;
  showHeaderNotes = false;
  getDistributionInvestments: any = null;
  showDistributionTable= true;
  //#endregion Data

  //#region Lifecycle
  async created() {
    this.formatters = new formatters();
    this.common = new Common();
    this._transactionsService = new TransactionsService.TransactionsService();
    this._assetService = new AssetService.AssetService();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
      0
    );
    this.getDistributionInvestments = debounce(this.getDistributionInvestmentsDebounced, 500);
  }
  async mounted() {
    if (!this.header || Object.keys(this.header).length == 0) {
      await this.getTransactionHeader();
    } else {
      this.header_ = cloneDeep(this.header);
      this.cachedHeader = cloneDeep(this.header);
    }
    if (!this.header_.BaseInvestmentParentId && this.header_.BaseInvestmentId){
      await this.getInvestmentParentForNewTransaction();
    }
    await this.$nextTick();

    await this.getDistributionInvestments();
    
    this.creating = false;
    this.$emit('loaded', this.balanced.isBalanced);
  }
//#endregion Lifecycle

  //#region Watches
  @Watch("header", {deep: true})
  async headerChanged(
    val: TransactionsService.Header,
    oldVal: TransactionsService.Header
  ) {
    this.header_ = cloneDeep(this.header);
    
  }
  
  @Watch("header_", {deep: true})
  async header_Changed(val: TransactionsService.Header, oldVal: TransactionsService.Header) {
    this.getDistributionInvestments();
  }
  @Watch("distributionItems", {deep: true})
  async distributionItemsChanged(val: TransactionsService.DistributionItems[], oldVal: TransactionsService.DistributionItems[]) {
    val.forEach(item => {
            item.GPAmount = (item.AllocationType == 'Allocation') ? item.GPRatio * item.Amount : null;
            item.LPAmount = (item.AllocationType == 'Allocation') ? item.LPRatio * item.Amount : null;
            item.ACAmount = (item.AllocationType == 'Accrued Carry') ? item.Amount : null;
        }
    )
    await this.$nextTick();
  }
  //#endregion Watches

  //#region Computed
  get headerIsDirty(): boolean {
    if (this.header_ && this.cachedHeader &&
        (
          this.header_.BaseOwnerId != this.cachedHeader.BaseOwnerId
        ||
          this.header_.BaseInvestmentParentId != this.cachedHeader.BaseInvestmentParentId
        ||
          this.header_.BaseInvestmentId != this.cachedHeader.BaseInvestmentId
        )
    ){
      return true;
    }
    else return false;
  }
  get distributionItemsFields(): Object{
    // return this.$validator.fields.items.filter(item => 
    //   item.name.startsWith('distributionItem.')
    // );
    return Object.keys(((this as any).veeFields as Array<Field>))
      .filter(key => key.startsWith('distributionItem.'))
      .reduce((obj, key) => {
      obj[key] = ((this as any).veeFields as Array<Field>)[key];
      return obj;
    }, {});
 }

  get isFormValid(): boolean {
    const formValid = !Object.keys((this as any).veeFields).some(
      (key) => (this as any).veeFields[key].invalid
    );
    if (!formValid) return formValid;

    return true;
  }

  get isDistributionItemsFormDirty(): boolean {
      const fields =  Object.keys(this.distributionItemsFields).some(
          key => this.distributionItemsFields[key].dirty
      );
      if (fields) return fields;
      //if either of the GL or LP commitments have changed, treat it as dirty so that the Save button is active
      if (this.lPInvestment && this.lPInvestment.SavedCommitmentDifferent != null) return true;
      if (this.gPInvestment && this.gPInvestment.SavedCommitmentDifferent != null) return true;
      else return false;
  }
  get isDistributionItemsFormValid(): boolean{
      return !(Object.keys(this.distributionItemsFields).some(
          key => this.distributionItemsFields[key].invalid
      ));
  }

  get lpLabel(): string{
    if (this.distributionItems && this.distributionItems.length){
      return this.distributionItems[0].LPLabel;
    }
  }

  get lPInvestment(): TransactionsService.DistributionInvestments{
      if (this.distributionInvestments && this.distributionInvestments.length == 1 && (this.distributionInvestments[0].Allocation == null || this.distributionInvestments[0].Allocation == 1)){ // single investment, not allocated over family, treat as LP
        return this.distributionInvestments[0];
      }
      else if (this.distributionInvestments && this.distributionInvestments.length){
          return this.common.getSelectedArrayItem(
                            this.distributionInvestments,
                            'Limited Partner',
                            'AccountType'
                        );
      }
  }
  get gPInvestment(): TransactionsService.DistributionInvestments{
      if (this.distributionInvestments && this.distributionInvestments.length){
          return this.common.getSelectedArrayItem(
                            this.distributionInvestments,
                            'General Partner',
                            'AccountType'
                        );
      }
  }
  get aCInvestment(): TransactionsService.DistributionInvestments{
      if (this.distributionInvestments && this.distributionInvestments.length){
          return this.common.getSelectedArrayItem(
                            this.distributionInvestments,
                            'Accrued Carry',
                            'AccountType'
                        );
      }
  }

  get distributionTotal(): number {
    if (this.distributionItems && this.distributionItems.length){
        return this.distributionItems.reduce(function(a, b) {
            return a + b.Amount;
        }, 0)
    }
    else return 0;
  }

  get balanced(): balanced{
    const result: number = this.refConsiderationListResult ? this.refConsiderationListResult.totalConsideration : 0;
    const source: number = this.distributionTotal;
    const netDistribution: number = this.header_.NetAmount;
    const isBalanced: boolean = this.$accounting.toFixed(result, 2) == this.$accounting.toFixed(source, 2)  && this.$accounting.toFixed(source, 2) == this.$accounting.toFixed(netDistribution, 2);
    const message = this.creating ? '' : isBalanced ? 'Balanced' : 'Not Balanced';
    return {
      isBalanced
      , message
      , source
      , result
      , netDistribution
      , showValues: !isBalanced && !(result ==0 && source == 0)
    }
  }


  //#endregion Computed

  //#region Methods
  async updateHeader(){
    if (this.headerIsDirty){
      await new SmartObject('InvestmentTransactionHeader', this.header_.Id).updateObject(
                    this.header_
                );
      this.$notify.success('Transaction Header Updated');
      this.cachedHeader = cloneDeep(this.header_);
    }
  }
  async getInvestmentParentForNewTransaction(){
    if (this.header_.BaseInvestmentId && !this.header_.BaseInvestmentParentId){
      const params = {} as AssetService.GetInvestmentListParameters;
      params.InvestmentId = this.header_.BaseInvestmentId;
      const investments = await this._assetService.GetInvestmentList(params);
      if (investments && investments.length == 1){
        this.header_.BaseInvestmentParentId = investments[0].InvestmentParentId;
        if (this.header_ && !this.header_.BaseOwnerId){
          this.header_.BaseOwnerId = investments[0].OwnerId;
        }
      }
    }
  }
  async getDistInvestments(updateCommitment: boolean = false){
      const params = {} as TransactionsService.GetDistributionInvestmentsParameters;
      params.TransactionHeaderId = this.header_.Id;
      params.Update = updateCommitment;
      this.distributionInvestments = await this._transactionsService.GetDistributionInvestments(
        params
      );

  }
  async getDistributionInvestmentsDebounced() {
      if (this.header_ && this.header_.Id && this.header_.BaseInvestmentParentId) {
        this.distributionInvestments = [];
        if (this.investmentParent && !this.investmentParent.FundFamilyRequired && !this.header_.BaseInvestmentId){
          return;
        }
        this.loading = true;
        await this.updateHeader(); // only updates if dirty
        await this.getDistInvestments();
        this.isNew = this.distributionInvestments.length == 0; // this only means it got rows back, which means that it found or inserted a DistributionHeader. isNew only controls the readOnly for Owner and IP.
        if (this.distributionInvestments.length) {
          await this.getDistributionItems();
        }

        this.$validator.reset();
        this.loading = false;
      }
      
      // after above step, either investmentParentId and ownerId were already set, or they got set.  If not, then it's new.
      if (this.header_ && this.header_.Id && !(this.header_.BaseInvestmentParentId && this.header_.BaseOwnerId)) {
        this.isNew = true;
      }
  }
  async getDistributionItems() {
    const params = {} as TransactionsService.GetDistributionItemsParameters;
    params.TransactionHeaderId = this.header_.Id;
    this.distributionItems = await this._transactionsService.GetDistributionItems(
      params
    );
    this.$emit('loaded', this.balanced.isBalanced);
  }

  async getTransactionHeader() {
    this.header_ = {} as TransactionsService.Header;
    if (this.transactionHeaderId) {
      const params = {} as TransactionsService.GetHeaderParameters;
      params.HeaderId = this.transactionHeaderId;
      this.header_ = (await this._transactionsService.GetHeader(params))[0];
      this.cachedHeader = cloneDeep(this.header_);
    }
  }

  clearItem() {}
  async deleteItem() {
    // not yet implemented
  }
  async saveItem() {
    const sos = this.distributionItems.map(async (item) => { 
      if (item.Id && item.Amount != item.PreviousAmount){
        await new SmartObject('DistributionItem', item.Id).updateObject(
            item
        );
        item.PreviousAmount = item.Amount;
        const field = this.$validator.fields.find({name: `distributionItem.${item.DistributionType}`});
        if (field){
          field.reset();
        }
        this.$notify.success(`${item.DistributionType} updated`);
        return; 
      }
      else if (item.Id == null && item.Amount){
        const newId = await new SmartObject('DistributionItem').createObject(item)
        item.Id = newId;
        item.PreviousAmount = item.Amount;
        const field = this.$validator.fields.find({name: `distributionItem.${item.DistributionType}`});
        if (field){
          field.reset();
        }
        if (item.Comment && item.Comment.length > 1){
          await new SmartObject('CommentLog').createObject({
            EntityType: 'DistributionItem',
            EntityId: item.Id,
            Comment: item.Comment
          });
          item.Comments = 1;
        }
        return; 
      }
    });
    await Promise.all(sos);
    if ((this.gPInvestment && this.gPInvestment.SavedCommitmentDifferent != null) || (this.lPInvestment && this.lPInvestment.SavedCommitmentDifferent != null)) {
      await this.getDistInvestments(true); // update Dist Investments and refresh
      this.$notify.success('Commitments updated');
      this.showDistributionTable = false;
      await this.$nextTick();
      this.showDistributionTable = true; //refresh table so that the header is updated reflecting non-discrepant values
    }
    this.$emit('saved', this.balanced.isBalanced)
  }
  getSummary(param) {
      const columnList = [1,2,3,4];
      const totalLabel = 'Total';
      const totalLabelIndex = 1;
      const formats = new Map();
      formats.set(1, 'currency');
      formats.set(2, 'currency');
      formats.set(3, 'currency');
      formats.set(4, 'currency');
      const result = this.common.getSummaryArray(param, columnList, totalLabel, totalLabelIndex, formats);
      return result;
  }

  blurred(row: TransactionsService.DistributionItems){
    if (row.Amount != row.PreviousAmount){
      console.log('difference', row);
    }

  }
  tableRowClassName({ row, rowIndex }) {
      if (row.Amount != row.PreviousAmount) {
          return 'dirty-row';
      }
      return '';
  }
  commentAdded(row: TransactionsService.DistributionItems){
    row.Comments++;
  }
  commentDeleted(row: TransactionsService.DistributionItems){
    row.Comments--;
  }
  async deleteDistributionItem(scope){
    const row: TransactionsService.DistributionItems = scope.row;
        try {
            await this.$confirm(
                'Are you sure you want to delete this item?  Continue?',
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );

            try {
                if (row.Id){
                    await new SmartObject(
                        'DistributionItem',
                        row.Id
                    ).deleteObject();
                    this.$notify.success('Distribution Item deleted.');
                }

            } catch (err) {
                console.log(err);
                this.$notify.error('Something went wrong processing your request, please try again.');
            }
        this.distributionItems.splice(scope.$index, 1); // remove it from the list
      } catch {} //canceled
    }

  copyDistributionItem(scope){
    const row: TransactionsService.DistributionItems = scope.row;
    const distributionItem = new TransactionsService.DistributionItems();
    distributionItem.TransactionHeaderId = row.TransactionHeaderId;
    distributionItem.LPRatio = row.LPRatio;
    distributionItem.GPRatio = row.GPRatio;
    distributionItem.LPInvestmentId = row.LPInvestmentId;
    distributionItem.GPInvestmentId = row.GPInvestmentId;
    distributionItem.ACInvestmentId = row.ACInvestmentId;
    distributionItem.LPAmount = row.LPAmount;
    distributionItem.GPAmount = row.GPAmount;
    distributionItem.ACAmount = row.ACAmount;
    distributionItem.AllocationType = row.AllocationType;
    distributionItem.Comments = 0;
    distributionItem.DistributionTypeId = row.DistributionTypeId;
    distributionItem.IsCopy = true;
    distributionItem.DistributionType = row.DistributionType;
    distributionItem.Amount = 0;
    distributionItem.LPLabel = row.LPLabel;
    this.distributionItems.splice(scope.$index+1, 0, distributionItem);

    
  }
  investmentParentSet( investmentParent: AssetService.InvestmentParentList){
    this.investmentParent = investmentParent;
    if (this.investmentParent && this.investmentParent.Id){
      this.header_.BaseInvestmentParentId = this.investmentParent.Id;
      this.getDistributionInvestments();
    }
  }

//#endregion Methods
}

