
import Vue from 'vue';
import Component from 'vue-class-component';
import Common from '@/utils/common';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as VamService from '../../services/DAL/vamService';
import ElementUI from 'element-ui';
declare var $: any;

@Component({
    inheritAttrs: false
})
export default class CoASelector extends Vue {
    $refs: {
        ddlSelector: ElementUI.Select
    }
//#region Private declarations for Services
    private _vamService: VamService.VamService;
    private _common: Common;
//#endregion Private declarations for Services

//#region Props
    @Prop()
    value: Array<number>;
    @Prop({
        type: Number
    })
    clientId: number;
//#endregion Props

//#region Data
    chartOfAccounts = [] as VamService.ChartOfAccountsForTree[];
    chartOfAccountsForCascader = [] as any;
    selectedValue: Array<number> = null;
    loadingOptions: boolean = false;
//#endregion Data

    //#region Computed
    //#endregion Computed

   //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();
        this._common = new Common();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchOptions();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onValueChange(val: Array<number>, oldVal: Array<number>) {
        this.selectedValue = val;
        // const account: VamService.ChartOfAccountsForTree = this._common.getSelectedArrayItem(this.chartOfAccounts, this.value.toString(), 'Id');
        // this.selectedValue = JSON.parse(account.IdArray);
    }
    @Watch('clientId')
    onClientIdChange(val: number, oldVal: number) {
        this.fetchOptions();
    }

    //#endregion Watches


    //#region Methods
    async fetchOptions(){
        this.loadingOptions = true;
        const params = {} as VamService.GetChartOfAccountsForTreeParameters;
        params.ClientId = this.clientId;
        this.chartOfAccounts = await this._vamService.GetChartOfAccountsForTree(params);
        this.chartOfAccountsForCascader = this._common.getCascaderHierarchy(this.chartOfAccounts, 'Id', 'Name', 'ParentId');
        this.$emit('options-fetched', this.chartOfAccounts);
        this.loadingOptions = false;
    }
    filterMethodCoA(item, query) {
        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
    }
    handleChange(selectedArray){
        if (selectedArray){
            this.$emit('input', selectedArray[selectedArray.length - 1])
            const coa: VamService.ChartOfAccountsForTree = this._common.getSelectedArrayItem(this.chartOfAccounts, selectedArray[selectedArray.length - 1], 'Id');
            if (coa) this.$emit('value-set', coa);
        }
    }

    //#endregion Methods
}
