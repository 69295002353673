
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import HeaderEl from '@/components/layout/HeaderEl.vue';
import {
    Sidebar,
    Breadcrumb
} from '../components/layout';

declare var $: any;

@Component({
    components: {
        HeaderEl: HeaderEl,
        Sidebar: Sidebar,
        breadcrumb: Breadcrumb
    }
})
export default class ElFull extends Vue {
    //#region Data
    loading = false;
    sidebarCollapsed: boolean = this.$getBootstrapSize() === 'xl' ? false : true;
    
    @Getter('systemStatusMessage') systemStatusMessage;
    
    get name() {
        return this.$route.name;
    }
    get list() {
        return this.$route.matched;
    }
    //#endregion Data

    //#region Lifecycle
    mounted() {
        this.$nextTick(() => {
            $(window).on('resize', () => {
                this.sidebarCollapsed =
                    this.$getBootstrapSize() === 'xl' ? false : true;
                $('body').toggleClass(
                    'page-sidebar-closed',
                    this.sidebarCollapsed
                );
            });

            $(window).on('click', e => {
                // if screen is 'md' or smaller, collapse menu when user clicks on an
                //  enabled menu item or anywhere other than the menu or menu toggler
                if (
                    this.$getBootstrapSize() !== 'xl' &&
                    e.target &&
                    !$(e.target).closest('.navbar-toggler').length &&
                    (!$(e.target).closest('.el-menu').length ||
                        $(e.target).closest('.el-menu-item:not(.is-disabled)')
                            .length)
                ) {
                    this.sidebarCollapsed = true;
                }
            });
        });
    }
    //#endregion Lifecycle
    closed(){
    if (window.history.length > 2)
        this.$router.go(-1);
    else
        this.$router.push('/welcome');
    }
}
