
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as UtilService from './../../services/DAL/utilService';
import formatters from '../../utils/formatters';
import Common from '../../utils/common';
import moment from 'moment';

declare var $: any;
declare var SmartObject: any;
declare function tryParseInt(input: number, deflt: number): number;
declare function getStoredSecurityLevel(Id: number): number;
declare var process: any;

@Component
export default class EventLogDetailView extends Vue {
    //#region Private declarations for Services
    private _utilService: UtilService.UtilService;
    public formatters: formatters = new formatters();
    public common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() eventLog: any;
    @Prop() viewType: string;
    @Prop() logId: number;
    //#endregion Props

    //#region Data
    eventLog_: any = {};
    eventKeys: UtilService.EventKeys[] = [];
    eventMessages: UtilService.EventMessages[] = [];
    isLoadingeventLog_: boolean = false;
    isLoading_eventKeys: boolean = false;
    isLoading_eventMessages: boolean = false;
    showEventKeys: boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._utilService = new UtilService.UtilService();
        this.formatters = new formatters();
        this.common = new Common();
        if (this.eventLog && Object.keys(this.eventLog).length){
            this.eventLog_ = this.eventLog;
        }
        else {
            await this.fetchEventLog();
        }
        this.fetchEventKeys();
        this.fetchEventMessages();
    }
    mounted() {}
    //#endregion Lifecycle

    //#region Watches
    //#endregion Watches

    //#region Methods
    async fetchEventLog(){
        this.isLoadingeventLog_ = true;
        const params = {} as UtilService.GetLogEventParameters;
        params.LogId = this.logId;
        const eventLogs = await this._utilService.GetLogEvent(params);
        if (eventLogs && eventLogs.length == 1){
            this.eventLog_ = eventLogs[0];
        }
        this.isLoadingeventLog_ = false;
    }
    async fetchEventKeys() {
        this.isLoading_eventKeys = true;
        this.eventKeys = [];
        const getEventKeysParameters = {} as UtilService.GetEventKeysParameters;
        getEventKeysParameters.LogId = this.eventLog_.Id;
        this.eventKeys = await this._utilService.GetEventKeys(
            getEventKeysParameters
        );
        this.isLoading_eventKeys = false;
        this.showEventKeys = this.eventKeys.length > 0;
    }

    async fetchEventMessages() {
        this.isLoading_eventMessages = true;
        this.eventMessages = [];
        const getEventMessagesParameters = {} as UtilService.GetEventMessagesParameters;
        getEventMessagesParameters.LogId = this.eventLog_.Id;
        this.eventMessages = await this._utilService.GetEventMessages(
            getEventMessagesParameters
        );
        this.isLoading_eventMessages = false;
    }

    transformUser(user: string): string {
        if (!user) return '';
        return user.indexOf('Login_Visium') > -1 ? 'Auto' : user;
    }

    formatterDateOrDateTime(row, column) {
        if (row[column.property]) {
            //if it has any value at all...
            if (
                row[column.property] instanceof Date &&
                moment(row[column.property], [
                    'MM/DD/YYYY hh:mm:ss a',
                    'MM/DD/YYYY'
                ]).isValid()
            ) {
                if (
                    moment(
                        row[column.property],
                        ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']
                    ).format('H:mm:ss') == '0:00:00'
                ) {
                    //time = midnight, so it's really just a date
                    return moment(row[column.property], [
                        'MM/DD/YYYY hh:mm:ss a',
                        'MM/DD/YYYY'
                    ]).format('MM/DD/YY');
                } else {
                    return moment(row[column.property], [
                        'MM/DD/YYYY hh:mm:ss a',
                        'MM/DD/YYYY'
                    ]).format('MM/DD/YY H:mm');
                }
            } else {
                return row[column.property];
            }
        } else {
            return '';
        }
    }
    //#endregion Methods
}
