
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Ref } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import * as BalanceSheetService from '../../services/DAL/balanceSheetService';
import * as VamService from '../../services/DAL/vamService';
import ClientSelector from '@/components/form/ClientSelector.vue';
import OwnerSelector from '@/components/form/OwnerSelector.vue';
import BankAccountSelector from '@/components/form/BankAccountSelector.vue'
import InvestmentParentSelector from '@/components/form/InvestmentParentSelector.vue';
import ElementUI from 'element-ui';
import { MessageBoxInputData } from 'element-ui/types/message-box';

declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: number, deflt: number): number;
declare var SmartObject: any;

@Component({
    components: {
        ClientSelector,
        OwnerSelector,
        BankAccountSelector,
        InvestmentParentSelector,
    }
})
export default class InvestmentEdit extends Vue {
    @Ref() readonly frmInvestmentEdit!: ElementUI.Form;

    //#region Data
    private _assetService: AssetService.AssetService;
    private _vamService: VamService.VamService;
    private _balanceSheetService: BalanceSheetService.BalanceSheetService;

    @Prop({type: Object}) readonly investment: AssetService.InvestmentList;
    @Prop({type: Number}) readonly investmentId!: number;
    @Prop({type: String}) readonly viewType: string;

    investment_ = {} as AssetService.InvestmentList;
    investmentId_: number = this.investmentId;
    investmentParent: AssetService.InvestmentParentList;
    securityLevel: number = 0;
    overrideDefaultBankAccount = false;
    overrideParentBalanceSheetCategory = false;
    owner = {} as VamService.OwnersV2;
    investmentParentBalanceSheetCategory = {} as AssetService.InvestmentParentBalanceSheetCategories;
    balanceSheetSubCategories = [];
    partnerTypes = [] as AssetService.AccountTypes[];
    investmentSources = [] as AssetService.InvestmentSources[];
    investmentType = {} as AssetService.InvestmentTypes;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this._vamService = new VamService.VamService();
        this._balanceSheetService = new BalanceSheetService.BalanceSheetService();

        this.securityLevel = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );

        if (this.investment && Object.keys(this.investment).length && this.investment.Id){
            this.investmentId_ = this.investment.Id;
        }
        else if (this.investmentId) {
            this.investmentId_ = this.investmentId;
        }

        if (this.investment && Object.keys(this.investment).length) { 
            this.investment_ = this.investment;
        }
        else if (this.investmentId_) {
            await this.fetchInvestment();
        }
        else {
            this.investment_ = new AssetService.InvestmentList();
            this.$nullifyObjectProps(this.investment_);
        }
        this.getBalanceSheetSubCategories();
        this.getPartnerTypes();
        this.getInvestmentSources();
    }

    async mounted() {
        await this.initializeInvestment();
    }
    //#endregion Lifecycle

    //#region Watches

    @Watch('investment_.ClientId')
    onChange_ClientId(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.getDefaultBalanceSheetCategory();
            this.getBalanceSheetSubCategories();
            this.getInvestmentSources();
            this.getPartnerTypes();
        }
    }
    @Watch('investment_.ParentId')
    onChange_ParentId(val: number, oldVal: number) {
        this.investment_.InvestmentParentId = val;
        if (oldVal !== undefined) {
            this.getDefaultBalanceSheetCategory();
        }
    }


    //#endregion Watches

    //#region Computed
    get isNew(): boolean {
        return (
            !this.investment_ ||
            !Object.keys(this.investment_).length ||
            (this.investment_.Id || 0) <= 0
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    get isFormValid(): boolean{
        return !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
    }

    //#endregion Computed

    //#region Methods

    async initializeInvestment(){
        if (this.investment_.IsBankAccountIdNotDefault){
            this.overrideDefaultBankAccount = true;
        }
        else {
            this.overrideDefaultBankAccount = false;
            this.investment_.BankAccountId = null; // need to clear it out because the view has filled it from the default
        }
        
        if (this.investment_.BalanceSheetCategoryIdOverride) this.overrideParentBalanceSheetCategory = true;
        await this.getDefaultBalanceSheetCategory();
        await this.getInvestmentType();
        //if (this.frmInvestmentEdit) this.frmInvestmentEdit.resetFields();
    }

    async fetchInvestment(doNotClear: boolean = false) {
        if (!doNotClear){ // don't clear existing when only refreshing after update.  Otherwise, it blows up the validators
            this.investment_ = {} as AssetService.InvestmentList;
        }
        const params = {} as AssetService.GetInvestmentListParameters;
        params.InvestmentId = this.investmentId_;
        const investments = await this._assetService.GetInvestmentList(params);
        if (investments.length) {
            this.investment_ = investments[0];
            await this.initializeInvestment();
        }
    }    
    
    async saveForm() {
        this.frmInvestmentEdit.validate(async valid => {
            if (valid) {
                let message: string;
                const isNew = this.isNew;

                try {
                    if (!isNew){
                        await new SmartObject('Investment', this.investment_.Id).updateObject(
                            this.investment_
                        );
                    }
                    else {
                        delete this.investment_.Name; // name should not be set.
                        const newId = await new SmartObject('Investment').createObject(
                            this.investment_
                        );
                        this.investmentId_ = newId;
                    }
                    await this.fetchInvestment(true); //get it fresh from the DB so all fields are updated

                    this.$notify.success(`Investment ${this.isNew ? 'added' : 'saved'}.`);
                    this.$emit('update:investment', this.investment_);
                    if (isNew){
                        this.$emit('create', this.investment_);    
                        this.$emit('close');
                    }
                    else {
                        this.$emit('save', this.investment_);
                    }
                }
                catch (err) {
                    console.error(err);
                    this.$notify.error('Something went wrong processing your request, please try again.');
                    return;
                }
            }
        });
    }

    async deleteInvestment() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this investment. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    await new SmartObject(
                        'Investment',
                        this.investment_.Id
                    ).deleteObject();
                    this.$notify.success('Investment deleted.');
                    this.$emit('delete', this.investment_.Id);
                    this.$emit('close');
                    
                } catch (e) {
                    this.$notify.error(e.message);
                }
            } catch {}
        }
    }
  async investmentParentSet( investmentParent: AssetService.InvestmentParentList){
    this.investmentParent = investmentParent;
    if (this.investmentParent && this.investmentParent.Id && this.isNew){
      this.investment_.InvestmentTypeId = this.investmentParent.InvestmentTypeId;
      this.investment_.InvestmentType = this.investmentParent.InvestmentType;
      this.investment_.Name = this.investmentParent.Name; // for display.  clear it before saving.
    }
    this.getDefaultBalanceSheetCategory();
    this.getInvestmentType();
  }
  async getDefaultBalanceSheetCategory(){
    this.investmentParentBalanceSheetCategory = {} as AssetService.InvestmentParentBalanceSheetCategories
    if (this.investment_ && this.investment_.InvestmentParentId && this.investment_.ClientId){
        const params = {} as AssetService.GetInvestmentParentBalanceSheetCategoriesParameters;
        params.InvestmentParentId = this.investment_.InvestmentParentId;
        params.ClientId = this.investment_.ClientId;
        const investmentParentBalanceSheetCategories = await this._assetService.GetInvestmentParentBalanceSheetCategories(params);
        if (investmentParentBalanceSheetCategories && investmentParentBalanceSheetCategories.length == 1){
            this.investmentParentBalanceSheetCategory = investmentParentBalanceSheetCategories[0];
        }
    }

  }
  async ownerSet(owner: VamService.OwnersList){
    this.owner = {} as VamService.OwnersV2;
    if (owner && owner.OwnerId){
        const params = {} as VamService.GetOwnersV2Parameters;
        params.OwnerId = owner.OwnerId;
        const owners = await this._vamService.GetOwnersV2(params);
        if (owners && owners.length == 1){
            this.owner = owners[0];
            if (this.isNew){
                this.investment_.BankAccountNameNumberMasked = this.owner.DefaultInvestmentAccountNameDisplay || this.owner.BankAccountNameDisplay;
            }
        }
    }
  }
async getBalanceSheetSubCategories() {
    const params = {} as BalanceSheetService.GetSubCategoriesParameters;
    params.ClientId = this.investment_.ClientId;
    params.Asset = true;
    params.Liability = true;
    const ds = await this._balanceSheetService.GetSubCategories(params);
    const options = [];
    let group = '';
    const groupByLabel = 'Category';
    const groupById = 'Category';
    const itemLabel = 'SubCategory';
    const itemId = 'Id';
    let groupItem = {} as any;
    ds.forEach(function (row) {
        if (group != row[groupByLabel]) {
            groupItem = {
                label: row[groupByLabel]
                , groupId: row[groupById]+row[itemId]
                , options: []
            };
            options.push(groupItem);
            group = row[groupByLabel];
        }
        var item = {
            value: row[itemId]
            , label: row[itemLabel]
        };
        groupItem.options.push(item);
    });
    this.balanceSheetSubCategories = options;
    }

    async getPartnerTypes(){
        this.partnerTypes = [] as AssetService.AccountTypes[];
        const params = {} as AssetService.GetAccountTypesParameters;
        params.ClientId = this.investment_.ClientId;
        params.InvestmentTypeId = this.investment_.InvestmentTypeId;
        this.partnerTypes = await this._assetService.GetAccountTypes(params);
    }
    async getInvestmentType(){
        this.investmentType = {} as AssetService.InvestmentTypes;
        if (this.investment_.InvestmentTypeId){
            const params = {} as AssetService.GetInvestmentTypesParameters;
            params.InvestmentTypeId = this.investment_.InvestmentTypeId;
            const t = await this._assetService.GetInvestmentTypes(params);
            if (t && t.length == 1){
                this.investmentType = t[0];
            }
        }
    }
    async getInvestmentSources(){
        this.investmentSources = [] as AssetService.InvestmentSources[];
        const params = {} as AssetService.GetInvestmentSourcesParameters;
        params.ClientId = this.investment_.ClientId;
        this.investmentSources = await this._assetService.GetInvestmentSources(params);
    }
    async addInvestmentSource(){
        try {
            const conf = await this.$prompt('Enter a new Investment Source for this Client', 'Investment Source', { 
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    inputErrorMessage: 'Investment Source is required and cannot be long than 100 characters.',
                    inputValidator: function(input){if (!!input && input.length < 101) return true; else return false;}
                    });
            try {
                const newSource = (conf as MessageBoxInputData).value;
                if (newSource && this.investment_.ClientId){
                    const investmentSource = {} as AssetService.InvestmentSources;
                    investmentSource.ClientId = this.investment_.ClientId;
                    investmentSource.Name = newSource;
                    const newSourceId = await new SmartObject('InvestmentSource').createObject(
                                    investmentSource
                                );
                    investmentSource.Id = newSourceId; 
                    if (newSourceId){
                        this.investmentSources.push(investmentSource);
                        this.investment_.SourceId = newSourceId;
                    }
                }
            }
                catch (err) {
                    try {
                        const errJson = JSON.parse(err.responseText);
                        if (errJson.ExceptionMessage.toLowerCase().indexOf('cannot insert duplicate key') > -1) {
                            this.$notify.error('There is already an investment source for this client with that name. Please select it from the list, or add a different name.');
                        }
                        else {
                            this.$notify.error('Something went wrong processing your request, please try again.');
                        }
                    }
                    catch {
                        this.$notify.error('Something went wrong processing your request, please try again.');
                    }
                    return;
                }
        }
        catch{
            console.log('New Source Canceled');
        }
    }
    //#endregion Methods
}
