
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import ExcelImport from '@/components/ExcelImport.vue';
import formatters from '../../utils/formatters';
import ClientSelector from '@/components/form/ClientSelector.vue';
import BankAccountSelector from '@/components/form/BankAccountSelector.vue'

import * as LiqService from './../../services/DAL/liq_Service';
import * as UtilService from './../../services/DAL/utilService';
declare var getDateStringFromExcelDate: any;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function getStoredSecurityLevel(Id: number): number;

@Component({
  components: {
    ExcelImport
    , ClientSelector
    , BankAccountSelector
  }
})
export default class AmexTransactionImport extends Vue {
  //#region Private declarations for Services
  private _liqService: LiqService.LiqService;
  private _utilService: UtilService.UtilService;
  private formatters: formatters = new formatters();

  //#endregion Private declarations for Services

  //#region Props
  //#endregion Props

  //#region Data
  amexCreditCardTransactionImportProcesses: LiqService.AmexTransactionImportProcesses[] = [];
  filterAmexTransactionImportPossibleDuplicates: string = '';
  amexCreditCardTransactionImportPossibleDuplicates: LiqService.AmexTransactionImportPossibleDuplicates[] = [];
  transactionsLoading: boolean = false;
  showPotentialConflicts: boolean = false;
  showTransactionExcelImport: boolean = true;
  excludedTransactions: LiqService.AmexTransactionImportPossibleDuplicates[] = [];
  logId: number = null;
  statusMessage: string = '';
  // fieldOptions: object[] = [
  //   ,
  //   { name: 'Transaction Date', field: 'TransactionDate' },
  //   { name: 'Post Date', field: 'PostDate' },
  // ];
  logParameters: object = {
    database: 'visium',
    schema: 'liq',
    caller: 'ImportAmexTransaction',
    event: 'Import'
  };
  requiredColumns: string[] = ['Date'];
  securityLevel = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageBankAccounts), 0);
  bankAccountId: number = null;
  clientId: number = null;

  //#endregion Data

  //#endregion Computed
  get additionalColumns(): object {
    return {
      BankAccountId: this.bankAccountId
    };
  }
  
  get amexCreditCardTransactionImportPossibleDuplicatesFiltered(): LiqService.AmexTransactionImportPossibleDuplicates[] {
    const filter_ = (
      this.filterAmexTransactionImportPossibleDuplicates || ''
    ).toLowerCase();
    return this.amexCreditCardTransactionImportPossibleDuplicates.filter(entity => {
      return (
        (entity.Source || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.Id || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.BankAccountId || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.Amount || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.Description || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1
      );
    });
  }
  get transactionsIdsToExclude(): number[]{
    return this.excludedTransactions.map(o => o.Id)
  }

  //#endregion Computed

  //#region Lifecycle
  async created() {
    this._liqService = new LiqService.LiqService();
    this._utilService = new UtilService.UtilService();
    this.formatters = new formatters();
    this.getAmexTransactionImportProcesses();
  }
  //#endregion Lifecycle
  async getAmexTransactionImportProcesses() {
    this.transactionsLoading = true;
    this.amexCreditCardTransactionImportProcesses = await this._liqService.GetAmexTransactionImportProcesses();
    this.transactionsLoading = false;
  }
  async handleImport(result) {
    console.log(result);
    if (result.errors > 0) {
      this.$alert(
        `${result.errors} records resulted in an error when writing to the database.  Review the records with an empty Id in the last column displayed, then try again.`
      );
    } else if (result.successes > 0) {
      this.showTransactionExcelImport = false;
      this.statusMessage = 'Checking for possible duplicate transactions';
      this.transactionsLoading = true;
      const params = {} as LiqService.GetAmexTransactionImportPossibleDuplicatesParameters;
      params.LogId = result.importLogId;
      this.amexCreditCardTransactionImportPossibleDuplicates = await this._liqService.GetAmexTransactionImportPossibleDuplicates(
        params
      );
      this.transactionsLoading = false;
      this.logId = result.importLogId;
      if (this.amexCreditCardTransactionImportPossibleDuplicates.length) {
        this.showPotentialConflicts = true;
      } else {
        this.statusMessage =
          'No Potential Duplicates Found.  Processing Import';
        await this.processTransactionsImport();
      }
    }
  }
  selectableItem(row) {
    return row.Source == 'IMPORT';
  }
  handleExcludeSelectionChange(val) {
    this.excludedTransactions = val;
  }
  async processTransactionsImport() {
    if (!this.transactionsLoading){ // prevent running twice
      this.transactionsLoading = true;
      await this.$nextTick();
      const params = {} as LiqService.ProcessAmexTransactionImportParameters;
      params.LogId = this.logId;
      params.ExcludeList = this.transactionsIdsToExclude.toString();
      await this._liqService.ProcessAmexTransactionImport(params);
      this.$notify.success('Transactions Processed');
      this.showPotentialConflicts = false;
      this.statusMessage = 'Refreshing Process List';
      await this.getAmexTransactionImportProcesses();
      this.statusMessage = '';
      this.transactionsLoading = false;
      this.showTransactionExcelImport = true;
    }
  }
  async cancelFromConflicts(){
    this.transactionsLoading = true;
    this.statusMessage = 'Writing Cancel Log message';
    const params = {} as UtilService.LogEventMessageParameters;
    params.LogId = this.logId;
    params.Message = 'User canceled from Conflicts UI';
    await this._utilService.LogEventMessage(params);
    this.statusMessage = 'Closing Log Event';
    const completeParams = {} as UtilService.CompleteLogEventParameters;
    completeParams.LogId = this.logId;
    completeParams.Success = false;
    await this._utilService.CompleteLogEvent(completeParams);
    this.statusMessage = '';
    this.excludedTransactions = [];
    this.amexCreditCardTransactionImportPossibleDuplicates = [];
    this.transactionsLoading = false;
    this.showPotentialConflicts = false;
    this.showTransactionExcelImport = true;
    await this.getAmexTransactionImportProcesses();
  }
  selectEvent(row){
    this.$emit('event-clicked', row.LogId);
  }
  //#region Methods
}
