
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import OwnerRoleAssigner from '@/views/Entity/OwnerRoleAssigner.vue';
import EntityForeignStateAssigner from '@/views/Entity/EntityForeignStateAssigner.vue';
import ClientSelector from '@/components/form/ClientSelector.vue';
import BankSelector from '@/components/form/BankSelector.vue';
import AddressSelect from '@/components/form/AddressSelect.vue';
import StateSelector from '@/components/form/StateSelector.vue';
import RegisteredAgentSelector from '@/components/form/RegisteredAgentSelector.vue';
import * as VamService from '../../services/DAL/vamService';
import * as TaxService from '../../services/DAL/taxService';
import * as VisiumService from '../../services/DAL/visiumService';
import * as LiqService from '../../services/DAL/liq_Service';
import * as SystemService from '../../services/DAL/systemService';
import Common from '../../utils/common';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import ElementUI from 'element-ui';
import NamedKeySelector from '@/components/form/NamedKeySelector.vue';

declare var $: any;
declare var SmartObject: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: number, deflt: number): number;
declare var process: any;

@Component({
  components: {
    OwnerRoleAssigner,
    EntityForeignStateAssigner,
    ClientSelector,
    BankSelector,
    AddressSelect,
    StateSelector,
    RegisteredAgentSelector,
    NamedKeySelector
  }
})
export default class EntityEdit extends Vue {
  $refs: {
    frmEntityEdit: ElementUI.Form
    , divRegistrationRequestEmail: HTMLDivElement
    , divBankAccountRequestEmail: HTMLDivElement
    , physicalAddressSelect: AddressSelect
  }
  //#region Private Declarations
  private _vam_Service: VamService.VamService;
  private _tax_Service: TaxService.TaxService;
  private _visium_Service: VisiumService.VisiumService;
  private _liq_Service: LiqService.LiqService;
  private _common: Common;
  //#endregion Private Declarations

  //#region Props
  @Prop() owner: VamService.OwnersV2;
  @Prop() onSave: Function;
  @Prop() onDelete: Function;
  @Prop() viewType: string;
  //#endregion Props

  //#region Data
  owner_: VamService.OwnersV2 = new VamService.OwnersV2();
  isNew: Boolean = false;
  securityLevel: number = 0;
  shouldShowForm: Boolean = false;
  isLoading = false;
  errorMessage = null;
  showStakeholderAssigner: Boolean = false;
  showAuthorizedPartyAssigner: Boolean = false;
  showEntityForeignStateAssigner: Boolean = false;
  newStateId: number = null;
  stakeholders: VamService.OwnerRoleAssignments[] = [];
  authorizedParties: VamService.OwnerRoleAssignments[] = [];
  entityForeignStates: VamService.EntityForeignStates[] = [];
  ownerCategories: VamService.OwnerCategories[] = [];
  taxClasses: TaxService.TaxClasses[] = [];
  selectedTaxClass: TaxService.TaxClasses = new TaxService.TaxClasses();
  taxPayers: VamService.OwnersV2[] = [];
  allBankAccounts = [] as LiqService.BankAccountList[];
  brokerageBankAccounts: LiqService.BankAccountList[] = [];
  entityUses: VamService.EntityUses[] = [];
  bank = {} as VisiumService.Banks;
  ownerCategoryState = {};
  requiresRegistration: boolean = false;
  reportingCategories: SystemService.NamedKeys[] = [];
  //#endregion Data

  //#region Computed
  get isFormDirty(): boolean {
    return Object.keys((this as any).veeFields).some(
      key => (this as any).veeFields[key].dirty
    );
  }

  get ownerIsDeletable(): boolean {
    return (
        this.owner_.CountStakeholders === 0 &&
        this.owner_.CountAuthorizedParties === 0 &&
        this.owner_.CountForeignStates === 0
    );
  }
  
  get baseUrl(): boolean {
    return process && process.env && process.env.VUE_APP_BASE_URL
      ? process.env.VUE_APP_BASE_URL
      : '';
  }

  get entityUsesSelectedAndSaved(): VamService.EntityUses[] {
    return this.entityUses.filter(entityUse => {
      return entityUse.EntityUsesId > 0;
    });
  }
  
  get isIndividual(): boolean{
    return (this.owner_.OwnerCategoryName == 'Individual');
  }
  get selectedReportingCategory(): SystemService.NamedKeys {
    if (this.reportingCategories && this.reportingCategories.length && this.owner_.ReportingCategoryId){
        return this._common.getSelectedArrayItem(
          this.reportingCategories,
          this.owner_.ReportingCategoryId.toString(),
          'KeyValue'
        );
    }
  }
  get showReportingSubCategory(): boolean {
    if (this.selectedReportingCategory && this.selectedReportingCategory.AdditionalData1 == 'True') {
      return true;
    }
    else return false;
  }
  //#endregion Computed

  //#region Lifecycle
  created() {
    this._vam_Service = new VamService.VamService();
    this._tax_Service = new TaxService.TaxService();
    this._visium_Service = new VisiumService.VisiumService();
    this._liq_Service = new LiqService.LiqService();
    this._common = new Common();

    if (this.owner && Object.keys(this.owner).length) {
      this.owner_ = this.owner;
      this.fetchReadData();
    }
    else {
      this.isNew = true;
      this.requiresRegistration = true;
      this.showForm();
    }
    this.securityLevel = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageOwners),
      0
    );
  }
  //#endregion Lifecycle

  //#region Watches
  @Watch('owner_.OwnerCategoryId')
  async onChangeOwnerCategoryId(val?: object, oldVal?: object) {
    if (this.ownerCategories.length) {
      let selectedOwnerCategory = null;
      if (this.owner_.OwnerCategoryId) {
        selectedOwnerCategory = this._common.getSelectedArrayItem(
          this.ownerCategories,
          this.owner_.OwnerCategoryId.toString(),
          'Id'
        );
      }
      if (selectedOwnerCategory) {
        this.owner_.OwnerCategoryName = selectedOwnerCategory.Name;
      } else {
        this.owner_.OwnerCategoryName = null;
      }
      await this.fetchTaxClasses();
      this.owner.TaxClassId = null;
    }
  }

  @Watch('owner_.TaxClassId')
  onChangeTaxClassId(val?: object, oldVal?: object) {
    if (this.taxClasses.length) {
      this.selectedTaxClass = null;
      if (this.owner_.TaxClassId) {
        this.selectedTaxClass = this._common.getSelectedArrayItem(
          this.taxClasses,
          this.owner_.TaxClassId.toString(),
          'Id'
        );
      }
      if (this.selectedTaxClass) {
        this.owner_.TaxClassName = this.selectedTaxClass.Name;
      } else {
        this.owner_.TaxClassName = null;
      }
    }
  }

  @Watch('owner_.TaxPayerId')
  onChangeTaxPayerId(val?: object, oldVal?: object) {
    if (this.taxPayers.length) {
      let selectedTaxPayer = null;
      if (this.owner_.TaxPayerId) {
        selectedTaxPayer = this._common.getSelectedArrayItem(
          this.taxPayers,
          this.owner_.TaxPayerId.toString(),
          'Id'
        );
      }
      if (selectedTaxPayer) {
        this.owner_.TaxPayerName = selectedTaxPayer.Name;
      } else {
        this.owner_.TaxPayerName = null;
      }
    }
  }

  @Watch('owner_.AccountId')
  onChangeAccountId(val?: object, oldVal?: object) {
    if (this.allBankAccounts.length) {
      let selectedBankAccount = null;
      if (this.owner_.AccountId) {
        selectedBankAccount = this._common.getSelectedArrayItem(
          this.allBankAccounts,
          this.owner_.AccountId.toString(),
          'BankAccountId'
        );
      }
      if (selectedBankAccount) {
        this.owner_.BankAccountNameDisplay = selectedBankAccount.Display;
      } else {
        this.owner_.BankAccountNameDisplay = null;
      }
    }
  }

  @Watch('owner_.DefaultInvestmentAccountId')
  onChangeDefaultInvestmentAccountId(val?: object, oldVal?: object) {
    if (this.brokerageBankAccounts.length) {
      let selectedBrokerageBankAccount = null;
      if (this.owner_.DefaultInvestmentAccountId) {
        selectedBrokerageBankAccount = this._common.getSelectedArrayItem(
          this.brokerageBankAccounts,
          this.owner_.DefaultInvestmentAccountId.toString(),
          'BankAccountId'
        );
      }
      if (selectedBrokerageBankAccount) {
        this.owner_.DefaultInvestmentAccountNameDisplay = selectedBrokerageBankAccount.Display;
      } else {
        this.owner_.DefaultInvestmentAccountNameDisplay = null;
      }
    }
  }

  @Watch('owner_.State')
  onChangeState(val?: object, oldVal?: object) {
    if (oldVal !== undefined) {
      this.owner_.RegisteredAgentId = null;
      this.owner_.RegisteredAgent = null;
      this.owner_.RegisteredAgentFullStreet = null;
      this.owner_.RegisteredAgentCityStateZip = null;
      this.owner_.RegisteredAgentFullAddress = null;
      this.owner_.RegisteredAgentCounty = null;
    }
  }

  @Watch('owner_.MailingAddressId')
  async onChangeMailingAddressId(val?: object, oldVal?: object) {
    let selectedAddress = null;
    if (this.owner_.MailingAddressId) {
      selectedAddress = await this.getAddress(this.owner_.MailingAddressId);
    }
    if (selectedAddress) {
      // this.owner_.MailingAddressId = selectedAddress.Id;
      this.owner_.MailingAddressDescription = selectedAddress.Description;
      this.owner_.MailingAddressCareOf = selectedAddress.CareOf;
      this.owner_.MailingAddressStreet = selectedAddress.Street;
      this.owner_.MailingAddressStreet2 = selectedAddress.Street2;
      this.owner_.MailingAddressCity = selectedAddress.City;
      this.owner_.MailingAddressStateAbbreviation =
        selectedAddress.StateAbbreviation;
      this.owner_.MailingAddressZip = selectedAddress.Zip;
      this.owner_.MailingAddressFull = selectedAddress.FullAddress;
    } else {
      this.owner_.MailingAddressId = null;
      this.owner_.MailingAddressDescription = null;
      this.owner_.MailingAddressCareOf = null;
      this.owner_.MailingAddressStreet = null;
      this.owner_.MailingAddressStreet2 = null;
      this.owner_.MailingAddressCity = null;
      this.owner_.MailingAddressStateAbbreviation = null;
      this.owner_.MailingAddressZip = null;
      this.owner_.MailingAddressFull = null;
    }
  }

  @Watch('owner_.PhysicalAddressId')
  async onChangePhysicalAddressId(val?: object, oldVal?: object) {
    let selectedAddress = null;
    if (this.owner_.PhysicalAddressId) {
      selectedAddress = await this.getAddress(this.owner_.PhysicalAddressId);
    }
    if (selectedAddress) {
      // this.owner_.PhysicalAddressId = selectedAddress.Id;
      this.owner_.PhysicalAddressDescription = selectedAddress.Description;
      this.owner_.PhysicalAddressCareOf = selectedAddress.CareOf;
      this.owner_.PhysicalAddressStreet = selectedAddress.Street;
      this.owner_.PhysicalAddressStreet2 = selectedAddress.Street2;
      this.owner_.PhysicalAddressCity = selectedAddress.City;
      this.owner_.PhysicalAddressStateAbbreviation =
        selectedAddress.StateAbbreviation;
      this.owner_.PhysicalAddressZip = selectedAddress.Zip;
      this.owner_.PhysicalAddressFull = selectedAddress.FullAddress;
    } else {
      this.owner_.PhysicalAddressId = null;
      this.owner_.PhysicalAddressDescription = null;
      this.owner_.PhysicalAddressCareOf = null;
      this.owner_.PhysicalAddressStreet = null;
      this.owner_.PhysicalAddressStreet2 = null;
      this.owner_.PhysicalAddressCity = null;
      this.owner_.PhysicalAddressStateAbbreviation = null;
      this.owner_.PhysicalAddressZip = null;
      this.owner_.PhysicalAddressFull = null;
    }
  }
  //#endregion Watches

  //#region Methods
  async fetchReadData() {
    this.isLoading = true;

    await Promise.all([
      this.fetchStakeholders(),
      this.fetchAuthorizedParties(),
      this.fetchEntityForeignStates(),
      this.fetchOwnerCategoryState(),
      this.fetchEntityUses(),
      this.fetchBank(),
      (async () => {
        if (this.owner_.BankId > 0) {
          this.bank = await new SmartObject(
            'Banks',
            this.owner_.BankId
          ).loadData();
        }
      })()
    ]);

    this.isLoading = false;
  }

  async fetchEditData() {
    this.isLoading = true;

    if (this.isNew) {
      await Promise.all([
        this.fetchOwnerCategories(),
        this.fetchTaxClasses(),
        this.fetchTaxPayers()
      ]);
    }
    else {
      await Promise.all([
        this.fetchOwnerCategories(),
        this.fetchTaxClasses(),
        this.fetchTaxPayers(),
        this.fetchAllBankAccounts(),
        this.fetchBrokerageBankAccounts()
      ]);
    }
    this.isLoading = false;
  }

  async fetchBank(){
    this.bank = {} as VisiumService.Banks;
    if (this.owner_.BankId > 0) {
      const params = {} as VisiumService.GetBanksParameters;
      params.BankId = this.owner_.BankId;
      const banks = await this._visium_Service.GetBanks(params);
      if (banks.length == 1){
        this.bank = banks[0];
      }
    }
  }
  async fetchStakeholders() {
    this.stakeholders = [];
    const parameters = {} as VamService.GetOwnerRoleAssignmentsParameters;
    parameters.OwnerId = this.owner_.Id;
    parameters.RoleTypeId = (this as any).$namedKey.Owner_RoleType.Stakeholder;
    this.stakeholders = await this._vam_Service.GetOwnerRoleAssignments(
      parameters
    );
    this.owner_.CountStakeholders = this.stakeholders.length;
  }

  async fetchAuthorizedParties() {
    this.authorizedParties = [];
    const parameters = {} as VamService.GetOwnerRoleAssignmentsParameters;
    parameters.OwnerId = this.owner_.Id;
    parameters.RoleTypeId = (this as any).$namedKey.Owner_RoleType.AuthorizedParty;
    this.authorizedParties = await this._vam_Service.GetOwnerRoleAssignments(
      parameters
    );
    this.owner_.CountAuthorizedParties = this.authorizedParties.length;
  }

  async fetchEntityForeignStates() {
    this.entityForeignStates = [];
    const parameters = {} as VamService.GetEntityForeignStatesParameters;
    parameters.EntityId = this.owner_.Id;
    this.entityForeignStates = await this._vam_Service.GetEntityForeignStates(
      parameters
    );
    this.owner_.CountForeignStates = this.entityForeignStates.length;
  }

  async fetchOwnerCategoryState() {
    this.ownerCategoryState = [];

    if (this.owner_.StateId && this.owner_.OwnerCategoryId) {
      const ocs = new SmartObject('OwnerCategoryState');
      await ocs.loadDataFromCompositeKey({
        OwnerCategoryId: this.owner_.OwnerCategoryId,
        StateId: this.owner_.StateId
      });

      if (ocs.entityId) {
        this.ownerCategoryState = ocs.dataObject;
      }
    }
  }

  async fetchEntityUses() {
    this.entityUses = [];
    const getEntityUsesParameters = {} as VamService.GetEntityUsesParameters;
    getEntityUsesParameters.EntityId = this.owner_.Id;
    this.entityUses = await this._vam_Service.GetEntityUses(
      getEntityUsesParameters
    );
  }

  async fetchOwnerCategories() {
    this.ownerCategories = [];
    this.ownerCategories = await this._vam_Service.GetOwnerCategories();
  }

  async fetchTaxClasses() {
    this.taxClasses = [];
    const parms = {} as TaxService.GetTaxClassesParameters;
    parms.OwnerCategoryId = this.owner_.OwnerCategoryId;
    this.taxClasses = await this._tax_Service.GetTaxClasses(parms);
    
    if (this.taxClasses.length == 1 && !this.owner_.TaxClassId){ // if only 1 option, and not already set, set to only option
      this.owner_.TaxClassId = this.taxClasses[0].Id;
    }

    if (this.owner_.TaxClassId){
      this.selectedTaxClass = this._common.getSelectedArrayItem(
            this.taxClasses,
            this.owner_.TaxClassId.toString(),
            'Id'
          );
    }
    else {
      this.selectedTaxClass = new TaxService.TaxClasses();
    }
  }

  async fetchTaxPayers() {
    this.taxPayers = [];
    if (this.owner_.ClientId > 0) {
      const getOwnersParameters = {} as VamService.GetOwnersV2Parameters;
      getOwnersParameters.ClientId = this.owner_.ClientId;
      this.taxPayers = await this._vam_Service.GetOwnersV2(getOwnersParameters);
    }
  }

  async fetchAllBankAccounts() {
    this.allBankAccounts = [];

    if (this.owner_.ClientId > 0) {
      const params = {} as LiqService.GetBankAccountListParameters;
      params.ClientId = this.owner_.ClientId;
      this.allBankAccounts = await this._liq_Service.GetBankAccountList(
        params
      );

      if (this.allBankAccounts.length === 1) {
        this.owner_.AccountId = this.allBankAccounts[0].BankAccountId;
      }
    }
  }

  async fetchBrokerageBankAccounts() {
    this.brokerageBankAccounts = [];

    if (this.owner_.ClientId > 0) {
      const params = {} as LiqService.GetBankAccountListParameters;
      params.ClientId = this.owner_.ClientId;
      params.AccountType = 'Investment';
      this.brokerageBankAccounts = await this._liq_Service.GetBankAccountList(
        params
      );

      if (this.brokerageBankAccounts.length === 1) {
        this.owner_.DefaultInvestmentAccountId = this.brokerageBankAccounts[0].BankAccountId;
      }
    }
  }

  async getAddress(AddressId: number) {
    const getAddressesParameters = {} as VamService.GetAddressesParameters;
    getAddressesParameters.AddressId = AddressId;
    const addresses = await this._vam_Service.GetAddresses(
      getAddressesParameters
    );
    return addresses.length ? addresses[0] : {};
  }

  onSet_Client(client: VamService.UserClients) {
      if (client) {
        this.owner_.ClientName = client.Name;
      } else {
        this.owner_.ClientName = null;
      }

      this.owner_.TaxPayerId = null;
      this.owner_.TaxPayerName = null;
      this.owner_.AccountId = null;
      this.owner_.BankAccountId = null;
      this.owner_.BankAccountNameDisplay = null;
      this.owner_.DefaultInvestmentAccountId = null;
      this.owner_.DefaultInvestmentAccountNameDisplay = null;
      this.fetchTaxPayers();
      this.fetchAllBankAccounts();
      this.fetchBrokerageBankAccounts();
  }

  onSet_State(state: VamService.States) {
    if (state) {
        this.owner_.StateId = state.Id;
        this.owner_.StateName = state.Name;
    }
    else {
        this.owner_.StateId = null;
        this.owner_.StateName = null;
    }
    this.fetchOwnerCategoryState();
  }

  onSet_RegisteredAgent(registeredAgent: VamService.RegisteredAgents) {
    if (registeredAgent) {
        this.owner_.RegisteredAgent = registeredAgent.Name;
        this.owner_.RegisteredAgentFullStreet = registeredAgent.FullStreet;
        this.owner_.RegisteredAgentCityStateZip = registeredAgent.CityStateZip;
        this.owner_.RegisteredAgentFullAddress = registeredAgent.FullAddress;
        this.owner_.RegisteredAgentCounty = registeredAgent.County;
    }
    else {
        this.owner_.RegisteredAgent = null;
        this.owner_.RegisteredAgentFullStreet = null;
        this.owner_.RegisteredAgentCityStateZip = null;
        this.owner_.RegisteredAgentFullAddress = null;
        this.owner_.RegisteredAgentCounty = null;
    }
  }

  async openAuthorizedPartyAssigner() {
      if (this.owner_.ClientId > 0 && this.owner_.Name) {
        // create owner record to assign authorized parties to
        if (this.isNew && !(this.owner_.Id > 0)) {
            this.owner_.Id = await new SmartObject('Owner').createObject({
                Name: 'TEMP SAVE for OwnerRoleAssignment ' + (new Date()).getTime()
            });
        }

        this.showAuthorizedPartyAssigner = true;
      }
  }

  async openStakeholderAssigner() {
      if (this.owner_.ClientId > 0 && this.owner_.Name) {
        // create owner record to assign stakeholders to
        if (this.isNew && !(this.owner_.Id > 0)) {
            this.owner_.Id = await new SmartObject('Owner').createObject({
                Name: 'TEMP SAVE for OwnerRoleAssignment' + (new Date()).getTime()
            });
        }

        this.showStakeholderAssigner = true;
      }
  }

  async openEntityForeignStateAssigner() {
      if (this.owner_.Name) {
        // create owner record to assign foreign entity states to
        if (this.isNew && !(this.owner_.Id > 0)) {
            this.owner_.Id = await new SmartObject('Owner').createObject({
                Name: 'TEMP SAVE for EntityForeignState' + (new Date()).getTime()
            });
        }

        this.showEntityForeignStateAssigner = true;
      }
  }

  async showForm() {
    this.shouldShowForm = true;
    await this.fetchEditData();
    this.$refs.frmEntityEdit.resetFields();
  }

  async cancelForm() {
    this.shouldShowForm = false;

    if (this.isNew && this.owner_.Id > 0) {
        // delete record created to assign authorized parties and/or stakeholders to
        await new SmartObject('Owner', this.owner_.Id).deleteObject();
    }

    if (this.viewType === 'dialog') {
        this.$emit('close-dialog');
    }
  }

  async deleteOwner() {
    if (!this.ownerIsDeletable) {
      return;
    }

    try {
      await this.$confirm(
        'This will permanently delete this entity. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      );

      this.isLoading = true;

      try {
        await new SmartObject('Owner', this.owner_.Id).deleteObject();

        this.$notify.success('Entity deleted.');

        this.shouldShowForm = false;
        this.isLoading = false;

        if (typeof this.onDelete === 'function') {
          await this.onDelete();
        }
      } catch (err) {
        try {
            const errJson = JSON.parse(err.responseText);
            if (errJson.ExceptionMessage.toLowerCase().indexOf('reference constraint') > -1) {
                this.$notify.error('Please remove all assignments (stakeholders, authorized parties, and foreign entity states) prior to deleting.');
            }
            else {
                this.$notify.error('Something went wrong processing your request, please try again.');
            }
        }
        catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }

        this.isLoading = false;
        return;
      }
    } catch {}
  }

  async saveForm() {
    this.$refs.frmEntityEdit.validate(async valid => {
      if (valid) {
        this.isLoading = true;
        if (!this.owner_.InvestmentAccountRequired){
          this.owner_.DefaultInvestmentAccountId = null;
          this.owner_.DefaultInvestmentAccountNameDisplay = null;
        }
        if (!this.owner_.BankAccountRequired){
          this.owner_.BankAccountId = null;
          this.owner_.AccountId = null;
          this.owner_.BankAccountNameDisplay = null;
        }
        try {
            if (this.owner_.Email == ''){
                this.owner_.Email = null;
            }
            const updateAttrs: any = cloneDeep(this.owner_);
            updateAttrs.OwnerId = updateAttrs.TaxPayerId; // TaxPayerId is actually o.OwnerId
            if (this.owner_.Id > 0) {
                await new SmartObject('Owner', this.owner_.Id).updateObject(updateAttrs);
            }
            else {
                this.owner_.Id = await new SmartObject('Owner').createObject(updateAttrs);
            }

            this.entityUses.forEach(async entityUse => {
                if (!entityUse.BitValue && entityUse.EntityUsesId > 0) {
                    await new SmartObject('EntityUses', entityUse.EntityUsesId).deleteObject();
                    entityUse.EntityUsesId = null;
                }
                else if (entityUse.BitValue && !entityUse.EntityUsesId) {
                    entityUse.EntityUsesId = await new SmartObject('EntityUses').createObject({
                        EntityId: this.owner_.Id,
                        EntityUseId: entityUse.EntityUseId
                    });
                }
            });
            await this.fetchEntityUses(); // gkb 01/24/19 - we're modifying entityUses directly in the .forEach() above, but this is still necessary for some reason to update the list in the read-only mode, and also for an up-to-date list when subsequently re-entering edit mode

            this.$notify.success('Changes saved.');

            if (this.isNew && this.requiresRegistration && !this.isIndividual) {
                this.sendRegistrationRequestEmail();
            }
            if (this.isNew && (this.owner_.BankAccountRequired || this.owner_.InvestmentAccountRequired)) {
                this.sendBankAccountRequestEmail();
            }
            this.isNew = false;

            this.isLoading = false;

            if (this.viewType !== 'dialog') {
                this.shouldShowForm = false;
                this.fetchReadData();
            }

            if (typeof this.onSave === 'function') {
                await this.onSave(this.owner_);
            }
            
            if (this.viewType === 'dialog') {
                this.$emit('close-dialog');
            }
        } catch (err) {
            try {
                const errJson = JSON.parse(err.responseText);
                if (errJson.ExceptionMessage.toLowerCase().indexOf('cannot insert duplicate key') > -1) {
                    this.$notify.error('The name entered matches the name of an entity already in the system. Please edit the existing record, or change the name and try again.');
                }
                else {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
            }
            catch {
                this.$notify.error('Something went wrong processing your request, please try again.');
            }

            this.isLoading = false;
            return;
        }
      }
    });
  }

  async sendRegistrationRequestEmail() {
    this.$notify.info('Sending registration request email...');

    const toAddresses: object = {};
    toAddresses[process.env.VUE_APP_ENTITY_REGISTRATION_EMAIL] = process.env.VUE_APP_ENTITY_REGISTRATION_EMAIL;

    try {
        var result = await $(
            this.$refs.divRegistrationRequestEmail
        ).exportToEmail({
            toAddresses: toAddresses,
            subject: `[Action Required] Register New Entity: ${this.owner_.Name}`,
            additionalAttributes: {
                EntityType: 'Owner',
                EntityId: this.owner_.Id,
                Source: 'Entity Creation'
            }
        });

        if (result.succeeded) {
            this.$notify.success('Registration request email sent.');
        } else {
          console.log('to: ', toAddresses);
          console.error('failed: ', result);
        this.$notify.error('Registration request email failed.');
        }
    } catch(ex) {
        console.log('to: ', toAddresses);
        console.error('error: ', ex);
        this.$notify.error('Registration request email failed.');
    }
  }

  async sendBankAccountRequestEmail() {
    this.$notify.info('Sending bank account request email...');

    const toAddresses: object = {};
    toAddresses[process.env.VUE_APP_ENTITY_BANK_ACCOUNT_SETUP_EMAIL] = process.env.VUE_APP_ENTITY_BANK_ACCOUNT_SETUP_EMAIL;

    try {
        var result = await $(
            this.$refs.divBankAccountRequestEmail
        ).exportToEmail({
            toAddresses: toAddresses,
            subject: `[Action Required] Create Bank Account(s) for New Entity: ${this.owner_.Name}`,
            additionalAttributes: {
                EntityType: 'Owner',
                EntityId: this.owner_.Id,
                Source: 'Entity Creation With Bank Accounts'
            }
        });

        if (result.succeeded) {
            this.$notify.success('Bank account request email sent.');
        } else {
            console.log('to: ', toAddresses);
            console.error('failed: ', result);
            this.$notify.error('Bank account request email failed.');
        }
    } catch(ex) {
      console.log('to: ', toAddresses);
      console.error('error: ', ex);
      this.$notify.error('Bank account request email failed.');
    }
  }

  closeStakeholderAssigner() {
    this.showStakeholderAssigner = false;
    this.fetchStakeholders();
  }

  closeAuthorizedPartyAssigner() {
    this.showAuthorizedPartyAssigner = false;
    this.fetchAuthorizedParties();
  }

  closeEntityForeignStateAssigner() {
    this.showEntityForeignStateAssigner = false;
    this.fetchEntityForeignStates();
  }

  async deleteOwnerRoleAssignment(
    AssociationId: number,
    ownerRoleAssignmentArray: any[],
    assignmentType: string
  ) {
    if (AssociationId > 0) {
      try {
        await this.$confirm(
          'Are you sure you want to remove this ' + assignmentType + '?',
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        await new SmartObject(
          'OwnerRoleAssignment',
          AssociationId
        ).deleteObject();

        const indexOfDeletedRecord = findIndex(
          ownerRoleAssignmentArray,
          ownerRoleAssignment => {
            return ownerRoleAssignment.AssociationId === AssociationId;
          }
        );
        ownerRoleAssignmentArray.splice(indexOfDeletedRecord, 1);

        this.owner_.CountStakeholders = this.stakeholders.length;
        this.owner_.CountAuthorizedParties = this.authorizedParties.length;

        this.$notify.success(assignmentType + ' removed.');
      } catch {}
    }
  }
  async addressAdded(){
    this.$refs.physicalAddressSelect.fetchAddresses();
  }
  async deleteEntityForeignState(
    AssociationId: number
  ) {
    if (AssociationId > 0) {
      try {
        await this.$confirm(
          'Are you sure you want to remove this foreign entity state?',
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        await new SmartObject(
          'EntityForeignState',
          AssociationId
        ).deleteObject();

        const indexOfDeletedRecord = findIndex(
          this.entityForeignStates,
          entityForeignState => {
            return entityForeignState.AssociationId === AssociationId;
          }
        );
        this.entityForeignStates.splice(indexOfDeletedRecord, 1);

        this.owner_.CountForeignStates = this.entityForeignStates.length;

        this.$notify.success('Foreign entity state removed.');
      } catch {}
    }
  }
  //#endregion Methods
}
