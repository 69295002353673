<template>
    <div id="divPaymentSchedule">
        <p>
            Method: {{scheduleType}}
        </p>
        <el-button v-on:click="showEditSchedule=true" type="success" icon="plus" size="mini">Add New Schedule</el-button>
        <span v-show="scheduleType == 'Imputed'">Adding a Schedule will remove all imputed schedules.</span>
        <table v-if="schedules[0]" class="table table-striped">
            <thead>
                <tr>
                    <th v-show="scheduleType == 'Specific'"></th>
                    <th class="alignCenter">Date</th>
                    <th class="alignRight">Payment</th>
                    <th class="alignRight" v-show="scheduleType == 'Specific'">Percentage</th>
                    <th v-show="scheduleType == 'Specific'" class="alignCenter">Modified</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="schedule in schedules" :key="schedule.PaymentScheduleId" style="height:29px;">

                    <td v-show="scheduleType == 'Specific'">
                        <el-button type="primary" size="mini" icon="el-icon-edit" v-show="editingId == -1" @click="editingId = schedule.PaymentScheduleId"></el-button>
                        <el-button type="danger" size="mini" icon="el-icon-delete" v-show="editingId == -1" @click="deleteSchedule(schedule.PaymentScheduleId)"></el-button>
                    </td>
                    <td class="alignCenter" style="white-space:nowrap">
                        <span>{{schedule.Date | shortDate}}</span>
                    </td>
                    <td class="alignRight" style="white-space:nowrap">
                        <span>{{schedule.Payment | currency}}</span>
                    </td>
                    <td class="alignRight" style="white-space:nowrap" v-show="scheduleType == 'Specific'">
                        <span>{{schedule.Percentage | percent0}}</span>
                    </td>
                    <td style="white-space:nowrap" v-show="scheduleType == 'Specific'">
                        <span>{{schedule.LastModified}}</span>
                    </td>

                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td v-show="scheduleType == 'Specific'"></td>
                    <td>Total:</td>
                    <td class="alignRight">
                        <span>{{totalPayments | currency}}</span>
                    </td>
                    <td class="alignRight" v-show="scheduleType == 'Specific'">
                        <span>{{totalPercent | percent0}}</span>
                    </td>
                </tr>
            </tfoot>
        </table>
        <p style="border-style:solid; border-color:red;" v-show="(scheduleType == 'Specific' && totalPercent!=1)">Because Total is not 100%, values are normalized to equal 100%.  Payment value reflects that logic.</p>
        <el-dialog :visible="showEditSchedule" :show-close="false">
            <h3 slot="title" class="dialog-title">{{(editingId == -1) ? 'New' : 'Edit'}} Schedule</h3>
            <el-form :model="editSchedule" :rules="editScheduleRules" ref="editSchedule" label-width="120px">
                <el-form-item label="Date:" prop="Date">
                    <el-date-picker v-model="editSchedule.Date"
                                    type="date"
                                    format="MM/dd/yyyy"
                                    placeholder="MM/DD/YYYY">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="Percentage:" prop="PercentInt">
                    <el-input-number v-model.number="editSchedule.PercentInt"
                                     :step="1"
                                     :max="100">
                    </el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('editSchedule')">{{(editingId == -1) ? 'Create' : 'Update'}}</el-button>
                <el-button @click="resetForm('editSchedule')" v-show="editingId == -1">Reset</el-button>
                <el-button type="warning" @click="cancelEditSchedule">Cancel</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import mixinTax from './../DAL/mixinSchema_tax'
    import mixinUtility from './../VueCommon/Utility.js'

    export default Vue.extend({
        name: 'TaxPaymentSchedule'
        , mixins: [mixinTax, mixinUtility]
        , props: {
            noteId: {
                type: Number
                , required: true
            }
            , securityLevel: {
                type: Number
                //, default: null
            }
        }
        , data() {
            return {
                schedules: {}
                , scheduleType: 'default'
                , editingId: -1
                , totalTaxDue: 0
                , showEditSchedule: false
                , editSchedule: {
                    Id: null
                    , Date: null
                    , PercentInt: null
                    , NoteId: null
                }
                , editScheduleHolder: []
                , editScheduleRules: {
                    PercentInt: [
                        { type: 'integer', required: true, message: 'Percentage is Required and must be an integer', trigger: 'blur' }
                        //, { min: 1, max: 100, message: 'Percentage must be between 1 and 100', trigger: 'blur' }
                    ],
                    Date: [
                        { type: 'date', required: true, message: 'Please pick a date', trigger: 'change' }
                    ],
                }
                // , securityLevel_: this.securityLevel
            }
        }
        , created: function () {
            // if (this.securityLevel_ === null) {
            //     this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), 0);
            // }
            
            this.fetchSchedules();
        }
        , computed: {
            totalPayments: function () {
                return this.sum(this.schedules, 'Payment');
            }
            , totalPercent: function () {
                return this.sum(this.schedules, 'Percentage');
            }
        }
        , watch: {
            'editingId': function (val, oldVal) {
                if (val > -1) {
                    this.editSchedule = this.getSelectedArrayItem(this.schedules, val, 'PaymentScheduleId');
                    this.editScheduleHolder = Vue.util.extend({}, this.editSchedule); //hold the original values in case user cancels, using a non-ref copy.
                    this.showEditSchedule = true;
                }
            }
        }
        , methods: {
            sum: function (items, prop) {
                if (items && items.length > 0) {
                    return items.reduce(function (a, b) {
                        return b[prop] == null ? a : tryParseFloat(a, 0) + tryParseFloat(b[prop], 0);
                    }, 0);
                }
                else {
                    return 0;
                }
            }
            , fetchSchedules: function () {
                var el = this;
                this.tax_GetPaymentSchedule({
                    NoteId: el.noteId
                }
                    , function (data) {
                        el.schedules = data;
                        el.totalTaxDue = data[0]._TotalTaxDue;
                        el.scheduleType = data[0].ScheduleType;
                    }
                    , function (error) {
                        console.error(error);
                    });
            }
            , saveSchedule: function (scheduleId) {
                var ccs = this.getSelectedArrayItem(this.schedules, scheduleId, 'PaymentScheduleId')
                var so = new SmartObject('TaxPaymentSchedule', scheduleId);
                so.updateObject_lgcy(ccs);
                this.$notify.success('Payment Schedule Updated');
                this.editingId = -1;
                this.editScheduleHolder = null;
            }
            , deleteSchedule: function (scheduleId) {
                this.$confirm('Delete Schedule. Continue?', 'Delete', {
                    confirmButtonText: 'Delete',
                    confirmButtonClass: 'el-button--danger',
                    cancelButtonText: 'Cancel',
                    type: 'error'
                }).then(() => {
                    var ccs = this.getSelectedArrayItem(this.schedules, scheduleId, 'PaymentScheduleId')
                    console.log(ccs);
                    var so = new SmartObject('TaxPaymentSchedule', scheduleId);
                    so.deleteObject_lgcy(ccs);
                    //this.deleteSelectedArrayItem(this.schedules, scheduleId, 'PaymentScheduleId'); don't need to update the display because the next step will refresh from DB
                    //refresh from DB in case there are no more specific entries, so we get the imputed.
                    this.fetchSchedules();
                    this.$notify.success('Payment Schedule Deleted');
                    this.editingId = -1;
                }).catch((err) => {
                    console.error(err);
                    console.log('delete canceled');
                });
            }
            , saveEditSchedule: function () {
                if (this.editingId == -1) {
                    console.log(this.editSchedule);
                    var schedule = new SmartObject('TaxPaymentSchedule');
                    var id = schedule.createObject_lgcy({
                        Date: this.editSchedule.Date
                        , NoteId: this.noteId
                        , PercentInt: this.editSchedule.PercentInt
                    });
                    this.editSchedule.PaymentScheduleId = id;
                    //this.schedules.push(Vue.util.extend({}, this.editSchedule)); //Vue.util.extend pushes a non-ref copy so that subsequent changes to the original inputs won't affect the Main list.
                }
                else {
                    this.saveSchedule(this.editingId);
                }
                this.clearEditSchedule();
                this.showEditSchedule = false;
                this.fetchSchedules(); //refresh from DB
            }
            , cancelEditSchedule: function () {
                if (this.editingId > -1) {//restore original values
                    var sch = this.getSelectedArrayItem(this.schedules, this.editingId, 'PaymentScheduleId');
                    sch.Percentage = this.editScheduleHolder.Percentage;
                    sch.Date = this.editScheduleHolder.Date;
                    sch.Notes = this.editScheduleHolder.Notes;
                    this.editScheduleHolder = null;
                }
                this.clearEditSchedule();
                this.editingId = -1;
                this.showEditSchedule = false;
            }
            , clearEditSchedule: function () {
                this.editSchedule = {
                    Id: null
                    , Date: null
                    , PercentInt: null
                }
            }
            , submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.saveEditSchedule();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        }
    })
</script>
