
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import formatters from '../../utils/formatters';
import * as TransactionsService from "./../../services/DAL/transactionsService";
import FundingSourceEdit from "@/views/FundingSource/FundingSourceEdit.vue";
import findIndex from "lodash/findIndex";
import ElementUI from 'element-ui';

@Component({
    components: {
        FundingSourceEdit
    }
})
export default class FundingSourceList extends Vue {
    $refs: {
        tblFundingSourceList: ElementUI.Table
    }
    //#region Data
    private _transactionsService: TransactionsService.TransactionsService;
    private formatters: formatters = new formatters();

    @Prop() ownerId: number;
    @Prop() transactionId: number;
    @Prop() transactionTypeId: number;
    @Prop() transactionDate: string;
    @Prop() holdingEntityId: number;
    @Prop() investmentId: number;
    @Prop() isConsideration: boolean;
    @Prop() targetAmount: number;
    @Prop({
        default: true
    })
    appendDialogsToBody: boolean;

    fundingSources: TransactionsService.FundingSource[] = [];
    totalAmount: number = 0;
    selectedFundingSource = null;
    showFundingSourceEdit = false;
    isLoading = false;
    securityLevel_: number = null;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets), 0);
        this._transactionsService = new TransactionsService.TransactionsService();
        this.formatters = new formatters();
    }

    async mounted() {
        if (this.transactionId > 0) {
            this.fetchFundingSources();
        }
    }
    //#endregion Lifecycle

    //#region Computed
    get invalidTotal(): boolean {
        return !!this.targetAmount && this.$accounting.unformat(this.totalAmount.toString()) !== this.$accounting.unformat(this.targetAmount.toString());
    }
    //#endregion Computed

    //#region Methods
    selectFundingSource(fundingSource) {
        if (fundingSource ) {
            this.showFundingSourceEdit = false;
            this.$nextTick(() => {
                this.showFundingSourceEdit = true;
                this.selectedFundingSource = fundingSource;
            });
        }
        else {
            this.$refs.tblFundingSourceList.setCurrentRow(null)
        }
    }

    openNewFundingSource() {
        this.showFundingSourceEdit = true;
    }

    closeFundingSource() {
        this.showFundingSourceEdit = false;
        this.selectedFundingSource = null;
        this.$refs.tblFundingSourceList.setCurrentRow(null);
    }

    removeFundingSource(fundingSourceId) {
        const index = findIndex(this.fundingSources, fundingSource => {
            return fundingSource.Id === fundingSourceId;
        });
        if (index > -1) {
            this.totalAmount -= this.fundingSources[index].Amount || 0;
            this.fundingSources.splice(index, 1);
        }
    }

    async fetchFundingSources() {
        this.isLoading = true;
        const parameters = {} as TransactionsService.GetFundingSourceParameters;
        parameters.TransactionId = this.transactionId;
        this.fundingSources = await this._transactionsService.GetFundingSource(
            parameters
        );

        this.totalAmount = 0;
        if (this.fundingSources.length) {
            this.fundingSources.forEach(fundingSource => {
                this.totalAmount += fundingSource.Amount || 0;
            });
        }
        
        this.isLoading = false;
        this.$emit('loaded', this.fundingSources);
    }
    //#endregion Methods
}
