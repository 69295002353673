
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import FundFamilyEdit from '@/views/FundFamily/FundFamilyEdit.vue';
import ChangeLogList from '@/js/ChangeLog/ChangeLogList.vue';

@Component({
    components: {
        FundFamilyEdit,
        ChangeLogList
    }
})
export default class FundFamilyTabView extends Vue {
    //#region Private Declarations
    private _assetService: AssetService.AssetService;
    //#endregion Private Declarations

    //#region Props
    @Prop() fundFamily: AssetService.FundFamilies;
    @Prop() viewType: string;
    //#endregion Props

    //#region Data
    fundFamily_: AssetService.FundFamilies = new AssetService.FundFamilies();
    investmentParents: AssetService.InvestmentParentList[] = [];
    hasLoaded_parents: boolean = false;
    isLoading_parents: boolean = false;
    showChangeLogList: boolean = true;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();

        if (this.fundFamily && Object.keys(this.fundFamily).length) {
            this.fundFamily_ = this.fundFamily;
        }
    }
    //#endregion Lifecycle

    //#region Methods
    onSave(FundFamilyId) {
        this.refreshChangeLogList();
        this.$emit('saved', FundFamilyId);
    }

    onTabClick(tab) {
        if (tab.label === 'Investment Parents' && !this.hasLoaded_parents) {
            this.fetchInvestmentParents();
            this.hasLoaded_parents = true;
        }
    }

    async fetchInvestmentParents() {
        this.isLoading_parents = true;
        this.investmentParents = [];
        const params = {} as AssetService.GetInvestmentParentListParameters;
        params.FundFamilyId = this.fundFamily_.Id;
        this.investmentParents = await this._assetService.GetInvestmentParentList(params);
        this.isLoading_parents = false;
    }

    async refreshChangeLogList() {
        this.showChangeLogList = false;
        await this.$nextTick();
        this.showChangeLogList = true;
    }
    //#endregion
}
