// TS API for Schema system
// Auto-generated 01/07/22 08:39


declare var smartData: any;
// output classes:
export class AzureDatabaseServiceLevel {
    Id: number=undefined;
    ServiceLevel: string=undefined;
    MonthlyCost: number=undefined;
    HourlyCost: number=undefined;
    Type: string = undefined;
}
export class BusinessObjects {
    Id: number=undefined;
    SchemaName: string=undefined;
    TableName: string=undefined;
    QualifiedName: string = undefined;
}
export class BusinessObjectsAssignedToSecurityView {
    AssociationId: number=undefined;
    Id: number=undefined;
    SchemaName: string=undefined;
    TableName: string=undefined;
    QualifiedName: string=undefined;
    CanRead: boolean=undefined;
    CanUpdate: boolean=undefined;
    CanCreate: boolean=undefined;
    CanDelete: boolean=undefined;
    CanInvokeMethod: boolean = undefined;
}
export class BusinessObjectSchemas {
    SchemaName: string = undefined;
}
export class BusinessObjectsForSecurityRole {
    Id: number=undefined;
    SchemaName: string=undefined;
    TableName: string = undefined;
}
export class BusinessObjectsForUser {
    Id: number=undefined;
    SchemaName: string=undefined;
    TableName: string = undefined;
}
export class BusinessObjectsUnassignedToSecurityView {
    Id: number=undefined;
    SchemaName: string=undefined;
    TableName: string=undefined;
    QualifiedName: string = undefined;
}
export class ChangeLog {
    Id: number=undefined;
    ChangeDate: string=undefined;
    UserId: string=undefined;
    Schema: string=undefined;
    Table: string=undefined;
    EntityId: number=undefined;
    Field: string=undefined;
    BeforeValue: string=undefined;
    AfterValue: string=undefined;
    FirstLast: string=undefined;
    ChangeDateLocal: string = undefined;
}
export class Comments {
    Id: number=undefined;
    Comment: string=undefined;
    AdditionalInformation: string=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    CreateUserFirstLast: string=undefined;
    TypeId: number=undefined;
    CommentType: string=undefined;
    CommentTypeLabel: string=undefined;
    CommentTypeColor: string=undefined;
    SystemGenerated: boolean = undefined;
}
export class ComponentPaths {
    Path: string = undefined;
}
export class Components {
    Id: number=undefined;
    Path: string=undefined;
    Name: string=undefined;
    PathAndName: string = undefined;
}
export class ComponentsAssignedToSecurityView {
    AssociationId: number=undefined;
    Id: number=undefined;
    Path: string=undefined;
    Name: string=undefined;
    PathAndName: string = undefined;
}
export class ComponentsForSecurityRole {
    Id: number=undefined;
    Path: string=undefined;
    Name: string = undefined;
}
export class ComponentsForUser {
    Id: number=undefined;
    Path: string=undefined;
    Name: string = undefined;
}
export class ComponentsUnassignedToSecurityView {
    Id: number=undefined;
    Path: string=undefined;
    Name: string=undefined;
    PathAndName: string = undefined;
}
export class HighestNamedKeyValue {
    HighestItemValue: number=undefined;
    HighestSortIndexValue: number = undefined;
}
export class HighestSortIndexValue {
    HighestSortIndexValue: number = undefined;
}
export class KeyCategories {
    ItemValue: number=undefined;
    ItemText: string = undefined;
}
export class KeyTypes {
    KeyValue: number=undefined;
    KeyText: string = undefined;
}
export class LastLogins {
    UserId: string=undefined;
    DisplayName: string=undefined;
    LastAccessET: string = undefined;
}
export class LogUserStats {
    DisplayName: string=undefined;
    UserId: string=undefined;
    MostRecent: string=undefined;
    LastDay: number=undefined;
    LastMonth: number=undefined;
    LastYear: number=undefined;
    Total: number = undefined;
}
export class MaxMenuCategorySortIndex {
    MaxSortIndex: number = undefined;
}
export class MaxMenuEntrySortIndex {
    MaxSortIndex: number = undefined;
}
export class MaxMenuItemSortIndex {
    MaxSortIndex: number = undefined;
}
export class MaxNamedKeyFields {
    MaxKeyValue: number=undefined;
    MaxSortIndex: number = undefined;
}
export class MenuCategories {
    Id: number=undefined;
    Name: string=undefined;
    IconClass: string=undefined;
    SortIndex: number=undefined;
    SecurityCategoryName: string = undefined;
}
export class MenuEntries {
    EntityType: string=undefined;
    EntityId: number=undefined;
    Name: string=undefined;
    MenuCategoryId: number=undefined;
    IconClass: string=undefined;
    SortIndex: number=undefined;
    LoadType: string=undefined;
    SmartControlPath: string=undefined;
    SmartControlName: string=undefined;
    VuePath: string=undefined;
    Parameters: string=undefined;
    URL: string=undefined;
    Disabled: boolean = undefined;
}
export class MenuEntriesHierarchy {
    EntityId: number=undefined;
    EntityType: string=undefined;
    Name: string=undefined;
    MenuCategoryId: number=undefined;
    IconClass: string=undefined;
    SortIndex: number=undefined;
    LoadType: string=undefined;
    SmartControlPath: string=undefined;
    SmartControlName: string=undefined;
    VuePath: string=undefined;
    Parameters: string=undefined;
    URL: string=undefined;
    Disabled: boolean=undefined;
    TreeLevel: number=undefined;
    ParentId: number = undefined;
}
export class MenuItemsAssignedToSecurityView {
    AssociationId: number=undefined;
    Id: number=undefined;
    Name: string=undefined;
    MenuCategoryId: number=undefined;
    MenuCategoryName: string=undefined;
    MenuCategoryNameWithSecurity: string=undefined;
    AdjustedSortIndex: number=undefined;
    KeyValue: number=undefined;
    KeyText: string=undefined;
    ParentValue: string=undefined;
    ParentText: string=undefined;
    SortIndex: number = undefined;
}
export class MenuItemsAssignedToSecurityViewV2 {
    AssociationId: number=undefined;
    Id: number=undefined;
    Name: string=undefined;
    MenuCategoryId: number=undefined;
    MenuCategoryName: string=undefined;
    MenuCategoryNameWithSecurity: string=undefined;
    AdjustedSortIndex: number=undefined;
    KeyValue: number=undefined;
    KeyText: string=undefined;
    ParentText: string=undefined;
    ParentValue: number = undefined;
}
export class MenuItemsBySecurity {
    MenuCategoryId: number=undefined;
    MenuCategoryName: string=undefined;
    MenuCategoryOrderIndex: number=undefined;
    IconClass: string=undefined;
    MenuItemId: number=undefined;
    Name: string=undefined;
    Path: string=undefined;
    ControlName: string=undefined;
    Parameters: string=undefined;
    LoadType: string=undefined;
    URL: string = undefined;
}
export class MenuItemsForSecurityRole {
    Id: number=undefined;
    Name: string=undefined;
    MenuCategoryId: number=undefined;
    MenuCategoryName: string=undefined;
    MenuCategoryNameWithSecurity: string=undefined;
    ForDistinctOnly1: number=undefined;
    ForDistinctOnly2: number=undefined;
    KeyValue: number=undefined;
    KeyText: string=undefined;
    ParentText: string=undefined;
    ParentValue: number = undefined;
}
export class MenuItemsForUser {
    Id: number=undefined;
    Name: string=undefined;
    MenuCategoryId: number=undefined;
    MenuCategoryName: string=undefined;
    MenuCategoryNameWithSecurity: string=undefined;
    ForDistinctOnly1: number=undefined;
    ForDistinctOnly2: number=undefined;
    KeyValue: number=undefined;
    KeyText: string=undefined;
    ParentText: string=undefined;
    ParentValue: number = undefined;
}
export class MenuItemsForUserV2 {
    Id: number=undefined;
    Name: string=undefined;
    MenuCategoryId: number=undefined;
    MenuCategoryName: string=undefined;
    MenuCategoryNameWithSecurity: string=undefined;
    ForDistinctOnly1: number=undefined;
    ForDistinctOnly2: number=undefined;
    ForDistinctOnly3: number=undefined;
    ForDistinctOnly4: number=undefined;
    ForDistinctOnly5: number=undefined;
    KeyValue: number=undefined;
    KeyText: string=undefined;
    ParentText: string=undefined;
    ParentValue: number=undefined;
    ParentSortIndex: number=undefined;
    SortIndex: number=undefined;
    GrandParentSortIndex: number=undefined;
    _ParentSortIndex: number=undefined;
    _SortIndex: number=undefined;
    MenuItemSortIndex: number = undefined;
}
export class MenuItemsUnassignedToSecurityView {
    Id: number=undefined;
    Name: string=undefined;
    MenuCategoryId: number=undefined;
    MenuCategoryName: string=undefined;
    MenuCategoryNameWithSecurity: string=undefined;
    AdjustedSortIndex: number=undefined;
    KeyValue: number=undefined;
    KeyText: string=undefined;
    ParentValue: string=undefined;
    ParentText: string=undefined;
    SortIndex: number = undefined;
}
export class MenuItemsUnassignedToSecurityViewV2 {
    Id: number=undefined;
    Name: string=undefined;
    MenuCategoryName: string=undefined;
    MenuCategoryNameWithSecurity: string=undefined;
    AdjustedSortIndex: number=undefined;
    KeyValue: number=undefined;
    KeyText: string=undefined;
    ParentText: string=undefined;
    ParentValue: number = undefined;
}
export class NamedKeyCategories {
    Id: number=undefined;
    Name: string=undefined;
    DateCreated: string=undefined;
    DateModified: string=undefined;
    DateDeleted: string=undefined;
    CreatedBy: string=undefined;
    ModifiedBy: string=undefined;
    DeletedBy: string=undefined;
    Description: string = undefined;
}
export class NamedKeys {
    Id: number=undefined;
    NamedKeyTypeId: number=undefined;
    KeyValue: string=undefined;
    KeyValueInt: number=undefined;
    KeyText: string=undefined;
    SortIndex: number=undefined;
    ParentNamedKeyTypeId: number=undefined;
    ParentKeyValue: string=undefined;
    Abbreviation: string=undefined;
    AdditionalData1: string=undefined;
    AdditionalData2: string=undefined;
    AdditionalData3: string=undefined;
    AdditionalData1TryInt: number=undefined;
    AdditionalData2TryInt: number=undefined;
    AdditionalData3TryInt: number=undefined;
    IsDefaultItem: boolean=undefined;
    DateCreated: string=undefined;
    DateModified: string=undefined;
    CreatedBy: string=undefined;
    ModifiedBy: string=undefined;
    Description: string=undefined;
    KeyType: string=undefined;
    ParentKeyType: string=undefined;
    ParentKeyText: string=undefined;
    NamedKeyCategoryId: number=undefined;
    KeyCategory: string=undefined;
    TextDashDescription: string=undefined;
    TextParenDescription: string=undefined;
    OrderByText: boolean = undefined;
}
export class NamedKeyTypes {
    Id: number=undefined;
    Name: string = undefined;
}
export class NamedKeyTypeValues {
    Id: number=undefined;
    NamedKeyTypeName: string=undefined;
    NamedKeyCategoryId: number=undefined;
    RequireUnique: boolean=undefined;
    AutoIncrement: boolean=undefined;
    UniqueSeed: number=undefined;
    UniqueIncrement: number=undefined;
    ShowParent: boolean=undefined;
    ShowParentFields: boolean = undefined;
}
export class QueriesAssignedToSecurityView {
    AssociationId: number=undefined;
    Id: number=undefined;
    Type: string=undefined;
    Name: string=undefined;
    TypeAndName: string = undefined;
}
export class QueriesForSecurityRole {
    Id: number=undefined;
    Type: string=undefined;
    Name: string=undefined;
    ForDistinctOnly: string = undefined;
}
export class QueriesForUser {
    Id: number=undefined;
    Type: string=undefined;
    Name: string=undefined;
    ForDistinctOnly: string = undefined;
}
export class QueriesUnassignedToSecurityView {
    Id: number=undefined;
    Type: string=undefined;
    Name: string=undefined;
    TypeAndName: string = undefined;
}
export class QueryWhitelist {
    Id: number=undefined;
    Type: string=undefined;
    Name: string=undefined;
    SecurityUserParameter: string=undefined;
    AllowClientAccess: boolean=undefined;
    Explanation: string=undefined;
    Description: string = undefined;
}
export class SecurityAuditLog {
    Action: string=undefined;
    EntityType: string=undefined;
    EntityName: string=undefined;
    ToFromEntityType: string=undefined;
    ToFromEntityName: string=undefined;
    ChangedByLastFirst: string=undefined;
    ChangeDate: string = undefined;
}
export class SecurityLog {
    Id: number=undefined;
    SecurityLogType: number=undefined;
    SecurityLogTypeText: string=undefined;
    EntityType: string=undefined;
    EntityName: string=undefined;
    EntityId: number=undefined;
    EntityAction: string=undefined;
    Description: string=undefined;
    DateCreated: string=undefined;
    ActualUserId: string=undefined;
    ActualUserFirstLast: string=undefined;
    ActualUserLastFirst: string=undefined;
    ImpersonatedUserId: string=undefined;
    ImpersonatedUserFirstLast: string=undefined;
    ImpersonatedUserLastFirst: string = undefined;
}
export class SecurityRolesAssignedToNamedKeyCategory {
    nkc_srId: number=undefined;
    NamedKeyCategoryId: number=undefined;
    SecurityRole: number=undefined;
    SecurityRoleName: string = undefined;
}
export class SecurityRolesAssignedToSecurityView {
    AssociationId: number=undefined;
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    SecurityLevel: number=undefined;
    AdjustedSortIndex: number=undefined;
    ParentText: string=undefined;
    ParentValue: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityRolesAssignedToUser {
    AssociationId: number=undefined;
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    AdjustedSortIndex: number=undefined;
    SecurityRole: string=undefined;
    SecurityRoleName: string=undefined;
    ParentValue: string=undefined;
    ParentText: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityRolesForBusinessObject {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    ForDistinctOnly1: number=undefined;
    ForDistinctOnly2: number = undefined;
}
export class SecurityRolesForComponent {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    ForDistinctOnly1: number=undefined;
    ForDistinctOnly2: number=undefined;
    ParentValue: string=undefined;
    ParentText: string = undefined;
}
export class SecurityRolesForMenuItem {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    ForDistinctOnly1: number=undefined;
    ForDistinctOnly2: number=undefined;
    ParentValue: string=undefined;
    ParentText: string = undefined;
}
export class SecurityRolesForQuery {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    ForDistinctOnly1: number=undefined;
    ForDistinctOnly2: number = undefined;
}
export class SecurityRolesUnassignedToNamedKeyCategory {
    SecurityRole: string=undefined;
    SecurityRoleName: string = undefined;
}
export class SecurityRolesUnassignedToSecurityView {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    AdjustedSortIndex: number=undefined;
    ParentText: string=undefined;
    ParentValue: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityRolesUnassignedToUser {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    AdjustedSortIndex: number=undefined;
    SecurityRole: string=undefined;
    SecurityRoleName: string=undefined;
    ParentText: string=undefined;
    ParentValue: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityViewsAssignedToBusinessObject {
    AssociationId: number=undefined;
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    CanRead: boolean=undefined;
    CanUpdate: boolean=undefined;
    CanCreate: boolean=undefined;
    CanDelete: boolean=undefined;
    CanInvokeMethod: boolean=undefined;
    AdjustedSortIndex: number=undefined;
    SecurityView: string=undefined;
    SecurityViewText: string=undefined;
    SecurityCategoryText: string=undefined;
    SecurityCategory: string = undefined;
}
export class SecurityViewsAssignedToComponent {
    AssociationId: number=undefined;
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    AdjustedSortIndex: number=undefined;
    ParentText: string=undefined;
    ParentValue: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityViewsAssignedToMenuItem {
    AssociationId: number=undefined;
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    AdjustedSortIndex: number=undefined;
    ParentText: string=undefined;
    ParentValue: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityViewsAssignedToQuery {
    AssociationId: number=undefined;
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    AdjustedSortIndex: number=undefined;
    SecurityView: string=undefined;
    SecurityViewText: string=undefined;
    SecurityCategory: string=undefined;
    SecurityCategoryText: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityViewsAssignedToSecurityRole {
    AssociationId: number=undefined;
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    SecurityLevel: number=undefined;
    AdjustedSortIndex: number=undefined;
    ParentText: string=undefined;
    ParentValue: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityViewsForUser {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    ForDistinctOnly1: number=undefined;
    ForDistinctOnly2: number=undefined;
    ParentText: string=undefined;
    ParentValue: string = undefined;
}
export class SecurityViewsUnassignedToBusinessObject {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    AdjustedSortIndex: number=undefined;
    CanRead: number=undefined;
    CanUpdate: number=undefined;
    CanCreate: number=undefined;
    CanDelete: number=undefined;
    CanInvokeMethod: number=undefined;
    SecurityView: string=undefined;
    SecurityViewText: string=undefined;
    SecurityCategory: string=undefined;
    SecurityCategoryText: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityViewsUnassignedToComponent {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    AdjustedSortIndex: number=undefined;
    ParentText: string=undefined;
    ParentValue: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityViewsUnassignedToMenuItem {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    AdjustedSortIndex: number=undefined;
    ParentText: string=undefined;
    ParentValue: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityViewsUnassignedToQuery {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    AdjustedSortIndex: number=undefined;
    SecurityView: string=undefined;
    SecurityViewText: string=undefined;
    SecurityCategoryText: string=undefined;
    SecurityCategory: string=undefined;
    SortIndex: number = undefined;
}
export class SecurityViewsUnassignedToSecurityRole {
    KeyValue: string=undefined;
    KeyText: string=undefined;
    ParentKeyValue: string=undefined;
    ParentKeyText: string=undefined;
    AdjustedSortIndex: number=undefined;
    ParentText: string=undefined;
    ParentValue: string=undefined;
    SortIndex: number = undefined;
}
export class TopAzureDatabaseServiceLevelOverride {
    Id: number=undefined;
    NoChangeUntil: string = undefined;
}
export class Users {
    Id: number=undefined;
    UserID: string=undefined;
    DisplayName: string=undefined;
    FirstLast: string=undefined;
    LastFirst: string=undefined;
    WorkPhone: string=undefined;
    EmailAddress: string=undefined;
    Company: string = undefined;
}
export class UsersAssignedToSecurityRole {
    AssociationId: number=undefined;
    Id: number=undefined;
    FirstLast: string=undefined;
    LastFirst: string=undefined;
    EmailAddress: string=undefined;
    UserObjectId: number=undefined;
    KeyText: string=undefined;
    KeyValue: number=undefined;
    ParentValue: number=undefined;
    ParentText: string = undefined;
}
export class UsersForBusinessObject {
    Id: number=undefined;
    LastFirst: string = undefined;
}
export class UsersForComponent {
    Id: number=undefined;
    LastFirst: string=undefined;
    KeyValue: number=undefined;
    KeyText: string = undefined;
}
export class UsersForMenuItem {
    Id: number=undefined;
    LastFirst: string=undefined;
    KeyValue: number=undefined;
    KeyText: string = undefined;
}
export class UsersForQuery {
    Id: number=undefined;
    LastFirst: string = undefined;
}
export class UsersForSecurityView {
    Id: number=undefined;
    LastFirst: string = undefined;
}
export class UsersUnassignedToSecurityRole {
    Id: number=undefined;
    FirstLast: string=undefined;
    LastFirst: string=undefined;
    KeyValue: number=undefined;
    KeyText: string=undefined;
    ParentValue: number=undefined;
    ParentText: string = undefined;
}
export class WelcomePageMenuItems {
    Id: number=undefined;
    Name: string=undefined;
    LoadType: string=undefined;
    SmartControlPath: string=undefined;
    SmartControlName: string=undefined;
    VuePath: string=undefined;
    Parameters: string=undefined;
    URL: string=undefined;
    SortIndex: number = undefined;
}

// Parameter interfaces:

export interface GetBusinessObjectsParameters {
    SchemaName: string;
}

export interface GetBusinessObjectsAssignedToSecurityViewParameters {
    SecurityView: number;
}

export interface GetBusinessObjectsForSecurityRoleParameters {
    SecurityRole: number;
}

export interface GetBusinessObjectsForUserParameters {
    UserObjectId: number;
}

export interface GetBusinessObjectsUnassignedToSecurityViewParameters {
    SecurityView: number;
}

export interface GetChangeLogParameters {
    Schema: string;
    Table: string;
    Field: string;
    EntityId: number;
}

export interface GetCommentsParameters {
    EntityType: string;
    EntityId: number;
    Entity2Type: string;
    Entity2Id: number;
}

export interface GetComponentsParameters {
    Path: string;
}

export interface GetComponentsAssignedToSecurityViewParameters {
    SecurityView: number;
}

export interface GetComponentsForSecurityRoleParameters {
    SecurityRole: number;
}

export interface GetComponentsForUserParameters {
    UserObjectId: number;
}

export interface GetComponentsUnassignedToSecurityViewParameters {
    SecurityView: number;
}

export interface GetHighestNamedKeyValueParameters {
    KeyType: string;
}

export interface GetHighestSortIndexValueParameters {
    MenuCategory: number;
}

export interface GetKeyTypesParameters {
    NamedKeyCategoryId: number;
}

export interface GetLogUserStatsParameters {
    Database: string;
    Schema: string;
    Caller: string;
    Event: string;
    MinMilliseconds: number;
}

export interface GetMaxMenuEntrySortIndexParameters {
    MenuCategoryId: number;
}

export interface GetMaxMenuItemSortIndexParameters {
    MenuCategoryId: number;
}

export interface GetMaxNamedKeyFieldsParameters {
    KeyType: string;
}

export interface GetMenuEntriesParameters {
    MenuCategoryId: number;
}

export interface GetMenuEntriesHierarchyParameters {
    ShowDisabledMenuItems: boolean;
}

export interface GetMenuItemByRouteParameters {
    Path: string;
    Parameters: string;
}

export interface GetMenuItemsAssignedToSecurityViewParameters {
    SecurityView: number;
    SecurityCategory: number;
}

export interface GetMenuItemsAssignedToSecurityViewV2Parameters {
    SecurityView: number;
}

export interface GetMenuItemsBySecurityParameters {
    MenuCategoryId: number;
    GroupByCategory: boolean;
}

export interface GetMenuItemsForSecurityRoleParameters {
    SecurityRole: number;
    SecurityCategory: number;
}

export interface GetMenuItemsForUserParameters {
    UserObjectId: number;
    SecurityCategory: number;
}

export interface GetMenuItemsForUserV2Parameters {
    UserObjectId: number;
    SecurityCategory: number;
}

export interface GetMenuItemsUnassignedToSecurityViewParameters {
    SecurityView: number;
    SecurityCategory: number;
}

export interface GetMenuItemsUnassignedToSecurityViewV2Parameters {
    SecurityView: number;
}

export interface GetNamedKeysParameters {
    KeyType: string;
    ParentKeyType: string;
    ParentKeyValue: number;
    KeyCategory: string;
    NamedKeyCategoryId: number;
    NamedKeyTypeId: number;
    KeyValue: number;
    OrderByText: boolean;
    IncludeDotSubstitutionRows: boolean;
}

export interface GetNamedKeyTypesParameters {
    NamedKeyCategoryId: number;
}

export interface GetNamedKeyTypeValuesParameters {
    NamedKeyTypeName: string;
}

export interface GetQueriesAssignedToSecurityViewParameters {
    SecurityView: number;
}

export interface GetQueriesForSecurityRoleParameters {
    SecurityRole: number;
}

export interface GetQueriesForUserParameters {
    UserObjectId: number;
}

export interface GetQueriesUnassignedToSecurityViewParameters {
    SecurityView: number;
}

export interface GetQueryWhitelistParameters {
    Type: string;
}

export interface GetSecurityAuditLogParameters {
    ShowAll: boolean;
}

export interface GetSecurityLogParameters {
    SecurityLogType: number;
    MaxNumResults: number;
}

export interface GetSecurityRolesAssignedToNamedKeyCategoryParameters {
    NamedKeyCategoryId: number;
}

export interface GetSecurityRolesAssignedToSecurityViewParameters {
    SecurityView: number;
    SecurityCategory: number;
}

export interface GetSecurityRolesAssignedToUserParameters {
    UserObjectId: number;
    SecurityCategory: number;
}

export interface GetSecurityRolesForBusinessObjectParameters {
    BusinessObjectId: number;
    SecurityCategory: number;
}

export interface GetSecurityRolesForComponentParameters {
    ComponentId: number;
    SecurityCategory: number;
}

export interface GetSecurityRolesForMenuItemParameters {
    MenuItemId: number;
    SecurityCategory: number;
}

export interface GetSecurityRolesForQueryParameters {
    QueryWhitelistId: number;
    SecurityCategory: number;
}

export interface GetSecurityRolesUnassignedToNamedKeyCategoryParameters {
    NamedKeyCategoryId: number;
}

export interface GetSecurityRolesUnassignedToSecurityViewParameters {
    SecurityView: number;
    SecurityCategory: number;
}

export interface GetSecurityRolesUnassignedToUserParameters {
    UserObjectId: number;
    SecurityCategory: number;
}

export interface GetSecurityViewsAssignedToBusinessObjectParameters {
    BusinessObjectId: number;
    SecurityCategory: number;
}

export interface GetSecurityViewsAssignedToComponentParameters {
    ComponentId: number;
    SecurityCategory: number;
}

export interface GetSecurityViewsAssignedToMenuItemParameters {
    MenuItemId: number;
    SecurityCategory: number;
}

export interface GetSecurityViewsAssignedToQueryParameters {
    QueryWhitelistId: number;
    SecurityCategory: number;
}

export interface GetSecurityViewsAssignedToSecurityRoleParameters {
    SecurityRole: number;
    SecurityCategory: number;
}

export interface GetSecurityViewsForUserParameters {
    UserObjectId: number;
    SecurityCategory: number;
}

export interface GetSecurityViewsUnassignedToBusinessObjectParameters {
    BusinessObjectId: number;
    SecurityCategory: number;
}

export interface GetSecurityViewsUnassignedToComponentParameters {
    ComponentId: number;
    SecurityCategory: number;
}

export interface GetSecurityViewsUnassignedToMenuItemParameters {
    MenuItemId: number;
    SecurityCategory: number;
}

export interface GetSecurityViewsUnassignedToQueryParameters {
    QueryWhitelistId: number;
    SecurityCategory: number;
}

export interface GetSecurityViewsUnassignedToSecurityRoleParameters {
    SecurityRole: number;
    SecurityCategory: number;
}

export interface GetUsersParameters {
    FirstName: string;
    LastName: string;
}

export interface GetUsersAssignedToSecurityRoleParameters {
    SecurityRole: number;
    OrderByFirstName: boolean;
}

export interface GetUsersForBusinessObjectParameters {
    BusinessObjectId: number;
}

export interface GetUsersForComponentParameters {
    ComponentId: number;
}

export interface GetUsersForMenuItemParameters {
    MenuItemId: number;
}

export interface GetUsersForQueryParameters {
    QueryWhitelistId: number;
}

export interface GetUsersForSecurityViewParameters {
    SecurityView: number;
}

export interface GetUsersUnassignedToSecurityRoleParameters {
    SecurityRole: number;
}

export class SystemService {

  GetAzureDatabaseServiceLevel (): Promise<AzureDatabaseServiceLevel[]> {
    return smartData({
        storedProcName: 'system.GetAzureDatabaseServiceLevel',
        params: {}
      });
    }

  GetBusinessObjects (params: GetBusinessObjectsParameters): Promise<BusinessObjects[]> {
    return smartData({
        storedProcName: 'System.GetBusinessObjects',
        params: params
      });
    }

  GetBusinessObjectsAssignedToSecurityView (params: GetBusinessObjectsAssignedToSecurityViewParameters): Promise<BusinessObjectsAssignedToSecurityView[]> {
    return smartData({
        storedProcName: 'System.GetBusinessObjectsAssignedToSecurityView',
        params: params
      });
    }

  GetBusinessObjectSchemas (): Promise<BusinessObjectSchemas[]> {
    return smartData({
        storedProcName: 'System.GetBusinessObjectSchemas',
        params: {}
      });
    }

  GetBusinessObjectsForSecurityRole (params: GetBusinessObjectsForSecurityRoleParameters): Promise<BusinessObjectsForSecurityRole[]> {
    return smartData({
        storedProcName: 'System.GetBusinessObjectsForSecurityRole',
        params: params
      });
    }

  GetBusinessObjectsForUser (params: GetBusinessObjectsForUserParameters): Promise<BusinessObjectsForUser[]> {
    return smartData({
        storedProcName: 'System.GetBusinessObjectsForUser',
        params: params
      });
    }

  GetBusinessObjectsUnassignedToSecurityView (params: GetBusinessObjectsUnassignedToSecurityViewParameters): Promise<BusinessObjectsUnassignedToSecurityView[]> {
    return smartData({
        storedProcName: 'System.GetBusinessObjectsUnassignedToSecurityView',
        params: params
      });
    }

  GetChangeLog (params: GetChangeLogParameters): Promise<ChangeLog[]> {
    return smartData({
        storedProcName: 'system.GetChangeLog',
        params: params
      });
    }

  GetComments (params: GetCommentsParameters): Promise<Comments[]> {
    return smartData({
        storedProcName: 'system.GetComments',
        params: params
      });
    }

  GetComponentPaths (): Promise<ComponentPaths[]> {
    return smartData({
        storedProcName: 'System.GetComponentPaths',
        params: {}
      });
    }

  GetComponents (params: GetComponentsParameters): Promise<Components[]> {
    return smartData({
        storedProcName: 'System.GetComponents',
        params: params
      });
    }

  GetComponentsAssignedToSecurityView (params: GetComponentsAssignedToSecurityViewParameters): Promise<ComponentsAssignedToSecurityView[]> {
    return smartData({
        storedProcName: 'System.GetComponentsAssignedToSecurityView',
        params: params
      });
    }

  GetComponentsForSecurityRole (params: GetComponentsForSecurityRoleParameters): Promise<ComponentsForSecurityRole[]> {
    return smartData({
        storedProcName: 'System.GetComponentsForSecurityRole',
        params: params
      });
    }

  GetComponentsForUser (params: GetComponentsForUserParameters): Promise<ComponentsForUser[]> {
    return smartData({
        storedProcName: 'System.GetComponentsForUser',
        params: params
      });
    }

  GetComponentsUnassignedToSecurityView (params: GetComponentsUnassignedToSecurityViewParameters): Promise<ComponentsUnassignedToSecurityView[]> {
    return smartData({
        storedProcName: 'System.GetComponentsUnassignedToSecurityView',
        params: params
      });
    }

  GetHighestNamedKeyValue (params: GetHighestNamedKeyValueParameters): Promise<HighestNamedKeyValue[]> {
    return smartData({
        storedProcName: 'system.GetHighestNamedKeyValue',
        params: params
      });
    }

  GetHighestSortIndexValue (params: GetHighestSortIndexValueParameters): Promise<HighestSortIndexValue[]> {
    return smartData({
        storedProcName: 'system.GetHighestSortIndexValue',
        params: params
      });
    }

  GetKeyCategories (): Promise<KeyCategories[]> {
    return smartData({
        storedProcName: 'system.GetKeyCategories',
        params: {}
      });
    }

  GetKeyTypes (params: GetKeyTypesParameters): Promise<KeyTypes[]> {
    return smartData({
        storedProcName: 'system.GetKeyTypes',
        params: params
      });
    }

  GetLastLogins (): Promise<LastLogins[]> {
    return smartData({
        storedProcName: 'system.GetLastLogins',
        params: {}
      });
    }

  GetLogUserStats (params: GetLogUserStatsParameters): Promise<LogUserStats[]> {
    return smartData({
        storedProcName: 'system.GetLogUserStats',
        params: params
      });
    }

  GetMaxMenuCategorySortIndex (): Promise<MaxMenuCategorySortIndex[]> {
    return smartData({
        storedProcName: 'system.GetMaxMenuCategorySortIndex',
        params: {}
      });
    }

  GetMaxMenuEntrySortIndex (params: GetMaxMenuEntrySortIndexParameters): Promise<MaxMenuEntrySortIndex[]> {
    return smartData({
        storedProcName: 'system.GetMaxMenuEntrySortIndex',
        params: params
      });
    }

  GetMaxMenuItemSortIndex (params: GetMaxMenuItemSortIndexParameters): Promise<MaxMenuItemSortIndex[]> {
    return smartData({
        storedProcName: 'system.GetMaxMenuItemSortIndex',
        params: params
      });
    }

  GetMaxNamedKeyFields (params: GetMaxNamedKeyFieldsParameters): Promise<MaxNamedKeyFields[]> {
    return smartData({
        storedProcName: 'system.GetMaxNamedKeyFields',
        params: params
      });
    }

  GetMenuCategories (): Promise<MenuCategories[]> {
    return smartData({
        storedProcName: 'system.GetMenuCategories',
        params: {}
      });
    }

  GetMenuEntries (params: GetMenuEntriesParameters): Promise<MenuEntries[]> {
    return smartData({
        storedProcName: 'system.GetMenuEntries',
        params: params
      });
    }

  GetMenuEntriesHierarchy (params: GetMenuEntriesHierarchyParameters): Promise<MenuEntriesHierarchy[]> {
    return smartData({
        storedProcName: 'system.GetMenuEntriesHierarchy',
        params: params
      });
    }

  GetMenuItemByRoute (params: GetMenuItemByRouteParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'system.GetMenuItemByRoute',
        params: params
      });
    }

  GetMenuItemsAssignedToSecurityView (params: GetMenuItemsAssignedToSecurityViewParameters): Promise<MenuItemsAssignedToSecurityView[]> {
    return smartData({
        storedProcName: 'System.GetMenuItemsAssignedToSecurityView',
        params: params
      });
    }

  GetMenuItemsAssignedToSecurityViewV2 (params: GetMenuItemsAssignedToSecurityViewV2Parameters): Promise<MenuItemsAssignedToSecurityViewV2[]> {
    return smartData({
        storedProcName: 'system.GetMenuItemsAssignedToSecurityViewV2',
        params: params
      });
    }

  GetMenuItemsBySecurity (params: GetMenuItemsBySecurityParameters): Promise<MenuItemsBySecurity[]> {
    return smartData({
        storedProcName: 'system.GetMenuItemsBySecurity',
        params: params
      });
    }

  GetMenuItemsForSecurityRole (params: GetMenuItemsForSecurityRoleParameters): Promise<MenuItemsForSecurityRole[]> {
    return smartData({
        storedProcName: 'System.GetMenuItemsForSecurityRole',
        params: params
      });
    }

  GetMenuItemsForUser (params: GetMenuItemsForUserParameters): Promise<MenuItemsForUser[]> {
    return smartData({
        storedProcName: 'System.GetMenuItemsForUser',
        params: params
      });
    }

  GetMenuItemsForUserV2 (params: GetMenuItemsForUserV2Parameters): Promise<MenuItemsForUserV2[]> {
    return smartData({
        storedProcName: 'system.GetMenuItemsForUserV2',
        params: params
      });
    }

  GetMenuItemsUnassignedToSecurityView (params: GetMenuItemsUnassignedToSecurityViewParameters): Promise<MenuItemsUnassignedToSecurityView[]> {
    return smartData({
        storedProcName: 'System.GetMenuItemsUnassignedToSecurityView',
        params: params
      });
    }

  GetMenuItemsUnassignedToSecurityViewV2 (params: GetMenuItemsUnassignedToSecurityViewV2Parameters): Promise<MenuItemsUnassignedToSecurityViewV2[]> {
    return smartData({
        storedProcName: 'system.GetMenuItemsUnassignedToSecurityViewV2',
        params: params
      });
    }

  GetNamedKeyCategories (): Promise<NamedKeyCategories[]> {
    return smartData({
        storedProcName: 'System.GetNamedKeyCategories',
        params: {}
      });
    }

  GetNamedKeys (params: GetNamedKeysParameters): Promise<NamedKeys[]> {
    return smartData({
        storedProcName: 'system.GetNamedKeys',
        params: params
      });
    }

  GetNamedKeyTypes (params: GetNamedKeyTypesParameters): Promise<NamedKeyTypes[]> {
    return smartData({
        storedProcName: 'System.GetNamedKeyTypes',
        params: params
      });
    }

  GetNamedKeyTypeValues (params: GetNamedKeyTypeValuesParameters): Promise<NamedKeyTypeValues[]> {
    return smartData({
        storedProcName: 'system.GetNamedKeyTypeValues',
        params: params
      });
    }

  GetQueriesAssignedToSecurityView (params: GetQueriesAssignedToSecurityViewParameters): Promise<QueriesAssignedToSecurityView[]> {
    return smartData({
        storedProcName: 'System.GetQueriesAssignedToSecurityView',
        params: params
      });
    }

  GetQueriesForSecurityRole (params: GetQueriesForSecurityRoleParameters): Promise<QueriesForSecurityRole[]> {
    return smartData({
        storedProcName: 'System.GetQueriesForSecurityRole',
        params: params
      });
    }

  GetQueriesForUser (params: GetQueriesForUserParameters): Promise<QueriesForUser[]> {
    return smartData({
        storedProcName: 'System.GetQueriesForUser',
        params: params
      });
    }

  GetQueriesUnassignedToSecurityView (params: GetQueriesUnassignedToSecurityViewParameters): Promise<QueriesUnassignedToSecurityView[]> {
    return smartData({
        storedProcName: 'System.GetQueriesUnassignedToSecurityView',
        params: params
      });
    }

  GetQueryWhitelist (params: GetQueryWhitelistParameters): Promise<QueryWhitelist[]> {
    return smartData({
        storedProcName: 'System.GetQueryWhitelist',
        params: params
      });
    }

  GetSecurityAuditLog (params: GetSecurityAuditLogParameters): Promise<SecurityAuditLog[]> {
    return smartData({
        storedProcName: 'system.GetSecurityAuditLog',
        params: params
      });
    }

  GetSecurityLog (params: GetSecurityLogParameters): Promise<SecurityLog[]> {
    return smartData({
        storedProcName: 'System.GetSecurityLog',
        params: params
      });
    }

  GetSecurityRolesAssignedToNamedKeyCategory (params: GetSecurityRolesAssignedToNamedKeyCategoryParameters): Promise<SecurityRolesAssignedToNamedKeyCategory[]> {
    return smartData({
        storedProcName: 'system.GetSecurityRolesAssignedToNamedKeyCategory',
        params: params
      });
    }

  GetSecurityRolesAssignedToSecurityView (params: GetSecurityRolesAssignedToSecurityViewParameters): Promise<SecurityRolesAssignedToSecurityView[]> {
    return smartData({
        storedProcName: 'System.GetSecurityRolesAssignedToSecurityView',
        params: params
      });
    }

  GetSecurityRolesAssignedToUser (params: GetSecurityRolesAssignedToUserParameters): Promise<SecurityRolesAssignedToUser[]> {
    return smartData({
        storedProcName: 'system.GetSecurityRolesAssignedToUser',
        params: params
      });
    }

  GetSecurityRolesForBusinessObject (params: GetSecurityRolesForBusinessObjectParameters): Promise<SecurityRolesForBusinessObject[]> {
    return smartData({
        storedProcName: 'System.GetSecurityRolesForBusinessObject',
        params: params
      });
    }

  GetSecurityRolesForComponent (params: GetSecurityRolesForComponentParameters): Promise<SecurityRolesForComponent[]> {
    return smartData({
        storedProcName: 'System.GetSecurityRolesForComponent',
        params: params
      });
    }

  GetSecurityRolesForMenuItem (params: GetSecurityRolesForMenuItemParameters): Promise<SecurityRolesForMenuItem[]> {
    return smartData({
        storedProcName: 'system.GetSecurityRolesForMenuItem',
        params: params
      });
    }

  GetSecurityRolesForQuery (params: GetSecurityRolesForQueryParameters): Promise<SecurityRolesForQuery[]> {
    return smartData({
        storedProcName: 'System.GetSecurityRolesForQuery',
        params: params
      });
    }

  GetSecurityRolesUnassignedToNamedKeyCategory (params: GetSecurityRolesUnassignedToNamedKeyCategoryParameters): Promise<SecurityRolesUnassignedToNamedKeyCategory[]> {
    return smartData({
        storedProcName: 'system.GetSecurityRolesUnassignedToNamedKeyCategory',
        params: params
      });
    }

  GetSecurityRolesUnassignedToSecurityView (params: GetSecurityRolesUnassignedToSecurityViewParameters): Promise<SecurityRolesUnassignedToSecurityView[]> {
    return smartData({
        storedProcName: 'System.GetSecurityRolesUnassignedToSecurityView',
        params: params
      });
    }

  GetSecurityRolesUnassignedToUser (params: GetSecurityRolesUnassignedToUserParameters): Promise<SecurityRolesUnassignedToUser[]> {
    return smartData({
        storedProcName: 'system.GetSecurityRolesUnassignedToUser',
        params: params
      });
    }

  GetSecurityViewsAssignedToBusinessObject (params: GetSecurityViewsAssignedToBusinessObjectParameters): Promise<SecurityViewsAssignedToBusinessObject[]> {
    return smartData({
        storedProcName: 'System.GetSecurityViewsAssignedToBusinessObject',
        params: params
      });
    }

  GetSecurityViewsAssignedToComponent (params: GetSecurityViewsAssignedToComponentParameters): Promise<SecurityViewsAssignedToComponent[]> {
    return smartData({
        storedProcName: 'System.GetSecurityViewsAssignedToComponent',
        params: params
      });
    }

  GetSecurityViewsAssignedToMenuItem (params: GetSecurityViewsAssignedToMenuItemParameters): Promise<SecurityViewsAssignedToMenuItem[]> {
    return smartData({
        storedProcName: 'system.GetSecurityViewsAssignedToMenuItem',
        params: params
      });
    }

  GetSecurityViewsAssignedToQuery (params: GetSecurityViewsAssignedToQueryParameters): Promise<SecurityViewsAssignedToQuery[]> {
    return smartData({
        storedProcName: 'System.GetSecurityViewsAssignedToQuery',
        params: params
      });
    }

  GetSecurityViewsAssignedToSecurityRole (params: GetSecurityViewsAssignedToSecurityRoleParameters): Promise<SecurityViewsAssignedToSecurityRole[]> {
    return smartData({
        storedProcName: 'System.GetSecurityViewsAssignedToSecurityRole',
        params: params
      });
    }

  GetSecurityViewsForUser (params: GetSecurityViewsForUserParameters): Promise<SecurityViewsForUser[]> {
    return smartData({
        storedProcName: 'System.GetSecurityViewsForUser',
        params: params
      });
    }

  GetSecurityViewsUnassignedToBusinessObject (params: GetSecurityViewsUnassignedToBusinessObjectParameters): Promise<SecurityViewsUnassignedToBusinessObject[]> {
    return smartData({
        storedProcName: 'System.GetSecurityViewsUnassignedToBusinessObject',
        params: params
      });
    }

  GetSecurityViewsUnassignedToComponent (params: GetSecurityViewsUnassignedToComponentParameters): Promise<SecurityViewsUnassignedToComponent[]> {
    return smartData({
        storedProcName: 'System.GetSecurityViewsUnassignedToComponent',
        params: params
      });
    }

  GetSecurityViewsUnassignedToMenuItem (params: GetSecurityViewsUnassignedToMenuItemParameters): Promise<SecurityViewsUnassignedToMenuItem[]> {
    return smartData({
        storedProcName: 'system.GetSecurityViewsUnassignedToMenuItem',
        params: params
      });
    }

  GetSecurityViewsUnassignedToQuery (params: GetSecurityViewsUnassignedToQueryParameters): Promise<SecurityViewsUnassignedToQuery[]> {
    return smartData({
        storedProcName: 'System.GetSecurityViewsUnassignedToQuery',
        params: params
      });
    }

  GetSecurityViewsUnassignedToSecurityRole (params: GetSecurityViewsUnassignedToSecurityRoleParameters): Promise<SecurityViewsUnassignedToSecurityRole[]> {
    return smartData({
        storedProcName: 'System.GetSecurityViewsUnassignedToSecurityRole',
        params: params
      });
    }

  GetTopAzureDatabaseServiceLevelOverride (): Promise<TopAzureDatabaseServiceLevelOverride[]> {
    return smartData({
        storedProcName: 'system.GetTopAzureDatabaseServiceLevelOverride',
        params: {}
      });
    }

  GetUsers (params: GetUsersParameters): Promise<Users[]> {
    return smartData({
        storedProcName: 'system.GetUsers',
        params: params
      });
    }

  GetUsersAssignedToSecurityRole (params: GetUsersAssignedToSecurityRoleParameters): Promise<UsersAssignedToSecurityRole[]> {
    return smartData({
        storedProcName: 'system.GetUsersAssignedToSecurityRole',
        params: params
      });
    }

  GetUsersForBusinessObject (params: GetUsersForBusinessObjectParameters): Promise<UsersForBusinessObject[]> {
    return smartData({
        storedProcName: 'System.GetUsersForBusinessObject',
        params: params
      });
    }

  GetUsersForComponent (params: GetUsersForComponentParameters): Promise<UsersForComponent[]> {
    return smartData({
        storedProcName: 'System.GetUsersForComponent',
        params: params
      });
    }

  GetUsersForMenuItem (params: GetUsersForMenuItemParameters): Promise<UsersForMenuItem[]> {
    return smartData({
        storedProcName: 'system.GetUsersForMenuItem',
        params: params
      });
    }

  GetUsersForQuery (params: GetUsersForQueryParameters): Promise<UsersForQuery[]> {
    return smartData({
        storedProcName: 'System.GetUsersForQuery',
        params: params
      });
    }

  GetUsersForSecurityView (params: GetUsersForSecurityViewParameters): Promise<UsersForSecurityView[]> {
    return smartData({
        storedProcName: 'System.GetUsersForSecurityView',
        params: params
      });
    }

  GetUsersUnassignedToSecurityRole (params: GetUsersUnassignedToSecurityRoleParameters): Promise<UsersUnassignedToSecurityRole[]> {
    return smartData({
        storedProcName: 'system.GetUsersUnassignedToSecurityRole',
        params: params
      });
    }

  GetWelcomePageMenuItems (): Promise<WelcomePageMenuItems[]> {
    return smartData({
        storedProcName: 'system.GetWelcomePageMenuItems',
        params: {}
      });
    }
}
