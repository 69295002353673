
import Vue from 'vue';
import Component from 'vue-class-component';
import AddressEdit from '@/views/Address/AddressEdit.vue';
import * as VamService from './../../services/DAL/vamService';
import ElementUI from 'element-ui';

@Component({
    components: {
        AddressEdit
    }
})
export default class AddressList extends Vue {
  $refs: {
    tblAddressList: ElementUI.Table
  }
    //#region Data
    private _vamService: VamService.VamService;

    addresses: VamService.Addresses[] =  [];
    selectedAddress = null;
    showAddressEdit = false;
    showAddressCreate = false;
    isLoading = false;

    get showDetailColumns(): boolean {
        return (
            ['xs', 'sm', 'md'].indexOf(this.$getBootstrapSize()) > 0 ||
            !this.showAddressEdit
        );
    }
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();
    }

    async mounted() {
        this.fetchAddresses();
    }
    //#endregion Lifecycle

    //#region Methods
    selectAddress(address) {
        if (address) {
            this.showAddressEdit = false;
            this.$nextTick(() => {
                this.showAddressEdit = true;
                this.selectedAddress = address;
            });
        }
    }

    closeAddress() {
        this.showAddressEdit = false;
        this.selectedAddress = null;
        this.$refs.tblAddressList.setCurrentRow(null);
    }

    onDeleteAddress() {
        this.closeAddress();
        this.fetchAddresses();
    }

    async fetchAddresses() {
        this.isLoading = true;
        const getAddressesParameters = {} as VamService.GetAddressesParameters;
        this.addresses = await this._vamService.GetAddresses(
            getAddressesParameters
        );
        this.isLoading = false;
    }
    //#endregion Methods
}
