
import { Vue, Component, Prop, PropSync, Watch, Ref, VModel } from 'vue-property-decorator';
import * as WorkflowService from '../../services/DAL/workflowService';
import * as LiqService from '../../services/DAL/liq_Service';
import * as VamService from '../../services/DAL/vamService';
import * as SystemService from '../../services/DAL/systemService';
import debounce from 'lodash/debounce';

import ElementUI from 'element-ui';

@Component({
  components: {
  }
})
export default class PaymentConfirmation extends Vue {

  //#region Data
  private _workflowService: WorkflowService.WorkflowService;
  private _liqService: LiqService.LiqService;
  private _vamService: VamService.VamService;
  private _systemService: SystemService.SystemService;
  //#endregion Private declarations for Services

  //#region Props
  @Prop({ type: Number, required: true }) readonly approvalId!: number;

  isLoading = false;
  securityLevel_: number = null;
  approvalBatch = {} as WorkflowService.PaymentConfirmationBatch;
  smsComplete = false;
  smsDelivered = false;
  smsDeliveredMessage: string = '';
  smsIterations: number = 0;
  commentList = [] as SystemService.Comments[];
  
//#region Lifecycle
  async created() {
    this._workflowService = new WorkflowService.WorkflowService();
    this._liqService = new LiqService.LiqService();
    this._vamService = new VamService.VamService();
    this._systemService = new SystemService.SystemService();

    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), //placeholder.  probably need something more specific
      0
    );
    await this.getApprovalBatch();
    if (this.approvalBatch.LastSMSID) this.checkForSMSDelivered(this.approvalBatch.LastSMSID);
  }

  //#endregion Lifecycle

  //#region Watches
    @Watch("approvalId")
    onChange_approvalId(val: number, oldVal: number) {
        this.getApprovalBatch();
    }
  //#endregion Watches

  //#region Computed
  get isActionable(): boolean {
    return (!!this.approvalBatch && this.approvalBatch.IsInitiated && this.approvalBatch.Approved == null && this.approvalBatch.ProvisionalApproval == null && this.approvalBatch.InstantUserIsApprover)
  }

  //#endregion Computed


  //#region Methods

  async getApprovalBatch(){
    try{
      let existingApproved: boolean;
      if (this.approvalBatch && Object.keys(this.approvalBatch).length){
        existingApproved = this.approvalBatch.Approved
      }
      const parms = {} as WorkflowService.GetPaymentConfirmationBatchParameters;
      parms.ApprovalId = this.approvalId;
      this.isLoading = true;
      const res = await this._workflowService.GetPaymentConfirmationBatch(parms);
      this.isLoading = false;
      if (res && res.length == 1){
        this.approvalBatch = res[0];
      }
      else {
        this.approvalBatch = {} as WorkflowService.PaymentConfirmationBatch;
      }
      if (existingApproved != this.approvalBatch.Approved && !(existingApproved == null && this.approvalBatch.Approved == null)){
            this.$emit('status-changed', {Approved: this.approvalBatch.Approved});
      }
    }
    catch(e){
      console.error(e);
      this.approvalBatch = {} as WorkflowService.PaymentConfirmationBatch;
    }
  }

  async sendTextAndSMS(){
    const params = {} as LiqService.SendPaymentConfirmationEmailAndSMSParameters;
    params.ApprovalId = this.approvalId;
    let smsId: number;
    this.isLoading = true;
    const result = await this._liqService.SendPaymentConfirmationEmailAndSMS(params);
    this.isLoading = false;
    if(result && result.length == 1){
      smsId = result[0].SMSId;
      if (smsId) this.checkForSMSDelivered(smsId); // non smsId will be returned if the method is Email.
    }
    await this.getApprovalBatch();
    this.$emit('refresh-list');
  }
  async checkForSMSDelivered(smsId: number){
    if (this.smsIterations == 0) this.smsDeliveredMessage = 'Checking for SMS Delivery Confirmation ...'
    
    const parms = {} as VamService.GetSMSDeliveryStatusParameters;
    parms.SMSId = smsId;
    while(!this.smsComplete && this.smsIterations < 100){
      this.smsIterations++;
      const result = await this._vamService.GetSMSDeliveryStatus(parms);
      if(result && result.length == 1){
        this.smsDelivered = result[0].Delivered;
        this.smsComplete = result[0].Complete;
        if (this.smsDelivered) this.smsDeliveredMessage = 'Delivery Confirmed'
        else if (this.smsComplete) this.smsDeliveredMessage = result[0].DeliveryStatusDetails

      }
      if (!this.smsComplete && this.smsIterations < 100){
        console.log('waiting 5 seconds to get SMS Delivery Status again');
        this.smsDeliveredMessage = this.smsDeliveredMessage + '.'
        await this.$asyncWait(5000);
      }
      else if (this.smsIterations == 100) {
        this.$notify.warning('Attempted to get SMS Delivery Confirmation 100 times; Giving up.');
        this.smsDeliveredMessage = 'Unable to confirm.'
      }
    }
  }
  async recordDidNotReach(){
    const comment = await this.promptAction('AttemptedPaymentConfirmCall');
    if (this.approvalBatch == null) this.approvalBatch.CallAttempts = 0;
    this.approvalBatch.CallAttempts++;
  }
  async recordProvisionalConfirm(){
    const comment = await this.promptAction('ProvisionalPaymentConfirm');
    this.approvalBatch.ProvisionalApproval = true;
    this.approvalBatch.ProvisionalApprovalComment = comment;
    this.approvalBatch.ProvisionalApprovalOn = this.$dayjs().utc().format();
    this.$emit('status-changed', {Approved: true});
  }
  async recordReject(){
    const comment = await this.promptAction('ProvisionalPaymentDecline');
    this.approvalBatch.ProvisionalApproval = false;
    this.approvalBatch.ProvisionalApprovalComment = comment;
    this.approvalBatch.ProvisionalApprovalOn = this.$dayjs().utc().format();
    this.$emit('status-changed', {Approved: false});
  }
  async promptAction(type: string): Promise<string>{
    let prompt: string;
    let title: string;
    let required: boolean;
    switch (type) {
      case 'AttemptedPaymentConfirmCall':
        prompt = 'Enter comment for Attempted Call (Optional)';
        title = 'Attempted Call';
        required = false;
        break;
      case 'ProvisionalPaymentConfirm':
        prompt = 'Enter comment for Provisional Confirm (Optional)';
        title = 'Provisional Confirm';
        required = false;
        break;
      case 'ProvisionalPaymentDecline':
        prompt = 'Enter comment for Decline (REQUIRED)';
        title = 'DECLINE';
        required = true;
        break;
    }

    let comment: string = null;
    const conf: any  = await this.$prompt(prompt, title
      , { // MessageBoxData Type must be incorrectly defined, so declaring it as any
        confirmButtonText: 'OK',
        showCancelButton: false,
        inputValidator: function(input){
            if (required && !input) return 'Comment is required.';
            if (required && !!input && input.length > 4000) return 'Comment is required and must be no longer than 4,000 characters.'
            if (!!input && input.length > 4000) return 'Comment must be no longer than 4,000 characters.'
            else return true;
            }
      }
    );
    comment = conf.value;
    const parms = {} as WorkflowService.LogPaymentConfirmationActionParameters;
    parms.ApprovalId = this.approvalId;
    parms.Action = type;
    parms.Comment = comment;
    this.isLoading = true;
    await this._workflowService.LogPaymentConfirmationAction(parms);
    this.isLoading = false;
    await this.refreshCommentList();
    return comment;
  }
  async refreshCommentList(){
    this.commentList = [] as SystemService.Comments[];
    const params = {} as SystemService.GetCommentsParameters;
    params.EntityType = 'Approval';
    params.EntityId = this.approvalId;
    this.isLoading = true;
    this.commentList = await this._systemService.GetComments(params);
    this.isLoading = false;
  }
}
