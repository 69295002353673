
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Mutation } from 'vuex-class';

@Component
export default class Login extends Vue {
  msg: string = 'Welcome to VAM';
  buttonText: string = 'Login';
  disableLoginButton: boolean = false;

@Getter('systemStatusMessage') systemStatusMessage;
@Mutation('systemStatusMessage') setSystemStatusMessage;

 login() {
    this.buttonText = 'Logging In'
    this.setSystemStatusMessage(this.buttonText);
    const el = this;
    this.$store.dispatch('login').then(() => {
      /* gkb 11/15/18 - main.ts will be reloaded upon redirect, so we need to clear the existing token interval
       *  before a new one is started in main.ts. we have to clear it here instead of in main.ts because somehow
       *  the scope (or something?) changes for the second run of main.ts and it can't clear the existing interval.
       */
      // gkb 02/05/19 - removing interval altogether, now that we've overridden $.ajax to call .acquireTokenSilent() every time.
      /*
      let existingIntervalId: string = sessionStorage.getItem('tokenIntervalId');
      if (existingIntervalId) {
        clearInterval(parseInt(existingIntervalId));
        console.log(`Previous token refresh interval cleared after login. Interval Id: ${existingIntervalId}.`);
      }
      */

      if (!el.$route.query.from) {
        el.$router.push('/welcome').catch(() => {});
      } else {
        const from: string = el.$route.query.from as string; // bug/change introduced in router 3.0.2: https://github.com/vuejs/vue-router/pull/2050#issuecomment-441797165
        el.$router.replace(from);
      }
    });
  }
get
}
