
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import FormattedInput from '@/components/form/FormattedInput.vue';
import * as AssetService from '@/services/DAL/assetService';
import * as TransactionsService from '@/services/DAL/transactionsService';
import Common from '@/utils/common';
import InvestmentSelector from '@/components/form/InvestmentSelector.vue';
import formatters from '@/utils/formatters';
import NamedKeySelector from '@/components/form/NamedKeySelector.vue';


import { ElForm } from 'element-ui/types/form';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
    components: {
        FormattedInput,
        InvestmentSelector,
        NamedKeySelector
    }
})
export default class ExpenseEdit extends Vue {
    @Ref() readonly frmExpenseEdit!: ElementUI.Form;
    @Ref() readonly refAmount!: ElementUI.Input;

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private common: Common;
    public formatters: formatters;
    
    //#endregion Private declarations for Services

    //#region Props
    @Prop({required: true}) readonly header: TransactionsService.Header;
    @Prop() readonly considerationType: TransactionsService.ConsiderationTypes;
    @Prop( {type: Object }) readonly transactionType: TransactionsService.Types;
    @Prop() readonly expense: TransactionsService.Expense;
    
    //#endregion Props

    //#region Data
    expense_ = {} as TransactionsService.Expense;
    selectedInvestment = {} as AssetService.InvestmentList;
    securityLevel_: number = null;
    loading = false;
    loadingPrepaid = false;
    isNew = false;
    transactionType_ = {} as TransactionsService.Types;
    newButInvestmentSetExternally: boolean = false;
    
    
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.formatters = new formatters();
        this.common = new Common();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );

    }
    async mounted() {
        this.initialize();
    }
    //#endregion Lifecycle
    
    //#region Watches


    //#endregion Watches
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
        key => (this as any).veeFields[key].dirty
        );
    }
    get isFormValid(): boolean{
        return !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
    }
    get investmentSelectorReadOnly(): boolean {
        return (this.isNew && this.newButInvestmentSetExternally);
    }

 
    get expenseAmountDisplay(): number {
        if (this.expense_.Direction == 'Source'){
            return 0 - this.expense_.Amount;
        }
        else {
            return this.expense_.Amount;
        }
    }
    set expenseAmountDisplay(value: number){
        if (this.expense_.Direction == 'Source'){
            this.expense_.Amount = 0 - value;
        }
        else {
            this.expense_.Amount = value;
        }
    }
    //#region Methods
   async initialize(){
        if (this.expense && Object.keys(this.expense).length
            && this.expense.Id){ // 10/4/24: ConsiderationEdit will now pass a cash object even for new items. ExpenseEdit is not yet handling that, so ignore the passed object if it's new.
            this.expense_ = this.expense;
            this.isNew = !this.expense.Id;
            this.newButInvestmentSetExternally = (this.isNew && !!this.expense_.InvestmentId);
        }
        else {
            this.isNew = true;
            this.expense_ = new TransactionsService.Expense();
            this.$nullifyObjectProps(this.expense_);
            this.expense_.TransactionHeaderId = this.header.Id;
            this.expense_.OwnerId = this.header.BaseOwnerId; // could be empty
            this.expense_.ConsiderationTypeId = this.considerationType ? this.considerationType.Id : undefined;
            this.expense_.ConsiderationType = this.considerationType ? this.considerationType.Name : undefined;
            this.expense_.Direction = this.considerationType ? this.considerationType.Direction : undefined;
            this.newButInvestmentSetExternally = (this.isNew && !!this.expense_.InvestmentId);
            this.expense_.Amount = this.header.NetAmount;  // default the amount
        }

        await this.handleTransactionType();
        // need to get TransactionType before thinking about setting the InvestmentId based on the Header.BaseInvestmentId
        if (this.isNew && !this.expense_.InvestmentId && this.header.BaseInvestmentId
            && !(this.transactionType_ && this.transactionType_.IsDistribution && this.expense_.Direction == 'Result')){ // Don't set the Investment to that of the distributing Fund.
            this.expense_.InvestmentId = this.header.BaseInvestmentId;
        }

        this.$validator.validate();
    }
    filterExpenseTypes(nk) {
        if (!this.selectedInvestment || !this.selectedInvestment.Id){
            return false;
        }
        return (nk.AdditionalData2 == this.selectedInvestment.IncomeType || !nk.AdditionalData2); //incomeType either Active or Passive.  Expense options Additional Data 2 will be Active or Passive or null.
    }
    async saveItem(){
        if (this.isFormValid) {
            this.expense_.ConsiderationDescription = this.selectedInvestment.NameOwnerAccountTypeIdBank;
            this.expense_.ConsiderationDirection = 'Result'; // Expense can only be result
            this.expense_.Direction = this.expense_.ConsiderationDirection;
            if (this.isNew){
                const id = await new SmartObject('InvestmentTransactionExpense').createObject(
                    this.expense_
                );
                this.expense_.ExpenseId = id;
                this.expense_.Id = id;
                this.isNew = false;
                this.$notify.success('New Expense Item Added');
            }
            else{
                await new SmartObject('InvestmentTransactionExpense', this.expense_.ExpenseId).updateObject(
                    this.expense_
                );
                this.$notify.success('Expense Item Updated');
            }
            this.expense_.ConsiderationValue = this.expense_.Amount;
            this.$emit('saved', this.expense_);
        }
    }
        
    clearItem(){
        this.expense_.Amount = null;
        this.expense_.ConsiderationValue = null;
        this.expense_.InvestmentId = null;
        this.expense_.ConsiderationDescription = null;
        this.$emit('clear');
    }

    async deleteItem() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this Expense item. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                await this.doDeleteTransactionExpense();
            } catch {}
        }
    }

    async doDeleteTransactionExpense() {
        try {
            await new SmartObject(
                'InvestmentTransactionExpense',
                this.expense_.ExpenseId
            ).deleteObject();

            this.$notify.success('Expense item deleted.');

            await new SmartObject('CommentLog').createObject({
                EntityType: 'InvestmentTransactionHeader',
                EntityId: this.expense_.TransactionHeaderId,
                Comment: `Expense ${this.expense_.ExpenseId} Deleted.  (${this.expense_.ConsiderationDescription} ${this.$accounting.formatMoney(this.expense_.Amount)})`,
                SystemGenerated: true
            });

            this.$notify.success('Investment Header Comment added.');

            this.$emit('deleted', this.expense_.ExpenseId);
            this.expense_ = {} as TransactionsService.Expense;
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }

    investmentSelected(investment: AssetService.InvestmentList){
        this.selectedInvestment = investment;
    }
    async handleTransactionType(){
            this.transactionType_ = {} as TransactionsService.Types;
        if (this.transactionType && Object.keys(this.transactionType).length && this.transactionType.Id > 0){
            this.transactionType_ = this.transactionType;
        }
        else if (this.header && Object.keys(this.header).length && this.header.TypeId > 0){ 
            const params = {} as TransactionsService.GetTypesParameters;
            const types = await this._transactionsService.GetTypes(params);
            if (types && types.length){
                this.transactionType_ = this.common.getSelectedArrayItem(
                    types,
                    this.header.TypeId.toString(),
                    'Id'
                    );
            }
        }
    }



//#endregion Methods
}
