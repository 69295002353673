
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as VamService from './../../services/DAL/vamService';
import * as ReportsService from './../../services/DAL/reportsService';
import { UserClient } from './../../models/Common/UserClient';
import BalanceSheetConfigEntitySettingsList from '@/views/BalanceSheet/BalanceSheetConfigEntitySettingsList.vue';

declare var SmartObject: any;

@Component({
    components: {
        BalanceSheetConfigEntitySettingsList
    }
})
export default class BalanceSheetConfigEditView extends Vue {
    //#region Data
    private _reportsService: ReportsService.ReportsService;

    @Prop()
    client: VamService.UserClients;
    @Prop()
    configuration: ReportsService.Configurations;

    entitySettingConfigurations: ReportsService.EntitySettingConfigurations[] = [];
    overallSettings: ReportsService.OverallSettings[] = [];
    showDescriptionEdit = false;
    isLoading = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._reportsService = new ReportsService.ReportsService();
    }

    async mounted() {
        this.fetchOverallSettings();
        this.fetchEntitySettingConfigurations();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchOverallSettings() {
        this.isLoading = true;
        this.overallSettings = [];

        const getOverallSettingsParameters = {} as ReportsService.GetOverallSettingsParameters;
        getOverallSettingsParameters.ConfigurationId = this.configuration.Id;
        this.overallSettings = await this._reportsService.GetOverallSettings(
            getOverallSettingsParameters
        );
        this.isLoading = false;
    }

    async fetchEntitySettingConfigurations() {
        this.entitySettingConfigurations = [];
        this.entitySettingConfigurations = await this._reportsService.GetEntitySettingConfigurations();
    }

    async setOverallSetting(row) {
        try {
            if (row.ConfigurationSettingsId > 0) {
                await new SmartObject(
                    'ConfigurationSettings',
                    row.ConfigurationSettingsId
                ).updateAttribute('SettingBit', row.SettingBit);
            } else {
                row.ConfigurationSettingsId = await new SmartObject(
                    'ConfigurationSettings'
                ).createObject({
                    ConfigurationId: this.configuration.Id,
                    EntityType: 'Configuration',
                    EntityId: this.configuration.Id,
                    SettingBit: row.SettingBit,
                    ConfigurationSettingId: row.ConfigurationSettingId
                });
            }

            this.$notify.success('Overall setting updated.');
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }
    async saveDescription() {
        try {
            await new SmartObject('Configurations', this.configuration.Id).updateAttribute('Description', this.configuration.Description);
            this.showDescriptionEdit = false;
            this.$notify.success('Description updated.');
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }

    //#endregion Methods
}
