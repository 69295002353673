<template>
    <div id="attachment-map-list">
        <div v-if="this.entityId && this.entityId != null">
            {{items.length}} attachments for {{items[0].Association}}
        </div>
        <el-table v-if="items[0]"
                  :data="items"
                  style="width: 100%;"
                  size="mini"
                  stripe
                  @cell-click="cellClick">
            <el-table-column label="X"
                            min-width="75px"
                            v-if="(securityLevel_>=80)"
                            >
                <template slot-scope="scope">
                    <i class="fa fa-trash-o" aria-hidden="true" title="Delete"></i>
                </template>
            </el-table-column>
            <el-table-column label="Description"
                             prop="Description"
                             class-name="overflow"
                             v-if="showDescription && !fileId"
                             min-width="400px">
            </el-table-column>
            <el-table-column label="Store"
                             prop="Store"
                             class-name="overflow"
                             v-if="!!fileId"
                             min-width="400px">
            </el-table-column>
            <el-table-column label="Type"
                             align="center"
                             class-name="overflow"
                             v-if="showDescription"
                             min-width="150px"
                             prop="AttachmentType">
            </el-table-column>
            <el-table-column label="Association"
                             min-width="150px"
                             align="left"
                             class-name="overflow"
                             v-if="showAssociation"
                             prop="Association">
                <template slot-scope="scope">
                    <a v-if="!!scope.row.AssociationUrl" :href="scope.row.AssociationUrl" target="_blank">{{scope.row.Association}}</a>
                    <span v-else>{{scope.row.Association}}</span>
                </template>
            </el-table-column>
            <el-table-column label="Assoc. Type"
                             min-width="150px"
                             v-if="showAssociation"
                             align="center"
                             prop="EntityType">
            </el-table-column>
            <el-table-column label="Assoc. Id"
                             min-width="150px"
                             v-if="showAssociation"
                             align="center"
                             prop="EntityId">
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinSchema_attachment from './../DAL/mixinSchema_attachment'

    export default Vue.extend({
        name: 'AttachmentMapList'
        , mixins: [mixinSchema_attachment]
        , props: {
            attachmentId: {
                type: Number
                , required: false
            }
            , fileId: {
                type: String
                , required: false
            }
            , entityType: {
                type: String
                , required: false
            }
            , entityId: {
                type: Number
                , required: false
            }
            , securityLevel: {
                type: Number
                , default: null
            }
        }
        , data() {
            return {
                attachment: {}
                , fileProperties: {}
                , items: {}
                , securityLevel_: this.securityLevel
            }
        }
        , created: function () {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDocuments), 0);
            }

            this.fetchMaps();
        }
        , computed: {
            showAssociation: function () {
                return !(this.entityId);
            }
            , showDescription: function () {
                return !(this.attachmentId);
            }
        }
        , watch: {
            'attachmentId': function (val, oldVal) {
                this.fetchMaps();
            }
            , 'fileId': function (val, oldVal) {
                this.fetchMaps();
            }
            , 'entityType': function (val, oldVal) {
                this.fetchMaps();
            }
            , 'entityId': function (val, oldVal) {
                this.fetchMaps();
            }
        }
        , methods: {
            fetchMaps: async function () {
                if (!!this.attachmentId || !!this.fileId) {
                    this.items = await this.attachment_GetMaps({
                        AttachmentId: this.attachmentId
                        , FileID: this.fileId
					});
                }
                else if (this.entityId) {
                    this.items = await this.attachment_GetMapsForEntity({
                        EntityType: this.entityType
                        , EntityId: this.entityId
                        });
                }

                else {
                    this.items = {};
                }
                this.$emit('number-of-maps-changed', this.items.length);
            }
            , cellClick: function (selectedRow, column, cell, event) {
                if (column.label == 'X' && this.securityLevel_ >= 80) {
                    this.$confirm('This will delete the selected mapping. Continue?', 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }).then(() => {
                        var so = new SmartObject('AttachmentMap', selectedRow.MapId);
                        so.deleteObject_lgcy();
                        var index = this.items.indexOf(selectedRow);
                        this.items.splice(index, 1);
                        this.$emit('map-deleted', selectedRow);
                        this.$emit('number-of-maps-changed', this.items.length);
                        this.$notify({
                            type: 'success',
                            message: 'Deleted Map'
                        });
                    }).catch(() => {
                        this.$notify({
                            type: 'info',
                            message: 'Delete canceled'
                        });
                    });
                }
            }
        }
    });
</script>
<style>
    i.red {
        color: red
    }

    i.green {
        color: green
    }
</style>