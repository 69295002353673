
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as VamService from '../../services/DAL/vamService';
import * as AttachmentService from '../../services/DAL/attachmentService';
import SingleMemberLLCAgreement from '../../views/Entity/AgreementDocuments/SingleMemberLLCAgreement.vue';
import DECert from '../../views/Entity/CertificateOfFormationDocuments/DECert.vue';
import NYAuth from '../../views/Entity/ApplicationForAuthorityDocuments/NYApplicationForAuthority.vue';
import html2pdf from 'html2pdf.js'
import * as MicrosoftGraphTypes from "@microsoft/microsoft-graph-types" 
import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';
import MicrosoftGraphClient, { GraphRequest, ResponseType } from '@microsoft/microsoft-graph-client';

declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: number, deflt: number): number;
declare function encodeFileName(filename: string): string;
declare var SmartObject: any;

@Component({
    components: {
        SingleMemberLLCAgreement,
        DECert,
        NYAuth
    }
})
export default class EntityDocuments extends Vue {
  //#region Private Declarations
  private _vam_Service: VamService.VamService;
  private _attachmentService: AttachmentService.AttachmentService;
  private _graphService: MicrosoftGraphService;
  //#endregion Private Declarations

  //#region Props
  @Prop() ownerId: number;
  //#endregion Props

  //#region Data
  entity = {} as VamService.OwnersV2;
  securityLevel: number = 0;
  isLoading = false;
  agreementType: string = '';
  highlightFields: boolean = true;
  agreementFileProperties = {} as microsoftgraph.DriveItem;
  certificateOfFormationFileProperties = {} as microsoftgraph.DriveItem;
  applicationForAuthorityFileProperties = {} as microsoftgraph.DriveItem;
  message: string = '';
  showDocumentCertificateOfFormation = false;
  showDocumentOperatingAgreement = false;
  //#endregion Data

  //#region Computed
  get foreignStates(): any[] {
    if (this.$objectPropertyIfExists(this.entity, 'ForeignStatesList')) {
      return JSON.parse(this.$objectPropertyIfExists(this.entity, 'ForeignStatesList'));
    }
  }
  get baseFileName(): string {
    if (this.$objectPropertyIfExists(this.entity, 'Name')){
      return `${this.$moment().format('YYYY-MM-DD')} ${(this.entity.Name.indexOf(this.entity.ClientName) < 0) ? this.entity.ClientName + ' - ' : ''}${this.entity.Name}`;
    }
  }
  get agreementFileName(): string {
    if (this.baseFileName){
      return `${this.baseFileName} Agreement.pdf`;
    }
  }
  get certificateOfFormationFileName(): string {
    if (this.baseFileName){
      return `${this.baseFileName} Certificate of Formation.pdf`;
    }
  }
  get applicationForAuthorityFileName(): string {
    return `${this.baseFileName} Application for Authority`; // .pdf is added inline since the state needs to be added first.
  }
//#endregion Computed

  //#region Lifecycle
  created() {
    this._vam_Service = new VamService.VamService();
    this._attachmentService = new AttachmentService.AttachmentService();
    this._graphService = new MicrosoftGraphService();

    this.securityLevel = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageOwners),
      0
    );
      if (this.ownerId){
      this.fetchEntity();
    }
  }
  //#endregion Lifecycle

  //#region Watches
  //#endregion Watches

  //#region Methods
  async fetchEntity() {
    this.isLoading = true;
    this.entity = {} as VamService.OwnersV2;
    const parameters = {} as VamService.GetOwnersV2Parameters;
    parameters.OwnerId = this.ownerId;
    const entity = await this._vam_Service.GetOwnersV2(
      parameters
    );
    this.entity = (!!entity && entity.length == 1) ? entity[0] : {} as VamService.OwnersV2;
    if (this.entity.TaxClassName == 'Disregarded Entity'){
      this.agreementType = 'SingleMemberLLCAgreement';
    }
    this.isLoading = false;
    this.processExistingFiles();
  }
  async processExistingFiles(){
    const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
    try {
      if (this.entity.HasDocumentOperatingAgreement && !this.entity.HasMultipleOperatingAgreement){
        this.agreementFileProperties = await graphClient.api(this.entity.PathOperatingAgreement + this.entity.FileIdOperatingAgreement).get();
      }
      if (this.entity.HasDocumentCertificateOfFormation && !this.entity.HasMultipleCertificateOfFormation){
        this.certificateOfFormationFileProperties = await graphClient.api(this.entity.PathCertificateOfFormation + this.entity.FileIdCertificateOfFormation).get();
      }
      if (this.entity.HasDocumentForeignStateApplicationForAuthority && !this.entity.HasMultipleForeignStateApplicationForAuthority){
        this.applicationForAuthorityFileProperties = await graphClient.api(this.entity.PathForeignStateApplicationForAuthority + this.entity.FileIdForeignStateApplicationForAuthority).get();
      }
    }
    catch(error){
      console.log('file error', error);
      this.$emit('hide-forms');
    }
  }
  async makePDF(element: Element, opt: any, path?: string){

    this.message = `Generating ${opt.filename}`
    let fileProperties: MicrosoftGraphTypes.DriveItem;
    if (path){
      const pdf = await html2pdf().set(opt).from(element).toPdf().get('pdf');
      fileProperties = await this.saveFile(pdf.output('blob'), path, opt.filename);
      return fileProperties;
    }
    else { //just create and download to client
      this.message = `Downloading ${opt.filename}`
      await html2pdf().set(opt).from(element).save();
    }
  }
  async saveFile(file: any, path: string, fileName: string): Promise<MicrosoftGraphTypes.DriveItem>{
    this.message = `Saving ${fileName}`
    const encodedFilename = encodeFileName(fileName);
    const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
    try {
        const fileResult: MicrosoftGraphTypes.DriveItem = await graphClient
            .api(path + 'children/' + encodedFilename + '/content?@name.conflictBehavior=fail')
            .put(file);
        this.message = `Saved ${fileName} Id: ${fileResult.id}`;
        return fileResult;
    }
    catch (err){
      console.log(err);
      this.$notify({
          title: 'File Upload Error',
          type: 'error',
          message: err.message
      });
      return;
    }
  }
  async saveAttachment(fileProps: MicrosoftGraphTypes.DriveItem, typeId: number){
      const attachment = new AttachmentService.Attachment();
      attachment.FileId = fileProps.id;
      attachment.ClientId = this.entity.ClientId;
      attachment.Dated = this.$moment().format('MM/DD/YYYY');
      attachment.Description = fileProps.name.substr(0, fileProps.name.lastIndexOf('.')) || fileProps.name;
      attachment.StoreCategoryId = 30; // Entities/Owners
      attachment.TypeId = typeId;
      attachment.StoreId = this.entity.DefaultDocumentStoreId;
      attachment.Comment = 'Autogenerated';

    const so = new SmartObject('Attachment');
    const newAttachmentIdInt = await so.createObject(attachment);
    if (newAttachmentIdInt) {
        this.message = `Mapping attachment ${newAttachmentIdInt}`;
        const so = new SmartObject('AttachmentMap');
        const attachmentMap = new AttachmentService.Maps();
        attachmentMap.AttachmentId = newAttachmentIdInt;
        attachmentMap.EntityId = this.entity.Id;
        attachmentMap.EntityType = 'Owner';
        const attachmentMapId = await so.createObject(attachmentMap);
        this.message = `Map created ${attachmentMapId}`;
    }
  }
  async doFiles(){
    this.highlightFields = false;
    await this.$nextTick();
    const opt = {
        margin:       1,
        filename:     this.agreementFileName,
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: 'css' }
        };
    let element: Element;
    if (!this.entity.HasDocumentOperatingAgreement && !!this.agreementType){
      this.message = 'Creating Entity Agreement';
      element = (this.$refs.pdfAgreement as Vue).$el;
      const fileProps = await this.makePDF(element, opt, this.entity.DefaultDocumentPath);
      this.message = `Created ${fileProps.name}`;
      await this.saveAttachment(fileProps, 72);  // Operating Agreement
    }
    if (!this.entity.HasDocumentCertificateOfFormation){
      this.message = 'Creating Certificate of Formation';
      element = (this.$refs.pdfCert as Vue).$el;
      opt.filename = this.certificateOfFormationFileName;
      const fileProps = await this.makePDF(element, opt); // don't save file; only want to ingest the stamped copy.
      this.message = `Created ${opt.filename}`;
      await this.$confirm('Certificate of Formation has been downloaded.  You must submit it and then upload STAMPED copy.', 'Downloaded', {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    type: 'warning'
      });
      // await this.saveAttachment(fileProps, 73);  // Certificate of Formation
    }
    if (!this.entity.HasDocumentForeignStateApplicationForAuthority && !!this.foreignStates && this.foreignStates.length){
    await Promise.all(this.foreignStates.map(async (state) => {
      this.message = `Creating Application for Authority for ${state.State}`
      element = (this.$refs[`pdfAuth${state.State}`][0] as Vue).$el;
      opt.filename = `${this.applicationForAuthorityFileProperties} - ${state.State}.pdf`;
      opt.margin = .5;
      await this.makePDF(element, opt); // don't save Auth - requires wet signature  
      this.message = `Created ${opt.filename}`;
    }
    ));
    }
    this.highlightFields = true;
    this.message = 'Files processed; refreshing';
    await this.fetchEntity();
    this.message = '';
  }

  //#endregion Methods
}
