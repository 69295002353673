
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as UtilService from './../../services/DAL/utilService';
import * as LiqService from './../../services/DAL/liq_Service';
import * as BalanceSheetService from './../../services/DAL/balanceSheetService';
import moment from 'moment';
import assign from 'lodash/assign';
import capitalize from 'lodash/capitalize';
import formatters from '../../utils/formatters';
import EventLogDetailView from '@/views/Event/EventLogDetailView.vue';
import ElementUI from 'element-ui';
import Common from '@/utils/common';

interface LogEventExtended extends UtilService.LogEvent {
    LiquidityScorecardSentToClient: boolean;
    LiquidityScorecardSentToClientOn: string;
    LiquidityScorecardSentToClientBy: string;
    LiquidityScorecardSentToClientKeyId: number;
	BalanceSheetSentToClient: boolean;
	BalanceSheetSentToClientOn: string;
	BalanceSheetSentToClientBy: string;
	BalanceSheetSentToClientKeyId: number;
}

declare var SmartObject: any;
declare function getSecurityUserId(): string;

@Component({
    components: {
        EventLogDetailView
    }
})
export default class EventLogList extends Vue {
    $refs: {
        tblEventList: ElementUI.Table
    }
    //#region Data
    private _utilService: UtilService.UtilService;
    private _liqService: LiqService.LiqService;
    private _balanceSheetService: BalanceSheetService.BalanceSheetService;
    private formatters: formatters = new formatters();
    public common: Common;

    @Prop()
    database: string;
    @Prop()
    schema: string;
    @Prop()
    caller: string;
    @Prop()
    event: string;
    @Prop()
    maxRows: number;
    @Prop()
    minMilliseconds: number;
    @Prop({ default: false })
    includeIncompleteRegardlessOfMilliseconds: boolean;
    @Prop()
    title: string;
    @Prop()
    onRunClick: Function;
    @Prop()
    onDataClick: Function;
    @Prop({ default: null })
    dataClickLabel: string;
    @Prop({ default: 'Run Now' })
    runButtonLabel: string;
    

    database_: string = this.database || null;
    schema_: string = this.schema || null;
    caller_: string = this.caller || null;
    event_: string = this.event || null;
    maxRows_: number = this.maxRows || 20;
    minMilliseconds_: number = this.minMilliseconds || null;
    includeIncompleteRegardlessOfMilliseconds_: boolean = this
        .includeIncompleteRegardlessOfMilliseconds;
    title_: string = this.title || 'Event Log';
    processViewers: UtilService.ProcessViewer[] = [];
    eventLogs: LogEventExtended[] = [];
    selectedProcessViewer: UtilService.ProcessViewer = null;
    selectedEventLog: LogEventExtended = null;
    eventLogBeingMarkedAsSent: LogEventExtended = null;
    showDataPane: boolean = false;
    showSendDatePrompt: boolean = false;
    sendDate: string = moment(new Date()).format('MM/DD/YYYY');
    sendDateIsInvalid: boolean = false;
    isLoading: boolean = false;
    interval: number = null;
    autoRefresh: boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._utilService = new UtilService.UtilService();
        this._liqService = new LiqService.LiqService();
        this._balanceSheetService = new BalanceSheetService.BalanceSheetService();
        this.formatters = new formatters();
        this.common = new Common();

        if (!this.processViewerSelected) {
            this.fetchProcessViewers();
        }
    }

    async mounted() {
        if (this.processViewerSelected) {
            this.fetchEventLogs(false);
        }
    }
    //#endregion Lifecycle

    //#region Computed
    get showDetailColumns(): boolean {
        return (
            ['xs', 'sm', 'md'].indexOf(this.$getBootstrapSize()) > 0 ||
            !this.showDataPane
        );
    }

    get isLiquidityProcessHistory(): boolean {
        return (
            (this.schema_ || '').toLowerCase() === 'liq' &&
            (this.caller_ || '').toLowerCase() === 'processhistory'
        );
    }

    get isBalanceSheetProcessHistory(): boolean {
        return (
            (this.schema_ || '').toLowerCase() === 'balancesheet' &&
            (this.caller_ || '').toLowerCase() === 'processhistory'
        );
    }

    get sendingEntityString(): string {
        if (this.isLiquidityProcessHistory) {
            return 'scorecard';
        }
        else if (this.isBalanceSheetProcessHistory) {
            return 'balance sheet';
        }
        else {
            return 'record';
        }
    }

    get processViewerPassedIn(): boolean {
        return (
            !!this.database && !!this.schema && !!this.caller && !!this.event
        );
    }

    get processViewerSelected(): boolean {
        return (
            !!this.database_ &&
            !!this.schema_ &&
            !!this.caller_ &&
            !!this.event_
        );
    }

    get runNowDisabled(): boolean {
        return (
            !!this.eventLogs &&
            !!this.eventLogs.length &&
            this.eventLogs[0].Success === null
        );
    }
    //#endregion Computed

    //#region Watches
    @Watch('autoRefresh')
    onChange_autoRefresh(val, oldVal) {
        if (val) {
            const el = this;
            this.interval = window.setInterval(function() {
                el.fetchEventLogs(true);
            }, 5000);
        } else {
            window.clearInterval(this.interval);
        }
    }

    @Watch('selectedProcessViewer')
    onChange_selectedProcessViewer(val, oldVal) {
        if (this.selectedProcessViewer) {
            this.database_ = this.selectedProcessViewer.Database || null;
            this.schema_ = this.selectedProcessViewer.Schema || null;
            this.caller_ = this.selectedProcessViewer.Caller || null;
            this.event_ = this.selectedProcessViewer.Event || null;
        } else {
            this.database_ = null;
            this.schema_ = null;
            this.caller_ = null;
            this.event_ = null;
        }

        if (this.processViewerSelected) {
            this.fetchEventLogs(false);
        }
    }
    //#endregion Watches

    //#region Methods
    async fetchProcessViewers() {
        this.processViewers = await this._utilService.GetProcessViewer();
    }

    async selectEvent(eventLog: any) {
        if (eventLog) {
            this.showDataPane = false;
            await this.$nextTick();
            this.showDataPane = true;
            this.selectedEventLog = eventLog;
        }
    }

    closeEventLog() {
        this.showDataPane = false;
        this.selectedEventLog = null;
        this.$refs.tblEventList.setCurrentRow(null);
    }

    async fetchEventLogs(isAutoRefresh: boolean) {
        if (!this.processViewerSelected) {
            return;
        }

        this.isLoading = true;

        if (this.isLiquidityProcessHistory) {
            const getLogIdsParameters = {} as LiqService.GetLogIdsParameters;
            getLogIdsParameters.Database = this.database_;
            getLogIdsParameters.Schema = this.schema_;
            getLogIdsParameters.Caller = this.caller_;
            getLogIdsParameters.Event = this.event_;
            getLogIdsParameters.MaxRows = this.maxRows_;
            getLogIdsParameters.MinMilliseconds = this.minMilliseconds_ || null;
            getLogIdsParameters.IncludeIncompleteRegardlessOfMilliseconds = this.includeIncompleteRegardlessOfMilliseconds_;

            const logIds: LiqService.LogIds[] = await this._liqService.GetLogIds(
                getLogIdsParameters
            );

            this.eventLogs = [];
            for (const logId of logIds) {
                this.eventLogs.push(
                    assign({}, logId, {
                        BalanceSheetSentToClient: null,
                        BalanceSheetSentToClientOn: null,
                        BalanceSheetSentToClientBy: null,
                        BalanceSheetSentToClientKeyId: null
                    })
                );
            }
        } else if (this.isBalanceSheetProcessHistory) {
            const getLogIdsParameters = {} as BalanceSheetService.GetLogIdsParameters;
            getLogIdsParameters.Database = this.database_;
            getLogIdsParameters.Schema = this.schema_;
            getLogIdsParameters.Caller = this.caller_;
            getLogIdsParameters.Event = this.event_;
            getLogIdsParameters.MaxRows = this.maxRows_;
            getLogIdsParameters.MinMilliseconds = this.minMilliseconds_ || null;
            getLogIdsParameters.IncludeIncompleteRegardlessOfMilliseconds = this.includeIncompleteRegardlessOfMilliseconds_;

            const logIds: BalanceSheetService.LogIds[] = await this._balanceSheetService.GetLogIds(
                getLogIdsParameters
            );

            this.eventLogs = [];
            for (const logId of logIds) {
                this.eventLogs.push(
                    assign({}, logId, {
                        LiquidityScorecardSentToClient: null,
                        LiquidityScorecardSentToClientOn: null,
                        LiquidityScorecardSentToClientBy: null,
                        LiquidityScorecardSentToClientKeyId: null
                    })
                );
            }
        } else {
            const getLogEventParameters = {} as UtilService.GetLogEventParameters;
            getLogEventParameters.Database = this.database_;
            getLogEventParameters.Schema = this.schema_;
            getLogEventParameters.Caller = this.caller_;
            getLogEventParameters.Event = this.event_;
            getLogEventParameters.MaxRows = this.maxRows_;
            getLogEventParameters.MinMilliseconds =
                this.minMilliseconds_ || null;
            getLogEventParameters.IncludeIncompleteRegardlessOfMilliseconds = this.includeIncompleteRegardlessOfMilliseconds_;

            const eventLogs: UtilService.LogEvent[] = await this._utilService.GetLogEvent(
                getLogEventParameters
            );

            this.eventLogs = [];
            for (const eventLog of eventLogs) {
                this.eventLogs.push(
                    assign({}, eventLog, {
                        LiquidityScorecardSentToClient: null,
                        LiquidityScorecardSentToClientOn: null,
                        LiquidityScorecardSentToClientBy: null,
                        LiquidityScorecardSentToClientKeyId: null,
                        BalanceSheetSentToClient: null,
                        BalanceSheetSentToClientOn: null,
                        BalanceSheetSentToClientBy: null,
                        BalanceSheetSentToClientKeyId: null
                    })
                );
            }
        }

        this.isLoading = false;

        if (isAutoRefresh) {
            this.$notify.success({
                title: null,
                message: 'Data automatically refreshed.',
                duration: 1700
            });
        }

        // if the most recent run is done, stop auto-refreshing
        if (
            this.eventLogs &&
            this.eventLogs.length &&
            this.eventLogs[0].Success !== null
        ) {
            this.autoRefresh = false;
        }
    }

    transformUser(user: string): string {
        return (user || '').indexOf('Login_Visium') > -1 ? 'Auto' : user;
    }

    fromNow(dtTm: moment.MomentInput): string {
        return moment(dtTm).fromNow();
    }

    tableRowClassName({ row, rowIndex }): string {
        if (row.Success === null) {
            return 'warning-row';
        } else if (
            (row.CreateUser || '').indexOf('Login_Visium') === -1 &&
            moment(row.CompleteDateET).isAfter(moment().subtract(1, 'minutes'))
        ) {
            //not an auto row, and it happened in the last minute
            return 'success-row';
        }
        return '';
    }

    promptSendDate(eventLog: LogEventExtended) {
        this.eventLogBeingMarkedAsSent = eventLog;
        this.showSendDatePrompt = true;
    }

    onOpenSendDatePrompt() {
        this.sendDateIsInvalid = false;
        this.sendDate = moment(new Date()).format('MM/DD/YYYY');
    }

    async markAsSent() {
        if (this.sendDate) {
            try {
                this.showSendDatePrompt = false;
                this.sendDateIsInvalid = false;

                const eventLog = this.eventLogBeingMarkedAsSent;
                const securityUserId = await getSecurityUserId();
                
                let keyName = null;
                if (this.isLiquidityProcessHistory) {
                    keyName = 'Liquidity Scorecard Sent to Client';
                }
                else if (this.isBalanceSheetProcessHistory) {
                    keyName = 'Permanent';
                }
                
                const ek = new SmartObject('EventKeys');
                await ek.createObject({
                    LogId: eventLog.Id,
                    KeyName: keyName,
                    DateTimeValue: this.sendDate,
                    VarcharValue: securityUserId
                });

                if (this.isLiquidityProcessHistory) {
                    eventLog.LiquidityScorecardSentToClient = true;
                    eventLog.LiquidityScorecardSentToClientOn = this.sendDate;
                    eventLog.LiquidityScorecardSentToClientBy = securityUserId;
                    eventLog.LiquidityScorecardSentToClientKeyId = ek.entityId;
                }
                else if (this.isBalanceSheetProcessHistory) {
                    eventLog.BalanceSheetSentToClient = true;
                    eventLog.BalanceSheetSentToClientOn = this.sendDate;
                    eventLog.BalanceSheetSentToClientBy = securityUserId;
                    eventLog.BalanceSheetSentToClientKeyId = ek.entityId;
                }

                this.sendDate = moment(new Date()).format('MM/DD/YYYY');

                this.$notify.success(`${capitalize(this.sendingEntityString)} marked as sent.`);
            }
            catch {
                this.$notify.error('Something went wrong processing your request, please try again.');
            }
        }
        else {
            this.sendDateIsInvalid = true;
        }
    }

    async unmarkAsSent(eventLog: LogEventExtended) {
        try {
            await this.$confirm(
                `Are you sure you want to unmark this ${this.sendingEntityString} as sent?`,
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );

            try {
                if (this.isLiquidityProcessHistory) {
                    await new SmartObject(
                        'EventKeys',
                        eventLog.LiquidityScorecardSentToClientKeyId
                    ).deleteObject();

                    eventLog.LiquidityScorecardSentToClient = false;
                    eventLog.LiquidityScorecardSentToClientOn = null;
                    eventLog.LiquidityScorecardSentToClientBy = null;
                    eventLog.LiquidityScorecardSentToClientKeyId = null;
                }
                else if (this.isBalanceSheetProcessHistory) {
                    await new SmartObject(
                        'EventKeys',
                        eventLog.BalanceSheetSentToClientKeyId
                    ).deleteObject();

                    eventLog.BalanceSheetSentToClient = false;
                    eventLog.BalanceSheetSentToClientOn = null;
                    eventLog.BalanceSheetSentToClientBy = null;
                    eventLog.BalanceSheetSentToClientKeyId = null;
                }

                this.$notify.success(`${capitalize(this.sendingEntityString)} unmarked as sent.`);
            } catch {
                this.$notify.error('Something went wrong processing your request, please try again.');
            }
        } catch {}
    }
    //#endregion Methods
}
