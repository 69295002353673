//js API for Schema tax
//Auto-generated 04/02/19 10:26
 

var mixinSchema_tax = {
    methods: {
        /** @description Get ModelImport using tax.GetModelImport
         */
		 tax_GetModelImport: function () {
			/*
				this.ModelImport = this.tax_GetModelImport(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'tax.GetModelImport'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get OwnerCategoryStates using tax.GetOwnerCategoryStates
         * @param {number} ownerCategoryId - 
         */
		, tax_GetOwnerCategoryStates: function (ownerCategoryId) {
			/*
				this.OwnerCategoryStates = this.tax_GetOwnerCategoryStates(
					null // ownerCategoryId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'tax.GetOwnerCategoryStates'
			  , params: {
					OwnerCategoryId: ownerCategoryId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get PaymentSchedule using tax.GetPaymentSchedule
         * @param {number} noteId - 
         */
		, tax_GetPaymentSchedule: function (params, onSuccess, onError, async) {
			/*
				this.PaymentSchedule = await this.tax_GetPaymentSchedule({
					NoteId: noteId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'tax.GetPaymentSchedule'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get SummaryCells using tax.GetSummaryCells
         * @param {number} clientId - 
         */
		, tax_GetSummaryCells: function (params, onSuccess, onError, async) {
			/*
				this.SummaryCells = await this.tax_GetSummaryCells({
					ClientId: clientId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'tax.GetSummaryCells'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get TaxClasses using tax.GetTaxClasses
         */
		, tax_GetTaxClasses: function () {
			/*
				this.TaxClasses = this.tax_GetTaxClasses(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'tax.GetTaxClasses'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description tax.UpdateTaxLiabilitiesFromModelImport
         */
		, tax_UpdateTaxLiabilitiesFromModelImport: function (params, onSuccess, onError, async) {
			/*
				this.UpdateTaxLiabilitiesFromModelImport = await this.tax_UpdateTaxLiabilitiesFromModelImport({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'tax.UpdateTaxLiabilitiesFromModelImport'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
    }
};
export default mixinSchema_tax;
