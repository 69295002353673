 <template>
    <el-row>
             <el-col :span="12">
                 <el-card class="box-card" ref="selectCard">
                     <el-select filterable clearable size="small"
                                placeholder="Select Type"
                                v-bind:disabled="excelCellImportTypes >0"
                                v-model="selectedConfigurationTypeId">
                         <el-option v-for="item in excelCellImportTypes "
                                    :key="item.Id"
                                    :label="item.Name"
                                    :value="item.Id">
                         </el-option>
                     </el-select>
                 </el-card>
                 <el-card class="box-card">
                     <div slot="header" class="clearfix configurationsHeader">
                         <span>Configurations</span>
                     </div>
                     <el-row :gutter="10" v-show="ConfigurationsList[0]" class="configurationsFilterRow">
                         <el-col :span="8">
                             <el-input v-model="filterConfigurationsList" placeholder="filter" size="mini" clearable suffix-icon="el-icon-search">
                             </el-input>
                         </el-col>
                     </el-row>
                     <el-row>
                         <el-col>
                             <el-table v-if="ConfigurationsList[0]"
                                       ref="refConfigurationsList"
                                       :data="filteredConfigurationsList"
                                       :height="tableHeight"
                                       row-key="Id"
                                       highlight-current-row
                                       @row-click="editConfigurationsListItem">
                                 <el-table-column label="Name"
                                                  min-width=""
                                                  sortable
                                                  class-name="overflow"
                                                  prop="ImportType">
                                     <template slot-scope="scope">
                                         <span :title="scope.row.Id">{{scope.row.ImportType}}</span>
                                     </template>
                                 </el-table-column>
                                 <el-table-column label="Client"
                                                  min-width=""
                                                  sortable
                                                  class-name="overflow"
                                                  prop="ClientName">
                                     <template slot-scope="scope">
                                         <span :title="scope.row.ClientId">{{scope.row.ClientName}}</span>
                                     </template>
                                 </el-table-column>
                                 <el-table-column label="Description"
                                                  min-width=""
                                                  sortable
                                                  class-name="overflow"
                                                  prop="Description">
                                     <template slot-scope="scope">
                                         <span>{{scope.row.Description }}</span>
                                     </template>
                                 </el-table-column>
                                 <el-table-column label="Add'l Desc"
                                                  min-width=""
                                                  sortable
                                                  class-name="overflow"
                                                  prop="Description2">
                                     <template slot-scope="scope">
                                         <span>{{scope.row.Description2 }}</span>
                                     </template>
                                 </el-table-column>
                                 <el-table-column label="Int"
                                                  min-width=""
                                                  sortable
                                                  class-name="overflow"
                                                  prop="IntDescription">
                                     <template slot-scope="scope">
                                         <span>{{scope.row.IntDescription }}</span>
                                     </template>
                                 </el-table-column>
                             </el-table>
                         </el-col>
                     </el-row>
                 </el-card>
             </el-col>
             <el-col :span="1">&nbsp;</el-col>
             <el-col :span="11">
                <el-card class="box-card" v-if="showConfigurationEditView">
                    <div slot="header" class="clearfix">
                        <span>{{selectedConfiguration.ImportType + ' - ' + selectedConfiguration.Description}}{{(selectedConfiguration.Description2 != null) ? ' - ' + selectedConfiguration.Description2 : ''}}</span>
                    </div>
                     <configuration-edit :p-configuration.sync="selectedConfiguration" v-if="showConfigurationEditView" ></configuration-edit>
                </el-card>
             </el-col>
         </el-row>
</template>
<script>
    import Vue from 'vue';
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import ConfigurationEdit from './ExcelCellImportConfigurationEdit.vue'

    export default Vue.extend({
        name: 'ExcelCellImportConfigurationMangement'
        , mixins: [mixinSchema_vam]
        , props: ["pConfigurationId"]
        , components: {
            'configuration-edit': ConfigurationEdit
        }
        , data: function () {
            return {
                ConfigurationsList: {}
                , filteredConfigurationsList: {}
                , filterConfigurationsList: ''
                , excelCellImportTypes: []
                , errorOnly: true
                , firstRun: true
                , selectedConfigurationId: null
                , selectedConfigurationTypeId: null
                , selectedConfiguration: this.vam_GetExcelCellImportConfiguration_New()
                , tableHeight: undefined
                , showConfigurationEditView: false
            }
        }
        , created: function () {
            this.fetchExcelCellImportTypes();
            this.fetchConfigurations();
        }
        , mounted: function () {
            this.tableHeight =
            $('.main').height() -
            $('.breadcrumb').outerHeight() -
            parseInt($('.breadcrumb').css('margin-bottom')) -
            $(this.$refs.selectCard.$el).outerHeight() -
            $('.configurationsHeader').parent().outerHeight() -
            $('.configurationsFilterRow').outerHeight() -
            parseInt($('.configurationsFilterRow').parent(':first').css('padding-top')) -
            parseInt($('.configurationsFilterRow').parent(':first').css('padding-bottom')) -
            $('.app-footer').outerHeight();
        }
        , watch: {
            'selectedConfigurationId': function (val, oldVal) {
                if (!(oldVal === undefined)) {//when oldVal=undefined, that is when the value is initially set.
                    if (val == '') {
                        this.selectedConfigurationId = null;
                        return; //this is going to fire the watch again, so we don't want to do the next steps twice.
                    }
                    this.fetchConfigurations();
                }
            }
            , 'selectedConfigurationTypeId': function (val, oldVal) {
                if (!(oldVal === undefined)) {//when oldVal=undefined, that is when the value is initially set.
                    if (val == '') {
                        this.selectedConfigurationTypeId = null;
                        return; //this is going to fire the watch again, so we don't want to do the next steps twice.
                    }
                    this.fetchConfigurations();
                }
            }
            , 'filterConfigurationsList': function (val, oldVal) {
                this.filteredConfigurationsList = this.ConfigurationsList.filter(function (e) {
                    return ((e.ImportType) ? e.ImportType.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.Description) ? e.Description.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.Description2) ? e.Description2.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.IntDescriptionLabel) ? e.IntDescriptionLabel.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.SheetName) ? e.SheetName.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.Cell) ? e.Cell.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.EntityType) ? e.EntityType.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.EntityType2) ? e.EntityType2.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        || ((e.ClientName) ? e.ClientName.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                        ;
                });
            }
        }
        , computed: {
        }
        , methods: {
            fetchConfigurations: function (NoteTypeId) {
                this.ConfigurationsList = this.vam_GetExcelCellImportConfiguration({
                    ImportTypeId: this.selectedConfigurationTypeId
                    , ConfigurationId: this.pConfigurationId
                });

                this.filteredConfigurationsList = this.ConfigurationsList;
            }
            , fetchExcelCellImportTypes: function () {
                this.excelCellImportTypes = this.vam_GetExcelCellImportTypes();
            }
            , editConfigurationsListItem: async function (selectedRow, event, column) {
                if (selectedRow && selectedRow != null) {
                    this.showConfigurationEditView = false; //this should reset the components, once it is set to true again in the NextTick
                    this.$nextTick(function () {
                        this.showConfigurationEditView = true;
                        this.selectedConfiguration = selectedRow;
                    });
                }
            }
        }
    })
</script>
<style >
    .el-table .warning-row {
        background: oldlace;
    }

    .el-table td, .el-table th {
        padding-bottom: 5px;
        padding-top: 5px;
    }
</style>
