 <template>
    <el-row>
             <el-col :span="12">
                 <el-collapse v-model="activeNames">
                     <el-collapse-item title="Search" name="search">
                         <el-select filterable clearable size="small"
                                    placeholder="Select Client"
                                    v-bind:disabled="clients>0"
                                    v-model="selectedClientId">
                             <el-option v-for="client in clients"
                                        :key="client.Id"
                                        :label="client.Name"
                                        :value="client.Id">
                             </el-option>
                         </el-select>
                         <el-select filterable clearable size="small"
                                    v-bind:disabled="owners>0"
                                    placeholder="Select Owner"
                                    v-model="selectedOwnerId">
                             <el-option v-for="owner in owners"
                                        :key="owner.Id"
                                        :label="owner.Name"
                                        :value="owner.Id">
                             </el-option>
                         </el-select>
                         <el-select filterable clearable size="small"
                                    v-bind:disabled="statuses>0"
                                    placeholder="Status"
                                    v-model="selectedStatusId">
                             <el-option v-for="status in statuses"
                                        :key="status.KeyValueInt"
                                        :label="status.KeyText"
                                        :value="status.KeyValueInt">
                             </el-option>
                         </el-select>
                     </el-collapse-item>
                     <el-collapse-item title="Results" name="results">
                         <el-table :data='notes'
                                   @row-click='rowClick'>
                             <el-table-column prop="DisplayId"
                                              label="Id"
                                              min-width="65"
                                              sortable>
                             </el-table-column>
                             <el-table-column prop="Client"
                                              label="Client"
                                              min-width="90"
                                              sortable>
                             </el-table-column>
                             <el-table-column prop="OwnerName"
                                              label="Debtor"
                                              min-width="220"
                                              sortable>
                             </el-table-column>
                             <el-table-column prop="Name"
                                              label="Name"
                                              min-width="240"
                                              sortable>
                             </el-table-column>
                             <el-table-column prop="StatusListName"
                                              label="Status"
                                              min-width="95"
                                              sortable>
                             </el-table-column>
                         </el-table>
                     </el-collapse-item>
                 </el-collapse>
             </el-col>
             <el-col :span="1">&nbsp;</el-col>
             <el-col :span="11">
                 <el-card class="box-card" v-if="selectedNote.NoteId">
                     <div slot="header">
                         <span>{{selectedNote.NoteName}} ({{selectedNote.DisplayId}})</span>
                         <!--<el-button style="float: right;" type="primary" size="small">Save</el-button>-->
                     </div>
                     <el-tabs type="card">
                         <el-tab-pane label="Basic">
                             <note-edit-basic :note-id="selectedNote.NoteId"></note-edit-basic>
                         </el-tab-pane>
                         <el-tab-pane label="Amortization">
                             <note-edit-term-amortization v-if="selectedNote.NoteTypeId == 20" :note-id="selectedNote.NoteId"></note-edit-term-amortization>
                         </el-tab-pane>
                         <el-tab-pane label="Transactions">Transactions</el-tab-pane>
                         <el-tab-pane label="Collateral">Collateral</el-tab-pane>
                     </el-tabs>

                 </el-card>
             </el-col>
         </el-row>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinLiability from './../VueCommon/liability.vue'
    import NoteEditBasic from './NoteEditBasic.vue'
    import NoteEditTermAmortization from './NoteEditTermAmortization.vue'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'

    export default Vue.extend({
        name: 'LiabilityNotesManagementView'
        , mixins: [mixinUtility, mixinLiability, mixinSchema_vam]
        , props: ["pNoteTypeId", "params"]
        , components: {
            'note-edit-basic': NoteEditBasic
            , 'note-edit-term-amortization': NoteEditTermAmortization
        }
        , data: function () {
            return {
                //vParameters: {}
                notes: []
                , NoteTypeId: null
                , clients: []
                , owners: []
                , statuses: []
                , selectedClientId: null
                , selectedOwnerId: null
                , selectedStatusId: 20
                , activeNames: ['search', 'results']
                , selectedNote: []
            }
        }
        , created: async function () {
            if (this.pNoteTypeId) {
                this.NoteTypeId = this.pNoteTypeId;
            }
            if (this.params && this.params.NoteTypeId) {
                this.NoteTypeId = this.params.NoteTypeId;
            }
            this.clients = await this.vam_GetUserClients({});
            this.owners = await this.vam_GetOwnersV2({
                ClientId: this.selectedOwnerId
                , ClientIdList: undefined
            });

            this.statuses = this.getNoteStatus();
            this.fetchNotes();
        }
        , mounted: function () {
            console.log('mounted');
        }
        , watch: {
            'selectedClientId': async function (val, oldVal) {
                if (!(oldVal === undefined)) {//when oldVal=undefined, that is when the value is initially set.
                    this.selectedOwnerId = null; //if Client is changed, then owner must be reset
                    this.owners = await this.vam_GetOwnersV2({
                        ClientId: this.selectedClientId
                        , ClientIdList: undefined
                    });

                    this.fetchNotes();
                }
            }
            , 'selectedOwnerId': function (val, oldVal) {
                if (!(oldVal === undefined)) {//when oldVal=undefined, that is when the value is initially set.
                    this.fetchNotes();
                }
            }
            , 'selectedStatusId': function (val, oldVal) {
                if (!(oldVal === undefined)) {//when oldVal=undefined, that is when the value is initially set.
                    this.fetchNotes();
                }
            }
        }
        , computed: {
        }
        , methods: {
            rowClick(row) {
                console.log('row clicked', row);
                this.selectedNote = row;
            }
            , fetchNotes: function (NoteTypeId) {
                this.notes = this.getNotes(this.selectedClientId, this.selectedOwnerId, this.selectedStatusId, this.NoteTypeId);
            }
        }
        , destroyed() {
            console.log('Destroyed:');
            console.log(this);
        }

    })
</script>
<style>

</style>