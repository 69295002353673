import Vue from 'vue';

export class FXImportService extends Vue {

  private _url: string =  '/fx/';

    async importData(): Promise<any> {
    const endpoint: string = this._url + 'import';
    try {
      const response = await this.$http.post(endpoint, {});
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}
