// TS API for Schema vam
// Auto-generated 06/14/24 13:15


declare var smartData: any;
// output classes:
export class Addresses {
    Id: number=undefined;
    Description: string=undefined;
    CareOf: string=undefined;
    Street: string=undefined;
    Street2: string=undefined;
    City: string=undefined;
    County: string=undefined;
    StateId: number=undefined;
    StateName: string=undefined;
    StateAbbreviation: string=undefined;
    Zip: string=undefined;
    CountryId: number=undefined;
    CountryName: string=undefined;
    FullStreet: string=undefined;
    CityStateZip: string=undefined;
    FullAddress: string=undefined;
    FullAddressWithCareOf: string = undefined;
}
export class ChartOfAccountsForTree {
    Id: number=undefined;
    Name: string=undefined;
    TreeLevel: number=undefined;
    TreePath: string=undefined;
    NamePath: string=undefined;
    ParentId: string=undefined;
    IdArray: string=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    DisableSelection: boolean = undefined;
}
export class Contacts {
    Id: number=undefined;
    Name: string=undefined;
    Address1: string=undefined;
    Address2: string=undefined;
    City: string=undefined;
    StateProvince: string=undefined;
    CityStateProvince: string=undefined;
    PostalCode: string=undefined;
    Email: string=undefined;
    Title: string=undefined;
    EntityType: string=undefined;
    EntityId: number = undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string = undefined;
    Created: string=undefined;
    Modified: string = undefined;
    Phone1: string=undefined;
    Phone1Type: number=undefined;
    Phone1TypeText: string=undefined;
    Phone2: string=undefined;
    Phone2Type: number=undefined;
    Phone2TypeText: string=undefined;
}
export class Currencies {
    GroupBy: string=undefined;
    Display: string=undefined;
    ISOCode: string = undefined;
}
export class Departments {
    DepartmentId: number=undefined;
    Name: string=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    GeneralAssetId: number = undefined;
}
export class EntityForeignStates {
    AssociationId: number=undefined;
    EntityId: number=undefined;
    StateId: number=undefined;
    StateName: string=undefined;
    StateAbbreviation: string=undefined;
    CountryName: string=undefined;
    RegisteredAgentId: number=undefined;
    RegisteredAgentName: string=undefined;
    RegisteredAgentFullStreet: string=undefined;
    RegisteredAgentCityStateZip: string=undefined;
    RegisteredAgentFullAddress: string=undefined;
    RegisteredAgentCounty: string = undefined;
}
export class EntityUses {
    EntityUsesId: number=undefined;
    EntityId: number=undefined;
    EntityUseId: number=undefined;
    KeyText: string=undefined;
    BitValue: boolean = undefined;
}
export class ExcelCellImportConfiguration {
    Id: number=undefined;
    ImportType: string=undefined;
    ClientId: number=undefined;
    Description: string=undefined;
    Description2: string=undefined;
    Description2Label: string=undefined;
    IntDescription: number=undefined;
    IntDescriptionLabel: string=undefined;
    DriveId: string=undefined;
    FileId: string=undefined;
    SheetName: string=undefined;
    Cell: string=undefined;
    FullPath: string=undefined;
    DisplayOrder: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    EntityType2: string=undefined;
    EntityId2: number=undefined;
    ImportTypeId: number=undefined;
    ClientName: string=undefined;
    Created: string=undefined;
    Modified: string = undefined;
}
export class ExcelCellImportTypes {
    Id: number=undefined;
    Name: string=undefined;
    Description2Label: string=undefined;
    IntDescriptionLabel: string=undefined;
    EntityType: string=undefined;
    EntityType2: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string = undefined;
}
export class ExtraOwners {
    OwnerId: number=undefined;
    Name: string=undefined;
    ShortName: string=undefined;
    OwnerCategoryName: string=undefined;
    TaxClassName: string=undefined;
    HasManualBankAccounts: boolean = undefined;
}
export class KeyPersonClients {
    ClientId: number=undefined;
    ClientName: string=undefined;
    KeyPersonId: number=undefined;
    KeyPersonClientId: number=undefined;
    Selected: boolean = undefined;
}
export class KeyPersons {
    Id: number=undefined;
    NameLast: string=undefined;
    NameFirst: string=undefined;
    NameMiddle: string=undefined;
    NameSuffix: string=undefined;
    FullName: string=undefined;
    Nickname: string=undefined;
    NameLastFirst: string=undefined;
    NameFirstLast: string=undefined;
    MailingAddressId: number=undefined;
    FullAddress: string=undefined;
    Email: string=undefined;
    Phone1TypeId: number=undefined;
    Phone1TypeText: string=undefined;
    Phone1: string=undefined;
    Phone2TypeId: number=undefined;
    Phone2TypeText: string=undefined;
    Phone2: string=undefined;
    Title: string=undefined;
    Priority: number=undefined;
    RelationshipId: number=undefined;
    RelationshipText: string=undefined;
    Company: string=undefined;
    Description: string=undefined;
    NotClientSpecific: boolean=undefined;
    ApproverSelectionRecords: number=undefined;
    BankAccountSigners: number=undefined;
    Deleteable: boolean=undefined;
    NotDeleteableReason: string=undefined;
    USSMSNumber: string=undefined;
    Created: string=undefined;
    Modified: string=undefined;
    PaymentConfirmationMethod: string = undefined;
}
export class Markdown {
    Value: string = undefined;
}
export class OutstandingVendorVerifications {
    Id: number=undefined;
    VendorId: number=undefined;
    Vendor: string=undefined;
    KeyPersonId: number=undefined;
    Verifier: string=undefined;
    USSMSNumber: string=undefined;
    VerificationType: string=undefined;
    VerificationId: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    Sent: boolean=undefined;
    Delivered: boolean=undefined;
    DeliveryStatus: string=undefined;
    DeliveryStatusDetails: string=undefined;
    MessageId: string=undefined;
    Created: string=undefined;
    Canceled: string=undefined;
    Closed: boolean=undefined;
    Response: boolean = undefined;
}
export class OwnerCategories {
    Id: number=undefined;
    SuperCategoryId: number=undefined;
    SuperCategoryText: string=undefined;
    Name: string=undefined;
    Description: string = undefined;
}
export class OwnerRoleAssignments {
    AssociationId: number=undefined;
    OwnerId: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    RoleTypeId: string=undefined;
    RoleTypeText: string=undefined;
    RoleId: number=undefined;
    RoleText: string=undefined;
    AssigneeName: string=undefined;
    Note: string=undefined;
    RoleTypeSort: number=undefined;
    RoleSort: number = undefined;
}
export class OwnersForAssignment {
    OwnerId: number=undefined;
    Name: string=undefined;
    ShortName: string=undefined;
    OwnerCategoryName: string=undefined;
    TaxClassName: string=undefined;
    HasManualBankAccounts: boolean = undefined;
}
export class OwnersList {
    OwnerId: number=undefined;
    Name: string=undefined;
    ShortName: string=undefined;
    ShortestName: string=undefined;
    OwnerCategoryName: string=undefined;
    TaxClassName: string=undefined;
    HasManualBankAccounts: boolean=undefined;
    BankAccountNameDisplay: string = undefined;
}
export class OwnersV2 {
    Id: number=undefined;
    Name: string=undefined;
    ShortName: string=undefined;
    FormerlyKnownAs: string=undefined;
    ClientId: number=undefined;
    ClientName: string=undefined;
    OwnerCategoryId: number=undefined;
    OwnerCategoryName: string=undefined;
    TaxClassId: number=undefined;
    TaxClassName: string=undefined;
    TaxPayerId: number=undefined;
    TaxPayerName: string=undefined;
    State: string=undefined;
    StateId: number=undefined;
    StateName: string=undefined;
    TaxId: string=undefined;
    FoundedDate: string=undefined;
    FoundedDateFormatted: string=undefined;
    DissolvedDate: string=undefined;
    AmendmentDate: string=undefined;
    MailingAddressId: number=undefined;
    MailingAddressDescription: string=undefined;
    MailingAddressCareOf: string=undefined;
    MailingAddressStreet: string=undefined;
    MailingAddressStreet2: string=undefined;
    MailingAddressCity: string=undefined;
    MailingAddressStateAbbreviation: string=undefined;
    MailingAddressZip: string=undefined;
    MailingAddressFull: string=undefined;
    PhysicalAddressId: number=undefined;
    PhysicalAddressDescription: string=undefined;
    PhysicalAddressCareOf: string=undefined;
    PhysicalAddressStreet: string=undefined;
    PhysicalAddressStreet2: string=undefined;
    PhysicalAddressCity: string=undefined;
    PhysicalAddressStateAbbreviation: string=undefined;
    PhysicalAddressZip: string=undefined;
    PhysicalAddressFull: string=undefined;
    BankAccountRequired: boolean=undefined;
    BankId: number=undefined;
    AccountId: number=undefined;
    BankAccountId: number=undefined;
    InvestmentAccountRequired: boolean=undefined;
    DefaultInvestmentAccountId: number=undefined;
    Purpose: string=undefined;
    AccountingMethod: string=undefined;
    SetupPayer: string=undefined;
    NameReservation: string=undefined;
    CountStakeholders: number=undefined;
    CountAuthorizedParties: number=undefined;
    CountForeignStates: number=undefined;
    StakeholdersList: string=undefined;
    AuthorizedPartiesList: string=undefined;
    ForeignStatesList: string=undefined;
    CountAssets: number=undefined;
    CountLiabilities: number=undefined;
    CountAgreements: number=undefined;
    Investments: number=undefined;
    GeneralAssets: number=undefined;
    Accounts: number=undefined;
    Deletable: boolean=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    BankAccountNameDisplay: string=undefined;
    DefaultInvestmentAccountNameDisplay: string=undefined;
    HasManualBankAccounts: boolean=undefined;
    HasDocumentOperatingAgreement: boolean=undefined;
    HasMultipleOperatingAgreement: boolean=undefined;
    PathOperatingAgreement: string=undefined;
    FileIdOperatingAgreement: string=undefined;
    HasDocumentCertificateOfFormation: boolean=undefined;
    HasMultipleCertificateOfFormation: boolean=undefined;
    PathCertificateOfFormation: string=undefined;
    FileIdCertificateOfFormation: string=undefined;
    HasDocumentForeignStateApplicationForAuthority: boolean=undefined;
    HasMultipleForeignStateApplicationForAuthority: boolean=undefined;
    PathForeignStateApplicationForAuthority: string=undefined;
    FileIdForeignStateApplicationForAuthority: string=undefined;
    DefaultDocumentStoreId: number=undefined;
    DefaultDocumentPath: string=undefined;
    RegisteredAgentId: number=undefined;
    RegisteredAgent: string=undefined;
    RegisteredAgentFileId: string=undefined;
    RegisteredAgentFullStreet: string=undefined;
    RegisteredAgentCityStateZip: string=undefined;
    RegisteredAgentFullAddress: string=undefined;
    RegisteredAgentCounty: string=undefined;
    IsMultiMember: boolean=undefined;
    ReportingCategory: string=undefined;
    ReportingCategoryId: number=undefined;
    ReportingSubCategory: string=undefined;
    ReportingSubCategoryId: number=undefined;
    Email: string = undefined;
}
export class RegisteredAgents {
    Id: number=undefined;
    Name: string=undefined;
    AddressId: number=undefined;
    StateId: number=undefined;
    FullStreet: string=undefined;
    CityStateZip: string=undefined;
    County: string=undefined;
    State: string=undefined;
    FullAddress: string = undefined;
}
export class SingleMemberEntityAgreementData {
    OwnerId: number=undefined;
    Name: string=undefined;
    Member: string=undefined;
    MemberKeyPersonId: number=undefined;
    Manager: string=undefined;
    ManagerKeyPersonId: number=undefined;
    FoundedDate: string=undefined;
    FoundedDateFormatted: string=undefined;
    StateName: string = undefined;
}
export class SMSDeliveryStatus {
    Id: number=undefined;
    MessageId: string=undefined;
    Sent: boolean=undefined;
    Complete: boolean=undefined;
    Delivered: boolean=undefined;
    DeliveryStatus: string=undefined;
    DeliveryStatusDetails: string = undefined;
}
export class States {
    Id: number=undefined;
    Name: string=undefined;
    Abbreviation: string=undefined;
    CountryId: number=undefined;
    CountryName: string = undefined;
}
export class UserClients {
    Id: number=undefined;
    Name: string=undefined;
    FullName: string=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    ModifyDate: string=undefined;
    ModifyUser: string=undefined;
    EntityId: number=undefined;
    SettingsJSON: string=undefined;
    VendorVerificationNotRequired: boolean = undefined;
}
export class VendorClients {
    ClientId: number=undefined;
    ClientName: string=undefined;
    VendorClientId: number=undefined;
    VendorId: number=undefined;
    COAId: number=undefined;
    DepartmentId: number=undefined;
    COAName: string=undefined;
    COANamePath: string=undefined;
    COAIdArray: string=undefined;
    COAButtonText: string=undefined;
    DepartmentName: string=undefined;
    DepartmentButtonText: string=undefined;
    DefaultInvoiceApproverKeyPersonId: number=undefined;
    DefaultInvoiceApproverNameFirstLast: string=undefined;
    DefaultInvoiceApproverButtonText: string=undefined;
    AgreementCount: number=undefined;
    InvoiceCount: number=undefined;
    ContractCount: number=undefined;
    PrepaidCount: number=undefined;
    Removable: boolean=undefined;
    Selected: boolean = undefined;
}
export class Vendors {
    Id: number=undefined;
    Name: string=undefined;
    PaymentNotes: string=undefined;
    Notes: string=undefined;
    W9Required: boolean=undefined;
    W9RequiredCheckMark: string=undefined;
    HasW9: boolean=undefined;
    HasW9CheckMark: string=undefined;
    W9Satisfied: string=undefined;
    TaxpayerId: string=undefined;
    TaxpayerIdTypeId: number=undefined;
    TaxpayerIdType: string=undefined;
    TaxpayerIdTypeAbbreviation: string=undefined;
    TaxpayerIdMask: string=undefined;
    TaxpayerIdTypeIRSCode: number=undefined;
    StreetAddress: string=undefined;
    City: string=undefined;
    State: string=undefined;
    Zip: string=undefined;
    ClientCount: number=undefined;
    AgreementCount: number=undefined;
    InvoiceCount: number=undefined;
    Deletable: boolean=undefined;
    TaxClassification: string=undefined;
    IsCharitable: boolean=undefined;
    AnnualLetterNotRequired: boolean=undefined;
    HasAnnualLetter: boolean=undefined;
    DocumentDateAnnualLetter: string=undefined;
    AnnualLetterRequirementSatisfied: string=undefined;
    Editable: boolean=undefined;
    ord: number=undefined;
    Source: string=undefined;
    DefaultPaymentMethodId: number=undefined;
    DefaultPaymentMethod: string=undefined;
    HasVendorForm: boolean=undefined;
    VendorFormNotRequired: boolean=undefined;
    VendorFormMissing: boolean=undefined;
    VendorFormMissingCheckMark: string=undefined;
    SourceTypeId: number=undefined;
    SourceType: string=undefined;
    SourceTypeDescription: string=undefined;
    SourceVerificationRequired: boolean=undefined;
    SourceEntityType: string=undefined;
    SourceEntityId: number=undefined;
    SourceName: string=undefined;
    SourceVerificationResponseId: number=undefined;
    SourceVerificationResponse: boolean=undefined;
    IsVerified: boolean=undefined;
    IsVerificationException: boolean=undefined;
    VerificationDescription: string=undefined;
    ClientIdForVerifierSelection: number=undefined;
    IsComplete: boolean=undefined;
    Created: string=undefined;
    AllowInvoiceAmountUpdateForFX: boolean=undefined;
    ExemptOrganizationDetail: string = undefined;
}
export class VendorsNotAssignedToClient {
    Id: number=undefined;
    Name: string = undefined;
}

// Parameter interfaces:

export interface GetAddressesParameters {
    AddressId: number;
    OrderByAddress: boolean;
}

export interface GetChartOfAccountsForTreeParameters {
    ClientId: number;
    AccountId: number;
    NamePathSearch: string;
    SourceOnly: boolean;
}

export interface GetContactsParameters {
    EntityType: string;
    EntityId: number;
    ContactType: number;
}

export interface GetCurrenciesParameters {
    SearchTerm: string;
}

export interface GetDepartmentsParameters {
    ClientId: number;
    DepartmentId: number;
}

export interface GetEntityForeignStatesParameters {
    EntityId: number;
}

export interface GetEntityUsesParameters {
    EntityId: number;
}

export interface GetExcelCellImportConfigurationParameters {
    ImportTypeId: number;
    ConfigurationId: number;
}

export interface GetExtraOwnersParameters {
    OwnerId: number;
}

export interface GetKeyPersonClientsParameters {
    KeyPersonId: number;
}

export interface GetKeyPersonsParameters {
    ClientId: number;
    KeyPersonId: number;
    HasEmailAddress: boolean;
    IsApproverOnly: boolean;
}

export interface GetMarkdownParameters {
    Name: string;
}

export interface GetOutstandingVendorVerificationsParameters {
    KeyPersonId: number;
    VendorId: number;
}

export interface GetOwnerRoleAssignmentsParameters {
    OwnerId: number;
    RoleTypeId: number;
}

export interface GetOwnersForAssignmentParameters {
    OwnerId: number;
}

export interface GetOwnersListParameters {
    ClientId: number;
    ClientIdList: string;
    UsesList: string;
    ConsiderationEntityType: string;
}

export interface GetOwnersV2Parameters {
    ClientId: number;
    ClientIdList: string;
    OwnerId: number;
}

export interface GetRegisteredAgentsParameters {
    State: string;
    StateId: number;
}

export interface GetSingleMemberEntityAgreementDataParameters {
    OwnerId: number;
}

export interface GetSMSDeliveryStatusParameters {
    SMSId: number;
}

export interface GetStatesParameters {
    CountryId: number;
}

export interface GetVendorClientsParameters {
    VendorId: number;
}

export interface GetVendorsParameters {
    ClientId: number;
    SearchTerm: string;
    AddNew: boolean;
    ClientIdList: string;
    VendorId: number;
    IsCharitable: boolean;
}

export interface GetVendorsNotAssignedToClientParameters {
    ClientId: number;
}

export class VamService {

  GetAddresses (params: GetAddressesParameters): Promise<Addresses[]> {
    return smartData({
        storedProcName: 'vam.GetAddresses',
        params: params
      });
    }

  GetChartOfAccountsForTree (params: GetChartOfAccountsForTreeParameters): Promise<ChartOfAccountsForTree[]> {
    return smartData({
        storedProcName: 'vam.GetChartOfAccountsForTree',
        params: params
      });
    }

  GetContacts (params: GetContactsParameters): Promise<Contacts[]> {
    return smartData({
        storedProcName: 'vam.GetContacts',
        params: params
      });
    }

  GetCurrencies (params: GetCurrenciesParameters): Promise<Currencies[]> {
    return smartData({
        storedProcName: 'vam.GetCurrencies',
        params: params
      });
    }

  GetDepartments (params: GetDepartmentsParameters): Promise<Departments[]> {
    return smartData({
        storedProcName: 'vam.GetDepartments',
        params: params
      });
    }

  GetEntityForeignStates (params: GetEntityForeignStatesParameters): Promise<EntityForeignStates[]> {
    return smartData({
        storedProcName: 'vam.GetEntityForeignStates',
        params: params
      });
    }

  GetEntityUses (params: GetEntityUsesParameters): Promise<EntityUses[]> {
    return smartData({
        storedProcName: 'vam.GetEntityUses',
        params: params
      });
    }

  GetExcelCellImportConfiguration (params: GetExcelCellImportConfigurationParameters): Promise<ExcelCellImportConfiguration[]> {
    return smartData({
        storedProcName: 'vam.GetExcelCellImportConfiguration',
        params: params
      });
    }

  GetExcelCellImportTypes (): Promise<ExcelCellImportTypes[]> {
    return smartData({
        storedProcName: 'vam.GetExcelCellImportTypes',
        params: {}
      });
    }

  GetExtraOwners (params: GetExtraOwnersParameters): Promise<ExtraOwners[]> {
    return smartData({
        storedProcName: 'vam.GetExtraOwners',
        params: params
      });
    }

  GetKeyPersonClients (params: GetKeyPersonClientsParameters): Promise<KeyPersonClients[]> {
    return smartData({
        storedProcName: 'vam.GetKeyPersonClients',
        params: params
      });
    }

  GetKeyPersons (params: GetKeyPersonsParameters): Promise<KeyPersons[]> {
    return smartData({
        storedProcName: 'vam.GetKeyPersons',
        params: params
      });
    }

  GetMarkdown (params: GetMarkdownParameters): Promise<Markdown[]> {
    return smartData({
        storedProcName: 'vam.GetMarkdown',
        params: params
      });
    }

  GetOutstandingVendorVerifications (params: GetOutstandingVendorVerificationsParameters): Promise<OutstandingVendorVerifications[]> {
    return smartData({
        storedProcName: 'vam.GetOutstandingVendorVerifications',
        params: params
      });
    }

  GetOwnerCategories (): Promise<OwnerCategories[]> {
    return smartData({
        storedProcName: 'vam.GetOwnerCategories',
        params: {}
      });
    }

  GetOwnerRoleAssignments (params: GetOwnerRoleAssignmentsParameters): Promise<OwnerRoleAssignments[]> {
    return smartData({
        storedProcName: 'vam.GetOwnerRoleAssignments',
        params: params
      });
    }

  GetOwnersForAssignment (params: GetOwnersForAssignmentParameters): Promise<OwnersForAssignment[]> {
    return smartData({
        storedProcName: 'vam.GetOwnersForAssignment',
        params: params
      });
    }

  GetOwnersList (params: GetOwnersListParameters): Promise<OwnersList[]> {
    return smartData({
        storedProcName: 'vam.GetOwnersList',
        params: params
      });
    }

  GetOwnersV2 (params: GetOwnersV2Parameters): Promise<OwnersV2[]> {
    return smartData({
        storedProcName: 'vam.GetOwnersV2',
        params: params
      });
    }

  GetRegisteredAgents (params: GetRegisteredAgentsParameters): Promise<RegisteredAgents[]> {
    return smartData({
        storedProcName: 'vam.GetRegisteredAgents',
        params: params
      });
    }

  GetSingleMemberEntityAgreementData (params: GetSingleMemberEntityAgreementDataParameters): Promise<SingleMemberEntityAgreementData[]> {
    return smartData({
        storedProcName: 'vam.GetSingleMemberEntityAgreementData',
        params: params
      });
    }

  GetSMSDeliveryStatus (params: GetSMSDeliveryStatusParameters): Promise<SMSDeliveryStatus[]> {
    return smartData({
        storedProcName: 'vam.GetSMSDeliveryStatus',
        params: params
      });
    }

  GetStates (params: GetStatesParameters): Promise<States[]> {
    return smartData({
        storedProcName: 'vam.GetStates',
        params: params
      });
    }

  GetUserClients (): Promise<UserClients[]> {
    return smartData({
        storedProcName: 'vam.GetUserClients',
        params: {}
      });
    }

  GetVendorClients (params: GetVendorClientsParameters): Promise<VendorClients[]> {
    return smartData({
        storedProcName: 'vam.GetVendorClients',
        params: params
      });
    }

  GetVendors (params: GetVendorsParameters): Promise<Vendors[]> {
    return smartData({
        storedProcName: 'vam.GetVendors',
        params: params
      });
    }

  GetVendorsNotAssignedToClient (params: GetVendorsNotAssignedToClientParameters): Promise<VendorsNotAssignedToClient[]> {
    return smartData({
        storedProcName: 'vam.GetVendorsNotAssignedToClient',
        params: params
      });
    }
}
