var mixinAttachmentService = {
  methods: {
    async getExternalLinkForEntity(entityType, entityId) {
      const endpoint = '/Attachment/GetExternalLinkForEntity';
      try {
        const response = await this.$http.post(endpoint, {
          'entityType': entityType,
          'entityId': entityId
        });
        return response;
      } catch (error) {
        console.log(error);
        return error;
      }
    }
  }
};
export default mixinAttachmentService;
