
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import KeyPersonTabView from '@/views/KeyPerson/KeyPersonTabView.vue';
import KeyPersonEdit from '@/views/KeyPerson/KeyPersonEdit.vue';
import * as VamService from './../../services/DAL/vamService';
import findIndex from 'lodash/findIndex';
import ElementUI from 'element-ui';

@Component({
    components: {
        KeyPersonTabView,
        KeyPersonEdit
    }
})
export default class KeyPersonList extends Vue {
    $refs: {
        tblKeyPersonList: ElementUI.Table
    }
    //#region Data
    private _vamService: VamService.VamService;

    keyPersons: VamService.KeyPersons[] = [];
    filter: string = '';
    selectedKeyPerson = null;
    showKeyPersonEdit = false;
    showKeyPersonCreate = false;
    isLoading = false;
    //#endregion Data

    //#endregion Computed
    get showDetailColumns(): boolean {
        return (
            ['xs', 'sm', 'md'].indexOf(this.$getBootstrapSize()) > 0 ||
            !this.showKeyPersonEdit
        );
    }

    get keyPersonsFiltered(): VamService.KeyPersons[] {
        return this.keyPersons.filter(keyPerson => {
            const filter_ = (this.filter || '').toLowerCase();
            return (
                (keyPerson.NameLastFirst || '').toLowerCase().indexOf(filter_) >
                    -1 ||
                (keyPerson.NameFirstLast || '').toLowerCase().indexOf(filter_) >
                    -1 ||
                (keyPerson.FullName || '').toLowerCase().indexOf(filter_) >
                    -1 ||
                (keyPerson.Nickname || '').toLowerCase().indexOf(filter_) >
                    -1 ||
                (keyPerson.Email || '').toLowerCase().indexOf(filter_) > -1 ||
                (keyPerson.Phone1 || '').toLowerCase().indexOf(filter_) > -1 ||
                (keyPerson.Phone2 || '').toLowerCase().indexOf(filter_) > -1
            );
        });
    }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();
    }

    async mounted() {
        this.fetchKeyPersons();
    }
    //#endregion Lifecycle

    //#region Methods
    selectKeyPerson(keyPerson) {
        if (keyPerson) {
            this.showKeyPersonEdit = false;
            this.$nextTick(() => {
                this.showKeyPersonEdit = true;
                this.selectedKeyPerson = keyPerson;
            });
        }
    }

    closeKeyPerson() {
        this.showKeyPersonEdit = false;
        this.selectedKeyPerson = null;
        this.$refs.tblKeyPersonList.setCurrentRow(null);
    }

    onDeleteKeyPerson(keyPersonId) {
        // this.fetchKeyPersons();
        if (
            this.selectedKeyPerson &&
            this.selectedKeyPerson.Id == keyPersonId
        ) {
            const index = findIndex(this.keyPersons, keyPerson => {
                return keyPerson.Id === this.selectedKeyPerson.Id;
            });
            if (index > -1) {
                this.keyPersons.splice(index, 1);
            }
        }
        this.closeKeyPerson();
    }

    async fetchKeyPersons() {
        this.isLoading = true;
        const params = {} as VamService.GetKeyPersonsParameters;
        this.keyPersons = await this._vamService.GetKeyPersons(params);
        this.isLoading = false;
    }
    //#endregion Methods
}
