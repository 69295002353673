
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as VamService from '../../services/DAL/vamService';
import Common from '../../utils/common';

declare function tryParseInt(input: any, defaultValue: number): number;
declare function getAppCookieKeyValue(key: string, defaultValue: object): string;
declare function setAppCookieKeyValue(key: string, value: number);

@Component({
    inheritAttrs: false
})
export default class ClientSelector extends Vue {
    //#region Private declarations for Services
    private _vam_Service: VamService.VamService;
    private _common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({ type: Number }) readonly value: number;
    @Prop({ type: String, default: 'standard' }) readonly singleOptionBehavior: string; // label
    @Prop({ type: Boolean, default: true }) readonly useCookieValue: boolean; // label
    @Prop({ type: Boolean, default: false}) readonly readOnly: boolean;

    //#endregion Props

    //#region Data
    clients = [] as VamService.UserClients[];
    selectedValue: number = null;
    selectedClient = {} as VamService.UserClients;
    //#endregion Data

    //#region Computed
    //#endregion Computed

   //#region Lifecycle
    async created() {
        this._vam_Service = new VamService.VamService();
        this._common = new Common();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchClients();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    async onClientChange(val: number, oldVal: number) {
        this.selectedValue = val;
        await setAppCookieKeyValue('SelectedClientId', val);
        this.getSelectedClient();
        // if (this.clients.length) {
        //     let selectedObject: VamService.UserClients = new VamService.UserClients();
        //     if (this.selectedValue) {
        //         selectedObject = this._common.getSelectedArrayItem(this.clients, this.selectedValue.toString(), 'Id');
        //     }
        //     this.$emit('value-set', selectedObject);
        // }
    }
    @Watch('selectedClient')
    onChange_selectedClient(val: VamService.UserClients, oldVal: VamService.UserClients) {
        this.$emit('value-set', this.selectedClient);
    }

    //#endregion Watches

    //#region Methods
    async fetchClients() {
        this.clients = [];
        this.clients = await this._vam_Service.GetUserClients();
        this.$emit('options-fetched', this.clients);
        if (this.clients.length === 1){
            this.selectedValue = this.clients[0].Id;
            this.$emit('single-option-available', this.selectedValue);
            if (this.selectedValue !== this.value) {
                this.$emit('input', this.selectedValue);
                // this.$emit('value-set', this.clients[0]);
            }
        }
        else if (!this.value && this.useCookieValue) { //only check for cookie value if there is not a value already set
            const selectedClientId = tryParseInt(await getAppCookieKeyValue('SelectedClientId', null), null);
            if (selectedClientId) {
                this.selectedValue = selectedClientId;
                if (this.selectedValue !== this.value) {
                    this.$emit('input', this.selectedValue);
                    // this.$emit('value-set', selectedClientId);
                }
            }
        }

        if (this.value && this.clients && this.clients.length){
            this.$emit('initial-value-set', this.clients.find(client => client.Id == this.value));
        }
        this.getSelectedClient();
    }
    getSelectedClient() {
        if (this.clients.length && this.selectedValue > 0) {
            this.selectedClient = this._common.getSelectedArrayItem(this.clients, this.selectedValue.toString(), 'Id');
        }
        else {
            this.selectedClient = new VamService.UserClients();
        }
    }

    //#endregion Methods
}
