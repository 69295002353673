
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ValidationProviderInstance, ValidationObserverInstance } from 'vee-validate';
import FormattedInput from '@/components/form/FormattedInput.vue';
import CommentLogList from '@/js/CommentLog/CommentLogList.vue';
import * as Common from '../../utils/common';
import * as LiqService from '../../services/DAL/liq_Service';
import cloneDeep from 'lodash/cloneDeep';

declare var SmartObject: any;
declare function tryParseInt(input: number, deflt: number): number;
declare function getStoredSecurityLevel(Id: number): number;

class EndType{
    description: string;
    showNumberOfPayments: boolean;
    showTotalOfPayments: boolean;
    showLastPayment: boolean;
}

@Component({
    components: {
        FormattedInput
        , CommentLogList
    }
})
export default class ScheduleEdit extends Vue {
    //#region Private declarations for Services
    private _liqService: LiqService.LiqService;
    private _common: Common.default;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() schedule: LiqService.Schedules ;
    @Prop() scheduleId: number;
    @Prop() entityId: number;
    @Prop() entityType: string;
    @Prop({ type: Number, default: null }) securityLevel: number;
    //#endregion Props

    schedule_= {} as LiqService.Schedules;
    frequencies = [] as LiqService.Frequencies[];
    securityLevel_: number = this.securityLevel;
    showCommentLogList: boolean = true;
    loading: boolean = false;
    notes: string = '';
    endTypes= [] as EndType[];
    //#region Data
    
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
        key => (this as any).veeFields[key].dirty
        );
    }
    get selectedEndType(): EndType {
        let et: EndType = {} as EndType;
        if (this.$objectPropertyIfExists(this.schedule_, 'EndTypeDescription')){
            et = this._common.getSelectedArrayItem(this.endTypes, this.schedule_.EndTypeDescription, 'description');
        }
        return et;
    }
    
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();
        this._common = new Common.default();
        this.initiateEndTypes();
        if (this.securityLevel_ === null) {
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP),
            0
        );
            }
        await this.fetchFrequencies();
        if (!!this.schedule && Object.keys(this.schedule).length){
            this.schedule_ = cloneDeep(this.schedule);
        }
        else await this.fetchSchedule();
        await (this.$refs.commentLog as HTMLFormElement).fetchCommentLog();
    }
    mounted() {
        //this.focus();
    }
    //#endregion Lifecycle
    
    //#region Watches
    //#endregion Watches

    //#region Methods
    async save(){
        let valid: boolean = false;
        try {
            valid = await (this.$refs.frmScheduleEdit as ValidationObserverInstance).validate();
        }
        catch{}
        if (valid) {
            this.loading = true;
            if (!this.selectedEndType.showNumberOfPayments) this.schedule_.NumberOfPayments = null;
            if (!this.selectedEndType.showTotalOfPayments) this.schedule_.TotalOfPayments = null;
            if (!this.selectedEndType.showLastPayment) this.schedule_.LastPaymentDate = null;

        const id = await new SmartObject('Schedule', this.schedule_.Id).updateObject(
                this.schedule_
            );
            this.schedule_.Id = id;
            await this.saveNotes();
            this.$notify.success('Schedule updated');
            this.reAllocate();
            this.$validator.reset();
            this.$emit('save', this.schedule_);
            this.loading = false;
        }
    }
    async saveNotes() {
        if (this.notes) {
            await new SmartObject('CommentLog').createObject({
                EntityType: 'Schedule',
                EntityId: this.schedule_.Id,
                Comment: this.notes
            });

            this.showCommentLogList = false;
            await this.$nextTick();
            this.showCommentLogList = true;
        }
    }    
    async reAllocate(){
        if(this.isFieldDirty('Amount')){
            const parms = {} as LiqService.AllocateAgreementParameters;
            parms.AgreementId = this.schedule_.EntityId;
            const updatedAllocations: LiqService.AllocateAgreement[] = await this._liqService.AllocateAgreement(parms);
            this.$emit('allocation-updated', updatedAllocations);
            this.$notify.success('Updated Allocation');
        }
    }
    isFieldDirty(name: string) {
        const field = this.$validator.fields.find({ name });
        if (field) {
            return field.flags.dirty;
        }
        return false;
    }
    initiateEndTypes(){
        this.endTypes.push({
            description: 'Number of Payments',
            showNumberOfPayments: true,
            showTotalOfPayments: false,
            showLastPayment: false
        });
        this.endTypes.push({
            description: 'Total of Payments',
            showNumberOfPayments: false,
            showTotalOfPayments: true,
            showLastPayment: false
        });
        this.endTypes.push({
            description: 'Last Payment Date',
            showNumberOfPayments: false,
            showTotalOfPayments: false,
            showLastPayment: true
        });        
        this.endTypes.push({
            description: 'No End',
            showNumberOfPayments: false,
            showTotalOfPayments: false,
            showLastPayment: false
        });
    }
    async fetchSchedule(){
        const parameters = {} as LiqService.GetSchedulesParameters;
        if (this.scheduleId){
            parameters.ScheduleId = this.scheduleId;
        }
        else {
            parameters.EntityType = this.entityType;
            parameters.EntityId = this.entityId;
        }
        const schedules = await this._liqService.GetSchedules(
            parameters
        );
        if (schedules.length == 1) {
            this.schedule_ = schedules[0];
        }
    }
    async fetchFrequencies(){
        this.frequencies = await this._liqService.GetFrequencies();
    }
    focus(){
        (this.$refs.inp as HTMLFormElement).focus();
    }
    //#endregion Methods
}
