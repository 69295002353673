declare var process: any;
declare var SmartJS: any;
declare var SmartPortlet: any;
declare var SmartPopup: any;
declare var SmartConfirm: any;
declare var SmartPrompt: any;
declare var SmartAlert: any;
declare var SmartControl: any;
declare var SmartRepeater: any;
declare var SmartFileUpload: any;
declare var SmartExport: any;
declare function replaceAll(string: string, find: string, replace: string): string;
declare function getBrowser();
// declare var $: any;
declare var storeSecurityProfile: any;
declare var window: any;

// gkb 06/10/20
import jQuery from 'jquery';
const $ = jQuery;
window.$ = $;
window.jQuery = jQuery;

import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store';

import Vuex from 'vuex';
import * as ElementUI from 'element-ui';
import './../scss/style.scss'; // Element, Bootstrap, and Core styles
import locale from 'element-ui/lib/locale/lang/en';
import Common from './utils/common';
import { AuthService } from './services/AuthService';

import moment from 'moment';
import accounting from 'accounting';
import numbro from 'numbro';
import veeValidate from 'vee-validate';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import filter from 'lodash/filter';
import kebabCase from 'lodash/kebabCase';
import findIndex from 'lodash/findIndex';
import truncate from 'lodash/truncate';

//Plugins
import '@/plugins/dayjs';

import VueCurrencyInput from 'vue-currency-input';
import VueNumeric from 'vue-numeric'

import axios, { RawAxiosRequestConfig, AxiosStatic } from 'axios';

import { APIConfig } from './app.config';

import DlgDraggable from 'vue-element-dialog-draggable';
//import ElCurrencyInput from './components/form/ELCurrencyInput.vue';
import ElCurrencyCompAPI from './components/form/elCurrencyCompAPI.vue';
Vue.component('el-currency-input', ElCurrencyCompAPI);


import VueMask from 'v-mask'
Vue.use(VueMask);

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/tab';
// import 'jquery-ui';
import 'jquery-ui-dist/jquery-ui.min.js'; // gkb 06/15/20
import 'datatables.net';
import 'datatables.net-bs4';
import 'font-awesome/css/font-awesome.min.css'; // gkb 06/11/20
import 'simple-line-icons/css/simple-line-icons.css'; // gkb 06/11/20
import * as asyncIteration from 'p-iteration';

const _auth: AuthService = new AuthService();

const validateConfig = {
  errorBagName: 'veeErrors', // change if property conflicts.
  fieldsBagName: 'veeFields',
  locale: 'en'
};

(window as any).Vue = Vue; // gkb 05/24/18

Vue.use(Vuex);
Vue.use(ElementUI, { locale });
Vue.use(DlgDraggable);
Vue.use(veeValidate, validateConfig);
(window as any).VeeValidate = veeValidate;
(window as any).Vue.use((window as any).VeeValidate, validateConfig); // DRC: I doubt this does anything. probably leftover from troubleshooting

Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.config.devtools = !(process.env.NODE_ENV === 'production');

Vue.prototype.$common = new Common();

Vue.prototype._isNumber = isNumber;
Vue.prototype._toNumber = toNumber;
Vue.prototype._filter = filter;
Vue.prototype._kebabCase = kebabCase;
Vue.prototype._findIndex = findIndex;

Vue.prototype.$moment = moment;
(window as any).moment = moment;

// const currencyInputOptions = {
//   globalOptions: { 
//       currency: 'USD'
//       , locale: 'en'
//       , precision: 2
//       //, distractionFree: false
//     }
// }
// Vue.use(VueCurrencyInput, currencyInputOptions);
Vue.use(VueNumeric);

accounting.settings = {
  currency: {
    symbol: '$', // default currency symbol is '$'
    format: {
      pos: '%s %v', // for positive values, eg. '$ 1.00' (required)
      neg: '%s (%v)', // for negative values, eg. '$ (1.00)' [optional]
      zero: '%s  -- ' // for zero values, eg. '$  --' [optional]
    },
    decimal: '.', // decimal point separator
    thousand: ',', // thousands separator
    precision: 2 // decimal places
  },
  number: {
    precision: 0, // default precision on numbers is 0
    thousand: ',',
    decimal: '.'
  }
};
Vue.prototype.$accounting = accounting;
(window as any).accounting = accounting;

(window as any).numbro = numbro;

const MicrosoftGraph = require('@microsoft/microsoft-graph-client');
(window as any).MicrosoftGraph = MicrosoftGraph;

(window as any)._truncate = truncate;

const axiosConfig: RawAxiosRequestConfig = APIConfig;
const ax = axios.create(axiosConfig);
ax.interceptors.request.use(
  config => {
    config.headers["Authorization"] = `Bearer ${store.getters.apiToken}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
ax.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.message == 'Network Error' || err.response) {
      // Access Token was expired
      if ((err.message == 'Network Error' || err.response.status === 401) && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          await store.dispatch('refreshToken');
          return ax(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);
Vue.use({
  install(Vue) {
    Vue.prototype.$http = ax;
  }
});

function asyncWait(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}
Vue.prototype.$asyncWait = asyncWait;

const setSystemStatus = (message: string) =>
  store.commit('systemStatusMessage', message);
Vue.prototype.$setSystemStatus = setSystemStatus;

const asyncForEach: any = async (array: any[], callback: any) => {
  const promises = array.map(async (item) => {
    await callback(item);
  });
  await Promise.all(promises);
};

Vue.prototype.$asyncForEach = asyncForEach;
Vue.prototype.$asyncIterations = asyncIteration;

const nullifyObjectProps = (obj) => Object.keys(obj).forEach(k => obj[k] = null);
Vue.prototype.$nullifyObjectProps = nullifyObjectProps;

const objectPropertyIfExists = function (obj: any, prop: string): any {
  if (!!obj && Object.keys(obj).length) {
    return obj[prop];
  }
  return undefined;
};
Vue.prototype.$objectPropertyIfExists = objectPropertyIfExists;

// derived from https://stackoverflow.com/questions/11793430/retry-a-jquery-ajax-request-which-has-callbacks-attached-to-its-deferred
$.ajaxPrefilter(function(options, originalOptions, jqXHR) {
  // you could pass this option in on a 'retry' so that it doesn't
  // get all recursive on you.
  if ((options as any).isRefreshRequest) {
    return;
  }

  // our own deferred object to handle done/fail callbacks
  const dfd = $.Deferred();

  // if the request works, return normally
  jqXHR.done(dfd.resolve);

  // if the request fails, do something else
  // yet still resolve
  jqXHR.fail(function() {
    const args = Array.prototype.slice.call(arguments);

    if (jqXHR.status === 401) {
      store
        .dispatch('refreshToken')
        .then(result => {
          // gkb 10/24/18 - just retry, now that the token has been refreshed
          const newOptions = $.extend(
            {},
            originalOptions,
            {
              isRefreshRequest: true
            }
          );
          // pass this one on to our deferred pass or fail.
          $.ajax(newOptions).then(dfd.resolve, dfd.reject);
        })
        .catch(result => {
          console.error(result);
          // reject with the original 401 data
          dfd.rejectWith(jqXHR, args);
        });
    } else {
      dfd.rejectWith(jqXHR, args);
    }
  });

  // NOW override the jqXHR's promise functions with our deferred
  // return dfd.promise(jqXHR);
  dfd.resolve(jqXHR);
});

// Override $.ajax to call .acquireTokenSilent() before every async AJAX call.
// (We can only do this for async AJAX calls because .acquireTokenSilent() is asynchronous.)
const origAjax = $.ajax;
$.ajax = function () {
    // console.log('AJAX override: begin');
    const this_ = this;
    const arguments_ = arguments;

    if (arguments_[0].async === false) {
        // console.log('AJAX override: early exit - async false');
        return origAjax.apply(this_, arguments_);
    }
    else {
        const deferred = $.Deferred();
        const deferredPromise = deferred.promise();
        let jqXHROfCall;

        (deferredPromise as any).abort = function () { }; // make sure abort is defined in case it is called before defined in .then below
        (deferredPromise as any).readyState = 0; // initialize readyState as 'unsent'

        _auth.acquireTokenSilent().then(function (tokenResponse) {
            // console.log('AJAX override: acquireTokenSilent', tokenResponse);
            SmartJS.defaults.requestHeaders.Authorization = `Bearer ${tokenResponse.apiToken}`;
            $.ajaxSetup({
                headers: { 'Authorization': `Bearer ${tokenResponse.apiToken}` }
            });

            if (!arguments_[0].headers) {
                arguments_[0].headers = {};
            }
            arguments_[0].headers.Authorization = `Bearer ${tokenResponse.apiToken}`;

            jqXHROfCall = origAjax.apply(this_, arguments_);
            (deferredPromise as any).abort = jqXHROfCall.abort;
            (deferredPromise as any).readyState = jqXHROfCall.readyState;

            return jqXHROfCall;
        }).then(function (ajaxResponse) {
            // console.log('AJAX override: ajaxResponse', ajaxResponse);
            (deferredPromise as any).readyState = jqXHROfCall.readyState;
            return deferred.resolve(ajaxResponse);
        }).catch(function (jqXHR) {
          (deferredPromise as any).readyState = (!jqXHROfCall) ? 0 : jqXHROfCall.readyState;
            return deferred.reject(jqXHR);
        });

        return deferredPromise;
    }
};

// Filters:
Vue.filter('longDateTime', function(date) {
  if (date === undefined || date === '' || date === null) return '';
  else return moment(date).format('MMMM Do YYYY, h:mm:ss a');
});

Vue.filter('longDateTime24', function(date) {
  if (date === undefined || date === '' || date === null) return '';
  else return moment(date).format('MMMM Do YYYY, H:mm:ss');
});

Vue.filter('longDate', function(date) {
  if (date === undefined || date === '' || date === null) return '';
  else return moment(date).format('MMMM Do YYYY');
});

Vue.filter('shortDateTime', function(date) {
  if (date === undefined || date === '' || date === null) return '';
  else if (moment(date, ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).isValid()) {
    if (
      moment(date, ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format('H:mm:ss') ==
      '0:00:00'
    ) {
      // time = midnight, so it's really just a date
      return moment(date, ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format(
        'MM/DD/YY'
      );
    } else {
      return moment(date, ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format(
        'MM/DD/YY H:mm'
      );
    }
  } else {
    // not valid moment, so return unformatted
    return date;
  }
});

Vue.filter('shortUser', function(user) {
  if (user === undefined || user === '' || user === null) return '';
  else return user.replace('@visiumpartners.com', '').replace('@vinitaspartners.com', '');
});

Vue.filter('shortDate', function(date) {
  if (date === undefined || date === '' || date === null) return '';
  else return moment(date).format('M/D/YY');
});
Vue.filter('monthDay', function(date) {
  if (date === undefined || date === '' || date === null) return '';
  else return moment(date).format('M/D');
});

Vue.filter('currency', function(value, decimals) {
  if (value === undefined || value === '' || value === null) return '';
  else {
    if (decimals == undefined) decimals = 2;
    return accounting.formatMoney(value, '$', decimals);
  }
});

Vue.filter('currency0', function(value) {
  if (value === undefined || value === '' || value === null) return '';
  else return accounting.formatMoney(value, '$', 0);
});
Vue.filter('percent', function(value, decimals) {
  if (value === undefined || value === '' || value === null) return '';
  else return accounting.formatNumber(value * 100, decimals || 0);
});

Vue.filter('percent0', function(value) {
  if (value === undefined || value === '' || value === null) return '';
  else return accounting.formatNumber(value * 100, 0);
});
Vue.filter('percent1', function(value) {
  if (value === undefined || value === '' || value === null) return '';
  else return accounting.formatNumber(value * 100, 1);
});
Vue.filter('percent2', function(value) {
  if (value === undefined || value === '' || value === null) return '';
  else return accounting.formatNumber(value * 100, 2);
});
Vue.filter('numberGeneric', function(value, show0ForNull: boolean = true) {
  if (value == null && !show0ForNull) { // tslint:disable-line
    return null; // tslint:disable-line
  }
  if (isNumber(toNumber(value)) && !isNaN(toNumber(value))) {
    // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
    return numbro(toNumber(value)).format({
      thousandSeparated: true
    });
  } else {
    return undefined;
  }
});
Vue.filter('number', function(value, decimals: number = 0, show0ForNull: boolean = true) {
  if (value == null && !show0ForNull) { // tslint:disable-line
    return null; // tslint:disable-line
  }
  if (isNumber(toNumber(value)) && !isNaN(toNumber(value))) {
    // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
    return accounting.formatNumber(value, decimals);
  } else {
    return undefined;
  }
});
Vue.filter('number0', function(value, show0ForNull: boolean = true) {
  if (value == null && !show0ForNull) { // tslint:disable-line
    return null; // tslint:disable-line
  }
  if (isNumber(toNumber(value)) && !isNaN(toNumber(value))) {
    // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
    return accounting.formatNumber(value, 0);
  } else {
    return undefined;
  }
});
Vue.filter('number1', function(value, show0ForNull: boolean = true) {
  if (value == null && !show0ForNull) { // tslint:disable-line
    return null; // tslint:disable-line
  }
  if (isNumber(toNumber(value)) && !isNaN(toNumber(value))) {
    // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
    return accounting.formatNumber(value, 1);
  } else {
    return undefined;
  }
});
Vue.filter('number2', function(value, show0ForNull: boolean = true) {
  if (value == null && !show0ForNull) { // tslint:disable-line
    return null; // tslint:disable-line
  }
  if (isNumber(toNumber(value)) && !isNaN(toNumber(value))) {
    // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
    return accounting.formatNumber(value, 2);
  } else {
    return undefined;
  }
});
Vue.filter('number3', function(value, show0ForNull: boolean = true) {
  if (value == null && !show0ForNull) { // tslint:disable-line
    return null; // tslint:disable-line
  }
  if (isNumber(toNumber(value)) && !isNaN(toNumber(value))) {
    // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
    return accounting.formatNumber(value, 3);
  } else {
    return undefined;
  }
});
Vue.filter('number4', function(value, show0ForNull: boolean = true) {
  if (value == null && !show0ForNull) { // tslint:disable-line
    return null; // tslint:disable-line
  }
  if (isNumber(toNumber(value)) && !isNaN(toNumber(value))) {
    // toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
    return accounting.formatNumber(value, 4);
  } else {
    return undefined;
  }
});

Vue.prototype.$getAvailableScreenHeight = (offset: number) => {
  offset = tryParseInt(offset, 0);

  // make sure offset isn't larger than screen height
  if (offset > Vue.prototype.$getAvailableScreenHeightInPixels()) {
    offset = 0;
  }

  offset += getScreenHeightOffset();

  return 'calc(100vh - ' + offset + 'px)';

  function getScreenHeightOffset() {
    let heightHeader = 0;
    let heightFooter = 0;
    let heightBreadcrumbs = 0;
    const paddingTop = 12;
    const paddingBottom = 25;

    const $appHeader = $('.app-header:first');
    if ($appHeader.length > 0) {
      heightHeader = $appHeader.outerHeight();
    }

    const $appFooter = $('.app-footer:first');
    if ($appFooter.length > 0) {
      heightFooter = $appFooter.outerHeight();
    }

    const $olBreadcrumbs = $('ol.breadcrumb');
    if ($olBreadcrumbs.length > 0) {
      heightBreadcrumbs = $olBreadcrumbs.outerHeight();
    }

    return (
      heightHeader +
      heightFooter +
      heightBreadcrumbs +
      paddingTop +
      paddingBottom
    );
  }
};

Vue.prototype.$getAvailableScreenHeightInPixels = (offset: number) => {
    offset = tryParseInt(offset, 0);

    return getScreenHeight() - getScreenHeightOffset() - offset;

    function getScreenHeight() {
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;
    }
    function getScreenHeightOffset() {
        let heightHeader = 0;
        let heightFooter = 0;
        let heightBreadcrumbs = 0;
        const paddingTop = 12;
        const paddingBottom = 25;

        const $appHeader = $('.app-header:first');
        if ($appHeader.length > 0) {
            heightHeader = $appHeader.outerHeight();
        }

        const $appFooter = $('.app-footer:first');
        if ($appFooter.length > 0) {
            heightFooter = $appFooter.outerHeight();
        }

        const $olBreadcrumbs = $('ol.breadcrumb');
        if ($olBreadcrumbs.length > 0) {
            heightBreadcrumbs = $olBreadcrumbs.outerHeight();
        }

        return heightHeader + heightFooter + heightBreadcrumbs + paddingTop + paddingBottom;
    }
};

Vue.prototype.$getBootstrapSize = () => {
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth ||
    0;
  if (screenWidth < 576) {
    return 'xs';
  } else if (screenWidth < 768) {
    return 'sm';
  } else if (screenWidth < 992) {
    return 'md';
  } else if (screenWidth < 1200) {
    return 'lg';
  } else {
    return 'xl';
  }
};

/****************** BEGIN from legacy Vue main.js *******************/
// /*
declare var require: any;

import BankTransactionEdit from './js/BankTransactions/BankTransactionEdit.vue';
(window as any).BankTransactionEdit = BankTransactionEdit;

import CapitalCallSchedule from './js/InvestmentParent/CapitalCallSchedule.vue';
(window as any).vcCapitalCallSchedule = CapitalCallSchedule;

import BankAccountEdit from './js/BankAccount/BankAccountEdit.vue';
(window as any).vcBankAccountEdit = BankAccountEdit;

import LiabilityNotesManagementView from './js/Liability/LiabilityNotesManagementView.vue';
(window as any).vcLiabilityNotesManagementView = LiabilityNotesManagementView;

import AttachmentList from './js/Attachments/AttachmentList.vue';
(window as any).vcAttachmentList = AttachmentList;

import TaxPaymentSchedule from './js/Tax/PaymentSchedule.vue';
(window as any).vcTaxPaymentSchedule = TaxPaymentSchedule;

import BankTransactionList from './js/BankTransactions/BankTransactionList.vue';
(window as any).vcBankTransactionList = BankTransactionList;

import DynamicComponent from './js/VueCommon/DynamicComponent.vue';
(window as any).vcDynamicComponent = DynamicComponent;

import Investment from './views/Investment/Investment.vue';
(window as any).vcInvestment = Investment;

import InvestmentList from './views/Investment/InvestmentList.vue';
(window as any).vcInvestmentList = InvestmentList;

import StockQuote from './components/form/StockQuote/StockQuote.vue';
(window as any).vcStockQuote = StockQuote;

import ChangeLogList from './js/ChangeLog/ChangeLogList.vue';
(window as any).vcChangeLogList = ChangeLogList;

import FundFamilyEdit from './views/FundFamily/FundFamilyEdit.vue';
(window as any).vcFundFamilyEdit = FundFamilyEdit;

import GeneralPartnerPointList from './views/InvestmentParent/GeneralPartnerPointList.vue';
(window as any).vcGeneralPartnerPointList = GeneralPartnerPointList;

import ManagementFeeList from './views/InvestmentParent/ManagementFeeList.vue';
(window as any).vcManagementFeeList = ManagementFeeList;

import FeePayingCommitmentList from './views/InvestmentParent/FeePayingCommitmentList.vue';
(window as any).vcFeePayingCommitmentList = FeePayingCommitmentList;

import * as SystemService from './services/DAL/systemService';
const NamedKey = {};
Vue.prototype.$namedKeyService = {};
// async function SetNamedKeys() {
Vue.prototype.$namedKeyService.refreshAll = async () => {
  console.log('$namedKeys.refreshAll');
  let NamedKeys = [];
  const getNamedKeysParameters = {} as SystemService.GetNamedKeysParameters;
  getNamedKeysParameters.IncludeDotSubstitutionRows = true;
  NamedKeys = await new SystemService.SystemService().GetNamedKeys(
    getNamedKeysParameters
  );
  // create 2D object to allow accessing NamedKey values from client
  //  i.e: NamedKey.OrderStatus.CommittedSale === 16
  //  i.e: NamedKey.OrderStatus.['Committed Sale'] === 16
  for (let i = 0; i < NamedKeys.length; i++) {
    const keyData = NamedKeys[i] || {};

    if (!$.isEmptyObject(keyData)) {
      // create element in outer object for KeyType
      if (NamedKey[keyData.KeyType] === undefined) {
        NamedKey[keyData.KeyType] = {};
      }

      // insert this NamedKey item into its KeyType (inner object)
      const KeyTextNoSpace = replaceAll(keyData.KeyText, ' ', '');
      NamedKey[keyData.KeyType][KeyTextNoSpace] = tryParseInt(
        keyData.KeyValue,
        keyData.KeyValue
      );
    }
  }
  (window as any).NamedKey = NamedKey;
  Vue.prototype.$namedKey = NamedKey;
};
Vue.prototype.$namedKeyService.get = async (
  keyType: string,
  keyValue: any,
  parentKeyType?: string,
  parentKeyValue?: any
) => {
  const getNamedKeysParameters = {} as SystemService.GetNamedKeysParameters;
  getNamedKeysParameters.KeyType = keyType;
  getNamedKeysParameters.KeyValue = keyValue;
  getNamedKeysParameters.ParentKeyType = parentKeyType || undefined;
  getNamedKeysParameters.ParentKeyValue = parentKeyValue || undefined;
  const namedKeys = await new SystemService.SystemService().GetNamedKeys(
    getNamedKeysParameters
  );

  if (!namedKeys.length) {
    return {};
  } else if (namedKeys.length === 1) {
    return namedKeys[0];
  } else {
    const paramObject = {
      keyType: keyType,
      keyValue: keyValue,
      parentKeyType: parentKeyType,
      parentKeyValue: parentKeyValue
    };
    console.warn(
      'Multiple NamedKey records returned for the following parameters: ' +
      JSON.stringify(paramObject) +
      '.'
    );
    return {};
  }
};

Vue.prototype.$namedKeyService.getText = async (
  keyType: string,
  keyValue: any,
  parentKeyType?: string,
  parentKeyValue?: any
) => {
  const namedKey = await Vue.prototype.$namedKeyService.get(
    keyType,
    keyValue,
    parentKeyType,
    parentKeyValue
  );
  return namedKey.KeyText || '';
};

function getIsMobile() {
    const $body = $('body');
    let isMobile = tryParseBool($body.data('isMobile'), undefined);
    if (isMobile === undefined) {
        isMobile = tryParseBool(getBrowser().isMobile, false);
        $body.data('isMobile', isMobile);
    }
    return isMobile;
}

function getScreenWidth() {
    return window.innerWidth || (document.documentElement ? document.documentElement.clientWidth : 0) || (document.body ? document.body.clientWidth : 0) || 0;
}

function getBootstrapSize() {
    const screenWidth = getScreenWidth();
    if (screenWidth < 768) {
        return 'xs';
    }
    else if (screenWidth < 992) {
        return 'sm';
    }
    else if (screenWidth < 1200) {
        return 'md';
    }
    else {
        return 'lg';
    }
}

async function finishSetup() {
  runSmartJSSetup();
  // set initial headers if available
  if (_auth.isLoggedIn() && store.getters.isAuthenticated && !!store.getters.apiToken && store.getters.apiToken.length > 0) {
    if (SmartJS.defaults.requestHeaders.Authorization) {
      SmartJS.defaults.requestHeaders.Authorization = `Bearer ${store.getters.apiToken}`;
      $.ajaxSetup({
        headers: { 'Authorization': `Bearer ${store.getters.apiToken}` }
      });
    }
  }
  setSmartJSDefaults();

  const apiToken = store.getters.apiToken;
  if (_auth.isLoggedIn() && store.getters.isAuthenticated && !!apiToken && apiToken.length > 0) {
    console.log('storeSecurityProfile()');
    await Promise.all([
      Vue.prototype.$namedKeyService.refreshAll(),
      storeSecurityProfile({
        methodName: 'GetSecurityLevelsOfCurrentUser',
        viewField: 'SecurityView',
        levelField: 'SecurityLevel'
      })
    ]);
    console.log('got NamedKeys & SecurityProfile from main');
  } else {
    console.log(
      'Did not get NamedKeys or SecurityProfile in main',
      'isLoggedIn: ' + _auth.isLoggedIn(),
      'isAuthenticated: ' + store.getters.isAuthenticated,
    );
  }

  // gkb 06/10/20 - finishSetup is called on document ready now, so no need for separate listener
  /*
  $(document).ready(function() {
    $('body').bindSelectOnFocus();
  });
  */
  ($('body') as any).bindSelectOnFocus();

  // gkb 06/10/20 - loading Vue outside finishSetup now (but still on document ready), without setting window.vueApp (unneeded)
  /*
  // load on document ready to ensure scripts in index.html are loaded first
  $(document).ready(function() {
    // finally, load vue:
    const app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
    window.vueApp = app;
  });
  */
}
function runSmartJSSetup() {
  /* gkb 08/03/18:
     *  This code (slightly modified here) is run automatically in SmartJS.js (on Smart's CDN), and that file is loaded
     *  by the application. Here, however, we're loading files individually, rather than loading SmartJS.js at all, so
     *  we need to have this code on our side.
     */
  const SmartJS: any = function() {};
  SmartJS.prototype.defaults = {
    apiBaseUrl: '/SmartApi', // to use legacy ASMX, the file MUST be called SmartWS.asmx, and apiBaseUrl should point to that file
    requestHeaders: {},
    requestXhrFields: {},
    loadAsync: true
  };

  // Initialize the globally accessible defaults
  SmartJS.defaults = $.extend({}, SmartJS.prototype.defaults);
  SmartJS.isApiAsmx = function() {
    return false;
  };

  // Give global access to the SmartJS variable
  if (!window.SmartJS) { // gkb 10/24/18 - don't redefine global variable on redirect after authentication
    window.SmartJS = SmartJS;
  }
}
function setSmartJSDefaults() {
  // Application default settings
  const isMobileXS = (getIsMobile() && getBootstrapSize() === 'xs');
  SmartJS.defaults = {
    apiBaseUrl: process.env.VUE_APP_SMART_API_URL || 'https://localhost:44390/SmartApi',
    requestHeaders: {
      Authorization: SmartJS.defaults.requestHeaders.Authorization || undefined
    },
    requestXhrFields: {
      withCredentials: true // allows cookie usage
    }
  };
  SmartPortlet.defaults = {
    buttonClass: 'btn btn-light',
    appendButtonClass: true,
    type: 'light bordered shadowed'
    // colorClass: 'blue-visium',
    // title: {
    //     text: '',
    //     className: 'bold uppercase'
    // }
  };
  SmartPopup.defaults = {
    title: 'Vinitas',
    buttonClass: 'btn btn-light',
    appendButtonClass: true,
    minWidth: (isMobileXS ? '100%' : undefined),
    minHeight: (isMobileXS ? '100%' : undefined),
    maxWidth: (isMobileXS ? '100%' : '90%'),
    maxHeight: (isMobileXS ? '100%' : '95%')
  };
  SmartConfirm.defaults = {
    title: 'Vinitas',
    buttonClass: 'btn btn-light',
    appendButtonClass: true,
    position: { my: 'center top+250', at: 'center top', of: window },
    maxWidth: '90%',
    maxHeight: '95%'
  };
  SmartPrompt.defaults = {
    title: 'Vinitas',
    buttonClass: 'btn btn-light',
    appendButtonClass: true,
    position: { my: 'center top+250', at: 'center top', of: window },
    maxWidth: '90%',
    maxHeight: '95%'
  };
  SmartAlert.defaults = {
    title: 'Vinitas',
    buttonClass: 'btn btn-light',
    appendButtonClass: true,
    position: { my: 'center top+250', at: 'center top', of: window },
    maxWidth: '90%',
    maxHeight: '95%'
  };
  SmartControl.Fetch.defaults = {
    fileExtension: '.html',
    // smartControlBasePath: '/public/static/legacy/smartControls',
    smartControlBasePath: '/SmartControls',
    // loadFileFromClient: true
    loadFileFromClient: false
    //    onLoad: function ($control) {
    //        $control.smartSecure();
    //    }
  };
  SmartRepeater.defaults = {
    forceShowHeader: true
  };
  SmartFileUpload.defaults = {
    title: 'Upload Files',
    buttonClass: 'btn btn-light'
  };
  SmartExport.PDF.defaults = {
    width: 8.5,
    height: 11,
    topMargin: 0.25,
    bottomMargin: 0.25,
    leftMargin: 0.25,
    rightMargin: 0.25
  };
  sessionStorage.setItem('showSmartJSErrors', process.env.NODE_ENV === 'production' ? 'false' : 'true');
}

// gkb 06/10/20 - moved inside document.ready now that SmartJS is loaded in index.html
// finishSetup();

// load on document ready to ensure scripts in index.html are loaded first
$(async function() { // $(document).ready is deprecated. this is the replacement
  await finishSetup();

  new Vue({
      router,
      store,
      render: h => h(App)
  }).$mount('#app');
});

/* get the token, then re-get the token every 4 minutes to avoid using an expired token: acquireTokenSilent() will
 *  get a fresh token if the current cached token expires in <= 5 min, so doing this every 4 min will ensure that
 *  the cached token is never expired.
 */
// store.dispatch('refreshToken')
//   .catch((err) => {
//     console.error(err);
//   });
/* gkb 11/15/18 - main.ts is loaded once at page load, and again after login; when main.ts is loaded the second time,
 *  we need to clear the previous interval before we start a new one. but we can't from here - somehow the scope (or something?)
 *  changes and we can't clear the original interval. so instead we have to clear it after login before the redirect (in Login.vue).
 */
// let existingIntervalId: string = sessionStorage.getItem('tokenIntervalId');
// if (existingIntervalId) {
//     clearInterval(parseInt(existingIntervalId));
//     console.log(`Previous token refresh interval cleared at ${moment().format('H:mm:ss')}. Interval Id: ${existingIntervalId}.`);
// }
// gkb 02/05/19 - removing interval altogether, now that we've overridden $.ajax to call .acquireTokenSilent() every time.
/*
let newIntervalId: number = setInterval(async () => {
  let initiationTime = moment();
  console.trace(`Interval initiated at ${initiationTime.format('H:mm:ss')}`);
  try {
    console.log('logged in:', _auth.isLoggedIn());
    console.log(`token refreshing (Interval initiated at ${initiationTime.format('H:mm:ss')})`);
    await store.dispatch('refreshToken');
    console.log(`token refreshed at ${moment().format('H:mm:ss')} (Interval initiated at ${initiationTime.format('H:mm:ss')})`);
  }
  catch (err) {
    console.error(err);
  }
}, 4 * 60 * 1000);
sessionStorage.setItem('tokenIntervalId', newIntervalId.toString());
console.log(`Token refresh interval started at ${moment().format('H:mm:ss')}. Interval Id: ${newIntervalId}.`);
*/
