 <template>
    <EventLogList
        database="VisiumDB"
        schema="liq"
        caller="ProcessHistory"
        event="Exec"
        :minMilliseconds="10"
        :includeIncompleteRegardlessOfMilliseconds="true"
        title="Liquidity Process"
        :onRunClick="processHistory"
        v-if="showEventList"></EventLogList>
</template>

<script>
import Vue from 'vue';
import mixinSchema_liq from './../DAL/mixinSchema_liq';
import EventLogList from '@/views/Event/EventLogList.vue';

export default Vue.extend({
    name: 'ProcessLiquidityHistory',
    mixins: [mixinSchema_liq],
    props: [],
    components: {
        EventLogList
    },
    data: function() {
        return {
            showEventList: true
        };
    },
    methods: {
        processHistory: async function() {
            this.doProcessHistory(); // do not await here, to allow following logic to occur while processing
            await this.$nextTick();
            await this.$asyncWait(2000); // wait 2 sec to be certain there's been time for the log row to be created.
            this.showEventList = false;
            await this.$nextTick();
            this.showEventList = true;
        },
        
        doProcessHistory: async function() {
            this.$notify.info('Process started');

            try {
                const data = await this.liq_ProcessHistory({
                    //LiquidityDate: liquidityDate
                    //, LiquidityEnd: liquidityEnd
                    //, LogId: logId
                });

                this.$notify.success({
                    title: null,
                    message: `Liquidity process complete. Log Id: ${
                        data[0].Id
                    }`,
                    duration: 0
                });
                
                this.showEventList = false;
                await this.$nextTick();
                this.showEventList = true;
            } catch (err) {
                console.error(err);
            }
        }
    }
});
</script>