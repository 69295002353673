
    import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
    import Common from '@/utils/common';
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import uniqBy from 'lodash/uniqBy';
    import ElementUI from "element-ui";
    import alasql from 'alasql'; 
    import * as XLSX from 'xlsx';
    alasql['utils'].isBrowserify = false;
    alasql['utils'].global.XLSX = XLSX;

    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;

    @Component
    export default class EquityList extends Vue {
        @Ref() readonly refTableEquity!: ElementUI.Table;

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    public common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop( {type: Number }) readonly investmentId: number;
    @Prop( {type: Number }) readonly transactionHeaderId: number;
    @Prop({ type: Number }) readonly upToButExcludingTransactionHeaderId: number;
    @Prop({ type: Boolean, default: false }) readonly allowEdit: boolean;
    @Prop({ type: Boolean, default: false }) readonly showRunningTotal: boolean;
    @Prop({ type: Boolean, default: false }) readonly hideSummary: boolean;

    //#endregion Props

    //#region Data
    loading = false;
    securityLevel_: number = null;
    showSummary = false;
    //effectively, the store:
    equity = [] as TransactionsService.Equity[];
    

    //#endregion Data

    //#region Lifecycle
    async created() {
        this.common = new Common();
        this._transactionsService = new TransactionsService.TransactionsService();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
       await this.getEquity();
       this.showSummary = true;
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('investmentId')
    async investmentIdChanged(val: number, oldVal: number) {
        if (oldVal){
            this.getEquity();
        }
    }
    //#endregion Watches

    //#region Computed
    get trancheSummary(){
        if (this.refTableEquity){
        const tranches = ((this.refTableEquity as any).tableData as TransactionsService.Equity[]);
        const data = tranches.map(tranche => ({
            Tranche: tranche.Tranche, 
            Quantity: tranche.Quantity
            }));
        const sums = [
            ...data.reduce(
            (map, item) => {
                const { Tranche: key, Quantity } = item;
                const prev = map.get(key);
                
                if(prev) {
                prev.Quantity += Quantity;
                } else {
                map.set(key, Object.assign({}, item))
                }
                
                return map
            },
            new Map()
            ).values()
        ]
        return sums;
        }
    }

    //#endregion Computed


    //#region Methods
    getSummary(param) {
        const { columns, data } = param;
        const columnList = [];
        const formats = new Map();
        let costColumnIndex = -1;
        let sharesColumnIndex = -1;
        let costPerShareColumnIndex = -1;
        columns.forEach((column, index) => {
            if (column.align == 'is-right' && column.formatter && column.label != 'Cumulative') {
                columnList.push(index);
                switch(column.formatter.name){
                    case 'formatterCurrency':
                        formats.set(index, "currency");
                        break;
                    case 'formatterNumber3':
                        formats.set(index, "number3");
                        break;
                }                
            }
            if (column.property == 'DisplayCost') {
                costColumnIndex = index;
            }
            else if (column.property == 'Quantity') {
                sharesColumnIndex = index;
            }
            else if (column.property == 'DisplayCostPerQuantity') {
                costPerShareColumnIndex = index;
            }
        });
        const totalLabel = 'Total';
        const totalLabelIndex = 0;
        const summary = this.common.getSummaryArray(param, columnList, totalLabel, totalLabelIndex, formats);
        
        const shares = this.$accounting.unformat(summary[sharesColumnIndex]);
        const cost = this.$accounting.unformat(summary[costColumnIndex]);
        const costPerShare = cost / shares;
        summary[costPerShareColumnIndex] = this.$accounting.formatMoney(costPerShare);
        return summary;
    }

     async editItem(
        selectedRow: TransactionsService.Equity,
        event,
        column
    ) {
        if (selectedRow && selectedRow != null && this.allowEdit) {
            const transactionHeaderId = selectedRow.TransactionHeaderId.toString();
            this.$router.push({ name: 'Investment Transaction', params: { transactionHeaderId } }).catch(() => {});
        }
    }
    async getEquity(){
        const params = {} as TransactionsService.GetEquityParameters;
        params.InvestmentId = this.investmentId;
        params.UpToButExcludingTransactionHeaderId = this.upToButExcludingTransactionHeaderId;
        params.TransactionHeaderId = this.transactionHeaderId;
        this.equity = await this._transactionsService.GetEquity(params)
        this.$emit("equityList", this.equity);
    }
    filterHandler(value, row, column, prop) {
        const property = prop ? prop : column['property'];
        return row[property] === value;
    }
    filters(column){
        const filters = this.equity.map(function(list) {
            return {
                text: list[column]
                , value: list[column]
                };
        });
        return uniqBy(filters, 'text');
    }
    openTransactionTab(transactionHeaderId: number){
        const th: string = transactionHeaderId.toString();
        const route = this.$router.resolve({ name: 'Investment Transaction', query: { transactionHeaderId: th } });
        window.open(route.href, '_blank');
    }
    rowStyle({row, rowIndex}): Object {
        if (row.Pending){
            return {'background-color': 'yellow'};
        }
        return '';
    }

    async exportItems(){
        if (this.equity.length > 0) {
            var opts = [{ sheetid: this.investmentId.toString(), header: true }];
            try {
                
                await alasql.promise(`SELECT * INTO XLSX("Tranches ${this.investmentId.toString()}.xlsx",?) FROM ?`, [opts, [this.equity]])
                this.$notify.success('Excel file exported.');
            }
            catch(err) {
                this.$notify.error(err.message);
            }
        }
    }


    //#endregion Methods

}
