import Vuex from 'vuex';
import Vue from 'vue';
import auth from './auth.store';
import status from './status.store';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    status
  },
  plugins: [createPersistedState()]
});
