
import Vue from 'vue';
import Component from 'vue-class-component';
import OwnerCategoryEdit from '@/views/OwnerCategory/OwnerCategoryEdit.vue';
import * as VamService from './../../services/DAL/vamService';
import ElementUI from 'element-ui';

@Component({
  components: {
    OwnerCategoryEdit
  }
})
export default class OwnerCategoryList extends Vue {
  $refs: {
    tblOwnerCategoryList: ElementUI.Table
  }
  //#region Data
  private _vamService: VamService.VamService;

  ownerCategories = [];
  selectedOwnerCategory = null;
  showOwnerCategoryEdit = false;
  showOwnerCategoryCreate = false;
  isLoading = false;
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._vamService = new VamService.VamService();
  }

  async mounted() {
    this.fetchOwnerCategories();
  }
  //#endregion Lifecycle

  //#region Methods
  selectOwnerCategory(ownerCategory) {
    if (ownerCategory) {
      this.showOwnerCategoryEdit = false;
      this.$nextTick(() => {
        this.showOwnerCategoryEdit = true;
        this.selectedOwnerCategory = ownerCategory;
      });
    }
  }

  closeOwnerCategory() {
    this.showOwnerCategoryEdit = false;
    this.selectedOwnerCategory = null;
    this.$refs.tblOwnerCategoryList.setCurrentRow(null);
  }

  onDeleteOwnerCategory() {
    this.closeOwnerCategory();
    this.fetchOwnerCategories();
  }

  async fetchOwnerCategories() {
    this.isLoading = true;
    this.ownerCategories = await this._vamService.GetOwnerCategories();
    this.isLoading = false;
  }
  //#endregion Methods
}
