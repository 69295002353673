
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import Common from '../../utils/common';

@Component({
    inheritAttrs: false
})
export default class CapitalCallScheduleSelector extends Vue {
//#region Private declarations for Services
    private _transactions_Service: AssetService.AssetService;
    private _common: Common;

//#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: number;
    @Prop( {required: true, type: Number}) investmentParentId: number;
    //#endregion Props

    //#region Data
    items = [] as AssetService.CapitalCallSchedule[];
    selectedValue: number = null;
    selectedOption: AssetService.CapitalCallSchedule = new AssetService.CapitalCallSchedule();
    showSelect: boolean = true;
    //#endregion Data

   //#region Lifecycle
    async created() {
        this._transactions_Service = new AssetService.AssetService();
        this._common = new Common();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchItems();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
        this.getSelectedItem();
    }
    
    @Watch('selectedOption')
    onChange_selectedItem(val: number, oldVal: number) {
        this.$emit('value-set', this.selectedOption);
    }
    @Watch('investmentParentId')
    onInvestmentParentIdChange(val: number, oldVal: number) {
        this.fetchItems();
    }

    //#endregion Watches

    //#region Methods
    async fetchItems() {
        this.items = [];
        if (this.investmentParentId > 0){
            const params = {} as AssetService.GetCapitalCallScheduleParameters;
            params.InvestmentParentId = this.investmentParentId;
            this.items = await this._transactions_Service.GetCapitalCallSchedule(params);
            if (this.items.length == 0) {
                this.showSelect = false;
            }
            else
            {           
                this.$emit('options-fetched', this.items);
                this.getSelectedItem();
            }
        }
    }

    getSelectedItem() {
        if (this.items.length && this.selectedValue > 0) {
            this.selectedOption = this._common.getSelectedArrayItem(this.items, this.selectedValue.toString(), 'Id');
        }
    }
    //#endregion Methods
}
