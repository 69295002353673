

    import { Component, Vue, Prop } from "vue-property-decorator";
    import * as SystemService from '@/services/DAL/systemService';
    import formatters from '@/utils/formatters';
    import numbro from 'numbro';

    @Component
    export default class ChangeLogList extends Vue {

    //#region Private declarations for Services
    private _systemService: SystemService.SystemService;
    private formatters: formatters;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({ type: String, required: true }) readonly schema: string;
    @Prop({ type: String, required: true }) readonly table: string; 
    @Prop({ type: Number, required: true }) readonly entityId: number;
    @Prop({ type: String }) readonly field: string; 
    @Prop({ type: String }) readonly viewType: string;
    //#endregion Props

    //#region Data
    changeLogList = [] as SystemService.ChangeLog[];
    loading = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._systemService = new SystemService.SystemService();
        this.formatters = new formatters();
        this.fetchChangeLog();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchChangeLog() {
        this.loading = true;
        const params = {} as SystemService.GetChangeLogParameters;
        params.Schema = this.schema;
        params.Table = this.table;
        params.Field = this.field;
        params.EntityId = this.entityId;
        this.changeLogList = await this._systemService.GetChangeLog(params);
        this.loading = false;
    }
    formatChangeValue(row, column) {
        if (row[column.property]) {//if it has any value at all...
            if (row[column.property].length >= 6 && row[column.property].length < 25 && this.$moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).isValid()) {
                // > 6 because moment will treat a number like a date; <25 because it will also just pick a date out of a whole sentence.
                if (this.$moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format('H:mm:ss') == '0:00:00') {//time = midnight, so it's really just a date
                    return this.$moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format('MM/DD/YY');
                }
                else {
                    return this.$moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format('MM/DD/YY H:mm');
                }
            }
            else if ((row.Field == 'Amount' || row.Field == 'Cost' || row.Field == 'Price') && this._isNumber(this._toNumber(row[column.property])) ) {
                return numbro(this._toNumber(row[column.property])).formatCurrency();
            }
            else if ((this._isNumber(this._toNumber(row[column.property]))) && !isNaN(this._toNumber(row[column.property]))) { //toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
                return numbro(this._toNumber(row[column.property])).format({ thousandSeparated: true});
            }
            else {
                return row[column.property];
            }
        }
        else {
            return '';
        }
    }
}
