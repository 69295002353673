
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Ref} from 'vue-property-decorator';
import InvestmentTransactionsList from '@/views/InvestmentTransaction/InvestmentTransactionsList.vue';
import InvestmentTransactionsListV2 from '@/views/InvestmentTransaction/InvestmentTransactionsListV2.vue';
import InstrumentList from '@/views/DebtInstrument/InstrumentList.vue';
import Conversion from '@/views/InvestmentTransaction/Conversion.vue';
import InvestmentEdit from '@/views/Investment/InvestmentEdit.vue';
import InvestmentGeneralLedgerStatistics from '@/views/Investment/InvestmentGeneralLedgerStatistics.vue';
import CommentLogList from '@/components/other/tsCommentLogList.vue'
import EquityList from '@/views/InvestmentTransaction/Equity/EquityList.vue';
import EntityJournalVouchers from '@/views/GeneralLedger/EntityJournalVouchers.vue'
import CommitmentAnalysis from '@/views/InvestmentTransaction/Capital/CommitmentAnalysis.vue';
import AttachmentList from '@/js/Attachments/AttachmentList.vue';
import ChangeLogList from '@/js/ChangeLog/ChangeLogList.vue';
import * as AssetService from '../../services/DAL/assetService';
import ElementUI from 'element-ui';
import InvestmentNotesAndContacts from '@/views/Investment/InvestmentNotesAndContacts.vue';
import VueRouter from 'vue-router';

declare var $;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function tryParseBool(input: any, defaultValue: boolean): boolean;
declare function getStoredSecurityLevel(Id: number): number;

@Component({
    components: {
        InvestmentTransactionsList
        , InvestmentTransactionsListV2
        , InstrumentList
        , InvestmentEdit
        , InvestmentGeneralLedgerStatistics
        , AttachmentList
        , ChangeLogList
        , CommitmentAnalysis
        , EntityJournalVouchers
        , EquityList
        , Conversion
        , CommentLogList
        , InvestmentNotesAndContacts
    }
    ,beforeRouteEnter(to, from, next){
        next(vm => {
            vm.$data.fromRoute = from;
        });
    }

})
export default class Investment extends Vue {
    @Ref() readonly refInvestmentTabs!: ElementUI.Tabs;
    //#region Data
    private _assetService: AssetService.AssetService;

    @Prop()
    investmentId: number;
    @Prop()
    loadTransactionsTab: boolean;
    @Prop()
    loadTransactionId: number;
    @Prop({ type: Number, default: null })
    securityLevel!: number;
    @Prop({ type: Object })
    investment: AssetService.InvestmentList;
    @Prop({type: String, default: 'card'})
    viewType: string;
    

    investmentId_: number = null;
    loadTransactionId_: number = null;
    loadTransactionsTab_: boolean = false;
    securityLevel_: number = this.securityLevel;
    showAttachmentList: boolean = false;
    showChangeLogList: boolean = false;
    showAccounting: boolean = false;
    showConversion: boolean = false;
    showTransactionsList: boolean = false;
    showTranches: boolean = true;
    selectedTab: string = '0';
    investment_ = {} as AssetService.InvestmentList;
    loading: boolean = false;
    showInvestmentEdit = false;
    fromRoute!: any;
    showStatistics = true;
    //#endregion Data

    //#region Computed
    
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        if (this.loadTransactionId) {
            this.loadTransactionId_ = this.loadTransactionId;
            this.showTransactionsList = true;
        }
        if (this.securityLevel_ === null) {
            this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets), 0);
        }
        if (this.investmentId) this.investmentId_= this.investmentId;

        if (this.investment && Object.keys(this.investment).length) {
            this.investment_ = this.investment;
            this.investmentId_ = this.investment.Id;
        }
        else {
            this.fetchInvestment();
        }
        this.loadTransactionsTab_ = (this.loadTransactionId_) ? true : tryParseBool(this.loadTransactionsTab, false);
    }
    destroyed(){
    }

    async mounted() {
        this.loadInvestment();
        if (this.loadTransactionId_){
            this.selectedTab = '0';
        }
        else if (this.$route.meta && this.$route.meta.selectedTab){
            this.selectedTab = this.$route.meta.selectedTab;
        }
    }
    //#endregion Lifecycle

    //#region Watches

    //#endregion Watches
    //#region Methods
    async loadInvestment(){
        const investmentTab = this.refInvestmentTabs;
        if (investmentTab){
            const $ucInvestmentInfoAndStats = $(investmentTab.$el).find('#ucInvestmentInfoAndStats');
            if (!$ucInvestmentInfoAndStats.hasClass('loaded')) {
                await $ucInvestmentInfoAndStats.fetchSmartControl({
                    params: {
                        InvestmentId: this.investmentId_
                    }
                    , collapsible: false
                    , showSpinner: true
                });
                $ucInvestmentInfoAndStats.addClass('loaded');
            }
        }
    }

    async fetchInvestment(){
        this.investment_ = {} as AssetService.InvestmentList;
        if (this.investmentId_ == null){
            console.error('InvestmentId_ is null');
            return;
        }
        this.loading = true;
        const params = {} as AssetService.GetInvestmentListParameters;
        params.InvestmentId = this.investmentId_;
        const investments = await this._assetService.GetInvestmentList(params);
        if (!!investments && investments.length == 1){
            this.investment_ = investments[0];
        }
        this.loading = false;
    }
    async onTabClick(tab) {
        this.$route.meta.selectedTab = tab.name;
        switch (tab.label) {
            case 'Valuation':
                var $ucValuationList = $(this.refInvestmentTabs.$el).find('#ucValuationList');
                if (!$ucValuationList.hasClass('loaded')) {
                    $ucValuationList.fetchSmartControl({
                        params: {
                            EntityType: 'Investment'
                            , EntityId: this.investmentId_
                            , CanEdit: this.securityLevel_ >= this.$namedKey.SecurityLevel.Admin
                        }
                        , collapsible: false
                        , showSpinner: true
                    });
                    $ucValuationList.addClass('loaded');
                }
                break;
            case 'Net Transactions':
                var $ucNetTransactionList = $(this.refInvestmentTabs.$el).find('#ucNetTransactionList');
                if (!$ucNetTransactionList.hasClass('loaded')) {
                    $ucNetTransactionList.fetchSmartControl({
                        params: {
                            InvestmentId: this.investmentId_
                        }
                        , collapsible: false
                        , showSpinner: true
                    });
                    $ucNetTransactionList.addClass('loaded');
                }
                break;
            case 'Transactions':
                this.showTransactionsList = false;
                await this.$nextTick();
                this.showTransactionsList = true;
                break;
            case 'Accounting':
                this.showAccounting = false;
                await this.$nextTick();
                this.showAccounting = true;
                break;
            case 'Tranches':
                this.showTranches = false;
                await this.$nextTick();
                this.showTranches = true;
                break;
            case 'Documents':
                this.showAttachmentList = false;
                await this.$nextTick();
                this.showAttachmentList = true;
                break;
            case 'Change Log':
                this.showChangeLogList = false;
                await this.$nextTick();
                this.showChangeLogList = true;
                break;
            case 'Conversion':
                this.showConversion = false;
                await this.$nextTick();
                this.showConversion = true;
                break;
        }
    }
    conversionUpdated(investment: AssetService.InvestmentList){
        this.investment_ = investment;
    }
    async investmentSaved(investment: AssetService.InvestmentList){
        this.showInvestmentEdit = false;
        await this.fetchInvestment();
    }
    async investmentDeleted(){
        this.showInvestmentEdit = false;
        this.$emit('deleted');
        if (this.fromRoute && this.fromRoute.fullPath == '/assets/InvestmentList') this.$router.go(-1); //back to investmentList
        // ToDo: other possible options we should consider returning to rather than Welcome screen?
        else this.$router.push('/welcome'); //return to home screen
    }
    async refreshStatistics(){
        this.showStatistics = false;
        await this.$nextTick();
        this.showStatistics = true;
    }

    //#endregion Methods
}
