
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Ref } from 'vue-property-decorator';
import ClientSelector from '@/components/form/ClientSelector.vue';
import OwnerSelector from '@/components/form/OwnerSelector.vue';
import BankAccountSelector from '@/components/form/BankAccountSelector.vue';
import FixedIncomeEdit from '@/views/FixedIncome/FixedIncomeEdit.vue';
import FixedIncomeEditView from '@/views/FixedIncome/FixedIncomeEditView.vue';
import * as AssetService from './../../services/DAL/assetService';
import Common from '../../utils/common';
import formatters from '../../utils/formatters';
import moment from 'moment';
import findIndex from 'lodash/findIndex';
import ElementUI from 'element-ui';
import uniqBy from 'lodash/uniqBy';
import dayjs from 'dayjs';

declare var $;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function tryParseBool(input: any, defaultValue: boolean): boolean;

@Component({
    components: {
        ClientSelector,
        OwnerSelector,
        BankAccountSelector,
        FixedIncomeEdit,
        FixedIncomeEditView
    }
})
export default class FixedIncomeList extends Vue {
    @Ref() tblFixedIncomeList: ElementUI.Table;
    //#region Private Declarations
    private _assetService: AssetService.AssetService;
    private _common: Common;
    public formatters: formatters;
    //#endregion Private Declarations

    //#region Props
    @Prop()
    clientId!: any;
    @Prop()
    ownerId!: any;
    @Prop()
    bankAccountId!: any;
    @Prop() treasuryType!: any;
    //#endregion Props

    //#region Data
    clientId_: number = tryParseInt(this.clientId, null);
    ownerId_: number = tryParseInt(this.ownerId == -1 ? null : this.ownerId, null);
    bankAccountId_: number = tryParseInt(this.bankAccountId, null);
    treasuryType_: string = this.treasuryType || null;
    fixedIncomes: AssetService.FixedIncome[] = [];
    filter: string = '';
    selectedFixedIncome = null;
    showFixedIncomeEdit = false;
    showFixedIncomeCreate = false;
    isLoading = false;
    currentOnly = true;
    fullscreen = false;
    tableHeight: number = null;
    securityLevel_: number = null;
    //#endregion Data

    //#region Computed
    get fixedIncomesFiltered(): AssetService.FixedIncome[] {
        return this.fixedIncomes.filter(fixedIncome => {
            const filter_ = (this.filter || '').toLowerCase();
            return (
                (fixedIncome.Client || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                (fixedIncome.Owner || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                (fixedIncome.Id || '').toString().toLowerCase().indexOf(filter_) > -1 ||
                moment(fixedIncome.SettlementDate || '').format('MM/DD/YY').indexOf(filter_) > -1 ||
                moment(fixedIncome.MaturityDate || '').format('MM/DD/YY').indexOf(filter_) > -1 ||
                this.$accounting.formatMoney(fixedIncome.FaceValue || '', '$').indexOf(filter_) > -1 ||
                (this.$accounting.formatNumber(fixedIncome.Discount || '', 2) + '%').indexOf(filter_) > -1 ||
                this.$accounting.formatMoney(fixedIncome.Price || '', '$').indexOf(filter_) > -1 ||
                this.$accounting.formatMoney(fixedIncome.Interest || '', '$').indexOf(filter_) > -1
            );
        });
    }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets), 0);
        this._common = new Common();
        this.formatters = new formatters();
        window.addEventListener("resize", this.setTableHeight);
    }
    destroyed(){
        window.removeEventListener("resize", this.setTableHeight);
    }

    async mounted() {
        await this.fetchFixedIncomes();
        this.setTableHeight();
    }

    renderHeader_ImpliedDiscount(h, {column, $index}) {
        return h(
            'span',
            {
                attrs: {
                    title: 'Implied Discount Rate using most recent FMV and corresponding Maturity Date'
                }
            },
            [column.label]
        );
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('clientId_')
    onChange_clientId_(val: number, oldVal: number) {
        if (!(oldVal == undefined && val == null)){
            this.fetchFixedIncomes();
        }
    }
    
    @Watch('ownerId_')
    onChange_ownerId_(val: number, oldVal: number) {
        if (!(oldVal == undefined && val == null)){
            this.fetchFixedIncomes();
        }
    }
    @Watch('bankAccountId_')
    onChange_bankAccountId_(val: number, oldVal: number) {
        if (!(oldVal == undefined && val == null)){
            this.fetchFixedIncomes();
        }
    }
    @Watch('currentOnly')
    onCurrentOnlyChange(val: number, oldVal: number) {
        if (!(oldVal == undefined && val == null)){
            this.fetchFixedIncomes();
        }
    }
    @Watch('treasuryType_')
    onTreasuryTypeChange(val: string, oldVal: string) {
        if (!(oldVal == undefined && val == null)){
            this.fetchFixedIncomes();
        }
    }

    //#endregion Watches

    //#region Methods
    async fetchFixedIncomes() {
        this.isLoading = true;
        this.fixedIncomes = [];

        const params = {} as AssetService.GetFixedIncomeParameters;
        params.ClientId = this.clientId_ || null;
        params.OwnerId = this.ownerId_ || null;
        params.CurrentOnly = this.currentOnly;
        params.BankAccountId = this.bankAccountId_ || null;
        this.fixedIncomes = await this._assetService.GetFixedIncome(params);

        this.isLoading = false;
    }
    async addNew(){
        this.showFixedIncomeEdit = false;
        await this.$nextTick();
        this.showFixedIncomeCreate = true;
    }
    async selectFixedIncome(fixedIncome?: AssetService.FixedIncome, event?) {
        this.showFixedIncomeEdit = false;
        await this.$nextTick();
        if (fixedIncome) {
            this.selectedFixedIncome = fixedIncome;
            this.showFixedIncomeEdit = true;
        }
    }

    closeFixedIncome() {
        this.showFixedIncomeEdit = false;
        this.selectedFixedIncome = null;
        this.tblFixedIncomeList.setCurrentRow(null);
    }

    onSaveNew(fixedIncome: AssetService.FixedIncome) {
        this.fetchFixedIncomes();
        this.selectFixedIncome(fixedIncome, null);
    }

    onDelete(fixedIncomeId) {
        if (fixedIncomeId) {
            const index = findIndex(this.fixedIncomes, fixedIncome => {
                return fixedIncome.Id === fixedIncomeId;
            });
            if (index > -1) {
                this.fixedIncomes.splice(index, 1);
            }
        }
        this.closeFixedIncome();
    }
    filterHandler(value, row, column) {
        const property = column['property'];
        return row[property] === value;
    }
    filters(column){
        const filters = this.fixedIncomesFiltered.map(function(list) {
            return {
                text: list[column]
                , value: list[column]
                };
        });
        return uniqBy(filters, 'value');
    }
    setFullscreen(fullscreen: boolean){
        this.fullscreen = fullscreen;
    }
    fiRowStyle({row, rowIndex}): Object {
        if (row.HasPendingRedemptionAsOf){
            return {'background-color': 'yellow'};
        }
        return '';
    }
    setTableHeight(){
        this.tableHeight =  this.$el.clientHeight - 130 - 38; //130: Selectors; 38: Filter w/ padding (ClientHeight is what is inside the router.  934 in DEV)
        this.tblFixedIncomeList.doLayout();
    }
    getSummary(param){
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = 'Total';
                return;
            }
            const values = data.map(item => Number(item[column.property]));
            if (column.label!='Maturity' && column.label!='Settlement' && !values.every(value => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }, 0);
                sums[index] = this.$accounting.formatMoney(sums[index], {precision : 0});
            } else {
                console.log('no total:', column.label);
                sums[index] = '';
            }
        });
        const face = this.$accounting.parse(sums[columns.findIndex(column => column.label === 'Face')]); //RemainingFaceValue
        const bey = this.$accounting.parse(sums[columns.findIndex(column => column.label === 'BEY')]); //WeightedBEYNumerator
        const wgtBEY = this.$accounting.formatNumber(face > 0 ? bey / face : 0, 4);
        sums[columns.findIndex(column => column.label === 'BEY')] = wgtBEY + '%';

        const disc = this.$accounting.parse(sums[columns.findIndex(column => column.label === 'Disc')]);  //WeightedDiscountNumerator
        const wgtDisc = this.$accounting.formatNumber(face > 0 ? disc / face : 0, 4);
        sums[columns.findIndex(column => column.label === 'Disc')] = wgtDisc + '%';

        const impliedDisc = this.$accounting.parse(sums[columns.findIndex(column => column.label === 'Implied Disc')]); //WeightedImpliedDiscountNumerator
        const wgtImpliedDisc = this.$accounting.formatNumber(face > 0 ? impliedDisc / face : 0, 4); 
        sums[columns.findIndex(column => column.label === 'Implied Disc')] = wgtImpliedDisc + '%';

        const price = this.$accounting.parse(sums[columns.findIndex(column => column.label === 'Price')]); //WeightedPricePer100Numerator
        const wgtPrice = this.$accounting.formatNumber(face > 0 ? price / face : 0, 4);
        sums[columns.findIndex(column => column.label === 'Price')] = this.$accounting.formatMoney(wgtPrice);

        return sums;
    }
    //#endregion Methods
}
