<template>
    <span :class="spanClass" v-bind:style="styleObject"><i v-if="iconClass" :class="iconClass" />{{value | currency(decimals) }}</span>
</template>
<script>
    import Vue from 'vue';
    export default Vue.extend({
        name: 'CurrencyLabel'
        , props: {
            value: Number
            , spanClass: {
                type: String
                , default: ''
            }
            , iconClass: {
                type: String
                , default: ''
            }
            , decimals: {
                type: Number
                , default: 2
            }
        }
        , computed: {
            styleObject: function () {
                var so = {
                    color: (this.value < 0) ? 'red' : 'black'
                };
                return so
            }
        }
    })

</script>
