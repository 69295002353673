
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import formatters from '../../utils/formatters';
import * as LiqService from '../../services/DAL/liq_Service';
import * as WorkflowService from '../../services/DAL/workflowService';
import { ElTable } from 'element-ui/types/table';
import ElementUI from 'element-ui';
import uniqBy from 'lodash/uniqBy';
import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';
import MicrosoftGraphClient, { GraphRequest, ResponseType } from '@microsoft/microsoft-graph-client';
import { spanRow } from "element-ui-table-span-method"
import Approval from '@/views/Approval/Approval.vue';
import PaymentConfirmation from '@/views/Approval/PaymentConfirmation.vue';

@Component({
  components: {
    'approval-item': Approval,
    PaymentConfirmation
  }
})
export default class ApprovalItemList extends Vue {
  @Ref() readonly tblApprovalItemList!: ElementUI.Table;
  @Ref() readonly approval!: Approval;

  //#region Data
  private _liqService: LiqService.LiqService;
  private _workflowService: WorkflowService.WorkflowService;
  private _graphService: MicrosoftGraphService;
  public formatters: formatters = new formatters();
  
  @Prop({type: Number, default: null}) readonly approvalId!: number;
  @Prop({type: Object, default: () => ({})}) readonly approvalBatch!: WorkflowService.ApprovalBatches;
  
  approvalItemsForApproval = [] as WorkflowService.ApprovalItemsForApproval[];
  isLoading = false;
  securityLevel_: number = null;
  showApprovalItemRemove = false;
  approvalItemToRemove = {} as WorkflowService.ApprovalItemsForApproval;
  approvalItemRemovalComment: string = '';
  approvalItemRemovalNotify = true;
  showApproval = true;
  approvalBatch_ = this.approvalBatch || {} as WorkflowService.ApprovalBatches;
  approvalId_: number = this.approvalId || this.approvalBatch.ApprovalId;
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._workflowService = new WorkflowService.WorkflowService();
    this._liqService = new LiqService.LiqService();
    this.formatters = new formatters();
    this._graphService = new MicrosoftGraphService();
        this.securityLevel_ = tryParseInt(
        getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), //placeholder.  probably need something more specific
        0
    );
    if (!this.approvalBatch || !this.approvalBatch.ApprovalId){
      this.getApprovalBatch();
    }

  }

  async mounted() {
    this.getApprovalItems();
  }
  //#endregion Lifecycle

  //#region Watches
    @Watch("approvalId")
    onChange_approvalId(val: number, oldVal: number) {
        this.getApprovalItems();
    }
  //#endregion Watches

  get approvalResponseReceived(){
    if (this.approval){
        return this.approval.responseReceived;
    }
    else return false;
  }
  get isTaPWorkflow(): boolean {
    return (this.approvalBatch_ && this.approvalBatch_.Workflow == 'Transfers and Payments')
  }
  get isConfirmWorkflow(): boolean {
    return (this.approvalBatch_ && this.approvalBatch_.Workflow == 'Payment Confirm')
  }

  //#region Methods

  async getApprovalItems() {
    this.isLoading = true;
    this.approvalItemsForApproval = [] as WorkflowService.ApprovalItemsForApproval[];
    const parms = {} as WorkflowService.GetApprovalItemsForApprovalParameters;
    parms.ApprovalId = this.approvalId;
    this.approvalItemsForApproval = await this._workflowService.GetApprovalItemsForApproval(parms);
    this.isLoading = false;
  }
  async getApprovalBatch(){
    this.isLoading = true;
    this.approvalBatch_ = {} as WorkflowService.ApprovalBatches;
    const parms = {} as WorkflowService.GetApprovalBatchesParameters;
    parms.ApprovalId = this.approvalId;
    const res = await this._workflowService.GetApprovalBatches(parms);
    if (res && res.length == 1){
      this.approvalBatch_ = res[0];
    }
    this.isLoading = false;
  }
  async openFile(path) {
    const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
    const fileProperties = await graphClient.api(path).get();
    if (fileProperties && fileProperties.webUrl) {
        window.open(fileProperties.webUrl, '_blank');
        this.$notify.success('File opened in new tab');
      }
  }
  selectableItem(row: WorkflowService.ApprovalItemsForApproval){
      return row.SelectableItem;
  }
  objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    if (this.isTaPWorkflow){
      const option = [
        { index: 0, field: "Confirmer" },
        { index: 1, field: "InvoiceApprover" }
      ]
      return spanRow(
        { row, column, rowIndex, columnIndex },
        this.approvalItemsForApproval,
        option
      );
    }
  }
  fnEntityURL(approvalId){
    if (!approvalId) return;
    return `https://vam.vinitaspartners.com/Treasury/PaymentList/${approvalId.toString()}`;
  }
  showRemoveApprovalItem(approvalItem: WorkflowService.ApprovalItemsForApproval){
    this.approvalItemToRemove = approvalItem;
    this.showApprovalItemRemove = true;
  }
  async removeApprovalItem(){
    try{
        this.isLoading = true;
        const params = {} as LiqService.RejectScheduledPaymentParameters;
        params.ApprovalItemId = this.approvalItemToRemove.ApprovalItemId;
        params.Comment = this.approvalItemRemovalComment;
        params.NotifyBatchCreator = this.approvalItemRemovalNotify;
        await this._liqService.RejectScheduledPayment(params);
        
        this.approvalItemsForApproval.splice(this.approvalItemsForApproval.indexOf(this.approvalItemToRemove), 1); //remove from list
        this.$notify.success('Item removed');
        this.showApproval = false;
        await this.$nextTick;
        this.showApproval = true; // refresh approval to show added comment
        this.isLoading = false;
    }
    catch (err) {
        this.$notify.error(err.message);
        this.isLoading = false;
    }
    this.approvalItemToRemove = {} as WorkflowService.ApprovalItemsForApproval;
    this.approvalItemRemovalComment = '';
    this.showApprovalItemRemove = false;
    this.approvalItemRemovalNotify = true;
  }
  cancelRemoveApprovalItem(){
    this.approvalItemToRemove = {} as WorkflowService.ApprovalItemsForApproval;
    this.approvalItemRemovalComment = '';
    this.showApprovalItemRemove = false;
    this.approvalItemRemovalNotify = true;
  }
  batchStatusChanged(status){
    this.approvalBatch_.Approved = status.Approved;
  }



//#endregion Methods
}
