
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import * as TransactionsService from "@/services/DAL/transactionsService";
import formatters from '@/utils/formatters';
import Common from "@/utils/common";
import ElementUI from 'element-ui';
import ElCurrencyInput from './components/form/ELCurrencyInput.vue';
import * as math from "mathjs";


declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component
export default class SellMarketableSecurity extends Vue {
    @Ref() readonly refMarketableSecuritiesForSale!: ElementUI.Table;

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    public formatters: formatters;
    private common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({type: Number, required: true}) readonly marketableSecuritySaleSecurityId!: number;

    //#endregion Props

    //#region Data
    marketableSecuritySaleSecurity_ = {} as TransactionsService.MarketableSecuritySaleSecurity;
    loading = false;
    marketableSecurityInvestments = [] as TransactionsService.MarketableSecurityInvestments[];
    inEditMode = false;

    //#endregion Data


     //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.common = new Common();
        this.formatters = new formatters();
    }

    async mounted() {
      await this.initialize()
    }

    //#endregion Lifecycle

    //#region Watches
    @Watch('marketableSecuritySaleSecurityId')
    onChange_marketableSecuritySaleSecurityId(val: number, oldVal: number) {
        if (!(oldVal == undefined && val == null)){
            this.fetchMarketableSecurityInvestments();
        }
    }
    @Watch('marketableSecuritySaleSecurity_.TargetProceeds')
    onChange_TargetProceeds(val: number, oldVal: number) {
      this.calculate();
    }
    @Watch('marketableSecuritySaleSecurity_.PriceAdjustment')
    onChange_PriceAdjustment(val: number, oldVal: number) {
        this.calculate();
    }
    @Watch('marketableSecuritySaleSecurity_.MarketPrice')
    onChange_MarketPrice(val: number, oldVal: number) {
        this.calculate();
    }
    //#endregion Watches

    //#region Computed
    get readyToAllocate(): boolean {
      return !!(this.marketableSecurityInvestments && this.marketableSecurityInvestments.length && this.marketableSecurityInvestments[0].BufferedPrice && this.marketableSecurityInvestments[0].TotalSharesToSell)
    }

    get allocationIsDirty(): boolean {
      return this.marketableSecurityInvestments.some(
        msi => this.rowIsDirty(msi)
    );
    }
    //#endregion Computed

    //#region Methods
    calculate(){
      this.marketableSecuritySaleSecurity_.TotalSharesToSell = math.floor((this.marketableSecuritySaleSecurity_.TargetProceeds / (this.marketableSecuritySaleSecurity_.MarketPrice * (1-(this.marketableSecuritySaleSecurity_.PriceAdjustment/100))) + 99) / 100) * 100
      this.marketableSecuritySaleSecurity_.BufferedPrice = this.marketableSecuritySaleSecurity_.MarketPrice * (1-(this.marketableSecuritySaleSecurity_.PriceAdjustment/100));
    }
    async initialize(){
      await this.fetchMarketableSecuritySaleSecurity();
      await this.fetchMarketableSecurityInvestments();
      if (this.marketableSecuritySaleSecurity_ && !(!!this.marketableSecuritySaleSecurity_.TargetProceeds && !!this.marketableSecuritySaleSecurity_.MarketPrice)) this.inEditMode = true;
    }
    
    rowIsDirty(row: TransactionsService.MarketableSecurityInvestments): boolean {
      const field = this.$validator.fields.find({name: `ActualSharesToSell_${row.InvestmentId.toString()}`});
      if (field) {
        return field.flags.dirty;
      }
      else return false;
    }

    async fetchMarketableSecuritySaleSecurity(){
      this.marketableSecuritySaleSecurity_ = {} as TransactionsService.MarketableSecuritySaleSecurity;
      if (this.marketableSecuritySaleSecurityId) {
          this.loading = true;
          const params = {} as  TransactionsService.GetMarketableSecuritySaleSecurityParameters;
          params.MarketableSecuritySaleSecurityId = this.marketableSecuritySaleSecurityId;
          const mss = await this._transactionsService.GetMarketableSecuritySaleSecurity(params);
          if (mss && mss.length == 1){
            this.marketableSecuritySaleSecurity_ = mss[0];
          }
          this.loading = false;
      }

    }
    async fetchMarketableSecurityInvestments(){
      if (this.marketableSecuritySaleSecurityId) {
          this.loading = true;
          const params = {} as  TransactionsService.GetMarketableSecurityInvestmentsParameters;
          params.MarketableSecuritySaleSecurityId = this.marketableSecuritySaleSecurityId;
          this.marketableSecurityInvestments = await this._transactionsService.GetMarketableSecurityInvestments(params);
          this.loading = false;
      }

    }

    async saveEdit(){
      this.loading = true;
      await new SmartObject('MarketableSecuritySaleSecurities', this.marketableSecuritySaleSecurity_.MarketableSecuritySaleSecurityId).updateObject(
          this.marketableSecuritySaleSecurity_
      );
      this.loading = false;
      this.inEditMode = false;
      await this.fetchMarketableSecurityInvestments();
    }
    async saveAllocation(){
      const promises = this.marketableSecurityInvestments.map(async (row) => { 
        if (this.rowIsDirty(row)){
          if (row.Id){
            const mssi = new SmartObject("MarketableSecuritySaleInvestment", row.Id);
            await mssi.updateAttribute("Shares", row.ActualSharesToSell);
          }
          else {
            const newMSSI = {
              Shares: row.ActualSharesToSell
              , InvestmentId: row.InvestmentId
              , MarketableSecuritySaleSecuritiesId: row.MarketableSecuritySaleSecuritiesId
            }
            const id = await new SmartObject(
              "MarketableSecuritySaleInvestment"
              ).createObject(newMSSI);
            row.Id = id;
          }
          const field = this.$validator.fields.find({name: `ActualSharesToSell_${row.InvestmentId.toString()}`});
          if (field) {
            field.reset();
          }
          this.$notify.success(`Investment Id ${row.InvestmentId} updated`);
          return; 
        }
      });

      this.loading = true;
      await Promise.all(promises);
      this.loading = false;

    }
    async cancelEdit(){
      this.inEditMode = false;
      this.initialize();
    }

    getSummaryInvestments(param): string[] {
      const { columns, data } = param;
      const columnList = [];
      const formats = new Map();
      columns.forEach((column, index) => {
          if (column.align == 'is-right' && column.formatter && column.formatter.name == 'formatterNumber0') {
              columnList.push(index);
              formats.set(index, "number0");
          }
      });
      const totalLabel = 'Total';
      const totalLabelIndex = 0;
      return this.common.getSummaryArray(param, columnList, totalLabel, totalLabelIndex, formats);    
    }

    //#endregion Methods

}
