//js API for Schema reports
//Auto-generated 02/23/24 13:56
 

var mixinSchema_reports = {
    methods: {
        /** @description Get ActivitySources using reports.GetActivitySources
         */
		 reports_GetActivitySources: function (params, onSuccess, onError, async) {
			/*
				this.ActivitySources = await this.reports_GetActivitySources({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'reports.GetActivitySources'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ConfigurationPermissionsForUser using reports.GetConfigurationPermissionsForUser
         * @param {string} userId - 
         */
		, reports_GetConfigurationPermissionsForUser: function (userId) {
			/*
				this.ConfigurationPermissionsForUser = this.reports_GetConfigurationPermissionsForUser(
					null // userId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'reports.GetConfigurationPermissionsForUser'
			  , params: {
					UserId: userId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Configurations using reports.GetConfigurations
         * @param {number} clientId - 
         * @param {number} configurationTypeId - 
         */
		, reports_GetConfigurations: function (params, onSuccess, onError, async) {
			/*
				this.Configurations = await this.reports_GetConfigurations({
					ClientId: clientId
					, ConfigurationTypeId: configurationTypeId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'reports.GetConfigurations'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ConfigurationSettingEntityTypes using reports.GetConfigurationSettingEntityTypes
         * @param {number} configurationSettingId - 
         */
		, reports_GetConfigurationSettingEntityTypes: function (configurationSettingId) {
			/*
				this.ConfigurationSettingEntityTypes = this.reports_GetConfigurationSettingEntityTypes(
					null // configurationSettingId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'reports.GetConfigurationSettingEntityTypes'
			  , params: {
					ConfigurationSettingId: configurationSettingId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get DefaultOwners using reports.GetDefaultOwners
         * @param {number} configurationId - 
         */
		, reports_GetDefaultOwners: function (params, onSuccess, onError, async) {
			/*
				this.DefaultOwners = await this.reports_GetDefaultOwners({
					ConfigurationId: configurationId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'reports.GetDefaultOwners'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get EntitySettingConfigurations using reports.GetEntitySettingConfigurations
         */
		, reports_GetEntitySettingConfigurations: function () {
			/*
				this.EntitySettingConfigurations = this.reports_GetEntitySettingConfigurations(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'reports.GetEntitySettingConfigurations'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get EntitySettings using reports.GetEntitySettings
         * @param {number} configurationId - 
         * @param {number} configurationSettingId - 
         */
		, reports_GetEntitySettings: function (params, onSuccess, onError, async) {
			/*
				this.EntitySettings = await this.reports_GetEntitySettings({
					ConfigurationId: configurationId
					, ConfigurationSettingId: configurationSettingId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'reports.GetEntitySettings'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Groups using reports.GetGroups
         */
		, reports_GetGroups: function (params, onSuccess, onError, async) {
			/*
				this.Groups = await this.reports_GetGroups({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'reports.GetGroups'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get LayoutGroupDetail using reports.GetLayoutGroupDetail
         * @param {number} layoutGroupId - 
         */
		, reports_GetLayoutGroupDetail: function (layoutGroupId) {
			/*
				this.LayoutGroupDetail = this.reports_GetLayoutGroupDetail(
					null // layoutGroupId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'reports.GetLayoutGroupDetail'
			  , params: {
					LayoutGroupId: layoutGroupId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get LayoutGroups using reports.GetLayoutGroups
         * @param {number} layoutId - 
         * @param {number} parentId - 
         */
		, reports_GetLayoutGroups: function (layoutId, parentId) {
			/*
				this.LayoutGroups = this.reports_GetLayoutGroups(
					null // layoutId
					, null // parentId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'reports.GetLayoutGroups'
			  , params: {
					LayoutId: layoutId
					, ParentId: parentId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Layouts using reports.GetLayouts
         */
		, reports_GetLayouts: function () {
			/*
				this.Layouts = this.reports_GetLayouts(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'reports.GetLayouts'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get OverallSettings using reports.GetOverallSettings
         * @param {number} configurationId - 
         */
		, reports_GetOverallSettings: function (params, onSuccess, onError, async) {
			/*
				this.OverallSettings = await this.reports_GetOverallSettings({
					ConfigurationId: configurationId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'reports.GetOverallSettings'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get OwnerEntityGroupTypes using reports.GetOwnerEntityGroupTypes
         * @param {number} clientId - 
         */
		, reports_GetOwnerEntityGroupTypes: function (params, onSuccess, onError, async) {
			/*
				this.OwnerEntityGroupTypes = await this.reports_GetOwnerEntityGroupTypes({
					ClientId: clientId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'reports.GetOwnerEntityGroupTypes'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ReportParameters using reports.GetReportParameters
         * @param {number} reportId - 
         */
		, reports_GetReportParameters: function (params, onSuccess, onError, async) {
			/*
				this.ReportParameters = await this.reports_GetReportParameters({
					ReportId: reportId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'reports.GetReportParameters'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ReportPermissionsForUser using reports.GetReportPermissionsForUser
         * @param {string} userId - 
         */
		, reports_GetReportPermissionsForUser: function (params, onSuccess, onError, async) {
			/*
				this.ReportPermissionsForUser = await this.reports_GetReportPermissionsForUser({
					UserId: userId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'reports.GetReportPermissionsForUser'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Reports using reports.GetReports
         * @param {number} groupId - 
         */
		, reports_GetReports: function (params, onSuccess, onError, async) {
			/*
				this.Reports = await this.reports_GetReports({
					GroupId: groupId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'reports.GetReports'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Reports.LogExecution
         * @param {number} reportId - 
         * @param {string} parameters - 
         */
		, Reports_LogExecution: function (params, onSuccess, onError, async) {
			/*
				this.LogExecution = await this.Reports_LogExecution({
					ReportId: reportId
					, Parameters: parameters
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'Reports.LogExecution'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
    }
};
export default mixinSchema_reports;
