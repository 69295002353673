
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import * as AssetService from "@/services/DAL/assetService";
import * as TransactionsService from "@/services/DAL/transactionsService";
import * as AcctService from "@/services/DAL/acctService";
import CommentLogList from '@/components/other/tsCommentLogList.vue'
import cloneDeep from "lodash/cloneDeep";
import formatters from '../../utils/formatters';
import Common from "@/utils/common";
import ElementUI from "element-ui";


declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function getSecurityUserId(): string;

@Component({
  components: {
      CommentLogList
  },
})
export default class Conversion extends Vue {
    //#region Private declarations for Services
    private _assetService: AssetService.AssetService;
    private _transactionsService: TransactionsService.TransactionsService;
    private _acctService: AcctService.AcctService;
    public formatters: formatters = new formatters();
    private common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({required: true, type: Object}) readonly investment: AssetService.InvestmentList;
    @Prop({ type: Number, default: null }) readonly securityLevel: number;

    //#endregion Props

    //#region Data

    securityLevel_: number = null;
    investment_ = {} as AssetService.InvestmentList;
    currentSecurityUserId: string = null;
    loading: boolean = false;
    loadingAnalysis: boolean = false;
    loadingLegacyComparison: boolean = false;
    newLegacyStatisticsAnalysis = [] as TransactionsService.NewLegacyStatisticsAnalysis[];
    investmentBalanceSheetLegacyComparison = [] as AcctService.InvestmentBalanceSheetLegacyComparison[];
    showingPopup: Map<number, boolean> = new Map();
    excludeFMVOnlyRows: boolean = false;

//#endregion Data

  //#region Lifecycle
  async created() {
    this._assetService = new AssetService.AssetService();
    this._transactionsService = new TransactionsService.TransactionsService();
    this._acctService = new AcctService.AcctService();
    this.common = new Common();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
      0
    );
    this.currentSecurityUserId = await getSecurityUserId();
    if (this.investment && Object.keys(this.investment).length) {
      this.investment_ = cloneDeep(this.investment);
    }
    else {
        this.$notify.error('Investment object required');
    }
    if (this.investment_ && this.investment_.UseNewTransactionScheme && !(this.investment_.TransactionConversionReview1 && this.investment_.TransactionConversionReview2)){
      this.fetchInvestmentBalanceSheetComparison()
    }
  }
  async mounted() {
  }
//#endregion Lifecycle

//#region watches

@Watch('investment_.UseNewTransactionScheme')
async useNewTransactionScheme_changed(val: boolean, oldVal: boolean){
    console.log(val, oldVal);
    if (oldVal == undefined) return; // don't do anything on load

    this.loading = true;
    const params = {} as AssetService.SetInvestmentUseNewTransactionSchemeParameters;
    params.InvestmentId = this.investment_.Id;
    params.UseNewScheme = val;
    const results = await this._assetService.SetInvestmentUseNewTransactionScheme(params);
    console.log(results, params);
    if (results && results.length == 1){
        Object.assign(this.investment_, results[0]); // they are really the same type
        this.$emit('investmentUpdated', this.investment_);
        this.$notify.success('New Transction Logic Status Updated')
    }
    this.loading = false;
    if (val){
      this.fetchInvestmentBalanceSheetComparison();
    }
}
@Watch('investment_.TransactionConversionReview1')
async TransactionConversionReview1_changed(val: boolean, oldVal: boolean){
    console.log(val, oldVal);
    if (oldVal == undefined) return; // don't do anything on load

    this.loading = true;
    const params = {} as AssetService.SetInvestmentNewTransactionReview1Parameters;
    params.InvestmentId = this.investment_.Id;
    params.IsReviewed = val;
    const results = await this._assetService.SetInvestmentNewTransactionReview1(params);
    console.log(results, params);
    if (results && results.length == 1){
        Object.assign(this.investment_, results[0]); // they are really the same type
        this.$notify.success('New Transction Review Status Updated')
    }
    this.loading = false;

}
@Watch('investment_.TransactionConversionReview2')
async TransactionConversionReview2_changed(val: boolean, oldVal: boolean){
    if (oldVal == undefined) return; // don't do anything on load

    this.loading = true;
    const params = {} as AssetService.SetInvestmentNewTransactionReview2Parameters;
    params.InvestmentId = this.investment_.Id;
    params.IsReviewed = val;
    const results = await this._assetService.SetInvestmentNewTransactionReview2(params);
    console.log(results, params);
    if (results && results.length == 1){
        Object.assign(this.investment_, results[0]); // they are really the same type
        this.$notify.success('New Transction Review Status Updated')
    }
    this.loading = false;

}

//#endregion

//#region computed


//#endregion

  async fetchInvestmentBalanceSheetComparison(){
    this.loadingLegacyComparison = true;
    const params = {} as AcctService.GetInvestmentBalanceSheetLegacyComparisonParameters;
    params.InvestmentId = this.investment_.Id;
    this.investmentBalanceSheetLegacyComparison = await this._acctService.GetInvestmentBalanceSheetLegacyComparison(params);
    this.loadingLegacyComparison = false;
  }

  async getNewLegacyStatisticsAnalysis(){
    this.loadingAnalysis = true;
    this.showingPopup.clear();
    const params = {} as TransactionsService.GetNewLegacyStatisticsAnalysisParameters;
    params.InvestmentId = this.investment_.Id;
    params.ExcludeFMVOnlyRows = this.excludeFMVOnlyRows
    this.newLegacyStatisticsAnalysis = await this._transactionsService.GetNewLegacyStatisticsAnalysis(params)
    this.newLegacyStatisticsAnalysis.map(item => {
      this.showingPopup = new Map(this.showingPopup.set(item.DateKey, false));
    });
    this.loadingAnalysis = false;
  }

  statisticsCellStyle({row, column, rowIndex, columnIndex}): object {
    if (column.property.indexOf('Diff') > -1 && row[column.property+'Pct'] != 0){ // only applies to diff columns, and only if DiffPct is non-0
      const hsl = this.getHeatmapColorForCSS(row[column.property+'Pct']);
      // console.log(row, column, rowIndex, column.property+'Pct', row[column.property], row[column.property+'Pct'], hsl);
      return {'background-color': hsl};
    }
    else return {};
  }

  private getHeatmapColorForCSS(normalizedValue0to1: number): string {
      normalizedValue0to1 = Math.abs(normalizedValue0to1);
      if (normalizedValue0to1 > 1) normalizedValue0to1 = 1;
      // h range is 0-360, but that is red-red.  so instead we want to use 80-360 (green - red)
      // so multiply by 280 (360-80), giving a range of 0-280; then add 80, resulting in 80-360
      const h = (normalizedValue0to1 * 280) + 80;
      const s = 50;
      const l = 70;
      return `hsl(${h}, ${s}%, ${l}%)`;
  }
  commentAdded(row: TransactionsService.NewLegacyStatisticsAnalysis){
    row.Comments++;
    (this.$refs[`refPopUp${row.DateKey}`] as any).doToggle(); //undocumented
  }
  commentDeleted(row: TransactionsService.NewLegacyStatisticsAnalysis){
    row.Comments--;
  }
  async onCommentShow(dateKey){
    await this.$nextTick();
    this.showingPopup = new Map(this.showingPopup.set(dateKey, true));
  }
  getSummary(param) {
    const columnList = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
    const totalLabel = "Total";
    const totalLabelIndex = 0;
    const formats = new Map();
    formats.set(1, "currency");
    formats.set(2, "currency");
    formats.set(3, "currency");
    formats.set(4, "currency");
    formats.set(5, "number0");
    formats.set(6, "currency");
    formats.set(7, "currency");
    formats.set(8, "currency");
    formats.set(9, "currency");
    formats.set(10, "currency");
    formats.set(11, "number0");
    formats.set(12, "currency");
    formats.set(13, "currency");
    formats.set(14, "currency");
    formats.set(15, "currency");
    formats.set(16, "number0");
    const result = this.common.getSummaryArray(
      param,
      columnList,
      totalLabel,
      totalLabelIndex,
      formats
    );
    return result;
  }


}

