
import Vue from 'vue';
import Component from 'vue-class-component';
import ExplorerBase from './../../js/Attachments/ExplorerBase.vue'
import InvoiceIngestView from './../../js/AP/InvoiceIngestView.vue'
import FileRenameView from '@/components/other/FileRenameView.vue';
import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';
import MicrosoftGraphClient, { GraphRequest, ResponseType } from '@microsoft/microsoft-graph-client';
import * as AttachmentService from '../../services/DAL/attachmentService';
import * as LiqService from '../../services/DAL/liq_Service';
import * as MicrosoftGraphTypes from "@microsoft/microsoft-graph-types" 
import Common from '../../utils/common';

declare var SmartObject: any;

declare function encodeFileName(filename: string): string;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: number, deflt: number): number;

@Component({
    components: {
        FileRenameView,
        ExplorerBase,
        InvoiceIngestView
    }
})
export default class IngestInvoices extends Vue {
    $refs: {
        txtSelectFile: HTMLInputElement,
        IngestExplorer: Vue
    }
    //#region Data
    private _graphService: MicrosoftGraphService;
    private _attachmentService: AttachmentService.AttachmentService;
    private _liqService: LiqService.LiqService;
    private _common: Common;

    securityLevel: number = 0;
    token: string = '';
    showFileRename: boolean = false;
    showSideBySide: boolean = false;
    selectedFile: any = null;
    selectedFileProperties: object = {};
    selectedInvoice = {} as LiqService.Invoices;
    ingestionStores = [] as LiqService.InvoiceIngestionStores[];
    selectedIngestionStoreId: number = null;

    fileCount: number = -1;
    isInitial: boolean = true;
    isSaving: boolean = false;
    isDragging: boolean = false;
    isLoading: boolean = false;

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._graphService = new MicrosoftGraphService();
        this._attachmentService = new AttachmentService.AttachmentService();
        this._liqService = new LiqService.LiqService();
        this._common = new Common();
        this.securityLevel = tryParseInt(
        getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP),
        0
        );
        this.fetchIngestionStores();
    }

    async mounted() {
    }
    //#endregion Lifecycle

    //#region Computed
    get title() {
        return `Invoice ${this.selectedInvoice.InvoiceId}`;
    }

    get ingestionPath() {
        if (!!this.selectedIngestionStoreId && !!Object.keys(this.selectedIngestionStore).length) {
            return `/drives/${this.selectedIngestionStore.DriveId}/items/${this.selectedIngestionStore.ItemId}`;
        }
    }

    get nextPath() {
        if (!!this.selectedIngestionStoreId && !!Object.keys(this.selectedIngestionStore).length) {
            return `/drives/${this.selectedIngestionStore.NextDriveId}/items/${this.selectedIngestionStore.NextItemId}`;
        }
    }

    get selectedIngestionStore() {
        if (this.selectedIngestionStoreId){
            return this._common.getSelectedArrayItem(
                this.ingestionStores,
                this.selectedIngestionStoreId.toString(),
                'Id'
            );
        }
    }
    //#endregion Computed

    //#region Methods
    async fetchIngestionStores() {
        const params = {} as LiqService.GetInvoiceIngestionStoresParameters;
        this.ingestionStores = await this._liqService.GetInvoiceIngestionStores(params);
        if (this.ingestionStores.length == 1){
            this.selectedIngestionStoreId = this.ingestionStores[0].Id;
        }
    }
    async fileSelected (file) {
        console.log(file);
        var FileId = file.id;
        var eTag = file.eTag;

        const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient()
        let res: microsoftgraph.DriveItem;
        try{
            res = await graphClient
                .api(`/drives/${this.selectedIngestionStore.DriveId}/items/${FileId}`)
                //.select("id,name,@microsoft.graph.downloadUrl")
                .get();
        }
        catch(err){
            console.log(err);
            return;
        }
        let moveRes = {} as any;
        if (res.eTag != eTag){
            this.$notify.error('File has changed or moved since list was created.  Please refresh the list.');
            return;
        }
        else {
            //move it first, so that anyone else that might be working will no longer see it.
            try {
                this.isLoading = true;
                moveRes = await graphClient
                    .api(`/drives/${this.selectedIngestionStore.DriveId}/items/${FileId}`)
                    .responseType(ResponseType.RAW)
                    .patch(
                        {
                            "parentReference": { 
                                "id": this.selectedIngestionStore.NextItemId
                                , "driveId": this.selectedIngestionStore.NextDriveId
                            }
                        });
                if (moveRes.status == 200){ // success, object returned.
                    const moveResObj: microsoftgraph.DriveItem = await moveRes.json();
                    res = moveResObj
                }
                else if (moveRes.status == 202 ){ // accepted.  must monitor location header to get final result.
                    const location = moveRes.headers.get("location"); 
                    if (location) {
                        res = await this._graphService.getDriveItemFromLocation(location);
                    }                    
                }
            }
            catch(err){
                if (err.statusCode == 409){
                    this.$notify.error('File Name already exists.  Please change the File Name in the Ingest Folder.');
                }
                else {
                    console.log(err);
                    this.$notify.error({
                        title: 'File Error',
                        message: err.message,
                        duration: 0
                    });
                }
                this.isLoading = false;
                return; // don't create invoice record, etc
            }
            this.$notify.success('Moved File from Ingest Folder.');
            this.$refs.IngestExplorer.$delete(this.$refs.IngestExplorer.$data.items, this.$refs.IngestExplorer.$data.items.indexOf(file));
            this.isLoading = false;
            this.openInvoice(res);
        }
    }

    async openInvoice(fileResponse){
        this.selectedInvoice.FileId = fileResponse.id;
        this.selectedInvoice.DriveId = fileResponse.parentReference.driveId;
        this.selectedInvoice.ClientId = this.selectedIngestionStore.ClientId; // will be null when non-client-specific, but will pre-select client for client-specific ingest sources
        this.selectedInvoice.OwnerId = null;
        this.selectedInvoice.VendorId = null;
        this.selectedInvoice.ReferenceEntityId = null;
        this.selectedInvoice.ReferenceEntityType = null;
        this.selectedInvoice.ReferenceEntityName = null;
        this.selectedInvoice.StatusText = 'Ingested';
        this.selectedInvoice.StatusId = 10; //Ingested
        this.selectedInvoice.NotifyApproversOnMatch = true;
        this.selectedInvoice.NotifyFYIOnMatch = true;
        this.selectedInvoice.AgreementId = null;
        this.selectedInvoice.ScheduleItemId = null;
        this.selectedInvoice.Deleteable = true;

        const InvoiceSO = new SmartObject('Invoice')
        const InvoiceObjectId = await InvoiceSO.createObject(this.selectedInvoice);
        this.selectedInvoice.InvoiceId = InvoiceObjectId;
        this.selectedInvoice.CreateUser = InvoiceSO.dataObject.CreateUser; // these are needed, otherwise they get updated to null in the next update.
        this.selectedInvoice.CreateDate = InvoiceSO.dataObject.CreateDate;
        this.selectedFileProperties = fileResponse;
        this.showSideBySide = true;
    }

    beforeCloseSideBySide(done) {
        this.showSideBySide = false;
        done();
    }

    filesChange(files) {
        this.fileCount = files.length;
        if (!files.length) {
            return;
        }
        this.isInitial = false;
        this.isSaving = true;
        this.selectedFile = files[0];
        this.showFileRename = true;
    }

    cancelFileRename() {
        this.showFileRename = false;
        this.$notify.warning('File upload canceled');
        console.log('File upload canceled from rename prompt');
        this.resetFileInput();
    }

    saveFileRename(filename) {
        if (filename) {
            this.showFileRename = false;
            this.uploadFile(filename);
        }
    }
    
    async uploadFile(filename) {
        const encodedFilename = encodeFileName(filename);
        const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
        try {
            const saveResponse = await graphClient
                .api(this.nextPath + '/children/' + encodedFilename + '/content?@name.conflictBehavior=fail')
                .put(this.selectedFile);
            console.log('File Saved: ', saveResponse);
            this.openInvoice(saveResponse);
        }
        catch (err) {
            console.error(err);
            if (err.statusCode == 409) {
                await this.$confirm('Document with the same name already exists. Upload again with a different name.', 'Already Exists!', {
                    showCancelButton: false,
                    type: 'warning'
                });
                this.$notify.warning('File upload canceled');
            }
            else {
                this.$notify({
                    title: 'File Upload Error',
                    type: 'error',
                    message: err.message
                });
            }
        }

        this.resetFileInput();
    }

    resetFileInput() {
        this.isSaving = false;
        this.isInitial = true;
        this.$refs.txtSelectFile.type = 'text';
        this.$refs.txtSelectFile.type = 'file';
    }
    //#endregion Methods
}
