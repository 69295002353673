
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import * as AssetService from '@/services/DAL/assetService';
    import ClientSelector from "@/components/form/ClientSelector.vue";
    import OwnerSelector from "@/components/form/OwnerSelector.vue";
    import formatters from '@/utils/formatters';
    import NamedKeySelector from '@/components/form/NamedKeySelector.vue';
    import ValuablesEdit from '@/views/Valuables/ValuablesEdit.vue';
    import * as SystemService from '@/services/DAL/systemService';
    import Common from '@/utils/common';
    import numbro from 'numbro';
import { extend } from "node_modules/vue/types/umd";

    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;

    @Component({
        components: {
            OwnerSelector,
            ClientSelector,
            NamedKeySelector,
            ValuablesEdit
        }
    })
    export default class ValuablesList extends Vue {
   //#region Private declarations for Services
    private _assetService: AssetService.AssetService;
    private common: Common;
    private formatters: formatters;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({ type: Number }) readonly valuablesId: number;
   
    //#endregion Props

    //#region Data
    securityLevel_: number = null;

    valuablesList = [] as AssetService.Valuables[];
    filteredValuablesList = [] as AssetService.Valuables[];
    filterValuablesList: string = null;
    filterValuablesListId: string = null;
    locations = [] as AssetService.ValuablesLocations[];
    valuablesCategories = [] as AssetService.ValuablesCategories[];

    categories = [] as SystemService.NamedKeys[];
    selectedLocation: string = null
    errorOnly = true
    selectedValuables = {} as AssetService.Valuables;
    tableHeight: Number = 500
    showValuablesEditView = false
    loading = false
    selectedCategoryId: number = null
    
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this.formatters = new formatters();
        if (this.securityLevel_ === null) {
            this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageValuables), 0);
        }
        this.fetchValuables();
        this.fetchLocations();
        this.fetchCategories();
    }
    mounted() {
        // this.tableHeight =
        //     $('.main').height() -
        //     $('.breadcrumb').outerHeight() -
        //     parseInt($('.breadcrumb').css('margin-bottom')) -
        //     $(this.$refs.selectCard.$el).outerHeight() -
        //     $('.valuablesListHeader').parent().outerHeight() -
        //     $('.valuablesListFilterRow').outerHeight() -
        //     parseInt($('.valuablesListFilterRow').parent(':first').css('padding-top')) -
        //     parseInt($('.valuablesListFilterRow').parent(':first').css('padding-bottom')) -
        //     $('.app-footer').outerHeight();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('selectedCategoryId')
    async selectedCategoryIdChanged(val: number, oldVal: number) {
        this.fetchLocations();
    }
    @Watch('filterValuablesList')
    async filterValuablesListChanged(val: string, oldVal: string) {
        this.filteredValuablesList = this.valuablesList.filter(function (e) {
            return ((e.Description) ? e.Description.toLowerCase().indexOf(val.toLowerCase()) : -1) !== -1
                || e.ValuablesId.toString().indexOf(val.toLowerCase()) !== -1
                || (e.InvoiceId == null ? '' : e.InvoiceId.toString()).indexOf(val.toLowerCase()) !== -1
                || (e.InvoiceNumber == null ? '' : e.InvoiceNumber.toLowerCase()).indexOf(val.toLowerCase()) !== -1
                ;
        });
    }
    @Watch('filterValuablesListId')
    async filterValuablesListIdChanged(val: string, oldVal: string) {
        this.filteredValuablesList = this.valuablesList.filter(function (e) {
                return e.ValuablesId.toString().indexOf(val.toLowerCase()) !== -1;
        });
    }
    // @Watch('selectedValuables.Id')
    // async selectedValuablesIdChanged(val: Number, oldVal: Number) {
    //     var index = this.valuablesList.indexOf(this.selectedValuables);
    //     if (index < 0 && this.selectedValuables.Id > 0) {
    //         this.valuablesList.push(this.selectedValuables);
    //         //this.filteredValuablesList.push(this.selectedValuables);
    //     }
    // }
    @Watch('selectedValuables')
    async selectedValuablesChanged(val: AssetService.Valuables, oldVal: AssetService.Valuables) {
        console.log(val);
        if (!oldVal || !Object.keys(oldVal).length) return;

        const existing = this.valuablesList.find(v => v.ValuablesId == val.ValuablesId);
        if (existing){
            const index = this.valuablesList.indexOf(existing);
            this.valuablesList.splice(index, 1, val);
        }

    }
    
    //#endregion Watches

    //#region Computed
    get fontPct() {
        return this.showValuablesEditView ? '75%' : undefined;
    }
    //#endregion Computed


    //#region Methods

    createdValuable(valuable: AssetService.Valuables){
        this.valuablesList.push(valuable);
    }
    async fetchValuables () {
        this.loading = true;
        const params = {} as AssetService.GetValuablesParameters;
        params.Location = this.selectedLocation ? this.selectedLocation : null;
        params.CategoryId = this.selectedCategoryId ? this.selectedCategoryId : null;
        this.valuablesList = await this._assetService.GetValuables(params);                
        this.filteredValuablesList = this.valuablesList;
        this.loading = false;
    }
    async fetchLocations() {
        const params = {} as AssetService.GetValuablesLocationsParameters;
        params.CategoryId = this.selectedCategoryId;
        this.locations = await this._assetService.GetValuablesLocations(params);
    }
    async fetchCategories() {
        this.valuablesCategories = await this._assetService.GetValuablesCategories();
    }

    async editValuablesListItem(selectedRow, event, column) {
        if (selectedRow && selectedRow != null) {
            this.showValuablesEditView = false; //this should reset the components, once it is set to true again in the NextTick
            await this.$nextTick();
            this.selectedValuables = selectedRow;
            this.showValuablesEditView = true;
        }
    }
    tableRowClassName({ row, rowIndex }) {
        //1/23/18 this is not currently working, but leaving it in.  The class gets assigned, but doesn't do anything.
        if (row.HasError) {
            return 'warning-row';
        }
        return '';
    }
    async handleNewValuables() {
        this.showValuablesEditView = false; //this should reset the components, once it is set to true again in the NextTick
        await this.$nextTick();
        this.selectedValuables = {} as AssetService.Valuables;
        this.selectedValuables.Id = -1;
        this.selectedValuables.ValuablesId = -1;
        this.selectedValuables.CategoryId = this.selectedCategoryId;
        this.showValuablesEditView = true;
    }
    formatValuablesCurrency(row, column) {
        if (!this.showValuablesEditView) {
            return this.formatters.formatterCurrency0(row, column);
        }
        else if (row[column.property] == null || row[column.property] == '' || row[column.property] == undefined) {
            return '';
        }
        else {
            return numbro(this._toNumber(row[column.property])).formatCurrency();
        }
    }
}
