
    import { Component, Vue, Prop, Watch, Ref, VModel } from "vue-property-decorator";
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import ConsiderationList from '@/views/InvestmentTransaction/Consideration/ConsiderationList.vue';
    import EquityList from '@/views/InvestmentTransaction/Equity/EquityList.vue'
    import * as AssetService from '@/services/DAL/assetService';
    import InvestmentSelector from '@/components/form/InvestmentSelector.vue';
    import cloneDeep from 'lodash/cloneDeep';
    import formatters from "@/utils/formatters";
    import Common from '@/utils/common';

    import EquityTrancheConsumer from '@/views/InvestmentTransaction/Equity/EquityTrancheConsumer.vue';

    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;
    declare var SmartObject: any;

    interface equityTransactionInvestment extends TransactionsService.TransactionInvestments {
        isNew: boolean,
        equityTransactionTranches: TransactionsService.EquityTransactionTranches[],
    }
    @Component({
        components: {
            ConsiderationList,
            InvestmentSelector,
            EquityTrancheConsumer,
            EquityList
        }
    })
    export default class EquityTransactionInvestment extends Vue {
    @Ref() readonly refConsiderationList!: ConsiderationList;

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private _assetService: AssetService.AssetService;
    private formatters: formatters;
    private common: Common;

    //#endregion Private declarations for Services

    //#region Props
    @VModel({ type: Object }) equityTransactionInvestment!: equityTransactionInvestment;
    @Prop( { type: Object, required: true }) header: TransactionsService.Header;
    //#endregion Props

    //#region Data
    loading = false;
    securityLevel_: number = null;
    
    baseInvestment = {} as AssetService.InvestmentList;
    selectedCounterInvestment = {} as AssetService.InvestmentList;
    
    header_ = {} as TransactionsService.Header;
    
    
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this._assetService = new AssetService.AssetService();
        this.common = new Common();

        this.formatters = new formatters();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
        this.header_ = cloneDeep(this.header)
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('header')
    async headerChanged(val: TransactionsService.Header, oldVal: TransactionsService.Header) {
        this.header_ = cloneDeep(this.header);
    }
    
    //#endregion Watches

    //#region Computed
    get operation(): string{
        if (this.header_){
            return this.header_.SubTypeShort;
        }
    }
    get operationDirection(): string{
        if (this.header_){
            return this.header_.SubTypeBaseDirection;
        }
    }
    get isTransferOrSwap(): boolean{
        return (this.operation == 'Transfer' || this.operation == 'Swap') && !(this.header_ && this.header_.SubTypeScope == '3rd Party');
    }

    //#endregion Computed


    //#region Methods
    oppositeDirection (direction: string): string{
        if (direction == 'Result') return 'Source';
        if (direction == 'Source') return 'Result';
    }
     
    investmentSelected(investment: AssetService.InvestmentList){
        if (investment && investment.InvestmentId){
            this.baseInvestment = investment;
            if (
                    (this.isTransferOrSwap && this.selectedCounterInvestment && this.selectedCounterInvestment.Id)
                    || !this.isTransferOrSwap) this.equityTransactionInvestment.isNew = false;
        }
    }
    counterInvestmentSelected(investment: AssetService.InvestmentList){
        if (investment && investment.InvestmentId){
            this.selectedCounterInvestment = investment;
            if (this.baseInvestment && this.baseInvestment.Id) this.equityTransactionInvestment.isNew = false;
        }
    }
    onEquityList(equityList: TransactionsService.Equity[]){
        const tranches: TransactionsService.EquityTransactionTranches[] = equityList.map((equity) => 
            {
                const tranche = new TransactionsService.EquityTransactionTranches();
                tranche.InvestmentId = equity.InvestmentId;
                tranche.DescendantEquityId = equity.EquityId;
                tranche.DescendantTransactionHeaderId = equity.TransactionHeaderId;
                tranche.Date = equity.Date;
                tranche.HoldingEntityId = equity.HoldingEntityId;
                tranche.HoldingEntity = equity.HoldingEntity;
                tranche.Tranche = equity.Tranche;
                tranche.OwnerId = equity.OwnerId;
                // tranche.IsCarryShares = equity.IsCarryShares;
                // tranche.OriginatedQuantity = equity.Quantity;
                tranche.CostPerQuantity = equity.AcquisitionCostPerQuantity;
                tranche.Consumed = equity.Quantity;
                // tranche.AvailableQuantity = equity.Quantity;
                tranche.CurrentConsumptionEquityId = equity.EquityId;
                tranche.CurrentConsumptionQuantity = equity.Quantity;
                tranche.CurrentConsumptionQuantityDisplay = 0; // Assuming no consumption initially
                // tranche.RemainingAvailableQuantity = equity.Quantity; // Assuming all quantity is available initially
                // tranche.AllRemainingAvailableQuantity = equity.Quantity; // Assuming all quantity is available initially
                // tranche.CounterEquityId = equity.EquityId;
                tranche.CurrentConsumptionCost = equity.SaleCostBasis; 
                tranche.CurrentConsumptionCostDisplay =0- equity.SaleCostBasis;
                tranche.CurrentConsumptionValue = equity.Amount
                tranche.CurrentConsumptionValueDisplay = 0-equity.Amount;
                // tranche.FMVShares = 0; // Assuming no FMV shares initially
                // tranche.FMVShareCountAsOf = equity.Date; // Assuming FMV share count as of equity date
                tranche.FMV = equity.FMV;
                tranche.FMVAsOf = equity.FMVAsOf;
                tranche.FMVPerQuantity = equity.FMVPerQuantity;
                tranche.FMVPerQuantityDisplay = equity.FMVPerQuantity; // Assuming FMV per quantity display is same as FMV per quantity
                tranche.Deletable = equity.Deletable;
                tranche.DownstreamRecords = equity.DownstreamRecords;
                
                tranche.CurrentConsumptionFMV = equity.FMV;
                tranche.CurrentConsumptionFMVDisplay = 0-equity.FMV;
                // tranche.ConsumptionGainLoss = 0; // Assuming no gain/loss initially
                // tranche.ConsumptionGainLossDisplay = 0; // Assuming no gain/loss initially
                // tranche.SplitFrom = equity.SplitFrom;
                // tranche.SplitTo = equity.SplitTo;
                // tranche.SplitDisplay = equity.SplitDisplay;
                // tranche.ExchangeToHoldingEntity = equity.HoldingEntity; // Assuming exchange to same holding entity
                // tranche.ExchangeToHoldingEntityId = equity.HoldingEntityId; // Assuming exchange to same holding entity
                // tranche.ResultingQuantity = equity.Quantity; // Assuming resulting quantity is same as original quantity
                // tranche.ExchangeToTranche = equity.Tranche; // Assuming exchange to same tranche
                // tranche.AcquisitionSourceId = equity.AcquisitionSourceId;
                // tranche.AmountMethod = equity.AmountMethod;
                // tranche.PerQuantityHigh = equity.PerQuantityHigh;
                // tranche.PerQuantityLow = equity.PerQuantityLow;
                // tranche.HasOutOfDateData = false; // Assuming data is not out of date initially
                // tranche.OutOfDateIssue = ""; // Assuming no out of date issue initially
                // tranche.ConsumptionFMVPerQuantity = 0; // Assuming no consumption initially
                // tranche.ConsumptionCostBasisPerQuantity = equity.AcquisitionCostPerQuantity; // Assuming cost basis per quantity is same as acquisition cost per quantity
                // tranche.STLT = equity.GainIsShortTerm ? "Short Term" : "Long Term"; // Assuming STLT based on GainIsShortTerm property
                // tranche.AllConsumed = 0; // Assuming no consumption initially
                // tranche.AllAvailableQuantity = equity.Quantity; // Assuming all quantity is available initially
                return tranche;
            });
        this.equityTransactionInvestment.equityTransactionTranches = tranches;

    }
    }
//#endregion Methods

    
