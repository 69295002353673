
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as SystemService from '../../services/DAL/systemService';
import Common from '../../utils/common';

@Component({
    inheritAttrs: false
})
export default class NamedKeySelector extends Vue {
    //#region Private declarations for Services
    private _systemService: SystemService.SystemService;
    private _common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() readonly value: number | string;
    @Prop() readonly keyType: string;
    @Prop() readonly parentKeyType: string;
    @Prop() readonly parentKeyValue: number;
    @Prop() readonly useAbbreviation: boolean;
    @Prop({type: String}) readonly columnForSelectability: string;
    @Prop( {type: Function}) readonly optionFilterFunction: Function;
    
    //#endregion Props

    //#region Data
    namedKeys = [] as SystemService.NamedKeys[];
    selectedValue: number | string = null;
    tryParseBool: Function = tryParseBool;
    //#endregion Data

   //#region Lifecycle
    async created() {
        this._systemService = new SystemService.SystemService();
        this._common = new Common();
    }
    async mounted() {
        this.selectedValue = this.value;
        this.fetchNamedKeys();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number | string, oldVal: number | string) {
        this.selectedValue = val;

        if (this.namedKeys.length) {
            let selectedObject: SystemService.NamedKeys = new SystemService.NamedKeys();
            if (this.selectedValue) {
                selectedObject = this._common.getSelectedArrayItem(
                    this.namedKeys,
                    this.selectedValue.toString(),
                    'KeyValue'
                );
            }
            this.$emit('value-set', selectedObject);
        }
    }
    @Watch('parentKeyValue')
    async onChangeParentKeyValue(val: number, oldVal: number){
        await this.fetchNamedKeys();
    }
    @Watch('parentKeyType')
    async onChangeParentKeyType(val: number, oldVal: number){
        await this.fetchNamedKeys();
    }
    //#endregion Watches

    //#region computed
    get filteredNamedKeys() {
        if (this.optionFilterFunction && typeof this.optionFilterFunction === 'function'){
            return this.namedKeys.filter(namedkey => this.optionFilterFunction(namedkey));
        }
        return this.namedKeys;
    }

    //#endregion computed

    //#region Methods
    async fetchNamedKeys() {
        this.namedKeys = [];

        const parameters = {} as SystemService.GetNamedKeysParameters;
        parameters.KeyType = this.keyType;
        parameters.ParentKeyType = this.parentKeyType;
        parameters.ParentKeyValue = this.parentKeyValue;
        this.namedKeys = await this._systemService.GetNamedKeys(parameters);
        
        this.$emit('options-fetched', this.namedKeys);

        if (this.namedKeys.length === 1){
            this.selectedValue = this.namedKeys[0].KeyValueInt;
            this.$emit('single-option-available', this.selectedValue);
            if (this.selectedValue !== this.value) {
                this.$emit('input', this.selectedValue);
            }
        }
        //emit value-set on initiation, if there is a value.  Won't happen from Watcher
        if (this.value && this.namedKeys && this.namedKeys.length){
            this.$emit('value-set', this.namedKeys.find(nk => nk.KeyValueInt == this.value));
        }
    }
    //#endregion Methods
}
