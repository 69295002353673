
import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop, Model } from "vue-property-decorator";
import * as TransactionsService from "../../services/DAL/transactionsService";
import accounting from 'accounting';

@Component({
    inheritAttrs: false
})
export default class TransactionSelector extends Vue {
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: number;
    @Prop()
    investmentId: number;
    @Prop({
        type: Boolean
        , default: false
    })
    availableForFundingSourceOnly: boolean;
    //#endregion Props

    //#region Data
    transactions = [] as TransactionsService.TransactionsList[];
    selectedValue: number = null;
    isLoading: boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
    }
    async mounted() {
        this.selectedValue = this.value;
        this.fetchEquities();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch("value")
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
    }
    @Watch("investmentId")
    onChange_investmentId(val: number, oldVal: number) {
        this.fetchEquities();
    }

    //#endregion Watches

    //#region Methods
    async fetchEquities() {
        this.transactions = [];
        if (this.investmentId) {
            const params = {} as TransactionsService.GetTransactionsListParameters;
            params.InvestmentId = this.investmentId;
            params.AvailableForFundingSourceOnly = this.availableForFundingSourceOnly;
            this.isLoading = true;
            this.transactions = await this._transactionsService.GetTransactionsList(params);
            this.isLoading = false;
        }
    }

    //#endregion Methods
}
