
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
//import ContactEdit from '@/views/Contact/ContactEdit.vue';
import * as VamService from '../../services/DAL/vamService';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare var $: any;

// 11-04-2021 13:26 CAMPBED: ContactEdit is remarked out for now because it currently only exists as Smart Controls.


@Component({
    inheritAttrs: false,
    // components: {
    //     ContactEdit
    // }
})
export default class ContactSelector extends Vue {
    $refs: {
        ddlContactSelect: ElementUI.Select,
        btnAddContact: ElementUI.Button
    }
    //#region Data
    private _vam_Service: VamService.VamService;

    @Prop() readonly value: number;
    @Prop() readonly entityId: number;
    @Prop() readonly entityType: string;
    @Prop() readonly hasEmailAddress: boolean;
    @Prop({ default: false }) readonly showAddNew: boolean;  

    contacts = [] as VamService.Contacts[];
    selectedValue: number = null;
    showContactEdit: Boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._vam_Service = new VamService.VamService();
    }
    async mounted() {
        this.selectedValue = this.value;
        this.fetchContacts();

        // get actual button width, and reset width of select to fill remaining space next to button
        if (this.showAddNew && this.$refs.btnAddContact && this.$refs.ddlContactSelect) {
            const buttonWidth = $(this.$refs.btnAddContact.$el).outerWidth() || 44;
            $(this.$refs.ddlContactSelect.$el).css('width', 'calc(100% - ' + (buttonWidth + 6) + 'px)');
        }
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
    }
    @Watch('entityId')
    onEntityIdChange(val: number, oldVal: number) {
        this.fetchContacts();
    }
    //#endregion Watches

    //#region Methods
    async fetchContacts() {
        this.contacts = [];
        if (this.entityId && this.entityType){
            const params = {} as VamService.GetContactsParameters;
            params.EntityId = this.entityId;
            params.EntityType = this.entityType;
            const results = await this._vam_Service.GetContacts(params);
            if (this.hasEmailAddress){
                this.contacts = results.filter(item => item.Email.length);  //only if it has an email address
            }
            else this.contacts = results;
            this.$emit('options-fetched', this.contacts);
        }
    }

    async onSaveNewContact(ContactId: number) {
        await this.fetchContacts();
        this.$emit('input', ContactId);
    }
    //#endregion Methods
}
