
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ManagementFeeEdit from '@/views/InvestmentParent/ManagementFeeEdit.vue';
import * as AssetService from './../../services/DAL/assetService';
import formatters from '../../utils/formatters';
import findIndex from 'lodash/findIndex';

declare var $: any;
declare function getContainerType(element: any): string;

@Component({
    components: {
        ManagementFeeEdit
    }
})
export default class ManagementFeeList extends Vue {
    //#region Private Declarations
    private _assetService: AssetService.AssetService;
    private formatters: formatters;
    //#endregion Private Declarations

    //#region Props
    @Prop()
    investmentParentId: number;
    //#endregion Props

    //#region Data
    managementFees: AssetService.ManagementFees[] = [];
    selectedManagementFee: AssetService.ManagementFees = null;
    showManagementFeeEdit = false;
    isLoading = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this.formatters = new formatters();
    }

    async mounted() {
        if (getContainerType($(this.$refs.divManagementFeeList)) === 'SmartPortlet') {
            this.addSmartPortletButtons();
        }
        this.fetchManagementFees();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchManagementFees() {
        this.isLoading = true;
        const parameters = {} as AssetService.GetManagementFeesParameters;
        parameters.InvestmentParentId = this.investmentParentId;
        this.managementFees = await this._assetService.GetManagementFees(parameters);
        this.isLoading = false;
    }

    selectManagementFee(managementFee) {
        if (managementFee) {
            this.showManagementFeeEdit = false;
            this.$nextTick(() => {
                this.showManagementFeeEdit = true;
                this.selectedManagementFee = managementFee;
            });
        }
    }

    closeManagementFee() {
        this.showManagementFeeEdit = false;
        this.selectedManagementFee = null;
    }

    onSaveManagementFee(managementFeeId) {
        this.closeManagementFee();
        this.fetchManagementFees();
    }

    onDeleteManagementFee(managementFeeId) {
        if (
            this.selectedManagementFee &&
            this.selectedManagementFee.Id == managementFeeId
        ) {
            const index = findIndex(this.managementFees, managementFee => {
                return managementFee.Id === this.selectedManagementFee.Id;
            });
            if (index > -1) {
                this.managementFees.splice(index, 1);
            }
        }
        this.closeManagementFee();
    }

    addSmartPortletButtons() {
        const vm = this;
        $(this.$refs.divManagementFeeList).smartPortlet('addButtons', {
            'Add': {
                buttonClass: 'btn-success-stripe'
                , iconClass: 'fa fa-plus'
                , onClick: function() {
                    vm.selectedManagementFee = null;
                    vm.showManagementFeeEdit = true;
                }
            }
        });
    }
    //#endregion Methods
}
