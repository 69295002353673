<template>
    <div id="attachment-list">
        <el-row :gutter="20" type="flex">
            <el-col :span="showStoreSelector ? 4 : 0">
                Store: &nbsp;
                <el-select filterable
                        id="selStore"
                        v-bind:disabled="!storesForDDL>0"
                        v-show="showStoreSelector"
                        size="mini"
                        style="width:100%; max-width:275px;"
                        v-model="storeId">
                    <el-option-group v-for="group in storesForDDL" :key="group.groupId"
                                    :label="group.label">
                        <el-option v-for="item in group.options" :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-col>
            <el-col :span="showStoreSelector ? 2 : 0">
                <el-checkbox size="mini" v-model="unmappedOnly" v-show="showStoreSelector && !entityId">Unmapped Only</el-checkbox>
            </el-col>
            <el-col :span="2">
                <span style="font-size:smaller; " v-loading="isLoading" >Files:{{items.length}}</span>
            </el-col>
            <el-col :span="5">
                <a v-if="!showStoreSelector && storeUrl" :href="storeUrl" target="_blank">Open Store in new tab</a>
            </el-col>
            <el-col :span="6">
                <span style="font-size:smaller" v-loading="isLoading"  >{{message}}</span>
            </el-col>
            <el-col :span="5" :offset="showStoreSelector ? 0 : 6" >
                <el-input v-model="filter" v-if="items[0]" placeholder="filter" size="mini" clearable suffix-icon="el-icon-search" />
            </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" v-if="showUpload">
            <el-col :span="24">
                <span style="color:red" v-if="showUpload && (!storeId)">Show Upload Specified but Store Id not set</span>
                <upload-attachment :store-id="storeId"
                                :parameters="params_"
                                :token="token"
                                :security-level="securityLevel_"
                                v-if="showUpload && !!storeId && storeId > 0 && securityLevel_ >=80"
                                @document-uploaded="documentUploaded"
                                @upload-failed="uploadFailed">
                </upload-attachment>
            </el-col>
        </el-row>
        <el-row :gutter="20" type="flex">
            <el-col :col-span="24">
                <el-table
                          :data="filteredItems"
                          stripe
                          v-if="!!items && items.length"
                          height="calc(100vh - 200px)"
                          row-key="Id"
                          :default-sort="{prop: 'Dated', order: 'descending'}"
                          @cell-click="cellClick"
                          v-loading="isLoading"
                >
                    <el-table-column label="View"
                                     class-name="text-center"
                                     width="55px">
                        <template slot-scope="scope">
                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                        </template>
                    </el-table-column>
                    <el-table-column label="Pin"
                        v-if="showPin"
                        class-name="text-center"
                        width="55px">
                        <template slot-scope="scope">
                            <i class="fa fa-thumb-tack" aria-hidden="true"></i>
                        </template>
                    </el-table-column>
                    <el-table-column label="Maps"
                                     v-if="showMapColumn && HideIfColumnEmpty(filteredItems, 'AttachmentId')"
                                     class-name="text-center"
                                     width="57px">
                        <template slot-scope="scope">
                            <i class="fa fa-chain" aria-hidden="true" v-if="scope.row.AttachmentId"></i>
                            <sup>
                                <span class="badge badge-pill badge-info" style="color: white; font-size: 10px !important;" v-if="scope.row.AttachmentId">{{scope.row.Maps}}</span>
                            </sup>
                        </template>
                    </el-table-column>
                    <el-table-column label="Req"
                                     min-width="70px"
                                     v-if="HideIfColumnEmpty(filteredItems, 'RequiredDocumentType')">
                        <template slot-scope="scope">
                            <i class="fa fa-star" v-if="scope.row.RequiredDocumentType" v-bind:class="(scope.row.RequiredDocumentCount> 1) ? 'red' : 'green'" aria-hidden="true" :title="`Required Type: ${scope.row.Type} ${scope.row.RequiredDocumentCount> 1 ? '(more than 1 of document type found)': ''}`"></i>
                        </template>
                    </el-table-column>
                    <el-table-column label="Description"
                                     prop="Description"
                                     sortable
                                     class-name="overflow"
                                     min-width="400px">
                        <template slot-scope="scope">
                            <span :title="`${scope.row.LastModified} (${scope.row.Id})`">{{scope.row.Description}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Association"
                                     min-width="200px"
                                     sortable
                                     class-name="overflow"
                                     v-if="showAssociation && HideIfColumnEmpty(filteredItems, 'Association')">
                        <template slot-scope="scope">
                            <span :title="scope.row.EntityId">{{scope.row.Association}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Store"
                                     sortable
                                     min-width="150px"
                                     v-if="showStore">
                        <template slot-scope="scope">
                            <span :title="scope.row.StoreId">{{scope.row.Store}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Doc Date"
                                     align="center"
                                     sortable
                                     min-width="100px"
                                     prop="Dated"
                                     v-if="HideIfColumnEmpty(filteredItems, 'Dated')"
                                     :formatter="formatterShortDate">
                    </el-table-column>
                    <el-table-column label="Type"
                                     align="center"
                                     sortable
                                     class-name="overflow"
                                     min-width="150px"
                                     prop="Type">
                        <template slot-scope="scope">
                            <span>{{scope.row.Type}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Entity Type"
                                     min-width="150px"
                                     align="center"
                                     sortable
                                     prop="TypeEntityType">
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
         <el-dialog title="Map Document" :visible.sync="showAttachmentMap" >
            <map-attachment
                v-if="Object.keys(attachmentToMap).length > 0"
                :attachment-id="attachmentToMap.AttachmentId"
                :attachment-file-properties="attachmentToMapFileProperties"
                :security-level="securityLevel_"
                v-on:attachment-mapped="attachmentMapped"
                v-on:number-of-maps-changed="numberOfMapsChanged"
                v-on:edit-attachment="editAttachment"
                ref="refAttachmentMap">
            </map-attachment>
         </el-dialog>
        <el-dialog title="Edit Attachment" :visible.sync="showAttachmentEdit" >
            <attachment-edit
                v-if="Object.keys(attachmentToMap).length > 0"
                :file-id="attachmentToMap.FileId"
                :key="attachmentToMap.AttachmentId"
                :attachment-id="attachmentToMap.AttachmentId"
                :security-level="securityLevel_"
                v-on:attachment-saved="attachmentSaved"
                v-on:attachment-deleted="attachmentDeleted"
                :store="attachmentToMapStore"
                ref="refAttachmentEdit">
            </attachment-edit>
        </el-dialog>

    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility'
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';
    import mixinSchema_attachment from './../DAL/mixinSchema_attachment'
    import UploadAttachment from './UploadAttachment.vue'
    import MapAttachment from './MapAttachment.vue'
    import AttachmentEdit from '@/views/Attachments/AttachmentEditV3.vue'
    import mixinAttachmentService from './../VueCommon/mixinAttachmentService.js'

    export default Vue.extend({
        name: 'AttachmentList'
        , mixins: [mixinUtility, mixinSchema_attachment, mixinAttachmentService ]
        , props: {
            params: {
                type: Object
            }
            , paramString: {
                type: String
            }
            , token: {
                type: String
            }
            , showMapColumn: {
                type: Boolean
                , default: true
            }
            , includeChildrenEntityTypes: {
                type: Boolean
                , default: true
            }
            , showEditEdocumentColumn: {
                type: Boolean
                , default: true
            }
            , securityLevel: {
                type: Number
                , default: null
            }
            , showUpload: {
                type: Boolean
                , default: false
            }
            , showPin: {
                type: Boolean
                , default: false
            }

        }
        , data() {
            return {
                params_: {}
                , attachment: {}
                , fileProperties: {}
                , entityId: null
                , entityType: null
                , attachmentTypeId: null
                , items: []
                , filteredItems: []
                , filter: ''
                , stores: []
                , storesForDDL: []
                , storeId: -1
                , showAttachmentMap: false
                , attachmentToMap: {}
                , attachmentToMapStore: {}
                , attachmentToMapFileProperties: {}
                , showAssociation: false
                , showStore: false
                , showAttachmentEdit: false
                , showStoreSelector: false
                , securityLevel_: this.securityLevel
                , isLoading: false
                , message: ''
                , unmappedOnly: false 
                , graphService: {}
                , selectedStore: {}
                , storeUrl: ''
                , storeExpectedChildCount: null
            }
        }
        , components: {
            'upload-attachment': UploadAttachment
            , 'map-attachment': MapAttachment
            , 'attachment-edit': AttachmentEdit
        }
        , created: async function () {
            this.graphService = new MicrosoftGraphService();
            this.initialize();
        }
        , computed: {
        }
        , mounted: function () {
        }
        , watch: {
            'filter': function(val, oldVal){
                this.filteredItems = this.items.filter(function(e) {
                    return e.Description.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.Type.toLowerCase().indexOf(val.toLowerCase()) !== -1
                        || e.TypeEntityType.toLowerCase().indexOf(val.toLowerCase()) !== -1;
                });
            }
            , 'storeId': async function(val, oldVal){
                await this.setStore();
                this.fetchAttachments();
            }
            , 'params.entityType': function(val, oldVal){
                this.initialize();
            }
            , 'params.entityId': function(val, oldVal){
                this.initialize();
            }
            , 'params.storeCategoryId': function(val, oldVal){
                this.initialize();
            }
            , 'params.clientId': function(val, oldVal){
                this.initialize();
            }
            , 'unmappedOnly': function(val, oldVal){
                this.fetchAttachments();
            }
        }
        , methods: {
            async initialize(){
                if (this.params && Object.keys(this.params).length) {
                    this.params_ = this.params;
                }
                else if (this.paramString) {
                    this.params_ = JSON.parse(this.paramString);
                }
                
                if (this.securityLevel_ === null) {
                    this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDocuments), 0);
                }

                if (this.params_) {
                    this.entityId = (this.params_.entityId) ? this.params_.entityId : this.entityId;
                    this.entityType = (this.params_.entityType) ? this.params_.entityType : this.entityType;
                    this.attachmentTypeId = (this.params_.attachmentTypeId) ? this.params_.attachmentTypeId : this.attachmentTypeId;
                    this.showStoreSelector = (this.params_.showStoreSelector) ? this.params_.showStoreSelector : this.showStoreSelector;
                    this.storeId = (this.params_.storeId) ? this.params_.storeId : this.storeId;
                }
                //get stores whether the selector is shown or not because we need the data set to send the store to Attachment Edit
                try {
                    this.stores = await this.attachment_GetStores({
                            ClientId: this.params_.clientId, // either/both parameters might no undefined
                            StoreCategoryId: this.params_.storeCategoryId,
                            StoreId: this.storeId
                        });
                    if (this.stores.length == 0){
                        throw new Error(`No store configured for Client Id ${this.params_.clientId}, Store Category Id ${this.params_.storeCategoryId},  Contact Administrator.`);
                    }
                    this.storesForDDL = this.getDDLOptionsWithGroup(this.stores, 'Type', 'Type', 'Name', 'Id');
                    this.storesForDDL.push({
                        groupId: 'Recent'
                        , label: 'Recent'
                        , options: [{
                            label: 'Last 50'
                            , value: -1
                        }]
                    });
                    if (this.$objectPropertyIfExists(this.params, 'storeCategoryId')){ 
                        this.storeId = this.stores[0].Id;
                    }
                }
                catch(err){
                    this.$notify.error(err.message);
                }
                await this.fetchAttachments();
            }
            , async setStore(){
                this.selectedStore = {};
                this.storeUrl = '';
                this.storeExpectedChildCount = null;
                if (this.storeId && this.stores && this.stores.length){
                    this.selectedStore = this.getSelectedArrayItem(this.stores, this.storeId, 'Id');
                    if (this.showStoreSelector){ //not going to show the link unless also showing the store selector, so no need to get the link in the first place
                        const graphClient = await this.graphService.getGraphClient();
                        const storeProperties = await graphClient.api(`drives/${this.selectedStore.DriveId}/items/${this.selectedStore.ItemId}`).get();
                        if (storeProperties){
                            this.storeUrl = storeProperties.webUrl;
                            this.storeExpectedChildCount = storeProperties.folder ? storeProperties.folder.childCount : null
                            console.log('Expected children from graph:', this.storeExpectedChildCount);
                        }
                    }
                }
            }
            , fetchAttachments: async function () {
                this.isLoading = true;
                this.items = [];

                if (this.includeChildrenEntityTypes && this.entityId && this.entityType ){
                    try {
                        this.message = `Getting Files ${this.entityType} ${this.entityId}`;
                        this.items = await this.attachment_GetMapsForEntity({
                            EntityType: this.entityType
                            , EntityId: this.entityId
                            });
                        this.filteredItems = this.items;
                        this.showAssociation= true;
                    }
                    catch(error) {
                        console.error(error);
                    }
                } else if (this.storeId == -1) { //Recent
                    try {
                        this.message = 'Getting Recent Files'
                        this.items = await this.attachment_GetRecentAttachments({
                            UnmappedOnly: this.unmappedOnly
                        });
                        this.filteredItems = this.items;
                        this.showAssociation= false;
                        this.showStore = true;
                    }
                    catch(error) {
                        console.error(error);
                    }
                } else {
                    try {
                        const storeName = this.storeId ? (await this.getStore(this.storeId)).Name : '';
                        this.message = `Getting Files ${storeName ? ' For ' + storeName : ''}`
                        this.items = await this.attachment_GetAttachments({
                            AttachmentTypeId: this.attachmentTypeId
                            , EntityType: this.entityType
                            , EntityId: this.entityId
                            , StoreId: this.storeId
                            , UnmappedOnly: this.unmappedOnly
                        });
                        this.filteredItems = this.items;
                        this.showAssociation = false;
                        this.showStore = false;
                    }
                    catch(error) {
                        console.error(error);
                    }
                }
                this.message = '';
                this.isLoading = false;
            }
            , pinDocument(selectedRow){
                this.$emit('pin-document', {path: selectedRow.DriveItemsPath + selectedRow.FileId, description: selectedRow.Description})
            }
            , cellClick: async function(selectedRow, column, cell){
                if (column.label == 'Pin' ){
                    this.pinDocument(selectedRow);
                    return;
                }
                if (column.label == 'View' && selectedRow.UseExternalDocumentLinks){
                    this.getExternalDocument(selectedRow);
                    return;
                }
                let fileProperties= {};
                try{
                    const graphClient = await this.graphService.getGraphClient();
                    fileProperties = await graphClient.api(selectedRow.DriveItemsPath + selectedRow.FileId).get();
                }
                catch (err) {
                    console.log(err);
                    console.log('Document:', selectedRow);
                    if (err.statusCode == 404){
                        this.$notify.error('File does not exist (404)');
                    }
                    if (err.statusCode == 403){
                        this.$notify.error('Access Denied (403)');
                    }
                    else {
                        this.$notify.error(err.message|| 'error loading file');
                    }
                }
                if (!fileProperties || !Object.keys(fileProperties).length){
                    console.log('No FileProperties', fileProperties);
                    return;
                }
                if (column.label == 'View'){
                    //console.log(fileProperties);
                    window.open(fileProperties.webUrl, '_blank');
                    if (fileProperties.webUrl.indexOf('.msg') > 0){
                        this.$notify.success('Email file downloaded.  Click downloaded file to open.');

                    }
                    else {
                        this.$notify.success('File opened in new tab');
                    }
                }
                else if (!!selectedRow && Object.keys(selectedRow).length && selectedRow.AttachmentId && this.securityLevel_ >= 80) /*if (column.label == 'Map')*/{ //any other column will edit mapping, as long as there is an AttachmentId (there won't be for Invoices)
                    this.attachmentToMap = selectedRow;
                    this.attachmentToMapStore = await this.getStore(selectedRow.StoreId);
                    this.attachmentToMapFileProperties = fileProperties;
                    this.$nextTick(function () {
                        if (this.$refs.refAttachmentMap) {//mounted will handle it the first time, when refs won't exist
                            this.$refs.refAttachmentMap.initialize();
                        }
                        this.showAttachmentMap = true;
                    });
                }
            }
            , async getExternalDocument(selectedRow){
                this.isLoading = true;
                this.$setSystemStatus('Getting External Link');
                const externalLink = await this.getExternalLinkForEntity('AttachmentMap', selectedRow.MapId);
                console.log(externalLink);
                //note that this is using webUrl vs. @microsoft.graph.downloadUrl.  If ever a problem, look at TripInvoiceView and the use of externalLink.downloadUrl, which is manually added by the server.
                if (!!externalLink && externalLink.data && externalLink.data.driveItem && externalLink.data.driveItem.webUrl){
                    window.open(externalLink.data.driveItem.webUrl, '_blank');
                        if (externalLink.data.driveItem.webUrl.indexOf('.msg') > 0){
                            this.$notify.success('Email file downloaded.  Click downloaded file to open.');
                        }
                        else {
                            this.$notify.success('File opened in new tab');
                        }
                }
                else {
                    this.$alert(externalLink.data.message, 'External File');
                }
                this.$setSystemStatus('');
                this.isLoading = false;
            }
            , attachmentMapped: function(mapsAdded, totalNumberOfMaps){
                console.log('mapped');
                this.showAttachmentMap = false;
                this.attachmentToMapFileProperties = {};
            }
            , numberOfMapsChanged: function(attachmentId, numberOfMaps){
                var index = this.items.indexOf(this.getSelectedArrayItem(this.items, attachmentId, 'AttachmentId'));
                if (index>-1){
                    this.items[index].Maps = numberOfMaps;
                }
            }
            , attachmentSaved: function () {
                this.showAttachmentEdit = false;
                if (this.showAttachmentMap) {//if we were editing from the mapping, re-initialize so that if the client has changed, we get new mapping options.
                    this.$nextTick(function () {
                        if (this.$refs.refAttachmentMap) {//mounted will handle it the first time, when refs won't exist
                            this.$refs.refAttachmentMap.initialize();
                        }
                    });
                }
                this.fetchAttachments();

            }
            , editAttachment: function () {
                if (this.$refs.refAttachmentEdit) {
                    this.$refs.refAttachmentEdit.fetchAttachment();
                }
                this.showAttachmentEdit = true;
            }
            , attachmentDeleted: function(attachment) {
                this.showAttachmentEdit = false;
                this.showAttachmentMap = false;
                var index = this.items.indexOf(this.getSelectedArrayItem(this.items, attachment.Id, 'AttachmentId'));
                if (index > -1) {
                    this.items.splice(index, 1);
                }
                index = this.filteredItems.indexOf(this.getSelectedArrayItem(this.filteredItems, attachment.Id, 'AttachmentId'));
                if (index > -1) {
                    this.filteredItems.splice(index, 1);
                }
            }
            , getStore: async function (storeId) {
                const stores = await this.attachment_GetStores({
                    StoreId: storeId
                });
                if (!!stores && !!stores.length){
                    return stores[0];
                }
                else return undefined;
            }
            , documentUploaded: async function (documentResponse) {
                if (!documentResponse || !documentResponse.attachment || !documentResponse.attachment.AttachmentId){
                    console.error(documentResponse);
                    this.$alert('Incomplete Document Response.');
                }
                else if (!this.params_ || !this.params_.entityId || !this.params_.entityType){
                    console.error(this.params_);
                    this.$alert('Incomplete Entity Information.');
                }
                else {
                    var map = {
                        AttachmentId: documentResponse.attachment.AttachmentId
                        , EntityId: this.params_.entityId
                        , EntityType: this.params_.entityType
                    }
                    let attachmentMapId = null;
                    try {
                        attachmentMapId = await new SmartObject('AttachmentMap').createObject(map);
                    } 
                    catch (err){
                        console.log(err);
                        if (!!err.responseText && err.responseText.toLowerCase().indexOf('duplicate key') > -1) {
                            this.$alert('Document is already mapped to entity.');
                            return;
                        }
                        else {
                            this.$alert(err.responseText);
                            return;
                        }
                    }
                    if (!!attachmentMapId > 0) {
                        //we don't have enough info to directly push onto our stack, so we have to get a fresh stack from the db.
                        //but we want to keep the order, and add our new one to the top, so we'll just get the fresh stack to a temp var, and pull out the new one, then push that.
                        const maps = await this.attachment_GetMapsForEntity({
                            EntityType: this.entityType
                            , EntityId: this.entityId
                            });
                        var newMap = this.getSelectedArrayItem(maps, attachmentMapId, 'MapId');
                        this.items.unshift(newMap);  //unshift = "push" but adds to beginning of array
                    }
                    this.$emit('document-uploaded', documentResponse);

                    this.$notify.success('Document Uploaded');
                }
            }
            , uploadFailed: function (error) {
                this.$notify.error('Upload Failed');
            }
        }
    })
</script>
<style >
    i.red{
        color: red
    }
    i.green {
        color: green
    }
</style>