
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FormattedInput from '@/components/form/FormattedInput.vue';
import * as VamService from '../../services/DAL/vamService';
import * as TaxService from '../../services/DAL/taxService';
import _ from 'lodash';

declare var SmartObject: any;

@Component({
  components: {
    FormattedInput
  }
})
export default class OwnerCategoryStateList extends Vue {
  //#region Data
  private _vam_Service: VamService.VamService;
  private _tax_Service: TaxService.TaxService;

  @Prop() ownerCategoryId: number;

  newOwnerCategoryState: TaxService.OwnerCategoryStates = {
    Id: null,
    OwnerCategoryId: this.ownerCategoryId,
    OwnerCategoryName: null,
    StateId: null,
    StateName: null,
    StateAbbreviation: null,
    ReportRequirement: null,
    FilingFee: null,
    Frequency: null,
    Deadline: null
  };
  ownerCategoryStates = [];
  states = [];
  showNewRow: boolean = false;
  showIsMissingState: boolean = false;

  get isNew(): boolean {
    return !this.ownerCategoryId || this.ownerCategoryId == -1;
  }
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._vam_Service = new VamService.VamService();
    this._tax_Service = new TaxService.TaxService();
    this.fetchOwnerCategoryStates();
    this.fetchStates();
  }
  //#endregion Lifecycle

  //#region Methods
  async fetchOwnerCategoryStates() {
    this.ownerCategoryStates = [];
    if (this.ownerCategoryId > 0) {
      const getOwnerCategoryStatesParameters = {} as TaxService.GetOwnerCategoryStatesParameters;
      getOwnerCategoryStatesParameters.OwnerCategoryId = this.ownerCategoryId;
      this.ownerCategoryStates = await this._tax_Service.GetOwnerCategoryStates(
        getOwnerCategoryStatesParameters
      );
    }
  }

  async fetchStates() {
    this.states = [];
    const getStatesParameters = {} as VamService.GetStatesParameters;
    getStatesParameters.CountryId = 226; // US
    this.states = await this._vam_Service.GetStates(getStatesParameters);
  }

  async saveChangesOwnerCategoryState(ownerCategoryState) {
    if (ownerCategoryState.StateId) {
      await new SmartObject(
        'OwnerCategoryState',
        ownerCategoryState.Id
      ).createObject(ownerCategoryState);

      this.$notify.success('Changes saved.');
    }
  }

  async deleteOwnerCategoryState(ownerCategoryState) {
    if (!this.isNew) {
      try {
        await this.$confirm(
          'Are you sure you want to remove this state assignment?',
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        try {
          await new SmartObject(
            'OwnerCategoryState',
            ownerCategoryState.Id
          ).deleteObject();

          this.$notify.success('State assignment removed.');

          const indexOfDeletedRecord = _.findIndex(
            this.ownerCategoryStates,
            ownerCategoryState_ => {
              return ownerCategoryState_.Id === ownerCategoryState.Id;
            }
          );
          this.ownerCategoryStates.splice(indexOfDeletedRecord, 1);
        } catch {
          this.$notify.error('Something went wrong processing your request, please try again.');
        }
      } catch {}
    }
  }

  async saveNewOwnerCategoryState() {
    if (this.newOwnerCategoryState.StateId) {
      await new SmartObject('OwnerCategoryState').createObject(
        this.newOwnerCategoryState
      );

      this.$notify.success('State assignment added.');

      this.fetchOwnerCategoryStates();
      this.resetNewOwnerCategoryState();
      this.showNewRow = false;
      this.showIsMissingState = false;
    } else {
      this.showIsMissingState = true;
    }
  }

  cancelNewOwnerCategoryState() {
    this.showNewRow = false;
    this.showIsMissingState = false;
    this.resetNewOwnerCategoryState();
  }

  resetNewOwnerCategoryState() {
    this.newOwnerCategoryState = {
      Id: null,
      OwnerCategoryId: this.ownerCategoryId,
      OwnerCategoryName: null,
      StateId: null,
      StateName: null,
      StateAbbreviation: null,
      ReportRequirement: null,
      FilingFee: null,
      Frequency: null,
      Deadline: null
    };
  }
  //#endregion Methods
}
