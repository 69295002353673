
import { Component, Vue, Watch, Prop, VModel, Ref } from 'vue-property-decorator';
import TripLegPassengerList from "@/views/Aviation/TripLegPassengerList.vue";
import CompanyAndPurposeEdit from "@/views/Aviation/CompanyAndPurposeEdit.vue";
import * as AvService from './../../services/DAL/avService';

declare var SmartObject: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
    components: {
        TripLegPassengerList,
        CompanyAndPurposeEdit
    }
})
export default class TripLegEdit extends Vue {
    //#region Data
    private _avService: AvService.AvService;
    
    @Prop({type: Object}) tripLeg: AvService.TripLegs; 
    @Prop({type: Number}) tripLegId: number; 
    @Prop() viewType: string;
    @Prop({ default: true }) autoSave: boolean;
    @Prop() onClose: Function;
    //#endregion Data
    
    securityLevel_: number = null;
    countOfCompaniesPurposes: number = 0;
    passengers = [] as AvService.TripLegManifest[];
    //#region Lifecycle
    async created() {
        this._avService = new AvService.AvService();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAviationDataImport),
            0
        );
        if (!this.tripLeg || Object.keys(this.tripLeg).length == 0){
            //remove this after dev.  Can't mutate the prop
            this.getTripLeg()
        }
    }
    //#endregion Lifecycle

    //#region Computed
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    get showGiftOption(): boolean {
        const base: boolean = this.tripLeg && Object.keys(this.tripLeg).length && this.tripLeg.Passengers > 0
        
        if (base && this.tripLeg && this.tripLeg.IsGift) return true;
        else 
        return (base 
            && !(this.passengers && this.passengers.some(passenger => {return passenger.IsBusinessTravel})) // not if any business
            && !(this.countOfCompaniesPurposes > 0) //not if there are already Company/Purpose records.
        )
    }

    get allPersonal(): boolean {
        return (this.passengers && this.passengers.every(passenger => {return !passenger.IsBusinessTravel}));
    }
    //#endregion Computed

    //#region Methods
    clearFormDirty() {
        Object.keys((this as any).veeFields).forEach(
            key => ((this as any).veeFields[key].dirty = false)
        );
    }

    async getTripLeg(){ //remove this after dev.  Can't mutate the prop
        if (this.tripLegId){
            const params = {} as AvService.GetTripLegsParameters;
            params.TripLegId = this.tripLegId;
            const legs = await this._avService.GetTripLegs(params);
            if (legs && legs.length ==1){
                this.tripLeg = legs[0];
            }
        }
    }

    async updateAttribute(fieldName, value) {
        if (!this.autoSave) {
            return;
        }
        
        var tl = new SmartObject('TripLeg', this.tripLeg.LegId);
        try {
            await tl.updateAttribute(fieldName, value);

            this.$notify.success('Changes saved.');

            // this.clearFormDirty();
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }

    async onClose_() {
        if (typeof this.onClose === 'function') {
            this.onClose();
        }
    }
    async clearLegacyReason(){

        this.tripLeg.Reason = null;
        this.updateAttribute('Reason', null);
    }
    // cpeInput(event){
    //     console.log(event);
    //     console.log(JSON.stringify(event));
    // }
    //#endregion Methods
}
