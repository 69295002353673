//js API for Schema asset
//Auto-generated 07/24/23 12:36
 

var mixinSchema_asset = {
    methods: {
        /** @description asset.DeleteInvestment
         * @param {number} investmentId - 
         */
		 asset_DeleteInvestment: function (investmentId) {
			/*
				this.DeleteInvestment = this.asset_DeleteInvestment(
					null // investmentId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.DeleteInvestment'
			  , params: {
					InvestmentId: investmentId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get AccountTypes using asset.GetAccountTypes
         * @param {number} clientId - 
         * @param {number} investmentTypeId - 
         */
		, asset_GetAccountTypes: function (params, onSuccess, onError, async) {
			/*
				this.AccountTypes = await this.asset_GetAccountTypes({
					ClientId: clientId
					, InvestmentTypeId: investmentTypeId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetAccountTypes'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get CapitalCallSchedule using asset.GetCapitalCallSchedule
         * @param {number} investmentParentId - 
         */
		, asset_GetCapitalCallSchedule: function (investmentParentId) {
			/*
				this.CapitalCallSchedule = this.asset_GetCapitalCallSchedule(
					null // investmentParentId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetCapitalCallSchedule'
			  , params: {
					InvestmentParentId: investmentParentId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get CommitmentAnalysis using asset.GetCommitmentAnalysis
         * @param {number} investmentId - 
         * @param {date} asOf - 
         * @param {number} excludeTransactionHeaderId - 
         */
		, asset_GetCommitmentAnalysis: function (params, onSuccess, onError, async) {
			/*
				this.CommitmentAnalysis = await this.asset_GetCommitmentAnalysis({
					InvestmentId: investmentId
					, AsOf: asOf
					, ExcludeTransactionHeaderId: excludeTransactionHeaderId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetCommitmentAnalysis'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get CostBasisMethods using asset.GetCostBasisMethods
         */
		, asset_GetCostBasisMethods: function (params, onSuccess, onError, async) {
			/*
				this.CostBasisMethods = await this.asset_GetCostBasisMethods({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetCostBasisMethods'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get DividendSummary using asset.GetDividendSummary
         * @param {number} clientId - 
         * @param {boolean} regularOnly - 
         */
		, asset_GetDividendSummary: function (params, onSuccess, onError, async) {
			/*
				this.DividendSummary = await this.asset_GetDividendSummary({
					ClientId: clientId
					, RegularOnly: regularOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetDividendSummary'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get FeePayingCommitments using asset.GetFeePayingCommitments
         * @param {number} investmentParentId - 
         */
		, asset_GetFeePayingCommitments: function (investmentParentId) {
			/*
				this.FeePayingCommitments = this.asset_GetFeePayingCommitments(
					null // investmentParentId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetFeePayingCommitments'
			  , params: {
					InvestmentParentId: investmentParentId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get FixedIncome using asset.GetFixedIncome
         * @param {number} clientId - 
         * @param {number} ownerId - 
         * @param {number} fixedIncomeId - 
         * @param {boolean} currentOnly - 
         */
		, asset_GetFixedIncome: function (params, onSuccess, onError, async) {
			/*
				this.FixedIncome = await this.asset_GetFixedIncome({
					ClientId: clientId
					, OwnerId: ownerId
					, FixedIncomeId: fixedIncomeId
					, CurrentOnly: currentOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetFixedIncome'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get FundFamilies using asset.GetFundFamilies
         */
		, asset_GetFundFamilies: function (params, onSuccess, onError, async) {
			/*
				this.FundFamilies = await this.asset_GetFundFamilies({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetFundFamilies'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get FundHoldingNames using asset.GetFundHoldingNames
         * @param {number} clientId - 
         */
		, asset_GetFundHoldingNames: function (params, onSuccess, onError, async) {
			/*
				this.FundHoldingNames = await this.asset_GetFundHoldingNames({
					ClientId: clientId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetFundHoldingNames'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get FundHoldings using asset.GetFundHoldings
         * @param {number} fundId - 
         * @param {number} clientId - 
         * @param {date} asOf - 
         */
		, asset_GetFundHoldings: function (params, onSuccess, onError, async) {
			/*
				this.FundHoldings = await this.asset_GetFundHoldings({
					FundId: fundId
					, ClientId: clientId
					, AsOf: asOf
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetFundHoldings'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get FundHoldingsWithTranches using asset.GetFundHoldingsWithTranches
         * @param {number} fundId - 
         * @param {number} clientId - 
         * @param {date} asOf - 
         * @param {string} orderBy - 
         * @param {number} holdingId - 
         */
		, asset_GetFundHoldingsWithTranches: function (params, onSuccess, onError, async) {
			/*
				this.FundHoldingsWithTranches = await this.asset_GetFundHoldingsWithTranches({
					FundId: fundId
					, ClientId: clientId
					, AsOf: asOf
					, OrderBy: orderBy
					, HoldingId: holdingId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetFundHoldingsWithTranches'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get FundingSource using asset.GetFundingSource
         * @param {number} transactionTypeId - 
         * @param {number} purchasingInvestmentId - 
         */
		, asset_GetFundingSource: function (transactionTypeId, purchasingInvestmentId) {
			/*
				this.FundingSource = this.asset_GetFundingSource(
					null // transactionTypeId
					, null // purchasingInvestmentId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetFundingSource'
			  , params: {
					TransactionTypeId: transactionTypeId
					, PurchasingInvestmentId: purchasingInvestmentId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get FundingSourceAnnuity using asset.GetFundingSourceAnnuity
         * @param {number} investmentId - 
         */
		, asset_GetFundingSourceAnnuity: function (investmentId) {
			/*
				this.FundingSourceAnnuity = this.asset_GetFundingSourceAnnuity(
					null // investmentId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetFundingSourceAnnuity'
			  , params: {
					InvestmentId: investmentId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get FundingSourcePromissoryNote using asset.GetFundingSourcePromissoryNote
         * @param {number} investmentId - 
         */
		, asset_GetFundingSourcePromissoryNote: function (investmentId) {
			/*
				this.FundingSourcePromissoryNote = this.asset_GetFundingSourcePromissoryNote(
					null // investmentId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetFundingSourcePromissoryNote'
			  , params: {
					InvestmentId: investmentId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get FundingSourcePublicEquity using asset.GetFundingSourcePublicEquity
         * @param {number} investmentId - 
         */
		, asset_GetFundingSourcePublicEquity: function (investmentId) {
			/*
				this.FundingSourcePublicEquity = this.asset_GetFundingSourcePublicEquity(
					null // investmentId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetFundingSourcePublicEquity'
			  , params: {
					InvestmentId: investmentId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get FundingSourceTypes using asset.GetFundingSourceTypes
         * @param {number} transactionTypeId - 
         * @param {number} newInvestmentId - 
         */
		, asset_GetFundingSourceTypes: function (params, onSuccess, onError, async) {
			/*
				this.FundingSourceTypes = await this.asset_GetFundingSourceTypes({
					TransactionTypeId: transactionTypeId
					, NewInvestmentId: newInvestmentId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetFundingSourceTypes'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get FundList using asset.GetFundList
         * @param {number} clientId - 
         * @param {boolean} includeDirect - 
         */
		, asset_GetFundList: function (params, onSuccess, onError, async) {
			/*
				this.FundList = await this.asset_GetFundList({
					ClientId: clientId
					, IncludeDirect: includeDirect
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetFundList'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get FundOverview using asset.GetFundOverview
         * @param {number} clientId - 
         * @param {date} asOf - 
         * @param {number} fundId - 
         */
		, asset_GetFundOverview: function (params, onSuccess, onError, async) {
			/*
				this.FundOverview = await this.asset_GetFundOverview({
					ClientId: clientId
					, AsOf: asOf
					, FundId: fundId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetFundOverview'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get GeneralAssetList using asset.GetGeneralAssetList
         * @param {number} generalAssetId - 
         * @param {number} clientId - 
         * @param {number} assetClassId - 
         * @param {number} ownerId - 
         */
		, asset_GetGeneralAssetList: function (generalAssetId, clientId, assetClassId, ownerId) {
			/*
				this.GeneralAssetList = this.asset_GetGeneralAssetList(
					null // generalAssetId
					, null // clientId
					, null // assetClassId
					, null // ownerId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetGeneralAssetList'
			  , params: {
					GeneralAssetId: generalAssetId
					, ClientId: clientId
					, AssetClassId: assetClassId
					, OwnerId: ownerId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get GeneralPartnerPoints using asset.GetGeneralPartnerPoints
         * @param {number} investmentParentId - 
         */
		, asset_GetGeneralPartnerPoints: function (investmentParentId) {
			/*
				this.GeneralPartnerPoints = this.asset_GetGeneralPartnerPoints(
					null // investmentParentId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetGeneralPartnerPoints'
			  , params: {
					InvestmentParentId: investmentParentId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get HoldingEntities using asset.GetHoldingEntities
         * @param {number} clientId - 
         */
		, asset_GetHoldingEntities: function (params, onSuccess, onError, async) {
			/*
				this.HoldingEntities = await this.asset_GetHoldingEntities({
					ClientId: clientId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetHoldingEntities'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ImportFundLookThrough using asset.GetImportFundLookThrough
         * @param {number} importLogId - 
         */
		, asset_GetImportFundLookThrough: function (params, onSuccess, onError, async) {
			/*
				this.ImportFundLookThrough = await this.asset_GetImportFundLookThrough({
					ImportLogId: importLogId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetImportFundLookThrough'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get InvestmentCashActivitySummary using asset.GetInvestmentCashActivitySummary
         * @param {date} startDate - 
         * @param {date} endDate - 
         * @param {number} clientId - 
         */
		, asset_GetInvestmentCashActivitySummary: function (params, onSuccess, onError, async) {
			/*
				this.InvestmentCashActivitySummary = await this.asset_GetInvestmentCashActivitySummary({
					StartDate: startDate
					, EndDate: endDate
					, ClientId: clientId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetInvestmentCashActivitySummary'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get InvestmentList using asset.GetInvestmentList
         * @param {number} clientId - 
         * @param {number} investmentParentId - 
         * @param {number} ownerId - 
         * @param {string} orderBy - 
         * @param {number} investmentTypeId - 
         * @param {boolean} includeTransferGroupOfSpecifiedInvestmentParentId - 
         * @param {boolean} includeChildrenOfOwner - 
         * @param {number} investmentId - 
         * @param {boolean} currentOnly - 
         * @param {string} considerationEntityType - 
         * @param {boolean} onlyUsingNewTransactionMethodology - 
         * @param {number} excludeOwnerId - 
         * @param {number} excludeInvestmentId - 
         * @param {string} ticker - 
         */
		, asset_GetInvestmentList: function (params, onSuccess, onError, async) {
			/*
				this.InvestmentList = await this.asset_GetInvestmentList({
					ClientId: clientId
					, InvestmentParentId: investmentParentId
					, OwnerId: ownerId
					, OrderBy: orderBy
					, InvestmentTypeId: investmentTypeId
					, IncludeTransferGroupOfSpecifiedInvestmentParentId: includeTransferGroupOfSpecifiedInvestmentParentId
					, IncludeChildrenOfOwner: includeChildrenOfOwner
					, InvestmentId: investmentId
					, CurrentOnly: currentOnly
					, ConsiderationEntityType: considerationEntityType
					, OnlyUsingNewTransactionMethodology: onlyUsingNewTransactionMethodology
					, ExcludeOwnerId: excludeOwnerId
					, ExcludeInvestmentId: excludeInvestmentId
					, Ticker: ticker
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetInvestmentList'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get InvestmentParentBalanceSheetCategories using asset.GetInvestmentParentBalanceSheetCategories
         * @param {number} investmentParentId - 
         * @param {number} clientId - 
         */
		, asset_GetInvestmentParentBalanceSheetCategories: function (investmentParentId, clientId) {
			/*
				this.InvestmentParentBalanceSheetCategories = this.asset_GetInvestmentParentBalanceSheetCategories(
					null // investmentParentId
					, null // clientId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetInvestmentParentBalanceSheetCategories'
			  , params: {
					InvestmentParentId: investmentParentId
					, ClientId: clientId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get InvestmentParentList using asset.GetInvestmentParentList
         * @param {string} clientIdList - 
         * @param {string} ownerIdList - 
         * @param {number} fundFamilyId - 
         * @param {number} investmentTypeId - 
         * @param {string} considerationEntityType - 
         */
		, asset_GetInvestmentParentList: function (params, onSuccess, onError, async) {
			/*
				this.InvestmentParentList = await this.asset_GetInvestmentParentList({
					ClientIdList: clientIdList
					, OwnerIdList: ownerIdList
					, FundFamilyId: fundFamilyId
					, InvestmentTypeId: investmentTypeId
					, ConsiderationEntityType: considerationEntityType
					}
					, true // async (optional)
				);
			*/

		if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length > 0) {
                params.ClientIdList = params.ClientIdList.join(',');
            }
			else if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length == 0) {
				params.ClientIdList = null;
			}
		if (typeof params.OwnerIdList === "object" && params.OwnerIdList != null && params.OwnerIdList.length > 0) {
                params.OwnerIdList = params.OwnerIdList.join(',');
            }
			else if (typeof params.OwnerIdList === "object" && params.OwnerIdList != null && params.OwnerIdList.length == 0) {
				params.OwnerIdList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetInvestmentParentList'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get InvestmentSources using asset.GetInvestmentSources
         * @param {number} clientId - 
         */
		, asset_GetInvestmentSources: function (params, onSuccess, onError, async) {
			/*
				this.InvestmentSources = await this.asset_GetInvestmentSources({
					ClientId: clientId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetInvestmentSources'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get InvestmentStatisticsHedgeFund using asset.GetInvestmentStatisticsHedgeFund
         * @param {number} investmentId - 
         * @param {date} asOf - 
         */
		, asset_GetInvestmentStatisticsHedgeFund: function (investmentId, asOf) {
			/*
				this.InvestmentStatisticsHedgeFund = this.asset_GetInvestmentStatisticsHedgeFund(
					null // investmentId
					, null // asOf
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetInvestmentStatisticsHedgeFund'
			  , params: {
					InvestmentId: investmentId
					, AsOf: asOf
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get InvestmentStatisticsPrivateEquity using asset.GetInvestmentStatisticsPrivateEquity
         * @param {number} investmentId - 
         * @param {date} asOf - 
         */
		, asset_GetInvestmentStatisticsPrivateEquity: function (investmentId, asOf) {
			/*
				this.InvestmentStatisticsPrivateEquity = this.asset_GetInvestmentStatisticsPrivateEquity(
					null // investmentId
					, null // asOf
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetInvestmentStatisticsPrivateEquity'
			  , params: {
					InvestmentId: investmentId
					, AsOf: asOf
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get InvestmentStatisticsSingleCompany using asset.GetInvestmentStatisticsSingleCompany
         * @param {number} investmentId - 
         * @param {date} asOf - 
         */
		, asset_GetInvestmentStatisticsSingleCompany: function (investmentId, asOf) {
			/*
				this.InvestmentStatisticsSingleCompany = this.asset_GetInvestmentStatisticsSingleCompany(
					null // investmentId
					, null // asOf
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetInvestmentStatisticsSingleCompany'
			  , params: {
					InvestmentId: investmentId
					, AsOf: asOf
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get InvestmentTypes using asset.GetInvestmentTypes
         * @param {number} investmentTypeId - 
         */
		, asset_GetInvestmentTypes: function (params, onSuccess, onError, async) {
			/*
				this.InvestmentTypes = await this.asset_GetInvestmentTypes({
					InvestmentTypeId: investmentTypeId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetInvestmentTypes'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get JPMCompImport using asset.GetJPMCompImport
         * @param {number} importLogId - 
         */
		, asset_GetJPMCompImport: function (importLogId) {
			/*
				this.JPMCompImport = this.asset_GetJPMCompImport(
					null // importLogId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetJPMCompImport'
			  , params: {
					ImportLogId: importLogId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get LookThrough using asset.GetLookThrough
         * @param {number} clientId - 
         * @param {date} reportDate - 
         * @param {string} fundIdList - 
         */
		, asset_GetLookThrough: function (clientId, reportDate, fundIdList) {
			/*
				this.LookThrough = this.asset_GetLookThrough(
					null // clientId
					, null // reportDate
					, null // fundIdList
					);
			*/

		if (typeof fundIdList === "object" && fundIdList != null && fundIdList.length > 0) {
                fundIdList = fundIdList.join(',');
            }
			else if (typeof fundIdList === "object" && fundIdList != null && fundIdList.length == 0) {
				fundIdList = null;
			}
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetLookThrough'
			  , params: {
					ClientId: clientId
					, ReportDate: reportDate
					, FundIdList: fundIdList
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ManagementFees using asset.GetManagementFees
         * @param {number} investmentParentId - 
         */
		, asset_GetManagementFees: function (investmentParentId) {
			/*
				this.ManagementFees = this.asset_GetManagementFees(
					null // investmentParentId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetManagementFees'
			  , params: {
					InvestmentParentId: investmentParentId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get OwnerAssets using asset.GetOwnerAssets
         * @param {number} ownerId - 
         */
		, asset_GetOwnerAssets: function (ownerId) {
			/*
				this.OwnerAssets = this.asset_GetOwnerAssets(
					null // ownerId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetOwnerAssets'
			  , params: {
					OwnerId: ownerId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ReceiptsOfAssets using asset.GetReceiptsOfAssets
         * @param {number} logId - 
         * @param {number} clientId - 
         * @param {string} ticker - 
         * @param {boolean} allowMatchToNonAccrual - 
         */
		, asset_GetReceiptsOfAssets: function (params, onSuccess, onError, async) {
			/*
				this.ReceiptsOfAssets = await this.asset_GetReceiptsOfAssets({
					LogId: logId
					, ClientId: clientId
					, Ticker: ticker
					, AllowMatchToNonAccrual: allowMatchToNonAccrual
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetReceiptsOfAssets'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ReturnIncomeType using asset.GetReturnIncomeType
         */
		, asset_GetReturnIncomeType: function (params, onSuccess, onError, async) {
			/*
				this.ReturnIncomeType = await this.asset_GetReturnIncomeType({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetReturnIncomeType'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get StructureForInvestment using asset.GetStructureForInvestment
         * @param {number} investmentId - 
         * @param {string} structureType - 
         * @param {number} transactionId - 
         * @param {date} transactionDate - 
         * @param {number} entityId - 
         */
		, asset_GetStructureForInvestment: function (investmentId, structureType, transactionId, transactionDate, entityId) {
			/*
				this.StructureForInvestment = this.asset_GetStructureForInvestment(
					null // investmentId
					, null // structureType
					, null // transactionId
					, null // transactionDate
					, null // entityId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetStructureForInvestment'
			  , params: {
					InvestmentId: investmentId
					, StructureType: structureType
					, TransactionId: transactionId
					, TransactionDate: transactionDate
					, EntityId: entityId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get StructureTypeForInvestment using asset.GetStructureTypeForInvestment
         * @param {number} investmentId - 
         * @param {string} side - 
         */
		, asset_GetStructureTypeForInvestment: function (investmentId, side) {
			/*
				this.StructureTypeForInvestment = this.asset_GetStructureTypeForInvestment(
					null // investmentId
					, null // side
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.GetStructureTypeForInvestment'
			  , params: {
					InvestmentId: investmentId
					, Side: side
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get TreasuryBillPricing using asset.GetTreasuryBillPricing
         * @param {date} settleDate - 
         * @param {date} maturityDate - 
         * @param {number} yield - 
         * @param {number} faceValue - 
         * @param {number} price - 
         */
		, asset_GetTreasuryBillPricing: function (params, onSuccess, onError, async) {
			/*
				this.TreasuryBillPricing = await this.asset_GetTreasuryBillPricing({
					SettleDate: settleDate
					, MaturityDate: maturityDate
					, Yield: yield
					, FaceValue: faceValue
					, Price: price
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetTreasuryBillPricing'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Valuables using asset.GetValuables
         * @param {number} clientId - 
         * @param {number} valueablesId - 
         * @param {string} location - 
         * @param {number} categoryId - 
         */
		, asset_GetValuables: function (params, onSuccess, onError, async) {
			/*
				this.Valuables = await this.asset_GetValuables({
					ClientId: clientId
					, ValueablesId: valueablesId
					, Location: location
					, CategoryId: categoryId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetValuables'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ValuablesCategories using asset.GetValuablesCategories
         */
		, asset_GetValuablesCategories: function (params, onSuccess, onError, async) {
			/*
				this.ValuablesCategories = await this.asset_GetValuablesCategories({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetValuablesCategories'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ValuablesLocations using asset.GetValuablesLocations
         * @param {boolean} inventoryOnly - 
         * @param {number} categoryId - 
         */
		, asset_GetValuablesLocations: function (params, onSuccess, onError, async) {
			/*
				this.ValuablesLocations = await this.asset_GetValuablesLocations({
					InventoryOnly: inventoryOnly
					, CategoryId: categoryId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.GetValuablesLocations'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description asset.ProcessFundLookThroughImport
         * @param {number} importLogId - 
         */
		, asset_ProcessFundLookThroughImport: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.asset_ProcessFundLookThroughImport({
					ImportLogId: importLogId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'asset.ProcessFundLookThroughImport'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description asset.ProcessJPMCompImport
         * @param {number} importLogId - 
         */
		, asset_ProcessJPMCompImport: function (importLogId) {
			/*
				this.ProcessJPMCompImport = this.asset_ProcessJPMCompImport(
					null // importLogId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'asset.ProcessJPMCompImport'
			  , params: {
					ImportLogId: importLogId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description asset.QueueProcessInvestmentStats
         * @param {number} logId - 
         * @param {boolean} silent - 
         */
		, asset_QueueProcessInvestmentStats: function (params, onSuccess, onError, async) {
			/*
				this.QueueProcessInvestmentStats = await this.asset_QueueProcessInvestmentStats({
					LogId: logId
					, Silent: silent
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.QueueProcessInvestmentStats'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description asset.QueueProcessLookThrough
         * @param {number} logId - 
         */
		, asset_QueueProcessLookThrough: function (params, onSuccess, onError, async) {
			/*
				this.QueueProcessLookThrough = await this.asset_QueueProcessLookThrough({
					LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.QueueProcessLookThrough'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description asset.ReapplyFixedIncomeValuations
         * @param {number} fixedIncomeId - 
         */
		, asset_ReapplyFixedIncomeValuations: function (params, onSuccess, onError, async) {
			/*
				this.ReapplyFixedIncomeValuations = await this.asset_ReapplyFixedIncomeValuations({
					FixedIncomeId: fixedIncomeId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.ReapplyFixedIncomeValuations'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description asset.ReapplyInvestmentValuations
         * @param {number} investmentId - 
         * @param {date} onAndAfterDate - 
         * @param {number} logId - 
         */
		, asset_ReapplyInvestmentValuations: function (params, onSuccess, onError, async) {
			/*
				this.ReapplyInvestmentValuations = await this.asset_ReapplyInvestmentValuations({
					InvestmentId: investmentId
					, OnAndAfterDate: onAndAfterDate
					, LogId: logId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.ReapplyInvestmentValuations'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description asset.SetInvestmentNewTransactionReview1
         * @param {number} investmentId - 
         * @param {boolean} isReviewed - 
         */
		, asset_SetInvestmentNewTransactionReview1: function (params, onSuccess, onError, async) {
			/*
				this.SetInvestmentNewTransactionReview1 = await this.asset_SetInvestmentNewTransactionReview1({
					InvestmentId: investmentId
					, IsReviewed: isReviewed
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.SetInvestmentNewTransactionReview1'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description asset.SetInvestmentNewTransactionReview2
         * @param {number} investmentId - 
         * @param {boolean} isReviewed - 
         */
		, asset_SetInvestmentNewTransactionReview2: function (params, onSuccess, onError, async) {
			/*
				this.SetInvestmentNewTransactionReview2 = await this.asset_SetInvestmentNewTransactionReview2({
					InvestmentId: investmentId
					, IsReviewed: isReviewed
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.SetInvestmentNewTransactionReview2'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description asset.SetInvestmentUseNewTransactionScheme
         * @param {number} investmentId - 
         * @param {boolean} useNewScheme - 
         */
		, asset_SetInvestmentUseNewTransactionScheme: function (params, onSuccess, onError, async) {
			/*
				this.SetInvestmentUseNewTransactionScheme = await this.asset_SetInvestmentUseNewTransactionScheme({
					InvestmentId: investmentId
					, UseNewScheme: useNewScheme
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'asset.SetInvestmentUseNewTransactionScheme'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
    }
};
export default mixinSchema_asset;
