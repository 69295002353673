
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as LiqService from '../../services/DAL/liq_Service';
import * as MicrosoftGraphService from './../../services/MicrosoftGraphService';
import { Client } from '@microsoft/microsoft-graph-client';

import moment from 'moment';
import ExplorerBase from '@/js/Attachments/ExplorerBase.vue'

declare var $: any;
declare var SmartObject: any;
declare function tryParseInt(input: number, deflt: number): number;

@Component({
    components: {
        ExplorerBase
    }
})
export default class FileMove extends Vue {
    $refs: {
        moveExplorerBase: any
    }
    //#region Private declarations for Services
    private _liqService: LiqService.LiqService;
    private _graphService: MicrosoftGraphService.MicrosoftGraphService;

    //#endregion Private declarations for Services

    //#region Props

    //#endregion Props

    //#region Data
    path= '/drives/b!EQqSdxW20E-KllnkLsWhCAk3e6n2zY1PigoasTYWCAMbuFfc-jnfTbTCqnYOXs8r/root:/VAM/Attachments/LLCs:';
    isLoading: boolean = false;
    message: string = '';

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();
        this._graphService = new MicrosoftGraphService.MicrosoftGraphService();
    }
    mounted() {}
    //#endregion Lifecycle

    //#region Watches
    //#endregion Watches

    //#region Methods
    async processFile(file: any, client: Client): Promise<number> {
        const parameters = {} as LiqService.GetInvoicesParameters; 
        let invoices = [] as LiqService.Invoices[];     
        parameters.FileId = file.id;
        this.message = `Processing ${file.name}`;
        invoices = await this._liqService.GetInvoices(parameters);
        if (invoices.length){
            this.message = `Processing ${file.name}. InvoiceId: ${invoices[0].InvoiceId}; Move To: ${invoices[0].DestinationFolder} (${invoices[0].DestinationPath})`;
            try{
                const moved = await client.api("/drive/items/" + file.id).patch(
                    {
                        parentReference: {
                        path: invoices[0].DestinationPath
                    }
                });
                const changeLog = {
                    ChangeDate: this.$moment().utc()
                    , UserId: 'File Move'
                    , Schema: 'liq'
                    , Table: 'Invoice'
                    , EntityId: invoices[0].InvoiceId
                    , Field: 'FileId'
                    , AfterValue: invoices[0].DestinationPath
                };
                const changeLogId = await new SmartObject('ChangeLog').createObject(changeLog);
                this.message = `${file.name} moved to ${moved.parentReference.path} and logged to Change Log: ${changeLogId}`;
            }
            catch(err) {
                console.error(err);
            }
            return 1;
        }
        else {
            this.message = `invoice not found for ${file.name}`;
            return 0;
        }
    }
    async processFiles() {
        const files: any = this.$refs.moveExplorerBase.items;
        console.log(files.length);
        this.isLoading = true;
        const graphClient: Client = await this._graphService.getGraphClient(undefined, undefined);
        let fileCount: number = 0;
        // files.splice(2); // remove all but 2 for test
        await Promise.all(files.map(async (file) => {
            try {
                // console.log(file.name);
                fileCount = fileCount + await this.processFile(file, graphClient);
            } 
            catch (error) {
                console.log('error'+ error);
            }
        }))
        console.log('complete all', fileCount);
        // console.log('not found:', notfound);
        this.isLoading = false;
        
    }
    //#endregion Methods
}
