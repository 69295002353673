
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import KeyPersonEdit from '@/views/KeyPerson/KeyPersonEdit.vue';
import AttachmentList from '@/js/Attachments/AttachmentList.vue';
import ChangeLogList from '@/js/ChangeLog/ChangeLogList.vue';
import * as VamService from '../../services/DAL/vamService';
import ApproverSelectionList from '@/views/Workflow/ApproverSelectionList.vue';

@Component({
  components: {
    KeyPersonEdit,
    AttachmentList,
    ChangeLogList,
    ApproverSelectionList
  }
})
export default class KeyPersonTabView extends Vue {
  private _vamService: VamService.VamService;

  //#region Props
  @Prop() keyPerson: VamService.KeyPersons;
  @Prop() keyPersonId: number;
  @Prop() viewType: string;
  //#endregion Props

  //#region Data
  keyPerson_: VamService.KeyPersons = new VamService.KeyPersons();
  showChangeLogList: boolean = true;
  //#endregion Data

  //#endregion Computed
  get attachmentParameters(): Object {
    return {
      entityId: this.keyPerson_.Id,
      entityType: 'KeyPerson',
      storeCategoryId: 30
      //storeId: 7 // Entity/Owner
    };
  }
    //#endregion Computed

  //#region Lifecycle
  async created() {
    this._vamService = new VamService.VamService();

    if (this.keyPerson && Object.keys(this.keyPerson).length) {
      this.keyPerson_ = this.keyPerson;
    }
    else if (this.keyPersonId > 0) {
      await this.fetchKeyPerson(this.keyPersonId);
    }
  }
  //#endregion Lifecycle

  //#region Methods
  async fetchKeyPerson(keyPersonId: number) {
      this.keyPerson_ = new VamService.KeyPersons();
      const params = {} as VamService.GetKeyPersonsParameters;
      params.KeyPersonId = keyPersonId;
      const keyPersons: VamService.KeyPersons[] = await this._vamService.GetKeyPersons(params);
      if (keyPersons && keyPersons.length) {
          this.keyPerson_ = keyPersons[0];
      }
  }

  onSave(KeyPersonId) {
      this.refreshChangeLogList();
      this.$emit('saved', KeyPersonId);
  }

  async refreshChangeLogList() {
      this.showChangeLogList = false;
      await this.$nextTick();
      this.showChangeLogList = true;
  }
  //#endregion
}
