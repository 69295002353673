
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import moment from 'moment';
import formatters from '../../utils/formatters';
import CashTransferEdit from '@/views/CashTransfers/CashTransferEdit.vue';
import * as LiqService from './../../services/DAL/liq_Service';
import { UserClient } from './../../models/Common/UserClient';
import BankAccountSelector from '@/components/form/BankAccountSelector.vue'
import { ElTable } from 'element-ui/types/table';
import ElementUI from 'element-ui';

@Component({
  components: {
    CashTransferEdit,
    BankAccountSelector
  }
})
export default class CashTransferList extends Vue {
  $refs: {
    tblCashTransferList: ElementUI.Table
  }
  //#region Data
  private _liqService: LiqService.LiqService;
  private formatters: formatters = new formatters();

  cashTransfers: LiqService.CashTransfers[] = [];
  filter = '';
  selectedCashTransfer: LiqService.CashTransfers = null;
  showCashTransferEdit = false;
  showCashTransferCreate = false;
  includeInBalanceOnly = true;
  fromBankAccountId: number = null;
  toBankAccountId: number = null;
  isLoading = false;
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._liqService = new LiqService.LiqService();
    this.formatters = new formatters();
  }

  async mounted() {
    this.getCashTransfers();
  }
  //#endregion Lifecycle

  //#region Watches
    @Watch("includeInBalanceOnly")
    onChange_includeInBalanceOnly(val: number, oldVal: number) {
        this.getCashTransfers();
    }
    @Watch("fromBankAccountId")
    onChange_fromBankAccountId(val: number, oldVal: number) {
        this.getCashTransfers();
    }
    @Watch("toBankAccountId")
    onChange_toBankAccountId(val: number, oldVal: number) {
        this.getCashTransfers();
    }
  //#endregion Watches

  //#region Methods
  selectCashTransfer(cashTransfer) {
    if (cashTransfer) {
      this.showCashTransferEdit = false;
      this.$nextTick(() => {
        this.showCashTransferEdit = true;
        this.selectedCashTransfer = cashTransfer;
      });
    }
  }

  closeCashTransfer() {
    this.showCashTransferEdit = false;
    this.selectedCashTransfer = null;
    this.$refs.tblCashTransferList.setCurrentRow(null);
  }

  onDeleteCashTransfer() {
    this.closeCashTransfer();
    this.getCashTransfers();
  }

  async getCashTransfers() {
    this.isLoading = true;
    const getCashTransfersParameters = {} as LiqService.GetCashTransfersParameters;
    getCashTransfersParameters.IncludeInBalanceOnly = this.includeInBalanceOnly;
    getCashTransfersParameters.FromBankAccountId = this.fromBankAccountId;
    getCashTransfersParameters.ToBankAccountId = this.toBankAccountId;
    this.cashTransfers = await this._liqService.GetCashTransfers(
      getCashTransfersParameters
    );
    this.isLoading = false;
  }
  async transferAdded(cashTransfer){
    this.cashTransfers.push(cashTransfer);
    await this.$nextTick();
    this.$refs.tblCashTransferList.toggleRowSelection(cashTransfer, true);
    this.showCashTransferCreate = false;
}
  //#endregion Methods
}
