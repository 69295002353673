
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import StateSelector from '@/components/form/StateSelector.vue';
import RegisteredAgentSelector from '@/components/form/RegisteredAgentSelector.vue';
import * as VamService from '../../services/DAL/vamService';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare var tryParseBool: any;

@Component({
  components: {
    StateSelector,
    RegisteredAgentSelector
  }
})
export default class EntityForeignStateAssigner extends Vue {
  $refs: {
    frmEntityForeignStateAssigner: ElementUI.Form
  }
  //#region Data
  private _vam_Service: VamService.VamService;

  @Prop() owner: VamService.OwnersV2;

  entityForeignState = new VamService.EntityForeignStates();
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._vam_Service = new VamService.VamService();
    
    this.entityForeignState.EntityId = this.owner ? this.owner.Id : null;
  }

  mounted() {
    this.$refs.frmEntityForeignStateAssigner.resetFields();
  }
  //#endregion Lifecycle

  //#region Watches
  @Watch('entityForeignState.StateId')
  onChangeState(val?: object, oldVal?: object) {
    if (oldVal !== undefined) {
        this.entityForeignState.RegisteredAgentId = null;
        this.entityForeignState.RegisteredAgentName = null;
        this.entityForeignState.RegisteredAgentFullStreet = null;
        this.entityForeignState.RegisteredAgentCityStateZip = null;
        this.entityForeignState.RegisteredAgentFullAddress = null;
        this.entityForeignState.RegisteredAgentCounty = null;
    }
  }
  //#endregion Watches

  //#region Methods
  onSet_State(state: VamService.States) {
    if (state) {
        this.entityForeignState.StateName = state.Name;
        this.entityForeignState.StateAbbreviation = state.Abbreviation;
        this.entityForeignState.CountryName = state.CountryName;
    }
    else {
        this.entityForeignState.StateName = null;
        this.entityForeignState.StateAbbreviation = null;
        this.entityForeignState.CountryName = null;
    }
  }

  onSet_RegisteredAgent(registeredAgent: VamService.RegisteredAgents) {
    if (registeredAgent) {
        this.entityForeignState.RegisteredAgentName = registeredAgent.Name;
        this.entityForeignState.RegisteredAgentFullStreet = registeredAgent.FullStreet;
        this.entityForeignState.RegisteredAgentCityStateZip = registeredAgent.CityStateZip;
        this.entityForeignState.RegisteredAgentFullAddress = registeredAgent.FullAddress;
        this.entityForeignState.RegisteredAgentCounty = registeredAgent.County;
    }
    else {
        this.entityForeignState.RegisteredAgentName = null;
        this.entityForeignState.RegisteredAgentFullStreet = null;
        this.entityForeignState.RegisteredAgentCityStateZip = null;
        this.entityForeignState.RegisteredAgentFullAddress = null;
        this.entityForeignState.RegisteredAgentCounty = null;
    }
  }

  async saveForm() {
    this.$refs.frmEntityForeignStateAssigner.validate(async valid => {
      if (valid) {
        try {
            await new SmartObject('EntityForeignState').createObject(
                this.entityForeignState
            );

            this.$notify.success('Foreign entity state added.');

            this.closeDialog();
        }
        catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
      }
    });
  }

  closeDialog() {
    this.$emit('close-dialog');
  }
  //#endregion Methods
}
