
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as VamService from './../../services/DAL/vamService';
import * as LiabService from './../../services/DAL/liabService';
import ElementUI from 'element-ui';

declare var $;

@Component
export default class OwnerLiabilityList extends Vue {
    $refs: {
        tblOwnerLiabilityList: ElementUI.Table
    }
    //#region Data
    private _vamService: VamService.VamService;
    private _liabService: LiabService.LiabService;

    @Prop()
    ownerId: number;

    notes: LiabService.Notes[] = [];
    filter: string = '';
    selectedNote = null;
    showNoteEdit = false;
    isLoading = false;
    //#endregion Data

    //#region Computed
    get notesFiltered(): LiabService.Notes[] {
        return this.notes.filter(note => {
            const filter_ = (this.filter || '').toLowerCase();
            return (
                (note.Name || '').toLowerCase().indexOf(filter_) > -1 ||
                (note.NoteType || '').toLowerCase().indexOf(filter_) > -1
            );
        });
    }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();
        this._liabService = new LiabService.LiabService();
    }

    async mounted() {
        this.getNotes();
    }
    //#endregion Lifecycle

    //#region Methods
    selectNote(note) {
        if (note) {
            this.showNoteEdit = true;
            this.selectedNote = note;
            this.$nextTick(() => {
                $('#divOwnerLiabilityList')
                    .find('#ucNoteEdit')
                    .fetchSmartControl({
                        params: {
                            NoteId: note.Id,
                            NoteTypeId: note.NoteTypeId,
                            ClientId: note.ClientId
                        }
                    });
            });
        }
    }

    closeNote() {
        this.showNoteEdit = false;
        this.selectedNote = null;
        this.$refs.tblOwnerLiabilityList.setCurrentRow(null);
    }

    onDeleteLiability() {
        this.closeNote();
        this.getNotes();
    }

    async getNotes() {
        this.isLoading = true;
        const getNotesParameters = {} as LiabService.GetNotesParameters;
        getNotesParameters.ClientId = null;
        getNotesParameters.OwnerId = this.ownerId;
        getNotesParameters.NoteStatus = 20;
        getNotesParameters.IncludeHolderOwners = false;
        this.notes = await this._liabService.GetNotes(getNotesParameters);
        this.isLoading = false;
    }
    //#endregion Methods
}
