import { render, staticRenderFns } from "./PromissoryNoteLegacyTransactionList.vue?vue&type=template&id=301a2c4e&scoped=true"
import script from "./PromissoryNoteLegacyTransactionList.vue?vue&type=script&setup=true&lang=ts"
export * from "./PromissoryNoteLegacyTransactionList.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./PromissoryNoteLegacyTransactionList.vue?vue&type=style&index=0&id=301a2c4e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301a2c4e",
  null
  
)

export default component.exports