
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as TransactionsService from '../../services/DAL/transactionsService';
import formatters from '../../utils/formatters';
import uniqBy from 'lodash/uniqBy';
import Common from '../../utils/common';
import ElementUI from 'element-ui';
import VueMarkdown from '@adapttive/vue-markdown'
import alasql from 'alasql';
import * as XLSX from 'xlsx';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;

declare var SmartObject: any;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function getStoredSecurityLevel(Id: number): number;

@Component({
  components: {
    //@ts-ignore
    VueMarkdown
  },
})
export default class InvestmentTransactionsListV2 extends Vue {
    $refs: {
        refTransactionHeaderForInvestment: ElementUI.Table
    }
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    public common: Common;
    public formatters: formatters;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({required: true}) readonly investmentId: number;
    @Prop() readonly selectedTransactionId: number;
    @Prop({ type: Number, default: null }) readonly securityLevel: number;

    //#endregion Props

    //#region Data
    transactions = [] as TransactionsService.TransactionHeaderForInvestment[];
    selectedTransaction = {} as TransactionsService.TransactionHeaderForInvestment;
    showTransactionEdit: boolean = false;
    loading: boolean = false;
    securityLevel_: number = 0;
    //#endregion Data

    //#region Lifecycle
    created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.formatters = new formatters();
        this.common = new Common();
        this.securityLevel_ = this.securityLevel || 
            tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets), 0);
    }
    async mounted() {
        await this.fetchTransactions();
        if (this.selectedTransactionId){
            this.selectedTransactionIdChanged(this.selectedTransactionId, undefined);
        }
    }
    //#endregion Lifecycle

    //#region Computed
    //#endregion Computed

    //#region Watches
    @Watch('investmentId', { deep: true })
    async investmentIdChanged(val: any, oldVal: any) {
        this.fetchTransactions();
    }
    @Watch('selectedTransactionId')
    async selectedTransactionIdChanged(val: any, oldVal: any) {
        const row = this.common.getSelectedArrayItem(
            this.transactions,
            val.toString(),
            'Id'
        );
        await this.editInvestmentTransaction(row, undefined, undefined);
    }

    //#endregion Watches

    //#region Computed
    //#endregion Computed

    //#region Methods
    async fetchTransactions(){
        this.transactions = [] as TransactionsService.TransactionHeaderForInvestment[];
        if (this.investmentId == null){
            console.error('Investment Id is null');
            return;
        }
        this.loading = true;
        const params = {} as TransactionsService.GetTransactionHeaderForInvestmentParameters;
        params.InvestmentId = this.investmentId;
        this.transactions = await this._transactionsService.GetTransactionHeaderForInvestment(params);
        await this.$nextTick();
        if (this.$refs.refTransactionHeaderForInvestment){
        this.$refs.refTransactionHeaderForInvestment.doLayout();
        }
        this.loading = false;
    }
    cancelledTransactionEdit(){
        this.showTransactionEdit = false;
        this.selectedTransaction = {} as TransactionsService.TransactionHeaderForInvestment;
    }
    async savedTransactionEdit(transaction: TransactionsService.TransactionHeaderForInvestment){
        await this.fetchTransactions();
    }
    filterHandler(value, row, column) {
        const property = column['property'];
        return row[property] === value;
    }
    filters(column){
        const filters = this.transactions.map(function(list) {
            return {
                text: list[column]
                , value: list[column]
                };
        });
        return uniqBy(filters, 'value');
    }
    async editInvestmentTransaction(
        selectedRow: TransactionsService.TransactionHeaderForInvestment,
        event,
        column
    ) {
        if (selectedRow && selectedRow != null) {
            const transactionHeaderId = selectedRow.Id.toString();
            this.$router.push({ name: 'Investment Transaction', params: { transactionHeaderId } }).catch(() => {});
        }
    }
    newTransaction(){
        this.selectedTransaction = {} as TransactionsService.TransactionHeaderForInvestment;
        const newTransactionInvestmentId = this.investmentId.toString();
        this.$router.push({ name: 'New Investment Transaction', params: { newTransactionInvestmentId } }).catch(() => {});
    }
    deletedTransaction(transactionId: number){
        if (!!this.selectedTransaction && this.selectedTransaction.Id == transactionId){
            const index = this.transactions.indexOf(this.selectedTransaction);
            this.transactions.splice(index, 1);
            this.selectedTransaction = {} as TransactionsService.TransactionHeaderForInvestment;
        }
        else{
            this.fetchTransactions();
        }
    }
    getSummary(param) {
        return ['', `Transactions: ${this.transactions.length}`];
    }
    transactionRowStyle({row, rowIndex}): Object {
        if (row.Pending){
            return {'background-color': 'yellow'};
        }
        return '';
    }
    async exportItems(){
        if (this.transactions.length > 0) {
            var opts = [{ sheetid: this.investmentId.toString(), header: true }];
            try {
                
                await alasql.promise(`SELECT * INTO XLSX("Headers ${this.investmentId.toString()} .xlsx",?) FROM ?`, [opts, [this.transactions]])
                this.$notify.success('Excel file exported.');
            }
            catch(err) {
                this.$notify.error(err.message);
            }
        }
    }


    //#endregion Methods
}
