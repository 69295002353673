
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import ClientSelector from '@/components/form/ClientSelector.vue';
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component({
  components: {
        ClientSelector
    }
})
export default class HoldingEntityEdit extends Vue {
  $refs: {
    frmHoldingEntityEdit: ElementUI.Form
  }
  //#region Data
  private _asset_Service: AssetService.AssetService;

  @Prop() holdingEntity: AssetService.HoldingEntities;
  @Prop() onClose: Function;
  @Prop() onSave: Function;
  @Prop() onDelete: Function;
  @Prop({
    default: 'dialog',
    })  viewType: string;
  @Prop() clientId: number;

  holdingEntity_: AssetService.HoldingEntities = {} as AssetService.HoldingEntities;
  clientId_;

  get isNew(): boolean {
    return !this.holdingEntity || !Object.keys(this.holdingEntity).length;
  }
  get isFormDirty(): boolean {
    return Object.keys((this as any).veeFields).some(
      key => (this as any).veeFields[key].dirty
    );
  }
  //#endregion Data

  //#region Lifecycle
  async created() {
    if (this.holdingEntity && Object.keys(this.holdingEntity).length) {
      this.holdingEntity_ = this.holdingEntity;
    }
    else {
      this.holdingEntity_.ClientId = this.clientId;
    }
    this._asset_Service = new AssetService.AssetService();

  }
  //#endregion Lifecycle

  //#region Methods

  async saveForm() {
    this.$refs.frmHoldingEntityEdit.validate(async valid => {
      if (valid) {
        let message: string;

        if (this.isNew) {
          this.holdingEntity_.Id = await new SmartObject('HoldingEntity').createObject(
            this.holdingEntity_
          );
          message = 'Holding Entity added.';
        } else {
          await new SmartObject('HoldingEntity', this.holdingEntity_.Id).updateObject(
            this.holdingEntity_
          );
          message = 'Changes saved.';
        }

        this.$notify.success(message);

        if (typeof this.onSave === 'function') {
          await this.onSave(this.holdingEntity_.Id);
        }

        this.$emit('saved', this.holdingEntity_.Id);
        this.$emit('update:holdingEntity', this.holdingEntity_);

        if (this.isNew) {
          this.closeDialog();
        }
      }
    });
  }

  async deleteHoldingEntity() {
    if (!this.isNew) {
      try {
        await this.$confirm(
          'This will permanently delete this Holding Entity. Continue?',
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        );

        try {
          await new SmartObject('HoldingEntities', this.holdingEntity_.Id).deleteObject();

          this.$notify.success('Holding Entity deleted.');

          if (typeof this.onDelete === 'function') {
            await this.onDelete(this.holdingEntity_.Id);
            this.onClose_();
          }
        } catch {
          this.$notify.error('Something went wrong processing your request, please try again.');
        }
      } catch {}
    }
  }

  onClose_() {
    if (typeof this.onClose === 'function') {
      this.onClose();
    }
  }

  closeDialog() {
    this.$emit('close-dialog');
    this.onClose_();
  }
  //#endregion Methods
}
