
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import SidebarItem from '@/components/layout/SidebarItem.vue';
import * as SystemService from '../../services/DAL/systemService';
import Common from '../../utils/common';

declare var $: any;
declare function smartData(options: object): any;

@Component({
    components: {
        SidebarItem
    }
})
export default class Sidebar extends Vue {
    //#region Data
    private systemService: SystemService.SystemService;
    private common: Common;

    @Prop() loading: boolean;
    @Prop() isCollapsed: boolean;

    menuEntries: SystemService.MenuEntriesHierarchy[] = [];
    menuEntriesHierarchy: SystemService.MenuEntriesHierarchy[] = [];
    numEntriesToLoad: number = null;
    numEntriesLoaded: number = null;
    defaultActive: string = null;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this.systemService = new SystemService.SystemService();

        this.fetchMenuEntries();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchMenuEntries() {
        this.$emit('update:loading', true);
        this.$setSystemStatus('Getting Menu Hierarchy');
        const parameters = {} as SystemService.GetMenuEntriesHierarchyParameters;
        this.menuEntriesHierarchy = await this.systemService.GetMenuEntriesHierarchy(parameters);
        const menuEntries_ = this.menuEntriesHierarchy.filter(
            item => item.ParentId === 0
        );
        this.numEntriesToLoad = menuEntries_.length;
        this.numEntriesLoaded = 0;
        this.menuEntries = menuEntries_;

        if (this.numEntriesToLoad === 0) {
            this.onAllEntriesLoaded();
        }
        this.$setSystemStatus('');
    }

    onLoadEntry() {
        if (++this.numEntriesLoaded === this.numEntriesToLoad) {
            this.onAllEntriesLoaded();
        }
    }

    async onAllEntriesLoaded() {
        this.$emit('update:loading', false);

        let dsMatchingMenuItems: Array<object>;
        try {
            this.$setSystemStatus(`Getting Menu Item for ${this.$route.path}`);
            const parameters = {} as SystemService.GetMenuItemByRouteParameters;
            parameters.Path = this.$route.path
                        .replace(/^\/+/, '')
                        .replace(/\/+$/, '');
            parameters.Parameters = this.$route.query && this.$route.query.params
                            ? JSON.stringify(this.$route.query.params) || null
                            : null;
            dsMatchingMenuItems = await this.systemService.GetMenuItemByRoute(parameters);
            this.$setSystemStatus('');
        } catch (ex) {
            console.error(ex);
        }

        if (dsMatchingMenuItems && dsMatchingMenuItems.length) {
            const topMatchingMenuItemId = (dsMatchingMenuItems[0] as any).Id;
            if (topMatchingMenuItemId > 0) {
                const $item = $('#divMenuContainer').find(
                    'li[MenuItemId="' + topMatchingMenuItemId + '"]'
                );

                const menuItemIndex: string = $item.attr('indexAttr');
                this.defaultActive = menuItemIndex;

                const vuePath: string = $item.attr('vuePath');
                const menuItemName: string = $item.attr('MenuItemName');
                if (!vuePath || vuePath === '/') {
                    $('.breadcrumb-item:last')
                        .find('span')
                        .text(menuItemName);
                }
            }
        }
    }
    //#endregion Methods
}
