
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import * as VamService from './../../services/DAL/vamService';
import * as ReportsService from './../../services/DAL/reportsService';
import Common from '../../utils/common';
import { UserClient } from './../../models/Common/UserClient';
import BalanceSheetConfigEditView from '@/views/BalanceSheet/BalanceSheetConfigEditView.vue';

declare function tryParseInt(input: any, defaultValue: number): number;
declare function getAppCookieKeyValue(key: string, defaultValue: object): string;
declare function setAppCookieKeyValue(key: string, value: object);

@Component({
    components: {
        BalanceSheetConfigEditView
    }
})
export default class BalanceSheetConfigView extends Vue {
//#region private Data
    private _vamService: VamService.VamService;
    private _reportsService: ReportsService.ReportsService;
    private _common: Common;
//#end region private Data

//#region Props
    @Prop({ type: Number, default: null }) configurationId: number;
//#endregion Props

//#region Data
    clients: VamService.UserClients[] = [];
    configurations: ReportsService.Configurations[] = [];
    selectedClient: VamService.UserClients = null;
    selectedConfiguration: ReportsService.Configurations = null;
    selectedClient_: VamService.UserClients = null;
    selectedConfiguration_: ReportsService.Configurations = null;
    showBalanceSheetConfig: boolean = false;
    isLoading = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();
        this._reportsService = new ReportsService.ReportsService();
        this._common = new Common();
        await this.fetchClients();
        if (this.configurationId){
            await this.fetchConfigurations();
            const propConfiguration = this._common.getSelectedArrayItem(this.configurations, this.configurationId.toString(), 'Id');
            this.selectedClient = this._common.getSelectedArrayItem(this.clients, propConfiguration.ClientId.toString(), 'Id');
            await this.$nextTick();
            this.selectedConfiguration = propConfiguration
            this.loadBalanceSheetConfig();
        }
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('selectedClient')
    onChange_selectedClient(val: object, oldVal: object) {
        this.selectedConfiguration = null;
        this.fetchConfigurations();
    }
    @Watch('selectedConfiguration')
    onChange_selectedConfiguration(val: object, oldVal: object) {
        this.loadBalanceSheetConfig();
    }
    //#endregion Watches

    //#region Methods
    async fetchClients() {
        this.clients = [];
        this.clients = await this._vamService.GetUserClients();

        const selectedClientId = tryParseInt(await getAppCookieKeyValue('SelectedClientId', null), null);
        if (selectedClientId) {
            const selectedClient = this._common.getSelectedArrayItem(
                this.clients,
                selectedClientId.toString(),
                'Id'
            );

            if (selectedClient && !this.configurationId) {
                this.selectedClient = selectedClient;
            }
        }
    }

    async fetchConfigurations() {

        const getConfigurationsParameters = {} as ReportsService.GetConfigurationsParameters;
        getConfigurationsParameters.ClientId = this.$objectPropertyIfExists(this.selectedClient, 'Id');
        getConfigurationsParameters.ConfigurationTypeId = 1; // Balance Sheets
        this.configurations = await this._reportsService.GetConfigurations(
            getConfigurationsParameters
        );
    }

    async loadBalanceSheetConfig() {
        this.showBalanceSheetConfig = false;
        this.selectedClient_ = this.selectedClient;
        this.selectedConfiguration_ = this.selectedConfiguration;
        await this.$nextTick();
        this.showBalanceSheetConfig = true;
    }
    //#endregion Methods
}
