export class InvestmentTransactionAllocation {
  Name: string = undefined;
  OwnerEntityId: number = undefined;
  OwnerEntityType: string = undefined;
  Shares: number = undefined;
  Amount: number = undefined;
  Value: number = undefined;
  Id: string = undefined;
  TransactionAllocationId: number = undefined;
}

// export class TransactionsService {
//   constructor() {
//   }


// }
