// TS API for Schema liq
// Auto-generated 08/20/24 15:14


declare var smartData: any;
// output classes:
export class AdjustAllocationForScheduleTypeChange {
    RowsUpdated: number = undefined;
}
export class AllocateAgreement {
    Id: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    Amount: number=undefined;
    AccountId: number=undefined;
    COAId: number=undefined;
    AccountName: string=undefined;
    AccountPath: string=undefined;
    DepartmentId: number=undefined;
    DepartmentName: string=undefined;
    DisplayModified: string=undefined;
    ModifyOrCreateDate: string=undefined;
    ModifyOrCreateUserFirstLast: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    ChartOfAccountsArray: string=undefined;
    OwnerId: number=undefined;
    Pct: number = undefined;
}
export class CompleteInvoice {
    InvoiceId: number=undefined;
    OwnerId: number=undefined;
    Date: string=undefined;
    DueDate: string=undefined;
    VendorId: number=undefined;
    VendorName: string=undefined;
    VendorInvoiceNumber: string=undefined;
    ReferenceEntityId: number=undefined;
    ReferenceEntityType: string=undefined;
    Amount: number=undefined;
    Notes: string=undefined;
    FileId: string=undefined;
    DriveId: string=undefined;
    StatusId: number=undefined;
    StatusText: string=undefined;
    PaidDate: string=undefined;
    CompletedDateDisplay: string=undefined;
    CompletedDate: string=undefined;
    PaidUserId: string=undefined;
    PaidMethodId: number=undefined;
    PaidMethodText: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Client: string=undefined;
    ClientId: number=undefined;
    CreateDateLabel: string=undefined;
    ModifyDateLabel: string=undefined;
    CompletedDateLabel: string=undefined;
    CompletedNotes: string=undefined;
    PaidDateLabel: string=undefined;
    PaidDateSetLabel: string=undefined;
    ReferenceEntityName: string=undefined;
    OwnerName: string=undefined;
    BankAccountId: number=undefined;
    BankAccountNameDisplay: string=undefined;
    IsComplete: boolean=undefined;
    AutoPay: boolean=undefined;
    AllocationStatusText: string=undefined;
    RowClass: string=undefined;
    TransactionMatched: boolean=undefined;
    ConfirmedTransactionMatched: boolean=undefined;
    AgreementId: number=undefined;
    ScheduleItemId: number=undefined;
    ScheduleDate: string=undefined;
    ScheduleAmount: number=undefined;
    ParentReferenceEntityId: number=undefined;
    ParentReferenceEntityType: string=undefined;
    AgreementGroup: string=undefined;
    Units: number=undefined;
    UnitsDisplay: number=undefined;
    UnitsLabel: string=undefined;
    AggregateUnits: boolean=undefined;
    NotifyThresholdMinimum: number=undefined;
    NotifyEmail: string=undefined;
    Deleteable: boolean=undefined;
    DisableMatches: boolean=undefined;
    DisabledMatchReason: string=undefined;
    ApprovalId: number=undefined;
    Approved: boolean=undefined;
    ApprovalKeyPerson: string=undefined;
    DestinationPath: string=undefined;
    DestinationFolder: string=undefined;
    FYIId: number=undefined;
    NotifyApproversOnMatch: boolean=undefined;
    NotifyFYIOnMatch: boolean=undefined;
    MatchNotificationSent: string=undefined;
    BypassInvoiceReview: boolean=undefined;
    ReviewLogId: number=undefined;
    ReviewedBy: string=undefined;
    ReviewedOn: string=undefined;
    Reviewed: string=undefined;
    SelectableForReview: boolean=undefined;
    IsLoading: boolean=undefined;
    Approvers: number=undefined;
    FYIs: number=undefined;
    ApprovalSetDate: string=undefined;
    LockAgreementSelection: boolean=undefined;
    IsTripInvoice: boolean=undefined;
    FXUpdates: number=undefined;
    AutoIngestFrom: string=undefined;
    AutoIngestSubject: string=undefined;
    AutoIngestMessageId: string=undefined;
    RecognizedInvoiceJSONId: number=undefined;
    AutoIngestFileCreateUser: string=undefined;
    AutoIngestFileName: string = undefined;
}
export class ConfirmTransactionMatch {
    MatchedTransactionId: number=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    TransactionId: number=undefined;
    PostedAmount: number=undefined;
    PostedDate: string=undefined;
    Description: string=undefined;
    AccountId: number=undefined;
    NumberDisplayMasked: string=undefined;
    AccountName: string=undefined;
    AccountOwnerId: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    SearchTermId: number=undefined;
    AutoMatchLogId: number=undefined;
    VendorId: number=undefined;
    VendorName: string=undefined;
    AutoMatchedDescription: string=undefined;
    AutoMatchRequiresConfirmation: number=undefined;
    AutoMatchTime: string=undefined;
    MatchConfirmed: string=undefined;
    IsConfirmed: number=undefined;
    SearchTermText: string=undefined;
    SearchTermEntityType: string=undefined;
    SearchTermEntityId: number=undefined;
    InvestmentId: number=undefined;
    AgreementId: number=undefined;
    NoteId: number=undefined;
    ReviewForTax: boolean=undefined;
    DepartmentId: number=undefined;
    ChartOfAccountsArray: string=undefined;
    EntityTypeDisplay: string=undefined;
    EntityTypeDisplayShort: string=undefined;
    MatchedDescription: string=undefined;
    ScheduledDate: string=undefined;
    ScheduledAmount: number=undefined;
    Multiplier: number=undefined;
    TransferId: number=undefined;
    Comment: string=undefined;
    PreviouslyMatchedAmount: number=undefined;
    LegacyCount: number=undefined;
    NewCount: number = undefined;
}
export class CopyAgreement {
    AgreementId: number=undefined;
    Name: string=undefined;
    TypeId: number=undefined;
    AgreementType: string=undefined;
    OwnerId: number=undefined;
    OwnerName: string=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    VendorId: number=undefined;
    VendorName: string=undefined;
    AccountId: number=undefined;
    AccountNumber: string=undefined;
    AccountName: string=undefined;
    BankAccountNameDisplay: string=undefined;
    InceptionDate: string=undefined;
    TerminationDate: string=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    ModifyDate: string=undefined;
    ModifyUser: string=undefined;
    Created: string=undefined;
    Modified: string=undefined;
    ScheduleType: string=undefined;
    Identifier: string=undefined;
    IncludeOnBalanceSheet: boolean=undefined;
    BalanceSheetCategoryId: number=undefined;
    ScheduleError: string=undefined;
    Configuration: string=undefined;
    GroupId: number=undefined;
    GroupName: string=undefined;
    GroupVendorSpecific: boolean=undefined;
    AggregateUnits: boolean=undefined;
    UnitsLabel: string=undefined;
    NotifyThresholdMinimum: number=undefined;
    NotifyEmail: string=undefined;
    DefaultUnitsMethod: string=undefined;
    CharitableDonationPayBy: string=undefined;
    UI: string=undefined;
    ApprovedRejected: string=undefined;
    PledgeCommitment: number=undefined;
    ShowPledgeFields: boolean=undefined;
    PledgeRemainingCommitment: number=undefined;
    PledgeActualTermination: string=undefined;
    InvoicesExpected: boolean=undefined;
    CharitableEINInvalidatedLogId: number=undefined;
    Deletable: boolean=undefined;
    ErrorMessage: string=undefined;
    HasError: boolean = undefined;
}
export class CreateInvoiceEmails {
    FullName: string=undefined;
    Items: number=undefined;
    HasApprovals: boolean=undefined;
    HasFYIs: boolean=undefined;
    HasResend: boolean=undefined;
    HasInfos: boolean = undefined;
}
export class CreateManualInvoiceBankTransaction {
    Id: number = undefined;
}
export class Accounts {
    Id: number=undefined;
    BankAccountId: number=undefined;
    Client: string=undefined;
    ClientId: number=undefined;
    Name: string=undefined;
    NameNumberMasked: string=undefined;
    DisplayName: string=undefined;
    Description: string=undefined;
    OwnerName: string=undefined;
    OwnerId: number=undefined;
    Number: string=undefined;
    CashAsOf: string=undefined;
    Cash: number=undefined;
    CashShortDateUser: string=undefined;
    CashSource: string=undefined;
    Type: string=undefined;
    IsKeyAccount: boolean=undefined;
    DashboardOrder: number=undefined;
    AcquiredDate: string=undefined;
    ClosedDate: string=undefined;
    BankId: number=undefined;
    BankName: string=undefined;
    BankNameNumberMasked: string=undefined;
    BalanceSheetCategoryId: number=undefined;
    SourceNameDisplay: string=undefined;
    NumberDisplayMasked: string=undefined;
    AlertMinimumBalance: number=undefined;
    AlertMinimumBalanceDisplay: string=undefined;
    AlertMinimumBalance0: number=undefined;
    Style: string=undefined;
    COADisplay: string=undefined;
    COAId: number=undefined;
    ManualTransactions: boolean=undefined;
    PrimaryAccountForOwner: boolean=undefined;
    Available: number=undefined;
    AvailableAsOf: string=undefined;
    MaskNameType: string=undefined;
    CreateDateLabel: string=undefined;
    ModifyDateLabel: string=undefined;
    PrimaryNumber: string=undefined;
    StartingBalance: number=undefined;
    StartingBalanceMonth: number = undefined;
}
export class AgreementBud {
    AgreementId: number=undefined;
    BudgetAmount: number=undefined;
    Invoiced: number=undefined;
    Paid: number=undefined;
    Remaining: number=undefined;
    AgreementPaid: number=undefined;
    InvoicePaid: number=undefined;
    ReferenceOnlyPaid: number=undefined;
    Unpaid: number=undefined;
    RemainingStyle: string = undefined;
}
export class AgreementGroups {
    AgreementGroupId: number=undefined;
    Name: string=undefined;
    VendorId: number=undefined;
    VendorSpecific: boolean=undefined;
    VendorName: string=undefined;
    ClientId: number=undefined;
    ClientName: string=undefined;
    Label: string=undefined;
    AggregateUnits: boolean=undefined;
    UnitsLabel: string=undefined;
    NotifyThresholdMinimum: number=undefined;
    NotifyEmail: string=undefined;
    NumAgreements: number=undefined;
    Deletable: boolean=undefined;
    WorkflowId: number=undefined;
    WorkflowName: string = undefined;
}
export class AgreementGroupUnitBalance {
    AgreementGroupId: number=undefined;
    UnitsLabel: string=undefined;
    NotifyThresholdMinimum: number=undefined;
    NotifyEmail: string=undefined;
    UnitsBalance: number=undefined;
    Notify: boolean=undefined;
    LastEmailSendDate: string = undefined;
}
export class Agreements {
    AgreementId: number=undefined;
    Name: string=undefined;
    TypeId: number=undefined;
    AgreementType: string=undefined;
    OwnerId: number=undefined;
    OwnerName: string=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    VendorId: number=undefined;
    VendorName: string=undefined;
    AccountId: number=undefined;
    AccountNumber: string=undefined;
    AccountName: string=undefined;
    BankAccountNameDisplay: string=undefined;
    InceptionDate: string=undefined;
    TerminationDate: string=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    ModifyDate: string=undefined;
    ModifyUser: string=undefined;
    Created: string=undefined;
    Modified: string=undefined;
    ScheduleType: string=undefined;
    Identifier: string=undefined;
    IncludeOnBalanceSheet: boolean=undefined;
    BalanceSheetCategoryId: number=undefined;
    ScheduleError: string=undefined;
    Configuration: string=undefined;
    GroupId: number=undefined;
    GroupName: string=undefined;
    GroupVendorSpecific: boolean=undefined;
    AggregateUnits: boolean=undefined;
    UnitsLabel: string=undefined;
    NotifyThresholdMinimum: number=undefined;
    NotifyEmail: string=undefined;
    DefaultUnitsMethod: string=undefined;
    CharitableDonationPayBy: string=undefined;
    UI: string=undefined;
    ApprovedRejected: string=undefined;
    PledgeCommitment: number=undefined;
    ShowPledgeFields: boolean=undefined;
    PledgeRemainingCommitment: number=undefined;
    PledgeActualTermination: string=undefined;
    InvoicesExpected: boolean=undefined;
    CharitableEINInvalidatedLogId: number=undefined;
    Deletable: boolean=undefined;
    ErrorMessage: string=undefined;
    HasError: boolean = undefined;
}
export class AgreementsDisplay {
    AgreementId: number=undefined;
    Name: string=undefined;
    TypeId: number=undefined;
    AgreementType: string=undefined;
    OwnerId: number=undefined;
    OwnerName: string=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    VendorId: number=undefined;
    VendorName: string=undefined;
    AccountId: number=undefined;
    AccountNumber: string=undefined;
    AccountName: string=undefined;
    BankAccountNameDisplay: string=undefined;
    InceptionDate: string=undefined;
    TerminationDate: string=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    ModifyDate: string=undefined;
    ModifyUser: string=undefined;
    Created: string=undefined;
    Modified: string=undefined;
    ScheduleType: string=undefined;
    Identifier: string=undefined;
    IncludeOnBalanceSheet: boolean=undefined;
    BalanceSheetCategoryId: number=undefined;
    Configuration: string=undefined;
    GroupId: number=undefined;
    GroupName: string=undefined;
    GroupVendorSpecific: boolean = undefined;
}
export class AgreementsForInvoiceReference {
    AgreementId: number=undefined;
    Name: string=undefined;
    BankAccountNameDisplay: string=undefined;
    OwnerId: number=undefined;
    VendorId: number=undefined;
    ScheduleType: string=undefined;
    Schedule: string=undefined;
    Amount: number=undefined;
    HasScheduledItems: boolean=undefined;
    GroupId: number=undefined;
    AggregateUnits: boolean=undefined;
    UnitsLabel: string=undefined;
    NotifyThresholdMinimum: number=undefined;
    NotifyEmail: string=undefined;
    DefaultUnitsMethod: string=undefined;
    DefaultUnitsMultiplier: number=undefined;
    WorkflowId: number=undefined;
    PaysAutomatically: boolean=undefined;
    TerminationDate: string=undefined;
    InceptionDate: string=undefined;
    InvoiceDateToTermStatus: string = undefined;
}
export class AgreementTransactions {
    MatchedTransactionId: number=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    InvoiceId: number=undefined;
    Invoice: string=undefined;
    TransactionId: number=undefined;
    ScheduledAmount: number=undefined;
    BankTransactionAmount: number=undefined;
    PaidAmount: number=undefined;
    FullBankTransactionAmount: number=undefined;
    MatchedTransactionAllocation: number=undefined;
    PostedDate: string=undefined;
    Description: string=undefined;
    EntityURL: string=undefined;
    TransactionURL: string=undefined;
    InvoiceDescription: string=undefined;
    InvoiceDisplay: string=undefined;
    Date: string=undefined;
    AgreementAmount: number=undefined;
    Source: string=undefined;
    ShowPledgeFields: boolean=undefined;
    InvoiceDate: string=undefined;
    ScheduleDate: string = undefined;
}
export class AgreementTypes {
    Id: number=undefined;
    Name: string=undefined;
    Configuration: string=undefined;
    IsConfigurationValid: number = undefined;
}
export class Allocation {
    Id: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    Amount: number=undefined;
    AccountId: number=undefined;
    COAId: number=undefined;
    AccountName: string=undefined;
    AccountPath: string=undefined;
    DepartmentId: number=undefined;
    DepartmentName: string=undefined;
    DisplayModified: string=undefined;
    ModifyOrCreateDate: string=undefined;
    ModifyOrCreateUserFirstLast: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    ChartOfAccountsArray: string=undefined;
    OwnerId: number=undefined;
    Pct: number = undefined;
}
export class AmexTransactionImportPossibleDuplicates {
    Source: string=undefined;
    Id: number=undefined;
    BankAccountId: number=undefined;
    PostedDateDATE: string=undefined;
    Amount: number=undefined;
    Description: string = undefined;
}
export class AmexTransactionImportProcesses {
    LogId: number=undefined;
    CreateDate: string=undefined;
    CompleteDate: string=undefined;
    Success: boolean=undefined;
    Imported: number=undefined;
    ImportedMinDate: string=undefined;
    ImportedMaxDate: string=undefined;
    Processed: number=undefined;
    ProcessedMinDate: string=undefined;
    ProcessedMaxDate: string=undefined;
    Matched: number=undefined;
    ShortUser: string=undefined;
    BankNameNumberMasked: string = undefined;
}
export class BankAccountList {
    BankAccountId: number=undefined;
    Client: string=undefined;
    ClientId: number=undefined;
    Name: string=undefined;
    NameNumberMasked: string=undefined;
    DisplayName: string=undefined;
    Description: string=undefined;
    OwnerName: string=undefined;
    OwnerId: number=undefined;
    Number: string=undefined;
    Type: string=undefined;
    IsKeyAccount: boolean=undefined;
    DashboardOrder: number=undefined;
    AcquiredDate: string=undefined;
    ClosedDate: string=undefined;
    BankId: number=undefined;
    BankName: string=undefined;
    BankNameNumberMasked: string=undefined;
    BalanceSheetCategoryId: number=undefined;
    NumberDisplayMasked: string=undefined;
    AlertMinimumBalance: number=undefined;
    AlertMinimumBalance0: number=undefined;
    COADisplay: string=undefined;
    COAId: number=undefined;
    ManualTransactions: boolean=undefined;
    PrimaryAccountForOwner: boolean=undefined;
    MaskNameType: string=undefined;
    CreateDateLabel: string=undefined;
    ModifyDateLabel: string=undefined;
    PrimaryNumber: string=undefined;
    Earliest: string=undefined;
    Latest: string=undefined;
    Transactions: number=undefined;
    NotOwned: boolean = undefined;
}
export class BankAccountSigners {
    AssociationId: number=undefined;
    BankAccountId: number=undefined;
    KeyPersonId: number=undefined;
    KeyPersonLastFirst: string=undefined;
    Limit: number=undefined;
    LimitExternal: number = undefined;
}
export class BankAccountStatistics {
    BankAccountId: number=undefined;
    Earliest: string=undefined;
    Latest: string=undefined;
    Transactions: number = undefined;
}
export class BankAccountTransactionsForCash {
    Client: string=undefined;
    ClientId: number=undefined;
    OwnerName: string=undefined;
    OwnerId: number=undefined;
    TransactionId: number=undefined;
    BankAccountId: number=undefined;
    BankAccountName: string=undefined;
    NumberDisplayMasked: string=undefined;
    TransactionDate: string=undefined;
    PostedDate: string=undefined;
    Amount: number=undefined;
    Description: string=undefined;
    Source: string=undefined;
    ShowInvestmentTransactionDetails: boolean=undefined;
    CostBase: number=undefined;
    IncomeCashBase: number=undefined;
    PrincipalCashBase: number=undefined;
    Units: number=undefined;
    CUSIPNumber: string=undefined;
    TickerSymbol: string=undefined;
    TypeofTransaction: string=undefined;
    TransactionShortDescription: string=undefined;
    CardHolder: string=undefined;
    MatchedTransactions: number=undefined;
    Deletable: boolean=undefined;
    DisableMatches: boolean=undefined;
    DisabledMatchReason: string=undefined;
    MatchedAmount: number=undefined;
    InvestmentMatchedAmount: number=undefined;
    AvailableToMatch: number=undefined;
    Status: string = undefined;
}
export class BankBalanceImportPossibleIssues {
    Issue: string=undefined;
    AccountName: string=undefined;
    AccountNumber: string=undefined;
    AccountType: string=undefined;
    Balance: number=undefined;
    Available: number = undefined;
}
export class BankBalanceImportProcesses {
    LogId: number=undefined;
    CreateDate: string=undefined;
    CompleteDate: string=undefined;
    Success: boolean=undefined;
    Imported: number=undefined;
    Processed: number=undefined;
    ShortUser: string = undefined;
}
export class BankDepositoryAccountReconciliations {
    BankAccountId: number=undefined;
    BankNameNumberMasked: string=undefined;
    Type: string=undefined;
    ReconciliationId: number=undefined;
    StartDate: string=undefined;
    EndDate: string=undefined;
    BankBeginningBalance: number=undefined;
    BankEndingBalance: number=undefined;
    Change: number=undefined;
    VAMChange: number=undefined;
    CurrentVAMChange: number=undefined;
    VAMTransactions: number=undefined;
    Status: string=undefined;
    ReconciliationCreatedLabel: string=undefined;
    FileId: string=undefined;
    DrivePath: string=undefined;
    StoreId: number=undefined;
    ClientId: number=undefined;
    Client: string=undefined;
    ExpectedFileName: string = undefined;
}
export class BankTransactionImportPossibleDuplicates {
    Source: string=undefined;
    Id: number=undefined;
    BankAccountId: number=undefined;
    PostedDateDATE: string=undefined;
    Amount: number=undefined;
    Description: string = undefined;
}
export class BankTransactionImportProcesses {
    LogId: number=undefined;
    CreateDate: string=undefined;
    CompleteDate: string=undefined;
    Success: boolean=undefined;
    Imported: number=undefined;
    ImportedMinDate: string=undefined;
    ImportedMaxDate: string=undefined;
    Processed: number=undefined;
    ProcessedMinDate: string=undefined;
    ProcessedMaxDate: string=undefined;
    Matched: number=undefined;
    ShortUser: string=undefined;
    DeleteDate: string=undefined;
    DeleteUser: string = undefined;
}
export class CashChartOfAccounts {
    Id: number=undefined;
    COAName: string=undefined;
    COADisplay: string = undefined;
}
export class CashTransfers {
    Id: number=undefined;
    FromBankAccountId: number=undefined;
    FromBankAccount: string=undefined;
    FromOwner: string=undefined;
    FromOwnerId: number=undefined;
    ToBankAccountId: number=undefined;
    ToBankAccount: string=undefined;
    ToOwner: string=undefined;
    ToOwnerId: number=undefined;
    Amount: number=undefined;
    Date: string=undefined;
    Description: string=undefined;
    FromAccountId: number=undefined;
    FromChartOfAccounts: string=undefined;
    FromDepartmentId: number=undefined;
    FromClient: string=undefined;
    FromClientId: number=undefined;
    ToAccountId: number=undefined;
    ToChartOfAccounts: string=undefined;
    ToDepartmentId: number=undefined;
    ToClient: string=undefined;
    ToClientId: number=undefined;
    StatusId: number=undefined;
    Status: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    Created: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Modified: string=undefined;
    TypeId: number=undefined;
    Type: string=undefined;
    TypeDescription: string=undefined;
    IncludeInBalanceCalculation: boolean=undefined;
    Selectable: boolean=undefined;
    FirstApprovalId: number=undefined;
    Editable: boolean = undefined;
}
export class CashTransferTypes {
    Id: number=undefined;
    Name: string=undefined;
    Flow: string=undefined;
    FromCoAId: number=undefined;
    ToCoAId: number=undefined;
    Description: string = undefined;
}
export class ChaseCreditCardTransactionImportPossibleDuplicates {
    Source: string=undefined;
    Id: number=undefined;
    BankAccountId: number=undefined;
    PostedDateDATE: string=undefined;
    Amount: number=undefined;
    Description: string = undefined;
}
export class ChaseCreditCardTransactionImportProcesses {
    LogId: number=undefined;
    CreateDate: string=undefined;
    CompleteDate: string=undefined;
    Success: boolean=undefined;
    Imported: number=undefined;
    ImportedMinDate: string=undefined;
    ImportedMaxDate: string=undefined;
    Processed: number=undefined;
    ProcessedMinDate: string=undefined;
    ProcessedMaxDate: string=undefined;
    Matched: number=undefined;
    ShortUser: string=undefined;
    BankNameNumberMasked: string = undefined;
}
export class CustomerNamesForRecognizedInvoice {
    String: string = undefined;
}
export class EntityTypes {
    Id: number=undefined;
    EntityType: string=undefined;
    Name: string=undefined;
    ShortName: string=undefined;
    DisplayName: string=undefined;
    DisplayOrder: number=undefined;
    ShowJSON: string = undefined;
}
export class Frequencies {
    Id: number=undefined;
    Name: string = undefined;
}
export class InvoiceIngestionStores {
    Id: number=undefined;
    Name: string=undefined;
    DriveId: string=undefined;
    ItemId: string=undefined;
    NextStoreId: number=undefined;
    ClientId: number=undefined;
    NextName: string=undefined;
    NextDriveId: string=undefined;
    NextItemId: string = undefined;
}
export class InvoiceRegister {
    ClientId: number=undefined;
    InvoiceId: number=undefined;
    VendorInvoiceNumber: string=undefined;
    Notes: string=undefined;
    InvoiceDate: string=undefined;
    DueDate: string=undefined;
    Amount: number=undefined;
    _InvoiceAmount: number=undefined;
    OwnerId: number=undefined;
    OwnerName: string=undefined;
    VendorId: number=undefined;
    VendorName: string=undefined;
    Status: string=undefined;
    Outstanding: boolean=undefined;
    PostedDate: string=undefined;
    BankTransactionAmount: number=undefined;
    AllocatedBankTransactionAmount: number=undefined;
    Description: string=undefined;
    BankAccount: string=undefined;
    BankAccountName: string=undefined;
    COAId: number=undefined;
    ChartOfAccountsPath: string=undefined;
    DepartmentId: number=undefined;
    Department: string=undefined;
    URL: string=undefined;
    AmountForAgreement: number=undefined;
    AgreementId: number=undefined;
    AgreementURL: string = undefined;
}
export class Invoices {
    InvoiceId: number=undefined;
    OwnerId: number=undefined;
    Date: string=undefined;
    DueDate: string=undefined;
    VendorId: number=undefined;
    VendorName: string=undefined;
    VendorInvoiceNumber: string=undefined;
    ReferenceEntityId: number=undefined;
    ReferenceEntityType: string=undefined;
    Amount: number=undefined;
    Notes: string=undefined;
    FileId: string=undefined;
    DriveId: string=undefined;
    StatusId: number=undefined;
    StatusText: string=undefined;
    PaidDate: string=undefined;
    CompletedDateDisplay: string=undefined;
    CompletedDate: string=undefined;
    PaidUserId: string=undefined;
    PaidMethodId: number=undefined;
    PaidMethodText: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Client: string=undefined;
    ClientId: number=undefined;
    CreateDateLabel: string=undefined;
    ModifyDateLabel: string=undefined;
    CompletedDateLabel: string=undefined;
    CompletedNotes: string=undefined;
    PaidDateLabel: string=undefined;
    PaidDateSetLabel: string=undefined;
    ReferenceEntityName: string=undefined;
    OwnerName: string=undefined;
    BankAccountId: number=undefined;
    BankAccountNameDisplay: string=undefined;
    IsComplete: boolean=undefined;
    AutoPay: boolean=undefined;
    AllocationStatusText: string=undefined;
    RowClass: string=undefined;
    TransactionMatched: boolean=undefined;
    ConfirmedTransactionMatched: boolean=undefined;
    AgreementId: number=undefined;
    ScheduleItemId: number=undefined;
    ScheduleDate: string=undefined;
    ScheduleAmount: number=undefined;
    ParentReferenceEntityId: number=undefined;
    ParentReferenceEntityType: string=undefined;
    AgreementGroup: string=undefined;
    Units: number=undefined;
    UnitsDisplay: number=undefined;
    UnitsLabel: string=undefined;
    AggregateUnits: boolean=undefined;
    NotifyThresholdMinimum: number=undefined;
    NotifyEmail: string=undefined;
    Deleteable: boolean=undefined;
    DisableMatches: boolean=undefined;
    DisabledMatchReason: string=undefined;
    ApprovalId: number=undefined;
    Approved: boolean=undefined;
    ApprovalKeyPerson: string=undefined;
    DestinationPath: string=undefined;
    DestinationFolder: string=undefined;
    FYIId: number=undefined;
    NotifyApproversOnMatch: boolean=undefined;
    NotifyFYIOnMatch: boolean=undefined;
    MatchNotificationSent: string=undefined;
    BypassInvoiceReview: boolean=undefined;
    ReviewLogId: number=undefined;
    ReviewedBy: string=undefined;
    ReviewedOn: string=undefined;
    Reviewed: string=undefined;
    SelectableForReview: boolean=undefined;
    IsLoading: boolean=undefined;
    Approvers: number=undefined;
    FYIs: number=undefined;
    ApprovalSetDate: string=undefined;
    LockAgreementSelection: boolean=undefined;
    IsTripInvoice: boolean=undefined;
    FXUpdates: number=undefined;
    AutoIngestFrom: string=undefined;
    AutoIngestSubject: string=undefined;
    AutoIngestMessageId: string=undefined;
    RecognizedInvoiceJSONId: number=undefined;
    AutoIngestFileCreateUser: string=undefined;
    AutoIngestFileName: string = undefined;
}
export class InvoiceStatusForPaymentDashboard {
    StatusId: number=undefined;
    Status: string = undefined;
}
export class JointBankAccountOwners {
    OwnerId: number=undefined;
    Name: string=undefined;
    JointBankAccountOwnerId: number = undefined;
}
export class JPMCreditCardTransactionImportPossibleDuplicates {
    Source: string=undefined;
    Id: number=undefined;
    BankAccountId: number=undefined;
    PostedDateDATE: string=undefined;
    Amount: number=undefined;
    Description: string = undefined;
}
export class JPMCreditCardTransactionImportProcesses {
    LogId: number=undefined;
    CreateDate: string=undefined;
    CompleteDate: string=undefined;
    Success: boolean=undefined;
    Imported: number=undefined;
    ImportedMinDate: string=undefined;
    ImportedMaxDate: string=undefined;
    Processed: number=undefined;
    ProcessedMinDate: string=undefined;
    ProcessedMaxDate: string=undefined;
    Matched: number=undefined;
    ShortUser: string = undefined;
}
export class LayoutHierarchy {
    LayoutId: number=undefined;
    LayoutGroupId: number=undefined;
    Name: string=undefined;
    TreeLevel: number=undefined;
    TreePath: string=undefined;
    NamePath: string=undefined;
    ParentId: number=undefined;
    DisplayOrder: number=undefined;
    AlternateDisplay: string=undefined;
    Factor: number=undefined;
    MaxTreeLevel: number = undefined;
}
export class LogIds {
    Id: number=undefined;
    LogId: number=undefined;
    RunTime: string=undefined;
    RunTimeWithId: string=undefined;
    EventId: number=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    CompleteDate: string=undefined;
    Milliseconds: number=undefined;
    Database: string=undefined;
    Schema: string=undefined;
    Caller: string=undefined;
    Event: string=undefined;
    Success: boolean=undefined;
    Machine: string=undefined;
    Seconds: number=undefined;
    Minutes: number=undefined;
    Hours: number=undefined;
    CreateDateET: string=undefined;
    CompleteDateET: string=undefined;
    RowNum: number=undefined;
    LiquidityScorecardSentToClient: boolean=undefined;
    LiquidityScorecardSentToClientOn: string=undefined;
    LiquidityScorecardSentToClientBy: string=undefined;
    LiquidityScorecardSentToClientKeyId: number=undefined;
    SPID: number = undefined;
}
export class ManualAccountTransaction {
    Id: number=undefined;
    BankAccountId: number=undefined;
    Amount: number=undefined;
    TransactionDate: string=undefined;
    PostedDate: string=undefined;
    Description: string=undefined;
    Source: string=undefined;
    SourceId: number=undefined;
    SourceEntityType: string = undefined;
}
export class MatchedTransaction {
    MatchedTransactionId: number=undefined;
    PostedDate: string=undefined;
    Description: string=undefined;
    Amount: number=undefined;
    Account: string=undefined;
    AutoMatched: string=undefined;
    ConfirmedBy: string=undefined;
    ConfirmedAt: string=undefined;
    ReviewForTax: boolean=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    BankNameNumberMasked: string=undefined;
    BankAccountId: number=undefined;
    BankTransactionId: number = undefined;
}
export class MatchesForTransaction {
    MatchedTransactionId: number=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    TransactionId: number=undefined;
    PostedAmount: number=undefined;
    PostedDate: string=undefined;
    Description: string=undefined;
    AccountId: number=undefined;
    NumberDisplayMasked: string=undefined;
    AccountName: string=undefined;
    AccountOwnerId: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    SearchTermId: number=undefined;
    AutoMatchLogId: number=undefined;
    VendorId: number=undefined;
    VendorName: string=undefined;
    AutoMatchedDescription: string=undefined;
    AutoMatchRequiresConfirmation: number=undefined;
    AutoMatchTime: string=undefined;
    MatchConfirmed: string=undefined;
    IsConfirmed: number=undefined;
    SearchTermText: string=undefined;
    SearchTermEntityType: string=undefined;
    SearchTermEntityId: number=undefined;
    InvestmentId: number=undefined;
    AgreementId: number=undefined;
    NoteId: number=undefined;
    ReviewForTax: boolean=undefined;
    DepartmentId: number=undefined;
    ChartOfAccountsArray: string=undefined;
    EntityTypeDisplay: string=undefined;
    EntityTypeDisplayShort: string=undefined;
    MatchedDescription: string=undefined;
    ScheduledDate: string=undefined;
    ScheduledAmount: number=undefined;
    Multiplier: number=undefined;
    TransferId: number=undefined;
    Comment: string=undefined;
    PreviouslyMatchedAmount: number=undefined;
    LegacyCount: number=undefined;
    NewCount: number = undefined;
}
export class PledgeAgreement {
    AgreementId: number=undefined;
    Name: string=undefined;
    OwnerId: number=undefined;
    OwnerName: string=undefined;
    ClientId: number=undefined;
    InceptionDate: string=undefined;
    TerminationDate: string=undefined;
    Commitment: number=undefined;
    ScheduledPayments: number=undefined;
    CashAmount: number=undefined;
    CashTransactions: number=undefined;
    ClearedAmount: number=undefined;
    ClearedTransactions: number=undefined;
    RemainingCommitment: number=undefined;
    BalanceSheetCategoryId: number=undefined;
    LastTransaction: string=undefined;
    ActualTermination: string=undefined;
    ScheduleType: string = undefined;
}
export class RelatedMatches {
    MatchedTransactionId: number=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    TransactionId: number=undefined;
    Amount: number=undefined;
    PostedDate: string=undefined;
    Description: string=undefined;
    AccountId: number=undefined;
    NumberDisplayMasked: string=undefined;
    AccountName: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ReviewForTax: boolean=undefined;
    MatchedDescription: string=undefined;
    EntityTypeDisplayShort: string=undefined;
    AutoMatchTime: string=undefined;
    MatchConfirmed: string=undefined;
    Comment: string = undefined;
}
export class ReserveImport {
    Id: number=undefined;
    ExcelCellImportConfigurationId: number=undefined;
    ClientId: number=undefined;
    Amount: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ClientName: string=undefined;
    NewAmount: number=undefined;
    Path: string=undefined;
    Created: string = undefined;
}
export class ScheduledPayments {
    Client: string=undefined;
    Owner: string=undefined;
    EntityType: string=undefined;
    EntityTypeDisplay: string=undefined;
    EntityId: number=undefined;
    ScheduleDate: string=undefined;
    Amount: number=undefined;
    Description: string=undefined;
    ScheduleType: string=undefined;
    VendorInvoiceNumber: string=undefined;
    DueDate: string=undefined;
    StatusText: string=undefined;
    FileId: string=undefined;
    DriveId: string=undefined;
    ReferenceName: string=undefined;
    ReferenceEntityId: number=undefined;
    ReferenceEntityType: string=undefined;
    OrderDate: string=undefined;
    EntityURL: string=undefined;
    ReferenceURL: string=undefined;
    Source: string=undefined;
    BankAccountId: number=undefined;
    BankNameNumberMasked: string=undefined;
    BankAccountMask: string=undefined;
    DefaultPaymentMethod: string=undefined;
    VendorName: string=undefined;
    ProcessingAdjustment: number=undefined;
    DateToProcess: string=undefined;
    DefaultPaymentMethodMissing: boolean=undefined;
    VendorId: number=undefined;
    VendorUrl: string=undefined;
    KeyEntityId: number=undefined;
    KeyEntityType: string=undefined;
    KeyId: string=undefined;
    Selectable: boolean=undefined;
    FirstApprovalId: number=undefined;
    PaysAutomatically: boolean=undefined;
    ReviewSelectable: boolean=undefined;
    AlreadyInApprovalyBatch: boolean=undefined;
    AlreadyInApprovalBatch: boolean=undefined;
    AlreadyInApprovalId: number=undefined;
    ApprovalItemId: number=undefined;
    InvoiceUrl: string=undefined;
    AgreementId: number=undefined;
    AgreementName: string=undefined;
    InvoiceId: number = undefined;
}
export class ScheduleItem {
    Id: number=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    ScheduleNumber: number=undefined;
    ScheduleDate: string=undefined;
    Frequency: string=undefined;
    Amount: number=undefined;
    RangeStart: string=undefined;
    RangeEnd: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Modified: string=undefined;
    Matches: number=undefined;
    MatchedDate: string=undefined;
    MatchStatus: string=undefined;
    Invoices: number=undefined;
    AgreementMatches: number=undefined;
    AgreementMatchDate: string=undefined;
    InvoiceMatches: number=undefined;
    InvoiceMatchDate: string = undefined;
}
export class ScheduleItemTransactions {
    AgreementId: number=undefined;
    ScheduleItemId: number=undefined;
    ScheduleDate: string=undefined;
    ScheduleAmount: number=undefined;
    PostedDate: string=undefined;
    Amount: number=undefined;
    Description: string=undefined;
    TransactionId: number=undefined;
    VendorInvoiceNumber: string=undefined;
    InvoiceId: number = undefined;
}
export class Schedules {
    Id: number=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    Amount: number=undefined;
    FrequencyId: number=undefined;
    Name: string=undefined;
    SeedDate: string=undefined;
    NumberOfPayments: number=undefined;
    TotalOfPayments: number=undefined;
    LastPaymentDate: string=undefined;
    PaysAutomatically: boolean=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Created: string=undefined;
    Modified: string=undefined;
    EndTypeDescription: string = undefined;
}
export class SearchTerms {
    Id: number=undefined;
    EntityId: number=undefined;
    EntityType: string=undefined;
    Text: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    SysStartTime: string=undefined;
    SysEndTime: string=undefined;
    MinAmount: number=undefined;
    MaxAmount: number=undefined;
    DepartmentId: number=undefined;
    EntityDescription: string=undefined;
    UseMinAmount: boolean=undefined;
    UseMaxAmount: boolean = undefined;
}
export class SourcesAndUsesReport {
    Heading: string=undefined;
    Grouping: string=undefined;
    HeadingId: number=undefined;
    GroupingId: number=undefined;
    Amount: number=undefined;
    Items: number=undefined;
    CashUsesAndSourcesGroupId: number=undefined;
    CashUsesAndSourcesGroup: string=undefined;
    DisplayOrder: number=undefined;
    ABSAmount: number=undefined;
    HeadingTotal: number=undefined;
    PctOfTotal: number = undefined;
}
export class TransactionsV2 {
    Client: string=undefined;
    ClientId: number=undefined;
    OwnerName: string=undefined;
    OwnerId: number=undefined;
    TransactionId: number=undefined;
    TransactionIdDisplay: string=undefined;
    AccountName: string=undefined;
    NumberDisplayMasked: string=undefined;
    TransactionDate: string=undefined;
    PostedDate: string=undefined;
    Amount: number=undefined;
    Description: string=undefined;
    Status: string=undefined;
    TagType: string=undefined;
    Comments: number=undefined;
    LastComment: string=undefined;
    MatchedTransactions: number=undefined;
    Deletable: boolean=undefined;
    Source: string=undefined;
    AccountId: number=undefined;
    CostBase: number=undefined;
    IncomeCashBase: number=undefined;
    PrincipalCashBase: number=undefined;
    Units: number=undefined;
    CUSIPNumber: string=undefined;
    TickerSymbol: string=undefined;
    TypeofTransaction: string=undefined;
    TransactionShortDescription: string=undefined;
    ShowInvestmentTransactionDetails: boolean=undefined;
    AmountRank: number=undefined;
    MatchURL: string=undefined;
    AccountType: string=undefined;
    IsManualTransaction: boolean=undefined;
    OwnerHasManualBankAccounts: boolean=undefined;
    DisableMatches: boolean=undefined;
    DisabledMatchReason: string=undefined;
    CardHolder: string = undefined;
}
export class TransferSourceBankAccounts {
    FromBankAccountId: number=undefined;
    FromBankAccount: string = undefined;
}
export class QueueProcessCashFlow {
    Id: number=undefined;
    EventId: number=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    CompleteDate: string=undefined;
    Milliseconds: number=undefined;
    Success: boolean=undefined;
    Database: string=undefined;
    Schema: string=undefined;
    Caller: string=undefined;
    Event: string=undefined;
    Machine: string=undefined;
    SPID: number=undefined;
    CreateDateLocal: string=undefined;
    Seconds: number=undefined;
    Minutes: number=undefined;
    CompleteDateLocal: string=undefined;
    DeleteDate: string=undefined;
    DeleteUser: string=undefined;
    QueueId: number = undefined;
}
export class QueueProcessHistory {
    Id: number=undefined;
    EventId: number=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    CompleteDate: string=undefined;
    Milliseconds: number=undefined;
    Success: boolean=undefined;
    Database: string=undefined;
    Schema: string=undefined;
    Caller: string=undefined;
    Event: string=undefined;
    Machine: string=undefined;
    SPID: number=undefined;
    CreateDateLocal: string=undefined;
    Seconds: number=undefined;
    Minutes: number=undefined;
    CompleteDateLocal: string=undefined;
    DeleteDate: string=undefined;
    DeleteUser: string=undefined;
    QueueId: number = undefined;
}
export class ResendApprovalEmail {
    NotificationEntityId: number=undefined;
    NotificationEntityType: string=undefined;
    Items: number=undefined;
    HasApprovals: boolean=undefined;
    HasFYIs: boolean=undefined;
    HasResend: boolean=undefined;
    HasInfo: boolean=undefined;
    FullName: string = undefined;
}
export class SendPaymentConfirmationEmailAndSMS {
    SMSId: number = undefined;
}
export class UpdateInvoiceAmountForExchanceRate {
    InvoiceId: number=undefined;
    OwnerId: number=undefined;
    Date: string=undefined;
    DueDate: string=undefined;
    VendorId: number=undefined;
    VendorName: string=undefined;
    VendorInvoiceNumber: string=undefined;
    ReferenceEntityId: number=undefined;
    ReferenceEntityType: string=undefined;
    Amount: number=undefined;
    Notes: string=undefined;
    FileId: string=undefined;
    DriveId: string=undefined;
    StatusId: number=undefined;
    StatusText: string=undefined;
    PaidDate: string=undefined;
    CompletedDateDisplay: string=undefined;
    CompletedDate: string=undefined;
    PaidUserId: string=undefined;
    PaidMethodId: number=undefined;
    PaidMethodText: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Client: string=undefined;
    ClientId: number=undefined;
    CreateDateLabel: string=undefined;
    ModifyDateLabel: string=undefined;
    CompletedDateLabel: string=undefined;
    CompletedNotes: string=undefined;
    PaidDateLabel: string=undefined;
    PaidDateSetLabel: string=undefined;
    ReferenceEntityName: string=undefined;
    OwnerName: string=undefined;
    BankAccountId: number=undefined;
    BankAccountNameDisplay: string=undefined;
    IsComplete: boolean=undefined;
    AutoPay: boolean=undefined;
    AllocationStatusText: string=undefined;
    RowClass: string=undefined;
    TransactionMatched: boolean=undefined;
    ConfirmedTransactionMatched: boolean=undefined;
    AgreementId: number=undefined;
    ScheduleItemId: number=undefined;
    ScheduleDate: string=undefined;
    ScheduleAmount: number=undefined;
    ParentReferenceEntityId: number=undefined;
    ParentReferenceEntityType: string=undefined;
    AgreementGroup: string=undefined;
    Units: number=undefined;
    UnitsDisplay: number=undefined;
    UnitsLabel: string=undefined;
    AggregateUnits: boolean=undefined;
    NotifyThresholdMinimum: number=undefined;
    NotifyEmail: string=undefined;
    Deleteable: boolean=undefined;
    DisableMatches: boolean=undefined;
    DisabledMatchReason: string=undefined;
    ApprovalId: number=undefined;
    Approved: boolean=undefined;
    ApprovalKeyPerson: string=undefined;
    DestinationPath: string=undefined;
    DestinationFolder: string=undefined;
    FYIId: number=undefined;
    NotifyApproversOnMatch: boolean=undefined;
    NotifyFYIOnMatch: boolean=undefined;
    MatchNotificationSent: string=undefined;
    BypassInvoiceReview: boolean=undefined;
    ReviewLogId: number=undefined;
    ReviewedBy: string=undefined;
    ReviewedOn: string=undefined;
    Reviewed: string=undefined;
    SelectableForReview: boolean=undefined;
    IsLoading: boolean=undefined;
    Approvers: number=undefined;
    FYIs: number=undefined;
    ApprovalSetDate: string=undefined;
    LockAgreementSelection: boolean=undefined;
    IsTripInvoice: boolean=undefined;
    FXUpdates: number=undefined;
    AutoIngestFrom: string=undefined;
    AutoIngestSubject: string=undefined;
    AutoIngestMessageId: string=undefined;
    RecognizedInvoiceJSONId: number=undefined;
    AutoIngestFileCreateUser: string=undefined;
    AutoIngestFileName: string = undefined;
}
export class ValidateVendorInvoiceNumber {
    Date: string=undefined;
    DueDate: string=undefined;
    Amount: number=undefined;
    Notes: string=undefined;
    StatusText: string=undefined;
    CreateDateLabel: string=undefined;
    VendorName: string = undefined;
}

// Parameter interfaces:

export interface AdjustAllocationForScheduleTypeChangeParameters {
    AgreementId: number;
}

export interface AllocateAgreementParameters {
    AgreementId: number;
}

export interface CompleteInvoiceParameters {
    InvoiceId: number;
    StatusId: number;
    CompletedNotes: string;
    PaidDate: string;
    CompletedDate: string;
}

export interface ConfirmTransactionMatchParameters {
    TransactionId: number;
    EntityId: number;
    EntityType: string;
    ReviewForTax: boolean;
    DepartmentId: number;
    MatchedTransactionId: number;
    UpdateInvestmentTransactionAmount: boolean;
    Comment: string;
}

export interface CopyAgreementParameters {
    AgreementId: number;
}

export interface CreateCharitableInvoiceParameters {
    CharitableAgreementId: number;
    StatusId: number;
}

export interface CreateInvoiceEmailsParameters {
    LogId: number;
}

export interface CreateManualInvoiceBankTransactionParameters {
    InvoiceId: number;
    BankAccountId: number;
}

export interface GetAccountsParameters {
    ClientId: number;
    OrderBy: string;
    AccountType: string;
    AccountId: number;
    OwnerId: number;
    COAId: number;
    ManualAccountsOnly: boolean;
}

export interface GetAgreementBudgetParameters {
    AgreementId: number;
}

export interface GetAgreementGroupsParameters {
    ClientId: number;
    VendorId: number;
    AggregateUnitsOnly: boolean;
}

export interface GetAgreementGroupUnitBalanceParameters {
    AgreementGroupId: number;
}

export interface GetAgreementsParameters {
    ClientId: number;
    AgreementTypeId: number;
    VendorId: number;
    AgreementId: number;
    OwnerId: number;
    ExcludeBudgeted: boolean;
    GroupId: number;
    CurrentOnly: boolean;
}

export interface GetAgreementsDisplayParameters {
    ClientId: number;
    VendorId: number;
    AgreementId: number;
    OwnerId: number;
    ExcludeBudgeted: boolean;
    GroupId: number;
}

export interface GetAgreementsForInvoiceReferenceParameters {
    OwnerId: number;
    VendorId: number;
    ExcludeBudgeted: boolean;
    InvoiceDate: string;
}

export interface GetAgreementTransactionsParameters {
    AgreementId: number;
}

export interface GetAllocationParameters {
    EntityType: string;
    EntityId: number;
}

export interface GetAmexTransactionImportPossibleDuplicatesParameters {
    LogId: number;
}

export interface GetBankAccountListParameters {
    ClientId: number;
    AccountType: string;
    AccountId: number;
    OwnerId: number;
    COAId: number;
    ManualAccountsOnly: boolean;
    BankId: number;
}

export interface GetBankAccountSignersParameters {
    BankAccountId: number;
}

export interface GetBankAccountStatisticsParameters {
    BankAccountId: number;
}

export interface GetBankAccountTransactionsForCashParameters {
    DateRangeStart: string;
    DateRangeEnd: string;
    BankAccountId: number;
    TransactionId: number;
    PromissoryNoteId: number;
    Amount: number;
}

export interface GetBankBalanceImportPossibleIssuesParameters {
    LogId: number;
}

export interface GetBankDepositoryAccountReconciliationsParameters {
    ClientId: number;
    Year: number;
    Month: number;
    ReconciliationId: number;
}

export interface GetBankTransactionImportPossibleDuplicatesParameters {
    LogId: number;
}

export interface GetCashChartOfAccountsParameters {
    ClientId: number;
}

export interface GetCashTransfersParameters {
    TransferId: number;
    StatusId: number;
    IncludeInBalanceOnly: boolean;
    FromBankAccountId: number;
    ToBankAccountId: number;
    ExcludeFuture: boolean;
    ClientId: number;
    ApprovalId: number;
}

export interface GetChaseCreditCardTransactionImportPossibleDuplicatesParameters {
    LogId: number;
}

export interface GetCustomerNamesForRecognizedInvoiceParameters {
    RecognizedInvoiceId: number;
}

export interface GetEntityTypesParameters {
    Form: string;
}

export interface GetExpectedUsesAndSourcesParameters {
    ClientId: number;
    ConfigurationId: number;
}

export interface GetInvoiceIngestionStoresParameters {
    ClientId: number;
}

export interface GetInvoiceRegisterParameters {
    DepartmentIdList: string;
    AccountIdList: string;
    VendorIdList: string;
    OwnerIdList: string;
    ClientIdList: string;
    Outstanding: boolean;
    InvoiceDateRangeStart: string;
    InvoiceDateRangeEnd: string;
    InvoiceDueDateRangeStart: string;
    InvoiceDueDateRangeEnd: string;
    PostedDateRangeStart: string;
    PostedDateRangeEnd: string;
    InvoiceId: number;
}

export interface GetInvoicesParameters {
    ClientId: number;
    OwnerId: number;
    VendorId: number;
    StatusId: number;
    InvoiceId: number;
    RequiredApprovalUserId: string;
    VendorInvoiceNumber: string;
    AgreementId: number;
    FileId: string;
    ReviewOutstandingOnly: boolean;
}

export interface GetJointBankAccountOwnersParameters {
    BankAccountId: number;
}

export interface GetJPMCreditCardTransactionImportPossibleDuplicatesParameters {
    LogId: number;
}

export interface GetLayoutHierarchyParameters {
    LayoutGroupId: number;
    LayoutId: number;
    OutermostLeafOnly: boolean;
}

export interface GetLogIdsParameters {
    Database: string;
    Schema: string;
    Caller: string;
    Event: string;
    KeyName: string;
    KeyInt: number;
    KeyVarChar: string;
    KeyDate: string;
    CompletedOnly: boolean;
    LogId: number;
    IncludeIncompleteRegardlessOfMilliseconds: boolean;
    MinMilliseconds: number;
    MaxRows: number;
    EventId: number;
    SuccessOnly: boolean;
}

export interface GetManualAccountTransactionParameters {
    ManualAccountTransactionId: number;
}

export interface GetMatchedTransactionParameters {
    EntityId: number;
    EntityType: string;
}

export interface GetMatchesForTransactionParameters {
    TransactionId: number;
    MatchedTransactionId: number;
    UseNewInvestmentTransactions: boolean;
}

export interface GetPledgeAgreementParameters {
    AgreementId: number;
}

export interface GetReferenceEntityParameters {
    EntityType: string;
    OwnerId: number;
    VendorId: number;
    InvestmentIdList: string;
    MatchedTransactionId: number;
    TransactionIdToMatch: number;
    AgreementId: number;
    LookupDate: string;
    NoteId: number;
    IgnoreDateWindow: boolean;
}

export interface GetRelatedMatchesParameters {
    TransactionId: number;
}

export interface GetScheduledPaymentsParameters {
    ClientId: number;
    ToDate: string;
    ApprovalId: number;
}

export interface GetScheduleItemParameters {
    EntityType: string;
    EntityId: number;
    ScheduleItemId: number;
}

export interface GetScheduleItemTransactionsParameters {
    EntityId: number;
    EntityType: string;
}

export interface GetSchedulesParameters {
    EntityType: string;
    EntityId: number;
    ScheduleId: number;
}

export interface GetSearchTermsParameters {
    SearchTermId: number;
    EntityId: number;
    EntityType: string;
}

export interface GetSourcesAndUsesReportParameters {
    ClientId: number;
    RangeStart: string;
    RangeEnd: string;
    ConfigurationId: number;
}

export interface GetTransactionsV2Parameters {
    ClientId: number;
    OwnerId: number;
    TransactionId: number;
    DateRangeStart: string;
    DateRangeEnd: string;
    AccountId: number;
    UnmatchedOnly: boolean;
}

export interface GetTransferSourceBankAccountsParameters {
    ToBankAccountId: number;
    CashTransferTypeId: number;
}

export interface ImportReserveParameters {
    ExcelCellImportConfigurationId: number;
    Amount: number;
}

export interface ProcessAmexTransactionImportParameters {
    LogId: number;
    ExcludeList: string;
}

export interface ProcessBankBalanceImportParameters {
    LogId: number;
    ExcludeList: string;
}

export interface ProcessBankTransactionImportParameters {
    LogId: number;
    ExcludeList: string;
}

export interface ProcessChaseCreditCardTransactionImportParameters {
    LogId: number;
    ExcludeList: string;
}

export interface ProcessHistoryParameters {
    LiquidityDate: string;
    LiquidityEnd: string;
    LogId: number;
}

export interface ProcessJPMCreditCardTransactionImportParameters {
    LogId: number;
    ExcludeList: string;
}

export interface QueueProcessCashFlowParameters {
    LogId: number;
}

export interface QueueProcessHistoryParameters {
    LogId: number;
}

export interface RejectScheduledPaymentParameters {
    ApprovalItemId: number;
    Comment: string;
    NotifyBatchCreator: boolean;
}

export interface ResendApprovalEmailParameters {
    GUID: string;
}

export interface SendPaymentConfirmationEmailAndSMSParameters {
    ApprovalId: number;
}

export interface UpdateInvoiceAmountForExchanceRateParameters {
    InvoiceId: number;
    OldAmount: number;
    NewAmount: number;
}

export interface ValidateVendorInvoiceNumberParameters {
    VendorId: number;
    VendorInvoiceNumber: string;
    InvoiceId: number;
}

export class LiqService {

  AdjustAllocationForScheduleTypeChange (params: AdjustAllocationForScheduleTypeChangeParameters): Promise<AdjustAllocationForScheduleTypeChange[]> {
    return smartData({
        storedProcName: 'liq.AdjustAllocationForScheduleTypeChange',
        params: params
      });
    }

  AllocateAgreement (params: AllocateAgreementParameters): Promise<AllocateAgreement[]> {
    return smartData({
        storedProcName: 'liq.AllocateAgreement',
        params: params
      });
    }

  CompleteInvoice (params: CompleteInvoiceParameters): Promise<CompleteInvoice[]> {
    return smartData({
        storedProcName: 'liq.CompleteInvoice',
        params: params
      });
    }

  ConfirmTransactionMatch (params: ConfirmTransactionMatchParameters): Promise<ConfirmTransactionMatch[]> {
    return smartData({
        storedProcName: 'liq.ConfirmTransactionMatch',
        params: params
      });
    }

  CopyAgreement (params: CopyAgreementParameters): Promise<CopyAgreement[]> {
    return smartData({
        storedProcName: 'liq.CopyAgreement',
        params: params
      });
    }

  CreateCharitableInvoice (params: CreateCharitableInvoiceParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'liq.CreateCharitableInvoice',
        params: params
      });
    }

  CreateInvoiceEmails (params: CreateInvoiceEmailsParameters): Promise<CreateInvoiceEmails[]> {
    return smartData({
        storedProcName: 'liq.CreateInvoiceEmails',
        params: params
      });
    }

  CreateManualInvoiceBankTransaction (params: CreateManualInvoiceBankTransactionParameters): Promise<CreateManualInvoiceBankTransaction[]> {
    return smartData({
        storedProcName: 'liq.CreateManualInvoiceBankTransaction',
        params: params
      });
    }

  GetAccounts (params: GetAccountsParameters): Promise<Accounts[]> {
    return smartData({
        storedProcName: 'liq.GetAccounts',
        params: params
      });
    }

  GetAgreementBudget (params: GetAgreementBudgetParameters): Promise<AgreementBud[]> {
    return smartData({
        storedProcName: 'liq.GetAgreementBudget',
        params: params
      });
    }

  GetAgreementGroups (params: GetAgreementGroupsParameters): Promise<AgreementGroups[]> {
    return smartData({
        storedProcName: 'liq.GetAgreementGroups',
        params: params
      });
    }

  GetAgreementGroupUnitBalance (params: GetAgreementGroupUnitBalanceParameters): Promise<AgreementGroupUnitBalance[]> {
    return smartData({
        storedProcName: 'liq.GetAgreementGroupUnitBalance',
        params: params
      });
    }

  GetAgreements (params: GetAgreementsParameters): Promise<Agreements[]> {
    return smartData({
        storedProcName: 'liq.GetAgreements',
        params: params
      });
    }

  GetAgreementsDisplay (params: GetAgreementsDisplayParameters): Promise<AgreementsDisplay[]> {
    return smartData({
        storedProcName: 'liq.GetAgreementsDisplay',
        params: params
      });
    }

  GetAgreementsForInvoiceReference (params: GetAgreementsForInvoiceReferenceParameters): Promise<AgreementsForInvoiceReference[]> {
    return smartData({
        storedProcName: 'liq.GetAgreementsForInvoiceReference',
        params: params
      });
    }

  GetAgreementTransactions (params: GetAgreementTransactionsParameters): Promise<AgreementTransactions[]> {
    return smartData({
        storedProcName: 'liq.GetAgreementTransactions',
        params: params
      });
    }

  GetAgreementTypes (): Promise<AgreementTypes[]> {
    return smartData({
        storedProcName: 'liq.GetAgreementTypes',
        params: {}
      });
    }

  GetAllocation (params: GetAllocationParameters): Promise<Allocation[]> {
    return smartData({
        storedProcName: 'liq.GetAllocation',
        params: params
      });
    }

  GetAmexTransactionImportPossibleDuplicates (params: GetAmexTransactionImportPossibleDuplicatesParameters): Promise<AmexTransactionImportPossibleDuplicates[]> {
    return smartData({
        storedProcName: 'liq.GetAmexTransactionImportPossibleDuplicates',
        params: params
      });
    }

  GetAmexTransactionImportProcesses (): Promise<AmexTransactionImportProcesses[]> {
    return smartData({
        storedProcName: 'liq.GetAmexTransactionImportProcesses',
        params: {}
      });
    }

  GetBankAccountList (params: GetBankAccountListParameters): Promise<BankAccountList[]> {
    return smartData({
        storedProcName: 'liq.GetBankAccountList',
        params: params
      });
    }

  GetBankAccountSigners (params: GetBankAccountSignersParameters): Promise<BankAccountSigners[]> {
    return smartData({
        storedProcName: 'liq.GetBankAccountSigners',
        params: params
      });
    }

  GetBankAccountStatistics (params: GetBankAccountStatisticsParameters): Promise<BankAccountStatistics[]> {
    return smartData({
        storedProcName: 'liq.GetBankAccountStatistics',
        params: params
      });
    }

  GetBankAccountTransactionsForCash (params: GetBankAccountTransactionsForCashParameters): Promise<BankAccountTransactionsForCash[]> {
    return smartData({
        storedProcName: 'liq.GetBankAccountTransactionsForCash',
        params: params
      });
    }

  GetBankBalanceImportPossibleIssues (params: GetBankBalanceImportPossibleIssuesParameters): Promise<BankBalanceImportPossibleIssues[]> {
    return smartData({
        storedProcName: 'liq.GetBankBalanceImportPossibleIssues',
        params: params
      });
    }

  GetBankBalanceImportProcesses (): Promise<BankBalanceImportProcesses[]> {
    return smartData({
        storedProcName: 'liq.GetBankBalanceImportProcesses',
        params: {}
      });
    }

  GetBankDepositoryAccountReconciliations (params: GetBankDepositoryAccountReconciliationsParameters): Promise<BankDepositoryAccountReconciliations[]> {
    return smartData({
        storedProcName: 'liq.GetBankDepositoryAccountReconciliations',
        params: params
      });
    }

  GetBankTransactionImportPossibleDuplicates (params: GetBankTransactionImportPossibleDuplicatesParameters): Promise<BankTransactionImportPossibleDuplicates[]> {
    return smartData({
        storedProcName: 'liq.GetBankTransactionImportPossibleDuplicates',
        params: params
      });
    }

  GetBankTransactionImportProcesses (): Promise<BankTransactionImportProcesses[]> {
    return smartData({
        storedProcName: 'liq.GetBankTransactionImportProcesses',
        params: {}
      });
    }

  GetCashChartOfAccounts (params: GetCashChartOfAccountsParameters): Promise<CashChartOfAccounts[]> {
    return smartData({
        storedProcName: 'liq.GetCashChartOfAccounts',
        params: params
      });
    }

  GetCashTransfers (params: GetCashTransfersParameters): Promise<CashTransfers[]> {
    return smartData({
        storedProcName: 'liq.GetCashTransfers',
        params: params
      });
    }

  GetCashTransferTypes (): Promise<CashTransferTypes[]> {
    return smartData({
        storedProcName: 'liq.GetCashTransferTypes',
        params: {}
      });
    }

  GetChaseCreditCardTransactionImportPossibleDuplicates (params: GetChaseCreditCardTransactionImportPossibleDuplicatesParameters): Promise<ChaseCreditCardTransactionImportPossibleDuplicates[]> {
    return smartData({
        storedProcName: 'liq.GetChaseCreditCardTransactionImportPossibleDuplicates',
        params: params
      });
    }

  GetChaseCreditCardTransactionImportProcesses (): Promise<ChaseCreditCardTransactionImportProcesses[]> {
    return smartData({
        storedProcName: 'liq.GetChaseCreditCardTransactionImportProcesses',
        params: {}
      });
    }

  GetCustomerNamesForRecognizedInvoice (params: GetCustomerNamesForRecognizedInvoiceParameters): Promise<CustomerNamesForRecognizedInvoice[]> {
    return smartData({
        storedProcName: 'liq.GetCustomerNamesForRecognizedInvoice',
        params: params
      });
    }

  GetEntityTypes (params: GetEntityTypesParameters): Promise<EntityTypes[]> {
    return smartData({
        storedProcName: 'liq.GetEntityTypes',
        params: params
      });
    }

  GetExpectedUsesAndSources (params: GetExpectedUsesAndSourcesParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'liq.GetExpectedUsesAndSources',
        params: params
      });
    }

  GetFrequencies (): Promise<Frequencies[]> {
    return smartData({
        storedProcName: 'liq.GetFrequencies',
        params: {}
      });
    }

  GetInvoiceIngestionStores (params: GetInvoiceIngestionStoresParameters): Promise<InvoiceIngestionStores[]> {
    return smartData({
        storedProcName: 'liq.GetInvoiceIngestionStores',
        params: params
      });
    }

  GetInvoiceRegister (params: GetInvoiceRegisterParameters): Promise<InvoiceRegister[]> {
    return smartData({
        storedProcName: 'liq.GetInvoiceRegister',
        params: params
      });
    }

  GetInvoices (params: GetInvoicesParameters): Promise<Invoices[]> {
    return smartData({
        storedProcName: 'liq.GetInvoices',
        params: params
      });
    }

  GetInvoiceStatusForPaymentDashboard (): Promise<InvoiceStatusForPaymentDashboard[]> {
    return smartData({
        storedProcName: 'liq.GetInvoiceStatusForPaymentDashboard',
        params: {}
      });
    }

  GetJointBankAccountOwners (params: GetJointBankAccountOwnersParameters): Promise<JointBankAccountOwners[]> {
    return smartData({
        storedProcName: 'liq.GetJointBankAccountOwners',
        params: params
      });
    }

  GetJPMCreditCardTransactionImportPossibleDuplicates (params: GetJPMCreditCardTransactionImportPossibleDuplicatesParameters): Promise<JPMCreditCardTransactionImportPossibleDuplicates[]> {
    return smartData({
        storedProcName: 'liq.GetJPMCreditCardTransactionImportPossibleDuplicates',
        params: params
      });
    }

  GetJPMCreditCardTransactionImportProcesses (): Promise<JPMCreditCardTransactionImportProcesses[]> {
    return smartData({
        storedProcName: 'liq.GetJPMCreditCardTransactionImportProcesses',
        params: {}
      });
    }

  GetLayoutHierarchy (params: GetLayoutHierarchyParameters): Promise<LayoutHierarchy[]> {
    return smartData({
        storedProcName: 'liq.GetLayoutHierarchy',
        params: params
      });
    }

  GetLogIds (params: GetLogIdsParameters): Promise<LogIds[]> {
    return smartData({
        storedProcName: 'liq.GetLogIds',
        params: params
      });
    }

  GetManualAccountTransaction (params: GetManualAccountTransactionParameters): Promise<ManualAccountTransaction[]> {
    return smartData({
        storedProcName: 'liq.GetManualAccountTransaction',
        params: params
      });
    }

  GetMatchedTransaction (params: GetMatchedTransactionParameters): Promise<MatchedTransaction[]> {
    return smartData({
        storedProcName: 'liq.GetMatchedTransaction',
        params: params
      });
    }

  GetMatchesForTransaction (params: GetMatchesForTransactionParameters): Promise<MatchesForTransaction[]> {
    return smartData({
        storedProcName: 'liq.GetMatchesForTransaction',
        params: params
      });
    }

  GetPledgeAgreement (params: GetPledgeAgreementParameters): Promise<PledgeAgreement[]> {
    return smartData({
        storedProcName: 'liq.GetPledgeAgreement',
        params: params
      });
    }

  GetReferenceEntity (params: GetReferenceEntityParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'liq.GetReferenceEntity',
        params: params
      });
    }

  GetRelatedMatches (params: GetRelatedMatchesParameters): Promise<RelatedMatches[]> {
    return smartData({
        storedProcName: 'liq.GetRelatedMatches',
        params: params
      });
    }

  GetReserveImport (): Promise<ReserveImport[]> {
    return smartData({
        storedProcName: 'liq.GetReserveImport',
        params: {}
      });
    }

  GetScheduledPayments (params: GetScheduledPaymentsParameters): Promise<ScheduledPayments[]> {
    return smartData({
        storedProcName: 'liq.GetScheduledPayments',
        params: params
      });
    }

  GetScheduleItem (params: GetScheduleItemParameters): Promise<ScheduleItem[]> {
    return smartData({
        storedProcName: 'liq.GetScheduleItem',
        params: params
      });
    }

  GetScheduleItemTransactions (params: GetScheduleItemTransactionsParameters): Promise<ScheduleItemTransactions[]> {
    return smartData({
        storedProcName: 'liq.GetScheduleItemTransactions',
        params: params
      });
    }

  GetSchedules (params: GetSchedulesParameters): Promise<Schedules[]> {
    return smartData({
        storedProcName: 'liq.GetSchedules',
        params: params
      });
    }

  GetSearchTerms (params: GetSearchTermsParameters): Promise<SearchTerms[]> {
    return smartData({
        storedProcName: 'liq.GetSearchTerms',
        params: params
      });
    }

  GetSourcesAndUsesReport (params: GetSourcesAndUsesReportParameters): Promise<SourcesAndUsesReport[]> {
    return smartData({
        storedProcName: 'liq.GetSourcesAndUsesReport',
        params: params
      });
    }

  GetTransactionsV2 (params: GetTransactionsV2Parameters): Promise<TransactionsV2[]> {
    return smartData({
        storedProcName: 'liq.GetTransactionsV2',
        params: params
      });
    }

  GetTransferSourceBankAccounts (params: GetTransferSourceBankAccountsParameters): Promise<TransferSourceBankAccounts[]> {
    return smartData({
        storedProcName: 'liq.GetTransferSourceBankAccounts',
        params: params
      });
    }

  ImportReserve (params: ImportReserveParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'liq.ImportReserve',
        params: params
      });
    }

  ProcessAmexTransactionImport (params: ProcessAmexTransactionImportParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'liq.ProcessAmexTransactionImport',
        params: params
      });
    }

  ProcessBankBalanceImport (params: ProcessBankBalanceImportParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'liq.ProcessBankBalanceImport',
        params: params
      });
    }

  ProcessBankTransactionImport (params: ProcessBankTransactionImportParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'liq.ProcessBankTransactionImport',
        params: params
      });
    }

  ProcessChaseCreditCardTransactionImport (params: ProcessChaseCreditCardTransactionImportParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'liq.ProcessChaseCreditCardTransactionImport',
        params: params
      });
    }

  ProcessHistory (params: ProcessHistoryParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'liq.ProcessHistory',
        params: params
      });
    }

  ProcessJPMCreditCardTransactionImport (params: ProcessJPMCreditCardTransactionImportParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'liq.ProcessJPMCreditCardTransactionImport',
        params: params
      });
    }

  QueueProcessCashFlow (params: QueueProcessCashFlowParameters): Promise<QueueProcessCashFlow[]> {
    return smartData({
        storedProcName: 'liq.QueueProcessCashFlow',
        params: params
      });
    }

  QueueProcessHistory (params: QueueProcessHistoryParameters): Promise<QueueProcessHistory[]> {
    return smartData({
        storedProcName: 'liq.QueueProcessHistory',
        params: params
      });
    }

  RejectScheduledPayment (params: RejectScheduledPaymentParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'liq.RejectScheduledPayment',
        params: params
      });
    }

  ResendApprovalEmail (params: ResendApprovalEmailParameters): Promise<ResendApprovalEmail[]> {
    return smartData({
        storedProcName: 'liq.ResendApprovalEmail',
        params: params
      });
    }

  SendPaymentConfirmationEmailAndSMS (params: SendPaymentConfirmationEmailAndSMSParameters): Promise<SendPaymentConfirmationEmailAndSMS[]> {
    return smartData({
        storedProcName: 'liq.SendPaymentConfirmationEmailAndSMS',
        params: params
      });
    }

  UpdateInvoiceAmountForExchanceRate (params: UpdateInvoiceAmountForExchanceRateParameters): Promise<UpdateInvoiceAmountForExchanceRate[]> {
    return smartData({
        storedProcName: 'liq.UpdateInvoiceAmountForExchanceRate',
        params: params
      });
    }

  ValidateVendorInvoiceNumber (params: ValidateVendorInvoiceNumberParameters): Promise<ValidateVendorInvoiceNumber[]> {
    return smartData({
        storedProcName: 'liq.ValidateVendorInvoiceNumber',
        params: params
      });
    }
}

