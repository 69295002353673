//js API for Schema vam
//Auto-generated 05/17/22 10:56
 

var mixinSchema_vam = {
    methods: {
        /** @description Get Addresses using vam.GetAddresses
         * @param {number} addressId - 
         * @param {boolean} orderByAddress - 
         */
		 vam_GetAddresses: function (addressId, orderByAddress) {
			/*
				this.Addresses = this.vam_GetAddresses(
					null // addressId
					, null // orderByAddress
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'vam.GetAddresses'
			  , params: {
					AddressId: addressId
					, OrderByAddress: orderByAddress
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ChartOfAccountsForTree using vam.GetChartOfAccountsForTree
         * @param {number} clientId - 
         * @param {number} accountId - 
         * @param {string} namePathSearch - 
         * @param {boolean} sourceOnly - 
         */
		, vam_GetChartOfAccountsForTree: function (params, onSuccess, onError, async) {
			/*
				this.ChartOfAccountsForTree = await this.vam_GetChartOfAccountsForTree({
					ClientId: clientId
					, AccountId: accountId
					, NamePathSearch: namePathSearch
					, SourceOnly: sourceOnly
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetChartOfAccountsForTree'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Contacts using vam.GetContacts
         * @param {string} entityType - 
         * @param {number} entityId - 
         * @param {number} contactType - 
         */
		, vam_GetContacts: function (entityType, entityId, contactType) {
			/*
				this.Contacts = this.vam_GetContacts(
					null // entityType
					, null // entityId
					, null // contactType
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'vam.GetContacts'
			  , params: {
					EntityType: entityType
					, EntityId: entityId
					, ContactType: contactType
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Currencies using vam.GetCurrencies
         * @param {string} searchTerm - 
         */
		, vam_GetCurrencies: function (params, onSuccess, onError, async) {
			/*
				this.Currencies = await this.vam_GetCurrencies({
					SearchTerm: searchTerm
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetCurrencies'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Departments using vam.GetDepartments
         * @param {number} clientId - 
         * @param {number} departmentId - 
         */
		, vam_GetDepartments: function (params, onSuccess, onError, async) {
			/*
				this.Departments = await this.vam_GetDepartments({
					ClientId: clientId
					, DepartmentId: departmentId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetDepartments'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get EntityForeignStates using vam.GetEntityForeignStates
         * @param {number} entityId - 
         */
		, vam_GetEntityForeignStates: function (entityId) {
			/*
				this.EntityForeignStates = this.vam_GetEntityForeignStates(
					null // entityId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'vam.GetEntityForeignStates'
			  , params: {
					EntityId: entityId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get EntityUses using vam.GetEntityUses
         * @param {number} entityId - 
         */
		, vam_GetEntityUses: function (params, onSuccess, onError, async) {
			/*
				this.EntityUses = await this.vam_GetEntityUses({
					EntityId: entityId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetEntityUses'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get ExcelCellImportConfiguration using vam.GetExcelCellImportConfiguration
         * @param {number} importTypeId - 
         * @param {number} configurationId - 
         */
		, vam_GetExcelCellImportConfiguration: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.vam_GetExcelCellImportConfiguration({
					ImportTypeId: importTypeId
					, ConfigurationId: configurationId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'vam.GetExcelCellImportConfiguration'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get ExcelCellImportConfiguration using vam.GetExcelCellImportConfiguration; Return Single Object
         * @param {number} importTypeId - 
         * @param {number} configurationId - 
         */
		, vam_GetExcelCellImportConfiguration_Object: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.vam_GetExcelCellImportConfiguration_Object({
					ImportTypeId: importTypeId
					, ConfigurationId: configurationId
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'vam.GetExcelCellImportConfiguration'
				, params: params
				, loadAsync: loadAsync
				});
				if (ds.length == 1)
						return ds[0];
				else
						return null;
			}
        /** @description Get NEW ExcelCellImportConfiguration using columns of vam.GetExcelCellImportConfiguration
         */
		, vam_GetExcelCellImportConfiguration_New: function () {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.vam_GetExcelCellImportConfiguration_New();
			*/
					return {
						Id: null
						, ImportType: null
						, ClientId: null
						, Description: null
						, Description2: null
						, Description2Label: null
						, IntDescription: null
						, IntDescriptionLabel: null
						, DriveId: null
						, FileId: null
						, SheetName: null
						, Cell: null
						, FullPath: null
						, DisplayOrder: null
						, EntityType: null
						, EntityId: null
						, EntityType2: null
						, EntityId2: null
						, ImportTypeId: null
						, ClientName: null
						, Created: null
						, Modified: null
						};
			}
        /** @description Get ExcelCellImportTypes using vam.GetExcelCellImportTypes
         */
		, vam_GetExcelCellImportTypes: function (params, onSuccess, onError, async) {
			/*
				let el = this; // "this" is not recognized inside the callback functions.  Use el.
				this.vam_GetExcelCellImportTypes({
					}
					,function(data){
						console.log(data);
					}
					, function(error){
						console.error(error);
					}
					, false // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? false : async;
			var ds = smartData({
				storedProcName: 'vam.GetExcelCellImportTypes'
				, params: params
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get KeyPersonClients using vam.GetKeyPersonClients
         * @param {number} keyPersonId - 
         */
		, vam_GetKeyPersonClients: function (keyPersonId) {
			/*
				this.KeyPersonClients = this.vam_GetKeyPersonClients(
					null // keyPersonId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'vam.GetKeyPersonClients'
			  , params: {
					KeyPersonId: keyPersonId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get KeyPersons using vam.GetKeyPersons
         * @param {number} clientId - 
         * @param {number} keyPersonId - 
         * @param {boolean} hasEmailAddress - 
         * @param {boolean} isApproverOnly - 
         */
		, vam_GetKeyPersons: function (clientId, keyPersonId, hasEmailAddress, isApproverOnly) {
			/*
				this.KeyPersons = this.vam_GetKeyPersons(
					null // clientId
					, null // keyPersonId
					, null // hasEmailAddress
					, null // isApproverOnly
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'vam.GetKeyPersons'
			  , params: {
					ClientId: clientId
					, KeyPersonId: keyPersonId
					, HasEmailAddress: hasEmailAddress
					, IsApproverOnly: isApproverOnly
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Markdown using vam.GetMarkdown
         * @param {string} name - 
         */
		, vam_GetMarkdown: function (params, onSuccess, onError, async) {
			/*
				this.Markdown = await this.vam_GetMarkdown({
					Name: name
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetMarkdown'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get OutstandingVendorVerifications using vam.GetOutstandingVendorVerifications
         * @param {number} keyPersonId - 
         * @param {number} vendorId - 
         */
		, vam_GetOutstandingVendorVerifications: function (params, onSuccess, onError, async) {
			/*
				this.OutstandingVendorVerifications = await this.vam_GetOutstandingVendorVerifications({
					KeyPersonId: keyPersonId
					, VendorId: vendorId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetOutstandingVendorVerifications'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get OwnerCategories using vam.GetOwnerCategories
         */
		, vam_GetOwnerCategories: function () {
			/*
				this.OwnerCategories = this.vam_GetOwnerCategories(
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'vam.GetOwnerCategories'
			  , params: {
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get OwnerRoleAssignments using vam.GetOwnerRoleAssignments
         * @param {number} ownerId - 
         * @param {number} roleTypeId - 
         */
		, vam_GetOwnerRoleAssignments: function (ownerId, roleTypeId) {
			/*
				this.OwnerRoleAssignments = this.vam_GetOwnerRoleAssignments(
					null // ownerId
					, null // roleTypeId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'vam.GetOwnerRoleAssignments'
			  , params: {
					OwnerId: ownerId
					, RoleTypeId: roleTypeId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get OwnersList using vam.GetOwnersList
         * @param {number} clientId - 
         * @param {string} clientIdList - 
         * @param {string} usesList - 
         * @param {string} considerationEntityType - 
         */
		, vam_GetOwnersList: function (params, onSuccess, onError, async) {
			/*
				this.OwnersList = await this.vam_GetOwnersList({
					ClientId: clientId
					, ClientIdList: clientIdList
					, UsesList: usesList
					, ConsiderationEntityType: considerationEntityType
					}
					, true // async (optional)
				);
			*/

		if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length > 0) {
                params.ClientIdList = params.ClientIdList.join(',');
            }
			else if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length == 0) {
				params.ClientIdList = null;
			}
		if (typeof params.UsesList === "object" && params.UsesList != null && params.UsesList.length > 0) {
                params.UsesList = params.UsesList.join(',');
            }
			else if (typeof params.UsesList === "object" && params.UsesList != null && params.UsesList.length == 0) {
				params.UsesList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetOwnersList'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get OwnersV2 using vam.GetOwnersV2
         * @param {number} clientId - 
         * @param {string} clientIdList - 
         * @param {number} ownerId - 
         */
		, vam_GetOwnersV2: function (params, onSuccess, onError, async) {
			/*
				this.OwnersV2 = await this.vam_GetOwnersV2({
					ClientId: clientId
					, ClientIdList: clientIdList
					, OwnerId: ownerId
					}
					, true // async (optional)
				);
			*/

		if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length > 0) {
                params.ClientIdList = params.ClientIdList.join(',');
            }
			else if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length == 0) {
				params.ClientIdList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetOwnersV2'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get RegisteredAgents using vam.GetRegisteredAgents
         * @param {string} state - 
         * @param {number} stateId - 
         */
		, vam_GetRegisteredAgents: function (state, stateId) {
			/*
				this.RegisteredAgents = this.vam_GetRegisteredAgents(
					null // state
					, null // stateId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'vam.GetRegisteredAgents'
			  , params: {
					State: state
					, StateId: stateId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get SingleMemberEntityAgreementData using vam.GetSingleMemberEntityAgreementData
         * @param {number} ownerId - 
         */
		, vam_GetSingleMemberEntityAgreementData: function (params, onSuccess, onError, async) {
			/*
				this.SingleMemberEntityAgreementData = await this.vam_GetSingleMemberEntityAgreementData({
					OwnerId: ownerId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetSingleMemberEntityAgreementData'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get States using vam.GetStates
         * @param {number} countryId - 
         */
		, vam_GetStates: function (countryId) {
			/*
				this.States = this.vam_GetStates(
					null // countryId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'vam.GetStates'
			  , params: {
					CountryId: countryId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get UserClients using vam.GetUserClients
         */
		, vam_GetUserClients: function (params, onSuccess, onError, async) {
			/*
				this.UserClients = await this.vam_GetUserClients({
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetUserClients'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get VendorClients using vam.GetVendorClients
         * @param {number} vendorId - 
         */
		, vam_GetVendorClients: function (params, onSuccess, onError, async) {
			/*
				this.VendorClients = await this.vam_GetVendorClients({
					VendorId: vendorId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetVendorClients'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Vendors using vam.GetVendors
         * @param {number} clientId - 
         * @param {string} searchTerm - 
         * @param {boolean} addNew - 
         * @param {string} clientIdList - 
         * @param {number} vendorId - 
         * @param {boolean} isCharitable - 
         */
		, vam_GetVendors: function (params, onSuccess, onError, async) {
			/*
				this.Vendors = await this.vam_GetVendors({
					ClientId: clientId
					, SearchTerm: searchTerm
					, AddNew: addNew
					, ClientIdList: clientIdList
					, VendorId: vendorId
					, IsCharitable: isCharitable
					}
					, true // async (optional)
				);
			*/

		if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length > 0) {
                params.ClientIdList = params.ClientIdList.join(',');
            }
			else if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length == 0) {
				params.ClientIdList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'vam.GetVendors'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get Vendors using vam.GetVendors; Return Single Object
         * @param {number} clientId - 
         * @param {string} searchTerm - 
         * @param {boolean} addNew - 
         * @param {string} clientIdList - 
         * @param {number} vendorId - 
         * @param {boolean} isCharitable - 
         */
		, vam_GetVendors_Object: function (params, onSuccess, onError, async) {
			/*
				this.Vendors = await this.vam_GetVendors_Object({
					ClientId: clientId
					, SearchTerm: searchTerm
					, AddNew: addNew
					, ClientIdList: clientIdList
					, VendorId: vendorId
					, IsCharitable: isCharitable
					}
					, true // async (optional)
				);
			*/

		if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length > 0) {
                params.ClientIdList = params.ClientIdList.join(',');
            }
			else if (typeof params.ClientIdList === "object" && params.ClientIdList != null && params.ClientIdList.length == 0) {
				params.ClientIdList = null;
			}
			var loadAsync = (async === undefined) ? true : async;
			var returnValue = smartData({
				storedProcName: 'vam.GetVendors'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        if (data && data.length == 1)
                            onSuccess(data[0]);
                        else
                            onSuccess({});
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});

            if (loadAsync) {
                returnValue = returnValue.then(function(data) {
                    if (data && data.length === 1) {
                        return data[0];
                    }
                    else {
                        return {};
                    }
                });
            }
			return returnValue;
			}
        /** @description Get NEW Vendors using columns of vam.GetVendors
         */
		, vam_GetVendors_New: function () {
			/*
				this.Vendors = await this.vam_GetVendors_New();
			*/
					return {
						Id: null
						, Name: null
						, PaymentNotes: null
						, Notes: null
						, W9Required: null
						, W9RequiredCheckMark: null
						, HasW9: null
						, HasW9CheckMark: null
						, W9Satisfied: null
						, TaxpayerId: null
						, TaxpayerIdTypeId: null
						, TaxpayerIdType: null
						, TaxpayerIdTypeAbbreviation: null
						, TaxpayerIdMask: null
						, TaxpayerIdTypeIRSCode: null
						, StreetAddress: null
						, City: null
						, State: null
						, Zip: null
						, ClientCount: null
						, AgreementCount: null
						, InvoiceCount: null
						, Deletable: null
						, TaxClassification: null
						, IsCharitable: null
						, AnnualLetterNotRequired: null
						, HasAnnualLetter: null
						, DocumentDateAnnualLetter: null
						, AnnualLetterRequirementSatisfied: null
						, Editable: null
						, ord: null
						, Source: null
						, DefaultPaymentMethodId: null
						, DefaultPaymentMethod: null
						, HasVendorForm: null
						, VendorFormNotRequired: null
						, VendorFormMissing: null
						, VendorFormMissingCheckMark: null
						, SourceTypeId: null
						, SourceType: null
						, SourceTypeDescription: null
						, SourceVerificationRequired: null
						, SourceEntityType: null
						, SourceEntityId: null
						, SourceName: null
						, SourceVerificationResponseId: null
						, SourceVerificationResponse: null
						, IsVerified: null
						, IsVerificationException: null
						, VerificationDescription: null
						};
			}
        /** @description Get VendorsNotAssignedToClient using vam.GetVendorsNotAssignedToClient
         * @param {number} clientId - 
         */
		, vam_GetVendorsNotAssignedToClient: function (clientId) {
			/*
				this.VendorsNotAssignedToClient = this.vam_GetVendorsNotAssignedToClient(
					null // clientId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'vam.GetVendorsNotAssignedToClient'
			  , params: {
					ClientId: clientId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
    }
};
export default mixinSchema_vam;
