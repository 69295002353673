 <template>
    <el-row>
        <el-card 
            :shadow="(viewType === 'card' ? 'always' : 'never')" 
            :body-style="(viewType === 'card' ? '' : { padding: '0' })"
            :style="(viewType === 'card' ? '' : { border: 'none' })">
            <div v-if="viewType === 'card'" slot="header" class="clearfix">
                <span>Change Log</span>
            </div>
            <el-row v-loading.body="loading">
                <el-col>
                    <el-table
                            ref="refChangeLogList"
                            :data="changeLogList"
                            row-key="Id">
                        <el-table-column label="Field"
                                        sortable
                                        prop="Field">
                        </el-table-column>
                        <el-table-column label="Before"
                                        sortable
                                        :formatter="formatChangeValue"
                                        prop="BeforeValue">
                        </el-table-column>
                        <el-table-column label="After"
                                        sortable
                                        :formatter="formatChangeValue"
                                        prop="AfterValue">
                        </el-table-column>
                        <el-table-column label="Name"
                                        sortable
                                        prop="FirstLast">
                        </el-table-column>
                        <el-table-column label="Change Date"
                                        sortable
                                        :formatter="formatterShortDateTime"
                                        prop="ChangeDate">
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-card>
    </el-row>
</template>
<script>
    import Vue from 'vue';
    import mixinSchema_system from './../DAL/mixinSchema_system'
    import utility from './../VueCommon/Utility.js' //has formatters

    export default Vue.extend({
        name: 'ChangeLogList'
        , mixins: [mixinSchema_system, utility]
        , props: {
            schema: {
                required: true
                , type: String
            }
            , table: {
                required: true
                ,type: String
            }
            , field: {
                required: false
                , type: String
            }
            , entityId: {
                required: true
                , type: Number
            }
            , viewType: {
                required: false
                , type: String
            }
        }
        , data: function () {
            return {
                changeLogList: []
                , loading: false
            }
        }
        , created: function () {
            this.fetchChangeLog();
        }
        , mounted: function () {
        }
        , methods: {
            fetchChangeLog: async function () {
                this.loading = true;
                var el = this;
                this.system_GetChangeLog({
                    Schema: this.schema
                    , Table: this.table
                    , Field: this.field
                    , EntityId: this.entityId
                }
                    , function (data) {
                        el.changeLogList = data;
                        el.loading = false;
                    }
                    , function (error) {
                        console.log(error);
                        el.changeLogList = [];
                    });
            }
            , formatChangeValue(row, column) {
                if (row[column.property]) {//if it has any value at all...
                    if (row[column.property].length >= 6 && row[column.property].length < 25 && moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).isValid()) {
                        // > 6 because moment will treat a number like a date; <25 because it will also just pick a date out of a whole sentence.
                        if (moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format('H:mm:ss') == '0:00:00') {//time = midnight, so it's really just a date
                            return moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format('MM/DD/YY');
                        }
                        else {
                            return moment(row[column.property], ['MM/DD/YYYY hh:mm:ss a', 'MM/DD/YYYY']).format('MM/DD/YY H:mm');
                        }
                    }
                    else if ((row.Field == 'Amount' || row.Field == 'Cost' || row.Field == 'Price') && this._isNumber(this._toNumber(row[column.property])) ) {
                        return numbro(this._toNumber(row[column.property])).formatCurrency();
                    }
                    else if ((this._isNumber(this._toNumber(row[column.property]))) && !isNaN(this._toNumber(row[column.property]))) { //toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
                        return numbro(this._toNumber(row[column.property])).format({ thousandSeparated: true});
                    }
                    else {
                        return row[column.property];
                    }
                }
                else {
                    return '';
                }
            }
        }
    })
</script>
<style >
    .el-table .warning-row {
        background: oldlace;
    }

    .el-table td, .el-table th {
        padding-bottom: 5px;
        padding-top: 5px;
    }
</style>
