
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import * as TransactionsService from "@/services/DAL/transactionsService";
import Common from "@/utils/common";
import formatters from "@/utils/formatters";
import CapitalEdit from '@/views/InvestmentTransaction/Capital/CapitalEdit.vue'
import { ElForm } from "element-ui/types/form";
import ElementUI from "element-ui";
import uniqBy from 'lodash/uniqBy';

declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
  components: {
    CapitalEdit
  },
})
export default class CapitalList extends Vue {
  $refs: {
    frmCapitalList: ElementUI.Form;
  };
  //#region Private declarations for Services
  private _transactionsService: TransactionsService.TransactionsService;
  private common: Common;
  public formatters: formatters;

  //#endregion Private declarations for Services

  //#region Props
  @Prop() readonly header: TransactionsService.Header;
  @Prop() readonly considerationType: TransactionsService.ConsiderationTypes;
  @Prop({ type: Number }) readonly investmentId: number;
  @Prop({ type: Number }) readonly upToButExcludingTransactionHeaderId: number;
  @Prop({ type: Boolean, default: false }) readonly addNew: boolean;
  @Prop({ type: Boolean, default: false }) readonly hideTitles: boolean;
  @Prop({ type: Boolean, default: false }) readonly allowEdit: boolean;
  @Prop([Number, Array]) readonly capitalTypeId: number | Array<number>;
  @Prop({ type: Boolean, default: true }) readonly showTableFilter: boolean;
  @Prop({ type: Boolean, default: false }) readonly showColumnFilters: boolean;
  //#endregion Props

  //#region Data
  securityLevel_: number = null;
  loading = false;
  clientId_: number = null;
  entityType_: string = null;
  showDate = false;
  filterCapital: string = "";
  capital = [] as TransactionsService.Capital[];
  selectedItem = {} as TransactionsService.Capital;
  showItemEdit = false;
  addNew_: boolean = false;
  newCapital = {} as TransactionsService.Capital;

  //#endregion Data

  //#region Lifecycle
  async created() {
    this._transactionsService = new TransactionsService.TransactionsService();
    this.formatters = new formatters();
    this.common = new Common();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
      0
    );
    if (this.header && Object.keys(this.header).length) {
      this.clientId_ = this.header.ClientId;
    }
    if (this.considerationType && Object.keys(this.considerationType).length) {
      this.entityType_ = this.considerationType.EntityType;
    }
    this.addNew_ = this.addNew;
    this.fetchCapital();
  }
  async mounted() {
    this.initiateNewCapital(true);
  }
  //#endregion Lifecycle
    @Watch('investmentId')
    onChange_investmentId(val: number, oldVal: number) {
        if (oldVal !== undefined) {
            this.fetchCapital();
        }
    }
    @Watch('addNew')
    onChange_addNew(val: boolean, oldVal: boolean) {
        this.addNew_ = val;
    }

  //#region Watches

  //#endregion Watches
  get capitalFiltered(): TransactionsService.Capital[] {
    const filter_ = (this.filterCapital || "").toLowerCase();
    return this.capital.filter((entity) => {
      return (
        (entity.Id || "").toString().toLowerCase().indexOf(filter_) > -1 ||
        (entity.CapitalType || "").toString().toLowerCase().indexOf(filter_) >
          -1 ||
        (entity.Amount || "").toString().toLowerCase().indexOf(filter_) > -1 ||
        (entity.Investment || "").toString().toLowerCase().indexOf(filter_) >
          -1 ||
        (entity.HeaderDescription || "")
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1
      );
    });
  }
  // get newCapital(): TransactionsService.Capital{
  //     const nc = new TransactionsService.Capital();
  //     this.$nullifyObjectProps(nc);
  //     nc.OwnerId = (this.header && Object.keys(this.header).length) ? this.header.BaseOwnerId : undefined;
  //     nc.Amount = this.header.NetAmount;
  //     nc.InvestmentId = this.investmentId ? this.investmentId : (this.header && Object.keys(this.header).length) ? this.header.BaseInvestmentId : undefined;
  //     nc.TransactionHeaderId = this.header.Id;
  //     if (!Array.isArray(this.capitalTypeId)){
  //       nc.CapitalTypeId = this.capitalTypeId;
  //     }
  //     return nc;
  // }
  get showInvestmentColumn(): boolean{
    return !this.investmentId;
  }
  get showTypeColumn(): boolean{
    return Array.isArray(this.capitalTypeId) || !this.capitalTypeId;
  }
  //#region Methods

  async fetchCapital() {
    this.loading = true;
    this.capital = [];
    if (this.investmentId || (this.header && Object.keys(this.header).length)){
        const params = {} as TransactionsService.GetCapitalParameters;
        params.InvestmentId = this.investmentId;
        params.TransactionHeaderId = (this.header && Object.keys(this.header).length) ? this.header.Id : undefined;
        params.UpToButExcludingTransactionHeaderId = this.upToButExcludingTransactionHeaderId;
        if (!Array.isArray(this.capitalTypeId)){
          params.CapitalTypeId = this.capitalTypeId;
        }
        else {
          params.CapitalTypeIdList = this.capitalTypeId.join();
        }
        this.capital = await this._transactionsService.GetCapital(params);
        const total = this.capital.reduce((prev, cur) => prev + cur.Amount, 0);
        this.$emit('total', total);
    }
    this.loading = false;
  }
  async editCapitalItem(
    selectedRow: TransactionsService.Capital,
  ) {
    if (selectedRow && selectedRow != null && this.allowEdit) {
      this.selectedItem = selectedRow;
      this.showItemEdit = true;
    }
  }
  getSummary(param) {
    const colNum = 4 + (this.showInvestmentColumn ? 1 : 0) + (this.showTypeColumn ? 1 : 0);
    const columnList = [colNum];
    const totalLabel = "Total";
    const totalLabelIndex = 0;
    const formats = new Map();
    formats.set(colNum, "currency");
    const result = this.common.getSummaryArray(
      param,
      columnList,
      totalLabel,
      totalLabelIndex,
      formats
    );
    return result;
  }
  initiateNewCapital(useAmountFromHeader: boolean = false){
    this.newCapital = new TransactionsService.Capital();
      this.$nullifyObjectProps(this.newCapital);
      this.newCapital.OwnerId = (this.header && Object.keys(this.header).length) ? this.header.BaseOwnerId : undefined;
      if (useAmountFromHeader) this.newCapital.Amount = this.header.NetAmount;
      this.newCapital.InvestmentId = this.investmentId ? this.investmentId : (this.header && Object.keys(this.header).length) ? this.header.BaseInvestmentId : undefined;
      this.newCapital.TransactionHeaderId = this.header.Id;
      if (!Array.isArray(this.capitalTypeId)){
        this.newCapital.CapitalTypeId = this.capitalTypeId;
      }
      
  }
  async itemSaved(){
    //this.addNew_ = false; // if it was true before, no longer; /* 12-28-2021 18:02 CAMPBED: removing this. if we can add new, you should be able to add another new. doing so to be able to add multiple new Cap Commitments. */
    /* 12-28-2021 18:08 CAMPBED: reset new Capital */
    this.initiateNewCapital(false);
    this.addNew_ = false;
    await this.$nextTick();
    this.addNew_ = true;
    this.fetchCapital();
  }
  async closeDialogAndRefresh(){
    this.showItemEdit = false; 
    this.selectedItem = {} as TransactionsService.Capital;
    this.fetchCapital();
  }
  filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
  }
  filters(column){
      const filters = this.capital.map(function(list) {
          return {
              text: list[column]
              , value: list[column]
              };
      });
      return uniqBy(filters, 'value');
  }

  //#endregion Methods
}
