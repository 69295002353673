
import { Watch, Prop, Model, Component, Vue } from "vue-property-decorator";
import * as DebtService from '../../services/DAL/debtService';
import Common from '@/utils/common';

@Component({
    inheritAttrs: false
})
export default class DebtInstrumentTransactionTypeSelector extends Vue {
    //#region Private declarations for Services
    private _debtService: DebtService.DebtService;
    private _common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() readonly value: number;
    @Prop({type: Number}) readonly instrumentId: number;
    @Prop({type: String}) readonly direction: string;
    @Prop({type: Number}) readonly transactionHeaderId: number;
    //#endregion Props

    //#region Data
    types = [] as DebtService.InstrumentTransactionType[];
    selectedObject = {} as DebtService.InstrumentTransactionType
    selectedValue: number = null;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._debtService = new DebtService.DebtService();
        this._common = new Common();
    }

    async mounted() {
        this.selectedValue = this.value;
        this.fetchTypes();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch("value")
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;

        if (this.types.length) {
            let selectedObject: DebtService.InstrumentTransactionType = new DebtService.InstrumentTransactionType();
            if (this.selectedValue) {
                selectedObject = this._common.getSelectedArrayItem(
                    this.types,
                    this.selectedValue.toString(),
                    'Id'
                );
            }
            this.getSelectedObject
        }
    }
    @Watch('selectedObject')
    onChange_selectedTransactionType(val: object, oldVal: object) {
        this.$emit('value-set', this.selectedObject);
    }


    @Watch("instrumentId")
    onChange_instrumentId(val: number, oldVal: number) {
        this.fetchTypes();
    }
    //#endregion Watches

    //#region Methods
    async fetchTypes() {
        this.types = [];
        const params = {} as DebtService.GetInstrumentTransactionTypeParameters;
        params.InstrumentId = this.instrumentId;
        params.Direction = this.direction;
        params.TransactionHeaderId = this.transactionHeaderId;
        this.types = await this._debtService.GetInstrumentTransactionType(params);
        if (this.types && this.types.length ==1) {
            this.$emit('input', this.types[0].Id);
            await this.$nextTick();
        }
        this.getSelectedObject();
    }

    getSelectedObject() {
        if (this.types.length && this.selectedValue > 0) {
            this.selectedObject = this._common.getSelectedArrayItem(this.types, this.selectedValue.toString(), 'Id');
        }
    }
    //#endregion Methods
}
