
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import ExcelImport from '@/components/ExcelImport.vue';
import formatters from '../../utils/formatters';

import * as AvService from './../../services/DAL/avService';
import * as UtilService from './../../services/DAL/utilService';
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;
declare var getDateStringFromExcelDate: any;
@Component({
  components: {
    ExcelImport
  }
})
export default class ImportJetAviationActivity extends Vue {
  //#region Private declarations for Services
  private _avService: AvService.AvService;
  private _utilService: UtilService.UtilService;
  public formatters: formatters = new formatters();

  //#endregion Private declarations for Services

  //#region Props
  //#endregion Props

  //#region Data
  importProcesses: AvService.ImportActivityJetAviation[] = [];
  loading: boolean = false;
  showExcelImport: boolean = true;
  logId: number = null;
  statusMessage: string = '';
  fieldOptions: object[] = [
    { name: 'AC Type', field: 'ACType' }, 
    { name: 'Delay Type', field: 'DelayType' }, 
    { name: 'Dept Date', field: 'DeptDate' }, 
    { name: 'Dept Day', field: 'DeptDay' }, 
    { name: 'Arrival Date', field: 'ArrivalDate' }, 
    { name: 'Leg Type', field: 'LegType' }, 
    { name: 'Flight Attendant', field: 'FlightAttendant' }, 
    { name: 'Lead PAX', field: 'LeadPAX' }, 
    { name: 'General Flight Purpose', field: 'GeneralFlightPurpose' }, 
    { name: 'Est FlightTime', field: 'EstFlightTime' }, 
    { name: 'Est FlightDec', field: 'EstFlightDec' }, 
    { name: 'Est Block', field: 'EstBlock' }, 
    { name: 'Est BlockDec', field: 'EstBlockDec' }, 
    { name: 'Act FlightTime', field: 'ActFlightTime' }, 
    { name: 'Act FlightDec', field: 'ActFlightDec' }, 
    { name: 'Block Time', field: 'BlockTime' }, 
    { name: 'Block Dec', field: 'BlockDec' }, 
    { name: 'HOBB Start', field: 'HOBBStart' }, 
    { name: 'HOBB End', field: 'HOBBEnd' }, 
    { name: 'HOBB Time', field: 'HOBBTime' }, 
    { name: 'Day Hours', field: 'DayHours' }, 
    { name: 'Night Hours', field: 'NightHours' }, 
    { name: 'Distance (NM)', field: 'DistanceNM' }, 
    { name: 'Distance (SM)', field: 'DistanceSM' }, 
    { name: 'Pax Count', field: 'PaxCount' }, 
    { name: 'Engine 1 Hours', field: 'Engine1Hours' }, 
    { name: 'Engine 2 Hours', field: 'Engine2Hours' }, 
    { name: 'Engine 3 Hours', field: 'Engine3Hours' }, 
    { name: 'APU Hours', field: 'APUHours' }, 
    { name: 'APU Cycles', field: 'APUCycles' }, 
  ];

  logParameters: object = {
    database: 'visium',
    schema: 'av',
    caller: 'JetAviationFlightDataImport',
    event: 'Import'
  };
  requiredColumns: string[] = [
    'Trip',
    'Tail'
  ];
  securityLevel_: number = null;
  

  //#endregion Data

  //#endregion Computed

  //#endregion Computed

  //#region Lifecycle
  async created() {
    this._avService = new AvService.AvService();
    this._utilService = new UtilService.UtilService();
    this.formatters = new formatters();
    this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAviationDataImport),
            0
        );
    this.getImportProcesses();
  }
  //#endregion Lifecycle
  unFormatParens(strInput) {
    return strInput
      .toString()
      .replace('(', '-')
      .replace(')', '');
  }

  async getImportProcesses() {
    this.loading = true;
    const parms = {} as AvService.GetImportActivityJetAviationParameters;
    this.importProcesses = await this._avService.GetImportActivityJetAviation(
      parms
    );
    this.loading = false;
  }
  async handleImport(result) {
    console.log(result);
    if (result.errors > 0) {
      this.$alert(
        `${result.errors} records resulted in an error when writing to the database.  Review the records with an empty Id in the last column displayed, then try again.`
      );
    } else if (result.successes > 0) {
      this.showExcelImport = false;
      this.logId = result.importLogId;
      await this.processImport();
    }
  }
  async processImport() {
    this.loading = true;
    const params = {} as AvService.ProcessImportJetAviationFlightDataParameters;
    params.ImportLogId = this.logId;
    await this._avService.ProcessImportJetAviationFlightData(params);
    this.$notify.success('Activity Processed');
    this.statusMessage = 'Refreshing Process List';
    await this.getImportProcesses();
    this.statusMessage = '';
    this.loading = false;
    this.showExcelImport = true;
  }
  selectEvent(row) {
    this.$emit('event-clicked', row.ImportLogId);
  }
  //#region Methods
}
