
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';

@Component({
    inheritAttrs: false
})
export default class FundSelector extends Vue {
    //#region Private declarations for Services
    private _assetService: AssetService.AssetService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop()
    value: number;
    @Prop() 
    clientId: number;
    //#endregion Props

    //#region Data
    options = [] as AssetService.FundOverview[];
    selectedValue: number = null;
    //#endregion Data

    //#region Computed
    //#endregion Computed

   //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchOptions();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onChange_value(val: number, oldVal: number) {
        this.selectedValue = val;
    }
    @Watch("clientId")
    onChange_clientId(val: number, oldVal: number) {
        this.fetchOptions();
    }

    //#endregion Watches

    //#region Methods
    async fetchOptions() {
        this.options = [];
        const params = {} as AssetService.GetFundOverviewParameters;
        params.ClientId = this.clientId;
        this.options = await this._assetService.GetFundOverview(params);
        this.$emit('options-fetched', this.options);
    }
    //#endregion Methods
}
