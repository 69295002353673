import IAuthService from './IAuthService.interface';
import axios from 'axios';
import * as Msal from '@azure/msal-browser';
import { msalConfig } from '../app.config';
import * as UserAccount from '../models/UserAccount';
import { TokenResponse } from '../models/TokenResponse';
import { RawAxiosRequestConfig } from 'axios';

export class AuthService implements IAuthService {
    private graphApiEndpoint = 'https://graph.microsoft.com/v1.0/me';
    private userAgentApplication: Msal.PublicClientApplication ;

    constructor() {
        const config: Msal.Configuration = {
            auth: {
                clientId: msalConfig.clientID,
                authority: msalConfig.authority,
                redirectUri: msalConfig.redirectUri
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: true
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {	
                            return;	
                        }	
                        switch (level) {	
                            case Msal.LogLevel.Error:	
                                if (msalConfig.LogLevel == Msal.LogLevel.Error) console.error(message);	
                                return;	
                                case Msal.LogLevel.Warning:	
                                if (msalConfig.LogLevel >= Msal.LogLevel.Warning) console.warn(message);	
                                return;	
                            case Msal.LogLevel.Info:	// default
                                if (msalConfig.LogLevel >= Msal.LogLevel.Info) console.info(message);	
                                return;	
                            case Msal.LogLevel.Verbose:	
                                if (msalConfig.LogLevel >= Msal.LogLevel.Verbose) console.debug(message);	
                                return;	
                        }
                    }
                }
            }
        };

        this.userAgentApplication = new Msal.PublicClientApplication (config);

        // this.userAgentApplication.handleRedirectCallback((error, response) => { });

        (window as any).userAgentApplication = this.userAgentApplication;
        (window as any).msalConfig = msalConfig;
    }
    public async login(): Promise<TokenResponse> {
        try {
            const request: Msal.PopupRequest = {
                scopes: [msalConfig.scope]
                //,authority: msalConfig.authority
            };
            const resp = await this.userAgentApplication.loginPopup(request);
            const tokenResp = await this.acquireTokenSilent();
            return tokenResp;
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    }

    async acquireTokenSilent(): Promise<TokenResponse> {
        let errorMessage = undefined;

        const apiRequest: Msal.SilentRequest = {
            scopes: [msalConfig.apiScope],
            account: this.getAccount()
            //, authority: msalConfig.authority
        };
        let apiResponse: Msal.AuthenticationResult;
        try {
            apiResponse = await this.userAgentApplication.acquireTokenSilent(apiRequest);
        } catch (err) {
            console.log('err: ' + err);
            try {
                apiResponse = await this.userAgentApplication.acquireTokenPopup(apiRequest);
            } catch (err2) {
                console.log('Failed to acquire user token via popup. Error: ' + err2);
                errorMessage = err2;
            }
        }

        const accountObj = this.getAccount();
        const graphRequest: Msal.SilentRequest = {
            scopes: msalConfig.b2cScopes,
            account: accountObj
        };
        let graphResponse: Msal.AuthenticationResult;
        try {
            graphResponse = await this.userAgentApplication.acquireTokenSilent(graphRequest);
        }
        catch (err) {
            try {
                graphResponse = await this.userAgentApplication.acquireTokenPopup(graphRequest);
            }
            catch (err2) {
                console.log('Failed to acquire graph token via popup. Error: ' + err2);
                errorMessage = err2;
            }
        }

        // const account: Msal.Account = this.userAgentApplication.getAccount();
        const account_: UserAccount.Account = accountObj ? Object.assign(accountObj) : undefined;

        // try {
        //   account_.picture = await this.getProfilePhoto(graphResponse.accessToken);
        // }
        // catch (err) { }

        const response: TokenResponse = {
            apiToken: (apiResponse ? apiResponse.accessToken : undefined),
            graphToken: (graphResponse ? graphResponse.accessToken : undefined),
            account: account_
        };

        if (errorMessage) {
            throw errorMessage;
        }
        return response;
    }

    public async logout() {
        // this.userAgentApplication.logout();
        await this.userAgentApplication.logoutPopup();
    }

    public getAccount(): Msal.AccountInfo | null {
        // return this.userAgentApplication.getAccount();
        let accountObj: Msal.AccountInfo;
        const currentAccounts = this.userAgentApplication.getAllAccounts();
        if (!currentAccounts || currentAccounts.length === 0) {
            console.log('No user signed in');
            return null;
        } else if (currentAccounts.length > 1) {
            console.log('More than one signed in', currentAccounts);
            const vinitasAccount = currentAccounts.filter(account => account.username.indexOf('vinitas') > -1 || account.username.indexOf('visium') > -1);
            if (vinitasAccount && vinitasAccount.length ==1) {
                accountObj = vinitasAccount[0];
                console.log('Picked Vinitas account', accountObj.username);
            } else {
                return null;
            }
            
        } else {
            accountObj = currentAccounts[0];
        }
        return accountObj;
    }

    public isLoggedIn(): boolean {
        const account = this.getAccount();
        const loggedIn = account !== null;
        // console.log(`AuthService.isLoggedIn(): ${loggedIn} - user is ${account ? account.userName : account}`);
        return loggedIn;
    }

    async getProfilePhoto(graphToken = undefined) {
        if (!graphToken) {
            graphToken = await this.getGraphToken();
        }
        const url: string = this.graphApiEndpoint + '/photo/$value';
        // const authHeader = { Authorization: 'Bearer ' + graphToken };
        const headers: RawAxiosRequestConfig['headers'] = {};
        headers['Authorization'] = 'Bearer ' + graphToken;

        const APIConfig = {} as RawAxiosRequestConfig;
        APIConfig.responseType = 'blob';
        APIConfig.headers = headers;
        const picture = await axios.get(url, APIConfig);
        return window.URL.createObjectURL(picture.data);
    }

    public async getGraphToken(): Promise<string> {
        const graphRequest: Msal.SilentRequest = {
            scopes: msalConfig.b2cScopes,
            account: this.getAccount()
        };
        const graphResponse: Msal.AuthenticationResult = await this.userAgentApplication.acquireTokenSilent(graphRequest);
        return (graphResponse ? graphResponse.accessToken : undefined);
    }
}
