//js API for Schema visium
//Auto-generated 06/15/22 14:28
 

var mixinSchema_visium = {
    methods: {
        /** @description visium.CancelAndResendVendorVerification
         * @param {number} vendorVerificationId - 
         */
		 visium_CancelAndResendVendorVerification: function (params, onSuccess, onError, async) {
			/*
				this.CancelAndResendVendorVerification = await this.visium_CancelAndResendVendorVerification({
					VendorVerificationId: vendorVerificationId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'visium.CancelAndResendVendorVerification'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description Get BalanceSheetCategories using visium.GetBalanceSheetCategories
         * @param {number} clientId - 
         * @param {boolean} asset - 
         * @param {boolean} liability - 
         */
		, visium_GetBalanceSheetCategories: function (clientId, asset, liability) {
			/*
				this.BalanceSheetCategories = this.visium_GetBalanceSheetCategories(
					null // clientId
					, null // asset
					, null // liability
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'visium.GetBalanceSheetCategories'
			  , params: {
					ClientId: clientId
					, Asset: asset
					, Liability: liability
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get BalanceSheetCategoryAssets using visium.GetBalanceSheetCategoryAssets
         * @param {number} balanceSheetCategoryId - 
         */
		, visium_GetBalanceSheetCategoryAssets: function (balanceSheetCategoryId) {
			/*
				this.BalanceSheetCategoryAssets = this.visium_GetBalanceSheetCategoryAssets(
					null // balanceSheetCategoryId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'visium.GetBalanceSheetCategoryAssets'
			  , params: {
					BalanceSheetCategoryId: balanceSheetCategoryId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Banks using visium.GetBanks
         * @param {number} clientId - 
         */
		, visium_GetBanks: function (clientId) {
			/*
				this.Banks = this.visium_GetBanks(
					null // clientId
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'visium.GetBanks'
			  , params: {
					ClientId: clientId
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get EntityMap using visium.GetEntityMap
         * @param {number} entityId - 
         * @param {string} entityType - 
         * @param {string} entityTextType - 
         */
		, visium_GetEntityMap: function (entityId, entityType, entityTextType) {
			/*
				this.EntityMap = this.visium_GetEntityMap(
					null // entityId
					, null // entityType
					, null // entityTextType
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'visium.GetEntityMap'
			  , params: {
					EntityId: entityId
					, EntityType: entityType
					, EntityTextType: entityTextType
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description Get Persons using visium.GetPersons
         * @param {number} personId - 
         * @param {string} searchTerm - 
         */
		, visium_GetPersons: function (personId, searchTerm) {
			/*
				this.Persons = this.visium_GetPersons(
					null // personId
					, null // searchTerm
					);
			*/
 
			var loadAsync = false;
			var ds = smartData({
				storedProcName: 'visium.GetPersons'
			  , params: {
					PersonId: personId
					, SearchTerm: searchTerm
					}
				, loadAsync: loadAsync
				});
				return ds;
			}
        /** @description visium.ResetVerification
         * @param {number} vendorId - 
         */
		, visium_ResetVerification: function (params, onSuccess, onError, async) {
			/*
				this.ResetVerification = await this.visium_ResetVerification({
					VendorId: vendorId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'visium.ResetVerification'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
        /** @description visium.SendVendorVerification
         * @param {number} vendorId - 
         * @param {number} keyPersonId - 
         */
		, visium_SendVendorVerification: function (params, onSuccess, onError, async) {
			/*
				this.SendVendorVerification = await this.visium_SendVendorVerification({
					VendorId: vendorId
					, KeyPersonId: keyPersonId
					}
					, true // async (optional)
				);
			*/
 
			var loadAsync = (async === undefined) ? true : async;
			return smartData({
				storedProcName: 'visium.SendVendorVerification'
				, params: params
				 , onSuccess: function (data) {
                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
				 , onError: function (error) {
                    if (typeof onError === 'function') {
                        onError(error);
                    }
                }
				, loadAsync: loadAsync
				});
			}
    }
};
export default mixinSchema_visium;
