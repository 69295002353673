<template>
    <div >
        <!--Note: v-validate is used for Dirty/Clean, but not actual validation. Actual validation is handled by el-form, which doesn't track dirty/clean-->
        <el-form :model="Budget" :rules="budgetRules" ref="budgetForm" label-width="200px">
            <el-form-item label="Budgeted" prop="BudgetAmount" v-show="securityLevel_>=80">
                <formatted-input v-model="Budget.BudgetAmount" format-type="currency" num-decimals="2" size="mini" name="Budgeted" v-validate></formatted-input>
                <button type="button" class="btn btn-default btn-sm btn-success-stripe" style="margin-left: 10px; margin-top: 2px;" v-show="showUpdateBudget" v-on:click="updateBudget">Update Budget</button>
            </el-form-item>
            <el-form-item label="Budgeted" prop="BudgetAmount" v-show="securityLevel_<80">
                {{Budget.BudgetAmount | currency}}
            </el-form-item>
            <el-form-item label="Invoiced:">
                {{Budget.Invoiced | currency}}
            </el-form-item>
            <el-form-item label="Invoices Paid:">
                {{Budget.InvoicePaid | currency}}
            </el-form-item>
            <el-form-item label="Unpaid Invoices:">
                {{Budget.Unpaid | currency}}
            </el-form-item>
            <el-form-item label="Reference Only Invoices:" title="Invoices that will not match to Transactions, but are complete and count against the budget">
                {{Budget.ReferenceOnlyPaid | currency}}
            </el-form-item>
            <el-form-item label="Agreement Paid:" title="Transactions Matched to the Agreement without an Invoice">
                {{Budget.AgreementPaid | currency}}
            </el-form-item>
            <el-form-item label="Total Against Budget:" title="Invoice Paid + Unpaid Invoices + Reference Only Invoices + Agreement Paid">
                {{Budget.Paid | currency}}
            </el-form-item>
            <el-form-item label="Remaining:" title="Budget - Total Against Budget">
                <el-tag :type="Budget.RemainingStyle">{{Budget.Remaining | currency}}</el-tag>
            </el-form-item>
        </el-form>
        <allocation :params="allocationParams" ref="allocationAgreementBudget" value-type="Percent" :security-level="securityLevel_" ></allocation>
    </div>

</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import FormattedInput from '@/components/form/FormattedInput.vue';
    import Allocation from './../Allocation/Allocation.vue'

    export default Vue.extend({
        name: 'BudgetSchedule'
        , mixins: [mixinUtility, mixinSchema_liq ]
        , components: {
            'formatted-input': FormattedInput
            , 'allocation': Allocation
        }
        , props: {
            agreementId: {
                required: true
                , type: Number
            }
            , clientId: {
                type: Number
            }
            , securityLevel: {
                type: Number
                , default: null
            }
        }
        , data() {
            return {
                Budget: this.liq_GetAgreementBudget_New()
                , budgetRules: {
                    BudgetAmount: [
                        { required: true, message: 'Budget Amount is required!', trigger: 'blur' }
                    ]
                }
                , allocationParams: {
                    EntityId: this.agreementId
                    , EntityType: 'Agreement'
                    , ClientId: this.clientId
                }
                , securityLevel_: this.securityLevel
            }
        }
        , created: function() {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), 0);
            }
        }
        , mounted: function () {
            this.fetchBudget(this.agreementId);
        }
        , computed: {
            showUpdateBudget: function () {
                var pristine = Object.keys(this.veeFields).every(field => {
                    console.log(field);
                    console.log(this.veeFields[field].pristine);
                    return this.veeFields[field] && this.veeFields[field].pristine;
                })
                return !pristine;
            }
        }
        , watch: {
        }
        , methods: {
            fetchBudget: function (agreementId) {
                if (!agreementId)
                    agreementId = this.agreementId;

                var el = this;
                this.liq_GetAgreementBudget_Object({
                    AgreementId: agreementId
                }
                    , function (data) {
                        el.Budget = data;
                    }
                    , function (error) {
                        console.error(error);
                    });
            }
            //, getAgreementBudget: function (agreementId) {

            //    var ds = this.liq_GetAgreementBudget(agreementId);
            //    if (ds.length == 1)
            //        return ds[0];
            //    else if (ds.length == 0)
            //        return {
            //            AgreementId: null
            //            , BudgetAmount: null
            //            , Invoiced: null
            //            , Paid: null
            //            , Remaining: null
            //        };
            //}
            , updateBudget: function () {
                var agreement = new SmartObject('Agreement', this.agreementId);
                var t = this;
                agreement.updateAttribute_lgcy(
                    'BudgetAmount', parseFormattedCurrency(this.Budget.BudgetAmount)
                    , {
                        onSuccess: function () {
                            this.$notify.success('Budget updated.');
                            t.fetchBudget();
                        }
                    });
            }
        }
    })
</script>
<style>
    .el-form-item__error {
        position: relative;
    }
</style>