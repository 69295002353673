
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Ref } from 'vue-property-decorator';
import ClientSelector from '@/components/form/ClientSelector.vue';
import * as AssetService from './../../services/DAL/assetService';
import formatters from '../../utils/formatters';
import ElementUI from 'element-ui';

declare var $;
declare function tryParseInt(input: any, defaultValue: number): number;
declare function tryParseBool(input: any, defaultValue: boolean): boolean;

@Component({
    components: {
        ClientSelector,
    }
})
export default class FixedIncomeDashboard extends Vue {
    @Ref() tblFixedIncomeDashboard: ElementUI.Table;
    //#region Private Declarations
    private _assetService: AssetService.AssetService;
    public formatters: formatters;
    //#endregion Private Declarations

    //#region Props
    @Prop()
    clientId!: any;
    //#endregion Props

    //#region Data
    clientId_: number = tryParseInt(this.clientId, null);
    fixedIncomeSummary: AssetService.FixedIncomeSummary[] = [];
    isLoading = false;
    tableHeight: number = null;
    //#endregion Data

    //#region Computed
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._assetService = new AssetService.AssetService();
        this.formatters = new formatters();
        window.addEventListener("resize", this.setTableHeight);
    }
    destroyed(){
        window.removeEventListener("resize", this.setTableHeight);
    }

    async mounted() {
        await this.fetchFixedIncomeSummary();
        this.setTableHeight();
    }

    //#endregion Lifecycle

    //#region Watches
    @Watch('clientId_')
    onChange_clientId_(val: number, oldVal: number) {
        if (!(oldVal == undefined && val == null)){
            this.fetchFixedIncomeSummary();
        }
    }
    
    //#endregion Watches

    //#region Methods
    async fetchFixedIncomeSummary() {
        this.isLoading = true;
        this.fixedIncomeSummary = [];

        const params = {} as AssetService.GetFixedIncomeSummaryParameters;
        params.ClientId = this.clientId_ || null;
        this.fixedIncomeSummary = await this._assetService.GetFixedIncomeSummary(params);
        this.isLoading = false;
    }
    async selectFixedIncome(fixedIncome?: AssetService.FixedIncome, event?) {
    }

    setTableHeight(){
        this.tableHeight =  this.$el.clientHeight - 130 - 38; //130: Selectors; 38: Filter w/ padding (ClientHeight is what is inside the router.  934 in DEV)
        this.tblFixedIncomeDashboard.doLayout();
    }
    getSummary(param){
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = 'Total';
                return;
            }
            const values = data.map(item => Number(item[column.property]));
            if (column.label.indexOf('Maturity') < 0 && !values.every(value => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }, 0);
                sums[index] = this.$accounting.formatMoney(sums[index], {precision : 0});
            } else {
                sums[index] = '';
            }
        });
        const face = this.$accounting.parse(sums[columns.findIndex(column => column.label === 'Face')]); //RemainingFaceValue
        const bey = this.$accounting.parse(sums[columns.findIndex(column => column.label === 'BEY')]); //WeightedBEYNumerator
        const wgtBEY = this.$accounting.formatNumber(face > 0 ? bey / face : 0, 4);
        sums[columns.findIndex(column => column.label === 'BEY')] = wgtBEY + '%';

        //format count to 0 decimal places non-currency
        const count = this.$accounting.parse(sums[columns.findIndex(column => column.label === 'Count')]); //NumberOfInstruments
        sums[columns.findIndex(column => column.label === 'Count')] = this.$accounting.formatNumber(count, 0);

        return sums;
    }
    //#endregion Methods
}
