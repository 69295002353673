
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FormattedInput from '@/components/form/FormattedInput.vue';
import * as allocationTypes from '@/views/InvestmentTransaction/investmentTransactionAllocation';
import cloneDeep from 'lodash/cloneDeep';

declare var SmartObject: any;

@Component({
    components: {
        FormattedInput
    }
})
export default class InvestmentTransactionAllocationEdit extends Vue {
    //#region Private declarations for Services
    //#endregion Private declarations for Services

    //#region Props
    @Prop({required: true}) allocation: allocationTypes.InvestmentTransactionAllocation;
    @Prop({default: 'Shares'}) field: String;
//#endregion Props

    //#region Data
    allocation_: allocationTypes.InvestmentTransactionAllocation; // = this.allocation;
    
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
        key => (this as any).veeFields[key].dirty
        );
    }

    
    //#endregion Data

    //#region Lifecycle
    created() {
        this.allocation_ = cloneDeep(this.allocation);
    }
    mounted() {
        if (this.field == 'Shares'){
            this.focus();
        }
        else {
            this.focusAmount();
        }
    }
    //#endregion Lifecycle
    
    //#region Watches
    //#endregion Watches

    //#region Methods
    save(){
        (this.$refs.frmTransactionAllocationEdit as any).validate(async valid => {
            if (valid) {
                const allocationToUpdate = cloneDeep(this.allocation_);
                delete allocationToUpdate.Id; // get rid of this alph-numeric key so it doesn't conflict with the real key in the db
                const id = await new SmartObject('InvestmentTransactionAllocation', this.allocation_.TransactionAllocationId).updateObject(
                    allocationToUpdate
                );
                this.allocation_.TransactionAllocationId = id;
                this.$notify.success('Allocation updated');
                this.$emit('save', this.allocation_);
            }
        });
    }
    focus(){
        (this.$refs.inp as HTMLFormElement).focus();
    }
    focusAmount(){
        (this.$refs.inpAmount as HTMLFormElement).focus();
    }
    //#endregion Methods
}
