
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Account } from './../../models/UserAccount';
import { AuthService } from './../../services/AuthService';

declare var $: any;
declare function getSmartCoreSetting(
    section: string,
    subsectionOrAttribute: string,
    attributeOrDefaultValue?: any,
    defaultValue?: any
): string;

@Component
export default class HeaderEL extends Vue {
    private _authService: AuthService;

    //#region Data
    @Prop() sidebarCollapsed: boolean;

    account: Account;
    databaseEnvironment: string;
    avatarUrl: string = '';
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._authService = new AuthService();

        this.account = this.$store.getters.account;
        this.databaseEnvironment = getSmartCoreSetting('databaseSettings', 'UseConnectionString');
        try {
          this.avatarUrl = await this._authService.getProfilePhoto();
        }
        catch {
          this.avatarUrl = ''; //when the user doesn't have a picture set up, this returns a 404 (error)
          return;
        }
    }
    //#endregion Lifecycle

    //#region Methods
    toggleSidebar(e) {
        $('body').toggleClass('page-sidebar-closed', !this.sidebarCollapsed);
        this.$emit('update:sidebarCollapsed', !this.sidebarCollapsed);
    }

    handleCommand(command) {
        switch (command) {
            case 'profile':
                // not hooked up yet
                break;
            case 'logOut':
                this.$store.dispatch('logout');
                break;
        }
    }
    //#endregion Methods
}
