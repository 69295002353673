
import Vue from 'vue';
import Component from 'vue-class-component';
import TaxClassEdit from '@/views/TaxClass/TaxClassEdit.vue';
import * as TaxService from './../../services/DAL/taxService';
import ElementUI from 'element-ui';

@Component({
  components: {
    TaxClassEdit
  }
})
export default class TaxClassList extends Vue {
  $refs: {
    tblTaxClassList: ElementUI.Table
  }
  //#region Data
  private _taxService: TaxService.TaxService;

  taxClasses = [];
  selectedTaxClass = null;
  showTaxClassEdit = false;
  showTaxClassCreate = false;
  isLoading = false;
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._taxService = new TaxService.TaxService();
  }

  async mounted() {
    this.fetchTaxClasses();
  }
  //#endregion Lifecycle

  //#region Methods
  selectTaxClass(taxClass) {
    if (taxClass) {
      this.showTaxClassEdit = false;
      this.$nextTick(() => {
        this.showTaxClassEdit = true;
        this.selectedTaxClass = taxClass;
      });
    }
  }

  closeTaxClass() {
    this.showTaxClassEdit = false;
    this.selectedTaxClass = null;
    this.$refs.tblTaxClassList.setCurrentRow(null);
  }

  onDeleteTaxClass() {
    this.closeTaxClass();
    this.fetchTaxClasses();
  }

  async fetchTaxClasses() {
    this.isLoading = true;
    const parms = {} as TaxService.GetTaxClassesParameters;
    this.taxClasses = await this._taxService.GetTaxClasses(parms);
    this.isLoading = false;
  }
  //#endregion Methods
}
