 <template>
     <el-dialog :visible.sync="showSetSearchTermDialog" :show-close="true" :close-on-click-modal="false"
                append-to-body>
         <span slot="title">Set Auto-Match Search Term</span>
         <el-row :gutter="20">
             Below is the entire description of the current transaction.<br />
             Edit it down to just what you expect to appear in EVERY transaction that should be matched to this Entity (Invoice, Agreement, etc) in the future. <br />
             Use % as a wildcard to skip words or characters.<br />
             <el-input type="textarea"
                       :rows="2"
                       v-model="Main.Text">
             </el-input>
         </el-row>
         <el-row type="flex" >
             <el-col :span="12">
                 <el-row type="flex" >
                     <el-col :span="12">
                         <el-switch v-model="Main.UseMinAmount" active-text="Use Minimum Amount Filter"></el-switch>
                     </el-col>
                     <el-col :span="12">
                         <formatted-input v-show="Main.UseMinAmount" v-model="Main.MinAmount" format-type="currency" num-decimals="2" style="width: 100%; max-width:100px;" size="mini" name="MinAmount" v-validate></formatted-input>
                     </el-col>
                 </el-row>
                 <el-row type="flex" >
                     <el-col :span="12">
                         <el-switch v-model="Main.UseMaxAmount" active-text="Use Maximum Amount Filter"></el-switch>
                     </el-col>
                     <el-col :span="12">
                         <formatted-input v-show="Main.UseMaxAmount" v-model="Main.MaxAmount" format-type="currency" num-decimals="2" style="width: 100%; max-width:100px;" size="mini" name="MaxAmount" v-validate></formatted-input>
                     </el-col>
                 </el-row>
             </el-col>
             <el-col :span="12">
                 <div v-show="showDefaultMinMessage" class="info-box bg-warning">
                     Minimum Filter has been set equal to the Transaction Amount.  If the future Bank Transactions you wish to Automatically Match will not be exactly this amount, either widen the filter, or turn the filter off.
                     The Maximum Filter is set to 0 to ensure only Charges (i.e. Negative Transactions) are matched.
                 </div>
                 <div v-show="showDefaultMaxMessage" class="info-box bg-warning">
                     Maximum Filter has been set equal to the Transaction Amount.  If the future Bank Transactions you wish to Automatically Match will not be exactly this amount, either widen the filter, or turn the filter off.
                     The Minimum Filter is set to 0 to ensure only Credits (i.e. Positive Transactions) are matched.
                 </div>
             </el-col>
        </el-row>
                 <hr />
                 <el-row :gutter="20">
                     Current Search Term{{(OtherTerms.length>1) ? 's' : ''}}:
                 </el-row>
                 <el-row>
                     <el-col :span="10">
                         <el-table :data='OtherTerms'>
                             <el-table-column prop="Text"
                                              label="Term"
                                              min-width="150"
                                              sortable>
                             </el-table-column>
                         </el-table>
                     </el-col>
                 </el-row>
                 <el-row :gutter="20" v-show="showLinkedTo">
                     <el-col :span="6">
                         Linked to:
                     </el-col>
                     <el-col :span="18">
                         <span class="form-control-static">{{linkedToEntityType}}</span>
                         <span class="form-control-static">{{linkedToEntityDescription}}</span>
                     </el-col>
                 </el-row>
                 <template slot="footer">
                     <el-button @click="closeSetSearchTerm">Close</el-button>
                     <el-button type="success" @click="saveSearchTerm">Save</el-button>
                 </template>
</el-dialog>

</template>
<script>
    import Vue from 'vue';
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import FormattedInput from '@/components/form/FormattedInput.vue';

    export default Vue.extend({
        name: 'SetSearchTerm'
        , mixins: [mixinSchema_liq]
        , components: {
            'formatted-input': FormattedInput
        }
        , props: {
              searchTermId: Number
            , searchTermEntityType: String
            , searchTermEntityId: Number
            , transactionDescription: String
            , transactionAmount: Number
            , showModal: {
                type: Boolean
                , default: false
            }
        }
        , data: function () {
            return {
                Main: this.liq_GetSearchTerms_New()
                , OtherTerms: []
                , showSetSearchTermDialog: false
                , showDefaultMinMessage: false
                , showDefaultMaxMessage: false
            }
        }
        , methods: {
            initialize() {
                if (this.searchTermId) {
                    this.Main = this.liq_GetSearchTerms_Object(
                        this.searchTermId // searchTermId
                        , null // entityId
                        , null // entityType
                    )
                    this.OtherTerms = this.liq_GetSearchTerms(
                        null// searchTermId
                        , this.Main.EntityId // entityId
                        , this.Main.EntityType // entityType
                    )
                }
                else {
                    this.Main = this.liq_GetSearchTerms_New();
                    this.Main.Text = this.transactionDescription; //set to full description initially
                    if (this.transactionAmount < 0) {
                        this.Main.MinAmount = this.transactionAmount;
                        this.Main.MaxAmount = 0;
                        this.Main.UseMaxAmount = true;
                        this.Main.UseMinAmount = true;
                        this.showDefaultMinMessage = true;
                        this.showDefaultMaxMessage = false;
                    }
                    if (this.transactionAmount > 0) {
                        this.Main.MaxAmount = this.transactionAmount;
                        this.Main.MinAmount = 0;
                        this.Main.UseMaxAmount = true;
                        this.Main.UseMinAmount = true;
                        this.showDefaultMaxMessage = true;
                        this.showDefaultMinMessage = false;
                    }
                    this.OtherTerms = this.liq_GetSearchTerms(
                        null// searchTermId
                        , this.searchTermEntityId // entityId
                        , this.searchTermEntityType // entityType
                    )
                }
                
            }
            , saveSearchTerm: function () {
                var values = {
                    EntityType: this.searchTermEntityType
                    , EntityId: this.searchTermEntityId
                    , Text: this.Main.Text
                    , MinAmount: (this.Main.UseMinAmount) ? this.Main.MinAmount : null
                    , MaxAmount: (this.Main.UseMaxAmount) ? this.Main.MaxAmount : null
                }
                if (this.searchTermId) {//update existing
                    const oSearchTerm = new SmartObject('SearchTerms', this.searchTermId);
                    oSearchTerm.updateObject_lgcy(values);
                    this.$notify.success('Search Term Updated.');
                    this.$emit('close');
                }
                else {
                    const oSearchTerm = new SmartObject('SearchTerms');
                    if (this.searchTermEntityType == 'Account' ) {
                        //For Account allow multiple entries. (i.e. insert new)
                        success = oSearchTerm.createObject_lgcy(values)
                        if (success > 0) {
                            this.Main.SearchTermId = success;
                            this.$notify.success('Search Term Added.');
                            this.$emit('close');
                        }
                    }
                    else {
                        //for other EntityType, only one entry per Type/Id. updateFromCompositeKey will do that.
                        var vm = this; //this isn't known inside onSuccess
                        var searchTermId = oSearchTerm.updateFromCompositeKey_lgcy({
                            keys: {
                                EntityType: this.searchTermEntityType
                                , EntityId: this.searchTermEntityId
                            }
                            , values: values
                            , onSuccess: function (d) {
                                this.$notify.success('Search Term Updated.');
                                //vm.$emit('search-term-saved'); //no way to get created ObjectId in the onSuccess method, apparently.
                                vm.$emit('close');
                            }
                        });
                    }
                }
            }
            , closeSetSearchTerm: function () {
                this.$emit('close');
            }
        }
        , watch: {
            'showModal': function (val, oldVal) {
                this.showSetSearchTermDialog = val;
            }
            , 'showSetSearchTermDialog': function (val, oldVal) {
                if (val) {
                    this.initialize();
                }
            }

        }
        , computed: {
            showLinkedTo: function () {
                return (this.OtherTerms.length > 0 || this.Main.EntityDescription);
            }
            , linkedToEntityDescription: function () {
                if (this.Main.EntityDescription) {
                    return this.Main.EntityDescription
                }
                else if (this.OtherTerms.length > 0) {
                    return this.OtherTerms[0].EntityDescription
                }
                else return null
            }
            , linkedToEntityType: function () {
                if (this.Main.EntityType) {
                    return this.Main.EntityType
                }
                else if (this.OtherTerms.length > 0) {
                    return this.OtherTerms[0].EntityType
                }
                else return null
            }
        }
    })

</script>
<style scoped>
    .info-box {
        border-radius: 4px;
        padding: 5px;
        box-sizing: border-box;
        font-size: 12px;
    }
</style>