
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import find from 'lodash/find';

@Component({
    inheritAttrs: false
})
export default class InvestmentParentSelector extends Vue {
    //#region Private declarations for Services
    private _asset_Service: AssetService.AssetService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() readonly value: number;
    @Prop() readonly clientId: number;
    @Prop() readonly ownerId: number;
    @Prop() readonly considerationEntityType: string;
    @Prop({ default: false }) readonly requireClientId: boolean
    @Prop({ default: false }) readonly requireOwnerId: boolean
    @Prop({ default: false }) readonly readOnly: boolean;

    //#endregion Props

    //#region Data
    investmentParents = [] as AssetService.InvestmentParentList[];
    selectedInvestmentParent = {} as AssetService.InvestmentParentList;
    selectedValue: number = null;
    //#endregion Data

   //#region Lifecycle
    async created() {
        this._asset_Service = new AssetService.AssetService();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchInvestmentParents();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onInvestmentParentChange(val: number, oldVal: number) {
        this.selectedValue = val;
        if (!this.investmentParents.length) {
            this.fetchInvestmentParents();
        }
        else {
            this.getSelected();
        }

    }
    @Watch('clientId')
    onClientChange(val: number, oldVal: number) {
        this.fetchInvestmentParents();
    }
    @Watch('ownerId')
    onOwnerChange(val: number, oldVal: number) {
        this.fetchInvestmentParents();
    }
    @Watch('selectedInvestmentParent')
    onChange_selectedInvestmentParent(val: AssetService.InvestmentParentList, oldVal: AssetService.InvestmentParentList) {
        // if directly selected, before picking Owner and Parent, set those, too.
        this.$emit('value-set', this.selectedInvestmentParent);
    }

    //#endregion Watches

    //#region Methods
    async fetchInvestmentParents() {
        this.investmentParents = [];
        const prevSelectedValue = this.selectedValue;
        this.selectedValue = null;

        const params = {} as AssetService.GetInvestmentParentListParameters;
        
        if ((!this.requireClientId || !!this.clientId) && (!this.requireOwnerId || !!this.ownerId)) {
            params.ClientIdList = (this.clientId || '').toString();
            params.OwnerIdList = (this.ownerId || '').toString();
            params.ConsiderationEntityType = this.considerationEntityType;
            this.investmentParents = await this._asset_Service.GetInvestmentParentList(params);
        }
        if (this.investmentParents.length === 1 && prevSelectedValue && prevSelectedValue !== this.investmentParents[0].Id){
            // if the previously selected value is not in the list, get the selected parent and add it to the list
            const params = {} as AssetService.GetInvestmentParentListParameters;
            params.InvestmentParentId = prevSelectedValue;
            const investmentParent = await this._asset_Service.GetInvestmentParentList(params);
            if (investmentParent.length === 1) {
                this.investmentParents.push(investmentParent[0]);
            }
        }
        if (this.investmentParents.length === 1){
            this.selectedValue = this.investmentParents[0].Id;
            this.$emit('single-option-available', this.selectedValue);
            if (this.selectedValue !== this.value) {
                this.$emit('input', this.selectedValue);
            }
        }
        // else if (this.selectedValue) {
        //     const selectedInvestmentParent = find(this.investmentParents, investmentParent => {
        //         return investmentParent.Id === this.selectedValue;
        //     });
        //     if (!selectedInvestmentParent) {
        //         this.selectedValue = null;
        //         if (this.selectedValue !== this.value) {
        //             this.$emit('input', this.selectedValue);
        //         }
        //     }
        // }
        else if (prevSelectedValue > 0) {
            // if previously selected value is in the new parent list, set the current value to the previous value
            const prevSelected = this.investmentParents.find( item => item.Id == prevSelectedValue);
            if (prevSelected) {
                this.selectedValue = prevSelectedValue;
                if (this.selectedValue !== this.value) {
                    this.$emit('input', this.selectedValue);
                }
            }
        }
        if (this.investmentParents.length) this.$emit('options-fetched', this.investmentParents);

        this.getSelected();
    }
    getSelected() {
        if (this.investmentParents.length && this.selectedValue > 0) {
            this.selectedInvestmentParent = this.investmentParents.find( item => item.Id == this.selectedValue);
        }
        else {
            this.selectedInvestmentParent = new AssetService.InvestmentParentList();
        }
    }
        
    //#endregion Methods
}
