
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as TransactionsService from '../../services/DAL/transactionsService';
import * as LiqService from './../../services/DAL/liq_Service';
import formatters from '../../utils/formatters';
import Common from '../../utils/common';
import dayjs from 'dayjs';

declare var $: any;
declare var SmartObject: any;

@Component
export default class FixedIncomeBankTransactionList extends Vue {
    //#region Private Declarations
    private _transactionsService: TransactionsService.TransactionsService;
    public formatters: formatters;
    private _common: Common;
//#endregion Private Declarations

    //#region Props
    @Prop() fixedIncomeId: number;
    //#endregion Props

    //#region Data
    loading: boolean = false;
    fixedIncomeBankTransactions = [] as TransactionsService.FixedIncomeBankTransactions[];
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.formatters = new formatters();
        this._common = new Common();

        this.fetchTransactions();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchTransactions() {
        this.loading = true;
        this.fixedIncomeBankTransactions = [];
        const params = {} as TransactionsService.GetFixedIncomeBankTransactionsParameters;
        params.FixedIncomeId = this.fixedIncomeId;
        this.fixedIncomeBankTransactions = await this._transactionsService.GetFixedIncomeBankTransactions(params);
        this.loading = false;
    }


    //#endregion
}
