<template>
    <div v-loading="loading">
        <!--Note: v-validate is used for Dirty/Clean, but not actual validation. Actual validation is handled by el-form, which doesn't track dirty/clean-->
        <el-form :model="agreement" :rules="agreementRules" ref="agreementForm" label-width="30%"  >
            <span v-if="agreement.HasError" style="color:red;">{{agreement.ErrorMessage}}</span>
            <el-form-item>
                <div class="pull-right">
                    <el-button @click="deleteAgreement" type="danger" plain size="small" icon="el-icon-delete" v-if="(securityLevel_>=80 && agreement.Deletable && agreement.AgreementId > 0)">Delete</el-button>
                    <el-button @click="copyAgreement" type="primary" plain size="small" v-if="(securityLevel_>=80 && agreement.AgreementId > 0)"><i class="fa fa-copy" style="margin-right: 5px;"></i>Copy</el-button>
                    <el-button @click="saveAgreement" type="success" plain size="small" icon="el-icon-check" :disabled="!(securityLevel_>=80 && formValid && isFormDirty)">Save</el-button>
                </div>
            </el-form-item>
            <el-form-item label="Special Type:">
                <el-select v-model="agreement.TypeId"
                            size="small"
                            v-bind:disabled="(agreementTypes.length==0 || securityLevel_<80 || agreementTypeLocked)"
                            filterable
                            default-first-option
                            v-validate name="AgreementType"
                            style="width: 100%; max-width:300px"
                            >
                    <el-option v-for="item in agreementTypes" :key="item.Id"
                                :label="item.Name"
                                :value="item.Id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Group:" prop="GroupId">
                <el-select filterable clearable size="small"
                            v-bind:disabled="(groups.length==0 || securityLevel_<80)"
                            name="Group" v-validate
                            default-first-option
                            style="width: 100%; max-width:300px"
                            v-model="agreement.GroupId">
                    <el-option v-for="group in groups"
                                :key="group.AgreementGroupId"
                                :label="group.Name"
                                :value="group.AgreementGroupId">
                    </el-option>
                </el-select>
                <el-button type="primary" plain size="mini" title="Add Group" icon="el-icon-plus" @click="addAgreementGroup" :disabled="(securityLevel_<80)"></el-button>
            </el-form-item>
            <el-form-item label="Agreement Name:" prop="Name">
                <el-input v-model="agreement.Name" size="small" v-validate name="Name" style="width: 100%; max-width:300px" :disabled="(securityLevel_<80)" ></el-input>
            </el-form-item>
            <el-form-item label="Vendor Acct Number:" prop="Identifier"
                :rules="[
                        { required: vendorAccountNumberRequired, message: 'Vendor Account Number is required', trigger: 'blur' },
                        { min: vendorAccountNumberRequired ? 2 : 0, trigger: 'blur', message: 'Vendor Account Number must be at least 2 characters' }                
                        ]"
                    >
                <el-input v-model="agreement.Identifier" size="small" name="Vendor Account Number" v-validate style="width: 100%; max-width:300px" :disabled="(securityLevel_<80)"></el-input>
            </el-form-item>
            <el-form-item label="Client:" prop="ClientId">
                <client-selector v-model="agreement.ClientId"
                            style="width: 100%; max-width:100px"
                            :disabled="clientIsSingleOption || securityLevel_<80"
                            @single-option-available="clientSingleOptionAvailable"
                            v-validate name="Client"
                            filterable clearable default-first-option
                            >
                            </client-selector>

                <!-- <el-select filterable clearable size="small"
                            v-bind:disabled="(clients.length==0 || securityLevel_<80)"
                            v-validate name="Client"
                            default-first-option
                            style="width: 100%; max-width:100px"
                            v-model="agreement.ClientId">
                    <el-option v-for="client in clients"
                                :key="client.Id"
                                :label="client.Name"
                                :value="client.Id">
                    </el-option>
                </el-select> -->
            </el-form-item>
            <el-form-item label="Owner:" prop="OwnerId">
                <el-select filterable clearable size="small"
                            v-bind:disabled="(owners.length==0 || securityLevel_<80)"
                            name="Owner" v-validate
                            default-first-option
                            style="width: 100%; max-width:300px"
                            v-model="agreement.OwnerId">
                    <el-option v-for="owner in owners"
                                :key="owner.Id"
                                :label="owner.Name"
                                :value="owner.Id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Vendor:" prop="VendorId">
                <el-select filterable clearable size="small"
                            v-bind:disabled="(vendors.length==0 || securityLevel_<80 || (agreement.GroupVendorSpecific && !!agreement.VendorId) )"
                            name="Vendor" v-validate
                            default-first-option
                            style="width: 100%; max-width:300px"
                            v-model="agreement.VendorId">
                    <el-option v-for="vendor in vendors"
                                :key="vendor.Id"
                                :label="vendor.Name"
                                :value="vendor.Id">
                    </el-option>
                </el-select>
                <el-button type="primary" plain size="mini" icon="el-icon-plus" title="Add vendor" @click="showVendorEdit = true" :disabled="(securityLevel_<80)"></el-button>
            </el-form-item>
            <el-form-item label="Invoices Expected:" prop="InvoicesExpected">
                <el-checkbox v-model="agreement.InvoicesExpected"
                    :disabled="(securityLevel_<80)"
                    v-validate name="InvoicesExpected"
                    >
                </el-checkbox>

            </el-form-item>
            <el-form-item label="Bank Account:" property="AccountId" required>
                <bank-account-selector
                    v-model="agreement.AccountId"
                    size="small"
                    :owner-id="agreement.OwnerId"
                    v-validate name="BankAccount"
                    displayColumn="NameNumberMasked"
                    class="fullContent"
                    filterable
                    default-first-option
                    :disabled="(securityLevel_<80)"
                    style="width: 100%; max-width:300px"
                    v-if="agreement && Object.keys(agreement).length"
                ></bank-account-selector>
            </el-form-item>

            <el-form-item label="Inception Date:" prop="InceptionDate">
                <el-date-picker type="date"
                                size="small"
                                placeholder="Inception Date"
                                name="Inception Date" v-validate
                                v-model="agreement.InceptionDate"
                                :disabled="(securityLevel_<80)"
                                format="MM/dd/yyyy"
                                style="width: 100%; max-width:300px">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="Schd Termination Date:" prop="TerminationDate">
                <el-date-picker type="date"
                                size="small"
                                name="Termination Date" v-validate
                                placeholder="Termination Date"
                                :disabled="(securityLevel_<80)"
                                v-model="agreement.TerminationDate"
                                format="MM/dd/yyyy"
                                style="width: 100%; max-width:300px">
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="showPledgeFields" label="Act Completion Date:">
                <span>{{agreement.PledgeActualTermination | shortDate}}</span>
            </el-form-item>
            <el-form-item v-if="showPledgeFields" label="Commitment:">
                <span>{{agreement.PledgeCommitment | currency(0)}}</span>
            </el-form-item>
            <el-form-item v-if="showPledgeFields" label="Remaining Commitment:">
                <span>{{agreement.PledgeRemainingCommitment | currency(0)}}</span>
            </el-form-item>
            <el-form-item label="Balance Sheet:">
                <el-checkbox v-model="agreement.IncludeOnBalanceSheet"
                    :disabled="(securityLevel_<80)"
                    v-validate name="BalanceSheetCategoryCheck"
                    >Include On Balance Sheet?</el-checkbox>
                <br />
                <el-select v-model="agreement.BalanceSheetCategoryId"
                            size="small"
                            v-bind:disabled="(balanceSheetSubCategories.length==0 || securityLevel_<80)"
                            v-show="agreement.IncludeOnBalanceSheet"
                            filterable
                            default-first-option
                            v-validate name="BalanceSheetCategory"
                            style="width: 100%; max-width:300px"
                            clearable>
                    <el-option v-for="cat in balanceSheetSubCategories" :key="cat.Id"
                                :label="cat.label"
                                :value="cat.Id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Default Units Method:" prop="DefaultUnitsMethod" v-show="!!selectedAgreementGroup && !!selectedAgreementGroup.AggregateUnits">
                <!-- <el-select v-model="agreement.DefaultUnitsMethod" size="small" v-validate name="DefaultUnitsMethod" :disabled="(securityLevel_<80)" style="width: 100%; max-width:300px">
                    <el-option value="Consumption">Consumption</el-option>
                    <el-option value="Purchase">Purchase</el-option>
                </el-select> -->
                <el-radio-group v-model="agreement.DefaultUnitsMethod" size="small" v-validate name="DefaultUnitsMethod" :disabled="(securityLevel_<80)">
                    <el-radio-button label="Consumption"></el-radio-button>
                    <el-radio-button label="Purchase"></el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="Created:" prop="Created">
                {{agreement.Created}}
            </el-form-item>
            <el-form-item label="Modified:" prop="Modified">
                {{agreement.Modified}}
            </el-form-item>
            <el-form-item label="Notes:">
                <comment-log-list 
                    ref="commentLog"
                    v-if="showCommentLogList"
                    entityType="Agreement" 
                    :entityId="agreement.AgreementId" 
                    :securityLevel="securityLevel_" 
                    :showTextbox="securityLevel_ >= 60" 
                    v-model="notes"></comment-log-list>
            </el-form-item>
        </el-form>
        <el-dialog title="Add Agreement Group" :visible.sync="showAgreementGroupEdit" width="500px">
            <AgreementGroupEdit
              v-if="showAgreementGroupEdit"
              viewType="dialog"
              :securityLevel="securityLevel_"
              :agreementGroup="newAgreementGroup"
              @saved="savedNewAgreementGroup"
              @canceled="canceledNewAgreementGroup"
              @deleted="deletedNewAgreementGroup"
              @close-dialog="showAgreementGroupEdit = false">
            </AgreementGroupEdit>
        </el-dialog>
        <el-dialog title="Add Vendor" :visible.sync="showVendorEdit" width="660px" append-to-body>
            <vendor-edit
                v-if="showVendorEdit"
                :on-save="onSaveNewVendor"
                @close-dialog="showVendorEdit = false">
            </vendor-edit>
            <div class="text-lg-right" style="padding-top: 20px;">
                <el-button @click="showVendorEdit = false">Close</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import mixinSchema_BalanceSheet from './../DAL/mixinSchema_balanceSheet'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import AgreementGroupEdit from '@/views/Agreements/AgreementGroupEdit.vue'
    import VendorEdit from './../Vendor/VendorEdit.vue'
    import debounce from 'lodash/debounce';
    import CommentLogList from '@/js/CommentLog/CommentLogList.vue';
    import BankAccountSelector from '@/components/form/BankAccountSelector.vue'
    import ClientSelector from '@/components/form/ClientSelector.vue'

    export default Vue.extend({
        name: 'AgreementEdit'
        , mixins: [mixinUtility, mixinSchema_liq, mixinSchema_vam, mixinSchema_BalanceSheet ]
        , components: {
            AgreementGroupEdit,
            'vendor-edit': VendorEdit,
            CommentLogList,
            BankAccountSelector,
            ClientSelector
        }
        , props: {
            pAgreementId: {
                type: Number
            }
            , pAgreement: {
                type: Object
            }
            , params: {
                type: Object
                , default: function () { return {} }
            }
            , securityLevel: {
                type: Number
                , default: null
            }
            , onSave: {
                type: Function
            }
}
        , data() {
            return {
                agreementId: null
                , agreement: {}
                , owners: []
                , vendors: []
                , groups: []
                , balanceSheetSubCategories: []
                , agreementRules: {
                    Name: [
                        { required: true, message: 'Agreement Name is required', trigger: 'blur' },
                        { min: 3, trigger: 'blur' }
                    ],
                    // Identifier: [
                        
                    // ],
                    ClientId: [
                        { required: true, type: 'integer', message: 'Client is Required', trigger: 'change' }
                    ],
                    TypeId: [
                        { required: true, type: 'integer', message: 'Type is Required', trigger: 'change' }
                    ],
                    OwnerId: [
                        { required: true, type: 'integer', message: 'Owner is Required', trigger: 'change' }
                    ],
                    VendorId: [
                        { required: true, type: 'integer', message: 'Vendor is Required', trigger: 'change' }
                    ]
                }
                , formValid: false
                , securityLevel_: this.securityLevel
                , agreementTypes: []
                , showAgreementGroupEdit: false
                , showVendorEdit: false
                , newAgreementGroup: this.liq_GetAgreementGroups_New()
                , loading: false
                , configuration: {}
                , notes: ''
                , showCommentLogList: true
                , clientIsSingleOption: false
            }
        }
        , created: async function () {
            this.loading = true;
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), 0);
            }
            await this.fetchAgreementTypes();
            this.agreementId = this.pAgreementId || this.params.AgreementId || (this.pAgreement ? this.pAgreement.AgreementId : null);
            if (this.pAgreement && this.pAgreement.AgreementId > 0) {
                this.agreement = this.pAgreement;
            }
            else {
                await this.fetchAgreement(this.agreementId); //watcher doesn't get it the first time
            }
            if (this.agreementId > 0) {
                await this.fetchOwners(this.agreement.ClientId);
                this.fetchVendors(this.agreement.ClientId);
                this.fetchGroups(this.agreement.ClientId);
                this.setConfiguration();
            }
            if (this.$refs && this.$refs.commentLog) await this.$refs.commentLog.fetchCommentLog();
            this.loading = false;
        }
        , computed: {
            isFormDirty() {
                return Object.keys(this.veeFields).some(key => this.veeFields[key].dirty);
            },
            selectedAgreementGroup: function() {
                if (
                    !!this.agreement &&
                    !!this.agreement.GroupId &&
                    this.groups.length > 0
                ) {
                    return this.getSelectedArrayItem(this.groups, this.agreement.GroupId, 'AgreementGroupId');
                } else {
                    return null;
                }
            },
            agreementTypeLocked: function(){
                if (!this.agreement || !this.agreement.AgreementId > 0 || !this.configuration || !Object.keys(this.configuration).length) {
                    return false;
                }
                else {
                    return  this.configuration.LockTypeSelection || false;
                }
            }
            , showPledgeFields: function(){
                if (!this.agreement || !this.agreement.AgreementId > 0 || !this.configuration || !Object.keys(this.configuration).length) {
                    return false;
                }
                else {
                    return  this.configuration.showPledgeFields || false;
                }
               
            }
            , vendorAccountNumberRequired: function() {
                if (this.configuration && Object.keys(this.configuration).length) {
                    return tryParseBool(this.configuration.vendorAccountNumberRequired, true);
                }
                else {
                    return true;
                }
            }
        }
        , watch: {
            'pAgreement': function (val, oldVal) {
                if (this.pAgreement && this.pAgreement.AgreementId) {
                    this.agreement = this.pAgreement;
                }
            }
            , 'pAgreementId': function (val, oldVal) {
                this.fetchAgreement(val);
            }
            , 'agreement.ClientId': function (val, oldVal) {
                this.fetchOwners(val);
                this.fetchGroups(val);
                this.fetchVendors(val);
                if (oldVal) { //reset if the Client Id is changed from a different value (oldVal is undefined on initial load)
                    this.agreement.OwnerId = null;
                    this.agreement.VendorId = null;
                    this.agreement.BankAccount = null;
                }
            }
            , 'agreement': {
                handler: function (val, oldVal) {
                    this.validateForm();
                }
                , deep: true
            }
            , 'agreement.IncludeOnBalanceSheet': function (val, oldVal) {
                if (val) {
                    this.fetchBalanceSheetSubCategories();
                }
                else {
                    this.agreement.BalanceSheetCategoryId = null;
                }
            }
            , 'agreement.TypeId': function(val, oldVal) {
                this.setConfiguration();
                this.setScheduleType();
            }
            , 'agreement.GroupId': function(val, oldVal) {
                if (oldVal === undefined) {
                    return; // this is during component load
                }
                const group = this.getSelectedArrayItem(this.groups, val, 'AgreementGroupId');
                if (group) {
                    if (group.VendorId){
                        if (!this.agreement.VendorId || (group.VendorSpecific && this.agreement.VendorId != group.VendorId)) { // Only change Current vendor if the group is Specific to a Vendor, or the Vendor is not set.
                            this.agreement.VendorId = group.VendorId;
                            this.$message({
                                message: 'Vendor updated to that of the Group',
                                type: 'warning'
                            });
                        }
                        this.agreement.GroupVendorSpecific = group.VendorSpecific;
                        const agreementType = this.getSelectedArrayItem(this.agreementTypes, val, 'Id');
                        this.setScheduleType();
                    }
                    if (!group.AggregateUnits) {
                        this.agreement.DefaultUnitsMethod = null;
                    }
                }
                else {
                    this.agreement.DefaultUnitsMethod = null;
                }
            }
        }
        , methods: {
            validateForm: debounce(function () {
                if (this.$refs['agreementForm']) {
                    this.$refs['agreementForm'].validate((valid) => {
                        this.formValid = valid;
                    });
                }
                else {
                    this.formValid = false;
                }
                }, 500)
            ,async fetchAgreement(agreementId) {
                if (!this.agreementId || this.agreementId == -1) {
                    this.agreement = this.liq_GetAgreements_New();
                    this.agreement.AgreementId = -1;
                    this.agreement.IncludeOnBalanceSheet = false;
                    this.agreement.InvoicesExpected = true;
                    this.agreement.TypeId = 1; // standard
                }
                else {
                    this.agreement = await this.liq_GetAgreements_Object({
                        AgreementId: agreementId
                    });
                }
            }
            , fetchAgreementTypes: async function(){
                this.agreementTypes = await this.liq_GetAgreementTypes();
            }
            , fetchOwners: async function (clientId) {
                this.owners = await this.vam_GetOwnersV2({
					ClientId: clientId
					, ClientIdList: undefined
                });
            }
            ,async fetchGroups(clientId) {
                this.groups = [];
                this.groups = await this.liq_GetAgreementGroups({
					ClientId: clientId
					});
            }

            , fetchVendors: async function (clientId) {
                if (clientId) {
                    this.vendors = await this.vam_GetVendors({
                        ClientId: clientId
                    });
                }
            }
            , fetchBalanceSheetSubCategories: async function () {
                this.balanceSheetSubCategories = await this.BalanceSheet_GetSubCategories({
					ClientId: this.agreement.ClientId
					}
				);
            }
            , setConfiguration(){
                if (!this.agreement || !Object.keys(this.agreement).length || !this.agreement.TypeId || !this.agreementTypes || !this.agreementTypes.length) this.configuration = {};

                const agreementType = this.getSelectedArrayItem(this.agreementTypes, this.agreement.TypeId, 'Id');
                if (!!agreementType && Object.keys(agreementType).length){
                    this.configuration = JSON.parse(agreementType.Configuration);
                }
                else {
                    this.configuration = {};
                }   
            }
            , onSaveNewVendor: async function(vendor) {
                await this.fetchVendors(this.agreement.ClientId);

                if (this.vendors.length) {
                    const addedVendor = this.getSelectedArrayItem(
                        this.vendors,
                        vendor.Id.toString(),
                        'Id'
                    );
                    // if the added vendor is now in the list of vendors (which requires being assigned to
                    //  the invoice's client), set agreement.VendorId to the new vendor Id
                    if (addedVendor) {
                        this.agreement.VendorId = vendor.Id;
                        this.veeFields['Vendor'].dirty = true;
                    }
                }
            }
            , async saveNotes() {
                if (this.notes) {
                    await new SmartObject('CommentLog').createObject({
                        EntityType: 'Agreement',
                        EntityId: this.agreement.AgreementId,
                        Comment: this.notes
                    });

                    this.showCommentLogList = false;
                    await this.$nextTick();
                    this.showCommentLogList = true;
                }
            }
            , saveAgreement: function () {
                this.$refs.agreementForm.validate(async (valid) => {
                    if (valid) {
                        let a;
                        if (this.agreementId > 0) {
                            a = new SmartObject('Agreement', this.agreementId);
                            await a.updateObject(this.agreement);
                            this.$notify.success('Agreement updated');
                        }
                        else {
                            a = new SmartObject('Agreement');
                            await a.createObject(this.agreement);
                            console.log(a);
                            this.agreement.Id = a.entityId;
                            this.$notify.success('Agreement created');
                            if (this.configuration && Object.keys(this.configuration).length && !!this.configuration.ClientConfiguration & this.configuration.ClientConfiguration.length){
                                const clientConfig = this.getSelectedArrayItem(this.configuration.ClientConfiguration, this.agreement.ClientId, 'ClientId');
                                if (clientConfig && Object.keys(clientConfig).length && clientConfig.Allocation && Object.keys(clientConfig.Allocation).length){
                                    await new SmartObject('Allocation').createObject({
                                        EntityType: 'Agreement',
                                        EntityId: this.agreement.Id,
                                        AccountId: clientConfig.Allocation.CoAId || null,
                                        DepartmentId: clientConfig.Allocation.DepartmentId || null,
                                        Pct: 1
                                    });
                                    this.$notify.success('Allocation created');
                                }
                            }
                        }
                        await this.saveNotes();
                        if (a.entityId > 0) {
                            this.agreementId = a.entityId;
                            await this.fetchAgreement(this.agreementId); 
                            
                            await this.$validator.reset();

                            if (typeof this.onSave === 'function') {
                                this.onSave(this.agreement);
                            }
                            this.$emit('saved', this.agreement);
                            this.$emit('update:pAgreement', this.agreement);
                        }
                    } else {
                        this.$message.error('Not Saved.  See Validation Errors.');
                        return false;
                    }
                });
            }
            ,async deleteAgreement() {
                try {
                    await this.$confirm('Delete Agreement?', 'Delete', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    })
                    //delete:
                    const so = new SmartObject('Agreement', this.agreement.AgreementId);
                    await so.deleteObject();
                    this.$notify.success('Agreement Deleted');
                    this.$emit('agreement-deleted', this.agreement.AgreementId);
                }
                catch {
                    // canceled
                }
            }
            , copyAgreement: async function () {
                try {
                    await this.$confirm(
                        'Are you sure you want to copy this agreement?',
                        'Copy Agreement',
                        {
                            confirmButtonText: 'Copy',
                            cancelButtonText: 'Cancel',
                            type: 'warning'
                        }
                    );

                    try {
                        this.loading = true;
                        const copyNotification = this.$notify.info({
                            title: null,
                            message: 'Copying agreement...',
                            duration: 0
                        });
                        const dsAgreement = await this.liq_CopyAgreement({
                            AgreementId: this.agreement.AgreementId
                        });
                        copyNotification.close();
                        this.loading = false;

                        this.$notify.success('Agreement copied.');

                        this.$emit('agreement-copied', dsAgreement ? dsAgreement[0] : null);
                    } catch (err) {
                        this.$notify.error('Something went wrong processing your request, please try again.');
                    }
                } catch (err) {}
            }
            , addAgreementGroup: function(){
                this.newAgreementGroup = this.liq_GetAgreementGroups_New();
                this.newAgreementGroup.AgreementGroupId = -1;
                this.newAgreementGroup.ClientId = this.agreement.ClientId;
                this.newAgreementGroup.VendorId = this.agreement.VendorId;
                this.$nextTick(function(){
                    this.showAgreementGroupEdit = true;
                });
            }
            , savedNewAgreementGroup: function(newAgreementGroup){
                this.groups.push(newAgreementGroup);
                this.agreement.GroupId = newAgreementGroup.AgreementGroupId;
                this.showAgreementGroupEdit = false;
            }
            , canceledNewAgreementGroup: function(){
                this.showAgreementGroupEdit = false;
            }
            , deletedNewAgreementGroup: function(){
                this.showAgreementGroupEdit = false;
            }
            , setScheduleType(){
                if (this.configuration && Object.keys(this.configuration).length && !!this.configuration.ScheduleType){
                    this.agreement.ScheduleType = this.configuration.ScheduleType;
                }
            }
            , clientSingleOptionAvailable(){
                this.clientIsSingleOption = true;
            },

        }
    })
</script>

<style scoped>
    .el-form-item{
        margin-bottom:5px;
    }
</style>