
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import InvestmentTransactionEdit from '@/views/InvestmentTransaction/InvestmentTransactionEdit.vue';
import OwnerAllocationTree from '@/views/InvestmentTransaction/OwnerAllocationTree.vue';
import AttachmentList from '@/js/Attachments/AttachmentList.vue';
import * as TransactionsService from '../../services/DAL/transactionsService';
import ChangeLogList from '@/js/ChangeLog/ChangeLogList.vue';
import * as transactionTypes from '@/views/InvestmentTransaction/transaction';

@Component({
  components: {
    InvestmentTransactionEdit,
    AttachmentList,
    OwnerAllocationTree,
    ChangeLogList
  }
})
export default class InvestmentTransactionTabView extends Vue {
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() transaction: transactionTypes.Transactions | TransactionsService.TransactionsList;
    @Prop() transactionId: number;
    @Prop() investmentId: number;
    @Prop({ default: false }) loadDocumentsTab: boolean;
    @Prop() viewType: string;
    @Prop({ type: Number, default: null })
    securityLevel: number;
    @Prop({ default: true }) appendDialogsToBody: boolean;
    //#endregion Props

    //#region Data
    transaction_: TransactionsService.TransactionsList = new TransactionsService.TransactionsList();
    transactionId_: number = null;
    clientId_: number = null;
    showChangeLogList = true;
    securityLevel_: number = null;

    //#endregion Data

    //#region Computed
    get attachmentParameters(): Object {
        return {
            entityId: this.transaction_.ImputedRecord ? 0 - this.transactionId_ : this.transactionId_
            , entityType: 'InvestmentTransaction'
            , storeCategoryId: 10
            , clientId: this.clientId_ || undefined
        };
    }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets), 0);

        if (this.transaction && Object.keys(this.transaction).length && this.transaction.Id > 0) {
            this.transaction_ = this.transaction;
            this.transactionId_ = this.transaction.Id;
            this.clientId_ = this.transaction.ClientId;
        }
        else if (this.transactionId) { 
            this.transactionId_ = this.transactionId;
            await this.fetchTransaction();
            this.clientId_ = this.transaction_.ClientId;
        }
    }
    //#endregion Lifecycle
    //#region Methods
    async fetchTransaction() {
        if (this.transactionId_ == null){
            console.error('TransactionId is null');
            return;
        }
        const parameters = {} as TransactionsService.GetTransactionsListParameters;
        parameters.TransactionId = this.transactionId_;
        const transactions = await this._transactionsService.GetTransactionsList(
            parameters
        );
        if (transactions.length) {
            this.transaction_ = transactions[0];
        }
    }
    async transactionSaved(transaction){
        this.transactionId_ = transaction.Id;
        await this.fetchTransaction();
        this.clientId_ = this.transaction_.ClientId;
        await this.$nextTick();
        this.refreshChangeLogList();
        this.$emit('save', transaction);
    }
    async refreshChangeLogList() {
      this.showChangeLogList = false;
      await this.$nextTick();
      this.showChangeLogList = true;
    }

    //#endregion Methods
}
