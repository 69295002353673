<template>
    <div id="sharepoint-library-list">
        <el-select v-model="selectedStoreId"
                   size="mini"
                   v-bind:disabled="(stores.length==0)"
                   v-show="!showAddLibraries"
                   filterable
                   clearable>
            <el-option v-for="store in stores"
                       :key="store.Id"
                       :label="store.Name"
                       :value="store.Id">
            </el-option>
        </el-select>
        <el-button type="primary" size="mini" v-on:click="showAddLibraries=true" v-show="!showAddLibraries">Add More Libraries</el-button>
        <div v-show="showAddLibraries" id="divAddLibraries">
            Select Site: <el-select v-model="sharePointSiteId"
                    size="mini"
                    v-bind:disabled="(sites.length==0)"
                    clearable>
                <el-option v-for="site in sites"
                        :key="site.Id"
                        :label="site.Name"
                        :value="site.SharePointId">
                </el-option>
            </el-select>
            <el-table v-show="libraries[0]"
                    :data="libraries"
                    style="width: 100%;"
                    stripe
                    @row-click="librarySelect">
                <el-table-column label="Existing Id"
                                property="StoreId">
                </el-table-column>
                <el-table-column label="Add"
                                min-width="75px">
                    <template slot-scope="scope">
                        <i class="fa fa-plus" aria-hidden="true" v-if="!scope.row.StoreId"></i>
                    </template>
                </el-table-column>
                <el-table-column label="Name"
                                min-width="400px"
                                property="name">
                </el-table-column>
                <el-table-column label="Description"
                                min-width="500px"
                                property="description">
                </el-table-column>
            </el-table>
            <el-dialog
                title="Save Additional Library"
                :visible.sync="dialogVisibleLibraryCategory"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                :show-close="false"
                >
                <h3>{{newLibrary.Name}}</h3>
                Category: <el-select v-model="newLibrary.TypeId"
                        size="mini"
                        v-bind:disabled="(categories.length==0)"
                        >
                    <el-option v-for="category in categories"
                                :key="category.Id"
                                :label="category.Name"
                                :value="category.Id">
                    </el-option>
                </el-select>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleLibraryCategory = false">Cancel</el-button>
                    <el-button type="primary" @click="saveNewLibrary">Add New Library</el-button>
                </span>
                </el-dialog>
        </div>
        <div v-show="selectedStoreId">
            Records:{{items.length}}
            <el-button type="primary" size="mini" v-on:click="saveLibraryStage">Stage</el-button>
        </div>
        {{message}}
        <el-table v-if="items[0]"
                  :data="items"
                  style="width: 100%;"
                  stripe
                  @current-change="itemSelect">
            <el-table-column label="File"
                             min-width="300px"
                             v-if="showFileName"
                             property="FileName">
            </el-table-column>
            <el-table-column label="Description"
                             align="left"
                             min-width="300px"
                             prop="Description">
            </el-table-column>
            <el-table-column label="Date"
                                align="center"
                                min-width="150px"
                                :formatter="formatterShortDate"
                                prop="Dated">
            </el-table-column>
            <el-table-column label="Type"
                                align="center"
                                min-width="150px"
                                prop="Type">
            </el-table-column>
            <el-table-column label="Owner"
                                min-width="150px"
                                align="center"
                                prop="Owner">
            </el-table-column>
            <el-table-column label="IP"
                                min-width="150px"
                                align="center"
                                prop="InvestmentParent">
            </el-table-column>
            <el-table-column label="Institution"
                                min-width="150px"
                                align="center"
                                prop="Institution">
            </el-table-column>
            <el-table-column label="Comment"
                                min-width="200px"
                                align="center"
                                prop="Comment">
            </el-table-column>
</el-table>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_attachment from './../DAL/mixinSchema_attachment'
    import mixinSchema_util from './../DAL/mixinSchema_util'
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';
    export default Vue.extend({
        name: 'SharepointLibraryList'
        , mixins: [mixinSchema_util, mixinUtility, mixinSchema_attachment ]
        , props: {
            parameters: {
                type: Object
            }
}
        , data() {
            return {
                attachment: {}
                , fileProperties: {}
                , entityId: null
                , entityType: null
                , attachmentTypeId: null
                , items: {}
                , showFileName: true
                , stores: []
                , selectedStoreId: null
                , message: ''
                , showAddLibraries: false
                , sharePointSiteId: null
                , sites: []
                , libraries: []
                , categories: []
                , dialogVisibleLibraryCategory: false
                , newLibrary: {
                    TypeId: null
                    , Name: null
                    , SharePointListId: null
                    , SiteId:null
                }
                , graphService: {}
            }
        }
        , created: function () {
            this.graphService = new MicrosoftGraphService();
            if (this.parameters) {
                this.entityId = this.parameters.entityId;
                this.entityType = this.parameters.entityType;
                this.attachmentTypeId = this.parameters.attachmentTypeId;
            }
            
            this.fetchAttachments();
            this.fetchStores();
            
        }
        , computed: {
            basePath: function () {
                if (this.selectedStoreId) {
                    return this.getSelectedArrayItem(this.stores, this.selectedStoreId, 'Id').DriveItemsPath;
                }
                else
                    return null;
            }
            , suffix: function () {
                if (this.selectedStoreId) {
                    return this.getSelectedArrayItem(this.stores, this.selectedStoreId, 'Id').Suffix;
                }
                else
                    return null;
            }
            , detailSuffix: function () {
                if (this.selectedStoreId) {
                    return this.getSelectedArrayItem(this.stores, this.selectedStoreId, 'Id').DetailSuffix;
                }
                else
                    return null;
            }

        }
        , watch: {
            'selectedStoreId': function (val, oldVal) {
                this.fetchAttachments();
            }
            , 'showAddLibraries': function (val, oldVal) {
                if (val && !oldVal){
                    this.sites = this.attachment_GetSites();
                    //this.categories = this.attachment_GetCategories((this.parameters) ? this.parameters.entityType : undefined);
                    var el = this;
                    this.attachment_GetCategories({
                        EntityType: (this.parameters) ? this.parameters.entityType : undefined
                        //, StoreId: this.store.Id
                    }
                        , function (data) {
                            el.categories = data;
                        }
                        , function (error) {
                            console.error(error);
                        });
                }
            }
            , 'sharePointSiteId': function (val, oldVal) {
                this.getSharePointLists(val);
            }
        }
        , methods: {
            fetchStores: function () {
                var el = this;
                this.attachment_GetStores({
                    Type: 'DocumentLibrary'
                    //, StoreId: storeId
                }
                    , function (data) {
                        //console.log(data);
                        el.stores = data;
                    }
                    , function (error) {
                        console.error(error);
                    });
            }
            , fetchAttachments: async function () {
                var nextLink = ' '; //not empty, but won't affect the outcome
                this.items = [];
                var batch = 1
                const graphClient = await this.graphService.getGraphClient();
                
                while (this.basePath != null && nextLink && nextLink != '') {
                    this.message = 'Getting files in Batch ' + batch;
                    //var files = await this.graphAPIGet(graphClient, this.basePath + this.suffix + nextLink, 'beta');
                    const files = await graphClient.api(this.basePath + this.suffix + nextLink).get();
                    if (files['@odata.nextLink'])
                        nextLink = files['@odata.nextLink'].substring(files['@odata.nextLink'].indexOf('&$skiptoken'));
                    else
                        nextLink = '';
                    var normalizedFiles = await this.normalizeFilesData(files.value, batch);
                    batch++;
                    this.message = '';
                    this.items = this.items.concat(normalizedFiles);
                }
            }
            , normalizeFilesData: async function (files, batch) {
                var ds = [];
                var prefix = (this.getSelectedArrayItem(this.stores, this.selectedStoreId, 'Id').ClientId == 10) ? 'LFX_x0020_' : '';
                // const graphClient = await this.getMicrosoftGraphClientPromise();
                const graphClient = await this.graphService.getGraphClient();
                if (files) {
                    for (var i = 0, len = files.length; i < len; i++) {
                        this.message = 'Getting details for file ' + batch + ':' + files[i].id + ' (' + files[i].sharepointIds.listItemUniqueId + ')';
                        // var details = await this.graphAPIGet(graphClient, this.basePath + '(\'' + files[i].id + '\')' + this.detailSuffix, 'beta');
                        const details = await graphClient.api(this.basePath + '(\'' + files[i].id + '\')' + this.detailSuffix).get();
                        var InvestmentParentId = null;
                        var InvestmentParent = null;
                        if (files[i].fields[prefix + 'InvestmentParent']) {
                            if ((files[i].fields[prefix + 'InvestmentParent'])[0]) {
                                InvestmentParent = (files[i].fields[prefix + 'InvestmentParent'])[0].LookupValue;
                                InvestmentParentId = (files[i].fields[prefix + 'InvestmentParent'])[0].LookupId;
                            }
                        }
                        var row = {
                            StoreId: this.selectedStoreId
                            , FileId: files[i].sharepointIds.listItemUniqueId
                            , FileName: files[i].fields.LinkFilename
                            , Description: files[i].fields.Title || files[i].fields.LinkFilename.substr(0, files[i].fields.LinkFilename.lastIndexOf('.')) || files[i].fields.LinkFilename
                            , Type: details['Correspondence_x0020_Type']
                            , Institution: details['Institution_x002f_Source']
                            , Owner: details[prefix + 'Owner']
                            , InvestmentParent: InvestmentParent
                            , Comment: files[i].fields.Comment
                            , Dated: files[i].fields.Date
                        }
                        ds.push(row);
                    }
                }
                return ds;
            }
            , saveLibraryStage: async function () {
                this.message = 'Getting LogId';
                const logEvent = await this.util_LogEventSelect_Object({
                        Database: 'VisiumDB'
                        , Schema: 'tax'
                        , Caller: 'ModelImport'
                        , Event: 'Import'
					}
					, true // async (optional)
                );
                const logId = logEvent.LogId;
                this.message = 'LogId: ' + logId;
                var counter = 0;
                var so = new SmartObject('LibraryStage');
                for (var i = 0, len = this.items.length; i < len; i++) {
                    var file = this.items[i];
                    file.LogId = logId;
                    var newId = await so.createObject_lgcy(file);
                    //console.log(newId);
                    this.message = 'File Record Created: ' + newId;
                    counter++;
                }
                this.message = counter + ' records inserted for LogId ' + logId;
                //alert(counter + " records inserted");
            }
            , itemSelect: async function (selectedRow) {
                // const graphClient = await this.getMicrosoftGraphClientPromise();
                const graphClient = await this.graphService.getGraphClient();
                if (selectedRow != null) {
                    console.log(selectedRow);
                    // var fileProperties = await this.getFileProperties(graphClient, this.basePath, selectedRow.FileId);
                    const fileProperties = await graphClient.api(this.basePath + selectedRow.FileId).get();
                    console.log(fileProperties);
                }
            }
            , librarySelect: async function(selectedRow){
                if (!selectedRow.StoreId){ //only if it's not already saved
                    console.log(selectedRow);
                    const graphClient = await this.graphService.getGraphClient();
                    const drives = await graphClient.api('/sites/' + selectedRow.parentReference.siteId + '/drives?select=name,id').get();
                    const drive = this.getSelectedArrayItem(drives.value, selectedRow.name, 'name');
                    console.log('drives:', drives);
                    this.newLibrary.Name = selectedRow.name;
                    this.newLibrary.SharePointListId = selectedRow.id;
                    if (drive && Object.keys(drive).length){
                        this.newLibrary.DriveId = drive.id;
                    }
                    else {
                        this.$alert(`Unable to find Drive Id for ${selectedRow.name}.  Update Store table manually.`)
                    }
                    this.dialogVisibleLibraryCategory = true;
                }
            }
            , saveNewLibrary: function(){
                var so = new SmartObject('Store');
                //Name, TypeId, SiteId, SharePointListId
                var newId = so.createObject_lgcy(this.newLibrary);
                if (newId){
                    this.$notify.success({
                        title: null,
                        message: 'Added new Library ' + this.newLibrary.Name + ' (' + newId + ')',
                        offset: 100
                    });
                    this.dialogVisibleLibraryCategory = false;
                    this.libraries = [];
                    this.sites = [];
                    this.showAddLibraries = false;
                    this.newLibrary= {
                            TypeId: null
                            , Name: null
                            , SharePointListId: null
                            , SiteId:null
                        }
                    this.fetchStores();
                }
                else {
                    this.$notify.warning({
                        title: 'Error',
                        message: 'Library not added ' + this.newLibrary.Name,
                        offset: 100
                    });
                    this.dialogVisibleLibraryCategory = false;
                }

            }
            , getSharePointLists: async function(sharePointSiteId){
                try{
                    //const graphClient = await this.getMicrosoftGraphClientPromise();
                    const graphClient = await this.graphService.getGraphClient();                    
                    this.newLibrary.SiteId = this.getSelectedArrayItem(this.sites, sharePointSiteId, 'SharePointId').Id;
                    var path = 'sites/' + sharePointSiteId + '/lists';
                    //var lists = await this.graphAPIGet(this.graphClient, path, 'beta');
                    const lists = await graphClient
                            .api(path)
                            .get();
                    var filteredLibraries = lists.value.filter(function(list){
                    //get rid of the lookup and other lists.
                    return list.list.template == 'documentLibrary';
                    });
                    var existing = this.stores.reduce(function(out, store){
                        // reduce the existing stores to just those that match the selected site, and convert the List Id and Store Id.
                        if (store.SharePointSiteId == sharePointSiteId){
                            out.push({
                                id: store.SharePointListId
                                , name: store.Name
                                , StoreId: store.Id //this is how we'll know it's already stored.
                            });
                        }
                        return out;
                    }, []); //[] is the initial value of "out" which allows us to .push on out.
                    var joined= _({}) // Start with an empty object
                            .merge(
                                _(filteredLibraries).groupBy("id").value(),
                                _(existing).groupBy("id").value()
                            )
                            .values()
                            .flatten()
                            .value(); 
                    this.libraries = joined; 
                }
                catch(e){
                    console.error(e);
                }
            }
        }
    })
</script>
