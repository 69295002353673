
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as VamService from '../../services/DAL/vamService';

type iOwners = VamService.OwnersList | VamService.OwnersForAssignment;

@Component({
    inheritAttrs: false
})
export default class OwnerSelector extends Vue {
    //#region Private declarations for Services
    private _vam_Service: VamService.VamService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() readonly value: number;
    @Prop() readonly clientId: number;
    @Prop({ default: false }) readonly requireClientId: boolean
    @Prop() readonly considerationEntityType: string;
    @Prop() readonly usesList: string;
    @Prop({type: String}) readonly method: string;
    @Prop({type: Number}) readonly sourceOwnerId: number;
    @Prop({ default: false }) readonly readOnly: boolean;
    //#endregion Props

    //#region Data
    owners = [] as iOwners[];
    selectedValue: number = null;
    public selectedOwner = {} as iOwners;
    //#endregion Data

   //#region Lifecycle
    async created() {
        this._vam_Service = new VamService.VamService();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchOwners();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onOwnerChange(val: number, oldVal: number) {
        this.selectedValue = val;
        if (!this.owners.length) {
            this.fetchOwners();
        }
        else {
            this.getSelectedOwner();
        }

    }
    @Watch('clientId')
    onClientChange(val: number, oldVal: number) {
        this.fetchOwners();
    }
    @Watch('sourceOwnerId')
    onChange_sourceOwnerId(val: number, oldVal: number) {
        this.fetchOwners();
    }
    @Watch('selectedOwner')
    onChange_selectedOwner(val: iOwners, oldVal: iOwners) {
        // if directly selected, before picking Owner and Parent, set those, too.
        this.$emit('value-set', this.selectedOwner);
    }

    //#endregion Watches

    //#region Methods
    async fetchOwners() {
        this.owners = [];
        const prevSelectedValue = this.selectedValue;
        this.selectedValue = null;
        if (this.method == 'GetOwnersForAssignment' && this.sourceOwnerId) {
            const params = {} as VamService.GetOwnersForAssignmentParameters;
            params.OwnerId = this.sourceOwnerId;
            this.owners = await this._vam_Service.GetOwnersForAssignment(params);
            if (this.owners.length == 0){
                this.$alert('No associated target owners available for selected source owner.', 'Warning');
            }
        }
        else if (this.method == 'ExtraOwner' && this.sourceOwnerId) {
            const params = {} as VamService.GetExtraOwnersParameters;
            params.OwnerId = this.sourceOwnerId;
            this.owners = await this._vam_Service.GetExtraOwners(params);
            if (this.owners.length == 0){
                this.$alert('No associated target owners available for selected source owner.', 'Warning');
            }
        }
        else if (!this.requireClientId || !!this.clientId){
            const params = {} as VamService.GetOwnersListParameters;
            params.ClientId = this.clientId;
            params.UsesList = this.usesList || null;
            params.ConsiderationEntityType = this.considerationEntityType;
            this.owners = await this._vam_Service.GetOwnersList(params);
        }
        this.$emit('options-fetched', this.owners);

        if (prevSelectedValue > 0) {
            // if previously selected value is in the new owner list, set the current value to the previous value
            const prevSelectedOwner = this.owners.find( owner => owner.OwnerId == prevSelectedValue);
            if (prevSelectedOwner) {
                this.selectedValue = prevSelectedValue;
                if (this.selectedValue !== this.value) {
                    this.$emit('input', this.selectedValue);
                }
            }
        }
        this.getSelectedOwner();
    }
    getSelectedOwner() {
        if (this.owners.length && this.selectedValue > 0) {
            this.selectedOwner = this.owners.find( owner => owner.OwnerId == this.selectedValue);
        }
        else {
            this.selectedOwner = new VamService.OwnersList();
        }
    }

    //#endregion Methods
}
