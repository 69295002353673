
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as LiqService from './../../services/DAL/liq_Service';
import ClientSelector from '@/components/form/ClientSelector.vue';
import BankAccountReconciliationEditView from '@/views/BankAccount/BankAccountReconciliationEditView.vue';
import uniqBy from 'lodash/uniqBy';
import formatters from '../../utils/formatters';
import Common from '../../utils/common';
import { ElTable } from 'element-ui/types/table';
import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';
import PDFViewer from '@/components/other/PDFViewer.vue';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare function getSecurityUserId(): string;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;
@Component({
  components: {
    ClientSelector,
    BankAccountReconciliationEditView,
    'pdf-viewer': PDFViewer
  }
})
export default class BankAccountReconciliationList extends Vue {
  $refs: {
    refBankDepositoryAccountReconciliations: ElementUI.Table
  }
  //#region Data
  private _liqService: LiqService.LiqService;
  private formatters: formatters = new formatters();
  private _common: Common;

  @Prop({ default: null })
  clientId: number;
  @Prop({ default: null })
  yearMonth: number;

  selectedClientId: number = null;
  selectedMonthYear: number = null;

  showDataPane: boolean = false;
  isLoading: boolean = false;

  filterBankDepositoryAccountReconciliations: string = '';
  bankDepositoryAccountReconciliations: LiqService.BankDepositoryAccountReconciliations[] = [];

  selectedItem: LiqService.BankDepositoryAccountReconciliations;
  showSideBySide = false;
  sideBySideFileData = {} as MicrosoftGraphTypes.DriveItem;

  securityLevel_: number = getStoredSecurityLevel(
    this.$namedKey.SecurityView.ManageBankAccounts
  );
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._liqService = new LiqService.LiqService();
    this.formatters = new formatters();
    this._common = new Common();
    if (this.yearMonth){
      this.selectedMonthYear = this.$moment(this.yearMonth, 'YYYYMM');
    }
    else {
      this.selectedMonthYear = this.$moment().subtract(1, 'months').startOf('month');
    }
    if (this.clientId){
      this.selectedClientId = this.clientId;
    }
  }

  async mounted() {}
  //#endregion Lifecycle

  //#region Computed
  get selectedMonth(): number {
    if (this.selectedMonthYear) {
      return this.$moment(this.selectedMonthYear).month() + 1;
    }
  }
  get selectedYear(): number {
    if (this.selectedMonthYear) {
      return this.$moment(this.selectedMonthYear).year();
    }
  }
  get bankDepositoryAccountReconciliationsFiltered(): LiqService.BankDepositoryAccountReconciliations[] {
    const filter_ = (
      this.filterBankDepositoryAccountReconciliations || ''
    ).toLowerCase();
    return this.bankDepositoryAccountReconciliations.filter(entity => {
      return (
        (entity.BankAccountId || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.BankNameNumberMasked || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.Type || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.ReconciliationId || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.BankBeginningBalance || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.BankEndingBalance || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.Change || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.VAMChange || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.VAMTransactions || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.Status || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1 ||
        (entity.ReconciliationCreatedLabel || '')
          .toString()
          .toLowerCase()
          .indexOf(filter_) > -1
      );
    });
  }
  //#endregion Computed

  //#region Watches
  @Watch('selectedMonthYear')
  onChange_selectedMonthYear(val, oldVal) {
    this.closeItem();
    this.fetchReconciliations();
  }
  @Watch('selectedClientId')
  onChange_selectedClientId(val, oldVal) {
    this.closeItem();
    this.fetchReconciliations();
  }

  //#endregion Watches

  //#region Methods
  async fetchReconciliations() {
    this.isLoading = true;
    this.bankDepositoryAccountReconciliations = [] as LiqService.BankDepositoryAccountReconciliations[];
    if (
      !!this.selectedClientId &&
      !!this.selectedMonth &&
      !!this.selectedYear
    ) {
      const params = {} as LiqService.GetBankDepositoryAccountReconciliationsParameters;
      params.ClientId = this.selectedClientId;
      params.Month = this.selectedMonth;
      params.Year = this.selectedYear;
      this.bankDepositoryAccountReconciliations = await this._liqService.GetBankDepositoryAccountReconciliations(
        params
      );
      await this.$nextTick();
      if (this.$refs.refBankDepositoryAccountReconciliations) {
        this.$refs.refBankDepositoryAccountReconciliations.doLayout();
      }
    }
    this.isLoading = false;
  }
  async editBankDepositoryAccountReconciliationsItem(
    selectedRow: LiqService.BankDepositoryAccountReconciliations,
    event,
    column
  ) {
    if (selectedRow && selectedRow != null) {
      this.showDataPane = false;
      this.selectedItem = selectedRow;
      this.showDataPane = true;
    }
  }
  bankDepositoryAccountReconciliationsRowClassName({ row, rowIndex }): string {
    if (row.Status == 'Not Reconciled' || row.Status == 'Current VAM Differs from Original') { // the plus signs corecrce the dates into numbers, so that we can get a good comparison
            return 'warning-row';
        } else {
    return '';
        }
  }
  filterHandler(
    value,
    row: LiqService.BankDepositoryAccountReconciliations,
    column
  ) {
    const property = column['property'];
    return row[property] === value;
  }
  filters(column) {
    const filters = this.bankDepositoryAccountReconciliationsFiltered.map(
      function(list) {
        return {
          text: list[column],
          value: list[column]
        };
      }
    );
    return uniqBy(filters, 'value');
  }
  deletedItem() {
    this.filterBankDepositoryAccountReconciliations = '';
    this.showSideBySide = false;
    this.showDataPane = false;
    this.fetchReconciliations();
  }
  closeItem() {
    this.showDataPane = false;
    this.selectedItem = null;
    this.showSideBySide = false;
    this.sideBySideFileData = {} as MicrosoftGraphTypes.DriveItem;

    if (this.$refs.refBankDepositoryAccountReconciliations) {
      this.$refs.refBankDepositoryAccountReconciliations.setCurrentRow(
        null
      );
    }
  }
  itemUpdated(updatedItem: LiqService.BankDepositoryAccountReconciliations) {
    //this.fetchReconciliations();
    const index = this.bankDepositoryAccountReconciliations.indexOf(this.selectedItem);
    this.bankDepositoryAccountReconciliations.splice(index, 1, updatedItem);
  }
    sideBySideChanged(payload: any){
      this.showSideBySide = payload.sideBySide;
      if (this.showSideBySide){
        this.sideBySideFileData = payload.fileData;
      }
      else {
        this.sideBySideFileData = {} as MicrosoftGraphTypes.DriveItem;
      }
    }
  //#endregion Methods
}
