
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as TransactionsService from '../../services/DAL/transactionsService';
import * as AssetService from '../../services/DAL/assetService';

import Common from '../../utils/common';
import FormattedInput from '@/components/form/FormattedInput.vue';
import CommentLogList from '@/js/CommentLog/CommentLogList.vue';
import InvestmentSelector from '@/components/form/InvestmentSelector.vue';
import InvestmentTransactionTypeSelector from '@/components/form/InvestmentTransactionTypeSelector.vue';
import HoldingEntitySelector from '@/components/form/HoldingEntitySelector.vue';
import StructureTypeSelector from '@/components/form/StructureTypeSelector.vue';
import StructureSelector from '@/components/form/StructureSelector.vue';
import NewStructureSelector from '@/components/form/NewStructureSelector.vue';
import ReturnIncomeTypeSelector from '@/components/form/ReturnIncomeTypeSelector.vue';
import CapitalCallScheduleSelector from '@/components/form/CapitalCallScheduleSelector.vue';

import FundingSourceList from '@/views/FundingSource/FundingSourceList.vue';
import SpecificLotEditView from '@/views/InvestmentTransaction/SpecificLotEditView.vue';
import * as transactionTypes from '@/views/InvestmentTransaction/transaction';
import ElementUI from 'element-ui';

declare var $: any;
declare var SmartObject: any;
declare function smartConfirm(options: any);
declare function tryParseInt(input: any, defaultValue: number): number;
declare function getStoredSecurityLevel(Id: number): number;
declare function getAppCookieKeyValue(key: string, defaultValue: object): string;
declare var process: any;

interface formItemProperties{
    show: boolean,
    label: string,
    required: boolean
}
@Component({
    components: {
        FormattedInput,
        CommentLogList,
        InvestmentSelector,
        InvestmentTransactionTypeSelector,
        HoldingEntitySelector,
        StructureTypeSelector,
        StructureSelector,
        NewStructureSelector,
        ReturnIncomeTypeSelector,
        FundingSourceList,
        SpecificLotEditView,
        CapitalCallScheduleSelector,
        InvestmentTransactionTabView: () => import('@/views/InvestmentTransaction/InvestmentTransactionTabView.vue')
    }
})
export default class InvestmentTransactionEdit extends Vue {
    $refs: {
        frmTransactionEdit: ElementUI.Form
        , ucTransferPreview: HTMLDivElement
    }
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private _assetService: AssetService.AssetService;
    private _common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop() transaction: transactionTypes.Transactions;
    @Prop() transactionId: number;
    @Prop() investmentId: number;
    @Prop() viewType: string;
    @Prop({ type: Number, default: null })
    securityLevel: number;
    @Prop({
        default: true
    })
    appendDialogsToBody: boolean;
    //#endregion Props

    //#region Data
    securityLevel_: number = this.securityLevel;
    creator: any = {};
    notes: string = null;
    showCommentLogList: boolean = true;
    clientId: number = null;
    ownerId: number = null;
    investmentParentId: number = null;
    transaction_: transactionTypes.Transactions = new transactionTypes.Transactions();
    investment: AssetService.InvestmentList = {} as AssetService.InvestmentList;
    transactionType = new TransactionsService.TransactionTypes();
    fullAmountFundedByCash: boolean = false;
    fundingSources: TransactionsService.FundingSource[] = [];
    showFundingSourceList: boolean = true;
    amount2Label: string = 'Amount2';
    showCreateAndMatchManualTransaction: boolean = false;
    showEditSpecificLot: boolean = false;
    showSourceTransaction: boolean = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this._assetService = new AssetService.AssetService();
        this._common = new Common();

        if (!this.securityLevel_) {
            this.securityLevel_ = tryParseInt(
                getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
                0
            );
        }

        if (this.transaction && Object.keys(this.transaction).length && this.transaction.Id > 0) {
            this.transaction_ = this.transaction;
        }
        else if (this.transactionId) { 
            await this.fetchTransaction();
        }
        else {
            this.transaction_ = new transactionTypes.Transactions();
            this.transaction_.InvestmentId = this.investmentId;
            this.transaction_.DocumentationReceived = false;
            this.transaction_.IsAccrual = false;
            this.transaction_.IsLastTransaction = false;
        }

        if (!this.isNew) {
            this.fetchCreator();
        }
        if (this.transaction_.ImputedRecord){
            this.securityLevel_ = 0; //disables all fields and removes save button.  No security risk in being overriden, because there's no actual database record to be updated.
        }
    }
     mounted() {
        this.$refs.frmTransactionEdit.resetFields(); 
    }
    //#endregion Lifecycle

    //#region Computed
    get isNew(): boolean {
        return (
            !this.transaction_ ||
            !Object.keys(this.transaction_).length ||
            (this.transaction_.Id || -1) == -1
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    
    get showNewEntity(): boolean { return this.transactionType.ShowNewEntity; }
    get showEntity(): boolean { return this.transactionType.ShowEntity; }
    get showStructureType(): boolean { return this.transactionType.ShowStructureType; }
    get showStructure(): boolean { return this.transactionType.ShowStructure; }
    get showNewStructureType(): boolean { return this.transactionType.ShowNewStructureType; }
    get showNewStructure(): boolean { return this.transactionType.ShowNewStructure; }
    get showAmount(): boolean { 
        if (this.transactionType.Display == 'Exchange' && this.transaction_.SourceStructureType == 'Equity') {
            // because Exchanges of Equity (like from Common to Ordinary) shouldn't have a value change
            return false;
        }
        else 
            return this.transactionType.ShowAmount; 
        }
    get showFundingSource(): boolean { return this.transactionType.ShowFundingSource; }
    get showShares(): boolean { return this.transactionType.ShowShares; }
    get showShareValue(): boolean { return this.transactionType.ShowShareValue; }
    get showCostBasisMethod(): boolean { return this.transactionType.ShowCostBasisMethod; }
    get showReturnIncomeType(): boolean { return this.transactionType.ShowReturnIncomeType; }
    get showNotTaxable(): boolean { return this.transactionType.ShowNotTaxable; }
    get showSplitRatio(): boolean { return this.transactionType.ShowSplitRatio; }
    get showIsLastTransaction(): boolean { return this.transactionType.ShowIsLastTransaction; }
    get showNewInvestment(): boolean { return this.transactionType.ShowNewInvestment; }
    get showTaxAcquisitionDate(): boolean { return this.transactionType.ShowTaxAcquisitionDate; }
    get showConsideration(): boolean { return this.transactionType.ShowConsideration; }
    get showAssessedValue(): boolean { return this.transactionType.ShowAssessedValue; }
    get showFundingSourceEntityType(): boolean { return this.transactionType.ShowFundingSourceEntityType; }
    get showFundingSourceEntityId(): boolean { return this.transactionType.ShowFundingSourceEntityId; }
    get showManualCostBasis(): boolean { return this.transactionType.ShowManualCostBasis; }    
    get allowSpecificLotManualCostBasis(): boolean { return this.transactionType.AllowSpecificLotManualCostBasis; }

    get showCapitalCallScheduleSelector(): boolean {
        if (this.investment && this.investment.HasCommitment && this.transactionType && this.transactionType.IsCapitalCall) return true;
        else return false;
    }

    get fundingSourceConsiderationLabel(): string {
        if (this.showFundingSource) return 'Funding Source';
        if (this.showConsideration) return 'Consideration';
        return null;
    }
    get amount2Properties(): formItemProperties{
        const prop = {} as formItemProperties;
        prop.show = false;
        prop.label = '';
        prop.required = true;
        
        if (this.transactionType.IsReturn && this.transaction_.SourceStructureType == 'Debt'){
            prop.label = 'Step Up Amount';
            prop.show = true;
            return prop;
        }

        if(this.transactionType.IsPurchase && 
            (this.investment.InvestmentType == 'Private Equity'
            || this.investment.InvestmentType == 'Venture'
            || this.investment.InvestmentType == 'PIP'
            || this.investment.InvestmentType == 'Hedge Fund w/ Commitment')) {
                prop.label = 'Commitment Amt to Relieve';
                prop.show = true;
                prop.required = false;
                return prop;
        }
        if(this.transactionType.IsTransfer && 
            (this.investment.InvestmentType == 'Private Equity'
            || this.investment.InvestmentType == 'Venture'
            || this.investment.InvestmentType == 'PIP')) { //NOTE: DO NOT Add Hedge Fund w/ Commitment here.  Transferring Commitments for HF causes problem due to frequent transfers of huge amounts of Crystalized carry, which will overshadow the outstanding commitment.
                prop.label = 'Called Capital Amount';
                prop.show = true;
                return prop;
        }
        return prop; //false

    }

    get amountLabel(): string {
        if(this.transactionType.IsTransfer && 
            (this.investment.InvestmentType == 'Private Equity'
            || this.investment.InvestmentType == 'Venture'
            || this.investment.InvestmentType == 'PIP')) {
                return 'Capital Commitment Amt:';
            }
        if(this.transactionType.IsTransfer && 
            this.investment.InvestmentType == 'Hedge Fund w/ Commitment') {
                return 'Amount:';
            }
        if(this.transactionType.IsPurchase && 
            (this.investment.InvestmentType == 'Private Equity'
            || this.investment.InvestmentType == 'Venture'
            || this.investment.InvestmentType == 'PIP'
            || this.investment.InvestmentType == 'Hedge Fund w/ Commitment')) {
                return 'Purchase Price:';
            }
        else {
            return 'Amount:';
        }
    }

    //#endregion Computed

    //#region Watches
    
    //#endregion Watches

    //#region Methods
    async fetchTransaction() {
        if (this.transactionId == null){
            console.error('TransactionId is null');
            return;
        }
        const parameters = {} as TransactionsService.GetTransactionsListParameters;
        parameters.TransactionId = this.transactionId;
        const transactions = await this._transactionsService.GetTransactionsList(
            parameters
        );
        if (transactions.length) {
            this.transaction_ = transactions[0];
        }
    }
    
    investmentSelected(investment: AssetService.InvestmentList){
        this.ownerId = investment.OwnerId;
        this.clientId = investment.ClientId;
        this.investmentParentId = investment.InvestmentParentId;
        this.investment = investment;
    }

    async reloadFundingSourceList() {
        this.showFundingSourceList = false;
        await this.$nextTick();
        this.showFundingSourceList = true;
    }
    
    async fetchCreator() {
        if (this.transaction_.CreateUser) {
            const uo = new SmartObject('UserObject');
            await uo.loadDataFromCompositeKey({
                UserID: this.transaction_.CreateUser
            });
            this.creator = uo.dataObject || {};
        } else {
            this.creator = {};
        }
    }

    async saveTransaction() {
        const form = this.$refs.frmTransactionEdit;
        form.validate(async valid => {
            if (valid) {
                // eslint-disable-next-line no-constant-condition
                if (true || this.isNew ||  // TEMP HACK to force allowing no Funding Source.  remove "true ||" when fixed. VM-13
                    !(this.showFundingSource || this.showConsideration) ||
                    (this.fundingSources && this.fundingSources.length)
                ) {
                    let message: string;

                    if (this.isNew) {
                        const TransactionId = await new SmartObject(
                            'Transaction'
                        ).createObject(this.transaction_);
                        this.transaction_.Id = TransactionId;

                        await this.saveNotes();

                        const a = new SmartObject('Transaction', TransactionId);
                        await a.loadData();
                        this.transaction_.CreateUser = a.dataObject.CreateUser;
                        this.transaction_.CreateDate = a.dataObject.CreateDate;
                        await this.fetchCreator();

                        message = 'Transaction added.';
                    } else {
                        await new SmartObject(
                            'Transaction',
                            this.transaction_.Id
                        ).updateObject(this.transaction_);

                        await this.saveNotes();

                        message = 'Changes saved.';
                    }

                    if (this.fullAmountFundedByCash) {
                        let AccountId = null;
                        
                        // first check the investment to see if an override bank account has been set.
                        const investment = new SmartObject('Investment', this.transaction_.InvestmentId);
                        await investment.loadData();
                        AccountId = investment.dataObject.BankAccountId;

                        // then, check the owner for default bank accounts
                        if (!AccountId && this.ownerId > 0) {
                            const o = new SmartObject('Owner', this.ownerId);
                            await o.loadData();

                            if (o.dataObject.DefaultInvestmentAccountId > 0) {
                                AccountId = o.dataObject.DefaultInvestmentAccountId;
                            }
                             else if (o.dataObject.AccountId > 0) {
                                AccountId = o.dataObject.AccountId;
                             }
                        }
                        
                        await new SmartObject('FundingSource').createObject({
                            TransactionId: this.transaction_.Id,
                            FundingSourceTypeId: 1, // Cash
                            Amount: this.transaction_.Amount,
                            EntityType: AccountId > 0 ? 'Account' : null,
                            EntityId: AccountId
                        });
                        
                        this.reloadFundingSourceList();
                    }

                    this.$notify.success(message);

                    this.notes = null;

                    this.$emit('save', this.transaction_);
                    this.$emit('update:transaction', this.transaction_);
                } else {
                    this.$message.error(`Please enter at least one ${this.fundingSourceConsiderationLabel}.`);
                }
            } else {
                this.$message.error('Not Saved.  See Validation Errors.');
                return false;
            }
        });
    }

    async deleteTransaction() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this transaction. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                await this.doDeleteTransaction();
            } catch {}
        }
    }

    async doDeleteTransaction() {
        try {
            

            await new SmartObject(
                'Transaction',
                this.transaction_.Id
            ).deleteObject();

            this.$notify.success('Transaction deleted.');

            await new SmartObject('CommentLog').createObject({
                EntityType: 'Investment',
                EntityId: this.transaction_.InvestmentId,
                Comment: `Transaction ${this.transaction_.Id} Deleted.  (${this.transactionType.Display} ${this.$moment(this.transaction_.TransactionDate).format('MM/DD/YY')} ${this.$accounting.formatMoney(this.transaction_.Amount)})`,
                SystemGenerated: true
            });

            this.$notify.success('Investment Comment added.');

            this.$emit('delete', this.transaction_.Id);
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }

    async saveNotes() {
        if (this.notes) {
            await new SmartObject('CommentLog').createObject({
                EntityType: 'InvestmentTransaction',
                EntityId: this.transaction_.Id,
                Comment: this.notes
            });

            this.showCommentLogList = false;
            await this.$nextTick();
            this.showCommentLogList = true;
        }
    }

    transactionTypeSet(transactionType: TransactionsService.TransactionTypes){
        this.$refs.frmTransactionEdit.clearValidate();
        this.transactionType = transactionType || new TransactionsService.TransactionTypes();
        this.fullAmountFundedByCash = (!!this.isNew && !!this.showFundingSource);
        if (this.transactionType.IsInkindDistribution){
            this.transaction_.NewStructureType = 'Equity';
            this.transaction_.UseSpecificLot = false;
            this.transaction_.IncomeTypeId = 1014; // Distribution - Shares
            this.transaction_.NotTaxable = true;
        }
    }

    closeDialog() {
        this.$emit('close');
    }
    async showTransferPreview($ucTransactionEdit_IPO, TransactionId) {
        const $ucTransferPreview = $(this.$refs.ucTransferPreview);
        const closeTransferPreview = function(){
            $ucTransferPreview.smartPopup('destroy');
        }
        this.$setSystemStatus('Loading Transfer Preview');
        const wasNew = this.isNew;
        const vm = this;
        await $ucTransferPreview.fetchSmartControl({
            params: {
                TransactionId: this.transaction_.Id
            }
            , title: 'Transfer-IPO Preview'
            , loadAsync: true
            , width: 1800
            , position: { my: 'center top+50', at: 'center top', of: window }
            , maxWidth: '90%' 
            , buttons: {
                'Update Destination Transactions': {
                    buttonClass: 'btn-warning-stripe'
                    , onClick: async function () {
                        const parameters = {} as TransactionsService.TransferParameters;
                            parameters.SourceTransactionId = vm.transaction_.Id;
                        const transfer: TransactionsService.Transfer[] = await vm._transactionsService.Transfer(
                            parameters
                        );
                        let message: string = '';
                        let success: boolean = false;
                        if (!!transfer && transfer.length){
                            message = transfer[0].Message;
                            success = transfer[0].Success;
                        }
                        closeTransferPreview();
                        if (wasNew == true) {
                            vm.closeDialog();
                        }
                        if (success){
                            vm.$notify.success(message);
                        }
                        else {
                            vm.$alert(message, 'Problem');
                        }

                    }
                }
                , 'Cancel': function () {
                    smartConfirm({
                        text: 'Are you sure you want to Cancel without saving the Destination side of the Transfer?'
                        , buttons: {
                            'Yes': function () {
                                closeTransferPreview();
                                if (wasNew == true) {
                                    vm.closeDialog();                                    
                                }
                            }
                            , 'No': null
                        }
                    });
                }
            }

            , onClose: function () {
                // $('head title', window.parent.document).text('VAM Investment List');
            }
        });
        this.$setSystemStatus('');
    }
    async createAndMatchManualTransaction() {
      this.showCreateAndMatchManualTransaction = false;

      try {
            const parameters = {} as TransactionsService.CreateManualInvestmentBankTransactionParameters;
            parameters.TransactionId = this.transaction_.Id;
            const dsMatchedTransaction = await this._transactionsService.CreateManualInvestmentBankTransaction(
                parameters
            );

          if (dsMatchedTransaction && dsMatchedTransaction.length) {
              this.$notify.success(`Manual Transaction ${dsMatchedTransaction[0].Id} has been created and matched.`);

              this.transaction_.DisableMatches = true;
              this.transaction_.DisabledMatchReason = 'Manual Transaction already matched';
              this.transaction_.Deletable = false;
          }
      }
      catch (err) {
          this.$notify.error('An error occurred processing your request. Please try again.');
          console.log(err);
      }
    }
    editSpecificLot(){
        if (!this.transaction_.Id){
            this.$alert('New Transaction must be saved before setting Specific Lot.')
        }
        else {
            this.showEditSpecificLot = true;
        }
    }
    closeSpecificLotEdit(specificLotCost: any){
        this.showEditSpecificLot = false;
        this.transaction_.SpecificLotCost = specificLotCost;
    }
}
