
import {PropType} from "vue";
import { Vue, Component, Prop, PropSync, Watch, Ref } from 'vue-property-decorator';
import formatters from '../../utils/formatters';
import * as WorkflowService from '../../services/DAL/workflowService';
import debounce from 'lodash/debounce';

import ElementUI from 'element-ui';
import ApprovalItemList from '@/views/Approval/ApprovalItemList.vue';

@Component({
  components: {
    ApprovalItemList
  }
})
export default class ApprovalBatchView extends Vue {
  @Ref() readonly tblApprovalItemList!: ElementUI.Table;

  //#region Data
  private _workflowService: WorkflowService.WorkflowService;
  public formatters: formatters = new formatters();
  
  @Prop({ type: Array as PropType<string[]>, default: () =>['4','5'] }) workflowId!: string[]
  approvalBatches = [] as WorkflowService.ApprovalBatches[];
  isLoading = false;
  securityLevel_: number = null;
  mineOnly = true;
  outstandingOnly = true;
  workflowId_: string[] = this.workflowId;
//#endregion Data

  //#region Lifecycle
  async created() {
    this._workflowService = new WorkflowService.WorkflowService();
    this.formatters = new formatters();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), //placeholder.  probably need something more specific
      0
    );
    this.getApprovalBatches = debounce(this.getApprovalBatches, 500);
  }

  async mounted() {
    if (this.$route && this.$route.meta && this.$route.meta.approval){
      if (this.$route.meta.approval.workflowId) this.workflowId_ = this.$route.meta.approval.workflowId;
      if (this.$route.meta.approval.mineOnly != undefined) this.mineOnly = this.$route.meta.approval.mineOnly;
      if (this.$route.meta.approval.outstandingOnly != undefined) this.outstandingOnly = this.$route.meta.approval.outstandingOnly;
    }
    else { //watches will cause refresh above
    this.getApprovalBatches();
    }
  }
  //#endregion Lifecycle

  //#region Watches
  @Watch('workflowId_', {deep: true})
  onChange_workflowCheckBox(val: string[], oldVal: string[]){
    this.getApprovalBatches();
  }
  @Watch('mineOnly')
  onChange_mineOnly(val: boolean, oldVal: boolean){
    this.getApprovalBatches();
  }
  @Watch('outstandingOnly')
  onChange_outstandingOnly(val: boolean, oldVal: boolean){
    this.getApprovalBatches();
  }
  //#endregion Watches


  //#region Methods

  async getApprovalBatches() {
    this.isLoading = true;
    this.approvalBatches = [] as WorkflowService.ApprovalBatches[];
    const params = {} as WorkflowService.GetApprovalBatchesParameters;
    if (this.workflowId_ && this.workflowId_.length) {
      params.WorkflowIdList = this.workflowId_.join();
    }
    params.OnlyInstantUser = this.mineOnly;
    params.OutstandingOnly = this.outstandingOnly;
    this.approvalBatches = await this._workflowService.GetApprovalBatches(params);
    console.log('ran query');
    this.isLoading = false;
  }
  editApprovalBatchesItem(approvalBatch: WorkflowService.ApprovalBatches){
    this.$route.meta.approval = {};
    this.$route.meta.approval.workflowId = this.workflowId_;
    this.$route.meta.approval.mineOnly = this.mineOnly;
    this.$route.meta.approval.outstandingOnly = this.outstandingOnly;
    const approvalId = approvalBatch.ApprovalId.toString();
    this.$router.push({ name: 'ApprovalList', params: { approvalId } }).catch(() => {});
  }


//#endregion Methods
}
