
// 10-18-2021 15:25 CAMPBED: don't set reviewOutstandingOnly to true by default
// 10-20-2021 16:00 CAMPBED: due to above, also don't auto fetch

import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Ref } from 'vue-property-decorator';
import * as UtilService from './../../services/DAL/utilService';
import * as LiqService from './../../services/DAL/liq_Service';
import ClientSelector from '@/components/form/ClientSelector.vue';
import OwnerSelector from '@/components/form/OwnerSelector.vue';
import VendorSelector from '@/components/form/VendorSelector.vue';
import NamedKeySelector from '@/components/form/NamedKeySelector.vue';
import InvoiceEditView from '@/views/Invoice/InvoiceEditView.vue';
import * as WorkflowService from '../../services/DAL/workflowService';
import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';
import PDFViewer from '@/components/other/PDFViewer.vue';
import uniqBy from 'lodash/uniqBy';

import formatters from '../../utils/formatters';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare function getSecurityUserId(): string;
declare function getStoredSecurityLevel(Id: number): number;

@Component({
  components: {
    ClientSelector,
    OwnerSelector,
    VendorSelector,
    NamedKeySelector,
    InvoiceEditView,
    'pdf-viewer': PDFViewer
  }
})
export default class InvoiceList extends Vue {
  @Ref() readonly refInvoices!: ElementUI.Table;
  //#region Data
  private _utilService: UtilService.UtilService;
  private _liqService: LiqService.LiqService;
  private _workflowService: WorkflowService.WorkflowService;
  public formatters: formatters = new formatters();

  @Prop({ default: null })
  invoiceId: number;

  selectedClientId: number = null;
  selectedOwnerId: number = null;
  selectedStatusId: number = null;
  selectedVendorId: number = null;

  filterInvoices: string = '';
  invoices: LiqService.Invoices[] = [];
  selectedInvoice = {} as LiqService.Invoices;
  showDataPane: boolean = false;
  isLoading: boolean = false;
  matchingBankTransactionsCollection = new Map();
  fyisCollection = new Map();
  approversCollection = new Map();
  showSideBySide = false;
  sideBySideFileData = {} as MicrosoftGraphTypes.DriveItem;
  matchDataLoading = false;
  securityLevel_: number = getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP);
  securityLevelReview_: number = getStoredSecurityLevel(this.$namedKey.SecurityView.ManageInvoiceReview);
  reviewOutstandingOnly: boolean = false;
  selectedInvoices = [] as LiqService.Invoices[];
  emailsCreated = [] as LiqService.CreateInvoiceEmails[];
  showReviewEmailsCompleted = false;
  fyisLoading = false;
  approversLoading = false;
  vendorInvoiceNumber_: string = null;
  invoiceId_: number = null;
  autoSideBySide: boolean = this.securityLevel_ > this.$namedKey.SecurityLevel.BasicUser;
  tableHeight: number = null;
  //#endregion Data

  //#region Lifecycle
  async created() {
    window.addEventListener("resize", this.setTableHeight);
    this._utilService = new UtilService.UtilService();
    this._liqService = new LiqService.LiqService();
    this._workflowService = new WorkflowService.WorkflowService();
    this.formatters = new formatters();
    // if (this.securityLevelReview_ >= 80 && !this.invoiceId){
      // this.reviewOutstandingOnly = true; /* 10-18-2021 15:24 CAMPBED Per RC, don't limit any data initially. And therefore, don't fetch initially*/      
      // this.fetchInvoices();
    // }
    // else 
    if (this.invoiceId){
      this.invoiceId_ = this.invoiceId;
      this.fetchInvoices();
    }
  }

  destroyed(){
    window.removeEventListener("resize", this.setTableHeight);
  }
  async mounted() {
    this.setTableHeight();
  }
  //#endregion Lifecycle

  //#region Computed
  get showDetailColumns(): boolean {
    return (
      ['xs', 'sm', 'md'].indexOf(this.$getBootstrapSize()) > 0 ||
      !this.showDataPane
    );
  }
  get invoicesFiltered(): LiqService.Invoices[] {
		const filter_ = (this.filterInvoices || '').toLowerCase();
        return this.invoices.filter(entity => {
            return (
				(entity.InvoiceId || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.VendorName || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.VendorInvoiceNumber || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.Amount || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.StatusText || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.CompletedDateDisplay || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.Client || '').toString().toLowerCase().indexOf(filter_) > -1 ||
				(entity.OwnerName || '').toString().toLowerCase().indexOf(filter_) > -1
            );
        });
    }

  //#endregion Computed

  //#region Watches
  @Watch('selectedVendorId')
  onChange_VendorId(val, oldVal) {
    this.invoices = [] as LiqService.Invoices[]
    if (this.selectedVendorId){
      this.fetchInvoices();
    }
  }
  @Watch('selectedOwnerId')
  onChange_OwnerId(val, oldVal) {
    this.invoices = [] as LiqService.Invoices[]
    if (!!this.selectedVendorId || this.reviewOutstandingOnly){
      this.fetchInvoices();
    }
  }
  @Watch('selectedStatusId')
  onChange_StatusId(val, oldVal) {
    this.invoices = [] as LiqService.Invoices[]
    if (!!this.selectedVendorId || this.reviewOutstandingOnly){
      this.fetchInvoices();
    }
    else console.log('list not refreshed because no selection of Vendor or Owner');
  }
  @Watch('reviewOutstandingOnly')
  onChange_reviewOutstandingOnly(val, oldVal) {
    this.invoices = [] as LiqService.Invoices[]
    if (!!this.selectedOwnerId || !!this.selectedVendorId){
      this.fetchInvoices();
    }
  }

  //#endregion Watches

  //#region Methods
  setTableHeight(){
    // 9/22/21: This is THE solution for proper table sizing with floating header. Wrap it in a el-row with flex and height set to tableHeight, and set table's max-height to tableHeight.  see other references here to setTableHeight.
    this.tableHeight =  this.$el.clientHeight - 100 - 38; //100: Selectors; 38: Filter w/ padding (ClientHeight is what is inside the router.  934 in DEV)
    this.refInvoices.doLayout();
  }
  async editInvoicesItem(selectedRow: LiqService.Invoices, event, column) {
    if (selectedRow && selectedRow != null) {
      this.showDataPane = false;
      await this.$nextTick();
      this.showSideBySide = this.autoSideBySide;
      this.showDataPane = true;
      this.selectedInvoice = selectedRow;
    }
  }

  invoicesRowClassName({ row, rowIndex }): string {
    // console.log(row);
    // if (row.Success === null) {
    //     return 'warning-row';
    // } else if (
    //     (row.CreateUser || '').indexOf('Login_Visium') === -1 &&
    //     moment(row.CompleteDateET).isAfter(moment().subtract(1, 'minutes'))
    // ) {
    //     //not an auto row, and it happened in the last minute
    //     return 'success-row';
    // }
    return '';
  }

  async deletedInvoice() {
    this.closeInvoice();
    await this.fetchInvoices();
  }
  closeInvoice() {
    this.showDataPane = false;
    this.selectedInvoice = null;
    if (this.refInvoices){
      this.refInvoices.setCurrentRow(null);
    }
    this.showSideBySide = false;
    this.sideBySideFileData = {} as MicrosoftGraphTypes.DriveItem;
  }

  async fetchInvoices() {
    this.isLoading = true;
    this.closeInvoice();
    const params = {} as LiqService.GetInvoicesParameters;
    params.ClientId = !this.selectedClientId ? null : this.selectedClientId;
    params.OwnerId = !this.selectedOwnerId ? null : this.selectedOwnerId;
    params.StatusId = !this.selectedStatusId ? null : this.selectedStatusId;
    params.VendorId = !this.selectedVendorId ? null : this.selectedVendorId;
    params.ReviewOutstandingOnly = this.reviewOutstandingOnly;
    params.InvoiceId = !this.invoiceId_ ? null : this.invoiceId_;
    params.VendorInvoiceNumber = this.vendorInvoiceNumber_ == "" ? null : this.vendorInvoiceNumber_;
    this.invoices = await this._liqService.GetInvoices(params);

    this.isLoading = false;
    if (!!this.invoiceId && this.invoices.length == 1){
      this.editInvoicesItem(this.invoices[0], null, null);
    }
  }
async getMatchData(invoice: LiqService.Invoices){
        this.matchDataLoading = true;
        let data = this.matchingBankTransactionsCollection.get(invoice.InvoiceId);
        if (!data){
            const params = {} as LiqService.GetMatchedTransactionParameters;
            params.EntityId = invoice.InvoiceId;
            params.EntityType = 'Invoice';
            data = await this._liqService.GetMatchedTransaction(params);
            //this.matchingBankTransactionsCollection.set(transaction.Id, data); //The method below is required to make Map act reactive. https://stackoverflow.com/questions/37130105/does-vue-support-reactivity-on-map-and-set-data-types
            this.matchingBankTransactionsCollection = new Map(this.matchingBankTransactionsCollection.set(invoice.InvoiceId, data));
        }
        this.matchDataLoading = false;
    }
    async getApprovalApprovers(approvalId: number): Promise<WorkflowService.ApprovalApprovers[]>{
          const params = {} as WorkflowService.GetApprovalApproversParameters;
          params.ApprovalId = approvalId;
          return await this._workflowService.GetApprovalApprovers(params);
    }
    async getFYIs(invoice: LiqService.Invoices){
        this.fyisLoading = true;
        let data = this.fyisCollection.get(invoice.InvoiceId);
        if (!data){
            data = await this.getApprovalApprovers(invoice.FYIId);
            this.fyisCollection = new Map(this.fyisCollection.set(invoice.InvoiceId, data));
        }
        this.fyisLoading = false;
    }
    async getApprovers(invoice: LiqService.Invoices){
        this.approversLoading = true;
        let data = this.approversCollection.get(invoice.InvoiceId);
        if (!data){
            data = await this.getApprovalApprovers(invoice.ApprovalId);
            this.approversCollection = new Map(this.approversCollection.set(invoice.InvoiceId, data));
        }
        this.approversLoading = false;
    }
    getCachedMatchData(invoice: LiqService.Invoices){
        return this.matchingBankTransactionsCollection.get(invoice.InvoiceId);
    }
    getCachedFYIs(invoice: LiqService.Invoices){
        return this.fyisCollection.get(invoice.InvoiceId);
    }
    getCachedApprovers(invoice: LiqService.Invoices){
        return this.approversCollection.get(invoice.InvoiceId);
    }
    sideBySideChanged(payload: any){
      this.showSideBySide = payload.sideBySide;
      if (this.showSideBySide){
        this.sideBySideFileData = payload.fileData;
      }
      else {
        this.sideBySideFileData = {} as MicrosoftGraphTypes.DriveItem;
      }
    }
    selectableItem(invoice: LiqService.Invoices){
        return invoice.SelectableForReview;
    }
    handleSelectionChange(invoices: LiqService.Invoices[]) {
        this.selectedInvoices = invoices;
    }
    filterHandler(value, row: LiqService.Invoices, column) {
        const property = column['property'];
        return row[property] === value;
    }
    filters(column){
        const filters = this.invoicesFiltered.map(function(list) {
            return {
                text: list[column]
                , value: list[column]
                };
        });
        return uniqBy(filters, 'value');
    }
    async setReviewed(invoice: LiqService.Invoices, reviewLogId: number): Promise<void>{
      try {
        invoice.IsLoading = true;
        await new SmartObject(
                'Invoice',
                invoice.InvoiceId
            ).updateObject({
                ReviewLogId: reviewLogId
            });
        invoice.ReviewLogId = reviewLogId;
        invoice.Reviewed = this.$moment().format("MM/DD/YYYY h:mm:ss a");
      }
      catch (ex) {
        console.error (ex);
      }
      invoice.IsLoading = false;    
    }
    async approveAndSend(){
      if (this.securityLevelReview_ >= 80 && !!this.selectedInvoices && this.selectedInvoices.length > 0){
        let reviewLogId: number = null;
        let logEvents = [] as UtilService.LogEventReturnRow[];
        const parameters = {} as UtilService.LogEventReturnRowParameters;
        parameters.Database = 'visium';
        parameters.Schema = 'liq';
        parameters.Caller = 'ReviewInvoices';
        parameters.Event = 'exec';
        logEvents = await this._utilService.LogEventReturnRow(parameters);
        if (!!logEvents && logEvents.length == 1){
            reviewLogId = logEvents[0].LogId;
        }
        const proms = this.selectedInvoices.map(async (invoice) => {
              try {
                    await this.setReviewed(invoice, reviewLogId);
              } 
              catch (error) {
                  console.log('error'+ error);
              }
          });
          await Promise.all(proms);
          this.$notify.info(`Marked Invoices as Reviewed.  Log Id: ${reviewLogId}`);

          const processParams = {} as LiqService.CreateInvoiceEmailsParameters;
          processParams.LogId = reviewLogId;
          this.emailsCreated = await this._liqService.CreateInvoiceEmails(processParams);


          const completeParams = {} as UtilService.CompleteLogEventParameters;
          completeParams.LogId = reviewLogId;
          completeParams.Success = true;
          await this._utilService.CompleteLogEvent(completeParams);
          if (this.emailsCreated.length){
            this.showReviewEmailsCompleted = true;
          }
          else  {
            this.$notify.info(`Review completed.  No Emails to send.  Log Id: ${reviewLogId}`);
          }
      }
    }
    closeReviewEmailsCompleted(){
      this.emailsCreated = [] as LiqService.CreateInvoiceEmails[];
      this.showReviewEmailsCompleted = false;
    }
//#endregion Methods
}
