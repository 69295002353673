 <template>
    <div id="entity-list" v-loading="isLoading">
        <el-row>
            <a :href="downloadUrl" target="_blank">{{attachment.Description}}</a>
            <template v-if="attachment.Type">
                ({{attachment.Type}})
            </template>
            <el-button size="mini" type="primary" @click="handleEditDocument"><i class="fa fa-pencil"></i></el-button>
        </el-row>
        <el-row :gutter="20">
            <el-col>
                <span style="font-size:x-small">{{attachment.MappingNote}}</span>
            </el-col>
        </el-row>
        <el-row :gutter="20" v-if="(securityLevel_>=80)">
            <el-col :span="(selectedEntities[0]) ? 12 : 24">
                <el-button type="text" @click="levelUp" v-show="location!=''"><i class="fa fa-level-up"></i> {{location}}</el-button>
                <el-input v-model="filter" v-if="entitiesToMap[0]" placeholder="filter" size="mini" clearable suffix-icon="el-icon-search" />
                <el-table v-if="entitiesToMap[0]"
                        :data="filteredEntitiesToMap"
                        style="width: 100%;"
                        height="400px"
                        stripe
                        >
                    <el-table-column label="Open"
                                    min-width="75px">
                        <template slot-scope="scope">
                            <i :class="scope.row.Icon"></i>
                            <el-button size="mini" type="primary" @click="handleOpenEntity(scope.row)" v-if="scope.row.Drillable" title="Open Children" > <i class="fa fa-folder-open-o"></i></el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="Map"
                                     v-if="securityLevel_>=80"
                                     min-width="75px">
                        <template slot-scope="scope">
                            <i :class="scope.row.Icon"></i>
                            <el-button size="mini" type="primary" @click="handleMapEntity(scope.row)" v-if="scope.row.Selectable" :title="'Map to this ' + scope.row.EntityType"><i class="fa fa-chain"></i></el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="Select"
                                    min-width="400px">
                        <template slot-scope="scope">
                            <span :title="scope.row.EntityId" >{{scope.row.Description}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="12">
                &nbsp;
                <el-table v-if="selectedEntities[0]"
                        :data="selectedEntities"
                        style="width: 100%;"
                        stripe
                        @cell-click="unmapEntity">
                    <el-table-column label=""
                                    min-width="50px">
                        <template slot-scope="scope">
                            <i class="fa fa-minus-circle"></i>
                        </template>
                    </el-table-column>
                    <el-table-column label="Selected"
                                    min-width="400px">
                        <template slot-scope="scope">
                            <span :title="scope.row.EntityId" >{{scope.row.MappedDisplay}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-button type="success" @click="saveMaps" v-show="selectedEntities[0]"><i class="fa fa-floppy-o"></i> Save</el-button>
            </el-col>
        </el-row>
        <el-row>
            Existing Mappings:
            <attachment-map-list :attachment-id="attachment.Id" v-on:map-deleted="mapDeleted" v-on:number-of-maps-changed="numberOfMapsChanged" ref="AttachmentMapList" :security-level="securityLevel_"></attachment-map-list>
        </el-row>
    </div>

</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_attachment from './../DAL/mixinSchema_attachment'
    import AttachmentMapList from './AttachmentMapList.vue'

    export default Vue.extend({
        name: 'MapAttachment'
        , mixins: [mixinUtility, mixinSchema_attachment]
        , props: {
            attachmentId: {
                type: Number
                , required: true
            }
            , attachmentFileProperties: {
                type: Object
                , required: true
            }
            , showEditAttachment: {
                type: Boolean
                , default: false
                //this should be handled locally rather than by an emmitted event, and then should always be shown.  But current version of Element doesn't work for nested Dialogs.
                //therefore, so that we don't have to handle the event on every component that might call it, we'll make it hidden by default.
            }
            , securityLevel: {
                type: Number
                , default: null
            }
        }
        , components: {
            'attachment-map-list': AttachmentMapList
        }
    , data() {
            return {
                //, graphClient: []
                //, clients: []
                //, clientIdConfirmNonSpecific: false
                attachment: []
                , newFileIdInt: null
                , entitiesToMap: []
                , filteredEntitiesToMap: []
                , selectedEntityStack:[]
                , selectedEntities: []
                , filter: ''
                , numberOfMaps: 0
                , securityLevel_: this.securityLevel
                , isLoading: false
            }
        }
        , created: function () {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDocuments), 0);
            }
        }
        , mounted: function () {
            console.log('mounted');
            this.initialize();
        }
        , computed: {
            location: function(){
                var out = '';
                for (var i = 0; i < this.selectedEntityStack.length; i++) {
                    out += ((i > 0) ? ' > ' : '') + this.selectedEntityStack[i].description ;
                }
                return out;
            }
            , downloadUrl: function(){
                return this.attachmentFileProperties['@microsoft.graph.downloadUrl'];
            }
        }
        , watch: {
            'filter': function(val, oldVal){
                this.filterTable(val);
            }
        }
        , methods: {

            initialize: async function () {
                this.reset();
                await this.fetchAttachment();
                this.fetchEntitiesToMap();

            }
            , filterTable: function(val){
                this.filteredEntitiesToMap = this.entitiesToMap.filter(function(e) {
                    return e.Description.toLowerCase().indexOf(val.toLowerCase()) !== -1;
                });
            }
            , reset: function() {
                this.entitiesToMap = [];
                this.filteredEntitiesToMap = [];
                this.filter = '';
                this.selectedEntityStack = []
                this.selectedEntities = [];
            }
            , handleOpenEntity: function (selectedRow) {
                //this.selectedEntityStack[this.selectedEntityStack.length - 1].description = selectedRow.Description;
                this.selectedEntityStack.push({
                    entityId: selectedRow.EntityId
                    , entityType: selectedRow.EntityType
                    , description: selectedRow.Description
                })
                this.fetchEntitiesToMap();
            }
            , handleMapEntity: function (selectedRow) {
                this.selectedEntities.push(selectedRow);
                var index = this.entitiesToMap.indexOf(selectedRow);
                this.entitiesToMap.splice(index, 1);
            }
            , fetchEntitiesToMap: async function(){
                if (this.attachment && Object.keys(this.attachment).length > 0) {
                    this.isLoading = true;
                    console.log('FetchEntitiesToMap');
                    var selectedEntityType = (this.selectedEntityStack.length > 0) ? this.selectedEntityStack[this.selectedEntityStack.length - 1].entityType : null;
                    var selectedEntityId = (this.selectedEntityStack.length > 0) ? this.selectedEntityStack[this.selectedEntityStack.length - 1].entityId : null;
                    //when SelectedEntityId is null and SelectedEntityType is not null, then the default EntityType (based on TypeId) is ignored and the proc will use the "SelectedEntityType" as the EntityType.  this is for overriding the default entity type ("leveling up" from the defaul in the UI)
                    this.entitiesToMap = await this.attachment_GetEntitiesToMap({
                        TypeId: this.attachment.TypeId
                        , SelectedEntityType: selectedEntityType
                        , SelectedEntityId: selectedEntityId
                        , ClientId: this.attachment.ClientId
                        , AttachmentId: this.attachment.Id
                        }
                        , true // async (optional)
                    );
                    this.isLoading = false;
                }
                else {
                    this.entitiesToMap = [];
                }
                this.filteredEntitiesToMap = this.entitiesToMap;
                this.filter = ''; //reset filter since it is no longer applied.
            }
            , levelUp: function(){
                this.selectedEntityStack.splice(this.selectedEntityStack.length-1, 1);
                this.fetchEntitiesToMap();
            }
            ,unmapEntity: function(selectedRow, column, cell){
                if (column.label == ''){
                    this.$confirm('This will remove the selected mapping. Continue?', 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                        }).then(() => {
                            var index = this.selectedEntities.indexOf(selectedRow);
                            this.selectedEntities.splice(index, 1);
                            if (this.selectedEntityId == selectedRow.SelectedEntityId && this.selectedEntityType == selectedRow.SelectedEntityType){
                                //add it back to the left, if the left is the same source.
                                this.entitiesToMap.push(selectedRow);
                            }
                            this.$notify({
                                type: 'success',
                                message: 'Removed'
                            });
                        }).catch(() => {
                            this.$notify({
                                type: 'info',
                                message: 'Remove canceled'
                            });
                        });
                }
            }
            , saveMaps: function(){
                var so = new SmartObject('AttachmentMap');
                var maps = [];
                for (var i = 0; i < this.selectedEntities.length; i++) {
                    this.selectedEntities[i].AttachmentId = this.attachment.Id;
                    var attachmentMapId = so.createObject_lgcy(this.selectedEntities[i]);
                    if (attachmentMapId > 0){
                        this.selectedEntities[i].attachmentMapId = attachmentMapId;
                        maps.push(this.selectedEntities[i]);
                        this.numberOfMaps++; //increment here, rather than depending on an event from the component, because we aren't updating the component for an add.  If that changes, change this.
                        this.$emit('number-of-maps-changed', this.attachment.Id, this.numberOfMaps);
                    }
                }
                if (maps.length > 0){
                    this.$notify({
                        type: 'info',
                        message: 'Mapping records saved: ' + maps.length
                    });
                    this.$emit('attachment-mapped',maps, this.numberOfMaps);
                    this.reset();
                }
            }
            , mapDeleted: function(deletedRow){
                var deletedEntity = this.entitiesToMap.filter(function(e) {
                        return e.EntityType == deletedRow.EntityType && e.EntityId == deletedRow.EntityId;
                    });
                if (deletedEntity.length == 1){
                    var index = this.entitiesToMap.indexOf(deletedEntity[0]);
                    this.entitiesToMap[index].Icon = 'fa fa-check-circle';
                    this.entitiesToMap[index].Selectable = true;
                    this.entitiesToMap[index].AlreadyMapped = false;
                    this.entitiesToMap[index].Drillable = false;
                    this.filterTable(this.filter); //re-filter so that table has same values as underlying array
                }
            }
            , numberOfMapsChanged: function(numberOfMaps){
                this.numberOfMaps = numberOfMaps;
                this.$emit('number-of-maps-changed', this.attachment.Id, numberOfMaps);
            }
            , handleEditDocument: function () {
                this.$emit('edit-attachment', this.attachment.Id);
            }
            , fetchAttachment: async function () {
                this.attachment = await this.attachment_GetAttachment_Object({
                    AttachmentId: this.attachmentId
                });
                this.selectedEntityStack = [ //seed the entityStack with the default type
                    {
                        entityId: null
                        , entityType: this.attachment.EntityType
                        , description: this.attachment.EntityType
                    }
                ];
                if (this.$refs.AttachmentMapList) {
                    this.isLoading = true;
                    console.log('fetchMaps');
                    await this.$refs.AttachmentMapList.fetchMaps();
                    this.isLoading = false;
                }
            }
        }

    })
</script>
<style>
    .el-form-item__error {
        position: relative;
    }
</style>