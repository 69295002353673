
    import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import ConsiderationList from '@/views/InvestmentTransaction/Consideration/ConsiderationList.vue';
    import CapitalList from '@/views/InvestmentTransaction/Capital/CapitalList.vue';
    import cloneDeep from 'lodash/cloneDeep';
    import GeneralLedgerTView from '@/views/GeneralLedger/GeneralLedgerTView.vue';
    import AttachmentList from '@/js/Attachments/AttachmentList.vue';
    import ChangeLogList from '@/views/InvestmentTransaction/TransactionChangeLogList.vue';
    import TransactionHeader from '@/views/InvestmentTransaction/InvestmentTransactionNew/TransactionHeader.vue';
    import StakeTransaction from '@/views/InvestmentTransaction/Funds/StakeTransaction.vue';
    import InstrumentTransactionEdit from '@/views/DebtInstrument/InstrumentTransactionEdit.vue'
    import EquityTransaction from '@/views/InvestmentTransaction/Equity/EquityTransaction.vue'
    import Distribution from '@/views/InvestmentTransaction/Funds/Distribution.vue';
    import Dividend from '@/views/InvestmentTransaction/Equity/Dividend.vue';
    import Escrow from '@/views/InvestmentTransaction/Equity/Escrow.vue';
    import DonationLetterCreateDisplay from '@/views/InvestmentTransaction/DonationLetterCreateDisplay.vue';
    import PDFViewer from '@/components/other/PDFViewer.vue';
    import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';
    import MicrosoftGraphClient, {
    GraphRequest,
    ResponseType,
    Client
    } from '@microsoft/microsoft-graph-client';
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';
    import EquityList from '@/views/InvestmentTransaction/Equity/EquityList.vue';


    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;
    declare var SmartObject: any;

    @Component({
        components: {
            ConsiderationList,
            CapitalList,
            GeneralLedgerTView,
            AttachmentList,
            ChangeLogList,
            TransactionHeader,
            StakeTransaction,
            InstrumentTransactionEdit,
            EquityTransaction,
            Distribution,
            Dividend,
            Escrow,
            'pdf-viewer': PDFViewer
            , EquityList
            , DonationLetterCreateDisplay
        }
    })
    export default class TransactionTabView extends Vue {
    @Ref() readonly refGeneralLedger!: GeneralLedgerTView
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private _graphService: MicrosoftGraphService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop( { type: Object }) header: TransactionsService.Header;
    @Prop( { type: Number }) transactionHeaderId: number;
    //#endregion Props

    //#region Data
    loading = false;
    securityLevel_: number = null;

    header_ = {} as  TransactionsService.Header;
    showAttachmentList: boolean = false;
    showDonationLetter: boolean = false;
    showChangeLogList: boolean = false;
    showAccounting: boolean = false;
    selectedTab: string = '0';
    showSideBySide = false;
    sideBySideFileData = {} as MicrosoftGraphTypes.DriveItem;
    sideBySideFileName: string = null;
    sideBySidePath: string = null; // used to highlight open file in children

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this._graphService = new MicrosoftGraphService();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
        if (!this.header || Object.keys(this.header).length == 0){
            await this.getTransactionHeader();
        }
        else {
            this.header_ = cloneDeep(this.header)
        }
    }
    //#endregion Lifecycle

    //#region Watches
    //#endregion Watches

    //#region Computed

    //#endregion Computed


    //#region Methods

    async getTransactionHeader(){
        this.loading = true;
        this.header_ = {} as TransactionsService.Header;
        if (this.transactionHeaderId){
            const params = {} as TransactionsService.GetHeaderParameters;
            params.HeaderId = this.transactionHeaderId;
            this.header_ = (await this._transactionsService.GetHeader(params))[0];
        }
        this.loading = false;
    }


    async onTabClick(tab) {
        switch (tab.label) {
            case 'Accounting':
                this.showAccounting = false;    
                await this.$nextTick();
                this.showAccounting = true; 
                break;
            case 'Documents':
                this.showAttachmentList = false;
                await this.$nextTick();
                this.showAttachmentList = true;
                break;
            case 'Change Log':
                this.showChangeLogList = false;
                await this.$nextTick();
                this.showChangeLogList = true;
                break;
            case 'Donation Letter':
                this.showDonationLetter = false;
                await this.$nextTick();
                this.showDonationLetter = true;
                break;
        }
    }
    headerSaved(header: TransactionsService.Header) {
        this.header_ = header;
    }
    async pinDocument(payload){
        await this.fetchsideBySideFileData(payload.path);
        
        if (this.sideBySideFileData && !!this.sideBySideFileData['@microsoft.graph.downloadUrl']){
            if (this.showSideBySide){ // clear it first
                this.showSideBySide = false;
                await this.$nextTick();
            }
            if (this.sideBySideFileData.name.indexOf('.xlsx') > 0){
                window.open(this.sideBySideFileData.webUrl, '_blank');
                this.$notify.info('Excel Sheet opened in new tab.');
            }
            else {
                this.showSideBySide = true;
                this.sideBySideFileName = this.sideBySideFileData.name;
                this.sideBySidePath = payload.path;
            }
        }
        else {
            this.showSideBySide = false;
        }

    }
    async fetchsideBySideFileData(path: string){
        try {
            const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
            this.sideBySideFileData = await graphClient.api(path).get();
            return this.sideBySideFileData;
        }
        catch (err){
            console.error(err);
        }
    }
    async closeSideBySide(){
        this.showSideBySide = false;
        this.sideBySideFileData = {};
        this.sideBySideFileName = '';
        this.sideBySidePath = '';
    }
    //#endregion Methods
    async transactionDeleted(transactionHeaderId: number){
        this.header_ = {} as  TransactionsService.Header;
        this.showSideBySide = false;
        this.sideBySideFileData = {} as MicrosoftGraphTypes.DriveItem;
        this.sideBySideFileName = null;
        this.sideBySidePath = null; 
        this.$emit('delete', transactionHeaderId);
        this.$router.go(-1);
    }
}
