<template>
    <div id="model-list">
        <i class="el-icon-info" style="color:blue;" /> Tax Config for "Prior Year" should be deleted after taxes for that year are finalized. e.g. 2019 config deleted after October 2020.
        <el-table v-if="modelConfig[0]"
                  :data="modelConfig"
                  style="width: 100%; max-width:1200px"
                  stripe
                  size="mini"
                  v-loading.body="loading"
                  >
            <el-table-column label="Owner"
                            :filters="filters('Owner')"
                            :filter-method="filterHandler"
                            sortable
                            prop="Owner"
                            >
            </el-table-column>
            <el-table-column label="Category"
                            :filters="filters('ReportingCategory')"
                            :filter-method="filterHandler"
                            sortable
                             prop="ReportingCategory"
                             min-width="60"
                             >
            </el-table-column>
            <el-table-column label="Description"
                            sortable
                             prop="Description"
                             min-width="200"
                             >
            </el-table-column>
            <el-table-column label="Year"
                            :filters="filters('TaxYear')"
                            :filter-method="filterHandler"
                            sortable
                             prop="TaxYear"
                             >
                <template slot-scope="props">
                    {{props.row.TaxYear}} ({{props.row.Year}})
                </template>
            </el-table-column>
            <el-table-column label="Jurisdiction"
                            :filters="filters('Jurisdiction')"
                            :filter-method="filterHandler"
                            sortable
                             prop="Jurisdiction"
                             >
            </el-table-column>
            <el-table-column label="Current"
                             align="right"
                             prop="Value"
                             >
                <template slot-scope="props">
                    <currency-label v-if="props.row.ValueIsCurrency" :value="props.row.Value"></currency-label>
                    <template v-else>{{props.row.Value}}</template>
                </template>
            </el-table-column>
            <el-table-column label="New" align="right" >
                <template slot-scope="props">
                    <div v-bind:style="{ backgroundColor: highlightDifference(props.row.Value, props.row.NewValue) }">
                        <currency-label v-if="props.row.ValueIsCurrency" :value="props.row.NewValue"></currency-label>
                        <template v-else>{{props.row.NewValue}}</template>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Created"
                             align="right"
                             prop="Created"
                             min-width="110px">
            </el-table-column>
        </el-table>
        <el-button v-if="showUpdateButton" type="success" @click="UpdateTaxModelImport">Update</el-button>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinTax from './../DAL/mixinSchema_tax'
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';
    import mixinUtility from './../VueCommon/Utility.js'
    import CurrencyLabel from './../VueCommon/CurrencyLabel.vue'
    import mixinSchema_util from './../DAL/mixinSchema_util'
    import uniqBy from 'lodash/uniqBy';

    export default Vue.extend({
        name: 'TaxModelImport'
        , mixins: [mixinSchema_util, mixinUtility, mixinTax]
        , components: {
            'currency-label': CurrencyLabel
        }
        , data() {
            return {
                  token: ''
                , showFileName: false
                , model: []
                , modelConfig: this.tax_GetModelImport()
                , showUpdateButton: true
                , loading: false
                , graphService: {}
            }
        }
        , created: function () {
            this.graphService = new MicrosoftGraphService();
            this.fetchModel();
        }
        , methods: {
            fetchModel: async function () {
                this.loading = true;
                var t = this;
                this.modelConfig.forEach(async function (modelConfig) {
                    var pathToModel = modelConfig.Path;
                    var model = await t.getModel(pathToModel)
                    modelConfig.NewValue = model.values[0][0];
                });
                this.loading = false;
            }
            , getModel: async function (pathToModel) {
                const graphClient = await this.graphService.getGraphClient();
                var fp =  graphClient
                    .api(pathToModel)
                    .get()
                return fp;
            }
            , UpdateTaxModelImport: async function () {
                this.loading = true;
                this.showUpdateButton = false;
                const logEvent = await this.util_LogEventSelect_Object({
                        Database: 'VisiumDB'
                        , Schema: 'tax'
                        , Caller: 'ModelImport'
                        , Event: 'Import'
					}
					, true // async (optional)
                );
                const logId = logEvent.LogId;
                let summaryUpdates = 0;
                const el = this;
                await this.$asyncForEach(this.modelConfig, 
                    async function (modelConfig) {
                        var taxImport = {
                            LogId: logId
                            , ImportConfigurationId: modelConfig.ImportConfigurationId
                            , ImportCellId: modelConfig.ImportCellId
                            , Value: modelConfig.NewValue
                        };
                        var so = new SmartObject('TaxImport');
                        var newId = await so.createObject(taxImport);
                        summaryUpdates++;
                    }
                );
                this.modelConfig = this.tax_GetModelImport(); //refresh.  "New" will be empty
                if (summaryUpdates){
                    this.$notify.success(`Summary Updated (${summaryUpdates} records)`);
                }
                this.loading = false;
            }
            , highlightDifference: function (a, b) {
                return (accounting.toFixed(a, 2) != accounting.toFixed(b, 2)) ? 'Yellow' : '';
            }
            , filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            }
            , filters(column){
                const filters = this.modelConfig.map(function(list) {
                    return {
                        text: list[column]
                        , value: list[column]
                        };
                });
                return uniqBy(filters, 'value');
            }

        }
    })
</script>
