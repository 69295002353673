// TS API for Schema transactions
// Auto-generated 09/26/24 06:11


declare var smartData: any;
// output classes:
export class CreateManualInvestmentBankTransaction {
    Id: number = undefined;
}
export class AssociatedTransactions {
    Id: number=undefined;
    InvestmentId: number=undefined;
    Amount: number=undefined;
    EntityId: number = undefined;
}
export class AvailableSpecificLotTransactions {
    FromTransactionId: number=undefined;
    FromTransactionDate: string=undefined;
    CarryShares: boolean=undefined;
    OriginalShares: number=undefined;
    PurchaseAmount: number=undefined;
    CostPerShare: number=undefined;
    ConsumedShares: number=undefined;
    ShareBalance: number=undefined;
    InstantPrice: number=undefined;
    STLT: string=undefined;
    DaysHeld: number=undefined;
    Selectable: boolean = undefined;
}
export class Capital {
    Id: number=undefined;
    CapitalId: number=undefined;
    TransactionHeaderId: number=undefined;
    InvestmentId: number=undefined;
    CapitalTypeId: number=undefined;
    CapitalType: string=undefined;
    CapitalTypeDisplay: string=undefined;
    Amount: number=undefined;
    ConsiderationValue: number=undefined;
    OwnerId: number=undefined;
    Investment: string=undefined;
    ConsiderationTypeId: number=undefined;
    ConsiderationType: string=undefined;
    ConsiderationDirection: string=undefined;
    Direction: string=undefined;
    Comment: string=undefined;
    ConsiderationDescription: string=undefined;
    Modified: string=undefined;
    HeaderDescription: string=undefined;
    DateDisplay: string=undefined;
    TransactionType: string=undefined;
    EntryType: string=undefined;
    SubType: string=undefined;
    SubTypeId: number=undefined;
    SourceId: number=undefined;
    Price: number=undefined;
    FMV: number=undefined;
    FMVMethod: string=undefined;
    CapitalCallScheduleId: number=undefined;
    Factor: number = undefined;
    Date: string=undefined;
    ToOwnerEntityId: number=undefined;
    FromOwnerEntityId: number=undefined;
}
export class CapitalSubTypes {
    CapitalTypeId: number=undefined;
    CapitalSubTypeId: number=undefined;
    CapitalSubType: string=undefined;
    AccountKey: string=undefined;
    OnlyIfHasCommitment: boolean = undefined;
}
export class CapitalTypes {
    Id: number=undefined;
    Name: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    UseSubType: boolean = undefined;
}
export class Cash {
    Id: number=undefined;
    CashId: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    BankAccountId: number=undefined;
    OwnerId: number=undefined;
    ClientId: number=undefined;
    Amount: number=undefined;
    ConsiderationValue: number=undefined;
    BankAccount: string=undefined;
    ConsiderationTypeId: number=undefined;
    ConsiderationType: string=undefined;
    ConsiderationDirection: string=undefined;
    Direction: string=undefined;
    Comment: string=undefined;
    ConsiderationDescription: string=undefined;
    Modified: string=undefined;
    BankTransactionId: number=undefined;
    MatchSource: string=undefined;
    TransactionHeaderDescription: string=undefined;
    SourceCashId: number=undefined;
    TargetCashId: number=undefined;
    IsTransfer: boolean=undefined;
    MatchedByOn: string=undefined;
    MatchedTransactionId: number=undefined;
    PostedDate: string=undefined;
    BankTransactionDescription: string=undefined;
    OwnerName: string=undefined;
    OwnerShortestName: string = undefined;
    TransactionHeaderId: number=undefined;
    Date: string=undefined;
    ToOwnerEntityId: number=undefined;
    FromOwnerEntityId: number=undefined;
}
export class ChangeLog {
    Id: number=undefined;
    ChangeDate: string=undefined;
    UserId: string=undefined;
    Schema: string=undefined;
    Table: string=undefined;
    EntityId: number=undefined;
    Field: string=undefined;
    BeforeValue: string=undefined;
    AfterValue: string=undefined;
    FirstLast: string=undefined;
    Description: string = undefined;
}
export class ConsiderationTypes {
    Id: number=undefined;
    Direction: string=undefined;
    Name: string=undefined;
    EntityType: string=undefined;
    Description: string=undefined;
    EntitySubTypeId: string = undefined;
}
export class CreditNote {
    Id: number=undefined;
    CreditNoteId: number=undefined;
    TransactionHeaderId: number=undefined;
    NoteId: number=undefined;
    PromissoryNoteId: number=undefined;
    NoteTransactionId: number=undefined;
    PromissoryNoteTransactionId: number=undefined;
    TransactionOwnerEntityId: number=undefined;
    TransactionOwnerEntityName: string=undefined;
    TransactionOwnerEntityNameShort: string=undefined;
    Amount: number=undefined;
    ConsiderationValue: number=undefined;
    ConsiderationTypeId: number=undefined;
    ConsiderationType: string=undefined;
    ConsiderationDirection: string=undefined;
    Direction: string=undefined;
    ConsiderationDescription: string=undefined;
    Comment: string=undefined;
    Modified: string=undefined;
    TransactionHeaderDescription: string = undefined;
    Date: string=undefined;
    ToOwnerEntityId: number=undefined;
    FromOwnerEntityId: number=undefined;
}
export class Debt {
    Id: number=undefined;
    DebtId: number=undefined;
    TransactionHeaderId: number=undefined;
    HeaderDescription: string=undefined;
    InvestmentId: number=undefined;
    Date: string=undefined;
    DateString: string=undefined;
    AcquisitionDate: string=undefined;
    AcquisitionDateString: string=undefined;
    AcquisitionCost: number=undefined;
    Amount: number=undefined;
    ConsiderationValue: number=undefined;
    OwnerId: number=undefined;
    Investment: string=undefined;
    ConsiderationTypeId: number=undefined;
    ConsiderationType: string=undefined;
    ConsiderationDirection: string=undefined;
    Direction: string=undefined;
    Comment: string=undefined;
    ConsiderationDescription: string=undefined;
    LastModified: string=undefined;
    Modified: string=undefined;
    Created: string=undefined;
    SourceId: number=undefined;
    AcquisitionSourceId: number=undefined;
    SourceTransactionHeaderId: number=undefined;
    TransactionTypeId: number=undefined;
    TransactionType: string=undefined;
    Deletable: boolean=undefined;
    DownstreamRecords: number=undefined;
    BasisReconciledToK1: boolean=undefined;
    Pending: boolean = undefined;
}
export class DistributionInvestments {
    OwnerId: number=undefined;
    InvestmentParentId: number=undefined;
    TransactionHeaderId: number=undefined;
    DistributionInvestmentsId: number=undefined;
    InvestmentId: number=undefined;
    Name: string=undefined;
    AccountType: string=undefined;
    InvestmentType: string=undefined;
    CapitalCommitment: number=undefined;
    Allocation: number=undefined;
    SavedCommitmentDifferent: number = undefined;
}
export class DistributionItems {
    Id: number=undefined;
    TransactionHeaderId: number=undefined;
    DistributionTypeId: number=undefined;
    DistributionType: string=undefined;
    Amount: number=undefined;
    PreviousAmount: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    LPRatio: number=undefined;
    GPRatio: number=undefined;
    LPInvestmentId: number=undefined;
    GPInvestmentId: number=undefined;
    ACInvestmentId: number=undefined;
    LPAmount: number=undefined;
    GPAmount: number=undefined;
    ACAmount: number=undefined;
    AllocationType: string=undefined;
    Comments: number=undefined;
    IsCopy: boolean=undefined;
    LPLabel: string=undefined;
    AmountPerQuantity: number=undefined;
    Quantity: number = undefined;
}
export class Equity {
    Id: number=undefined;
    EquityId: number=undefined;
    TransactionHeaderId: number=undefined;
    HeaderDescription: string=undefined;
    InvestmentId: number=undefined;
    Date: string=undefined;
    DateString: string=undefined;
    HoldingEntityId: number=undefined;
    HoldingEntity: string=undefined;
    Tranche: string=undefined;
    Quantity: number=undefined;
    QuantityWithSign: number=undefined;
    AcquisitionDateDisplay: string=undefined;
    AcquisitionDateDisplayFromSourceId: boolean=undefined;
    AcquisitionDate: string=undefined;
    AcquisitionDateString: string=undefined;
    AcquisitionCost: number=undefined;
    AcquisitionCostPerQuantity: number=undefined;
    Amount: number=undefined;
    ConsiderationValue: number=undefined;
    AmountPerQuantity: number=undefined;
    OwnerId: number=undefined;
    Investment: string=undefined;
    ConsiderationTypeId: number=undefined;
    ConsiderationType: string=undefined;
    ConsiderationDirection: string=undefined;
    Direction: string=undefined;
    Comment: string=undefined;
    ConsiderationDescription: string=undefined;
    LastModified: string=undefined;
    Modified: string=undefined;
    Created: string=undefined;
    SourceId: number=undefined;
    AcquisitionSourceId: number=undefined;
    SourceTransactionHeaderId: number=undefined;
    ReturnIncomeTypeId: number=undefined;
    ReturnIncomeType: string=undefined;
    SaleCostBasis: number=undefined;
    SaleCostBasisPerQuantity: number=undefined;
    GainLoss: number=undefined;
    TransactionTypeId: number=undefined;
    TransactionType: string=undefined;
    IsCarryShares: boolean=undefined;
    FMV: number=undefined;
    FMVPerQuantity: number=undefined;
    FMVAsOf: string=undefined;
    Deletable: boolean=undefined;
    DownstreamRecords: number=undefined;
    BasisReconciledToK1: boolean=undefined;
    Pending: boolean=undefined;
    SplitFrom: number=undefined;
    SplitTo: number=undefined;
    SplitDisplay: string=undefined;
    ExchangeToHoldingEntityId: number=undefined;
    ExchangeToTranche: string=undefined;
    IsQSBS: boolean=undefined;
    DisplayCost: number=undefined;
    DisplayCostPerQuantity: number=undefined;
    AmountMethod: string=undefined;
    PerQuantityHigh: number=undefined;
    PerQuantityLow: number=undefined;
    RunningTotal: number = undefined;
    ToOwnerEntityId: number=undefined;
    FromOwnerEntityId: number=undefined;
}
export class EquityTransactionTranches {
    InvestmentId: number=undefined;
    DescendantEquityId: number=undefined;
    DescendantTransactionHeaderId: number=undefined;
    Date: string=undefined;
    HoldingEntityId: number=undefined;
    HoldingEntity: string=undefined;
    Tranche: string=undefined;
    OwnerId: number=undefined;
    IsCarryShares: boolean=undefined;
    OriginatedQuantity: number=undefined;
    CostPerQuantity: number=undefined;
    Consumed: number=undefined;
    AvailableQuantity: number=undefined;
    CurrentConsumptionEquityId: number=undefined;
    CurrentConsumptionQuantity: number=undefined;
    CurrentConsumptionQuantityDisplay: number=undefined;
    RemainingAvailableQuantity: number=undefined;
    AllRemainingAvailableQuantity: number=undefined;
    CounterEquityId: number=undefined;
    CurrentConsumptionCost: number=undefined;
    CurrentConsumptionCostDisplay: number=undefined;
    CurrentConsumptionValue: number=undefined;
    CurrentConsumptionValueDisplay: number=undefined;
    FMVShares: number=undefined;
    FMVShareCountAsOf: string=undefined;
    FMV: number=undefined;
    FMVAsOf: string=undefined;
    FMVPerQuantity: number=undefined;
    FMVPerQuantityDisplay: number=undefined;
    Deletable: boolean=undefined;
    DownstreamRecords: number=undefined;
    CurrentConsumptionFMV: number=undefined;
    CurrentConsumptionFMVDisplay: number=undefined;
    ConsumptionGainLoss: number=undefined;
    ConsumptionGainLossDisplay: number=undefined;
    SplitFrom: number=undefined;
    SplitTo: number=undefined;
    SplitDisplay: string=undefined;
    ExchangeToHoldingEntity: string=undefined;
    ExchangeToHoldingEntityId: number=undefined;
    ResultingQuantity: number=undefined;
    ExchangeToTranche: string=undefined;
    AcquisitionSourceId: number=undefined;
    AmountMethod: string=undefined;
    PerQuantityHigh: number=undefined;
    PerQuantityLow: number=undefined;
    HasOutOfDateData: boolean=undefined;
    OutOfDateIssue: string=undefined;
    ConsumptionFMVPerQuantity: number=undefined;
    ConsumptionCostBasisPerQuantity: number=undefined;
    STLT: string=undefined;
    AllConsumed: number=undefined;
    AllAvailableQuantity: number = undefined;
}
export class Expense {
    Id: number=undefined;
    ExpenseId: number=undefined;
    TransactionHeaderId: number=undefined;
    HeaderDescription: string=undefined;
    ExpenseTypeId: number=undefined;
    ExpenseType: string=undefined;
    InvestmentId: number=undefined;
    Date: string=undefined;
    DateString: string=undefined;
    Amount: number=undefined;
    ConsiderationValue: number=undefined;
    OwnerId: number=undefined;
    Investment: string=undefined;
    ConsiderationTypeId: number=undefined;
    ConsiderationType: string=undefined;
    ConsiderationDirection: string=undefined;
    Direction: string=undefined;
    ConsiderationDescription: string=undefined;
    LastModified: string=undefined;
    Modified: string=undefined;
    Created: string=undefined;
    TransactionTypeId: number=undefined;
    TransactionType: string=undefined;
    Deletable: boolean=undefined;
    DownstreamRecords: number=undefined;
    BasisReconciledToK1: boolean=undefined;
    Pending: boolean = undefined;
    ToOwnerEntityId: number=undefined;
    FromOwnerEntityId: number=undefined;
}
export class ExternalCashMovementConsideration {
    Id: number=undefined;
    ConsiderationType: string=undefined;
    ConsiderationDirection: string=undefined;
    ConsiderationDescription: string=undefined;
    Description: string=undefined;
    Amount: number=undefined;
    ConsiderationValue: number=undefined;
    ConsiderationTypeId: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    TransactionHeaderId: number=undefined;
    TransactionHeaderDescription: string=undefined;
    OwnerEntityId: number=undefined;
    OwnerEntityName: string=undefined;
    OwnerEntityNameShort: string=undefined;
    Modified: string = undefined;
    Date: string=undefined;
    ToOwnerEntityId: number=undefined;
    FromOwnerEntityId: number=undefined;
}
export class FixedIncomeBankTransactions {
    Id: number=undefined;
    FixedIncomeId: number=undefined;
    Type: string=undefined;
    BankTransactionId: number=undefined;
    LogId: number=undefined;
    Source: string=undefined;
    SourceShort: string=undefined;
    Amount: number=undefined;
    Units: number=undefined;
    BankUnits: number=undefined;
    Factor: number=undefined;
    ValueFactor: number=undefined;
    BankAmount: number=undefined;
    PostingDate: string=undefined;
    TransactionShortDescription: string = undefined;
}
export class FundingSource {
    Id: number=undefined;
    TransactionId: number=undefined;
    FundingSourceTypeId: number=undefined;
    FundingSourceType: string=undefined;
    Amount: number=undefined;
    EntityType: string=undefined;
    EntityId: number=undefined;
    Comment: string=undefined;
    RequiresAmount: boolean=undefined;
    RequiresComment: boolean=undefined;
    Description: string=undefined;
    SourceInvestmentId: number = undefined;
}
export class Header {
    Id: number=undefined;
    ClientId: number=undefined;
    TypeId: number=undefined;
    Date: string=undefined;
    ProFormaDate: string=undefined;
    NetAmount: number=undefined;
    Description: string=undefined;
    ImportLogId: number=undefined;
    Created: string=undefined;
    Modified: string=undefined;
    SubTypeId: number=undefined;
    TransactionType: string=undefined;
    SubType: string=undefined;
    SubTypeShort: string=undefined;
    SubTypeBaseDirection: string=undefined;
    IsStakeTransaction: boolean=undefined;
    IsShareTransaction: boolean=undefined;
    IsDistribution: boolean=undefined;
    IsCapitalCommitment: boolean=undefined;
    BaseOwnerId: number=undefined;
    BaseOwner: string=undefined;
    TargetOwnerId: number=undefined;
    TargetOwner: string=undefined;
    TargetCharitableEIN: string=undefined;
    BaseInvestmentId: number=undefined;
    BaseInvestmentParentId: number=undefined;
    Notes: string=undefined;
    Pending: boolean=undefined;
    SubTypeScope: string=undefined;
    SubOperation: string=undefined;
    SubTypeNoConsideration: boolean = undefined;
}
export class InterPromissoryNoteConsideration {
    PromissoryNoteTransactionId: number=undefined;
    PromissoryNoteId: number=undefined;
    InterNoteSource: string=undefined;
    InterNotePaymentPromissoryNoteId: number=undefined;
    InterNotePaymentPromissoryNote: string=undefined;
    DebtorLender: string=undefined;
    TransactionType: string=undefined;
    OwnerEntityId: number=undefined;
    OwnerEntityName: string=undefined;
    OwnerEntityNameShort: string=undefined;
    ConsiderationValue: number = undefined;
    Modified: string=undefined;
}
export class InvestmentsForHeader {
    InvestmentId: number=undefined;
    Name: string=undefined;
    Owner: string=undefined;
    AccountType: string=undefined;
    BankAccountNameNumberMasked: string = undefined;
}
export class LegacyAssociated {
    Id: number=undefined;
    TransactionDate: string=undefined;
    TransactionTypeDisplay: string=undefined;
    Amount: number=undefined;
    Investment: string=undefined;
    DocumentsJSON: string = undefined;
}
export class MarketableSecuritiesForSale {
    ClientId: number=undefined;
    Name: string=undefined;
    Ticker: string=undefined;
    ParentId: number=undefined;
    MarketableSecuritySaleSecurityId: number=undefined;
    Investments: number=undefined;
    ConvertedInvestments: number=undefined;
    DisableInTool: boolean=undefined;
    Owners: number=undefined;
    Shares: number=undefined;
    FMV: number=undefined;
    FMVPerShare: number=undefined;
    CostBasis: number=undefined;
    CostPerShare: number=undefined;
    UnrealizedGain: number = undefined;
}
export class MarketableSecurityInvestments {
    Id: number=undefined;
    MarketableSecuritySaleSecuritiesId: number=undefined;
    ActualSharesToSell: number=undefined;
    InvestmentId: number=undefined;
    OwnerId: number=undefined;
    Owner: string=undefined;
    Name: string=undefined;
    Source: string=undefined;
    BankAccountNameNumberMasked: string=undefined;
    Ticker: string=undefined;
    SharesOutstanding: number=undefined;
    TotalSharesAvailable: number=undefined;
    CostBasis: number=undefined;
    CostPerShare: number=undefined;
    MarketPrice: number=undefined;
    MarketTime: string=undefined;
    BufferedPrice: number=undefined;
    TotalSharesToSell: number=undefined;
    ProRataFactor: number=undefined;
    ProRataSharesToSell: number = undefined;
}
export class MarketableSecuritySale {
    Id: number=undefined;
    ClientId: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    Created: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    SubmitUser: string=undefined;
    SubmitDate: string=undefined;
    ApproveUser: string=undefined;
    Approver: string=undefined;
    ApproveDate: string=undefined;
    ApprovedOnBy: string=undefined;
    EmailsJSON: string=undefined;
    Status: string = undefined;
}
export class MarketableSecuritySaleSecurities {
    Id: number=undefined;
    MarketableSecuritySaleId: number=undefined;
    ParentId: number=undefined;
    Ticker: string=undefined;
    Name: string=undefined;
    TargetProceeds: number=undefined;
    MarketPrice: number=undefined;
    MarketTime: string=undefined;
    PriceAdjustment: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string = undefined;
}
export class MarketableSecuritySaleSecurity {
    MarketableSecuritySaleSecurityId: number=undefined;
    MarketableSecuritySaleId: number=undefined;
    ParentId: number=undefined;
    Ticker: string=undefined;
    Name: string=undefined;
    TargetProceeds: number=undefined;
    MarketPrice: number=undefined;
    MarketTime: string=undefined;
    PriceAdjustment: number=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    Deletable: boolean=undefined;
    LastPrice: number=undefined;
    LastPriceDate: string=undefined;
    LastPriceDateTime: string=undefined;
    BufferedPrice: number=undefined;
    TotalSharesToSell: number = undefined;
}
export class NewLegacyStatisticsAnalysis {
    Date: string=undefined;
    BasisLegacy: number=undefined;
    InvestedLegacy: number=undefined;
    ReturnedLegacy: number=undefined;
    CalledLegacy: number=undefined;
    SharesLegacy: number=undefined;
    FMV: number=undefined;
    Basis: number=undefined;
    Invested: number=undefined;
    Returned: number=undefined;
    Called: number=undefined;
    Shares: number=undefined;
    DiffBasis: number=undefined;
    DiffInvested: number=undefined;
    DiffReturned: number=undefined;
    DiffCalled: number=undefined;
    DiffShares: number=undefined;
    DiffBasisPct: number=undefined;
    DiffInvestedPct: number=undefined;
    DiffReturnedPct: number=undefined;
    DiffCalledPct: number=undefined;
    DiffSharesPct: number=undefined;
    DateKey: number=undefined;
    Comments: number = undefined;
}
export class SpecificLotTransactions {
    InvestmentId: number=undefined;
    OwnerId: number=undefined;
    SpecificLotId: number=undefined;
    FromTransactionId: number=undefined;
    FromTransactionType: string=undefined;
    FromTransactionDate: string=undefined;
    CarryShares: boolean=undefined;
    OriginalShares: number=undefined;
    PurchaseAmount: number=undefined;
    CostPerShare: number=undefined;
    ConsumedShares: number=undefined;
    SpecificLotShares: number=undefined;
    ConsumerTransactionId: number=undefined;
    ConsumerTransactionType: string=undefined;
    ConsumedPricePerShare: number=undefined;
    Cost: number=undefined;
    SaleProceeds: number=undefined;
    GainLoss: number=undefined;
    DaysHeld: number=undefined;
    ThresholdDaysHeld: number=undefined;
    STLT: string = undefined;
}
export class SubType {
    Id: number=undefined;
    Name: string=undefined;
    TransactionTypeId: number=undefined;
    Scope: string=undefined;
    Operation: string=undefined;
    SubOperation: string=undefined;
    BaseDirection: string=undefined;
    CreateUser: string=undefined;
    CreateDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyDate: string=undefined;
    DisplayOrder: number=undefined;
    NoConsideration: boolean = undefined;
}
export class TrancheHistory {
    Direction: string=undefined;
    EquityId: number=undefined;
    TransactionHeaderId: number=undefined;
    HeaderDescription: string=undefined;
    TransactionType: string=undefined;
    Quantity: number=undefined;
    Date: string=undefined;
    Created: string=undefined;
    SourceId: number=undefined;
    InvestmentId: number=undefined;
    Investment: string=undefined;
    CumulativeTotal: number = undefined;
}
export class TrancheTypes {
    Records: number=undefined;
    LotType: string = undefined;
}
export class TransactionCalculation {
    Id: number=undefined;
    RunningCostBasis: number=undefined;
    NetShares: number = undefined;
}
export class TransactionHeaderForInvestment {
    Id: number=undefined;
    ClientId: number=undefined;
    TypeId: number=undefined;
    Date: string=undefined;
    ProFormaDate: string=undefined;
    NetAmount: number=undefined;
    TransactionAmount: number=undefined;
    TransactionAmountDisplay: string=undefined;
    Description: string=undefined;
    ImportLogId: number=undefined;
    LastModified: string=undefined;
    TransactionType: string=undefined;
    Client: string=undefined;
    Notes: string=undefined;
    AmountEquity: number=undefined;
    AmountExpense: number=undefined;
    AmountCapital: number=undefined;
    AmountDistribution: number=undefined;
    InvestmentAmount: number=undefined;
    AbsInvestmentAmount: number=undefined;
    TransactionTypeOrder: number=undefined;
    Pending: boolean=undefined;
    ConsiderationIsBalanced: boolean=undefined;
    ConsiderationSource: number=undefined;
    ConsiderationResult: number = undefined;
}
export class TransactionInvestments {
    IsSwap: boolean=undefined;
    SourceInvestmentId: number=undefined;
    TargetInvestmentId: number = undefined;
}
export class TransactionNet {
    id: number=undefined;
    TransactionId: number=undefined;
    WorkingTransactionId: number=undefined;
    TransactionDate: string=undefined;
    WorkingTransactionDate: string=undefined;
    WorkingTransactionType: string=undefined;
    Entity: string=undefined;
    StructureType: string=undefined;
    Structure: string=undefined;
    NetShares: number=undefined;
    PurchaseAmount: number=undefined;
    ReturnAmount: number=undefined;
    _RecursiveAccumulatedShares: number=undefined;
    _RecursiveFactor: number=undefined;
    _ReturnFactor: number=undefined;
    RunningCostBasis: number=undefined;
    PurchaseTaxAcquisitionDate: string=undefined;
    CostBasisMethod: string=undefined;
    CostPerShare: number=undefined;
    ReturnPrice: number=undefined;
    DayHeld: number = undefined;
}
export class TransactionNetSummary {
    InvestmentId: number=undefined;
    Entity: string=undefined;
    StructureType: string=undefined;
    Structure: string=undefined;
    NetShares: number=undefined;
    RunningCostBasis: number=undefined;
    PurchaseAmount: number=undefined;
    ReturnAmount: number = undefined;
}
export class Transactions {
    Id: number=undefined;
    InvestmentId: number=undefined;
    ClientId: number=undefined;
    OwnerId: number=undefined;
    TransactionTypeId: number=undefined;
    TransactionType: string=undefined;
    TransactionTypeDisplay: string=undefined;
    TransactionDate: string=undefined;
    Entity: string=undefined;
    EntityId: number=undefined;
    NewEntityId: number=undefined;
    Structure: string=undefined;
    NewStructure: string=undefined;
    NewStructureType: string=undefined;
    SourceStructureType: string=undefined;
    Amount: number=undefined;
    Shares: number=undefined;
    ShareValue: number=undefined;
    CostBasisMethod: string=undefined;
    ManualCostBasis: number=undefined;
    SpecificLotCost: number=undefined;
    UseSpecificLot: boolean=undefined;
    IncomeType: string=undefined;
    IncomeTypeId: number=undefined;
    Notes: string=undefined;
    NotesDisplay: string=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    ShortDateUser: string=undefined;
    ModifyDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyShortDateUser: string=undefined;
    LastModified: string=undefined;
    DocumentationReceived: boolean=undefined;
    IsLastTransaction: boolean=undefined;
    Age: number=undefined;
    AgeYears: number=undefined;
    SplitRatioFrom: number=undefined;
    SplitRatioTo: number=undefined;
    NotTaxable: boolean=undefined;
    Owner: string=undefined;
    InvestmentName: string=undefined;
    EntityName: string=undefined;
    NewEntity: string=undefined;
    NewInvestmentId: number=undefined;
    TransferredPurchaseTransactionId: number=undefined;
    RunningCostBasis: number=undefined;
    NetShares: number=undefined;
    CostBasisForTransferDisplay: number=undefined;
    HasMatchedTransactions: boolean=undefined;
    IsAccrual: boolean=undefined;
    Documents: number=undefined;
    BankAccountId: number=undefined;
    IsBankAccountManual: boolean=undefined;
    Deletable: boolean=undefined;
    DisableMatches: boolean=undefined;
    DisabledMatchReason: string=undefined;
    TaxAcquisitionDate: string=undefined;
    Amount2: number=undefined;
    AllocationHierarchyJSON: string=undefined;
    CarryShares: boolean=undefined;
    SameDayOrder: number=undefined;
    ImputedRecord: boolean=undefined;
    SourceTransactionId: number=undefined;
    NoDocumentAvailable: boolean=undefined;
    IncludeInBalanceSheetPriorToPosting: boolean = undefined;
}
export class TransactionsList {
    Id: number=undefined;
    InvestmentId: number=undefined;
    ClientId: number=undefined;
    OwnerId: number=undefined;
    TransactionTypeId: number=undefined;
    TransactionType: string=undefined;
    TransactionTypeDisplay: string=undefined;
    TransactionDate: string=undefined;
    Entity: string=undefined;
    EntityId: number=undefined;
    NewEntityId: number=undefined;
    Structure: string=undefined;
    NewStructure: string=undefined;
    StructureDisplay: string=undefined;
    NewStructureType: string=undefined;
    SourceStructureType: string=undefined;
    Amount: number=undefined;
    Shares: number=undefined;
    ShareValue: number=undefined;
    CostBasisMethod: string=undefined;
    ManualCostBasis: number=undefined;
    SpecificLotCost: number=undefined;
    UseSpecificLot: boolean=undefined;
    IncomeType: string=undefined;
    IncomeTypeId: number=undefined;
    Notes: string=undefined;
    NotesDisplay: string=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    ShortDateUser: string=undefined;
    ModifyDate: string=undefined;
    ModifyUser: string=undefined;
    ModifyShortDateUser: string=undefined;
    LastModified: string=undefined;
    DocumentationReceived: boolean=undefined;
    IsLastTransaction: boolean=undefined;
    Age: number=undefined;
    AgeYears: number=undefined;
    SplitRatioFrom: number=undefined;
    SplitRatioTo: number=undefined;
    NotTaxable: boolean=undefined;
    Owner: string=undefined;
    InvestmentName: string=undefined;
    EntityName: string=undefined;
    NewEntity: string=undefined;
    NewInvestmentId: number=undefined;
    TransferredPurchaseTransactionId: number=undefined;
    CostBasisForTransferDisplay: number=undefined;
    HasMatchedTransactions: boolean=undefined;
    IsAccrual: boolean=undefined;
    Documents: number=undefined;
    BankAccountId: number=undefined;
    IsBankAccountManual: boolean=undefined;
    Deletable: boolean=undefined;
    DisableMatches: boolean=undefined;
    DisabledMatchReason: string=undefined;
    TaxAcquisitionDate: string=undefined;
    Amount2: number=undefined;
    AllocationHierarchyJSON: string=undefined;
    CarryShares: boolean=undefined;
    SameDayOrder: number=undefined;
    ImputedRecord: boolean=undefined;
    SourceTransactionId: number=undefined;
    NoDocumentAvailable: boolean=undefined;
    IncludeInBalanceSheetPriorToPosting: boolean=undefined;
    CapitalCallScheduleId: number=undefined;
    AssociatedTransactionHeaders: string = undefined;
}
export class TransactionTypes {
    Id: number=undefined;
    Display: string=undefined;
    ControlName: string=undefined;
    ShowEntity: boolean=undefined;
    ShowNewEntity: boolean=undefined;
    ShowStructureType: boolean=undefined;
    ShowStructure: boolean=undefined;
    ShowNewStructureType: boolean=undefined;
    ShowNewStructure: boolean=undefined;
    ShowAmount: boolean=undefined;
    ShowFundingSource: boolean=undefined;
    ShowShares: boolean=undefined;
    ShowShareValue: boolean=undefined;
    ShowCostBasisMethod: boolean=undefined;
    ShowReturnIncomeType: boolean=undefined;
    ShowNotTaxable: boolean=undefined;
    ShowSplitRatio: boolean=undefined;
    ShowIsLastTransaction: boolean=undefined;
    ShowNewInvestment: boolean=undefined;
    ShowConsideration: boolean=undefined;
    ShowAssessedValue: boolean=undefined;
    ShowFundingSourceEntityType: boolean=undefined;
    ShowFundingSourceEntityId: boolean=undefined;
    ShowManualCostBasis: boolean=undefined;
    ShowTaxAcquisitionDate: boolean=undefined;
    ShowReadOnlyTaxAcquisitionDate: boolean=undefined;
    TaxAcquisitionDateLabel: string=undefined;
    IsPurchase: boolean=undefined;
    IsReturn: boolean=undefined;
    IsSplit: boolean=undefined;
    IsExchange: boolean=undefined;
    IsTransfer: boolean=undefined;
    IsIPO: boolean=undefined;
    IsCapitalCall: boolean=undefined;
    IsCapitalCommitment: boolean=undefined;
    AllowSpecificLotManualCostBasis: boolean=undefined;
    IsInkindDistribution: boolean=undefined;
    ShowCashSourceConsideration: boolean=undefined;
    ShowCashResultConsideration: boolean=undefined;
    ShowSourceConsideration: boolean=undefined;
    ShowResultConsideration: boolean = undefined;
}
export class TransferPreview {
    InvestmentId: number=undefined;
    Name: string=undefined;
    Owner: string=undefined;
    TransactionDate: string=undefined;
    Entity: string=undefined;
    NewStructureType: string=undefined;
    NewStructure: string=undefined;
    Shares: number=undefined;
    Amount: number=undefined;
    CostBasis: number=undefined;
    TransferredPurchaseTransactionId: number=undefined;
    TransactionType: string=undefined;
    TaxAcquisitionDate: string=undefined;
    Notes: string=undefined;
    CarryShares: boolean = undefined;
}
export class Types {
    Id: number=undefined;
    Name: string=undefined;
    ShowNetAmount: boolean=undefined;
    IsCapitalCommitment: boolean=undefined;
    IsContribution: boolean=undefined;
    IsCapitalCall: boolean=undefined;
    IsDistribution: boolean=undefined;
    IsStakeTransaction: boolean=undefined;
    IsShareTransaction: boolean=undefined;
    IsDebtTransaction: boolean=undefined;
    HasSubTypes: boolean=undefined;
    EquityResultRequiresProvenance: boolean=undefined;
    IsShortEquityTransaction: boolean=undefined;
    DisableUserSelection: boolean = undefined;
}
export class PreviewHeaderDelete {
    Type: string=undefined;
    Records: number=undefined;
    PreventDelete: number = undefined;
}
export class QueueProcessNonShareValuationStatistics {
    Id: number=undefined;
    EventId: number=undefined;
    CreateDate: string=undefined;
    CreateUser: string=undefined;
    CompleteDate: string=undefined;
    Milliseconds: number=undefined;
    Success: boolean=undefined;
    Database: string=undefined;
    Schema: string=undefined;
    Caller: string=undefined;
    Event: string=undefined;
    Machine: string=undefined;
    SPID: number=undefined;
    CreateDateLocal: string=undefined;
    Seconds: number=undefined;
    Minutes: number=undefined;
    CompleteDateLocal: string=undefined;
    DeleteDate: string=undefined;
    DeleteUser: string=undefined;
    QueueId: number = undefined;
}
export class QuickCapitalContribution {
    Message: string=undefined;
    Succcess: boolean = undefined;
}
export class ResetAccountingCapital {
    BeforeRecords: number=undefined;
    AfterRecords: number = undefined;
}
export class Transfer {
    Message: string=undefined;
    Success: boolean = undefined;
}

// Parameter interfaces:

export interface CreateManualInvestmentBankTransactionParameters {
    TransactionId: number;
}

export interface DeleteHeaderAndComponentsParameters {
    TransactionHeaderId: number;
    LogId: number;
}

export interface GetAssociatedTransactionsParameters {
    SourceTransactionId: number;
}

export interface GetAvailableSpecificLotTransactionsParameters {
    TransactionId: number;
}

export interface GetCapitalParameters {
    TransactionHeaderId: number;
    CapitalId: number;
    InvestmentId: number;
    Direction: string;
    CapitalTypeId: number;
    CapitalTypeIdList: string;
    UpToButExcludingTransactionHeaderId: number;
    OwnerIdList: string;
}

export interface GetCapitalSubTypesParameters {
    InvestmentId: number;
    CapitalTypeId: number;
}

export interface GetCapitalTypesParameters {
    CapitalTypeId: number;
    CapitalType: string;
}

export interface GetCashParameters {
    // TransactionHeaderId: number; deprecated 9/11/24
    CashId: number;
    Direction: string;
    InvestmentId: number;
    OwnerIdList: string;
    EntityType: string;
    EntityId: number;
}

export interface GetChangeLogParameters {
    TransactionHeaderId: number;
}

export interface GetConsiderationTypesParameters {
    TransactionTypeId: number;
    Direction: string;
    ConsiderationTypeId: number;
}

export interface GetCreditNoteParameters {
    TransactionHeaderId: number;
    CreditNoteId: number;
    Direction: string;
    PromissoryNoteTransactionId: number;
}

export interface GetDebtParameters {
    TransactionHeaderId: number;
    DebtId: number;
    InvestmentId: number;
    Direction: string;
    UpToButExcludingTransactionHeaderId: number;
    OwnerIdList: string;
}

export interface GetDistributionInvestmentsParameters {
    TransactionHeaderId: number;
    Update: boolean;
}

export interface GetDistributionItemsParameters {
    TransactionHeaderId: number;
}

export interface GetEquityParameters {
    TransactionHeaderId: number;
    EquityId: number;
    InvestmentId: number;
    Direction: string;
    UpToButExcludingTransactionHeaderId: number;
    OwnerIdList: string;
}

export interface GetEquityTransactionTranchesParameters {
    TransactionHeaderId: number;
    InvestmentId: number;
}

export interface GetExpenseParameters {
    TransactionHeaderId: number;
    ExpenseId: number;
    InvestmentId: number;
    Direction: string;
    UpToButExcludingTransactionHeaderId: number;
    OwnerIdList: string;
}

export interface GetExternalCashMovementConsiderationParameters {
    TransactionHeaderId: number;
    ExternalCashMovementId: number;
    Direction: string;
    PromissoryNoteTransactionId: number;
}

export interface GetFixedIncomeBankTransactionsParameters {
    FixedIncomeId: number;
}

export interface GetFundingSourceParameters {
    TransactionId: number;
}

export interface GetHeaderParameters {
    HeaderId: number;
}

export interface GetInterPromissoryNoteConsiderationParameters {
    PromissoryNoteId: number;
    PromissoryNoteTransactionId: number;
}

export interface GetInvestmentsForHeaderParameters {
    TransactionHeaderId: number;
}

export interface GetLegacyAssociatedParameters {
    TransactionHeaderId: number;
}

export interface GetMarketableSecuritiesForSaleParameters {
    MarketableSecuritySaleId: number;
}

export interface GetMarketableSecurityInvestmentsParameters {
    MarketableSecuritySaleSecurityId: number;
}

export interface GetMarketableSecuritySaleParameters {
    MarketableSecuritySaleId: number;
}

export interface GetMarketableSecuritySaleSecuritiesParameters {
    MarketableSecuritySaleId: number;
}

export interface GetMarketableSecuritySaleSecurityParameters {
    MarketableSecuritySaleSecurityId: number;
}

export interface GetNewLegacyStatisticsAnalysisParameters {
    InvestmentId: number;
    ExcludeFMVOnlyRows: boolean;
}

export interface GetSpecificLotTransactionsParameters {
    TransactionId: number;
}

export interface GetSubTypeParameters {
    TransactionTypeId: number;
}

export interface GetTrancheHistoryParameters {
    AcquisitionSourceId: number;
    InvestmentId: number;
}

export interface GetTrancheTypesParameters {
    InvestmentId: number;
}

export interface GetTransactionCalculationParameters {
    TransactionId: number;
}

export interface GetTransactionHeaderForInvestmentParameters {
    InvestmentId: number;
}

export interface GetTransactionInvestmentsParameters {
    TransactionHeaderId: number;
}

export interface GetTransactionNetParameters {
    InvestmentId: number;
    TransactionDate: string;
}

export interface GetTransactionNetSummaryParameters {
    InvestmentId: number;
    TransactionDate: string;
}

export interface GetTransactionsParameters {
    InvestmentId: number;
    TransactionId: number;
}

export interface GetTransactionsListParameters {
    InvestmentId: number;
    TransferredFromId: number;
    AvailableForFundingSourceOnly: boolean;
    TransactionId: number;
}

export interface GetTransactionTypesParameters {
    InvestmentId: number;
}

export interface GetTransferPreviewParameters {
    SourceTransactionId: number;
}

export interface GetTypesParameters {
    InvestmentId: number;
}

export interface PreviewHeaderDeleteParameters {
    TransactionHeaderId: number;
}

export interface QueueProcessNonShareValuationStatisticsParameters {
    LogId: number;
}

export interface QuickCapitalContributionParameters {
    TransactionHeaderId: number;
    IsCapitalCall: boolean;
}

export interface ResetAccountingCapitalParameters {
    CapitalId: number;
}

export interface TransferParameters {
    SourceTransactionId: number;
}

export class TransactionsService {

  CreateManualInvestmentBankTransaction (params: CreateManualInvestmentBankTransactionParameters): Promise<CreateManualInvestmentBankTransaction[]> {
    return smartData({
        storedProcName: 'transactions.CreateManualInvestmentBankTransaction',
        params: params
      });
    }

  DeleteHeaderAndComponents (params: DeleteHeaderAndComponentsParameters): Promise<any[]> {
    return smartData({
        storedProcName: 'transactions.DeleteHeaderAndComponents',
        params: params
      });
    }

  GetAssociatedTransactions (params: GetAssociatedTransactionsParameters): Promise<AssociatedTransactions[]> {
    return smartData({
        storedProcName: 'transactions.GetAssociatedTransactions',
        params: params
      });
    }

  GetAvailableSpecificLotTransactions (params: GetAvailableSpecificLotTransactionsParameters): Promise<AvailableSpecificLotTransactions[]> {
    return smartData({
        storedProcName: 'transactions.GetAvailableSpecificLotTransactions',
        params: params
      });
    }

  GetCapital (params: GetCapitalParameters): Promise<Capital[]> {
    return smartData({
        storedProcName: 'transactions.GetCapital',
        params: params
      });
    }

  GetCapitalSubTypes (params: GetCapitalSubTypesParameters): Promise<CapitalSubTypes[]> {
    return smartData({
        storedProcName: 'transactions.GetCapitalSubTypes',
        params: params
      });
    }

  GetCapitalTypes (params: GetCapitalTypesParameters): Promise<CapitalTypes[]> {
    return smartData({
        storedProcName: 'transactions.GetCapitalTypes',
        params: params
      });
    }

  GetCash (params: GetCashParameters): Promise<Cash[]> {
    return smartData({
        storedProcName: 'transactions.GetCash',
        params: params
      });
    }

  GetChangeLog (params: GetChangeLogParameters): Promise<ChangeLog[]> {
    return smartData({
        storedProcName: 'transactions.GetChangeLog',
        params: params
      });
    }

  GetConsiderationTypes (params: GetConsiderationTypesParameters): Promise<ConsiderationTypes[]> {
    return smartData({
        storedProcName: 'transactions.GetConsiderationTypes',
        params: params
      });
    }

  GetCreditNote (params: GetCreditNoteParameters): Promise<CreditNote[]> {
    return smartData({
        storedProcName: 'transactions.GetCreditNote',
        params: params
      });
    }

  GetDebt (params: GetDebtParameters): Promise<Debt[]> {
    return smartData({
        storedProcName: 'transactions.GetDebt',
        params: params
      });
    }

  GetDistributionInvestments (params: GetDistributionInvestmentsParameters): Promise<DistributionInvestments[]> {
    return smartData({
        storedProcName: 'transactions.GetDistributionInvestments',
        params: params
      });
    }

  GetDistributionItems (params: GetDistributionItemsParameters): Promise<DistributionItems[]> {
    return smartData({
        storedProcName: 'transactions.GetDistributionItems',
        params: params
      });
    }

  GetEquity (params: GetEquityParameters): Promise<Equity[]> {
    return smartData({
        storedProcName: 'transactions.GetEquity',
        params: params
      });
    }

  GetEquityTransactionTranches (params: GetEquityTransactionTranchesParameters): Promise<EquityTransactionTranches[]> {
    return smartData({
        storedProcName: 'transactions.GetEquityTransactionTranches',
        params: params
      });
    }

  GetExpense (params: GetExpenseParameters): Promise<Expense[]> {
    return smartData({
        storedProcName: 'transactions.GetExpense',
        params: params
      });
    }

  GetExternalCashMovementConsideration (params: GetExternalCashMovementConsiderationParameters): Promise<ExternalCashMovementConsideration[]> {
    return smartData({
        storedProcName: 'transactions.GetExternalCashMovementConsideration',
        params: params
      });
    }

  GetFixedIncomeBankTransactions (params: GetFixedIncomeBankTransactionsParameters): Promise<FixedIncomeBankTransactions[]> {
    return smartData({
        storedProcName: 'transactions.GetFixedIncomeBankTransactions',
        params: params
      });
    }

  GetFundingSource (params: GetFundingSourceParameters): Promise<FundingSource[]> {
    return smartData({
        storedProcName: 'transactions.GetFundingSource',
        params: params
      });
    }

  GetHeader (params: GetHeaderParameters): Promise<Header[]> {
    return smartData({
        storedProcName: 'transactions.GetHeader',
        params: params
      });
    }

  GetInterPromissoryNoteConsideration (params: GetInterPromissoryNoteConsiderationParameters): Promise<InterPromissoryNoteConsideration[]> {
    return smartData({
        storedProcName: 'transactions.GetInterPromissoryNoteConsideration',
        params: params
      });
    }

  GetInvestmentsForHeader (params: GetInvestmentsForHeaderParameters): Promise<InvestmentsForHeader[]> {
    return smartData({
        storedProcName: 'transactions.GetInvestmentsForHeader',
        params: params
      });
    }

  GetLegacyAssociated (params: GetLegacyAssociatedParameters): Promise<LegacyAssociated[]> {
    return smartData({
        storedProcName: 'transactions.GetLegacyAssociated',
        params: params
      });
    }

  GetMarketableSecuritiesForSale (params: GetMarketableSecuritiesForSaleParameters): Promise<MarketableSecuritiesForSale[]> {
    return smartData({
        storedProcName: 'transactions.GetMarketableSecuritiesForSale',
        params: params
      });
    }

  GetMarketableSecurityInvestments (params: GetMarketableSecurityInvestmentsParameters): Promise<MarketableSecurityInvestments[]> {
    return smartData({
        storedProcName: 'transactions.GetMarketableSecurityInvestments',
        params: params
      });
    }

  GetMarketableSecuritySale (params: GetMarketableSecuritySaleParameters): Promise<MarketableSecuritySale[]> {
    return smartData({
        storedProcName: 'transactions.GetMarketableSecuritySale',
        params: params
      });
    }

  GetMarketableSecuritySaleSecurities (params: GetMarketableSecuritySaleSecuritiesParameters): Promise<MarketableSecuritySaleSecurities[]> {
    return smartData({
        storedProcName: 'transactions.GetMarketableSecuritySaleSecurities',
        params: params
      });
    }

  GetMarketableSecuritySaleSecurity (params: GetMarketableSecuritySaleSecurityParameters): Promise<MarketableSecuritySaleSecurity[]> {
    return smartData({
        storedProcName: 'transactions.GetMarketableSecuritySaleSecurity',
        params: params
      });
    }

  GetNewLegacyStatisticsAnalysis (params: GetNewLegacyStatisticsAnalysisParameters): Promise<NewLegacyStatisticsAnalysis[]> {
    return smartData({
        storedProcName: 'transactions.GetNewLegacyStatisticsAnalysis',
        params: params
      });
    }

  GetSpecificLotTransactions (params: GetSpecificLotTransactionsParameters): Promise<SpecificLotTransactions[]> {
    return smartData({
        storedProcName: 'transactions.GetSpecificLotTransactions',
        params: params
      });
    }

  GetSubType (params: GetSubTypeParameters): Promise<SubType[]> {
    return smartData({
        storedProcName: 'transactions.GetSubType',
        params: params
      });
    }

  GetTrancheHistory (params: GetTrancheHistoryParameters): Promise<TrancheHistory[]> {
    return smartData({
        storedProcName: 'transactions.GetTrancheHistory',
        params: params
      });
    }

  GetTrancheTypes (params: GetTrancheTypesParameters): Promise<TrancheTypes[]> {
    return smartData({
        storedProcName: 'transactions.GetTrancheTypes',
        params: params
      });
    }

  GetTransactionCalculation (params: GetTransactionCalculationParameters): Promise<TransactionCalculation[]> {
    return smartData({
        storedProcName: 'transactions.GetTransactionCalculation',
        params: params
      });
    }

  GetTransactionHeaderForInvestment (params: GetTransactionHeaderForInvestmentParameters): Promise<TransactionHeaderForInvestment[]> {
    return smartData({
        storedProcName: 'transactions.GetTransactionHeaderForInvestment',
        params: params
      });
    }

  GetTransactionInvestments (params: GetTransactionInvestmentsParameters): Promise<TransactionInvestments[]> {
    return smartData({
        storedProcName: 'transactions.GetTransactionInvestments',
        params: params
      });
    }

  GetTransactionNet (params: GetTransactionNetParameters): Promise<TransactionNet[]> {
    return smartData({
        storedProcName: 'transactions.GetTransactionNet',
        params: params
      });
    }

  GetTransactionNetSummary (params: GetTransactionNetSummaryParameters): Promise<TransactionNetSummary[]> {
    return smartData({
        storedProcName: 'transactions.GetTransactionNetSummary',
        params: params
      });
    }

  GetTransactions (params: GetTransactionsParameters): Promise<Transactions[]> {
    return smartData({
        storedProcName: 'transactions.GetTransactions',
        params: params
      });
    }

  GetTransactionsList (params: GetTransactionsListParameters): Promise<TransactionsList[]> {
    return smartData({
        storedProcName: 'transactions.GetTransactionsList',
        params: params
      });
    }

  GetTransactionTypes (params: GetTransactionTypesParameters): Promise<TransactionTypes[]> {
    return smartData({
        storedProcName: 'transactions.GetTransactionTypes',
        params: params
      });
    }

  GetTransferPreview (params: GetTransferPreviewParameters): Promise<TransferPreview[]> {
    return smartData({
        storedProcName: 'transactions.GetTransferPreview',
        params: params
      });
    }

  GetTypes (params: GetTypesParameters): Promise<Types[]> {
    return smartData({
        storedProcName: 'transactions.GetTypes',
        params: params
      });
    }

  PreviewHeaderDelete (params: PreviewHeaderDeleteParameters): Promise<PreviewHeaderDelete[]> {
    return smartData({
        storedProcName: 'transactions.PreviewHeaderDelete',
        params: params
      });
    }

  QueueProcessNonShareValuationStatistics (params: QueueProcessNonShareValuationStatisticsParameters): Promise<QueueProcessNonShareValuationStatistics[]> {
    return smartData({
        storedProcName: 'transactions.QueueProcessNonShareValuationStatistics',
        params: params
      });
    }

  QuickCapitalContribution (params: QuickCapitalContributionParameters): Promise<QuickCapitalContribution[]> {
    return smartData({
        storedProcName: 'transactions.QuickCapitalContribution',
        params: params
      });
    }

  ResetAccountingCapital (params: ResetAccountingCapitalParameters): Promise<ResetAccountingCapital[]> {
    return smartData({
        storedProcName: 'transactions.ResetAccountingCapital',
        params: params
      });
    }

  Transfer (params: TransferParameters): Promise<Transfer[]> {
    return smartData({
        storedProcName: 'transactions.Transfer',
        params: params
      });
    }
}
