var mixinLiability = {
    methods: {
        getNotes: function (clientId, ownerId, noteStatusId, noteTypeId) {
            noteStatusId = (noteStatusId == '') ? null : noteStatusId;
            clientId = (clientId == '') ? null : clientId;
            ownerId = (ownerId == '') ? null : ownerId;
            var ds = smartData({
                storedProcName: 'liab.GetNotes'
                , params: {
                    ClientId: clientId
                    , OwnerId: ownerId
                    , NoteStatus: noteStatusId
                    , NoteType: noteTypeId
                }
                , loadAsync: false
            });
            return ds;
        }
        , getNote: function (noteId) {
            var ds = smartData({
                storedProcName: 'liab.GetNotes'
                , params: {
                    NoteId: noteId
                }
                , loadAsync: false
            });
       if (ds.length === 1) {
        return ds[0];
       }
       else
        return {};
       }
        , getNoteStatus: function () {
            return this.system_GetNamedKeys('Liability.NoteStatus');
        }
        , getAmortizationTypes: function () {
            return this.system_GetNamedKeys('Liability.AmortizationType');
        }

    }
};
export default mixinLiability;

