<template>
    <el-dialog v-loading.body="loading" 
        :visible="showCoAPicker" 
        :show-close="true" 
        :before-close="handleClose"
        :close-on-click-modal="false"
        >
        <span slot="title">Set Account (CoA)</span>
        <!-- <pre>{{COA}}</pre> -->
        <el-cascader placeholder="(searchable)"
                         :options="chartOfAccountsForCascader"
                         filterable clearable
                         :filter-method="filterMethodCoA"
                         size="small"
                         style="width: 100%; "
                         expand-trigger="hover"
                         :change-on-select="true"
                         name="ChartOfAccounts"
                         v-validate
                         v-model="chartOfAccountsArray">
            </el-cascader>
    </el-dialog>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import mixinSchema_vam from './../DAL/mixinSchema_vam'

    export default Vue.extend({
        name: 'CoAPicker'
        , mixins: [mixinUtility,  mixinSchema_vam]
        , props: {
            row: {
                required: true
            }
            , showCoAPicker: {
                type: Boolean
                , default: false
            }
        }
        , data: function () {
            return {
                loading: false
                , COA: {
                    Id: null
                    , ClientId: null
                    , COAId: null
                    , COAIdArray: ''
                    , Name: ''
                    , NamePath: ''
                }
                , chartOfAccounts: []
                , chartOfAccountsForCascader: []
            }
        }
        , created: function(){
        }
        , computed: {
            showSave: function () {
                return this.veeFields.dirty;
            }
            , chartOfAccountsArray: {
                get: function() {
                    if (this.COA.COAIdArray) {
                        return JSON.parse(this.COA.COAIdArray);
                    }
                    else
                        return [];
                }
                , set: function (data) {
                    this.COA.COAId = data[data.length - 1];
                    this.COA.COAIdArray = JSON.stringify(data);
                    var account = this.getSelectedArrayItem(this.chartOfAccounts, this.COA.COAId, 'Id');
                    this.COA.NamePath = (account) ? account.NamePath : null;
                    this.COA.Name = (account) ? account.Name : null;
                    this.$nextTick(function () {
                        this.$emit('coa-updated', this.COA); 
                        this.$emit('update:showCoAPicker', false);
                    });
                }
            }

        }
        , watch: {
            'row': async function(iRow, oldVal){
                if (Object.keys(iRow).length > 0){
                    this.COA.Id = iRow.Id;
                    this.COA.ClientId = iRow.ClientId || null;
                    this.COA.COAId = iRow.COAId;
                    this.COA.COAIdArray = iRow.COAIdArray;
                    this.COA.Name = iRow.COAName;
                    this.COA.NamePath = iRow.COANamePath;
                    // this.chartOfAccounts = this.vam_GetChartOfAccountsForTree(this.COA.ClientId);
                    this.chartOfAccounts = await this.vam_GetChartOfAccountsForTree({
					ClientId: this.COA.ClientId
					}
					, true // async (optional)
				);
                    this.chartOfAccountsForCascader = this.getCascaderHierarchy(this.chartOfAccounts, 'Id', 'Name', 'ParentId');
                }
            }
        }
        , methods: {
            handleClose(){
                this.$emit('update:showCoAPicker', false);
            }
            , filterMethodCoA(item, query) {
                return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
        }
    })

</script>