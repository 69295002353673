<template>
    <div >
  <el-card
    v-if="vCard"
    :shadow="(viewType === 'card' ? 'always' : 'never')"
    :body-style="(viewType === 'card' ? '' : { padding: '0' })"
    :style="(viewType === 'card' ? '' : { border: 'none' })">
    <div v-if="viewType === 'card'" slot="header">
      <span>New Invoice (No Document)</span>
      <button type="button" class="close" @click="onClose_" style="position: relative; top: 7px; margin-right: 2px;">&times;</button>
    </div>
    <el-tabs type="card" v-model="activeTab" >
        <el-tab-pane label="Invoice" >
            <invoice-edit :invoice="invoice"  :security-level="securityLevel_" ref="InvoiceEdit" @saved="invoiceSaved" @deleted="invoiceDeleted"></invoice-edit>
        </el-tab-pane>
        <el-tab-pane label="Allocation" :disabled="allocationTabDisabled">
            <allocation :params="allocationParameters" ref="invoiceAllocation" :security-level="securityLevel_" :show-title="false"></allocation>
        </el-tab-pane>
    </el-tabs>
    <div v-if="viewType === 'dialog'" class="text-lg-right" style="padding-top: 20px;">
        <el-button @click="closeDialog">Close</el-button>
    </div>
  </el-card>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import InvoiceEdit from './InvoiceEdit.vue'
    import Allocation from './../Allocation/Allocation.vue'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'

    export default Vue.extend({
        name: 'NewInvoiceView'
        , mixins: [mixinUtility, mixinSchema_liq]
        , components: {
            'invoice-edit': InvoiceEdit
            , 'allocation': Allocation
        }
        , props: {
            invoice: {
                type: Object
            }
            , viewType: {
                type: String
                , default: 'card'
            }

        }
        , data: function () {
            return {
                activeTab: '0'
                , allocationParameters: {
                    EntityType: 'Invoice'
                    , EntityId: null
                    , TotalAmount: null
                    , ClientId: null
                }
                , securityLevel_: null
                , invoiceId: null
                , vCard: true
            }
        }
        , watch: {
        }
        , created: function() {
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), 0);
            }
        }
        , mounted: function () {
        }
        , computed: {
            divHeight: function () {
                return getAvailableScreenHeight(20);
            }
            , allocationTabDisabled: function () {
                return !(this.allocationParameters.TotalAmount !== 0 && this.allocationParameters.ClientId > 0 && this.invoiceId > 0)
            }
        }
        , methods: {
            invoiceSaved: function (invoiceObject, field, val, oldVal) {
                this.invoiceId = invoiceObject.InvoiceId;
                this.allocationParameters = {
                    EntityType: 'Invoice'
                    , EntityId: invoiceObject.InvoiceId
                    , TotalAmount: invoiceObject.Amount
                    , ClientId: invoiceObject.ClientId
                };
                this.activeTab = '1';
            }
            , invoiceDeleted() {
                this.$router.push('InvoiceListView/')
            }
            ,   onClose_() {
                this.$emit('close');
                this.vCard = false;
            }
            , closeDialog() {
                this.$emit('close-dialog');
            }

        }
    });

</script>