
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import PaymentScheduleEdit from '@/views/TaxConfiguration/PaymentScheduleEdit.vue';
import * as TaxService from './../../services/DAL/taxService';
import formatters from '../../utils/formatters';
import findIndex from 'lodash/findIndex';

declare var $: any;
declare function getContainerType(element: any): string;

@Component({
    components: {
        PaymentScheduleEdit
    }
})
export default class PaymentScheduleList extends Vue {
    //#region Private Declarations
    private _taxService: TaxService.TaxService;
    private formatters: formatters;
    //#endregion Private Declarations

    //#region Props
    @Prop()
    importConfigurationId: number;
    //#endregion Props

    //#region Data
    modelPaymentSchedules: TaxService.ModelPaymentSchedules[] = [];
    selectedModelPaymentSchedule: TaxService.ModelPaymentSchedules = null;
    showPaymentScheduleEdit = false;
    isLoading = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._taxService = new TaxService.TaxService();
        this.formatters = new formatters();
    }

    async mounted() {
        this.fetchModelPaymentSchedules();
    }
    //#endregion Lifecycle

    //#region Methods
    async fetchModelPaymentSchedules() {
        this.isLoading = true;
        const parameters = {} as TaxService.GetModelPaymentSchedulesParameters;
        parameters.ImportConfigurationId = this.importConfigurationId;
        this.modelPaymentSchedules = await this._taxService.GetModelPaymentSchedules(parameters);
        this.isLoading = false;
    }

    selectPaymentSchedule(modelPaymentSchedule) {
        if (modelPaymentSchedule) {
            this.showPaymentScheduleEdit = false;
            this.$nextTick(() => {
                this.showPaymentScheduleEdit = true;
                this.selectedModelPaymentSchedule = modelPaymentSchedule;
            });
        }
    }

    closePaymentSchedule() {
        this.showPaymentScheduleEdit = false;
        this.selectedModelPaymentSchedule = null;
    }

    onSavePaymentSchedule(modelPaymentScheduleId) {
        this.closePaymentSchedule();
        this.fetchModelPaymentSchedules();
    }

    onDeletePaymentSchedule(modelPaymentScheduleId) {
        if (
            this.selectedModelPaymentSchedule &&
            this.selectedModelPaymentSchedule.ModelPaymentScheduleId == modelPaymentScheduleId
        ) {
            const index = findIndex(this.modelPaymentSchedules, modelPaymentSchedule => {
                return modelPaymentSchedule.ModelPaymentScheduleId === this.selectedModelPaymentSchedule.ModelPaymentScheduleId;
            });
            if (index > -1) {
                this.modelPaymentSchedules.splice(index, 1);
            }
        }
        this.closePaymentSchedule();
    }
    //#endregion Methods
}
