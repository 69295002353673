<template>
    <div >
        <el-row>
            <el-col :span="12">
                {{fileName}}
                <div ref="divPDF"></div>
            </el-col>
            <el-col :span="12">
                <el-tabs type="card" v-model="activeTab" >
                    <el-tab-pane label="Invoice" :style="{height: divHeight, 'overflow-y': 'scroll'}">
                        <invoice-edit :invoice="invoice" :append-dialogs-to-body="false" :security-level="securityLevel_" ref="InvoiceEdit" @saved="invoiceSaved" @deleted="invoiceDeleted"></invoice-edit>
                    </el-tab-pane>
                    <el-tab-pane label="Allocation" :disabled="allocationTabDisabled">
                        <allocation :params="allocationParameters" ref="invoiceAllocation" :security-level="securityLevel_" :show-title="false"></allocation>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';
    import InvoiceEdit from './InvoiceEdit.vue'
    import Allocation from './../Allocation/Allocation.vue'
    import mixinSchema_liq from './../DAL/mixinSchema_liq'
    import PDFObject from 'pdfobject';

    export default Vue.extend({
        name: 'InvoiceIngestView'
        , mixins: [mixinUtility, mixinSchema_liq]
        , components: {
            'invoice-edit': InvoiceEdit
            , 'allocation': Allocation
        }
        , props: {
            invoice: {
                type: Object
                , required: true
            }
            , fileProperties: {
                type: Object
                , required: true
            }
            , securityLevel: {
                type: Number
                , default: null
            }
        }
        , data: function () {
            return {
                pdfSource: ""
                , fileName: ""
                , referenceInvoice: this.liq_GetInvoices_New()
                , activeTab: '0'
                , allocationParameters: {
                    EntityType: 'Invoice'
                    , EntityId: null
                    , TotalAmount: null
                    , ClientId: null
                }
                , securityLevel_: this.securityLevel
                , graphService: {}
            }
        }
        , watch: {
            'fileProperties': function (val, oldVal) {
                this.loadInvoiceImage();
                this.activeTab = '0'; //set to initial on each start
            }
        }
        , created: function() {
            this.graphService = new MicrosoftGraphService();
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), 0);
            }
        }
        , mounted: function () {
            this.loadInvoiceImage();
        }
        , computed: {
            divHeight: function () {
                return getAvailableScreenHeight(20);
            }
            , allocationTabDisabled: function () {
                return !(this.allocationParameters.TotalAmount !== 0 && this.allocationParameters.ClientId > 0 && this.invoice.InvoiceId)
            }
        }
        , methods: {
            invoiceSaved: function (invoiceObject, field, val, oldVal) {
                this.referenceInvoice = invoiceObject;
                this.allocationParameters = {
                    EntityType: 'Invoice'
                    , EntityId: this.referenceInvoice.InvoiceId
                    , TotalAmount: this.referenceInvoice.Amount
                    , ClientId: this.referenceInvoice.ClientId
                };
                this.activeTab = '1';
            }
            , loadInvoiceImage: async function () {
                const graphClient = await this.graphService.getGraphClient();
                const PDFOptions = {
                    //width: "800px"
                    height: getAvailableScreenHeight(20)
                };
                const div = this.$refs.divPDF;
                try {
                    const res = await graphClient
                        .api(`/drives/${this.fileProperties.parentReference.driveId}/items/${this.fileProperties.id}`)
                        .get();
                    if (res) {
                        this.fileName = res.name;
                        //use Fetch to overcome CORS issue when the user has not authenticated with SharePoint (even though the URL should not require authentication)
                        const response = await fetch(res['@microsoft.graph.downloadUrl'])
                        const blobResponse = await response.blob();
                        const objectURL = window.URL.createObjectURL(blobResponse);
                        PDFObject.embed(objectURL, div, PDFOptions);
                    }
                }
                catch (err) {
                    console.warn(err);
                }

            }
            , invoiceDeleted() {
                this.$emit('close');
            }
        }
    });

</script>