
import Vue from 'vue';
import Component from 'vue-class-component';
import RegisteredAgentEdit from '@/views/RegisteredAgent/RegisteredAgentEdit.vue';
import * as VamService from './../../services/DAL/vamService';
import ElementUI from 'element-ui';

@Component({
  components: {
    RegisteredAgentEdit
  }
})
export default class RegisteredAgentList extends Vue {
  $refs: {
    tblRegisteredAgentList: ElementUI.Table
  }
  //#region Data
  private _vamService: VamService.VamService;

  registeredAgents = [];
  selectedRegisteredAgent = null;
  showRegisteredAgentEdit = false;
  showRegisteredAgentCreate = false;
  isLoading = false;
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._vamService = new VamService.VamService();
  }

  async mounted() {
    this.fetchRegisteredAgents();
  }
  //#endregion Lifecycle

  //#region Methods
  selectRegisteredAgent(registeredAgent) {
    if (registeredAgent) {
      this.showRegisteredAgentEdit = false;
      this.$nextTick(() => {
        this.showRegisteredAgentEdit = true;
        this.selectedRegisteredAgent = registeredAgent;
      });
    }
  }

  closeRegisteredAgent() {
    this.showRegisteredAgentEdit = false;
    this.selectedRegisteredAgent = null;
    this.$refs.tblRegisteredAgentList.setCurrentRow(null);
  }

  onDeleteRegisteredAgent() {
    this.closeRegisteredAgent();
    this.fetchRegisteredAgents();
  }

  async fetchRegisteredAgents() {
    this.isLoading = true;
    const parms = {} as VamService.GetRegisteredAgentsParameters;
    this.registeredAgents = await this._vamService.GetRegisteredAgents(parms);
    this.isLoading = false;
  }
  //#endregion Methods
}
