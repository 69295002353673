
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component
export default class FundFamilyEdit extends Vue {
    $refs: {
        frmFundFamilyEdit: ElementUI.Form
    }
    //#region Data
    private _assetService: AssetService.AssetService;

    @Prop() fundFamily: AssetService.FundFamilies;
    @Prop() viewType: string;
    @Prop() onSave: Function;
    @Prop() closeHandler: Function;

    fundFamily_: AssetService.FundFamilies = new AssetService.FundFamilies();
    notes = null;
    relationships = [];
    phoneTypes = [];
    //#endregion Data

    //#region Lifecycle
    async created() {
        if (this.fundFamily && Object.keys(this.fundFamily).length) {
            this.fundFamily_ = this.fundFamily;
        }
        this._assetService = new AssetService.AssetService();
    }
    //#endregion Lifecycle

    //#region Computed
    get isNew(): boolean {
        return (
            !this.fundFamily_ ||
            !Object.keys(this.fundFamily_).length ||
            (this.fundFamily_.Id || 0) <= 0
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    //#endregion Computed

    //#region Methods
    async saveForm() {
        this.$refs.frmFundFamilyEdit.validate(async valid => {
            if (valid) {
                let message: string;
                const isNew = this.isNew;

                if (isNew) {
                    try {
                        this.fundFamily_.Id = await new SmartObject('FundFamily').createObject(this.fundFamily_);
                    }
                    catch (err) {
                        try {
                            const errJson = JSON.parse(err.responseText);
                            if (errJson.ExceptionMessage.toLowerCase().indexOf('cannot insert duplicate key') > -1) {
                                this.$notify.error('The name entered matches the name of a fund family already in the system. Please edit the existing record, or change the name and try again.');
                            }
                            else {
                                this.$notify.error('Something went wrong processing your request, please try again.');
                            }
                        }
                        catch {
                            this.$notify.error('Something went wrong processing your request, please try again.');
                        }
                        return;
                    }
                    message = 'Fund family added.';

                    if (this.notes) {
                        await new SmartObject('CommentLog').createObject({
                            Comment: this.notes,
                            EntityType: 'FundFamily',
                            EntityId: this.fundFamily_.Id
                        });
                    }
                } else {
                    try {
                        await new SmartObject('FundFamily', this.fundFamily_.Id).updateObject(this.fundFamily_);
                        message = 'Changes saved.';
                    }
                    catch {
                        this.$notify.error('Something went wrong processing your request, please try again.');
                    }
                }

                this.$notify.success(message);

                if (typeof this.onSave === 'function') {
                    this.onSave(this.fundFamily_.Id);
                }

                this.$emit('saved', this.fundFamily_.Id);
                this.$emit('update:fundFamily', this.fundFamily_);

                if (isNew) {
                    this.$emit('close');
                }
            }
        });
    }

    async deleteFundFamily() {
        if (!this.isNew) {
        try {
            await this.$confirm(
                'This will permanently delete this fund family. Continue?',
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );

            try {
                await new SmartObject('FundFamily', this.fundFamily_.Id).deleteObject();

                this.$notify.success('Fund family deleted.');

                this.$emit('deleted', this.fundFamily_.Id);
                this.$emit('close');
            } catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                }
            } catch {}
        }
    }

    closePopup() {
        if (typeof this.closeHandler === 'function') {
            this.closeHandler();
        }
        this.$emit('close');
    }
    //#endregion Methods
}
