
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Admin extends Vue {
  msg: string = 'Welcome to Your Vue.js Admin';
  get user() {
    return this.$store.getters.account;
  }
}
