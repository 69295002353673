 <template>
     <div id="divExplorerVue" v-cloak >
         <div class="row">
             <div class="col-lg-9 col-md-6">
                Store:
                <el-select filterable
                            v-bind:disabled="!stores>0"
                            size="mini"
                            style="width:100%; max-width:300px;"
                            v-model="storeId">
                    <el-option-group v-for="group in storesForDDL" :key="group.groupId"
                                    :label="group.label">
                        <el-option v-for="item in group.options" :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                        </el-option>
                    </el-option-group>
                </el-select>
                <span style="font-size:smaller" v-loading="isLoading" >Files:{{items.length}}</span>
                <span style="font-size:smaller" >{{message}}</span>
            </div>
         </div>
        <div class="row">
            <div class="col-lg-9 col-md-6">
                <upload-attachment :store-id="parameters.storeId"
                    :parameters="parameters"
                    style="max-width:300px"
                    v-if="showUpload"
                    @document-uploaded="documentUploaded"
                    @upload-failed="uploadFailed">
                </upload-attachment>
            </div>
            <div class="col-lg-3 col-md-6">
                <el-input v-model="filter" v-if="items[0]" placeholder="filter" size="mini" clearable suffix-icon="el-icon-search" style="max-width:250px" class="pull-right"/>
            </div>
        </div>

        <div class="row">
          <div class="col-12">
            <el-button icon="d-arrow-left" v-on:click="folderUp" type="info" size="mini" v-show="!(path==previousPath)">{{previousPathDisplay}}</el-button>
            <span class="text-small"> {{ path }}</span>
            <el-table v-if="items[0]"
                    :data="itemsFiltered"
                    :default-sort = "{prop: 'createdDateTime', order: 'descending'}"
                    style="width: 100%;"
                    stripe
                    @row-click="itemSelect">
                <el-table-column>
                    <template slot-scope="props">
                        <i class="el-icon-document" v-show="!props.row.folder && !props.row.AttachmentId"></i>
                        <i class="fa fa-paperclip" v-show="!props.row.folder && !!props.row.AttachmentId" :title="props.row.AttachmentId"></i>
                        <span class="badge badge-pill badge-info" v-if="props.row.AttachmentId">{{props.row.Maps}}</span>
                        <i class="el-icon-caret-right" v-show="props.row.folder && props.row.folder != null && props.row.folder.childCount>0"></i>
                    </template>
                </el-table-column>
                <el-table-column label="Name"
                                 prop="name"
                                 sortable
                                 min-width="400px">
                    <template slot-scope="props">
                        <span :title="props.row.AttachmentId">{{props.row.name}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Children"
                                 align="center"
                                 sortable
                                 min-width="70px"
                                 prop="folder.childCount">
                </el-table-column>
                <el-table-column label="Created By"
                                 min-width="150px"
                                 align="center"
                                 sortable
                                 prop="createdBy.user.displayName">
                </el-table-column>
                <el-table-column label="Created On"
                                 min-width="150px"
                                 align="center"
                                 sortable
                                 sort-by="createdDateTime"
                                 prop="createdDateTime"
                                 :formatter="formatterShortDateTime">
                </el-table-column>
            </el-table>
          </div>
        </div>
        <el-dialog title="Ingest Attachment" :visible.sync="showAttachmentEdit" width="40%" top="18vh">
            <attachment-edit 
                v-if="showAttachmentEdit" 
                ref="refAttachmentEdit" 
                :store="(!selectedStore.IsIngestionSource) ? selectedStore : undefined" 
                :ingest-from-store="(selectedStore.IsIngestionSource) ? selectedStore : undefined" 
                :file-properties="selectedFileProperties" 
                :file-id="selectedFileId" 
                :attachment-id="attachmentToMap.Id" 
                @attachment-saved="newAttachmentSaved" 
                @attachment-deleted="fileDeleted" 
                @attachment-canceled="attachmentCanceled" >
            </attachment-edit>
        </el-dialog>
        <el-dialog title="Map Document" :visible.sync="showAttachmentMap" append-to-body  >
            <map-attachment v-if="showAttachmentMap" :attachment-id="attachmentToMap.Id" :attachment-file-properties="attachmentToMapFileProperties" v-on:attachment-mapped="attachmentMapped" ref="refAttachmentMap" v-on:edit-attachment="editAttachment"></map-attachment>
        </el-dialog>
     </div>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import AttachmentEdit from '@/views/Attachments/AttachmentEditV3.vue'
    import MapAttachment from './MapAttachment.vue'
    import UploadAttachment from './UploadAttachment.vue'
    import mixinSchema_attachment from './../DAL/mixinSchema_attachment'
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';

    export default Vue.extend({
        name: 'Explorer'
        , mixins: [mixinUtility, mixinSchema_attachment]
        , props: {
            parameters: {
                type: Object
                , required: true
            }
            , securityLevel: {
                type: Number
                , default: null
            }
            , showUpload: {
                type: Boolean
                , default: true
            }
            , basePath: {
                type: String
                , default: '/drive/root:/VAM/Attachments/Ingest'
            }
        }
        , components: {
            'attachment-edit': AttachmentEdit
            , 'upload-attachment': UploadAttachment
            , 'map-attachment': MapAttachment
        }
        , data: function () {
            return {
                items: []
                , filter: ''
                , path: ''
                , previousPath: ''
                , showAttachmentEdit: false
                , showAttachmentMap: false
                , showAttachmentAssignment: false
                , selectedFileId: null
                , attachmentToMap: {}
                , attachmentToMapFileProperties: {}
                , storeId: null
                , stores: []
                , storesForDDL: []
                , selectedFileProperties: {}
                , attachments: []
                , message: ''
                , securityLevel_: this.securityLevel
                , isLoading: false
                , graphService: {}
            }
        }
        , created: function () {
            this.graphService = new MicrosoftGraphService();
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDocuments), 0);
            }

            this.fetchStores();
        }
        , computed: {
            previousPathDisplay: function () {
                return (this.previousPath == '' || this.previousPath == undefined) ? '<root>' : this.previousPath;
            }
            , selectedStore: function () {
                if (Object.keys(this.stores).length == 0 || !this.storeId) {
                    return {}
                }
                else {
                    return this.getSelectedArrayItem(this.stores, this.storeId, 'Id');
                }
            }
            , itemsFiltered: function() {
                const filtered = this.items.filter(item => {
                    const filter_ = (this.filter || '').toLowerCase();
                    return (
                        (item.name || '').toLowerCase().indexOf(filter_) > -1 ||
                        (item.folder ? item.folder.childCount || '' : '').toString().toLowerCase().indexOf(filter_) > -1 ||
                        (item.createdBy && item.createdBy.user ? item.createdBy.user.displayName || '' : '').toLowerCase().indexOf(filter_) > -1 ||
                        moment(item.createdDateTime || '').format('MM/DD/YY H:mm').indexOf(filter_) > -1
                    );
                });
                return filtered;
            }
        }
        , watch: {
            async 'storeId' (val, oldVal) {
                await this.fetchItems(this.path);
            }
        }
        , methods: {
            getAndMergeAttachments: async function () {
                this.attachments = [];
                this.attachments = await this.attachment_GetAttachments({
                    StoreId: this.storeId
                });
                this.items = this.mergeItemsAndAttachments(this.items, this.attachments);
            }
            , mergeItemsAndAttachments: function (items, attachments) {
                const result = items.map(val => {
                    return Object.assign({}, val, attachments.filter(v => v.FileId === val.id)[0]);
                });
                return result;
            }
            , fetchStores: function () {
                var el = this;
                return new Promise(function (resolve, reject) {
                    el.attachment_GetStores({
                        //Type: 'DocumentLibrary'
                        //, StoreId: storeId
                    }
                    , function (data) {
                        el.stores = data;
                        el.storeId = data[0].Id; //set default to first item, which should be Ingest, and also fires the watch
                        el.storesForDDL = el.getDDLOptionsWithGroup(data, 'Type', 'Type', 'Name', 'Id');
                        resolve();
                    }
                    , function (error) {
                        reject(error);
                    });
                });
            }
            , fetchItems: async function (path) {
                this.isLoading = true;
                this.items = [];
                var el = this;
                // return new Promise(function (resolve, reject) {
                    if (path != undefined && path != '' && path.substring(0, 1) != '/')
                        path = '/' + path;
                    else if (path == undefined)
                        path = '';

                    var suffix = '?$select=name,id,folder,webUrl, createdBy, createdDateTime, parentReference';

                    const graphClient = await this.graphService.getGraphClient();
                    await this.loop(this.selectedStore.FolderItemPath + '/children' + path + suffix, graphClient);
                    await this.getAndMergeAttachments();
            }
            , async getFiles (path, graphClient) {
                try{
                    const files =  await graphClient
                            .api(path)
                            .get();
                    return files;
                }
                catch(err){
                    console.error(err);
                    this.items = [];
                }
            }
            , async loop (path, graphClient) {
                let nextLink = ' '; //not empty, but won't affect the outcome
                let result = null
                let batch = 1
                while (!!path && !!nextLink) {
                    this.message = 'Getting files in Batch ' + batch;
                    result = await this.getFiles(path + nextLink, graphClient);
                    this.items = this.items.concat(result.value);
                    //path = (result['@odata.nextLink']) ? result['@odata.nextLink'] : '';
                    if (result['@odata.nextLink'])
                        nextLink = result['@odata.nextLink'].substring(result['@odata.nextLink'].indexOf('&$skiptoken'));
                    else
                        nextLink = '';
                    batch++;
                    this.message = '';
                }
                console.log('File Loops Complete');
                this.message = '';
                this.isLoading = false;
            }

, async itemSelect (selectedRow) {
                if (selectedRow != null) {
                    console.log(selectedRow);
                    if (selectedRow.folder != null && selectedRow.folder.childCount) {
                        this.previousPath = this.path;
                        var prefix = (this.path != undefined && this.path != '') ? '/' : '';
                        this.path = prefix + this.path + prefix + selectedRow.name + '/children';
                        await this.fetchItems(this.path);
                    }
                    else if (!selectedRow.folder && !selectedRow.AttachmentId) {
                        this.attachmentToMap = []; //clear it if it was set before
                        this.selectedFileId = selectedRow.id;
                        this.showAttachmentEdit = true;
                        this.selectedFileProperties = selectedRow;
                    }
                    else if (selectedRow.AttachmentId) {
                        this.$notify.warning('File is already ingested');
                    }
                }
            }
            , async folderUp () {
                this.path = this.previousPath;
                if (this.previousPath.indexOf('/') > 0) {
                    paths = this.previousPath.split('/');
                    this.previousPath = paths[paths.length];
                }
                else {
                    this.previousPath = '';
                }

                await this.fetchItems(this.path);
            }
            , newAttachmentSaved: function (attachment, properties, originalFileId) {
                if (this.selectedStore.IsIngestionSource) { // remove from list if list is Ingestion
                    const item = this.getSelectedArrayItem(this.items, originalFileId, 'id') || this.getSelectedArrayItem(this.items, attachment.FileId, 'id')
                    const index = this.items.indexOf(item);
                    this.items.splice(index, 1);
                }
                this.mapAttachment(attachment, properties);
            }
            , mapAttachment(attachment, properties){
                this.showAttachmentEdit = false;
                this.attachmentToMap = attachment;
                this.attachmentToMapFileProperties = properties;
                this.$nextTick(function () {
                    if (this.$refs.refAttachmentMap) {//mounted will handle it the first time, when refs won't exist
                        this.$refs.refAttachmentMap.initialize();
                    }
                    this.showAttachmentMap = true;
                });

            }
            , attachmentMapped: function(){
                console.log('mapped');
                this.showAttachmentMap = false;
                this.selectedFileProperties = {};
                this.selectedFileId = null;
                this.attachmentToMap = [];
            }
            , editAttachment: function () {
                if (this.$refs.refAttachmentEdit) {
                    this.$refs.refAttachmentEdit.fetchAttachment();
                }
                this.showAttachmentMap = false;
                this.showAttachmentEdit = true;
            }
            , async fileDeleted (attachment) {
                this.showAttachmentEdit = false;
                this.showAttachmentMap = false;
                await this.fetchItems(this.path); // refetch rather than splice out because a file may have been "deleted" back into the current folder.
                this.selectedFileProperties = {};
            }
            , attachmentCanceled: function() {
                this.showAttachmentEdit = false;
                this.showAttachmentMap = false;
                this.selectedFileProperties = {};
                this.attachmentToMap = [];
                this.selectedFileId = null;
            }
            , documentUploaded(result){
                console.log('Uploaded', result);
                this.mapAttachment(result.attachment, result.file);
            }
            , uploadFailed(error){
                console.error(error);
            }
            //, fixFileId: function () { //one-off code to fix the file Ids that were incorrectly imported as eTags. keeping it here because it's a good example of promises. removing button to call it.
            //    console.log('Fix File Id');
            //    var el = this;
            //    var promises = [];
            //    for (let item of this.items) {
            //        console.log(item['@odata.etag'].substring(2, 38), item.id, el.selectedStore.Id);
            //        promises.push(el._fixFileId(el.selectedStore.Id, item['@odata.etag'].substring(2, 38), item.id));
            //    }
            //    Promise.all(promises)
            //        .then(() => {
            //            console.log('Done Fixing File Ids');
            //            el.mergeItemsAndAttachments(el.items, el.attachments);
            //        })
            //        .catch((e) => {
            //            console.error(e);
            //        });
            //}
            //, _fixFileId: function (storeId, eTag, fileId) {
            //    var el = this;
            //    return new Promise(function (resolve, reject) {
            //        el.attachment_FixFileId({
            //            StoreId: storeId
            //            , eTag: eTag
            //            , FileId: fileId
            //        }
            //            , function (data) {
            //                console.log(data, 'Ok');
            //                resolve('OK')
            //            }
            //            , function (error) {
            //                console.error(error);
            //                reject(error);
            //            });
            //    });
            //}
        }
    })
</script>
