
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch, Ref } from "vue-property-decorator";
import * as AssetService from "@/services/DAL/assetService";
import * as TransactionsService from "@/services/DAL/transactionsService";
import Common from "@/utils/common";
import InvestmentSelector from "@/components/form/InvestmentSelector.vue";
import HoldingEntitySelector from "@/components/form/HoldingEntitySelector.vue";
import formatters from "@/utils/formatters";
import ReturnIncomeTypeSelector from "@/components/form/ReturnIncomeTypeSelector.vue";
import cloneDeep from "lodash/cloneDeep";
import ElCurrencyInput from './components/form/ELCurrencyInput.vue';

import ElementUI from "element-ui";
import { ElMessageBoxOptions } from "element-ui/types/message-box";

declare var SmartObject: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
  components: {
    InvestmentSelector,
    HoldingEntitySelector,
    ReturnIncomeTypeSelector,
  },
})
export default class EquityEdit extends Vue {
  @Ref() readonly frmEquityEdit!: ElementUI.Form;
  @Ref() readonly tblAvailableEquity!: ElementUI.Table;
  @Ref() readonly tblAvailableEquitySpecific!: ElementUI.Table;
  @Ref() readonly acquisitionDate!: ElementUI.DatePicker;

  //#region Private declarations for Services
  private _transactionsService: TransactionsService.TransactionsService;
  private common: Common;
  public formatters: formatters;

  //#endregion Private declarations for Services

  //#region Props
  @Prop() readonly header: TransactionsService.Header;
  @Prop() readonly considerationType: TransactionsService.ConsiderationTypes;
  @Prop({ type: Object }) readonly transactionType: TransactionsService.Types;
  @Prop() readonly equity: TransactionsService.Equity;
  @Prop({ type: Number }) readonly newTransactionInvestmentId: number;

  //#endregion Props

  //#region Data
  equity_ = {} as TransactionsService.Equity;
  selectedInvestment = {} as AssetService.InvestmentList;
  securityLevel_: number = null;
  loading = false;
  isNew = false;
  considerationType_ = {} as TransactionsService.ConsiderationTypes;
  transactionType_ = {} as TransactionsService.Types;

  //#endregion Data

  //#region Lifecycle
  async created() {
    this._transactionsService = new TransactionsService.TransactionsService();
    this.formatters = new formatters();
    this.common = new Common();
    this.securityLevel_ = tryParseInt(
      getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
      0
    );
  }
  async mounted() {
    if (this.considerationType && Object.keys(this.considerationType).length) {
      this.considerationType_ = this.considerationType;
    }
    if (this.equity && Object.keys(this.equity).length
      && this.equity.Id) { // 10/4/24: ConsiderationEdit will now pass a cash object even for new items. EquityEdit is not yet handling that, so ignore the passed object if it's new.
      this.equity_ = this.equity;
      if (
        !this.considerationType ||
        Object.keys(this.considerationType).length == 0
      ) {
        this.considerationType_ = {} as TransactionsService.ConsiderationTypes;
        this.considerationType_.Direction = this.equity_.Direction;
        this.considerationType_.Name = this.equity_.ConsiderationType;
        this.considerationType_.Id = this.equity_.ConsiderationTypeId;
      }
    } else {
      this.equity_ = new TransactionsService.Equity();
      this.$nullifyObjectProps(this.equity_);
      this.equity_.TransactionHeaderId = this.header.Id;
      this.equity_.ConsiderationTypeId = this.considerationType.Id;
      this.equity_.ConsiderationType = this.considerationType.Name;
      this.equity_.ConsiderationDirection = this.considerationType.Direction;
      this.equity_.Direction = this.considerationType.Direction;
      this.equity_.OwnerId = this.header.BaseOwnerId; // might need logic to exclude this or use TargetOwnerId in some situations.
      this.equity_.InvestmentId = this.newTransactionInvestmentId;
      this.equity_.IsCarryShares = false;
      this.equity_.BasisReconciledToK1 = false;
      this.equity_.IsQSBS = false;
      
      this.isNew = true;
    }
    await this.handleTransactionType();
    
    // need to get TransactionType before thinking about setting the InvestmentId based on the Header.BaseInvestmentId
    if (this.isNew && !this.equity_.InvestmentId && this.header.BaseInvestmentId
      && !(this.transactionType_ && this.transactionType_.IsDistribution && this.equity_.Direction == 'Result')){ // Don't set the Equity Investment to that of the distributing Fund.
      this.equity_.InvestmentId = this.header.BaseInvestmentId;
    }
  }
  //#endregion Lifecycle

  //#region Watches

  //#endregion Watches
  get isFormDirty(): boolean {
    return Object.keys((this as any).veeFields).some(
      (key) => (this as any).veeFields[key].dirty
    );
  }
  get isFormValid(): boolean {
    return !Object.keys((this as any).veeFields).some(
      (key) => (this as any).veeFields[key].invalid
    );
  }
  get showInvestmentSelector(): boolean {
    if (this.isNew) return true;
    else return false;
  }
  get acquisitionCostPerShare() {
    if (
      this.equity_ &&
      this.equity_.Quantity &&
      this.equity_.Quantity != 0 &&
      this.equity_.AcquisitionCost &&
      this.equity_.AcquisitionCost != 0
    ) {
      return this.equity_.AcquisitionCost / this.equity_.Quantity;
    }
  }
  get amountPerShare() {
    if (
      this.equity_ &&
      this.equity_.Quantity &&
      this.equity_.Quantity != 0 &&
      this.equity_.Amount &&
      this.equity_.Amount != 0
    ) {
      return this.equity_.Amount / this.equity_.Quantity;
    }
  }
  get equityQuantityDisplay(): number {
    if (this.considerationType_.Direction == "Source") {
      return 0 - this.equity_.Quantity;
    } else {
      return this.equity_.Quantity;
    }
  }
  set equityQuantityDisplay(value: number) {
    if (this.considerationType_.Direction == "Source") {
      this.equity_.Quantity = 0 - value;
    } else {
      this.equity_.Quantity = value;
    }
  }

  //#region Methods
  async saveItem(addNew: boolean = false) {
    if (this.isFormValid) {
      this.equity_.ConsiderationDescription = `Equity: ${
        this.equity_.Investment
      } ${this.$accounting.toFixed(this.equity_.Quantity, 2)} ${
        this.equity_.Tranche
      } @ ${this.$accounting.formatMoney(
        this.equity_.Quantity != 0
          ? this.equity_.Amount / this.equity_.Quantity
          : 0
      )} (${this.$accounting.formatMoney(this.equity_.Amount)})`;
      const gainLoss = this.equity_.GainLoss;
      delete this.equity_.GainLoss; // GainLoss is a computed column
      if (this.isNew) {
        const id = await new SmartObject(
          "InvestmentTransactionEquity"
        ).createObject(this.equity_);
        this.equity_.EquityId = id;
        this.equity_.Id = id;
        this.equity_.Deletable = true;
        this.$notify.success("New Equity Item Added");
      } else {
        await new SmartObject(
          "InvestmentTransactionEquity",
          this.equity_.EquityId
        ).updateObject(this.equity_);
        this.$notify.success("Equity Item Updated");
      }
      if (this.equity_.Direction == 'Source' || (this.transactionType_.IsDistribution && this.equity_.Direction == 'Result')){
        this.equity_.ConsiderationValue = this.equity_.Amount;
      }
      else {
        this.equity_.ConsiderationValue = this.equity_.AcquisitionCost;
      }
      this.$emit("saved", this.equity_);
      if (addNew) {
        this.resetForNewTranche();
        this.acquisitionDate.focus();
      } else {
        this.$emit("close");
      }
    }
  }

  resetForNewTranche() {
    const prev: TransactionsService.Equity = cloneDeep(this.equity_);
    this.equity_ = new TransactionsService.Equity();
    this.$nullifyObjectProps(this.equity_);
    this.equity_.TransactionHeaderId = prev.TransactionHeaderId;
    this.equity_.ConsiderationTypeId = prev.ConsiderationTypeId;
    this.equity_.ConsiderationType = prev.ConsiderationType;
    this.equity_.ConsiderationDirection = prev.ConsiderationDirection;
    this.equity_.Direction = prev.Direction;
    this.equity_.InvestmentId = prev.InvestmentId;
    this.equity_.HoldingEntityId = prev.HoldingEntityId;
    this.equity_.HoldingEntity = prev.HoldingEntity;
    this.equity_.Tranche = prev.Tranche;
    this.equity_.AmountPerQuantity = prev.AmountPerQuantity;
    this.equity_.OwnerId = prev.OwnerId;
    this.equity_.Investment = prev.Investment;
    this.equity_.IsCarryShares = false;
    this.equity_.BasisReconciledToK1 = false;
    this.equity_.IsQSBS = false;
    this.equity_.AcquisitionCostPerQuantity = prev.AcquisitionCostPerQuantity
    this.equity_.AcquisitionCost = prev.AcquisitionCost;
    this.isNew = true;
  }

  clearItem() {
    this.equity_.Amount = null;
    this.equity_.InvestmentId = null;
    this.equity_.Quantity = null;
    this.equity_.Tranche = null;
    this.equity_.HoldingEntityId = null;
    this.equity_.Comment = null;
    this.equity_.ConsiderationDescription = null;
    this.$emit("clear");
  }

  async deleteItem() {
    if (!this.isNew) {
      try {
        await this.$confirm(
          "This will permanently delete this Equity item. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );

        await this.doDeleteTransactionEquity();
      } catch {}
    }
  }

  async doDeleteTransactionEquity() {
    try {
      await new SmartObject(
        "InvestmentTransactionEquity",
        this.equity_.EquityId
      ).deleteObject();

      this.$notify.success("Equity item deleted.");

      await new SmartObject("CommentLog").createObject({
        EntityType: "InvestmentTransactionHeader",
        EntityId: this.equity_.TransactionHeaderId,
        Comment: `Equity ${this.equity_.EquityId} Deleted.  (${
          this.considerationType_.Direction
        } ${
          this.equity_.ConsiderationDescription
        } ${this.$accounting.formatMoney(this.equity_.Amount)})`,
        SystemGenerated: true,
      });

      this.$notify.success("Investment Header Comment added.");

      this.$emit("deleted", this.equity_.EquityId);
      this.equity_ = {} as TransactionsService.Equity;
    } catch (err) {
      const errJson = JSON.parse(err.responseText);
      if (
        errJson.ExceptionMessage.toLowerCase().indexOf("same table reference") > -1
      ) {
        this.$alert(
          'This item cannot be deleted because it is the source of subsquent transactions.  Those transactions must be deleted first.'
          ,'Error'
          , {type: 'error'}
        );
      } else {
        this.$alert(
          'There was an error processing your request.  Please contact support with details from the console'
          ,'Error'
          , {type: 'error'}
        );
        console.error(errJson);
      }
    }
  }

  async investmentSelected(investment: AssetService.InvestmentList) {
    this.selectedInvestment = investment;
    this.equity_.Investment = investment.NameOwnerAccountTypeIdBank;
  }
  async fetchedHoldingEntitys() {
    await this.$validator.validate("Holding Entity"); // force validation because it's not happening automatically
  }
  async handleTransactionType() {
    this.transactionType_ = {} as TransactionsService.Types;
    if (
      this.transactionType &&
      Object.keys(this.transactionType).length &&
      this.transactionType.Id > 0
    ) {
      this.transactionType_ = this.transactionType;
    } else if (
      this.header &&
      Object.keys(this.header).length &&
      this.header.TypeId > 0
    ) {
      const params = {} as TransactionsService.GetTypesParameters;
      const types = await this._transactionsService.GetTypes(params);
      if (types && types.length) {
        this.transactionType_ = this.common.getSelectedArrayItem(
          types,
          this.header.TypeId.toString(),
          "Id"
        );
      }
    }
  }
  async promptPerShare(label: string, field: string) {
    const el = this;
    const conf: any = await this.$prompt(
      `Please enter ${label} Per Share`,
      `${label} Per Share`,
      {
        // MessageBoxData Type must be incorrectly defined, so declaring it as any
        confirmButtonText: "OK",
        showCancelButton: true,
        inputValue: this.equity_[field + "PerQuantity"],
        inputErrorMessage: `${label} is required and must be a positive number.`,
        inputValidator: function (input) {
          if (
            input &&
            el._isNumber(el._toNumber(input)) &&
            !isNaN(el._toNumber(input)) &&
            el._toNumber(input) > 0
          )
            return true;
          else return false;
        },
      }
    );
    this.equity_[field] = conf.value * this.equity_.Quantity;
    this.equity_[field + "PerQuantity"] = conf.value;
  }
  //#endregion Methods
}
