
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

declare var $: any;

@Component
export default class SmartControlLoader extends Vue {
    //#region Data
    @Prop() path: string;
    @Prop() controlName: string;
    //#endregion Data

    //#region Lifecycle
    mounted() {
        this.loadControl();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('path')
    onPathChanged(val: string, oldVal: string) {
        this.loadControl();
    }
    @Watch('controlName')
    onControlNameChanged(val: string, oldVal: string) {
        this.loadControl();
    }
    //#endregion Watches

    //#region Methods
    loadControl() {
        const query = this.$route.query;
        var params = {};
        if (query && query.params) {
            try {
                const params_: string = query.params as string; // bug/change introduced in router 3.0.2: https://github.com/vuejs/vue-router/pull/2050#issuecomment-441797165
                params = JSON.parse(params_);
            } catch {
                params = {};
            }
        }

        $('#ucMainContent').fetchSmartControl({
            path: this.path,
            controlName: this.controlName,
            // params: query && query.params ? query.params : {},
            params: params,
            loadType: query && query.loadType ? query.loadType : undefined,
            title: query && query.title ? query.title : undefined
        });
    }
    //#endregion Methods
}
