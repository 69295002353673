var mixinUtility = {
  methods: {
    getSecurityUserId: function() {
      var so = new SmartObject("SmartObject");
      var securityUserId = so.invokeMethod("GetSecurityUserId");
      return securityUserId;
    },
    propertySum: function (items, prop) {
    if (items == null) {
      return 0;
    }
    return items.reduce(function (a, b) {
      return b[prop] == null ? a : a + b[prop];
    }, 0);
    },
    getDDLOptionsWithGroup: function(
      ds,
      groupByLabel,
      groupById,
      itemLabel,
      itemId
    ) {
      var options = [];
      var group = "";
      var groupItem = {};
      if (ds && ds.length > 0) {
        ds.forEach(function(row) {
          if (group != row[groupByLabel]) {
            groupItem = {
              label: row[groupByLabel],
              groupId: row[groupById],
              options: []
            };
            options.push(groupItem);
            group = row[groupByLabel];
          }
          var item = {
            value: row[itemId],
            label: row[itemLabel]
          };
          groupItem.options.push(item);
        });
        return options;
      } else {
        return [];
      }
    },
    /**
     * Search up the hierarchy of parents for a particular element. will match the supplied name
     * @param {string} vueEl - this.$el
     * @param {string} elementName - Element Name to search for
     */
    searchParentsForElement(vueEl, elementName) {
      if (vueEl.parentElement) {
        if (vueEl.parentElement.id == elementName) {
          // || vueEl.parentElement.id == elementName + '_Content') {
          return vueEl.parentElement;
        } else
          return this.searchParentsForElement(vueEl.parentElement, elementName);
      } else return null;
    },
    getCascaderHierarchy(inputArray, idField, nameField, parentIdField, disableSelectionField = 'DisableSelection') {
      const id = idField || "Id";
      const name = nameField || "Name";
      const parentId = parentIdField || "ParentId";
      var outArray = [];
      const disableSelection = disableSelectionField;
      var t = this; //"this" doesn't make sense inside the .forEach
      inputArray.forEach(function(input) {
        var outObject = {
          value: input[id],
          label: input[name],
          disabled: input[disableSelection]
        };
        if (input.TreeLevel == 0) {
          //must have "TreeLevel" column
          outArray.push(outObject);
        } else {
          var parent = t.getParentObjectFromArray(outArray, input[parentId]);
          if (parent.children != undefined) {
            parent.children.push(outObject);
          } else {
            parent.children = [outObject];
          }
        }
      });
      return outArray;
    },
    getSelectedArrayItem: function(array, Id, IdName) {
      if (array && array.length > 0) {
        function getItem(array, Id, IdName) {
          return array.filter(function(arrayItem) {
            return arrayItem[IdName] == Id;
          });
        }
        var item = getItem(array, Id, IdName);
        return item[0];
      } else {
        return undefined;
      }
    },
    getSelectedName(inArray, Id, IdName, NameKey) {
      const obj = this.getSelectedArrayItem(
        inArray,
        Id,
        IdName
      );
      if (!!obj && Object.keys(obj).length > 0) {
        return obj[NameKey];
      }
      else {
        return undefined;
      }
    },
    getParentObjectFromArray: function(input, parentId) {
      var result = null;
      if (input instanceof Array) {
        for (var i = 0; i < input.length; i++) {
          result = this.getParentObjectFromArray(input[i], parentId);
        }
      } else {
        for (var prop in input) {
          if (prop == "value") {
            if (input[prop] == parentId) {
              return input;
            }
          }
          if (input[prop] instanceof Object || input[prop] instanceof Array)
            result = this.getParentObjectFromArray(input[prop], parentId);
        }
      }
      return result;
    },
    deleteSelectedArrayItem: function(array, Id, IdName) {
      for (i = array.length - 1; i >= 0; i--) {
        if (array[i][IdName] == Id) array.splice(i, 1);
      }
    },
    arrayItemSum: function(items, prop) {
      return items.reduce(function(a, b) {
        return b[prop] == null
          ? a
          : tryParseFloat(a, 0) + tryParseFloat(b[prop], 0);
      }, 0);
    },

    HideIfColumnEmpty: function(arrayOfObjects, columnName) {
      var columnHasData = false;
      for (var j = 0; j < arrayOfObjects.length; j++) {
        //need to check for actual false value
        if (arrayOfObjects[j][columnName] === false || !!arrayOfObjects[j][columnName]) {
          columnHasData = true;
          return columnHasData;
        }
      }
      return columnHasData;
    },
    /**
     *
     * @param items An array of items.
     * @param fn A function that accepts an item from the array and returns a promise.
     * @returns {Promise}
     */
    forEachPromise: function(items, fn, context) {
      return items.reduce(function(promise, item) {
        return promise.then(function() {
          return fn(item, context);
        });
      }, Promise.resolve());
    },

    getSummaryArray: function(param, columnList, totalLabel, totalLabelIndex) {
      const { columns, data } = param;
      //console.log(param, columns, data, columnList);
      const sums = [];
      columns.forEach((column, index) => {
        if (index === totalLabelIndex || 0) {
          sums[index] = totalLabel || "Total";
          return;
        }
        if (
          this._findIndex(columnList, function(o) {
            return o == index;
          }) < 0
        ) {
          sums[index] = "";
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = smartFormat(
            values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value) && value != 0) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0),
            "$,.2"
          );
          if (sums[index].indexOf("-$") > -1) {
            sums[index] = sums[index].replace("-", "(") + ")";
          }
        } else {
          sums[index] = "";
        }
        return;
      });
      return sums;
    },
    formatterShortDate(row, column) {
      if (row[column.property]) {
        return moment(row[column.property]).format("MM/DD/YY");
      } else {
        return "";
      }
    },
    formatterShortDateTime(row, column) {
      if (row[column.property]) {
        return moment(row[column.property]).format("MM/DD/YY H:mm");
      } else {
        return "";
      }
    },
    formatterCurrency(row, column) {
      var out = "";
      out = row[column.property] == null ? null : accounting.formatMoney(row[column.property]);
      return out;
    },
    formatterCurrency0(row, column) {
      var out = "";
      out = row[column.property] == null ? null : accounting.formatMoney(row[column.property], {precision: 0});
      return out;
    },
    formatterPercent2(row, column) {
      return row[column.property] == null
        ? null
        : smartFormat(row[column.property], "%,.2");
    },
    formatterNumberGeneric(row, column) {
      if (
        this._isNumber(this._toNumber(row[column.property])) &&
        !isNaN(this._toNumber(row[column.property]))
      ) {
        //this._toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
        return numbro(this._toNumber(row[column.property])).format({
          thousandSeparated: true
        });
      } else {
        return null;
      }
    },
    formatterNumber0(row, column) {
      if (
        this._isNumber(this._toNumber(row[column.property])) &&
        !isNaN(this._toNumber(row[column.property]))
      ) {
        //this._toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
        return accounting.toFixed(row[column.property], 0);
      } else {
        return null;
      }
    },
    formatterNumber1(row, column) {
      if (
        this._isNumber(this._toNumber(row[column.property])) &&
        !isNaN(this._toNumber(row[column.property]))
      ) {
        //this._toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
        return accounting.toFixed(row[column.property], 1);
      } else {
        return null;
      }
    },
    formatterNumber2(row, column) {
      if (
        this._isNumber(this._toNumber(row[column.property])) &&
        !isNaN(this._toNumber(row[column.property]))
      ) {
        // this._toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
        return accounting.toFixed(row[column.property], 2);
      } else {
        return null;
      }
    },
    formatterNumber3(row, column) {
      if (
        this._isNumber(this._toNumber(row[column.property])) &&
        !isNaN(this._toNumber(row[column.property]))
      ) {
        //this._toNumber returns NaN for non-numbers, and isNumbers returns true for NaN, so have to check for NaN separately
        return accounting.toFixed(row[column.property], 3);
      } else {
        return null;
      }
    }
  }
};
export default mixinUtility;

//# sourceURL=Utility.js
