
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
import formatters from '../../utils/formatters';
import EntityEdit from '@/views/Entity/EntityEdit.vue';
import EntityEditView from '@/views/Entity/EntityEditView.vue';
import ClientSelector from '@/components/form/ClientSelector.vue';
import * as VamService from './../../services/DAL/vamService';
import { UserClient } from './../../models/Common/UserClient';
import Common from '../../utils/common';
import ElementUI from 'element-ui';

declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: number, deflt: number): number;

@Component({
    components: {
        EntityEdit,
        EntityEditView,
        ClientSelector
    }
})
export default class EntityList extends Vue {
    $refs: {
        tblEntityList: ElementUI.Table
    }
    //#region Data
    private _vamService: VamService.VamService;
    private _common: Common;
    public formatters: formatters = new formatters();

    @Prop() initialEntityId: number;

    owners: VamService.OwnersV2[] = [];
    filter: string = '';
    selectedClientId = null;
    selectedOwner = null;
    showEntityEditView = false;
    showEntityCreate = false;
    securityLevel: number = 0;
    isLoading = false;
    //#endregion Data

    //#region Computed
    get showDetailColumns(): boolean {
        return (
            ['xs', 'sm', 'md'].indexOf(this.$getBootstrapSize()) > 0 ||
            !this.showEntityEditView
        );
    }

    get ownersFiltered(): VamService.OwnersV2[] {
        // let filtered: VamService.OwnersV2[] = [];
        const filtered = this.owners.filter(owner => {
            const filter_ = (this.filter || '').toLowerCase();
            return (
                (owner.ClientName || '').toLowerCase().indexOf(filter_) > -1 ||
                (owner.Name || '').toLowerCase().indexOf(filter_) > -1 ||
                (owner.OwnerCategoryName || '').toLowerCase().indexOf(filter_) >
                    -1 ||
                (owner.TaxClassName || '').toLowerCase().indexOf(filter_) >
                    -1 ||
                (owner.TaxPayerName || '').toLowerCase().indexOf(filter_) >
                    -1 ||
                (owner.State || '').toLowerCase().indexOf(filter_) > -1 ||
                (owner.TaxId || '').toLowerCase().indexOf(filter_) > -1 ||
                (owner.MailingAddressFull || '')
                    .toLowerCase()
                    .indexOf(filter_) > -1 ||
                (owner.PhysicalAddressFull || '')
                    .toLowerCase()
                    .indexOf(filter_) > -1 ||
                moment(owner.FoundedDate || '')
                    .format('MM/DD/YY')
                    .indexOf(filter_) > -1 ||
                moment(owner.DissolvedDate || '')
                    .format('MM/DD/YY')
                    .indexOf(filter_) > -1
            );
        });
        return filtered;
    }
    //#endregion Computed

    //#region Lifecycle
    async created() {
        this._vamService = new VamService.VamService();
        this._common = new Common();
        this.formatters = new formatters();

        this.securityLevel = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageOwners),
            0
        );
    }

    async mounted() {
        await this.fetchOwners();

        if (this.initialEntityId > 0) {
            const initialEntity: VamService.OwnersV2 = this._common.getSelectedArrayItem(
                this.owners,
                this.initialEntityId.toString(),
                'Id'
            );
            this.selectOwner(initialEntity);
        }
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('selectedClientId')
    onClientIdChanged(val: string, oldVal: string) {
        console.log('EntityList onClientIdChanged', oldVal, val)
        this.fetchOwners();
    }
    //#endregion Watches

    //#region Methods
    // clearSearch() {
    //   this.selectedClientId = null;
    // }

    selectOwner(owner) {
        if (owner) {
            this.showEntityEditView = false;
            this.$nextTick(() => {
                this.showEntityEditView = true;
                this.selectedOwner = owner;
            });
        }
    }

    closeOwner() {
        this.showEntityEditView = false;
        this.selectedOwner = null;
        this.$refs.tblEntityList.setCurrentRow(null);
    }

    onDeleteOwner() {
        this.closeOwner();
        this.fetchOwners();
    }

    async fetchOwners() {
        this.isLoading = true;
        const getOwnersParameters = {} as VamService.GetOwnersV2Parameters;
        getOwnersParameters.ClientId = this.selectedClientId || null;
        this.owners = await this._vamService.GetOwnersV2(getOwnersParameters);
        this.isLoading = false;
    }
    //#endregion Methods
}
