
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as TransactionsService from '../../services/DAL/transactionsService';
import Tabulator from "tabulator-tables"; 
import * as specificLotTypes from '@/views/InvestmentTransaction/specificLot';
import SpecificLotEdit from '@/views/InvestmentTransaction/SpecificLotEdit.vue';

import "tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css"; 

declare var SmartObject: any;

@Component({
    components: {
        SpecificLotEdit
    }
})
export default class SpecificLotEditView extends Vue {
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    //#endregion Private declarations for Services

    //#region Props
    @Prop({required: true}) transactionId: number;
    @Prop() consumerTransaction: TransactionsService.Transactions;
    //#endregion Props

    //#region Data
    specificLotTransactions = [] as TransactionsService.SpecificLotTransactions[]; 
    availableSpecificLotTransactions = [] as TransactionsService.AvailableSpecificLotTransactions[]; 
    consumerTransaction_ = {} as TransactionsService.Transactions;
    tabulator: any = null; 
    tabulatorAvailable: any = null; 
    showSpecificLotEdit: boolean = false;
    selectedSpecificLot = {} as specificLotTypes.SpecificLot;
    loading = false;
    //#endregion Data

    //#region Lifecycle
    created() {
        this._transactionsService = new TransactionsService.TransactionsService();
    }
    async mounted() {
        if (!!this.consumerTransaction && !!this.consumerTransaction.Id){
            this.consumerTransaction_ = this.consumerTransaction;
        }
        else {
            this.fetchTransaction();
        }
        await this.refreshAllLotData();
        const editIcon = function(cell, formatterParams){ //plain text value
            return "<i class='fa fa-edit'></i>";
        };
        this.tabulator = new Tabulator(this.$refs.table, {
            height:'auto',
            data: this.specificLotTransactions, //link data to table
            placeholder:"No Lots Consumed",
            columnCalcs: "bottom",
            columns: [
                { field: 'FromTransactionId'
                    , title: 'Transaction'
                    , hozAlign: 'center'
                },
                { field: 'FromTransactionDate'
                    , title: 'Acq Date'
                    , hozAlign: 'center'
                    , formatter:"datetime"
                    , formatterParams:{
                        outputFormat:"MM/DD/YY",
                        invalidPlaceholder:"(invalid)",
                    }
                    , sorter:"date"
                    , sorterParams:{
                        format:"MM/DD/YY",
                        alignEmptyValues:"top",
                    }                
                },
                { field: 'OriginalShares', title: 'Shares Purchased'
                    , hozAlign: 'right'
                    , formatter:"money"
                    , formatterParams:{
                        decimal:".",
                        thousand:",",
                        precision:0,
                    } },
                { field: 'PurchaseAmount', title: 'Total Cost', hozAlign: 'right', formatter:"money", formatterParams:{
                        decimal:".",
                        thousand:",",
                        symbol:"$",
                        precision:2,
                    } },
                { field: 'CostPerShare', title: 'Tax Lot', hozAlign: 'right', formatter:"money", formatterParams:{
                        decimal:".",
                        thousand:",",
                        symbol:"$",
                        precision:2,
                    }  },
                { field: 'Cost'
                    , width: 125
                    , title: 'Cost'
                    , hozAlign: 'right'
                    , bottomCalc:"sum"
                    , bottomCalcFormatter: "money"
                    , bottomCalcFormatterParams:{
                        decimal:".",
                        thousand:",",
                        symbol:"$",
                        precision:2,
                    }
                    , formatter:"money"
                    , formatterParams:{
                        decimal:".",
                        thousand:",",
                        symbol:"$",
                        precision:2,
                    }
                },
                { field: 'ConsumedShares'
                    , title: 'Shares Out'
                    , hozAlign: 'right'
                    , bottomCalc:"sum"
                    , bottomCalcFormatter: "money"
                    , bottomCalcFormatterParams:{
                        decimal:".",
                        thousand:",",
                        precision:2,
                    }
                    , formatter:"money"
                    , formatterParams:{
                        decimal:".",
                        thousand:",",
                        precision:2,
                        }
                },
                { field: 'SaleProceeds'
                    , title: 'Sale Proceeds'
                    , hozAlign: 'right'
                    , bottomCalc:"sum"
                    , bottomCalcFormatter: "money"
                    , bottomCalcFormatterParams:{
                        decimal:".",
                        thousand:",",
                        symbol:"$",
                        precision:2,
                    }
                    , formatter:"money"
                    , formatterParams:{
                        decimal:".",
                        thousand:",",
                        symbol:"$",
                        precision:2,
                    }    },
                { field: 'GainLoss'
                    , title: 'Gain/(Loss)'
                    , hozAlign: 'right'
                    , bottomCalc:"sum"
                    , bottomCalcFormatter: "money"
                    , bottomCalcFormatterParams:{
                        decimal:".",
                        thousand:",",
                        symbol:"$",
                        precision:2,
                    }
                    , formatter:"money"
                    , formatterParams:{
                        decimal:".",
                        thousand:",",
                        symbol:"$",
                        precision:2,
                    }
                },
                { field: 'CarryShares', title: 'Carry Shares', hozAlign: 'center', formatter:"tickCross", formatterParams:{
                        allowEmpty:true,
                        allowTruthy:true,
                        tickElement:"<i class='fa fa-check'></i>",
                        crossElement:false,
                    } },
                { 
                    field: 'STLT'
                    , title: 'ST / LT'
                    , hozAlign: 'center'
                    , tooltip: function(cell){
                        return `Days Held: ${cell.getData().DaysHeld.toString()}`;
                    }
                },
                {title: 'Edit', headerSort:false, formatter:editIcon, hozAlign:"center", cellClick:function(e, cell){el.editSpecificLot(cell);}}

            ], //define table columns
        });
        const selectIcon = function(cell, formatterParams){ //plain text value
            if (!cell.getData().Selectable || !el.showRequiredAnalysis) return '';
            return "<i class='fa fa-square-o'></i>";
        };
        const el = this;
        this.tabulatorAvailable = new Tabulator(this.$refs.availableLots, {
            height:'auto',
            data: this.availableSpecificLotTransactions, //link data to table
            placeholder:"No Lots Available",
            columnCalcs: "bottom",
            columns: [
                { field: 'FromTransactionId'
                    , title: 'Transaction'
                    , hozAlign: 'center'
                },
                { field: 'FromTransactionDate'
                    , title: 'Acq Date'
                    , hozAlign: 'center'
                    , formatter:"datetime"
                    , formatterParams:{
                        outputFormat:"MM/DD/YY",
                        invalidPlaceholder:"(invalid)",
                    } 
                    , sorter:"date"
                    , sorterParams:{
                        format:"MM/DD/YY",
                        alignEmptyValues:"top",
                    }                
                },
                { field: 'OriginalShares'
                    , title: 'Shares Purchased'
                    , hozAlign: 'right'
                    , formatter:"money"
                    , formatterParams:{
                        decimal:".",
                        thousand:",",
                        precision:0,
                    } },
                { field: 'PurchaseAmount', title: 'Total Cost', hozAlign: 'right', formatter:"money", formatterParams:{
                        decimal:".",
                        thousand:",",
                        symbol:"$",
                        precision:2,
                    } },
                { field: 'CostPerShare', title: 'Tax Lot', hozAlign: 'right', formatter:"money", formatterParams:{
                        decimal:".",
                        thousand:",",
                        symbol:"$",
                        precision:2,
                    }  },
                { field: 'ConsumedShares'
                    , title: 'Shares Out'
                    , hozAlign: 'right'
                    , formatter:"money"
                    , formatterParams:{
                        decimal:".",
                        thousand:",",
                        precision:2,
                        }
                },
                { field: 'ShareBalance'
                    , title: 'Available Shares'
                    , hozAlign: 'right'
                    , bottomCalc:"sum"
                    , bottomCalcFormatter: "money"
                    , bottomCalcFormatterParams:{
                        decimal:".",
                        thousand:",",
                        precision:2,
                    }
                    , formatter:"money"
                    , formatterParams:{
                        decimal:".",
                        thousand:",",
                        precision:2,
                        }
                },
                // { field: 'GainLoss'
                //     , title: 'Gain/(Loss)'
                //     , hozAlign: 'right'
                //     , bottomCalc:"sum"
                //     , bottomCalcFormatter: "money"
                //     , bottomCalcFormatterParams:{
                //         decimal:".",
                //         thousand:",",
                //         symbol:"$",
                //         precision:2,
                //     }
                //     , formatter:"money"
                //     , formatterParams:{
                //         decimal:".",
                //         thousand:",",
                //         symbol:"$",
                //         precision:2,
                //     }
                // },
                { field: 'CarryShares', title: 'Carry Shares', hozAlign: 'center', formatter:"tickCross", formatterParams:{
                        allowEmpty:true,
                        allowTruthy:true,
                        tickElement:"<i class='fa fa-check'></i>",
                        crossElement:false,
                    } },
                { 
                    field: 'STLT'
                    , title: 'ST / LT'
                    , hozAlign: 'center'
                    , tooltip: function(cell){
                        return `Days Held: ${cell.getData().DaysHeld.toString()}`;
                    }
                },
                {title: 'PICK', headerSort:false, formatter:selectIcon, hozAlign:"center", cellClick:function(e, cell){el.pickSpecificLot(cell);}}
            ], //define table columns
        });
    }
    //#endregion Lifecycle

    //#region Computed
    get showRequiredAnalysis(): boolean {
        let tots: any = {};
        if (!!this.tabulator && !!this.tabulator.getCalcResults()){
            tots = this.tabulator.getCalcResults();
        }
        if (!tots || !Object.keys(tots).length){
            return false;
        }
        return (
            !!this.consumerTransaction_ && this.consumerTransaction_.Shares && (tots.bottom.ConsumedShares != this.consumerTransaction_.Shares )
        );
    }
    //#endregion Computed

    //#region Watches
    @Watch('specificLotTransactions', { deep: true })
    specificLotTransactionsChanged(val: any, oldVal: any) {
        if (this.tabulator){
            this.tabulator.replaceData(val);
        }
    }
    @Watch('availableSpecificLotTransactions', { deep: true })
    availableSpecificLotTransactionsChanged(val: any, oldVal: any) {
        if (this.tabulatorAvailable){
            this.tabulatorAvailable.replaceData(val);
        }
    }
    @Watch('showRequiredAnalysis', { deep: true })
    showRequiredAnalysisChanged(val: any, oldVal: any) {
        if (this.tabulatorAvailable){
            this.tabulatorAvailable.replaceData(this.availableSpecificLotTransactions);
        }
    }
    //#endregion Watches

    //#region Methods
    async fetchTransaction(){
        if (this.transactionId == null){
            console.error('TransactionId is null');
            return;
        }
        const params = {} as TransactionsService.GetTransactionsParameters;
        params.TransactionId = this.transactionId;
        this.consumerTransaction_ = (await this._transactionsService.GetTransactions(params))[0];
    }
    async refreshAllLotData(){
        this.loading = true;
        await Promise.all([
            this.fetchSpecificLotTransactions(),
            this.fetchAvailableSpecificLotTransactions()
        ]);
        this.loading = false;
    }
    async fetchSpecificLotTransactions(){
        const params = {} as TransactionsService.GetSpecificLotTransactionsParameters;
        params.TransactionId = this.transactionId;
        this.specificLotTransactions = await this._transactionsService.GetSpecificLotTransactions(params);
    }
    async fetchAvailableSpecificLotTransactions(){
        const params = {} as TransactionsService.GetAvailableSpecificLotTransactionsParameters;
        params.TransactionId = this.transactionId;
        this.availableSpecificLotTransactions = await this._transactionsService.GetAvailableSpecificLotTransactions(params);
    }
    async editSpecificLot(cell: any){
        const data = cell.getData();
        this.tabulatorAvailable.selectRow(cell.getRow());
        this.selectedSpecificLot = new specificLotTypes.SpecificLot();
        await this.$nextTick(); //reset previous selection
        this.selectedSpecificLot.Id = data.SpecificLotId;
        this.selectedSpecificLot.ConsumerTransactionId = data.ConsumerTransactionId;
        this.selectedSpecificLot.FromTransactionId = data.FromTransactionId;
        this.selectedSpecificLot.Shares = data.SpecificLotShares;
        this.showSpecificLotEdit = true;
    }
    async pickSpecificLot(cell: any){
        if (!cell.getData().Selectable || !this.showRequiredAnalysis) return; // don't do anything if not selectable or consumption is satisfied

        const data = cell.getData();
        this.tabulatorAvailable.selectRow(cell.getRow());
        this.selectedSpecificLot = null;
        await this.$nextTick(); //reset previous selection
        this.selectedSpecificLot = new specificLotTypes.SpecificLot();
        this.selectedSpecificLot.Id = -1;
        this.selectedSpecificLot.ConsumerTransactionId = this.transactionId;
        this.selectedSpecificLot.FromTransactionId = data.FromTransactionId;
        this.selectedSpecificLot.Shares = data.ShareBalance;
        this.showSpecificLotEdit = true;
    }
    cancelledSpecificLotEdit(){
        this.showSpecificLotEdit = false;
        this.selectedSpecificLot = new specificLotTypes.SpecificLot();
    }
    async savedSpecificLotEdit(specificLot: specificLotTypes.SpecificLot){
        this.showSpecificLotEdit = false;
        await this.refreshAllLotData();
    }
    close(){
        this.$emit('close', this.tabulator.getCalcResults().bottom.Cost);
    }
    async deletedSpecificLotEdit(specificLot: specificLotTypes.SpecificLot){
        this.showSpecificLotEdit = false;
        await this.refreshAllLotData();
    }

    //#endregion Methods
}
