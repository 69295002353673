
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';
import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';
import trimEnd from 'lodash/trimEnd';

import MicrosoftGraphClient, {
  GraphRequest,
  ResponseType,
  Client
} from '@microsoft/microsoft-graph-client';

declare var SmartObject: any;
declare function tryParseInt(input: number, deflt: number): number;
declare function getStoredSecurityLevel(Id: number): number;

@Component
export default class DriveItemFromUrl extends Vue {
  //#region Private declarations for Services
  private _graphService: MicrosoftGraphService;

  //#endregion Private declarations for Services

  //#region Props
  //#endregion Props

  //#region Data
  securityLevel_: number = null;
  url: string = '';
  isLoading: boolean = false;
  driveItem = {} as MicrosoftGraphTypes.DriveItem;
  
  //#endregion Data

  //#endregion Computed

  //#endregion Computed

  //#region Lifecycle
  async created() {
    this._graphService = new MicrosoftGraphService();
    // this._common = new Common.default();

    if (this.securityLevel_ === null) {
      this.securityLevel_ = tryParseInt(
        getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDocuments),
        0
      );
    }

  }
  //#endregion Lifecycle
  //#region Watches

//#endregion Watches
  //#region Methods

  async fetchDriveItemforUrl() {
    try {
      if (
        this.url
      ) {
        this.isLoading = true;
        const encodedUrl = trimEnd('u!' + btoa(this.url).replace('/', '_').replace('+','-'), '=');
        
        this.driveItem = await this.getDriveItem(
          `shares/${encodedUrl}/driveitem`
        );
        this.isLoading = false;
        this.$emit('drive-item', this.driveItem);
      }
    } catch (err) {
      this.isLoading = false;
      this.$emit('error', err);
    }
  }

  async getDriveItem(driveItem: string): Promise<MicrosoftGraphTypes.DriveItem> {
    if (driveItem) {
      const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
      const res = await graphClient.api(driveItem).get();
      return res;
    } else return {} as MicrosoftGraphTypes.DriveItem;
  }
  //#endregion Methods
}
