
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';
import DriveItemFromUrl from '@/views/Attachments/DriveItemFromUrl.vue';
import AttachmentEdit from '@/views/Attachments/AttachmentEditV3.vue';
import AttachmentMapList from '@/js/Attachments/AttachmentMapList.vue';
import * as AttachmentService from '../../services/DAL/attachmentService';
import formatters from '../../utils/formatters';
import Common from '../../utils/common';

declare function tryParseInt(input: number, deflt: number): number;
declare function getStoredSecurityLevel(Id: number): number;

@Component({
  components: {
    DriveItemFromUrl,
    AttachmentMapList,
    AttachmentEdit
  }
})
export default class DocumentUseList extends Vue {
  //#region Private declarations for Services
  private _attachmentService: AttachmentService.AttachmentService;
  private formatters: formatters = new formatters();
  private _common: Common;

  //#endregion Private declarations for Services

  //#region Props
  //#endregion Props

  //#region Data
  securityLevel_: number = null;

  message: string = '';
  isLoading: boolean = false;
  driveItem = {} as MicrosoftGraphTypes.DriveItem;
  attachments = [] as AttachmentService.Attachment[];
  showAttachmentEdit = false;
  attachmentToEdit = {} as AttachmentService.Attachment;
  attachmentToEditStore = {} as AttachmentService.Stores;

  //#endregion Data

  get driveItemFound() {
    return !!this.driveItem && Object.keys(this.driveItem).length > 0;
  }
  get driveItemIsFolder() {
    return this.driveItemFound && !!this.driveItem.folder;
  }
  //#endregion Computed

  //#endregion Computed

  //#region Lifecycle
  async created() {
    this._attachmentService = new AttachmentService.AttachmentService();
    this.formatters = new formatters();
    this._common = new Common();

    if (this.securityLevel_ === null) {
      this.securityLevel_ = tryParseInt(
        getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDocuments),
        0
      );
    }
  }
  //#endregion Lifecycle
  //#region Watches
  //#endregion Watches
  //#region Methods
  async onDriveItem(driveItem: MicrosoftGraphTypes.DriveItem) {
    this.attachments = [] as AttachmentService.Attachment[];
    this.driveItem = driveItem;
    if (this.driveItemFound){
      this.fetchAttachments();
    }
  }
  async onDriveItemError(error) {
    this.driveItem = {} as MicrosoftGraphTypes.DriveItem;
    this.$alert(error);
  }
  async fetchAttachments(){
    const parms = {} as AttachmentService.GetAttachmentParameters;
    parms.FileId = this.driveItem.id;
    this.attachments = await this._attachmentService.GetAttachment(parms);
  }
  attachmentSaved() {
      this.showAttachmentEdit = false;
  }
  async editAttachment(attachment) {
      // if (this.$refs.refAttachmentEdit) {
      //     this.$refs.refAttachmentEdit.fetchAttachment();
      // }
      this.attachmentToEdit = attachment;
      const parms = {} as AttachmentService.GetStoresParameters;
      parms.StoreId = attachment.StoreId;
      this.isLoading = true;
      const stores = await this._attachmentService.GetStores(parms);
      this.isLoading = false;
      if (!!stores && stores.length == 1){
        this.attachmentToEditStore = stores[0];
        this.showAttachmentEdit = true;
      }
      else {
        this.$alert('unable to get Store for Document');
      }
      
  }
  attachmentDeleted(attachment) {
      this.showAttachmentEdit = false;
      var index = this.attachments.indexOf(this._common.getSelectedArrayItem(this.attachments, attachment.Id, 'Id'));
      if (index > -1) {
          this.attachments.splice(index, 1);
      }
  }

  //#endregion Methods
}
