
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import FormattedInput from '@/components/form/FormattedInput.vue';
import ElementUI from 'element-ui';

declare var SmartObject: any;

@Component({
    components: {
        FormattedInput
    }
})
export default class FeePayingCommitmentEdit extends Vue {
    $refs: {
        frmFeePayingCommitmentEdit: ElementUI.Form
    }
    //#region Data
    private _assetService: AssetService.AssetService;

    @Prop() feePayingCommitment: AssetService.FeePayingCommitments;
    @Prop() investmentParentId: number;
    @Prop() viewType: string;

    feePayingCommitment_: AssetService.FeePayingCommitments = new AssetService.FeePayingCommitments();
    isLoading: boolean = false;
    validateEndDate: Function = (rule, value, callback) => {
        if (value < this.feePayingCommitment_.StartDate) {
            callback(new Error('End Date cannot be before Start Date'));
        }
        else {
            callback();
        }
    };
    //#endregion Data

    //#region Lifecycle
    created() {
        if (this.feePayingCommitment && Object.keys(this.feePayingCommitment).length) {
            this.feePayingCommitment_ = this.feePayingCommitment;
        }
        this._assetService = new AssetService.AssetService();
    }
    //#endregion Lifecycle

    //#region Computed
    get isNew(): boolean {
        return (
            !this.feePayingCommitment_ ||
            !Object.keys(this.feePayingCommitment_).length ||
            (this.feePayingCommitment_.Id || 0) <= 0
        );
    }
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].dirty
        );
    }
    //#endregion Computed

    //#region Methods
    async saveForm() {
        this.$refs.frmFeePayingCommitmentEdit.validate(async valid => {
            if (valid) {
                let message: string;
                const isNew = this.isNew;
                this.isLoading = true;

                try {
                    if (isNew) {
                        this.feePayingCommitment_.InvestmentParentId = this.investmentParentId;
                        this.feePayingCommitment_.Id = await new SmartObject('FundFeePayingCommitment').createObject(this.feePayingCommitment_);
                        message = 'Fee paying commitment added.';
                    } else {
                        await new SmartObject('FundFeePayingCommitment', this.feePayingCommitment_.Id).updateObject(this.feePayingCommitment_);
                        message = 'Changes saved.';
                    }
                }
                catch (err) {
                    try {
                        const errJson = JSON.parse(err.responseText);
                        if (errJson.ExceptionMessage.toLowerCase().indexOf('daterange') > -1) {
                            this.$notify.error('The selected date range overlaps the date range of an existing fee paying commitment of this investment parent. Please change the date range and try again.');
                        }
                        else {
                            this.$notify.error('Something went wrong processing your request, please try again.');
                        }
                    }
                    catch {
                        this.$notify.error('Something went wrong processing your request, please try again.');
                    }
                    this.isLoading = false;
                    return;
                }

                this.$notify.success(message);
                this.isLoading = false;

                this.$emit('save', this.feePayingCommitment_.Id);
                this.$emit('update:feePayingCommitment', this.feePayingCommitment_);

                if (isNew) {
                    this.$emit('close');
                }
            }
        });
    }

    async deleteFeePayingCommitment() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this fee paying commitment. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                try {
                    this.isLoading = true;
                    await new SmartObject('FundFeePayingCommitment', this.feePayingCommitment_.Id).deleteObject();

                    this.$notify.success('Fee paying commitment deleted.');
                    this.isLoading = false;

                    this.$emit('delete', this.feePayingCommitment_.Id);
                    this.$emit('close');
                } catch {
                    this.$notify.error('Something went wrong processing your request, please try again.');
                    this.isLoading = false;
                }
            } catch {}
        }
    }
    //#endregion Methods
}
