
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import KeyPersonSelector from '@/components/form/KeyPersonSelector.vue';
import * as VamService from '../../services/DAL/vamService';
import * as SystemService from '../../services/DAL/systemService';
import ClientSelector from '@/components/form/ClientSelector.vue';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare var tryParseBool: any;

@Component({
  components: {
    KeyPersonSelector,
    ClientSelector
  }
})
export default class OwnerRoleAssigner extends Vue {
  $refs: {
    frmOwnerRoleAssigner: ElementUI.Form
  }
  //#region Data
  private _vam_Service: VamService.VamService;
  private _system_Service: SystemService.SystemService;

  @Prop() owner!: VamService.OwnersV2;
  @Prop() roleTypeId: number;

  ownerRoleAssignment = new VamService.OwnerRoleAssignments();
  roles = [];
  owners = [] as VamService.OwnersV2[];
  keyPersons = [] as VamService.KeyPersons[];
  clientId: number = this.owner.ClientId;

  get selectedRole(): any {
    let selectedRoleArray = [];

    if (this.roles.length && this.ownerRoleAssignment.RoleId) {
      var vm = this;
      selectedRoleArray = this.roles.filter(role => {
        return role.KeyValueInt == vm.ownerRoleAssignment.RoleId;
      });
    }

    return selectedRoleArray.length ? selectedRoleArray[0] : {};
  }

  get allowPerson(): Boolean {
    return tryParseBool(this.selectedRole.AdditionalData1, false);
  }

  get allowEntity(): Boolean {
    return tryParseBool(this.selectedRole.AdditionalData2, false);
  }
  //#endregion Data

  //#region Lifecycle
  async created() {
    this._vam_Service = new VamService.VamService();
    this._system_Service = new SystemService.SystemService();
    this.fetchData();
    this.ownerRoleAssignment.OwnerId = this.owner ? this.owner.Id : null;
    this.ownerRoleAssignment.RoleTypeId = this.roleTypeId.toString();
    this.ownerRoleAssignment.RoleTypeText = await this.$namedKeyService.getText(
      'Owner.RoleType',
      this.roleTypeId
    );
  }

  mounted() {
    this.$refs.frmOwnerRoleAssigner.resetFields();
  }
  //#endregion Lifecycle

  //#region Watches
  @Watch('ownerRoleAssignment.RoleId')
  onChangeRoleId() {
    if (this.ownerRoleAssignment.RoleId) {
      if (this.allowEntity && !this.allowPerson) {
        this.ownerRoleAssignment.EntityType = 'Entity';
      } else if (this.allowPerson && !this.allowEntity) {
        this.ownerRoleAssignment.EntityType = 'Person';
      }
    } else {
      this.ownerRoleAssignment.EntityType = null;
      this.ownerRoleAssignment.EntityId = null;
    }
  }
  @Watch('clientId')
  onChangeClientId() {
    this.fetchOwners();
}
  //#endregion Watches

  //#region Methods
  async fetchData() {
    this.fetchRoles();
    this.fetchOwners();
    this.fetchKeyPersons();
  }

  async fetchRoles() {
    this.roles = [];
    const getNamedKeysParameters = {} as SystemService.GetNamedKeysParameters;
    getNamedKeysParameters.KeyType = 'Owner.Role';
    getNamedKeysParameters.ParentKeyType = 'Owner.RoleType';
    getNamedKeysParameters.ParentKeyValue = this.roleTypeId;
    this.roles = await this._system_Service.GetNamedKeys(
      getNamedKeysParameters
    );
    if (this.roles.length === 1) {
      this.ownerRoleAssignment.RoleId = this.roles[0].KeyValueInt;
    }
  }

  async fetchOwners() {
    this.owners = [];
    if (this.owner && this.owner.ClientId > 0) {
      const getOwnersParameters = {} as VamService.GetOwnersV2Parameters;
      getOwnersParameters.ClientId = this.clientId;
      this.owners = await this._vam_Service.GetOwnersV2(getOwnersParameters);
      if (this.owners.length === 1 && this.ownerRoleAssignment.EntityType === 'Owner' ){
        this.ownerRoleAssignment.EntityId = this.owners[0].Id;
      }
    }
  }

  async fetchKeyPersons() {
    this.keyPersons = [];
    const params = {} as VamService.GetKeyPersonsParameters;
    this.keyPersons = await this._vam_Service.GetKeyPersons(params);
  }

  async onSaveNewKeyPerson(KeyPersonId: number) {
    await this.fetchKeyPersons();
    this.ownerRoleAssignment.EntityId = KeyPersonId;
  }

  async saveForm() {
    this.$refs.frmOwnerRoleAssigner.validate(async valid => {
      if (valid) {
        try {
            await new SmartObject('OwnerRoleAssignment').createObject(
                this.ownerRoleAssignment
            );

            this.$notify.success(this.ownerRoleAssignment.RoleTypeText + ' added.');

            this.closeDialog();
        }
        catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
      }
    });
  }

  closeDialog() {
    this.$emit('close-dialog');
  }
  //#endregion Methods
}
