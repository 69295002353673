
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';
import ChangeLogList from '@/js/ChangeLog/ChangeLogList.vue';
import formatters from '../../utils/formatters';
import InvestmentParentSelector from '@/components/form/InvestmentParentSelector.vue';
import ElementUI from 'element-ui';

declare var $: any;
declare var SmartObject: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(value: any, defaultValue: any): any;

@Component({
    components: {
        ChangeLogList,
        InvestmentParentSelector
    }
})
export default class FundHoldingItemEditView extends Vue {
    $refs: {
        fundItemEditView: ElementUI.Card
    }
    //#region Private Declarations
    // private _assetervice: AssetService.AssetService;
    //#endregion Private Declarations

    //#region Props
    @Prop({ required: true, type: Object }) item: AssetService.FundHoldings | AssetService.FundHoldingsWithTranches;
    @Prop() viewType: string;
    //#endregion Props

    //#region Data
    item_ = {} as AssetService.FundHoldings | AssetService.FundHoldingsWithTranches;

    showChangeLogList: boolean = false;
    securityLevel_: number = getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP);
    activeTab: string = '0';
    isLoading = false;

    //#endregion Data
    @Watch('item')
    async itemChanged(
        val: AssetService.FundHoldings | AssetService.FundHoldingsWithTranches,
        oldVal: AssetService.FundHoldings | AssetService.FundHoldingsWithTranches
    ) {
        this.item_ = this.item;
    }
    @Watch('item.HoldingInvestmentParentId')
    async holdingInvestmentParentIdChanged(val: string, oldVal: string){
        if (!this.isTranche){
            this.isLoading = true;
            await new SmartObject('FundHolding', this.item.KeyId).updateObject({HoldingInvestmentParentId: (this.item as AssetService.FundHoldings).HoldingInvestmentParentId});
            this.$notify.success('Co-Investment Parent Updated');
            this.isLoading = false;
        }
    }
    //#region Lifecycle
    async created() {
        // this._liqService = new LiqService.LiqService();

    }
    //#endregion Lifecycle
    get isTranche(): boolean{
        if ((this.item as AssetService.FundHoldingsWithTranches).TrancheId){
            return true;
        }
        else {
            return false;
        }
    }
    get title(){
        if (this.isTranche){
            return `${this.item.Holding}: ${(this.item as AssetService.FundHoldingsWithTranches).Tranche} `;
        }
        else {
            return this.item.Holding;
        }
    }
    //#region Methods
    onTabClick(tab) {
        switch (tab.label) {
            case 'Valuations':
                var $ucValuationList = $(this.$refs.fundItemEditView.$el).find('#ucValuationList');
                if (!$ucValuationList.hasClass('loaded')) {
                    $ucValuationList.fetchSmartControl({
                        params: {
                            EntityType: this.isTranche ? 'FundHoldingTranche' : 'FundHolding'
                            , EntityId: this.item.KeyId
                            , CanEdit: tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets), 0) >= this.$namedKey.SecurityLevel.Admin
                        }
                        , collapsible: false
                        , showSpinner: true
                    });
                    $ucValuationList.addClass('loaded');
                }
                break;
            case 'Change Log':
                this.showChangeLogList = true;
                break;
        }
    }

    onSave(item) {
        this.refreshChangeLogList();
        this.$emit('save', item);
    }

    async refreshChangeLogList() {
        this.showChangeLogList = false;
        await this.$nextTick();
        this.showChangeLogList = true;
    }
    async editName(){
        try {
            let name: string = '';
            const conf: any  = await this.$prompt('Update Name', 'Update', { // MessageBoxData Type must be incorrectly defined, so declaring it as any
                confirmButtonText: 'OK',
                showCancelButton: true,
                inputValue: this.item.DisplayName,
                inputErrorMessage: 'Name is required and must be no longer than 100 characters.',
                inputValidator: function(input){if (!!input && input.length < 101) return true; else return false;}
                });
                name = conf.value;
            try {
                this.isLoading = true;
                await new SmartObject(this.isTranche ? 'FundHoldingTranche' : 'FundHolding', this.item.KeyId).updateObject({Name: name});
                this.item_.DisplayName = name;
                this.$notify.success(`${this.isTranche ? 'Tranche' : 'Holding'} updated.`);
                this.isLoading = false;
                this.$emit('updated', this.item_);
            } catch {
                this.$notify.error('Something went wrong processing your request, please try again.');
                this.isLoading = false;
            }
        }
        catch{}    
    }

    async deleteItem(){
        try {
            await this.$confirm(
                `This will permanently delete this ${this.isTranche ? 'Tranche' : 'Holding'}. Continue?`,
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }
            );

            try {
                this.isLoading = true;
                await new SmartObject(this.isTranche ? 'FundHoldingTranche' : 'FundHolding', this.item.KeyId).updateObject({Deactivated: this.$moment()});
                this.$notify.success(`${this.isTranche ? 'Tranche' : 'Holding'} deleted.`);
                this.isLoading = false;
                this.$emit('item-deleted');
                this.$emit('close');
            } catch {
                this.$notify.error('Something went wrong processing your request, please try again.');
                this.isLoading = false;
            }
        } catch {} //canceled

    }
    //#endregion
}
