
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop, Model } from 'vue-property-decorator';
import * as AssetService from '../../services/DAL/assetService';

@Component({
    inheritAttrs: false
})
export default class StructureTypeSelector extends Vue {
//#region Private declarations for Services
    private _asset_Service: AssetService.AssetService;

//#endregion Private declarations for Services

//#region Props
    @Prop() investmentId: number;
    @Prop() side: string
    @Prop() value: string;
//#endregion Props

//#region Data
    structureTypes = [] as AssetService.StructureTypeForInvestment[];
    selectedValue: string = null;
//#endregion Data

    //#region Computed
    //#endregion Computed

   //#region Lifecycle
    async created() {
        this._asset_Service = new AssetService.AssetService();
    }
    async mounted(){
        this.selectedValue = this.value;
        this.fetchStructureTypes();
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('value')
    onStructureTypeChange(val: string, oldVal: string) {
        this.selectedValue = val;
    }

    //#endregion Watches

//#region Methods
    async fetchStructureTypes() {
        this.structureTypes = [];
        const parms = {} as AssetService.GetStructureTypeForInvestmentParameters;
        parms.InvestmentId = this.investmentId;
        parms.Side = this.side;
        this.structureTypes = await this._asset_Service.GetStructureTypeForInvestment(parms);
        if (this.structureTypes.length == 1 && this.value == undefined){ //only set if no value. otherwise, it makes the field dirty even if it's the same value
            this.selectedValue = this.structureTypes[0].StructureType;
            this.$emit('input', this.selectedValue);
        }
    }

    //#endregion Methods
}
