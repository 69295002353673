
    import { Component, Vue, Prop, Watch } from "vue-property-decorator";
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import EventLogList from '@/views/Event/EventLogList.vue';


    @Component({
        components: {
            EventLogList
        }
    })
    export default class ProcessNonShareValuationStatistics extends Vue {

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    //#endregion Private declarations for Services

    //#region Props
    //#endregion Props

    //#region Data
    queueRecord = {} as TransactionsService.QueueProcessNonShareValuationStatistics;
    loading = false;
    showEventList = true;
    //#endregion Data

    //#region Lifecycle
    created() {
        this._transactionsService = new TransactionsService.TransactionsService();
    }
    //#endregion Lifecycle

    //#region Watches
    //#endregion Watches

    //#region Computed
    //#endregion Computed

    //#region Methods
    async queueProcess(){
        try {
            this.loading = true;
            const params = {} as TransactionsService.QueueProcessNonShareValuationStatisticsParameters;
            const result: TransactionsService.QueueProcessNonShareValuationStatistics[] = await this._transactionsService.QueueProcessNonShareValuationStatistics(params);
            this.queueRecord = result[0];
            console.log(result);
            this.$notify.info(`Process queued (${this.queueRecord.Id})`);
            this.showEventList = false;
            await this.$nextTick();
            this.showEventList = true;
        } catch (err) {
            console.error(err);
        }
        this.loading = false;
    }
    //#endregion Methods

    }
