<template>
    <div id="divExplorerVue" v-cloak >
        {{message}}
        {{items.length}} Files Found
        <el-button icon="d-arrow-left" v-on:click="folderUp" type="info" size="small" v-show="!(path==previousPath)">{{previousPathDisplay}}</el-button>
        <span class="text-small"> {{ path }}</span>
        <el-table v-if="items[0]"
                  :data="items"
                  style="width: 100%;"
                  stripe
                  v-loading="isLoading"
                  @current-change="itemSelect">
            <el-table-column>
                <template slot-scope="props">
                    <i class="el-icon-document" v-show="!props.row.folder"></i>
                    <i class="el-icon-caret-right" v-show="props.row.folder && props.row.folder != null && props.row.folder.childCount>0"></i>
                </template>
            </el-table-column>
            <el-table-column label="Name"
                             prop="name"
                             min-width="400px">
            </el-table-column>
            <el-table-column label="Children"
                             align="center"
                             min-width="70px"
                             prop="folder.childCount">
            </el-table-column>
            <el-table-column label="Created By"
                             min-width="150px"
                             align="center"
                             prop="createdBy.user.displayName">
            </el-table-column>
            <el-table-column label="Created On"
                             min-width="150px"
                             align="center"
                             prop="createdDateTime"
                             :formatter="formatterShortDateTime">
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
    import Vue from 'vue';
    import mixinUtility from './../VueCommon/Utility.js'
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';

    export default Vue.extend({
        name: 'ExplorerBase'
        , mixins: [mixinUtility]
        , props: {
            securityLevel: {
                type: Number
                , default: null
            }
            , basePath: {
                type: String
                , default: '/drive/root:/VAM/Attachments/Ingest'
            }
            , suffix: {
                type: String
                , default: '/children?$select=name,id,folder,webUrl, createdBy, createdDateTime, parentReference, eTag'
            }
        }
        , data: function () {
            return {
                items: {}
                , path: ''
                , previousPath: ''
                , showAttachmentEdit: false
                , showAttachmentAssignment: false
                , selectedFileId: null
                , securityLevel_: this.securityLevel
                , message: ''
                , isLoading: false
                , graphService: {}
            }
        }
        , created: function () {
            this.graphService = new MicrosoftGraphService();
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageDocuments), 0);
            }

            this.fetchItems(this.path);
        }
        , computed: {
            previousPathDisplay: function () {
                return (this.previousPath == '' || this.previousPath == undefined) ? '<root>' : this.previousPath;
            }
        }
        , watch: {
            'basePath': function (val, oldVal) {
                this.fetchItems();
            }
        }
        , methods: {
            fetchItems: async function (path) {
                this.isLoading = true;
                if (path != undefined && path != '' && path.substring(0, 1) != '/')
                    path = '/' + path;
                else if (path == undefined)
                    path = '';


                const graphClient = await this.graphService.getGraphClient();
                this.items = [];
                const fullPath = this.basePath + path + this.suffix;
                await this.loop(fullPath, graphClient);
                console.log('File Loops Complete');
                this.message = '';
                this.isLoading = false;

            },
            getFiles: async function (path, graphClient) {
                return await graphClient
                        .api(path)
                        .get();
            },
            loop: async function (path, graphClient) {
                let nextLink = ' '; //not empty, but won't affect the outcome
                let result = null
                let batch = 1
                while (!!path && !!nextLink) {
                    this.message = 'Getting files in Batch ' + batch;
                    result = await this.getFiles(path + nextLink, graphClient);
                    this.items = this.items.concat(result.value);
                    if (result['@odata.nextLink'])
                        nextLink = result['@odata.nextLink'].substring(result['@odata.nextLink'].indexOf('&$skiptoken'));
                    else
                        nextLink = '';
                    batch++;
                    this.message = '';
                }
            }
            , itemSelect: function (selectedRow) {
                if (selectedRow != null) {
                    console.log(selectedRow);
                    if (selectedRow.folder != null && selectedRow.folder.childCount) {
                        this.previousPath = this.path;
                        var prefix = (this.path != undefined && this.path != '') ? '/' : '';
                        this.path = prefix + this.path + prefix + selectedRow.name;
                        this.fetchItems(this.path);
                    }
                    else if (!selectedRow.folder) {
                        this.selectedFileId = selectedRow.id;
                        this.$emit('row-selected', selectedRow);
                    }
                }
            }
            , folderUp: function () {
                this.path = this.previousPath;
                if (this.previousPath.indexOf('/') > 0) {
                    paths = this.previousPath.split('/');
                    this.previousPath = paths[paths.length];
                }
                else {
                    this.previousPath = '';
                }

                this.fetchItems(this.path);
            }
        }
    })
</script>
