
import Vue from 'vue';
import Component from 'vue-class-component';
//import { Watch } from 'vue-property-decorator';
//import moment from 'moment';
import CurrencyLabel from '@/js/VueCommon/CurrencyLabel.vue'
import * as LiqService from '../../services/DAL/liq_Service';
//import Common from '../../utils/common';
import * as MicrosoftGraphTypes from "@microsoft/microsoft-graph-types" 
import { MicrosoftGraphService } from './../../services/MicrosoftGraphService';
import MicrosoftGraphClient, { GraphRequest, ResponseType } from '@microsoft/microsoft-graph-client';

declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
    components: {
        CurrencyLabel
    }
})
export default class ReserveImport extends Vue {
    //#region Data
    private _liqService: LiqService.LiqService;
    //private _common: Common;
    private _graphService: MicrosoftGraphService;

    reserveImport = [] as LiqService.ReserveImport[];
    graphClient: MicrosoftGraphClient.Client;
    showUpdateButton = false;
    loading = false;
    //#endregion Data

    //#region Lifecycle
    async created() {
        this._liqService = new LiqService.LiqService();
        //this._common = new Common();
        this._graphService = new MicrosoftGraphService();
        this.graphClient = await this._graphService.getGraphClient();

        this.fetchReserveImport();
    }
    //#endregion Lifecycle

    //#region Watches
    //#endregion Watches

    //#region Methods

    async fetchReserveImport() {
        this.reserveImport = await this._liqService.GetReserveImport();
        var t = this;
        this.reserveImport.forEach(async function (item) {
            var path = item.Path;
            var model = await t.getFromExcel(path)
            item.NewAmount = model.values[0][0];
            if (t.$accounting.toFixed(item.NewAmount, 2) != t.$accounting.toFixed(item.Amount, 2)){
                t.showUpdateButton = true; 
            }
        });
    }
    async updateReserveImport() {
        this.loading = true;
        this.showUpdateButton = false;
        let updates = 0;
        const el = this;
        await this.$asyncForEach(this.reserveImport, 
            async function (reserveImport: LiqService.ReserveImport) {
                const params = {} as LiqService.ImportReserveParameters;
                params.ExcelCellImportConfigurationId = reserveImport.ExcelCellImportConfigurationId;
                params.Amount = reserveImport.NewAmount;
                await el._liqService.ImportReserve(params);
                updates++;
            }
        );
        this.fetchReserveImport(); //refresh.  "New" will be empty
        if (updates){
            this.$notify.success(`Imported (${updates} record${updates > 1 ? 's' : ''})`);
        }
        this.loading = false;
    }
    async getFromExcel(path) {
        const result = this.graphClient
            .api(path)
            .get();
        return result;
    }
    highlightDifference(a, b) {
        return (this.$accounting.toFixed(a, 2) != this.$accounting.toFixed(b, 2)) ? 'Yellow' : '';
    }

    //#endregion Methods
}
