<template>
    <!--Note: v-validate is used for Dirty/Clean, but not actual validation. Actual validation is handled by el-form, which doesn't track dirty/clean-->
    <el-form :model="configuration" :rules="configurationRules" ref="configurationForm" label-width="15%" v-loading="loading">
        <el-form-item>
            <el-button @click="saveConfiguration" type="primary" class="pull-right" size="small" :disabled="!(securityLevel_>=80 && formValid && isFormDirty)">Save</el-button>
        </el-form-item>
        <el-form-item label="Description" prop="Description">
            {{configuration.Description}}
        </el-form-item>
        <el-form-item :label="configuration.Description2Label" prop="Description2">
            {{configuration.Description2}}
        </el-form-item>
        <el-form-item :label="configuration.IntDescriptionLabel" prop="IntDescription">
            {{configuration.IntDescription}}
        </el-form-item>
        <el-form-item :label="configuration.EntityType" prop="EntityId">
            {{configuration.EntityId}}
        </el-form-item>
        <hr />
        <el-form-item label="File" prop="FileId">
            <el-button v-if="!!fileProperties && Object.keys(fileProperties).length && !!fileProperties.parentReference && Object.keys(fileProperties.parentReference).length" type="text" @click="handleSetFile" :title="configuration.FileId">{{fileProperties.parentReference.path.replace('/drive/root:', '')}}/{{fileProperties.name}}</el-button>
            <el-button v-else type="text" @click="handleSetFile" title="invalid file">Set File</el-button>
            <a :href="fileProperties.webUrl" target="_blank" v-validate name="FileId" ><i class="el-icon-download" ></i></a>
        </el-form-item>
        <el-form-item label="Sheet" prop="SheetName">
            <el-select filterable size="mini"
                       v-validate name="SheetName"
                       v-bind:disabled="(worksheets.length == 0 || securityLevel_<80)"
                       v-model="configuration.SheetName">
                <el-option v-for="item in worksheets"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Cell" prop="Cell">
            <el-input size="mini" style="width: 100%; max-width:75px" v-validate name="Cell" type="text" v-model="configuration.Cell" :disabled="(securityLevel_<80)"></el-input>
        </el-form-item>
        <el-form-item label="Value" >
            {{cellValue | number2}}
        </el-form-item>
        <hr />
        <el-form-item label="Created" prop="Created">
            {{configuration.Created}}
        </el-form-item>
        <el-form-item label="Modified" prop="Modified">
            {{configuration.Modified}}
        </el-form-item>
    </el-form>
</template>
<script>
    import Vue from 'vue';
    import mixinSchema_vam from './../DAL/mixinSchema_vam'
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';
    import util from './../VueCommon/Utility.js'
    import debounce from 'lodash/debounce';
    import { AuthService } from '@/services/AuthService';


    export default Vue.extend({
        name: 'ExcelCellImportConfigurationEdit'
        , mixins: [mixinSchema_vam, util ]
        , props: {
            pId: {
                type: Number
            }
            , pConfiguration: {
                type: Object
            }
            , params: {
                type: Object
                , default: function () { return {} }
            }
            , securityLevel: {
                type: Number
                , default: null
            }
}
        , data() {
            return {
                id: null
                , configuration: this.vam_GetExcelCellImportConfiguration_New()
                , configurationRules: {
                    FileId: [
                        { required: true, message: 'File is required', trigger: 'blur' },
                        { min: 30, trigger: 'blur' }
                    ],
                    SheetName: [
                        { required: true, message: 'Sheet is required', trigger: 'blur' },
                        { min: 2, trigger: 'blur' }
                    ],
                    Cell: [
                        { required: true, message: 'Cell is required', trigger: 'blur' },
                        { min: 2, trigger: 'blur' }
                    ],
                }
                , formValid: false
                , fileProperties: {}
                , worksheets: []
                , cellValue: null
                , securityLevel_: this.securityLevel
                , loading: false
                , graphService: {}
                , authService: {}
            }
        }
        , created: function () {
            this.graphService = new MicrosoftGraphService();
            this.authService = new AuthService();
            if (this.securityLevel_ === null) {
                this.securityLevel_ = tryParseInt(getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAP), 0);
            }

            this.id = this.pId || this.params.Id || (this.pConfiguration ? this.pConfiguration.Id : null);
            if (this.pConfiguration && this.pConfiguration.Id) {
                this.configuration = this.pConfiguration;
            }
            else {
                this.fetchConfiguration(this.id); //watcher doesn't get it the first time
            }
            this.fetchFileProperties();
        }
        , computed: {
            isFormDirty() {
                return Object.keys(this.veeFields).some(key => this.veeFields[key].dirty);
            }
        }
        , watch: {
            'pConfiguration': function (val, oldVal) {
                if (this.pConfiguration && this.pConfiguration.Id) {
                    this.configuration = this.pConfiguration;
                    this.fetchFileProperties();
                }
            }
            , 'pId': function (val, oldVal) {
                this.fetchConfiguration(val);
            }
            , 'configuration.FileId': function (val, oldVal) {
                this.validateForm();
            }
            , 'configuration.SheetName': function (val, oldVal) {
                this.validateForm();
            }
            , 'configuration.Cell': function (val, oldVal) {
                if (this.configuration.SheetName && this.configuration.SheetName != null && this.configuration.SheetName != '' && val != null && val != '') {
                    this.fetchCellValue();
                }
                else {
                    this.cellValue = null;
                }
                this.validateForm();
            }
        }
        , methods: {
            validateForm: debounce(function () {
                if (this.$refs['configurationForm']) {
                    this.$refs['configurationForm'].validate((valid) => {
                        this.formValid = valid;
                    });
                }
                else {
                    this.formValid = false;
                }
            }, 500)
            , fetchConfiguration: function (id) {
                if (this.id == -1) {
                    this.configuration = this.vam_GetExcelCellImportConfiguration_New();
                    if (this.clients.length == 1) {
                        this.configuration.ClientId = this.clients[0].Id;
                    }
                }
                else {
                    this.configuration = this.vam_GetExcelCellImportConfiguration_Object({
                        ConfigurationId: this.id
                    });
                    if (this.configuration && this.configuration.FileId) {
                        this.fetchFileProperties();
                    }
                }
            }
            , saveConfiguration: async function () {
                this.$refs.configurationForm.validate(async (valid) => {
                    if (valid) {
                        this.loading = true;
                        //let path = this.configuration.Path;
                        //delete this.configuration.Path; // remove this attribute because it is a computed column
                        const success = await new SmartObject('ExcelCellImportConfiguration', this.id).updateObject(this.configuration);
                        if (success) {
                            if (success !== this.configuration.Id) {
                                this.configuration.Id = success;
                            }
                            this.$notify.success('Configuration Updated');
                            this.$emit('saved', this.configuration);
                            this.$emit('update:pConfiguration', this.configuration);
                            this.loading = false;
                        }
                    } else {
                        this.$message.error('Not Saved.  See Validation Errors.');
                        this.loading = false;
                        return false;
                    }
                });
            }
            , async fetchFileProperties() {
                this.loading = true;
                const graphClient = await this.graphService.getGraphClient();

                const fileId = this.configuration.FileId;

                let basePath = '/drive/items/'; 
                if (this.configuration.DriveId){
                    basePath = `/drives/${this.configuration.DriveId}/items/`; 
                }
                const path = basePath + fileId;
                try {
                    const response = await graphClient
                        .api(basePath + fileId)
                        .get();
                    this.fileProperties = response;
                    this.fetchWorksheets(path, graphClient);
                }
                catch (err){
                    console.log(err);
                    if (err.code == "itemNotFound") {
                        this.fileNotAvailable = true;
                        this.$alert('File not available.  You may not have permission from the File Store (One Drive or Share Point).', 'error');
                    }
                    else {
                        this.$alert(err.message, 'Error');
                    }
                }
                this.loading = false;
            }
            , fetchWorksheets: async function(path, graphClient) {
                this.loading = true;
                try {
                    const response = await graphClient
                        .api(path + '/workbook/worksheets')
                        .get();
                    console.log(response);
                    this.worksheets = response.value;
                    //see if currently-set sheet exists in updated list of sheets; if not, clear current sheet.
                    var sheetItem = this.getSelectedArrayItem(this.worksheets, this.configuration.SheetName, 'id');
                    if (sheetItem == undefined) {
                        this.configuration.SheetName = null;
                        this.configuration.Cell = null;
                        this.cellValue = null;
                    }
                    else {
                        this.fetchCellValue();
                    }
                }
                catch (err){
                    console.log(err);
                    this.$notify.warning('Error getting worksheets');
                }
                this.loading = false;
            }
            , fetchCellValue: debounce(async function () {
                this.loading = true;
                const fileId = this.configuration.FileId;

                let basePath = '/drive/items/'; 
                if (this.configuration.DriveId){
                    basePath = `/drives/${this.configuration.DriveId}/items/`; 
                }
                const path = basePath + fileId + '/workbook/worksheets/' + this.configuration.SheetName + '/Range(address=\'' + this.configuration.Cell + ':' + this.configuration.Cell + '\')?$select=values';

                try {
                    const graphClient = await this.graphService.getGraphClient();
                    const response = await graphClient
                        .api(path)
                        .get();

                    if (response.values && response.values[0] && response.values[0][0]) {
                        this.cellValue = response.values[0][0];
                    }
                    else {
                        this.cellValue = null;
                        this.$notify.warning('No Cell Value Found');
                    }
                }
                catch (err) {
                    console.log(err);
                    this.cellValue = null;
                    this.$notify.warning('Error getting cell value');
                    return;
                }
                this.loading = false;
            }, 500)

            , handleSetFile: async function () {
                var el = this;
                const graphToken = this.authService.getGraphToken();
                var odOptions = {
                    clientId: "9deff1bd-41fb-453c-8e7a-b3d12abe1fa5",
                    action: "query",
                    multiSelect: false,
                    advanced: {
                        endpointHint: "https://visiumpartners.sharepoint.com/shared%20documents/",
                        accessToken: graphToken,
                        loginHint: getSecurityUserId(),
                        isConsumerAccount: false,
                        filter: '.xlsx',
                        //navigation: { //can't figure out the right values to make this work, and there is no documentation
                        //    entryLocation: {
                        //        sharePoint: {
                        //            sitePath: 'https://visiumpartners.sharepoint.com',
                        //            listPath: 'https://visiumpartners.sharepoint.com/shared%20documents' + this.fileProperties.parentReference.path.substr(12, 10000) //12 is the length of "/drive/root:"
                        //            , itemPath: 'https://visiumpartners.sharepoint.com/shared%20documents' + this.fileProperties.parentReference.path.substr(12, 10000) + this.fileProperties.name
                        //        }
                        //    }
                        //}
                    },
                    success: function (files) {
                        if (files && files.value && files.value.length == 1){
                            el.configuration.FileId = files.value[0].id;
                            el.configuration.DriveId = files.value[0].parentReference.driveId;
                            el.fetchFileProperties();
                        }
                        else {
                            this.$notify.warning('A single file must be selected');
                            console.log(files);
                        }
                    },
                    cancel: function () { /* cancel handler */ },
                    error: function (e) {
                        console.log(e);
                    }
                }
                OneDrive.open(odOptions);
            }
        }
    })
</script>
<style >
    .el-form-item{
        margin-bottom:5px;
    }
    .el-form-item__label{
        font-weight: bold;
    }
</style>